<template>
  <v-dialog
    class="backdrop"
    overlay-opacity="0.5"
    persistent
    v-model="dialogState_"
    width="570px"
  >
    <v-card class="add-new-property pt-0">
      <v-card-title
        class="py-0 px-5 pl-sm-2 mb-4 d-flex justify-space-between align-center custom__shadow"
      >
        <div class="d-flex align-center">
          <h4>
            {{ propertyInfoValid ? 'تحديث بيانات العقار' : 'اضافه عقار' }}
          </h4>
        </div>

        <v-btn
          data-test="closeModal"
          @click.prevent="closeModel"
          class="ma-0 my-1"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs-items v-model="tab" class="pa-5 pt-0">
        <v-tab-item class="add__property__detail__popup">
          <propertyDetails
            v-bind="propertyDetailsProps"
            @nextTab="nextTab"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
        <v-tab-item>
          <propertyBuildingType
            ref="property-building"
            v-bind="buildingTypeProps"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
        <v-tab-item>
          <propertyLocation
            ref="property-location"
            :resetValidation="resetValidationStatus"
            :propertyInfo="addPropertyInfo"
            :propertyInfoValid="propertyInfoValid"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
        <v-tab-item>
          <propertyOwnerStatus
            ref="propertyOwner"
            v-bind="ownerStatusProps"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @addRealEstate="addRealEstate"
            @updateRealEstate="updateRealEstate"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
        <v-tab-item>
          <propertyInnerDetails
            ref="propertyDetails"
            v-bind="innerDetailsProps"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @addRealEstate="addRealEstate"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
        <v-tab-item>
          <filesUploading
            ref="uploadImage"
            @previousTab="previousTab"
            @nextTab="nextTab"
            :title="propImage"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
            @addRealEstate="addRealEstate"
            :propertyInfo="addPropertyInfo"
            :propertyInfoValid="propertyInfoValid"
          />
        </v-tab-item>
        <v-tab-item>
          <showInHectar
            ref="showInHectar"
            v-bind="showInHectarProps"
            @addRealEstate="addRealEstate"
            @updateRealEstate="updateRealEstate"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
        <v-tab-item>
          <propertyRolechart
            ref="propertyRolechart"
            v-bind="propertyRolechartProps"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @addRealEstate="addRealEstate"
            @updateRealEstate="updateRealEstate"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import config from '@/config'
import propertyDetails from './unitModelTabs/propertyDetails.vue'
import propertyLocation from './unitModelTabs/propertyLocation.vue'
import propertyBuildingType from './unitModelTabs/propertyBuildingType.vue'
import showInHectar from './unitModelTabs/showInHectar.vue'
import propertyOwnerStatus from './unitModelTabs/propertyOwnerStatus.vue'
import propertyInnerDetails from './unitModelTabs/propertyInnerDetails.vue'
import filesUploading from './unitModelTabs/filesUploading.vue'
import propertyRolechart from './unitModelTabs/propertyRolechart.vue'
import { EnvService, realEstateService } from '@/services'
import { bus } from '@/helpers/eventBus'
import checkClientData from '@/mixins/checkClientData.js'

export default {
  name: 'unitModel',
  mixins: [checkClientData],
  components: {
    propertyDetails,
    propertyLocation,
    propertyBuildingType,
    propertyOwnerStatus,
    propertyInnerDetails,
    filesUploading,
    propertyRolechart,
    showInHectar
  },
  data() {
    return {
      addingBtnLoading: false,
      propImage: 'صور العقار',
      rolChartpImage: 'صور المخطط',
      tab: 0,
      addPropertyInfo: {},
      employees: [],
      resetValidationStatus: false,
      buildingFlag: false,
      newOwner: {},
      newRenter: {},
      hasPurposeTypes: [
        'land',
        'compound',
        'building',
        'Tower',
        'Building',
        'Storehouse'
      ]
    }
  },
  props: {
    propertyInfo: {
      default() {
        return {}
      }
    },
    dialogState: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'user', 'currentEnv']),
    realestateUnits() {
      return this.realEstatePurpose.length
        ? this.addPropertyInfo?.type?.ref[this.realEstatePurpose]
        : []
    },
    realEstatePurpose() {
      return this.addPropertyInfo?.purpose?.nameEn ?? ''
    },
    unitsPayload() {
      let obj = {}
      this.realestateUnits.map((unit) => {
        obj = {
          ...obj,
          [unit.key]: this.addPropertyInfo[unit.key]
            ? Number(this.addPropertyInfo[unit.key])
            : 0
        }
      })
      return obj
    },
    isRentContractValid() {
      return !!this.propertyInfo?.rentedUnitsCount
    },
    isMainPropDisabled() {
      return !!(this.isRentContractValid && this.propertyInfoValid)
    },
    hasArenter() {
      return !!(
        this.addPropertyInfo.newRenterChecked || this.addPropertyInfo.tenet
      )
    },
    propertyInfoValid() {
      if (this.propertyInfo) {
        return !(
          Object.keys(this.propertyInfo).length === 0 &&
          this.propertyInfo.constructor === Object
        )
      }
      return null
    },
    payTypeOptions() {
      return config.payTypeOptions
    },
    realestateTypes() {
      return this.configs.realestateTypes
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    },
    realEstateStatus() {
      return this.configs.realEstateStatus
    },
    realestatePopulations() {
      return this.configs.realestatePopulations
    },
    clientType() {
      return config.clientType
    },
    propertyTypeName() {
      return this.addPropertyInfo?.type?.nameAr
    },
    isForRent() {
      return (
        this.addPropertyInfo?.status?.nameAr === 'للإيجار' ||
        this.addPropertyInfo?.status?.nameAr === 'شاغرة'
      )
    },
    isForSale() {
      return this.addPropertyInfo?.status?.nameAr === 'للبيع'
    },
    isLand() {
      return this.buildingType === 'land'
    },
    isStorehouse() {
      return this.buildingType === 'Storehouse'
    },
    isStore() {
      return this.buildingType === 'store'
    },
    isFlat() {
      return this.buildingType === 'flat'
    },
    isFloor() {
      return this.buildingType === 'floor'
    },
    isChalet() {
      return this.buildingType === 'Chalet'
    },
    isGuestHouse() {
      return this.buildingType === 'Guest house'
    },
    accommodationType() {
      return (
        this.isForRent &&
        (this.isFlat || this.isFloor || this.isChalet || this.isGuestHouse)
      )
    },
    isSingleBuilding() {
      return this.addPropertyInfo?.isSingleOrMultiple === 'singleUnit'
    },
    isBuilding() {
      return this.addPropertyInfo?.isBuilding
    },
    isMultiBuilding() {
      return this.isBuilding && !!!this.isSingleBuilding
    },
    buildingType() {
      return this.addPropertyInfo?.type?.nameEn || ''
    },
    realEstatesHasPurpose() {
      return !!this.hasPurposeTypes.includes(this.buildingType)
    },
    propertyDetailsProps() {
      return {
        realestateTypes: this.realestateTypes,
        realEstateStatus: this.realEstateStatus,
        resetValidation: this.resetValidationStatus,
        propertyInfo: this.addPropertyInfo,
        propertyInfoValid: this.propertyInfoValid,
        isMainPropDisabled: this.isMainPropDisabled
      }
    },
    buildingTypeProps() {
      return {
        resetValidation: this.resetValidationStatus,
        propertyTypeName: this.propertyTypeName,
        propertyInfo: this.addPropertyInfo,
        propertyInfoValid: this.propertyInfoValid,
        isMainPropDisabled: this.isMainPropDisabled
      }
    },
    ownerStatusProps() {
      return {
        buildingType: this.buildingType,
        employees: this.employees,
        clientType: this.clientType,
        resetValidation: this.resetValidationStatus,
        propertyInfo: this.addPropertyInfo,
        propertyInfoValid: this.propertyInfoValid,
        isMultiBuilding: this.isMultiBuilding
      }
    },
    innerDetailsProps() {
      return {
        resetValidation: this.resetValidationStatus,
        propertyInfo: this.addPropertyInfo,
        propertyInfoValid: this.propertyInfoValid,
        isLand: this.isLand,
        isStorehouse: this.isStorehouse,
        isStore: this.isStore,
        isFlat: this.isFlat,
        isGuestHouse: this.isGuestHouse,
        isChalet: this.isChalet
      }
    },
    propertyRolechartProps() {
      return {
        title: this.rolChartpImage,
        resetValidation: this.resetValidationStatus,
        propertyInfo: this.addPropertyInfo,
        propertyInfoValid: this.propertyInfoValid,
        addingBtnLoading: this.addingBtnLoading
      }
    },
    showInHectarProps() {
      return {
        propertyInfoValid: this.propertyInfoValid,
        resetValidation: this.resetValidationStatus,
        propertyInfo: this.addPropertyInfo,
        realestatePopulations: this.realestatePopulations,
        payTypeOptions: this.payTypeOptions,
        realEstateConfig: {
          isLand: this.isLand,
          isStorehouse: this.isStorehouse,
          isMultiBuilding: this.isMultiBuilding,
          isFlat: this.isFlat,
          isFloor: this.isFloor,
          isChalet: this.isChalet,
          isGuestHouse: this.isGuestHouse
        }
      }
    },
    // eslint-disable-next-line complexity
    propertyToSend() {
      if (this.currentEnv && this.currentEnv._id) {
        const dataToAdd = {
          type: this.addPropertyInfo.type?._id,
          status: this.addPropertyInfo.status?._id,
          price: this.addPropertyInfo.price,
          pricePerMeter: this.addPropertyInfo.pricePerMeter,
          space: this.addPropertyInfo.space,
          purpose: this.addPropertyInfo?.purpose?._id,
          propertyAddress: {
            address:
              this.addPropertyInfo.location?.address ||
              this.addPropertyInfo?.propertyAddress?.address,
            city:
              this.addPropertyInfo?.location?.city ||
              this.addPropertyInfo?.propertyAddress?.city,
            district:
              this.addPropertyInfo?.location?.district ||
              this.addPropertyInfo?.propertyAddress?.district,
            province:
              this.addPropertyInfo?.location?.province ||
              this.addPropertyInfo?.propertyAddress?.province
          },
          location: {
            type: this.addPropertyInfo?.location?.type,
            coordinates: {
              lat: this.addPropertyInfo?.location?.coordinates[1],
              lng: this.addPropertyInfo?.location?.coordinates[0]
            }
          },
          owner: {
            _id: this.getPropertyOwner?._id,
            phoneNumber: this.getPropertyOwner?.phoneNumber,
            name: this.getPropertyOwner?.name,
            taxNumber: this.getPropertyOwner?.taxNumber
              ? Number(this.getPropertyOwner?.taxNumber)
              : undefined,
            idNumber: this.getPropertyOwner?.idNumber,
            clientType: this.generateClientType(
              this.getPropertyOwner?.clientType || this.getPropertyOwner?.type
            )
          },
          finishing: this.addPropertyInfo?.finishing,
          furnishing: this.addPropertyInfo?.furnishing,
          conditioning: this.addPropertyInfo?.conditioning,
          // check if user is adding realestate to send number of units
          // as User cannot add units when updating the realestate
          ...(!this.propertyInfoValid && {
            ...this.unitsPayload,
            createdBy: this.userData
          }),
          showInHectar: this.addPropertyInfo.showInHectar || false,
          name: this.addPropertyInfo.propertyName,
          length: Number(this.addPropertyInfo.length),
          width: Number(this.addPropertyInfo.width),
          builtAt: this.addPropertyInfo.builtAt,
          streetWidth: Number(this.addPropertyInfo.streetWidth),
          selectedSides: this.addPropertyInfo.selectedSides,
          // eslint-disable-next-line no-implicit-coercion
          age: +this.addPropertyInfo.age,
          moderators: [...this.addPropertyInfo.moderators],
          account:
            this.addPropertyInfo.showHectarAccount || this.currentEnv.owner,
          tags: this.addPropertyInfo.tags,
          environment: this.currentEnv._id,
          images: this.addPropertyInfo.images,
          completePercentage:
            this.addPropertyInfo.images?.length > 0 ? 100 : 80,
          bluePrint: this.addPropertyInfo.bluePrint || {},
          fromOwner: this.addPropertyInfo.hasOwnProperty('fromOwner')
            ? this.addPropertyInfo.fromOwner
            : true,
          notes: this.addPropertyInfo.notes
        }
        if (this.accommodationType) {
          dataToAdd.populationType = this.addPropertyInfo.populationType?._id
        }
        if (this.isForRent) {
          dataToAdd.payType = this.addPropertyInfo.payType?._id
        }
        if (!this.addPropertyInfo.fromOwner) {
          dataToAdd.authorizationNumber =
            this.addPropertyInfo.authorizationNumber
        }
        if (this.isRented) {
          if (this.hasArenter) {
            dataToAdd.tenet = {
              _id: this.getPropertyRenter._id,
              phoneNumber: this.getPropertyRenter.phoneNumber,
              name: this.getPropertyRenter.name,
              taxNumber: this.getPropertyRenter.taxNumber
                ? Number(this.getPropertyRenter.taxNumber)
                : undefined,
              idNumber: this.getPropertyRenter.idNumber,
              clientType: this.generateClientType(
                this.getPropertyRenter?.clientType ||
                  this.getPropertyRenter?.type
              )
            }
          } else {
            dataToAdd.tenet = this.addPropertyInfo?.tenet
          }
        }
        return dataToAdd
      }
      return null
    },
    getPropertyOwner() {
      return this.addPropertyInfo.newUserChecked
        ? this.newOwner
        : this.addPropertyInfo?.owner
    },
    userData() {
      return {
        _id: this.user?._id,
        name: this.user?.name,
        phoneNumber: this.user?.phoneNumber
      }
    },
    getPropertyRenter() {
      return this.addPropertyInfo.newRenterChecked
        ? this.newRenter
        : this.addPropertyInfo.tenet
    },
    isRented() {
      return this.addPropertyInfo.status?.nameEn === 'rented'
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeModel() {
      this.tab = 0
      this.resetValidationStatus = !this.resetValidationStatus
      if (!this.propertyInfoValid) {
        this.addPropertyInfo = {}
      }
      this.$emit('closed-property-dialog', 'close')
    },
    setAddPropertyInfo(innerObj) {
      this.addPropertyInfo = {
        ...this.addPropertyInfo,
        ...innerObj
      }
    },
    async addRealEstate() {
      this.addingBtnLoading = true
      if (this.addPropertyInfo.newUserChecked) {
        await this.addNewClient('owner')
      }
      if (this.addPropertyInfo.newRenterChecked) {
        await this.addNewClient('renter')
      }
      try {
        await realEstateService.addRealEstate(this.propertyToSend)

        const nameEn = this.addPropertyInfo.type?.nameEn
        if (nameEn === 'building' || nameEn === 'compound') {
          bus.$emit('buildingOrCompoundAdded')
        }
        bus.$emit('newRealestateAdded')
        this.tab = 0
        this.$emit('closed-property-dialog', 'unit')
        this.addPropertyInfo = {}
        this.resetValidationStatus = !this.resetValidationStatus
      } catch (error) {
        const errMessage = error.response.data.message.includes('up to')
          ? 'لقد تخطيت الحد المسموح من عدد العقارات يجب ترقية الباقه'
          : 'حدث خطأ اثناء اضافه العقار'
        this.addNotification({ text: errMessage, color: 'error' })
        this.closeModel()
      } finally {
        this.addingBtnLoading = false
      }
    },
    async updateRealEstate() {
      this.addingBtnLoading = true
      if (this.addPropertyInfo.newUserChecked) {
        await this.addNewClient('owner')
      }
      if (this.addPropertyInfo.newRenterChecked) {
        await this.addNewClient('renter')
      }
      this.propertyToSend._id = this.propertyInfo._id
      realEstateService
        .updateRealEstate(this.propertyToSend)
        .then(
          () => {
            this.addNotification({
              text: `تم تحديث بيانات العقار بنجاح`,
              color: 'success'
            })
            this.tab = 0
            this.$emit('refreshPropertiesList')
            this.$emit('closed-property-dialog')
            this.resetValidationStatus = !this.resetValidationStatus
          },
          (error) => {
            this.addNotification({
              text:
                error.response?.data?.errors?.[0] ||
                error.message ||
                'something went wrong',
              color: 'error'
            })
            this.resetValidationStatus = !this.resetValidationStatus
          }
        )
        .catch((error) => {
          this.addNotification({
            text:
              error.response?.data?.errors?.[0] ||
              error.message ||
              'something went wrong',
            color: 'error'
          })
        })
        .finally(() => {
          this.addingBtnLoading = false
        })
    },
    async addNewClient(clientType) {
      const attName = clientType === 'owner' ? 'newOwner' : 'newRenter'
      const clientPayload = {
        name: this.addPropertyInfo[attName]?.name,
        idNumber: this.addPropertyInfo[attName]?.idNumber,
        phoneNumber: this.addPropertyInfo[attName]?.phoneNumber?.replace(
          '0',
          '966'
        ),
        clientType: this.addPropertyInfo[attName]?.clientType,
        taxNumber: this.addPropertyInfo[attName]?.taxNumber,
        environment: {
          _id: this.currentEnv._id,
          name: this.currentEnv.name,
          role: [clientType]
        },
        userAddress: this.addPropertyInfo[attName]?.userAddress
      }
      const res = await EnvService.addClient(clientPayload)
      this[attName] = res.data.user
    },
    nextTab() {
      if (
        (this.tab === 0 &&
          (!this.isBuilding ||
            (!this.isSingleBuilding &&
              Object.keys(this.propertyInfo).length))) ||
        (!this.isForRent && !this.isForSale && this.tab === 5) ||
        (!this.isSingleBuilding &&
          Object.keys(this.propertyInfo).length &&
          this.tab === 0)
      ) {
        this.tab += 2
      } else if (this.tab === 3 && this.isMultiBuilding) {
        this.tab = 6
      } else {
        this.tab++
      }
    },
    previousTab() {
      if (
        (this.tab === 2 &&
          (!this.isBuilding ||
            (!this.isSingleBuilding &&
              Object.keys(this.propertyInfo).length))) ||
        (!this.isForRent && !this.isForSale && this.tab === 7)
      ) {
        this.tab -= 2
      } else if (this.tab === 6 && this.isMultiBuilding) {
        this.tab = 3
      } else {
        this.tab--
      }
    },
    pushValidationNotification(msg = 'يجب ملئ جميع الحقول المطلوبه') {
      this.addNotification({
        text: msg,
        color: 'error'
      })
    },
    async loadEmployees() {
      try {
        const { data } = await EnvService.getAllEmployee(
          this.currentEnv._id,
          '&registered=1'
        )
        this.employees = data.users
      } catch {
        // this.addNotification({
        //   text: 'حدث خطا',
        //   color: 'error'
        // })
      }
    },
    setBuildingStatus() {
      // Initial value assuming that it's a single building relestate
      this.addPropertyInfo.isBuilding = false
      const multipleUnitsBuilding = [
        'villa',
        'building',
        'compound',
        'Tower',
        'Building'
      ]
      if (multipleUnitsBuilding.includes(this.addPropertyInfo.type?.nameEn)) {
        this.addPropertyInfo.isBuilding = true
        if (
          this.addPropertyInfo.unitsCount &&
          this.addPropertyInfo.unitsCount > 0
        ) {
          this.addPropertyInfo.isSingleOrMultiple = 'multipleUnits'
        } else {
          this.addPropertyInfo.isSingleOrMultiple = 'singleUnit'
        }
      }
    }
  },
  watch: {
    propertyInfo: {
      immediate: true,
      handler() {
        if (this.propertyInfoValid) {
          this.addPropertyInfo = this.propertyInfo
          this.setBuildingStatus()
        }
      }
    }
  },
  created() {
    this.loadEmployees()
  }
}
</script>
