<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab v-for="(item, i) in filterdFiles" :key="i">{{
        getNameAr(item.nameEn)
      }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, i) in filterdFiles" :key="i">
        <p
          class="font-16 text-primary font-weight-bold d-flex justify-center ma-0"
        >
          {{ `إجمالي ${getNameAr(item.nameEn)} : ` }}
          {{ item.total }}
        </p>
        <div class="h-148 overflow-y-auto">
          <div
            class="black--text d-flex"
            v-for="(data, i) in item.data"
            :key="i"
          >
            <img
              :width="icon.width"
              :height="icon.height"
              :src="icon.src"
              alt=""
              class="mt-2"
              loading="lazy"
            />
            <div class="mr-3">
              <h5 class="font-weight-medium ma-0">
                {{ data.propertyName }}
              </h5>
              <div>
                <h6 class="text-gray ma-0 font-12">
                  {{ formatDate(data.date) }}
                </h6>
              </div>
            </div>
          </div>
          <div v-intersect="endIntersect" class="white--text">_</div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'financialDetailsFileList',
  mixins: [dateFormat],
  props: {
    files: {
      default: [],
      required: true
    }
  },
  watch: {
    tab: {
      handler() {
        this.$emit('financialActiveTabChanged', this.tab)
      }
    }
  },
  data() {
    return {
      tab: 0,
      icon: {
        src: require('@/assets/img/svgs/contracts.svg'),
        width: 25,
        height: 25
      }
    }
  },
  methods: {
    getNameAr(nameEn) {
      switch (nameEn) {
        case 'outcomes':
          return 'المطالبات'
        case 'claims':
          return 'المحصلات'
        case 'dues':
          return 'المستحقات'
      }
    },
    endIntersect(isIntersecting) {
      if (isIntersecting[0].isIntersecting) {
        this.$emit('nextPageTrigger')
      }
    }
  },
  computed: {
    filterdFiles() {
      return this.files.filter((file) => {
        return file.data.length
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/material-dashboard/_variables.scss';
.justify-between {
  justify-content: space-between;
}
.primaryColor {
  color: $primary-color;
}
.h-148 {
  height: 148px;
}
</style>
