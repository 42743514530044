<template>
  <div>
    <v-layout class="mt-5 d-flex flex-column">
      <!-- Row 1 -->
      <v-row no-gutters class="mb-5">
        <v-col cols="6" lg="2" class="my-1">
          <div
            v-if="
              contract?.realEstate?.propertyName || contract?.realEstate?.name
            "
          >
            <h6 class="text-gray ma-0 mb-2 font-15">اسم العقار</h6>
            <h5
              class="ma-0 font-weight-medium line__height__fixes text-truncate w-200"
            >
              {{
                contract.realEstate.name ?? contract?.realEstate?.propertyName
              }}
            </h5>
          </div>
        </v-col>
        <v-col cols="6" lg="2" class="my-1" v-if="contratDuration(contract)">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">مده العقد</h6>
            <div class="d-flex">
              <h5 class="ma-0 font-weight-medium line__height__fixes">
                {{ contratDuration(contract) }}
              </h5>
              <h5
                class="ma-0 pa-0 font-weight-bold line__height__fixes mr-2 text-danger"
                :class="{ 'text-success': isRenewableContract }"
              >
                {{ renewableContractText }}
              </h5>
            </div>
          </div>
        </v-col>
        <v-col
          cols="6"
          lg="3"
          class="my-1"
          v-if="haveSupType && isMaintenanceContract"
        >
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">عنوان العقد الفرعي</h6>
            <h5
              v-for="subType in contract.subTypes"
              :key="subType._id"
              class="ma-0 font-weight-medium line__height__fixes"
            >
              {{ subType.nameAr }}
            </h5>
          </div>
        </v-col>
        <v-col cols="6" lg="2" class="my-1" v-if="contratDuration(contract)">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">رقم العقد</h6>
            <div class="d-flex">
              <h5 class="ma-0 mt-1 font-weight-bold line__height__fixes">
                {{ contract?.number }}
              </h5>
            </div>
          </div>
        </v-col>
        <v-col cols="6" lg="2" class="my-1" v-if="contratDuration(contract)">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">عقد بواسطة</h6>
            <div class="d-flex">
              <h6
                class="red--tag bill-md text-center font-12 ma-0 ml-2 text-truncate"
              >
                {{ moderatorNameSplit(contract) }}
              </h6>
              <h5 class="ma-0 font-weight-medium text-truncate">
                {{ moderatorName(contract) }}
              </h5>
            </div>
          </div>
        </v-col>
        <v-col cols="6" lg="2" class="my-1" v-if="contract.start">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">تاريخ البداية</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ formatDate(contract.start) }}
            </h5>
          </div>
        </v-col>
      </v-row>

      <!-- Row 2 -->
      <v-row no-gutters>
        <v-col cols="6" lg="2" class="my-1" v-if="isRentContract">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">قيمه الإيجار الكليه</h6>
            <h5 class="ma-0 font-weight-bold line__height__fixes">
              {{ formattedRentValue }}
            </h5>
          </div>
        </v-col>
        <v-col cols="6" lg="2" class="my-1" v-if="contratPeriod">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">آلية التحصيل</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ contratPeriod }}
            </h5>
          </div>
        </v-col>
        <v-col cols="6" lg="2" class="my-1" v-if="contract.percentage">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">نسبه الشركه من المحصلات</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ contract.percentage }} %
            </h5>
          </div>
        </v-col>
        <v-col cols="6" lg="2" class="my-1" v-if="isMaintenanceContract">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">السعر المتفق عليه</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ contractAmount }} ريال
            </h5>
          </div>
        </v-col>
        <v-col cols="6" lg="2" class="my-1" v-if="isRentContract">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">قيمة الإيجار السنوية</h6>
            <h5 class="ma-0 font-weight-bold line__height__fixes">
              {{ formattedAnnualRate }}
            </h5>
          </div>
        </v-col>
        <v-col cols="6" lg="2" class="my-1" v-if="isRentContract">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">تاريخ الانشاء</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ formatDate(contract?.createdAt) }}
            </h5>
          </div>
        </v-col>
        <v-col cols="6" lg="2" class="my-1" v-if="contract.end">
          <div>
            <h6 class="text-gray ma-0 mb-2 font-15">تاريخ النهايه</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ formatDate(contract.end) }}
            </h5>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    <v-divider class="mt-3 ml-6"></v-divider>
    <!-- PropertyManagment Markting -->
    <v-row class="" v-if="isPropertyManagment && contract.feesAmount">
      <v-col cols="12" class="mt-5">
        <h4 class="font-16 font-weight-medium line__height__fixes">
          أتعاب التسويق والتأجير
        </h4>
      </v-col>
      <v-col cols="4" sm="4" class="mb-1">
        <h6 class="text-gray ma-0 mb-2 font-15">نوع الأتعاب</h6>
        <h5
          class="ma-0 font-weight-medium line__height__fixes text-truncate w-200"
        >
          {{ contract.feesType }}
        </h5>
      </v-col>
      <v-col cols="4" sm="4" class="mb-1">
        <h6 class="text-gray ma-0 mb-2 font-15">نسبه الشركة من المحصلات</h6>
        <div class="d-flex">
          <h5 class="ma-0 font-weight-medium line__height__fixes">
            {{ contract.feesAmount }} % (شاملة الضريبة)
          </h5>
        </div>
      </v-col>
      <v-col cols="4" sm="4" class="mb-1">
        <h6 class="text-gray ma-0 mb-2 font-15">طريقة تطبيق العمولة</h6>
        <div class="d-flex">
          <h5 class="ma-0 font-weight-medium line__height__fixes">
            {{ contract.feesFrequency ?? '' }}
          </h5>
        </div>
      </v-col>
      <v-divider class="mt-5 ml-6"></v-divider>
    </v-row>

    <!-- PropertyManagment Fee -->
    <v-row class="" v-if="isPropertyManagment && contract.percentage">
      <v-col cols="12" class="mt-5">
        <h4 class="font-16 ma-0 font-weight-medium line__height__fixes">
          أتعاب إدارة الأملاك
        </h4>
      </v-col>
      <v-col cols="4" sm="4" class="mb-1">
        <h6 class="text-gray ma-0 mb-2 font-15">نوع الأتعاب</h6>
        <h5
          class="ma-0 font-weight-medium line__height__fixes text-truncate w-200"
        >
          نسبة
        </h5>
      </v-col>
      <v-col cols="4" sm="4" class="mb-1">
        <h6 class="text-gray ma-0 mb-2 font-15">نسبه الشركة من المحصلات</h6>
        <div class="d-flex">
          <h5 class="ma-0 font-weight-medium line__height__fixes">
            {{ contract.percentage }} % (شاملة الضريبة)
          </h5>
        </div>
      </v-col>
      <v-col cols="4" class="mb-1">
        <h6 class="text-gray ma-0 mb-2 font-15">طريقة حساب الأتعاب</h6>
        <div class="d-flex">
          <h5 class="ma-0 font-weight-medium line__height__fixes">
            {{ contract.feesAndCommissionDate }}
          </h5>
        </div>
      </v-col>
      <v-divider class="mt-5 ml-6"></v-divider>
    </v-row>
    <!-- still no integrated -->
    <v-row class="" v-if="isPropertyManagment && contract.fixed">
      <v-col cols="12" class="mt-5">
        <h4 class="font-16 ma-0 font-weight-medium line__height__fixes">
          أتعاب التشغيل والخدمات
        </h4>
      </v-col>
      <v-col cols="4" sm="4" class="mb-1">
        <h6 class="text-gray ma-0 mb-2 font-15">نوع الأتعاب</h6>
        <h5
          class="ma-0 font-weight-medium line__height__fixes text-truncate w-200"
        >
          مبلغ العمولة الثابتة
        </h5>
      </v-col>
      <v-col cols="4" sm="4" class="mb-1">
        <h6 class="text-gray ma-0 mb-2 font-15">مبلغ العمولة الثابتة</h6>
        <div class="d-flex">
          <h5 class="ma-0 font-bold line__height__fixes">
            {{ formattedNumbers(contract.fixed) }} ريال
          </h5>
        </div>
      </v-col>
      <v-col cols="4" sm="4" class="mb-1">
        <h6 class="text-gray ma-0 mb-2 font-15">طريقة تحصيل الرسوم</h6>
        <div class="d-flex">
          <h5 class="ma-0 font-weight-medium line__height__fixes">
            {{ getPaymentNameAr(contract.commissionPeriod) }}
          </h5>
        </div>
      </v-col>
      <v-divider class="mt-5 ml-6"></v-divider>
    </v-row>
    <v-layout column>
      <h4 class="mt-5 mr-3 font-16 ma-0 font-weight-medium line__height__fixes">
        أطراف التعاقد
      </h4>
      <div class="mr-3">
        <v-layout class="mt-5" align-center>
          <v-icon color="primary" class="ml-1">mdi-account-outline</v-icon>
          <h4 class="font-weight-medium font-16 ma-0">طرف اول</h4>
        </v-layout>
        <v-layout v-if="isRentContract" class="mr-1">
          <div class="mt-3 col-md-4 pa-0">
            <h6 class="text-gray ma-0 mb-2 font-15">إسم العميل</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ firstPartyName(contract) }}
            </h5>
          </div>
          <div class="mt-3 col-md-4 pa-0">
            <h6 class="text-gray ma-0 mb-2 font-15">رقم الهويه</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ firstPartyIdNumber }}
            </h5>
          </div>
          <div class="mt-3 col-md-4 pa-0">
            <h6 class="text-gray ma-0 mb-2 font-15">رقم الجوال</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ firstPartyPhoneNumber }}<span class="text-gray"> +966 </span>
            </h5>
          </div>
        </v-layout>
        <v-layout v-else class="mr-1">
          <div class="mt-3 col-md-4 pa-0">
            <h6 class="text-gray ma-0 mb-2 font-15">اسم الشركه</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ companyName }}
            </h5>
          </div>
          <div v-if="commercialNumber" class="mt-3 col-md-4 pa-0">
            <h6 class="text-gray ma-0 mb-2 font-15">{{ idNumberLabel }}</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ commercialNumber }}
            </h5>
          </div>
        </v-layout>
      </div>
      <div
        class="mr-3"
        v-for="otherParty in contract.otherParties"
        :key="otherParty._id"
      >
        <v-layout class="mt-5" align-center>
          <v-img
            class="ml-1"
            :src="building"
            :max-width="16"
            :max-height="16"
          />
          <h4 class="font-weight-medium font-16 ma-0">طرف ثاني</h4>
        </v-layout>
        <v-layout class="mr-1">
          <div class="mt-3 col-md-4 pa-0">
            <h6 class="text-gray ma-0 mb-2 font-15">إسم العميل</h6>
            <h5
              class="ma-0 font-weight-medium line__height__fixes"
              v-if="otherParty.name"
            >
              {{ generteOtherPartyName(otherParty) }}
            </h5>
          </div>
          <div class="mt-3 col-md-4 pa-0">
            <h6 class="text-gray ma-0 mb-2 font-15">رقم الهويه</h6>
            <h5 class="ma-0 font-weight-medium line__height__fixes">
              {{ otherParty.idNumber || 'لا يوجد' }}
            </h5>
          </div>
          <div class="mt-3 col-md-4 pa-0">
            <h6 class="text-gray ma-0 mb-2 font-15">رقم الجوال</h6>
            <h5
              class="ma-0 font-weight-medium line__height__fixes"
              v-if="otherParty.phoneNumber"
            >
              {{ formatOtherPartyPhone(otherParty)
              }}<span class="text-gray"> +966 </span>
            </h5>
          </div>
        </v-layout>
      </div>
      <v-divider class="mt-5 mb-1 ml-6"></v-divider>
    </v-layout>
    <v-layout column v-if="isPropertyManagment && haveSupType">
      <div>
        <h4 class="mr-3 font-16 ma-0 font-weight-medium line__height__fixes">
          الصيانة
        </h4>
        <v-layout align-center :class="managmentSubTypeStyle">
          <div
            class="d-flex mt-5"
            v-for="subType in contract.subTypes"
            :key="subType._id"
          >
            <v-icon small color="#3dba7e" class="check-subtype-icon"
              >mdi-checkbox-marked-circle-outline</v-icon
            >
            <span class="mr-1"> {{ subType.nameAr }} </span>
          </div>
        </v-layout>
      </div>
      <v-divider class="mt-5 ml-6"></v-divider>
    </v-layout>

    <v-layout column v-if="isEjarContract">
      <h4 class="mt-5 mr-3 font-16 ma-0 font-weight-medium line__height__fixes">
        تفاصيل العقد على منصة إيجار
      </h4>
      <v-layout class="mr-3">
        <div class="mt-3 col-md-4 pa-0">
          <h6 class="text-gray ma-0 mb-2 font-15">رقم العقد</h6>
          <h5 class="ma-0 font-weight-medium line__height__fixes">
            {{ contract.electronicRent.electronicNumber || 'لا يوجد' }}
          </h5>
        </div>
        <div class="mt-3 col-md-4 pa-0">
          <h6 class="text-gray ma-0 mb-2 font-15">رسوم العقد</h6>
          <h5 class="ma-0 font-weight-medium line__height__fixes">
            {{ ejarContractRentValue }}
          </h5>
        </div>
        <div
          v-if="contract.electronicRent.electronicImage"
          class="mt-3 col-md-4 pa-0"
        >
          <h6 class="text-gray ma-0 mb-2 font-15">صورة العقد</h6>
          <div class="d-flex align-center">
            <v-img
              :max-width="14"
              :max-height="14"
              :src="ejarImageIcon"
            ></v-img>
            <span
              class="font-14 cursor ejar-image-text mr-1"
              @click="openEjarImage"
            >
              وثيقة تسجيل العقار
            </span>
          </div>
        </div>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import contractsSharedData from '@/mixins/contractsSharedData'
import dateFormat from '@/mixins/dateFormat.js'
import numberFormatter from '@/mixins/numberFormatter.js'
import { mapState } from 'vuex'
export default {
  name: 'ContractDetails',
  mixins: [dateFormat, numberFormatter, contractsSharedData],
  props: {
    contract: {
      type: Object,
      required: true
    },
    contractIntervals: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      building: require('@/assets/img/svgs/build.svg'),
      ejarImageIcon: require('@/assets/img/svgs/bonds.svg')
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    contractAmount() {
      return Number(this.contract.amount).toLocaleString('en-US')
    },
    formattedNumbers() {
      return (str) => str?.toLocaleString('en-US', { maximumFractionDigits: 2 })
    },
    contratPeriod() {
      const selected = this.getDurationNameAr(this.contract.collectionInterval)
      return selected?.nameAr ?? ''
    },
    firstPartyIdNumber() {
      return this.contract?.firstParty?.idNumber || 'لا يوجد'
    },
    firstPartyPhoneNumber() {
      if (this.contract?.firstParty?.phoneNumber) {
        return this.contract.firstParty.phoneNumber.replace('966', '0')
      }
      return null
    },
    haveSupType() {
      return !!this.contract?.subTypes?.length
    },
    commissionPeriod() {
      const selected = this.getDurationNameAr(this.contract.commissionPeriod)
      return selected?.nameAr ?? ''
    },
    isPropertyManagment() {
      return this.contract?.typeName === 'propertyManagement'
    },
    isMaintenanceContract() {
      return this.contract?.typeName === 'maintenance'
    },
    managmentSubTypeStyle() {
      return this.getBreakPoint.sm
        ? 'managment-subtypes'
        : 'managment-subtypes-mobile'
    },
    isFirstPartyEnvOwner() {
      return this.contract?.firstParty?._id === this.currentEnv.owner?.id
    },
    getFirstPartyName() {
      if (this.isFirstPartyEnvOwner && this.firstPartyName(this.contract)) {
        return `${this.firstPartyName(this.contract)} (مالك الحساب)`
      }
      return this.firstPartyName(this.contract)
    },
    isRentContract() {
      return this.contract.typeName === 'rent'
    },
    annualRate() {
      if (this.contract.duration === 0.0333) {
        return this.formatNumbers(365 * this.contract.rentValue)
      } else if (this.contract.duration <= 12) {
        return this.formatNumbers(
          (12 / this.contract.duration) * this.contract.rentValue
        )
      }
      return this.formatNumbers(
        this.contract.rentValue / (this.contract.duration / 12)
      )
    },
    formattedAnnualRate() {
      return this.annualRate.includes('ريال')
        ? this.annualRate
        : `${this.annualRate} ريال`
    },
    formattedRentValue() {
      return `${this.formatNumbers(this.contract.rentValue)} ريال`
    },
    isEjarContract() {
      return (
        this.isRentContract && this.contract.electronicRent?.hasElectronicRent
      )
    },
    ejarContractRentValue() {
      if (!this.contract.electronicRent?.rentValue) {
        return 'لا يوجد'
      }
      const rentValue = this.numberFormatter(
        this.contract.electronicRent?.rentValue
      )
      return rentValue.includes('ريال') ? rentValue : `${rentValue} ريال`
    },
    commercialNumber() {
      return this.contract.firstParty?.idNumber ?? ''
    },
    idNumberLabel() {
      return ['company', 'office'].includes(this.contract.firstParty?.type)
        ? 'رقم المنشأة / السجل التجاري'
        : 'رقم الهوية'
    },
    isRenewableContract() {
      return this.contract?.isRenewable
    },
    renewableContractText() {
      return this.isRenewableContract ? '(يتجدد تلقائيا)' : ''
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  methods: {
    getPaymentNameAr(paymentCode) {
      switch (paymentCode) {
        case 0.0333:
          return ' - يومي'
        case 1:
          return ' - شهري'
        case 2:
          return ' - شهرين'
        case 3:
          return ' - ربع سنوي'
        case 6:
          return ' - نصف سنوي'
        case 12:
          return ' - سنوي'
        case 24:
          return ' - سنتان'
        default:
          return ''
      }
    },
    formatNumbers(number) {
      return number.toLocaleString('en-US', { maximumFractionDigits: 2 })
    },
    formatOtherPartyPhone(otherParty) {
      return otherParty.phoneNumber.replace('966', '0')
    },
    generteOtherPartyName(otherParty) {
      if (otherParty._id === this.currentEnv.owner?.id) {
        return `${otherParty.name} (مالك الحساب)`
      }
      return otherParty.name
    },
    openEjarImage() {
      window.open(this.contract.electronicRent.electronicImage, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_sideDetails.scss';
.managment-subtypes {
  div:not(:first-child) {
    margin-right: 32px;
  }
  div:first-child {
    margin-right: 12px;
  }
}
.managment-subtypes-mobile {
  div:not(:first-child) {
    margin-right: 10px;
  }
}
.check-subtype-icon {
  margin-top: 6px;
}
.ejar-image-text {
  color: $primary-color !important;
}
</style>
