<template>
  <v-dialog
    max-width="680px"
    class="associations__modal"
    persistent
    :value="dialogState"
  >
    <!-------------------------------------->
    <!-------------Modal-------------------->
    <!-------------------------------------->

    <v-card class="add-new-property pa-0 add__customer__popup">
      <!-------------------------------------->
      <!-------------Head modal--------------->
      <!-------------------------------------->

      <v-card-title
        class="py-0 pr-4 pl-0 mb-4 d-flex justify-space-between pb-1 mobile__py__6px mobile__px__15px"
      >
        <!------------------Title-------------------->
        <h4 class="ma-0" v-text="title" />
        <!-------------Close modal-------------------->
        <v-btn
          @click.prevent="closeModal"
          data-test="closeClientModal"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" @submit.prevent="submit" v-model="isValid">
        <!-------------------------------------->
        <!-------------Details modal------------>
        <!-------------------------------------->
        <v-card-text class="px-5 pb-0">
          <v-row class="mb-5">
            <!-- Realestats -->
            <v-col cols="12" sm="6" class="pb-0">
              <h6 class="mb-2 font-12" style="color: black">اسم العقار *</h6>
              <v-select
                v-model="realEstatsSelected"
                :items="realEstats"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => (v && !!v.length) || 'من فضلك اختر العقار']"
                append-icon="mdi-chevron-down"
                color="black"
                dense
                filled
                item-text="nameAr"
                item-value="code"
                placeholder="اختر العقار"
                required
                class="font-14"
              ></v-select>
            </v-col>

            <!-- Manager -->
            <v-col cols="12" sm="6">
              <h6 class="mb-2 font-12" style="color: black">رئيس الجمعية *</h6>
              <v-select
                v-model="managerSelected"
                :items="managers"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="rules.manager"
                append-icon="mdi-chevron-down"
                color="black"
                dense
                filled
                item-text="nameAr"
                item-value="code"
                placeholder="اختر رئيس الجمعية"
                required
                class="font-14"
              ></v-select>
            </v-col>

            <!-- Members -->
            <v-col cols="12" class="py-0">
              <h6 class="mb-2 font-12" style="color: black">أعضاء الجمعية</h6>
              <v-autocomplete
                ref="members"
                :items="members"
                placeholder="ابحث باسم العضو"
                item-text="code"
                return-code
                :clearable="true"
                :hide-details="true"
                @change="membersChangeHandler"
                class="pa-0"
              />
            </v-col>

            <!-- View all members -->
            <v-col cols="12" sm="12">
              <v-row no-gutters class="flex-wrap associations__modal__badge">
                <v-col
                  class="d-flex align-center px-3 py-2"
                  v-for="(member, i) in allMembersSelected"
                  :key="member"
                >
                  <span v-text="member" />
                  <button
                    @click="removeMemberHandler(i)"
                    class="d-flex align-center justify-center"
                  >
                    &times;
                  </button>
                </v-col>
              </v-row>
            </v-col>

            <!-- Admin -->
            <v-col cols="12" sm="6">
              <h6 class="mb-2 font-12" style="color: black">المسؤول *</h6>
              <v-select
                v-model="adminSelected"
                :items="admins"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="rules.admin"
                append-icon="mdi-chevron-down"
                color="black"
                dense
                filled
                item-text="name"
                item-value="code"
                placeholder="اختر المسؤول عن الجمعية"
                required
                class="font-14"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <!------------------------------>
        <!-------------Submit----------->
        <!------------------------------>
        <v-card-actions
          class="pa-1 mt-4 add__customer__action border-0"
          style="border: none"
        >
          <v-spacer></v-spacer>
          <v-btn
            :loading="isLoading"
            type="submit"
            class="ma-0 rounded-7 my-3 ml-3 px-5"
            color="primary"
          >
            <span
              class="mb-1 mobile__mb__0 ml-1 font-weight-bold font-12"
              v-text="titleSubmitBtn"
            />
            <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-select.v-autocomplete >>> .v-select__slot {
  padding: 0.3125rem 0.625rem;
}
</style>
<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'AddAssoicationModal',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    dialogState: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: 'إضافة جمعية',
      rules: {
        manager: [(v) => (v && !!v.length) || 'من فضلك اختر رئيس الجمعية'],
        admin: [(v) => (v && !!v.length) || 'من فضلك اختر المسؤول']
      },
      realEstats: [
        {
          nameAr: 'test1',
          code: 'test'
        },
        {
          nameAr: 'test2',
          code: 'test'
        }
      ],
      managers: [
        {
          nameAr: 'test1',
          code: 'test'
        },
        {
          nameAr: 'test2',
          code: 'test'
        }
      ],
      members: [
        {
          name: 'test1',
          code: 'test1'
        },
        {
          name: 'test2',
          code: 'test2'
        },
        {
          name: 'test3',
          code: 'test3'
        }
      ],
      admins: [
        {
          name: 'test1',
          code: 'test1'
        },
        {
          name: 'test2',
          code: 'test2'
        },
        {
          name: 'test3',
          code: 'test3'
        }
      ],
      realEstatsSelected: '',
      managerSelected: '',
      allMembersSelected: [],
      adminSelected: '',
      isLoading: false,
      isValid: false
    }
  },
  created() {},
  computed: {
    ...mapState('accountState', ['currentEnv']),
    titleSubmitBtn() {
      return 'حفظ'
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    submit() {
      this.$refs.form.validate()
      if (this.isValid) {
        console.log('Done')
      }
    },
    membersChangeHandler(value) {
      const members = this.allMembersSelected
      if (value && !members.includes(value)) {
        members.push(value)
      }
      this.$refs.members.reset()
    },
    removeMemberHandler(index) {
      this.allMembersSelected.splice(index, 1)
    },
    closeModal() {
      this.$emit('close-client-dialog', 'close')
      this.allMembersSelected = []
      this.realEstatsSelected = ''
      this.managerSelected = ''
      this.adminSelected = ''
      this.$refs.form.reset()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/associations/associations.scss';
</style>
