export default {
  icons: {
    defaultNotificationIcon: require('@/assets/img/svgs/lightBell.svg'),
    reports: require('@/assets/img/svgs/reports.svg'),
    propertyManagement: require('@/assets/img/svgs/notifications/propertyManagement.svg'),
    realEstates: require('@/assets/img/svgs/notifications/realEstates.svg'),
    users: require('@/assets/img/svgs/notifications/users.svg'),
    accounts: require('@/assets/img/svgs/notifications/accounts.svg'),
    messages: require('@/assets/img/svgs/notifications/messages.svg')
  }
}
