<template>
  <v-container class="mt-4 pt-0" :class="{ userView: isCustomer }">
    <tabTableDetails
      v-bind="{ ...notesProp }"
      @addAction="openAddNoteModal"
      @delete="$emit('deleteNote', $event)"
      @preview="$emit('previewNote', $event)"
    />
  </v-container>
</template>

<script>
import tabTableDetails from './tabTableDetails.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ContractNotes',
  components: {
    tabTableDetails
  },
  props: {
    contract: {
      type: Object,
      required: true
    },
    noDate: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    notesProp() {
      return {
        items: this.contract.notes,
        title: 'الملاحظات',
        headers: [
          {
            text: 'بواسطة',
            value: 'createdBy',
            align: 'center',
            sortable: false
          },
          {
            text: 'التاريخ',
            value: 'createdAt',
            align: 'center',
            sortable: false
          },
          {
            text: 'الملاحظات',
            value: 'body',
            align: 'start',
            sortable: false
          },
          {
            text: 'عرض',
            value: 'preview',
            sortable: false,
            align: 'left',
            width: 20
          },
          {
            text: 'حذف',
            value: 'delete',
            sortable: false,
            align: 'left',
            width: 20
          }
        ],
        noDate: this.noDate
      }
    }
  },

  methods: {
    openAddNoteModal() {
      this.$emit('openNoteModal')
    }
  }
}
</script>
