<template>
  <div class="quotation--paper2 quotation--paper9">
    <h1>معلومات التواصل</h1>
    <h2 class="ma-0">أرقام التواصل وموقع الشركة</h2>

    <div class="quotation--paper9__card py-2 d-flex">
      <div>
        <h3 class="ma-0 mb-2 font-20">{{ user?.company }}</h3>
        <p class="ma-0 font-16">{{ user?.address }}</p>
        <p class="ma-0 my-1 font-16">{{ user?.email }}</p>
        <p class="ma-0 font-16">{{ user?.phoneNumber?.replace('966', '0') }}</p>
      </div>
      <!-- <div class="quotation--paper9__card__img">
        <img src="https://placehold.co" alt="" />
      </div> -->
    </div>

    <!-- Map -->
    <gmap-map
      :center="center"
      :options="options"
      :zoom="14"
      ref="mapRef"
      style="width: 100%; height: 400px"
      class="mt-5"
    >
      <gmap-marker
        data-test="marker"
        :clickable="true"
        :icon="{ url: require('@/assets/img/pin.svg') }"
        :position="currentPlace"
        @click="center = m.position"
      />
    </gmap-map>
  </div>
</template>

<script>
import config from '@/config'
import { mapState } from 'vuex'

export default {
  name: 'Paper9',
  data() {
    return {
      center: { lat: 24.448378, lng: 39.586848 },
      currentPlace: { lat: 24.448378, lng: 39.586848 },
      options: {
        scrollwheel: false,
        fullscreenControl: false,
        streetViewControl: false,
        minZoom: 9,
        mapTypeControlOptions: {
          style: 1,
          position: 12
        },
        styles: config.mapStyles
      }
    }
  },
  computed: {
    ...mapState('accountState', ['user'])
  },
  created() {
    this.center = {
      lat: this.user.location.coordinates[1],
      lng: this.user.location.coordinates[0]
    }
    this.currentPlace = this.center
  }
}
</script>

<style lang="scss" scoped></style>
