var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{attrs:{"refs":"form"},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',{staticClass:"mt-5",attrs:{"align-content-sm":"","no-gutters":""}},[_c('v-col',{staticClass:"pl-1",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-2",class:{
            'red--flag': _vm.validationStatues(_vm.info.propertyName, _vm.nextabClicked)
          }},[_vm._v("\n          اسم الوحده*\n        ")]),_c('v-text-field',{attrs:{"dense":"","filled":"","hide-details":"","placeholder":"اسم الوحده","require":"","rules":_vm.selectRules},model:{value:(_vm.info.propertyName),callback:function ($$v) {_vm.$set(_vm.info, "propertyName", $$v)},expression:"info.propertyName"}})],1)],1),_c('v-row',{staticClass:"my-4",attrs:{"align-content-sm":"","no-gutters":""}},[_c('v-col',{staticClass:"pl-1",attrs:{"cols":"12"}},[_c('h5',{class:{
            'red--flag': _vm.validationStatues(_vm.info.moderators, _vm.nextabClicked)
          }},[_vm._v("\n          اسم المسئول*\n        ")]),_c('v-select',{attrs:{"items":_vm.employees,"menu-props":{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          },"placeholder":`المسؤول عن العقار`,"append-icon":"mdi-chevron-down","dense":"","rules":_vm.selectRules,"filled":"","hide-details":"","item-text":"name","item-value":"_id","return-object":"","clearable":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index < 2)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"text-grey text-caption align-self-center"},[_vm._v("\n              (+"+_vm._s(_vm.info.moderators.length - 2)+" مسؤولين)\n            ")]):_vm._e()]}}]),model:{value:(_vm.info.moderators),callback:function ($$v) {_vm.$set(_vm.info, "moderators", $$v)},expression:"info.moderators"}})],1)],1)],1),_c('v-card-actions',{staticClass:"pt-4 d-flex align-center"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold",attrs:{"text":""},on:{"click":_vm.nextTab}},[_c('span',{staticClass:"mb-1 ml-1"},[_vm._v("التالي")]),_c('v-icon',{staticClass:"font-16"},[_vm._v("mdi-arrow-left")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }