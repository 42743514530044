<template>
  <div>
    <h4 class="mt-6 font-12">
      {{ title }}
      <span v-if="isOptional" class="grey--text">(إختياري)</span>
    </h4>
    <v-row v-if="actionsStatus" align-content-sm no-gutters class="mb-6">
      <v-col
        class="mx-1"
        sm="3"
        v-for="(image, i) in oldImages"
        :key="image.smallImage.path"
      >
        <div class="position-relative">
          <img :src="image.smallImage.path" class="add-pic" loading="lazy" />
          <v-icon
            @click="deleteImage(image, i, true)"
            class="pic-icon position-absolute slide-y-transition"
            color="white"
          >
            mdi-close
          </v-icon>
        </div>
      </v-col>

      <v-col class="mx-1" sm="3" v-for="(image, i) in images" :key="image._id">
        <div class="position-relative">
          <img :ref="`image`" class="add-pic" />
          <div v-if="!image.file.smallImage" class="lds-ring-container">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>

          <v-icon
            data-test="deleteImage"
            @click="deleteImage(image.file, i, false)"
            class="pic-icon position-absolute slide-y-transition"
            color="white"
          >
            mdi-close
          </v-icon>
        </div>
      </v-col>

      <v-col
        class="d-flex justify-center align-center pic-wrapper"
        sm="3"
        v-if="images.length || oldImages.length"
      >
        <v-btn
          @click="$refs.inputUpload2.click()"
          class="brub--border rounded-7"
          icon
        >
          <v-icon color="general"> mdi-plus </v-icon>
        </v-btn>
        <input
          @change="addPropertyImages($event)"
          accept="image/*"
          ref="inputUpload2"
          type="file"
          v-show="false"
          multiple
        />
      </v-col>
      <div
        @drop.prevent="addPropertyImages($event)"
        @dragleave.prevent="dragLeave('pic')"
        @dragover.prevent="dragOver('pic')"
        @dragenter.prevent
        ref="pic"
        class="pic-wrapper d-flex align-center w-100 pa-6 mt-3"
        v-else
      >
        <div>
          <v-btn
            @click="$refs.inputUpload.click()"
            data-test="uploadImage"
            class="py-2 px-3 rounded-7 font-weight-bold"
            color="general"
            outlined
            :disabled="!$hasPermission('realestates', 'realestates', 'add')"
          >
            <v-icon size="20" color="general"> mdi-plus </v-icon>
            <span class="font-12"> إضافة الصور </span>
          </v-btn>
          <input
            @change="addPropertyImages($event)"
            accept="image/*"
            multiple
            ref="inputUpload"
            type="file"
            v-show="false"
          />
        </div>
        <div class="mr-3">
          <h4 class="font-12">
            اسحب الصور هنا لرفعها أو اضغط على زر إضافة الصور
          </h4>
          <h5 class="text-gray font-12">يمكنك الإضافة حتى ١٠ صور لعقارك</h5>
        </div>
      </div>
    </v-row>

    <div v-else>
      <div v-if="blueprintImage.image" class="position-relative add-pic">
        <img :src="blueprintImage.image" class="add-pic" loading="lazy" />
        <div v-if="!blueprintImage.key" class="lds-ring-container">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <v-icon
          data-test="deleteBlueprint"
          @click="deleteBlueprint"
          class="pic-icon position-absolute slide-y-transition"
          color="white"
        >
          mdi-close
        </v-icon>
      </div>

      <div
        @drop.prevent="addPropertyBlueprint($event)"
        @dragleave.prevent="dragLeave('pic')"
        @dragover.prevent="dragOver('pic')"
        @dragenter.prevent
        ref="pic"
        class="pic-wrapper d-flex align-center w-100 pa-6 mt-3"
        v-else
      >
        <div>
          <v-btn
            @click="$refs.inputUpload.click()"
            class="py-2 px-3 rounded-7 font-weight-bold"
            color="general"
            outlined
          >
            <v-icon size="20" color="general"> mdi-plus </v-icon>
            <span class="font-12">
              {{ btnTitle }}
            </span>
          </v-btn>
          <input
            @change="addPropertyBlueprint($event)"
            accept="image/*"
            ref="inputUpload"
            type="file"
            v-show="false"
          />
        </div>
        <div class="mr-3">
          <h4 class="font-12">
            {{ uploadingText }}
          </h4>
          <h5 class="text-gray font-12">
            {{ upladingDescription }}
          </h5>
        </div>
      </div>
    </div>

    <v-card-actions
      v-if="actionsStatus"
      class="pt-4 d-flex align-center justify-space-between"
    >
      <v-btn
        @click="previousTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <v-icon class="font-16">mdi-arrow-right</v-icon>
        <span class="mb-1 ml-1">السابق</span>
      </v-btn>
      <v-btn
        @click="nextTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <span class="mb-1 ml-1">التالي</span>
        <v-icon class="font-16">mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { realEstateService } from '@/services'
import { mapMutations } from 'vuex'
import drag from '@/mixins/drag.js'

export default {
  mixins: [drag],
  props: {
    btnTitle: {
      text: 'إضافة المخطط'
    },
    uploadingText: {
      default: 'اسحب الصور هنا لرفعها أو اضغط على زر إضافة الصور'
    },
    upladingDescription: {
      default: 'يمكنك إضافه مخطط واحد فقط لعقارك'
    },
    isOptional: {
      default: true
    },
    actionsStatus: {
      default: true
    },
    title: {
      default: 'صور'
    },
    resetValidation: {
      default: false
    },
    propertyInfo: {
      default() {
        return {}
      }
    },
    propertyInfoValid: {},
    rolechart: {
      required: false,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      test: '',
      images: [],
      oldImages: [],
      addPropertyInfo: {},
      slectedFile: null,
      blueprintImage: {
        image: '',
        key: ''
      },
      nextTabClicked: false
    }
  },
  watch: {
    propertyInfo: {
      immediate: true,
      handler() {
        this.addPropertyInfo = { ...this.propertyInfo }
        if (this.propertyInfoValid || this.nextTabClicked) {
          this.oldImages = this.addPropertyInfo.images || []
          this.images = []
        }
      }
    },
    rolechart: {
      immediate: true,
      handler(newVal) {
        if (newVal.image) {
          this.$set(this.blueprintImage, 'image', this.rolechart.image)
          this.$set(this.blueprintImage, 'key', this.rolechart.key)
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    addPropertyImages(e) {
      const files = e.dataTransfer ? e.dataTransfer.files : e.target.files
      const totalAddedImagesExceeded =
        this.oldImages.length + this.images.length + files.length > 10
      if (files.length > 10 || totalAddedImagesExceeded) {
        this.addNotification({
          text: 'لا يمكن اضافه اكثر من 10 صور',
          color: 'error'
        })
      } else {
        const selectedFiles = Object.values(files)
        selectedFiles.forEach((file) => {
          this.images.push({ file, _id: String(Date.now()) + Math.random() })
          const imagesLength = this.images.length - 1
          const reader = new FileReader()
          reader.onload = () => {
            this.$refs.image[imagesLength].src = reader.result
            this.onUpload(file, imagesLength)
          }
          reader.readAsDataURL(file)
        })
      }
    },
    async onUpload(image, imageIndex) {
      try {
        const fd = new FormData()
        fd.append('image', image)

        const { data } = await realEstateService.uploadImages(fd)

        const imageObject = {
          normalImage: data.success.normalImage,
          smallImage: data.success.smallImage
        }
        this.$set(this.images[imageIndex], 'file', imageObject)
        this.addNotification({
          text: 'تم إضافه الصورة بنجاح',
          color: 'success'
        })
      } catch {
        this.images.splice(imageIndex, 1)
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    async deleteImage(image, imageIndex, isOldImage) {
      try {
        const imageKey = {
          imageKey: image.normalImage.key,
          smallImageKey: image.smallImage.key
        }
        if (this.addPropertyInfo._id) {
          imageKey.id = this.addPropertyInfo._id
        }

        await realEstateService.deleteImage(imageKey)

        if (isOldImage) {
          this.oldImages.splice(imageIndex, 1)
        } else {
          this.images.splice(imageIndex, 1)
        }
        this.addNotification({
          text: 'تم حذف الصورة بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    addPropertyBlueprint(e) {
      const files = e.dataTransfer ? e.dataTransfer.files : e.target.files
      if (files.length > 1) {
        this.addNotification({
          text: 'لا يمكن اضافه اكثر من مخطط واحد',
          color: 'error'
        })
      } else {
        const file = files[0]
        const reader = new FileReader()
        reader.onload = () => {
          this.blueprintImage.image = reader.result
          this.uploadBlueprint(file)
        }
        reader.readAsDataURL(file)
      }
    },
    async uploadBlueprint(file) {
      const fd = new FormData()
      fd.append('blueprint', file)

      try {
        const { data } = await realEstateService.addBlueprint(fd)

        this.$set(this.blueprintImage, 'image', data.success.Location)
        this.$set(this.blueprintImage, 'key', data.success.key)
        this.$emit('set-blueprint-image', this.blueprintImage)
        this.addNotification({
          text: 'تم إضافه المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.$set(this.blueprintImage, 'image', '')
        this.$set(this.blueprintImage, 'key', '')
        this.addNotification({
          text: 'حدث خطأ ما برجاء المحاوله لاحقا',
          color: 'success'
        })
      }
    },
    async deleteBlueprint() {
      const blueprintKey = {
        key: this.blueprintImage.key
      }
      try {
        await realEstateService.deleteBlueprint(blueprintKey)

        this.$set(this.blueprintImage, 'image', '')
        this.$set(this.blueprintImage, 'key', '')
        this.$emit('set-blueprint-image', this.blueprintImage)
        this.addNotification({
          text: 'تم حذف المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    nextTab() {
      this.nextTabClicked = true
      let imagesStillUploading = false
      this.images.forEach((image) => {
        if (!image.file.normalImage) {
          imagesStillUploading = true
        }
      })
      if (imagesStillUploading) {
        this.addNotification({
          text: 'من فضلك انتظر صور العقار تتم معالجتها',
          color: 'error'
        })
      } else {
        this.images = this.images.map((image) => {
          return {
            smallImage: image.file.smallImage,
            normalImage: image.file.normalImage
          }
        })
        this.addPropertyInfo.images = [...this.oldImages, ...this.images]
        this.$emit('setAddPropertyInfo', this.addPropertyInfo)
        this.$emit('nextTab')
      }
    },
    previousTab() {
      this.$emit('previousTab')
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/material-dashboard/lds-ring.scss';

.pic-wrapper {
  border: 2px dashed #c4c4c4;
  border-radius: 7px;

  &:hover {
    border: 2px dashed $primary-color;
  }
}

.add-pic {
  width: 160px;
  height: 120px;
  border-radius: 10px;
}

.pic-icon {
  top: 8px;
  left: 8px;
  background: rgba(16, 16, 16, 0.5);
  padding: 3px 5px 2px;
  border-radius: 15px !important;
  font-size: 12px !important;
}
</style>
