<template>
  <v-col cols="12" class="pa-2 report__card__col col-sm-4 col-md-3">
    <v-card
      :class="{ 'report__cards cursor': perReports.actions }"
      @click="handlePreReportClick"
      class="v-card--material-stats tile-card pa-6 text-center"
    >
      <img :src="getImgUrl(perReports.icon)" alt="perReports" loading="lazy" />
      <div class="mt-2">
        <h4 class="font-16 text-truncate font-weight-medium">
          {{ perReports.report.title }}
        </h4>
        <h6
          class="font-12 text-gray font-weight-medium ma-0"
          v-if="perReports.description"
        >
          {{ perReports.description }}
        </h6>
      </div>
    </v-card>
  </v-col>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  name: 'perRportsCard',
  props: {
    perReports: {}
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getImgUrl(img) {
      return require(`@/assets/img/reports/${img}`)
    },
    handlePreReportClick() {
      this.perReports.actions
        ? (this.$parent.dialogState = true)
        : this.$parent.editReport(this.perReports.report)
    }
  }
}
</script>
<style lang="scss" scoped>
.action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    color: #4a148c;
  }

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23662D91FF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
}

.report__card__col {
  .v-card--material-stats {
    border: 2px dashed transparent !important;
    height: 100%;
    h4 {
      color: #101010;
    }
    &:hover {
      border: 2px dashed #ad90c2 !important;
    }
  }

  // .v-card--material-stats
  .report__cards {
    background: #ffffff;
    border: 2px dashed #ad90c2 !important;
    box-shadow: 2px 2px 20px rgb(0 0 0 / 3%) !important;
    border-radius: 10px !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h4 {
      color: #4a148c;
    }
  }

  // report__cards
}

// report__card__col
</style>
