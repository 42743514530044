<template>
  <div class="d-flex align-center justify-end ml-md-8 fast-actions">
    <v-btn
      @click="$emit('openAddDialog', true)"
      class="font-weight-bold fast-actions-btn pa-5 font-12"
      id="openModal"
      color="primary"
      large
    >
      <img class="mx-2" src="@/assets/img/svgs/user-w.svg" />
      إضافة عميل
    </v-btn>

    <!----------------------------->
    <!-------Fast actions---------->
    <!----------------------------->
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OpenClientModal'
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
</style>
