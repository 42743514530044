<template>
  <v-card class="px-5 py-4 my-3 d-flex justify-space-between">
    <div class="d-flex align-center">
      <!-----------Icon----------->
      <div class="ml-4">
        <div class="notification-icon">
          <img v-if="icon" :src="icon" width="20" />
          <img v-else :src="customerIcon" width="20" />
        </div>
      </div>

      <div>
        <h6 class="font-14 font-weight-medium mt-0">{{ template.title }}</h6>
        <p class="font-12 ma-0" :class="descriptionClasses">
          {{ template.description || template.body }}
        </p>
      </div>
    </div>
    <div class="d-flex align-center">
      <div class="d-flex align-center" v-if="isNotifications">
        <span class="caption mx-2">تشغيل الي</span>
        <v-switch
          class="ma-auto"
          dense
          hide-details="auto"
          inset
          v-model="notificationStatus"
          @change="changeNotificationStatus"
        ></v-switch>
      </div>

      <div
        v-else-if="isActiveOption"
        class="d-flex justify-center align-center ml-5 settings-status"
      >
        {{ isActive }}
      </div>

      <v-switch
        v-else
        class="ma-auto"
        dense
        hide-details="auto"
        inset
        v-model="template_"
        @change="editTemplates"
      ></v-switch>
      <img
        @click="getTemplateBody"
        alt="editPen"
        class="cursor"
        height="18"
        src="@/assets/img/svgs/editPen.svg"
        loading="lazy"
      />
    </div>
  </v-card>
</template>

<script>
import { templatesService } from '@/services'
import { mapMutations } from 'vuex'

export default {
  name: 'modelUint',
  props: {
    type: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 40
    },
    template: {
      type: Object,
      required: true
    },
    isActiveOption: {
      type: Boolean,
      default: false
    },
    notificationsSettings: {
      type: Object,
      default() {
        return {}
      }
    },
    isChangeProcessFailed: {
      type: Boolean
    },
    icon: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      notificationStatus: null,
      customerIcon: require('@/assets/img/svgs/customer-notification.svg')
    }
  },
  computed: {
    isNotifications() {
      return this.type === 'notification'
    },
    templateToUpdate() {
      const data = {
        active: this.template.active,
        body: this.template.body
      }
      return data
    },
    templateId() {
      return this.template?._id
    },
    template_: {
      get() {
        return this.template.active
      },
      set(value) {
        this.templateToUpdate.active = value
      }
    },
    descriptionClasses() {
      return this.isActiveOption ? 'description' : 'text-gray'
    },
    isActive() {
      return this.template.active ? 'مفعل' : 'غير مفعل'
    }
  },
  watch: {
    notificationsSettings: {
      handler() {
        this.notificationStatus = this.notificationsSettings.status
      },
      immediate: true
    },
    isChangeProcessFailed: {
      handler(val) {
        if (val) {
          this.notificationStatus = this.notificationsSettings.status
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getTemplateBody() {
      if (this.isNotifications) {
        this.$emit('openNotificationModal', this.notificationsSettings)
      } else if (this.isActiveOption) {
        this.$emit('openSettingsModal', this.template)
      } else {
        this.$emit('setTemplateBody', this.template)
      }
    },
    editTemplates() {
      templatesService
        .editTemplates(this.templateToUpdate, this.templateId)
        .then(() => {
          this.addNotification({
            text: 'تم تعديل النموذج',
            color: 'success'
          })
        })
        .catch(() => {
          this.addNotification({
            text: 'حدث خطأ في تعديل نموذج العقد يرجى المحاوله مجددا',
            color: 'error'
          })
        })
    },
    changeNotificationStatus() {
      const newSettings = {
        active: this.notificationStatus,
        code: this.template.code,
        _id: this.template._id,
        levels: this.template.levels,
        environment: this.template.environment,
        users: this.template.users
      }
      this.$emit('notificationsChanged', {
        notification: newSettings
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  color: #585858;
}
.settings-status {
  width: 65px;
  height: 28px;
  background: rgba(102, 45, 145, 0.1);
  border-radius: 47px;
  color: #662d91;
  font-size: 12px;
}

.notification-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: #ebf2fd;
}
</style>
