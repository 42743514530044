export const debounce = function (fn, delay) {
  let timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    const args = arguments
    // eslint-disable-next-line no-invalid-this
    const that = this
    timeoutID = setTimeout(() => fn.apply(that, args), delay)
  }
}
