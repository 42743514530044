<template>
  <v-layout d-flex justify-space-between align-center class="flex-wrap mb-5">
    <div>
      <p class="grey--text ma-0 font-12">إجمالي قيمة الإيجار</p>
      <h5 class="ma-0 font-14 font-weight-bold">
        <span>
          <span>{{ formattingNumbers(contractInfo?.rent) }} ريال</span>
        </span>
      </h5>
    </div>
    <div>
      <p class="grey--text ma-0 font-12">إجمالي قيمة الخدمات</p>
      <h5 class="ma-0 font-14 font-weight-bold">
        <span>
          <span
            >{{ formattingNumbers(contractInfo?.utilitiesTotal) }} ريال</span
          >
        </span>
      </h5>
    </div>
    <div>
      <p class="grey--text ma-0 font-12">إجمالي الضريبة</p>
      <h5 class="ma-0 font-14 font-weight-bold">
        <span>
          <span
            >{{
              formattingNumbers(
                contractInfo?.rentTax + contractInfo?.utilitiesTotalTax
              )
            }}
            ريال</span
          >
        </span>
      </h5>
    </div>
    <div>
      <p class="grey--text ma-0 font-12">إجمالي قيمة العقد</p>
      <h5 class="ma-0 font-14 font-weight-bold">
        <span>
          <span
            >{{ formattingNumbers(contractInfo?.netContractValue) }} ريال</span
          >
        </span>
      </h5>
    </div>
  </v-layout>
</template>

<script>
export default {
  name: 'HeaderInstallments',
  props: {
    contractInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({}),
  methods: {
    formattingNumbers(num) {
      return parseFloat(num ?? 0).toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    }
  }
}
</script>
