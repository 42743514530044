<template>
  <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" md="12">
    <h5 class="mb-2 mt-3 font-12 px-3">حدد موقع {{ mapTitle }} علي الخريطة*</h5>
    <gmap-map
      :center="center"
      :options="options"
      :zoom="14"
      ref="mapRef"
      style="width: 100%; height: 400px"
      class="mx-2"
    >
      <gmap-marker
        data-test="marker"
        :clickable="true"
        :draggable="true"
        :icon="{ url: require('@/assets/img/pin.svg') }"
        :position="currentPlace"
        @click="center = m.position"
        @dragend="getFormattedAddress($event.latLng)"
      />
    </gmap-map>
  </v-col>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'

import config from '@/config'
export default {
  props: {
    mapTitle: { default: 'العقار' },
    selectedPlace: {
      required: true
    }
  },
  data() {
    return {
      address: '',
      province: '',
      district: '',
      center: { lat: 24.448378, lng: 39.586848 },
      currentPlace: { lat: 24.448378, lng: 39.586848 },
      hasError: false,
      autocompleteOptions: {
        componentRestrictions: {
          country: ['sa']
        }
      }
    }
  },
  computed: {
    google: gmapApi,
    options() {
      return {
        scrollwheel: false,
        fullscreenControl: false,
        streetViewControl: false,
        minZoom: 9,
        mapTypeControlOptions: {
          style: 1,
          position: 12
        },
        styles: config.mapStyles
      }
    }
  },
  methods: {
    async getFormattedAddress(location) {
      // Convert the latlang to formatted address
      const geocoder = new window.google.maps.Geocoder()
      await geocoder.geocode({ latLng: location }, (results) => {
        this.address = results[0].formatted_address
        this.city = results[0].address_components[3]?.long_name
        this.province = results[0].address_components[2]?.long_name
        this.district = results[0].address_components[1]?.long_name
        this.currentPlace = {
          lat: location.lat(),
          lng: location.lng(),
          address: this.address,
          city: this.city,
          province: this.province,
          district: this.district
        }
        this.center = this.currentPlace
      })
      this.$emit('onCheckAddress', { place: this.currentPlace })
    },
    setPlace(location) {
      if (location) {
        this.address = location.formatted_address
        this.city = location.address_components[3]?.long_name
        this.province = location.address_components[2]?.long_name
        this.district = location.address_components[1]?.long_name
        this.currentPlace = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng(),
          address: this.address,
          city: this.city,
          province: this.province,
          district: this.district
        }
        this.center = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng()
        }
        this.hasError = false
        this.$emit('onCheckAddress', { place: this.currentPlace })
      }
    }
  },
  mounted() {
    if (this.selectedPlace.lat) {
      this.currentPlace = this.selectedPlace
      this.center = this.currentPlace
    }
  }
}
</script>
