<template>
  <div>
    <div class="show-detailes">
      <v-slide-x-transition>
        <v-layout>
          <v-flex
            @click="$emit('showVersions', { visible: false, id: tax })"
            class="greySide"
            md5
          ></v-flex>
          <v-flex class="whiteSide" md7>
            <v-layout align-center class="my-6">
              <v-img
                :lazy-src="exitArrow"
                :src="exitArrow"
                @click="$emit('showVersions', { visible: false, id: tax })"
                max-height="15"
                max-width="15"
              ></v-img>
              <span class="font-16 font-weight-bold mx-3">
                {{ taxName }}
              </span>
            </v-layout>
            <v-divider class="mb-5"></v-divider>
            <v-layout>
              <v-flex>
                <taxInvoiceCard
                  v-for="paymentNote in tax.paymentNotes"
                  :key="paymentNote._id"
                  v-bind="generateTaxCardProps(paymentNote)"
                  @exportPdfTax="$emit('exportPdfTax', $event)"
                  @deleteTax="$emit('deleteTax', $event)"
                  @editApprovedTax="$emit('editApprovedTax', $event)"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-slide-x-transition>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import taxInvoiceCard from './taxInvoiceCard.vue'

export default {
  name: 'side-versions',
  components: { taxInvoiceCard },
  props: ['tax'],
  data() {
    return {
      exitArrow: require('@/assets/img/svgs/right-arrow.svg')
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setDetails({ visible, tax }) {
      this.$emit('showDetails', { visible, tax })
      this.$emit('showVersions', { visible: false, id: tax._id })
    },
    generateTaxCardProps(paymentNote) {
      return {
        item: this.tax,
        paymentNotes: paymentNote,
        sideVersions: true,
        type: 'notes'
      }
    }
  },
  computed: {
    taxName() {
      return this.tax?.title ?? 'فاتوره ضريبيه'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_sideVersionPages.scss';
</style>
