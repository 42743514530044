<template>
  <div>
    <!-------card Exceed Limit-------->
    <cardExceedLimit
      v-if="exceededAddingMember"
      :title="exceedTitle"
      :subTitle="exceedSubTitle"
    />

    <v-card v-else-if="hasPermission" class="px-5 py-6">
      <h4 class="font-16">دعوة جديدة</h4>
      <v-form ref="form" v-model="isValid">
        <v-row no-gutters>
          <v-col
            class="pl-3 mobile__pl__0 mobile__mw__100 mobile__flex__auto"
            cols="3"
          >
            <h6 class="mb-2 font-12">رقم الجوال*</h6>
            <vue-tel-input-vuetify
              :loading="checkPhoneNumberLoader"
              dense
              :error-messages="phoneNumberErrorMessage"
              :success-messages="phoneNumberSuccessMesage"
              class="phonenumber-field"
              v-model="member.phoneNumber"
              label=""
              :only-countries="['SA']"
              default-country="SA"
              ref="regPhoneNumber"
              placeholder="رقم الجوال"
              :filled="true"
              required
              :rules="[
                (v) => (v && !!isValidPhone) || 'من فضلك تأكد من رقم الهاتف'
              ]"
              :valid-characters-only="true"
              :validate-on-blur="true"
              @validate="onValidatePhoneNumber"
              @input="checkUserExistance($event)"
            />
          </v-col>

          <v-col
            class="pl-3 mobile__pl__0 mobile__mw__100 mobile__flex__auto"
            cols="3"
          >
            <h6 class="mb-2 font-12">البريد الإلكتروني (إختياري)</h6>
            <v-text-field
              filled
              v-model="member.email"
              :rules="emailRules"
              placeholder="البريد الإلكتروني"
              dense
              hide-details
              class="setting__input__fields"
            ></v-text-field>
          </v-col>

          <v-col
            class="pl-3 mobile__pl__0 mobile__mw__100 mobile__flex__auto"
            cols="3"
          >
            <h6 class="mb-2 font-12">نوع الدعوة*</h6>
            <v-select
              color="black"
              filled
              v-model="member.role"
              :items="roles"
              :rules="[(v) => !!v || 'من فضلك اختر نوع الدعوة']"
              placeholder="نوع الدعوة"
              required
              hide-details
              dense
              :menu-props="{ bottom: true, offsetY: true }"
              class="setting__input__fields"
            ></v-select>
          </v-col>

          <v-col
            class="pl-3 mobile__pl__0 mobile__mw__100 mobile__flex__auto"
            cols="3"
          >
            <h6 class="mb-2 font-12">مجال العمل</h6>
            <v-select
              filled
              v-model="member.tags"
              :items="tagsData"
              placeholder="العلامات"
              hide-details
              :menu-props="{ bottom: true, offsetY: true }"
              chips
              dense
              multiple
              class="setting__input__fields"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-col class="mt-5 text-left col-4" cols="2">
            <v-btn
              color="primary"
              :loading="isLoading"
              class="ma-0 rounded-7 mb-2 ml-3 mr-auto"
              :disabled="!isValid"
              @click="addTeamMember()"
            >
              <span class="mb-2 ml-1 font-weight-bold font-12"
                >ارسال الدعوة</span
              >
              <v-icon class="send-icon font-12">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <div class="px-5 py-6 mt-5 mobile__pt__0">
      <!-- Loader -->

      <v-row class="mx-0" v-if="isTeamLoading">
        <v-col
          cols="12"
          class="property-col mb-2 shadow-border-radius px-0"
          v-for="i in 6"
          :key="i"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <!--no-team-->

      <div v-else-if="!team.length" class="no-team">
        <h3>لا يوجد اعضاء مضافين لديك</h3>
      </div>

      <!--data-table-team-->

      <v-row v-else>
        <v-toolbar-title class="d-flex align-center mb-3">
          <v-toolbar-title class="font-weight-bold font-16">
            فريق العمل</v-toolbar-title
          >
          <p class="sub-title mx-2 p gery--text ma-0">{{ team.length }} عضو</p>
        </v-toolbar-title>

        <data-table-team
          :users="team"
          :headers="headersArr"
          @toggle-status="toggleTeamMemberStatus"
          @edit-member="editTeamMember"
          @refresh-team-list="refreshTeamList"
        />
      </v-row>
      <!--Pagination-->
      <div class="text-center mt-6 mb-5" v-if="viewPagination">
        <Pagination :pagination="pagination" @onChange="loadEmployees" />
      </div>
    </div>
    <add-team-member-modal
      :editMemberId="memberId"
      :memberData="memberData"
      @closed-add-dialog="dialogState = false"
      :dialogState="dialogState"
      v-if="dialogState"
      @changeDialogState="dialogState = $event"
    />
  </div>
</template>

<script>
import cardExceedLimit from '@/components/messages/cardExceedLimit'
import dataTableTeam from '../team/dataTableTeam'
import { mapState, mapMutations, mapActions } from 'vuex'
import { EnvService } from '../../services'
import Pagination from '@/components/helper/pagination.vue'

export default {
  name: 'invite-sittings',
  components: {
    dataTableTeam,
    AddTeamMemberModal: () =>
      import('@/components/modals/AddTeamMemberModal.vue'),
    Pagination,
    cardExceedLimit
  },
  created() {
    this.loadEmployees()
  },
  data() {
    return {
      checkPhoneNumberLoader: false,
      phoneNumberErrorMessage: '',
      phoneNumberSuccessMesage: '',
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 8,
        count: 0
      },
      isValid: false,
      isLoading: false,
      isTeamLoading: true,
      member: {},
      memberData: {},
      memberId: null,
      dialogState: false,
      emailRules: [
        (v) => /.+@.+\..+/.test(v) || !v || 'من فضلك أدخل بريد الكتروني صحيح'
      ],
      phoneData: {},
      roles: [
        { text: 'مشرف', value: 'admin' },
        { text: 'عضو', value: 'member' }
      ],
      tagsData: [
        { text: 'إدارة أملاك', value: 'إدارة أملاك' },
        { text: 'صيانة', value: 'صيانة' },
        { text: 'تسويق', value: 'تسويق' },
        { text: 'تحصيل', value: 'تحصيل' }
      ],
      headersArr: [
        {
          text: ' الاسم',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: ' رقم الجوال', value: 'phoneNumber' },
        { text: 'الايميل', value: 'email' },
        { text: 'النوع', value: 'environments' },
        { text: 'حالة الدعوة', value: 'registered' },
        { text: 'الحالة', value: 'active' },
        { text: '', value: 'actions' }
      ],
      team: [],
      exceedTitle:
        'لقد  استنفذت العدد المحدد من الإضافات المتاحة في باقتك الحالية وبإمكانك ترقية الباقة لإجراء إضافات جديدة',
      exceedSubTitle:
        'يمكنك ترقية الباقة  الحالية  لباقة أعلى أو التواصل معنا مباشرة في حال كان اشتراكك الحالي في أعلى باقة متاحة'
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'bundleFeautres']),
    isValidPhone() {
      return this.phoneData.isValid
    },
    viewPagination() {
      return this.pagination.pagesCount > 1
    },

    exceededAddingMember() {
      return this.bundleFeautres?.team === 'unlimited'
        ? true
        : !!(this.bundleFeautres?.team <= 0)
    },
    hasPermission() {
      return (
        this.$hasPermission('users', 'invitations', 'add') &&
        !this.exceededAddingMember
      )
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapActions('accountState', ['getUserBundle']),
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    checkUserExistance(phoneNumber) {
      phoneNumber = phoneNumber.replace(/ /g, '')
      if (phoneNumber.length !== 10) {
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = 'من فضلك تأكد من رقم الهاتف'
      } else if (this.isValidPhone) {
        this.checkPhoneNumberLoader = true
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = ''
        EnvService.checkEmployee(this.currentEnv._id, phoneNumber)
          .then((response) => {
            if (response.data.available) {
              this.phoneNumberSuccessMesage = 'الموظف متاح للدعوة'
              this.phoneNumberValid = true
              this.phoneNumberErrorMessage = null
            }
            if (phoneNumber.length !== 10) {
              this.phoneNumberSuccessMesage = ''
              this.phoneNumberErrorMessage = ''
            }
          })
          .catch(() => {
            this.phoneNumberErrorMessage = ' الرقم مستخدم في بيئة اخرى'
          })
          .finally(() => {
            this.checkPhoneNumberLoader = false
          })
      }
    },
    editTeamMember(val) {
      this.memberId = val._id
      this.memberData = val
      this.dialogState = true
    },
    loadEmployees() {
      const payLoad = `&pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
      EnvService.getAllEmployee(this.currentEnv._id, payLoad)
        .then((res) => {
          if (res.data) {
            this.team = res?.data?.users ?? []
            // bring the owner to the top
            this.team.sort((x, y) => {
              if (x.environments[0].employeeRole === 'owner') {
                return -1
              }
              if (y.environments[0].employeeRole === 'owner') {
                return 1
              }
              return 0
            })
            this.pagination.pagesCount = Math.ceil(
              res?.data?.count / this.pagination.pageSize
            )
            this.pagination.count = res?.data?.count ?? 0
          }
        })
        .catch(() => {
          this.addNotification({
            text: 'خطأ في تحميل فريق العمل',
            color: 'error'
          })
        })
        .finally(() => {
          this.isTeamLoading = false
        })
    },
    addTeamMember() {
      this.member.phoneNumber = this.member.phoneNumber.replace(/ /g, '')
      const data = {
        environmentId: this.currentEnv._id,
        ...this.member,
        active: 0
      }
      this.isLoading = true
      EnvService.inviteEmployee(data)
        .then((res) => {
          if (res.data && res.data.user) {
            this.addNotification({
              text: 'تم ارسال الدعوة بنجاح',
              color: 'success'
            })
            this.refreshTeamList()
            this.member = {}
            return
          }
          this.addNotification({
            text:
              res.data.error === 'user_exists'
                ? 'عفواً! هذا المستخدم موجود مسبقا'
                : 'عفواً! البيئة المدخلة غير موجودة',
            color: 'error'
          })
        })
        .catch((err) => {
          this.addNotification({
            text:
              err.response.data.error === 'user_exists'
                ? 'عفواً! هذا المستخدم موجود مسبقا'
                : 'عفواً! البيئة المدخلة غير موجودة',
            color: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    toggleTeamMemberStatus(payload) {
      this.$set(this.team[payload.memberIndex], 'active', payload.active)
    },
    refreshTeamList() {
      this.loadEmployees()
      this.getUserBundle()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .setting__input__fields {
    .v-input__control {
      .v-input__slot {
        min-height: 34px !important;
        .v-text-field__slot,
        .v-select__slot {
          margin-bottom: 0 !important;
          .v-input__append-inner {
            margin-top: 5px;
          }
          // v-input__append-inner
          input {
            font-size: 14px;
            padding-top: 0 !important;
            font-weight: 500;
            color: #101010;
          }
          // input
          .v-text-field__suffix {
            font-size: 13px;
            padding-top: 0 !important;
          }
        }
        // v-text-field__slot
        .v-input__icon {
          .v-icon {
            font-size: 20px;
          }
          // v-icon
        }
        // v-input__icon
      }
      // v-input__slot
      .v-text-field__details {
        margin-bottom: 0;
      }
      // v-text-field__details
      .v-select__selections {
        font-size: 14px;
        min-height: 34px !important;
        input {
          margin-bottom: 0 !important;
        }
        .v-chip.v-size--default {
          font-size: 12px;
          height: 26px;
        }
      }
    }
    // v-input__control
  }
  // setting__input__fields
}
// ::v-deep
</style>
