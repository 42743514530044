<template>
  <div class="flex-grow-1">
    <!-- ------------------------------------- -->
    <!-- SWITCH BETWEEN CLIENTS AND REALESTATS -->
    <!-- ------------------------------------- -->
    <div class="d-flex mt-6 mb-3 align-center w-100" v-if="data.showSwitch">
      <v-col class="pa-0" cols="12" sm="12">
        <dropdown-alt
          @filterTypes="data.onReportOptionChange($event)"
          :defaultValue="data.selectedOption"
          :configrations="{ name: '' }"
          :listingType="data.reportOption"
        />
      </v-col>
    </div>

    <!-- ---------- -->
    <!-- Realestates - Unit -->
    <!-- ---------- -->
    <div v-if="data.showUnits || data.showRealEstats" class="row w-100">
      <v-col class="pl-3 pt-0" cols="6">
        <h6 class="mb-2">اختر العقار</h6>
        <autocomplete
          :loading="data.realestateIsLoading"
          :autoCompleteReset="autoCompleteReset"
          :itemText="propertyToShow"
          :items="data.allProperties"
          :noData="
            data.realestateIsLoading ? loadingRealestatesText : noDataRealestate
          "
          :placeholder="selectedRelestatePlaceholder"
          :returnObject="true"
          :validateString="validateString"
          @emitSearchKey="data.getPropertyDataBySearchKey"
          @nextPageTrigger="data.getPropertyNextData"
          @input="getUnits($event)"
          v-model="data.reportSelectedCriteria.realEstate"
          :multiple="true"
          :selection="true"
          selectionTemplate="عقارات"
        />
      </v-col>
      <v-col class="pl-3 pt-0" cols="6">
        <h6 class="mb-2">إختر الوحدات</h6>
        <autocomplete
          :disabled="data.reportSelectedCriteria.realEstate.length !== 1"
          :loading="data.unitIsLoading"
          :autoCompleteReset="autoCompleteReset"
          :itemText="propertyToShow"
          :items="filteredAvailableUnits"
          :noData="data.unitIsLoading ? loadingUnitsText : noDataunit"
          :showLengthData="showLengthData"
          placeholder="إختر الوحدات"
          :returnObject="true"
          :validateString="validateStringUnit"
          @emitSearchKey="data.getUnitDataBySearchKey"
          @input="setSelectedUnits"
          multiple
          :chips="false"
          hideDetails
          v-model="selectedUnits"
        />
      </v-col>
    </div>

    <!-- ---------- -->
    <!-- Realestates - Client -->
    <!-- ---------- -->
    <div v-else-if="data.showRealEstats" class="row w-100">
      <v-col
        class="pl-3 pt-0"
        cols="12"
        :sm="data.getReportTypeCategoryName !== 'Users' ? 6 : 12"
      >
        <h6 class="mb-2" :class="{ 'red--flag': !data.realestateRequiemrnt }">
          اختر العقار
        </h6>
        <autocomplete
          :loading="data.realestateIsLoading"
          :autoCompleteReset="autoCompleteReset"
          :itemText="propertyToShow"
          :items="data.allProperties"
          :noData="
            data.realestateIsLoading ? loadingRealestatesText : noDataRealestate
          "
          :placeholder="selectedRelestatePlaceholder"
          :returnObject="true"
          :validateString="validateString"
          @emitSearchKey="data.getPropertyDataBySearchKey"
          @nextPageTrigger="data.getPropertyNextData"
          v-model="data.reportSelectedCriteria.realEstate"
          :multiple="isOwnerFinancial ? false : true"
          :selection="true"
          selectionTemplate="عقارات"
        />
      </v-col>
      <v-col
        class="pl-3 pt-0"
        cols="6"
        v-if="data.getReportTypeCategoryName !== 'Users'"
      >
        <h6 class="mb-2">اختر اسم ال{{ data.generatePrefix.title }}</h6>
        <autocomplete
          :loading="data.clientIsLoading"
          :autoCompleteReset="autoCompleteReset"
          :itemData="true"
          :items="data.allUsers"
          :noData="data.clientIsLoading ? loadingClientsText : noDataClient"
          :placeholder="`إختر اسم ال${data.generatePrefix.title}`"
          :returnObject="true"
          :validateString="validateStringClient"
          @emitSearchKey="data.getClientDataBySearchKey"
          v-model="data.reportSelectedCriteria.user"
          :multiple="data.isItAGeneralReport ? false : true"
          :selection="true"
          :selectionTemplate="data.generatePrefix.title"
          :disabled="isOwnerFinancial ? true : false"
        />
      </v-col>
    </div>

    <!-- ------- -->
    <!-- Client - Realestates -->
    <!-- ------- -->
    <div v-else-if="data.showClients" class="row w-100 mt-6">
      <v-col
        :sm="
          data.getReportTypeCategoryName !== 'Users' &&
          data.realestateRequiemrnt
            ? 6
            : 12
        "
        :class="['pt-0', 'pl-0', { 'red--flag': !data.clientRequirement }]"
        cols="12"
      >
        <h6 class="mb-2">اختر اسم ال{{ data.generatePrefix.title }}</h6>
        <autocomplete
          :loading="data.clientIsLoading"
          :autoCompleteReset="autoCompleteReset"
          :itemData="true"
          :items="data.allUsers"
          :noData="data.clientIsLoading ? loadingClientsText : noDataClient"
          :placeholder="`اختر اسم ال${data.generatePrefix.title}`"
          :returnObject="true"
          :validateString="validateStringClient"
          @emitSearchKey="data.getClientDataBySearchKey"
          @nextPageTrigger="data.getClientNextData"
          v-model="data.reportSelectedCriteria.user"
          :multiple="data.isClientFilterMultiple ? false : true"
          :selection="true"
          :selectionTemplate="data.generatePrefix.title"
        />
      </v-col>
      <v-col
        class="pl-0 pt-0 pr-3"
        cols="12"
        sm="6"
        v-if="
          data.getReportTypeCategoryName !== 'Users' &&
          data.realestateRequiemrnt
        "
      >
        <h6 class="mb-2">اختر العقار</h6>
        <autocomplete
          :loading="data.realestateIsLoading"
          :autoCompleteReset="autoCompleteReset"
          :itemText="propertyToShow"
          :items="data.allProperties"
          :noData="
            data.realestateIsLoading ? loadingRealestatesText : noDataRealestate
          "
          :placeholder="selectedRelestatePlaceholder"
          :returnObject="true"
          :validateString="validateString"
          @emitSearchKey="data.getPropertyDataBySearchKey"
          @nextPageTrigger="data.getPropertyNextData"
          v-model="data.reportSelectedCriteria.realEstate"
          :multiple="true"
          :selection="true"
          selectionTemplate="عقارات"
        />
      </v-col>
    </div>
  </div>
</template>

<script>
import DropdownAlt from '@/components/add-unit/dropdownAlt'
import autocomplete from '@/components/forms/autocomplete'
export default {
  name: 'ReportsFilter',
  props: {
    data: {
      type: Object,
      required: true
    },
    isOwnerFinancial: {
      default: false,
      type: Boolean
    }
  },
  components: {
    autocomplete,
    DropdownAlt
  },
  data() {
    return {
      all: [],
      autoCompleteReset: false,
      propertyToShow: 'propertyName',
      loadingRealestatesText: 'جاري تحميل العقارات',
      noDataRealestate: 'لا توجد عقارات متاحه لهذا البحث',
      selectedRelestatePlaceholder: 'كل العقارات',
      noDataClient: 'لا يوجد عملاء متاحين لهذا البحث',
      loadingClientsText: 'جاري تحميل العملاء',
      loadingUnitsText: 'جاري تحميل الوحدات',
      noDataunit: 'لا توجد واحدات متاحة',
      validateStringClient: '',
      validateString: '',
      validateStringUnit: '',
      availableUnits: [],
      selectedUnits: [{ propertyName: 'كل الوحدات', allocated: true }]
    }
  },
  watch: {
    selectedUnits: {
      deep: true,
      handler(val) {
        if (!val.length) {
          this.setavailableUnits(this.data.availableUnitsForParent)
          return
        }

        const modifiedAvailableUnits = [
          ...this.data.availableUnitsForParent
        ].map((unit) => {
          unit.disabled = false
          return unit
        })
        this.setavailableUnits(modifiedAvailableUnits)
        if (this.availableUnits?.length) {
          const ParentpropertyChecked = this.selectedUnits.find((unit) => {
            return unit.propertyName === 'كل الوحدات'
          })
          if (ParentpropertyChecked) {
            this.availableUnits = [this.data.availableUnitsForParent[0]]
            return
          }
          this.availableUnits.shift()
        }
        return modifiedAvailableUnits
      }
    },
    'data.availableUnitsForParent': {
      handler(val) {
        this.setavailableUnits(val)
      }
    }
  },
  computed: {
    showLengthData() {
      return {
        status: true,
        length: this.selectedUnits.length,
        allText: 'كل الوحدات',
        showAllText:
          !this.selectedUnits[0]?._id ||
          this.data.reportSelectedCriteria.realEstate.length !== 1
      }
    },
    filteredAvailableUnits() {
      if (!this.selectedUnits.length) {
        return this.availableUnits
      }
      return this.availableUnits.filter((unit) => {
        return !this.selectedUnits[0]?._id ? !unit?._id : unit?._id
      })
    },
    isUnitsAvailable() {
      return (
        this.data?.reportSelectedCriteria?.realEstate?.length === 1 &&
        this.data?.availableUnitsForParent?.length > 1 &&
        !this.data.unitIsLoading
      )
    }
  },
  methods: {
    getUnits(realestates) {
      if (realestates.length === 1) {
        this.$emit('getUnits', realestates)
        this.selectedUnits = []
      } else {
        this.selectedUnits = [{ propertyName: 'كل الوحدات', allocated: true }]
      }
    },
    setavailableUnits(newAvailableUnits) {
      this.availableUnits = newAvailableUnits
    },
    setSelectedUnits() {
      this.$emit('setSelectedUnits', this.selectedUnits)
    },
    transformRealEstateObject(object) {
      const whiteListedKeys = [
        'propertyName',
        '_id',
        'address',
        'owner',
        'parentId',
        'purpose',
        'type'
      ]
      Object.keys(object).forEach((key) => {
        if (!whiteListedKeys.find((keyToBeDeleted) => keyToBeDeleted === key)) {
          delete object[key]
        }
      })
      return object
    }
  }
}
</script>

<style lang="scss" scoped></style>
