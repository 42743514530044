<template>
  <!-- Forget Password -->
  <form-wizard
    id="register-wizard"
    ref="regWizard"
    shape="circle"
    title
    subtitle
    next-button-text="التالي"
    back-button-text="رجوع"
    finish-button-text="تسجيل"
    step-size="xs"
    color="#3cab74"
    error-color="#e74c3c"
  >
    <!-- Step #1 ----------------------------------------------------->
    <tab-content :before-change="() => validateStep('step1')">
      <forget-password-step1
        ref="step1"
        @on-validate="collectFirstStepData"
        page="forgetPassword"
      >
      </forget-password-step1>
    </tab-content>

    <!-- Step #2 ----------------------------------------------------->
    <tab-content :before-change="() => validateStep('step2')">
      <div id="step-2">
        <div class="step-title mb-5">
          <h4 class="submit-section-header">التحقق من رقم الجوال</h4>
          <p>
            فضلا قم بإدخال رمز التحقيق المكون من ٤ أرقام المرسل إلى جوالك رقم
            <strong>{{ finalModel.phoneNumber | filterPhoneNumber }}</strong>
          </p>
        </div>
        <forget-password-step2
          ref="step2"
          :phone-number="finalModel.phoneNumber"
          @on-validate="onConfirmPhoneNumber"
          @resendVerficationCode="resendVerficationCode"
          page="forgetPassword"
        >
        </forget-password-step2>
      </div>
    </tab-content>

    <!-- Step #3 ----------------------------------------------------->
    <tab-content :before-change="() => validateStep('step3')">
      <reset-password
        ref="step3"
        :phone-number="finalModel.phoneNumber"
        :confirmation-code="confirmationCode"
        @on-validate="collectData"
      >
      </reset-password>
    </tab-content>

    <template slot="footer" slot-scope="props">
      <div class="wizard-footer-left">
        <v-btn
          class="ma-0"
          v-if="props.activeTabIndex === 1"
          rounded
          large
          text
          color="grey"
          @click.prevent="
            () => {
              props.prevTab()
              $refs.step2.resetTimer()
            }
          "
        >
          <v-icon left>mdi-chevron-right</v-icon>
          <span class="tf">السابق</span>
        </v-btn>
      </div>
      <div class="wizard-footer-left w-100">
        <v-btn
          large
          :loading="spinnerLoading"
          v-if="!props.isLastStep && props.activeTabIndex !== 1"
          color="primary"
          class="wizard-footer-right w-100 ma-0"
          @click.prevent="props.nextTab()"
        >
          <span class="tf">الخطوة التالية</span>
        </v-btn>

        <v-btn
          large
          v-else-if="props.isLastStep"
          :loading="spinnerLoading"
          color="primary"
          class="wizard-footer-right w-100 finish-button ma-0"
          @click.prevent="validateStep('step3')"
        >
          <span class="tf">تعيين كلمة المرور الجديدة</span>
        </v-btn>

        <v-btn
          v-if="props.activeTabIndex === 0"
          large
          block
          class="mt-4 mb-1"
          color="green"
          @click="showLoginStep"
        >
          <span class="tf">العوده الى تسجيل الدخول</span>
        </v-btn>
      </div>
    </template>
  </form-wizard>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import '@/styles/material-dashboard/_login.scss'

// Wizard
import { FormWizard, TabContent } from 'vue-form-wizard'
import ForgetPasswordStep1 from '@/components/login-register/ForgetPasswordStep1'
import ForgetPasswordStep2 from '@/components/login-register/ForgetPasswordStep2'
import ResetPassword from '@/components/login-register/ResetPassword'

export default {
  components: {
    FormWizard,
    TabContent,
    ForgetPasswordStep1,
    ForgetPasswordStep2,
    ResetPassword
  },
  data() {
    return {
      phoneNumber: '',
      confirmationCode: '',
      errorMessages: 'Incorrect login info',
      finalModel: {},
      phoneData: {}
    }
  },
  mounted() {
    this.tab = this.activeLignTab
    if (this.validePhonenumber) {
      this.phoneNumber = this.validePhonenumber
    }
  },

  computed: {
    ...mapState('userState', [
      'spinnerLoading',
      'validePhonenumber',
      'activeLignTab'
    ])
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    ...mapActions('accountState', [
      'checkPhoneNumberForResetPassword',
      'confirmForgetCode'
    ]),
    ...mapMutations('accountState', ['otpConfirmed']),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapMutations('appState', ['addNotification']),

    validateStep(name) {
      const refToValidate = this.$refs[name]
      return refToValidate.validate()
    },

    collectData(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model)
      }
    },

    async resendVerficationCode(phoneNumber) {
      try {
        this.setSpinnerLoading(true)
        const { data } = await this.checkPhoneNumberForResetPassword({
          phoneNumber
        })
        this.$refs.step2.timer()
        this.confirmationCode = data.confirmationCode
        this.addNotification({
          text: 'تم إرسال الرقم التأكيدي لهاتفك بنجاح!',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في إعادة إرسال رمز التحقق، يرجى المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.setSpinnerLoading(false)
      }
    },

    onConfirmPhoneNumber(data) {
      this.collectData(data, true)
      this.confirmForgetCode(data)
        .then((response) => {
          if (response.data.success) {
            this.addNotification({
              text: 'تم التحقق من رقم هاتفك',
              color: 'success'
            })
            // this.otpConfirmed(true);
            this.$refs.regWizard.changeTab(1, 2)
          } else {
            this.addNotification({
              text: 'رقم التحقيق الذي ادخلته خاطئ',
              color: 'error'
            })
          }
        })
        .catch((err) => {
          const errorMessage = err.response?.data?.message?.includes('خاطئ')
            ? 'رقم التحقيق الذي ادخلته خاطئ'
            : 'حدث خطأ اثناء تأكيد رقم التحقيق، يرجى المحاوله مجددا'
          this.addNotification({
            text: errorMessage,
            color: 'error'
          })
        })
    },
    collectFirstStepData(payload) {
      const phone = { phoneNumber: payload.phoneNumber }
      this.collectData(phone, true)
      this.$refs.step2.timer()
      this.confirmationCode = payload.confirmationCode
      // Go to next step
      this.$refs.regWizard.changeTab(0, 1)
    },
    showLoginStep() {
      this.$emit('showLoginStep')
    }
  }
}
</script>
