<template>
  <v-container>
    <v-form v-model="isValid">
      <v-row no-gutters class="mt-4">
        <v-col class="mobile__pl__0 pt-0" cols="12" sm="12">
          <h6 class="ma-0 font-14 text--101010">
            عمولة التسويق والتأجير (إختياري)
          </h6>
        </v-col>
        <v-col cols="12" sm="6" class="pl-4">
          <h6 />
          <div class="informativeCard mt-6">
            <div class="d-flex justify-between align-center w-100">
              <h6 class="ma-0 mr-1 font-12">تفعيل العمولة</h6>
              <v-switch
                color="primary"
                class="my-auto"
                dense
                hide-details
                inset
                small
                v-model="hasMarketingFees"
              />
            </div>
          </div>
        </v-col>
        <v-col class="mobile__pl__0 pl-4" cols="12" sm="6">
          <h6 class="ma-0 mb-1 text--737373">نوع العمولة</h6>
          <v-select
            :items="feesTypesItem"
            :disabled="!hasMarketingFees"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            :rules="
              hasMarketingFees ? [(v) => !!v || `اختر نوع العمولة`] : true
            "
            placeholder="نوع العموله"
            append-icon="mdi-chevron-down"
            dense
            filled
            item-text="nameAr"
            item-value="nameEn"
            v-model="contractInfo.feesType"
          />
        </v-col>
        <v-col class="mobile__pl__0 pt-0 pl-4" cols="12" sm="6">
          <h6 class="ma-0 mb-2 text--737373">
            مبلغ العمولة المحتسب من محصلات إيجار السنة الأولى
          </h6>
          <v-text-field
            :disabled="!hasMarketingFees"
            dense
            filled
            @input="handleValuesChanging($event, feesKeys)"
            @keydown="$formatNumberInputs($event, true)"
            :value="feesAmountMask"
            placeholder="مبلغ العمولة المحتسب من محصلات إيجار السنة الأولى"
            :suffix="contractInfo.feesType === 'percentage' ? '%' : 'ريال'"
            class="font-14"
            :rules="
              hasMarketingFees
                ? [
                    (v) =>
                      !!v ||
                      `ادخل نسبة
          العمولة`
                  ]
                : true
            "
          />
        </v-col>
        <v-col class="mobile__pl__0 pl-4" cols="12" sm="6">
          <h6 class="mb-2 mt-0 text--737373">طريقة تطبيق العمولة</h6>
          <v-select
            :disabled="!hasMarketingFees"
            :items="feesFrequencyItems"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            placeholder="طريقة تطبيق العمولة"
            append-icon="mdi-chevron-down"
            dense
            filled
            :rules="
              hasMarketingFees
                ? [(v) => !!v || `اختر طريقة تطبيق العمولة`]
                : true
            "
            item-text="nameAr"
            item-value="nameEn"
            v-model="contractInfo.feesFrequency"
          />
        </v-col>
      </v-row>
      <v-divider />
      <v-row no-gutters class="mt-4">
        <v-col class="mobile__pl__0 pt-0" cols="12" sm="12">
          <h6 class="ma-0 font-14">أتعاب إدارة الأملاك (إختياري)</h6>
        </v-col>
        <v-col cols="12" sm="6" class="pl-4">
          <h6 />
          <div class="informativeCard mt-5">
            <div class="d-flex justify-between align-center w-100">
              <h6 class="ma-0">تفعيل الأتعاب</h6>
              <v-switch
                color="primary"
                class="my-auto"
                dense
                hide-details="auto"
                inset
                small
                v-model="hasCommission"
              />
            </div>
          </div>
        </v-col>
        <v-col class="mobile__pl__0 pt-0 pl-4" cols="12" sm="6">
          <h6 class="ma-0 mb-1 text--737373">نسبة الشركة من المحصلات</h6>
          <v-text-field
            :disabled="!hasCommission"
            dense
            filled
            :rules="hasCommission ? [(v) => !!v || `اختر نسبة الشركة`] : true"
            placeholder="نسبة الشركة من المحصلات"
            suffix="%"
            class="font-14"
            @input="formatEnglihshNumber($event, 'percentage')"
            v-model="contractInfo.percentage"
            @keydown="$formatNumberInputs($event, true)"
          />
        </v-col>
        <v-col
          class="mobile__pl__0 pl-4"
          cols="12"
          sm="6"
          v-if="hasMarketingFees"
        >
          <h6 class="ma-0 mb-1 text--737373">طريقة حساب الأتعاب</h6>
          <v-select
            :disabled="!hasCommission"
            :items="pmFeeApproches"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            placeholder="طريقة حساب الأتعاب"
            append-icon="mdi-chevron-down"
            dense
            filled
            :rules="
              hasCommission && hasMarketingFees
                ? [(v) => !!v || `اختر طريقة حساب الأتعاب`]
                : true
            "
            item-text="nameAr"
            item-value="nameEn"
            v-model="contractInfo.feesAndCommissionDate"
          />
        </v-col>
      </v-row>
      <v-divider />
      <v-row no-gutters class="mt-4">
        <v-col class="mobile__pl__0 pt-0" cols="12" sm="12">
          <h6 class="ma-0 font-14">رسوم خدمات وتشغيل المرافق (إختياري)</h6>
        </v-col>
        <v-col cols="12" sm="6" class="pl-4">
          <h6 />
          <div class="informativeCard mt-5">
            <div class="d-flex justify-between align-center w-100">
              <h6 class="ma-0">تفعيل الرسوم</h6>
              <v-switch
                color="primary"
                class="my-auto"
                dense
                hide-details="auto"
                inset
                small
                v-model="hasServicesFees"
              />
            </div>
          </div>
        </v-col>
        <v-col class="mobile__pl__0 pt-0 pl-4" cols="12" sm="6">
          <h6 class="ma-0 mb-1 text--737373">مبلغ الرسوم السنوي</h6>

          <v-text-field
            :disabled="!hasServicesFees"
            dense
            filled
            :rules="
              hasServicesFees ? [(v) => !!v || `اختر مبلغ الرسوم السنوي`] : true
            "
            placeholder="مبلغ الرسوم السنوي"
            suffix="ريال"
            class="font-14"
            @input="handleValuesChanging($event, fixedKeys)"
            @keydown="$formatNumberInputs($event, true)"
            v-model="fixedMask"
          />
        </v-col>
        <v-col class="mobile__pl__0 lx-4" cols="12" sm="6">
          <h6 class="ma-0 mb-1 text--737373">طريقة تحصيل الرسوم</h6>
          <v-select
            :disabled="!hasServicesFees"
            :items="commesionPeriods"
            :menu-props="{
              transition: 'slide-y-transition',
              top: true,
              offsetY: true
            }"
            placeholder="طريقة تحصيل الرسوم"
            append-icon="mdi-chevron-down"
            dense
            filled
            :rules="
              hasServicesFees ? [(v) => !!v || `اختر طريقة تحصيل الرسوم`] : true
            "
            item-text="nameAr"
            item-value="value"
            v-model="contractInfo.commissionPeriod"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import inputMasking from '@/mixins/inputMasking.js'

import { convertNumbers2English } from '@/utils/formatters'
export default {
  name: 'contractPmAdditionalDetails',
  props: {
    contractIntervals: {
      default: []
    },
    envHasCommissionTax: {
      type: Boolean,
      default: false
    }
  },
  mixins: [inputMasking],
  data() {
    return {
      contractInfo: {},
      isValid: false,
      fixedMask: '',
      feesAmountMask: '',
      hasCommission: false,
      hasServicesFees: false,
      hasMarketingFees: false,
      feesFrequencyItems: [
        {
          nameAr: 'مع اول مستاجر فقط',
          nameEn: 'once'
        },
        {
          nameAr: 'مع كل مستأجر جديد',
          nameEn: 'recurrent'
        }
      ],
      feesTypesItem: [
        {
          nameAr: 'نسبة',
          nameEn: 'percentage'
        },
        {
          nameAr: 'مبلغ ثابت',
          nameEn: 'fixed'
        }
      ],
      pmFeeApproches: [
        {
          nameAr: 'مع حساب عمولة التسويق والتأجير',
          nameEn: 'start'
        },
        {
          nameAr: 'بعد حساب عمولة التسويق والتأجير',
          nameEn: 'end'
        }
      ]
    }
  },
  computed: {
    commesionPeriods() {
      return this.contractIntervals.filter(
        (interval) => interval.value !== 0.0333 && interval.value !== 2
      )
    },
    fixedKeys() {
      return {
        maskValKey: 'fixedMask',
        parentKey: 'contractInfo',
        actualValKey: 'fixed'
      }
    },
    feesKeys() {
      return {
        maskValKey: 'feesAmountMask',
        parentKey: 'contractInfo',
        actualValKey: 'feesAmount'
      }
    }
  },
  watch: {
    isValid(val) {
      this.$emit('setValdationStatus', val)
    },
    hasMarketingFees: {
      handler(val) {
        if (!val) {
          delete this.contractInfo.feesType
          delete this.contractInfo.feesAmount
          delete this.contractInfo.feesFrequency
        }
      }
    },
    hasCommission: {
      handler(val) {
        if (!val) {
          delete this.contractInfo.percentage
          delete this.contractInfo.feesAndCommissionDate
        }
      }
    },
    hasServicesFees: {
      handler(val) {
        if (!val) {
          delete this.contractInfo.fixed
          delete this.contractInfo.commissionPeriod
        }
      }
    }
  },
  methods: {
    bindContractData() {
      this.$emit('bindContractData', this.contractInfo)
    },
    presetData() {
      this.$emit('setValdationStatus', this.isValid)
      this.$emit('setLastTabStatus', true)
    },
    formatEnglihshNumber(val, key) {
      this.contractInfo[key] = Number(convertNumbers2English(val))
    }
  },
  created() {
    this.hasCommission =
      this.hasServicesFees =
      this.hasMarketingFees =
        this.envHasCommissionTax
  }
}
</script>
