<template>
  <v-row
    no-gutters
    class="calc__bundle flex-column font-12 mt-5 font-weight-medium"
  >
    <v-col class="d-flex justify-between mb-2">
      <span class="font-weight-black" v-text="data.titleBundlePrice" />
      <span
        ><span class="font-weight-black">{{
          data.bundlePrice | formatNumber
        }}</span>
        ريال سعودي</span
      >
    </v-col>
    <v-col class="d-flex justify-between mb-2" v-if="data.couponObj.percentage">
      <span class="font-weight-black"
        >كوبون الخصم
        <span class="discount">({{ data.couponObj.percentage }}%)</span></span
      >
      <span class="discount"
        >-
        <span class="font-weight-black">{{
          data.couponObj.deductedAmount | formatNumber
        }}</span>
        ريال سعودي</span
      >
    </v-col>
    <v-col class="d-flex justify-between mb-2">
      <span class="font-weight-black">
        ضريبة القيمة المضافة ({{ data.percentage }}%)
      </span>
      <span>
        <span class="font-weight-black">
          {{ data.amount | formatNumber }}
        </span>
        ريال سعودي
      </span>
    </v-col>

    <!-- TOTAL -->
    <v-col class="d-flex justify-between align-center border-y py-2 font-14">
      <h6 class="font-weight-bold text-black font-14">
        الإجمالي
        <span class="font-weight-light calc__bundle font-12"
          >(السعر شامل ضريبة القيمة المضافة)</span
        >
      </h6>
      <span class="text-black font-weight-bold"
        >{{ data.total | formatNumber }} ريال سعودي</span
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'CalculationBundle',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  filters: {
    formatNumber(val) {
      return val
        .toLocaleString('en-US', { minimumFractionDigits: 0 })
        .replace(/,/g, ',')
    }
  }
}
</script>

<style lang="scss" scoped>
.calc__bundle {
  color: #737373;
}
.discount {
  color: #4fb3f9;
}
.text-black {
  color: #101010;
}
.border-y {
  border: 1px solid;
  border-color: #efebf2 transparent #efebf2 transparent;
}
</style>
