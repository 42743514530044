<template>
  <div class="mt-2">
    <div v-if="!totalReceivedDeposit" class="d-flex flex-column text-center">
      <span>لا توجد عُهدة مستلمة بعد</span>
      <span class="mt-1 deposit-no-text font-14">
        لإضافة عُهدة جديدة ,قم بإنشاء سند قبض من نوع عُهدة
      </span>
    </div>
    <div
      v-else
      class="d-flex justify-space-between align-center font-14 deposit-text px-5"
    >
      <span>قيمة العُهدة المستملة </span>
      <div class="d-flex align-center deposit-value font-12">
        {{ totalReceivedDeposit }} ريال
      </div>
    </div>
  </div>
</template>

<script>
import { realEstateService } from '@/services'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'ReceivedDeposit',
  props: {
    realEstateId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      totalReceivedDeposit: 0
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getReceivedDeposit() {
      const query = {
        realestate: this.realEstateId,
        type: 'depositReceivedFromOwner',
        environment: this.currentEnv?._id
      }
      realEstateService
        .getReceivedDeposit(query)
        .then(({ data }) => {
          this.totalReceivedDeposit = data.total
        })
        .catch(() => {
          this.addNotification({
            text: 'خطأ في تحميل العُهده المستلمة',
            color: 'error'
          })
        })
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv'])
  },
  created() {
    this.getReceivedDeposit()
  }
}
</script>

<style lang="scss" scoped>
.deposit {
  &-no-text {
    color: #9f9fac;
  }
  &-text {
    color: #3e3e3e;
  }
  &-value {
    height: 24px;
    color: #662d91;
    padding: 15px 15px;
    background: rgba(102, 45, 145, 0.1);
    border-radius: 47px;
  }
}
</style>
