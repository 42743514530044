<template>
  <div class="mt-5">
    <modelUint
      v-for="template in templates"
      :key="template._id"
      :template="template"
      :icon="modelsIcons[template.type]"
      @setTemplateBody="setTemplateBody"
    />
    <modelsEdit
      :dialogState="dialogState"
      :data="template"
      @changeDialogState="changeDialogState"
      @changeData="changeTemplate"
    />
  </div>
</template>

<script>
import modelUint from './modelUint'
import modelsEdit from './modelsEdit'
import { templatesService } from '@/services'

export default {
  name: 'contractsModels',
  components: { modelUint, modelsEdit },
  data() {
    return {
      dialogState: false,
      templates: [],
      template: {},
      modelsIcons: {
        rent: require('@/assets/img/svgs/models/rent.svg'),
        maintenance: require('@/assets/img/svgs/models/maintenance.svg'),
        pm: require('@/assets/img/svgs/models/properties-management.svg')
      }
    }
  },
  methods: {
    async getTemplate() {
      try {
        const { data } = await templatesService.getAllTemplates()
        this.templates = data.template
      } catch {}
    },
    setTemplateBody(template) {
      this.dialogState = true
      this.template = template
    },
    changeDialogState(dialogState) {
      this.dialogState = dialogState
    },
    changeTemplate(template) {
      this.template = template
    }
  },
  created() {
    this.getTemplate()
  }
}
</script>
