<template>
  <v-dialog width="750px" persistent v-model="contractModalDialogState_">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="py-0 pr-5 pl-md-1 pl-3 mb-4 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0 font-16">
          {{ tabsTitles[tab] }}
        </h4>
        <v-btn
          @click.prevent="contractModalDialogState_ = false"
          data-test="close-contract"
          class="ma-0 mt-1"
          color="grey"
          fab
          text
        >
          <v-icon class="font-20">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Contract Modal Tabs-->
      <v-tabs-items class="pa-3 pt-0 overflow-y-scroll" v-model="tab" touchless>
        <v-tab-item>
          <v-form v-model="isValidFormTab0">
            <v-row no-gutters>
              <v-col cols="12" md="6" class="px-1 py-2">
                <h6 class="mt-4 mb-2">إختر تاريخ إخلاء الوحده الإيجاريه</h6>
                <v-dialog
                  :return-value.sync="rejection.evacDate"
                  ref="evacuationDate"
                  v-model="dateAtModal"
                  width="320px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :rules="
                        !rejection.daysAreCounted
                          ? [
                              (v) =>
                                !!v ||
                                'من فضلك ادخل تاريخ إخلاء الوحده الإيجاريه'
                            ]
                          : [true]
                      "
                      append-icon="mdi-calendar-month-outline"
                      dense
                      filled
                      hide-details
                      placeholder="الرجاء إختيار تاريخ إخلاء المستأجر للوحده الإيجاريه"
                      readonly
                      required
                      v-bind="attrs"
                      v-model="rejection.evacDate"
                      v-on="on"
                      class="date--dropdown font-14"
                    />
                  </template>
                  <v-date-picker
                    color="primary"
                    scrollable
                    :min="contract.start"
                    v-model="date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn @click="dateAtModal = false" color="primary" text>
                      الغاء
                    </v-btn>
                    <v-btn
                      @click="$refs.evacuationDate.save(date)"
                      color="primary"
                      text
                      >حفظ
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6" class="pr-1 pl-4 py-2">
                <h6 class="mt-4 mb-2">عدد الأيام المتبقيه للإخلاء</h6>
                <div
                  class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
                >
                  <!-- countedDays -->
                  <span>{{ differenceInDays }}</span>
                  <span>أيام</span>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="px-1 py-2">
                <h6 class="mt-0 mb-2">
                  {{ terminationReason }}
                </h6>
                <v-select
                  :rules="[(v) => (v && !!v.length) || '']"
                  :items="rejectionsList"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  placeholder="الرجاء إختيار سبب إلغاء تجديد العقد"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="title"
                  item-value="code"
                  required
                  v-model="rejection.reason"
                />
              </v-col>
              <v-col cols="12" md="6" class="px-1 py-2">
                <h6 class="ma-0 mb-2">
                  <!-- هل هناك فتره إضافيه تود إحتسابها علي المستأجر ؟ -->
                  هل تود إصدار مطالبات مالية للفترة المتبقيه للإخلاء
                </h6>
                <v-card
                  :disabled="!differenceInDays"
                  class="informativeCard pa-1 ml-3 pb-2"
                >
                  <div class="holder d-flex align-center pr-2">
                    <div class="d-flex justify-between align-center w-100">
                      <p class="headTitle font-14 font-weight-bold ma-0">
                        {{ rejection.daysAreCounted ? 'نعم' : 'لا' }}
                      </p>
                      <v-switch
                        class="my-auto"
                        dense
                        hide-details="auto"
                        inset
                        small
                        v-model="rejection.daysAreCounted"
                      />
                    </div>
                  </div>
                </v-card>
              </v-col>

              <!-- <v-col cols="12" md="3" class="pr-1 py-2">
                <h6 class="mt-4 mb-2">عدد الأيام المحتسبه</h6>
                <div
                  class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
                >
                  <span>{{ DifferenceInTime(this.contract.end) }}</span>
                  <span>أيام</span>
                </div>
              </v-col> -->
              <!-- <v-col cols="12" md="3" class="pr-1 pl-4 py-2">
                <h6 class="mt-4 mb-2">عدد الأيام المتبقيه</h6>
                <div
                  class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
                >
                  <span>{{ DifferenceInTime(this.rejection.evacDate) }}</span>
                  <span>أيام</span>
                </div>
              </v-col> -->
              <v-col cols="12" class="px-1 py-2">
                <v-card class="informativeCard px-1 py-4 mt-4">
                  <div class="holder d-flex align-center">
                    <div class="mx-3">
                      <img
                        height="22"
                        :lazy-src="exclamation"
                        :src="exclamation"
                      />
                    </div>
                    <div class="text">
                      <span>
                        <!-- <p
                          v-if="rejection.daysAreCounted"
                          class="headTitle font-14 font-weight-bold ma-0"
                        >
                          سيتم إصدار المستحقات المطلوبة إلى تاريخ إخلاء العين
                          العقارية
                        </p> -->
                        <!-- v-else -->
                        <p class="headTitle font-14 font-weight-bold ma-0">
                          سيتم الإبقاء على كل الدفعات المستحقة على المستأجر
                          وإضافة دفعات مستحقة جديدة إن وجدت
                        </p>
                      </span>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
        <v-tab-item>
          <v-form v-model="isValidFormTab1">
            <v-row no-gutters>
              <v-col cols="12" md="6" class="px-1 py-2">
                <h6 class="ma-0 mb-2">هل تود احتساب مطالبات إضافيه ؟</h6>
                <v-card class="informativeCard pa-1 ml-3 pb-2">
                  <div class="holder d-flex align-center pr-2">
                    <div class="d-flex justify-between align-center w-100">
                      <p class="headTitle font-14 font-weight-bold ma-0">
                        {{ rejection.damageIsCounted ? 'نعم' : 'لا' }}
                      </p>
                      <v-switch
                        class="my-auto"
                        dense
                        hide-details="auto"
                        inset
                        small
                        v-model="rejection.damageIsCounted"
                      />
                    </div>
                  </div>
                </v-card>
              </v-col>
              <v-col class="px-1 py-2" cols="12" sm="6">
                <h6 class="mt-0 mb-2">
                  إضافه المطالبات لحساب المالك أم المستأجر؟
                </h6>
                <v-select
                  :items="ownerOrRenter"
                  :rules="
                    rejection.damageIsCounted
                      ? [(v) => (v && !!v.length) || '']
                      : [true]
                  "
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  placeholder="اختر واحد منها"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="nameAr"
                  item-value="value"
                  required
                  v-model="rejection.payer"
                  :disabled="!rejection.damageIsCounted"
                />
              </v-col>
            </v-row>
            <v-divider class="my-5" />
            <v-row
              no-gutters
              v-for="(damageType, key) in damagesTypes"
              :key="key"
            >
              <v-col class="px-1 py-2" cols="12" sm="4">
                <div class="d-flex justify-space-between">
                  <h6 class="mt-0 mb-2">
                    {{ damageType.title }}
                  </h6>
                </div>
                <v-text-field
                  class="pb-2"
                  dense
                  filled
                  hide-details
                  :placeholder="damageType.title"
                  v-model.number="rejection[`${key}Damage`]"
                  :disabled="!rejection.payer"
                  @input="updateTotalValue(`${key}Damage`, $event)"
                  @keydown="$formatNumberInputs($event, true)"
                />
              </v-col>
              <v-col class="px-1 py-2" cols="12" sm="4">
                <h6 class="mt-0 mb-2">نسبه الضريبه</h6>
                <v-select
                  :items="taxPercentages"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  placeholder="فتره التحصيل"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="name"
                  item-value="value"
                  required
                  v-model.number="rejection[`${key}DamageTax`]"
                  :disabled="!rejection.payer"
                  @change="updateTotalValue(`${key}DamageTax`, $event)"
                />
              </v-col>
              <v-col class="px-1 py-2" cols="12" sm="4">
                <h6 class="mt-0 mb-2">القيمه الكليه لخدمه الكهرباء</h6>
                <v-text-field
                  class="pb-2"
                  dense
                  filled
                  hide-details
                  placeholder="القيمه الكليه لخدمه الكهرباء"
                  suffix="ريال"
                  v-model.number="rejection[`${key}DamageTotal`]"
                  :disabled="
                    !rejection.payer ||
                    (!rejection[`${key}DamageTax`] &&
                      rejection[`${key}DamageTax`] != 0.0)
                  "
                  @input="updateTotalValue(`${key}DamageTotal`, $event)"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>
        <v-tab-item v-if="!ispendingRenewalContract">
          <tabTableDetails
            :items="nextInstallments"
            :headers="headers"
            :noData="noDataConfig"
          />
        </v-tab-item>
      </v-tabs-items>

      <v-card-actions class="px-4 py-3">
        <v-btn
          v-if="tab > 0"
          @click="previousTab"
          class="ma-0 rounded-7 font-14"
          color="grey"
        >
          <v-icon class="font-18">mdi-arrow-right</v-icon>
          <span class="ml-1 font-weight-bold font-14">السابق</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="handleNextTab"
          class="ma-0 rounded-7 my-3 ml-3 px-8"
          color="primary"
          :disabled="
            !ispendingRenewalContract && tab === 1
              ? false
              : !isValidForms[`tab${tab}`]
          "
        >
          <span class="ml-1 font-weight-bold">
            {{
              (!ispendingRenewalContract && tab > 1) ||
              (ispendingRenewalContract && tab === 1)
                ? 'تأكيد'
                : 'التالي'
            }}
          </span>
          <v-icon class="font-18" v-if="tab === 0"> mdi-arrow-left </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tabTableDetails from '@/components/contracts/SideDetails/tabTableDetails.vue'
import { bondsService } from '@/services'
import inputMasking from '@/mixins/inputMasking.js'
import { mapMutations } from 'vuex'
import { convertNumbers2English } from '@/utils/formatters'
export default {
  name: 'cancelContractModal',
  components: {
    tabTableDetails
  },
  props: {
    contract: {
      require: true,
      default() {
        return {}
      }
    },
    contractModalState: {
      required: true,
      type: Boolean
    },
    cancellationReasons: {
      type: Array,
      default() {
        return []
      }
    }
  },
  mixins: [inputMasking],
  data() {
    return {
      nextInstallments: [],
      headers: [
        {
          text: 'نوع المطالبة',
          sortable: false,
          value: 'installmentType',
          align: 'center'
        },
        {
          text: 'القيمه',
          sortable: false,
          value: 'payment',
          align: 'center'
        },
        {
          text: 'الضريبه',
          sortable: false,
          value: 'taxAmount',
          align: 'center'
        },
        {
          text: 'المجموع',
          sortable: false,
          value: 'total',
          align: 'center'
        },
        {
          text: 'تاريخ المطالبه',
          sortable: false,
          value: 'date',
          align: 'center'
        }
      ],
      isValidFormTab0: false,
      isValidFormTab1: false,
      tab: 0,
      rejection: {},
      dateAtModal: false,
      exclamation: require('@/assets/img/svgs/exclamationMark.svg'),
      date: new Date().toISOString().substr(0, 10),
      taxPercentages: [
        {
          name: '%0',
          value: 0.0
        },
        {
          name: '%5',
          value: 0.05
        },
        {
          name: '%15',
          value: 0.15
        }
      ],
      ownerOrRenter: [
        {
          value: 'owner',
          nameAr: 'مالك'
        },
        {
          value: 'renter',
          nameAr: 'مستأجر'
        }
      ],
      damagesTypes: {
        paint: {
          Damage: 0,
          Tax: 0,
          status: false,
          Total: 0,
          title: 'تلف دهان السقف والجدران'
        },
        rooms: {
          Damage: 0,
          Tax: 0,
          status: false,
          Total: 0,
          title: 'تلف الغرف و الملاحق'
        },
        wc: {
          Damage: 0,
          Tax: 0,
          status: false,
          Total: 0,
          title: 'تلف الحمام والأطقم الصحيه'
        },
        kitchen: {
          Damage: 0,
          Tax: 0,
          status: false,
          Total: 0,
          title: 'تلف المطبخ'
        },
        lights: {
          Damage: 0,
          Tax: 0,
          status: false,
          Total: 0,
          title: 'تلف الإضاءات'
        },
        plumbing: {
          Damage: 0,
          Tax: 0,
          status: false,
          Total: 0,
          title: 'تلف السباكه والتمديدات'
        },
        tank: {
          Damage: 0,
          Tax: 0,
          status: false,
          Total: 0,
          title: 'تلف خزان المياه'
        }
      }
    }
  },
  computed: {
    noDataConfig() {
      return {
        noDataText: `ليس هناك أي دفعات متبقيه مستحقه بناءا علي معلومات الإخلاء المذكوره`,
        noDataSubTitle: '',
        isbgPrimary: false
      }
    },
    dataToSendPreview() {
      return {
        ...(this.rejection.daysAreCounted && {
          countedDays: this.differenceInDays
        }),
        paintDamage: this.rejection.paintDamage,
        roomsDamage: this.rejection.roomsDamage,
        wcDamage: this.rejection.wcDamage,
        kitchenDamage: this.rejection.kitchenDamage,
        lightsDamage: this.rejection.lightsDamage,
        plumbingDamage: this.rejection.plumbingDamage,
        tankDamage: this.rejection.tankDamage,
        paintDamageTax: this.rejection.paintDamageTax,
        roomsDamageTax: this.rejection.roomsDamageTax,
        wcDamageTax: this.rejection.wcDamageTax,
        kitchenDamageTax: this.rejection.kitchenDamageTax,
        lightsDamageTax: this.rejection.lightsDamageTax,
        plumbingDamageTax: this.rejection.plumbingDamageTax,
        tankDamageTax: this.rejection.tankDamageTax,
        paintDamageTotal: this.rejection.paintDamageTotal,
        tankDamageTotal: this.rejection.tankDamageTotal,
        roomsDamageTotal: this.rejection.roomsDamageTotal,
        wcDamageTotal: this.rejection.wcDamageTotal,
        kitchenDamageTotal: this.rejection.kitchenDamageTotal,
        lightsDamageTotal: this.rejection.lightsDamageTotal,
        plumbingDamageTotal: this.rejection.plumbingDamageTotal,
        evacDate: this.rejection.evacDate
      }
    },
    isActiveContract() {
      return this.contract?.status === 'active'
    },
    terminationReason() {
      return this.isActiveContract ? 'سبب إنهاء العقد' : 'سبب إلغاء التجديد'
    },
    tabsTitles() {
      return [
        this.modalTitle,
        '',
        'مراجعه الوحده الايجاريه للاستلام والاخلاء (اختياري) '
      ]
    },
    modalTitle() {
      return this.ispendingRenewalContract ? 'إلغاء تجديد العقد' : 'إنهاء العقد'
    },
    ispendingRenewalContract() {
      return this.contract.status === 'pendingRenewal'
    },
    rejectionsList() {
      return this.contract.status === 'pendingRenewal'
        ? this.cancellationReasons.filter(
            (reason) => reason.category === 'renewal' || !reason.category
          )
        : this.cancellationReasons.filter(
            (reason) => reason.category === 'termination' || !reason.category
          )
    },
    isValidForms() {
      return {
        tab0: this.isValidFormTab0,
        tab1: this.isValidFormTab1,
        tab2: this.isValidFormTab1
      }
    },
    contractModalDialogState_: {
      get() {
        return this.contractModalState
      },
      set(value) {
        this.$emit('closeCancelContractModal', value)
      }
    },
    differenceInDays() {
      return this.DifferenceInTime(this.rejection.evacDate)
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    async terminatedPreview() {
      try {
        const {
          data: { installments }
        } = await bondsService.getTerminateContractPreview({
          environment: this.contract?.environment,
          id: this.contract?._id,
          ...this.dataToSendPreview
        })
        this.nextInstallments = installments
      } catch {
        this.addNotification({
          text: `حدث خطأ ما`,
          color: 'error'
        })
      }
    },
    updateTotalValue(key, value) {
      if (key.endsWith('Damage')) {
        this.updateDamage(key, value)
      } else if (key.endsWith('Total')) {
        this.updateTotal(key, value)
      } else if (key.endsWith('DamageTax')) {
        this.updateTax(key, value)
      }

      this.calculateTotal(key)
    },

    updateDamage(key, value) {
      const formattedValue = Number(convertNumbers2English(value))
      this.$set(this.rejection, `${key}`, formattedValue)
    },

    updateTotal(key, value) {
      const formattedValue = Number(convertNumbers2English(value))
      const baseKey = this.extractBaseKey(key)
      this.$set(this.rejection, `${baseKey}DamageTotal`, formattedValue)

      const totalValue = (
        formattedValue /
        (Number(this.rejection[`${baseKey}DamageTax`]) + 1)
      ).toFixed(2)
      this.$set(this.rejection, `${baseKey}Damage`, Number(totalValue))
    },

    updateTax(key, value) {
      this.$set(this.rejection, `${key}`, Number(value))
    },

    calculateTotal(key) {
      const baseKey = this.extractBaseKey(key)
      const damage = this.rejection[`${baseKey}Damage`] || 0
      const tax = this.rejection[`${baseKey}DamageTax`] || 0

      const totalValue = (damage * (1 + tax)).toFixed(2)
      this.$set(this.rejection, `${baseKey}DamageTotal`, Number(totalValue))
    },
    extractBaseKey(key) {
      // capitalize first letter of the key
      const rgx = /(?<!^)(?=[A-Z])/g
      const newKey = key.replace(rgx, ' ')
      return newKey.split(' ')[0]
    },
    async handleNextTab() {
      if (this.ispendingRenewalContract && this.tab === 1) {
        return this.tab === 1 ? this.onTerminate() : this.nextTab()
      }
      if (this.tab === 1) {
        await this.terminatedPreview()
      }
      return this.tab === 2 ? this.onTerminate() : this.nextTab()
    },
    onTerminate() {
      this.$emit('onTerminate', {
        contractId: this.contract?._id,
        isIncluded: true,
        extraBody: this.rejection
      })
    },
    DifferenceInTime(date) {
      const date1 = new Date()
      const date2 = new Date(date)
      const DifferenceInTime = date2.getTime() - date1.getTime()
      return date && Math.round(DifferenceInTime / (1000 * 3600 * 24)) > 0
        ? Math.round(DifferenceInTime / (1000 * 3600 * 24))
        : 0
    },
    nextTab() {
      this.tab++
    },
    previousTab() {
      this.tab--
    }
  },
  created() {
    this.$emit('getCancellationReasons')
    this.date = new Date(this.contract.start).toISOString().substr(0, 10)
  }
}
</script>
