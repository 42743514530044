<template>
  <div class="overflow-y pb-4">
    <div v-if="!uniqueTenet.length">
      <h5
        class="font-weight-medium ma-0 mr-1 text-truncate font-16 d-flex justify-center"
      >
        لا يوجد مستاجرين
      </h5>
    </div>
    <div
      v-for="(user, i) in uniqueTenet"
      :key="i"
      class="d-flex justify-space-between align-center w-100 mb-4 px-4"
    >
      <div class="client-name">
        <router-link
          v-if="user._id && !isCustomer"
          :to="`/dashboard/client/${user._id}`"
          target="_blank"
        >
          <h5
            class="font-weight-medium ma-0 mr-1 text-truncate font-16"
            style="max-width: 250px"
          >
            {{ user.name }}
          </h5>
        </router-link>
        <h5
          v-else
          class="font-weight-medium ma-0 mr-1 text-truncate font-16"
          style="max-width: 250px"
        >
          {{ user.name }}
        </h5>
      </div>

      <div class="d-flex" v-if="user._id">
        <a
          :href="`tel:+${user.phoneNumber}`"
          class="cursor d-flex green--tag tabel-tag-sm py-2 px-3"
          target="_blank"
        >
          <img
            src="@/assets/img/svgs/telephone.svg"
            alt="telephone"
            loading="lazy"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ownerList',
  props: {
    propertyParties: {}
  },
  data() {
    return {
      fileIcons: {
        txt: require('@/assets/img/svgs/txt-file-icon.svg'),
        pdf: require('@/assets/img/svgs/pdf-file-icon.svg')
      }
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    uniqueTenet() {
      const jsonObj = new Set(this.propertyParties.map(JSON.stringify))
      return [...jsonObj].map(JSON.parse)
    }
  }
}
</script>

<style lang="scss" scoped>
.client-name a {
  color: black !important;
}

.overflow-y {
  overflow-y: auto;
  height: 30vh;
}
</style>
