<template>
  <v-card class="cardExceedLimit my-6 py-1 px-4">
    <div class="holder d-flex align-center">
      <div class="mt-2 mr-3 ml-5">
        <img class="vert-move" width="40" :src="arrowUpTag" loading="lazy" />
      </div>
      <div class="text">
        <span>
          <p class="headTitle font-16 font-weight-bold ma-0">
            {{ title }}
          </p>
          <p class="subTitle font-14 ma-0 mt-1">
            {{ subTitle }}
          </p>
        </span>
      </div>
      <v-spacer />
      <div class="btn mr-3 ml-1">
        <!-- For contact with us -->
        <v-btn
          v-if="isGoldenBundle"
          @click="dialogState = !dialogState"
          elevation="0"
          depressed
          color="primary"
        >
          <span class="mb-1 mx-1"> تواصل معنا </span>
        </v-btn>

        <!-- For upgrade package -->
        <button
          v-else-if="isAnOwnerAccount"
          @click="$emit('changeTab')"
          class="black--text"
        >
          <v-btn elevation="0" depressed color="primary">
            <span class="mb-1 mx-1"> ترقيه الباقه </span>
          </v-btn>
        </button>
      </div>

      <!------------------------->
      <!-- Contact sales model -->
      <!------------------------->

      <contactSalesModel
        v-if="dialogState"
        v-bind="{ dialogState }"
        @changeDialogState="dialogState = $event"
        @closed-add-dialog="dialogState = false"
      />
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    title: { default: '' },
    subTitle: { default: '' }
  },
  data() {
    return {
      arrowUpTag: require('@/assets/img/svgs/arrowUpTag.svg'),
      dialogState: false
    }
  },
  components: {
    contactSalesModel: () => import('@/components/modals/contactSalesModel.vue')
  },
  computed: {
    ...mapState('accountState', ['subscription', 'currentEnv']),
    isGoldenBundle() {
      return this.subscription.bundle.nameEn === 'Golden bundle'
    },
    isAnOwnerAccount() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    }
  }
}
</script>

<style lang="scss">
.vert-move {
  -webkit-animation: mover 0.6s infinite alternate;
  animation: mover 0.6s infinite alternate;
}
.vert-move {
  -webkit-animation: mover 0.6s infinite alternate;
  animation: mover 0.6s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-8px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-8px);
  }
}

.cardExceedLimit {
  background: #ffffff;
  border: 1px solid #efebf2;
  border-radius: 7px;
  .holder {
    .text {
      .headTitle {
        color: #101010;
      }
      .subTitle {
        color: #737373;
      }
    }
    .btn {
      .v-btn {
        border-radius: 50px;
      }
    }
  }
}
</style>
