import { notificationService } from '@/services'
import store from '@/store'
import { mapState, mapGetters } from 'vuex'

const notificationsPage = {
  data() {
    return {
      notificationLoading: false,
      notifications: [],
      notificationsDeleted: [],
      notificationsCount: 0,
      pagination: {
        pageNumber: 1,
        pagesCount: 1,
        pageSize: 6,
        count: 0
      }
    }
  },
  mounted() {
    this.getNotifications()
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    paginationCalc() {
      return Math.ceil(this.notificationsCount / this.pagination.pageSize)
    }
  },
  watch: {
    tab(nValue) {
      if (nValue === 0) {
        this.getNotifications()
      }
      if (nValue === 1) {
        this.getNotificationsDeleted()
      }
      this.pagination.pageNumber = 1
    }
  },
  methods: {
    paginationFunction() {
      return this.tab === 0
        ? this.getNotifications(this.pagination.pageNumber)
        : this.getNotificationsDeleted(this.pagination.pageNumber)
    },
    async getNotifications(pageNumber) {
      this.notificationLoading = true
      try {
        const notificationQuery = {
          context: {
            platform: 'hectarPlus'
          },
          user: {
            _id: this.user._id
          },
          pageSize: this.pagination.pageSize,
          pageNumber
        }
        const { data } = await notificationService.getNotifications(
          notificationQuery
        )
        this.notifications = data.items
        this.notificationsCount = data.totalCount
        this.pagination.pagesCount = this.paginationCalc
      } catch {
        this.addNotification({
          text: 'خطا في تحميل الاشعارات يرجى المحاولة مره اخرى'
        })
      } finally {
        this.notificationLoading = false
      }
    },
    async getNotificationsDeleted(pageNumber) {
      this.notificationLoading = true
      try {
        const notificationQuery = {
          context: {
            platform: 'hectarPlus'
          },
          user: {
            _id: this.user._id
          },
          pageSize: this.pagination.pageSize,
          isDeleted: true,
          pageNumber
        }
        if (this.isCustomer) {
          notificationQuery.environmentId = this.currentEnv._id
        }
        const { data } = await notificationService.getNotifications(
          notificationQuery
        )
        this.notificationsDeleted = data.items
        this.notificationsCount = data.totalCount
        this.pagination.pagesCount = this.paginationCalc
      } catch {
        this.addNotification({
          text: 'خطا في تحميل الاشعارات يرجى المحاولة مره اخرى'
        })
      } finally {
        this.notificationLoading = false
      }
    },
    async addNewNotification() {
      const query = {
        title: 'الحادي عشر',
        body: 'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز',
        context: {
          platform: 'hectarPlus'
        },
        user: this.user
      }
      await notificationService.addNotification(query)
    },
    async updateNotificationAsRead(id) {
      try {
        await notificationService.updateNotification(id)
        this.getNotifications(this.pagination.pageNumber)
      } catch {
        this.addNotification({
          text: 'خطا يرجى المحاولة مره اخرى'
        })
      }
    },
    async deleteNotification(id) {
      try {
        await notificationService.deleteNotification(id)
        this.notifications = this.notifications.filter((cur) => cur._id !== id)
        this.notificationsCount--
        if (this.notifications.length === 0) {
          if (this.pagination.pageNumber > 1) {
            this.getNotifications(--this.pagination.pageNumber)
          } else {
            this.getNotifications(++this.pagination.pageNumber)
          }
        }
      } catch {
        this.addNotification({
          text: 'خطا يرجى المحاولة مره اخرى'
        })
      }
    }
  }
}

const notificationsDropdown = {
  data() {
    return {
      notifications: [],
      pagination: {
        pageNumber: 1,
        pageSize: 7
      },
      isMessage: false
    }
  },
  mounted() {
    this.getNotifications()
    // Implementation REALTIME with SOCKET
    this.$socket.$subscribe(this.user._id, (notifi) => {
      this.notifications.unshift(notifi)
      if (notifi.title !== 'إظهار العقار') {
        store.commit('appState/realTimeNotification', {
          text: notifi.body,
          icon: notifi.context.serviceName
        })
      }
      if (!this.isMessage && notifi.context.serviceName === 'messages') {
        this.isMessage = true
      }
    })
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    ifUnread() {
      return this.notifications.slice(0, 5).some((notifi) => !notifi.isRead)
    }
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async getNotifications() {
      try {
        const notificationQuery = {
          context: {
            platform: 'hectarPlus'
          },
          user: {
            _id: this.user._id
          },
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber
        }
        const { data } = await notificationService.getNotifications(
          notificationQuery
        )
        this.notifications.push(...data.items)
        return data.items
      } catch {
        this.addNotification({
          text: 'خطا في تحميل الاشعارات يرجى المحاولة مره اخرى'
        })
      }
    },
    async updateNotificationAsRead(id, index) {
      try {
        await notificationService.updateNotification(id)
        this.notifications[index].isRead = true
      } catch {
        this.addNotification({
          text: 'خطا يرجى المحاولة مره اخرى'
        })
      }
    },
    async deleteNotification(id, index) {
      try {
        await notificationService.deleteNotification(id)
        this.notifications.splice(index, 1)
        this.pagination.pageNumber++
        this.getNotifications()
      } catch {
        this.addNotification({
          text: 'خطا يرجى المحاولة مره اخرى'
        })
      }
    },
    async infiniteScroll($state) {
      try {
        if (this.pagination.pageNumber === 1) {
          this.pagination.pageNumber = 2
        }
        const notifications = await this.getNotifications()
        if (notifications.length > 0) {
          this.pagination.pageNumber++
          $state.loaded()
        } else {
          $state.complete()
        }
      } catch {
        this.addNotification({
          text: 'خطا في تحميل باقي الاشعارات يرجى المحاولة مره اخرى'
        })
        $state.complete()
      }
    }
  }
}

export { notificationsPage, notificationsDropdown }
