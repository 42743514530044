<template>
  <v-dialog max-width="850" persistent :value="dialogState_">
    <v-card class="add-new-property">
      <v-card-title
        class="cardTitle pa-0 px-4 mb-4 d-flex justify-space-between custom__shadow"
      >
        <h4>ارسال الدعوة</h4>
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs-items v-model="tab" class="mt-6">
        <v-tab-item>
          <!-- Details -->
          <v-card-text class="pa-0">
            <v-form ref="form" v-model="isValid">
              <v-container class="pa-0 px-6">
                <v-row no-gutters>
                  <v-col class="pl-3" cols="12" sm="6">
                    <h6 class="mb-2 mt-0">الاسم*</h6>
                    <v-text-field
                      dense
                      filled
                      min
                      :value="customer.name"
                      :disabled="true"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pl-3" cols="12" sm="6">
                    <h6 class="mb-2 mt-0">رقم الجوال*</h6>
                    <v-text-field
                      dense
                      filled
                      min
                      :value="customer.phoneNumber"
                      type="number"
                      suffix="+996"
                      :disabled="true"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pl-3" cols="12" sm="6">
                    <h6 class="mb-2 mt-0">البريد الإلكتروني</h6>
                    <v-text-field
                      dense
                      filled
                      placeholder="البريد الإلكتروني"
                      v-model="email"
                      :disabled="!!customer?.email?.length"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-1 mt-4">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!isValid"
              :loading="isLoading"
              @click="inviteClient"
              class="ma-0 rounded-7 my-3 ml-3"
              color="primary"
            >
              <span class="mb-0 ml-1 font-weight-bold">تأكيد الدعوه</span>
              <v-icon class="send-icon">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'customer-invite-model',
  props: {
    dialogState_: {
      type: Boolean,
      default: false
    },
    customer: {
      default() {
        return {}
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tab: 0,
      isValid: false,
      email: '',
      emailRules: [
        (v) => /.+@.+\..+/.test(v) || !v || 'من فضلك تحقق من البريد الالكتروني'
      ]
    }
  },
  watch: {
    dialogState_(val) {
      this.email = val ? this.customer?.email : ''
    }
  },
  methods: {
    inviteClient() {
      this.$emit('inviteClient', { email: this.email })
    },
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>
