<template>
  <v-card
    class="mainCard mainContractCard pa-4 mobile__px__8px my-3 justify-space-between"
  >
    <div class="d-flex">
      <div
        class="cursor d-flex justify-space-between max-w-150"
        data-test="show-details"
        @click="$emit('showDetails', { visible: true, bond, terminated })"
      >
        <div class="ml-3 mt-4">
          <v-img :src="bondIcon" alt="bondIcon"></v-img>
        </div>
        <div
          class="min-w-120"
          v-if="$hasPermission('properties', 'bonds', 'list')"
        >
          <h5
            class="max-w-100 mb-2 line__height__1-3 mobile__mw__100 text-truncate max-w-180"
          >
            {{ bond.title || bond.bondAddress }}
          </h5>
          <v-layout align-center>
            <h5 class="brub--tag ma-0 tag-sm text-center py-0 pb-1">
              <span class="font-12 font-weight-bold my-auto text-truncate">
                {{ bond.bondType?.nameAr }}
              </span>
            </h5>
          </v-layout>
        </div>
      </div>
      <!-- Bond Versions -->
      <!-- <div
        v-if="+bond.version > 1 && !sideVersions"
        class="d-flex align-center"
      >
        <v-tooltip content-class="v-tool" color="black" min-width="95" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              @click="$emit('showVersions', { visible: true, id: bond._id })"
              class="mx-3 cursor mt-3 w-100"
            >
              <h5
                class="ma-0 font-weight-medium versions bill-sm ma-auto d-flex justify-center align-center"
              >
                <span class="font-16 font-weight-bold">
                  {{ bond.version }}
                </span>
              </h5>
            </div>
          </template>
          <span class="tool--tip">عدد إصدارات السند</span>
        </v-tooltip>
      </div> -->
    </div>
    <div
      class="d-flex justify-space-between flex-wrap w-100"
      :class="{ 'mt-4': sm, 'mw-65 ': md, 'mw-75': !md }"
    >
      <div :class="{ 'd-flex': !md, 'mt-2': md }" class="mobile__ml__10px">
        <div
          class="d-flex justify-space-between align-center"
          style="width: fit-content"
        >
          <div class="d-flex flex-column">
            <div class="mb-2" v-if="bondValue">
              <h6 class="pb-3 text-gray ma-0 mt-2 text-truncate font-12">
                قيمة السند
              </h6>
              <h5
                class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
              >
                {{ bondValue }}
              </h5>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-space-between align-center"
          :class="{ 'mr-10': !md, 'mt-2': md }"
        >
          <div class="d-flex flex-column">
            <div class="d-flex flex-column mb-5" style="gap: 0.7rem">
              <h6 class="mt-2 text-gray ma-0 text-truncate font-12">
                اسم العميل
              </h6>
              <h5
                class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
              >
                {{ bond.client?.name }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div :class="{ 'd-flex': !md }" class="mobile__ml__10px">
        <div
          class="d-flex justify-space-between align-center"
          v-if="createdByName.length"
        >
          <div class="d-flex flex-column">
            <div class="mb-2">
              <h6 class="pb-2 text-gray ma-0 text-truncate font-12">بواسطة</h6>
              <div class="d-flex align-center">
                <h6
                  class="red--tag bill-md text-center font-12 ma-0 ml-2 text-truncate"
                >
                  {{ $subString(createdByName) }}
                </h6>
                <h5
                  class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
                >
                  {{ createdByName }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-space-between align-center max-w-80"
          :class="{ 'mr-10': !md, 'mt-2': md }"
          style="width: fit-content"
        >
          <div class="d-flex flex-column" v-if="bond?.realEstate?.name">
            <div class="mb-2">
              <h6 class="pb-2 text-gray ma-0 text-truncate font-12">
                اسم العقار
              </h6>
              <h5
                :class="{ 'w-35px': sm }"
                class="text-truncate ma-0 font-weight-medium max-w-80"
              >
                {{ bond.realEstate.name }}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div :class="{ 'd-flex': !md, 'mt-2': md }" class="mobile__ml__10px">
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex flex-column">
            <div class="mb-2">
              <h6 class="pb-2 text-gray ma-0 text-truncate font-12">
                تاريخ الإنشاء
              </h6>
              <h5 class="ma-0 font-weight-medium text-truncate max-w-120">
                {{ new Date(bond.createdAt).toISOString().substr(0, 10) }}
              </h5>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-space-between align-center"
          v-if="terminated"
          :class="{ 'mr-10': !md, 'mt-4': md }"
        >
          <div class="d-flex flex-column">
            <div
              class="px-5 mb-1 red--tag tabel-tag text-center font-12 font-weight-bold d-flex align-center"
            >
              <v-sheet class="dotted ma-0 pa-0 ml-2" color="#EB4545"></v-sheet>
              <h6
                class="pb-2 ma-0 pb-1 font-12 font-weight-bold text-truncate px-2"
              >
                ملغي
              </h6>
            </div>
          </div>
        </div>
        <div
          v-else
          class="d-flex justify-space-between align-center"
          :class="{ 'mr-10': !md, 'mt-4': md }"
        >
          <div class="d-flex flex-column">
            <div
              class="px-5 mb-1 green--tag tabel-tag d-flex align-center px-4"
            >
              <v-sheet class="dotted ma-0 pa-0 ml-2" color="#65C467"></v-sheet>
              <h6
                class="pb-2 ma-0 pb-1 font-12 font-weight-bold text-truncate px-2"
              >
                ساري
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="my-auto mobile__ml__10px">
        <v-menu
          bottom
          content-class="dropdown-menu"
          offset-x
          transition="slide-x-transition"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="options-btn"
              color="lamb"
              icon
              v-bind="attrs"
              v-on="on"
              :disabled="terminated || sideVersions"
              id="openMenuBtn"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0 ma-0">
            <!-- <v-list-item
              @click="$emit('openDialog', bond)"
              v-if="$hasPermission('properties', 'bonds', 'update')"
            >
              <v-list-item-icon>
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>تعديل</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <v-list-item
              @click="$emit('showDetails', { visible: true, bond, terminated })"
            >
              <v-list-item-icon>
                <v-icon>mdi-eye-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>تفاصيل</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              data-test="pdf"
              @click="$emit('downloadPDF', { visible: true, bond: bond })"
            >
              <v-list-item-icon>
                <v-icon>mdi-export-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>بي دي اف</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="$emit('sharePdf', bond)"
              data-test="social-sharing"
            >
              <v-list-item-icon>
                <v-icon>mdi-share</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>مشاركة</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="
                $hasPermission('properties', 'bonds', 'delete') && !terminated
              "
              data-test="terminate-bond"
              @click="$emit('bondDel', bond._id)"
            >
              <v-list-item-icon>
                <v-icon color="danger">mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="danger--text"
                  >الغاء السند</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <!-- Multiple selections for delete -->
    <slot />
  </v-card>
</template>
<script>
export default {
  name: 'bond-card',
  props: {
    bond: {
      required: true
    },
    sideVersions: {
      default: false
    },
    versionsLength: {
      default: 0
    }
  },
  data() {
    return {
      bondIcon: require('@/assets/img/svgs/bonds.svg')
    }
  },
  computed: {
    createdByName() {
      return this.bond?.createdBy?.name || ''
    },
    bondValue() {
      return (
        this.bond?.totalAmount?.toLocaleString('en-US', {
          maximumFractionDigits: 2
        }) ?? ''
      )
    },
    md() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly
    },
    terminated() {
      return !!this.bond?.terminated?.isTerminated
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_main-card-list.scss';
.max-w-80 {
  max-width: 100px;
}
</style>
