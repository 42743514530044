<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="dues"
      :expanded.sync="expanded"
      hide-default-footer
      mobile-breakpoint="0"
      class="hectar-table noScroll pointerCursor"
      @click:row="handleClick"
    >
      <template v-slot:[`item.userType`]="{ item }">
        <div
          class="justify-center align-center d-flex flex-wrap"
          v-if="item.userType"
        >
          <div class="blue--tag tabel-tag text-center ml-1 font-12 my-1">
            <h5 class="font-12 font-weight-medium pa-0 ma-0 text-truncate">
              {{ item.userType }}
            </h5>
          </div>
        </div>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <h6 class="font-14 font-weight-medium ml-md-6 w-100">
          {{ formatDate(item.date) }}
        </h6>
      </template>
      <template v-slot:[`item.installmentType`]="{ item }">
        <div
          class="justify-center align-center d-flex flex-wrap"
          v-if="item.installmentType"
        >
          <div class="purple--bill tabel-tag text-center font-12">
            <h5 class="font-12 font-weight-medium ma-0 text-truncate">
              {{ item.installmentType }}
            </h5>
          </div>
        </div>
        <div class="text-center" v-else>-</div>
      </template>
      <template v-slot:[`item.installmentStatus`]="{ item }">
        <div
          class="justify-center align-center d-flex flex-wrap"
          v-if="item.installmentStatus"
        >
          <div
            class="tabel-tag text-center ml-1 font-12 my-1"
            :class="generateStyleClasses(item.installmentStatus)"
          >
            <h5 class="font-12 font-weight-medium px-2 ma-0 text-truncate">
              {{ item.installmentStatus }}
            </h5>
          </div>
        </div>
      </template>

      <template v-slot:[`item.payment`]="{ item }">
        <div class="justify-center align-center d-flex flex-wrap">
          <h5
            v-if="item.payment"
            class="font-12 font-weight-medium pa-0 ma-0 text-truncate"
          >
            {{
              (item.payment + item.taxAmount ?? 0).toLocaleString('en-US', {
                maximumFractionDigits: 2
              })
            }}
          </h5>
        </div>
      </template>

      <template v-slot:[`item.assignmentStatus`]="{ item }">
        <v-menu
          content-class="dataTableMenus"
          elevation-0
          offset-y
          v-if="
            item.assignmentStatus &&
            !nonEditableDuesStatus.includes(item.paymentStatus)
          "
        >
          <template v-slot:activator="{ on: menu }">
            <div v-on="{ ...menu }">
              <div
                @click="setArrowDir(item)"
                class="tabel-tag text-center ml-1 font-12 d-flex justify-center"
                :class="generateStyleClasses(item.assignmentStatus)"
              >
                <h5
                  class="font-12 font-weight-medium pa-0 ma-0 ml-2 text-truncate pr-2"
                >
                  {{ item.assignmentStatus }}
                </h5>
                <div
                  class="font-12 rotate-180 mt-3"
                  :class="{
                    'rotate-0': toggleArrow && ArrowId == item.id
                  }"
                  :style="`height: 4px; ${
                    toggleArrow && ArrowId == item.id
                      ? 'margin-top: 5px !important;'
                      : ''
                  }`"
                  color="general"
                >
                  &#x2303;
                </div>
              </div>
            </div>
          </template>

          <v-list id="assignmentStatus" class="ma-0 pa-0">
            <v-list-item
              class="px-3 pointerCursor"
              v-for="(link, i) in assignmentStatus"
              :key="i"
              @click="setInstallmentStatus(link.nameAr, item)"
            >
              <v-list-item-title class="font-14 font-weight-medium ma-0">
                {{ link.nameAr }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div
          v-else
          class="tabel-tag text-center ml-1 font-12 d-flex justify-center"
          :class="generateStyleClasses(item.assignmentStatus)"
        >
          <h5
            class="font-12 font-weight-medium pa-0 ma-0 ml-2 text-truncate pr-2"
          >
            {{ item.assignmentStatus }}
          </h5>
        </div>
      </template>
      <template v-slot:[`item.paymentStatus`]="{ item }">
        <div
          class="justify-center align-center d-flex flex-wrap"
          v-if="item.paymentStatus"
        >
          <div
            class="tabel-tag text-center font-12 d-flex justify-between align-center"
            :class="generateStyleClasses(item.paymentStatus)"
          >
            <h5 class="font-12 font-weight-medium ma-0 text-truncate ma-auto">
              {{ item.paymentStatus }}
            </h5>
          </div>
        </div>
      </template>
      <template v-slot:no-data>
        <span>لا يوجد مطالبات مالية</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateFormat from '@/mixins/dateFormat.js'

export default {
  props: {
    nonEditableDuesStatus: {
      type: Array,
      default() {
        return []
      }
    },
    dues: {
      type: Array,
      default: () => {
        return []
      }
    },
    assignmentStatus: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      expanded: [],
      singleExpand: true,
      categoryMenuState: false,
      defaultConfigPlaceholder: {
        installmentType: 'نوع المطالبة',
        assignmentStatus: 'حالة التحصيل',
        installmentStatus: 'حالة المطالبة',
        paymentStatus: 'حالة الدفع'
      },
      headers: [
        // TODO: to be implemented once the prod team confirmed the use case
        // { text: '', value: 'data-table-expand' },
        {
          text: 'نوع العميل',
          value: 'userType',
          align: 'center',
          sortable: false
        },
        {
          text: 'اسم العميل',
          value: 'user.name',
          align: 'center',
          sortable: false
        },
        {
          text: 'تاريخ الإستحقاق',
          value: 'date',
          align: 'center',
          sortable: false
        },
        {
          text: 'نوع المطالبة',
          value: 'installmentType',
          align: 'center',
          sortable: false
        },
        {
          text: 'حاله المطالبة',
          value: 'installmentStatus',
          align: 'center',
          sortable: false
        },
        {
          text: 'القيمه  ',
          value: 'payment',
          align: 'center',
          sortable: false
        },
        {
          text: 'حالة التحصيل ',
          value: 'assignmentStatus',
          align: 'center',
          sortable: false
        },
        {
          text: 'حالة الدفع ',
          value: 'paymentStatus',
          align: 'center',
          sortable: false
        }
      ],
      toggleArrow: false,
      ArrowId: ''
    }
  },
  mixins: [dateFormat],
  methods: {
    setInstallmentStatus(assignmentStatus, item) {
      this.$emit('setInstallmentStatus', { ...item, assignmentStatus })
    },
    setArrowDir({ id }) {
      this.ArrowId = id
      this.toggleArrow = !this.toggleArrow
    },
    handleClick(row) {
      this.$emit('open-due-details', row)
    },

    generateStyleClasses(value) {
      let classes = ''
      switch (value) {
        case 'مبلغ التأمين':
        case 'دفعة إيجار':
          classes = 'purple--bill'
          break
        case 'غير مدفوعة':
        case 'متأخرة':
        case 'ملغيّة':
          classes = 'red--bill'
          break
        case 'مدفوعة':
        case 'مستقبلية':
        case 'تم التحصيل':
          classes = 'green--bill'
          break
        case 'معلّقة':
          classes = 'orange--bill'
          break
        default:
          classes = 'default--bill'
      }
      return classes
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    tableHeaders() {
      return this.isCustomer
        ? this.headers.filter((header) => header.value !== 'assignmentStatus')
        : this.headers
    }
  }
}
</script>

<style lang="scss" scoped>
.hectar-table.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
.rotate-180 {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.rotate-0 {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
</style>
