<template>
  <div>
    <h4>سجل النشاطات</h4>
    <div class="ma-auto" v-if="!logs.length">
      <NoData v-bind="noDataConfig" />
    </div>
    <div
      class="d-flex align-center mt-3"
      v-else
      v-for="log in logs"
      :key="log._id"
    >
      <svg
        width="36"
        height="32"
        viewBox="0 0 36 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.012207"
          width="35.9878"
          height="32"
          rx="16"
          fill="#662D91"
          fill-opacity="0.1"
        />
        <g clip-path="url(#clip0_13864_307)">
          <path
            d="M21.3957 14.875C21.3957 14.5298 21.0811 14.25 20.6929 14.25H13.4531C13.0649 14.25 12.7502 14.5298 12.7502 14.875C12.7502 15.2202 13.0649 15.5 13.4531 15.5H20.6929C21.0811 15.5 21.3957 15.2202 21.3957 14.875Z"
            fill="#662D91"
          />
          <path
            d="M13.4531 16.75C13.0649 16.75 12.7502 17.0298 12.7502 17.375C12.7502 17.7202 13.0649 18 13.4531 18H17.8501C18.2383 18 18.553 17.7202 18.553 17.375C18.553 17.0298 18.2383 16.75 17.8501 16.75H13.4531Z"
            fill="#662D91"
          />
          <path
            d="M15.1049 22.75H12.7542C11.979 22.75 11.3484 22.1892 11.3484 21.5V10.5C11.3484 9.81075 11.979 9.25 12.7542 9.25H21.3958C22.1709 9.25 22.8016 9.81075 22.8016 10.5V14.3438C22.8016 14.6889 23.1163 14.9688 23.5045 14.9688C23.8927 14.9688 24.2073 14.6889 24.2073 14.3438V10.5C24.2073 9.1215 22.9461 8 21.3958 8H12.7542C11.2039 8 9.94263 9.1215 9.94263 10.5V21.5C9.94263 22.8785 11.2039 24 12.7542 24H15.1049C15.4932 24 15.8078 23.7202 15.8078 23.375C15.8078 23.0298 15.4932 22.75 15.1049 22.75Z"
            fill="#662D91"
          />
          <path
            d="M25.4523 17.0491C24.6301 16.318 23.2924 16.318 22.4707 17.0486L18.6118 20.4723C18.5298 20.5451 18.4694 20.6346 18.4357 20.7331L17.5953 23.1933C17.5208 23.4114 17.5857 23.6482 17.7647 23.8114C17.8988 23.9336 18.081 24 18.2679 24C18.3304 24 18.3935 23.9925 18.4555 23.9772L21.2925 23.2785C21.4093 23.2497 21.5157 23.1946 21.6014 23.1185L25.4523 19.7008C26.2745 18.9697 26.2745 17.7802 25.4523 17.0491ZM20.7391 22.1177L19.3119 22.4693L19.7296 21.2465L22.3334 18.9363L23.3276 19.8204L20.7391 22.1177ZM24.4588 18.8165L24.3226 18.9374L23.3285 18.0535L23.4643 17.933C23.7383 17.6894 24.1842 17.6894 24.4583 17.933C24.7324 18.1767 24.7324 18.5732 24.4588 18.8165Z"
            fill="#662D91"
          />
          <path
            d="M20.6929 11.75H13.4531C13.0649 11.75 12.7502 12.0298 12.7502 12.375C12.7502 12.7202 13.0649 13 13.4531 13H20.6929C21.0811 13 21.3957 12.7202 21.3957 12.375C21.3957 12.0298 21.0811 11.75 20.6929 11.75Z"
            fill="#662D91"
          />
        </g>
        <defs>
          <clipPath id="clip0_13864_307">
            <rect
              width="17.9939"
              height="16"
              fill="white"
              transform="translate(9.00903 8)"
            />
          </clipPath>
        </defs>
      </svg>
      <div class="mr-3">
        <h6>{{ log.body }}</h6>
        <p class="font-14">
          {{ formatDate(log.updatedAt) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import NoData from '@/components/helper/noData.vue'

export default {
  props: {
    logs: {
      type: Array
    }
  },
  components: { NoData },
  mixins: [dateFormat],
  data() {
    return {}
  },
  computed: {
    noDataConfig() {
      return {
        noDataText: 'ليست هناك اي  نشاطات',
        noDataIcon: require('@/assets/img/activity-log-icons/activity-log-icon.png'),
        noDataSubTitle: '',
        isbgPrimary: false,
        widthHeight: '80'
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
