<template>
  <div>
    <v-form method="post" class="register">
      <div class="text-field">
        <h6>الإسم</h6>
        <v-text-field
          ref="name"
          v-model="name"
          :rules="[() => !!name || '']"
          label="الإسم"
          required
          filled
          @keydown.enter="login"
          hide-details
        />
      </div>

      <div class="text-field">
        <h6>البريد الإلكتروني</h6>
        <v-text-field
          ref="email"
          v-model="email"
          :rules="[() => (!!email && $v.email.email) || '']"
          label="البريد الإلكتروني"
          required
          readonly
          filled
          @keydown.enter="login"
          hide-details
        />
      </div>

      <span v-if="submitted && !$v.email.email" class="invalid-feedback d-block"
        >من فضلك أدخل بريدك الكتروني صحيح</span
      >
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

export default {
  props: ['phoneNumber', 'userName', 'userEmail', 'password'],
  data() {
    return {
      showPassword: false,
      type: null,
      name: '',
      company: '',
      email: '',
      terms: false,
      submitted: false,
      passwordFieldType: 'password'
    }
  },
  mounted() {
    // Prevent a link inside a label from triggering a checkbox
    const termLink = document.getElementsByClassName('termLink')
    const termClickHandler = function (event) {
      event.stopPropagation()
      event.preventDefault()
      return false
    }
    for (let i = 0; i < termLink.length; i++) {
      termLink[i].addEventListener('click', termClickHandler)
      termLink[i].addEventListener('touchstart', termClickHandler)
    }

    if (this.userEmail) {
      this.email = this.userEmail
    }
    if (this.userName) {
      this.name = this.userName
    }
  },
  computed: {
    ...mapState('accountState', ['configs']),
    ...mapState('userState', ['spinnerLoading']),
    user() {
      const user = {
        type: this.userName ? 'employee' : this.type,
        name: this.name,
        company:
          this.type === 'owner' || this.type === 'tenant'
            ? 'false'
            : this.company,
        phoneNumber: this.phoneNumber,
        accountId: JSON.parse(localStorage.getItem('tokenData'))._id
      }
      if (this.email) {
        user.email = this.email
      }
      return user
    }
  },
  validations: {
    name: {
      required
    },
    email: {
      email
    },

    form: ['name', 'email']
  },

  methods: {
    ...mapActions('accountState', ['register', 'getUserEnvs', 'userDetails']),
    ...mapMutations('accountState', [
      'registerSuccess',
      'loginSuccess',
      'registerFailure',
      'setCurrentEnv',
      'setConfigrations'
    ]),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapMutations('appState', ['addNotification', 'setSync']),
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    async validate() {
      this.submitted = true
      this.setSpinnerLoading(true)
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      this.$emit('on-validate', this.$data, isValid)

      if (!isValid) {
        this.addNotification({
          text: 'من فضلك تأكد من جميع الحقول',
          color: 'error'
        })
        this.setSpinnerLoading(false)
      } else {
        // userDetailsStep
        await this.userDetailsStep(this.user)
        this.setSpinnerLoading(false)
      }

      return isValid
    },
    async userDetailsStep(val) {
      await this.userDetails(val)
        .then((res) => {
          const oldDate = JSON.parse(localStorage.getItem('tokenData'))
          const newDate = Object.assign({}, oldDate, res.data.user)
          localStorage.setItem('tokenData', JSON.stringify(newDate))

          // set user to vuex
          this.loginSuccess(newDate)

          // redirect to home or env
          if (this.type === 'customer') {
            this.$router.push('/setup-env')
          } else {
            this.$router.push(this.$route.query.redirectFrom || '/dashboard')
            this.setSync(true)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
