<template>
  <v-dialog
    class="backdrop"
    max-width="700px"
    overlay-opacity="0.5"
    persistent
    v-model="dialogState_"
  >
    <v-card class="add-new-property pa-0 pa-lg-4">
      <v-card-title class="pl-2 pr-4 pt-3 pb-5 d-flex justify-space-between">
        <div>
          <h4
            class="font-weight-bold"
            v-if="isNewPropertyUnit"
            v-text="
              editPropetyId == 'new' ? 'إضافة وحدة جديد' : 'تحديث بيانات الوحدة'
            "
          ></h4>
          <h4
            class="font-weight-bold"
            v-else
            v-text="
              editPropetyId == 'new' ? 'إضافة عقار جديد' : 'تحديث بيانات العقار'
            "
          ></h4>
        </div>

        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs-items v-model="tab" touchless>
        <!-- Address -->
        <v-tab-item>
          <v-card-text>
            <google-maps-search
              :currentAddress="fullAddress"
              @onCheckAddress="checkAddress"
              mapStatus="new"
              ref="addressRef"
            >
            </google-maps-search>
          </v-card-text>

          <v-card-actions class="pa-1">
            <v-spacer></v-spacer>
            <v-btn
              :class="{ 'add-new-property-arrow-disable': tab0IsDisabled }"
              :disabled="tab0IsDisabled"
              @click="tab++"
              class="pt-1 add-new-property-arrow rounded-15"
              text
              x-large
            >
              <span class="mb-3">التالي</span>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <!-- info -->
        <v-tab-item>
          <v-card-text>
            <v-form ref="form" v-model="isValid">
              <v-container>
                <v-row no-gutters>
                  <!-- ***realtype****-->
                  <v-col cols="12" sm="6">
                    <h5>نوع {{ propertyName }}*</h5>
                    <v-select
                      :items="
                        isNewPropertyUnit ? unitType : configs.realestateTypes
                      "
                      :menu-props="{
                        transition: 'slide-y-transition',
                        bottom: true,
                        offsetY: true
                      }"
                      :placeholder="`اختر نوع ${this.propertyName}`"
                      :rules="selectRules"
                      append-icon="mdi-chevron-down"
                      class="mx-2"
                      dense
                      filled
                      hide-details
                      item-text="nameAr"
                      item-value="_id"
                      required
                      return-object
                      v-model="addPropertyInfo.type"
                    ></v-select>
                  </v-col>
                  <v-col :sm="isForRent ? 3 : 6" cols="12">
                    <h5>حالة العقار*</h5>
                    <v-select
                      :items="configs.realEstateStatus"
                      :menu-props="{
                        transition: 'slide-y-transition',
                        bottom: true,
                        offsetY: true
                      }"
                      :placeholder="`اختر حالة ${this.propertyName}`"
                      :rules="selectRules"
                      @change="togglePaytypSelect = true"
                      append-icon="mdi-chevron-down"
                      dense
                      filled
                      hide-details
                      item-text="nameAr"
                      item-value="_id"
                      required
                      return-object
                      :disabled="isNewPropertyUnit"
                      v-model="addPropertyInfo.status"
                    ></v-select>
                  </v-col>
                  <!-- ***realtype****-->
                  <v-col cols="12" sm="3" v-if="isForRent">
                    <h5>مدة الإيجار*</h5>
                    <v-select
                      :items="payTypeOptions"
                      :menu-props="{
                        value: togglePaytypSelect,
                        closeOnClick: true,
                        closeOnContentClick: true,
                        disableKeys: false,
                        openOnClick: true,
                        bottom: true,
                        offsetY: true,
                        transition: 'slide-y-transition'
                      }"
                      append-icon="mdi-chevron-down"
                      class="mr-2"
                      dense
                      filled
                      hide-details
                      :rules="selectRules"
                      required
                      placeholder="مدة الإيجار"
                      ref="payType"
                      item-text="text"
                      item-value="_id"
                      return-object
                      v-model="addPropertyInfo.payType"
                    ></v-select>
                  </v-col>
                  <!-- ***accommodation Type***-->
                  <v-col cols="12" sm="6">
                    <h5>الغرض</h5>
                    <v-select
                      :items="configs.realestatePurpose"
                      :menu-props="{
                        transition: 'slide-y-transition',
                        bottom: true,
                        offsetY: true
                      }"
                      append-icon="mdi-chevron-down"
                      class="mx-2"
                      dense
                      filled
                      hide-details
                      item-text="nameAr"
                      item-value="_id"
                      placeholder="الغرض"
                      return-object
                      v-model="addPropertyInfo.purpose"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="accommodationType">
                    <h5>نوع السكن</h5>
                    <v-select
                      :items="configs.realestatePopulations"
                      :menu-props="{
                        transition: 'slide-y-transition',
                        bottom: true,
                        offsetY: true
                      }"
                      :rules="selectRules"
                      append-icon="mdi-chevron-down"
                      class="mx-2"
                      dense
                      filled
                      hide-details
                      item-text="nameAr"
                      item-value="_id"
                      placeholder="نوع السكن"
                      required
                      return-object
                      v-model="addPropertyInfo.populationType"
                    ></v-select>
                  </v-col>
                  <!-- ***organizers****-->
                  <v-col cols="12" sm="6">
                    <h5>
                      {{ isNewPropertyUnit ? 'رقم الوحده*' : 'اسم العقار*' }}
                    </h5>
                    <v-text-field
                      class="mx-2 mb-0"
                      dense
                      :rules="selectRules"
                      filled
                      hide-details
                      :placeholder="
                        isNewPropertyUnit ? 'رقم الوحده' : 'اسم العقار'
                      "
                      required
                      :type="isNewPropertyUnit ? 'number' : 'text'"
                      :value="
                        isNewPropertyUnit
                          ? addPropertyInfo.unitNumber
                          : addPropertyInfo.propertyName
                      "
                      @input="
                        isNewPropertyUnit
                          ? (addPropertyInfo.unitNumber = $event)
                          : (addPropertyInfo.propertyName = $event)
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h5>المسؤول*</h5>
                    <v-select
                      :items="employees"
                      :menu-props="{
                        transition: 'slide-y-transition',
                        bottom: true,
                        offsetY: true
                      }"
                      :placeholder="`المسؤول عن العقار`"
                      append-icon="mdi-chevron-down"
                      dense
                      :rules="selectRules"
                      filled
                      hide-details
                      item-text="name"
                      item-value="_id"
                      return-object
                      clearable
                      v-model="addPropertyInfo.moderator"
                    ></v-select>
                  </v-col>
                  <v-row no-gutters>
                    <v-col cols="24" sm="12" v-if="isOwner">
                      <h5>المالك</h5>
                      <v-row no-gutters>
                        <v-col cols="12" sm="6">
                          <v-select
                            :items="allClients"
                            :menu-props="{
                              transition: 'slide-y-transition',
                              bottom: true,
                              offsetY: true
                            }"
                            :placeholder="`مالك  العقار`"
                            :rules="selectRules"
                            @change="onSelectUser($event)"
                            append-icon="mdi-chevron-down"
                            chips
                            class="mx-2 mb-0"
                            clearable
                            dense
                            filled
                            hide-details
                            item-text="name"
                            item-value="_id"
                            return-object
                            v-model="addPropertyInfo.owner"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <NewAgent
                            :obj="addPropertyInfo"
                            @changeNewUser="addPropertyInfo"
                            @onChange="onNewAgent"
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col v-if="isOwner && addPropertyInfo.newUserChecked">
                      <NewAgentForm
                        :index="0"
                        :isAqarForm="true"
                        :isDisabled="false"
                        @new_user_added="newUserAdded"
                      />
                    </v-col>
                  </v-row>
                  <!--tags-->
                  <v-col class="mt-3 pl-2" cols="12" sm="6" v-if="tagShow">
                    <v-card
                      class="d-flex justify-space-between px-2 mx-2 w-100 align-center"
                    >
                      <h5>قابل للتفاوض</h5>
                      <v-switch
                        :size="md ? 'xs' : 'lg'"
                        class="ma-0 pa-0"
                        hide-details
                        color="rib"
                        inset
                        v-model="tags"
                        value="negotiable"
                      ></v-switch>
                    </v-card>
                  </v-col>
                  <v-col class="mt-3 pr-2" cols="12" sm="6" v-if="isLand">
                    <v-card
                      class="d-flex justify-space-between px-2 mx-2 w-100 align-center"
                    >
                      <h5>علي السوم</h5>
                      <v-switch
                        :size="md ? 'xs' : 'lg'"
                        class="ma-0 pa-0"
                        color="rib"
                        hide-details
                        inset
                        v-model="notLimitedPrice"
                        value="Jo11hn"
                      ></v-switch>
                    </v-card>
                  </v-col>

                  <v-col class="mt-3" cols="12" sm="6" v-if="isFire">
                    <v-card
                      class="d-flex justify-space-between px-2 mx-2 w-100 align-center"
                    >
                      <h5>للتقبيل</h5>
                      <v-switch
                        :size="md ? 'xs' : 'lg'"
                        class="ma-0 pa-0"
                        color="rib"
                        inset
                        hide-details
                        v-model="tags"
                        value="allTheFutures"
                      ></v-switch>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions
            class="pa-1 d-flex justify-space-between align-center"
          >
            <v-btn
              :disabled="isNewPropertyUnit"
              @click="tab--"
              class="ma-0 rounded-15"
              color="gery"
              text
              x-large
            >
              <v-icon left>mdi-arrow-right</v-icon>
              <span class="mb-3">السابق</span>
            </v-btn>
            <div class="d-flex align-center">
              <h5 class="mx-0 mx-md-2 font-weight-bold">اظهار في هكتار</h5>
              <v-switch
                color="rib"
                inset
                :size="md ? 'xs' : 'lg'"
                v-model="addPropertyInfo.addToHecter"
              ></v-switch>
            </div>
            <v-btn
              :class="{ 'add-new-property-arrow-disable': !isValid }"
              :disabled="!isValid"
              :loading="isLoading"
              @click="validate"
              class="ma-0 add-new-property-arrow rounded-15"
              text
              v-if="isNewPropertyUnit"
              x-large
            >
              <span class="mb-3">إضافة الوحدة</span>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn
              :class="{ 'add-new-property-arrow-disable': !isValid }"
              :disabled="!isValid"
              :loading="isLoading"
              @click="validate"
              class="pt-2 ma-0 add-new-property-arrow rounded-15 pa-md-0"
              text
              v-else
              x-large
            >
              <span class="mb-3"> التالي</span>
              <v-icon class="mr-2"> mdi-arrow-left </v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <!-- details -->
        <v-tab-item>
          <v-card-text>
            <v-form ref="form" v-model="isValid">
              <v-container>
                <v-row no-gutters>
                  <!-- ***realtype****-->
                  <v-col cols="12" sm="6">
                    <h5 class="ma-0 mb-2">السعر *</h5>
                    <v-text-field
                      :placeholder="isForRent ? 'الإيجار' : 'السعر '"
                      :suffix="
                        isForRent && payType ? `ريال/${payType.text}` : 'ريال'
                      "
                      filled
                      :rules="selectRules"
                      required
                      type="number"
                      v-model.number="addPropertyInfo.price"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h5 class="ma-0 mb-2">المساحة*</h5>
                    <v-text-field
                      class="mx-2"
                      filled
                      :rules="selectRules"
                      placeholder="المساحة "
                      required
                      suffix="متر مربع"
                      type="number"
                      v-model.number="addPropertyInfo.space"
                    ></v-text-field>
                  </v-col>

                  <!-- ***space****-->
                  <v-col cols="12" sm="6" v-if="flat">
                    <h5 class="ma-0 mb-2">عدد الصالات</h5>
                    <v-text-field
                      class="mx-2"
                      filled
                      placeholder="عدد الصالات (اختياري)"
                      type="number"
                      v-model.number="addPropertyInfo.numberOfLivingRoom"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="flat">
                    <h5 class="ma-0 mb-2">عدد الغرف*</h5>
                    <v-text-field
                      class="mx-2"
                      filled
                      :rules="selectRules"
                      placeholder=" عدد الغرف "
                      required
                      type="number"
                      v-model.number="addPropertyInfo.numberOfRooms"
                    ></v-text-field>
                  </v-col>
                  <!-- ***organizers****-->
                  <v-col cols="12" sm="6" v-if="flat">
                    <h5 class="ma-0 mb-2">عدد المطابخ</h5>
                    <v-text-field
                      class="mx-2"
                      filled
                      placeholder=" عدد المطابخ (اختياري)"
                      type="number"
                      v-model.number="addPropertyInfo.numberOfKitchenUnit"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="flat">
                    <h5 class="ma-0 mb-2">عدد دورات المياة</h5>
                    <v-text-field
                      class="mx-2"
                      filled
                      placeholder=" عدد دورات المياة  (اختياري)"
                      type="number"
                      v-model.number="addPropertyInfo.numberOfBathRoom"
                    ></v-text-field>
                  </v-col>
                  <!--***land***-->
                  <v-col cols="12" sm="6" v-if="land">
                    <h5 class="ma-0 mb-2">عرض الشارع</h5>
                    <v-text-field
                      class="mx-2"
                      filled
                      placeholder=" عرض الشارع (اختياري)"
                      type="text"
                      v-model="addPropertyInfo.streetWidth"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="land">
                    <h5 class="ma-0 mb-2">الارض تطل علي</h5>
                    <div
                      class="d-flex flex-row justify-space-between align-center"
                    >
                      <div v-for="(check, i) in config.selectedSides" :key="i">
                        <v-checkbox
                          class="ma-0 contract mr-1"
                          dense
                          hide-details
                          v-model="selectedSides[check]"
                        ></v-checkbox>
                        <h5 class="ma-0 mb-2">{{ check }}</h5>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <!--adding photos-->
                <h4>صور العقار</h4>
                <v-row>
                  <v-col :key="i" sm="3" v-for="(image, i) in images">
                    <div class="position-relative">
                      <img :ref="'image'" class="add-pic" loading="lazy" />
                      <v-icon
                        @click="images.splice(i, 1)"
                        class="pic-icon position-absolute slide-y-transition"
                        color="white"
                      >
                        mdi-close
                      </v-icon>
                    </div>
                  </v-col>

                  <v-col
                    class="d-flex justify-center align-center pic-wrapper"
                    sm="3"
                    v-if="images.length"
                  >
                    <v-btn
                      @click="$refs.inputUpload2.click()"
                      class="brub--border"
                      icon
                    >
                      <v-icon color="general"> mdi-plus </v-icon>
                    </v-btn>
                    <input
                      @change="addFiles($event)"
                      accept="image/*"
                      ref="inputUpload2"
                      type="file"
                      v-show="false"
                    />
                  </v-col>
                  <div
                    class="pic-wrapper d-flex align-center w-100 pa-6 mt-3 flex-wrap"
                    v-else
                  >
                    <div>
                      <v-btn
                        @click="$refs.inputUpload.click()"
                        class="py-2 px-3 rounded-7 font-weight-bold"
                        color="general"
                        outlined
                        :disabled="isCustomer"
                      >
                        <v-icon color="general"> mdi-plus </v-icon>
                        <span class="font-12"> إضافة الصور </span>
                      </v-btn>
                      <input
                        @change="addFiles($event)"
                        accept="image/*"
                        multiple
                        ref="inputUpload"
                        type="file"
                        v-show="false"
                      />
                    </div>
                    <div class="mr-3">
                      <h4 class="font-12">
                        اسحب الصور هنا لرفعها أو اضغط على زر إضافة الصور
                      </h4>
                      <h5 class="text-gray font-12">
                        يمكنك الإضافة حتى ١٠ صور لعقارك
                      </h5>
                    </div>
                  </div>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions
            class="pa-4 d-flex justify-space-between align-center"
          >
            <v-btn @click="tab--" class="ma-0" color="gery" text x-large>
              <v-icon left>mdi-arrow-right</v-icon>
              <span class="tf">السابق</span>
            </v-btn>
            <div class="d-flex align-center">
              <h5 class="mx-2">اظهار في هكتار</h5>
              <v-switch
                :disabled="!isValid"
                color="rib"
                inset
                v-model="addPropertyInfo.addToHecter"
              ></v-switch>
            </div>
            <v-btn
              :class="{
                ' pt-2 ma-0 add-new-property-arrow-disable rounded-15': !isValid
              }"
              :disabled="!isValid"
              :loading="isLoading"
              @click="
                editPropetyId == 'new' ? addRealEstate() : updateRealEstate()
              "
              class="pt-2 ma-0 add-new-property-arrow"
              text
              v-if="isNewPropertyUnit"
              x-large
            >
              <span class="tf">إضافة الوحدة</span>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn
              :class="{ 'add-new-property-arrow-disable': !isValid }"
              :disabled="!isValid"
              :loading="isLoading"
              @click="
                editPropetyId == 'new' ? addRealEstate() : updateRealEstate()
              "
              class="pt-2 ma-0 add-new-property-arrow rounded-15"
              text
              v-else
              x-large
            >
              <span class="mb-3">{{
                editPropetyId == 'new'
                  ? `إضافة ${propertyName}`
                  : `تحديث ${propertyName}`
              }}</span>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import config from '@/config'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { EnvService, realEstateService } from '../../services'

import '@/styles/material-dashboard/_add-new-property.scss'
import GoogleMapsSearch from '@/components/map/GoogleMapsSearch.vue'
import NewAgent from '@/components/common/new-agent/NewAgent'
import NewAgentForm from '@/components/common/new-agent/NewAgentForm'

export default {
  props: [
    'dialogState',
    'editPropetyId',
    'propertyInfo',
    'parentPropertyId',
    'isNewPropertyUnit',
    'addNewOwner'
  ],
  components: {
    NewAgentForm,
    NewAgent,
    GoogleMapsSearch
  },
  data() {
    return {
      selectedSides: [],
      tab: 0,
      tab0IsDisabled: true,
      tags: [],
      addPropertyInfo: {},
      payType: null,
      togglePaytypSelect: false,
      notLimitedPrice: false,
      isValid: false,
      isLoading: false,
      allClients: [],
      currentEnv: {},
      images: [],
      employees: [],
      selectRules: [
        (v) =>
          this.addPropertyInfo.newUserChecked ? true : !!v || 'هذا الحقل مطلوب'
      ],
      config,
      newUser: {}
    }
  },

  watch: {
    propertyInfo: {
      immediate: true,
      handler() {
        this.addPropertyInfo = this.propertyInfo
        if (Object.keys(this.propertyInfo).length) {
          this.tab0IsDisabled = false
        }
      }
    },
    dialogState() {
      if (this.addNewOwner) {
        this.tab = 1
      } else {
        this.tab = 0
      }
    },
    notLimitedPrice(val) {
      if (val) {
        this.addPropertyInfo.price = 'علي السوم'
      } else {
        this.addPropertyInfo.price = ''
      }
    }
  },
  created() {
    this.currentEnv = JSON.parse(localStorage.getItem('currentEnv'))
    if (this.currentEnv && this.currentEnv._id) {
      const query = '&environmentRole=owner'
      EnvService.getAllClients(this.currentEnv._id, query).then((res) => {
        this.allClients = res.data.users
      })
    }
    this.loadEmployees()
    this.setUnitDetails()
  },
  computed: {
    ...mapState('accountState', ['configs', 'user']),
    ...mapGetters('accountState', ['isCustomer']),
    propertyName() {
      return this.isNewPropertyUnit ? 'الوحدة' : 'العقار'
    },
    payTypeOptions() {
      return config.payTypeOptions
    },
    fullAddress() {
      return {
        ...this.propertyInfo.location,
        ...this.propertyInfo.propertyAddress
      }
    },
    flat() {
      return ['مكتب', 'شقة', 'معرض', 'دور', 'فيلا'].includes(
        this.addPropertyInfo?.type?.nameAr
      )
    },
    land() {
      return this.addPropertyInfo?.type?.nameAr === 'أرض'
    },
    infoToSend() {
      if (this.currentEnv && this.currentEnv._id) {
        const { _id, name, phoneNumber, email } = this.addPropertyInfo.moderator
        const dataToAdd = {
          type: this.addPropertyInfo.type._id,
          status: this.addPropertyInfo.status._id,
          price: this.addPropertyInfo.price,
          space: this.addPropertyInfo.space,
          propertyAddress: {
            address:
              this.addPropertyInfo.location.address ||
              this.addPropertyInfo.propertyAddress.address,
            city:
              this.addPropertyInfo?.location?.city ||
              this.addPropertyInfo?.propertyAddress?.city
          },
          location: {
            type: this.addPropertyInfo.location.type,
            coordinates: {
              lat: this.addPropertyInfo.location.coordinates[1],
              lng: this.addPropertyInfo.location.coordinates[0]
            }
          },
          tenet: this.addPropertyInfo?.tenet?.deleted
            ? {
                _id: this.addPropertyInfo.tenet._id,
                name: this.addPropertyInfo.tenet.name,
                phone: this.addPropertyInfo.tenet.phoneNumber
              }
            : undefined,
          owner: this.addPropertyInfo.owner
            ? {
                _id: this.addPropertyInfo.owner._id,
                name: this.addPropertyInfo.owner.name,
                phone: this.addPropertyInfo.owner.phoneNumber
              }
            : undefined,
          numberOfUnit: this.addPropertyInfo.numberOfUnit,
          numberOfKitchenUnit: this.addPropertyInfo.numberOfKitchenUnit,
          numberOfRooms: this.addPropertyInfo.numberOfRooms,
          numberOfBathRoom: this.addPropertyInfo.numberOfBathRoom,
          numberOfLivingRoom: this.addPropertyInfo.numberOfLivingRoom,
          showInHectar: this.addPropertyInfo.addToHecter,
          propertyName: this.addPropertyInfo.propertyName,
          moderator: { _id, name, email, phoneNumber },
          account: this.currentEnv.owner,
          tags: this.tags,
          purpose: this.addPropertyInfo.purpose._id
        }
        if (this.accommodationType) {
          dataToAdd.populationType = this.addPropertyInfo.populationType._id
        }
        if (this.isForRent) {
          dataToAdd.payType = this.addPropertyInfo.payType._id
        }
        if (this.editPropetyId === 'new') {
          dataToAdd.environment = this.currentEnv._id
        }
        if (this.addPropertyInfo._id) {
          dataToAdd._id = this.addPropertyInfo._id
        }
        if (this.isNewPropertyUnit) {
          dataToAdd.parentId = this.parentPropertyId
          dataToAdd.unitNumber = this.addPropertyInfo.unitNumber
        }
        return dataToAdd
      }
      return null
    },
    tagShow() {
      return (
        this.addPropertyInfo?.status?.nameAr === 'للإيجار' ||
        this.addPropertyInfo?.status?.nameAr === 'للبيع'
      )
    },
    isForRent() {
      return (
        this.addPropertyInfo?.status?.nameAr === 'للإيجار' ||
        this.addPropertyInfo?.status?.nameAr === 'شاغرة'
      )
    },
    isOwner() {
      return this.addPropertyInfo?.status?.nameAr !== 'مُباع'
    },
    isFire() {
      return this.addPropertyInfo?.type?.nameAr === 'معرض'
    },
    isLand() {
      return (
        this.addPropertyInfo?.type?.nameAr === 'أرض' &&
        this.addPropertyInfo?.status?.nameAr === 'للبيع'
      )
    },
    accommodationType() {
      return (
        (this.isForRent && this.addPropertyInfo?.type?.nameAr === 'شقة') ||
        (this.isForRent && this.addPropertyInfo?.type?.nameAr === 'فيلا') ||
        (this.isForRent && this.addPropertyInfo?.type?.nameAr === 'دور')
      )
    },
    unitType() {
      return this.configs.realestateTypes.filter((o) => {
        if (
          o.nameAr === 'مكتب' ||
          o.nameAr === 'شقة' ||
          o.nameAr === 'معرض' ||
          o.nameAr === 'دور' ||
          o.nameAr === 'فيلا'
        ) {
          return o
        }
        return null
      })
    },
    md() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  methods: {
    ...mapActions('accountState', ['getAllClients']),
    ...mapMutations('appState', ['addNotification']),
    setUnitDetails() {
      if (this.isNewPropertyUnit) {
        this.addPropertyInfo.type = ''
      }
    },
    addFiles(e) {
      const selectedFiles = e.target ? e.target.files : ''
      if (selectedFiles) {
        for (const i of selectedFiles) {
          this.images.push(selectedFiles[i])
        }
        for (let i = 0; i < this.images.length; i++) {
          const reader = new FileReader()
          reader.onload = () => {
            this.$refs.image[i].src = reader.result
          }
          reader.readAsDataURL(this.images[i])
        }
      }
    },
    closeDialog() {
      this.$emit('close-add-dialog', 'close')
    },
    checkAddress(options) {
      this.addPropertyInfo.location = {
        coordinates: [options.place.lng, options.place.lat],
        address: options.place.address,
        type: 'Point',
        city: options.place?.city
      }
      this.tab0IsDisabled = !options.valid
    },

    validate() {
      this.$refs.form.validate()
      if (this.isValid === true) {
        this.tab++
      }
    },
    loadEmployees() {
      EnvService.getAllEmployee(this.currentEnv._id, '&registered=1')
        .then((res) => {
          this.employees = res.data.users
          const currentEmp = this.employees.find((e) => {
            return e._id === this.user._id
          })
          currentEmp.name += ` (أنا)`
        })
        .catch((err) => console.log('err', err))
    },
    async addRealEstate() {
      this.isLoading = true
      if (this.editPropetyId === 'new') {
        delete this.addPropertyInfo._id
      }
      if (this.addPropertyInfo.newUserChecked) {
        await this.addNewClient()
      }
      realEstateService
        .addRealEstate(this.infoToSend)
        .then((response) => {
          this.isLoading = false
          this.$parent.addState = true
          this.tab = 0
          if (
            this.addPropertyInfo.type.nameAr === 'عمارة' ||
            this.addPropertyInfo.type.nameAr === 'مجمع'
          ) {
            this.setParentId(response.data.realEstate._id)
          } else {
            this.$emit('close-add-dialog', 'close')
          }
          if (this.propertyInfo && this.isNewPropertyUnit) {
            this.$parent.getRealEstateDetails()
          }
        })
        .catch((error) => {
          this.addNotification({
            text: error.message || 'something went wrong',
            color: 'error'
          })
          this.isLoading = false
        })
    },
    async addNewClient() {
      const env = JSON.parse(localStorage.getItem('currentEnv'))
      const clientPayload = {
        name: this.newUser.name,
        idNumber: this.newUser.idNumber,
        phoneNumber: this.newUser.phoneNumber.replace(/\s/g, ''),
        clientType: this.newUser.clientType,
        taxNumber: this.newUser.tax,
        environment: {
          id: env._id,
          name: 'مالك عقار',
          role: ['owner']
        }
      }
      const newClient = await EnvService.addClient(clientPayload)
      this.addPropertyInfo.owner = newClient.data.user
    },
    newUserAdded(val) {
      this.newUser = val.obj
    },
    updateRealEstate() {
      this.isLoading = true
      this.infoToSend.environment = this.currentEnv._id
      realEstateService
        .updateRealEstate(this.infoToSend)
        .then(
          () => {
            this.isLoading = false
            this.addNotification({
              text: `تم تحديث بيانات  بنجاح`,
              color: 'success'
            })
            this.tab = 0
            this.$emit('close-add-dialog')
            this.$emit('refresh-properties-list')
          },
          (error) => {
            this.isLoading = false
            this.addNotification({
              text:
                error.response?.data?.errors?.[0] ||
                error.message ||
                'something went wrong',
              color: 'error'
            })
          }
        )
        .catch((error) => {
          this.addNotification({
            text:
              error.response?.data?.errors?.[0] ||
              error.message ||
              'something went wrong',
            color: 'error'
          })
          this.isLoading = false
        })
    },
    setParentId(val) {
      this.$parent.closeAddDialog('unit')
      this.$parent.parentId = val
    },
    async onSelectUser(user) {
      if (!user) {
        this.isOwner = false
        this.addPropertyInfo.owner = {}
        this.newUser = {}
      } else {
        this.addPropertyInfo.newUserChecked = false
        this.newUser = user
      }
    },
    onNewAgent(e) {
      if (e) {
        this.addPropertyInfo.owner = {}
        this.newUser = {}
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.pic-wrapper {
  border: 2px dashed #efebf2;
  border-radius: 7px;

  &:hover {
    border: 1px dashed $primary-color;
  }
}

.add-pic {
  width: 160px;
  height: 120px;
  border-radius: 10px;
}

.pic-icon {
  top: 8px;
  left: 8px;
  background: rgba(16, 16, 16, 0.5);
  padding: 3px 5px 2px;
  border-radius: 15px !important;
  font-size: 12px !important;
}
</style>
