<template>
  <v-dialog max-width="680px" persistent :value="dialogState">
    <!-------------------------------------->
    <!-------------Modal-------------------->
    <!-------------------------------------->

    <v-card class="add-new-property pa-0 add__customer__popup">
      <!-------------------------------------->
      <!-------------Head modal--------------->
      <!-------------------------------------->

      <v-card-title
        class="py-0 pr-4 pl-0 mb-4 d-flex justify-space-between pb-1 custom__shadow mobile__py__6px mobile__px__15px"
      >
        <!------------------Title-------------------->
        <h4 class="ma-0" v-text="actionBtnText" />
        <!-------------Close modal-------------------->
        <v-btn
          @click.prevent="closeModal"
          data-test="closeClientModal"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-5 pb-0">
        <v-form ref="form" v-model="isValid">
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col class="pl-3" cols="12" sm="6">
                <h6 class="mb-2 font-12">إسم العميل*</h6>
                <v-text-field
                  data-test="clientName"
                  v-model.trim="newUser.name"
                  :rules="clientNameRules"
                  dense
                  filled
                  hide-details
                  placeholder="إسم العميل"
                  required
                  class="font-14"
                ></v-text-field>
              </v-col>
              <v-col class="" cols="12" sm="6">
                <dropdown-alt
                  data-test="client-type"
                  ref="owner-type"
                  :configrations="{ name: 'نوع العميل*' }"
                  :listingType="clientType"
                  class="client--type--dropdown"
                  @filterTypes="getClientType"
                />
              </v-col>
              <v-col class="pl-3" cols="12" sm="6">
                <h6 class="mb-2 font-12">رقم الجوال*</h6>
                <vue-tel-input-vuetify
                  :loading="checkPhoneNumberLoader"
                  :error-messages="getPhoneNumberErrorMessage"
                  :success-messages="getPhoneNumberSuccessMesage"
                  class="phonenumber-field font-14"
                  v-model="newUser.phoneNumber"
                  label=""
                  :only-countries="['SA']"
                  default-country="SA"
                  ref="regPhoneNumber"
                  placeholder="رقم الجوال"
                  :filled="true"
                  required
                  dense
                  suffix="+966"
                  :rules="[
                    (v) => (v && !!isValidPhone) || 'من فضلك تأكد من رقم الواتس'
                  ]"
                  :valid-characters-only="true"
                  @keydown="$formatNumberInputs"
                  @input="formatNumberAExistance($event, 'phoneNumber')"
                  @validate="onValidatePhoneNumber"
                />
              </v-col>
              <v-col class="pl-3" cols="12" sm="6">
                <h6 class="mb-2 font-12">رقم الواتس اب</h6>
                <v-text-field
                  data-test="clientName"
                  dense
                  filled
                  placeholder="رقم الواتس اب"
                  :rules="phoneNumberRules"
                  class="font-14"
                  :value="whatsAppNumberMask"
                  @keydown="$formatNumberInputs"
                  @input="formatWhatsAppNumber($event, 'whatsAppNumber')"
                />
              </v-col>
              <v-col class="pl-3" cols="12" sm="6">
                <h6 class="mb-2 font-12 mt-0">البريد الإلكتروني</h6>
                <v-text-field
                  data-test="clientName"
                  dense
                  filled
                  @input="formatNumberAExistance($event, 'email')"
                  placeholder="البريد الإلكتروني"
                  class="font-14"
                  :rules="emailRules"
                  v-model="newUser.email"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <h6 class="mb-2 font-12 mt-0">صفة العميل*</h6>
                <v-col
                  class="pt-0 mobile__p__0 employee--select--col pr-0"
                  cols="12"
                  sm="12"
                >
                  <v-select
                    ref="employees"
                    :menu-props="{
                      transition: 'slide-y-transition',
                      bottom: true,
                      offsetY: true
                    }"
                    v-model="newUser.roles"
                    :items="clientRoles"
                    placeholder="صفة العميل"
                    append-icon="mdi-chevron-down"
                    dense
                    chips
                    item-text="nameAr"
                    item-value="code"
                    :rules="selectRules"
                    filled
                    clearable
                    class="font-14"
                    multiple
                    hide-details
                  >
                  </v-select>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-2 px-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-0 rounded-7 my-1 d-flex px-3"
          :disabled="!disableSubmit"
          :loading="addLoadingStatus"
          @click="addOrEdit"
        >
          <span class="mb-1 mobile__mb__0 ml-1 font-weight-bold mt-1 font-12">
            إضافة
          </span>
          <v-icon class="send-icon font-16 mt-1">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DropdownAlt from '@/components/add-unit/dropdownAlt'
import { debounce } from '@/helpers/debounce'
import { convertNumbers2English } from '@/utils/formatters'
import { EnvService } from '@/services'
import { mapState } from 'vuex'
import { buildQueryString, isValidEmail } from '@/utils/general'
import { clientType } from '@/constans/ptClients'
export default {
  name: 'AddPTModal',
  components: {
    DropdownAlt
  },
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    PTTypes: {
      type: Array,
      default: () => []
    },
    addLoadingStatus: {
      default: false,
      type: Boolean
    },
    ptTypes: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      emailSuccessMesage: '',
      selectRules: [(v) => !!v?.length || 'هذا الحقل مطلوب'],
      emailErrorMessage: '',
      phoneNumberErrorMessage: '',
      phoneNumberSuccessMesage: '',
      emailValid: false,
      phoneNumberValid: false,
      phoneData: {},
      phoneNumberRules: [
        (v) =>
          /^[0-9]*$/.test(v) ||
          (v && v.length === 10) ||
          !v ||
          'صيغة رقم الهاتف غير صحيحة'
      ],
      emailRules: [
        (v) => {
          if (v) {
            return /.+@.+\..+/.test(v) || 'من فضلك أدخل بريد الكتروني صحيح'
          }
          return true
        }
      ],
      checkPhoneNumberLoader: false,
      checkEmailLoader: false,
      isLoading: false,
      isValid: false,
      whatsAppNumberMask: '',
      newUser: {},
      clientNameRules: [(v) => !!v?.trim() || 'إسم العميل'],
      clientType,
      clientRoles: [
        { code: 'owner', nameAr: 'مالك' },
        { code: 'renter', nameAr: 'مستأجر' }
      ]
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'currentEnv']),
    actionBtnText() {
      return 'اضافة عميل'
    },
    disableSubmit() {
      return this.isValid
    },
    isValidPhone() {
      return this.phoneData.isValid
    },
    getPhoneNumberErrorMessage() {
      return this.phoneNumberErrorMessage || ''
    },
    getPhoneNumberSuccessMesage() {
      return this.phoneNumberSuccessMesage || ''
    },
    getEmailSuccessMesage() {
      return this.emailSuccessMesage || ''
    },
    newClient() {
      return {
        name: this.newUser.name,
        phoneNumber: (this.newUser.phoneNumber ?? '')
          .replace(/\s/g, '')
          .replace(/^0/, '966'),
        ...(this.newUser.email && { email: this.newUser.email }),
        clientType: this.newUser.clientType,
        environment: {
          _id: this.currentEnv._id,
          name: this.currentEnv.name,
          role: this.newUser.roles,
          whatsAppNumber: this.client?.whatsAppNumber
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal', 'close')
    },
    getClientType({ code }) {
      this.newUser.clientType = code
    },
    addOrEdit() {
      // emit newUser
      this.$emit('add-new-user', this.newClient)
      this.newUser = {}
    },
    onValidatePhoneNumber(val) {
      this.phoneData = val
    },
    debounceCheckUserExistance: debounce(async function (val, key) {
      await this.checkUserExistance(key)

      key === 'phoneNumber' &&
        (this.whatsAppNumberMask = this.newUser.whatsAppNumber =
          val.replace(/ /g, ''))
    }, 500),
    formatNumberAExistance(val, key) {
      this.newUser[key] = convertNumbers2English(val)
      if (key === 'phoneNumber' || key === 'email') {
        this.debounceCheckUserExistance(val, key)
      }
    },
    formatWhatsAppNumber(val) {
      this.whatsAppNumberMask = convertNumbers2English(val)
    },
    checkUserExistance(key) {
      const user = this.newUser
      const phoneNumber =
        user.phoneNumber?.replace(/\s/g, '').replace(/^0/, '966') ?? ''
      const isPhoneNumber = key === 'phoneNumber'
      const isEmail = key === 'email'

      if (isPhoneNumber && phoneNumber.length !== 12) {
        this.setPhoneError('من فضلك تأكد من رقم الهاتف')
        return
      }

      if (isEmail && !this.isValidEmail(user.email)) {
        this.setEmailError('من فضلك تأكد من البريد الإلكتروني')
        return
      }

      if (this.isValidPhone || isEmail) {
        const loaderKey = isPhoneNumber
          ? 'checkPhoneNumberLoader'
          : 'checkEmailLoader'

        this.resetMessages()
        this.toggleLoader(loaderKey, true)
        // const query = {
        //   env: this.currentEnv._id,
        //   role: 'admin',
        //   phoneNumber,
        //   ...(isEmail && { email: user.email })
        // }

        EnvService.checkEmployee(
          'admin',
          this.currentEnv._id,
          phoneNumber,
          user.email
        )
          .then((response) => {
            this.handleSuccessResponse(response, isPhoneNumber)
          })
          .catch((error) => {
            this.handleErrorResponse(error, isPhoneNumber)
          })
          .finally(() => {
            this.toggleLoader(loaderKey, false)
          })
      }
    },
    setPhoneError(message) {
      this.phoneNumberErrorMessage = message
      this.phoneNumberValid = false
    },
    isValidEmail(email) {
      return isValidEmail(email)
    },
    setEmailError(message) {
      this.emailErrorMessage = message
      this.emailValid = false
    },
    resetMessages() {
      this.phoneNumberErrorMessage = this.emailErrorMessage = ''
      this.phoneNumberSuccessMesage = this.emailSuccessMesage = ''
    },
    toggleLoader(key, state) {
      this[key] = state
    },
    handleSuccessResponse(response, isPhoneNumber) {
      if (response.data.exists) {
        this.handleErrorResponse(response, isPhoneNumber)
        return
      }
      if (isPhoneNumber && !response.data.exists) {
        this.phoneNumberSuccessMesage = 'العميل متاح للإضافه'
        this.phoneNumberValid = true
      } else if (!isPhoneNumber) {
        this.emailValid = true
        this.emailSuccessMesage = 'البريد متاح للدعوه'
      }
    },
    handleErrorResponse(error, isPhoneNumber) {
      const errorMessage = isPhoneNumber
        ? ' الرقم مُسجل بإسم عميل أخر'
        : ' البريد مُسجل بإسم عميل أخر'
      if (isPhoneNumber) {
        this.setPhoneError(errorMessage)
      } else {
        this.setEmailError(errorMessage)
      }
    }
  }
}
</script>
