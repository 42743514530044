<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex justify-space-between align-center mb-2 mt-6 position-relative"
    >
      <div class="d-flex justify-start flex-wrap">
        <div class="d-flex align-center">
          <v-menu
            v-if="smAndUp"
            content-class="vMenuFilter elevation-0"
            offset-y
            transition="slide-y-transition"
            width="150"
            ref="contractsType"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center">
                <v-slide-x-transition>
                  <v-card
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex justify-space-between px-2"
                    width="110"
                  >
                    <h6
                      class="mr-2 text-truncate"
                      v-if="filterTypeCodes.length"
                    >
                      {{ getActiveFilter }}
                    </h6>
                    <h6 class="mr-2" v-else>حالة العقد</h6>
                    <v-icon
                      >{{
                        menuStateSwitcher($refs.contractsType)
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-card>
                </v-slide-x-transition>
              </div>
            </template>
            <v-card width="400">
              <v-row no-gutters align="center" class="pa-3">
                <v-col class="py-0" cols="6">
                  <h6
                    :class="{
                      'brub--tag-border': !filterTypeCodes.length
                    }"
                    @click="resetTypeFilter"
                    class="text-center roundeed-tag px-2 py-1 cursor ml-1"
                  >
                    الكل
                  </h6>
                </v-col>
                <v-col
                  class="py-0"
                  cols="6"
                  v-for="type in contractsStatuses"
                  :key="type._id"
                >
                  <h6
                    :class="{
                      'brub--tag-border': filterTypeCodes.includes(type.code)
                    }"
                    @click="handleChangeTypeFilter(type)"
                    class="text-center roundeed-tag px-2 py-1 cursor ml-1"
                  >
                    {{ type.nameAr }}
                  </h6>
                </v-col>
                <v-col md="12" class="text-left">
                  <a
                    @click="resetTypeFilter"
                    data-test="rest-filter"
                    class="text-decoration-underline font-12 black--text"
                    >إعادة تعيين</a
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
          <v-slide-x-transition>
            <v-card
              :class="{
                'w-100': sm,
                'mx-0 px-1': !smAndUp,
                'mx-3 px-2': smAndUp
              }"
              class="d-flex justify-space-around xxl__ws__nowrap"
            >
              <h6
                :class="{ 'border-black': activeFilter == filter.nameEn }"
                @click="handleFilterChange(filter)"
                class="cursor mx-2 mobile__mx__4px d-flex align-center"
                v-for="(filter, i) in availableFilters"
                :key="i"
              >
                {{ filter.nameAr }}
                <span class="propCounter px-2 mr-1 font-12">
                  {{ filter.count }}
                </span>
              </h6>
            </v-card>
          </v-slide-x-transition>
          <!-- For multiple selections -->
          <slot />
        </div>
        <div class="mr-1 d-flex" v-if="!mdAndDown && !isCustomer">
          <span @click="handleLoadData('isClientsCalled', 'loadClients')">
            <autocomplete
              style="width: 175px"
              v-bind="clientProps"
              @nextPageTrigger="getClientNextData"
              @emitSearchKey="getClientDataBySearchKey"
              @input="clientChanged"
              class="contract__page__dropdown"
            />
          </span>
          <span @click="handleLoadData('isPropertiesCalled', 'loadProperties')">
            <autocomplete
              class="mr-3 contract__page__dropdown"
              v-bind="propertiesProps"
              style="width: 175px"
              @nextPageTrigger="getPropertyNextData"
              @emitSearchKey="getPropertyDataBySearchKey"
              @input="realEstateChanged"
            />
          </span>
        </div>
      </div>
      <div class="pa-0 d-flex" v-if="!sm">
        <h6 class="font-weight-light ml-2">ترتيب بواسطة</h6>
        <v-menu
          content-class="vMenuFilter elevation-0"
          offset-y
          transition="slide-y-transition"
          width="150"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center">
              <v-slide-x-transition>
                <v-card
                  v-on="on"
                  v-bind="attrs"
                  class="d-flex justify-space-between px-2"
                  width="110"
                  @click="toggleShowSortOptions"
                >
                  <h6 class="mr-2">
                    {{ currentSort }}
                  </h6>
                  <v-icon
                    >{{
                      showSortOptions ? 'mdi-chevron-up' : 'mdi-chevron-down'
                    }}
                  </v-icon>
                </v-card>
              </v-slide-x-transition>
            </div>
          </template>
          <v-list
            class="contractVmenuList pa-0"
            v-for="(option, i) in sortOptions"
            :key="i"
            :width="110"
          >
            <v-list-item-group>
              <v-list-item class="px-1">
                <v-list-item-title
                  @click="handleSortChange(option.nameEn)"
                  class="font-14 mr-1 mb-2 font-weight-medium t"
                >
                  {{ option.nameAr }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-slide-x-transition>
          <v-card
            width="72"
            height="40"
            class="d-flex justify-space-between px-2 mr-3"
          >
            <img
              class="cursor"
              :src="gridView ? colIconColored : colIcon"
              width="20"
              @click="setGridView(true)"
              loading="lazy"
            />
            <img
              class="cursor"
              :src="gridView ? rowIcon : rowIconColored"
              width="20"
              @click="setGridView(false)"
              loading="lazy"
            />
          </v-card>
        </v-slide-x-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { realEstateService, EnvService } from '@/services'
import autocomplete from '@/components/forms/autocomplete'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'contracts-filters',
  components: {
    autocomplete
  },
  props: {
    availableFilters: {
      type: Array,
      required: true
    },
    sortOptions: {
      type: Array,
      required: true
    },
    gridView: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // Icons
      colIconColored: require('@/assets/img/svgs/colIconColored.svg'),
      colIcon: require('@/assets/img/svgs/colIcon.svg'),
      rowIcon: require('@/assets/img/svgs/rowIcon.svg'),
      rowIconColored: require('@/assets/img/svgs/rowIconColored.svg'),

      showSortOptions: false,
      activeFilter: 'count',
      filterQuery: {
        types: null,
        sort: 'Newest',
        users: null,
        realEstate: null,
        statuses: null
      },
      currentEnv: JSON.parse(localStorage.getItem('currentEnv')),

      // Clients autocomplete
      isClientsCalled: false,
      clientSearchKey: '',
      clients: [],
      allClients: [],
      client: {},
      noData: 'لا يوجد عملاء متاحه لهذا البحث',
      selectedClientPlaceholder: 'العملاء',
      isClientsLoading: false,
      paginationClient: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },

      // Properties autocomplete
      isPropertiesCalled: false,
      propertySearchKey: '',
      properties: [],
      allProperties: [],
      property: {},
      noProperties: 'لا توجد عقارات متاحه لهذا البحث',
      selectedPropertyPlaceholder: 'العقارات',
      isPropertiesLoading: false,
      paginationProperty: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      filterTypeCodes: []
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer', 'contractsStatuses']),
    ...mapGetters('appState', ['getClientForRoute', 'getClientTypeForRoute']),
    getActiveFilter() {
      const activeFilter = this.contractsStatuses[this.filterTypeCodes[0]]
      return activeFilter.nameAr
    },
    clientProps() {
      return {
        itemData: true,
        items: this.allClients,
        noData: this.noData,
        placeholder: this.selectedClientPlaceholder,
        returnObject: true,
        hideDetails: true,
        rules: [true],
        loading: this.isClientsLoading,
        value: this.client
      }
    },
    propertiesProps() {
      return {
        itemData: true,
        itemText: 'propertyName',
        items: this.allProperties,
        noData: this.isPropertiesLoading
          ? 'جاري البحث عن عقارات...'
          : this.noProperties,
        placeholder: this.selectedPropertyPlaceholder,
        returnObject: true,
        hideDetails: true,
        rules: [true],
        loading: this.isPropertiesLoading,
        value: this.property
      }
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    smAndUp() {
      return this.$vuetify.breakpoint.smAndUp
    },
    currentSort() {
      return this.sortOptions.find(
        (sort) => sort.nameEn === this.filterQuery.sort
      ).nameAr
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    menuStateSwitcher(menu) {
      return menu?.isActive
    },
    resetTypeFilter() {
      if (this.filterQuery.statuses === null) {
        return
      }
      this.filterTypeCodes = []
      this.filterQuery.statuses = null
      this.$emit('handleFilters', this.filterQuery)
    },
    handleChangeTypeFilter({ code }) {
      const isItSelected = this.filterTypeCodes.find((item) => item === code)
      if (isItSelected) {
        this.filterTypeCodes = this.filterTypeCodes.filter(
          (item) => item !== code
        )
        this.filterQuery.statuses = [...this.filterTypeCodes]
        if (!this.filterTypeCodes.length) {
          this.resetTypeFilter()
          return
        }
      } else {
        this.filterTypeCodes = [code]
        this.filterQuery.statuses = [...this.filterTypeCodes]
      }
      this.$emit('handleFilters', this.filterQuery)
    },
    filterContractType() {
      // Load contract selected types
    },
    handleLoadData(isCalled, methodToBeCalled) {
      // to call data for only first time
      if (!this[`${isCalled}`]) {
        this[`${methodToBeCalled}`]()
        this[`${isCalled}`] = !this[`${isCalled}`]
      }
    },
    toggleShowSortOptions() {
      this.showSortOptions = !this.showSortOptions
    },
    setGridView(payload) {
      if (this.gridView === payload) {
        return
      }
      this.$emit('gridViewChanged', payload)
    },
    handleFilterChange(filter) {
      if (filter.nameEn === 'count') {
        this.filterQuery = { ...this.filterQuery, types: null }
      } else {
        this.filterQuery = {
          ...this.filterQuery,
          types: [filter.nameEn]
        }
      }
      this.activeFilter = filter.nameEn
      this.$emit('handleFilters', this.filterQuery)
    },
    handleSortChange(sort) {
      if (sort === this.filterQuery.sort) {
        return
      }
      this.filterQuery = {
        ...this.filterQuery,
        sort
      }
      this.$emit('handleFilters', this.filterQuery)
    },
    async loadClients(query = '') {
      this.isClientsLoading = true
      let data = ''
      if (this.clientSearchKey) {
        data += `&search=${this.clientSearchKey}`
      } else {
        data += `pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}`
      }
      try {
        const res = await EnvService.getAllClients(
          this.currentEnv._id,
          query,
          data
        )
        this.paginationClient.pagesCount = Math.ceil(
          res?.data?.count / this.paginationClient.pageSize
        )
        this.paginationClient.count = res?.data?.count ?? 0
        this.clients = res?.data?.users ?? []
        this.allClients = [...this.allClients, ...this.clients]

        // Filter by client
        if (this.getClientTypeForRoute !== '' && this.$route.query.id) {
          this.allClients.push(this.getClientForRoute)
          this.clientChanged(this.getClientForRoute)
        }
      } catch {
        this.addNotification({
          text: 'خدث خطأ أثناء تحميل العملاء',
          color: 'error'
        })
      } finally {
        this.isClientsLoading = false
      }
    },
    getClientNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.loadClients()
      }
    },
    getClientDataBySearchKey(e) {
      if (e) {
        this.clientSearchKey = e
        this.loadClients()
      }
    },
    async loadProperties() {
      this.isPropertiesLoading = true
      let data = ''
      if (this.propertySearchKey) {
        data += `&search=${this.propertySearchKey}`
      } else {
        data += `pageSize=${this.paginationProperty.pageSize}&pageNumber=${this.paginationProperty.pageNumber}`
      }
      const filters = {
        account: this.currentEnv?.owner?.id
      }
      if (this.client?._id) {
        filters.user = this.client._id
      }
      try {
        const res = await realEstateService.getRealEstates(filters, data)
        this.paginationProperty.pagesCount = Math.ceil(
          res?.data?.count / this.paginationProperty.pageSize
        )
        this.paginationProperty.count = res?.data?.count ?? 0
        this.properties = res?.data?.realEstates ?? []
        this.allProperties = [...this.allProperties, ...this.properties]
      } catch {
        this.addNotification({
          text: 'خدث خطأ أثناء تحميل العقارات',
          color: 'error'
        })
      } finally {
        this.isPropertiesLoading = false
      }
    },
    getPropertyNextData() {
      if (
        this.paginationProperty.pageNumber < this.paginationProperty.pagesCount
      ) {
        this.paginationProperty.pageNumber += 1
        this.loadProperties()
      } else {
      }
    },
    getPropertyDataBySearchKey(e) {
      if (e) {
        this.propertySearchKey = e
        this.loadProperties()
      }
    },
    clientChanged(client) {
      this.client = client
      const clientFiltering = {
        users: client?._id ?? null,
        realEstate: null
      }
      this.filterQuery = { ...this.filterQuery, ...clientFiltering }
      this.$emit('handleFilters', this.filterQuery)
      this.paginationProperty.pageNumber = 1
      this.allProperties = []
      if (this.property?._id) {
        this.property = {}
      }
      this.loadProperties()
    },
    realEstateChanged(realEstate) {
      this.property = realEstate
      this.filterQuery = {
        ...this.filterQuery,
        realEstate: realEstate?._id ?? null
      }
      this.$emit('handleFilters', this.filterQuery)
    }
  }
}
</script>
