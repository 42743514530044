<template>
  <div class="quotation--paper2 quotation--paper4">
    <h1>دفعات الإيجار</h1>
    <h2 class="ma-0">معلومات وقيمة دفعات الإيجار</h2>
    <div
      v-if="quotation.details?.insts.length"
      class="quotation--paper3__table"
    >
      <div
        class="quotation--paper3__table__row quotation--paper3__table__row--mod d-flex"
      >
        <div>
          <p>{{ quotation.details.insts.length }} دفعة</p>
        </div>
        <div>
          <p>{{ annual.nameAr }}</p>
        </div>
      </div>

      <!--  -->
      <div
        v-for="(inst, i) in quotation.details?.insts"
        :key="i"
        class="quotation--paper4__table__row d-flex"
      >
        <div>
          <div>
            <p>{{ i + 1 }}</p>
          </div>
          <div>
            <p>{{ inst?.payment?.toLocaleString() }} ريال</p>
          </div>
        </div>
        <div>
          <div>
            <p>{{ inst?.date | formatDate }}</p>
          </div>
          <div>
            <p>{{ inst?.date?.split('-')[0] }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Paper4',
  props: {
    quotation: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      intervals: [
        {
          _id: '6114ed4be2f4d556425f5a0b',
          nameEn: 'daily',
          nameAr: 'يومي',
          __v: 0,
          value: 0.0333
        },
        {
          _id: '6114ed4be2f4d556425f5a0c',
          nameEn: 'monthly',
          nameAr: 'شهري',
          __v: 0,
          value: 1
        },
        {
          _id: '6486f9c7d37b34d8ae26dd4b',
          nameEn: '2 months',
          nameAr: 'شهرين',
          value: 2
        },
        {
          _id: '6114ed83e2f4d556425f5a0d',
          nameEn: 'quarterly',
          nameAr: 'ربع سنوي',
          __v: 0,
          value: 3
        },
        {
          _id: '6114ed94e2f4d556425f5a0e',
          nameEn: 'biannual',
          nameAr: 'نصف سنوي',
          __v: 0,
          value: 6
        },
        {
          _id: '6114eda7e2f4d556425f5a0f',
          nameEn: 'yearly',
          nameAr: 'سنوي',
          __v: 0,
          value: 12
        }
      ]
    }
  },
  filters: {
    formatDate(value) {
      return new Date(value).toLocaleDateString('ar-AS', {
        month: 'long',
        day: 'numeric'
      })
    }
  },
  computed: {
    annual() {
      return this.intervals[this.quotation.details.insts.length]
    }
  }
}
</script>

<style lang="scss" scoped></style>
