<template>
  <v-data-table
    :class="{ 'hectar-table-sm': tableSm }"
    style="cursor: pointer"
    :items-per-page="users.length"
    :headers="headers"
    :items="users"
    class="hectar-table clients__list__view"
    hide-default-footer
    mobile-breakpoint="0"
    @click:row="handleClick($event)"
  >
    <template v-slot:[`item.checked`]="{ item }">
      <v-checkbox
        v-if="isDeletable(item)"
        v-model="item.checked"
        @click.stop
        class="reset-all ma-0"
        dense
        hide-details
      />
    </template>
    <!-- color tag -->
    <template v-slot:[`item.environments`]="{ item }">
      <div v-if="item && item.environments">
        <div v-if="!item.environments[0].role.length">-</div>
        <div v-else class="d-flex flex-grow-0">
          <div
            class="mx-2"
            v-for="role in item.environments[0].role"
            :key="role"
          >
            <h6 :class="setRoleLabelStyle(role)">
              {{ formatRole(role) }}
            </h6>
          </div>
        </div>
      </div>
      <div v-else-if="item.type">
        <h5 :class="setRoleLabelStyle(item.type)">
          {{ formatUserRole(item.type) }}
        </h5>
      </div>
    </template>
    <template v-slot:[`item.clientType`]="{ item }">
      <div v-if="item" class="d-flex flex-grow-0">
        <h6>
          {{ item.clientType === 'individual' ? 'فرد' : 'شركه' }}
        </h6>
      </div>
    </template>
    <template v-slot:[`item.clientNumber`]="{ item }">
      <div v-if="item" class="d-flex flex-grow-0">
        <h6>
          {{ item.clientNumber ?? '-' }}
        </h6>
      </div>
    </template>
    <template v-slot:[`item.idNumber`]="{ item }">
      <div v-if="item" class="d-flex flex-grow-0">
        <h6>{{ item.idNumber ?? item.registrationNumber ?? 'لا يوجد' }}</h6>
      </div>
    </template>
    <template
      v-if="$hasPermission('users', 'clients', 'list')"
      v-slot:[`item.name`]="{ item }"
    >
      {{ item.name }}
    </template>
    <template v-slot:[`item.payments`]="{ item }">
      <div v-if="item && paymentsIsDefined(item)" class="d-flex flex-grow-0">
        <img
          alt="arrow_dwon"
          class="ml-2"
          src="@/assets/img/svgs/arrow-down.svg"
          loading="lazy"
        />
        <h6>{{ generateOverdues(item) }}</h6>
      </div>
    </template>
    <template v-slot:[`item.active`]="{ item }">
      <div
        :class="[
          'tabel-tag',
          'red--tag',
          'text-center',
          'font-12',
          'font-weight-bold',
          'd-flex',
          'align-center',
          setStatusClass(item)
        ]"
      >
        <v-sheet
          v-if="item.active"
          class="dotted ma-0 pa-0 ml-2"
          color="#65C467"
        >
        </v-sheet>
        <v-sheet v-else class="dotted ma-0 pa-0 ml-2" color="#fc4a4a">
        </v-sheet>

        <span class="mb-1">{{ item.active ? 'مفعّل' : 'معطّل' }}</span>
      </div>
    </template>
    <template v-slot:[`item.invite`]="{ item }">
      <!-- If not invited and not registred as a customer-->
      <div
        class="d-flex flex-grow-0 justify-center"
        v-if="checkInvitationAvailability(item)"
      >
        <v-btn
          @click.stop="emitInvitation(item)"
          class="elevation-0 rounded-7"
          color="primary"
        >
          <span class="font-14 font-weight-bold mb-1">
            دعوة العميل لبوابة العملاء
          </span>
        </v-btn>
      </div>

      <!-- If registered as a customer or invited -->
      <div v-else class="d-flex flex-grow-0 justify-center">
        <v-btn class="elevation-0" rounded style="pointer-events: none">
          <span class="brub--flag font-14 font-weight-bold mb-1">
            {{ getInvitationStatus(item) }}
          </span>
          <v-img
            v-if="item.registered"
            width="15px"
            class="ml-1 mr-2"
            :src="checkPrimary"
          />
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex'
import numberFormatter from '@/mixins/numberFormatter.js'

export default {
  name: 'data-table-client',
  props: {
    users: {},
    headers: {},
    tableSm: {
      defaults: false
    }
  },
  data() {
    return {
      checkPrimary: require('@/assets/img/svgs/check-purple.svg')
    }
  },
  mixins: [numberFormatter],
  computed: {
    ...mapState('accountState', ['user', 'currentEnv'])
  },
  methods: {
    getActiveEnv(item) {
      const activeEnv = item.environments.find((env) => {
        return env._id === this.currentEnv?._id
      })
      return activeEnv
    },
    checkInvitationAvailability(item) {
      const { customerInvited = false, customerRegistered = false } =
        this.getActiveEnv(item)
      return !customerInvited && !customerRegistered
    },
    getInvitationStatus(item) {
      const { customerInvited = false, customerRegistered = false } =
        this.getActiveEnv(item)
      return customerInvited && !customerRegistered
        ? 'تم إرسال الدعوة'
        : 'مشترك في بوابة العملاء'
    },
    formatUserRole(role) {
      // get the config options
      const clientTypes = JSON.parse(
        localStorage.getItem('configrations')
      ).clientTypes
      const clientType = clientTypes.find((c) => {
        return c.code === role
      })

      return clientType?.nameAr
    },
    emitInvitation(item) {
      this.$emit('setCustomerData', item)
    },
    formatRole(role) {
      // get the config options
      const clientTypes = JSON.parse(
        localStorage.getItem('configrations')
      ).clientTypes
      const clientType = clientTypes.find((c) => {
        return c.code === role
      })

      return clientType?.nameAr ?? 'مالك'
    },
    setRoleLabelStyle(role) {
      let style = 'tabel-tag text-center font-12 px-4 '
      switch (role) {
        case 'renter':
          style += 'brub--tag'
          break
        case 'owner':
          style += 'orange--tag'
          break
        case 'buyer':
          style += 'green--tag'
          break

        default:
          style += 'blue--tag'
          break
      }
      return style
    },
    setCall(num) {
      window.open(`tel:${num}`)
    },
    generateOverdues(user) {
      if (typeof user.payments === 'object') {
        return `${user.payments[Object.keys(user.payments)[0]]} | ${
          user.payments[Object.keys(user.payments)[1]]
        }`
      }
      return this.numberFormatter(user.payments, 2, true)
    },
    paymentsIsDefined(user) {
      return this.generateOverdues(user)
    },
    handleClick(item) {
      this.$router.push(`/dashboard/client/${item._id}`)
    },
    isDeletable(client) {
      if (
        client._id === this.user._id ||
        (client.environments?.length && client.environments[0]?.isOwner) ||
        !this.$hasPermission('users', 'clients', 'delete')
      ) {
        return false
      }
      return true
    },
    setStatusClass(user) {
      if (user.active) {
        return 'green--tag'
      }
      ;('gery--tag')
    }
  }
}
</script>
