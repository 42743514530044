import Vue from 'vue'
import Vuex from 'vuex'
import pathify from './pathify'

import modules from './modules'

if (process.env.NODE_ENV === 'development') {
  pathify.debug()
}

Vue.use(Vuex)

const state = {}

const getters = {}

const mutations = {}

const actions = {}

const store = new Vuex.Store({
  plugins: [pathify.plugin],
  state,
  mutations,
  actions,
  getters,
  modules,
  strict: process.env.NODE_ENV !== 'production'
})

export default store
