import { render, staticRenderFns } from "./InvitationModel.vue?vue&type=template&id=3d620326&scoped=true"
import script from "./InvitationModel.vue?vue&type=script&lang=js"
export * from "./InvitationModel.vue?vue&type=script&lang=js"
import style0 from "./InvitationModel.vue?vue&type=style&index=0&id=3d620326&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d620326",
  null
  
)

export default component.exports