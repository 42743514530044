<template>
  <v-dialog v-model="dialogState_" persistent max-width="490">
    <v-card class="add-new-property">
      <v-btn
        fab
        text
        color="grey"
        class="ma-2 position-absolute"
        style="left: 0"
        @click.prevent="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="text-center pt-16 pb-10">
        <AnimatedCheckmark type="check"></AnimatedCheckmark>

        <template>
          <h4 class="green--text">تم إظهار العقار في هكتار</h4>
          <p class="">
            كي يظهر العقار بشكل مناسب على هكتار، من فضلك <br />
            أكمل بيانات العقار على هكتار
          </p>
          <v-btn x-large rounded min-width="300" class="ma-0" color="success">
            <v-icon class="send-icon ml-2">mdi-home-edit-outline</v-icon>
            <span class="tf">أكمل بيانات العقار الآن</span>
          </v-btn>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import '@/styles/material-dashboard/_add-new-property.scss'
import AnimatedCheckmark from '@/components/helper/AnimatedCheckmark'

export default {
  props: ['dialogState'],
  components: {
    AnimatedCheckmark
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog')
    }
  },
  computed: {
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  }
}
</script>
