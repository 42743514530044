<template>
  <div>
    <v-card class="py-5 px-5" v-if="generalReportsHeaderData.length">
      <v-row no-gutters>
        <v-col
          v-for="data in generalReportsHeaderData"
          :key="data.title"
          sm="3"
          cols="6"
        >
          <h6 class="grey--text font-16">
            {{ data.title }}
            <h6 class="mt-4 black--text font-weight-bold font-16">
              {{ data.value }}
            </h6>
          </h6>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'reportHeaderCard',
  props: {
    generalReportsHeaderData: {
      default() {
        return []
      },
      required: true
    }
  }
}
</script>
