import { render, staticRenderFns } from "./models.vue?vue&type=template&id=4a5ce482&scoped=true"
import script from "./models.vue?vue&type=script&lang=js"
export * from "./models.vue?vue&type=script&lang=js"
import style0 from "./models.vue?vue&type=style&index=0&id=4a5ce482&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a5ce482",
  null
  
)

export default component.exports