<template>
  <v-card class="property-card" :class="classes" v-if="options">
    <v-list-item
      class="card-title"
      @click="usedFor != 'preview' ? goToProperty(options._id) : ''"
    >
      <v-list-item-content>
        <v-list-item-title class="headline mb-1"
          >{{ options.type.nameAr + ' ' + options.status.nameAr }}
        </v-list-item-title>
      </v-list-item-content>

      <!-- Aqar num -->
      <v-list-item dense class="aqar-num" v-if="gridView">
        <v-list-item-content>
          <v-list-item-title v-if="usedFor == 'property'">{{
            options._id
          }}</v-list-item-title>
          <v-list-item-title
            v-else-if="
              usedFor == 'unit' || (usedFor == 'preview' && nameOfUnit)
            "
          >
            {{ nameOfUnit }}
          </v-list-item-title>
          <v-list-item-title v-else-if="usedFor == 'preview' && !nameOfUnit"
            >xxx</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list-item>

    <div class="property-info">
      <!-- Moderator -->
      <v-list-item
        dense
        v-if="options.moderator && options.moderator.name"
        class="moderator-cell"
      >
        <v-list-item-content>
          <v-list-item-title>{{ options.moderator.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Price -->
      <v-list-item
        dense
        v-if="usedFor == 'unit' && options.price"
        class="price-cell"
      >
        <v-list-item-content>
          <v-list-item-title>{{ options.price }} ريال</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Aqar num -->
      <v-list-item dense v-if="!gridView" class="list-num-cell">
        <v-list-item-content>
          <v-list-item-title>{{ options._id }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Address -->
      <v-list-item
        dense
        v-if="usedFor == 'property' || usedFor == 'preview'"
        class="address-cell"
      >
        <v-list-item-content>
          <v-list-item-title
            v-text="
              newUnitAddress && newUnitAddress.address
                ? newUnitAddress.address
                : options.address.address
            "
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <v-menu
      v-if="!gridView"
      content-class="dropdown-menu"
      transition="slide-x-transition"
      bottom
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          :loading="isDeleteLoading"
          class="mr-5 options-btn"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="goToProperty(options._id)"
          v-if="$hasPermission('realestates', 'realestates', 'list')"
        >
          <v-list-item-icon>
            <v-icon>mdi-eye-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>تفاصيل {{ propertyName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$hasPermission('realestates', 'realestates', 'update')"
          @click="editProperty(options._id)"
        >
          <v-list-item-icon>
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>تعديل {{ propertyName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="$hasPermission('realestates', 'realestates', 'delete')"
          @click="deleteConfirm"
        >
          <v-list-item-icon>
            <v-icon color="danger">mdi-trash-can-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="danger--text"
              >حذف {{ propertyName }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!--gridView-->
    <v-card-actions v-if="gridView">
      <v-btn
        :to="`/dashboard/single-property/${options._id}/0`"
        color="primary"
        rounded
        text
        v-if="$hasPermission('realestates', 'realestates', 'list')"
      >
        <span class="tf">تفاصيل {{ propertyName }}</span>
      </v-btn>

      <v-spacer></v-spacer>

      <template>
        <div v-if="$hasPermission('realestates', 'realestates', 'update')">
          <v-btn
            icon
            color="grey"
            v-if="usedFor == 'property'"
            @click="editProperty(options._id)"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </div>

        <v-btn icon color="grey">
          <v-icon>mdi-share-variant</v-icon>
        </v-btn>

        <v-btn
          v-if="$hasPermission('realestates', 'realestates', 'delete')"
          icon
          color="error"
          :loading="isDeleteLoading"
          @click="deleteConfirm"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { realEstateService } from '../../services'

export default {
  props: [
    'usedFor',
    'classes',
    'gridView',
    'options',
    'nameOfUnit',
    'propertyName',
    'parentId',
    'newUnitAddress'
  ],
  data() {
    return {
      deleteDialog: false,
      isDeleteLoading: false
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'currentEnv'])
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    deleteConfirm() {
      this.$root.$confirm
        .open({
          title: `حذف ${this.propertyName}`,
          message: `هل أنت متأكد من حذف ${this.propertyName} ؟ العملية لا يمكن استرجاعها`,
          options: { color: 'danger', width: 500, zIndex: 200 }
        })
        .then((confirm) => {
          if (confirm) {
            if (this.usedFor === 'unit') {
              console.log('delete unit')
              this.deleteUnit()
            } else {
              this.deleteRealEstate()
            }
          }
        })
    },

    deleteRealEstate() {
      this.isDeleteLoading = true
      realEstateService.deleteRealEstate(this.options._id).then(
        () => {
          this.isDeleteLoading = false
          this.addNotification({
            text: `تم حذف ${this.propertyName} بنجاح`,
            color: 'success'
          })
          if (this.usedFor === 'property') {
            this.$emit('refresh-properties-list')
          }
        },
        (error) => {
          this.isDeleteLoading = false
          this.addNotification({
            text: error,
            color: 'error'
          })
        }
      )
    },

    deleteUnit() {
      this.isDeleteLoading = true
      realEstateService.deleteUnit(this.options._id, this.parentId).then(
        () => {
          this.isDeleteLoading = false
          this.addNotification({
            text: 'تم حذف الوحدة بنجاح',
            color: 'success'
          })
          if (this.usedFor === 'unit') {
            this.$emit('refresh-units-list')
          }
        },
        (error) => {
          this.isDeleteLoading = false
          this.addNotification({
            text: error,
            color: 'error'
          })
        }
      )
    },

    editProperty(id) {
      this.$emit('edit-property', id)
    },

    goToProperty(propertyId) {
      this.$router.push({
        name: 'singleProperty',
        params: { id: propertyId, addNewUnit: 0 }
      })
    }
  }
}
</script>
