<template>
  <div class="d-flex align-center" v-if="!isCustomer">
    <!-- View deleted items -->
    <v-slide-x-transition v-if="restoreDeleted">
      <v-card
        class="d-flex align-center justify-space-between px-2 ml-3 mr-0"
        width="120"
        height="40"
      >
        <h6 class="mx-1">المحذوف</h6>
        <v-switch
          class="mx-auto mb-auto client__filter__switch deleted-switch"
          v-model="deleted"
          @change="handleDeletedSwitch"
          inset
          dense
          small
          hide-details="auto"
        ></v-switch>
      </v-card>
    </v-slide-x-transition>

    <!-- Select all items -->
    <div v-show="showSelectAll" class="ml-4">
      <v-btn
        :class="allClassesBtnSelect"
        style="min-width: 81px; min-height: 35px"
        @click="selectAll = !selectAll"
        small
        :ripple="false"
        dark
        elevation="0"
      >
        {{ selectAllTitle }}
        <GSvg
          :style="{
            width: '17px',
            height: '17px',
            fill: selectAll ? 'white' : '#662d91'
          }"
          :title="selectAllTitle"
          class="mr-1"
          nameIcon="selectall"
        />
      </v-btn>
    </div>

    <!-- Delete items -->
    <div v-show="showBtnDelete && !statusRestore" class="ml-4">
      <v-btn
        @click="deleteItems"
        class="error my-0"
        style="min-width: 81px; min-height: 35px"
        small
        :ripple="false"
        elevation="0"
        dark
      >
        {{ deleteTitle }}
        <GSvg
          :style="{ width: '17px', height: '17px', fill: 'white' }"
          :title="deleteTitle"
          class="mr-1"
          nameIcon="delete"
        />
      </v-btn>
    </div>

    <!-- Restore deleted items -->
    <div v-show="showBtnDelete && statusRestore" class="ml-4">
      <v-btn
        @click="restoreDelete"
        class="error my-0 d-flex align-center"
        style="min-width: 81px; min-height: 35px"
        small
        :ripple="false"
        elevation="0"
        dark
      >
        {{ restoreTitle }}
        <GSvg
          :style="{ width: '17px', height: '17px', fill: 'white' }"
          :title="restoreTitle"
          class="mr-1"
          nameIcon="restore"
        />
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
.v-application .mr-3 {
  margin-right: 3px !important;
}
@media (max-width: 996px) {
  h6,
  .h6 {
    font-size: 13px !important;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MultipleSelectionsForDelete',
  props: {
    showSelectAll: {
      type: Boolean,
      default: false
    },
    showBtnDelete: {
      type: Boolean,
      default: false
    },
    deleteItems: {
      type: Function,
      required: true
    },
    restoreDelete: {
      type: Function,
      default: () => {}
    },
    showRestoreDeleted: {
      type: Boolean,
      default: true
    },
    deleteTitle: {
      type: String,
      required: true
    },
    statusRestore: {
      type: Boolean,
      default: false
    },
    restoreTitle: {
      type: String,
      required: true
    },
    statusBackLog: {
      type: Boolean,
      default: false
    },
    statusclaims: {
      type: Boolean,
      default: false
    },
    statusHaveContracts: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectAll: false,
      deleted: false
    }
  },
  watch: {
    selectAll(v) {
      this.$emit('changeSelect', v)
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    selectAllTitle() {
      return this.selectAll ? 'الغاء الكل' : 'تحديد الكل'
    },
    allClassesBtnSelect() {
      return [
        'min-button my-0 ',
        { 'white black--text': !this.selectAll, primary: this.selectAll }
      ]
    },
    restoreDeleted() {
      return (
        this.showRestoreDeleted &&
        !this.statusBackLog &&
        !this.statusclaims &&
        !this.statusHaveContracts
      )
    }
  },
  methods: {
    handleDeletedSwitch() {
      this.$emit('switched', { name: 'deleted', value: this.deleted })
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/material-dashboard/_variables.scss';
.v-btn > span {
  font-weight: 500;
  line-height: 0;
}
.deleted-switch {
  margin-top: 7px !important;
}
</style>
