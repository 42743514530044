import store from '@/store/index'
import Vue from 'vue'

Vue.prototype.$hasPermission = function (property, name, permission) {
  return store.getters['accountState/hasPermission'](property, name, permission)
}

Vue.prototype.$checkBundleModules = function (module) {
  return store.getters['accountState/checkBundleModules'](module)
}
