var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showImageCard)?_c('v-card',{staticClass:"mb-6 px-6",attrs:{"width":"100%"}},[_c('v-card-title',{staticClass:"pa-0 py-4 px-5 d-flex align-center justify-content-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"font-16 ml-4"},[_vm._v("صور العقار")])])]),_c('div',[(_vm.allImages.length)?_c('v-carousel',{staticClass:"mb-4 px-4",attrs:{"animation":"","id":"carouselGrid","cycle":"","height":"300","hide-delimiter-background":"","hide-delimiters":""},scopedSlots:_vm._u([{key:"prev",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({staticClass:"carousel-arrows mr-5"},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":"#9F9FAC"}},[_vm._v("mdi-chevron-right")])],1)]}},{key:"next",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({staticClass:"carousel-arrows next-arrow"},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":"#9F9FAC"}},[_vm._v("mdi-chevron-left")])],1)]}}],null,false,1593618320),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.allImages),function(image,i){return _c('v-carousel-item',{key:i},[_c('div',{style:(`height: 300px;
                          background-size: cover !important;
                          background: url('${
                            image.normalImage ? image.normalImage.path : ''
                          }') no-repeat center;
                            border-radius: 10px;
                          `)})])}),1):_vm._e()],1),_c('v-row',{staticClass:"mb-5 px-5"},[_vm._l((_vm.oldImages),function(image,i){return _c('v-col',{key:image.normalImage.path,attrs:{"md":"2","sm":"3","cols":"6"}},[_c('div',{staticClass:"position-relative add-pic-container"},[_c('img',{staticClass:"add-pic",attrs:{"src":image.smallImage.path,"loading":"lazy"}}),(_vm.$hasPermission('realestates', 'realestates', 'add'))?_c('v-icon',{staticClass:"pic-icon position-absolute slide-y-transition",attrs:{"color":"white"},on:{"click":function($event){return _vm.deleteImage(image, i, true)}}},[_vm._v("\n          mdi-close\n        ")]):_vm._e()],1)])}),_vm._l((_vm.images),function(image,i){return _c('v-col',{key:image._id,attrs:{"md":"2","sm":"3","cols":"6"}},[_c('div',{staticClass:"position-relative add-pic-container"},[_c('img',{ref:`image`,refInFor:true,staticClass:"add-pic"}),(!image.file.smallImage)?_c('div',{staticClass:"lds-ring-container"},[_c('div',{staticClass:"lds-ring"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_vm._e(),(_vm.$hasPermission('realestates', 'realestates', 'delete'))?_c('v-icon',{staticClass:"pic-icon position-absolute slide-y-transition",attrs:{"color":"white"},on:{"click":function($event){return _vm.deleteImage(image.file, i, false)}}},[_vm._v("\n          mdi-close\n        ")]):_vm._e()],1)])}),(
        (_vm.images.length || _vm.oldImages.length) &&
        _vm.$hasPermission('realestates', 'realestates', 'add')
      )?_c('v-col',{staticClass:"d-flex justify-center align-center pic-wrapper",attrs:{"md":"2","sm":"3","cols":"6"}},[_c('v-btn',{staticClass:"brub--border",attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.inputUpload2.click()}}},[_c('v-icon',{attrs:{"color":"general"}},[_vm._v(" mdi-plus ")])],1),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"inputUpload2",attrs:{"multiple":"","accept":"image/*","type":"file"},on:{"change":function($event){return _vm.addFiles($event)}}})],1):(_vm.$hasPermission('realestates', 'realestates', 'add'))?_c('div',{ref:"propertyImages",staticClass:"pic-wrapper d-flex align-center w-100 pa-6 my-16 flex-wrap",on:{"drop":function($event){$event.preventDefault();return _vm.addFiles($event)},"dragleave":function($event){$event.preventDefault();return _vm.dragLeave('propertyImages')},"dragover":function($event){$event.preventDefault();return _vm.dragOver('propertyImages')},"dragenter":function($event){$event.preventDefault();}}},[_c('div',[_c('v-btn',{staticClass:"py-2 px-3 rounded-7 font-weight-bold",attrs:{"color":"general","outlined":""},on:{"click":_vm.uploadImage}},[_c('v-icon',{attrs:{"color":"general"}},[_vm._v(" mdi-plus ")]),_c('span',{staticClass:"font-12"},[_vm._v(" إضافة الصور ")])],1),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"inputUpload",attrs:{"accept":"image/*","multiple":"","type":"file"},on:{"change":function($event){return _vm.addFiles($event)}}})],1),_c('div',{staticClass:"mr-3"},[_c('h4',{staticClass:"font-12"},[_vm._v("\n          اسحب الصور هنا لرفعها أو اضغط على زر إضافة الصور\n        ")]),_c('h5',{staticClass:"text-gray font-12"},[_vm._v("يمكنك الإضافة حتى ١٠ صور لعقارك")])])]):_vm._e()],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }