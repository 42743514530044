var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:{ userView: _vm.isCustomer }},[(Object.keys(_vm.realEstats).length)?_c('div',[_c('v-data-table',{staticClass:"tab-table-details",attrs:{"headers":_vm.headers,"items":[_vm.realEstats],"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.realEstate`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"},[_c('h6',[_vm._v("\n            "+_vm._s(item.realEstate?.parentId?.name
                ? item.realEstate?.parentId?.name
                : item.realEstate.name)+"\n          ")])])]}},{key:`item.realEstate.parentId`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"},[_c('h6',[_vm._v("\n            "+_vm._s(item.realEstate?.parentId?.name
                ? item.realEstate.name
                : item.realEstate?.parentId?.name || '-')+"\n          ")])])]}},{key:`item.space`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"},[_c('h6',[_vm._v(_vm._s(item.space)+" م2")])])]}},{key:`item.pricePerMeter`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"},[_c('h6',[_vm._v(_vm._s(item.pricePerMeter?.toLocaleString())+" ريال/م2")])])]}},{key:`item.price`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"},[_c('h6',[_vm._v(_vm._s(item.price?.toLocaleString())+" ريال/السنة")])])]}},{key:`item.status`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"},[_c('h5',{class:[
              'ma-0 tag text-center py-1',
              _vm.statusClasses(item.realEstate.status.nameAr)
            ]},[_vm._v("\n            "+_vm._s(item.realEstate.status.nameAr)+"\n          ")])])]}}],null,true)})],1):_c('NoData',_vm._b({},'NoData',_vm.noDataConfig,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }