import Vue from 'vue'

Vue.prototype.$formatNumberInputs = (event, allowDecimals = false) => {
  const isDecimalSeparator = event.key === '.' || event.key === ','
  const isBackspace = event.key === 'Backspace'
  const inputValue = event.target.value

  // Check if the previous character is a decimal separator
  const isPreviousDecimalSeparator =
    inputValue.slice(-1) === '.' ||
    inputValue.slice(-1) === ',' ||
    inputValue.includes('.')

  if (
    // If the key is not a digit, Arabic or English number, or backspace key
    !/^[\d\u0660-\u0669\u06f0-\u06f9]$/.test(event.key) &&
    !isBackspace &&
    // If decimals are not allowed or if decimals are allowed and the key is not a decimal separator
    (!allowDecimals || (allowDecimals && !isDecimalSeparator))
  ) {
    event.preventDefault()
    // If the key is not a decimal separator or if the previous character is not a decimal separator
  } else if (isPreviousDecimalSeparator && isDecimalSeparator) {
    event.preventDefault()
  }
}

Vue.prototype.$allowOnlyDecimalNumbersCommas = (event, val = 0) => {
  const allowedKeys = [',', '.', 'Backspace']
  // Conditions that preventing user from entering any key
  // 1st: Not a number and not in the allowed keys array
  // 2nd: Decimal number and the coming key is dot (.) -> we can't accept 2 dots (.) we only accept single dot
  // 3th: Decimal number and have 2 decimal numbers -> we can't accept more than 2 decimal numbers (Business requirement)
  if (
    (!allowedKeys.includes(event.key) && isNaN(event.key)) ||
    (!Number.isInteger(val) &&
      (event.key === '.' ||
        (val?.toString()?.split('.')[1]?.length > 1 &&
          !allowedKeys.includes(event.key))))
  ) {
    event.preventDefault()
  }
}
