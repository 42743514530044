<template>
  <div class="col-md-6 mobile__pt__0 hectar--statistics">
    <div class="mobile__pt__0">
      <div class="maintenance-statistics-withData withData">
        <div class="component-title">
          <h5 class="text-truncate font-16 mb-1 mobile__mt__0">
            إحصائيات الصيانة
          </h5>
        </div>
        <div class="financial__statistics--cards py-2">
          <v-col class="pt-0 px-1 pb-1" cols="4">
            <div
              class="card v-card v-sheet theme--light card-1 mb-2 pa-5 justify-space-between align-start"
            >
              <div class="financial__statistics--tick--icon icon icon__green">
                <img
                  height="16"
                  width="16"
                  lazy-src="@/assets/img/svgs/wallet.svg"
                  src="@/assets/img/svgs/wallet.svg"
                />
              </div>
              <div class="card-title text-container">
                <p class="title text-truncate">مجموع المصروفات</p>
                <h5
                  class="col-value font-weight-bold"
                  :class="totalExpensesClasses"
                >
                  {{ totalExpenses }}
                  <span class="grey--text font-20"> ريال </span>
                </h5>
              </div>
            </div>
          </v-col>
          <v-col class="pt-0 px-1 pb-1" cols="4">
            <div
              class="card v-card v-sheet theme--light card-1 pa-5 justify-space-between align-start"
            >
              <div class="financial__statistics--tick--icon icon icon__purple">
                <img
                  height="16"
                  width="16"
                  lazy-src="@/assets/img/svgs/purple-globe.svg"
                  src="@/assets/img/svgs/purple-globe.svg"
                />
              </div>
              <div class="card-title text-container">
                <p class="title text-truncate">عدد طلبات الصيانة</p>
                <h5
                  class="col-value font-weight-bold"
                  v-text="maintenanceStats.totalRequests"
                />
              </div>
            </div>
          </v-col>
          <v-col class="pt-0 px-1 pb-1" cols="4">
            <div
              class="card v-card v-sheet theme--light card-1 pa-5 justify-space-between align-start"
            >
              <div class="financial__statistics--tick--icon icon icon__green">
                <img
                  height="16"
                  width="16"
                  lazy-src="@/assets/img/svgs/statistics/green-house.svg"
                  src="@/assets/img/svgs/statistics/green-house.svg"
                />
              </div>
              <div class="card-title text-container">
                <p class="title text-truncate">الطلبات الجديدة</p>
                <h5
                  class="col-value font-weight-bold"
                  v-text="maintenanceStats.new"
                />
              </div>
            </div>
          </v-col>
          <v-col class="pt-0 px-1 pb-1" cols="4">
            <div
              class="card v-card v-sheet theme--light card-1 pa-5 justify-space-between align-start"
            >
              <div class="financial__statistics--tick--icon icon blue--tag">
                <img
                  height="15"
                  width="15"
                  lazy-src="@/assets/img/svgs/trueIcon.svg"
                  src="@/assets/img/svgs/trueIcon.svg"
                />
              </div>
              <div class="card-title text-container">
                <p class="title text-truncate">الطلبات المنتهية</p>
                <h5
                  class="col-value font-weight-bold"
                  v-text="maintenanceStats.done"
                />
              </div>
            </div>
          </v-col>
          <v-col class="pt-0 px-1 pb-1" cols="4">
            <div
              class="card v-card v-sheet theme--light card-1 pa-5 justify-space-between align-start"
            >
              <div class="financial__statistics--tick--icon icon red--tag">
                <img
                  height="15"
                  width="15"
                  lazy-src="@/assets/img/svgs/statistics/orange-line.svg"
                  src="@/assets/img/svgs/statistics/orange-line.svg"
                />
              </div>
              <div class="card-title text-container">
                <p class="title text-truncate">الطلبات الملغية</p>
                <h5
                  class="col-value font-weight-bold"
                  v-text="maintenanceStats.canceled"
                />
              </div>
            </div>
          </v-col>
          <v-col class="pt-0 px-1 pb-1" cols="4">
            <div
              class="card v-card v-sheet theme--light card-1 pa-5 justify-space-between align-start"
            >
              <div class="financial__statistics--tick--icon icon gray--tag">
                <img
                  height="15"
                  width="15"
                  lazy-src="@/assets/img/svgs/x.svg"
                  src="@/assets/img/svgs/x.svg"
                />
              </div>
              <div class="card-title text-container">
                <p class="title text-truncate">الطلبات المرفوضة</p>
                <h5
                  class="col-value font-weight-bold"
                  v-text="maintenanceStats.rejected"
                />
              </div>
            </div>
          </v-col>
        </div>
        <!-- financial__statistics--cards -->
      </div>
      <!-- maintenance-statistics-status -->
    </div>
  </div>
</template>

<script>
import numberFormatter from '@/mixins/numberFormatter.js'

export default {
  name: 'MaintenanceStatistice',
  mixins: [numberFormatter],
  props: {
    maintenanceStats: {
      type: Object,
      required: true
    }
  },
  computed: {
    totalExpenses() {
      return this.maintenanceStats.totalExpenses
    },
    totalExpensesClasses() {
      return typeof this.totalExpenses === 'string' &&
        !this.totalExpenses.includes('ر يال')
        ? 'position-relative'
        : ''
    }
  }
}
</script>
