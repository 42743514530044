<template>
  <div>
    <v-row
      v-if="isMultiBuilding && !propertyInfoValid"
      align-content-sm
      no-gutters
      class="mb-6"
    >
      <v-col
        v-for="unit in realestateUnits"
        :key="unit._id"
        class="pl-1"
        :cols="6"
        :sm="6"
      >
        <h6 class="mb-2">{{ unit.title || unit.nameAr }}</h6>
        <v-text-field
          :data-test="unit.key"
          v-model="addPropertyInfo[`${unit.key}`]"
          dense
          filled
          hide-details="auto"
          :placeholder="unit.title"
          require
          min="0"
          @keydown="$formatNumberInputs"
          :readonly="propertyInfoValid"
          :disabled="propertyInfoValid"
          @input="formatEnglihshNumber($event, `${unit.key}`)"
        ></v-text-field>
      </v-col>
    </v-row>

    <hr v-if="isMultiBuilding && !propertyInfoValid" class="tab3Line" />

    <v-row align-content-sm no-gutters class="mt-4">
      <v-form ref="form" class="w-100">
        <v-col cols="12" sm="12" class="mobile__p__0" v-if="!propertyInfoValid">
          <dropdown-alt
            ref="clientType"
            :defaultValue="currentUser"
            :configrations="{ name: 'المالك' }"
            :listingType="clientType"
            :restId="restId"
            @filterTypes="onUserTypeChange($event, 'owner')"
          />
        </v-col>

        <v-col cols="12" sm="12" v-if="!isNewOwner" class="mobile__p__0">
          <h5
            class="ma-0 mb-3 font-12"
            :class="{ 'red--flag': nexTabClicked ? !selectedOwner : false }"
          >
            اسم المالك*
          </h5>
          <autocomplete
            ref="autoComplete"
            v-model="addPropertyInfo.owner"
            :loading="clientIsLoading"
            :itemData="true"
            :items="allClients"
            :noData="noData"
            :placeholder="selectedRelestatePlaceholder"
            :validateString="validateString"
            :returnObject="true"
            hide-details="auto"
            @nextPageTrigger="getNextData"
            @emitSearchKey="getDataBySearchKey"
            class="font-14"
            :disabled="propertyInfoValid"
          />
        </v-col>
      </v-form>
    </v-row>
    <v-row class="pb-0" v-if="isNewOwner">
      <v-col>
        <NewAgentForm
          ref="newAgent"
          v-bind="generateNewClientProps('owner')"
          @checkUserExistance="checkUserExistance($event, 'owner')"
          @onValidatePhoneNumber="
            setNewClientPhoneData('owner', 'phoneData', $event)
          "
          @new_user_added="newUserAdded($event, 'owner')"
        />
      </v-col>
    </v-row>

    <!--Realestate renter-->
    <v-row v-if="isTenantAvailable()" align-content-sm no-gutters>
      <v-form class="w-100">
        <v-col cols="12" sm="12" class="mobile__p__0" v-if="!propertyInfoValid">
          <dropdown-alt
            ref="renterType"
            :defaultValue="currentUser"
            :configrations="{ name: 'المستأجر' }"
            :listingType="clientType"
            :restId="restId"
            @filterTypes="onUserTypeChange($event, 'renter')"
          />
        </v-col>

        <v-col cols="12" sm="12" v-if="!isNewRenter" class="mobile__p__0">
          <h5 class="ma-0 mb-3 font-12">اسم المستأجر*</h5>
          <autocomplete
            v-model="addPropertyInfo.tenet"
            :loading="renterIsLoading"
            :itemData="true"
            :items="allRenters"
            :noData="noData"
            :placeholder="selectedRelestatePlaceholder"
            :returnObject="true"
            :rules="[(v) => true]"
            @nextPageTrigger="getRentersNextData"
            @emitSearchKey="getRentersDataBySearchKey"
            class="font-14"
            :disabled="propertyInfoValid"
          />
        </v-col>

        <v-col cols="12" sm="12" v-else class="mobile__p__0">
          <NewAgentForm
            v-bind="generateNewClientProps('renter')"
            @checkUserExistance="checkUserExistance($event, 'renter')"
            @onValidatePhoneNumber="
              setNewClientPhoneData('renter', 'phoneData', $event)
            "
            @new_user_added="newUserAdded($event, 'renter')"
          />
        </v-col>
      </v-form>
    </v-row>

    <!--Moderator-->
    <v-row no-gutters>
      <v-form ref="form" class="w-100" v-model="moderatorVaild">
        <v-col
          class="pt-0 mobile__p__0 employee--select--col"
          cols="12"
          sm="12"
        >
          <h5
            class="ma-0 mb-3 font-12"
            :class="{ 'red--flag': nexTabClicked ? !moderatorVaild : false }"
          >
            المسؤولين *
          </h5>
          <v-select
            ref="employees"
            :items="employees"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            :placeholder="`المسؤولين عن العقار`"
            append-icon="mdi-chevron-down"
            dense
            :rules="selectRules"
            filled
            hide-details
            item-text="name"
            item-value="_id"
            return-object
            clearable
            multiple
            v-model="addPropertyInfo.moderators"
            class="font-14"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>{{ item.name }}</span>
              </v-chip>
              <span
                v-if="index === 2"
                class="text-grey text-caption align-self-center"
              >
                (+{{ addPropertyInfo.moderators?.length - 2 }} مسؤولين)
              </span>
            </template>
          </v-select>
        </v-col>
      </v-form>
    </v-row>

    <v-card-actions class="pt-4 d-flex align-center justify-space-between">
      <v-btn
        @click="previousTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <v-icon class="font-16">mdi-arrow-right</v-icon>
        <span class="mb-1 ml-1">السابق </span>
      </v-btn>
      <v-btn
        data-test="nextTab"
        @click="handleFunctionCall(nextAction.method)"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <span class="mb-1 ml-1">{{ nextAction.title }} </span>
        <v-icon class="font-16">mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EnvService } from '@/services'
import DropdownAlt from '../dropdownAlt'
import NewAgentForm from '@/components/common/new-agent/NewAgentForm'
import autocomplete from '@/components/forms/autocomplete'
import { convertNumbers2English } from '@/utils/formatters'
export default {
  name: 'propertyOwnerStatus',
  components: {
    DropdownAlt,
    NewAgentForm,
    autocomplete
  },
  props: {
    isMultiBuilding: {
      type: Boolean,
      required: true
    },
    clientType: {
      type: Array,
      required: true
    },
    employees: {
      type: Array,
      required: true
    },
    resetValidation: {
      default: false
    },
    buildingType: {
      type: String,
      required: true
    },
    propertyInfo: {
      default() {
        return {}
      }
    },
    propertyInfoValid: {}
  },
  data() {
    return {
      clients: [],
      allClients: [],
      selectedRelestatePlaceholder: 'اختر العميل',
      noData: 'لا يوجد عملاء متاحه لهذا البحث',
      validateString: 'من فضلك ادخل اسم العميل',
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      clientIsLoading: false,
      searchKey: '',
      moderatorVaild: false,
      isValid: false,
      currentUser: 'حالي',
      selectRules: [(v) => !!v.length || 'هذا الحقل مطلوب'],
      newUser: {},
      newAgentFormValid: false,
      nexTabClicked: false,
      addPropertyInfo: {
        newUserChecked: false,
        newRenterChecked: false
      },
      restId: false,
      renters: [],
      allRenters: [],
      renterPagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      renterIsLoading: false,
      renterSearchKey: '',
      newClientPhoneNumber: {
        owner: {
          phoneData: {},
          successMessage: '',
          errorMessage: '',
          isLoading: false
        },
        renter: {
          phoneData: {},
          successMessage: '',
          errorMessage: '',
          isLoading: false
        }
      },
      newRenterFormValid: false,
      multipleUnitsBuilding: [
        'villa',
        'building',
        'compound',
        'Tower',
        'Building'
      ]
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    realestateUnits() {
      return this.propertyInfo?.type?.ref[this.realEstatePurpose] || []
    },
    realEstatePurpose() {
      return this.propertyInfo?.purpose?.nameEn
    },
    hasPermission() {
      return this.$hasPermission('users', 'clients', 'add')
    },
    isNewOwner() {
      return !!this.addPropertyInfo?.newUserChecked
    },
    isNewRenter() {
      return !!this.addPropertyInfo?.newRenterChecked
    },
    selectedOwner() {
      return this.addPropertyInfo?.owner
        ? !!Object.keys(this.addPropertyInfo?.owner).length
        : false
    },
    tabIsValid() {
      const ownerIsValid = this.addPropertyInfo.newUserChecked
        ? this.newAgentFormValid
        : this.selectedOwner
      return this.moderatorVaild && ownerIsValid
    },
    isResidential() {
      return this.addPropertyInfo.purpose?.nameEn === 'Residential'
    },
    isCommercial() {
      return this.addPropertyInfo.purpose?.nameEn === 'Commercial'
    },
    isResidentialOrVilla() {
      return (
        this.isResidential ||
        this.isResidentialCommercial ||
        this.buildingType === 'villa'
      )
    },
    showNumberOfExhibtions() {
      return !(
        this.buildingType === 'villa' ||
        (this.buildingType === 'compound' &&
          !this.isCommercial &&
          !this.isResidentialCommercial)
      )
    },
    isCommercialAndNotVilla() {
      return (
        (this.isCommercial || this.isResidentialCommercial) &&
        this.buildingType !== 'villa'
      )
    },
    isResidentialAndCompound() {
      return (
        (this.isResidential || this.isResidentialCommercial) &&
        this.buildingType === 'compound'
      )
    },
    isSoldOrRented() {
      return (
        this.addPropertyInfo.status?.nameEn === 'sold' ||
        this.addPropertyInfo.status?.nameEn === 'rented'
      )
    },
    nextAction() {
      if (this.isMultiBuilding && this.isSoldOrRented) {
        return {
          method: 'addRealEstate',
          title: this.propertyInfoValid ? 'تحديث' : 'إضافة'
        }
      }
      return {
        method: 'nextTab',
        title: 'التالي'
      }
    },
    isResidentialCommercial() {
      return this.addPropertyInfo.purpose?.nameEn === 'Residential_Commercial'
    },
    isRented() {
      return this.addPropertyInfo.status?.nameEn === 'rented'
    },
    isMultipleUnitsBuilding() {
      return this.multipleUnitsBuilding.includes(this.buildingType)
    },
    isWholeBuilding() {
      return this.propertyInfo?.isSingleOrMultiple === 'singleUnit'
    },
    isSelectedRenterValid() {
      return this.addPropertyInfo.newRenterChecked
        ? this.newRenterFormValid
        : !!this.addPropertyInfo.tenet
    },
    selectedRenter() {
      return this.addPropertyInfo.tenet
        ? !!Object.keys(this.addPropertyInfo.tenet).length
        : false
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    isTenantAvailable() {
      return this.isMultipleUnitsBuilding
        ? !!(this.isWholeBuilding && this.isRented)
        : this.isRented
    },
    // if renter data isn't valid we have to ignore it
    resetRenterData() {
      if (!this.newRenterFormValid && this.isRented) {
        this.$set(this.addPropertyInfo, 'newRenterChecked', false)
        this.addPropertyInfo.renter = {}
        if (this.$refs?.renterType) {
          this.$refs.renterType.defaultValue = 'حالي'
          this.$refs.renterType.id = 2
        }
      }
    },
    formatEnglihshNumber(val, key) {
      this.addPropertyInfo[key] = Number(convertNumbers2English(val))
    },
    checkUserExistance(phone, clientType) {
      const phoneNumber = phone.replace(/ /g, '')
      if (phoneNumber.length !== 10) {
        this.setNewClientPhoneData(clientType, 'successMessage', '')
        this.setNewClientPhoneData(
          clientType,
          'errorMessage',
          'من فضلك تأكد من رقم الهاتف'
        )
      } else if (this.newClientPhoneNumber[clientType].phoneData?.isValid) {
        this.setNewClientPhoneData(clientType, 'isLoading', true)
        this.setNewClientPhoneData(clientType, 'successMessage', '')
        this.setNewClientPhoneData(clientType, 'errorMessage', '')

        const body = {
          environment: this.currentEnv._id,
          phoneNumber: phoneNumber.replace('0', '966')
        }
        EnvService.checkClient(body)
          .then((response) => {
            if (response.data.available) {
              this.setNewClientPhoneData(
                clientType,
                'successMessage',
                'العميل متاح للإضافه'
              )
              this.setNewClientPhoneData(clientType, 'errorMessage', '')
            }
          })
          .catch(() => {
            this.setNewClientPhoneData(
              clientType,
              'errorMessage',
              ' الرقم مُسجل بإسم عميل أخر'
            )
          })
          .finally(() => {
            this.setNewClientPhoneData(clientType, 'isLoading', false)
          })
      }
    },
    getDataBySearchKey(e) {
      this.searchKey = e
      if (this.searchKey) {
        this.loadClients()
        this.clientIsLoading = true
      } else {
        this.clientIsLoading = false
      }
    },
    getNextData() {
      if (this.pagination.pageNumber < this.pagination.pagesCount) {
        this.pagination.pageNumber += 1
        this.clientIsLoading = true
        this.loadClients()
      }
    },
    async loadClients(query) {
      let _data = 'role=owner'
      if (this.searchKey) {
        _data += `&search=${this.searchKey}`
      } else {
        _data += `&pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
      }
      try {
        const { data } = await EnvService.getAllClients(
          this.currentEnv._id,
          query,
          _data
        )
        if (data && data?.users) {
          this.pagination.pagesCount = Math.ceil(
            data.count / this.pagination.pageSize
          )
          this.clients = data?.users ?? []
          this.pagination.count = data?.count
          this.allClients = [...this.allClients, ...this.clients]
        }
        this.clientIsLoading = false
      } catch (error) {
        this.clientIsLoading = false
        console.log('err', error)
      }
    },
    nextTab() {
      this.nexTabClicked = true
      if (this.tabIsValid) {
        this.resetRenterData()
        this.$emit('nextTab')
        this.$emit('setAddPropertyInfo', this.addPropertyInfo)
      } else {
        this.$emit('addNotification')
      }
    },
    previousTab() {
      this.$emit('previousTab')
    },
    addRealEstate() {
      this.nexTabClicked = true
      if (this.tabIsValid) {
        this.$emit('setAddPropertyInfo', this.addPropertyInfo)
        this.propertyInfoValid
          ? this.$emit('updateRealEstate')
          : this.$emit('addRealEstate')
      } else {
        this.$emit('addNotification')
      }
    },
    newUserAdded(val, clientType) {
      const clientPayload = {
        name: val.obj.name,
        idNumber: val.obj.idNumber,
        phoneNumber: val.obj.phoneNumber?.replace(/\s/g, ''),
        clientType: val.obj.clientType,
        taxNumber: val.obj.taxNumber,
        environment: {
          id: this.currentEnv._id,
          name: this.currentEnv.name,
          role: [clientType]
        },
        userAddress: {
          address: val.obj.address,
          city: val.obj.city,
          district: val.obj.district
        }
      }
      if (clientType === 'owner') {
        this.newUser = val.obj
        this.newAgentFormValid = val.isValid
        this.addPropertyInfo.newOwner = { ...clientPayload }
      } else {
        this.newRenterFormValid = val.isValid
        this.addPropertyInfo.newRenter = { ...clientPayload }
      }
    },
    onSelectUser(user) {
      if (!user) {
        this.addPropertyInfo.owner = {}
        this.newUser = {}
      } else {
        this.addPropertyInfo.newUserChecked = false
        this.newUser = user
      }
      this.isValid = true
    },
    onUserTypeChange(e, type) {
      if (e.text === 'جديد' && !this.hasPermission) {
        this.addNotification({
          text: 'المعذرة، ليس لديك صلاحية لإضافة عملاء. الرجاء مراجعة مشرف الصلاحيات',
          color: 'error'
        })
        this.restId = !this.restId
        return
      }

      if (type === 'owner') {
        if (e.text === 'جديد') {
          this.$set(this.addPropertyInfo, 'newUserChecked', true)
          if (!this.propertyInfoValid) {
            this.addPropertyInfo.owner = {}
          }
          this.isValid = false
          return
        }

        this.addPropertyInfo.newUserChecked = false
        this.newAgentFormValid = false
        if (this.propertyInfoValid) {
          this.isValid = true
        }
      } else {
        if (e.text === 'جديد') {
          this.$set(this.addPropertyInfo, 'newRenterChecked', true)
          if (!this.propertyInfoValid) {
            this.addPropertyInfo.renter = {}
          }
          return
        }
        this.$set(this.addPropertyInfo, 'newRenterChecked', false)
        this.newRenterFormValid = false
      }
    },
    handleFunctionCall(functionName) {
      this[functionName]()
    },
    async loadRenters() {
      this.renterIsLoading = true
      let _data = 'role=renter'
      if (this.renterSearchKey) {
        _data += `&search=${this.renterSearchKey}`
      } else {
        _data += `&pageSize=${this.renterPagination.pageSize}&pageNumber=${this.renterPagination.pageNumber}`
      }
      try {
        const { data } = await EnvService.getAllClients(
          this.currentEnv._id,
          '',
          _data
        )
        if (data && data?.users) {
          this.renterPagination.pagesCount = Math.ceil(
            data?.count / this.renterPagination.pageSize
          )
          this.renters = data?.users ?? []
          this.renterPagination.count = data?.count
          this.allRenters = [...this.allRenters, ...this.renters]
        }
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل المستأجرين، يرجى المحاولة مجددا',
          color: 'error'
        })
      } finally {
        this.renterIsLoading = false
      }
    },
    setNewClientPhoneData(clientType, key, value) {
      this.$set(this.newClientPhoneNumber[clientType], key, value)
    },
    generateNewClientProps(clientType) {
      return {
        index: 0,
        isAqarForm: true,
        isDisabled: false,
        phoneNumberSuccessMesage:
          this.newClientPhoneNumber[clientType].successMessage,
        phoneNumberErrorMessage:
          this.newClientPhoneNumber[clientType].errorMessage,
        checkPhoneNumberLoader: this.newClientPhoneNumber[clientType].isLoading,
        isValidPhone:
          this.newClientPhoneNumber[clientType].phoneData?.isValid || false
      }
    },
    getRentersNextData() {
      if (this.renterPagination.pageNumber < this.renterPagination.pagesCount) {
        this.renterPagination.pageNumber += 1
        this.loadRenters()
      }
    },
    getRentersDataBySearchKey(e) {
      this.renterSearchKey = e
      if (this.renterSearchKey) {
        this.loadRenters()
      }
    }
  },
  watch: {
    resetValidation: {
      handler() {
        if (!this.propertyInfoValid) {
          this.$refs.form.reset()
          this.nexTabClicked = false
          this.isValid = false
          this.addPropertyInfo.newUserChecked = false
          this.newUser = {}
          this.newAgentFormValid = false
          this.$refs.clientType.defaultValue = this.currentUser
          this.isRented &&
            (this.$refs.renterType.defaultValue = this.currentUser)
          this.addPropertyInfo = {}
        }
        this.$refs.clientType.id = 2
        this.isRented && (this.$refs.renterType.id = 2)
      }
    },
    propertyInfo: {
      immediate: true,
      handler() {
        this.addPropertyInfo = {
          ...this.propertyInfo,
          newUserChecked: this.addPropertyInfo.newUserChecked,
          newRenterChecked: this.addPropertyInfo.newRenterChecked
        }
        if (
          !this.addPropertyInfo?.owner &&
          !this.addPropertyInfo?.moderators?.length &&
          this.addPropertyInfo?.account
        ) {
          const account = { ...this.addPropertyInfo.account }
          account._id = account.id
          delete account.id
          this.$set(this.addPropertyInfo, 'owner', account)
          this.$set(this.addPropertyInfo, 'moderators', [account])
        }
        if (this.addPropertyInfo?.owner) {
          this.isValid = true
        }
        if (this.propertyInfoValid) {
          this.isRented && this.allRenters.push(this.addPropertyInfo.tenet)
        }
      }
    }
  },
  created() {
    this.loadClients()
    this.loadRenters()
  }
}
</script>
<style lang="scss" scoped>
.tab3Line {
  border: 4px solid #f7f7f7;
}
.employee--select--col {
  ::v-deep {
    .mdi-close {
      font-size: 18px;
    }
  }
}
</style>
