export default {
  arabicKeys: {
    collectedAmount: 'إجمالي المحصلات',
    totalCollectedAmount: 'صافي المحصلات',
    expenses: 'المصروفات',
    payments: 'المدفوعات',
    commissionPercentage: 'نسبة العمولة',
    commissionFixed: 'العمولة الثابتة',
    totalOwnerClaims: 'إجمالي مستحقات بعد الضريبة',
    totalCredit: 'إجمالي الائتمان',
    totalDues: 'إجمالي المستحقات',
    totalPaid: 'مجموع المبالغ المدفوعة',
    totalTaxAmount: 'إجمالي قيمة الضريبة',
    taxAmount: 'قيمة الضريبة',
    grossTotalClaims: 'إجمالي مستحقات قبل الضريبة',
    commission: 'إجمالي نسبة إدارة الأملاك'
  }
}
