<template>
  <div class="mt-6">
    <div class="d-flex justify-space-between align-start flex-wrap gap-10px">
      <div
        class="d-flex justify-space-between flex-wrap gap-10px mobile__mw__100 w-100"
      >
        <div
          class="d-flex align-center justify-space-between flex-wrap gap-top-bottom-10px mobile__mw__100"
        >
          <v-slide-x-transition>
            <v-card
              class="d-flex justify-space-between px-2 ml-3 mobile__mx__0 mobile__w__100 mobile__mw__100"
              height="40"
            >
              <h6
                v-for="status in availableFilters"
                :class="[
                  'cursor d-flex justify-space-between align-center',
                  { 'border-black': filterType === status.role }
                ]"
                :key="status.role"
                ref="filterBtn"
                @click="filterList(status)"
              >
                {{ roleNameAr(status) }}
                <span class="propCounter mx-2 px-2 font-12 mobile__px__4px">
                  {{ status.count }}
                </span>
              </h6>
            </v-card>
          </v-slide-x-transition>
          <!-- search by client name -->
          <div
            class="max-width-150 mx-2 mobile__mw__100 mobile__flex__auto mobile__mx__0"
          >
            <v-text-field
              class="search__by__customer__name"
              height="40"
              filled
              dense
              hide-details="auto"
              placeholder="ابحث بإسم العميل"
              :value="search"
              @input="searchForClients"
            ></v-text-field>
          </div>
        </div>
        <v-slide-x-transition>
          <v-card height="40" class="d-flex justify-space-between px-2 mr-3">
            <img
              class="cursor"
              :src="gridView ? rowIcon : rowIconColored"
              width="20"
              @click="changeGridView(false)"
              loading="lazy"
            />
          </v-card>
        </v-slide-x-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/helpers/debounce'
import { getLocalNames } from '@/utils/general'
export default {
  name: 'clintesFilter',
  props: {
    gridView: {
      type: Boolean,
      default: true
    },
    availableFilters: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    rowIcon: require('@/assets/img/svgs/rowIcon.svg'),
    rowIconColored: require('@/assets/img/svgs/rowIconColored.svg'),
    showFilterTypes: false,
    filterType: '',
    search: ''
  }),
  computed: {
    arrowIcons() {
      return this.showFilterTypes ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    ifGridRow() {
      return this.gridView ? this.rowIcon : this.rowIconColored
    },
    filterQuery() {
      return {
        ...(this.filterType && { code: this.filterType }),
        search: this.search
      }
    }
  },
  methods: {
    filterList(type) {
      this.filterType = type.role
      this.emitFilterQuery()
    },
    searchForClients: debounce(function (searchText) {
      this.search = searchText
      this.emitFilterQuery()
    }, 600),
    emitFilterQuery() {
      this.$parent.filterChanged(this.filterQuery)
    },
    changeGridView(status) {
      this.$parent.changeGridView(status)
      this.showFilterTypes = false
    },
    roleNameAr(status) {
      return getLocalNames(status.role)
    }
  }
}
</script>
