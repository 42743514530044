<template>
  <v-container :class="{ userView: isCustomer }" class="mt">
    <TabTableDetails
      v-bind="tabTableDetailsProps"
      @addAction="addAttachment"
      @preview="previewAttach"
      @download="downloadAttach"
      @delete="deleteAttach"
    />
    <AttachmentsModal
      :dialogState="dialogState"
      :isLoading="isLoading"
      :title="title"
      :attachTypes="attachTypes"
      @close-dialog="closeHandler"
      @selectFile="addNewAttachment"
      v-on="$listeners"
    />
  </v-container>
</template>

<script>
import TabTableDetails from '@/components/contracts/SideDetails/tabTableDetails.vue'
import { realEstateService } from '@/services'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'PriceOfferAttachments',
  components: {
    TabTableDetails,
    AttachmentsModal: () =>
      import('@/components/contracts/SideDetails/AttachmentsModal.vue')
  },
  props: {
    quotation: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: 'إضافة مرفق'
    },
    openAttachmentStatus: {
      type: Boolean,
      default: false
    },
    attachTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialogState: false,
      isLoading: false,
      attachments: []
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    tabTableDetailsProps() {
      return {
        items: this.attachments,
        title: 'المرفقات',
        headers: [
          {
            text: 'بواسطة',
            value: 'createdBy',
            sortable: false,
            align: 'center'
          },
          {
            text: 'التاريخ',
            value: 'createdAt',
            sortable: false,
            align: 'center'
          },
          {
            text: 'اسم المرفق',
            value: 'description',
            sortable: false,
            align: 'center'
          },
          {
            text: 'رقم',
            value: 'number',
            sortable: false,
            align: 'center'
          },
          {
            text: 'عرض',
            value: 'preview',
            sortable: false,
            align: 'left',
            width: 20
          },
          {
            text: 'تحميل',
            value: 'download',
            sortable: false,
            align: 'left',
            width: 20
          },
          {
            text: 'حذف',
            value: 'delete',
            sortable: false,
            align: 'left',
            width: 20
          }
        ],
        noDate: {
          text: 'ليس هناك مرفقات مضافة حتى الآن',
          icon: require('@/assets/img/no-attachment.png'),
          widthHeight: '100'
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    addAttachment() {
      this.dialogState = true
    },
    previewAttach(item) {
      // open the file
      window.open(item.url, '_blank')
    },
    async downloadAttach(item) {
      // download the file
      try {
        const response = await fetch(item.url, { mode: 'cors' })
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', item.description)

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(link.href)
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    deleteAttach(item) {
      this.$root.$confirm
        .open({
          title: 'هل أنت متأكد من حذف المرفق',
          message: 'سيتم حذف المرفق نهائياّ من النظام',
          options: {
            color: 'danger',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteAttachment(item)
          }
        })
    },
    async addNewAttachment(attachments) {
      const fd = new FormData()
      fd.append('attachment', attachments.files, attachments.files.name)
      fd.append('envId', this.currentEnv._id)
      fd.append('entityType', 'quotation')
      fd.append('entityId', this.quotation._id)

      try {
        this.isLoading = true
        await realEstateService.addNewAttachmentForQuotation(fd)
        this.getAllAttachmentsForQuotation()
        this.addNotification({
          text: 'تم إضافة المرفق بنجاح',
          color: 'success'
        })

        if (this.openAttachmentStatus) {
          this.$emit('accept')
        }
      } catch {
        this.addNotification({
          text: 'لم يتم إضافة المرفق بنجاح',
          color: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    async getAllAttachmentsForQuotation() {
      try {
        const { data } = await realEstateService.getAllAttachmentsForQuotation(
          this.quotation._id
        )
        this.attachments = data.attachments
      } catch {
        this.addNotification({
          text: 'حدثت مشكلة في المرفقات',
          color: 'error'
        })
      }
    },
    async deleteAttachment(item) {
      try {
        await realEstateService.deleteAttachmentForQuotation(item._id, {
          envId: this.currentEnv._id,
          entityType: 'quotation'
        })
        this.getAllAttachmentsForQuotation()
        this.addNotification({
          text: 'تم حذف المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث مشكلة اثناء حذف المرفق',
          color: 'error'
        })
      }
    },
    closeHandler() {
      this.dialogState = false
      this.$emit('restAttachmentData')
    }
  },
  watch: {
    openAttachmentStatus(val) {
      if (val) {
        this.dialogState = true
      }
    }
  },
  created() {
    this.getAllAttachmentsForQuotation()
  }
}
</script>
<style>
.mt {
  margin-top: -30px;
}
</style>
