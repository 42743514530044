const state = {
  realTimeSnackbar: false,
  realTimeNotificationIcon: 'defaultNotificationIcon',
  snackbar: false,
  snackbarText: '',
  snackbarTimeout: 8000,
  snackbarColor: 'secondary',
  drawer: true,
  miniDrawer: false,
  sync: false,
  coupon: false,
  dialog: false,
  endOfferDate: '2025-05-25',
  filterClientForRoute: {},
  filterClientTypeForRoute: ''
}

const actions = {}

const getters = {
  getClientForRoute(fromState) {
    return fromState.filterClientForRoute
  },
  getClientTypeForRoute(fromState) {
    return fromState.filterClientTypeForRoute
  },
  isThereAnOffer(fromState) {
    const currentDate = new Date()
    const endOfferDate = new Date(fromState.endOfferDate)

    return endOfferDate >= currentDate
  }
}

const mutations = {
  loggedInConfirm(fromState) {
    fromState.dialog = true
  },
  setDialog(fromState, val) {
    fromState.dialog = val
  },
  setClientForRoute(fromState, client) {
    fromState.filterClientForRoute = client
  },
  setClientTypeForRoute(fromState, filterClientTypeForRoute) {
    fromState.filterClientTypeForRoute = filterClientTypeForRoute
  },
  realTimeNotification(fromState, options) {
    fromState.realTimeSnackbar = true
    fromState.snackbar && (fromState.snackbar = false)
    fromState.snackbarTimeout = options.timeout ? options.timeout : 8000
    fromState.snackbarText = options.text
    fromState.realTimeNotificationIcon = options.icon
      ? options.icon
      : 'defaultNotificationIcon'
    setTimeout(() => {
      this.commit('appState/setRealTimeSnackbar', false)
    }, fromState.snackbarTimeout)
  },
  setRealTimeSnackbar(fromState, val) {
    fromState.realTimeSnackbar = val
  },

  addNotification(fromState, options) {
    fromState.snackbar = true
    fromState.snackbarText = options.text
    fromState.snackbarTimeout = options.timeout ? options.timeout : 8000
    fromState.snackbarColor = options.color ? options.color : null

    setTimeout(() => {
      this.commit('appState/setSnackbar', false)
    }, fromState.snackbarTimeout)
  },
  setSnackbar(fromState, val) {
    fromState.snackbar = val
  },

  setDrawer(fromState, val) {
    fromState.drawer = val
  },
  toggleDrawer(fromState) {
    fromState.drawer = !fromState.drawer
  },

  setMiniDrawer(fromState, val) {
    fromState.miniDrawer = val
  },
  toggleMiniDrawer(fromState) {
    fromState.miniDrawer = !fromState.drawer
  },
  setSync(fromState, val) {
    fromState.sync = val
  },
  setCoupon(fromState, val) {
    fromState.coupon = val
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
