<template>
  <v-toolbar
    height="80px"
    id="page-tollbar"
    class="my-5 narrow-toolbar elevation-0"
  >
    <div class="page-title">
      <!--breadcrumbs-->
      <v-breadcrumbs class="pa-1 pt-3 breadcrumb" :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-left</v-icon>
        </template>
      </v-breadcrumbs>
      <!--Count tasks-->
      <v-toolbar-title class="d-flex align-center">
        <v-toolbar-title class="font-weight-bold"> {{ title }}</v-toolbar-title>
        <span class="sub-title mx-2">
          {{ itemsLength }}
          {{ lengthTitle }}
        </span>
      </v-toolbar-title>
    </div>
  </v-toolbar>
</template>

<script>
export default {
  name: 'Toolbar',
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    },
    itemsLength: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: 'المهام'
    },
    lengthTitle: {
      type: String,
      default: ''
    }
  }
}
</script>
