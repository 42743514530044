import axios from 'axios'
import config from '../config'

const maintenanceStats = (envId) => {
  const url = `${config.bonds}/requests/stats/${envId}`
  return axios.get(url)
}

const hectarStats = function (body) {
  const url = `${config.realEstateUrl}/stats/hectar`
  return axios.post(url, body)
}

const realEstateStats = function (body) {
  const url = `${config.realEstateUrl}/realEstates/dashboard-stats`
  return axios.post(url, body)
}

const contractsStats = function (body) {
  const url = `${config.bonds}/stats/contracts`
  return axios.post(url, body)
}

export const statisticsService = {
  maintenanceStats,
  hectarStats,
  realEstateStats,
  contractsStats
}
