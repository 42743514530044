<template>
  <div>
    <!-- Realestate type -->
    <dropdown-alt
      class="mt-6"
      ref="realestateTypes"
      v-bind="realEstateTypesProps"
      @filterTypes="setBuildingDetails($event, 'type')"
      @setValidate="setDropdownValidation(dropdownValidate.type.text)"
    />

    <!-- Realestate status -->
    <dropdown-alt
      ref="realEstateStatus"
      v-bind="realEstateStatusProps"
      @setValidate="setDropdownValidation(dropdownValidate.status.text)"
      @filterTypes="setBuildingDetails($event, 'status')"
    />

    <v-row no-gutters class="mb-2">
      <v-col cols="12" md="12" v-if="realestatePurpose.length > 1">
        <dropdown-alt
          ref="realestatePurpose"
          :configrations="{ name: 'الغرض من العقار' }"
          :listingType="realestatePurpose"
          :validate="dropdownValidate.purpose.val"
          :defaultValue="addPropertyInfoPurpose"
          @filterTypes="setBuildingDetails($event, 'purpose')"
          @setValidate="setDropdownValidation(dropdownValidate.purpose.text)"
        />
      </v-col>
    </v-row>

    <v-card-actions class="pt-4 px-0 d-flex align-center justify-end">
      <v-btn data-test="next-tab" @click="nextTab" color="primary">
        <span class="mb-1 ml-1">التالي</span>
        <v-icon class="font-16">mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import DropdownAlt from '../dropdownAlt'
import { mapState } from 'vuex'

export default {
  name: 'PropertyDetails',
  components: {
    DropdownAlt
  },
  props: {
    realestateTypes: {},
    realEstateStatus: {},
    resetValidation: {
      default: false
    },
    propertyInfo: {
      default() {
        return {}
      }
    },
    isMainPropDisabled: {
      default: false
    },
    propertyInfoValid: {}
  },
  data() {
    return {
      dropdownValidate: {
        type: {
          text: 'type',
          val: false
        },
        status: {
          text: 'status',
          val: false
        },
        purpose: {
          text: 'purpose',
          val: false
        }
      },
      allDropDownValidation: {
        status: {
          status: false,
          required: true
        },
        type: {
          status: false,
          required: true
        },
        purpose: {
          status: false,
          required: false
        }
      },
      multipleUnitsBuilding: [
        'villa',
        'building',
        'compound',
        'Tower',
        'Building'
      ],
      addPropertyInfo: {},
      hasPurposeTypes: [
        'land',
        'compound',
        'building',
        'Tower',
        'Building',
        'Storehouse'
      ],
      hasResidentialPurpose: ['flat', 'villa', 'floor']
    }
  },
  methods: {
    setDropdownValidation(key) {
      this.dropdownValidate[key].val = true
    },
    setBuildingDetails(e, key) {
      this.$set(this.addPropertyInfo, key, e)
      this.allDropDownValidation[key].status = true
      if (key === 'type') {
        delete this.addPropertyInfo.purpose
        if (!this.hasPurposeTypes.includes(e.nameEn)) {
          if (this.hasResidentialPurpose.includes(e.nameEn)) {
            this.addPropertyInfo.purpose = this.realestatePurpose.find(
              (purpose) => purpose.nameEn === 'Residential'
            )
          } else {
            this.addPropertyInfo.purpose = this.realestatePurpose.find(
              (purpose) => purpose.nameEn === 'Commercial'
            )
          }
        }
        if (this.multipleUnitsBuilding.includes(e.nameEn)) {
          this.addPropertyInfo.isBuilding = true
        } else {
          this.addPropertyInfo.isBuilding = false
        }
      }
    },
    nextTab() {
      Object.keys(this.dropdownValidate).forEach((v) => {
        this.dropdownValidate[v].val = true
      })
      if (this.dropDownValidation) {
        this.$emit('setAddPropertyInfo', this.addPropertyInfo)
        this.$emit('nextTab')
      } else {
        this.$emit('addNotification')
      }
    }
  },
  watch: {
    'addPropertyInfo.type': {
      immediate: true,
      handler(val, oldVal) {
        const isNewTypeHasPurposes =
          val?.nameEn === 'compound' ||
          val?.nameEn === 'building' ||
          val?.nameEn === 'Storehouse' ||
          val?.nameEn === 'Tower' ||
          val?.nameEn === 'Building'
        if (oldVal?.nameEn === 'land' && isNewTypeHasPurposes) {
          this.$refs.realestatePurpose.id = ''
          this.$refs.realestatePurpose.checked = false
          this.allDropDownValidation.purpose.status = false
          this.allDropDownValidation.purpose.required = true
        }
        if (
          ![
            'land',
            'building',
            'compound',
            'Storehouse',
            'Tower',
            'Building'
          ].includes(val?.nameEn)
        ) {
          this.allDropDownValidation.purpose.status = false
          this.allDropDownValidation.purpose.required = false
        } else {
          this.allDropDownValidation.purpose.required = true
        }
      }
    },
    resetValidation: {
      handler() {
        if (!this.propertyInfoValid) {
          Object.keys(this.dropdownValidate).forEach((v) => {
            this.dropdownValidate[v].val = false
          })
          this.$refs.realestateTypes.id = ''
          this.$refs.realEstateStatus.id = ''
          this.$refs.realestateTypes.checked = false
          this.$refs.realEstateStatus.checked = false
          if (this.realEstatesHasPurpose) {
            this.$refs.realestatePurpose.id = ''
            this.$refs.realestatePurpose.checked = false
          }
          this.allDropDownValidation = {
            status: {
              status: false,
              required: true
            },
            type: {
              status: false,
              required: true
            },
            purpose: {
              status: false,
              required: false
            }
          }
          this.addPropertyInfo = {}
        }
      }
    },
    propertyInfo: {
      immediate: true,
      handler() {
        if (this.propertyInfoValid) {
          this.addPropertyInfo = { ...this.propertyInfo }
          this.allDropDownValidation.type.status = true
          this.allDropDownValidation.status.status = true
          if (this.realEstatesHasPurpose) {
            this.allDropDownValidation.purpose.status = true
            this.allDropDownValidation.purpose.required = true
          }
        }
      }
    }
  },
  computed: {
    ...mapState('accountState', ['configs']),
    realEstatesHasPurpose() {
      return !!this.hasPurposeTypes.includes(this.buildingType)
    },
    addPropertyInfoType() {
      console.log(this.addPropertyInfo)
      console.log(this.realestatePurpose)
      return this.addPropertyInfo?.type?.nameAr ?? ''
    },
    addPropertyInfoStatus() {
      return this.addPropertyInfo?.status?.nameAr ?? ''
    },
    addPropertyInfoPurpose() {
      const purposeType = this.realestatePurpose.find(
        (purpose) => purpose._id === this.addPropertyInfo?.purpose?._id
      )
      return purposeType?.nameAr
    },
    realestatePurpose() {
      const realestatePurpose = [...this.configs.realestatePurpose]
      const purposeKeys = Object.keys(
        this.addPropertyInfo?.type?.ref ?? []
      ).map((key) => {
        return key
      })
      return realestatePurpose.filter((purpose) => {
        return purposeKeys.includes(purpose.nameEn)
      })
    },
    isBuilding() {
      return this.addPropertyInfo?.type?.nameEn === 'building'
    },
    buildingType() {
      return this.addPropertyInfo?.type?.nameEn || ''
    },
    dropDownValidation() {
      let propertyNames = Object.values(this.allDropDownValidation)
      propertyNames = propertyNames.filter((i) => i.required)
      return propertyNames.every((i) => i.status)
    },
    realEstateStatusProps() {
      return {
        configrations: { name: 'حالة العقار', cols: 3 },
        defaultValue: this.addPropertyInfoStatus,
        validate: this.dropdownValidate.status.val,
        listingType: this.realEstateStatus,
        disable: {
          status: this.isMainPropDisabled,
          message: 'لا يمكن تغيير حالة العقار',
          messageType: 'notification'
        }
      }
    },
    realEstateTypesProps() {
      return {
        configrations: { name: 'نوع العقار', cols: 3 },
        listingType: this.realestateTypes,
        validate: this.dropdownValidate.type.val,
        defaultValue: this.addPropertyInfoType,
        disable: {
          status: this.isMainPropDisabled,
          message: 'لا يمكن تغيير نوع العقار',
          messageType: 'notification'
        }
      }
    }
  }
}
</script>
