<template>
  <v-card class="px-0">
    <!-- Header -->
    <v-card-title>
      <v-row class="py-2 font-16">
        معلومات الجمعية
        <v-spacer />
        <v-btn data-test="openModalEdit" @click="editHandler" icon>
          <v-icon class="ml-4" color="grey">mdi-pencil-outline</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>

    <!-- Body -->
    <v-row class="pa-6 pt-0">
      <v-col>
        <div class="details-title">تاريخ الإنشاء</div>
        <h5 class="ma-0 font-weight-medium text-truncate">21 يناير 2022</h5>
      </v-col>

      <v-col>
        <div class="details-title">المسؤول عن الجمعية</div>
        <h5 class="ma-0 font-weight-medium text-truncate">أحمد محمد الكريم</h5>
      </v-col>

      <v-col>
        <div class="details-title">اسم العقار</div>
        <h5 class="ma-0 font-weight-medium text-truncate">مجمع العزيزية</h5>
      </v-col>

      <v-col class="pr-0">
        <div class="details-title">عنوان العقار</div>
        <h5 class="ma-0 font-weight-medium text-truncate">
          24 شارع جرير, الملز, مدينة الرياض, 1775
        </h5>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'AssociationDetailsInfoCard',
  mixins: [dateFormat],
  data() {
    return {}
  },
  computed: {
    ...mapState('accountState', ['user']),
    signingDate() {
      //   return this.formatDate(new Date(this.realEstate?.createdAt))
      return 'date'
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    editHandler() {
      this.$emit('edit')
    }
  }
}
</script>

<style lang="scss" scoped>
.details-title {
  font-size: 12px;
  line-height: 15px;
  color: #737373;
}

.borderBtn {
  border: 1px #e8e5e5 solid;
  border-right: 0px;
  border-radius: 10px 0px 0px 10px !important;
}
</style>
