export default {
  data() {
    return {}
  },
  methods: {
    timeDifferenceCalc(date) {
      const currentDate = new Date()
      const notificationDtae = new Date(date)
      const hourDiff = Math.abs(currentDate - notificationDtae) / 36e5
      if (hourDiff < 1) {
        return Math.floor(hourDiff * 60) === 0
          ? 'الأن'
          : `${Math.floor(hourDiff * 60)} دقيقه`
      } else if (hourDiff > 23) {
        return `${Math.ceil(hourDiff / 24)} يوم`
      }
      return `${Math.floor(hourDiff)} ساعه`
    }
  }
}
