<template>
  <div class="quotation--paper2 quotation--paper5">
    <h1>مخطط العقار</h1>
    <h2 class="ma-0">{{ quotation.realEstate?.bluePrint?.length }} مخططات</h2>

    <div class="quotation--paper6__images mt-10 d-flex">
      <div
        class="quotation--paper6__images__image"
        v-for="(image, i) in quotation.realEstate?.bluePrint"
        :key="i"
      >
        <img v-if="image?.image" :src="image?.image" alt="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Paper7',
  props: {
    quotation: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
