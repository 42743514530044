<template>
  <div class="px-4 ma-0 scroll" v-if="reports.length">
    <div class="py-2 d-flex align-center">
      <figure>
        <img
          src="../../assets/img/svgs/report-icon-colored.svg"
          alt="Reports Icon"
          loading="lazy"
        />
      </figure>
      <!-- reports-icon-colored  -->
      <h6 class="primary--text font-12 mr-2 font-weight-bold">
        الإحصائيات والتقارير
      </h6>
    </div>
    <div
      :key="i"
      class="clientListItem pointerCursor py-2 px-1"
      v-for="(report, i) in reports"
    >
      <h6 class="black--text font-weight-bold font-12 mb-0">
        {{ report.title || report.name }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'report-search-comp',
  props: ['reports'],
  data() {
    return {
      propTempPic: require('@/assets/img/propTempPic.png')
    }
  }
}
</script>
<style lang="scss" scoped>
.clientListItem:hover {
  background: #f9f9f9;
  border-radius: 7px;
}
</style>
