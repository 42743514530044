<template>
  <v-data-table
    v-if="!gridView"
    :headers="headers"
    :items="contracts"
    hide-default-footer
    mobile-breakpoint="0"
    class="hectar-table"
    :item-class="itemClass"
  >
    <template v-slot:[`item.number`]="{ item }">
      <span class="text--101010">
        {{ item.number || '-' }}
      </span>
    </template>

    <template v-slot:[`item.title`]="{ item }">
      <!-- <h6
        v-if="item.status === 'cancelled'"
        class="font-14 ma-0 text-truncate max-w-300"
      >
        {{ item.title }}
      </h6> -->
      <h6
        @click="showDetails({ ...item })"
        class="cursor font-14 ma-0 text-truncate max-w-300"
      >
        {{ item.title }}
      </h6>
    </template>

    <template v-slot:[`item.type`]="{ item }">
      <div class="d-flex">
        <h5
          :class="tagColor(item.typeName)"
          class="mx-auto ma-0 tag-sm text-center py-0 pb-1"
        >
          <span class="font-12 font-weight-bold text-truncate" v-if="item.type">
            {{ item.type.nameAr }}
          </span>
        </h5>
      </div>
    </template>

    <template v-slot:[`item.start`]="{ item }">
      <h6 class="font-14 ma-0 text-truncate max-w-300">
        {{ formatDate(item.start) }}
      </h6>
    </template>

    <template v-slot:[`item.moderator`]="{ item }">
      <div class="d-flex">
        <div class="align-center mx-auto d-flex">
          <div>
            <span
              v-if="item.moderator"
              class="mx-auto py-0 green--tag bill-md font-12 ml-2 d-flex align-center justify-center"
            >
              {{ $subString(item.moderator.name) }}
            </span>
          </div>
          <div>
            <h5 v-if="item.moderator" class="ma-0 font-weight-medium">
              {{ item.moderator.name }}
            </h5>
            <span v-else>-</span>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <div class="d-flex justify-center">
        <div class="d-flex align-center">
          <div
            :class="contractsStatuses[item.status].tagColor"
            class="px-5 mb-1 tabel-tag text-center font-12 font-weight-bold d-flex align-center"
          >
            <v-sheet
              class="dotted ma-0 pa-0 ml-2"
              :color="contractsStatuses[item.status].color"
            ></v-sheet>
            <span class="mb-1 text-truncate">{{
              contractsStatuses[item.status].nameAr
            }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:[`item.actions`]="{ item, index }">
      <div class="my-auto mobile__ml__10px">
        <v-menu
          content-class="dropdown-menu"
          transition="slide-x-transition"
          bottom
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="lamb"
              class="options-btn"
              icon
              v-bind="attrs"
              v-on="on"
              :disabled="isTerminated(item)"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(action, i) in validActions(item)"
              @click="
                callActionFun({
                  action,
                  item: { ...item, index }
                })
              "
              :key="i"
            >
              <v-list-item-icon v-if="action.icon">
                <v-icon :color="action?.iconColor">{{ action.icon }}</v-icon>
              </v-list-item-icon>
              <img
                v-if="action.img"
                :src="action.img"
                class="mr-1 ml-2"
                loading="lazy"
              />
              <v-list-item-content>
                <v-list-item-title :class="action.class">{{
                  action.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import contractsSharedData from '@/mixins/contractsSharedData'

export default {
  name: 'data-table-contracts',
  mixins: [dateFormat, contractsSharedData],
  props: {
    contracts: {
      type: Array,
      required: true
    },
    gridView: {
      type: Boolean,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  },
  methods: {
    validActions(item) {
      return this.actions.filter((action) => {
        return this.isAvalidAction({ item, action })
      })
    },
    itemClass(item) {
      // return item.status === 'cancelled' ? 'unclickable' : ''
    }
  }
}
</script>

<style lang="scss">
// .unclickable {
//   opacity: 0.5;
// }
</style>
