<template>
  <v-card
    class="w-100 d-flex justify-space-between px-5 pt-3 pb-2 my-3 mobile__flex__wrap mobile__px__15px mobile__py__15px"
  >
    <div
      class="d-flex align-center notification-container mobile__flex__wrap mobile__w__auto"
    >
      <div class="ml-4">
        <div class="notification-icon">
          <img :src="notificationImage" />
        </div>
      </div>
      <div>
        <div class="d-flex align-center mobile__flex__wrap">
          <span class="font-weight-medium font-14">
            {{ notificationTitle }}
          </span>
          <span class="notification-badge mr-2 font-12">
            {{ notificationCategory }}
          </span>
        </div>
        <p class="notification-text font-12 mt-1">
          {{ notificationContent }}
        </p>
      </div>
    </div>

    <div class="d-flex align-center">
      <div
        class="d-flex align-center"
        :class="{ 'ml-5': notificationIndex === 0 }"
      >
        <span class="caption grey--text mx-2">تشغيل الي</span>
        <v-switch
          class="ma-auto"
          dense
          hide-details="auto"
          inset
          v-model="notificationStatus"
          :disabled="isNotificationLoading"
          @change="changeNotificationStatus(notificationStatus)"
        ></v-switch>
      </div>

      <img
        alt="editPen"
        class="cursor"
        height="18"
        :src="editIcon"
        loading="lazy"
        @click="openNotificationModal"
        v-if="notification.titleEn"
      />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'CustomerNotificationCard',
  props: {
    notification: {
      type: Object,
      required: true
    },
    notificationIndex: {
      type: Number,
      required: true
    },
    isChangeProcessFailed: {
      type: Boolean
    },
    isNotificationLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      notificationIcon: require('@/assets/img/svgs/sms-message.svg'),
      customerIcon: require('@/assets/img/svgs/customer-notification.svg'),
      editIcon: require('@/assets/img/svgs/editPen.svg'),
      notificationStatus: null
    }
  },
  computed: {
    notificationImage() {
      return this.notification.category
        ? this.notificationIcon
        : this.customerIcon
    },
    notificationTitle() {
      if (this.notification.title) {
        return this.notification.title
      }
      return this.notification.__type === 'customer'
        ? 'إشعارات العملاء'
        : 'إشعارات الفريق'
    },
    notificationCategory() {
      switch (this.notification.category) {
        case 'contract':
          return 'إشعارات إبرام و إنتهاء العقود'
        case 'financial':
          return 'الإشعارات الماليه'
        default:
          return 'إعدادات الإشعارات'
      }
    },
    notificationContent() {
      return (
        this.notification.content ??
        'يمكنك تفعيل إشعارات العملاء ، لتكون على تواصل دائم مع عملاءك'
      )
    }
  },
  watch: {
    notification: {
      immediate: true,
      handler() {
        this.notificationStatus = this.notification.status
      }
    },
    isChangeProcessFailed: {
      handler(val) {
        if (val && this.notificationStatus !== this.notification.status) {
          this.notificationStatus = this.notification.status
        }
      },
      immediate: true
    }
  },
  methods: {
    openNotificationModal() {
      this.$emit('openNotificationModal')
    },
    changeNotificationStatus(val) {
      const newSettings = {
        ...this.notification,
        status: val,
        viaPlatform: val,
        viaSMS: val,
        viaEmail: val,
        includeAssignmentLink: val
      }
      this.$emit('notificationsChanged', {
        notification: newSettings,
        index: this.notificationIndex
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';

.notification-container {
  width: 70%;
  .notification-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: #ebf2fd;
  }

  .notification-badge {
    padding: 0px 10px;
    border: 1px solid #efebf2;
    border-radius: 30px;
    color: $primary-color;
    background-color: #efebf2;
    font-weight: 700;
  }

  .notification-text {
    text-align: justify;
    color: #585858;
  }
}
</style>
