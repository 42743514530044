<template>
  <div>
    <v-container class="main-container team-container">
      <!---------For toolbar------------>
      <slot />

      <!---------Loader------------>
      <Loader v-if="isPageLoading" v-bind="loaderProps" />

      <div v-else-if="Object.keys(reports).length">
        <!---------Report buttons------------>
        <ReportButtons
          class="mb-2"
          v-bind="{ pdfgen, sharePdf, excelgen, statusSharePdf }"
        >
          <h3 class="ma-0" v-text="reportTitle" />
        </ReportButtons>

        <!---------Renter Financial report------------>
        <template v-if="isRenterFinancial">
          <GeneralReportView v-bind="ownerReportViewProps" />
        </template>

        <!---------Owner Financial report------------>
        <template v-else-if="isOwnerFinancial">
          <OwnerReportView v-bind="ownerReportViewProps" />
        </template>

        <template v-else>
          <!---------Data table report------------>
          <DataTableReports v-bind="dataTableReports" id="PRINT-REPORT" />
          <!---------Pagination------------>
          <div
            class="mt-6 position-relative container"
            v-if="pagination.pagesCount > 1"
          >
            <Pagination :pagination="pagination" />
          </div>
        </template>
      </div>

      <!---------If no data------------>
      <NoData v-else-if="!reports.length" v-bind="noDataProps" />
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { reportService } from '@/services/report.service'
import DataTableReports from '@/components/reports/data-table-reports'
import ReportButtons from '@/components/reports/ReportButtons'
import NoData from '@/components/helper/noData.vue'
import Pagination from '@/components/helper/pagination.vue'
import Loader from '@/components/helper/loader.vue'
import reportTotalArabicKeys from '@/constans/reportTotalArabicKeys.js'
import dateFormat from '@/mixins/dateFormat.js'
import config from '../../config'

export default {
  name: 'ReportDetails',
  mixins: [dateFormat],
  props: {
    statusSharePdf: {
      type: Boolean,
      default: false
    },
    clickedReport: {
      default() {
        return {}
      }
    },
    viewSharedPdf: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DataTableReports,
    ReportButtons,
    NoData,
    Pagination,
    Loader,
    OwnerReportView: () => import('@/components/reports/OwnerReportView.vue'),
    GeneralReportView: () =>
      import('@/components/reports/GeneralReportView.vue')
  },
  data() {
    return {
      realestate: {},
      report_user: {},
      totalTableKeys: [
        'المبلغ',
        'قيمه الضريبه',
        'الإجمـــــــــــــــــالــــــي'
      ],
      mainReports: [],
      haveTotalRow: false,
      isPageLoading: true,
      noDataConfig: {
        text: 'لا توجد تقارير مضافه حتي الأن',
        icon: require('@/assets/img/reports/3.svg'),
        subTitle:
          'يمكن إضافه تقارير جديده, اضف معلومات التعاقد بينك وبين عملائك, واحتفظ بكل التقارير <br />في مكان واحد أمن'
      },
      headers: [],
      reports: [],
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      reportTotalArabicKeys: reportTotalArabicKeys.arabicKeys
    }
  },
  async created() {
    this.getReportData()
  },
  watch: {
    reports(val) {
      if (this.isItAGeneralReport) {
        this.generatingComplexReportObj(this.clickedReport?.sectionFields)
      } else {
        this.pagination.pagesCount = Math.ceil(
          val.length / this.pagination.pageSize
        )
        this.pagination.count = val.length
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    ...mapGetters('accountState', ['companyImages']),
    isLastPaginationPage() {
      return !!(this.pagination.pagesCount === this.pagination.pageNumber)
    },
    ownerReportViewProps() {
      return {
        generalReportsHeaderData: this.generalReportsHeaderData,
        mainReports: this.mainReports,
        headers: this.headers,
        reports: this.reports,
        totalTableKeys: this.totalTableKeys,
        generateTooltipData: this.generateTooltipData,
        headersInit: this.headersInit
      }
    },
    companyLogoPath() {
      return this.companyImages?.companyLogo?.path || ''
    },
    isItAGeneralReport() {
      return this.isOwnerFinancial || this.isRenterFinancial
    },
    isRenterFinancial() {
      return !!this.clickedReport?.title.includes('مالي عام لمستأجر')
    },
    isOwnerFinancial() {
      return !!this.clickedReport?.title.includes('مالي عام لمالك')
    },
    isRentContractReport() {
      return !!this.clickedReport?.title.includes('عقود إيجار')
    },
    titleReportOwnerName() {
      return this.reports.ownersStatement ? 'اسم المالك' : 'اسم المستأجر'
    },
    reportUser: {
      set(val) {
        this.report_user = val
      },
      get() {
        return this.report_user?.name || ''
      }
    },
    reportRealestate: {
      set(val) {
        this.realestate = val
      },
      get() {
        return this.realestate?.name || ''
      }
    },
    tableClasses() {
      return this.haveTotalRow && this.isLastPaginationPage ? 'totalTable' : ''
    },
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    noDataProps() {
      return {
        noDataText: this.noDataConfig.text,
        noDataSubTitle: this.noDataConfig.subTitle
      }
    },
    reportsToBeDispayed() {
      // Handle Pagination from front-side
      const res = this.pagination.pageNumber * 10 + 1
      return this.reports.slice(res - 11, res - 1) ?? []
    },
    dataTableReports() {
      return {
        headers: this.headers,
        reports: this.reportsToBeDispayed,
        stopClicked: true,
        tableClasses: this.tableClasses
      }
    },
    pdfTablePageChunk() {
      const obj = {}
      Object.keys(this.reports).forEach((report) => {
        obj[report] = this.arrayChunk(this.reports[report], 28)
        return obj
      })
      delete obj?.ownersStatement
      Object.keys(obj).forEach((key) => {
        if (obj[key].length === 0) {
          delete obj[key]
        }
      })
      return obj
    },
    pdfTableChunks() {
      let obj = []
      obj = this.arrayChunk(this.reports, 32)
      Object.keys(obj).forEach((key) => {
        if (obj[key].length === 0) {
          delete obj[key]
        }
      })
      return obj
    },

    reportTitle() {
      const splittedTitle = this.clickedReport.title?.split('-')
      return !!this.reportRealestate &&
        (this.isItAGeneralReport || this.isRentContractReport)
        ? `${splittedTitle[0]} - ${this.reportRealestate}`
        : this.clickedReport.title
    },
    reportDuration() {
      return `${this.formatDate(this.clickedReport.start)} - ${this.formatDate(
        this.clickedReport.end
      )}`
    },
    generalReportsHeaderData() {
      return [
        { title: 'رقم التقرير', value: this.clickedReport.number },
        { title: this.titleReportOwnerName, value: this.reportUser },
        {
          title: 'تاريخ التقرير',
          value: this.formatDate(this.clickedReport.createdAt)
        },
        { title: 'مدة التقرير', value: this.reportDuration }
      ]
    }
  },
  methods: {
    ...mapMutations('appState', ['toggleDrawer', 'addNotification']),
    arrayChunk(inputArray, perChunk = 11) {
      return inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)
        if (!resultArray[chunkIndex]) {
          // start a new chunk
          resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      }, [])
    },
    getTotalKeysAr(text) {
      switch (text) {
        case 'totalPaid':
          return 'إجمالي المصروفات'
        case 'totalCredit':
          return 'إجمالي '
        case 'totalDues':
          return 'إجمالي المحصلات'
        default:
          return ''
      }
    },
    generatingComplexReportObj(data) {
      const subFieldsCopy = [...data]
      subFieldsCopy.map((filed) => {
        this.mainReports.push({
          code: this.isOwnerFinancial ? filed.code : filed.categoryCode,
          nameAr: this.isOwnerFinancial ? filed.nameAr : filed.categoryAr,
          checked: true
        })
      })
      const codes = this.mainReports.map((R) => R.code)
      this.mainReports = this.mainReports.filter(
        ({ code }, index) => !codes.includes(code, index + 1)
      )
    },
    bindTotalData(response) {
      const totalRow = {}
      _.map(response, (val, key) => {
        if (key !== 'headers' && key !== 'body') {
          totalRow[key] = val
        }
      })
      if (this.reports.length && Object.keys(totalRow).length) {
        this.reports.push(totalRow)
        this.haveTotalRow = true
      }
    },
    headersInit(headers = this.headers) {
      headers.map((header) => {
        header.text = header.nameEn !== 'total' ? header.nameAr : null
        header.value = header.nameEn
        header.sortable = false
        header.align = 'center'
      })
      return headers
    },

    async getReportData() {
      try {
        if (Object.keys(this.clickedReport).length) {
          const response = await reportService.generateReportData(
            this.clickedReport._id
          )
          this.reports = response.data.result.body
          this.headers = response.data.result.headers
          this.reportRealestate = response.data.result.realestate
          this.reportUser = response.data.result.user
          this.bindTotalData(response.data.result)
          if (!this.isItAGeneralReport) {
            this.headers = this.headersInit()
          }
          this.isPageLoading = false
        }
      } catch (err) {
        this.addNotification({
          text: err,
          color: 'error'
        })
      }
    },
    pdfgen() {
      const url = `${config.reportsUrl}/reports/generate/${this.clickedReport?._id}?format=pdf&logo=${this.companyLogoPath}`
      window.open(url, '_blank', 'noreferrer')
    },
    // When click on share button will trigger this function
    sharePdf() {
      this.$root.$socialSharing.open({
        query: `?data=${encodeURIComponent(
          JSON.stringify(this.clickedReport)
        )}&component=reportPdf&type=التقرير ${this.clickedReport?.title}`,
        type: `التقرير ${this.clickedReport?.title} `
      })
    },
    async excelgen() {
      if (Object.keys(this.clickedReport).length) {
        const query = 'format=excel'
        const responseType = 'blob'
        const response = await reportService.generateReportData(
          this.clickedReport._id,
          query,
          responseType
        )
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${Date.now()}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    },
    generateTooltipData(cellName) {
      const isRentIncome = cellName === 'إيرادات الإيجار'
      const isServicesIncome = cellName === 'إيرادات الخدمات'
      return {
        status: isRentIncome || isServicesIncome,
        text: isRentIncome
          ? 'إجمالي قيم التأجير المستلمة من المستأجرين'
          : 'إجمالي قيم الخدمات المستلمة من المستأجرين من مياه وغاز وكهرباء'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/report/totalTable.scss';

.dFlex {
  display: flex;
}
.greyText {
  color: #9e9e9e;
}
.mb2px {
  margin-bottom: 2px;
}
///
.pdf__btns {
  box-shadow: 2px 2px 20px rgb(0 0 0 / 3%);
  border-radius: 7px;
}

.pdf__content {
  margin: 12px;
}
</style>
