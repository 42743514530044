<template>
  <v-container>
    <v-app-bar flat color="transparent">
      <v-btn
        v-if="responsive"
        class="default v-btn--simple"
        icon
        @click="drawerState = !drawerState"
      >
        <v-img :src="burgerMenu" class="site-logo" height="20" contain />
      </v-btn>
      <v-toolbar-title class="pr-0 max-w-200 logo--width">
        <v-btn
          v-if="responsive"
          class="default v-btn--simple"
          icon
          @click.stop="onClickBtn"
        >
          <v-img :src="resLogo" class="site-logo" height="30" contain />
        </v-btn>
        <router-link class="toolbar-items" to="/" v-if="logoState">
          <v-img :src="currentLogo" class="site-logo" height="50" contain />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <div v-if="!responsive">
        <a href="https://hectar.io/about" target="_blank">
          <v-btn class="font-14 text--101010" rounded text color="black">
            عن هكتار
          </v-btn>
        </a>
        <v-btn
          @click="scroll('adv')"
          v-if="!isCustomerLandingPage"
          class="font-14 text--101010"
          rounded
          text
          color="black"
        >
          المميزات
        </v-btn>
        <v-btn
          @click="scroll('feautres')"
          class="font-14 text--101010"
          rounded
          text
          color="black"
          v-if="!isCustomerLandingPage"
        >
          الخصائص
        </v-btn>
        <v-btn
          @click="scroll('bundels')"
          v-if="!isCustomerLandingPage"
          class="font-14 text--101010"
          rounded
          text
          color="black"
        >
          الباقات
        </v-btn>
        <!-- <router-link
          :to="{ name: 'customerLandingPage' }"
          v-if="!isCustomerLandingPage"
        >
          <v-btn class="font-14 text--101010" rounded text color="black">
            بوابة العملاء
          </v-btn>
        </router-link> -->
        <router-link v-else :to="{ name: 'landingPage' }">
          <v-btn class="font-14 text--101010" rounded text color="black">
            بوابة الأعمال
          </v-btn>
        </router-link>
        <!-- <a href="https://hectar.io/">
          <v-btn class="font-14 text--101010" rounded text color="black">
            بوابة الخدمات
          </v-btn>
        </a> -->
      </div>
      <v-spacer></v-spacer>
      <div v-if="!isEnvSet && showLoginButton">
        <router-link :to="{ path: '/auth/login', query: { auth: 'register' } }">
          <v-btn
            class="mx-1 log_reg_btns reg_btn"
            height="40px"
            width="150px"
            text
          >
            <span class="tf">إبدأ فترتك المجانية</span>
          </v-btn>
        </router-link>
        <router-link :to="{ path: '/auth/login', query: { auth: 'login' } }">
          <v-btn
            class="mx-1 log_reg_btns log_btn"
            height="40px"
            width="150px"
            text
          >
            <span class="tf">سجل دخول</span>
          </v-btn>
        </router-link>
      </div>
      <v-btn
        color="secondary"
        outlined
        rounded
        to="/dashboard"
        v-else-if="isEnvSet"
      >
        <span class="tf">لوحة التحكم</span>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer right v-model="drawerState" app width="220">
      <v-container class="pb-0 mb-2 d-flex">
        <v-img
          :src="resLogo"
          class="site-logo"
          height="30"
          width="30"
          contain
        />
        <v-icon @click="drawerState = !drawerState">mdi-close</v-icon>
      </v-container>
      <v-divider></v-divider>
      <v-list>
        <v-list-item class="d-block">
          <v-col class="pa-0" cols="12">
            <v-btn rounded text color="ma-0 primary">
              <a href="/"> الرئيسيه </a>
            </v-btn>
          </v-col>
          <v-col class="pa-0" cols="12">
            <v-btn rounded text color="ma-0 primary">
              <a href="/#adv"> المميزات </a>
            </v-btn>
          </v-col>
          <v-col class="pa-0" cols="12">
            <v-btn rounded text color="ma-0 primary">
              <a href="/#bundels"> الباقات </a>
            </v-btn>
          </v-col>
          <v-col class="pa-0" cols="12">
            <v-btn rounded text color="primary" @click="openAddDialog">
              تواصل مع المبيعات
            </v-btn>
          </v-col>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <contactSalesModel
      v-if="dialogState"
      :dialogState="dialogState"
      :registration="registration"
      @changeDialogState="dialogState = $event"
      @closed-add-dialog="closeDialog"
    />
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { smoothScroll } from '../../helpers/scroll.js'

export default {
  components: {
    contactSalesModel: () => import('../modals/contactSalesModel.vue')
  },
  data: () => ({
    drawerState: false,
    activeBtn: 1,
    logo: require('@/assets/img/Hectar_Logos/HLogo.png'),
    babyBlueLogo: require('@/assets/img/Hectar_Logos/HLogo-babyBlue.png'),
    resLogo: require('@/assets/img/svgs/responsiveLogo.svg'),
    burgerMenu: require('@/assets/img/svgs/burgerMenu.svg'),
    drawerLogo: require('@/assets/img/svgs/hectarLogo.png'),
    title: 'مزامنة مع هكتار',
    responsive: false,
    responsiveInput: false,
    matchedPath: null,
    dialogState: false,
    logoState: true,
    registration: false
  }),

  computed: {
    ...mapGetters(['authorized']),
    ...mapState('accountState', ['currentEnv']),
    ...mapState('appState', ['drawer']),
    currentLogo() {
      return this.isCustomerLandingPage ? this.babyBlueLogo : this.logo
    },
    isCustomerLandingPage() {
      return !!(this.$route.name === 'customerLandingPage')
    },
    isValidPhone() {
      return this.phoneData.valid ? this.phoneData.valid : false
    },
    isEnvSet() {
      return this.currentEnv?._id
    },
    showLoginButton() {
      return this.$route?.name !== 'login'
    }
  },

  watch: {
    $route(val) {
      this.title = val.meta.name
    },
    '$route.path'(val) {
      if (val === '/auth/login' || '/setup-env') {
        this.matchedPath = val
      }
    }
  },

  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('appState', ['setDrawer', 'toggleDrawer']),
    ...mapMutations('accountState', ['setlogInPopUp']),
    scroll(id) {
      const isScrolled = smoothScroll(id)
      if (!isScrolled) {
        this.$router.push('/')
      }
    },
    onClickBtn() {
      this.setDrawer(this.drawer)
    },
    onResponsiveInverted() {
      if (window.innerWidth < 700) {
        this.responsive = true
        this.responsiveInput = false
        this.logoState = false
      } else {
        this.drawerState = false
        this.responsive = false
        this.responsiveInput = true
        this.logoState = true
      }
    },
    openAddDialog(registration = false) {
      this.drawerState = false
      this.dialogState = true
      if (registration) {
        this.registration = true
      }
    },
    closeDialog() {
      this.dialogState = false
      this.registration = false
    }
  }
}
</script>
<style lang="scss">
.log_reg_btns {
  border-radius: 12px !important;
}
.log_btn {
  border: 1px solid #341c5c;
  span {
    color: #341c5c;
  }
}
.reg_btn {
  border: 1px solid #fff;
  background-color: #662d91;
  span {
    color: #fff;
  }
}
</style>
