<template>
  <v-data-table
    :headers="headers"
    :items="bankAccountsDetails"
    hide-default-footer
    mobile-breakpoint="0"
    class="hectar-table dataTaskTable task__table"
    :items-per-page="20"
  >
    <template v-slot:[`item.bankName`]="{ item }">
      <div>{{ item.bankName }}</div>
    </template>
    <template v-slot:[`item.accountNumber`]="{ item }">
      <div>{{ item.accountNumber }}</div>
    </template>
    <template v-slot:[`item.accountType`]="{ item }">
      <div>{{ item.accountType }}</div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div v-if="item" class="d-flex align-center justify-center">
        <v-icon
          class="mx-2 pointerCursor"
          size="22"
          @click="openEditModel(item)"
        >
          mdi-pencil-outline
        </v-icon>
        <v-icon
          class="mx-2 pointerCursor"
          size="22"
          color="red"
          @click="confirmDeletion(item)"
        >
          mdi-trash-can-outline
        </v-icon>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import { EnvService } from '@/services'
import { mapMutations } from 'vuex'

export default {
  name: 'bankAccountsListing',
  props: {
    bankAccountsDetails: {
      default() {
        return []
      }
    },
    headers: {
      default() {
        return [
          {
            text: 'إسم البنك',
            align: 'center',
            sortable: false,
            value: 'bankName',
            width: 200
          },
          {
            text: 'إسم الحساب',
            value: 'name',
            align: 'center',
            sortable: false,
            width: 300
          },
          {
            text: 'رقم الأيبان',
            value: 'iban',
            align: 'center',
            sortable: false,
            width: 300
          },
          { text: '', value: 'actions', align: 'left', sortable: false }
        ]
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    hasPermission(action) {
      return !!this.$hasPermission('users', 'bankAccountsSettings', action)
    },
    openEditModel(item) {
      if (!this.hasPermission('update')) {
        return this.addNotification({
          text: 'ليس لديك صلاحيه لتعديل الحسابات البنكيه',
          color: 'error'
        })
      }
      return this.$emit('setBankAccountDetails', item)
    },
    confirmDeletion(bankAccount) {
      if (!this.hasPermission('delete')) {
        return this.addNotification({
          text: 'ليس لديك صلاحيه لحذف الحسابات البنكيه',
          color: 'error'
        })
      }
      return this.$root.$confirm
        .open({
          title: `حذف بيانات ${bankAccount?.bankName}`,
          message: `هل أنت متأكد من حذف بيانات ${bankAccount?.bankName}`,
          options: {
            color: 'red'
          }
        })
        .then(async (confirm) => {
          if (confirm) {
            await this.deleteBankAccounts(bankAccount._id)
          }
        })
    },
    async deleteBankAccounts(bankAccountId) {
      try {
        await EnvService.deleteBankAccounts(bankAccountId)
        this.addNotification({
          text: 'تم حذف بيانات الحساب البنكي بنجاح',
          color: 'success'
        })
        this.$emit('updateBankAccountsList')
      } catch {
        this.addNotification({
          text: 'لم نتمكن من حذف بيانات البنك في الوقت الحالي, حاول في وقت لاحق',
          color: 'error'
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/vDataTable/bankAccountsDataTable.scss';
</style>
