<template>
  <VRow no-gutters>
    <v-col
      class="pl-3 mobile__pl__0 mb-2"
      cols="12"
      sm="6"
      v-if="showPropertType"
    >
      <h6 class="mb-2 font-14 font-weight-bold black--text">
        نوع العقار
        <span class="red--text">*</span>
      </h6>
      <!-- @input="loadClientProperties" -->

      <autocomplete
        :rules="[(v) => !!v || 'من فضلك قم بإختيار نوع العقار']"
        filled
        v-model="addRequest.type"
        :items="configs.realestateTypes"
        placeholder="نوع العقار"
        hide-details
        item-text="nameAr"
        item-value="_id"
        return-object
        dense
        append-icon="mdi-chevron-down"
        :menu-props="{
          transition: 'slide-y-transition',
          bottom: true,
          offsetY: true
        }"
      />
    </v-col>
    <v-col
      class="pl-3 mobile__pl__0 mb-2"
      cols="12"
      :sm="isNotarization ? 3 : 6"
      v-if="showPropertType"
    >
      <h6 class="mb-2 font-14 font-weight-bold black--text">
        الغرض من العقار
        <span class="red--text">*</span>
      </h6>
      <!-- @input="loadClientProperties" -->
      <autocomplete
        :rules="[(v) => !!v || 'من فضلك قم بإختيار الغرض من العقار']"
        filled
        v-model="addRequest.purpose"
        :items="autoRealEstatePurpose"
        placeholder="الغرض من العقار"
        hide-details
        item-text="nameAr"
        item-value="_id"
        return-object
        dense
        append-icon="mdi-chevron-down"
        :menu-props="{
          transition: 'slide-y-transition',
          bottom: true,
          offsetY: true
        }"
      />
    </v-col>
    <v-col
      class="pl-3 mobile__pl__0 mb-2"
      cols="12"
      sm="3"
      v-if="isNotarization"
    >
      <h6 class="mb-2 font-14 font-weight-bold black--text">
        عدد سنين العقد
        <span class="red--text">*</span>
      </h6>
      <v-text-field
        v-model="addRequest.contractYears"
        placeholder=" ادخل عدد السنين العقد"
        dense
        :rules="[
          (v) =>
            isNotarization ? !!v || ' العقد من فضلك قم بإدخال عدد السنين' : true
        ]"
        filled
        hide-details
        @input="changeArNumToEn($event, 'contractYears')"
        @keydown="$formatNumberInputs"
        class="font-14"
      ></v-text-field>
    </v-col>
    <v-row
      class="my-0 pl-3"
      style="display: flex; text-align: center"
      v-if="showPropertyDetails"
    >
      <v-col>
        <h6 class="mb-2 font-12 font-weight-bold black--text">رقم الدور</h6>
        <v-text-field
          v-model="addRequest.floorNum"
          @input="changeArNumToEn($event, 'floorNum')"
          @keydown="$formatNumberInputs"
          dense
          filled
          :rules="
            (v) => [
              showPropertyDetails ? !!v || 'من فضلك قم بإدخال رقم الدور' : true
            ]
          "
          hide-details
          class="font-14"
        ></v-text-field>
      </v-col>
      <v-col>
        <h6 class="mb-2 font-12 font-weight-bold black--text">عدد الغرف</h6>
        <v-text-field
          v-model="addRequest.numOfRooms"
          dense
          :rules="[
            (v) =>
              showPropertyDetails ? !!v || 'من فضلك قم بإدخال عدد الغرف' : true
          ]"
          filled
          @input="changeArNumToEn($event, 'numOfRooms')"
          @keydown="$formatNumberInputs"
          hide-details
          class="font-14"
        ></v-text-field>
      </v-col>
      <v-col>
        <h6 class="mb-2 font-12 font-weight-bold black--text">عدد المطابخ</h6>
        <v-text-field
          v-model="addRequest.numOfKitchens"
          dense
          :rules="[
            (v) =>
              showPropertyDetails
                ? !!v || 'من فضلك قم بإدخال عدد المطابخ'
                : true
          ]"
          filled
          @input="changeArNumToEn($event, 'numOfKitchens')"
          @keydown="$formatNumberInputs"
          hide-details
          class="font-14"
        ></v-text-field>
      </v-col>
      <v-col>
        <h6 class="mb-2 font-12 font-weight-bold black--text">
          عدد دورات المياة
        </h6>
        <v-text-field
          v-model="addRequest.numOfBathrooms"
          dense
          :rules="[
            (v) =>
              showPropertyDetails
                ? !!v || 'من فضلك قم بإدخال عدد دورات المياة'
                : true
          ]"
          filled
          hide-details
          @input="changeArNumToEn($event, 'numOfBathrooms')"
          @keydown="$formatNumberInputs"
          class="font-14"
        ></v-text-field>
      </v-col>
      <v-col>
        <h6 class="mb-2 font-12 font-weight-bold black--text">عدد الأدوار</h6>
        <v-text-field
          v-model="addRequest.numOfFloors"
          dense
          @input="changeArNumToEn($event, 'numOfFloors')"
          @keydown="$formatNumberInputs"
          filled
          :rules="[
            (v) =>
              showPropertyDetails
                ? !!v || 'من فضلك قم بإدخال عدد الأدوار'
                : true
          ]"
          hide-details
          class="font-14"
        ></v-text-field>
      </v-col>
    </v-row>
    <transition name="fade">
      <SearchAMap
        v-if="clicked"
        :selectedPlace="coordinates"
        @onCheckAddress="getAdress"
      />
    </transition>
    <v-col class="pl-3 mobile__pl__0" cols="12" sm="6" v-if="showPropertType">
      <div class="gmap-search-bar">
        <h5 class="mb-2 font-14 font-weight-bold black--text">
          موقع {{ mapTitle }}
          <span class="font-12 primary--text open-map" @click="getLocation"
            >اضغط {{ clicked ? 'لغلاق' : 'لفتح' }} الخريطة</span
          >
        </h5>
        <label class="d-flex autosuggest__input-wrap">
          <gmap-autocomplete
            :rules="[
              (v) => (showPropertType ? !!v || 'من فضلك حدد المكان' : true)
            ]"
            :class="{ 'has-error': hasError }"
            :icon="{ url: require('../../assets/img/pin.svg') }"
            :options="autocompleteOptions"
            :value="location"
            @place_changed="setPlace"
            class="autosuggest__input form-control font-14 px-3 pb-0 mb-4"
            placeholder="إبحث بإسم المدينة او الحي .."
            dense
          ></gmap-autocomplete>
        </label>

        <p class="invalid-feedback d-block" v-if="hasError">
          من فضلك حدد المكان
        </p>
      </div>
    </v-col>

    <v-col class="pl-3 mobile__pl__0" cols="12" sm="6" v-if="showPropertType">
      <h6 class="mb-2 font-14 font-weight-bold black--text">المدينة</h6>
      <v-text-field
        v-model="city"
        placeholder="المدينة"
        filled
        dense
        hide-details
        :rules="[
          (v) => (showPropertType ? !!v || 'من فضلك قم بإختيار المدينة' : true)
        ]"
        class="font-14"
      ></v-text-field>
    </v-col>

    <v-col
      class="pl-3 mobile__pl__0 mb-2"
      cols="12"
      sm="6"
      v-if="showPropertType"
    >
      <h6 class="mb-2 font-14 font-weight-bold black--text">الحي</h6>
      <v-text-field
        v-model="district"
        placeholder="الحي"
        filled
        :rules="[
          (v) => (showPropertType ? !!v || 'من فضلك قم بإختيار الحي' : true)
        ]"
        :required="showPropertType"
        dense
        hide-details
        class="font-14"
      ></v-text-field>
    </v-col>

    <v-col
      class="pl-3 mobile__pl__0 mb-2"
      cols="12"
      md="6"
      v-if="showPropertType"
    >
      <h6 class="mb-2 font-14 font-weight-bold black--text">المنطقة</h6>
      <v-text-field
        v-model="province"
        placeholder="المنطقة"
        :rules="[
          (v) => (showPropertType ? !!v || 'من فضلك قم بإختيار المنطقة' : true)
        ]"
        filled
        dense
        hide-details
        class="font-14"
      ></v-text-field>
    </v-col>
  </VRow>
</template>

<script>
import { mapState } from 'vuex'
import autocomplete from '@/components/forms/autocomplete'
import SearchAMap from '@/components/map/SearchAMap.vue'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  name: 'ServiceField',
  components: { autocomplete, SearchAMap },
  props: {
    showPropertType: {
      type: Boolean,
      default: false
    },
    isNotarization: {
      type: Boolean,
      default: false
    },
    showPropertyDetails: {
      type: Boolean,
      default: false
    },
    mapTitle: { default: 'العقار' },
    currentAddress: {}
  },
  data() {
    return {
      addRequest: {},
      clicked: false,
      city: '',
      province: '',
      address: '',
      coordinates: {},
      district: '',
      location: '',
      currentPlace: { lat: 24.448378, lng: 39.586848 },
      hasError: false,
      autocompleteOptions: {
        componentRestrictions: {
          country: ['sa']
        }
      }
      // address: this.currentAddress.address || ''
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'currentEnv']),
    realestatePurpose() {
      return [...this.configs.realestatePurpose].filter((realestatePurpose) => {
        const neededType = [
          'Residential',
          'Commercial',
          'Residential_Commercial'
        ]

        return neededType.includes(realestatePurpose.nameEn)
      })
    },
    autoRealEstatePurpose() {
      // based on selected property type get real estate purpose from config
      if (!this.addRequest.type) {
        return this.realestatePurpose
      }
      const suggestions = [...this.configs.realestatePurpose].filter(
        (realestatePurpose) => {
          const propertyTypes = Object.keys(this.addRequest?.type?.ref)
          // check if property types is equal to real estate purpose nameEn
          return propertyTypes.includes(realestatePurpose.nameEn)
        }
      )

      return suggestions
    }
  },
  methods: {
    getLocation() {
      // open map
      this.clicked = !this.clicked
    },
    changeArNumToEn(value, key) {
      this.addRequest[key] = convertNumbers2English(value)
    },
    getAdress({ place }) {
      // this.clicked = false
      this.location = place.address
      this.city = place.city
      this.province = place.province
      this.district = place.district
      this.$emit('getLocation', place)
    },
    setPlace(location) {
      if (location) {
        this.address = location.formatted_address
        this.city = location.address_components[3]?.long_name
        this.province = location.address_components[2]?.long_name
        this.district = location.address_components[1]?.long_name
        this.currentPlace = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng(),
          address: this.address,
          city: this.city,
          province: this.province,
          district: this.district
        }
        this.coordinates = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng()
        }
        this.hasError = false
        this.getAdress({ place: this.currentPlace })
      }
    }
  },
  watch: {
    addRequest: {
      handler() {
        this.$emit('serviceRequest', this.addRequest)
      },
      deep: true
    },
    autoRealEstatePurpose: {
      handler(val) {
        if (val.length === 1) {
          // auto select
          this.addRequest.purpose = val[0]
        } else if (val.length > 1) {
          this.addRequest.purpose = ''
        }
      }
    }
  },

  mounted() {}
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.open-map {
  cursor: pointer;
  background: #f5f5f5;
  padding: 5px 10px;
  border-radius: 9px;
}
</style>
<style>
.add-new-property .autosuggest__input {
  min-height: 39px;
}
</style>
