<template>
  <div
    :class="{
      'show-detailes pdf-summary-container taxInvoicesPDF': downloadPdfMarkup
    }"
  >
    <v-slide-x-transition>
      <v-layout>
        <v-col
          v-if="downloadPdfMarkup"
          :cols="5"
          @click="$emit('closePdf')"
          class=""
        ></v-col>
        <v-col
          :cols="5"
          class="whiteSide pa-0 tax-pdf"
          :id="!downloadPdfMarkup ? 'toBePrint' : ''"
          :class="{ 'min-h-auto': !downloadPdfMarkup }"
        >
          <pdfHeader
            class="mt-1 mx-4"
            v-bind="{ viewSharedPdf, tax }"
            @closeDetails="$emit('closePdf')"
            @updateSignatures="Signatures = $event"
            @downloadPdf="downloadPdf"
            :downloadPdfMarkup="false"
            :showHeader="showHeader"
          />
          <!-- <v-divider class="mt-3"></v-divider> -->
          <v-layout class="d-flex mt-10">
            <div class="d-flex pa-0 mx-auto w-100">
              <div
                class="d-flex flex-column justify-space-between align-center px-2 w-100"
              >
                <div class="scroll w-100">
                  <div class="d-block pb-3" v-if="tax">
                    <div class="topSection d-flex px-5 pt-2">
                      <div class="topSection__right dashed-border">
                        <table>
                          <tr v-for="(info, j) in companyInfo" :key="j">
                            <td class="text font-12 font-weight-bold">
                              {{ info.text }}
                            </td>
                            <td></td>
                            <td class="value font-12">
                              {{ info.value }}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <v-spacer />
                      <div v-if="imageData.length">
                        <img :src="imageData" alt="QrCode" />
                      </div>
                    </div>
                    <div class="infoSection ma-5 pa-0 pb-5">
                      <div
                        class="infoSection__client-detail font-13 dashed-border mb-1 pa-0"
                      >
                        <div class="infoSection__client-detail__ul">
                          <ul>
                            <li class="bg-gray">
                              <div class="client-name mw-60 font-weight-bold">
                                <span>اسم العميل</span>
                                <span class="font-12 pr-1">Client Name</span>
                              </div>
                              <!-- client-name -->
                              <div class="">
                                <span class="name">{{ taxRenter }}</span>
                              </div>
                            </li>
                            <!-- li 1 -->
                            <li class="bg-gray">
                              <div class="id-number mw-60 font-weight-bold">
                                <span>{{ renterIdNumberText.nameAr }}</span>
                                <span class="font-12 pr-1">{{
                                  renterIdNumberText.nameEn
                                }}</span>
                              </div>
                              <!-- id-number -->
                              <div class="text-left">
                                <span class="number">
                                  {{ renterCommercialRegister }}
                                </span>
                              </div>
                            </li>
                            <!-- li 2 -->
                            <li>
                              <div class="tax-number mw-60 font-weight-bold">
                                <span>الرقم الضريبي </span>
                                <span class="font-12 pr-1">TAX number</span>
                              </div>
                              <!-- client-name -->
                              <div class="">
                                <span class="number">
                                  {{ renterTaxNumber }}
                                </span>
                              </div>
                            </li>
                            <!-- li 3 -->
                            <li>
                              <div class="phone-number mw-60 font-weight-bold">
                                <span>رقم الهاتف</span>
                                <span class="font-12 pr-1">Phone number</span>
                              </div>
                              <!-- id-number -->
                              <div class="text-left">
                                <span class="number">
                                  {{ renterPhoneNUmber }}
                                </span>
                              </div>
                            </li>
                            <!-- li 4 -->
                            <li class="bg-gray">
                              <div class="detail font-weight-bold">
                                <span>الفتره</span>
                              </div>
                              <!-- client-name -->
                              <div class="">
                                <span class="number">
                                  {{ taxDateFrom }} - {{ taxDateTo }}
                                </span>
                              </div>
                            </li>
                            <!-- li 5 -->
                            <li class="bg-gray">
                              <div class="phone-number mw-60 font-weight-bold">
                                <span>الفاتورة </span>
                              </div>
                              <!-- id-number -->
                              <div class="text-left">
                                <span class="number"> سنوي </span>
                              </div>
                            </li>
                            <!-- li 6 -->
                            <li class="last-li">
                              <div class="statement font-weight-bold">
                                <span class="text-truncate"
                                  >تحصيل إيجار سنوي</span
                                >
                              </div>
                              <!-- client-name -->
                              <div class="statement-detail">
                                <span class="detail">تفاصيل سنوي</span>
                              </div>
                              <!-- id-number -->
                            </li>
                            <!-- li 7 -->
                          </ul>
                        </div>
                        <!-- infoSection__client-detail__ul -->
                      </div>
                      <!-- infoSection__client-detail -->
                      <div class="amount-section">
                        <div class="tax-total font-14">
                          <div
                            class="total-amount d-flex align-items-center justify-between py-3 border-2px-bottom-gray"
                          >
                            <div class="total-text font-weight-bold">
                              <span> المبلغ قبل الضريبة </span>
                              <span class="text font-13"> Total </span>
                            </div>
                            <!-- total-text -->

                            <div class="total-number">
                              <span>
                                {{ taxPayment?.toLocaleString('en-US') }}
                              </span>
                              <span class="text font-13"> ريال </span>
                            </div>
                            <!-- total-number -->
                          </div>
                          <!-- total-amount -->

                          <div
                            class="total-discount d-flex align-items-center justify-between py-3 border-2px-bottom-gray"
                          >
                            <div class="discount-text font-weight-bold">
                              <span> الخصم </span>
                              <span class="text font-13"> Discount </span>
                            </div>
                            <!-- discount-text -->

                            <div class="discount-number">
                              <span> {{ taxDiscount }}</span>
                              <span class="text font-13"> ريال </span>
                            </div>
                            <!-- total-number -->
                          </div>
                          <!-- total-discount -->

                          <div
                            class="vat-amount d-flex align-items-center justify-between py-3"
                          >
                            <div class="vat-text font-weight-bold">
                              <span> ضريبة القيمة المضافة (VAT) </span>
                              <span class="text font-13">
                                %{{ taxInPercentage }}
                              </span>
                            </div>
                            <!-- vat-text -->

                            <div class="vat-number">
                              <span>
                                {{ taxInSar?.toLocaleString('en-US') }}
                              </span>
                              <span class="text font-13"> ريال </span>
                            </div>
                            <!-- vat-number -->
                          </div>
                          <!-- vat-discount -->

                          <div
                            class="total-vat-amount font-weight-bold bg-gray d-flex align-items-center justify-between py-3 px-3"
                          >
                            <div class="total-vat-text">
                              <span> الإجمالي مع ضريبة القيمة المضافة </span>
                              <span class="text font-13"> Total with VAT </span>
                            </div>
                            <!-- total-vat-text -->

                            <div class="total-vat-number text-left">
                              <span> {{ totalTaxValue }} </span>
                              <span class="text font-13"> ريال </span>
                            </div>
                            <!-- total-vat-number -->
                          </div>
                          <!-- total-vat-amount -->
                        </div>
                        <!-- tax-total -->
                      </div>
                    </div>
                    <!-- infoSection px-4 -->
                    <div
                      class="footer pdf-footer mt-4 d-flex px-5 align-center py-2"
                    >
                      <div class="d-flex">
                        <img
                          :src="hectarPdfText"
                          width="80"
                          class="mx-1"
                          loading="lazy"
                        />
                      </div>
                      <v-spacer></v-spacer>
                      <span class="copyRight font-14"
                        >فاتوره علي منصه هكتار للخدمات العقاريه</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-layout>
        </v-col>
        <v-col :cols="4" @click="$emit('closePdf')" class=""></v-col>
      </v-layout>
    </v-slide-x-transition>
  </div>
</template>
<script>
import dateFormat from '@/mixins/dateFormat.js'
import pdfHeader from '@/components/contracts/pdfHeader.vue'
import { Invoice } from '@axenda/zatca'
import { mapGetters } from 'vuex'
import config from '../../config'

export default {
  name: 'taxInvoicePdf',
  props: {
    tax: {
      default: {}
    },
    viewSharedPdf: {
      type: Boolean,
      default: false
    },
    downloadPdfMarkup: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  components: {
    pdfHeader
  },
  mixins: [dateFormat],
  data() {
    return {
      imageData: '',
      hectarPdfText: require('@/assets/img/svgs/hectarPdfText.svg')
    }
  },
  created() {
    this.getQrCode()
  },
  methods: {
    async getQrCode() {
      const invoice = new Invoice({
        sellerName: this.taxOwner,
        vatRegistrationNumber: this.tax?.owner?.taxNumber || 'لا يوجد',
        invoiceTimestamp: this.taxCreatedDate,
        invoiceTotal: this.taxPayment + this.taxInSar,
        invoiceVatTotal: this.taxInSar
      })

      this.imageData = await invoice.render()
    },
    downloadPdf() {
      const url = `${config.bonds}/taxBills/pdf/${this.tax?._id}?logo=${this.companyLogo}`
      window.open(url, '_blank', 'noreferrer')
    }
  },
  computed: {
    ...mapGetters('accountState', ['companyImages']),
    companyLogo() {
      return this.companyImages?.companyLogo?.path || ''
    },
    companyInfo() {
      return [
        {
          text: this.ownerIdNumberText,
          value: this.ownerCommercialRegister
        },
        {
          text: 'اسم المكلف Taxpayer name: ',
          value: this.taxOwner
        },
        {
          text: 'الرقم الضريبي TAX number: ',
          value: this.ownerTaxNumber
        },
        {
          text: 'التاريخ Date: ',
          value: this.formatDate(this.taxCreatedDate)
        },
        {
          text: 'رقم الهاتف Phone number: ',
          value: this.ownerPhoneNumber
        }
      ]
    },
    specifyNameColor() {
      // eslint-disable-next-line consistent-return
      return (app) => {
        switch (app) {
          case 'draft':
            return {
              nameAr: 'مسوده',
              color: 'orange--tag'
            }
          case 'approved':
            return {
              nameAr: 'ساريه',
              color: 'brub--tag'
            }
        }
      }
    },
    taxTitle() {
      return this.tax?.title ?? 'فاتوره'
    },
    taxOwner() {
      return this.tax?.owner?.name ?? 'المالك'
    },
    taxRenter() {
      return this.tax?.renter?.name ?? 'المستأجر'
    },
    taxCreatedDate() {
      return this.tax?.createdAt
    },
    taxPayment() {
      return this.tax?.payment
    },
    taxInPercentage() {
      return this.tax?.tax
    },
    taxInSar() {
      return this.tax?.total
    },
    taxDiscount() {
      return this.tax?.discount?.toLocaleString('en-US') ?? 0
    },
    taxDateFrom() {
      return this.tax?.hijri
        ? this.formatToHijriDate(this.tax?.dateFrom)
        : this.formatDate(this.tax?.dateFrom)
    },
    taxDateTo() {
      return this.tax?.hijri
        ? this.formatToHijriDate(this.tax?.dateTo)
        : this.formatDate(this.tax?.dateTo)
    },
    ownerPhoneNumber() {
      return this.tax?.owner?.phoneNumber ?? 'لا يوجد'
    },
    renterPhoneNUmber() {
      return this.tax?.renter?.phoneNumber ?? 'لا يوجد'
    },
    renterCommercialRegister() {
      return this.tax?.renter?.idNumber ?? 'لا يوجد'
    },
    renterTaxNumber() {
      return this.tax?.renter?.taxNumber ?? 'لا يوجد'
    },
    ownerCommercialRegister() {
      return this.tax?.owner?.idNumber ?? 'لا يوجد'
    },
    ownerTaxNumber() {
      return this.tax?.owner?.taxNumber ?? 'لا يوجد'
    },
    ownerIdNumberText() {
      return this.tax?.owner?.clientType === 'individual'
        ? 'رقم الهوية Id number: '
        : 'السجل التجاري Commercial register: '
    },
    renterIdNumberText() {
      const isIndividual = this.tax?.renter?.clientType === 'individual'
      return {
        nameAr: isIndividual ? 'رقم الهوية' : 'السجل التجاري',
        nameEn: isIndividual ? 'Id number' : 'Commercial register'
      }
    },
    totalTaxValue() {
      return (this.taxPayment + this.taxInSar)?.toLocaleString('en-US')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/pdf/_TaxPdf.scss';
@import '@/styles/pdf/_contractPDF.scss';
</style>
