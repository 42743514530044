<template>
  <div>
    <v-form v-model="formValidation">
      <div class="row mt-3">
        <v-col cols="12" md="12">
          <div class="gmap-search-bar">
            <h5
              class="mb-2 mt-3 font-12"
              :class="{ 'red--flag': nexTabClicked ? !address : false }"
            >
              موقع {{ mapTitle }}*
            </h5>
            <label class="d-flex autosuggest__input-wrap">
              <gmap-autocomplete
                :class="{ 'has-error': hasError }"
                :icon="{ url: require('../../assets/img/pin.svg') }"
                :options="autocompleteOptions"
                :value="address"
                @place_changed="setPlace"
                class="autosuggest__input form-control font-14 px-3"
                placeholder="إبحث بإسم المدينة او الحي .."
                dense
              ></gmap-autocomplete>
            </label>

            <p class="invalid-feedback d-block" v-if="hasError">
              من فضلك حدد المكان
            </p>
          </div>
        </v-col>

        <h5 class="mb-2 mt-3 font-12 px-3">
          حدد موقع {{ mapTitle }} علي الخريطة*
        </h5>
        <gmap-map
          :center="center"
          :options="options"
          :zoom="14"
          ref="mapRef"
          style="width: 100%; height: 400px"
          class="mx-2"
        >
          <gmap-marker
            data-test="marker"
            :clickable="true"
            :draggable="true"
            :icon="{ url: require('../../assets/img/pin.svg') }"
            :position="currentPlace"
            @click="center = m.position"
            @dragend="getFormattedAddress($event.latLng)"
          />
        </gmap-map>

        <v-col cols="12" md="6" v-if="addressInnerDetails">
          <h5
            class="mb-2 font-12 mobile__mt__0"
            :class="{ 'red--flag': nexTabClicked ? !formValidation : false }"
          >
            اسم {{ mapTitle }}*
          </h5>
          <v-text-field
            data-test="property-name"
            :rules="selectRules"
            class="mb-0 pa-0 font-14"
            dense
            filled
            hide-details
            placeholder="اسم العقار"
            required
            v-model="propertyName"
            @change="$emit('setPropertyName', propertyName)"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" v-if="addressInnerDetails">
          <h5 class="mb-2 font-12 mobile__mt__0">المدينه</h5>
          <v-text-field
            v-model="city"
            placeholder="المدينه"
            filled
            dense
            hide-details
            class="font-14"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" v-if="addressInnerDetails">
          <h5 class="mb-2 font-12 mobile__mt__0">الحي</h5>
          <v-text-field
            v-model="district"
            placeholder="الحي"
            filled
            dense
            hide-details
            class="font-14"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" v-if="addressInnerDetails">
          <h5 class="mb-2 font-12 mobile__mt__0">المنطقة</h5>
          <v-text-field
            v-model="province"
            placeholder="المنطقة"
            filled
            dense
            hide-details
            class="font-14"
          ></v-text-field>
        </v-col>
      </div>
    </v-form>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
import config from '@/config'

export default {
  name: 'GoogleMap',
  props: {
    nexTabClicked: {
      default: false
    },
    addressInnerDetails: {
      default: false
    },
    currentAddress: {},
    mapStatus: {},
    isValid: { default: true },
    mapTitle: { default: 'العقار' },
    realEstateName: {
      default: ''
    },
    propertyInfoValid: {}
  },
  data() {
    return {
      formValidation: false,
      propertyName: '',
      selectRules: [(v) => !!v || 'هذا الحقل مطلوب'],
      city: '',
      province: '',
      district: '',
      center: { lat: 24.448378, lng: 39.586848 },
      currentPlace: { lat: 24.448378, lng: 39.586848 }, // null
      hasError: false,
      address: this.currentAddress.address || '',
      autocompleteOptions: {
        componentRestrictions: {
          country: ['sa']
        }
      },
      options: {
        scrollwheel: false,
        fullscreenControl: false,
        streetViewControl: false,
        minZoom: 9,
        mapTypeControlOptions: {
          style: 1,
          position: 12
        },
        styles: config.mapStyles
      }
    }
  },

  watch: {
    currentAddress: {
      immediate: true,
      handler() {
        if (this.propertyInfoValid) {
          this.address = this.currentAddress?.address
          this.city = this.currentAddress?.city
          this.district = this.currentAddress?.district
          this.province = this.currentAddress?.province
          if (this.currentAddress.coordinates) {
            this.currentPlace = {
              lat: this.currentAddress.coordinates[1],
              lng: this.currentAddress.coordinates[0],
              address: this.currentAddress.address,
              city: this.currentAddress.city,
              district: this.currentAddress.district,
              province: this.currentAddress.province
            }
            this.center = {
              lat: this.currentAddress.coordinates[1],
              lng: this.currentAddress.coordinates[0]
            }
          }
        }
      }
    },
    realEstateName: {
      immediate: true,
      handler() {
        this.propertyName = this.realEstateName
      }
    },
    city() {
      this.changeLocation(this.city, 'city')
    },
    district() {
      this.changeLocation(this.district, 'district')
    },
    province() {
      this.changeLocation(this.province, 'province')
    }
  },

  computed: {
    google: gmapApi,
    id() {
      return this.$route.params.id
    },
    componentValidation() {
      return this.address
        ? this.formValidation && this.address?.length
        : this.formValidation && this.address
    }
  },
  mounted() {
    if (this.mapStatus === 'new') {
      this.geolocate()
    }
  },

  methods: {
    setPlace(location) {
      if (location) {
        this.address = location.formatted_address
        this.city = location.address_components[3]?.long_name
        this.province = location.address_components[2]?.long_name
        this.district = location.address_components[1]?.long_name
        this.currentPlace = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng(),
          address: this.address,
          city: this.city,
          province: this.province,
          district: this.district
        }
        this.center = {
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng()
        }
        this.hasError = false
        this.$emit('onCheckAddress', { place: this.currentPlace })
      }
    },
    async getFormattedAddress(location) {
      // Convert the latlang to formatted address
      const geocoder = new window.google.maps.Geocoder()
      await geocoder.geocode({ latLng: location }, (results) => {
        this.address = results[0].formatted_address
        this.city = results[0].address_components[3]?.long_name
        this.province = results[0].address_components[2]?.long_name
        this.district = results[0].address_components[1]?.long_name
        this.currentPlace = {
          lat: location.lat(),
          lng: location.lng(),
          address: this.address,
          city: this.city,
          province: this.province,
          district: this.district
        }
        this.center = this.currentPlace
      })
      this.$emit('onCheckAddress', { place: this.currentPlace })
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    },
    changeLocation(value, key) {
      if (this.currentPlace.address && value !== this.currentPlace[key]) {
        this.currentPlace[key] = value
        this.$emit('onCheckAddress', { place: this.currentPlace })
      }
    }
  }
}
</script>
