<template>
  <div class="mt-6">
    <CancelldTable
      v-if="approvel.status === 'cancelled'"
      :approvel="approvel"
    />

    <NonCancelldTable
      v-else
      :approvel="approvel"
      :currentApprovalOwner="currentApprovalOwner"
      @approved="handelApprove"
      @rejected="handelReject"
    />
    <v-row>
      <table>
        <tr
          v-for="{ user, status } in approvel?.approvals"
          :key="user.id"
          :class="{ cancelled: approvel.status === 'cancelled' }"
        >
          <td class="w-20 text-center b-b">
            {{ user.name }}
          </td>
          <td class="w-20 text-center b-r">
            <span v-if="status === 'approved'">
              <v-icon color="green">mdi-check</v-icon>
              تم الموافقه
            </span>
            <span v-else-if="status === 'rejected'">
              <v-icon color="danger">mdi-close</v-icon>
              تم الرفض
            </span>
            <span v-else>في إنتظار الموافقة</span>
          </td>
          <td
            :class="{
              'b-r text-center': approvel.reason && status === 'rejected'
            }"
          >
            <span v-if="status === 'rejected'">
              {{ approvel.reason }}
            </span>
          </td>
        </tr>
      </table>
    </v-row>
  </div>
</template>

<script>
import CancelldTable from './CancelldTable.vue'
import NonCancelldTable from './NonCancelldTable.vue'
import { localizeStatus, generateStyleClasses } from '@/constans/approval'

export default {
  components: {
    CancelldTable,
    NonCancelldTable
  },
  props: {
    approvel: {
      type: Object,
      default: () => {}
    },
    currentApprovalOwner: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isOnwerCanclled() {
      return this.approvel?.approvals?.find(
        ({ user: { _id } }) => _id === this.approvel?.createdBy?.id
      )?.status
    }
  },
  methods: {
    handelApprove() {
      this.$emit('approved', {
        item: { ...this.approvel, status: 'approved' }
      })
    },
    handelReject() {
      this.$emit('rejected', {
        item: { ...this.approvel, status: 'rejected' }
      })
    },
    localizeStatus(status) {
      return localizeStatus(status)
    },
    generateStyleClasses(status) {
      return generateStyleClasses(status)
    }
  }
}
</script>
<style lang="scss" scoped>
.theme--light.v-btn.v-btn--has-bg {
  padding: 0 2rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-left: 0.8rem;

  td {
    padding: 8px;
  }
  td:first-child {
    background-color: #efebf2;
  }
  .cancelled td {
    background-color: #efebf2;
  }
  tr {
    border: 2px solid #e0e0e0;
  }
  .b-r {
    border-right: 1px solid #e0e0e0;
  }
  .w-20 {
    width: 20%;
  }
}

.lable {
  background-color: #f44336;
  color: white;
  padding: 0.3rem 1.8rem;
  border-radius: 8rem;
  font-size: 0.8rem;
  pointer-events: none;
}

.b-b {
  border-bottom: 1px solid #e0e0e0;
}

.btn {
  padding: 1px 8px !important;
  margin: 0 8px;
  font-size: 8px;
  min-width: auto !important;
  box-shadow: 0 0 0 0 !important;
  background-color: transparent !important;
  i {
    font-size: 18px;
    margin: 0 !important;

    &.red {
      color: #f44336;
      background-color: transparent !important;
    }
    &.green {
      color: #4caf50;
      background-color: transparent !important;
    }
  }
  &.g-brder {
    border: 1px solid #4caf4f75;
  }
  &.r-brder {
    border: 1px solid #f4433670;
  }
}
</style>
