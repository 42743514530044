<template>
  <div class="quotation--paper1">
    <h1 class="ma-0">
      عرض تأجير {{ quotation.realEstate?.type?.nameAr }}
      {{ quotation.realEstate?.purpose?.nameAr }}
    </h1>
    <h2 class="ma-0">{{ quotation.realEstate?.propertyName }}</h2>
    <h3 class="ma-0">
      حي {{ quotation.realEstate?.propertyAddress.province }}
    </h3>
    <h4 class="ma-0 mt-3">
      {{ quotation?.createdAt | formatDate }}
    </h4>
    <div></div>
    <img
      v-if="companyImages?.companyLogo?.location"
      :src="companyImages?.companyLogo?.location"
      alt="logo"
      style="max-width: 200px"
      loading="lazy"
    />
  </div>
</template>

<script>
export default {
  name: 'Paper1',
  props: {
    quotation: {
      type: Object,
      default: () => {}
    },
    companyImages: {
      type: Object,
      required: true
    }
  },
  filters: {
    formatDate(value) {
      return new Date(value).toLocaleDateString('ar-AS', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }
  }
}
</script>
