<template>
  <v-row class="mx-1" v-if="!isTaxInvoice">
    <v-layout class="d-flex justify-space-between align-center">
      <v-icon
        v-if="!viewSharedPdf && downloadPdfMarkup"
        data-test="close-pdf"
        @click="$emit('closeDetails')"
        >mdi-arrow-right</v-icon
      >

      <v-img
        v-if="!showSigntures"
        :src="logo"
        class="site-logo"
        style="margin-left: 280px"
        height="40"
        contain
      />
      <v-spacer></v-spacer>
      <v-row class="d-flex justify-space-between" v-if="showSigntures">
        <div
          v-for="(sign, i) in signatures"
          :key="i"
          class="d-flex justify-space-between align-center"
          @click="isSignatureExisted(sign)"
        >
          <h6 class="font-12 ma-0 ml-1">{{ sign.name }}</h6>
          <v-switch
            dense
            inset
            v-model="sign.status"
            class="mt-5"
            @change="$emit('updateSignatures', signatures)"
            :disabled="!companyImages[sign.title]"
          />
        </div>
      </v-row>
      <v-spacer></v-spacer>
      <v-btn
        v-if="downloadPdfMarkup"
        class="d-flex align-center primary px-4"
        @click="$emit('downloadPdf')"
      >
        <v-icon class="ml-2" small>mdi-export-variant</v-icon>
        <span class="font-14 mb-1"> بي دي اف </span>
      </v-btn>
    </v-layout>
  </v-row>

  <div v-else class="tax-pdf__header mx-0">
    <div class="d-flex justify-space-between tax-pdf__header__left">
      <div class="d-flex justify-space-between align-center" v-if="showHeader">
        <v-icon
          class="mx-3"
          v-if="!viewSharedPdf"
          data-test="close-pdf"
          @click="$emit('closeDetails')"
          color="purple"
          large
        >
          mdi-arrow-right
        </v-icon>
        <v-btn
          class="d-flex align-center primary px-4"
          @click="$emit('downloadPdf')"
        >
          <v-icon class="ml-2" small>mdi-export-variant</v-icon>
          <span class="font-14 mb-1"> بي دي اف </span>
        </v-btn>
      </div>
      <div class="tax-pdf__header__company-logo text-left px-0 py-3 mr-auto">
        <v-img
          :src="companyLogo"
          alt="Company Logo"
          min-width="100"
          max-width="100"
        />
      </div>
      <!-- company-logo -->
    </div>
    <!-- tax-pdf__header__left -->
    <div class="tax-pdf__title">
      <div class="headTitle mb-2 d-flex align-start flex-column">
        <span class="bg-white">
          <h6 class="mb-2">{{ tax.title }}</h6>
          <h6 class="">{{ tax.number }} #</h6>
        </span>
      </div>
    </div>
    <!-- tax-pdf__header__title -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'pdfHeader',
  props: {
    tax: {
      default() {
        return {}
      }
    },
    showSigntures: {
      type: Boolean,
      default: false
    },
    viewSharedPdf: {
      type: Boolean,
      default: false
    },
    downloadPdfMarkup: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    signatures: {
      type: Array,
      default() {
        return [
          {
            title: 'financialSignature',
            name: 'توقيع المدير المالي',
            status: false
          },
          {
            title: 'directorSignature',
            name: 'توقيع مدير الشركه',
            status: false
          },
          {
            title: 'companySeal',
            name: 'ختم الشركه',
            status: false
          }
        ]
      }
    }
  },
  data() {
    return {
      exitArrow: require('@/assets/img/svgs/right-arrow.svg'),
      logo: require('@/assets/img/svgs/logo.svg')
    }
  },
  computed: {
    ...mapGetters('accountState', ['companyImages']),
    isTaxInvoice() {
      return this.tax ? Object.keys(this.tax).length : false
    },
    companyLogo() {
      return this.companyImages?.companyLogo
        ? this.companyImages?.companyLogo.location
        : require('@/assets/img/svgs/HectarPlusPlatform.svg')
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    isSignatureExisted(sign) {
      if (!this.companyImages[sign.title]) {
        this.addNotification({
          text: 'ليس هناك معلومات متاحة. الرجاء إضافة المعلومات المطلوبة من الإعدادات',
          color: 'error',
          duraation: 4000
        })
      }
    }
  }
}
</script>
<style lang="scss">
.tax-pdf {
  &__header {
    &.mx-0 {
      margin: 0 !important;
    }
    &__left {
      padding-left: 2rem;
    }
  }
  &__title {
    background: #662d91;
    .headTitle {
      padding-right: 4rem;
      span {
        background: #fff;
        padding: 0 1rem;
        margin-top: -8px;
        h6 {
          font-size: 1.2rem !important;
          font-weight: 700;
          line-height: 1 !important;
          margin: 0;
        }
      }
    }
  }
  // __title
}
// __header
</style>
