<template>
  <v-dialog persistent v-model="dialogState_" max-width="400px">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="pa-0 px-5 mb-0 d-flex justify-space-between custom__shadow"
      >
        <h4>تواصل معنا</h4>
        <v-btn
          fab
          text
          color="grey"
          class="ma-0 mt-2"
          @click.prevent="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- Details -->
      <v-container class="pb-2">
        <v-card-text class="pa-0">
          <v-form ref="form" v-model="isValid">
            <v-row no-gutters>
              <v-col class="pl-3 mt-1" cols="12" sm="12">
                <h5 class="mb-1 mt-0">نوع التواصل*</h5>
                <v-select
                  :items="communicationTypes"
                  item-text="nameAr"
                  item-value="nameEn"
                  :menu-props="{ bottom: true, offsetY: true }"
                  append-icon="mdi-chevron-down"
                  chips
                  :disabled="registration"
                  color="black"
                  dense
                  filled
                  hide-details
                  placeholder="نوع التواصل"
                  :rules="[(v) => !!v || 'من فضلك ادخل نوع التواصل']"
                  v-model="formData.communicationType"
                ></v-select>
              </v-col>
              <v-col class="pl-3 mt-2" cols="12" sm="12">
                <h5 class="mb-1 mt-0">رقم الهاتف*</h5>
                <v-text-field
                  filled
                  ref="phoneNumberInput"
                  v-model="formData.phoneNumber"
                  @input="changeArNumToEn($event, 'phoneNumber')"
                  @keydown="$formatNumberInputs"
                  :rules="phoneNumberRules"
                  placeholder="رقم الهاتف"
                  required
                  min
                  dense
                  hide-details
                  suffix="+966"
                ></v-text-field>
              </v-col>
              <v-col class="pl-3 mt-2" cols="12" sm="12">
                <h5 class="mb-1 mt-0">الإسم*</h5>
                <v-text-field
                  filled
                  v-model="formData.name"
                  :rules="[(v) => !!v || 'من فضلك ادخل الإسم']"
                  placeholder="الإسم"
                  required
                  min
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col class="pl-3 mt-2" cols="12" sm="12">
                <h5 class="mb-1 mt-0">
                  إسم الشركه <span v-if="!isOwner">*</span>
                </h5>
                <v-text-field
                  filled
                  v-model="formData.companyName"
                  :rules="companyRules"
                  placeholder="إسم الشركه"
                  required
                  min
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col class="pl-3 mt-2 mb-2" cols="12" sm="12">
                <h5 class="mb-1 mt-0">نص الرسالة*</h5>
                <v-textarea
                  id="contactTextArea"
                  dense
                  filled
                  hide-details="auto"
                  placeholder="ادخل محتوى الرسالة"
                  auto-grow
                  no-resize
                  rows="2"
                  v-model="formData.message"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- ReCAPTCHA widget -->
        <div
          ref="recaptcha"
          class="g-recaptcha"
          style="transform: scale(0.9); margin-top: 10px; transform-origin: 0 0"
        ></div>
        <p class="text-center" v-show="errorReCAPTCHA">
          يرجى إكمال اختبار reCAPTCHA
        </p>

        <v-card-actions class="pa-0 mt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="isLoading"
            class="w-100 ma-0 rounded-7 mt-2 mb-0 ml-3"
            :disabled="!isValid"
            @click="sendData"
          >
            <span class="my-1 ml-1 font-weight-bold"> إرسال </span>
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { userService } from '@/services'
import { mapMutations, mapState } from 'vuex'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    registration: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sitekey: '6Lf44hEqAAAAAGyWDyH_-lxJqggAeu45vej-M52V',
      recaptchaToken: null,
      formData: {},
      isValid: false,
      isLoading: false,
      phoneNumberRules: [
        (v) => !!v || 'من فضلك ادخل رقم الهاتف',
        (v) => /^[0-9]*$/.test(v) || 'صيغة رقم الهاتف غير صحيحة',
        (v) => (v && v.length === 10) || 'صيغة رقم الهاتف غير صحيحة'
      ],
      communicationTypes: [
        {
          nameEn: 'message',
          nameAr: 'رسالة'
        },
        {
          nameEn: 'complaint',
          nameAr: 'شكوى'
        },
        {
          nameEn: 'suggestion',
          nameAr: 'إقتراح'
        },
        {
          nameEn: 'registration',
          nameAr: 'طلب اشتراك'
        },
        {
          nameEn: 'contactSales',
          nameAr: 'تواصل مع المبيعات'
        }
      ],
      errorReCAPTCHA: false
    }
  },
  computed: {
    ...mapState('accountState', ['user']),
    dataToSend() {
      const data = {
        phoneNumber: this.formData.phoneNumber.replace('0', '966'),
        name: this.formData.name,
        companyName: this.formData.companyName,
        communicationType: this.formData.communicationType,
        message: this.formData.message
      }
      return data
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    },
    isOwner() {
      return this.user?.type === 'owner' ?? ''
    },
    companyRules() {
      return this.isOwner ? [] : [(v) => !!v || 'من فضلك ادخل إسم الشركه']
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('closed-add-dialog')
      this.$refs.form.reset()
    },
    changeArNumToEn(value, key) {
      this.formData[key] = convertNumbers2English(value)
    },
    onVerify(token) {
      this.recaptchaToken = token
      this.errorReCAPTCHA = false
    },
    renderRecaptcha() {
      if (window.grecaptcha) {
        grecaptcha.render(this.$refs.recaptcha, {
          sitekey: this.sitekey,
          callback: this.onVerify
        })
      }
    },
    async sendData() {
      if (!this.recaptchaToken || !this.recaptchaToken.length > 10) {
        this.errorReCAPTCHA = true
        return
      }

      try {
        this.isLoading = true
        const { data } = await userService.contactSales(this.dataToSend)
        this.addNotification({
          text: `اهلا ومرحبا بك ${data.lead.name} ،تم استلام طلبك. سوف نقوم بالتواصل معك قريبا جدا بهذا الخصوص`,
          color: 'success'
        })
        this.formData = {}
      } catch {
        this.addNotification({
          text: `لقد قمت بالتواصل معنا من قبل، يرجى الانتظار حتى يتم الرد عليك`,
          color: 'error'
        })
      } finally {
        this.isLoading = false
        this.closeDialog()
      }
    }
  },
  created() {
    if (this.user) {
      const { name, company, phoneNumber } = this.user
      this.formData = {
        name,
        phoneNumber: phoneNumber.replace(/966/, 0),
        companyName: company,
        communicationType: this.registration ? 'registration' : ''
      }
    }
    this.formData = {
      communicationType: this.registration ? 'registration' : ''
    }
  },
  mounted() {
    this.renderRecaptcha()
  }
}
</script>

<style lang="scss">
#contactTextArea {
  margin-top: 0px !important;
}
#contactTextArea::placeholder {
  font-size: 12px !important;
}
</style>

<style lang="scss" scoped>
h5 {
  color: #7a7a7a !important;
}
</style>
