export default {
  methods: {
    formatDate(date, format = 'rtl') {
      if (!date) {
        return ''
      }
      const [dateRtl] = new Date(date).toISOString().split('T')
      return format === 'rtl' ? dateRtl : dateRtl.split('-').reverse().join('-')
    },
    ToGregorianDateInArabic(date) {
      return date
        ? new Date(date).toLocaleString('ar-EG', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })
        : ''
    },
    formatToHijriDate(date) {
      return date
        ? Intl.DateTimeFormat('ar-SA-u-ca-islamic-umalqura', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          }).format(new Date(date))
        : ''
    }
  }
}
