<template>
  <v-col class="mw-180px">
    <v-card
      :class="{ 'ml-3': !final }"
      :elevation="hovering ? 20 : 0"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
      class="v-card--material-stats py-6 px-5 tile-card"
    >
      <v-card-title class="icon-container pa-0 d-flex justify-space-between">
        <img :alt="statIcon" :src="statIcon" loading="lazy" />
      </v-card-title>
      <br />
      <div class="text-container">
        <div class="title text-truncate" id="title">
          {{ getStateTitle }}
        </div>
        <div
          id="amount"
          class="font-weight-bold font-18 col-value d-flex no-wrap"
        >
          {{ valueFormatted(stat.value) }}
          <span class="grey--text font-16 mr-2">
            {{ stat.value?.currency || '' }}
          </span>
          <slot></slot>
        </div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import cardsIcon from '@/config/cardsIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'ClientDetailsTile',
  data: () => ({
    icons: cardsIcon.icons,
    hovering: false
  }),
  props: {
    classes: { default: '', type: String },
    icon: {},
    stat: {
      default() {
        return {}
      }
    },
    final: { default: false },
    clientId: {
      type: String
    },
    userType: {
      type: String
    }
  },
  methods: {
    getStatIcon: cardsIcon.getStatIcon,
    valueFormatted(value) {
      if (value) {
        if (!isNaN(value)) {
          return value
        } else if (typeof value === 'object' && value.total) {
          return value.total
        }
        let returnedValue = ''
        Object.values(value).forEach((val, index) => {
          returnedValue += `${index > 0 ? '|' : ''} ${val} `
        })
        return returnedValue
      }
      return 0
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    isOwner() {
      return !!(this.userType === 'owner')
    },
    getStateTitle() {
      return this.stat?.nameAr || ''
    },
    statIcon() {
      return this.getStatIcon(this.stat.nameEn)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/responsiveness/responsiveness.scss';
.container {
  min-width: 10rem;
}

img {
  height: 32px;
  width: 32px;
}

.hover {
  box-shadow: -16px 24px 80px rgba(0, 0, 0, 0.15);
}

.text-container {
  margin-top: auto;

  & .title {
    font-size: 14px !important;
    line-height: 21px;
    text-align: right;
    color: #737373;
    @include max-xl {
      font-size: 12px !important;
    }
  }

  & .col-value {
    font-size: 18px !important;
    line-height: 35px !important;
    text-align: right;
    letter-spacing: -0.09em;
    color: #101010;
    @include max-xl {
      font-size: 20px !important;
      @include max-lg {
        font-size: 15px !important;
      }
    }
  }
}
.mw-180px {
  min-width: 180;
}
</style>
