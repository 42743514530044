<template>
  <v-card class="pa-0">
    <v-card-title>
      <v-row class="py-2 font-16">
        {{ clientDetailsInfo?.title || realEstate?.title }}
        <v-spacer />
        <v-btn
          v-if="!isAUnit && !isCustomer() && !hasValidContract"
          data-test="openModalEdit"
          @click="edit"
          icon
        >
          <v-icon class="ml-4" color="grey">mdi-pencil-outline</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>

    <v-row class="pa-6 pt-0" v-if="realEstate">
      <v-col md="3" v-if="propCode">
        <div class="details-title">رقم العقار</div>
        <h5 class="ma-0 font-weight-medium text-truncate">{{ propCode }}</h5>
      </v-col>

      <v-col md="3" v-if="propType">
        <div class="details-title">نوع العقار</div>
        <h5 class="ma-0 font-weight-medium text-truncate">{{ propType }}</h5>
      </v-col>

      <v-col class="pr-0" md="3">
        <div class="details-title">عنوان العقار</div>
        <h5
          v-if="realEstate && realEstate?.propertyAddress"
          class="ma-0 font-weight-medium text-truncate"
        >
          {{ realEstate.propertyAddress.address }}
        </h5>
      </v-col>

      <v-col md="3" v-if="realEstate?.propertyName || realEstate?.name">
        <div class="details-title">اسم العقار</div>
        <h5 class="ma-0 font-weight-medium text-truncate">
          {{ realEstate?.propertyName ?? realEstate?.name }}
        </h5>
      </v-col>

      <v-col md="3">
        <div class="details-title">حالة العقار</div>
        <div>
          <h5
            v-if="realEstate && realEstate?.status"
            :class="setStatusLabelStyle(realEstate?.status?.nameAr)"
            class="ma-0 mw-80px font-weight-medium mt-1 w-35 text-truncate"
          >
            {{ realEstate?.status?.nameAr }}
          </h5>
        </div>
      </v-col>

      <v-col md="2" v-if="propSpace">
        <div class="details-title">المساحة</div>
        <h5 class="ma-0 font-weight-medium text-truncate">
          {{ propSpace }} متر
        </h5>
      </v-col>

      <v-col md="2" v-if="propPrice">
        <div class="details-title">
          {{ priceTitle }}
        </div>
        <h5 class="ma-0 font-weight-medium text-truncate">
          {{ propPrice }} ريال
        </h5>
      </v-col>
      <v-col md="2" v-if="propPricePerMeter">
        <div class="details-title">سعر المتر</div>
        <h5 class="ma-0 font-weight-medium text-truncate">
          {{ propPricePerMeter }} ريال
        </h5>
      </v-col>
      <v-col md="3" v-if="signingDate">
        <div class="details-title">تاريخ الإضافة</div>
        <h5 class="ma-0 font-weight-medium text-truncate">{{ signingDate }}</h5>
      </v-col>
    </v-row>
    <v-row class="pa-6 pt-0" v-if="clientDetailsInfo?.infoCard">
      <v-col class="text-truncate" v-if="clientCode">
        <div class="details-title">رقم العميل</div>
        <h5 class="ma-0 font-weight-medium text-truncate">{{ clientCode }}</h5>
      </v-col>

      <v-col class="text-truncate">
        <div class="details-title">اسم العميل</div>
        <h5 class="font-weight-medium ma-0">
          {{ clientDetailsInfo?.infoCard?.name }}
        </h5>
      </v-col>

      <v-col v-if="accountType == 'فرد'" class="text-truncate">
        <div class="details-title">رقم الهوية</div>
        <h5 class="font-weight-medium ma-0">
          {{ clientDetailsInfo?.infoCard?.idNumber || 'لا يوجد' }}
        </h5>
      </v-col>
      <v-col v-else class="text-truncate">
        <div class="details-title">رقم المنشأة / السجل التجاري</div>
        <h5 class="font-weight-medium ma-0">
          {{ registrationNumber }}
        </h5>
      </v-col>

      <v-col class="text-truncate">
        <div class="details-title">رقم الجوال</div>
        <h5 class="font-weight-medium ma-0">
          {{ clientDetailsInfo?.infoCard?.phoneNumber | filterPhoneNumber }}
        </h5>
      </v-col>

      <v-col class="text-truncate">
        <div class="details-title mb-1">نوع العميل</div>
        <div v-if="clientDetailsInfo?.infoCard?.environments.length">
          <h5
            :class="setRoleLabelStyle(clientDetailsInfo?.role)"
            class="font-weight-bold"
          >
            {{ formatUserRole(clientDetailsInfo?.role) }}
          </h5>
        </div>
      </v-col>

      <v-col class="text-truncate">
        <div class="details-title">نوع الحساب</div>
        <h5 class="font-weight-medium ma-0">{{ accountType }}</h5>
      </v-col>

      <v-col class="text-truncate">
        <div class="details-title">الرقم الضريبي</div>
        <h5 class="font-weight-medium ma-0">
          {{ clientDetailsInfo?.infoCard?.taxNumber || 'لا يوجد' }}
        </h5>
      </v-col>

      <v-col class="text-truncate">
        <div class="details-title">تاريخ التعاقد</div>
        <h5 class="font-weight-medium ma-0">
          {{
            clientDetailsInfo?.contractsLength ? infoCardDate : 'لا يوجد تعاقد'
          }}
        </h5>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import { mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name: 'ClientDetailsInfoCard',
  props: ['clientDetailsInfo', 'realEstate'],
  mixins: [dateFormat],
  data: () => ({
    showEditModal: false,
    val: 80
  }),
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    hasValidContract() {
      return this.realEstate?.rentContract
    },
    priceTitle() {
      return this.realEstate?.status?.nameEn === 'rented'
        ? 'قيمة الإيجار السنوي'
        : 'السعر الكلي'
    },
    registrationNumber() {
      return this.clientDetailsInfo?.infoCard?.registrationNumber || 'لا يوجد'
    },
    realestateCompletePercentage() {
      return this.realEstate?.completePercentage || 0
    },
    signingDate() {
      return this.formatDate(new Date(this.realEstate?.createdAt))
    },
    infoCardDate() {
      return this.formatDate(this.infoCard?.createdAt)
    },
    clientCode() {
      return this.clientDetailsInfo?.infoCard?.number ?? ''
    },
    propCode() {
      return this.realEstate?.number ?? ''
    },
    propType() {
      return this.realEstate?.type?.nameAr
    },
    propSpace() {
      return this.realEstate?.space
    },
    propPrice() {
      return this.realEstate?.price?.toLocaleString('en-US') ?? ''
    },
    propPricePerMeter() {
      return this.realEstate?.pricePerMeter?.toLocaleString('en-US') ?? ''
    },
    isAUnit() {
      return !!this.realEstate?.parentId
    },
    accountType() {
      switch (
        this.clientDetailsInfo?.infoCard?.clientType ??
        this.clientDetailsInfo?.infoCard?.type
      ) {
        case 'individual':
          return 'فرد'
        case 'office':
          return 'مكتب'
        case 'company':
          return 'شركه'
        default:
          return 'لم يتم تحديد نوع الحساب'
      }
    }
  },
  methods: {
    ...mapGetters('accountState', ['isCustomer']),
    ...mapMutations('appState', ['addNotification']),
    setRoleLabelStyle(role) {
      let style = 'ma-0 tag text-center pb-1 '
      switch (role) {
        case 'renter':
          style += 'brub--tag'
          break
        case 'owner':
          style += 'orange--tag'
          break
        case 'buyer':
          style += 'green--tag'
          break

        case 'lead-buyer':
          style += 'blue--tag'
          break
        case 'lead-renter':
          style += 'red--tag'
          break

        default:
          style += 'gery--tag'
          break
      }
      return style
    },
    setStatusLabelStyle(status) {
      let style = 'ma-0 tag text-center pb-1 '
      switch (status) {
        case 'للبيع':
          style += 'brub--tag'
          break
        case 'مُباع':
          style += 'orange--tag'
          break
        case 'مؤجر':
          style += 'green--tag'
          break

        default:
          style += 'blue--tag'
          break
      }
      return style
    },
    formatUserRole(role) {
      // get the config options
      const clientTypes = JSON.parse(
        localStorage.getItem('configrations')
      ).clientTypes
      const clientType = clientTypes.find((c) => {
        return c.code === role
      })

      return clientType?.nameAr
    },
    completeUnitData() {
      this.$emit('completeUnitData')
    },
    edit() {
      if (this.isAUnit) {
        this.completeUnitData()
      } else if (this.infoCard?._id === this.user._id) {
        this.$router.push('/dashboard/edit-profile/0')
      } else if (
        (!this.infoCard?._id &&
          !this.$hasPermission('realestates', 'realestates', 'update')) ||
        (this.infoCard?._id &&
          !this.$hasPermission('users', 'clients', 'update'))
      ) {
        this.addNotification({
          text: `ليس لديك صلاحيه للتعديل`,
          color: 'error'
        })
      } else {
        this.$emit('dialogState', true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.details-title {
  font-size: 12px;
  line-height: 15px;
  color: #737373;
}

.borderBtn {
  border: 1px #e8e5e5 solid;
  border-right: 0px;
  border-radius: 10px 0px 0px 10px !important;
}
</style>