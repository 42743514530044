var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isCustomer && _vm.fastActionsList.length)?_c('div',[_c('v-menu',{ref:"fastActionsMenu",attrs:{"top":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"mr-3"},[_c('div',_vm._g(_vm._b({staticClass:"cursor fast-actions-m-btn px-2 py-2 add__report__btn",attrs:{"icon":""},on:{"click":function($event){_vm.FastActions = !_vm.FastActions}}},'div',attrs,false),on),[_c('img',{attrs:{"width":18,"alt":"FastActions","src":!_vm.fastActionsMenuStateSwitcher(_vm.$refs.fastActionsMenu)
                ? require('@/assets/img/svgs/plus.svg')
                : require('@/assets/img/svgs/x.svg'),"loading":"lazy"}})])])]}}],null,false,3351520516)},[_c('v-list',{staticClass:"fast-actions-list"},_vm._l((_vm.fastActionsList),function(btn,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.handleFastActionBtns(btn.action)}}},[_c('v-list-item-icon',{staticClass:"ml-1"},[_c('img',{attrs:{"width":20,"alt":"FastActions","src":btn.icon,"loading":"lazy"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n            "+_vm._s(btn.text)+"\n          ")])],1)],1)}),1)],1),(
      _vm.dialogStateForUnitModal &&
      _vm.$hasPermission('realestates', 'realestates', 'add')
    )?_c('unit-modal',{attrs:{"dialogState":_vm.dialogStateForUnitModal},on:{"closed-property-dialog":function($event){return _vm.closeDialog('properties', $event)}}}):_vm._e(),(_vm.dialogStateForClient && _vm.$hasPermission('users', 'clients', 'add'))?_c('add-client-modal',{attrs:{"clientDetaile":false,"dialogState":_vm.dialogStateForClient},on:{"close-client-dialog":function($event){return _vm.closeDialog('client', $event)}}}):_vm._e(),(
      _vm.dialogStateForContract &&
      _vm.$hasPermission('properties', 'contracts', 'add')
    )?_c('AddContractModal',{attrs:{"contractIntervals":_vm.contractIntervals,"contractModalState":_vm.dialogStateForContract},on:{"closeContractModal":function($event){_vm.dialogStateForContract = $event}}}):_vm._e(),(_vm.dialogStateForBond && _vm.$hasPermission('properties', 'bonds', 'add'))?_c('add-bond-modal',{attrs:{"dialogState":_vm.dialogStateForBond},on:{"closed-bond-dialog":function($event){return _vm.closeDialog('bond', $event)}}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }