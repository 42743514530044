<template>
  <div class="d-flex flex-column justify-space-between conversation-container">
    <div>
      <v-container fluid class="user-container">
        <div v-if="isCustomer" class="d-flex align-center">
          <span class="customer-substring">{{
            $subString(receiver.name)
          }}</span>
          <span class="font-weight-bold mr-3 user-name">{{
            receiver.name
          }}</span>
        </div>
        <span v-else class="font-weight-bold mr-3 user-name">{{
          receiver.name
        }}</span>

        <v-btn
          @click="showDeleteConversation"
          text
          plain
          icon
          small
          color="transparent"
          class="mt-5"
        >
          <v-icon color="#737373"> mdi-dots-horizontal </v-icon>
        </v-btn>

        <button
          v-if="isDeleteConversationShown"
          class="delete-conversation pa-1"
          @click="deleteMessage"
        >
          <div class="delete-conversation-container py-1 pr-1 pl-4">
            <img :src="deleteConversationIcon" loading="lazy" />
            <span class="mt-n1 mr-2 delete-conversation-text"
              >حذف المحادثة</span
            >
          </div>
        </button>
      </v-container>

      <div id="chatScrolling" class="mt-5 chat-container">
        <div
          v-for="(message, i) in messages"
          :key="message._id"
          class="messages-container mt-1"
        >
          <p v-if="!isCustomer && i === 0" class="default-message">
            {{ getNameOfSender(message) }}
          </p>
          <p
            v-else-if="!isCustomer"
            :class="['message', setMessageStyle(message.sender)]"
          >
            {{ message.content }}
          </p>
          <template v-else>
            <p
              v-if="user._id === message.sender._id && i === 0"
              class="default-message"
            >
              {{ getNameOfSender(message) }}
            </p>
            <p
              v-else-if="i !== 0"
              :class="['message', setMessageStyle(message.sender)]"
            >
              {{ message.content }}
            </p>
            <div v-else class="d-flex ml-5 float-left">
              <p :class="['message', setMessageStyle(message.sender)]">
                {{ message.content }}
              </p>
              <span class="customer-substring mr-3">{{
                $subString(receiver.name)
              }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="mt-2 mb-5 mx-5">
      <v-text-field
        placeholder="أكت رسالتك هنا ثم اضغط على زر الإرسال"
        rounded
        dense
        class="new-message-input"
        :disabled="isSendingMessage"
        v-model="newMessage"
        @keyup.enter="addMessage"
      >
        <template v-slot:append>
          <button
            :class="[
              'mt-2',
              'add-message-btn',
              { 'disable-add-message': isSendingMessage }
            ]"
            @click="addMessage"
          >
            <!-- <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.33143 8.51924L13.1882 15.3762C13.4248 15.4836 13.7071 15.4196 13.8716 15.2162C14.0373 15.0128 14.043 14.7237 13.8853 14.5145L9.89974 9.20039C9.36641 8.48928 9.36641 7.51151 9.89974 6.80039L13.8853 1.48627C14.043 1.27713 14.0373 0.986852 13.8728 0.784571C13.7619 0.646288 13.5962 0.572004 13.4282 0.572004C13.3471 0.572004 13.2659 0.589147 13.1894 0.624575L0.332573 7.48155C0.129149 7.57526 9.29723e-06 7.77754 9.27643e-06 8.00039C9.25562e-06 8.22324 0.129149 8.42553 0.33143 8.51924Z"
                :fill="iconColor"
              />
            </svg> -->

            <figure>
              <img
                src="../../assets/img/svgs/send.svg"
                alt="Send Icon"
                loading="lazy"
              />
            </figure>
            <!-- Send Icon  -->
          </button>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { messageService } from '@/services/messagae.service'
export default {
  name: 'ConversationDetails',
  props: {
    messages: {
      type: Array,
      required: true
    },
    hectarTabActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newMessage: '',
      isSendingMessage: false,
      isDeleteConversationShown: false,
      deleteConversationIcon: require('@/assets/img/svgs/messages/delete-conversation.svg')
    }
  },
  computed: {
    ...mapState('accountState', ['user']),
    ...mapGetters('accountState', ['isCustomer']),
    iconColor() {
      if (this.hectarTabActive) {
        return '#3dba7e'
      }
      return '#662d91'
    },
    receiver() {
      if (this.messages[0].sender._id !== this.user._id) {
        return this.messages[0].sender
      }
      return this.messages[0].receiver
    }
  },
  methods: {
    showDeleteConversation() {
      this.isDeleteConversationShown = !this.isDeleteConversationShown
    },
    getNameOfSender(message) {
      if (message.content.includes(this.user?.name)) {
        return message.content.replace(this.user?.name, message.sender?.name)
      }
      return message.content.replace(this.sender?.name, message.receiver?.name)
    },
    setMessageStyle(messageSender) {
      if (this.hectarTabActive && this.user._id === messageSender._id) {
        return 'outgoing-message hectar-messages-background'
      } else if (!this.hectarTabActive && this.user._id === messageSender._id) {
        return 'outgoing-message team-messages-background'
      }
      return this.isCustomer
        ? 'incoming-message'
        : 'incoming-message float-left ml-5'
    },
    addMessage() {
      this.isSendingMessage = true
      const newMessage = this.newMessage.trim()
      if (newMessage.length === 0) {
        this.isSendingMessage = false
        return
      }
      const messageBody = {
        content: newMessage,
        sender: {
          _id: this.user._id,
          name: this.user.name,
          phoneNumber: this.user.phoneNumber,
          email: this.user.email
        },
        receiver: this.receiver,
        context: {
          platform: this.hectarTabActive ? 'hectar' : 'hectarPlusTeam'
        },
        thread: this.messages[0].thread
      }
      if (this.receiver.pushToken) {
        messageBody.pushToken = this.receiver.pushToken
      }
      messageService
        .addNewMessage(messageBody)
        .then(async (res) => {
          if (res.data.added) {
            await this.$emit('message-added', messageBody)
            this.newMessage = ''
            const chatContainer = this.$el.querySelector('#chatScrolling')
            chatContainer.scrollTop = chatContainer.scrollHeight
          }
        })
        .catch(() => {
          this.addNotification({
            text: 'خطأ في ارسال الرسالة',
            color: 'error'
          })
        })
        .finally(() => {
          this.isSendingMessage = false
        })
    },
    deleteMessage() {
      this.isDeleteConversationShown = false
      this.$emit('delete-message', this.messages[0].thread)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/material-dashboard/conversation-details.scss';
.default-message {
  text-align: center;
  font-weight: bold;
  background-color: #66328e1a;
  width: fit-content;
  padding: 8px;
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  color: #66328e;
}
</style>
