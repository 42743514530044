<template>
  <v-layout
    justify-space-between
    align-center
    class="flex-wrap sidedetail__header"
  >
    <v-layout align-center flex-wrap class="py-2">
      <v-img
        :lazy-src="exitArrow"
        max-height="15"
        max-width="15"
        :src="exitArrow"
        @click="closeSideDetails"
      />
      <v-layout align-center flex-wrap class="mr-3">
        <div v-if="item?.previousContracts?.length">
          <v-menu
            v-model="versionsContractMenu"
            bottom
            content-class="elevation-2"
            offset-y
            transition="slide-y-transition"
            rounded="lg"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="grey--text text--lighten-1 py-5 px-2 flex justify-center justify-space-between rounded-lg"
                outlined
                v-bind="attrs"
                v-on="on"
              >
                <p class="ma-0 font-16 black--text">
                  <span class="font-weight-bold"> {{ item.title }} </span>
                </p>
                <!-- Tags -->
                <div class="d-flex items-center">
                  <div
                    :class="[
                      'mx-5 tabel-tag text-center d-flex align-center justify-center',
                      tagColor(item.typeName)
                    ]"
                  >
                    <h6 class="font-weight-bold ma-0 font-16">
                      {{ item.typeAr }}
                    </h6>
                  </div>
                  <div
                    :class="[
                      'tabel-tag text-center d-flex align-center justify-center',
                      statusTagColor
                    ]"
                  >
                    <h6
                      class="d-flex align-center font-weight-bold ma-0 font-16"
                    >
                      <v-sheet
                        class="dotted ma-0 pa-0 ml-2"
                        :color="statusColor"
                      ></v-sheet>
                      <span> {{ statusNameAr }}</span>
                    </h6>
                  </div>
                </div>
                <v-icon class="ms-2" color="gray">
                  {{ `mdi-chevron-${!versionsContractMenu ? 'down' : 'up'}` }}
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-radio-group v-model="getSelectedContract" class="px-1 my-1">
                <v-radio
                  :key="contractVersion._id"
                  v-for="contractVersion in [...item.previousContracts, item]"
                  class="pointerCursor mt-1"
                  :value="contractVersion._id"
                >
                  <template slot="label">
                    <div
                      class="font-14 mb-1 font-weight-medium d-flex justify-space-between"
                    >
                      <div class="ma-0 font-16 black--text my-auto">
                        <span class="font-weight-bold">
                          {{ contractVersion.title }}
                        </span>
                        - (
                        {{ ToGregorianDateInArabic(contractVersion.start) }} -
                        {{ ToGregorianDateInArabic(contractVersion.end) }}
                        )
                      </div>
                      <div
                        class="d-flex align-center mx-3"
                        :style="{ 'min-width': '120px' }"
                      >
                        <div
                          :class="
                            contractsStatuses[contractVersion.status].tagColor
                          "
                          class="px-5 mb-1 tabel-tag text-center font-12 font-weight-bold d-flex align-center mx-auto"
                        >
                          <v-sheet
                            class="dotted ma-0 pa-0 ml-2"
                            :color="
                              contractsStatuses[contractVersion.status].color
                            "
                          />
                          <span class="py-2">{{
                            contractsStatuses[contractVersion.status].nameAr
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-card>
          </v-menu>
        </div>
        <div class="ma-0" v-else>
          <h5
            class="font-weight-medium text-truncate max-width-250 mobile__m__0 white__space__normal"
          >
            <span class="font-16 font-weight-bold"
              >{{ item.title }}
              <span v-if="showUnitsNames">
                {{ unitName }}
              </span>
            </span>
          </h5>
        </div>

        <!-- Tags -->
        <div
          v-if="!item?.previousContracts?.length"
          class="d-flex items-center"
        >
          <div
            :class="[
              'mx-5 tabel-tag text-center d-flex align-center justify-center',
              tagColor(item.typeName)
            ]"
          >
            <h6 class="font-weight-bold ma-0 font-16">
              {{ item.typeAr }}
            </h6>
          </div>
          <div
            :class="[
              'tabel-tag text-center d-flex align-center justify-center',
              statusTagColor
            ]"
          >
            <h6 class="d-flex align-center font-weight-bold ma-0 font-16">
              <v-sheet
                class="dotted ma-0 pa-0 ml-2"
                :color="statusColor"
              ></v-sheet>
              <span> {{ statusNameAr }}</span>
            </h6>
          </div>
        </div>
      </v-layout>
    </v-layout>
    <v-spacer />
    <v-layout
      v-if="needAnApprovalAndNotApproved"
      justify-end
      flex-wrap
      class="mobile__justify__start dots__btn"
    >
      <v-btn
        v-for="(action, i) in validActions"
        @click="callActionFun({ action, item })"
        max-width="145"
        class="actions px-8 mx-2"
        :class="[
          action.class,
          { 'white--text': action.title === 'إلغاء العقد' }
        ]"
        :color="action.color"
        :key="i"
      >
        <v-icon
          size="20"
          v-if="action.sideHeaderIcon"
          :color="action?.sideHeaderColor"
          >{{ action.sideHeaderIcon }}</v-icon
        >
        <v-icon
          size="20"
          v-else-if="action.icon"
          :color="action?.sideHeaderColor"
          >{{ action.icon }}</v-icon
        >
        <v-img
          v-else-if="action.img"
          max-height="16"
          max-width="16"
          :src="cancelIcon"
        ></v-img>
        <span class="font-14 font-weight-bold mx-2"> {{ action.title }}</span>
      </v-btn>
    </v-layout>
  </v-layout>
</template>

<script>
import monthsInArabic from '@/constans/monthsInArabic.js'
import contractsSharedData from '@/mixins/contractsSharedData'
import dateFormat from '@/mixins/dateFormat.js'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'side-contracts-header-detailes',
  props: {
    contractTitles: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  mixins: [dateFormat, contractsSharedData],
  data() {
    return {
      selectedContract: null,
      versionsContractMenu: false,
      months: monthsInArabic.months,
      exitArrow: require('@/assets/img/svgs/right-arrow.svg'),
      backToPreviousPath: false
    }
  },
  watch: {
    selectedContract(contract) {
      const selectedContract = this.item.previousContracts?.find(
        (item) => item._id === contract
      )
      this.$emit('setDetailsItem', {
        visible: true,
        contract: selectedContract
      })
    }
  },
  created() {
    this.backToPreviousPath = !!this.$route.query?.backToCurrentPath
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeSideDetails() {
      if (this.backToPreviousPath) {
        this.$router.back()
      }
      this.$emit('closeDetails', { visible: false, contract: null })
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    needAnApprovalAndNotApproved() {
      return !this.item?.needsApproval
    },
    getSelectedContract: {
      get() {
        return this.selectedContract?.length
          ? this.selectedContract
          : this.item?._id
      },
      set(val) {
        this.selectedContract = val
      }
    },
    validActions() {
      return this.actions.filter((action) => {
        return (
          action.sideDetailsAction &&
          this.isAvalidAction({ item: this.item, action })
          //  &&
          // (action.title === 'تجديد العقد' ? !this.isExpiredAndRenewed : true)
        )
      })
    },
    isExpiredAndRenewed() {
      return !!(
        this.item?.status === 'expired' && this.item?.previousContracts?.length
      )
    },
    showUnitsNames() {
      return !this.item?.title?.includes(this.unitName.trim())
    },
    contractCode() {
      return this.item?.number ?? ''
    },
    unitName() {
      return `- ${this.item?.realEstate?.propertyName?.split(' - ')[1] || ''}`
    },
    contractTypeName() {
      switch (this.item.typeName) {
        case 'rent':
          return 'ايجار'
        case 'propertyManagement':
          return 'ادارة املاك'
        case 'maintenance':
          return 'صيانة'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.max-width-250 {
  max-width: 250px;
}
</style>
