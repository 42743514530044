<template>
  <div>
    <v-tabs
      align-with-title
      color="primary"
      right
      show-arrows
      v-model="tab"
      class="setting__page__tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab
        :ripple="false"
        class="mr-6 rounded-15"
        v-for="(tab, i) in availableComponents"
        :key="i"
      >
        {{ tab.componentTitle }}
      </v-tab>

      <v-tabs-items class="mt-5 profile-tabs" v-model="tab">
        <v-tab-item
          v-for="(tab, i) in availableComponents"
          :key="i"
          :eager="true"
        >
          <component
            :is="tab.componentNameEn"
            :envSetting="true"
            :bundleDuration="subscription.period"
            :bills="bills"
            :pagination="billsPagination"
            :bundles="bundles"
            ignoreBundle="الباقة المؤسسية"
            v-on="$listeners"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { subscriptionService, userService } from '@/services'
import { mapState } from 'vuex'

import Bundles from '@/components/env/bundles'
import HomeCards from '@/components/home/HomeCards.vue'
import UsageAgreement from '@/components/UsageAgreement/UsageAgreement.vue'
import Bills from '@/views/Bills.vue'

export default {
  name: 'SubscriptionSettings',
  components: { Bundles, HomeCards, UsageAgreement, Bills },
  data() {
    return {
      bills: [],
      bundles: [],

      billsPagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },

      allTabs: [
        {
          componentTitle: 'فواتير الإشتراك والدفع',
          componentNameEn: 'Bills',
          avaliablefor: ['forOwners']
        },
        {
          componentTitle: 'معلومات الإشتراك',
          componentNameEn: 'HomeCards',
          avaliablefor: ['forAll']
        },
        {
          componentTitle: 'الباقات',
          componentNameEn: 'Bundles',
          avaliablefor: ['forOwners']
        },
        {
          componentTitle: 'إتفاقية الإستخدامات والإشتراكات',
          componentNameEn: 'UsageAgreement',
          avaliablefor: ['forAll']
        }
      ],
      tab: 0,
      filters: {}
    }
  },
  computed: {
    ...mapState('accountState', ['subscription', 'currentEnv']),
    isOwner() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    },
    availableComponents() {
      const filteredComponents = this.allTabs.filter((tab) => {
        return this.isOwner ? true : tab.avaliablefor.includes('forAll')
      })
      return filteredComponents
    }
  },
  methods: {
    async handleBillsFilters(filters = '') {
      this.billsPagination.pageNumber = 1
      let payLoad = `environment=${this.currentEnv._id}&pageSize=${this.billsPagination.pageSize}&pageNumber=${this.billsPagination.pageNumber}`
      if (filters.length) {
        this.filters = filters
        payLoad += `&status=${this.filters}`
      } else {
        this.filters = ''
      }
      const { data } = await subscriptionService.getPayments(payLoad)
      this.bills = data.payments
      this.billsPagination.pagesCount = Math.ceil(
        data.count / this.billsPagination.pageSize
      )
      this.billsPagination.count = data.count
    },
    async getBundles() {
      try {
        const { data } = await userService.getBundles()
        this.bundles = data
      } catch {}
    }
  },
  async created() {
    await this.getBundles()
    await this.handleBillsFilters()
  }
}
</script>
