<template>
  <div class="d-flex flex-column justify-space-between mb-2 mt-6">
    <v-row no-gutters>
      <!-- Filter by price Offer type -->
      <div class="px-0">
        <v-menu
          content-class="vMenuFilter elevation-0 w-fit"
          offset-y
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-flex align-center mx-1"
              :class="{ 'mt-1': smAndDown }"
            >
              <v-slide-x-transition>
                <v-card
                  ref="sortListActivator"
                  v-on="on"
                  v-bind="attrs"
                  class="d-flex justify-space-between px-2 pt-1"
                  width="130"
                >
                  <h6 class="mx-2 w-fit text-no-wrap text-truncate">
                    {{ priceOfferTypePlaceHolder }}
                  </h6>
                  <v-icon
                    >{{
                      priceOffersTypeStatus
                        ? 'mdi-chevron-up'
                        : 'mdi-chevron-down'
                    }}
                  </v-icon>
                </v-card>
              </v-slide-x-transition>
            </div>
          </template>
          <v-list
            class="contractVmenuList pa-0"
            v-for="(type, i) in priceOffersTypes"
            :key="i"
          >
            <v-list-item-group>
              <v-list-item class="px-1">
                <v-list-item-title
                  ref="sortOption"
                  @click="setFilterPriceOffersType(type)"
                  class="font-14 mr-1 mb-2 font-weight-medium"
                >
                  {{ type.nameAr }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <!-- Search by name -->
      <v-col lg="3">
        <v-text-field
          class="search__by__customer__name mx-1 font-14"
          :class="{ 'mt-1': smAndDown }"
          height="45"
          filled
          dense
          hide-details="auto"
          placeholder="ابحث بإسم العميل"
          @input="searchDebounce($event)"
          :value="filtersQuery.user"
        />
      </v-col>

      <!-- Search by date -->
      <v-col lg="2">
        <div class="realestates-date-filter">
          <v-menu
            v-model="datePickerModal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                append-icon="mdi-calendar-month-outline"
                dense
                filled
                hide-details
                placeholder="تاريخ الإنشاء"
                readonly
                height="45"
                required
                v-bind="attrs"
                v-on="on"
                :value="date"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="date"
              @input="setFilterData($event)"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-col>

      <!-- Sort -->
      <v-col class="pa-0 d-flex justify-end">
        <h6 class="font-weight-light ml-2">ترتيب بواسطة</h6>
        <v-menu
          content-class="vMenuFilter elevation-0"
          offset-y
          ref="dateMenu"
          transition="slide-y-transition"
          width="150"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center">
              <v-slide-x-transition>
                <v-card
                  class="d-flex justify-space-between px-2"
                  v-bind="attrs"
                  v-on="on"
                  width="110"
                >
                  <h6 class="mr-2 font-14">
                    {{ filterQuery.sort.nameAr }}
                  </h6>
                  <v-icon size="20"
                    >{{
                      menuStateSwitcher($refs.dateMenu)
                        ? 'mdi-chevron-up'
                        : 'mdi-chevron-down'
                    }}
                  </v-icon>
                </v-card>
              </v-slide-x-transition>
            </div>
          </template>
          <v-list
            class="pa-0"
            v-for="(option, i) in sortOptions"
            :key="i"
            :width="110"
          >
            <v-list-item-group>
              <v-list-item class="px-1">
                <v-list-item-title
                  @click="handleSortChange(option)"
                  class="font-14 mr-1 mb-2 font-weight-medium"
                >
                  {{ option.nameAr }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <div class="d-flex ms-3">
        <template v-for="(filter, idx) in filtersQuery">
          <v-card
            class="d-flex pa-1 flex-grow-0 justify-between align-center mx-1"
            v-if="filter"
            :key="idx"
          >
            <h5 class="ma-0 mx-2 text--purple font-12 text-no-wrap py-2">
              {{ filter }}
            </h5>
            <v-icon
              class="font-weight-medium font-14 pl-1"
              @click="restFilters(idx)"
            >
              mdi-close
            </v-icon>
          </v-card>
        </template>
      </div>
    </v-row>
  </div>
</template>

<script>
import { debounce } from '@/helpers/debounce'

export default {
  name: 'Filters',
  data() {
    return {
      priceOffersTypes: [
        { nameAr: 'الكل', code: '', active: true },
        { nameAr: 'جديد', code: 'new', active: false },
        { nameAr: 'منتهي', code: 'expired', active: false },
        { nameAr: 'مسودة', code: 'draft', active: false },
        { nameAr: 'مقبول', code: 'accepted', active: false },
        { nameAr: 'مرفوض', code: 'rejected', active: false },
        { nameAr: 'ملغي', code: 'cancelled', active: false }
      ],
      priceOffersTypeStatus: false,
      priceOfferTypePlaceHolder: 'حالة العرض',
      clientName: '',
      date: '',
      datePickerModal: false,
      // Sort
      filterQuery: {
        types: undefined,
        sort: {
          nameAr: 'الأحدث',
          nameEn: 'desc',
          value: 'Newest'
        }
      },
      sortOptions: [
        { nameAr: 'الأحدث', nameEn: 'desc', value: 'Newest' },
        { nameAr: 'الأقدم', nameEn: 'asc', value: 'Oldest' }
      ],
      filtersQuery: {
        user: ''
      }
    }
  },
  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  watch: {
    'filtersQuery.user'() {
      this.$emit('filter', {
        type: 'user',
        payload: this.filtersQuery.user
      })
    }
  },
  methods: {
    setFilterPriceOffersType(filter) {
      this.priceOfferTypePlaceHolder = filter.nameAr
      this.$emit('filter', {
        type: 'type',
        payload: filter.code
      })
    },
    searchDebounce: debounce(function (name) {
      this.filtersQuery.user = name
    }, 900),
    setFilterData(date) {
      this.datePickerModal = false
      this.date = date
      this.filtersQuery.date = date
      this.$emit('filter', {
        type: 'date',
        payload: date
      })
    },
    restFilters(type) {
      this.$emit('filter', { type, payload: '' })
      this.filtersQuery[type] = ''
      this[type] = ''
    },
    menuStateSwitcher(menu) {
      return menu?.isActive
    },
    handleSortChange(sort) {
      this.filterQuery = {
        ...this.filterQuery,
        sort
      }
      this.sortType = this.filterQuery.sort.nameEn
      this.$emit('sortChanged', {
        type: 'sort',
        payload: this.filterQuery.sort.value
      })
    },
    handlePriceOffersType(code) {
      this.priceOffersTypes.map((T) => {
        if (T.code === code) {
          T.active = true
        } else {
          T.active = false
        }
      })
      this.priceOffersType = code
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_properties.scss';
.switcher {
  background: #fff;
  border-radius: 10px;

  .active {
    border-radius: 10px;
    background: rgba($primary-color, 0.1);
    color: $primary-color !important;
    font-weight: 500;
  }

  .choices {
    cursor: pointer;
    color: #101010;
  }
}
</style>
