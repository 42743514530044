import config from '../config'
import axios from 'axios'

const getEnvAssets = function (envId) {
  const url = `${config.usersUrl}/images/assets/${envId}`
  return axios.get(url)
}

const addCompanyImage = function (data, envId) {
  const url = `${config.usersUrl}/environments/upload-media/${envId}`
  return axios.post(url, data)
}

const deleteCompanyImage = function (key, envId) {
  const data = {
    imageKey: key
  }
  const url = `${config.usersUrl}/environments/delete-media/${envId}`
  return axios.put(url, data)
}

export const companyImagesService = {
  getEnvAssets,
  addCompanyImage,
  deleteCompanyImage
}
