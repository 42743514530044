import axios from 'axios'
import {
  companyImagesService,
  realEstateService,
  userService,
  EnvService,
  contractsService
} from '../../services'
import { restrucureContractSatuses } from '../../constans/contracts/contractStatusTagsColors.js'

// const router = _path.resolve('./router')

const user = JSON.parse(localStorage.getItem('tokenData'))
const currentEnv = JSON.parse(localStorage.getItem('currentEnv'))
let subscription = JSON.parse(localStorage.getItem('subscription'))

const bundleFeautres = {}
const bundleModules = {}
const state = currentEnv
  ? {
      status: { loggedIn: true },
      loggedIn: true,
      user,
      currentEnv,
      configs: {},
      subscription,
      profile: {},
      isAuthLoading: false,
      bundleFeautres,
      bundleModules,
      customerDefaultRole: JSON.parse(
        localStorage.getItem('activeCustomerRole')
      ),
      companyImages: JSON.parse(localStorage.getItem('companyImages')),
      contractsStatuses: JSON.parse(localStorage.getItem('contractsStatuses'))
    }
  : {
      status: { loggedIn: false },
      loggedIn: false,
      subscription: null,
      user: null,
      configs: {},
      currentEnv,
      profile: null,
      logInPopUp: false,
      bundleFeautres: null,
      bundleModules: null,
      customerDefaultRole: '',
      companyImages: {},
      contractsStatuses: {}
    }

const resetLocalStorage = () => {
  localStorage.removeItem('tokenData')
  localStorage.removeItem('currentEnv')
  localStorage.removeItem('subscription')
  localStorage.removeItem('companyImages')
  localStorage.removeItem('contractsStatuses')
  localStorage.setItem('logged_in', 'false')
  if (state.customerDefaultRole) {
    localStorage.removeItem('activeCustomerRole')
  }
}

const resetVuexProperties = (commit) => {
  commit('setCurrentEnv', null)
  commit('setUserSubscription', null)
  commit('setLoggedInStatus', false)
}

const getters = {
  hasPermission: (fromState) => (domain, route, permission) => {
    if (!fromState.currentEnv) {
      return false
    }
    // if environment owner or admin always return true
    if (
      ['owner', 'admin'].includes(state.currentEnv?.employeeRole) ||
      state.currentEnv.isOwner ||
      state.currentEnv.isAdmin
    ) {
      return true
    }
    if (state.currentEnv.permissions) {
      if (!state.currentEnv.permissions[domain]) {
        return false
      }
      const permissionArray = state.currentEnv.permissions[domain][route]
      if (!permissionArray) {
        return false
      }
      return permissionArray.some((item) => permission === item)
    }
    return null
  },
  checkBundleModules: (state) => (module) => {
    return !!state.bundleModules[module]
  },
  getConfigNameById:
    (fromState) =>
    (fieldName, lang = 'Ar', id) => {
      const configs = fromState.configs
      const item = configs[fieldName].find((cur) => cur._id === id)
      if (item) {
        return item[`name${lang}`]
      }
      return ''
    },
  /* Limit user foreach case */
  // ----------------------- //
  exceededAddingProperty(fromState) {
    return getters.isCustomer ||
      fromState.bundleFeautres?.realestates === 'unlimited'
      ? false
      : !!(fromState.bundleFeautres?.realestates <= 0)
  },
  exceededAddingMember(fromState) {
    return getters.isCustomer || fromState.bundleFeautres?.team === 'unlimited'
      ? false
      : !!(fromState.bundleFeautres?.team <= 0)
  },
  companyImages(fromState) {
    return fromState.companyImages ?? {}
  },
  contractsStatuses(fromState) {
    return fromState.contractsStatuses
  },
  isCustomer(fromState) {
    return !!fromState.currentEnv?.customerRegistered
  }
}

const actions = {
  login(_, { phoneNumber, password }) {
    return userService.login(phoneNumber, password)
  },
  async logout({ commit }) {
    try {
      await userService.resetEnvironmentId()
    } catch {
      commit(
        'appState/addNotification',
        {
          text: 'حدث خطأ أثناء تحميل تسجيل الخروج',
          color: 'error'
        },
        { root: true }
      )
      location.reload()
    } finally {
      resetLocalStorage()
      resetVuexProperties(commit)
    }
  },

  // Regsiter new user
  checkPhoneNumber(_, phoneNumber) {
    return userService.checkPhoneNumber(phoneNumber)
  },
  confirmPhoneNumber(_, data) {
    return userService.confirmPhoneNumber(data)
  },
  register({ commit }, _user) {
    commit('registerRequest', _user)
    return userService.register(_user)
  },
  userDetails(_, _user) {
    return userService.userDetails(_user)
  },
  // eslint-disable-next-line no-shadow
  updateUserDetails({ commit }, user) {
    const invitaion = JSON.parse(localStorage.getItem('user-invitation'))
    return new Promise((resolve) => {
      const _user = {
        ...user,
        accountId: user.accountId,
        _id: invitaion?._id,
        environmentId: invitaion?.environments[0]?._id
      }
      userService
        .updateProfile(invitaion ? _user : user)
        .then((userResponse) => {
          localStorage.setItem(
            'currentEnv',
            JSON.stringify(userResponse.data.user.environments[0])
          )
          commit('setCurrentEnv', userResponse.data.user.environments[0])
          commit('updateUserDetails', userResponse.data.user)
          resolve(userResponse)
        })
    })
  },

  updateUserProfile({ commit }, user) {
    return new Promise((resolve) => {
      userService
        .updateProfile(user)
        .then((updateResponse) => {
          localStorage.setItem('tokenData', JSON.stringify(user))
          commit('updateUserDetails', user)
          commit(
            'appState/addNotification',
            {
              text: 'تم تحديث البيانات بنجاح',
              color: 'success'
            },
            { root: true }
          )
          resolve(updateResponse)
        })
        .catch((err) => {
          if (err.response?.data.message.includes('email')) {
            commit(
              'appState/addNotification',
              {
                text: 'هذا البريد مُسجل, جرب بريد إالكتروني أخر',
                color: 'error'
              },
              { root: true }
            )
          } else {
            commit(
              'appState/addNotification',
              {
                text: 'خطأ في تعديل بيانات الملف الشخصي',
                color: 'error'
              },
              { root: true }
            )
          }
        })
    })
  },

  // update env settings
  updateEnvSettings({ commit }, _user) {
    const currentEnvIndex = state.user.environments.findIndex(
      (env) => env._id === state.currentEnv._id
    )
    localStorage.setItem('tokenData', JSON.stringify(_user))
    localStorage.setItem(
      'currentEnv',
      JSON.stringify(_user.environments[currentEnvIndex])
    )
    commit('updateUserDetails', _user)
    commit('setCurrentEnv', _user.environments[currentEnvIndex])
  },

  // Forget/Reset password
  checkPhoneNumberForResetPassword(_, phoneNumber) {
    return userService.checkPhoneNumberForResetPassword(phoneNumber)
  },
  confirmForgetCode(_, data) {
    return userService.confirmForgetCode(data)
  },
  resetPassword(_, data) {
    return userService.resetPassword(data)
  },
  async getUserSubscription({ commit }) {
    if (!state.currentEnv?._id) return
    const res = await userService.getUserSubscription(state.currentEnv?._id)
    commit('setUserSubscription', res.data.subscription)
    subscription = res.data.subscription
    return res.data
  },
  async getUserTokenData() {
    const res = await userService.getSingleClient(state.user._id)
    return res.data.user
  },

  // Configration
  async getConfigrations({ commit }) {
    await realEstateService
      .realestateFeatures('/realestatefeatures/getAll')
      .then((configs) => {
        commit('setConfigrations', configs.data)
      })
    await realEstateService
      .realestateFeatures('/realestatepopulation/getAll')
      .then((configs) => {
        commit('setConfigrations', configs.data)
      })
    await realEstateService
      .realestateFeatures('/realestatepurposes/getAll')
      .then((configs) => {
        commit('setConfigrations', configs.data)
      })
    await realEstateService
      .realestateFeatures('/realestatestatus/getAll')
      .then((configs) => {
        commit('setConfigrations', configs.data)
      })
    await realEstateService
      .realestateFeatures('/realestatepurposes/getAll')
      .then((configs) => {
        commit('setConfigrations', configs.data)
      })
    await realEstateService
      .realestateFeatures('/realestatepopulation/getAll')
      .then((configs) => {
        commit('setConfigrations', configs.data)
      })
    await realEstateService
      .realestateFeatures('/realestatetypes/getAll')
      .then((configs) => {
        commit('setConfigrations', configs.data)
      })
    await userService.getClientTypes().then((configs) => {
      commit('setConfigrations', configs.data)
    })
    await userService.getUserTypes().then((configs) => {
      commit('setConfigrations', configs.data)
    })
    await userService.getAllPermissions().then((configs) => {
      commit('setConfigrations', configs.data)
    })

    return state.configs
  },
  // get userBundle
  // eslint-disable-next-line consistent-return
  async getUserBundle({ commit }) {
    try {
      const res = await userService.getUserBundle(state.currentEnv?._id) // subscription
      const _subscription = JSON.parse(localStorage.getItem('subscription'))
      _subscription.bundle.modules = res.data.userBundle.modules
      localStorage.setItem('subscription', JSON.stringify(_subscription))
      commit('setUserSubscription', _subscription)
      commit('setBundleFeautreData', res.data.userBundle.features)
      commit('setBundleModuleData', res.data.userBundle.modules)
      return res
    } catch {}
  },
  async getContractsStatuses({ commit }) {
    try {
      const res = await contractsService.getContractsStatuses()
      commit('setContratsStatuses', restrucureContractSatuses(res.data))
    } catch {
      commit(
        'appState/addNotification',
        {
          text: 'خطأ في تحميل انواع العقود',
          color: 'error'
        },
        { root: true }
      )
    }
  },
  // get env data including company logos
  async getCompanyImages({ commit }, envId) {
    try {
      const { data } = await companyImagesService.getEnvAssets(envId)
      const envMedia = {}
      for (let i in data) {
        if (Object.keys(data[i] || {}).length) {
          envMedia[i] = {
            location:
              'data:image/jpeg;base64,' +
              btoa(
                new Uint8Array(data[i].image.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ''
                )
              ),
            key: data[i].key,
            path: data[i].location
          }
        }
      }
      commit('setcompanyImages', envMedia)
    } catch {
      commit(
        'appState/addNotification',
        {
          text: 'خطأ في تحميل بيانات الشركة',
          color: 'error'
        },
        { root: true }
      )
    }
  },
  async addCompanyImages({ commit, state }, payload) {
    try {
      const { data } = await companyImagesService.addCompanyImage(
        payload.fd,
        payload.envId
      )
      let updatedMedia = { ...state.companyImages }
      this._vm.$set(updatedMedia, payload.imageKey, {
        key: data.imageUrl.key,
        location: data.imageUrl.location
      })
      commit('setcompanyImages', updatedMedia)
      commit(
        'appState/addNotification',
        {
          text: `تم إضافة ${payload.imageTitle} بنجاح`,
          color: 'success'
        },
        { root: true }
      )
    } catch {
      commit(
        'appState/addNotification',
        {
          text: `لم يتم إضافة ${payload.imageTitle} يرجى المحاولة مرة أخرى`,
          color: 'error'
        },
        { root: true }
      )
    }
  },
  async removeCompanyImages({ commit, state }, payload) {
    try {
      await companyImagesService.deleteCompanyImage(
        payload.imageKey,
        payload.envId
      )
      const updatedMedia = { ...state.companyImages }
      this._vm.$set(updatedMedia, payload.imageKey, undefined)
      commit('setcompanyImages', updatedMedia)
      commit(
        'appState/addNotification',
        {
          text: `تم حذف ${payload.imageTitle} بنجاح`,
          color: 'success'
        },
        { root: true }
      )
    } catch {
      commit(
        'appState/addNotification',
        {
          text: `لم يتم حذف ${payload.imageTitle} يرجى المحاولة مجددا`,
          color: 'error'
        },
        { root: true }
      )
    }
  },
  updateCurrentEnv({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      userService
        .updateEnv({ ...payload, _id: state.currentEnv._id })
        .then(() => {
          commit('setCurrentEnv', { ...state.currentEnv, ...payload })
          localStorage.setItem(
            'currentEnv',
            JSON.stringify({ ...state.currentEnv, ...payload })
          )
          commit(
            'appState/addNotification',
            {
              text: 'تم تعديل الإعدادات بنجاح',
              color: 'success'
            },
            { root: true }
          )
          resolve()
        })
        .catch((err) => {
          commit(
            'appState/addNotification',
            {
              text: 'خطأ في تعديل الإعدادات، يرجى المحاولة مجددا',
              color: 'error'
            },
            { root: true }
          )
          reject(err)
        })
    })
  },
  async getCurrentEnvData({ commit }, envId) {
    try {
      const res = await EnvService.getEnvData(envId)
      const newEnv = { ...state.currentEnv, ...res.data.env }
      commit('setCurrentEnv', newEnv)
      localStorage.setItem('currentEnv', JSON.stringify(newEnv))
      const role = state.currentEnv?.employeeRole?.length
        ? 'employee'
        : 'customer'
      const { data } = await userService.setEnvironmentId(envId, role)
      await commit('loginRequest', {
        ...state.user,
        userEnvToken: data.userEnvToken
      })
    } catch {
      commit(
        'appState/addNotification',
        {
          text: 'خطأ في تحميل بيانات البيئة',
          color: 'error'
        },
        { root: true }
      )
    }
  }
}

const mutations = {
  setCurrentEnv(fromState, value) {
    fromState.currentEnv = value
  },
  setcompanyImages(fromState, value) {
    localStorage.setItem('companyImages', JSON.stringify(value))
    fromState.companyImages = value
  },
  setContratsStatuses(fromState, value) {
    localStorage.setItem('contractsStatuses', JSON.stringify(value))
    fromState.contractsStatuses = value
  },
  setLoggedInStatus(fromState, isLoggedIn) {
    fromState.loggedIn = isLoggedIn
  },
  loginRequest(fromState, _user) {
    fromState.user = _user
    axios.defaults.headers.common.Authorization = `Bearer ${_user.token}`
    axios.defaults.headers.common[
      'HPlus-Authorization'
    ] = `Bearer ${_user.userEnvToken}`
    localStorage.setItem('tokenData', JSON.stringify(_user))
    localStorage.setItem('logged_in', 'true')
  },
  loginSuccess(fromState, _user) {
    fromState.user = _user
    fromState.loggedIn = true
    fromState.status.loggedIn = true
  },
  loginFailure(fromState) {
    fromState.status = { loggedIn: false }
    fromState.user = null
  },
  logout(fromState) {
    delete axios.defaults.headers.common.Authorization
    delete axios.defaults.headers.common['HPlus-Authorization']
    fromState.status = { loggedIn: false }
    fromState.subscription = null
    fromState.loggedIn = false
    fromState.user = null
  },
  otpConfirmed(fromState, confirmed) {
    fromState.status = { otpConfirmed: confirmed }
  },
  registerRequest(fromState) {
    fromState.status = { registering: true }
  },
  registerFailure(fromState) {
    fromState.status = {}
  },

  setConfigrations(fromState, config) {
    Object.assign(fromState.configs, config)
  },
  setUserSubscription(fromState, _subscription) {
    fromState.subscription = _subscription
  },
  setlogInPopUp(fromState, payload) {
    fromState.logInPopUp = payload
  },
  setBundleFeautreData(fromState, payload) {
    fromState.bundleFeautres = payload
  },
  setBundleModuleData(fromState, payload) {
    fromState.bundleModules = payload
  },
  updateUserDetails(fromState, _user) {
    fromState.user = _user
  },
  updateTheme(fromState, theme) {
    this._vm.$set(fromState.currentEnv, 'userTheme', theme)
    localStorage.setItem('currentEnv', JSON.stringify(fromState.currentEnv))
  },
  setCustomerRole(fromState, newRole) {
    fromState.customerDefaultRole = newRole || fromState.currentEnv?.role[0]
    localStorage.setItem(
      'activeCustomerRole',
      JSON.stringify(fromState.customerDefaultRole)
    )
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
