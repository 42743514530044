export default {
  headersArr: [
    {
      text: 'نوع الطلب',
      value: 'type',
      width: 60,
      align: 'center',
      sortable: false,
      isCustomer: true
    },
    {
      text: 'القسم',
      value: 'category',
      width: 60,
      align: 'center',
      sortable: false,
      isCustomer: true
    },
    {
      text: 'اسم العميل',
      value: 'applicant',
      align: 'center',
      width: 90,
      sortable: false,
      show: true,
      isCustomer: false
    },
    {
      text: 'عقار',
      value: 'realEstate',
      align: 'center',
      width: 100,
      sortable: false,
      isCustomer: true
    },
    {
      text: 'منشئ الطلب',
      value: 'createdBy',
      align: 'center',
      width: 65,
      sortable: false,
      isCustomer: true
    },
    {
      text: 'المسؤول',
      value: 'moderator',
      align: 'center',
      sortable: false,
      show: true,
      isCustomer: false
    },
    {
      text: 'تاريخ الإنشاء',
      value: 'createdAt',
      align: 'center',
      width: 60,
      sortable: false,
      isCustomer: true
    },
    {
      text: 'مصدر الطلب',
      value: 'issuedFrom',
      align: 'center',
      width: 60,
      sortable: false,
      isCustomer: false
    },
    {
      text: 'الأولوية',
      value: 'priority',
      align: 'center',
      width: 50,
      sortable: false,
      isCustomer: false
    },
    {
      text: 'الحاله',
      value: 'status',
      align: 'center',
      width: 70,
      isCustomer: true
    },
    {
      text: '',
      width: 30,
      isCustomer: true
    }
  ],
  lengthTitle: 'طلبات',
  requestStates: [
    {
      nameAr: 'جديده',
      nameEn: 'new'
    },
    {
      nameAr: 'قيد التنفيذ',
      nameEn: 'inProgress'
    },
    // {
    //   nameAr: 'قيد المراجعه',
    //   nameEn: 'underReview'
    // },
    {
      nameAr: 'تم التنفيذ',
      nameEn: 'done'
    },
    {
      nameAr: 'ملغي',
      nameEn: 'cancelled'
    },
    {
      nameAr: 'مرفوض',
      nameEn: 'rejected'
    }
  ]
}
