<template>
  <v-tabs align-with-title color="primary" right show-arrows v-model="tab">
    <v-tabs-slider color="primary"></v-tabs-slider>
    <v-tab
      :key="item"
      :ripple="false"
      class="mr-6 rounded-15"
      v-for="item in items"
    >
      {{ item.displayedName }}
    </v-tab>

    <v-tabs-items class="mt-5 profile-tabs" v-model="tab">
      <v-tab-item v-for="item in items" :eager="true">
        <component :is="item.componentName" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import contractsModels from './contractsModels'
import branding from './branding'

export default {
  name: 'models',
  components: { contractsModels, branding },
  data() {
    return {
      items: [
        {
          displayedName: 'نماذج العقود',
          componentName: 'contractsModels'
        },
        {
          displayedName: 'الشعار و التوقيعات',
          componentName: 'branding'
        }
      ],
      tab: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_env.scss';
</style>
