<template>
  <div>
    <v-row
      class="align-center py-5 overflow-y-hidden overflow-x-auto px-5"
      :class="[sm || xs ? 'd-block' : 'd-flex']"
    >
      <v-row
        class="d-flex"
        :class="{ 'flex-nowrap': mdAndUp, 'flex-wrap': smAndDown }"
      >
        <div
          class="px-0"
          style="width: 200px"
          v-for="(filter, idx, i) in approvalConfig"
          :key="idx"
        >
          <v-menu
            v-if="i !== 0"
            content-class="vMenuFilter elevation-0 w-fit"
            offset-y
            transition="slide-y-transition"
            ref="aprrovalType"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex align-center mx-1"
                :class="{ 'mt-1': smAndDown }"
              >
                <v-slide-x-transition>
                  <v-card
                    ref="sortListActivator"
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex justify-space-between px-2 pt-1 w-100"
                    width="130"
                  >
                    <h6 class="mx-2 w-fit text-no-wrap text-truncate">
                      {{ ConfigPlaceholder[idx] }}
                    </h6>
                    <v-icon
                      >{{
                        menuStateSwitcher($refs.aprrovalType?.[i - 1])
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-card>
                </v-slide-x-transition>
              </div>
            </template>
            <v-list
              class="contractVmenuList pl-4 pa-0"
              v-for="option in approvalConfig[idx]"
              :key="option.id"
            >
              <v-list-item-group>
                <v-list-item class="px-1">
                  <v-list-item-title
                    ref="sortOption"
                    class="font-14 mr-1 mb-2 font-weight-medium"
                    @click="setFiltervalue(option, idx)"
                  >
                    {{ option.nameAr }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-menu
            content-class="vMenuFilter elevation-0"
            offset-y
            transition="slide-y-transition"
            ref="bondsType"
            v-else
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center">
                <v-slide-x-transition>
                  <v-card
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex justify-space-between px-2 pt-1"
                    width="200px"
                  >
                    <h6 class="mr-2">
                      {{ ConfigPlaceholder[idx] }}
                    </h6>

                    <v-icon
                      >{{
                        menuStateSwitcher($refs?.bondsType?.[i])
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-card>
                </v-slide-x-transition>
              </div>
            </template>
            <v-card width="230">
              <v-row no-gutters align="center" class="pa-3">
                <v-col
                  class="py-0"
                  md="6"
                  v-for="type in approvalConfig['status']"
                  :key="type._id"
                >
                  <h6
                    :class="{
                      'brub--tag-border': filtersQuery['status'] === type.nameEn
                    }"
                    @click="setFiltervalue(type, 'status')"
                    class="text-center roundeed-tag px-2 py-1 cursor ml-1"
                  >
                    {{ type.nameAr }}
                  </h6>
                </v-col>
                <v-col md="12" class="text-left">
                  <a
                    @click="restFilters('status')"
                    data-test="rest-filter"
                    class="text-decoration-underline font-12 black--text"
                    >إعادة تعيين</a
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
        </div>
        <v-text-field
          class="search__by__customer__name mx-1 font-14"
          :class="{ 'mt-1': smAndDown }"
          height="45"
          filled
          dense
          hide-details="auto"
          placeholder="ابحث بإسم العقار أو اسم الوحدة"
          v-model="filtersQuery.realEstateName"
        />
        <v-btn
          style="
            background-color: white;
            margin: 0;
            margin-top: 5px;
            margin-right: 3rem;
            box-shadow: 0 0 0 0;
          "
        >
          <router-link
            :to="{
              path: '/dashboard/edit-profile/5',
              query: { currComponent: 'Approvement' }
            }"
            style="color: black"
          >
            <h6 class="mx-2 w-fit text-no-wrap text-truncate">
              الإعدادات
              <v-icon class="mr-2" style="color: gray">mdi-cog</v-icon>
            </h6>
          </router-link>
        </v-btn>
      </v-row>
    </v-row>
    <div class="d-flex mt-4">
      <template v-for="(filter, idx) in filters">
        <v-card
          class="d-flex pa-1 flex-grow-0 justify-between align-center mx-1"
          v-if="filter"
          :key="idx"
        >
          <h5 class="ma-0 mx-2 text--purple font-12 text-no-wrap py-2">
            {{ filter }}
          </h5>
          <v-icon
            class="font-weight-medium font-14 pl-1"
            @click="restFilters(idx)"
          >
            mdi-close
          </v-icon>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/helpers/debounce'
import { ApprovalFilter } from '@/constans/approval'
export default {
  name: 'ApprovalFilter',
  props: {
    approvalConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ...ApprovalFilter
    }
  },
  computed: {
    xs() {
      return this.$vuetify.breakpoint.xs
    },
    sm() {
      return this.$vuetify.breakpoint.sm
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    },
    mdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  watch: {
    filtersQuery: {
      deep: true,
      immediate: true,
      handler(UpdatedFilters) {
        this.$emit('updateFilters', UpdatedFilters)
      }
    }
  },
  methods: {
    setFiltervalue(filter, idx) {
      this.$emit('resetPagination')
      this.ConfigPlaceholder[idx] = filter.nameAr
      this.$set(this.filters, idx, filter.nameAr)
      this.$set(this.filtersQuery, idx, filter.nameEn)
    },
    menuStateSwitcher(menu) {
      return menu?.isActive
    },
    changeIconDir(idx) {
      this.resetFiltersArrows(idx)
    },
    resetFiltersArrows(idx) {
      Object.keys(this.filtersCards).map((key) => {
        if (idx === key) {
          this.filtersCards[key] = !this.filtersCards[key]
        } else {
          this.filtersCards[key] = false
        }
      })
    },
    setFiltervalueForInput(event, filedName) {
      this.$emit('resetPagination')
      this.$set(this.filters, event, filedName)
      this.$set(this.filtersQuery, filedName, event)
    },
    restFilters(idx) {
      this.filters[idx] = ''
      this.filtersQuery[idx] = ''
      this.ConfigPlaceholder[idx] = this.defaultConfigPlaceholder[idx]
    },
    searchDebounce: debounce(function ($event, filedName) {
      this.setFiltervalueForInput($event, filedName)
    }, 900)
  }
}
</script>

<style lang="scss" scoped></style>
