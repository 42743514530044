<template>
  <div class="show-detailes pdf-summary-container">
    <v-slide-x-transition>
      <v-layout>
        <v-col :cols="4" class="whiteSide pa-0">
          <pdfHeader
            ref="pdfHeader"
            @closeDetails="$emit('closePdf')"
            class="mt-1 mx-4"
            @downloadPdf="downloadPdf"
          />
          <v-divider class="mt-3"></v-divider>
          <v-layout class="d-flex w-100">
            <div class="w-100" id="toBePrint">
              <div class="mx-2">
                <div class="toBePrintContentBond">
                  <div class="px-3 d-flex justify-space-between align-center">
                    <div class="my-5">
                      <h3 class="font-18 font-weight-bold">
                        إتفاقيه الإستخدام والإشتراكات
                      </h3>
                      <h6 class="font-12 font-weight-medium">
                        <span class="text-gray font-14">
                          موافقتك على شروط الخدمة المبينة أدناه، فباستخدامك
                          لموقع "هكتار" تقر بأنك قرأت شروط الخدمة وبأنك وافقت
                          عليها، إذا كنت لا توافق على هذه الشروط الخاصة
                          بالاستعمال لا يسمح لك بدخول أو استعمال هكتار او احد
                          تطبيقاته ويمكنك المغادرة والمشار اليها فيما يلي:
                          <p class="ma-0 mt-2">
                            1- كل ما يسمي بالموقع يعني hectar.io.
                          </p>
                          <p class="ma-0">
                            2- منصة هكتار ومنصة هكتار بلس تعني المتصفح المملوك
                            لنا.
                          </p>
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="footer d-flex px-5 align-center py-2">
                  <div class="d-flex">
                    <img
                      :src="hectarPdfText"
                      width="40"
                      class="mx-1"
                      loading="lazy"
                    />
                  </div>
                  <v-spacer></v-spacer>
                  <span class="copyRight font-14">
                    هكتار للخدمات العقاريه
                  </span>
                </div>
              </div>
            </div>
          </v-layout>
        </v-col>
      </v-layout>
    </v-slide-x-transition>
  </div>
</template>
<script>
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import pdfHeader from '@/components/contracts/pdfHeader.vue'

export default {
  name: 'UsageAgreementPdf',
  components: { pdfHeader },
  data() {
    return {
      hectarPdfText: require('@/assets/img/svgs/hectarPdfText.svg')
    }
  },
  methods: {
    downloadPdf() {
      const pdf = new jsPDF()
      const element = document.getElementById('toBePrint')
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png')
        pdf.addImage(image, 'PNG', 0, 0, 190, 0)
        pdf.save(`إتفاقيه الإستخدام والإشتراكات.pdf`)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/pdf/_contractPDF.scss';
</style>
