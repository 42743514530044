<template>
  <div class="show-detailes pdf-summary-container">
    <v-slide-x-transition>
      <v-layout>
        <v-col :cols="4" @click="$emit('closePdf')" class=""></v-col>
        <v-col :cols="4" class="whiteSide pa-0">
          <pdfHeader
            class="mt-1 mx-4"
            v-bind="{ showSigntures, viewSharedPdf }"
            @closeDetails="$emit('closePdf')"
            @updateSignatures="Signatures = $event"
            @downloadPdf="downloadPdf"
          />
          <v-divider class="mt-3"></v-divider>
          <ContractPdfSchema v-bind="ContractPdfSchema" />
        </v-col>
        <v-col :cols="4" @click="$emit('closePdf')" class=""></v-col>
      </v-layout>
    </v-slide-x-transition>
    <!---------Generate PDF------------>
    <VueHtml2pdf v-bind="dataVuePdf">
      <section slot="pdf-content">
        <div class="pdf__content">
          <ContractPdfSchema
            v-bind="{ ...ContractPdfSchema, showAction: false }"
          />
        </div>
      </section>
    </VueHtml2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
import pdfHeader from './pdfHeader.vue'
import { mapGetters, mapMutations } from 'vuex'
import ContractPdfSchema from './ContractPdfSchema.vue'
export default {
  name: 'contract-pdf',
  props: {
    item: {
      type: Object,
      required: true
    },
    showSigntures: {
      type: Boolean,
      default: false
    },
    viewSharedPdf: {
      type: Boolean,
      default: false
    },
    sharedPdfMedia: {
      type: Object,
      default() {
        return {}
      }
    },
    contractIntervals: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    pdfHeader,
    VueHtml2pdf,
    ContractPdfSchema
  },
  data() {
    return {
      Signatures: []
    }
  },
  computed: {
    ...mapGetters('accountState', ['companyImages']),
    ContractPdfSchema() {
      return {
        item: this.item,
        Signatures: this.Signatures,
        pdfMedia: this.pdfMedia,
        contractIntervals: this.contractIntervals
      }
    },
    dataVuePdf() {
      return {
        showLayout: false,
        enableDownload: true,
        previewModal: false,
        paginateElementsByHeight: 1000,
        pdfQuality: 2,
        manualPagination: true,
        pdfMargin: 5,
        ref: 'html2Pdf',
        filename: this.item.title,
        pdfFormat: 'a4',
        pdfContentWidth: '100%',
        html2canvas: {
          useCORS: true
        }
      }
    },
    pdfMedia() {
      return this.viewSharedPdf ? this.sharedPdfMedia : this.companyImages
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    downloadPdf() {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/pdf/_contractPDF.scss';
</style>
