<template>
  <div class="quotation--paper2 quotation--paper5">
    <h1>الحسابات البنكية</h1>
    <h2 class="ma-0">الحسابات البنكية المعتمدة لعمليات التحويل والإيداع</h2>

    <div
      v-for="(bankAccount, i) in currentEnv?.bankAccounts"
      :key="i"
      class="quotation--paper9__card quotation--paper9__card--mod py-2 d-flex"
    >
      <div>
        <h3 class="ma-0 mb-2 font-20">{{ bankAccount?.name }}</h3>
        <p class="ma-0 font-16">{{ bankAccount?.iban }}</p>
      </div>
      <div class="quotation--paper9__card__img">
        <img src="https://placehold.co" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Paper8',
  computed: {
    ...mapState('accountState', ['currentEnv'])
  }
}
</script>

<style lang="scss" scoped></style>
