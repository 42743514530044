<template>
  <v-sheet id="env-pic">
    <img width="160" src="@/assets/img/logo.svg" class="mb-13" loading="lazy" />
    <h3 class="ma-0 font-weight-medium mb-8">تعيين لون ايقونة الحساب</h3>
    <h6 class="font-weight-medium h6-gery mb-6">
      تمييز الحساب بالأحرف الأولى وتعيين ألوان لهذه الأحرف يساعد أعضاء فريق
      العمل للتفريق بين الأعضاء المختلفين بحيث يكون لكل عضو أحرف وألوان تميزه عن
      الأخرين
    </h6>
    <h3 data-test="name" class="ma-0 font-weight-medium mb-8">{{ name }}</h3>

    <v-flex d-flex>
      <div
        class="profile-color ml-9"
        :style="`color:${userTheme}; border: 2px solid ${userTheme};`"
      >
        {{ $subString(name) }}
      </div>
      <div class="d-flex align-center">
        <div
          class="round ml-5 cursor"
          v-for="(color, index) in colorAvatar"
          :key="index"
        >
          <input
            class="usercb"
            data-test="setColor"
            @click="setColor(color, $event)"
            :style="setCheckboxStyle(color)"
            type="checkbox"
            :id="`user-checkbox-${index}`"
          />
          <label
            :style="setCheckboxStyle(color)"
            :for="`user-checkbox-${index}`"
          ></label>
        </div>
      </div>
    </v-flex>
  </v-sheet>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'profilePic',
  data() {
    return {
      name: '',
      userTheme: '',
      colorAvatar: [
        '#662D91',
        '#12CAD6',
        '#28DF99',
        '#4FB3F9',
        '#1C5FAE',
        '#FC4A4A',
        '#F49300',
        '#ED6237',
        '#E4897B',
        '#202020',
        '#737373',
        '#E2E2E2'
      ]
    }
  },
  mounted() {
    const tokenData = JSON.parse(localStorage.getItem('tokenData'))
    this.name = tokenData.name
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    validate() {
      const valid = this.userTheme !== ''
      if (!valid) {
        this.addNotification({
          text: 'من فضلك تأكد من اختيار لون ايقونه الحساب',
          color: 'error'
        })
      } else {
        this.$emit('on-validate', { userTheme: this.userTheme }, !!valid)
      }
      return !!valid
    },
    setCheckboxStyle(color) {
      return `background-color: ${color};  border-color: ${color};`
    },
    setColor(color, event) {
      const cbs = document.getElementsByClassName('usercb')
      for (let i = 0; i < cbs.length; i++) {
        cbs[i].checked = false
      }
      event.target.checked = true
      this.userTheme = color
    }
  }
}
</script>

<style scoped>
.round {
  position: relative;
}
.usercb {
  margin-left: 12px;
  margin-right: 12px;
}
.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox'] + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
</style>
