<template>
  <header id="headerContainer">
    <!-- Header -->
    <div class="headerWrapper">
      <div id="headerr">
        <!-- <div id="header"> -->
        <div class="container">
          <div class="headerInner">
            <div class="rightContent" id="">
              <router-link :to="{ path: '/dashboard' }">
                <img
                  src="@/assets/img/svgs/helpCenterLogo.svg"
                  alt=""
                  loading="lazy"
                />
              </router-link>
            </div>
            <div class="centerContent"></div>
            <div class="leftContent">
              <div class="" v-if="loggedIn">
                <div class="d-flex nameContainer align-end">
                  <h6 v-if="currentUserName" class="substringName">
                    {{ currentUserName ? $subString(currentUserName) : '' }}
                  </h6>
                  <div class="left">
                    <p class="hi">حياك الله</p>
                    <h5 v-if="currentUserName" class="name">
                      {{ currentUserName }}
                    </h5>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
              <div class="loginBtn" v-else>
                <button variant="primary" @click="loginPageRoute" class="m-0">
                  <span class="text">تسجيل دخول</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Header / End -->
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      loggedIn: '',
      currentUserName: ''
    }
  },
  methods: {
    loginPageRoute() {
      this.$router.push('/auth/login')
    }
  },
  created() {
    this.loggedIn = JSON.parse(localStorage.getItem('logged_in'))
    if (this.loggedIn) {
      this.currentUserName = JSON.parse(localStorage.getItem('tokenData')).name
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/helpCenter/helpCenterHeader.scss';
</style>
