<template>
  <div class="mt-4">
    <div class="d-flex justify-space-between">
      <h4 class="my-auto toolbar-title font-weight-medium">
        <span class="primary--text">إليك </span>
        <span class="">بعض إحصائيات وأرقام الحساب المـهـمة </span>
      </h4>
      <div class="d-flex align-center">
        <div class="px-0">
          <v-card
            class="d-flex justify-space-between mr-2"
            style="
              background-color: #66328e1a;
              color: #66328e;
              border-radius: 5px;
            "
            @click="openRangePicker"
          >
            <h6 class="mx-1 mr-2 w-fit text-no-wrap">
              {{ RangePlaceholder }}
            </h6>
            <v-icon class="ml-2" color="#66328e">
              {{ isRangeOpened ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-card>
          <date-picker-rage
            ref="datePickerRage"
            class="range--card__date-picker"
            @date-picker-filter-text="setFiltervalueForInput"
            @date-picker-filter-reset="resetDateFilter"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePickerRage from '../core/DatePickerRage.vue'

export default {
  components: {
    DatePickerRage
  },
  data: () => ({
    RangePlaceholder: 'تحديد الفترة',
    filtersQuery: {
      startDate: '',
      endDate: ''
    },
    isRangeOpened: false
  }),
  watch: {
    filtersQuery: {
      deep: true,
      handler(UpdatedFilters) {
        this.$emit('queryBuilder', UpdatedFilters)
      }
    }
  },
  methods: {
    setFiltervalueForInput(sreachKey, input) {
      if (input === 'date') {
        this.isRangeOpened = !this.isRangeOpened
        this.RangePlaceholder = sreachKey
      }
      if (input === 'endDate' || input === 'startDate') {
        return this.$set(this.filtersQuery, input, sreachKey)
      }
      // this.$set(this.filters, input, sreachKey)
      this.$set(this.filtersQuery, input, sreachKey)
      return null
    },
    resetDateFilter() {
      this.RangePlaceholder = 'تحديد الفترة'
      delete this.filtersQuery.date
      // this.$emit('date-picker-filter-reset')
    },
    openRangePicker() {
      this.isRangeOpened = !this.isRangeOpened
      if (this.isRangeOpened) {
        this.$refs.datePickerRage.$children[0].openPopup()
      } else {
        this.$refs.datePickerRage.$children[0].closePopup()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.year-label {
  background-color: #66328e1a;
  color: #66328e;
  padding: 5px 30px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  height: 90%;
}
</style>
