<template>
  <v-dialog max-width="720px" persistent :value="dialogState">
    <v-card class="add-new-property">
      <v-card-title
        class="px-4 py-2 mb-5 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0">
          إضافة عملية تحصيل (إضافة المعلومات المطلوبة وتأكيد العملية)
        </h4>
        <v-btn @click.prevent="closeDialog" class="ma-0" color="grey" fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!--content gose here -->
      <div class="px-4 py-2">
        <!-- FORM -->
        <!-- informativeCard -->
        <div class="py-2 px-4 bg-brub rounded-xl d-flex">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="-0.000244141"
              width="20"
              height="20"
              rx="10"
              fill="#662D91"
              fill-opacity="0.1"
            />
            <g clip-path="url(#clip0_13864_307)">
              <path
                d="M12.4228 14.5743L12.2598 15.2407C11.7708 15.4337 11.3801 15.5806 11.0892 15.6816C10.798 15.7829 10.4597 15.8333 10.0741 15.8333C9.48212 15.8333 9.02163 15.6883 8.6932 15.4002C8.36478 15.111 8.20051 14.7445 8.20051 14.2999C8.20051 14.1278 8.21243 13.9508 8.23721 13.7704C8.2622 13.5899 8.30194 13.3865 8.35631 13.1592L8.96747 10.996C9.02184 10.7889 9.06806 10.5926 9.10507 10.407C9.14261 10.2226 9.1607 10.0529 9.1607 9.90009C9.1607 9.62384 9.1035 9.43061 8.98964 9.32197C8.87577 9.21364 8.65849 9.15864 8.33592 9.15864C8.17793 9.15864 8.01554 9.18395 7.85002 9.23288C7.68377 9.28203 7.54167 9.3295 7.42236 9.37362L7.58579 8.70673C7.98616 8.54372 8.36885 8.40413 8.73492 8.28827C9.10099 8.1721 9.44688 8.11397 9.77406 8.11397C10.362 8.11397 10.8157 8.25607 11.1343 8.54027C11.4529 8.82467 11.6121 9.19336 11.6121 9.64768C11.6121 9.74168 11.6017 9.9073 11.5792 10.144C11.5573 10.3813 11.5164 10.5987 11.4568 10.7964L10.8482 12.9509C10.7983 13.1239 10.7535 13.3218 10.7145 13.5444C10.6742 13.7655 10.655 13.9345 10.655 14.0478C10.655 14.3339 10.7188 14.5292 10.8467 14.6331C10.9755 14.7369 11.1973 14.7886 11.5125 14.7886C11.6605 14.7886 11.8288 14.7623 12.0159 14.7108C12.2024 14.6592 12.3384 14.6139 12.4228 14.5743ZM12.5771 5.5291C12.5771 5.90448 12.4357 6.22506 12.1514 6.48866C11.8678 6.7532 11.5261 6.88558 11.1264 6.88558C10.7254 6.88558 10.3828 6.7532 10.096 6.48866C9.80971 6.22496 9.66625 5.90448 9.66625 5.5291C9.66625 5.15446 9.80971 4.83335 10.096 4.5664C10.3823 4.29988 10.7255 4.16666 11.1264 4.16666C11.526 4.16666 11.8678 4.30019 12.1514 4.5664C12.4359 4.83335 12.5771 5.15456 12.5771 5.5291Z"
                fill="#662D91"
              />
            </g>
            <defs>
              <clipPath id="clip0_13864_307">
                <rect
                  width="11.6667"
                  height="11.6667"
                  fill="white"
                  transform="translate(4.16638 4.16666)"
                />
              </clipPath>
            </defs>
          </svg>
          <h6 class="text-primary font-12 ma-0 mr-2 text-center">
            سيتم إنشاء سند تلقائيا عند إتمام عملية الدفع
          </h6>
        </div>
        <v-form v-model="isValidForm" ref="form">
          <v-row class="mt-4">
            <v-col class="pl-3 mobile__pl__0" md="6" cols="12">
              <h6 class="mb-2 mt-0">اسم المحصل</h6>
              <v-select
                :items="team"
                :menu-props="{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }"
                placeholder="اسم المحصل"
                :rules="[(v) => !!v || `اختر المحصل`]"
                append-icon="mdi-chevron-down"
                dense
                filled
                hide-details
                item-text="name"
                return-object
                required
                v-model="due.user"
              />
            </v-col>
            <v-col class="pl-3 mobile__pl__0" md="6" cols="12">
              <h6 class="mb-2 mt-0">تاريخ التحصيل*</h6>
              <v-dialog
                :return-value.sync="due.date"
                ref="startAtDialog"
                v-model="startAtModal"
                width="320px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="[(v) => !!v || 'من فضلك ادخل تاريخ التحصيل']"
                    append-icon="mdi-calendar-month-outline"
                    dense
                    filled
                    hide-details
                    placeholder="تاريخ التحصيل"
                    readonly
                    required
                    v-bind="attrs"
                    v-model="due.date"
                    v-on="on"
                    class="date--dropdown font-14"
                  />
                </template>
                <v-date-picker color="primary" scrollable v-model="start">
                  <v-spacer />
                  <v-btn @click="startAtModal = false" color="primary" text>
                    الغاء
                  </v-btn>
                  <v-btn
                    @click="$refs.startAtDialog.save(start)"
                    color="primary"
                    text
                    >حفظ
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col
              class="pl-3 mobile__pl__0"
              :md="isRentCombined ? '12' : '3'"
              cols="12"
            >
              <h6 class="mb-2 mt-0">
                {{
                  isRentCombined
                    ? 'القيمة المطلوبة للإيجار والمبالغ الثابتة'
                    : 'القيمة المطلوبه'
                }}
              </h6>
              <div
                class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
              >
                <span>{{ formattingNumbers(assignment.payment) }}</span>
                <span>ريال</span>
              </div>
            </v-col>
            <v-col
              class="pl-3 mobile__pl__0"
              :md="isRentCombined ? 5 : 3"
              cols="12"
            >
              <h6 class="mb-2 mt-0">
                {{ isRentCombined ? 'قيمة الإيجار' : 'القيمة المستلمة' }}
              </h6>
              <v-text-field
                ref="bondValue"
                suffix="ريال"
                dense
                filled
                class="font-12"
                placeholder="0"
                hide-details
                :rules="fromSideDetails ? recievedAmountRules : []"
                :value="assignment?.details?.rent"
                required
                @input="formatArabicNumber($event, 'bondValue')"
              />
            </v-col>
            <v-col
              class="pl-3 mobile__pl__0"
              :md="isRentCombined ? '2' : '3'"
              cols="12"
            >
              <h6 class="mb-2 mt-0">نسبة الضريبة</h6>
              <div
                class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
              >
                <span>{{ assignment.tax }}%</span>
              </div>
            </v-col>
            <v-col
              class="pl-3 mobile__pl__0"
              :md="isRentCombined ? '2' : '3'"
              cols="12"
            >
              <h6 class="mb-2 mt-0">قيمه الضريبة</h6>
              <div
                class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
              >
                <span>{{ taxAmount?.toFixed(2) }}</span>
              </div>
            </v-col>
            <v-col
              class="pl-3 mobile__pl__0"
              md="3"
              cols="12"
              v-if="isRentCombined"
            >
              <h6 class="mb-2 mt-0">إجمالي قيمة الإيجار</h6>
              <div
                class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
              >
                <span>{{ formattingNumbers(due.bondValue + taxAmount) }}</span>
              </div>
            </v-col>
            <template v-if="isRentCombined">
              <v-col class="pl-3 mobile__pl__0" md="5" cols="12">
                <h6 class="mb-2 mt-0">قيمة المبالغ الثابتة</h6>
                <v-text-field
                  ref="utilities"
                  suffix="ريال"
                  dense
                  filled
                  class="font-12"
                  placeholder="0"
                  required
                  hide-details
                  :rules="fixedAmountRules"
                  :value="utilitiesMask"
                  @input="formatArabicNumber($event, 'utilities')"
                />
              </v-col>
              <v-col class="pl-3 mobile__pl__0" md="2" cols="12">
                <h6 class="mb-2 mt-0">نسبة الضريبة</h6>
                <div
                  class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
                >
                  <span>{{ assignment.utilitiesTax ?? 0 }}%</span>
                </div>
              </v-col>
              <v-col class="pl-3 mobile__pl__0" md="2" cols="12">
                <h6 class="mb-2 mt-0">قيمه الضريبة</h6>
                <div
                  class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
                >
                  <span>{{ utilitiesTaxAmount?.toFixed(2) }}</span>
                </div>
              </v-col>
              <v-col class="pl-3 mobile__pl__0" md="3" cols="12">
                <h6 class="mb-2 mt-0">إجمالي قيمه المبالغ الثابتة</h6>
                <div
                  class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
                >
                  <span>{{
                    formattingNumbers(due.utilities + utilitiesTaxAmount)
                  }}</span>
                </div>
              </v-col>
            </template>
          </v-row>
          <v-row class="mt-2">
            <v-col class="pl-3 mobile__pl__0" md="6" cols="12">
              <h6 class="mb-2 mt-0">اجمالي المحصل مع الضريبه</h6>
              <div
                class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
              >
                <span>{{
                  formattingNumbers(
                    assignment.taxAmount + due.bondValue + due.utilities
                  )
                }}</span>
                <span>ريال</span>
              </div>
            </v-col>
            <v-col class="pl-3 mobile__pl__0" md="6" cols="12">
              <h6 class="mb-2 mt-0">القيمة المتبقية</h6>
              <div
                class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
              >
                <span>{{ remainingAfterTax }}</span>
                <span>ريال</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col
              v-if="showIsClaimedInput"
              class="pl-3 mobile__pl__0"
              md="6"
              cols="12"
            >
              <h6 class="mb-2 mt-0">
                هل تم تسليم مبلغ قيمة المطالبة للمالك مباشرة؟
              </h6>
              <v-select
                :items="setCategories"
                :menu-props="{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }"
                :rules="[(v) => v != null || '']"
                placeholder="هل تم تسليم مبلغ قيمة المطالبة للمالك مباشرة؟"
                append-icon="mdi-chevron-down"
                dense
                filled
                hide-details
                item-text="name"
                item-value="value"
                required
                v-model="due.isClaimed"
                @input="checkPmContracts($event)"
              />
            </v-col>

            <!-- <v-col
              v-if="isMarketingFees"
              class="pl-2 mobile__pl__0 mb-2"
              cols="12"
              sm="6"
            >
              <h6 class="mb-2 font-14">هل تود خصم عمولة التسويق والتأجير؟</h6>
              <v-select
                :items="setCategories"
                :menu-props="{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }"
                placeholder="الرجاء إختيار نعم أو لا"
                append-icon="mdi-chevron-down"
                dense
                filled
                hide-details
                item-text="name"
                item-value="value"
                return-value
                v-model="due.calculateMarketingFees"
                :rules="[(v) => v != null || '']"
                class="font-14"
              />
            </v-col> -->
            <v-col
              class="pl-3 mobile__pl__0"
              md="6"
              cols="12"
              v-if="isClaimRentType"
            >
              <h6 class="mb-2 mt-0">هل تود حساب أتعاب إدارة الأملاك ؟</h6>
              <v-select
                :items="setCategories"
                :menu-props="{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }"
                placeholder="هل تود حساب أتعاب إدارة الأملاك ؟"
                :rules="[(v) => v != null || ``]"
                append-icon="mdi-chevron-down"
                dense
                filled
                hide-details
                item-text="name"
                item-value="value"
                required
                v-model="due.includeCommission"
              />
            </v-col>
            <v-col
              class="pl-3 mobile__pl__0"
              md="6"
              cols="12"
              v-if="recieveOrCutPmFees"
            >
              <h6 class="mb-2 mt-0">
                هل تود إستلام وإستقطاع مبلغ أتعاب إدارة الأملاك ؟
              </h6>
              <v-select
                :items="setCategories"
                :menu-props="{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }"
                :rules="[(v) => v != null || '']"
                placeholder="هل تود إستلام وإستقطاع مبلغ أتعاب إدارة الأملاك ؟"
                append-icon="mdi-chevron-down"
                dense
                filled
                hide-details
                item-text="name"
                item-value="value"
                required
                v-model="due.setCommissionDebt"
              />
            </v-col>
            <v-col
              class="pl-3 mobile__pl__0"
              md="6"
              cols="12"
              v-if="$hasPermission('properties', 'taxInvoices', 'add')"
            >
              <h6 class="mb-2 mt-0">هل تود اصدار فاتوره ضريبيه</h6>
              <v-select
                :items="setCategories"
                :menu-props="{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }"
                :rules="[(v) => v != null || '']"
                placeholder="هل تود اصدار فاتوره ضريبيه"
                append-icon="mdi-chevron-down"
                dense
                filled
                hide-details
                item-text="name"
                item-value="value"
                v-model="due.createTaxBill"
              />
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-card-actions class="pa-4 mt-4">
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 rounded-7 font-14"
          color="grey"
        >
          <span class="font-weight-bold font-14">إلغاء</span>
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          class="ma-0 rounded-7"
          :disabled="!isValidForm"
          @click="confirmHandler(collection)"
        >
          <span class="ml-1 font-weight-bold"> تأكيد</span>
          <v-icon class="send-icon">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import inputMasking from '@/mixins/inputMasking.js'
import { EnvService, contractsService } from '@/services'
import { mapMutations, mapState } from 'vuex'

export default {
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    assignment: {
      default() {
        return {}
      },
      type: Object
    },
    isClaimRentType: {
      default: false,
      type: Boolean
    },
    fromSideDetails: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isValidForm: false,
      havePmContracts: false,
      isMarketingPaid: false,
      due: {
        utilities: 0,
        bondValue: 0,
        user: {
          _id: ''
        }
      },
      startAtModal: false,
      start: new Date().toISOString().substr(0, 10),
      team: [],
      bondValueMask: 0,
      utilitiesMask: 0,
      setCategories: [
        {
          name: 'نعم',
          value: true
        },
        {
          name: 'لا',
          value: false
        }
      ],
      isClaimedInputTypes: [
        'إيجار',
        'غاز',
        'كهرباء',
        'مياه',
        'خدمات عامة',
        'إيجار ومبالغ ثابتة'
      ]
    }
  },
  mixins: [inputMasking],
  watch: {
    dialogState(newValue) {
      if (newValue) {
        this.due = {
          bondValue: 0,
          utilities: 0,
          user: {
            _id: ''
          }
        }

        const utilitiesTotal = this.assignment?.details?.utilitiesTotal
        const rent = this.assignment?.details?.rent
        if (utilitiesTotal) {
          this.formatArabicNumber(utilitiesTotal, 'utilities')
        }
        if (rent) {
          this.formatArabicNumber(rent, 'bondValue')
        }
      }
    }
  },
  created() {
    this.loadEmployees()
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    formattingNumbers(num) {
      return parseFloat(num).toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    },
    async checkPmContracts(val) {
      if (val) {
        this.havePmContracts = this.isMarketingPaid = false
        return
      }
      try {
        const res = await contractsService.checkPmContracts({
          realestateID:
            this.assignment?.unit?._id ?? this.assignment?.realEstate?._id,
          parentID: this.assignment?.unit?.parentId ?? undefined,
          date: this.assignment?.date,
          rentContract: this.assignment?.contract?._id
        })
        this.havePmContracts = res?.data?.contract
        this.isMarketingPaid = res?.data?.marketing
      } catch {
        this.addNotification({
          text: 'خطأ في التحقق من عقود إدارة الأملاك',
          color: 'error'
        })
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    async loadEmployees() {
      try {
        const { data } = await EnvService.getAllEmployee(
          this.currentEnv._id,
          '&registered=1'
        )
        this.team = data.users
        const currentEmp = this.team.find((e) => {
          return e._id === this.user._id
        })
        currentEmp.name += ` (أنا)`
      } catch {
        // this.addNotification({
        //   text: 'حدث خطا',
        //   color: 'error'
        // })
      }
    },
    // TODO:
    formatArabicNumber(val, key) {
      this.handleValuesChanging(
        val,
        key === 'bondValue' ? this.bondValueKeys : this.utilitiesKeys
      )
      setTimeout(() => {
        if (!this.$refs?.[key]?.valid && this.due[key] !== 0) {
          this.addNotification({
            text: 'لايمكن اضافه رقم اكبر من القيمه المطلوبه',
            color: 'error'
          })
        }
      }, 500)
      if (this.$refs?.form) {
        this.$refs.form.validate()
      }
    },
    confirmHandler(collection) {
      this.$emit('addDue', collection)
      this.bondValueMask = 0
      this.utilitiesMask = 0
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    taxAmount() {
      return (this.assignment.tax / 100) * this.due.bondValue
    },
    bondValueKeys() {
      return {
        maskValKey: 'bondValueMask',
        parentKey: 'due',
        actualValKey: 'bondValue'
      }
    },
    utilitiesKeys() {
      return {
        maskValKey: 'utilitiesMask',
        parentKey: 'due',
        actualValKey: 'utilities'
      }
    },
    utilitiesTaxAmount() {
      return (this.due?.utilities * this.assignment?.utilitiesTax) / 100
    },
    recievedAmountRules() {
      return [
        () =>
          !this.due.bondValue
            ? 'من فضلك ادخل قيمة الإيجار'
            : this.due.bondValue + this.due.utilities <= this.assignment.payment
      ]
    },
    fixedAmountRules() {
      return [
        () =>
          !this.due.utilities
            ? true
            : this.due.bondValue + this.due.utilities <= this.assignment.payment
      ]
    },
    isRentCombined() {
      return !!this.assignment?.isCombined
    },
    showIsClaimedInput() {
      return !!this.isClaimedInputTypes.includes(
        this.assignment.installmentType
      )
    },
    recieveOrCutPmFees() {
      return !!(
        this.isClaimRentType &&
        !this.due.isClaimed &&
        this.due.includeCommission
      )
    },
    isMarketingFees() {
      return !!(
        this.havePmContracts &&
        this.due.isClaimed === false &&
        this.isMarketingPaid
      )
    },
    combinedDetailsPayload() {
      const { bondValue, utilities } = this.due
      return {
        rent: bondValue,
        rentTax: this.assignment.tax,
        rentTaxAmount: Number(
          (bondValue * (this.assignment.tax / 100)).toFixed(2)
        ),
        utilities,
        utilitiesTax: this.assignment.tax,
        utilitiesTaxAmount: Number(
          (utilities * (this.assignment.tax / 100)).toFixed(2)
        )
      }
    },
    collectionPayload() {
      const data = {
        ...this.due,
        bondValue: this.due.bondValue + this.due.utilities,
        ...(this.isRentCombined && {
          combinedDetails: this.combinedDetailsPayload
        })
      }

      delete data.utilities
      return data
    },
    collection() {
      return {
        environment: this.currentEnv._id,
        assignment: this.assignment.id,
        collection: this.collectionPayload
      }
    },
    taxValueOfValueReceived() {
      if (!this.due.bondValue) {
        return 0
      }
      return (this.assignment.tax ?? 0 / 100) * this.due.bondValue
    },
    totalAfterTax() {
      return this.taxValueOfValueReceived + this.assignment.tax ?? 0
    },
    remainingAfterTax() {
      const remaining =
        this.assignment.remaining - (this.due.bondValue + this.due.utilities)
      return remaining <= 0
        ? 0
        : remaining.toLocaleString('en-US', {
            maximumFractionDigits: 2
          })
    }
  }
}
</script>
