<template>
  <v-list class="px-4 py-2 ma-0 scroll" v-if="realestates.length">
    <div class="px-0 d-flex align-center">
      <figure>
        <img
          src="../../assets/img/svgs/build.svg"
          alt="Building Icon"
          loading="lazy"
        />
      </figure>
      <!-- Building Icon -->
      <h6 class="primary--text font-12 font-weight-bold mr-2">العقارات</h6>
    </div>

    <div
      :key="i"
      class="realEstatListItem pointerCursor my-3 px-1"
      v-for="(realEstate, i) in realestates"
    >
      <router-link
        :to="`/dashboard/property-details/${realEstate.objectID}`"
        class="d-flex align-center justify-start"
      >
        <img
          :src="realEstate.image"
          alt="property"
          class="border-none rounded-7"
          height="40"
          v-if="realEstate.image"
          width="60"
          loading="lazy"
        />
        <img
          :src="propTempPic"
          alt="property"
          class="border-none"
          height="40"
          v-else
          width="60"
          loading="lazy"
        />
        <div class="mr-2">
          <h6
            class="black--text font-weight-medium font-12 font-weight-bold ma-0"
          >
            {{ realEstate.name ? realEstate.name : 'اسم العقار' }}
          </h6>
          <p class="font-12 ma-0 text-gray">
            {{ realEstate.type }}
          </p>
        </div>
      </router-link>
    </div>
  </v-list>
</template>

<script>
export default {
  name: 'realestate-search-comp',
  props: ['realestates'],
  data() {
    return {
      propTempPic: require('@/assets/img/propTempPic.png')
    }
  }
}
</script>
<style lang="scss" scoped>
.realEstatListItem:hover {
  background: #f9f9f9;
  border-radius: 7px;
}

.border-none {
  border: none !important;
}

.scroll {
  overflow-y: scroll;
}
</style>
