<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex justify-space-between align-center mb-2 mt-6 position-relative"
    >
      <div class="d-flex justify-start flex-wrap">
        <div v-if="!statusRestore" class="d-flex align-center">
          <v-menu
            v-if="smAndUp"
            content-class="vMenuFilter elevation-0"
            offset-y
            transition="slide-y-transition"
            width="150"
            ref="bondsType"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center">
                <v-slide-x-transition>
                  <v-card
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex justify-space-between px-2"
                    width="110"
                  >
                    <h6 class="mr-2" v-if="filterTypeCodes.length">
                      {{ getActiveFilter }}
                    </h6>
                    <h6 class="mr-2" v-else>حالة السند</h6>
                    <v-icon
                      >{{
                        menuStateSwitcher($refs?.bondsType)
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-card>
                </v-slide-x-transition>
              </div>
            </template>
            <v-card width="230">
              <v-row no-gutters align="center" class="pa-3">
                <v-col class="py-0" cols="4">
                  <h6
                    :class="{
                      'brub--tag-border': !filterTypeCodes.length
                    }"
                    @click="resetTypeFilter"
                    class="text-center roundeed-tag px-2 py-1 cursor ml-1"
                  >
                    الكل
                  </h6>
                </v-col>
                <v-col
                  class="py-0"
                  md="4"
                  v-for="type in allBondTypes"
                  :key="type._id"
                >
                  <h6
                    :class="{
                      'brub--tag-border': filterTypeCodes.includes(type.code)
                    }"
                    @click="handleChangeTypeFilter(type)"
                    class="text-center roundeed-tag px-2 py-1 cursor ml-1"
                  >
                    {{ type.nameAr }}
                  </h6>
                </v-col>
                <v-col md="12" class="text-left">
                  <a
                    @click="resetTypeFilter"
                    data-test="rest-filter"
                    class="text-decoration-underline font-12 black--text"
                    >إعادة تعيين</a
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
          <v-slide-x-transition>
            <v-card
              :class="{
                'w-100': sm,
                'mx-0 px-1': !smAndUp,
                'mx-3 px-2': smAndUp
              }"
              class="d-flex justify-space-around xxl__ws__nowrap"
            >
              <h6
                v-for="filter in availableFilters"
                :key="filter.nameAr"
                :class="{ 'border-black': activeFilter == filter.nameEn }"
                @click="handleFilterChange(filter)"
                class="cursor mx-3"
                ref="filterBtn"
              >
                {{ filter.nameAr }}
                <span class="propCounter px-2 font-12">
                  {{ filter.count }}
                </span>
              </h6>
            </v-card>
          </v-slide-x-transition>

          <!-- For multiple selections -->
          <slot />
        </div>

        <div
          class="mr-1 d-flex"
          v-if="!mdAndDown && !statusRestore && !isCustomer"
        >
          <span @click="handleLoadData('isClientsCalled', 'loadClients')">
            <autocomplete
              class="contract__page__dropdown"
              ref="clientsFilter"
              style="width: 175px"
              v-bind="clientProps"
              @nextPageTrigger="getClientNextData"
              @emitSearchKey="getClientDataBySearchKey"
              @input="clientChanged"
            />
          </span>
          <span @click="handleLoadData('isPropertiesCalled', 'loadProperties')">
            <autocomplete
              ref="realEstatesFilter"
              class="mr-3 contract__page__dropdown"
              v-bind="propertiesProps"
              style="width: 175px"
              @nextPageTrigger="getPropertyNextData"
              @emitSearchKey="getPropertyDataBySearchKey"
              @input="realEstateChanged"
            />
          </span>
        </div>
      </div>

      <div class="d-flex align-center" v-if="!sm">
        <h6 class="font-weight-light ml-2">ترتيب بواسطة</h6>

        <v-menu
          content-class="vMenuFilter elevation-0"
          offset-y
          transition="slide-y-transition"
          width="150"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center">
              <v-slide-x-transition>
                <v-card
                  ref="sortListActivator"
                  v-on="on"
                  v-bind="attrs"
                  class="d-flex justify-space-between px-2"
                  width="110"
                >
                  <h6 class="mr-2">
                    {{ currentSort }}
                  </h6>
                  <v-icon
                    >{{
                      showSortOptions ? 'mdi-chevron-up' : 'mdi-chevron-down'
                    }}
                  </v-icon>
                </v-card>
              </v-slide-x-transition>
            </div>
          </template>
          <v-list
            class="contractVmenuList pa-0"
            v-for="(option, i) in sortOptions"
            :key="i"
            :width="110"
          >
            <v-list-item-group>
              <v-list-item class="px-1">
                <v-list-item-title
                  ref="sortOption"
                  @click="handleSortChange(option.nameEn)"
                  class="font-14 mr-1 mb-2 font-weight-medium t"
                >
                  {{ option.nameAr }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-slide-x-transition>
          <v-card
            width="72"
            height="40"
            class="d-flex justify-space-between px-2 mr-3"
          >
            <img
              class="cursor"
              :src="gridView ? colIconColored : colIcon"
              width="20"
              @click="$emit('gridView', true)"
              loading="lazy"
            />
            <img
              class="cursor"
              :src="gridView ? rowIcon : rowIconColored"
              width="20"
              @click="$emit('gridView', false)"
              loading="lazy"
            />
          </v-card>
        </v-slide-x-transition>
      </div>
    </div>
    <v-card
      width="100"
      v-if="showSortOptions"
      class="position-absolute z-index-3 mt-0 pa-3 left"
    >
      <div
        v-for="(option, i) in sortOptions"
        :key="i"
        class="d-flex align-center"
      >
        <h6 class="ma-0 mb-1 cursor" @click="handleSortChange(option)">
          {{ option.nameAr }}
        </h6>
      </div>
    </v-card>
  </div>
</template>

<script>
import { EnvService, realEstateService } from '@/services'
import autocomplete from '@/components/forms/autocomplete'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'bonds-filters',
  components: {
    autocomplete
  },
  props: {
    availableFilters: null,
    sortOptions: null,
    gridView: {
      default: false
    }
  },
  data() {
    return {
      allBondTypes: [
        {
          nameAr: 'ساري',
          code: 'valid',
          nameEn: 'valid',
          _id: 1
        },
        {
          nameAr: 'ملغي',
          code: 'invalid',
          nameEn: 'invalid',
          _id: 2
        }
      ],
      colIconColored: require('@/assets/img/svgs/colIconColored.svg'),
      colIcon: require('@/assets/img/svgs/colIcon.svg'),
      rowIcon: require('@/assets/img/svgs/rowIcon.svg'),
      rowIconColored: require('@/assets/img/svgs/rowIconColored.svg'),
      activeFilter: 'count',
      showFilterOptions: false,
      showSortOptions: false,
      filterQuery: {},
      isClientsCalled: false,
      clientSearchKey: '',
      clients: [],
      allClients: [],
      client: {},
      noData: 'لا يوجد عملاء متاحه لهذا البحث',
      selectedClientPlaceholder: 'العملاء',
      isClientsLoading: false,
      paginationClient: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      isPropertiesCalled: false,
      propertySearchKey: '',
      properties: [],
      allProperties: [],
      property: {},
      noProperties: 'لا توجد عقارات متاحه لهذا البحث',
      selectedPropertyPlaceholder: 'العقارات',
      isPropertiesLoading: false,
      paginationProperty: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      filterTypeCodes: []
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    ...mapGetters('appState', ['getClientForRoute', 'getClientTypeForRoute']),
    getActiveFilter() {
      const activeFilter = this.allBondTypes.find(
        (T) => T.code === this.filterTypeCodes[0]
      )
      return activeFilter.nameAr
    },
    clientProps() {
      return {
        itemData: true,
        items: this.allClients,
        noData: this.noData,
        noData: this.isClientsLoading ? 'جاري البحث عن عملاء...' : this.noData,
        placeholder: this.selectedClientPlaceholder,
        returnObject: true,
        hideDetails: true,
        rules: [true],
        loading: this.isClientsLoading,
        value: this.client
      }
    },
    propertiesProps() {
      return {
        itemData: true,
        itemText: 'propertyName',
        items: this.allProperties,
        noData: this.isPropertiesLoading
          ? 'جاري البحث عن عقارات...'
          : this.noProperties,
        placeholder: this.selectedPropertyPlaceholder,
        returnObject: true,
        hideDetails: true,
        rules: [true],
        loading: this.isPropertiesLoading,
        value: this.property
      }
    },
    md() {
      return this.$vuetify.breakpoint.md
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    smAndUp() {
      return this.$vuetify.breakpoint.smAndUp
    },
    currentSort() {
      return (
        this.sortOptions.find((sort) => sort.nameEn === this.filterQuery.sort)
          ?.nameAr || 'الأحدث'
      )
    },
    statusRestore() {
      return this.$parent.statusRestore
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    menuStateSwitcher(menu) {
      return menu?.isActive
    },
    resetTypeFilter() {
      if (!this.filterQuery.hasOwnProperty('terminated')) {
        return
      }
      this.filterTypeCodes = []
      delete this.filterQuery.terminated
      this.$emit('handleFilters', this.filterQuery)
    },
    handleChangeTypeFilter({ code }) {
      const isItSelected = this.filterTypeCodes.find((item) => item === code)
      if (isItSelected) {
        this.filterTypeCodes = this.filterTypeCodes.filter(
          (item) => item !== code
        )
        this.filterQuery.statuses = [...this.filterTypeCodes]
        if (!this.filterTypeCodes.length) {
          this.resetTypeFilter()
          return
        }
      } else {
        switch (code) {
          case 'invalid':
            this.filterQuery.terminated = true
            break
          case 'valid':
            this.filterQuery.terminated = false
            break
          default:
            delete this.filterQuery.terminated
        }
        this.filterTypeCodes = [code]
      }
      this.$emit('handleFilters', this.filterQuery)
    },
    handleLoadData(isCalled, methodToBeCalled) {
      // to call data for only first time
      if (!this[`${isCalled}`]) {
        this[`${methodToBeCalled}`]()
        this[`${isCalled}`] = !this[`${isCalled}`]
      }
    },
    setOutSideOrders() {
      this.showSortOptions = false
    },
    setOutSidetypes() {
      this.showFilterOptions = false
    },
    handleFilterChange(filter) {
      if (!filter._id) {
        this.filterQuery = {
          ...this.filterQuery,
          types: null
        }
      } else {
        this.filterQuery = {
          ...this.filterQuery,
          types: [filter?._id]
        }
      }
      this.activeFilter = filter.nameEn
      this.$emit('handleFilters', this.filterQuery)
    },
    handleSortChange(sort) {
      if (sort === this.filterQuery.sort) {
        return
      }
      this.filterQuery = {
        ...this.filterQuery,
        sort
      }
      this.$emit('handleFilters', this.filterQuery)
    },
    async loadClients(query = '') {
      this.isClientsLoading = true
      try {
        let data = ''
        if (this.clientSearchKey) {
          data += `&search=${this.clientSearchKey}`
        } else {
          data += `pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}`
        }
        const res = await EnvService.getAllClients(
          this.currentEnv._id,
          query,
          data
        )
        this.paginationClient.pagesCount = Math.ceil(
          res?.data?.count / this.paginationClient.pageSize
        )
        this.paginationClient.count = res?.data?.count ?? 0
        this.clients = res?.data?.users ?? []
        this.allClients = [...this.allClients, ...this.clients]

        // Filter by client
        if (this.getClientTypeForRoute !== '' && this.$route.query.id) {
          this.allClients.push(this.getClientForRoute)
          this.clientChanged(this.getClientForRoute)
        }
      } catch {
        this.addNotification({
          text: 'خدث خطأ أثناء تحميل العملاء',
          color: 'error'
        })
      } finally {
        this.isClientsLoading = false
      }
    },
    getClientNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.loadClients()
      }
    },
    getClientDataBySearchKey(e) {
      if (e) {
        this.clientSearchKey = e
        this.loadClients()
      }
    },
    async loadProperties() {
      this.isPropertiesLoading = true
      try {
        let data = ''
        if (this.propertySearchKey) {
          data += `&search=${this.propertySearchKey}`
        } else {
          data += `pageSize=${this.paginationProperty.pageSize}&pageNumber=${this.paginationProperty.pageNumber}`
        }
        const filters = {
          account: this.currentEnv?.owner?.id
        }
        if (this.client?._id) {
          filters.user = this.client._id
        }
        const res = await realEstateService.getRealEstates(filters, data)
        this.paginationProperty.pagesCount = Math.ceil(
          res?.data?.count / this.paginationProperty.pageSize
        )
        this.paginationProperty.count = res?.data?.count ?? 0
        this.properties = res?.data?.realEstates ?? []
        this.allProperties = [...this.allProperties, ...this.properties]
        this.isPropertiesLoading = false
      } catch {
        this.addNotification({
          text: 'خدث خطأ أثناء تحميل العقارات',
          color: 'error'
        })
      } finally {
        this.isPropertiesLoading = false
      }
    },
    getPropertyNextData() {
      if (
        this.paginationProperty.pageNumber < this.paginationProperty.pagesCount
      ) {
        this.paginationProperty.pageNumber += 1
        this.loadProperties()
      }
    },
    getPropertyDataBySearchKey(e) {
      if (e) {
        this.propertySearchKey = e
        this.loadProperties()
      }
    },
    clientChanged(client) {
      this.client = client
      const clientFiltering = {
        client: client?._id ?? null,
        realEstate: null
      }
      this.filterQuery = {
        ...this.filterQuery,
        ...clientFiltering
      }
      this.$emit('handleFilters', this.filterQuery)
      this.paginationProperty.pageNumber = 1
      this.allProperties = []
      if (this.property?._id) {
        this.property = {}
      }
      this.loadProperties()
    },
    realEstateChanged(realEstate) {
      this.property = realEstate
      this.filterQuery = {
        ...this.filterQuery,
        realEstate: realEstate?._id ?? null
      }
      this.$emit('handleFilters', this.filterQuery)
    }
  }
}
</script>
