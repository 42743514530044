<template>
  <v-toolbar class="page-toolbar v-card">
    <router-link
      v-if="innerUnit && !isCustomer"
      :to="`/dashboard/${controalOfBackRoute}`"
      class="ml-2 mb-1"
    >
      <v-icon size="25">mdi-arrow-right</v-icon>
    </router-link>

    <h5 :class="setRoleLabelStyle(role)" v-if="showMenu">
      {{ formatUserRole(role) }}
    </h5>
    <v-menu
      v-else
      offset-y
      content-class="clientsType py-3 px-5 mt-2"
      transition="slide-y-transition"
    >
      <template #activator="{ on, attrs }">
        <h5
          @click="cheveronState = !cheveronState"
          v-bind="attrs"
          v-on="on"
          :class="setRoleLabelStyle(role)"
          v-if="toolbarInfoEnv && client"
        >
          {{ formatUserRole(role) }}
          <span class="mr-3">
            <v-icon size="15" v-if="!cheveronState">mdi-chevron-down</v-icon>
            <v-icon size="15" v-else>mdi-chevron-up</v-icon>
          </span>
        </h5>
      </template>
      <div class="clientTypes">
        <span
          class="mb-1 d-block"
          v-for="(r, i) in toolbarInfoRole"
          :key="i"
          :class="setRolesStyle(r)"
        >
          <span v-if="formatUserRole(r) != formatUserRole(role)">{{
            formatUserRole(r)
          }}</span>
        </span>
        <span class="mb-1 brub--flag d-flex" @click="$emit('openAddTagModel')">
          <span> اضافة صفة </span>
          <v-icon size="15" class="circle mt-2 mr-1" color="primary">
            mdi-plus
          </v-icon>
        </span>
      </div>
    </v-menu>
    <div class="phone-number mr-3" v-if="client">
      <img :src="phoneIcon" alt="" class="" />
      <span class="tf mr-1 text-right font-weight-medium font-16 pb-2"
        >{{ toolbarInfo.phoneNumber }}
      </span>
    </div>
    <h5
      :class="setStatusLabelStyle(toolbarInfoNameAR)"
      class="font-16 ml-3"
      v-if="!client"
    >
      {{ toolbarInfoNameAR }}
    </h5>
    <div class="d-flex align-center" v-if="!client && getBreakPoint['mdAndUp']">
      <img :src="location" alt="loation" class="ml-2" />
      <h5
        class="ma-0 mb-1 text-truncate font-weight-medium text--darken-3 font-16 addressMaxWidth"
      >
        {{ toolbarInfoAddress }}
      </h5>
    </div>
    <v-spacer></v-spacer>
    <v-btn
      v-if="showCompleteUnitData"
      @click="handleFunctionCall(fillUnitData.method)"
      small
      class="roundedBorder py-4"
      depressed
    >
      <span class="text-success font-weight-bold">{{ fillUnitData.name }}</span>
    </v-btn>
    <div
      v-if="!client && !innerUnit"
      class="d-flex justify-between align-center"
    >
      <template v-for="btn in actions">
        <div v-if="checkPermession(btn)" :key="btn.title">
          <div v-if="btn.isSwitchBtn">
            <v-tooltip
              content-class="v-tool"
              color="black"
              min-width="95"
              bottom
              :disabled="getDataKeyVal(btn.disabled)"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="d-flex my-auto cursor">
                  <p class="text--737373 font-14 font-weight-bold my-auto mx-2">
                    {{ btn.name }}
                  </p>
                  <v-switch
                    class="my-auto"
                    dense
                    hide-details="auto"
                    inset
                    small
                    @change="handleFunctionCall(btn.method)"
                    v-model="actionKeys[`${btn.value}`]"
                    :disabled="!getDataKeyVal(btn.disabled)"
                  />
                </div>
              </template>
              <span class="tool--tip">
                {{ btn.toolTip }}
              </span>
            </v-tooltip>
          </div>
          <v-btn
            v-else-if="!getBreakPoint['mdAndDown']"
            :color="btn.color"
            @click="handleFunctionCall(btn.method)"
            class="mx-1 px-6 py-4 showHideBtn"
            depressed
            small
            :data-test="actions.dataTest"
          >
            <v-img :src="btn.img"></v-img>
            <span class="mr-1">{{ btn?.name?.[`${role}`] || btn.name }}</span>
          </v-btn>
        </div>
      </template>
    </div>

    <div v-if="client">
      <!-- Open modal for send invitation to client -->
      <v-btn
        v-if="invitationPermission && getBreakPoint['mdAndUp']"
        @click="openModelInviteClient"
        class="elevation-0 rounded-7"
        color="primary"
      >
        <span class="font-14 font-weight-bold mb-1">
          دعوة العميل لبوابة العملاء
        </span>
      </v-btn>

      <!-- Is registered or invited -->
      <v-btn
        v-if="invitedOrAccepted && getBreakPoint['mdAndUp']"
        class="elevation-0"
        rounded
        style="pointer-events: none"
      >
        <span
          class="brub--flag font-14 font-weight-bold mb-1"
          v-text="statusTitleInviteClient"
        />
        <v-img
          v-if="isRegistered"
          width="20px"
          class="ml-1 mr-2"
          :src="checkPrimary"
        />
      </v-btn>

      <v-img
        v-if="!getBreakPoint['mdAndUp'] && (isRegistered || isInvited)"
        width="20px"
        class="ml-1 mr-2"
        :src="checkPrimary"
      />
    </div>

    <v-menu
      content-class="dropdown-menu"
      transition="slide-x-transition"
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class="mx-0 px-0 options-btn dots-btn"
          icon
          v-bind="attrs"
          v-on="on"
          v-if="showMdiDots"
          data-test="menuDotsBtn"
        >
          <v-icon class="px-0 mx-0" color="#737373">mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list class="pa-4">
        <div
          v-if="
            !client && ((getBreakPoint['mdAndDown'] && !innerUnit) || innerUnit)
          "
        >
          <template v-for="btn in actions">
            <v-list-item
              v-if="checkPermession(btn) && !btn.isSwitchBtn"
              :key="btn.title"
              @click="handleFunctionCall(btn.method)"
              :data-test="actions.dataTest"
            >
              <v-list-item-icon>
                <img
                  v-if="btn.method != 'sendNotifaction'"
                  :src="btn.imgResponsive"
                  class="mr-1 actions-responsive-icons"
                />
                <v-icon color="grey" v-else>mdi-share</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ btn?.name?.[`${role}`] || btn.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>

        <v-list-item
          @click="handleFunctionCall(fillUnitData.method)"
          v-if="innerUnit && getBreakPoint['mdAndDown']"
        >
          <v-list-item-icon>
            <img
              width="16"
              :src="fillUnitData.img"
              class="mr-1 actions-responsive-icons"
            />
          </v-list-item-icon>
          <v-list-item-content class="mr-2">
            <v-list-item-title class="black--text">{{
              fillUnitData.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <div
          v-if="!client && $hasPermission('realestates', 'realestates', 'add')"
        >
          <v-list-item @click="handleFunctionCall(showHideHectarAction.method)">
            <v-list-item-icon>
              <img
                class="mr-1 actions-responsive-icons"
                :src="showHideHectarAction.icon"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                showHideHectarAction.name
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <template v-for="action in responsiveActions">
            <v-list-item
              v-if="checkPermession(action)"
              :key="action.title"
              @click="handleFunctionCall(action.method)"
              :data-test="actions.dataTest"
            >
              <v-list-item-icon>
                <img class="mr-1 actions-responsive-icons" :src="action.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ action.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-if="$hasPermission('realestates', 'realestates', 'delete')"
            @click="deleteConfirm(toolbarInfo._id)"
          >
            <v-list-item-icon>
              <v-img class="mt-2" :src="deleteIcon"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="danger--text"
                >حذف العقار</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </div>

        <div v-else-if="client">
          <v-list-item @click="handleFunctionCall('setCall')">
            <v-list-item-icon>
              <v-img class="mt-2" :src="callIcon"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>إتصال</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="invitationPermission && !getBreakPoint['mdAndUp']"
            @click="openModelInviteClient"
          >
            <v-list-item-icon>
              <img
                width="18px"
                class="mr-1 actions-responsive-icons"
                :src="inviteClient"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                دعوة العميل لبوابة العملاء
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template v-for="action in actionsFiltered">
            <v-list-item
              v-if="checkPermession(action)"
              :key="action.title"
              @click="handleFunctionCall(action.method)"
              :data-test="`${action.dataTest}`"
            >
              <v-list-item-icon>
                <img
                  class="mr-1 actions-responsive-icons"
                  :src="action.img"
                  loading="lazy"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  action?.name?.[`${role}`] || action.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            v-if="$hasPermission('users', 'clients', 'delete')"
            data-test="delete-client"
            @click="deleteItem(toolbarInfo._id)"
          >
            <v-list-item-icon>
              <v-img class="mt-2" :src="deleteIcon"></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="danger--text"
                >حذف العميل</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import { MultipleDeletion } from '@/services'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { messageService } from '@/services'

export default {
  name: 'ClientDetailsToolbar',
  data: () => ({
    actionKeys: {
      notificationStatus: false
    },
    isGlobalNotification: false,
    cancelIcon: require('@/assets/img/svgs/cancel.svg'),
    phoneIcon: require('@/assets/img/svgs/phone.svg'),
    location: require('@/assets/img/icons/location.png'),
    deleteIcon: require('@/assets/img/svgs/messages/delete-conversation.svg'),
    callIcon: require('@/assets/img/svgs/purple-notification.svg'),
    checkPrimary: require('@/assets/img/svgs/check-purple.svg'),
    inviteClient: require('@/assets/img/svgs/invite-client.svg'),
    cheveronState: true,
    fillUnitData: {
      name: 'معلومات الوحده',
      color: 'green',
      img: require('@/assets/img/svgs/house-b.svg'),
      method: 'completeUnitData'
    }
  }),
  props: {
    toolbarInfo: {},
    client: { default: false },
    actions: {},
    role: {},
    contractsResponse: { default: 0 },
    realEstatesResponse: { default: 0 },
    menuState: { default: false },
    responsiveActions: {
      type: Array
    },
    statusInviteClient: {
      type: Object,
      default: () => {}
    },
    showHideHectarAction: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    ...mapGetters('accountState', ['isCustomer']),
    previousPage() {
      return this.toolbarInfo?.parentId
        ? `/dashboard/property-details/${this.toolbarInfo?.parentId}`
        : '/dashboard/properties/0'
    },
    controalOfBackRoute() {
      const isOwner = this.toolbarInfo.parent.owner._id === this.user._id
      const isCustomer = this.isCustomer

      if (isOwner && isCustomer) {
        return `property-details/${this.toolbarInfo.parent.owner._id}`
      }

      if (!isOwner && isCustomer) {
        return 'properties/0'
      }

      return `property-details/${this.toolbarInfo.parentId}`
    },
    invitationPermission() {
      return this.forOpenInviteClientModel && this.$checkBundleModules('team')
    },
    dataDialog() {
      return {
        title: 'حذف عميل',
        message: 'هل أنت متأكد من حذف بيانات هذا العميل؟',
        options: {
          color: 'red'
        }
      }
    },
    showMdiDots() {
      return (
        (!this.getBreakPoint.lg &&
          !this.$hasPermission('realestates', 'realestates', 'add')) ||
        this.$hasPermission('realestates', 'realestates', 'add')
      )
    },
    showCompleteUnitData() {
      return (
        this.innerUnit &&
        this.getBreakPoint.lgAndUp &&
        this.$hasPermission('realestates', 'realestates', 'add')
      )
    },
    realestateCompletePercentage() {
      return this.toolbarInfo?.completePercentage || 0
    },
    getBreakPoint() {
      return this.$vuetify.breakpoint || {}
    },
    showMenu() {
      return this.toolbarInfo?.environments && this.client && !this.menuState
    },
    innerUnit() {
      return !!this.toolbarInfo?.parentId
    },
    parentUnit() {
      return !this.innerUnit && !this.client
    },
    toolbarInfoRole() {
      return this.toolbarInfo?.role ?? []
    },
    toolbarInfoNameAR() {
      return this.toolbarInfo?.status?.nameAr ?? ''
    },
    toolbarInfoAddress() {
      return this.toolbarInfo?.propertyAddress?.address ?? ''
    },
    toolbarInfoEnv() {
      return this.toolbarInfo?.environments ?? ''
    },
    actionsFiltered() {
      const filteredActions = this.actions.filter((action) => {
        return !(action.method === 'openRealEstateHectarLink' && this.isRented)
      })
      return filteredActions
    },
    isInvited() {
      return this.statusInviteClient?.isInvited
    },
    isRegistered() {
      return this.statusInviteClient?.isRegistered
    },
    forOpenInviteClientModel() {
      return (
        this.statusInviteClient &&
        Object.keys(this.statusInviteClient).length !== 0 &&
        !this.isRegistered &&
        !this.isInvited
      )
    },
    invitedOrAccepted() {
      return this.isRegistered || this.isInvited
    },
    statusTitleInviteClient() {
      return this.isInvited && !this.isRegistered
        ? 'تم إرسال الدعوة'
        : 'مشترك في بوابة العملاء'
    }
  },
  created() {
    if (this.parentUnit) {
      this.getNotificationStatus()
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getDataKeyVal(key) {
      return !!this[key]
    },
    async popUpConfirm(data) {
      return await this.$root.$confirm.open(data)
    },
    checkPermession(btn) {
      if (btn.isSwitchBtn) {
        return this.$hasPermission('users', 'notificationsSettings', 'update')
      } else if (btn.needPermession) {
        return this.$hasPermission('properties', btn.title, 'add')
      }
      return true
    },
    setRoleLabelStyle(role) {
      let style = 'ma-0 tag text-center pb-1 '
      switch (role) {
        case 'renter':
          style += 'brub--tag'
          break
        case 'owner':
          style += 'orange--tag'
          break
        case 'buyer':
          style += 'green--tag'
          break

        case 'lead-buyer':
          style += 'blue--tag'
          break
        case 'lead-renter':
          style += 'red--tag'
          break

        default:
          style += 'gery--tag'
          break
      }
      return style
    },
    setRolesStyle(role) {
      let style = 'ma-0 tag text-right '
      switch (role) {
        case 'renter':
          style += 'lightGreen--flag'
          break
        case 'owner':
          style += 'brub--flag'
          break
        case 'buyer':
          style += 'orange--flag'
          break
        case 'lead-renter':
          style += 'lightenBlue--flag'
          break
        default:
          style += 'brub--flag'
          break
      }
      return style
    },
    setStatusLabelStyle(status) {
      let style = 'ma-0 tag text-center pb-1 '
      switch (status) {
        case 'للبيع':
          style += 'brub--tag'
          break
        case 'مُباع':
          style += 'orange--tag'
          break
        case 'مؤجر':
          style += 'green--tag'
          break

        default:
          style += 'blue--tag'
          break
      }
      return style
    },
    formatUserRole(role) {
      // get the config options
      const clientTypes = JSON.parse(
        localStorage.getItem('configrations')
      ).clientTypes
      const clientType = clientTypes.find((c) => {
        return c.code === role
      })

      return clientType?.nameAr
    },
    handleFunctionCall(functionName) {
      this[functionName]()
    },
    deleteConfirm(id) {
      this.$root.$confirm
        .open({
          title: 'حذف العقار',
          message: 'هل أنت متأكد من حذف العقار، العملية لا يمكن استرجاعها',
          options: {
            color: 'danger',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            this.delProperty(id)
          }
        })
    },
    async delProperty(id) {
      const body = {
        ids: [id],
        environment: this.currentEnv._id
      }
      try {
        await MultipleDeletion.deleteRealestate(body)
        this.addNotification({
          text: `تم حذف العقار بنجاح`,
          color: 'success'
        })
        this.$router.push(this.previousPage)
      } catch (err) {
        const errorMessage = err.response?.data?.message
        const isArabicMessage = /[\u0600-\u06FF]/g.test(errorMessage)
        this.addNotification({
          text: isArabicMessage
            ? errorMessage
            : 'حدث خطأ اثناء حذف العقارات، يرجى المحاوله مجددا',
          color: 'error'
        })
      }
    },
    async deleteItem(clientId) {
      if (!this.$hasPermission('users', 'clients', 'delete')) {
        this.addNotification({
          text: `ليس لديك صلاحيه لحذف العملاء`,
          color: 'error'
        })
        return
      }

      try {
        const confirm = await this.popUpConfirm(this.dataDialog)

        if (confirm) {
          if (this.realEstatesResponse) {
            this.addNotification({
              text: `لا يمكنك حذف العميل (${this.toolbarInfo.name}), يجب حذف عقاراته أولا`,
              color: 'danger'
            })
          } else {
            try {
              const body = { ids: [clientId] }
              await MultipleDeletion.deleteClients(this.currentEnv._id, body)
              this.$router.go(-1)
            } catch {
              this.addNotification({
                text: 'تعذر حذف العميل, حاول في وقت لاحق',
                color: 'error'
              })
            }
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    ShowOnHectar() {
      this.$root.$confirm
        .open({
          title: 'اظهار في هكتار ',
          message: 'هل تريد اظهار العقار في هكتار',
          options: {
            color: 'primary',
            width: 400,
            zIndex: 200
          }
        })
        .then(() => {})
    },
    sendNotifaction() {
      this.addNotification({
        text: ' تم ارسال الاشعار بنجاح',
        color: 'success'
      })
    },
    addNewUnit() {
      this.$emit('addNewUnit')
    },
    completeUnitData() {
      this.$emit('completeUnitData')
    },
    showAddBondModal() {
      this.$parent.openAddBondModal()
    },
    showActivityLogs() {
      this.$parent.openActivityLogsModal()
    },
    async toggleNotificationStatus() {
      try {
        await messageService.PutRealestateNotificationStatus({
          realEstate: this.toolbarInfo?._id,
          active: this.actionKeys.notificationStatus
        })
        this.addNotification({
          text: `تم ${
            this.actionKeys.notificationStatus ? 'تفعيل' : 'تعطيل'
          } اشعارات العقار`,
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: ' لم نتمكن من تعديل حاله الاشعارات للعقار',
          color: 'error'
        })
        this.actionKeys.notificationStatus = !this.actionKeys.notificationStatus
      }
    },
    async getNotificationStatus() {
      try {
        const { data } = await messageService.realestateNotificationStatus(
          this.toolbarInfo?._id
        )
        this.isGlobalNotification = data.isGlobal
        this.actionKeys.notificationStatus = data.isGlobal
          ? data.muteNotification?.active
          : data.isGlobal
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل حاله الاشعارات للعقار',
          color: 'error'
        })
      }
    },
    showAddContractModal() {
      this.$parent.openAddContractModal()
    },
    changeShowInHectar() {
      this.$emit('change-show-hectar')
    },
    openRealEstateHectarLink() {
      const hectarLink = window.location.origin.replace('plus', '')
      window
        .open(`${hectarLink}/property/${this.toolbarInfo._id}/0`, '_blank')
        .focus()
    },
    setCall() {
      window.open(`tel:${this.toolbarInfo.phoneNumber}`)
    },
    openModelInviteClient() {
      this.$emit('openModelInviteClient')
    },
    showSendMessageModal() {
      this.$parent.openSendMessageModal()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.page-toolbar {
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.03);
}

.clientsType {
  width: 174px;
  background: #fff;
  border: 1px solid #efebf2;
  box-shadow: 2px 2px 60px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  right: 20px;

  div.clientTypes {
    span {
      font-size: 12px;
    }
  }
}

.circle {
  width: 14px;
  height: 14px;
  background: rgba($primary-color, 0.1);
  -moz-border-radius: 70px;
  -webkit-border-radius: 70px;
  border-radius: 70px;
}

.addressMaxWidth {
  max-width: 450px;
}

.actions-responsive-icons {
  margin-top: 15px;
}

.dots-btn {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
}

.roundedBorder {
  border: 2px solid #b1e4d4;
  background: #fff !important;
}
</style>
