<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex justify-space-between align-center mb-2 mt-6 position-relative"
    >
      <div class="d-flex justify-start flex-wrap gap-10px">
        <div class="d-flex align-center">
          <div v-if="!statusRestore" class="d-flex align-center">
            <v-slide-x-transition v-if="!sm">
              <v-card
                width="105"
                height="40"
                class="d-flex justify-space-between px-2 cursor xxl__ws__nowrap align-center"
                v-click-outside="setOutSidetypes"
                @click="toggleFilterTypes"
              >
                <h6 class="font-14">نوع العقار</h6>
                <v-icon class="mt-1">{{
                  showFilterTypes ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-card>
            </v-slide-x-transition>

            <v-slide-x-transition v-if="!isCustomer && !sm">
              <v-card
                class="d-flex justify-space-between px-2 mx-3 xxl__ws__nowrap"
                height="40"
              >
                <h6
                  :class="{
                    'border-black': filterStatusId === status._id
                  }"
                  :data-test="`filter-${status.nameEn}`"
                  @click="filterStatus(status._id)"
                  class="cursor mx-3"
                  v-for="status in availableFilters"
                  :key="status._id"
                >
                  {{ status.nameAr }}
                  <span class="propCounter px-2 font-12">
                    {{ status.count }}
                  </span>
                </h6>
              </v-card>
            </v-slide-x-transition>
          </div>

          <div class="max-width-220 mx-2">
            <v-text-field
              height="40"
              dense
              filled
              placeholder="ابحث بإسم العقار أو الوحدة"
              :hide-details="true"
              :value="search"
              @input="searchForRealEstates"
            />
          </div>

          <v-card
            v-if="haveContractsBtn"
            class="d-flex align-center justify-space-between px-2 xxl__ws__nowrap mr-1"
            width="130"
            height="40px"
          >
            <h6 class="mx-1">لديه عقود</h6>
            <v-switch
              class="mx-auto mb-auto client__filter__switch contracts-switch"
              :value="showHasContract"
              @change="changeShowHasContract"
              inset
              dense
              small
              hide-details="auto"
            ></v-switch>
          </v-card>
        </div>
      </div>
      <v-slide-x-transition>
        <v-card
          width="72"
          height="40"
          class="d-flex justify-space-between px-2 mr-3 align-center"
        >
          <div
            class="d-flex justify-center align-center grid-view"
            :class="{ 'selected-grid-view': !gridView }"
          >
            <img
              class="cursor"
              :src="gridView ? rowIcon : rowIconColored"
              width="14"
              @click="setGridView(false)"
              loading="lazy"
            />
          </div>
          <div
            class="d-flex justify-center align-center grid-view"
            :class="{ 'selected-grid-view': gridView }"
          >
            <img
              class="cursor"
              :src="gridView ? colIconColored : colIcon"
              width="14"
              @click="setGridView(true)"
              loading="lazy"
            />
          </div>
        </v-card>
      </v-slide-x-transition>
    </div>

    <div class="d-flex align-center mt-3 cards--multiple--selections">
      <!-- For multiple selections -->
      <slot />
      <!-- INFO: was for the export data befour remove it  -->
    </div>
    <v-card
      width="230"
      v-if="showFilterTypes"
      class="position-absolute z-index-3 pt-0 mt-0"
    >
      <v-row no-gutters align="center" class="pa-3">
        <v-col
          class="py-0"
          md="4"
          v-for="type in configs.realestateTypes"
          :key="type._id"
        >
          <h6
            :class="{ 'brub--tag-border': filterTypesIds.includes(type._id) }"
            @click="filterTypes(type._id)"
            :data-test="`filter-${type.nameEn}`"
            class="text-center roundeed-tag px-2 py-1 cursor ml-1"
          >
            {{ type.nameAr }}
          </h6>
        </v-col>
        <v-col md="12" class="text-left">
          <a
            @click="resetFilterTypes"
            data-test="rest-filter"
            class="text-decoration-underline font-12 black--text"
            >إعادة تعيين</a
          >
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { debounce } from '../../helpers/debounce'

export default {
  name: 'PropertyFilter',
  props: {
    availableFilters: {
      type: Array,
      default() {
        return []
      }
    },
    showHasContract: {
      type: Boolean,
      default: false
    },
    statusRestore: {
      type: Boolean,
      default: false
    },
    gridView: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showFilterTypes: false,
      filterStatusId: '',
      filterTypesIds: [],
      headersArr: [
        {
          text: 'اسم العقار',
          value: 'propertyName',
          align: 'center'
        },
        {
          text: 'نوع العقار',
          align: 'center',
          sortable: false,
          value: 'type'
        },
        {
          text: 'المساحه',
          value: 'space',
          align: 'center'
        },
        {
          text: 'السعر',
          value: 'price',
          align: 'center'
        },
        {
          text: 'الحاله',
          value: 'status',
          width: 70
        },
        {
          text: 'المالك',
          value: 'owner.name',
          align: 'center'
        },
        {
          text: 'المسؤل',
          value: 'moderator',
          align: 'center'
        },
        {
          text: 'المستأجر',
          value: 'tenet.name',
          align: 'center'
        }
      ],
      types: [
        'اسم العقار',
        'نوع العقار',
        'المساحه',
        'السعر',
        'الحاله',
        'المالك',
        'المسؤل',
        'المستأجر'
      ],
      checkedHeaders: [
        'اسم العقار',
        'نوع العقار',
        'المساحه',
        'السعر',
        'الحاله',
        'المالك',
        'المسؤل',
        'المستأجر'
      ],
      // Component icons
      colIconColored: require('@/assets/img/svgs/colIconColored.svg'),
      colIcon: require('@/assets/img/svgs/colIcon.svg'),
      rowIcon: require('@/assets/img/svgs/rowIcon.svg'),
      rowIconColored: require('@/assets/img/svgs/rowIconColored.svg'),
      search: '',
      upload: require('@/assets/img/export-data/upload-grey.svg')
    }
  },
  computed: {
    ...mapState('accountState', [
      'user',
      'configs',
      'currentEnv',
      'customerDefaultRole'
    ]),
    ...mapGetters('accountState', ['isCustomer']),
    haveContractsBtn() {
      return this.isCustomer ? false : !this.statusRestore
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    },
    realEstateStatuses() {
      return (
        this.configs.realEstateStatus?.map((status) => {
          const realEstateStatus = { ...status }
          if (
            realEstateStatus.nameEn === 'rented' ||
            realEstateStatus.nameEn === 'sold'
          ) {
            realEstateStatus.nameAr += 'ة'
          }
          return realEstateStatus
        }) ?? []
      )
    }
  },
  watch: {
    checkedHeaders() {
      const arr = []
      this.headersArr.map((header) => {
        if (this.checkedHeaders.includes(header.text)) {
          return arr.push(header)
        }
        return null
      })
      this.$emit('dataFilterChanged', arr)
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setOutSidetypes() {
      this.showFilterTypes = false
    },
    toggleFilterTypes() {
      this.showFilterTypes = !this.showFilterTypes
    },
    resetFilterStatus() {
      if (this.filterStatusId) {
        this.filterStatusId = ''
        this.$emit('filterStatusChanged', this.filterStatusId)
      }
    },
    filterStatus(statusId) {
      if (this.filterStatusId === statusId) {
        return
      }
      this.filterStatusId = statusId
      this.$emit('filterStatusChanged', this.filterStatusId)
    },
    filterTypes(filterId) {
      const filterIndex = this.filterTypesIds.findIndex(
        (item) => item === filterId
      )
      if (filterIndex !== -1) {
        this.filterTypesIds.splice(filterIndex, 1)
      } else {
        this.filterTypesIds.push(filterId)
      }
      this.$emit('filterTypesChanged', this.filterTypesIds)
    },
    resetFilterTypes() {
      if (this.filterTypesIds.length === 0) {
        return
      }
      this.filterTypesIds = []
      this.$emit('filterTypesChanged', this.filterTypesIds)
    },
    setGridView(payload) {
      if (this.gridView === payload) {
        return
      }
      this.$emit('gridViewChanged', payload)
    },
    changeShowHasContract(val) {
      this.$emit('showHasAContract', val)
    },
    searchForRealEstates: debounce(function (searchText) {
      this.search = searchText
      this.$emit('search', this.search)
    }, 600)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/responsiveness/responsiveness.scss';
.min-button:hover {
  background-color: #9c27b0 !important;
  color: white !important;
  transition: all 0.4s ease-out;
}
.max-width-220 {
  max-width: 220px;
}
.contracts-switch {
  margin-top: 7px !important;
  width: 40px !important;
}
.grid-view {
  width: 28px;
  height: 28px;
}
.selected-grid-view {
  border-radius: 50% !important;
  background: rgba(102, 45, 145, 0.1);
}
.cards--multiple--selections {
  .cards--upload--btn {
    @include max-sm {
      min-width: 154px !important;
      width: auto !important;
    }
  }
}
</style>
