<template>
  <div class="page-main-bg" id="team__page">
    <v-container class="main-container team-container position-relative">
      <h3 class="font-weight-bold mt-0 mb-4">فريق العمل</h3>

      <!------------------------------------>
      <!----MultipleSelectionsForDelete----->
      <!------------------------------------>

      <MultipleSelectionsForDelete
        v-if="!featureNotIncluded && listPermission"
        v-bind="multipleSelectionsData"
        :showRestoreDeleted="false"
        @changeSelect="selectAllChanged"
        @switched="switched"
      />

      <!-- If basic package -->
      <cardExceedLimit
        v-if="featureNotIncluded"
        v-bind="{ title: notIncluded, subTitle: exceedSubTitle }"
        @changeTab="$emit('routeToSubscription')"
      />

      <!-------card Exceed Limit-------->
      <cardExceedLimit
        v-if="exceededAddingMember && !featureNotIncluded"
        v-bind="{ title: exceedTitle, subTitle: exceedSubTitle }"
        @changeTab="$emit('routeToSubscription')"
      />

      <UpgradeView v-if="!listPermission" />

      <!-- Loader -->
      <v-row class="mx-0" v-else-if="isPageLoading">
        <v-col
          cols="12"
          class="property-col mb-2 shadow-border-radius px-0"
          v-for="i in 6"
          :key="i"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <!--no-team-->
      <div v-else-if="!team.length" class="no-team">
        <h3>لا يوجد اعضاء مضافين لديك</h3>

        <v-btn
          rounded
          large
          color="success"
          v-if="addPermission"
          @click.prevent="openAddDialog"
        >
          <v-icon>mdi-plus</v-icon>
          <span class="tf mr-1">إضافة عضو</span>
        </v-btn>
      </div>

      <!--data-table-team-->

      <dataTableTeam
        v-else
        :users="team"
        :headers="headersArr"
        @edit-member="editTeamMember"
        @toggle-status="toggleTeamMemberStatus"
        @refresh-team-list="refreshTeamList"
        v-on="$listeners"
      />

      <!--Pagination-->
      <div class="text-center mt-6 mb-5" v-if="viewPagination">
        <Pagination :pagination="pagination" @onChange="loadEmployees" />
      </div>

      <!----------FastActions-------->

      <div
        v-if="addPermission && !featureNotIncluded"
        class="d-flex align-center justify-end ml-md-8 fast-actions"
      >
        <v-btn
          @click="openAddDialog"
          color="primary"
          large
          class="font-weight-bold fast-actions-btn pa-5 font-12"
        >
          <img src="@/assets/img/svgs/user-w.svg" class="mx-2" loading="lazy" />
          ارسال دعوه
        </v-btn>

        <!----------------------------->
        <!-------Fast actions---------->
        <!----------------------------->
        <fastActions />
      </div>

      <add-team-member-modal
        ref="addTeamMemberModal"
        v-if="dialogState"
        :dialogState="dialogState"
        :editMemberId="memberId"
        :memberData="memberData"
        @refresh-team-list="refreshTeamList"
        @closed-add-dialog="closeDialog"
      />
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { EnvService, MultipleDeletion } from '@/services'
import multipleSelections from '@/mixins/multipleSelections'
import dataTableTeam from '@/components/team/dataTableTeam'
import Pagination from '@/components/helper/pagination.vue'
import MultipleSelectionsForDelete from '@/components/helper/MultipleSelectionsForDelete'
import cardExceedLimit from '@/components/messages/cardExceedLimit'
import fastActions from '@/components/helper/fastActions'
import UpgradeView from '../components/helper/upgradeView.vue'

export default {
  name: 'Team',
  mixins: [multipleSelections],
  components: {
    fastActions,
    AddTeamMemberModal: () =>
      import('@/components/modals/AddTeamMemberModal.vue'),
    dataTableTeam,
    Pagination,
    cardExceedLimit,
    MultipleSelectionsForDelete,
    UpgradeView
  },
  data: () => {
    return {
      exceedTitle:
        'لقد  استنفذت العدد المحدد من الإضافات المتاحة في باقتك الحالية وبإمكانك ترقية الباقة لإجراء إضافات جديدة',
      exceedSubTitle:
        'يمكنك ترقية الباقة  الحالية  لباقة أعلى أو التواصل معنا مباشرة في حال كان اشتراكك الحالي في أعلى باقة متاحة',
      notIncluded:
        'هذه الخاصية غير متاحة في الباقة الحالية، يمكنك ترقية الباقة لتتمكن من استخدامها',
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 8,
        count: 0
      },
      isPageLoading: true,
      memberId: null,
      memberData: {},
      team: [],
      headersArr: [
        {
          text: ' الاسم',
          align: 'center',
          sortable: false,
          value: 'name'
        },
        { text: ' رقم الجوال', value: 'phoneNumber', align: 'center' },
        { text: 'الايميل', value: 'email' },
        { text: 'المسمي الوظيفي', value: 'environments' },
        { text: 'القسم', value: 'tags', align: 'center' },
        { text: 'حالة الدعوة', value: 'registered' },
        { text: 'الحالة', value: 'active' },
        { text: '', value: 'actions' }
      ],
      dialogState: false
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'subscription']),
    listPermission() {
      return !!this.$hasPermission('users', 'invitations', 'list')
    },
    featureNotIncluded() {
      return this.subscription.bundle.nameEn === 'Basic bundle'
    },
    exceededAddingMember() {
      return this.$store.getters['accountState/exceededAddingMember']
    },
    viewPagination() {
      return this.pagination.pagesCount > 1
    },
    addPermission() {
      return (
        this.$hasPermission('users', 'invitations', 'add') &&
        !this.exceededAddingMember
      )
    },
    /* Multiple selections */
    // ------------------ //
    deleteTitle() {
      return this.showSelectAll ? 'حذف الاعضاء' : 'حذف العضو'
    },
    restoreTitle() {
      return this.showSelectAll
        ? 'استرجاع الاعضاء المحذوفة'
        : 'استرجاع العضو المحذوف'
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapActions('accountState', ['getUserBundle']),
    openAddDialog() {
      this.memberId = null
      this.memberData = {}
      if (this.$hasPermission('users', 'invitations', 'add')) {
        this.dialogState = true
      } else {
        this.addNotification({
          text: 'ليس لديك صلاحية لدعوة موظف',
          color: 'error'
        })
      }
    },
    closeDialog() {
      this.dialogState = false
    },
    editTeamMember(val) {
      this.memberId = val._id
      this.memberData = val
      this.dialogState = true
    },
    toggleTeamMemberStatus(payload) {
      this.$set(this.team[payload.memberIndex], 'active', payload.active)
    },
    async loadEmployees() {
      try {
        const payLoad = `&pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`

        const { data } = await EnvService.getAllEmployee(
          this.currentEnv._id,
          payLoad
        )

        if (data) {
          this.team = data.users.map((user) => ({
            ...user,
            checked: false
          }))
          // bring the owner to the top
          this.team.sort((x, y) => {
            if (x.environments[0]?.emlpoyeeRole === 'owner') {
              return -1
            }
            if (y.environments[0]?.emlpoyeeRole === 'owner') {
              return 1
            }
            return 0
          })
          this.pagination.pagesCount = Math.ceil(
            data.count / this.pagination.pageSize
          )
          this.pagination.count = data.count
        }
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل فريق العمل',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    allDeletedEmployees() {
      console.log('All delete employees')
    },
    refreshTeamList() {
      this.loadEmployees()
      this.getUserBundle()
    },
    /* Multiple selections */
    // ------------------ //
    // Delete teams with multiple selections
    async deleteItems() {
      const message = this.showSelectAll
        ? `هل انت متاكد من حذف الاعضاء وعددهم ${this.getAllChecked.length}`
        : 'هل انت متاكد من حذف العضو'
      // 1 - Open popup and confirm delete or not
      const status = await this.$root.$confirm.open({
        title: this.deleteTitle,
        message,
        options: {
          color: 'red'
        }
      })

      // If confirmed
      if (status) {
        try {
          const body = { ids: this.getAllChecked }

          await MultipleDeletion.deleteTeams(this.currentEnv._id, body)
          this.loadEmployees()
          this.addNotification({
            text: 'تم الحذف بنجاح',
            color: 'success'
          })
        } catch {
          this.addNotification({
            text: 'حدث خطا يرجى المحاولة مره اخرى',
            color: 'error'
          })
        }
      }
    },
    // Restore teams with multiple selections
    async restoreDelete() {
      const message = this.showSelectAll
        ? `هل انت متاكد من استرجاع الاعضاء المحذوفة وعددهم ${this.getAllChecked.length}`
        : 'هل انت متاكد من استرجاع العضو المحذوف'
      //
      const status = await this.$root.$confirm.open({
        title: this.restoreTitle,
        statusRestore: true,
        message,
        options: {
          color: 'red'
        }
      })
      console.log(status)
    },
    // This will handle all cases switching
    switched(data) {
      const { name, value } = data
      this.statusRestore = value

      if (name === 'deleted') {
        return this.allDeletedEmployees()
      }
      return this.loadEmployees()
    }
  },
  async created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    if (this.listPermission) {
      this.loadEmployees()
    }
  },
  metaInfo() {
    return {
      title: 'اعضاء الفريق'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_properties.scss';
@import '@/styles/material-dashboard/_team.scss';
@import '@/styles/material-dashboard/fastactions.scss';
</style>
