<template>
  <div class="quotation--paper2 quotation--paper6">
    <h1>صور العقار</h1>
    <h2 class="ma-0">{{ quotation.realEstate?.images?.length }} صور</h2>
    <div class="quotation--paper6__images mt-10 d-flex">
      <div
        class="quotation--paper6__images__image"
        v-for="(image, i) in quotation.realEstate?.images"
        :key="i"
      >
        <img :src="image?.normalImage?.path" alt="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Paper6',
  props: {
    quotation: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
