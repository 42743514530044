<template>
  <div>
    <v-form ref="form" v-model="isValid" class="my-2">
      <!-- Details -->
      <h6 class="ma-0 mb-4">اطراف التعاقد</h6>
      <v-layout class="mb-4" justify-space-between>
        <v-layout>
          <h4 class="font-weight-medium font-16 ma-0">
            {{ isRentContract ? 'طرف أول (مالك)' : 'طرف أول' }}
          </h4>
        </v-layout>
      </v-layout>
      <v-layout class="mb-4 mt-2">
        <v-col class="pa-0">
          <template v-if="isRentContract">
            <autocomplete
              v-bind="firstPartyAutoCompleteProps"
              @nextPageTrigger="getClientNextData"
              @emitSearchKey="getClientDataBySearchKey"
              @input="onSelectFirstParty($event)"
              v-model="contractInfo.firstParty"
            />
            <v-card
              v-if="
                isAvaliableRealestate &&
                isRentContract &&
                this.firstPartyIsValid
              "
              class="informativeCard pa-1"
            >
              <div class="holder d-flex align-center">
                <div class="mx-3">
                  <img height="22" :lazy-src="exclamation" :src="exclamation" />
                </div>
                <div class="text">
                  <span>
                    <p class="headTitle font-14 font-weight-bold ma-0">
                      لا توجد عقارات متاحه لهذا المالك
                      <span>
                        <router-link to="/dashboard/properties/0">
                          اضغط هنا لإضافه عقار
                        </router-link>
                      </span>
                    </p>
                  </span>
                </div>
              </div>
            </v-card>
          </template>
          <div v-else>
            <h4 class="font-weight-medium font-16 ma-0">اسم الشركة</h4>
            <h4>{{ firstPartyName || 'لا يوجد اسم' }}</h4>
          </div>
        </v-col>
      </v-layout>
      <v-expansion-panels
        :disabled="expansionPanelsStatus"
        accordion
        v-model="panels"
      >
        <v-expansion-panel class="mb-4">
          <v-expansion-panel-header class="no-border rounded-15">
            <v-layout class="" justify-space-between>
              <v-layout class="flex-column">
                <div class="d-flex">
                  <v-icon class="ml-1" color="primary">person</v-icon>
                  <h4 class="font-weight-medium font-16 ma-0">
                    الطرف الثاني ({{ isRentContract ? 'مستأجر' : 'مالك عقار' }})
                  </h4>
                </div>
              </v-layout>
            </v-layout>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row align-content-sm no-gutters class="mt-4">
              <v-form ref="form" class="w-100">
                <v-col
                  v-if="
                    !(
                      (defaultValuesCheck && !isRentContract) ||
                      (defaultValuesCheck && isRentContract && isOnlyRenter)
                    )
                  "
                  cols="12"
                  sm="12"
                  class="mobile__p__0 py-0"
                >
                  <dropdown-alt
                    v-if="isRent && hasApermissionToAddClient"
                    ref="newExistedClient"
                    v-bind="dropDownAltProps"
                    @filterTypes="onUserTypeChange($event)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  v-if="!isNewOwner"
                  class="mobile__p__0"
                >
                  <h5 class="ma-0 mb-3 font-12">
                    {{ isRentContract ? '*اسم المستأجر' : 'اسم المالك*' }}
                  </h5>
                  <autocomplete
                    ref="autoComplete"
                    :itemData="true"
                    :items="AllavailableUsers"
                    :noData="noDataClient"
                    :placeholder="selectedClientPlaceholder"
                    :validateString="validateStringClient"
                    :returnObject="true"
                    @nextPageTrigger="getRenterNextData"
                    @emitSearchKey="getRenterDataBySearchKey"
                    v-model="contractInfo.otherParties"
                    @input="onSelectOtherParty($event, isRentContract)"
                    :disabled="
                      (defaultValuesCheck && !isRentContract) ||
                      (defaultValuesCheck && isRentContract && isOnlyRenter)
                    "
                    class="font-14"
                  />
                  <v-card
                    v-if="
                      isAvaliableRealestate &&
                      !isRentContract &&
                      this.otherPartyIsValid
                    "
                    class="informativeCard pa-1"
                  >
                    <div class="holder d-flex align-center">
                      <div class="mr-3 ml-5">
                        <img :src="exclamation" />
                      </div>
                      <div class="text">
                        <span>
                          <p class="headTitle font-14 font-weight-bold ma-0">
                            لا توجد عقارات متاحه لهذا المالك
                            <span>
                              <router-link to="/dashboard/properties/0">
                                اضغط هنا لإضافه عقار
                              </router-link>
                            </span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-form>
            </v-row>

            <v-row v-if="isNewOwner && isRent">
              <v-col>
                <NewAgentForm
                  ref="newAgent"
                  v-bind="newAgentFormProps"
                  @checkUserExistance="checkUserExistance"
                  @onValidatePhoneNumber="onValidatePhoneNumber"
                  @new_user_added="newUserAdded"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
    <v-row class="mb-2" v-if="isPm">
      <v-col cols="12" sm="12">
        <div class="d-flex my-4">
          <v-icon color="#662d91" size="25">
            {{ commissionText.icon }}
          </v-icon>
          <p class="ma-0 mr-2 text--purple font-16 font-weight-bold">
            {{ commissionText.text }}
          </p>
        </div>
        <v-card class="informativeCard pa-2">
          <div class="holder d-flex align-center">
            <div class="mr-3 ml-5">
              <v-img :src="exclamation" />
            </div>
            <div class="text">
              <span>
                <p class="headTitle font-14 font-weight-bold ma-0">
                  {{ informativeCardText }}
                </p>
              </span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import autocomplete from '@/components/forms/autocomplete'
import { EnvService, userService } from '@/services'
import { mapState, mapMutations } from 'vuex'

import DropdownAlt from '@/components/add-unit/dropdownAlt'
import NewAgentForm from '@/components/common/new-agent/NewAgentForm'
import config from '@/config'
import checkClientData from '@/mixins/checkClientData.js'

export default {
  name: 'contractParties',
  mixins: [checkClientData],
  components: { autocomplete, DropdownAlt, NewAgentForm },
  props: {
    isPendingRenewal: {
      default: false,
      type: Boolean
    },
    exclamation: {
      default: require('@/assets/img/svgs/exclamationMark.svg')
    },
    isRentContract: {
      default: false,
      required: true
    },
    defaultData: {
      default() {
        return {}
      }
    },
    updatedContract: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    },
    contrcatType: {
      type: String,
      required: true
    },
    envHasCommissionTax: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isValid: false,
      contractInfo: {},
      expansionPanels: true,
      panels: [],
      firstPartySearchKey: '',
      otherPartySearchKey: '',
      envOwners: [],
      allEnvOwners: [],
      paginationFirstParty: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      availableUsers: [],
      AllavailableUsers: [],
      paginationRenter: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      noDataClient: 'لا يوجد عملاء متاحين لهذا البحث',
      selectedClientPlaceholder: 'عميل حالي',
      validateStringClient: 'من فضلك ادخل اسم العميل',
      newAgentFormValid: false,
      newUserChecked: false,
      newUser: {},
      isAvaliableRealestate: false,
      phoneNumberSuccessMesage: '',
      phoneNumberErrorMessage: '',
      checkPhoneNumberLoader: false,
      phoneData: {},
      activeCommission: {
        text: 'الضريبة مٌفعلة في الإعدادات',
        description:
          'سيتم إضافة ضريبة علي العمليات المالية التي ستنتج عن هذا العقد, بالإمكان إلغاء احتساب الضريبة من إعدادات الحساب',
        icon: 'mdi-check'
      },
      inactiveCommission: {
        text: 'الضريبة غير مٌفعلة في الإعدادات',
        description:
          'لن يتم إضافة ضريبة علي العمليات المالية التي ستنتج عن هذا العقد, بالإمكان تفعيل احتساب الضريبة من إعدادات الحساب',
        icon: 'mdi-close'
      }
    }
  },
  watch: {
    newAgentFormValid() {
      this.emitValidationStatus()
    },
    'contractInfo.firstParty': {
      deep: true,
      handler() {
        this.emitValidationStatus()
      }
    },
    'contractInfo.otherParties': {
      deep: true,
      handler() {
        this.emitValidationStatus()
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    commissionText() {
      return this.envHasCommissionTax
        ? this.activeCommission
        : this.inactiveCommission
    },
    informativeCardText() {
      return this.commissionText?.description
    },
    hasApermissionToAddClient() {
      return !!this.$hasPermission('users', 'clients', 'add')
    },
    isValidPhone() {
      return this.phoneData.isValid ?? false
    },
    restructureParties() {
      const data = {
        otherParties: [...this.otherPartyData],
        firstParty: !this.isRentContract
          ? { ...this.userData }
          : { ...this.firstPartyData }
      }
      return data
    },
    userData() {
      const user = {
        _id: this.user._id,
        name: this.user.name,
        phoneNumber: this.user.phoneNumber,
        type: this.user.type || 'company',
        idNumber: this.user?.idNumber || '',
        taxNumber: Number(this.user?.taxNumber) || 0
      }
      return user
    },
    firstPartyData() {
      const firstPartyData = {
        _id: this.contractInfo.firstParty?._id,
        name: this.contractInfo.firstParty?.name,
        phoneNumber: this.contractInfo.firstParty?.phoneNumber,
        idNumber: this.contractInfo.firstParty?.idNumber || '',
        taxNumber: Number(this.contractInfo.firstParty?.taxNumber) || 0,
        type: 'owner',
        clientType: this.generateClientType(
          this.contractInfo.firstParty?.clientType ||
            this.contractInfo.firstParty?.type
        )
      }
      return firstPartyData
    },
    otherPartyData() {
      const otherPartySelected = this.newUserChecked
        ? this.newUser
        : this.contractInfo.otherParties
      const otherPartyData = {
        ...(otherPartySelected._id && {
          _id: otherPartySelected._id
        }),
        email: otherPartySelected.email,
        whatsAppNumber: otherPartySelected.phoneNumber,
        name: otherPartySelected.name,
        phoneNumber: otherPartySelected.phoneNumber,
        idNumber: otherPartySelected.idNumber || '',
        registrationNumber: otherPartySelected.registrationNumber || '',
        number: otherPartySelected.clientNumber || '',
        taxNumber: Number(otherPartySelected.taxNumber) || 0,
        type: this.isRentContract ? 'renter' : 'owner',
        userAddress: {
          ...(otherPartySelected.address && {
            address: otherPartySelected.address
          }),
          ...(otherPartySelected.city && {
            city: otherPartySelected.city
          }),
          ...(otherPartySelected.district && {
            district: otherPartySelected.district
          })
        },
        clientType: this.generateClientType(
          otherPartySelected.clientType || otherPartySelected.type
        )
      }
      return [otherPartyData]
    },
    isOnlyRenter() {
      const activeEnv = this.defaultData?.environments?.find((env) => {
        return env._id === this.currentEnv?._id
      })
      const clientRoles = activeEnv?.role
      return !!(clientRoles?.length === 1 && clientRoles?.includes('renter'))
    },
    defaultValuesCheck() {
      return !!Object.keys(this.defaultData).length
    },
    defaultRealestateValuesCheck() {
      return this.defaultValuesCheck && this.defaultData.hasOwnProperty('owner')
    },
    partiesTabValid() {
      return !!(
        this.firstPartyIsValid &&
        !this.isAvaliableRealestate &&
        (this.newAgentFormValidStatus || this.otherPartyIsValid)
      )
    },
    firstPartyIsValid() {
      return (this.isRentContract && !this.defaultValuesCheck) ||
        (this.defaultValuesCheck && this.isRentContract)
        ? !!(
            this.contractInfo?.firstParty &&
            Object.keys(this.contractInfo?.firstParty).length
          )
        : true
    },
    otherPartyIsValid() {
      return !!(
        this.contractInfo?.otherParties &&
        Object.keys(this.contractInfo?.otherParties).length
      )
    },
    newAgentFormValidStatus() {
      return this.newAgentFormValid
    },
    isNewOwner() {
      return !!this.newUserChecked
    },
    newExistedClient() {
      return config.clientType
    },
    expansionPanelsStatus: {
      get() {
        return this.isRentContract ? this.expansionPanels : false
      },
      set(val) {
        this.expansionPanels = val
      }
    },
    dropDownAltProps() {
      return {
        configrations: { name: '' },
        listingType: this.newExistedClient,
        defaultValue: 'حالي'
      }
    },
    firstPartyAutoCompleteProps() {
      return {
        itemData: true,
        items: this.allEnvOwners,
        noData: this.noDataClient,
        placeholder: this.selectedClientPlaceholder,
        validateString: this.validateStringClient,
        returnObject: true,
        disabled:
          this.defaultValuesCheck && this.isRentContract && !this.isOnlyRenter
      }
    },
    firstPartyName() {
      return this.currentEnv?.owner?.company || this.currentEnv?.name
    },
    isRent() {
      return this.contrcatType === 'rent'
    },
    isPm() {
      return this.contrcatType === 'propertyManagement'
    },
    newAgentFormProps() {
      return {
        index: 0,
        isAqarForm: true,
        isDisabled: false,
        phoneNumberSuccessMesage: this.phoneNumberSuccessMesage,
        phoneNumberErrorMessage: this.phoneNumberErrorMessage,
        checkPhoneNumberLoader: this.checkPhoneNumberLoader,
        isValidPhone: this.isValidPhone
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    checkUserExistance(phoneNumber) {
      phoneNumber = phoneNumber.replace(/ /g, '')
      if (phoneNumber.length !== 10) {
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = 'من فضلك تأكد من رقم الهاتف'
      } else if (this.isValidPhone) {
        this.checkPhoneNumberLoader = true
        this.$emit('createContractBtnLoading', this.checkPhoneNumberLoader)
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = ''
        phoneNumber = phoneNumber.replace('0', '966')
        const environment = this.currentEnv._id
        userService
          .checkClient({ environment, phoneNumber })
          .then((response) => {
            if (response.data.available) {
              this.phoneNumberSuccessMesage = 'العميل متاح للإضافه'
              this.phoneNumberErrorMessage = null
            }
          })
          .catch(() => {
            this.phoneNumberErrorMessage = ' الرقم مُسجل بإسم عميل أخر'
          })
          .finally(() => {
            this.checkPhoneNumberLoader = false
            this.$emit('createContractBtnLoading', this.checkPhoneNumberLoader)
          })
      }
    },
    setAvaliableRealestate(val) {
      this.isAvaliableRealestate = !val
      this.emitValidationStatus()
    },
    async handlingContractDefaultValues() {
      if (this.defaultValuesCheck) {
        if (this.defaultData.hasOwnProperty('owner')) {
          this.setContractClientDefaultValues(this.defaultData.owner)
        } else if (
          this.defaultData.hasOwnProperty('phoneNumber') &&
          !this.isOnlyRenter
        ) {
          this.$emit('getRealEstates', this.defaultData)
          this.setContractClientDefaultValues(this.defaultData)
        } else {
          this.$emit('getRealEstates', this.defaultData)
          this.setContractClientDefaultValues(this.defaultData)
        }
      }
    },
    setContractClientDefaultValues(owner) {
      // Set Owner values as default
      if (!this.isRentContract || (this.isRentContract && this.isOnlyRenter)) {
        this.AllavailableUsers.push(owner)
        this.$set(this.contractInfo, 'otherParties', owner)
      } else {
        this.$set(this.contractInfo, 'otherParties', null)
        this.allEnvOwners.push(owner)
        this.$set(this.contractInfo, 'firstParty', owner)
        this.expansionPanels = false
      }
    },
    newUserAdded(val) {
      this.newUser = val.obj
      this.newAgentFormValid = val.isValid
    },
    onUserTypeChange(otherPartyType) {
      if (otherPartyType.text === 'جديد') {
        this.newUserChecked = true
        this.$set(this.contractInfo, 'otherParties', {})
      } else {
        this.newUserChecked = false
        this.newAgentFormValid = false
        this.$set(this.contractInfo, 'otherParties', {})
      }
      this.emitValidationStatus()
    },
    getClientNextData() {
      if (
        this.paginationFirstParty.pageNumber <
        this.paginationFirstParty.pagesCount
      ) {
        this.paginationFirstParty.pageNumber += 1
        this.getAvaliableFirstParties()
      }
    },
    getClientDataBySearchKey(e) {
      this.firstPartySearchKey = e
      if (this.firstPartySearchKey) {
        this.getAvaliableFirstParties()
      }
    },
    getRenterNextData() {
      if (this.paginationRenter.pageNumber < this.paginationRenter.pagesCount) {
        this.paginationRenter.pageNumber += 1
        this.getAvaliableOtherParties()
      }
    },
    getRenterDataBySearchKey(e) {
      this.otherPartySearchKey = e
      if (this.otherPartySearchKey) {
        this.getAvaliableOtherParties()
      }
    },
    async manageOwnerClientsRequests() {
      if (this.isRentContract) {
        await this.getAvaliableFirstParties()
      }
      await this.getAvaliableOtherParties()
    },
    async getAvaliableFirstParties() {
      const data = 'ownersOnly=1'
      let query = ''
      if (this.firstPartySearchKey) {
        query += `search=${this.firstPartySearchKey}`
      } else {
        query += `pageSize=${this.paginationFirstParty.pageSize}&pageNumber=${this.paginationFirstParty.pageNumber}`
      }
      try {
        const availableOwnersResponse = await EnvService.getAllClients(
          this.currentEnv._id,
          query,
          data
        )
        this.paginationFirstParty.pagesCount = Math.ceil(
          availableOwnersResponse?.data?.count /
            this.paginationFirstParty?.pageSize
        )
        this.paginationFirstParty.count = availableOwnersResponse?.data?.count
        this.envOwners = availableOwnersResponse?.data?.users ?? []
        this.allEnvOwners = [...this.allEnvOwners, ...this.envOwners]
      } catch {
        this.addNotification({
          text: 'حدث خطأ أثناء تحميل الطرف الاول',
          color: 'error'
        })
      }
    },
    async getAvaliableOtherParties() {
      const data = this.isRentContract ? 'rentersOnly=1' : 'ownersOnly=1'
      let query = ''
      if (this.otherPartySearchKey) {
        query += `search=${this.otherPartySearchKey}`
      } else {
        query += `pageSize=${this.paginationRenter.pageSize}&pageNumber=${this.paginationRenter.pageNumber}`
      }
      const availableClientsResponse = await EnvService.getAllClients(
        this.currentEnv._id,
        query,
        data
      )
      this.paginationRenter.pagesCount = Math.ceil(
        availableClientsResponse?.data?.count / this?.paginationRenter?.pageSize
      )
      this.paginationRenter.count = availableClientsResponse?.data?.count
      this.availableUsers = availableClientsResponse?.data?.users ?? []
      this.AllavailableUsers = [
        ...this.AllavailableUsers,
        ...this.availableUsers
      ]
    },
    async onSelectFirstParty(user) {
      this.expansionPanelsStatus = false
      if (user && !Object.keys(user).length && !this.defaultValuesCheck) {
        this.expansionPanelsStatus = true
        this.panels = []
        this.$set(this.contractInfo, 'otherParties', null)
      } else if (user && Object.keys(user).length) {
        this.$emit('resetRelaestate')
        this.$emit('getRealEstates', user)
      }
      this.emitValidationStatus()
    },
    async onSelectOtherParty(user, isRentContract) {
      this.newUserChecked = false
      if (user && !isRentContract && !this.defaultValuesCheck) {
        this.$emit('getRealEstates', user)
      }
      this.emitValidationStatus()
    },
    bindContractData() {
      this.$emit('bindContractData', this.restructureParties)
    },
    emitValidationStatus() {
      this.$emit('setValdationStatus', this.partiesTabValid)
    },
    resetData() {
      this.contractInfo = {}
    },
    presetData() {
      this.editMoodPresetData()
      this.emitValidationStatus()
      this.manageOwnerClientsRequests()
      this.handlingContractDefaultValues()
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        if (this.updatedContract.hasOwnProperty('firstParty')) {
          this.allEnvOwners.push(this.updatedContract?.firstParty)
          this.$set(
            this.contractInfo,
            'firstParty',
            this.updatedContract?.firstParty
          )
          if (!this.isPendingRenewal) {
            this.$emit('getRealEstates', this.contractInfo.firstParty)
          }
        }
        if (this.updatedContract.hasOwnProperty('otherParties')) {
          this.AllavailableUsers.push(this.updatedContract?.otherParties)
          this.$set(
            this.contractInfo,
            'otherParties',
            this.updatedContract?.otherParties[0]
          )
          this.expansionPanelsStatus = false
        }
        if (this.isPendingRenewal) {
          this.$emit('presetIsEditMoodData')
        }
      }
    }
  }
}
</script>
