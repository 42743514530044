<template>
  <v-dialog
    class="backdrop"
    width="570px"
    overlay-opacity="0.5"
    persistent
    :value="editTaxModelStatus"
  >
    <v-card class="add-new-property">
      <v-card-title
        class="px-4 pa-0 mb-4 custom__shadow d-flex justify-space-between align-center"
      >
        <div class="d-flex align-center">
          <h4 class="ma-0">تعديل فاتوره</h4>
        </div>
        <v-btn
          @click.prevent="closeModel"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" v-model="isValid" class="px-4">
        <v-row no-gutters class="my-5">
          <v-col class="pl-3" :cols="6" :sm="6">
            <h6
              :class="{ 'red--flag': validationFiring }"
              class="mb-2 filedTiltle"
            >
              خصم أو إضافه *
            </h6>
            <v-select
              :items="taxType"
              :menu-props="{
                transition: 'slide-y-transition',
                bottom: true,
                offsetY: true
              }"
              :placeholder="`خصم أو إضافه`"
              dense
              filled
              hide-details
              item-text="title"
              item-value="_id"
              return-object
              v-model="taxNewData.type"
              :rules="selectRules"
              class="mb-0"
            ></v-select>
          </v-col>
          <v-col class="pl-1" :cols="6" :sm="6">
            <h6
              :class="{ 'red--flag': priceValidationFiring }"
              class="mb-2 filedTiltle"
            >
              المبلغ *
            </h6>
            <v-text-field
              dense
              filled
              hide-details
              placeholder="المبلغ"
              suffix="ريال"
              required
              min="0"
              @keydown="
                $allowOnlyDecimalNumbersCommas($event, taxNewData.amount)
              "
              @input="handleValuesChanging($event, amountMaskKeys)"
              :value="amountMask"
              :rules="selectRules"
              class="amount"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions
        class="cardActions pt-2 pb-0 d-flex align-center justify-space-between"
      >
        <v-spacer />
        <v-btn
          @click="editTax"
          class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
          text
        >
          <span class="mb-1 ml-1">حفظ التعديل</span>
          <v-icon class="font-16">mdi-arrow-left</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="textTile mt-5 pt-4 pb-5 px-4">
        <div class="holder d-flex align-center">
          <div class="mr-3 ml-5">
            <v-img :src="exclamationMarkrounded" />
          </div>
          <div class="text">
            <p class="font-12 font-weight-bold ma-0">
              في حاله الخصم اختر خصم ثم اضف المبلغ المراد خصمه وفي حاله الاضافه
              اختر إضافه واضف المبلغ المراد اضافته
            </p>
          </div>
          <v-spacer />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import confirmationMixin from '@/mixins/confirmationMixin.js'
import inputMasking from '@/mixins/inputMasking.js'
import { taxBillsService } from '@/services/taxBills.service.js'
import { mapMutations } from 'vuex'

export default {
  name: 'EditTaxInvoice',
  props: {
    editTaxModelStatus: {
      default: false
    },
    selectedTaxId: {
      default: ''
    }
  },
  mixins: [inputMasking, confirmationMixin],
  data() {
    return {
      exclamationMarkrounded: require('@/assets/img/svgs/exclamationMarkrounded.svg'),
      taxType: [
        {
          title: 'خصم',
          _id: 0,
          nameEn: 'dr'
        },
        {
          title: 'إضافه',
          _id: 1,
          nameEn: 'cr'
        }
      ],
      isValid: false,
      amountMask: '',
      selectRules: [(v) => !!v || 'هذا الحقل مطلوب'],
      nexButtonClicked: false,
      taxNewData: {}
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeModel() {
      this.taxNewData = {}
      this.nexButtonClicked = false
      this.$refs.form.reset()
      this.$emit('closeModel')
    },
    async editTax() {
      this.nexButtonClicked = true
      if (this.isValid) {
        await this.addPaymentNote(this.selectedTaxId)
        this.taxNewData = {}
        this.$refs.form.reset()
        this.nexButtonClicked = false
      } else {
        this.addNotification({
          text: 'من فضلك تأكد من جميع الحقول',
          color: 'error'
        })
      }
    },
    async addPaymentNote(taxId) {
      try {
        const { data } = await taxBillsService.addPaymentNotes(
          this.noteData,
          taxId
        )
        this.$emit('addPaymentNote', {
          taxId: this.selectedTaxId,
          paymentNote: data.paymentNote
        })

        if (data.paymentNote.needsApproval) {
          this.showConfirmationPopupMixin()
          this.$emit('closeModel')
        } else {
          this.$root.$confirm
            .open({
              title:
                data.paymentNote.type === 'dr'
                  ? 'تمت العمليه, تم إصدار إشعار دائن'
                  : ' تمت العمليه, تم إصدار إشعار مدين',
              options: {
                color: 'primary',
                width: 400,
                zIndex: 200
              },
              confirmTxt: 'تصدير الفاتوره',
              img: {
                path: require('@/assets/img/svgs/exported.svg'),
                width: 55,
                height: 55
              },
              cancelText: 'تصفح الفاتوره'
            })
            .then((status) => {
              status
                ? this.$emit('showTax', 'pdf')
                : this.$emit('showTax', 'sideDetails')
            })
            .catch(() => {
              this.$emit('closeModel')
            })
        }
      } catch {
        this.addNotification({
          text: 'خطأ في تعديل الفاتورة، يرجى المحاولة مجددا',
          color: 'error'
        })
      }
    }
  },
  computed: {
    validationFiring() {
      return this.nexButtonClicked ? !this.isValid : false
    },
    amountMaskKeys() {
      return {
        maskValKey: 'amountMask',
        parentKey: 'taxNewData',
        actualValKey: 'amount'
      }
    },
    priceValidationFiring() {
      return this.nexButtonClicked ? !this.taxNewData?.amount : false
    },
    noteData() {
      const dataToAdd = {
        type: this.taxNewData?.type?.nameEn,
        amount: Number(this.taxNewData?.amount),
        taxBill: this.selectedTaxId
      }
      return dataToAdd
    }
  }
}
</script>
<style lang="scss" scoped>
.textTile {
  background: #fafafa;

  .holder {
    .text {
      p {
        color: #585858;
      }
    }
  }
}

.cardActions {
  border: none !important;
}

.filedTiltle {
  color: #737373;
}

.v-input.amount {
  .v-input__control {
    .v-input__slot {
      padding-bottom: 4px !important;
    }
  }
}
</style>
