<template>
  <v-dialog max-width="740px" persistent :value="dialogState">
    <v-card class="add-new-property pa-0 add__customer__popup">
      <v-card-title
        class="py-0 pr-4 pl-0 mb-4 d-flex justify-space-between pb-1 custom__shadow mobile__py__6px mobile__px__15px"
      >
        <h4 class="ma-0 font-weight-bold">{{ title }}</h4>
        <v-btn
          @click.prevent="closeDialog"
          data-test="closeClientModal"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-5 pb-0">
        <v-form ref="form" v-model="isValid">
          <v-container class="pa-0">
            <v-row no-gutters class="file-container">
              <v-col class="" cols="12" sm="6">
                <h6 class="mb-2 font-12">نوع المرفق</h6>
                <v-col
                  class="pt-0 mobile__p__0 employee--select--col pr-0"
                  cols="12"
                  sm="12"
                >
                  <v-select
                    ref="employees"
                    :menu-props="{
                      transition: 'slide-y-transition',
                      bottom: true,
                      offsetY: true
                    }"
                    v-model="newAttachment.type"
                    :items="attachTypes"
                    placeholder="أختر نوع المرفق"
                    append-icon="mdi-chevron-down"
                    dense
                    chips
                    :rules="selectRules"
                    filled
                    clearable
                    class="font-14"
                    hide-details
                  >
                  </v-select>
                </v-col>
              </v-col>
              <!-- <v-col class="pl-3" cols="12" sm="6">
                <h6 class="mb-2 font-12">إسم المرفق</h6>
                <v-text-field
                  data-test="clientName"
                  v-model.trim="newAttachment.description"
                  :rules="attachNameRules"
                  dense
                  filled
                  hide-details
                  placeholder="ادخل أسم المرفق"
                  required
                  class="font-14"
                ></v-text-field>
              </v-col> -->
              <v-col class="pl-6" cols="12" sm="6" v-if="isNumberNeeded">
                <h6 class="mb-2 font-12">رقم المرفق (إختياري)</h6>
                <v-text-field
                  @input="formatArabicNumber($event, 'number')"
                  data-test="clientName"
                  v-model="newAttachment.number"
                  placeholder="ادخل رقم المرفق"
                  dense
                  filled
                  hide-details
                  class="font-14"
                ></v-text-field>
              </v-col>
              <v-col class="pl-3 file-container-col" cols="12" sm="6">
                <h6 class="mb-2 font-12">إضافة المرفق</h6>
                <v-file-input
                  label=""
                  placeholder="إضغط لإضافة مرفق (واحد فقط)"
                  outlined
                  :rules="selectRules"
                  class="rounded-lg"
                  @change="uploadAttachment"
                  prepend-icon=""
                  required
                  dense
                  v-model="attachment"
                >
                  <template v-slot:append>
                    <v-icon color="primary">mdi-upload</v-icon>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-2 px-8">
        <v-btn
          color="grey"
          class="ma-0 rounded-7 my-1 d-flex px-3"
          @click="closeDialog"
        >
          <span class="mb-1 mobile__mb__0 ml-1 font-weight-bold mt-1 font-12">
            إلغاء
          </span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-0 rounded-7 my-1 d-flex px-3"
          :disabled="!disableSubmit"
          :loading="isLoading"
          @click="saveAttachment"
        >
          <span class="mb-1 mobile__mb__0 ml-1 font-weight-bold mt-1 font-12">
            إضافة
          </span>
          <v-icon class="send-icon font-16 mt-1">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { convertNumbers2English } from '@/utils/formatters'
import { mapMutations } from 'vuex'
export default {
  name: 'AttachmentsModal',
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isNumberNeeded: {
      type: Boolean,
      default: true
    },
    attachTypes: {
      type: Array,
      default: () => ['عقد منصة إيجار', 'اخرى']
    },
    title: {
      type: String,
      default: 'إضافة مرفق'
    }
  },

  data() {
    return {
      newAttachment: {},
      selectedAttachment: null,
      attachment: null,
      isValid: false,
      selectRules: [(v) => !!v || 'هذا الحقل مطلوب'],
      attachNameRules: [(v) => !!v?.trim() || 'إسم المرفق']
    }
  },
  computed: {
    disableSubmit() {
      return this.isValid
    }
  },
  watch: {
    isLoading() {
      if (!this.isLoading) {
        this.closeDialog()
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('close-dialog')
      this.newAttachment = {}
      this.selectedAttachment = null
      this.attachment = null
    },
    uploadAttachment(file) {
      this.selectedAttachment = file
    },
    checkIsAttachIsValid() {
      if (!this.selectedAttachment) {
        this.addNotification({
          text: 'الرجاء التأكد من إضافة المرفق',
          color: 'error'
        })
      }
    },
    formatArabicNumber(event, type) {
      this.newAttachment[type] = convertNumbers2English(event)
    },
    saveAttachment() {
      this.checkIsAttachIsValid()
      this.$emit('selectFile', {
        ...this.newAttachment,
        files: this.selectedAttachment
      })
    }
  }
}
</script>

<style lang="scss">
.v-text-field--outlined .v-input__slot {
  font-size: 12px;
  color: #d2d2d2;
}
.file-container-col .v-text-field--outlined fieldset {
  border: 1px dashed #662d91;
}
</style>
