<template>
  <div class="quotation--paper2 quotation--paper3">
    <h1>الزيادة السنوية</h1>
    <h2 class="ma-0">معلومات الزيادة السنوية لقيمة الإيجار</h2>
    <div v-if="durations" class="quotation--paper3__table">
      <div
        class="quotation--paper3__table__row quotation--paper3__table__row--mod d-flex"
      >
        <div>
          <p>زيادة سنوية متغيرة</p>
        </div>
        <div>
          <p>{{ durations }} سنوات</p>
        </div>
      </div>
      <div
        v-for="yearValue in yearlyValues"
        :key="yearValue.year"
        class="quotation--paper3__table__row d-flex"
      >
        <div>
          <p>السنة {{ arabic[yearValue.year - 1] }}</p>
        </div>
        <div>
          <p>{{ yearValue.increase?.toLocaleString() }} ريال</p>
        </div>
        <div>
          <p>{{ yearValue.value?.toLocaleString() }} ريال</p>
        </div>
      </div>
    </div>
    <h2 v-else class="ma-0 mt-15 text-center">لا توجد زيادة سنوية</h2>
  </div>
</template>

<script>
export default {
  name: 'Paper3',
  props: {
    quotation: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      arabic: [
        'الأولى',
        'الثانية',
        'الثالثة',
        'الرابعة',
        'الخامسة',
        'السادسة',
        'السابعة',
        'الثامنة',
        'التاسعة'
      ]
    }
  },
  computed: {
    durations() {
      const duration = this.quotation.details?.duration
      return duration >= 24 ? duration / 12 : 0
    },
    yearlyValues() {
      let total = this.quotation.details?.total
      const increaseType = this.quotation.details?.increaseType
      const increases = this.quotation.details?.increase

      return Array.from({ length: this.durations }).map((_, index) => {
        let increase = 0

        if (index > 0) {
          if (increaseType === 'fixed') {
            increase = increases
          } else if (increaseType === 'percentage') {
            increase = total * (increases / 100)
          }

          total += increase
        }

        return {
          year: index + 1,
          value: Math.round(total),
          increase: Math.round(increase)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
