<template>
  <div class="quotation">
    <!-- All Papers -->
    <v-tabs-items ref="tabsItems" v-model="tab" touchless>
      <v-tab-item>
        <Paper1 v-bind="{ companyImages, quotation }" />
      </v-tab-item>
      <v-tab-item>
        <Paper2 :quotation="quotation" />
      </v-tab-item>
      <v-tab-item v-if="renderPaper3">
        <Paper3 :quotation="quotation" />
      </v-tab-item>
      <v-tab-item>
        <Paper4 :quotation="quotation" />
      </v-tab-item>
      <v-tab-item>
        <Paper5 :quotation="quotation" />
      </v-tab-item>
      <v-tab-item v-if="renderPaper6">
        <Paper6 :quotation="quotation" />
      </v-tab-item>
      <v-tab-item v-if="renderPaper7">
        <Paper7 :quotation="quotation" />
      </v-tab-item>
      <v-tab-item>
        <Paper8 />
      </v-tab-item>
      <v-tab-item>
        <Paper9 />
      </v-tab-item>
      <v-tab-item>
        <Paper10 v-bind="{ companyImages, signatures, quotation }" />
      </v-tab-item>
    </v-tabs-items>

    <!-----------Footer--------->
    <footer class="quotation--footer d-flex">
      <div>
        <img
          src="@/assets/img/svgs/logo.svg"
          alt=""
          style="max-height: 50px; max-width: 50px"
          loading="lazy"
        />
      </div>

      <!-- Arrows for actions -->
      <div class="quotation--footer__arrows">
        <!-- quotation--footer__arrows__arrow -->
        <button
          @click="arrowHandler('next')"
          :class="[tab + 1 === maxTab && 'quotation--footer__arrows__arrow']"
        >
          <img
            style="max-width: 30px"
            src="@/assets/img/icons/arrowRight.svg"
            alt="arrowRight"
          />
        </button>
        <div>
          <span>{{ maxTab }} - {{ tab + 1 }}</span>
        </div>
        <button
          @click="arrowHandler('prev')"
          :class="[tab === 0 && 'quotation--footer__arrows__arrow', 'me-15']"
        >
          <img
            style="max-width: 30px"
            src="@/assets/img/icons/arrowLeft.svg"
            alt="arrowLeft"
          />
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import Paper1 from '@/components/priceOffers/PDF/Paper1.vue'
import Paper10 from '@/components/priceOffers/PDF/Paper10.vue'
import Paper2 from '@/components/priceOffers/PDF/Paper2.vue'
import Paper3 from '@/components/priceOffers/PDF/Paper3.vue'
import Paper4 from '@/components/priceOffers/PDF/Paper4.vue'
import Paper5 from '@/components/priceOffers/PDF/Paper5.vue'
import Paper6 from '@/components/priceOffers/PDF/Paper6.vue'
import Paper7 from '@/components/priceOffers/PDF/Paper7.vue'
import Paper8 from '@/components/priceOffers/PDF/Paper8.vue'
import Paper9 from '@/components/priceOffers/PDF/Paper9.vue'
import contractsSharedData from '@/mixins/contractsSharedData'
import dateFormat from '@/mixins/dateFormat.js'
import sanitizers from '@/mixins/sanitizers.js'
import { mapGetters } from 'vuex'

export default {
  props: {
    quotation: {
      type: Object,
      default: () => {}
    },
    maxTab: {
      type: Number,
      required: true
    },
    signatures: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab: 0
    }
  },
  components: {
    Paper1,
    Paper2,
    Paper3,
    Paper4,
    Paper5,
    Paper6,
    Paper7,
    Paper8,
    Paper9,
    Paper10
  },
  mixins: [dateFormat, sanitizers, contractsSharedData],
  computed: {
    ...mapGetters('accountState', ['companyImages']),
    renderPaper3() {
      return this.quotation.details?.increase
    },
    renderPaper6() {
      return this.quotation.realEstate?.images.length
    },
    renderPaper7() {
      return this.quotation.realEstate?.bluePrint.length
    }
  },
  methods: {
    arrowHandler(type) {
      if (type === 'next') {
        this.tab += 1
        return
      }
      this.tab -= 1
    }
  },
  watch: {
    tab() {
      this.$emit('changeTab', this.tab)
    }
  },
  mounted() {
    this.$emit('changeMaxTab', this.$refs.tabsItems.$children.length)
  }
}
</script>
<style lang="scss">
.quotation {
  position: relative;
  height: 90vh;

  &--paper1 {
    margin-top: 100px;
    text-align: center;
    h1,
    h3 {
      font-size: 30px !important;
      font-weight: 400;
    }
    h1 {
      color: #606060;
    }
    h3 {
      color: #696969;
    }
    h4 {
      color: #b0b0b0;
      font-weight: 400;
    }
    div {
      margin-top: 40px;
      margin-bottom: 150px;
      background-color: #f1eff5;
      height: 400px;
    }
  }

  &--paper2 {
    padding-inline: 60px;

    &__table {
      position: relative;
      margin-top: 40px;
      &__row {
        &:nth-child(odd) {
          background-color: #f0ecf2;
        }
        &:nth-child(even) {
          background-color: #f7f7f7;
        }
        & > div {
          padding-block: 10px;
          padding-inline: 20px;
          flex-basis: calc(100% / 2);

          & p {
            margin: 0 !important;
            margin-bottom: 10px !important;
            font-size: 15px !important;

            &:first-of-type {
              font-weight: bold;
              color: #7a7a7a;
            }
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 3px;
        background-color: white;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    h1 {
      margin-top: 50px;
      font-size: 30px !important;
      font-weight: 400;
    }
    h2 {
      font-size: 16px !important;
      font-weight: bold;
      color: #c0c5d2;
    }
  }

  &--paper3 {
    padding-inline: 60px;

    &__table {
      position: relative;
      margin-top: 40px;
      &__row {
        background-color: #f7f7f7;
        & > div {
          padding-block: 20px;
          padding-inline: 20px;
          flex-basis: calc(100% / 3);
          text-align: center;
          border-bottom: 3px solid white;

          &:not(:last-of-type) {
            border-left: 3px solid white;
          }

          & p {
            margin: 0 !important;
            font-size: 20px !important;
          }
        }

        &--mod {
          background-color: #f0ecf2;
          & > div {
            flex-basis: calc(100% / 2);
          }
        }
      }
    }

    h1 {
      margin-top: 50px;
      font-size: 30px !important;
      font-weight: 400;
    }
    h2 {
      font-size: 16px !important;
      font-weight: bold;
      color: #c0c5d2;
    }
  }

  &--paper4 {
    padding-inline: 60px;

    &__table {
      position: relative;
      margin-top: 40px;
      &__row {
        background-color: #f7f7f7;
        & > div {
          display: flex;
          flex-basis: calc(100% / 2);
          text-align: center;
          border-bottom: 3px solid white;

          & > div {
            padding-block: 20px;
            padding-inline: 20px;
            flex-basis: calc(100% / 2);

            &:not(:last-of-type) {
              border-left: 3px solid white;
            }
          }

          &:not(:last-of-type) {
            border-left: 3px solid white;
          }

          & p {
            margin: 0 !important;
            font-size: 20px !important;
          }
        }

        &--mod {
          background-color: #f0ecf2;
          & > div {
            flex-basis: calc(100% / 2);
          }
        }
      }
    }

    h1 {
      margin-top: 50px;
      font-size: 30px !important;
      font-weight: 400;
    }
    h2 {
      font-size: 16px !important;
      font-weight: bold;
      color: #c0c5d2;
    }
  }

  &--paper5 {
    &__tags {
      display: flex;
      margin-top: 40px;
      flex-wrap: wrap;
      gap: 15px;

      & > div {
        padding-block: 10px;
        padding-inline: 20px;
        border: 2px solid #dedede;
        border-radius: 10px;
      }
    }
  }

  &--paper6 {
    &__images {
      flex-wrap: wrap;
      gap: 10px;

      &__image {
        overflow: hidden;
        flex-basis: calc((100% - 10px) / 2);
        border-radius: 10px;

        & img {
          max-width: 100%;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &--paper9 {
    &__card {
      overflow: hidden;
      margin-top: 30px;
      padding-inline: 20px;
      background-color: #f7f7f7;
      justify-content: space-between;

      &__img {
        width: 190px;
        height: 160px;

        & img {
          width: 100%;
          height: 100%;
          max-width: 100%;
        }
      }

      &--mod {
        p {
          color: #7d7d7d;
        }
      }
    }
  }

  &--paper10 {
    &__card {
      p {
        color: #7d7d7d;
      }

      &__sign {
        p {
          color: #d6d6d6;
        }

        &__seal {
          position: relative;

          & img {
            position: absolute;
            width: 40px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  &--footer {
    position: fixed;
    align-items: center;
    width: 42%;
    padding-block: 15px;
    padding-inline: 20px;
    bottom: 0;
    background-color: #fafafa;

    &__arrows {
      display: flex;
      gap: 15px;
      flex-grow: 1;
      justify-content: center;

      &__arrow {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
</style>
