<template>
  <section class="mt-0">
    <div class="summary-cards__wrapper">
      <v-card style="height: 265px; position: relative">
        <div class="d-flex justify-end pl-3 pt-3" style="gap: 10px">
          <span
            v-for="i in tabsCount"
            :key="i"
            :style="`
              background-color: ${activeTab === i ? '#662d91' : '#e0e0e0'};
              height: 3px;
              width: 50px;
              cursor: pointer;
            `"
            @click="activeTab = i"
          >
            &nbsp;
          </span>
        </div>
        <v-card-title class="mb-3">
          <!-- circular ----- Tilte -->
          <v-row class="mb-3">
            <div
              class="d-flex pt-3 align-baseline w-100"
              :class="{ 'justify-space-between': !progressData.leftTitle }"
            >
              <div class="d-flex align-center">
                <div
                  class="titleDot d-flex justify-center align-center ml-2"
                  :style="`background-color:${progressData.tabs[0]?.rows[0]?.color};`"
                >
                  <v-img
                    :src="getIcons(progressData)"
                    max-width="15"
                    max-height="15"
                  />
                </div>
                <h5 class="ma-0 font-weight-bold font-16 text-truncate">
                  {{ progressData?.title }}
                </h5>
              </div>
              <div class="d-flex align-center">
                <h4
                  class="font-weight-bold font-14 text-truncate d-flex align-center"
                  :class="{ ' mt-0': !xs, 'mr-0': xs }"
                >
                  <p v-if="progressData.leftTitle" class="mr-2 font-12">
                    {{ progressData.leftTitle }}
                  </p>
                  <AnimatedCounter
                    class="mb-3 mr-8"
                    :targetNumber="
                      progressData.tabs[activeTab - 1]?.total.replace(',', '')
                    "
                  />
                </h4>
              </div>
            </div>
          </v-row>
          <!-- circular ----- Progress -->
          <v-row class="d-flex align-center">
            <v-col class="px-0">
              <div class="d-flex justify-center flex-column w-100">
                <div
                  v-for="(indicator, i) in progressData.tabs[activeTab - 1]
                    ?.rows"
                  :key="i"
                  class="justify-space-between align-baseline mb-3 d-flex w-100"
                >
                  <!-- dots and names -->
                  <div class="d-flex align-center">
                    <div
                      class="subTitleDot ml-2"
                      :style="`background-color:${indicator?.color};`"
                    ></div>
                    <template class="d-flex" style="width: 80px">
                      <h5 class="ma-0 h4 font-weight-regular text-truncate">
                        {{ indicator?.field?.name }}
                      </h5>
                      <h6
                        :style="`color:${indicator?.color}; font-size: 14px; height: 8px;`"
                        class="mr-4"
                        v-if="dountTap === activeTab"
                      >
                        {{ indicator?.percentage }}
                      </h6>
                    </template>
                  </div>
                  <!-- values -->
                  <transition name="slide-fade" mode="out-in">
                    <!-- v-if="indicator?.field?.value?.toString().length < 18" -->
                    <h5
                      v-if="indicator?.field?.value?.toString().length < 18"
                      class="ma-0 font-14 font-weight-bold d-flex align-center"
                    >
                      <span
                        v-if="dountTap === activeTab"
                        :style="`color:${indicator?.color};`"
                        class="ml-2"
                      >
                        <AnimatedCounter
                          :targetNumber="indicator?.percentage"
                        />
                      </span>

                      <!-- <span style="color: #ddd" class="ml-2"> | </span> -->
                      <span :style="`color: ${indicator?.color}`">
                        {{ indicator?.field?.value }}
                      </span>
                    </h5>
                    <v-col
                      class="pa-0 d-flex ma-0"
                      lg="4"
                      md="4"
                      sm="4"
                      :cols="xs ? '' : '4'"
                      v-if="dountTap === activeTab"
                      style="position: absolute; top: 94px; left: 10px"
                    >
                      <DonutChart
                        :data="chartData"
                        :colors="chartColors"
                        :width="chartWidth"
                        :cutoutPercentage="90"
                      />
                    </v-col>
                  </transition>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </div>
  </section>
</template>

<script>
import close from '@/assets/img/reports/close.svg'
import rightCheck from '@/assets/img/reports/right-check.svg'
import realestateWhite from '@/assets/img/reports/realestate-white.svg'
import DonutChart from '../reports/DonutChart.vue'
import AnimatedCounter from '../helper/AnimatedCounter.vue'

export default {
  components: { DonutChart, AnimatedCounter },
  props: {
    progressData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      close,
      rightCheck,
      realestateWhite,
      chartWidth: 150,
      activeTab: 1,
      dountTap: 2
    }
  },
  computed: {
    xs() {
      return this.$vuetify.breakpoint.xs
    },
    md() {
      return this.$vuetify.breakpoint.md
    },
    lg() {
      return this.$vuetify.breakpoint.lg
    },
    chartData() {
      return this.progressData.tabs[0].rows?.map((row) =>
        Number(row?.percentage?.replace('%', ''))
      )
    },
    chartColors() {
      const hexColorPattern = /#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})\b/g
      const hexColors =
        this.progressData.tabs[0].progress?.match(hexColorPattern)
      if (!hexColors) {
        return []
      }
      return hexColors
    },
    tabsCount() {
      if (
        this.progressData.title.includes('المتأخرات') ||
        this.progressData.title.includes('المتوقعة') ||
        this.progressData.title.includes('الوحدات')
      ) {
        // console.log('test')
        return 3
      }
      return 2
    }
  },
  methods: {
    getIcons({ title = '' }) {
      switch (title) {
        case 'المتأخرات':
          return this.close
        default:
          return this.rightCheck
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<style lang="scss">
.v-progress-circular__overlay {
  stroke-linecap: round;
}
.opacity-half {
  opacity: 0.5;
}
@import '@/styles/report/progressCircularCard.scss';
</style>
