var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-5 py-6 mt-4"},[_c('v-form',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-3 mobile__pl__0 pb-0 mobile__pt__0",attrs:{"cols":"12","md":"6"}},[_c('h6',{staticClass:"mb-2 font-12"},[_vm._v("السجل التجاري")]),_c('v-text-field',{ref:"company",staticClass:"setting__input__fields",attrs:{"data-test":"commercialNumber","filled":"","dense":"","placeholder":"السجل التجاري","disabled":!_vm.hasUpdateFinancialPermission},on:{"keydown":_vm.$formatNumberInputs,"input":function($event){return _vm.formatArabicNumber($event, 'CommercialNumber')}},model:{value:(_vm.accountInfoEnv.CommercialNumber),callback:function ($$v) {_vm.$set(_vm.accountInfoEnv, "CommercialNumber", $$v)},expression:"accountInfoEnv.CommercialNumber"}})],1),_c('v-col',{staticClass:"pl-3 mobile__pl__0 pb-0 mobile__pt__0",attrs:{"cols":"12","md":"6"}},[_c('h6',{staticClass:"mb-2 font-12"},[_vm._v("الرقم الضريبي")]),_c('v-text-field',{staticClass:"setting__input__fields",attrs:{"data-test":"taxNumber","filled":"","placeholder":"الرقم الضريبي","dense":"","disabled":!_vm.hasUpdateFinancialPermission},on:{"keydown":_vm.$formatNumberInputs,"input":function($event){return _vm.formatArabicNumber($event, 'taxNumber')}},model:{value:(_vm.accountInfoUser.taxNumber),callback:function ($$v) {_vm.$set(_vm.accountInfoUser, "taxNumber", $$v)},expression:"accountInfoUser.taxNumber"}})],1),_c('v-col',{staticClass:"pl-3 mobile__pl__0 pb-0 mobile__pt__0",attrs:{"cols":"12","md":"6"},on:{"click":_vm.checkingAccessibility}},[_c('h6',{staticClass:"mb-2 font-12"},[_vm._v("\n          إضافة ضريبة علي الأتعاب والرسوم والعمولات المحتسبة علي العملاء ؟\n        ")]),_c('v-select',{staticClass:"font-14",attrs:{"disabled":_vm.isEnterdCommercialNumberAndTaxNumber ||
            !_vm.hasUpdateFinancialPermission,"items":_vm.PmCommissionOptions,"menu-props":{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          },"placeholder":`لا`,"append-icon":"mdi-chevron-down","dense":"","filled":"","item-text":"name","item-value":"value","return-value":""},model:{value:(_vm.accountInfoEnv.hasCommissionTax),callback:function ($$v) {_vm.$set(_vm.accountInfoEnv, "hasCommissionTax", $$v)},expression:"accountInfoEnv.hasCommissionTax"}})],1),_c('v-col',{staticClass:"pl-3 mobile__pl__0 pb-0 mobile__pt__0",attrs:{"cols":"12","md":"6"}},[_c('h6',{staticClass:"mb-2 font-12"},[_vm._v("إظهار الحسابات البنكية؟")]),_c('v-select',{staticClass:"font-14",attrs:{"disabled":!_vm.accountInfoUser.taxNumber || !_vm.hasUpdateFinancialPermission,"items":_vm.PmCommissionOptions,"menu-props":{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          },"placeholder":`لا يوجد`,"append-icon":"mdi-chevron-down","dense":"","filled":"","hide-details":"","item-text":"name","item-value":"value","return-value":""},model:{value:(_vm.accountInfoEnv.showBankAccounts),callback:function ($$v) {_vm.$set(_vm.accountInfoEnv, "showBankAccounts", $$v)},expression:"accountInfoEnv.showBankAccounts"}})],1),(!_vm.hasUpdateFinancialPermission)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"informativeCard pa-1"},[_c('div',{staticClass:"holder d-flex align-center my-1"},[_c('div',{staticClass:"mx-3 my-auto"},[_c('v-img',{attrs:{"min-height":"18px","max-width":"20px","lazy-src":_vm.exclamation,"src":_vm.exclamation}})],1),_c('div',{staticClass:"text"},[_c('span',[_c('p',{staticClass:"headTitle font-14 font-weight-bold ma-0"},[_vm._v("\n                  ليس لديك صلاحيه للتعديل, قم بالتواصل مع صاحب البيئه لطلب\n                  الحصول علي اذن\n                ")])])])])])],1):_vm._e(),_c('v-btn',{staticClass:"font-weight-bold pa-5 ml-3 mr-auto font-12",attrs:{"color":"primary","large":""},on:{"click":_vm.saveDataHandler}},[_c('span',{},[_vm._v("حفظ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }