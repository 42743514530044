<template>
  <div>
    <v-form v-model="isValid" class="register">
      <p>كلمة المرور الجديدة</p>
      <v-text-field
        ref="passwordRef"
        v-model="password"
        :rules="passwordRules"
        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
        :type="showPassword ? 'text' : 'password'"
        label="كلمة المرور"
        required
        filled
        @keydown.enter="login"
        @click:append="showPassword = !showPassword"
      />

      <p>تأكيد كلمة المرور الجديدة</p>
      <v-text-field
        v-model="confirmPassword"
        name="confirmPassword"
        :rules="confirmPasswordRules"
        ref="confirmPassword"
        :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
        :type="showConfirmPassword ? 'text' : 'password'"
        label="تأكيد كلمة المرور"
        required
        filled
        @keydown.enter="login"
        @click:append="showConfirmPassword = !showConfirmPassword"
      />
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { EventBus } from '@/services/EventBus'
import { throwStatement } from '@babel/types'

export default {
  props: ['phoneNumber', 'confirmationCode'],
  data() {
    return {
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      passwordRules: [
        (v) => !!v || 'حقل كلمة المرور مطلوب',
        () =>
          (this.password && this.password.length >= 6) ||
          'يجب الا تقل كملة المرور عن 6 أحرف'
      ],
      confirmPasswordRules: [
        (value) => !!value || 'حقل تأكيد كلمة المرور مطلوب',
        (value) => value === this.password || 'كلمة المرور غير متطابقة'
      ],
      isValid: false
    }
  },
  computed: {
    ...mapState('accountState', ['configs']),
    ...mapState('userState', ['spinnerLoading', 'activeLignTab']),
    resetPasswordData() {
      const resetPasswordData = {
        phoneNumber: this.phoneNumber,
        password: this.password,
        confirmationCode: this.confirmationCode
      }
      return resetPasswordData
    },
    isPasswordValid() {
      return this.password && this.password.length < 6
    },
    isConfirmPasswordValid() {
      return this.confirmPassword && this.confirmPassword.length < 6
    },
    matchingPassword() {
      return this.password === this.confirmPassword
    }
  },
  methods: {
    ...mapActions('accountState', ['resetPassword']),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapMutations('appState', ['addNotification']),

    validate() {
      this.$emit('on-validate', this.$data, this.isValid)
      if (!this.isValid) {
        let message = 'من فضلك تأكد من جميع البيانات المطلوبة'
        if (this.isPasswordValid || this.isConfirmPasswordValid) {
          message = 'يجب الا تقل كلمة المرور عن 6 أحرف'
        }
        if (this.password && this.confirmPassword && !this.matchingPassword) {
          message = 'كلمة المرور غير متطابقة'
        }
        this.addNotification({
          text: message,
          color: 'error'
        })
      } else {
        this.setSpinnerLoading(true)
        this.resetPassword(this.resetPasswordData)
          .then(() => {
            this.addNotification({
              text: 'تم إعادة تعيين كلمة المرور بنجاح!',
              color: 'success'
            })
            this.$refs.passwordRef.clearableCallback()
            EventBus.$emit('backToLogin', { tab: 0, phone: this.phoneNumber })
          })
          .catch(() => {
            this.addNotification({
              text: 'خطأ في تعيين كلمة المرور يرجى المحاولة مجددا'
            })
          })
          .finally(() => {
            this.setSpinnerLoading(false)
          })
      }

      return this.isValid
    }
  }
}
</script>
