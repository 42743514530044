<template>
  <v-dialog
    persistent
    v-model="dialogState_"
    :max-width="isPreview ? '600px' : '700px'"
  >
    <v-card class="add-new-property">
      <v-card-title
        class="pl-2 pr-4 pt-3 pb-5 mb-5 d-flex justify-space-between custom__shadow"
      >
        <span
          class="headline ft"
          v-text="isPreview ? 'معاينة الخطاب' : title"
        ></span>
        <v-btn
          fab
          text
          color="grey"
          class="ma-0 mt-2"
          @click.prevent="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- <v-slide-y-transition> -->
      <v-card-text class="pb-0 px-0" v-if="isPreview">
        <div
          class="preview-letter pt-4 pb-10 px-10 mb-7"
          id="preview-letter"
          ref="previewLetter"
        >
          <v-row class="ma-0">
            <v-col cols="6">
              <h3 class="ma-0">{{ title }}</h3>
            </v-col>
            <v-col cols="6" class="text-left">
              <img
                :src="logo"
                width="100"
                class="letter-logo mb-n5"
                loading="lazy"
              />
            </v-col>
          </v-row>

          <v-divider class="mb-7"></v-divider>

          <div class="my-4">
            <div style="height: 10px"></div>
            <h5>نص الخطاب</h5>
            <p>{{ letterContent }}</p>
          </div>

          <v-row class="ma-0">
            <v-col cols="6" class="pb-0 pr-0 d-flex align-center" v-if="name">
              <h5 class="ma-0">الإسم</h5>
              <p class="ma-0 mr-4">{{ name }}</p>
            </v-col>

            <v-col
              cols="6"
              class="pb-0 pr-0 d-flex align-center"
              v-if="letterDate"
            >
              <h5 class="ma-0">تاريخ الخطاب</h5>
              <p class="ma-0 mr-4">{{ letterDate }}</p>
            </v-col>
          </v-row>
        </div>

        <v-card-actions class="py-4 px-7">
          <v-spacer></v-spacer>
          <v-btn
            large
            rounded
            :loading="isDownloadLoading"
            class="ma-0 mr-2 px-7"
            color="success"
            @click="createAndDownloadPDF"
          >
            <v-icon class="send-icon ml-2">mdi-cloud-download-outline</v-icon>
            <span class="tf">تحميل نسخة PDF</span>
          </v-btn>
          <v-btn large rounded class="ma-0 mr-2 px-6" color="warning">
            <v-icon class="send-icon ml-2 mr-0"
              >mdi-share-variant-outline</v-icon
            >
            <span class="tf">مشاركة</span>
          </v-btn>
          <v-btn
            large
            rounded
            class="ma-0 mr-2 px-6"
            color="grey"
            @click="isPreview = false"
          >
            <v-icon class="send-icon ml-2 mr-0">mdi-share</v-icon>
            <span class="tf">عودة للتعديل</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
      <v-card-text class="pb-0 px-0" v-if="!isPreview">
        <v-form ref="form" class="px-3" v-model="isValid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  v-model="title"
                  filled
                  :rules="[(v) => !!v || 'من فضلك ادخل عنوان الخطاب']"
                  prepend-inner-icon="mdi-card-account-details-outline"
                  label="عنوان الخطاب"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <v-dialog
                  ref="letterDateDialog"
                  v-model="letterDateModal"
                  :return-value.sync="letterDate"
                  width="320px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      clearable
                      v-model="letterDate"
                      label="تاريخ الخطاب"
                      placeholder="غير محدد التاريخ"
                      prepend-inner-icon="event"
                      readonly
                      filled
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="letterDate"
                    color="primary"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="letterDateModal = false"
                      >الغاء</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.letterDateDialog.save(letterDate)"
                      >حفظ</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  v-model="name"
                  filled
                  :rules="[(v) => !!v || 'من فضلك ادخل الإسم']"
                  prepend-inner-icon="mdi-account-outline"
                  label="الاسم"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <v-file-input
                  small-chips
                  filled
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  v-model="attachments"
                  label="شعار الشركة"
                >
                </v-file-input>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-textarea filled v-model="letterContent"> </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card-actions class="py-4 px-7">
          <v-spacer></v-spacer>
          <v-btn
            large
            rounded
            class="ma-0 ml-3 px-6"
            color="info"
            @click="isPreview = true"
          >
            <span class="tf">عرض</span>
            <v-icon class="send-icon mr-2 ml-0">mdi-eye</v-icon>
          </v-btn>

          <v-btn
            large
            rounded
            :loading="isLoading"
            width="150"
            class="ma-0 px-6"
            color="success"
            @click="saveLetter"
          >
            <span class="tf">حفظ</span>
            <v-icon class="send-icon mr-2 ml-0">mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-text>
      <!-- </v-slide-y-transition> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_add-new-property.scss'

export default {
  props: ['dialogState'],
  data() {
    return {
      isLoading: false,
      isDownloadLoading: false,
      isPreview: false,
      isValid: false,
      name: 'أشروف رضوان',
      title: 'خطاب تنبيه',
      letterContent:
        'شكرا لك على تفضيلك العقار، نتمنى أن يحوز العقار على اعجابك ونسعد بزيارتك في الوقت المناسب لك لمعاينة العقار على الحقيقة، متأكدين انه سينالك اعجابك اكثر، في انتظارك سيد نهر الأصبهاني الخرساني، لوريم ابسم عربي',
      attachments: null,
      letterDate: new Date().toISOString().substr(0, 10),
      letterDateModal: false,
      fontFile: require('@/styles/fonts/DINNextLTArabic/DINNextLTArabic-Regular.ttf'),
      fontFileBold: require('@/styles/fonts/DINNextLTArabic/DINNextLTArabic-Bold.ttf'),
      logo: require('@/assets/img/logo.png')
    }
  },
  computed: {
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('closed-add-dialog')
      setTimeout(() => {
        this.isPreview = false
      }, 500)
    },
    checkAddress(val) {
      console.log(`val:${val}`)
      this.tab0IsDisabled = !val
    },
    validate() {
      this.$refs.form.validate()
      if (this.isValid === true) {
        this.tab++
      }
    },
    saveLetter() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.$emit('closed-add-dialog')
        this.addNotification({
          text: 'تم تعديل نص الإشعار بنجاح',
          color: 'success'
        })

        this.tab = 0
      }, 1000)
    },
    previewLetter() {
      this.isPreview = true
    },
    createAndDownloadPDF() {}
  }
}
</script>

<style lang="scss">
.preview-letter {
  float: right;
  direction: rtl;
  text-align: right;
  padding: 50px;
  max-width: 700px;
}

.letter-logo {
  display: block;
  float: left;
  margin: 0;
  padding: 0;
}
</style>
