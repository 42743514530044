<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <dropdown-alt
          ref="dropDown"
          :configrations="{ name: 'إظهار العقار في هكتار*', cols: 6 }"
          :listingType="showingOption"
          :defaultValue="defaultOption"
          @filterTypes="setOption($event)"
        />
      </v-col>
    </v-row>
    <div class="mt-5">
      <div v-if="show" data-test="show" class="mr-1">
        <h5
          class="ma-0 font-12 mr-2"
          :class="{ 'red--flag': accountOptionValidation }"
        >
          إختر حساب (واحد فقط)*
        </h5>
        <v-row class="pa-1" no-gutters>
          <v-col class="ml-1" v-for="(account, i) in accounts" :key="i">
            <h6
              class="gery-tag-border text-center rounded-7 px-3 py-2 cursor ml-1 mt-2"
            >
              <div class="d-flex align-center justify-space-around">
                <h6 class="ma-0">{{ account.titleAr }}</h6>
                <v-switch
                  data-test="switch"
                  class="ma-0"
                  hide-details
                  inset
                  v-model="account.checked"
                  @change="accountSwitching(account.title)"
                ></v-switch>
              </div>
            </h6>
          </v-col>
        </v-row>
      </div>

      <v-row no-gutters>
        <v-col cols="12" md="12" v-if="hasPopulationType && isForRent">
          <dropdown-alt
            :validate="isValidationON(dropdownValidate.populationType.val)"
            @setValidate="
              setDropdownValidation(dropdownValidate.populationType.text)
            "
            @filterTypes="setBuildingDetails($event, 'populationType')"
            :configrations="{ name: 'نوع السكن' }"
            :listingType="realestatePopulations"
            ref="realestatePopulations"
            :defaultValue="addPropertyInfoPopulations"
          />
        </v-col>
        <v-col cols="12" md="12" v-if="isForRent">
          <dropdown-alt
            ref="payTypeOptions"
            :configrations="{ name: 'مدة الإيجار' }"
            :listingType="payTypeOptions"
            :defaultValue="addPropertyInfoPayType"
            :validate="isValidationON(dropdownValidate.payType.val)"
            @filterTypes="setBuildingDetails($event, 'payType')"
            @setValidate="setDropdownValidation(dropdownValidate.payType.text)"
          />
        </v-col>
      </v-row>

      <v-form ref="form" v-model="isValid">
        <v-row no-gutters class="pt-6 pb-4">
          <v-col cols="12" sm="4" class="px-1">
            <h6 :class="{ 'red--flag': spaceValidation }" class="ma-0 mb-2">
              المساحة*
            </h6>
            <v-text-field
              v-model.number="addPropertyInfo.space"
              data-test="space"
              ref="space"
              :rules="selectRules"
              filled
              placeholder="المساحة "
              required
              suffix="متر"
              min="0"
              @keydown="$formatNumberInputs($event, true)"
              hide-details
              dense
              @input="handleSpaceChanging($event)"
            />
          </v-col>
          <v-col cols="12" sm="4" class="px-1">
            <h6 :class="{ 'red--flag': priceValidation }" class="ma-0 mb-2">
              السعر الكلي *
            </h6>
            <v-text-field
              data-test="price"
              ref="price"
              @keydown="$formatNumberInputs($event, true)"
              @input="handleValueChanging($event, priceKeys)"
              :value="priceMask"
              placeholder="السعر الكلي"
              :rules="selectRules"
              filled
              required
              min="0"
              hide-details
              suffix="ريال"
              dense
              :disabled="!addPropertyInfo.space"
            />
          </v-col>
          <v-col cols="12" sm="4" class="px-1">
            <h6 :class="{ 'red--flag': priceValidation }" class="ma-0 mb-2">
              سعر المتر *
            </h6>
            <v-text-field
              data-test="price"
              ref="price"
              @keydown="$formatNumberInputs($event, true)"
              @input="handleValueChanging($event, pricePerMeterKeys)"
              :value="pricePerMeterMask"
              placeholder="سعر المتر"
              :rules="selectRules"
              filled
              required
              min="0"
              hide-details
              suffix="ريال"
              dense
              :disabled="!addPropertyInfo.space"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-row class="my-6 mx-0">
        <v-col
          class="py-0 pr-3 pl-1 mobile__p__0 mobile__mb__10px"
          cols="12"
          sm="6"
        >
          <v-card class="d-flex px-2">
            <v-switch
              data-test="ownerAgent"
              v-model="addPropertyInfo.notFromOwner"
              class="my-auto"
              inset
              dense
              small
              hide-details="auto"
            ></v-switch>
            <h6 class="mx-1">وكيل المالك</h6>
          </v-card>
        </v-col>
        <v-col
          class="py-0 pr-1 pl-3 mobile__p__0 mobile__mb__10px"
          cols="12"
          sm="6"
          v-if="showNegotiable"
        >
          <v-card class="d-flex px-2">
            <v-switch
              data-test="negotiable"
              v-model="tags"
              class="my-auto"
              inset
              dense
              small
              hide-details="auto"
              value="negotiable"
            ></v-switch>
            <h6 class="mx-1">قابل للتفاوض</h6>
          </v-card>
        </v-col>

        <v-col
          v-if="addPropertyInfo.notFromOwner"
          class="py-0 pr-1 pl-3 mobile__p__0 mobile__mb__10px"
          cols="12"
        >
          <h6 class="mx-1" :class="{ 'red--flag': authNumberValidation }">
            رقم التفويض*
          </h6>
          <v-text-field
            data-test="authorizationNumber"
            v-model="addPropertyInfo.authorizationNumber"
            placeholder="رقم التفويض"
            filled
            dense
            hide-details
            min="0"
            @input="formatEnglihshNumber($event, 'authorizationNumber')"
            @keydown="$formatNumberInputs"
            class="noArrows"
          />
        </v-col>
      </v-row>

      <span v-if="textInfo" class="font-16 d-flex">
        <img class="ml-3" :src="exclamationMark" loading="lazy" />
        <h5 class="font-12 dark__grey__color">{{ textInfo }}</h5>
      </span>

      <v-card-actions
        class="pt-4 px-0 d-flex align-center justify-space-between"
      >
        <v-btn
          @click="previousTab"
          class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
          text
        >
          <v-icon class="font-16">mdi-arrow-right</v-icon>
          <span class="mb-1 ml-1">السابق</span>
        </v-btn>
        <v-btn
          data-test="nextTab"
          @click="handleFunctionCall(nextAction.method)"
          class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
          text
        >
          <span class="mb-1 ml-1">{{ nextAction.title }}</span>
          <v-icon class="font-16">mdi-arrow-left</v-icon>
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import DropdownAlt from '../dropdownAlt'
import { mapState } from 'vuex'
import inputMasking from '@/mixins/inputMasking.js'
import { convertNumbers2English } from '@/utils/formatters'
import { debounce } from '@/helpers/debounce'

export default {
  components: {
    DropdownAlt
  },
  props: {
    propertyInfo: {
      default() {
        return {}
      }
    },
    propertyInfoValid: {},
    resetValidation: {
      default: false
    },
    realestatePopulations: {
      type: Array,
      required: true
    },
    payTypeOptions: {
      type: Array,
      required: true
    },
    realEstateConfig: {
      type: Object,
      required: true
    }
  },
  mixins: [inputMasking],
  data() {
    return {
      priceMask: '',
      pricePerMeterMask: '',
      exclamationMark: require('@/assets/img/svgs/exclamationMarkrounded.svg'),
      defaultOption: '',
      showingOption: [
        {
          _id: 0,
          text: 'إظهار'
        },
        {
          _id: 1,
          text: 'إخفاء'
        }
      ],
      accounts: [
        {
          title: 'personalAccount',
          titleAr: 'حسابي',
          checked: false,
          info: 'سيتم الإعلان عن هذا العقار في حسابك وسيتمكن الباحثين عن عقار بالتواصل معك  بخصوصه سواءا عن طريق الأتصال المباشر أو الرسائل النصية أو تطبيق واتساب '
        },
        {
          title: 'companyAccount',
          titleAr: 'حساب الشركه',
          checked: false,
          info: 'سيتم الإعلان عن هذا العقار في حسابك مالك الحساب وسيتمكن  الباحثين عن عقار بالتواصل معه  بخصوصه سواءا عن طريق الأتصال المباشر أو الرسائل النصية أو تطبيق واتساب'
        }
      ],
      nextabClicked: false,
      isValid: false,
      addPropertyInfo: {},
      show: true,
      tags: [],
      allDropDownValidation: {
        payType: {
          status: false,
          required: false
        },
        populationType: {
          status: false,
          required: false
        }
      },
      dropdownValidate: {
        payType: {
          text: 'payType',
          val: false
        },
        populationType: {
          text: 'populationType',
          val: false
        }
      }
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    priceKeys() {
      return {
        maskValKey: 'priceMask',
        parentKey: 'addPropertyInfo',
        actualValKey: 'price'
      }
    },
    pricePerMeterKeys() {
      return {
        maskValKey: 'pricePerMeterMask',
        parentKey: 'addPropertyInfo',
        actualValKey: 'pricePerMeter'
      }
    },
    selectRules() {
      return this.show ? [(v) => !!v || 'هذا الحقل مطلوب'] : []
    },
    selectedOption() {
      const res = this.accounts.find((acc) => acc.checked === true)
      return !!res
    },
    accountOptionValidation() {
      return this.nextabClicked ? !this.selectedOption : false
    },
    authNumberValidation() {
      return this.nextabClicked
        ? !this.addPropertyInfo.authorizationNumber
        : false
    },
    validateStatus() {
      const isOwnerAgent = this.addPropertyInfo.notFromOwner
        ? this.addPropertyInfo.authorizationNumber
        : true

      const isAccountOptionValid = this.show ? this.selectedOption : true

      return isAccountOptionValid && isOwnerAgent
    },
    currentUser() {
      return {
        id: this.user._id,
        name: this.user.name,
        phoneNumber: this.user.phoneNumber
      }
    },
    textInfo() {
      if (this.show) {
        const selectedOption = this.accounts.find((acc) => acc.checked === true)
        return selectedOption?.info ?? ''
      }
      return 'العقار لن يظهر في قائمة العقارات في هكتار. إختيارك لإظهار العقار في هكتار سيسهل عملية تسويقه وسيكون متاحا لأكير عدد من العملاء الباحثين عن عقار من هدا النوع'
    },
    nextAction() {
      const { isMultiBuilding } = this.realEstateConfig
      if (isMultiBuilding) {
        return {
          method: 'addRealEstate',
          title: this.propertyInfoValid ? 'تحديث' : 'إضافة'
        }
      }
      return {
        method: 'nextTab',
        title: 'التالي'
      }
    },
    hasPopulationType() {
      const { isFlat, isFloor, isChalet, isGuestHouse } = this.realEstateConfig
      return isFlat || isFloor || isChalet || isGuestHouse
    },
    isForRent() {
      return this.addPropertyInfo?.status?.nameEn === 'rent'
    },
    addPropertyInfoPopulations() {
      if (this.hasPopulationType && this.isForRent) {
        return this.addPropertyInfo?.populationType?.nameAr
      }
      return null
    },
    addPropertyInfoPayType() {
      if (this.isForRent) {
        const rentType = this.payTypeOptions.find(
          (payType) => payType._id === this.addPropertyInfo?.payType
        )
        return rentType?.text
      }
      return null
    },
    spaceValidation() {
      return this.nextabClicked ? !this.$refs?.space?.valid : false
    },
    priceValidation() {
      return this.nextabClicked ? !this.$refs?.price?.valid : false
    },
    dropDownValidation() {
      if (!this.show) {
        return true
      }
      let propertyNames = Object.values(this.allDropDownValidation)
      propertyNames = propertyNames.filter((i) => i.required)
      return propertyNames.every((i) => i.status) && this.isValid
    },
    showNegotiable() {
      const { isLand, isStorehouse } = this.realEstateConfig
      return !isLand && !isStorehouse
    }
  },
  watch: {
    propertyInfo: {
      immediate: true,
      handler() {
        this.addPropertyInfo = { ...this.propertyInfo }
        this.priceMask = this.addPropertyInfo?.price?.toLocaleString('en-US')
        this.pricePerMeterMask =
          this.addPropertyInfo?.pricePerMeter?.toLocaleString('en-US')

        if (this.propertyInfoValid) {
          // In case User is updating realestate
          this.setDefaultShowInHectar()
          if (!this.nextabClicked) {
            this.setHectarInformation()
          }
          if (this.isForRent) {
            this.allDropDownValidation.payType.status = true
            this.allDropDownValidation.payType.required = true
            if (this.hasPopulationType) {
              this.allDropDownValidation.populationType.status = true
              this.allDropDownValidation.populationType.required = true
            }
          }
        } else {
          // In case User is adding realestate
          this.defaultOption = 'إظهار'
          this.addPropertyInfo.showInHectar = true
          this.activateAccount('personalAccount')
        }
      }
    },
    resetValidation: {
      handler() {
        if (!this.propertyInfoValid) {
          Object.keys(this.dropdownValidate).forEach((v) => {
            this.dropdownValidate[v].val = false
          })
          this.nextabClicked = false
          this.$refs.form.reset()
          if (this.isForRent) {
            this.$refs.payTypeOptions.id = ''
            this.$refs.payTypeOptions.checked = false
          }
          if (this.hasPopulationType && this.isForRent) {
            this.$refs.realestatePopulations.id = ''
            this.$refs.realestatePopulations.checked = false
          }
          this.allDropDownValidation = {
            payType: {
              status: false,
              required: false
            },
            populationType: {
              status: false,
              required: false
            }
          }
          this.addPropertyInfo = {}
        }
      }
    },
    'addPropertyInfo.status': {
      immediate: true,
      handler(val) {
        if (val?.nameEn !== 'rent') {
          this.allDropDownValidation.payType.status = false
          this.allDropDownValidation.payType.required = false
          this.allDropDownValidation.populationType.status = false
          this.allDropDownValidation.populationType.required = false
          delete this.addPropertyInfo.payType
          delete this.addPropertyInfo.populationType
        } else {
          this.allDropDownValidation.payType.required = true
          if (this.hasPopulationType) {
            this.allDropDownValidation.populationType.required = true
          }
        }
      }
    },
    'addPropertyInfo.type': {
      immediate: true,
      handler(val) {
        if (!['floor', 'flat', 'Chalet', 'Guest house'].includes(val?.nameEn)) {
          this.allDropDownValidation.populationType.status = false
          this.allDropDownValidation.populationType.required = false
        } else if (this.isForRent) {
          this.allDropDownValidation.populationType.required = true
        } else {
          this.allDropDownValidation.populationType.required = false
        }
      }
    }
  },
  methods: {
    handleSpaceChanging: debounce(function (val) {
      this.formatEnglihshNumber(val, 'space')
      this.handleValuesChanging(0, this.pricePerMeterKeys)
      this.handleValuesChanging(0, this.priceKeys)
    }, 500),
    handleValueChanging: debounce(function (val, key) {
      this.handleValuesChanging(val, key)

      switch (key.actualValKey) {
        case 'price':
          this.handleValuesChanging(
            this.addPropertyInfo.price / this.addPropertyInfo.space,
            this.pricePerMeterKeys
          )
          break
        case 'pricePerMeter':
          this.handleValuesChanging(
            this.addPropertyInfo.pricePerMeter * this.addPropertyInfo.space,
            this.priceKeys
          )
          break
      }
    }, 500),
    isValidationON(val) {
      return this.show ? val : false
    },
    formatEnglihshNumber(val, key) {
      this.addPropertyInfo[key] = Number(convertNumbers2English(val))
    },
    accountSwitching(option) {
      this.accounts.map((acc) => {
        if (acc.title !== option) {
          acc.checked = false
        }
      })
    },
    resetOptions() {
      this.accounts.map((acc) => {
        if (acc.title === 'personalAccount') {
          acc.checked = true
        } else {
          acc.checked = false
        }
      })
    },
    setOption(e) {
      if (e.text === 'إظهار') {
        this.show = true
        this.addPropertyInfo.showInHectar = true
      } else {
        this.show = false
        this.addPropertyInfo.showInHectar = false
        this.resetOptions()
      }
    },
    nextTab() {
      Object.keys(this.dropdownValidate).forEach((v) => {
        this.dropdownValidate[v].val = true
      })
      this.nextabClicked = true
      if (this.validateStatus && this.dropDownValidation) {
        if (this.addPropertyInfo.hasOwnProperty('notFromOwner')) {
          this.$set(
            this.addPropertyInfo,
            'fromOwner',
            !this.addPropertyInfo.notFromOwner
          )
        }
        this.addPropertyInfo.tags = this.tags
        this.setAccount()
        this.$emit('setAddPropertyInfo', this.addPropertyInfo)
        this.$emit('nextTab')
      } else {
        this.$emit('addNotification')
      }
    },
    addRealEstate() {
      Object.keys(this.dropdownValidate).forEach((v) => {
        this.dropdownValidate[v].val = true
      })
      this.nextabClicked = true
      if (this.validateStatus && this.dropDownValidation) {
        if (this.addPropertyInfo.hasOwnProperty('notFromOwner')) {
          this.$set(
            this.addPropertyInfo,
            'fromOwner',
            !this.addPropertyInfo.notFromOwner
          )
        }
        this.addPropertyInfo.tags = this.tags
        this.setAccount()
        this.$emit('setAddPropertyInfo', this.addPropertyInfo)
        if (this.propertyInfoValid) {
          this.$emit('updateRealEstate')
        } else {
          this.$emit('addRealEstate')
        }
      } else {
        this.$emit('addNotification')
      }
    },
    previousTab() {
      this.$emit('previousTab')
    },
    setDefaultShowInHectar() {
      if (!this.addPropertyInfo.showInHectar) {
        this.defaultOption = 'إخفاء'
        this.show = false
      } else {
        this.defaultOption = 'إظهار'
        if (
          this.addPropertyInfo.account?.id === this.user._id &&
          this.addPropertyInfo.account?.id === this.currentEnv.owner.id
        ) {
          this.activateAccount('personalAccount')
        } else if (this.addPropertyInfo.account?.id === this.user._id) {
          this.activateAccount('personalAccount')
        } else {
          this.activateAccount('companyAccount')
        }
        this.show = true
      }
    },
    setAccount() {
      if (this.show) {
        const accountOption = this.accounts.find((account) => account.checked)
        this.addPropertyInfo.showHectarAccount =
          accountOption.title === 'personalAccount'
            ? this.currentUser
            : this.currentEnv.owner
      } else if (this.addPropertyInfo.showHectarAccount) {
        delete this.addPropertyInfo.showHectarAccount
      }
    },
    activateAccount(accountTitle) {
      this.accounts = this.accounts.map((account) => {
        if (account.title === accountTitle) {
          account.checked = true
        } else {
          account.checked = false
        }
        return account
      })
    },
    handleFunctionCall(functionName) {
      this[functionName]()
    },
    setHectarInformation() {
      this.tags = this.addPropertyInfo.tags || []
      if (this.addPropertyInfo.hasOwnProperty('fromOwner')) {
        this.$set(
          this.addPropertyInfo,
          'notFromOwner',
          !this.addPropertyInfo.fromOwner
        )
      }
      if (this.addPropertyInfo.hasOwnProperty('ad')) {
        this.$set(
          this.addPropertyInfo,
          'authorizationNumber',
          this.addPropertyInfo.ad?.authorizationNumber
        )
      }
    },
    setDropdownValidation(key) {
      this.dropdownValidate[key].val = true
    },
    setBuildingDetails(e, key) {
      this.$set(this.addPropertyInfo, key, e)
      this.allDropDownValidation[key].status = true
    }
  }
}
</script>
