<template>
  <!--Loader-->
  <v-row>
    <v-col
      :key="i"
      :class="laoderClasses"
      :cols="12"
      :sm="getColsNUmber('sm')"
      :md="getColsNUmber('md')"
      :lg="getColsNUmber('lg')"
      v-for="i in numberOfLines"
    >
      <v-skeleton-loader
        class="mx-auto"
        max-width="100%"
        :type="type"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'loader',
  props: {
    numberOfLines: {
      default: 1
    },
    type: {
      default: 'list-item-avatar-two-line'
    },
    laoderClasses: {
      default: 'ma-0 pa-0 shadow-border-radius'
    },
    cols: {
      default: 0
    }
  },
  data() {
    return {
      colsTypes: {
        sm: 6,
        md: 4,
        lg: 3
      }
    }
  },
  methods: {
    getColsNUmber(widthProperty) {
      if (this.cols) return this.cols
      else return this.colsTypes[widthProperty]
    }
  }
}
</script>
<style lang="scss">
.v-dialog__content.v-dialog__content--active
  div.v-skeleton-loader__image.v-skeleton-loader__bone {
  height: 100px !important;
}
</style>
