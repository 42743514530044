<!-- eslint-disable vue/return-in-computed-property -->
<template>
  <v-form ref="form" v-model="isValid">
    <!-- Details -->
    <h6 class="ma-0 mb-4">تفاصيل التعاقد</h6>
    <v-row no-gutters>
      <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
        <h6 class="ma-0 mb-2">عقار*</h6>
        <autocomplete
          v-bind="realestateInputProps"
          v-model="contractInfo.realEstate"
          @input="getAvaliableUnits($event)"
        />
      </v-col>
      <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
        <h6 class="ma-0 mb-2">واحدات/وحدة*</h6>
        <autocomplete
          v-model="contractInfo.availableUnits"
          v-bind="unitsInputProps"
        />
      </v-col>

      <!--  -->
      <v-col class="pl-3 mobile__pl__0" cols="6" sm="3">
        <h6 class="mt-4 mb-2">تاريخ البداية*</h6>
        <v-dialog
          :return-value.sync="contractInfo.start"
          ref="startAtDialog"
          v-model="startAtModal"
          width="320px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :rules="[(v) => !!v || 'من فضلك ادخل تاريخ البداية']"
              append-icon="mdi-calendar-month-outline"
              dense
              filled
              hide-details
              placeholder="تاريخ البداية"
              readonly
              required
              v-bind="attrs"
              v-model="contractInfo.start"
              v-on="on"
              class="date--dropdown font-14"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            scrollable
            v-model="start"
            @change="calculateEndDate"
            :min="isPendingRenewal ? updatedContract.start : undefined"
          >
            <v-spacer></v-spacer>
            <v-btn @click="startAtModal = false" color="primary" text>
              الغاء
            </v-btn>
            <v-btn @click="$refs.startAtDialog.save(start)" color="primary" text
              >حفظ
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <!--  -->
      <v-col class="pl-3 mobile__pl__0" cols="6" sm="3">
        <h6 class="mt-4 mb-2">تاريخ النهاية*</h6>
        <v-dialog
          :return-value.sync="contractInfo.end"
          ref="endAtDialog"
          v-model="endAtModal"
          width="320px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :rules="[(v) => !!v || 'من فضلك ادخل تاريخ النهاية']"
              append-icon="mdi-calendar-month-outline"
              dense
              filled
              hide-details
              placeholder="تاريخ النهاية"
              readonly
              required
              v-bind="attrs"
              v-model="contractInfo.end"
              v-on="on"
              class="date--dropdown font-14"
              :disabled="!contractInfo.start"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            :min="minEndDate"
            scrollable
            v-model="end"
            @change="handleEndDateChange"
          >
            <v-spacer></v-spacer>
            <v-btn @click="endAtModal = false" color="primary" text>
              الغاء
            </v-btn>
            <v-btn @click="$refs.endAtDialog.save(end)" color="primary" text
              >حفظ
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
        <h6 class="ma-0 mt-4 mb-2">فترة العقد*</h6>
        <v-row>
          <v-col cols="4 pl-1">
            <v-text-field
              :rules="[validateYear]"
              dense
              filled
              placeholder="0"
              suffix="سنة"
              class="font-14"
              v-model="years"
              :disabled="!this.contractInfo.start"
              @input="formatArabicNumber($event, 'years')"
              @keydown="$formatNumberInputs"
            />
          </v-col>
          <v-col cols="4 px-1">
            <v-text-field
              :rules="[validateMonth]"
              dense
              filled
              placeholder="0"
              suffix="شهر"
              class="font-14"
              v-model="months"
              :disabled="!this.contractInfo.start"
              @input="formatArabicNumber($event, 'months')"
              @keydown="$formatNumberInputs"
            />
          </v-col>
          <v-col cols="4 pr-1">
            <v-text-field
              :rules="[validateDay]"
              dense
              filled
              placeholder="0"
              suffix="يوم"
              class="font-14"
              v-model="days"
              :disabled="!this.contractInfo.start"
              @input="formatArabicNumber($event, 'days')"
              @keydown="$formatNumberInputs"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="mb-2 mt-0">
          {{ collectionIntervalsText }}
        </h6>
        <v-select
          :items="collectionIntervals"
          :menu-props="{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          }"
          :placeholder="collectionIntervalsText"
          :disabled="!contractDuration"
          :rules="[(v) => !!v || `اختر الفترة`]"
          append-icon="mdi-chevron-down"
          dense
          filled
          hide-details
          item-text="nameAr"
          item-value="value"
          required
          v-model="contractInfo.collectionInterval"
        ></v-select>
      </v-col>
      <v-col
        class="pl-3 mobile__pl__0 mb-2"
        cols="12"
        sm="6"
        v-if="isRentContract"
      >
        <h6 class="mb-2 mt-0">
          إضافة قيمة الضريبة المضافة لقيم الإيجارات المحصلة
        </h6>
        <v-card class="d-flex justify-space-between px-2 mx-2">
          <h6 class="mx-1 font-14">
            {{ hasTax ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            dense
            hide-details="auto"
            inset
            small
            v-model="hasTax"
          />
        </v-card>
      </v-col>
      <v-col class="pl-3 mb-2" cols="12" sm="6" v-if="isMaintenanceContract">
        <h6 class="mb-2 ma-0">* السعر المتفق علبه</h6>
        <v-text-field
          :required="isMaintenanceContract"
          :rules="[(v) => !!v || 'من فضلك أدخل السعر']"
          dense
          filled
          hide-details
          placeholder="السعر المتفق علبه"
          suffix="ريال"
          @input="handleValuesChanging($event, amountKeys)"
          :value="contractAmountMask"
          @keydown="$formatNumberInputs($event, true)"
          class="font-14"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- marketingPercentage -->
    <!-- Temporary remove (Business need)-->
    <v-row v-if="false">
      <v-col cols="12">
        <dropdown-alt
          ref="'marketing-percentage'"
          :configrations="{
            name: 'أتعاب التسويق والتأجير',
            subTitle: 'إختياري'
          }"
          :listingType="requestType"
          @filterTypes="setMarketingPercentage"
          class="marketing--percentage--dropdown"
        />
      </v-col>
      <template v-if="marketingPercentage">
        <v-col cols="12" sm="6" class="pl-3 mobile__pl__0 flex-grow-1">
          <h6 class="ma-0 mb-2">
            نسبة الأتعاب من <strong class="mr-1"> الإيجار السنوي</strong>
          </h6>
          <v-text-field
            class="font-14"
            dense
            filled
            hide-details
            required
            placeholder="قيمة النسبة"
            suffix="%"
            :disabled="!contractInfo.collectionInterval"
            @keydown="$formatNumberInputs($event, true)"
            v-model.number="contractInfo.tempVal"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="pl-3 mobile__pl__0">
          <h6 class="ma-0 mb-2">طريقة حساب الأتعاب*</h6>
          <v-select
            :items="feePercentage"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            :placeholder="`اختر  نسبة الأتعاب `"
            :rules="[(v) => !!v || `اختر نسبة الأتعاب`]"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            item-text="nameAr"
            item-value="value"
          />
          <!-- To be checked with the business -->
          <!-- @input="collectionInterval" -->
          <!-- v-model="contractInfo.duration" -->
          <!-- @change="$emit('setContractDuration', collectionIntervals)" -->
        </v-col>
      </template>
      <template v-if="marketingFixed">
        <v-col class="pl-3 mobile__pl__0" cols="6">
          <h6 class="ma-0 mb-2">
            مبلغ العمولة الثابتة من
            <strong class="mr-1">الإيجار السنوي </strong>
          </h6>
          <v-text-field
            :disabled="!contractInfo.collectionInterval"
            :rules="[
              (v) => (fixedCommission ? !!v && !!contractInfo.fixed : true)
            ]"
            dense
            filled
            hide-details
            placeholder="مبلغ العمولة الثابتة"
            required
            suffix="ريال"
            @input="handleValuesChanging($event, fixedValueKeys)"
            :value="contractFixedMask"
            @keydown="
              $allowOnlyDecimalNumbersCommas($event, contractInfo.fixed)
            "
            class="font-14"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="pl-3 mobile__pl__0">
          <h6 class="ma-0 mb-2">طريقة حساب الأتعاب*</h6>
          <v-select
            :items="feePercentage"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            :placeholder="`اختر  نسبة الأتعاب `"
            :rules="[(v) => !!v || `اختر نسبة الأتعاب`]"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            item-text="nameAr"
            item-value="value"
          />
          <!-- To be checked with the business -->
          <!-- @input="collectionInterval" -->
          <!-- v-model="contractInfo.duration" -->
          <!-- @change="$emit('setContractDuration', collectionIntervals)" -->
        </v-col>
      </template>
    </v-row>

    <!-- propertyPercentage -->
    <h6 class="mt-6 mb-0" v-if="false">أتعاب إدارة الأملاك</h6>
    <v-row no-gutters class="my-2 mb-5" v-if="false">
      <v-col cols="12" sm="6">
        <v-card class="px-2" height="40">
          <h5 class="mx-1 font-weight-regular">نسبه الشركة</h5>
          <v-switch
            class="ma-auto client__filter__switch mt-2 maintenance-switch"
            v-model="companyPercentage"
            inset
            dense
            small
            hide-details="auto"
          ></v-switch>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="mr-2 px-2 client--contract--radio--btns" height="40">
          <h5 class="mx-1 font-weight-regular">العمولة الثابتة</h5>
          <v-switch
            class="ma-auto client__filter__switch mt-2 maintenance-switch"
            v-model="fixedCommission"
            inset
            dense
            small
            hide-details="auto"
          ></v-switch>
        </v-card>
      </v-col>
      <v-col v-if="companyPercentage" cols="12" class="pl-3 mobile__pl__0">
        <h6 class="mb-2">نسبه الشركة من المحصلات*</h6>
        <v-text-field
          class="font-14"
          dense
          filled
          hide-details
          required
          placeholder="نسبه الشركة من المحصلات"
          suffix="%"
          type="number"
          :disabled="!contractInfo.collectionInterval"
          :rules="[
            (v) => (companyPercentage ? !!v && !!contractInfo.percentage : true)
          ]"
          @keydown="$formatNumberInputs($event, true)"
          v-model.number="contractInfo.percentage"
        ></v-text-field>
      </v-col>
      <template v-if="fixedCommission">
        <v-col class="pl-3 mobile__pl__0" cols="6">
          <h6 class="mb-2">مبلغ العمولة الثابتة*</h6>
          <v-text-field
            :disabled="!contractInfo.collectionInterval"
            :rules="[
              (v) => (fixedCommission ? !!v && !!contractInfo.fixed : true)
            ]"
            dense
            filled
            hide-details
            placeholder="مبلغ العمولة الثابتة"
            required
            suffix="ريال"
            @input="handleValuesChanging($event, fixedValueKeys)"
            :value="contractFixedMask"
            @keydown="
              $allowOnlyDecimalNumbersCommas($event, contractInfo.fixed)
            "
            class="font-14"
          ></v-text-field>
        </v-col>
        <v-col class="pl-3 mobile__pl__0" cols="6">
          <h6 class="mb-2">فترة تحصيل العمولة الثابتة</h6>
          <v-select
            :disabled="!contractDuration"
            :items="collectionIntervals"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            placeholder="فترة تحصيل العمولة الثابتة"
            :rules="[
              (v) =>
                fixedCommission ? !!v && !!contractInfo.commissionPeriod : true
            ]"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            item-text="nameAr"
            item-value="value"
            required
            v-model.number="contractInfo.commissionPeriod"
          ></v-select>
        </v-col>
      </template>
      <!-- Temporary remove (Business need)-->
      <template v-if="false">
        <v-col cols="12" sm="6" class="mt-3 pl-3 mobile__pl__0">
          <h6 class="ma-0 mb-2">طريقة حساب أتعاب إدارة الأملاك*</h6>
          <v-select
            :items="pmFeeApproches"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            :placeholder="`اختر طريقة حساب أتعاب إدارة الأملاك `"
            :rules="[(v) => !!v || `اختر طريقة حساب أتعاب إدارة الأملاك`]"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            item-text="nameAr"
            item-value="value"
          />
        </v-col>
      </template>
      <v-col cols="12" sm="12" class="mt-4" v-if="managementMaintenance">
        <v-layout class="mx-10" justify-space-between>
          <v-card
            :class="{
              'brub--tag-border': isSubTypeContractChecked(maintenanceType._id)
            }"
            v-for="maintenanceType in subContractTypes"
            :key="maintenanceType._id"
            width="125"
          >
            <v-checkbox
              :value="maintenanceType._id"
              class="ma-0 contract mr-1"
              dense
              hide-details
              v-model="selectedContractSuptypes"
              :rules="subContractTypeRule"
            >
              <template v-slot:label>
                <div class="text-center">
                  <h5
                    :class="{
                      'text-primary': isSubTypeContractChecked(
                        maintenanceType._id
                      )
                    }"
                    class="font-weight-medium mt-0 pt-1 mb-2"
                  >
                    {{ maintenanceType.nameAr }}
                  </h5>
                </div>
              </template>
            </v-checkbox>
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4" v-if="isRentContract">
      <v-col class="pl-3 mobile__pl__0 pt-0 mb-2" cols="12" sm="6">
        <h6 class="ma-0 mb-2">قيمة الإيجار السنوي</h6>
        <v-text-field
          :disabled="!contractInfo.collectionInterval"
          ref="annualRate"
          class="font-14"
          dense
          filled
          hide-details
          placeholder="قيمة الإيجار السنوي"
          suffix="ريال"
          @input="handleTotalValue"
          :value="contractTotalValueMask"
          @keydown="$formatNumberInputs($event, true)"
        ></v-text-field>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 pt-0 mb-2" cols="12" sm="3">
        <h6 class="ma-0 mb-2">*قيمة دفعه التحصيل</h6>
        <v-text-field
          :required="isRentContract"
          :disabled="true"
          :rules="[(v) => !!v || 'من فضلك ادخل قيمة الايجار']"
          dense
          filled
          hide-details
          placeholder="قيمة الدفعه الواحده"
          suffix="ريال"
          :value="payOnceValue"
          class="font-14"
        ></v-text-field>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 pt-0 mb-2" cols="12" sm="3">
        <h6 class="ma-0 mb-2">*قيمة الايجار الكليه</h6>
        <v-text-field
          :disabled="!contractInfo.collectionInterval"
          :required="isRentContract"
          :rules="[(v) => !!v || 'من فضلك ادخل قيمة الايجار']"
          dense
          filled
          hide-details
          placeholder="قيمة الايجار الكلي"
          suffix="ريال"
          @input="updateAnuualRateData($event)"
          :value="contractRentValueMask"
          @keydown="$formatNumberInputs($event, true)"
          class="font-14"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- flexible installments -->
    <v-row no-gutters v-if="isRentContract && years >= 1" class="mt-3">
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="ma-0 mb-2">هل توجد زيادة سنوية على مبلغ الإيجار؟</h6>
        <v-card class="d-flex justify-space-between px-2 mx-2">
          <h6 class="mx-1 font-14">
            {{ hasFlexibleInstallments ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            dense
            hide-details="auto"
            inset
            small
            v-model="hasFlexibleInstallments"
          />
        </v-card>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="3">
        <h6 class="ma-0 mb-2">نوع الزيادة السنوية</h6>
        <v-select
          :items="flexibleInstallments"
          :menu-props="{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          }"
          placeholder="نوع الزيادة السنوية"
          append-icon="mdi-chevron-down"
          dense
          filled
          hide-details
          item-text="nameAr"
          item-value="nameEn"
          :disabled="!hasFlexibleInstallments"
          v-model="contractInfo.increaseType"
        ></v-select>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="3">
        <h6 class="mb-2 mt-0">
          *{{
            showPercentage ? 'نسبة الزيادة السنوية' : 'قيمة الزيادة السنوية'
          }}
        </h6>
        <v-text-field
          class="font-14"
          dense
          filled
          hide-details
          required
          :placeholder="
            showPercentage ? 'نسبة الزيادة السنوية' : 'قيمة الزيادة السنوية'
          "
          :disabled="!hasFlexibleInstallments"
          :rules="[
            (v) => (companyPercentage ? !!v && !!contractInfo.percentage : true)
          ]"
          @keydown="$formatNumberInputs($event, true)"
          :suffix="showPercentage ? '%' : 'ريال'"
          v-model.number="contractIncreaseMask"
          @input="handleValuesChanging($event, increaseValueKeys)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="my-2 d-flex algin-center px-5" v-if="isRentContract">
      <v-col class="px-0 d-flex align-center mb-2" cols="3" sm="4">
        <v-divider></v-divider>
      </v-col>
      <v-col class="px-2 mb-2" cols="12" sm="4">
        <div class="borderedContainer text-center py-1">
          <p class="ma-0 pa-0 font-14">
            قيمه نسبه الشركة من {{ pmContractCompanyPrecentage }} %
          </p>
          <p class="ma-0 pa-0 font-14 font-weight-bold">
            {{ companyValueInRyal }} ريال
          </p>
        </div>
      </v-col>
      <v-col class="px-0 d-flex align-center mb-2" cols="3" sm="4">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="my-2" v-if="isRentContract">
      <v-col cols="12" sm="12">
        <v-card class="informativeCard pa-2">
          <div class="holder d-flex align-center">
            <div class="mr-3 ml-5">
              <v-img :src="exclamation" />
            </div>
            <div class="text">
              <span>
                <p class="headTitle font-14 font-weight-bold ma-0">
                  {{ companyPercentageText }}
                </p>
              </span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="my-2 file-continer" v-if="isRentContract">
      <v-col cols="12" sm="6">
        <h6 class="mt-0 mb-2">رقم العقد الإلكترونى في منصة إيجار (إختياري)</h6>
        <v-text-field
          class="pb-2"
          dense
          filled
          placeholder="رقم العقد الإلكترونى في منصة إيجار"
          hide-details
          v-model="contractInfo.electronicRent.electronicNumber"
        />
      </v-col>
      <v-col cols="12" sm="6" class="file-continer-col">
        <h6 class="mt-0 mb-2">إرفاق العقد الإلكترونى (إختياري)</h6>
        <v-file-input
          label=""
          placeholder="إضغط لإضافة مرفق (واحد فقط)"
          outlined
          @change="uploadEjarContract"
          prepend-icon=""
          dense
          append-icon="mdi-upload"
        ></v-file-input>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import DropdownAlt from '@/components/add-unit/dropdownAlt'
import autocomplete from '@/components/forms/autocomplete'
import { debounce } from '@/helpers/debounce'
import inputMasking from '@/mixins/inputMasking.js'
import { contractsService } from '@/services'
import { convertNumbers2English } from '@/utils/formatters'
import { add, format, intervalToDuration } from 'date-fns'
import { mapState } from 'vuex'

export default {
  name: 'contractDetails',
  components: { autocomplete, DropdownAlt },
  props: {
    isPendingRenewal: {
      default: false,
      type: Boolean
    },
    isUnitLoading: {
      default: false
    },
    isRentContract: {
      default: false,
      required: true
    },
    isMaintenanceContract: {
      default: false,
      required: true
    },
    isPmContract: {
      default: false,
      required: true
    },
    availableRealEstates: {
      default() {
        return []
      },
      require: true
    },
    availableUnitsForParent: {
      default() {
        return []
      },
      require: false
    },
    subContractTypes: {
      default: []
    },
    exclamation: {
      default: require('@/assets/img/svgs/exclamationMark.svg')
    },
    contractIntervals: {
      default: [],
      required: true
    },
    defaultData: {
      default() {
        return {}
      }
    },
    updatedContract: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    },
    contrcatType: {
      default: ''
    }
  },
  mixins: [inputMasking],
  data() {
    return {
      contractAmountMask: '',
      contractFixedMask: '',
      contractRentValueMask: '',
      contractTotalValueMask: '',
      contractIncreaseMask: '',
      contractInfo: {
        availableUnits: [{ name: 'كامل العقار', allocated: true }],
        realEstate: {},
        electronicRent: {
          rentValue: '',
          electronicNumber: '',
          electronicImage: ''
        }
      },
      ejarContratNum: '',
      isValid: false,
      noData: 'لا توجد عقارات متاحه لهذا البحث',
      validateString: 'من فضلك ادخل اسم العقار',
      selectedRelestatePlaceholder: 'اختر العقار',
      propertyToShow: 'name',
      pmContractCompanyPrecentage: 0,
      startAtModal: false,
      start: new Date().toISOString().substr(0, 10),
      end: new Date().toISOString().substr(0, 10),
      endAtModal: false,
      formattedEndDate: new Date(),
      collectionIntervals: [],
      companyPercentage: false,
      fixedCommission: false,
      managementMaintenance: false,
      selectedContractSuptypes: [],
      contractTypesNameAr: {
        rent: 'إيجار',
        propertyManagement: 'إدارة أملاك',
        maintenance: 'صيانة'
      },
      availableUnits: [],
      hasTax: false,
      annualRate: 0,
      requestType: [
        {
          _id: 1,
          text: 'نسبة',
          val: 'percentage'
        },
        {
          _id: 2,
          text: 'عمولة ثابتة',
          val: 'fixed'
        }
      ],
      marketingPercentage: false,
      marketingFixed: false,
      feePercentage: ['على أول عقد إيجار', 'على كل عقود الإيجار'],
      pmFeeApproches: [
        'مع حساب عمولة التسويق والتأجير',
        'بعد حساب عمولة التسويق والتأجير'
      ],
      years: 0,
      months: 0,
      days: 0,
      hasFlexibleInstallments: false,
      flexibleInstallments: [
        {
          nameAr: 'زيادة بمبلغ ثابت',
          nameEn: 'fixed'
        },
        {
          nameAr: 'زيادة بنسبة ثابتة',
          nameEn: 'percentage'
        }
      ]
    }
  },
  methods: {
    async uploadEjarContract(file) {
      const fd = new FormData()
      fd.append('attachment', file, file.name)
      fd.append('envId', this.currentEnv._id)
      fd.append('entityType', 'contract')
      const {
        data: { id }
      } = await contractsService.addAttachment(fd)
      this.contractInfo.attachment = id
    },
    handleEndDateChange() {
      this.calculatePeriod()
      this.collectionInterval()
      this.$emit('setContractDuration', this.collectionIntervals)
    },
    formatArabicNumber(val, key, child = '') {
      if (!child) {
        this[key] = Number(convertNumbers2English(val).toLocaleString('en-US'))
        this.calculateEndDate()
      } else {
        this[key][child] = Number(
          convertNumbers2English(val).toLocaleString('en-US')
        )
      }
    },
    validateYear(v) {
      const stringValue = String(v)
      return (
        ((stringValue || stringValue === 0) &&
          (!stringValue.length ||
            (!!stringValue.length && stringValue >= 0 && stringValue <= 99))) ||
        '0 - 99'
      )
    },
    validateMonth(v) {
      const stringValue = String(v)
      return (
        ((stringValue || stringValue === 0) &&
          (!stringValue.length ||
            (!!stringValue.length && stringValue >= 0 && stringValue <= 11))) ||
        '0 - 11'
      )
    },
    validateDay(v) {
      const stringValue = String(v)
      return (
        ((stringValue || stringValue === 0) &&
          (!stringValue.length ||
            (!!stringValue.length && stringValue >= 0 && stringValue <= 30))) ||
        '0 - 30'
      )
    },
    calculateEndDate() {
      const res = add(new Date(this.start), {
        years: this.years ?? 0,
        months: this.months ?? 0,
        days: this.days ? this.days + 1 : 0,
        seconds: -1,
        minutes: new Date().getTimezoneOffset()
      })
      this.end = format(res, 'yyyy-MM-dd')

      this.formattedEndDate = res.toISOString()
      this.$set(this.contractInfo, 'end', this.end)
      this.collectionInterval()
      this.$emit('setContractDuration', this.collectionIntervals)
      this.getPmContractCompantPercentage(this.contractInfo.realEstate)
    },
    calculatePeriod() {
      const { days, months, years } = intervalToDuration({
        start: new Date(this.start),
        end: new Date(this.end)
      })
      this.years = years
      this.months = months
      this.days = days
      this.formattedEndDate = new Date(this.end).toISOString()
      this.getPmContractCompantPercentage(this.contractInfo.realEstate)
    },
    updateAnuualRateData(val) {
      const formatTotalValue = isNaN(val)
        ? Number(convertNumbers2English(val.replaceAll(',', '')))
        : val
      if (typeof formatTotalValue === 'undefined') {
        return
      }
      this.handleValuesChanging(formatTotalValue, this.rentValueKeys)
      if (!this.contractDuration || !this.contractInfo.rentValue) {
        this.annualRate = 0
      } else if (this.contractDuration === 0.0333) {
        this.annualRate = this.contractInfo.rentValue * 365
      } else if (this.contractDuration <= 12) {
        this.annualRate =
          this.contractInfo.rentValue * (12 / this.contractDuration)
      } else {
        this.annualRate = Number(
          (this.contractInfo.rentValue / (this.contractDuration / 12)).toFixed(
            3
          )
        )
      }
      this.handleValuesChanging(this.annualRate, this.totalValueKeys)
    },
    setavailableUnits(availableUnits) {
      this.availableUnits = availableUnits
    },
    handleTotalValue(totalValue) {
      const formatTotalValue = convertNumbers2English(totalValue)
      this.handleValuesChanging(formatTotalValue, this.totalValueKeys)
      let val = 0
      if (this.contractDuration === 0.0333) {
        val = Number(this.contractInfo?.annualRate) / 365
      } else if (this.contractDuration <= 12) {
        val =
          Number(this.contractInfo?.annualRate) / (12 / this.contractDuration)
      } else {
        val =
          Number(this.contractInfo?.annualRate) * (this.contractDuration / 12)
      }
      this.handleValuesChanging(val, this.rentValueKeys)
    },

    async handlingContractDefaultValues() {
      if (this.defaultValuesCheck) {
        if (this.defaultData.hasOwnProperty('owner')) {
          this.setContractRealestateDefaultValues(this.defaultData)
          await this.getPmContractCompantPercentage(this.defaultData)
        }
      }
    },
    setContractRealestateDefaultValues(realestate) {
      // Set realestate values as default
      this.$emit('pushToAvailableRealEstates', realestate)
      this.contractInfo.realEstate = realestate
    },
    resetSelectedContractSuptypes(switchVal) {
      if (!switchVal) {
        this.selectedContractSuptypes = []
      }
    },
    isSubTypeContractChecked(id) {
      return !!this.selectedContractSuptypes.includes(id)
    },
    async getAvaliableUnits(realestate) {
      this.contractInfo.availableUnits = []
      this.$emit('resetRealEstatesUnits')
      if (this.isRentContract && realestate?.unitsCount) {
        this.$emit('getRealEstates', { parentId: realestate._id })
      }
    },
    getPmContractCompantPercentage: debounce(async function () {
      const { realEstate } = this.contractInfo
      if (this.isRentContract && realEstate) {
        let query = `realEstate=${realEstate._id}&environment=${this.currentEnv._id}&startDate=${this.start}&endDate=${this.end}`
        query += realEstate?.parentId?.length
          ? `&parentId=${realEstate?.parentId}`
          : ''
        const response = await contractsService.getPmContract(query)
        this.pmContractCompanyPrecentage =
          response?.data?.contract?.percentage || 0
      }
    }, 500),
    collectionInterval() {
      delete this.contractInfo.collectionInterval
      this.contractTotalValueMask = null
      this.contractRentValueMask = null
      delete this.contractInfo.rentValue
      this.collectionIntervals = this.contractIntervals?.filter((interval) => {
        return interval.value <= 12 && this.contractDuration >= interval.value
      })
      return this.collectionIntervals
    },
    bindContractData() {
      this.$emit('bindContractData', this.dataToBeBinded())
    },
    resetData() {
      this.contractInfo = {}
    },
    presetData() {
      this.editMoodPresetData()
      this.handlingContractDefaultValues()
      this.setLastTabStatus()
    },
    // TODO:
    editMoodPresetData() {
      if (this.isEditMood) {
        this.$set(this.contractInfo, 'duration', this.updatedContract?.duration)
        this.$emit('pushToAvailableRealEstates')
        this.$set(
          this.contractInfo,
          'realEstate',
          this.updatedContract?.realEstate
        )
        this.$set(
          this.contractInfo,
          'rentValue',
          this.updatedContract?.rentValue
        )
        this.contractRentValueMask = Number(
          this.updatedContract?.rentValue
        ).toLocaleString('en-US')

        this.$set(
          this.contractInfo,
          'start',
          this.updatedContract?.start.slice(
            -this.updatedContract?.start.length,
            10
          )
        )
        this.start = this.contractInfo?.start

        this.$set(
          this.contractInfo,
          'end',
          this.updatedContract?.end.slice(-this.updatedContract?.end.length, 10)
        )
        this.end = this.contractInfo?.end

        this.calculatePeriod()

        this.collectionInterval()

        this.$set(
          this.contractInfo,
          'collectionInterval',
          this.updatedContract?.collectionInterval
        )

        this.contractInfo.annualRate = this.updatedContract.annualRate
        this.contractTotalValueMask = new Intl.NumberFormat('en-IN', {
          maximumSignificantDigits: 2
        }).format(this.contractInfo.annualRate)

        this.hasFlexibleInstallments =
          this.updatedContract?.hasFlexibleInstallments

        this.$set(
          this.contractInfo,
          'increaseType',
          this.updatedContract?.increaseType
        )

        if (this.updatedContract?.fixed) {
          this.$set(this.contractInfo, 'fixed', this.updatedContract?.fixed)
          this.handleValuesChanging(
            this.contractInfo.fixed,
            this.fixedValueKeys
          )
        } else if (this.updatedContract?.increase) {
          this.$set(
            this.contractInfo,
            'increase',
            this.updatedContract?.increase
          )
          this.handleValuesChanging(
            this.contractInfo.increase,
            this.increaseValueKeys
          )
        }

        this.updateAnuualRateData(this.updatedContract?.rentValue)

        // set the tax in case of user selected as chainging tax only will not chaning the UI
        this.$set(this.contractInfo, 'tax', this.updatedContract?.tax)
        this.hasTax = this.updatedContract?.tax
      }
    },
    setLastTabStatus() {
      if (this.isMaintenanceContract) {
        this.$emit('setLastTabStatus', true)
      }
    },
    transformRealEstateObject(object) {
      const whiteListedKeys = [
        'name',
        '_id',
        'address',
        'owner',
        'parentId',
        'purpose',
        'type'
      ]
      Object.keys(object).forEach((key) => {
        if (!whiteListedKeys.find((keyToBeDeleted) => keyToBeDeleted === key)) {
          delete object[key]
        }
      })
      return object
    },
    setMarketingPercentage({ val }) {
      this.resetMarketingPercentage()
      // this[val === 'fixed' ? 'marketingFixed' : 'marketingPercentage'] = true
      val === 'fixed'
        ? (this.marketingFixed = true)
        : (this.marketingPercentage = true)
    },
    resetMarketingPercentage() {
      this.marketingFixed = false
      this.marketingPercentage = false
    },
    dataToBeBinded() {
      const data = {
        ...this.contractInfo,
        end: this.formattedEndDate,
        hasFlexibleInstallments: this.hasFlexibleInstallments,
        duration: this.contractDuration,
        title: this.contractTitle,
        realEstate: {
          moderators: this.contractInfo.realEstate.moderators,
          name: this.realestatePropertyName,
          parentName: this.realestatePropertyParentName,
          _id: this.contractInfo.realEstate?._id,
          address:
            this.contractInfo.realEstate?.address ||
            this.contractInfo.realEstate?.propertyAddress?.address,
          owner:
            this.contractInfo.realEstate?.owner?._id ||
            this.contractInfo.realEstate?.owner,
          parentId: this.contractInfo.realEstate?.parentId,
          purpose: this.getRealestatePurpose
        }
      }
      return data
    }
  },
  watch: {
    isValid(val) {
      this.$emit('setValdationStatus', val)
    },
    'contractInfo.availableUnits': {
      deep: true,
      handler() {
        const modifiedAvailableUnits = [...this.availableUnitsForParent].map(
          (unit) => {
            unit.disabled = false
            return unit
          }
        )
        this.setavailableUnits(modifiedAvailableUnits)
        if (this.contractInfo?.availableUnits?.length) {
          const filteredSelectedUints = modifiedAvailableUnits.filter(
            (item) => item.type === this.contractInfo?.availableUnits[0].type
          )
          this.setavailableUnits(filteredSelectedUints)
          const ParentpropertyChecked = this.contractInfo.availableUnits.find(
            (unit) => {
              return unit.name === 'كامل العقار'
            }
          )
          if (ParentpropertyChecked) {
            return [...this.availableUnitsForParent].slice(1).map((unit) => {
              unit.disabled = true
              return unit
            })
          }
        }
        return modifiedAvailableUnits
      }
    },
    availableUnitsForParent(val) {
      if (val.length === 1) {
        this.contractInfo.availableUnits = [
          { name: 'كامل العقار', allocated: true }
        ]
      } else {
        this.contractInfo.availableUnits = []
      }
      this.setavailableUnits(val)
    },
    hasTax(val) {
      this.contractInfo.tax = val ? 15 : 0
    },
    hasFlexibleInstallments(val) {
      if (val) {
        return
      }
      this.contractInfo.increaseType = ''
      this.contractInfo.increase = 0
    },
    'contractInfo.realEstate': {
      deep: true,
      handler(val) {
        if (val && !this.availableUnits.length) {
          this.getAvaliableUnits(val)
        }
        // eslint-disable-next-line no-unused-expressions
        val?.purpose?.nameEn === 'Commercial' ||
          (val?.purpose?.nameEn === 'Residential_Commercial' &&
            (this.hasTax = 15 && (this.tax = 15)))
        // when we draft the contract with the tax on
        if (this.updatedContract?.tax) {
          this.hasTax = 15
          this.tax = 15
        }
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'configs']),
    showPercentage() {
      return this.contractInfo.increaseType === 'percentage'
    },
    realestateInputProps() {
      return {
        items: this.availableRealEstates,
        noData: this.noData,
        placeholder: this.selectedRelestatePlaceholder,
        validateString: this.validateString,
        returnObject: true,
        itemText: this.propertyToShow,
        hideDetails: true,
        disabled: this.defaultRealestateValuesCheck || this.isPendingRenewal
      }
    },
    unitsInputProps() {
      return {
        disabled:
          !this.contractInfo.realEstate?._id ||
          this.availableUnitsForParent.length === 1 ||
          this.isPendingRenewal,
        loading: this.isUnitLoading,
        itemText: this.propertyToShow,
        items: this.filteredAvailableUnits,
        noData: this.noData,
        placeholder: this.selectedRelestatePlaceholder,
        returnObject: true,
        showLengthData: this.showLengthData,
        hideDetails: true,
        multiple: true,
        chips: false
      }
    },
    showLengthData() {
      return {
        status: true,
        length: this.contractInfo?.availableUnits.length,
        allText: 'كامل العقار',
        showAllText:
          !this.contractInfo?.availableUnits[0]?._id ||
          this.contractInfo?.realEstate[0]?._id
      }
    },
    filteredAvailableUnits() {
      if (
        !this.contractInfo?.availableUnits?.length ||
        this.availableUnits.length
      ) {
        return this.availableUnits
      }
      return [this.contractInfo?.availableUnits[0]]
    },
    contractDuration() {
      return (
        Number(this.years) * 12 +
        Number(this.months) +
        Number(this.days) * 0.0333
      )
    },
    getPeriodInDays() {
      return (
        Number(this.years) * 365 + Number(this.months * 30) + Number(this.days)
      )
    },
    isPmFeeApproches() {
      return (
        (this.contractFixedMask.length || this.contractInfo?.tempVal) &&
        (this.companyPercentage || this.fixedCommission)
      )
    },
    amountKeys() {
      return {
        maskValKey: 'contractAmountMask',
        parentKey: 'contractInfo',
        actualValKey: 'amount'
      }
    },
    rentValueKeys() {
      return {
        maskValKey: 'contractRentValueMask',
        parentKey: 'contractInfo',
        actualValKey: 'rentValue'
      }
    },
    totalValueKeys() {
      return {
        maskValKey: 'contractTotalValueMask',
        parentKey: 'contractInfo',
        actualValKey: 'annualRate'
      }
    },
    fixedValueKeys() {
      return {
        maskValKey: 'contractFixedMask',
        parentKey: 'contractInfo',
        actualValKey: 'fixed'
      }
    },
    increaseValueKeys() {
      return {
        maskValKey: 'contractIncreaseMask',
        parentKey: 'contractInfo',
        actualValKey: 'increase'
      }
    },
    unitsTitles() {
      let unitsTitles = ''
      unitsTitles += this.contractInfo.availableUnits.map((unit) => {
        return ` ${unit.name} `
      })
      if (this.realestatePropertyParentName) {
        return ''
      }
      return unitsTitles.length ? `- ${unitsTitles}` : unitsTitles
    },
    realestatePropertyName() {
      return `${this.contractInfo.realEstate?.name} ${this.unitsTitles}`
    },
    realestatePropertyParentName() {
      return this.contractInfo.realEstate?.parentName ?? ''
    },
    contrcatTypeNameAr() {
      return this.contractTypesNameAr[this.contrcatType]
    },
    contractTitle() {
      if (this.realestatePropertyParentName && this.realestatePropertyName) {
        return `عقد - ${this.contrcatTypeNameAr} - ${this.realestatePropertyParentName} - ${this.realestatePropertyName}`
      }
      return `عقد - ${this.contrcatTypeNameAr} - ${this.realestatePropertyName}`
    },
    getRealestatePurpose() {
      const realestatePurpose = this.configs.realestatePurpose?.find(
        (Purpose) => {
          return Purpose._id === this.contractInfo?.realEstate?.purpose?._id
        }
      )
      return realestatePurpose ?? undefined
    },
    defaultValuesCheck() {
      return !!Object.keys(this.defaultData).length
    },
    defaultRealestateValuesCheck() {
      return this.defaultValuesCheck && this.defaultData.hasOwnProperty('owner')
    },
    companyPercentageText() {
      if (this.isRentContract) {
        return 'قيمة نسبة الشركة تحسب بناء علي النسبة المضافة في عقد إدارة الأملاك، يمكنك إضافة عقد إدارة الاملاك لاحقا'
      } else if (this.isPmContract) {
        return 'قيمة نسبة الشركة تحسب بناء علي النسبة المضافة في عقد إدارة الأملاك'
      }
      return ''
    },
    companyValueInRyal() {
      const formattedPayOnceValue = String(this.payOnceValue).replace(/,/g, '')
      return (
        Number(formattedPayOnceValue) * this.companyPrecentage
      ).toLocaleString('en-US', {
        maximumFractionDigits: 3
      })
    },
    companyPrecentage() {
      return this.pmContractCompanyPrecentage / 100 || 0
    },
    payOnceValue() {
      const collectionPeriod =
        this.contractDuration / this.contractInfo?.collectionInterval
      const result = this.contractInfo?.rentValue / collectionPeriod
      return isNaN(result)
        ? 0
        : result.toLocaleString('en-US', {
            maximumFractionDigits: 2
          })
    },
    subContractTypeRule() {
      return this.managementMaintenance
        ? [this.selectedContractSuptypes.length > 0 || '']
        : true
    },
    sm() {
      return this.$vuetify.breakpoint.smAndUp
    },
    collectionIntervalsText() {
      if (this.isRentContract) {
        return '* آلية التحصيل'
      } else if (this.isMaintenanceContract) {
        return '* فترة إستلام المبلغ المتفق عليه'
      }
      return ' فترة دفع محصلات المالك*'
    },
    minEndDate() {
      const specificDate = new Date(this.start)
      specificDate.setDate(specificDate.getDate() + 1)
      return specificDate.toISOString().split('T')[0]
    }
  }
}
</script>

<style lang="scss">
.maintenance-switch-container {
  height: 78px;
  display: flex;
  align-items: flex-end;
}

.maintenance-switch {
  position: absolute;
  top: 0;
  left: 0;
}

.borderedContainer {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.informativeCard {
  border: 1px solid #efebf2;
  border-radius: 8px;
  padding: 5px;
  .holder {
    .text {
      .headTitle {
        color: #101010;
      }

      .subTitle {
        color: #737373;
      }
    }
  }
}
.p-10 {
  padding: 10px;
}
.date--dropdown {
  .v-text-field__slot {
    margin-bottom: 5px !important;
  }
}
.file-continer {
  &-col {
    .v-text-field--outlined {
      font-size: 12px;
      & fieldset {
        border: 1px dashed #ddd;
        color: #662d91;
      }
    }
  }
}
</style>
