<template>
  <div>
    <div class="my-6 d-flex justify-between">
      <h4>السندات</h4>
    </div>
    <div v-if="assignments.length">
      <div
        class="d-flex justify-between pa-4 border__card rounded-lg mb-lg-2 mt-2"
        v-for="assignment in assignments"
        :key="assignment._id"
      >
        <span> {{ assignment.bond.number }} </span>
        <span
          class="brub--tag tabel-tag font-12 font-weight-bold text-center px-5 pb-2"
        >
          {{ assignment.bond.bondType.nameAr }}
        </span>
        <span> {{ formattingNumbers(assignment.bond.bondValue) }}</span>
        <span> {{ formatDate(assignment.bond.date) }} </span>
        <div
          class="d-flex justify-space-between align-center"
          v-if="assignment?.bond?.terminated?.isTerminated"
        >
          <div class="d-flex flex-column">
            <div
              class="px-5 mb-1 red--tag tabel-tag text-center font-12 font-weight-bold d-flex align-center"
            >
              <v-sheet class="dotted ma-0 pa-0 ml-2" color="#EB4545"></v-sheet>
              <h6 class="ma-0 pb-1 font-12 font-weight-bold text-truncate px-2">
                ملغي
              </h6>
            </div>
          </div>
        </div>
        <div
          v-else
          class="px-5 green--tag mb-1 tabel-tag text-center font-12 font-weight-bold d-flex align-center justify-center"
        >
          <v-sheet class="dotted ma-0 pa-0 ml-2" color="#65C467"></v-sheet>
          <span class="mb-1 text-truncate">ساري</span>
        </div>
      </div>
    </div>
    <div class="ma-auto" v-else>
      <NoData v-bind="noDataConfig" />
    </div>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import NoData from '@/components/helper/noData.vue'

export default {
  components: { NoData },
  props: {
    assignments: {
      type: Array
    }
  },
  data() {
    return {
      noBonds: require('@/assets/img/bonds/bonds.png')
    }
  },
  mixins: [dateFormat],
  computed: {
    noDataConfig() {
      return {
        noDataIcon: this.noBonds,
        noDataText: 'ليست هناك اي سندات',
        noDataSubTitle: '',
        isbgPrimary: false,
        widthHeight: '80'
      }
    }
  },
  methods: {
    formattingNumbers(num) {
      return parseFloat(num).toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.border__card {
  border: 1px solid #e8e8e8;
}
</style>
