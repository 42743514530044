import { render, staticRenderFns } from "./DefaultersSettingsModal.vue?vue&type=template&id=aa71d170&scoped=true"
import script from "./DefaultersSettingsModal.vue?vue&type=script&lang=js"
export * from "./DefaultersSettingsModal.vue?vue&type=script&lang=js"
import style0 from "./DefaultersSettingsModal.vue?vue&type=style&index=0&id=aa71d170&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa71d170",
  null
  
)

export default component.exports