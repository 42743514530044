import config from '../config'
import axios from 'axios'

axios.defaults.headers.client = 'HectarPlus'

const getAllTemplates = function () {
  const url = `${config.bonds}/templates`
  return axios.get(url)
}

const addTemplates = function (data) {
  const url = `${config.bonds}/templates`
  return axios.post(url, data)
}

const editTemplates = function (data, id) {
  const url = `${config.bonds}/templates/${id}`
  return axios.put(url, data)
}

const getTemplate = function (type) {
  const url = `${config.bonds}/templates/get?type=${type}`
  return axios.get(url)
}

export const templatesService = {
  getAllTemplates,
  addTemplates,
  editTemplates,
  getTemplate
}
