<template>
  <v-dialog max-width="600px" persistent :value="dialogState">
    <v-card class="add-new-property">
      <v-card-title
        class="px-4 py-2 mb-5 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0">إضافه ملاحظه</h4>
        <v-btn
          @click.prevent="closeNoteDialog"
          class="ma-0"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-4">
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col cols="12" sm="12">
              <h6 class="mb-2">ادخل ملاحظتك هنا</h6>
              <v-textarea
                class="note-textarea"
                dense
                filled
                hide-details="auto"
                placeholder="ادخل محتوى الملاحظة"
                auto-grow
                no-resize
                rows="5"
                v-model="note.body"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-1 mt-4">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-0 rounded-7 my-3 ml-3"
          @click="handleNotes()"
          :disabled="!isValidNote"
        >
          <span class="mb-2 ml-1 font-weight-bold">{{
            Object.keys(this.noteToEdit).length ? 'تعديل' : 'حفظ'
          }}</span>
          <v-icon class="send-icon">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'

export default {
  props: ['dialogState', 'noteToEdit'],
  components: {
    quillEditor
  },
  data() {
    return {
      note: {}
    }
  },
  watch: {
    noteToEdit: {
      immediate: false,
      handler() {
        if (Object.keys(this.noteToEdit).length) {
          this.note = { ...this.noteToEdit }
        }
      }
    }
  },
  methods: {
    handleNotes() {
      this.$emit('handleNotes', this.note)
      this.note = {}
    },
    closeNoteDialog() {
      this.note = {}
      this.$emit('closeNoteDialog')
    }
  },
  computed: {
    isValidNote() {
      return !!this.note?.body?.length
    }
  }
}
</script>
