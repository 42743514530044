<template>
  <v-dialog v-model="dialogState">
    <v-card class="sync pa-4" elevation="5">
      <div class="d-flex justify-space-between align-center">
        <v-spacer></v-spacer>
        <v-icon class="cursor" @click="setSync(false)">mdi-close</v-icon>
      </div>
      <div class="d-flex align-center pt-5">
        <v-spacer></v-spacer>
        <slot name="logo"></slot>
        <v-spacer></v-spacer>
      </div>
      <v-card-text class="pt-6">
        <h4 class="ma-0 text-center black--text">
          <slot name="title"></slot>
        </h4>
        <h6 class="text-center mb-6">
          <slot name="desc"></slot>
        </h6>
        <slot name="btn"></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'sync',
  data() {
    return {
      dialogState: true
    }
  },
  methods: {
    ...mapMutations('appState', ['setSync']),
    syncRout() {
      // this.$router.push({name: 'sync'})
      this.dialogState = false
      this.setSync(false)
    }
  }
}
</script>

<style scoped>
@import '../../styles/material-dashboard/_sync.scss';
</style>
