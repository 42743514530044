export default {
  icons: {
    pmContracts: require('@/assets/img/svgs/state_cards_icons/pmContracts.svg'),
    rentContracts: require('@/assets/img/svgs/state_cards_icons/rentContracts.svg'),
    maintenanceContracts: require('@/assets/img/svgs/state_cards_icons/pmContracts.svg'),
    customerDues: require('@/assets/img/svgs/state_cards_icons/customerDues.svg'),
    arrears: require('@/assets/img/svgs/state_cards_icons/arrears.svg'),
    rentedToRent: require('@/assets/img/svgs/state_cards_icons/rentedToRent.svg'),
    TotalIncome: require('@/assets/img/svgs/state_cards_icons/TotalIncome.svg'),
    Commission: require('@/assets/img/svgs/state_cards_icons/Commission.svg'),
    totalExpenses: require('@/assets/img/svgs/state_cards_icons/totalExpenses.svg'),
    openingCredit: require('@/assets/img/svgs/state_cards_icons/openingCredit.svg'),
    house: require('@/assets/img/svgs/house.svg'),
    wallet: require('@/assets/img/svgs/walletBG.svg'),
    redWallet: require('@/assets/img/svgs/state_cards_icons/totalExpenses.svg'),
    client: require('@/assets/img/svgs/coloredClient.svg'),
    arrowUp: require('@/assets/img/svgs/arrow-up.svg'),
    arrowUpCircular: require('@/assets/img/svgs/state_cards_icons/customerDues.svg'),
    houseOutline: require('@/assets/img/svgs/house-outline.svg'),
    contractsCount: require('@/assets/img/svgs/contract/maintenance-active.svg'),
    newMaintenanceRequests: require('@/assets/img/svgs/new-request.svg'),
    allMaintenanceRequests: require('@/assets/img/svgs/all-requests.svg'),
    canceledMaintenanceRequests: require('@/assets/img/svgs/canceled-request.svg'),
    rejectedMainenanceRequests: require('@/assets/img/svgs/rejected-request.svg'),
    doneMaintenanceRequests: require('@/assets/img/svgs/done-request.svg'),
    marketRentCommission: require('@/assets/img/svgs/state_cards_icons/market-rent-commission.svg'),
    allTasks: require('@/assets/img/tasks/AllTasks.svg'),
    newTasks: require('@/assets/img/tasks/new.svg'),
    doneTasks: require('@/assets/img/tasks/done.svg'),
    canceledTasks: require('@/assets/img/tasks/cancelled.svg'),
    inProgressTasks: require('@/assets/img/tasks/in-progress.svg')
  },
  // eslint-disable-next-line complexity
  getStatIcon(statName) {
    switch (statName) {
      case 'pmContracts':
      case 'companyRatio':
        return this.icons.pmContracts
      case 'rentContracts':
        return this.icons.rentContracts
      case 'maintenanceContracts':
        return this.icons.maintenanceContracts
      case 'customerDues':
      case 'Net Income':
      case 'netCredit':
      case 'totalFixedCommissions':
      case 'outcomes':
        return this.icons.customerDues
      case 'arrears':
      case 'Arrears':
      case 'totalDues':
        return this.icons.arrears
      case 'rentedToRent':
        return this.icons.rentedToRent
      case 'Total income':
      case 'currentBalance':
      case 'paidCredit':
        return this.icons.TotalIncome
      case 'Commission':
      case 'expensesCredit':
        return this.icons.Commission
      case 'expensesDebit':
        return this.icons.totalExpenses
      case 'openingCredit':
        return this.icons.openingCredit
      case 'ownerToRenters':
        return this.icons.client
      case 'dues':
      case 'totalFixedCommissions':
      case 'totalExpensesCredit':
        return this.icons.wallet
      case 'companyRatio':
        return this.icons.house
      case 'receipts':
        return this.icons.arrowUp
      case 'numOfTenants':
        return this.icons.user
      case 'noOfProperties':
        return this.icons.propertiesCount
      case 'propertiesCount':
        return this.icons.propertiesCount
      case 'numOfVacancies':
      case 'buildingsToUnits':
      case 'compoundsToUnits':
        return this.icons.houseOutline
      case 'noOfContracts':
        return this.icons.contractsCount
      case 'newRequests':
        return this.icons.newMaintenanceRequests
      case 'allRequests':
        return this.icons.allMaintenanceRequests
      case 'canceledRequests':
        return this.icons.canceledMaintenanceRequests
      case 'rejectedRequests':
        return this.icons.rejectedMainenanceRequests
      case 'doneRequests':
        return this.icons.doneMaintenanceRequests
      case 'totalExpensesDebits':
        return this.icons.redWallet
      case 'netDues':
        return this.icons.arrowUpCircular
      case 'totalMarketingFees':
        return this.icons.marketRentCommission
      case 'جديدة':
      case 'allTasks':
        return this.icons.allTasks
      case 'new':
        return this.icons.newTasks
      case 'inProgress':
        return this.icons.inProgressTasks
      case 'cancelled':
        return this.icons.canceledTasks
      case 'done':
        return this.icons.doneTasks
      default:
        return this.icons.wallet
    }
  }
}
