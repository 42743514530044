<template>
  <div class="mt-7 template-container">
    <h4 class="font-16 font-weight-medium template-title my-0">نموذج العقد</h4>
    <h5 class="contentSection font-weight-medium">
      <span
        class="desc font-12"
        v-if="contract.description"
        v-html="sanitize(contract.description)"
      ></span>
      <span v-else class="desc font-12">لا يوجد</span>
    </h5>
  </div>
</template>

<script>
import sanitizers from '@/mixins/sanitizers.js'
export default {
  name: 'ContractTemplate',
  mixins: [sanitizers],
  props: {
    contract: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.template-title {
  color: #101010 !important;
}
.template-container {
  max-height: 70vh;
  overflow-y: scroll;
}
</style>
