<template>
  <v-container :class="{ userView: isCustomer }">
    <div v-if="Object.keys(realEstats).length">
      <v-data-table
        :headers="headers"
        :items="[realEstats]"
        hide-default-footer
        class="tab-table-details"
      >
        <template v-slot:[`item.realEstate`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>
              {{
                item.realEstate?.parentId?.name
                  ? item.realEstate?.parentId?.name
                  : item.realEstate.name
              }}
            </h6>
          </div>
        </template>
        <template v-slot:[`item.realEstate.parentId`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>
              {{
                item.realEstate?.parentId?.name
                  ? item.realEstate.name
                  : item.realEstate?.parentId?.name || '-'
              }}
            </h6>
          </div>
        </template>
        <template v-slot:[`item.space`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>{{ item.space }} م2</h6>
          </div>
        </template>
        <template v-slot:[`item.pricePerMeter`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>{{ item.pricePerMeter?.toLocaleString() }} ريال/م2</h6>
          </div>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>{{ item.price?.toLocaleString() }} ريال/السنة</h6>
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h5
              :class="[
                'ma-0 tag text-center py-1',
                statusClasses(item.realEstate.status.nameAr)
              ]"
            >
              {{ item.realEstate.status.nameAr }}
            </h5>
          </div>
        </template>
      </v-data-table>
    </div>

    <NoData v-else v-bind="noDataConfig" />
  </v-container>
</template>

<script>
import NoData from '@/components/helper/noData.vue'
import dateFormat from '@/mixins/dateFormat.js'
import { mapGetters } from 'vuex'

export default {
  name: 'RealEstatsDetails',
  mixins: [dateFormat],
  props: {
    realEstats: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    NoData
  },
  data() {
    return {
      headers: [
        {
          text: 'اسم العقار',
          sortable: false,
          value: 'realEstate',
          align: 'center'
        },
        {
          text: 'اسم الوحدة',
          sortable: false,
          value: 'realEstate.parentId',
          align: 'center'
        },
        {
          text: 'المساحة',
          sortable: false,
          value: 'space',
          align: 'center'
        },
        {
          text: 'سعر المتر',
          sortable: false,
          value: 'pricePerMeter',
          align: 'center'
        },
        {
          text: 'قيمة الإيجار السنوي',
          sortable: false,
          value: 'price',
          align: 'center'
        },
        {
          text: 'حالة العقار',
          sortable: false,
          value: 'status',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    noDataConfig() {
      return {
        noDataText: 'لا يوجد عقارات حتى الان',
        noDataSubTitle: '',
        isbgPrimary: false,
        noDataIcon: require('@/assets/img/svgs/building.svg')
      }
    }
  },
  methods: {
    statusClasses(val) {
      let classes = ''
      switch (val) {
        case 'مؤجر':
          classes += 'green--tag'
          break
        case 'للبيع':
          classes += 'brub--tag'
          break
        case 'للإيجار':
        case 'شاغرة':
          classes += 'blue--tag'
          break
        case 'ايجار':
          classes += 'gery--tag'
          break
      }
      return classes
    }
  }
}
</script>
<style>
.tab-table-details table thead tr th span {
  font-size: 16px !important;
  color: #b2b2b2;
}
.container.userView {
  min-width: 100%;
  margin-right: 0 !important;
}
</style>
