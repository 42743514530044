<template class="expense__limit--modal--div">
  <v-dialog persistent :value="dialogState" max-width="520">
    <v-card class="add-new-property pa-0">
      <v-card-title class="py-1 pl-1 pr-4 mb-0 custom__shadow card--title">
        <div class="d-flex align-center justify-space-between">
          <h4 class="ma-0 font-16">الحد الأقصى لمصروفات الصيانة</h4>
          <v-btn
            data-test="closeModal"
            @click="closeDialog"
            class="ma-0 closeModal"
            color="grey"
            fab
            text
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <div class="card--text--div">
        <v-card-text>
          <div class="title">
            <h5 class="pt-3 mb-2 black--text font-13">
              هل تريد إضافة حد أقصي للمصروفات الخاصة بهذا العقار ؟
            </h5>
          </div>
          <v-layout class="d-flex justify-space-between radioGroup w-100">
            <v-radio-group
              class="mx-auto my-0"
              row
              mandatory
              v-model="radioGroup"
            >
              <v-radio v-for="(option, key) in options" :key="key" :value="key">
                <template slot="label">
                  <div
                    class="d-flex flex-column justify-center text-right align-right h-100 text-gray expense__limit--descp"
                  >
                    <h5
                      class="font-weight-medium ma-0 text-truncate font-12 expense__limit--title"
                    >
                      {{ option.title }}
                    </h5>
                    <h5 class="fw-400 mb-2 mt-0 font-12">
                      {{ option.text }}
                    </h5>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-layout>
          <v-form
            v-if="showExpensesInput"
            v-model="isValid"
            class="expenditures--form mx-auto"
          >
            <h5 class="grey--text font-12 mb-2">
              من فضلك ادخل الحد الأقصى للمصروفات بالأرقام
            </h5>
            <v-text-field
              dense
              filled
              required
              placeholder="الحد الأقصى للمصروفات"
              suffix="ريال"
              min="1"
              v-model.number="options.limited.value"
              :rules="expenseLimitRules"
            />
          </v-form>
        </v-card-text>
      </div>
      <v-card-actions
        class="action--btn pt-4 pb-3 d-flex align-center justify-end"
      >
        <v-btn
          class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold closeModal"
          :disabled="isDialogValid"
          @click="saveExpensesLimit"
          :loading="isSaveLoading"
          text
        >
          <span class="mb-1 ml-1 font-12">حفظ التغييرات</span>
          <v-icon class="font-16">mdi-arrow-left</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ExpensesLimitModal',
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    expenseLimit: {
      required: true
    },
    isSaveExpenseLimitFailed: {
      required: true
    }
  },
  data() {
    return {
      radioGroup: '',
      isValid: false,
      expenseLimitRules: [
        (v) => !!v || 'من فضلك ادخل الحد الأقصى للمصروفات',
        (v) => v !== '0' || 'الحد الأقصى للمصروفات يجب ان يكون اكبر من 0'
      ],
      isSaveLoading: false,
      options: {
        colsed: {
          title: 'مغلق',
          text: 'منع صرف أي مصروفات تابعه لهذا العقار',
          value: 0
        },
        open: {
          title: 'مفتوح',
          text: 'لا يوجد حد أفصي للمصروفات التابعة لهذا العقار',
          value: undefined
        },
        limited: {
          title: 'حد أقصي محدد',
          text: 'إدخال رقم محدد كحد أقصى للمصروفات التابعة لهذا العقار',
          value: ''
        }
      }
    }
  },
  computed: {
    showExpensesInput() {
      return this.radioGroup === 'limited'
    },
    isDialogValid() {
      return this.showExpensesInput ? !this.isValid : false
    }
  },
  watch: {
    isSaveExpenseLimitFailed(val) {
      if (val) {
        this.isSaveLoading = false
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeExpensesLimitModal')
    },
    saveExpensesLimit() {
      this.isSaveLoading = true
      this.$emit('saveExpensesLimit', this.options[`${this.radioGroup}`].value)
    },
    setExpensesLimit() {
      switch (this.expenseLimit) {
        case null:
          this.radioGroup = 'open'
          break
        case 0:
          this.radioGroup = 'closed'
          break
        default:
          this.radioGroup = 'limited'
          this.options.limited.value = this.expenseLimit
      }
    }
  },
  created() {
    this.setExpensesLimit()
  }
}
</script>
<style lang="scss" scoped>
@import '../../../src/styles/responsiveness/responsiveness.scss';
@import '@/styles/material-dashboard/_variables.scss';

.card--text--div {
  padding-right: 35px;
  @include max-sm {
    padding-right: 0;
    .title {
      h5 {
        &.pt-3 {
          padding-top: 0 !important;
        }
      }
    }
  }
}
.font-13 {
  font-size: 13px !important;
}
.closeModal {
  @include max-sm {
    &.v-btn.ma-0 {
      margin-left: 4px !important;
    }
  }
  i {
    font-size: 18px !important;
  }
}
.expense__limit--descp {
  .v-card__text {
    margin-right: 30px;
  }
}
.radioGroup {
  .v-radio {
    background-color: #fff;
    border: 1px solid #efebf2;
    border-radius: 7px !important;
    margin-bottom: 8px !important;
    padding: 9px 15px 4px;
    min-width: 83%;
    @include max-sm {
      padding: 8px 6px 3px;
      width: 100%;
      .font-12 {
        &:last-child {
          font-size: 10px !important;
        }
      }
    }
    // @include max-sm
    &:hover,
    &.active,
    &.v-item--active {
      background: rgba($primary-color, 0.1);
      border: 1px solid $primary-color;
      border-radius: 7px;
      transform: matrix(1.02, 0, 0, 1, 0, 0);
      ::v-deep {
        .v-input--selection-controls__input {
          .v-icon {
            color: $primary-color;
          }
        }
        .expense__limit--title {
          color: $primary-color;
        }
      }
    }
    ::v-deep {
      .v-input--selection-controls__input {
        position: initial !important;
        .v-icon {
          font-size: 18px;
          color: #efebf2;
        }
      }
    }
  }
}
.expenditures--form {
  width: 83%;
  @include max-sm {
    width: 100%;
  }
}
::v-deep {
  .v-btn--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
  .error--text {
    .v-messages__message {
      font-size: 10px;
    }
  }
  .v-dialog {
    @include max-sm {
      margin: 0 19px 0 10px;
    }
  }
}
// ::v-deep
</style>
