export const smoothScroll = (id) => {
  const element = document.querySelector(`#${id}`)

  // If the target element is not found, try to scroll again after a delay
  if (!element) {
    // Retry scrolling after 500 milliseconds
    setTimeout(() => smoothScroll(id), 100)
  } else {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
