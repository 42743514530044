<template>
  <v-card-text class="py-0 px-2">
    <h6 class="mb-2">تفاصيل التعاقد (اختياري)</h6>
    <quill-editor ref="myQuillEditor" v-model="contractInfo.description" />
  </v-card-text>
</template>

<script>
import { templatesService } from '@/services'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'contractTemplate',
  components: { quillEditor },
  props: {
    selectedContract: {
      default: '',
      required: true
    },
    updatedContract: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    }
  },
  data() {
    return {
      contractInfo: {
        description: ''
      }
    }
  },
  methods: {
    async getContractTemplate() {
      const contractType =
        this.selectedContract === 'propertyManagement'
          ? 'pm'
          : this.selectedContract
      const template = await templatesService.getTemplate(contractType)
      const active = template?.data ? template?.data?.active : false
      const templateContent =
        active && !this.contractInfo?.description?.length
          ? template.data.body
          : this.contractInfo?.description
      this.$set(this.contractInfo, 'description', templateContent)
    },
    emitValidationStatus() {
      this.$emit('setValdationStatus', true)
      this.$emit('setLastTabStatus', true)
    },
    bindContractData() {
      this.$emit('bindContractData', this.contractInfo)
    },
    resetData() {
      this.contractInfo = {
        description: ''
      }
    },
    presetData() {
      this.editMoodPresetData()
      this.emitValidationStatus()
      this.getContractTemplate()
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        this.$set(
          this.contractInfo,
          'description',
          this.updatedContract?.description
        )
      }
    }
  }
}
</script>
