<template>
  <v-dialog
    persistent
    v-model="dialogState_"
    :max-width="isPreview ? '600px' : '700px'"
  >
    <v-card class="add-new-property">
      <v-card-title
        class="pl-2 pr-4 pt-3 pb-5 mb-5 d-flex justify-space-between custom__shadow"
      >
        <span
          class="headline ft"
          v-text="this.vouch.type === 'payment' ? 'سند قبض' : 'سند صرف'"
        ></span>
        <v-btn
          fab
          text
          color="grey"
          class="ma-0 mt-2"
          @click.prevent="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- <v-slide-y-transition> -->
      <v-card-text class="pb-0 px-0" v-if="isPreview">
        <div
          class="preview-letter pt-4 pb-10 px-10 mb-7"
          id="preview-letter"
          ref="previewLetter"
        >
          <v-row class="ma-0">
            <v-col cols="6">
              <h3 class="ma-0">
                {{ this.vouch.type === 'payment' ? 'سند قبض' : 'سند صرف' }}
              </h3>
            </v-col>
            <v-col cols="6" class="text-left">
              <img
                :src="logo"
                width="100"
                class="letter-logo mb-n5"
                loading="lazy"
              />
            </v-col>
          </v-row>

          <v-divider class="mb-7"></v-divider>

          <div class="my-4">
            <div style="height: 10px"></div>
            <!-- <h5>نص الخطاب</h5> -->
            <p>
              {{
                `استلمنا من / ${this.contract.client.name}         المحترم
                  مبلغ ${price}
                  ملاحظات: ${comment}
                  `
              }}
              {{ letterContent }}
            </p>
          </div>

          <v-row class="ma-0">
            <v-col
              cols="6"
              class="pb-0 pr-0 d-flex align-center"
              v-if="vouch.by.name"
            >
              <h5 class="ma-0">المحرر</h5>
              <p class="ma-0 mr-4">{{ vouch.by.name }}</p>
            </v-col>

            <v-col
              cols="6"
              class="pb-0 pr-0 d-flex align-center"
              v-if="this.vouch.date"
            >
              <h5 class="ma-0">تاريخ</h5>
              <p class="ma-0 mr-4">
                {{ new Date(this.vouch.date).toISOString().substr(0, 10) }}
              </p>
            </v-col>
          </v-row>
        </div>
        <v-card-actions class="py-4 px-7">
          <v-spacer></v-spacer>
          <v-btn
            large
            rounded
            :loading="isDownloadLoading"
            class="ma-0 mr-2 px-7"
            color="success"
            @click="createAndDownloadPDF"
          >
            <v-icon class="send-icon ml-2">mdi-cloud-download-outline</v-icon>
            <span class="tf">تحميل نسخة PDF</span>
          </v-btn>
          <v-btn large rounded class="ma-0 mr-2 px-6" color="warning">
            <v-icon class="send-icon ml-2 mr-0"
              >mdi-share-variant-outline</v-icon
            >
            <span class="tf">مشاركة</span>
          </v-btn>
          <v-btn
            large
            rounded
            class="ma-0 mr-2 px-6"
            color="grey"
            @click="isPreview = false"
          >
            <v-icon class="send-icon ml-2 mr-0">mdi-share</v-icon>
            <span class="tf">عودة للتعديل</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
      <v-card-text class="pb-0 px-0" v-if="!isPreview">
        <v-form ref="form" class="px-3" v-model="isValid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  v-model="price"
                  filled
                  :rules="[(v) => !!v || 'من فضلك ادخل السعر']"
                  prepend-inner-icon="mdi-card-account-details-outline"
                  label="السعر"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  v-model="comment"
                  filled
                  :rules="[(v) => !!v || 'من فضلك ادخل الملاحظات']"
                  prepend-inner-icon="mdi-account-outline"
                  label="ملاحظات"
                  required
                ></v-text-field>
              </v-col>

              <div v-if="!isPreview" class="property-info-list">
                <v-data-table
                  :items="activities"
                  item-key="_id"
                  :headers="activitiesHeader"
                  class="elevation-0"
                  v-if="activities"
                >
                  <v-col cols="12" sm="6" class="py-0">
                    <template v-slot:item.message="{ item }">
                      <span>{{ item.message }}</span>
                    </template>
                  </v-col>
                  <template v-slot:item.date="{ item }">
                    <v-chip small outlined
                      ><v-icon class="ml-1">mdi-calendar</v-icon>
                      <span class="tf">{{
                        new Date(item.date).toISOString().substr(0, 10)
                      }}</span></v-chip
                    >
                  </template>
                </v-data-table>
              </div>
            </v-row>
          </v-container>
        </v-form>

        <v-card-actions class="py-4 px-7">
          <v-spacer></v-spacer>
          <v-btn
            large
            rounded
            class="ma-0 ml-3 px-6"
            color="info"
            @click="isPreview = true"
          >
            <span class="tf">عرض</span>
            <v-icon class="send-icon mr-2 ml-0">mdi-eye</v-icon>
          </v-btn>

          <v-btn
            large
            rounded
            :loading="isLoading"
            width="150"
            class="ma-0 px-6"
            color="success"
            @click="editVouch"
          >
            <span class="tf">حفظ</span>
            <v-icon class="send-icon mr-2 ml-0">mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-text>
      <!-- </v-slide-y-transition> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_add-new-property.scss'
import '@/styles/material-dashboard/_single.scss'
import '@/styles/material-dashboard/_properties.scss'
import { EnvService } from '../../services'

export default {
  props: ['dialogState', 'vouch', 'contract'],
  data() {
    return {
      isLoading: false,
      isDownloadLoading: false,
      isPreview: false,
      isValid: false,
      name: 'أشروف رضوان',
      title: 'خطاب تنبيه',
      letterContent:
        'شكرا لك على تفضيلك العقار، نتمنى أن يحوز العقار على اعجابك ونسعد بزيارتك في الوقت المناسب لك لمعاينة العقار على الحقيقة، متأكدين انه سينالك اعجابك اكثر، في انتظارك سيد نهر الأصبهاني الخرساني، لوريم ابسم عربي',
      attachments: null,
      letterDate: new Date().toISOString().substr(0, 10),
      letterDateModal: false,
      fontFile: require('@/styles/fonts/DINNextLTArabic/DINNextLTArabic-Regular.ttf'),
      fontFileBold: require('@/styles/fonts/DINNextLTArabic/DINNextLTArabic-Bold.ttf'),
      logo: require('@/assets/img/logo.png'),
      price: null,
      comment: null,
      activities: null,
      activitiesHeader: [
        { text: 'اشعار', value: 'message' },
        { text: 'التاريخ', value: 'date' }
      ]
    }
  },
  mounted() {
    const { price, comment, activities } = this.vouch
    console.log('mounted -> this.vouch', this.vouch)
    this.price = price
    this.comment = comment
    this.activities = activities
    console.log('mounted -> activities', activities)
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('closed-add-dialog')
      setTimeout(() => {
        this.isPreview = false
      }, 500)
    },
    checkAddress(val) {
      console.log(`val:${val}`)
      this.tab0IsDisabled = !val
    },
    validate() {
      this.$refs.form.validate()
      if (this.isValid === true) {
        this.tab++
      }
    },
    editVouch() {
      this.isLoading = true
      const env = JSON.parse(localStorage.getItem('currentEnv'))

      EnvService.editVouchers({
        _id: this.contract._id,
        env: env._id,
        voucherId: this.vouch._id,
        price: this.price,
        comment: this.comment
      })
        .then(() => {
          this.$emit('finishEdit')
          this.addNotification({
            text: 'تم تعديل نص الإشعار بنجاح',
            color: 'success'
          })
        })
        .catch((err) => {
          console.log('err', err)
          this.addNotification({
            text: 'حدث خطأ',
            color: 'error'
          })
        })
      this.isLoading = false
    },
    previewLetter() {
      this.isPreview = true
    },

    createAndDownloadPDF() {
      this.isDownloadLoading = true
    }
  },
  computed: {
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  }
}
</script>

<style lang="scss">
.preview-letter {
  float: right;
  direction: rtl;
  text-align: right;
  padding: 50px;
  max-width: 700px;
}

.letter-logo {
  display: block;
  float: left;
  margin: 0;
  padding: 0;
}
</style>
