<template>
  <v-dialog
    class="backdrop"
    overlay-opacity="0.5"
    persistent
    v-model="dialogState_"
    width="950px"
  >
    <v-card class="add-new-property pt-0">
      <v-card-title
        class="pa-0 pr-5 pl-1 mb-5 d-flex justify-space-between align-center custom__shadow"
      >
        <div class="d-flex align-center">
          <h4>معلومات حساب البنك</h4>
        </div>

        <v-btn
          data-test="close"
          @click.prevent="closeModel"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <banksAccountsForm
          ref="banksAccountsForm"
          :bankAccountsLoading="bankAccountsLoading"
          @bindBankAccountsData="bindBankAccountsData"
          @setBankAccountValidation="isBankAccountValid = $event"
        />
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary darken-1"
          @click="saveBankAccounts"
          :disabled="!isBankAccountValid"
          :loading="addBanksDataLoading"
        >
          حفظ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import banksAccountsForm from '@/components/add-client/clientModalTabs/banksAccountsForm.vue'
import { EnvService } from '@/services'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'bankAccountsAddModel',
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    clientId: {
      type: String,
      default: '',
      required: true
    }
  },
  components: { banksAccountsForm },
  data() {
    return {
      bankAccounts: [],
      bankAccountsLoading: false,
      isBankAccountValid: false,
      addBanksDataLoading: false
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeModel() {
      this.dialogState_ = false
    },
    saveBankAccounts() {
      this.$refs.banksAccountsForm.bindBankAccountsData()
    },
    async bindBankAccountsData(bankAccountsData) {
      this.addBanksDataLoading = true
      this.bankAccounts = bankAccountsData.map((account) => {
        return {
          ...account,
          entityId: this.clientId,
          entityType: 'Client',
          environmentId: this.currentEnv?._id
        }
      })
      try {
        await EnvService.addEnvBankAccount({ bankAccounts: this.bankAccounts })
        this.addNotification({
          text: 'تم إضافة البيانات البنكية بنجاح',
          color: 'success'
        })
        this.$emit('refreshBankList')
      } catch {
        this.addNotification({
          text: 'لم نتمكن من إضافة حسابك البنكي, من فضلك حاول في وقت لاحق',
          color: 'error'
        })
      } finally {
        this.addBanksDataLoading = false
        this.closeModel()
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    loaderProps() {
      return {
        numberOfLines: 3,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'table-heading',
        cols: 12
      }
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  }
}
</script>
