export const bondsStaticData = {
  clientAutoCompleteData: {
    noData: 'لا يوجد عملاء متاحه لهذا البحث',
    placeholder: 'اختر العميل',
    validateString: 'من فضلك ادخل اسم العميل',
    loading: false
  },
  contractAutoCompleteData: {
    placeholder: 'اختر العقد الخاص بالسند',
    noData: 'لا توجد عقودات متاحه لهذا البحث',
    validateString: 'من فضلك ادخل اسم العقد',
    contractPropertyToShow: 'madeUpTitle',
    loading: false
  },
  propertyAutoCompleteData: {
    placeholder: 'اختر العقار',
    noData: 'لا توجد عقارات متاحه لهذا البحث',
    validateString: 'من فضلك ادخل اسم العقار',
    itemText: 'name',
    loading: false
  },
  unitsAutoCompleteData: {
    placeholder: 'اختر الوحدة',
    noData: 'لا توجد وحدات متاحة لهذا البحث',
    validateString: 'من فضلك ادخل اسم الوحدة',
    itemText: 'name',
    loading: false
  }
}
