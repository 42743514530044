<template>
  <v-layout
    justify-space-between
    align-center
    flex-wrap
    class="sidedetail__header"
  >
    <v-layout d-flex justify-space-between align-center class="flex-wrap">
      <v-img
        :lazy-src="exitArrow"
        max-height="15"
        max-width="15"
        :src="exitArrow"
        class="mt-3"
        @click="$emit('showDetails')"
      ></v-img>

      <div v-if="task.category">
        <div
          :class="`mr-4 mt-2 tabel-tag text-center d-flex justify-center
              ${getAllTagsColors(task.category.nameEn)}`"
        >
          <h6 class="font-weight-bold mb-1 ma-0 font-12 px-1">
            {{ task.category.nameAr }}
          </h6>
        </div>
      </div>

      <div class="mr-4 mobile__mr__0">
        <p class="grey--text ma-0 font-10">{{ taskTitles.type }}</p>
        <h5 class="ma-0 font-14 font-weight-medium">
          <span v-if="task.type">
            <span>{{ task.type.nameAr }}</span>
          </span>
          <span v-else>-</span>
        </h5>
      </div>

      <div class="mr-4 mobile__mr__25px">
        <p class="grey--text ma-0 font-10">
          {{ taskTitles.name }}
        </p>
        <h5 class="ma-0 font-weight-medium text-truncate max-width">
          <span>{{ taskRealestate }}</span>
        </h5>
      </div>

      <div class="mr-4 mobile__mr__25px">
        <p class="grey--text ma-0 font-10">{{ taskTitles.clientName }}</p>
        <h5 class="ma-0 font-weight-medium text-truncate max-width">
          <span>{{ task.client?.name || '-' }}</span>
        </h5>
      </div>

      <img
        class="mx-1"
        src="@/assets/img/svgs/Line.svg"
        alt=""
        loading="lazy"
      />
      <div style="display: flex; flex-direction: column">
        <p class="grey--text ma-0 font-10">{{ taskTitles.to }}</p>
        <div class="d-flex align-center mx-auto" v-if="task.assignedTo">
          <div
            v-for="(assignedTo, index) in task.assignedTo.slice(0, 2)"
            :key="index"
            class="d-flex align-center mx-auto"
          >
            <v-tooltip
              bottom
              color="black"
              content-class="v-tool"
              min-width="50"
            >
              <template slot="activator" slot-scope="{ on: tooltip }">
                <div v-on="{ ...tooltip }">
                  <div>
                    <h6
                      class="blue--tag bill-md font-12 ml-2 white-space-nowrap"
                    >
                      {{ assignedTo.name ? $subString(assignedTo.name) : '' }}
                    </h6>
                  </div>
                </div>
              </template>
              <span class="tool--tip" v-if="assignedTo">{{
                assignedTo.name
              }}</span>
            </v-tooltip>
          </div>
          <p class="my-auto" v-if="task.assignedTo.length > 2">
            (+{{ task.assignedTo.length - 2 }} أخرون)
          </p>
        </div>
        <h5 class="font-12 font-weight-bold text-truncate" v-else>-</h5>
      </div>

      <div class="d-flex mr-4 mobile__mr__0">
        <h6 v-if="task.createdBy" class="brub--tag bill-md font-12 ml-2">
          {{ createdBy.name ? $subString(createdBy.name) : '' }}
        </h6>
        <div>
          <p class="grey--text ma-0 font-10">{{ taskTitles.from }}</p>
          <h5 v-if="task.createdBy" class="ma-0 font-weight-medium">
            {{ createdBy.name }}
          </h5>
          <span v-else>-</span>
        </div>
      </div>

      <div class="mr-4 my-2 mobile__mr__25px mobile__mb__0 mobile__mt__20px">
        <p class="grey--text ma-0 font-10">{{ taskTitles.date }}</p>
        <h5 class="ma-0 font-weight-medium">
          <span>{{ formatDate(task.createdAt) }}</span>
        </h5>
      </div>

      <div class="mr-8">
        <div
          class="d-flex pt-2 align-center justify-content-center"
          :class="`mdi-18px ${getAllTagsColors(task.priority)}`"
        >
          <v-icon :class="`mdi-20px ${getAllTagsColors(task.priority)}`">
            mdi-flag-variant-outline
          </v-icon>
          <h5 class="font-weight-medium font-16">{{ task.priority }}</h5>
        </div>
      </div>

      <div v-if="task.status">
        <div
          :class="`mr-6 mt-2 px-3 tabel-tag text-center font-12 d-flex align-center ${getAllTagsColors(
            task.status.nameEn
          )}`"
        >
          <v-sheet
            v-if="task.status.nameEn === 'new'"
            class="dotted ma-0 pa-0 ml-2"
            color="#65C467"
          >
          </v-sheet>

          <v-sheet
            v-else-if="task.status.nameEn === 'inProgress'"
            class="dotted ma-0 pa-0 ml-2"
            color="#FF8000"
          >
          </v-sheet>

          <v-sheet
            v-else-if="task.status.nameEn === 'inReview'"
            class="dotted ma-0 pa-0 ml-2"
            color="#662D91"
          >
          </v-sheet>

          <v-sheet
            v-else-if="task.status.nameEn === 'done'"
            class="dotted ma-0 pa-0 ml-2"
            color="#fc4a4a"
          >
          </v-sheet>

          <span class="ma-0 mb-1">
            {{ task.status.nameAr }}
          </span>
        </div>
      </div>
    </v-layout>
    <v-layout justify-end class="mobile__justify__start">
      <v-btn
        max-width="120"
        class="actions cancel px-8 cancel__btn"
        @click="$emit('deleteTaskFromSideDetails', task._id)"
      >
        <v-icon size="20" color="danger">mdi-trash-can-outline</v-icon>
        <span class="font-18"> حذف </span>
      </v-btn>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'side-detailes-header',
  props: {
    taskTitles: {},
    task: {}
  },
  data() {
    return {
      exitArrow: require('@/assets/img/svgs/right-arrow.svg'),
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سيتمبر',
        'أكتوبر',
        'نوفمبر',
        'ديسمبر'
      ]
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    // Generate used date format
    formatDate(date) {
      const d = new Date(date)
      return `${d.getDate()}  ${this.months[d.getMonth()]}`
    },
    // Generate all Tags Colors using binding class
    getAllTagsColors(priority) {
      switch (priority) {
        case 'طارئ':
          return 'red--flag'
        case 'عاجل':
          return 'lightb--flag'
        case 'متوسط':
          return 'orange--flag'
        case 'منخفض':
          return 'low--flag'
        case 'inReview':
          return 'brub--tag'
        case 'new':
          return 'green--tag'
        case 'request':
        case 'inProgress':
          return 'orange--tag'
        case 'mission':
          return 'brub--tag'
        case 'complain':
          return 'lightGreen--tag'
        case 'done':
          return 'red--tag'
      }
      return null
    }
  },
  computed: {
    assignedTo() {
      return this.task.assignedTo
    },
    createdBy() {
      return this.task.createdBy
    },
    taskRealestate() {
      return this.task?.realEstate?.name || '-'
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/material-dashboard/_variables.scss';
</style>
