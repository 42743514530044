<template>
  <v-card
    class="d-flex flex-column document__bond__contract__list__cards fixed_height"
  >
    <v-card-title
      class="d-flex justify-space-between flex-row pa-4 pb-0 mobile__mb__0"
    >
      <div
        :class="[
          'font-weight-bold',
          'font-16',
          'text-truncate',
          { 'd-flex': isSmsMessages },
          'flex-grow-1'
        ]"
      >
        {{ title }}
        <span class="mr-1" v-if="isSmsMessages && messagesCount">
          ( {{ messagesCount }} )
        </span>
      </div>
      <v-btn
        v-if="isAddAction && showAddRequestForCustomer"
        @click="onOpenModal(modal)"
        :data-test="`file-list-${subTitle}`"
        class="add-btn add__property__btn px-1 py-3 my-3 mx-1"
        depressed
        x-small
      >
        <v-icon color="purple">mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <div v-if="filters.length">
      <v-slide-group show-arrows active-class="deep-purple--text">
        <v-slide-item
          v-for="filter in filters"
          :key="filter"
          v-slot="{ active, toggle }"
          active-class="deep-purple--text"
        >
          <v-btn
            depressed
            @click="toggle"
            :input-value="active"
            :class="{
              'black--text': !active,
              'deep-purple--text': active,
              'filter-underline': active,
              'font-weight-bold': active
            }"
            class="white"
          >
            {{ filter }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>
    <v-row class="d-flex align-center mx-0 pa-0" v-if="fileListLoading">
      <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
        class="ma-auto"
      ></v-progress-circular>
    </v-row>
    <div
      :class="[
        filesContainerClass,
        'pt-0',
        'mb-4',
        'flex-grow-1',
        'overflow-y-auto',
        'mobile__mb__0'
      ]"
      v-else-if="componentToShow"
    >
      <component
        v-bind="dynamicComponentProps"
        @nextPageTrigger="$emit('nextPageTrigger', title)"
        v-on="$listeners"
        @financialActiveTabChanged="$emit('financialActiveTabChanged', $event)"
      >
        <template v-slot:bankAccountsContent>
          <slot name="bankAccountsContent" />
        </template>
      </component>
    </div>
    <div
      class="d-flex flex-column justify-center align-center h-100 text-gray"
      v-else
    >
      <h5>
        {{ hasListPermission ? noData : 'ليس لديك صلاحيه لعرض هذه المعلومات' }}
      </h5>
    </div>
    <v-card-actions v-if="link && link.name">
      <router-link
        :to="redirectLink"
        class="text-center w-100 py-2 font-12"
        :class="[customerHome ? 'font-weight-medium' : 'font-weight-bold']"
      >
        <span>
          {{ link.name }}
          <v-icon :class="{ 'font-16': customerHome }" color="primary">{{
            linkIconName
          }}</v-icon>
        </span>
      </router-link>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FileListCard',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FileList: () => import('@/components/details/FileList'),
    // eslint-disable-next-line vue/no-unused-components
    NotificationList: () => import('@/components/details/NotificationList'),
    // eslint-disable-next-line vue/no-unused-components
    ClientDetailsFileList: () => import('@/components/details/FileList'),
    // eslint-disable-next-line vue/no-unused-components
    financialDetailsFileList: () =>
      import('@/components/details/financialDetailsFileList'),
    // eslint-disable-next-line vue/no-unused-components
    MessageCardContainer: () => import('../messages/MessageCardContainer'),
    // eslint-disable-next-line vue/no-unused-components
    RequestCardContainer: () =>
      import('@/components/requests/RequestCardContainer')
  },
  props: {
    icon: {
      default() {
        return {}
      }
    },
    fileListLoading: {
      default: false
    },
    filters: {
      default: () => []
    },
    link: {
      default: () => {
        return {}
      }
    },
    files: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      required: true
    },
    onFilter: {},
    hasAddPermission: {
      default: true,
      type: Boolean
    },
    isAddAction: {
      default: true
    },
    componentName: {},
    noData: {},
    modal: {},
    subTitle: {
      type: String,
      default: ''
    },
    showDocuments: {
      type: Boolean,
      default: false
    },
    client: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    messagesCount: {
      type: Number,
      default: 0
    },
    customerHome: {
      type: Boolean,
      default: false
    },
    hasListPermission: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('appState', ['getClientForRoute']),
    ...mapGetters('accountState', ['isCustomer']),
    isBankAccounts() {
      return !!this.title.includes('حسابات')
    },
    getHasNoPermissionText() {
      switch (this.subTitle) {
        case 'bond':
          return 'سندات'
        case 'contract':
          return 'عقود'
        case 'tax':
          return 'فواتير'
        default:
          return 'هذا العنصر'
      }
    },
    componentToShow() {
      return (
        (this.componentName === 'financialDetailsFileList' &&
          this.filterdFiles.length) ||
        (this.componentName !== 'financialDetailsFileList' &&
          this.files.length &&
          this.hasListPermission)
      )
    },
    filterdFiles() {
      return this.files.filter((file) => {
        return file?.data?.length || 0
      })
    },
    isSmsMessages() {
      return !!this.link.url?.includes('sms')
    },
    isInternalMessages() {
      return !!this.link.url?.includes('messaging')
    },
    redirectLink() {
      return this.isSmsMessages
        ? {
            path: `${this.link.url}`,
            query: {
              phone: this.client.phoneNumber,
              name: this.client.name,
              clientId: this.client._id
            }
          }
        : {
            path: `${this.link.url}`,
            query: {
              realEstate: JSON.stringify(this.link?.realEstate),
              client: JSON.stringify(this.link?.client)
            }
          }
    },
    filesContainerClass() {
      return this.isSmsMessages || this.isInternalMessages ? 'pa-3' : 'pa-6'
    },
    dynamicComponentProps() {
      const props = {
        is: this.componentName,
        itemType: this.link.url,
        'show-documents': this.showDocuments,
        icon: this.icon,
        isBankAccounts: this.isBankAccounts
      }
      switch (this.componentName) {
        case 'MessageCardContainer':
          props.messages = this.files
          break
        case 'RequestCardContainer':
          props.requests = this.files
          break
        default:
          props.files = this.files
      }
      return props
    },
    linkIconName() {
      return this.customerHome ? 'mdi-arrow-down' : 'mdi-chevron-left'
    },
    isRequests() {
      return this.link.url?.includes('requests')
    },
    showAddRequestForCustomer() {
      return this.isRequests ? true : !this.isCustomer
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    onOpenModal(modal) {
      if (!this.hasAddPermission) {
        this.addNotification({
          text: `ليس لديك صلاحية لإضافة ${this.getHasNoPermissionText}`,
          color: 'error'
        })
        return
      }
      this.$emit(modal)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../src/styles/responsiveness/responsiveness.scss';
@import '@/styles/material-dashboard/_variables.scss';
.v-btn.add-btn {
  background: rgba($primary-color, 0.1) !important;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.03);
  border-radius: 7px !important;
  &.add__property__btn {
    height: 20px;
    min-width: 24px;
    &:hover {
      background: $primary-color !important;
      i {
        color: #fff !important;
      }
    }
    ::v-deep {
      .v-btn__content {
        i {
          font-size: 17px !important;
        }
      }
    }
  }
  // add__property__btn
}
.filter-underline {
  color: $primary-color;
}
.v-btn::before {
  background-color: transparent;
}
.h-100 {
  height: 100%;
}
.document__bond__contract__list__cards {
  height: 350px;
  @include max-xl {
    h5,
    .h5 {
      font-size: 0.6rem !important;
    }
    h6,
    .h6 {
      font-size: 0.55rem !important;
    }
  }
  // @include max-xl
  ::-webkit-scrollbar-thumb {
    background: $primary-color !important;
  }
}
// document__bond__contract__list__cards
//text__messages
.sms-messages-count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #ebe5ef;
  border: 1px solid transparent;
  color: $primary-color;
  border-radius: 10px;
  margin-top: 5px;
}
.document__list--card {
  &:last-child {
    height: 173px;
    @media only screen and (max-width: 1261px) {
      height: 170px;
    }
    @include max-xl {
      height: 172px;
    }
  }
}
</style>
