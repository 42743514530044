<template>
  <v-dialog max-width="360" v-model="dialogState">
    <v-card width="360" height="260" class="coupon pa-4" elevation="5">
      <div class="d-flex justify-space-between align-center">
        <v-spacer></v-spacer>
        <v-icon class="cursor" @click="setCoupon(false)">mdi-close</v-icon>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'coupon',
  data() {
    return {
      dialogState: true
    }
  },
  methods: {
    ...mapMutations('appState', ['setCoupon'])
  }
}
</script>
<style lang="scss">
.coupon {
  background: url('../../assets/img/coupon.png') no-repeat;
}
</style>
