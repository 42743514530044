const switchButtons = [
  {
    title: 'رسالة نصية عبر الجوال',
    key: 'viaSMS',
    icon: 'mdi-message-outline'
  },
  {
    title: 'رسالة داخلية عبر البوابة / التطبيق',
    key: 'viaPlatform',
    icon: 'mdi-chat-outline'
  },
  {
    title: 'رسالة عبر البريد الإلكتروني',
    key: 'viaEmail',
    icon: 'mdi-email-outline'
  },
  {
    title: 'إرفاق رابط خطاب مع الإشعار',
    key: 'includeLink',

    icon: 'mdi-text-box-outline',
    isOnlyFinancial: true,
    children: [
      {
        title: 'ختم الشركه',
        key: 'displayCompanySeal'
      },
      {
        title: 'توقيع المدير المالي',
        key: 'displayFinancialSignature'
      },
      {
        title: 'توقيع مدير الشركه',
        key: 'displayDirectorSignature'
      },
      {
        title: 'توقيع مدير إدارة الأملاك',
        key: 'displayPropertyManagementManagerSignature'
      }
    ]
  }
]

export default switchButtons
