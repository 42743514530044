<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :max-height="options.height"
    :style="{ zIndex: options.zIndex, border: 'none' }"
    @keydown.esc="dialog = false"
  >
    <v-card style="border: 0 !important">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = false" :width="16">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text class="my-0 pb-4 text-center">
        <v-img
          :max-width="img.width"
          :max-height="img.height"
          class="mx-auto"
          :src="img.path"
        ></v-img>
        <div class="mt-6 font-18 font-weight-medium mainTitle">
          شارك بي دي اف {{ type }} الآن
        </div>
        <div class="mt-3 font-12 font-weight-medium subTitle">
          شارك رابط بي دي اف {{ type }} عبر واحدة من هذه المنصات
        </div>
      </v-card-text>

      <!------------------------------->
      <!---------Social sharing-------->
      <!------------------------------->

      <div class="d-flex align-center justify-center px-10">
        <ShareNetwork
          v-for="social in socialSharing"
          :key="social.network"
          :network="social.network"
          :url="setLink"
          :title="`بي دي اف ${type}`"
          class="mx-2"
        >
          <div class="d-flex align-center justify-center flex-column">
            <v-img
              max-width="50"
              max-height="50"
              :src="social.icon.path"
              class="mx-auto"
            ></v-img>
            <p v-text="social.title" />
          </div>
        </ShareNetwork>
      </div>

      <!-------------------------->
      <!---------Copy link-------->
      <!-------------------------->
      <div class="d-flex align-center justify-center flex-column pt-2 pb-10">
        <p class="font-14 font-weight-medium mb-1">او يمكنك نسخ الرابط</p>
        <v-btn
          elevation="0"
          color="primary"
          :disabled="copied"
          small
          @click="copyLink"
          >نسخ الرابط</v-btn
        >
        <input
          type="text"
          class="body-2 text-center"
          :value="setLink"
          ref="link"
          style="width: 90%"
          v-show="!copied"
          readonly
        />
        <span class="font-14" v-show="copied">تم النسخ</span>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-application a {
  color: black;
}
</style>
<script>
export default {
  data() {
    return {
      dialog: false,
      type: '',
      query: '',
      img: {
        path: require('@/assets/img/svgs/social-share.svg'),
        width: 56,
        height: 56
      },
      socialSharing: [
        {
          network: 'whatsApp',
          title: 'واتس اب',
          icon: {
            path: require('@/assets/img/svgs/social-icons/whatsapp.svg')
          }
        },
        {
          network: 'sms',
          title: 'رسالة',
          icon: {
            path: require('@/assets/img/svgs/social-icons/sms.svg')
          }
        },
        {
          network: 'Email',
          title: 'ايميل',
          icon: {
            path: require('@/assets/img/svgs/social-icons/email.svg')
          }
        }
      ],
      options: {
        color: 'primary',
        width: 328,
        height: 278,
        zIndex: 20000
      },
      copied: false
    }
  },
  computed: {
    setLink() {
      return `${location.origin}/view-pdf${this.query}`
    }
  },
  watch: {
    dialog() {
      this.copied = false
    }
  },
  methods: {
    copyLink() {
      // 1 - Get ref
      const copyLink = this.$refs.link
      // For mobile
      copyLink.setSelectionRange(0, 99999)
      // 2 - Copy the content
      navigator.clipboard.writeText(copyLink.value)
      // 3 - If user copied a link will disabled copy buttton and input link
      this.copied = true
    },
    open({ type, query }) {
      this.dialog = true
      this.query = query
      this.type = type
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/responsiveness/responsiveness.scss';
.mainTitle {
  color: #101010;
}
.subTitle {
  color: #737373;
}
</style>
