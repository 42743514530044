<template>
  <v-dialog persistent v-model="dialogState_" max-width="580px">
    <v-card class="add-new-property">
      <v-card-title
        class="pl-2 pr-4 pt-3 pb-5 mb-5 d-flex justify-space-between custom__shadow"
      >
        <span class="headline ft">اشعار تفضيل العقار</span>

        <v-btn
          fab
          text
          color="grey"
          class="ma-0 mt-2"
          @click.prevent="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-textarea
          filled
          value="شكرا لك على تفضيلك العقار، نتمنى أن يحوز العقار على اعجابك ونسعد بزيارتك في الوقت المناسب لك لمعاينة العقار على الحقيقة، متأكدين انه سينالك اعجابك اكثر، في انتظارك سيد نهر الأصبهاني الخرساني، لوريم ابسم عربي"
        >
        </v-textarea>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          large
          text
          :loading="isLoading"
          class="ma-0"
          color="primary darken-1"
          @click="addProperty"
        >
          <span class="tf">تعديل الإشعار</span>
          <v-icon class="send-icon mr-2">mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_add-new-property.scss'

export default {
  props: ['dialogState'],
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('closed-add-dialog')
    },
    checkAddress(val) {
      console.log(`val:${val}`)
      this.tab0IsDisabled = !val
    },
    validate() {
      this.$refs.form.validate()
      if (this.isValid === true) {
        this.tab++
      }
    },
    addProperty() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.$emit('closed-add-dialog')
        this.addNotification({
          text: 'تم تعديل نص الإشعار بنجاح',
          color: 'success'
        })
        this.tab = 0
      }, 1000)
    }
  }
}
</script>
