<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex justify-space-between align-center mb-2 mt-6 position-relative"
    >
      <div class="d-flex justify-start flex-wrap">
        <div class="d-flex align-center" style="gap: 1rem">
          <v-menu
            content-class="vMenuFilter elevation-0"
            offset-y
            transition="slide-y-transition"
            width="150"
            ref="tasksType"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center">
                <v-slide-x-transition>
                  <v-card
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex justify-space-between px-2"
                    width="150"
                  >
                    <h6 class="mr-2 text-truncate" v-if="filters.selectedType">
                      {{ filters.selectedType }}
                    </h6>
                    <h6 class="mr-2" v-else>حالة المهمة</h6>
                    <v-icon
                      >{{
                        menuStateSwitcher($refs.tasksType)
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-card>
                </v-slide-x-transition>
              </div>
            </template>
            <v-list
              class="contractVmenuList pa-0"
              v-for="(option, i) in tasksType"
              :key="i"
            >
              <v-list-item-group>
                <v-list-item class="px-1">
                  <v-list-item-title
                    ref="sortOption"
                    class="font-14 mr-1 mb-2 font-weight-medium"
                    @click="setFiltervalue(option, 'selectedType')"
                  >
                    {{ option.nameAr }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <autocomplete
            :hideDetails="true"
            :rules="[true]"
            :chips="false"
            item-text="name"
            item-value="_id"
            :items="allEmployees"
            placeholder="أختر اسم المسؤول"
            :returnObject="true"
            width="150"
            @input="setAssedPerson"
            :value="selectedAssignPersons"
            @nextPageTrigger="getEmployeesNextData"
          />
          <div class="max-width-220 mx-2">
            <v-text-field
              height="40"
              dense
              filled
              placeholder="ابحث بإسم العميل"
              :hide-details="true"
              :value="search"
              @input="searchForRealEstates"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-4">
      <template v-for="(filter, idx) in filters">
        <v-card
          class="d-flex pa-1 flex-grow-0 justify-between align-center mx-1"
          v-if="filter"
          :key="idx"
        >
          <h5 class="ma-0 mx-2 text--purple font-12 text-no-wrap py-2">
            {{ filter }}
          </h5>
          <v-icon
            class="font-weight-medium font-14 pl-1"
            @click="restFilters(idx)"
          >
            mdi-close
          </v-icon>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/helpers/debounce'
import autocomplete from '@/components/forms/autocomplete'
export default {
  components: {
    autocomplete
  },
  props: {
    allEmployees: {
      default() {
        return []
      }
    }
  },
  data() {
    return {
      search: '',
      filterQuery: {
        status: null,
        user: null
      },
      selectedAssignPersons: null,
      filters: {
        selectedType: null,
        selectedAssignPerson: null
      },
      tasksType: [
        {
          nameAr: 'الكل',
          nameEn: ''
        },
        {
          nameAr: 'جديدة',
          nameEn: 'new'
        },
        {
          nameAr: 'قيد التنفيذ',
          nameEn: 'inProgress'
        },
        {
          nameAr: 'تم التنفيذ',
          nameEn: 'done'
        },
        {
          nameAr: 'تم الإلغاء',
          nameEn: 'cancelled'
        }
      ]
    }
  },
  methods: {
    menuStateSwitcher(ref) {
      return ref?.isActive
    },
    getEmployeesDataBySearchKey(e) {
      this.$emit('getEmployeesDataBySearchKey', e)
    },
    getEmployeesNextData() {
      this.$emit('getEmployeesNextData')
    },
    restFilters(idx) {
      const formatIdx = idx === 'selectedType' ? 'status' : 'user'
      this.filters[idx] = ''
      if (idx === 'selectedAssignPerson') {
        this.selectedAssignPersons = null
      }
      this.filterQuery[formatIdx] = null
      this.$emit('handleFilters', this.filterQuery)
    },
    searchForRealEstates: debounce(function (searchText) {
      this.search = searchText
      this.filterQuery.clientName = searchText
      this.$emit('search', this.filterQuery)
    }, 600),
    setFiltervalue(filter, filterType) {
      this.$emit('resetPagination')
      this[filterType] = filter.nameAr
      this.filterQuery.status = filter.nameEn
      this.$set(this.filters, filterType, filter.nameAr)
      this.$emit('handleFilters', this.filterQuery)
    },
    setAssedPerson(person) {
      this.filters.selectedAssignPerson = person.name
      this.selectedAssignPersons = person

      this.filterQuery.user = person._id
      this.$emit('handleFilters', this.filterQuery)
    }
  }
}
</script>
<style lang="scss">
div.v-input__control > div.v-input__slot > div.v-select__slot input {
  font-size: 14px;
}
</style>
