<template>
  <v-form ref="form" v-model="isValid">
    <v-container>
      <h6 class="ma-0 mb-4">تفاصيل إضافيه</h6>
      <v-row no-gutters class="mt-4 mx-1">
        <!-- Electricity -->
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">القيمه السنويه لخدمه الكهرباء</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="القيمه السنويه لخدمه الكهرباء"
            @input="handleTotalValue($event, 'electricity')"
            :value="electricityYearlyMask"
            @keydown="$formatNumberInputs($event, true)"
          />
        </v-col>
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">القيمه الكليه لخدمه الكهرباء</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            placeholder="القيمه الكليه لخدمه الكهرباء"
            hide-details
            :value="electricityMask"
            disabled
          />
        </v-col>
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">رقم الإشتراك (اختياري)</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="رقم الإشتراك (اختياري)"
            v-model.number="contractInfo.electricityMeter"
            @input="formatArabicNumber($event, 'electricityMeter')"
            @keydown="$formatNumberInputs"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <h6 class="mt-0 mb-2">فتره التحصيل</h6>
          <v-select
            :rules="
              contractInfo.electricity && !hasCombinedRent
                ? [(v) => !!v || `اختر الفترة`]
                : [true]
            "
            :disabled="!contractInfo.electricity || hasCombinedRent"
            :items="contractIntervals"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            placeholder="فتره التحصيل"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            item-text="nameAr"
            item-value="value"
            required
            v-model="contractInfo.electricityPayment"
          />
        </v-col>

        <!-- Water -->
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">القيمه السنويه لخدمه المياه</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="القيمه السنويه لخدمه المياه"
            @input="handleTotalValue($event, 'water')"
            :value="waterYearlyMask"
            @keydown="$formatNumberInputs($event, true)"
          />
        </v-col>
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">القيمه الكليه لخدمه المياه</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="قيمه  العقد الكليه لخدمه المياه"
            :value="waterMask"
            disabled
          />
        </v-col>
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">رقم الإشتراك (اختياري)</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="رقم الإشتراك (اختياري)"
            v-model.number="contractInfo.waterMeter"
            @input="formatArabicNumber($event, 'waterMeter')"
            @keydown="$formatNumberInputs"
          />
        </v-col>

        <v-col cols="12" sm="3">
          <h6 class="mt-0 mb-2">فتره التحصيل</h6>
          <v-select
            :rules="
              contractInfo.water && !hasCombinedRent
                ? [(v) => !!v || `اختر الفترة`]
                : [true]
            "
            :disabled="!contractInfo.water || hasCombinedRent"
            :items="contractIntervals"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            placeholder="فتره التحصيل"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            item-text="nameAr"
            item-value="value"
            required
            v-model="contractInfo.waterPayment"
          />
        </v-col>

        <!-- Gaz -->
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">القيمه السنويه لخدمه الغاز</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="القيمه السنويه لخدمه الغاز"
            @input="handleTotalValue($event, 'gas')"
            :value="gasYearlyMask"
            @keydown="$formatNumberInputs($event, true)"
          />
        </v-col>
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">القيمه الكليه لخدمه الغاز</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="القيمه الكليه لخدمه الغاز"
            :value="gasMask"
            disabled
          />
        </v-col>
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">رقم الإشتراك (اختياري)</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="رقم الإشتراك (اختياري)"
            v-model.number="contractInfo.gasMeter"
            @input="formatArabicNumber($event, 'gasMeter')"
            @keydown="$formatNumberInputs"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <h6 class="mt-0 mb-2">فتره التحصيل</h6>
          <v-select
            :rules="
              contractInfo.gas && !hasCombinedRent
                ? [(v) => !!v || `اختر الفترة`]
                : [true]
            "
            :disabled="!contractInfo.gas || hasCombinedRent"
            :items="contractIntervals"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            placeholder="فتره التحصيل"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            item-text="nameAr"
            item-value="value"
            required
            v-model="contractInfo.gasPayment"
          />
        </v-col>
        <!-- GEN -->
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">القيمه السنويه للخدمات العامه</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="القيمه السنويه للخدمات العامه"
            @input="handleTotalValue($event, 'generalServices')"
            :value="generalServicesYearlyMask"
            @keydown="$formatNumberInputs($event, true)"
          />
        </v-col>
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">القيمه الكليه للخدمات العامه</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="القيمه الكليه للخدمات العامه"
            :value="generalServicesMask"
            disabled
          />
        </v-col>
        <v-col class="pl-3" cols="12" sm="3">
          <h6 class="mt-0 mb-2">رقم الإشتراك (اختياري)</h6>
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="رقم الإشتراك (اختياري)"
            v-model.number="contractInfo.generalServicesMeter"
            @input="formatArabicNumber($event, 'generalServicesMeter')"
            @keydown="$formatNumberInputs"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <h6 class="mt-0 mb-2">فتره التحصيل</h6>
          <v-select
            :rules="
              contractInfo.generalServices && !hasCombinedRent
                ? [(v) => !!v || `اختر الفترة`]
                : [true]
            "
            :disabled="!contractInfo.generalServices || hasCombinedRent"
            :items="contractIntervals"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            placeholder="فتره التحصيل"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            item-text="nameAr"
            item-value="value"
            required
            v-model="contractInfo.generalServicesPayment"
          />
        </v-col>
      </v-row>
      <v-row class="my-2 d-flex algin-center px-5">
        <v-col class="px-0 d-flex align-center mb-2" cols="3" sm="3">
          <v-divider></v-divider>
        </v-col>
        <v-col class="px-2 mb-2" cols="12" sm="6">
          <div class="borderedContainer text-center py-1">
            <p class="ma-0 pa-0 font-14">القيمه الكليه للمبالغ الثابته</p>
            <p class="ma-0 pa-0 font-14 font-weight-bold">{{ total }} ريال</p>
          </div>
        </v-col>
        <v-col class="px-0 d-flex align-center mb-2" cols="3" sm="3">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="mt-1" cols="6" sm="6">
          <h6 class="mt-0 mb-2">
            احتساب المبالغ الثابته مع الايجار في مطالبه واحده
          </h6>
          <v-card class="d-flex justify-space-between px-2 mx-2">
            <h6 class="mx-1 font-14">{{ hasCombinedRent ? 'نعم' : 'لا' }}</h6>
            <v-switch
              class="my-auto"
              dense
              hide-details="auto"
              inset
              small
              v-model="hasCombinedRent"
            ></v-switch>
          </v-card>
        </v-col>
        <v-col class="mt-1" cols="6" sm="6">
          <h6 class="mt-0 mb-2">هل توجد ضريبة على الخدمات؟</h6>
          <v-card class="d-flex justify-space-between px-2 mx-2">
            <h6 class="mx-1 font-14">{{ hasTax ? 'نعم' : 'لا' }}</h6>
            <v-switch
              class="my-auto"
              dense
              hide-details="auto"
              inset
              small
              v-model="hasTax"
            ></v-switch>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="12" sm="12">
          <v-card class="informativeCard pa-4">
            <div class="holder d-flex align-center">
              <div class="mr-3 ml-5">
                <v-img :src="exclamation" />
              </div>
              <div class="text">
                <span>
                  <p class="headTitle font-14 font-weight-bold ma-0">
                    ستحتسب فترات تحصيل المبالغ الثابته بناء علي تحصيل مبالغ
                    الايجار
                  </p>
                </span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import inputMasking from '@/mixins/inputMasking.js'
import { convertNumbers2English } from '@/utils/formatters'
import { mapMutations } from 'vuex'

export default {
  name: 'Step3',
  props: {
    isRentContract: {
      default: false
    },
    contractIntervals: {
      default: [],
      required: true
    },
    updatedContract: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    },
    exclamation: {
      default: require('@/assets/img/svgs/exclamationMark.svg')
    },
    duration: {
      type: Number,
      required: true
    }
  },
  mixins: [inputMasking],
  data() {
    return {
      electricityMask: '',
      electricityYearlyMask: '',
      waterMask: '',
      waterYearlyMask: '',
      gasMask: '',
      gasYearlyMask: '',
      generalServicesMask: '',
      generalServicesYearlyMask: '',
      isValid: false,
      contractInfo: {
        utilitiesTax: 0
      },
      hasCombinedRent: false,
      hasTax: false,
      servicesKeys: ['electricity', 'water', 'gas', 'generalServices']
    }
  },
  computed: {
    periodKeys() {
      return this.servicesKeys.map((key) => {
        return `${key}Payment`
      })
    },
    electricityKeys() {
      return {
        maskValKey: 'electricityMask',
        parentKey: 'contractInfo',
        actualValKey: 'electricity'
      }
    },
    electricityYearlyKeys() {
      return {
        maskValKey: 'electricityYearlyMask',
        parentKey: 'contractInfo',
        actualValKey: 'electricityYearlyPayment'
      }
    },
    waterKeys() {
      return {
        maskValKey: 'waterMask',
        parentKey: 'contractInfo',
        actualValKey: 'water'
      }
    },
    waterYearlyKeys() {
      return {
        maskValKey: 'waterYearlyMask',
        parentKey: 'contractInfo',
        actualValKey: 'waterYearlyPayment'
      }
    },
    gasKeys() {
      return {
        maskValKey: 'gasMask',
        parentKey: 'contractInfo',
        actualValKey: 'gas'
      }
    },
    gasYearlyKeys() {
      return {
        maskValKey: 'gasYearlyMask',
        parentKey: 'contractInfo',
        actualValKey: 'gasYearlyPayment'
      }
    },
    generalServicesKeys() {
      return {
        maskValKey: 'generalServicesMask',
        parentKey: 'contractInfo',
        actualValKey: 'generalServices'
      }
    },
    generalServicesYearlyKeys() {
      return {
        maskValKey: 'generalServicesYearlyMask',
        parentKey: 'contractInfo',
        actualValKey: 'generalServicesYearlyPayment'
      }
    },
    total() {
      const calcutations =
        (this.contractInfo?.water ?? 0) +
        (this.contractInfo?.gas ?? 0) +
        (this.contractInfo?.electricity ?? 0) +
        (this.contractInfo?.generalServices ?? 0)

      return calcutations.toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    }
  },
  watch: {
    hasTax(val) {
      this.contractInfo.utilitiesTax = val ? 15 : 0
    },
    hasCombinedRent(val) {
      this.contractInfo.hasCombinedRent = !!val
      this.periodKeys.map((key) => {
        delete this.contractInfo[key]
      })
    },
    isValid(val) {
      this.$emit('setValdationStatus', val)
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    handleTotalValue(value, key) {
      const formatTotalValue = convertNumbers2English(value)
      this.handleValuesChanging(formatTotalValue, this[`${key}YearlyKeys`])
      let val = 0
      const formatedValue = Number(formatTotalValue.replaceAll(',', ''))
      if (this.duration === 0.0333) {
        val = Number(formatedValue) / 365
      } else if (this.duration <= 12) {
        val = (Number(formatedValue) / (12 / this.duration)).toFixed(3)
      } else {
        val = (Number(formatedValue) * (this.duration / 12)).toFixed(3)
      }
      return this.handleValuesChanging(val, this[`${key}Keys`])
    },

    resetDurations() {
      // reset payments object values
      for (const key in this.contractInfo) {
        if (key.includes('Payment')) {
          delete this.contractInfo[key]
        }
      }
    },
    formatArabicNumber(val, key) {
      this.contractInfo[key] = Number(convertNumbers2English(val))
    },
    bindContractData() {
      this.$emit('bindContractData', {
        ...this.contractInfo,
        hasCombinedRent: this.hasCombinedRent
      })
    },
    emitValidationStatus() {
      this.$emit('setValdationStatus', this.isValid)
    },
    resetData() {
      this.contractInfo = {}
    },
    presetData() {
      this.editMoodPresetData()
      this.emitValidationStatus()
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        // set electricity, water, gas and generalServices values
        this.servicesKeys.map((key) => {
          this.$set(
            this.contractInfo,
            `${key}`,
            this.updatedContract?.[key] ?? 0
          )
          this.handleValuesChanging(this.contractInfo[key], this[`${key}Keys`])

          this.$set(
            this.contractInfo,
            `${key}Payment`,
            this.updatedContract?.[`${key}Payment`] ?? 0
          )
          this.$set(
            this.contractInfo,
            `${key}Meter`,
            this.updatedContract?.[`${key}Meter`] ?? 0
          )
          this.$set(
            this.contractInfo,
            `${key}Meter`,
            this.updatedContract?.[`${key}Meter`] ?? 0
          )

          this.$set(
            this.contractInfo,
            `${key}YearlyPayment`,
            this.updatedContract?.[`${key}YearlyPayment`] ?? 0
          )
          this.handleValuesChanging(
            this.contractInfo[`${key}YearlyPayment`],
            this[`${key}YearlyKeys`]
          )
        })

        this.$set(
          this.contractInfo,
          'utilitiesTax',
          this.updatedContract?.utilitiesTax
        )
        this.hasTax = !!this.updatedContract?.utilitiesTax

        this.hasCombinedRent = this.updatedContract?.hasCombinedRent ?? false
      }
    }
  }
}
</script>
