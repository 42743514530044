<template>
  <div id="step-2">
    <!-- phone-->
    <div class="step-title">
      <h6 class="submit-section-header black--text font-weight-bold">
        رقم الجوال
      </h6>
    </div>
    <div class="form-row form-row-wide m-0 font-weight-bold">
      <v-card
        class="pa-3 d-flex justify-space-between align-center"
        color="lightGery"
      >
        {{ phoneNumber }}
      </v-card>
    </div>
    <!-- password-->
    <div class="step-title">
      <h6 class="submit-section-header black--text font-weight-bold">
        كلمة المرور
      </h6>
    </div>
    <div class="passwordDisabled d-flex align-center pl-3">
      <v-text-field
        ref="password"
        v-model="password_"
        :rules="[() => !!password || '']"
        :type="showPassword ? 'text' : 'password'"
        filled
        persistent-hint
        hide-details
        disabled
        @click:append="showPassword = !showPassword"
      />
      <v-btn icon color="grey" @click="showPassword = !showPassword">
        <v-icon v-if="showPassword">mdi-eye</v-icon>
        <v-icon v-else>mdi-eye-off</v-icon>
      </v-btn>
    </div>
    <!-- otp-->
    <div class="step-title mb-5">
      <h6 class="submit-section-header black--text font-weight-bold">
        التحقق من رقم الجوال
      </h6>
      <p>
        فضلا قم بإدخال رمز التحقيق المكون من ٤ أرقام المرسل إلى جوالك رقم
        <strong>{{ phoneNumber }}</strong>
        <span v-show="timeOTP"> في خلال </span>
        <strong data-test="ss" class="subtitle-1 px-1" v-text="timeOTP" />
        <a
          data-test="generateOTP"
          @click="generateNewOTP"
          class="d-block mt-2 link-generate__otp"
          v-if="!timeOTP && !enteredOtp"
          >ارسل الرمز التاكيدي</a
        >
      </p>
    </div>
    <div id="vie-otp-input-wrap" class="mb-5">
      <vie-otp-input
        ref="otpInput"
        input-classes="otp-input"
        :num-inputs="4"
        separator=""
        :should-auto-focus="true"
        @on-complete="handleOnComplete"
      />
    </div>
  </div>
</template>

<script>
import VieOtpInput from '@bachdgvn/vue-otp-input'
import { mapActions, mapMutations } from 'vuex'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  name: 'RegisterStep2',
  components: {
    VieOtpInput
  },
  props: ['phoneNumber', 'password', 'confirmationCode'],
  inject: ['OTP'],
  data() {
    return {
      enteredOtp: null,
      showPassword: false,
      helpCenterIcon: require('@/assets/img/svgs/register-help-center.svg'),
      timeOTP: null,
      durationOTP: 1
    }
  },
  created() {
    this.timerOTP()
  },
  methods: {
    ...mapActions('accountState', ['register']),
    ...mapMutations('accountState', [
      'registerSuccess',
      'loginSuccess',
      'registerFailure',
      'setCurrentEnv',
      'setConfigrations'
    ]),

    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapMutations('appState', ['addNotification']),
    // Timer for OTP - 1 minute
    timerOTP() {
      let timeOTP = this.durationOTP * 60

      const interval = setInterval(() => {
        const minutes = Math.floor(timeOTP / 60)
        let seconds = timeOTP % 60

        // 1 - If code valid will clear interval
        if (this.OTP.isCodeValid) {
          clearInterval(interval)
        }

        // 2 - Reset time when generate new OTP
        if (this.OTP.newCode) {
          timeOTP = this.durationOTP * 60
          this.OTP.newCode = false
          return
        }
        seconds = seconds < 10 ? `0${seconds}` : seconds

        // 3 - View in UI
        this.timeOTP = `${minutes}:${seconds}`

        // 4 - When time out
        if (timeOTP === 0) {
          this.timeOTP = null
        }

        // 5 - Time decrement
        if (timeOTP > 0) {
          timeOTP--
        }
      }, 1000)
    },
    // Generate new OTP
    generateNewOTP() {
      this.$emit('sendUserConfirmCode')
    },
    handleOnComplete(otp) {
      this.enteredOtp = Number(convertNumbers2English(otp))
      const phoneNumber = this.phoneNumber
      this.$emit('on-validate', {
        phoneNumber,
        confirmationCode: Number(convertNumbers2English(otp)),
        showMsg: false
      })
      return false
    }
  },
  computed: {
    password_: {
      get() {
        return this.password
      },
      set(value) {
        this.$emit('changePassword', value)
      }
    }
  }
}
</script>

<style>
#vie-otp-input-wrap > div {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 -10px;
}

#vie-otp-input-wrap > div > div {
  flex-grow: 1;
  margin: 0 10px;
}

.otp-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.otp-input.error {
  border: 1px solid red !important;
}

a.link-generate__otp {
  text-decoration: underline;
}
</style>
