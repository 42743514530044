/**
 * Checks if the given data has a meaningful value.
 *
 * This function handles various data types and considers:
 * - Objects and arrays: They have a value if they contain at least one element with a value (recursively checks nested elements).
 * - Strings: They have a value if they contain non-whitespace characters after trimming.
 * - Booleans: They have a value if they are either `true` or `false`.
 * - Null and undefined: They are considered to have no value.
 *
 * @param {any} data The data to check for value.
 * @returns {boolean} True if the data has a value, false otherwise.
 *
 * @example
 * hasValue(null); // false
 * hasValue(undefined); // false
 * hasValue(""); // false (empty string)
 * hasValue("  "); // false (whitespace string)
 * hasValue("hello"); // true
 * hasValue([]); // false (empty array)
 * hasValue([1, 2, 3]); // true
 * hasValue({}); // false (empty object)
 * hasValue({ name: "hazem" }); // true (object with a property)
 */
const hasValue = (data) => {
  if (data && [Object, Array].includes(data.constructor)) {
    const values = Object.values(data)
    return !!values.length && values.some(hasValue)
  } else if (typeof data === 'string') {
    return !!data.replace(/\s+/g, '').length
  } else if (typeof data === 'boolean') {
    return [true, false].includes(data)
  } else if ([null, undefined].includes(data)) {
    return false
  }
  return !!data
}

/**
 * Removes empty elements (null, undefined, empty strings, empty arrays, or empty objects) from an array or object.
 *
 * This function performs a depth-first traversal, recursively excluding empty values.
 * Non-empty values are retained in the resulting data structure.
 *
 * @param {any} data The array or object to filter.
 * @returns {any} A new array or object containing only the non-empty elements, or null if the entire data structure is empty.
 *
 * @example
 * excludeEmpty([1, "", null, { name: "hazem" }]); // [1, { name: "hazem" }]
 * excludeEmpty({ name: "", age: undefined, hobbies: ["programming"] }); // { hobbies: ["programming"] }
 * excludeEmpty([]); // []
 * excludeEmpty({}); // null
 * excludeEmpty(null); // null
 * excludeEmpty(undefined); // null
 */
const excludeEmpty = (data) => {
  if (data === null) {
    return null
  }

  if (Array.isArray(data) || typeof data === 'object') {
    return Object.entries(data).reduce(
      (acc, [key, value]) => {
        const _value = excludeEmpty(value)
        if (hasValue(_value)) {
          acc[key] = _value
        }
        return acc
      },
      Array.isArray(data) ? [] : {}
    )
  }
  return hasValue(data) ? data : null
}

/**
 * Checks if the given value is a valid email address format.
 *
 * This function uses a regular expression to validate the structure of the email address,
 * including the local part, "@" symbol, and domain name. It is case-insensitive.
 *
 * @param {string} value The email address to validate.
 * @returns {boolean} True if the value is a valid email address format, false otherwise.
 *
 * @example
 * isValidEmail("john.doe@example.com"); // true
 * isValidEmail("user_name@domain.co.uk"); // true
 * isValidEmail("invalid@email"); // false (missing domain extension)
 * isValidEmail("notanemail"); // false (missing "@" symbol)
 * isValidEmail(""); // false (empty string)
 */
const isValidEmail = (value) =>
  !!(
    value &&
    String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  )

/**
 * Builds a query string from an object of parameters.
 *
 * This function takes an object containing key-value pairs and constructs a URL-encoded query string.
 * It properly handles encoding of both keys and values to ensure compatibility with different URLs.
 *
 * @param {Object} params An object containing key-value pairs representing the query parameters.
 * @param {string} [prefix='?'] An optional prefix to be prepended to the query string (default is '?').
 * @returns {string} The constructed query string.
 *
 * @example
 * const params = { name: 'hazem', age: 24 };
 * const queryString1 = buildQueryString(params); // Output: ?name=hazem&age=24
 * const queryString2 = buildQueryString(params, '&'); // Output: &name=hazem&age=24 (custom prefix)
 */
const buildQueryString = (params, prefix = '?') => {
  const queryString = []

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key]
      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryString.push(
            `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`
          )
        })
      } else {
        queryString.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
      }
    }
  }

  return prefix + queryString.join('&')
}

/**
 * Checks if any value within a nested object structure is falsy, including checking for falsy values within arrays.

 * @param {object} data - The object to be checked.

 * @returns {boolean} True if any value is falsy, False otherwise.

 * @example
 * const data = {
 *   name: "hazem Hamdy",
 *   age: 24,
 *   addresses: ["123 Main St", null],  // Null value in array
 *   skills: [],  // Empty array
 *   hobbies: false,  // Falsy value
 * };

 * const hasFalsy = hasFalsyValue(data);
 * console.log(hasFalsy);  // Output: true (empty array, null value, and false found)
 */
const hasFalsyValue = function (data) {
  for (const key in data) {
    const value = data[key]
    if (value === undefined || value === null) {
      return true
    } else if (Array.isArray(value)) {
      if (value.length === 0 || value.some((item) => item === null)) {
        return true
      }
    }
  }
  return false
}

/**
 * Excludes a list of keys from a provided object.

 * @param {Object} data - The object from which keys will be excluded.
 * @param {string[]} keysToExclude - An array containing the keys to exclude.

 * @returns {Object} A new object with the excluded keys removed.

 * @example
 * const data = { name: 'Hazem', age: 24, city: 'Hawamdya' };
 * const keysToExclude = ['age', 'city'];
 *
 * const excludedData = excludeKeys(data, keysToExclude);
 * console.log(excludedData); // Output: { name: "Hazem" }
 */
const excludeKeys = function (data, keysToExclude) {
  return Object.entries(data)
    .filter(([key]) => !keysToExclude.includes(key))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
}

/**
 * Compares two arrays to check if they are equal.
 * @param {Array} arr1 - The first array to compare.
 * @param {Array} arr2 - The second array to compare.
 * @returns {boolean} Returns true if the arrays are equal, false otherwise.
 */
const areArraysEqual = function (arr1, arr2) {
  // Check if lengths are different
  if (arr1.length !== arr2.length) {
    return false
  }

  /**
   * Helper function to compare two objects.
   * @param {Object} obj1 - The first object to compare.
   * @param {Object} obj2 - The second object to compare.
   * @returns {boolean} Returns true if the objects are equal, false otherwise.
   */
  const areObjectsEqual = function (obj1, obj2) {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    // Check if number of properties is different
    if (keys1.length !== keys2.length) {
      return false
    }

    // Check if values of each property are different
    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false
      }
    }
    return true
  }

  // Compare each object in the arrays
  for (let i = 0; i < arr1.length; i++) {
    if (!areObjectsEqual(arr1[i], arr2[i])) {
      return false
    }
  }

  // All checks passed, arrays are equal
  return true
}

const getLocalNames = (name) => {
  const names = {
    owner: 'مالك',
    renter: 'مستأجر',
    company: 'شركة',
    individual: 'فرد',
    new: 'جديدة',
    inProgress: 'قيد الإنتظار',
    done: 'تم التنفيذ',
    cancelled: 'ملغي',
    rejected: 'مرفوض',
    جديدة: 'new',
    'قيد الإنتظار': 'inProgress',
    'تم التنفيذ': 'done',
    ملغي: 'cancelled',
    مرفوض: 'rejected',
    عاجل: 'high',
    طارئ: 'urgent',
    متوسط: 'normal',
    منخفض: 'low',
    high: 'عاجل',
    urgent: 'طارئ',
    normal: 'متوسط',
    low: 'منخفض',
    الكل: 'الكل'
  }
  return names[name] || '-'
}

export {
  hasValue,
  excludeEmpty,
  areArraysEqual,
  isValidEmail,
  buildQueryString,
  hasFalsyValue,
  excludeKeys,
  getLocalNames
}
