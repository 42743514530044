<template>
  <div class="col-md-6 hectar--statistics">
    <div class="withData">
      <div class="component-title">
        <h5 class="text-truncate font-16 mb-1">الإحصائيات المالية</h5>
      </div>
      <div class="financial__statistics--cards py-2">
        <v-col
          class="pt-0 px-1"
          :cols="getCardsCols"
          v-for="financialStat in financialStats"
          :key="financialStat.nameEn"
        >
          <div
            class="card v-card v-sheet theme--light card-1 py-5 px-4 justify-space-between align-start"
          >
            <div class="financial__statistics--tick--icon icon icon__green">
              <img
                height="18"
                width="18"
                :lazy-src="icons[financialStat.nameEn] || icons['default']"
                :src="icons[financialStat.nameEn] || icons['default']"
              />
            </div>
            <div class="card-title text-container">
              <p
                @click="handleRedirection(financialStat.path)"
                class="title text-truncate"
                :class="{
                  'pointerCursor text-decoration-underline':
                    financialStat.path.length
                }"
                v-text="financialStat.nameAr"
              />
              <h5 class="col-value font-weight-bold">
                {{ financialStat.value.total || financialStat.value }}
                <span class="grey--text font-20">
                  {{ financialStat.value.currency || '' }}
                </span>
              </h5>
            </div>
          </div>
        </v-col>
      </div>
      <!-- End-financial__statistics--cards -->
    </div>
  </div>
</template>

<script>
import numberFormatter from '@/mixins/numberFormatter.js'

export default {
  name: 'FinancialStatistice',
  props: {
    financialStats: {
      type: [Array, Object],
      required: true
    },
    status: {
      type: Boolean,
      default: false
    }
  },
  mixins: [numberFormatter],
  data() {
    return {
      icons: {
        arrears: require('@/assets/img/svgs/close.svg'),
        customerDues: require('@/assets/img/svgs/wallet.svg'),
        receipts: require('@/assets/img/svgs/wallet.svg'),
        default: require('@/assets/img/svgs/checkmark-outline.svg')
      }
    }
  },
  computed: {
    getCardsCols() {
      const isAlongVal = this.financialStats.find((state) => {
        return state.value.toString().length > 10
      })
      return !!isAlongVal ? 6 : 4
    }
  },
  methods: {
    handleRedirection(path) {
      if (path.length) {
        this.$router.replace({ name: 'clients', query: { filter: path } })
      }
    }
  }
}
</script>
