<template>
  <v-layout justify-space-between align-center>
    <v-layout align-center>
      <v-img
        :lazy-src="exitArrow"
        max-height="15"
        max-width="15"
        :src="exitArrow"
        class="mt-3 mobile__mt__0"
        @click="closeSideDetails"
      ></v-img>
      <div class="ma-0 mt-2 mr-4 mobile__mt__0" v-if="item">
        <h5 class="font-weight-medium text-truncate max-width-250">
          <span class="font-16 font-weight-bold">{{
            item.title || 'فاتوره ضريبيه'
          }}</span>
        </h5>
      </div>
      <h5
        v-if="!isCancelledTax"
        :class="specifyNameColor(statusItem).color"
        class="ma-0 tag-sm text-center py-0 pb-1 mx-3 mt-2 mobile__mt__0"
      >
        <span class="font-12 font-weight-bold my-auto text-truncate">
          {{ specifyNameColor(statusItem).nameAr }}
        </span>
      </h5>
      <h5 v-else class="red--tag ma-0 tag-sm text-center py-0 pb-1 mx-3 mt-2">
        <span class="font-12 font-weight-bold my-auto text-truncate">
          ملغيه
        </span>
      </h5>
    </v-layout>
    <v-layout
      class="actions-container"
      :justify-end="!xs"
      wrap
      v-if="!isCancelledTax"
    >
      <div v-for="(btn, i) in coreBtns" :key="i">
        <v-btn
          width="133"
          class="actions px-8 mx-2"
          :class="btn.class"
          :color="btn.color"
          @click="btnActions(btn.action)"
          v-if="isActionAvaliable(btn.needAuthority)"
        >
          <v-img
            class="mt-1 ml-1"
            max-height="16"
            max-width="16"
            :src="btn.icon"
          ></v-img>
          <span class="font-12">
            {{ btn.text }}
          </span>
        </v-btn>
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'sideDetailesTaxInvoiceHeader',
  props: {
    isCancelledTax: {
      default: false
    },
    item: {
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      selectedActions: [],
      cancelIcon: require('@/assets/img/svgs/cancel.svg'),
      exitArrow: require('@/assets/img/svgs/right-arrow.svg'),
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أكتوبر',
        'نوفمبر',
        'ديسمبر'
      ],
      userName: JSON.parse(localStorage.getItem('tokenData')).name,
      menuStatus: false,
      backToPreviousPath: false
    }
  },
  computed: {
    coreBtns() {
      const btns = [
        {
          text: 'بي دي إف',
          icon: require('@/assets/img/svgs/pdf-white.svg'),
          color: '#662D91',
          action: 'export',
          needAuthority: false
        },
        {
          text: 'تحويل الى ساريه',
          icon: require('@/assets/img/svgs/exportTaxDetails.svg'),
          class: 'edit',
          action: 'changeToApproved',
          needAuthority: false
        },
        {
          text: 'مشاركة',
          icon: require('@/assets/img/svgs/white-share-pdf.svg'),
          action: 'share',
          color: '#12CAD6',
          needAuthority: false
        },
        {
          text: 'تعديل الفاتوره',
          icon: require('@/assets/img/svgs/edit.svg'),
          class: 'edit',
          action: 'edit',
          needAuthority: true
        },
        {
          text: 'إلغاء الفاتوره',
          icon: require('@/assets/img/svgs/cancel.svg'),
          class: 'edit',
          action: 'deleteTax',
          needAuthority: true
        }
      ]
      return this.item?.status === 'approved'
        ? btns.filter((btn) => btn.action !== 'changeToApproved')
        : btns
    },
    cardTitle() {
      return this.item?.title ?? 'فاتوره ضريبيه'
    },
    specifyNameColor: () => (app) => {
      switch (app) {
        case 'draft':
          return {
            nameAr: 'مسوده',
            color: 'orange--tag'
          }
        case 'approved':
          return {
            nameAr: 'ساريه',
            color: 'brub--tag'
          }
        default:
          return {
            nameAr: 'ساريه',
            color: 'brub--tag'
          }
      }
    },
    statusItem() {
      return this.item?.status
    },
    xs() {
      return this.$vuetify.breakpoint.xs
    }
  },
  created() {
    this.backToPreviousPath = !!this.$route.query?.backToCurrentPath
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeSideDetails() {
      if (this.backToPreviousPath) {
        this.$router.back()
      }
      this.$emit('closeDetails')
    },
    isActionAvaliable(needAuthority) {
      return needAuthority
        ? this.$hasPermission('properties', 'taxBills', 'add')
        : !needAuthority
    },
    resetCheckboxes() {
      this.menuStatus = false
      this.selectedActions = []
    },
    sendToClients() {
      this.menuStatus = true
      this.selectedActions = []
    },
    btnActions(action) {
      switch (action) {
        case 'edit':
          this.$emit('editTax', this.item)
          break
        case 'export':
          this.$emit('closeAddModelsEdit')
          break
        case 'deleteTax':
          this.$emit('deleteTax')
          break
        case 'share':
          this.$emit('sharePdf')
          break
        case 'sendToOwner':
          this.$emit('onTerminate', this.item._id)
          break
        case 'sendToRenter':
          this.$emit('onRenew', this.item._id)
        case 'changeToApproved':
          this.$emit('changeToApproved', this.item)
      }
    },
    // Generate used date format
    formatDate(date) {
      const recievedDate = new Date(date)
      return `${recievedDate.getDate()}  ${
        this.months[recievedDate.getMonth()]
      }`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.max-width-250 {
  max-width: 250px;
}
.actions-container {
  row-gap: 4px;
}
</style>
