<template>
  <div>
    <v-form>
      <!--  Ensurance -->
      <v-row no-gutters class="mb-5">
        <template v-for="(input, index) in financialClaimsInputs">
          <v-col
            class="pr-3 mobile__pl__0 py-2"
            cols="12"
            sm="6"
            v-if="
              input.availiablein.includes(isRentContract ? 'rent' : 'pm') &&
              input.vModel !== excludeInput
            "
            :key="index"
          >
            <h6 class="ma-0 mb-2">{{ input.title }}</h6>
            <v-text-field
              class="pl-3 mobile__pl__0"
              dense
              filled
              hide-details
              :placeholder="input.title"
              suffix="ريال"
              @input="handleValuesChanging($event, input.keys, 'maskVals')"
              :value="maskVals[`${input.keys.maskValKey}`]"
              @keydown="$formatNumberInputs($event, true)"
            />
          </v-col>
        </template>
      </v-row>
      <v-row no-gutters>
        <v-col class="my-5" cols="12" sm="12">
          <v-card class="informativeCard pa-2">
            <div class="holder d-flex align-center">
              <div class="mr-3 ml-5">
                <v-img :src="exclamation" />
              </div>
              <div class="text">
                <span>
                  <p class="headTitle font-14 font-weight-bold ma-0">
                    هذه المطالبات لا تدخل في قيمة العقد الكلية وتحسب لمرة واحدة
                    خلال فترة العقد
                  </p>
                </span>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col class="mt-0 mb-10" cols="12" sm="12">
          <v-card class="informativeCard pa-2">
            <div class="holder d-flex align-center">
              <div class="mr-3 ml-5">
                <v-img :src="exclamation" />
              </div>
              <div class="text">
                <span>
                  <p class="headTitle font-14 font-weight-bold ma-0">
                    {{ commissionText }}
                  </p>
                </span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import inputMasking from '@/mixins/inputMasking.js'

export default {
  name: 'contractFinancialClaims',
  props: {
    exclamation: {
      default: require('@/assets/img/svgs/exclamationMark.svg')
    },
    updatedContract: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false,
      type: Boolean
    },
    isRentContract: {
      default: true,
      type: Boolean
    },
    envHasCommissionTax: {
      default: false,
      type: Boolean
    },
    excludeInput: {
      type: String,
      default: ''
    }
  },
  mixins: [inputMasking],

  data() {
    return {
      maskVals: {
        insuranceMask: '',
        brokerageFeesMask: '',
        remnantRemovalFeesMask: '',
        engineeringSupervisionFeesMask: '',
        unitPreparementFeesMask: '',
        rentFeesMask: '',
        openingBalanceMask: ''
      },
      contractInfo: {}
    }
  },
  methods: {
    // Working on it - tying to find a way to build the obj calling dynamically

    emitValidationStatus() {
      this.$emit('setValdationStatus', true)
    },
    async bindContractData() {
      this.$emit('bindContractData', this.dataToBeBinded)
    },
    resetData() {
      this.contractInfo = {}
    },
    presetData() {
      this.editMoodPresetData()
      this.emitValidationStatus()
      this.$emit('setLastTabStatus', true)
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        this.financialClaimsInputs.map((input) => {
          this.$set(
            this.contractInfo,
            input.vModel,
            this.updatedContract?.[`${input.vModel}`]
          )
          this.maskVals[`${input.keys.maskValKey}`] =
            this.updatedContract?.[`${input.vModel}`]?.toLocaleString('en-US')
        })
      }
    }
  },
  computed: {
    commissionText() {
      return this.envHasCommissionTax
        ? 'الضريبة مٌفعلة في الإعدادات المالية التي ستنتج عن هذا العقد, بالإمكان إلغاء احتساب الضريبة من إعدادات الحساب'
        : 'الضريبة غير مٌفعلة في الإعدادات المالية التي ستنتج عن هذا العقد, بالإمكان تفعيل احتساب الضريبة من إعدادات الحساب'
    },
    financialClaimsInputs() {
      return [
        {
          title: 'قيمة التأمين',
          vModel: 'insurance',
          keys: this.insuranceKeys,
          availiablein: ['rent']
        },
        {
          title: 'أجرة سعي',
          vModel: 'brokerageFees',
          keys: this.brokerageFeesKeys,
          availiablein: ['rent']
        },
        {
          title: 'إزالة مخلفات',
          vModel: 'remnantRemovalFees',
          keys: this.remnantRemovalFeesKeys,
          availiablein: ['rent']
        },
        {
          title: 'إشراف هندسي',
          vModel: 'engineeringSupervisionFees',
          keys: this.engineeringSupervisionFeesKeys,
          availiablein: ['rent']
        },
        {
          title: 'تجهيز الوحدة الإيجارية',
          vModel: 'unitPreparementFees',
          keys: this.unitPreparementFeesKeys,
          availiablein: ['rent']
        },
        {
          title: 'قيمة تقبيل',
          vModel: 'rentFees',
          keys: this.rentFeesKeys,
          availiablein: ['rent']
        },
        {
          title: 'المطالبات السابقة',
          vModel: 'openingBalance',
          keys: this.openingBalanceKeys,
          availiablein: ['rent', 'pm']
        }
      ]
    },
    dataToBeBinded() {
      return this.contractInfo
    },
    insuranceKeys() {
      return {
        maskValKey: 'insuranceMask',
        parentKey: 'contractInfo',
        actualValKey: 'insurance'
      }
    },
    brokerageFeesKeys() {
      return {
        maskValKey: 'brokerageFeesMask',
        parentKey: 'contractInfo',
        actualValKey: 'brokerageFees'
      }
    },
    remnantRemovalFeesKeys() {
      return {
        maskValKey: 'remnantRemovalFeesMask',
        parentKey: 'contractInfo',
        actualValKey: 'remnantRemovalFees'
      }
    },
    engineeringSupervisionFeesKeys() {
      return {
        maskValKey: 'engineeringSupervisionFeesMask',
        parentKey: 'contractInfo',
        actualValKey: 'engineeringSupervisionFees'
      }
    },
    unitPreparementFeesKeys() {
      return {
        maskValKey: 'unitPreparementFeesMask',
        parentKey: 'contractInfo',
        actualValKey: 'unitPreparementFees'
      }
    },
    rentFeesKeys() {
      return {
        maskValKey: 'rentFeesMask',
        parentKey: 'contractInfo',
        actualValKey: 'rentFees'
      }
    },
    openingBalanceKeys() {
      return {
        maskValKey: 'openingBalanceMask',
        parentKey: 'contractInfo',
        actualValKey: 'openingBalance'
      }
    }
  }
}
</script>
