<template>
  <v-sheet id="env-pic">
    <img width="160" src="@/assets/img/logo.svg" class="mb-13" loading="lazy" />
    <h3 class="ma-0 font-weight-medium mb-8">ارسال الدعوات للأعضاء الجدد</h3>
    <h6 class="font-weight-medium h6-gery mb-6">
      يمكنك ارسال دعوات للأعضاء الجدد عن طريق رقم الجوال، ويمكنهم مشاركتك في
      بيئة عملك وتقسيم العمل بينكم ومتابعة اعمالكم
    </h6>
    <v-form ref="form" v-model="isValid">
      <div class="container">
        <v-text-field
          class="text-input"
          ref="input-inv"
          v-model="invitationModel"
          type="text"
          :rules="validateInput"
          label="ارسل دعوات عن طريق رقم الجوال او البريد الالكتروني"
          required
          filled
          :append-icon="invitationModel ? 'mdi-account-plus' : ''"
          @click:append="addToinvitaion(invitationModel, $event)"
          hide-details="auto"
          @keypress.enter="addToinvitaion(invitationModel, $event)"
        />
      </div>
    </v-form>
    <v-card v-if="invitation.length > 0">
      <v-slide-x-transition class="py-0" group tag="v-list">
        <template v-for="(invite, i) in invitation">
          <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>
          <invitee-item
            :key="i"
            :invitee="invite"
            :environmentId="envId"
          ></invitee-item>
        </template>
      </v-slide-x-transition>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapMutations } from 'vuex'
import InviteeItem from './AddInvitee'

export default {
  name: 'invitation',
  props: ['envId'],
  components: { InviteeItem },
  data() {
    return {
      invitation: [],
      invitationModel: '',
      isValid: false,
      validateInput: [
        (value) => {
          if (!value) {
            return 'صيغة رقم الهاتف أو البريد الإلكتروني غير صحيحة'
          } else if (
            value.includes('@') ||
            value.includes('.') ||
            /[a-zA-Z]/.test(value)
          ) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'البريد الالكتروني غير صحيح.'
          }
          const phonePattern = /^(05)([0-9]{8})$/
          return phonePattern.test(value) || 'صيغة رقم الهاتف غير صحيحة'
        }
      ]
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    validate() {
      if (this.invitation.length > 0) {
        this.$emit('on-validate', { invitations: this.invitation }, true)

        return true
      }
      this.addNotification({
        text: 'لم يتم اضافة اي دعوة',
        color: 'error',
        timeout: 4000
      })
      return false
    },
    addToinvitaion(value, event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.isValid === true) {
        this.invitation.push(value)
        this.$emit('toggle-skip-text', true)
        this.invitationModel = ''
      }
    },
    removeInvitaion(val) {
      const index = this.invitation.indexOf(val)
      this.invitation.splice(index, 1)
    }
  }
}
</script>
