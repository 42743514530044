import { realEstateService } from '../../services'
import {
  makeMutations,
  makeActions,
  makeGetters
} from '@/store/helpers/default.helper'
const configs = JSON.parse(localStorage.getItem('configrations'))

const defaultState = {
  propertiesFound: {},
  numbetOfReaEstate: null,
  mapCenter: { lat: 24.7135517, lng: 46.67529569999999 }, // Riyadh is default
  mapZoom: 12,
  listingsTitle: '',
  realEstateStatus: configs ? configs.realEstateStatus : {},
  realEstateTypes: configs ? configs.realestateTypes : {},
  realEstateFeatures: configs ? configs.realestateFeatures : {},
  realEstatePopulation: configs ? configs.realestatePopulations : {},
  realEstatePurposes: configs ? configs.realestatePurpose : {},
  previewedImages: [],
  pageSize: 12,
  mapEventsFlag: true,
  urlQuery: '',
  visitedProps: [],
  step1Percentage: 0,
  step2Percentage: 0,
  step3Percentage: 0,
  step4Percentage: 0,
  step5Percentage: 0,
  completePercentage: 0,
  isDisplayedOnce: false
}

const getters = {
  propertyLayouts: (state) => (id) =>
    state.properties.find((item) => item.id === id).layouts,
  totalCompletePercentage: (state) =>
    state.step1Percentage +
    state.step2Percentage +
    state.step3Percentage +
    state.step4Percentage +
    state.step5Percentage
}

const actions = {
  updateRealEstate(_, realEstateData) {
    return realEstateService.updateRealEstate(realEstateData)
  },
  getSingleProperty(_, propertyId) {
    return realEstateService.getSingleProperty(propertyId)
  }
}

const mutations = {
  setPropertiesFound(state, properties) {
    state.propertiesFound = properties
  },
  setNumbetOfReaEstate(state, number) {
    state.numbetOfReaEstate = number
  },
  previewedImages: (state, imageSrc) => {
    state.previewedImages.push(imageSrc)
  },
  deletePreviewedImages: (state) => {
    state.previewedImages = []
  },
  realEstateStatus: (state) => {
    const found = state.realEstateStatus.find((item) => item._id === 0)
    if (state.realEstateStatus && !found) {
      state.realEstateStatus.push({ _id: 0, nameAr: 'الكل' })
    }
  },
  realEstateTypes: (state) => {
    if (state.realEstateTypes) {
      const allItem = { nameAr: 'الكل', nameEn: 'All Types', _id: '0' }
      const found = state.realEstateTypes.find((item) => item._id === '0')
      if (!found) {
        return state.realEstateTypes.unshift(allItem)
      }
    }
    return null
  },
  toggleLayoutVisible(state, { propertyId, layoutIndex }) {
    const prope = state.properties.find((item) => item.id === propertyId)
    const layout = prope.layouts[layoutIndex]
    layout.visible = !layout.visible
  },
  deleteThisProperty(state, propertyId) {
    let deletedIndex = -1
    for (let i = 0; i < state.properties.length; i += 1) {
      if (state.properties[i].id === propertyId) {
        deletedIndex = i
      }
    }
    state.properties.splice(deletedIndex, 1)
  },
  setMapCenter(state, location) {
    state.mapCenter = location
  },
  setMapZoom(state, zoom) {
    state.mapZoom = zoom
  },
  setPropertiesTitle(state, title) {
    state.listingsTitle = title
  },
  setMapEventsFlag(state, value) {
    state.mapEventsFlag = value
  },
  setUrlQuery(state, query) {
    state.urlQuery = query
  },

  setstep1Percentage(state, val) {
    state.step1Percentage = val
  },
  setstep2Percentage(state, val) {
    state.step2Percentage = val
  },
  setstep3Percentage(state, val) {
    state.step3Percentage = val
  },
  setstep4Percentage(state, val) {
    state.step4Percentage = val
  },
  setstep5Percentage(state, val) {
    state.step5Percentage = val
  },

  addtoVisitedProps: (state, id) => {
    state.visitedProps.push(id)
  },

  setIsDisplayedOnce: (state, value) => {
    state.isDisplayedOnce = value
  }
}

export default {
  namespaced: true,
  state: defaultState,
  mutations: makeMutations(defaultState, mutations),
  actions: makeActions(defaultState, actions),
  getters: makeGetters(defaultState, getters)
}
