<template>
  <v-form ref="form" v-model="isValid">
    <v-row no-gutters>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="ma-0 font-weight-bold mb-2">
          هل تود إضافة الشروط والأحكام لهذا العرض؟
        </h6>
        <v-card class="d-flex justify-space-between px-2">
          <h6 class="mx-1 font-14">
            {{ priceOfferInfo.hasPolicy ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            readonly
            dense
            hide-details="auto"
            inset
            small
            :value="priceOfferInfo.hasPolicy"
            @click="showAlert('terms')"
          />
        </v-card>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="ma-0 font-weight-bold mb-2">
          هل تود إضافة ختم الشركة لتوقيع ممثل التأجير ؟
        </h6>
        <v-card class="d-flex justify-space-between px-2">
          <h6 class="mx-1 font-14">
            {{ priceOfferInfo.hasCompanySeal ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            readonly
            dense
            hide-details="auto"
            inset
            small
            :value="priceOfferInfo.hasCompanySeal"
            @click="showAlert('seal')"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-5">
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="mb-2 mt-0 font-weight-bold">ممثل التأجير</h6>
        <v-select
          :items="team"
          :menu-props="{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          }"
          placeholder="ممثل التأجير"
          :rules="[(v) => !!v || '']"
          append-icon="mdi-chevron-down"
          dense
          v-model="priceOfferInfo.rentReprsentitive"
          filled
          item-text="name"
          return-object
          hide-details
          required
        ></v-select>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="mb-2 mt-0 text-capitalize font-weight-bold">
          توقيع ممثل التأجير
        </h6>
        <v-file-input
          label=""
          disabled
          placeholder="ليس هناك توقيع متاح. يمكن إرفاقه من إعددات حساب ممثل التأجير"
          outlined
          class="rounded-lg"
          prepend-icon=""
          required
          dense
          accept="image/*"
          v-model="priceOfferInfo.attachment"
        >
          <template v-slot:append>
            <!-- <v-icon color="primary">mdi-check</v-icon> -->
            <v-icon color="primary">mdi-close</v-icon>
          </template>
        </v-file-input>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="ma-0 font-weight-bold mb-2">إضافة ممثل عن المستأجر</h6>
        <v-card class="d-flex justify-space-between px-2">
          <h6 class="mx-1 font-14">
            {{ renterStatus ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            dense
            hide-details="auto"
            inset
            small
            v-model="renterStatus"
          />
        </v-card>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="ma-0 font-weight-bold mb-2">
          إرسال طلب موافقة إلكترونية للعميل أو من يمثلة
        </h6>
        <v-card class="d-flex justify-space-between px-2">
          <h6 class="mx-1 font-14">
            {{ priceOfferInfo.sendEContract ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            readonly
            dense
            hide-details="auto"
            inset
            small
            :value="priceOfferInfo.sendEContract"
            @click="showAlert('sendEContract')"
          />
        </v-card>
      </v-col>
    </v-row>

    <!-- informativeCard -->
    <v-row
      no-gutters
      class="margin-block mt-5"
      v-if="!priceOfferInfo.sendEContract"
    >
      <v-col class="py-2 px-4 bg-brub rounded-xl d-flex">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="-0.000244141"
            width="20"
            height="20"
            rx="10"
            fill="#662D91"
            fill-opacity="0.1"
          />
          <g clip-path="url(#clip0_13864_307)">
            <path
              d="M12.4228 14.5743L12.2598 15.2407C11.7708 15.4337 11.3801 15.5806 11.0892 15.6816C10.798 15.7829 10.4597 15.8333 10.0741 15.8333C9.48212 15.8333 9.02163 15.6883 8.6932 15.4002C8.36478 15.111 8.20051 14.7445 8.20051 14.2999C8.20051 14.1278 8.21243 13.9508 8.23721 13.7704C8.2622 13.5899 8.30194 13.3865 8.35631 13.1592L8.96747 10.996C9.02184 10.7889 9.06806 10.5926 9.10507 10.407C9.14261 10.2226 9.1607 10.0529 9.1607 9.90009C9.1607 9.62384 9.1035 9.43061 8.98964 9.32197C8.87577 9.21364 8.65849 9.15864 8.33592 9.15864C8.17793 9.15864 8.01554 9.18395 7.85002 9.23288C7.68377 9.28203 7.54167 9.3295 7.42236 9.37362L7.58579 8.70673C7.98616 8.54372 8.36885 8.40413 8.73492 8.28827C9.10099 8.1721 9.44688 8.11397 9.77406 8.11397C10.362 8.11397 10.8157 8.25607 11.1343 8.54027C11.4529 8.82467 11.6121 9.19336 11.6121 9.64768C11.6121 9.74168 11.6017 9.9073 11.5792 10.144C11.5573 10.3813 11.5164 10.5987 11.4568 10.7964L10.8482 12.9509C10.7983 13.1239 10.7535 13.3218 10.7145 13.5444C10.6742 13.7655 10.655 13.9345 10.655 14.0478C10.655 14.3339 10.7188 14.5292 10.8467 14.6331C10.9755 14.7369 11.1973 14.7886 11.5125 14.7886C11.6605 14.7886 11.8288 14.7623 12.0159 14.7108C12.2024 14.6592 12.3384 14.6139 12.4228 14.5743ZM12.5771 5.5291C12.5771 5.90448 12.4357 6.22506 12.1514 6.48866C11.8678 6.7532 11.5261 6.88558 11.1264 6.88558C10.7254 6.88558 10.3828 6.7532 10.096 6.48866C9.80971 6.22496 9.66625 5.90448 9.66625 5.5291C9.66625 5.15446 9.80971 4.83335 10.096 4.5664C10.3823 4.29988 10.7255 4.16666 11.1264 4.16666C11.526 4.16666 11.8678 4.30019 12.1514 4.5664C12.4359 4.83335 12.5771 5.15456 12.5771 5.5291Z"
              fill="#662D91"
            />
          </g>
          <defs>
            <clipPath id="clip0_13864_307">
              <rect
                width="11.6667"
                height="11.6667"
                fill="white"
                transform="translate(4.16638 4.16666)"
              />
            </clipPath>
          </defs>
        </svg>
        <h6 class="text-primary font-12 ma-0 mr-2 font-weight-bold">
          سيتطلب عدم تفعيل الموافقة الإلكترونية الموافقة اليدوية من منشئ الطلب
          مع إرفاق العرض بتوقيع وختم العميل أو من يمثله
        </h6>
      </v-col>
    </v-row>

    <v-row v-if="renterStatus" no-gutters class="mt-5">
      <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
        <h6 class="ma-0 font-weight-bold mb-2">ممثل المستأجر</h6>
        <v-text-field
          :rules="renterStatus ? [(v) => !!v || ''] : []"
          class="font-14"
          dense
          filled
          required
          hide-details
          v-model="priceOfferInfo.renterRepresentative"
          placeholder="الرجاء ادخال اسم ممثل المستأجر"
        ></v-text-field>
      </v-col>
      <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
        <h6 class="ma-0 font-weight-bold mb-2">رقم هاتف ممثل المستأجر</h6>
        <v-text-field
          dense
          filled
          placeholder="الرجاء ادخال هاتف ممثل المستأجر"
          :rules="[
            (v) => !!v || '',
            (v) => /^055\d{6}$/.test(v) || 'من فضلك ادخل رقم هاتف صحيح'
          ]"
          class="font-14"
          :value="phoneNumberValueMask"
          @keydown="$formatNumberInputs"
          @input="formatPhoneNumber($event)"
          maxlength="9"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import inputMasking from '@/mixins/inputMasking.js'
import { convertNumbers2English } from '@/utils/formatters'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'TermsAndCondition',
  mixins: [inputMasking],
  props: {
    updatedPriceOffer: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    },
    team: {
      type: Array,
      required: true
    },
    attachment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isValid: false,
      priceOfferInfo: {},
      clearFileOnce: true,
      renterStatus: false,
      //
      phoneNumberValueMask: ''
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv'])
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    formatPhoneNumber(value) {
      const val = convertNumbers2English(value)
      this.priceOfferInfo.renterRepresentativePhoneNubmber = val
      this.phoneNumberValueMask = val
    },
    showAlert(type) {
      if (type === 'terms') {
        this.addNotification({
          text: 'لا توجد شروط وأحكام متاحة. الرجاء إضافتها من الإعدادات في قسم الخدمات',
          color: 'error'
        })
      }
      if (type === 'seal') {
        this.addNotification({
          text: 'لا يوجد ختم للشركة متاح. الرجاء إضافته من الإعدادات في قسم إعدادات الحساب',
          color: 'error'
        })
      }
      if (type === 'sendEContract') {
        this.addNotification({
          text: 'هذا الخيار غير متاح في الوقت الحالي',
          color: 'error'
        })
      }
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        const {
          rentReprsentitive,
          hasCompanySeal,
          hasPolicy,
          renterRepresentative,
          renterRepresentativePhoneNubmber,
          sendEContract
        } = this.updatedPriceOffer

        // renterStatus
        this.$set(this.priceOfferInfo, 'rentReprsentitive', rentReprsentitive)
        this.$set(this.priceOfferInfo, 'hasPolicy', hasPolicy)
        this.$set(this.priceOfferInfo, 'hasCompanySeal', hasCompanySeal)
        if (renterRepresentative) {
          this.renterStatus = true
        }
        this.formatPhoneNumber(renterRepresentativePhoneNubmber)
        this.$set(this.priceOfferInfo, 'sendEContract', sendEContract)
        this.$set(
          this.priceOfferInfo,
          'renterRepresentative',
          renterRepresentative
        )
        // this.$set(this.priceOfferInfo, 'attachment', {
        //   ...attachment,
        //   name: 'signature'
        // })
      }
    },
    presetData() {
      this.editMoodPresetData()
      this.$emit('setLastTabStatus', true)
    },
    dataToBeBinded() {
      return this.priceOfferInfo
    },
    bindContractData() {
      this.$emit('bindPriceOfferData', this.dataToBeBinded())
    }
  },
  watch: {
    renterStatus(val) {
      if (!val) {
        this.priceOfferInfo.renterRepresentativePhoneNubmber = null
        this.priceOfferInfo.renterRepresentative = null
        this.phoneNumberValueMask = ''
      }
    },
    isValid(val) {
      this.$emit('setValdationStatus', val)
    }
  }
}
</script>

<style lang="scss" scoped></style>
