<template>
  <div class="show-detailes">
    <v-slide-x-transition>
      <v-layout>
        <v-flex
          @click="$emit('showVersions', { visible: false, id: contract })"
          class="greySide"
          md3
        ></v-flex>
        <v-flex class="whiteSide" md9>
          <v-layout>
            <v-flex md1></v-flex>
            <v-flex md9>
              <contract-card
                v-for="i in versions"
                :item="i"
                :key="i"
                @showDetails="setDetails"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-slide-x-transition>
  </div>
</template>
<script>
import { contractsService } from '@/services'
import { mapMutations } from 'vuex'
import ContractCard from '@/components/contracts/ContractCard'

export default {
  name: 'side-versions',
  components: { ContractCard },
  props: ['contract'],
  data() {
    return {
      versions: []
    }
  },
  watch: {
    contract: {
      immediate: true,
      async handler() {
        try {
          const contractId = this.$props.contract
          if (contractId) {
            const allVersions = await contractsService.getContractsVersions(
              contractId
            )
            this.versions = allVersions.data.contractVersions
          }
        } catch (error) {
          this.addNotification({
            text: error,
            color: 'error'
          })
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setDetails({ visible, contract }) {
      this.$emit('showDetails', { visible, contract })
      this.$emit('showVersions', { visible: false, id: contract._id })
    }
  }
}
</script>
<style lang="scss" scoped>
.show-detailes {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  transition: 0.8s all;
  background: #00000063;
}

.whiteSide {
  background-color: #fff;
  min-width: 70vw;
  min-height: 100vh;
  padding: 14px 24px;
}

.greySide {
  background-color: rgba(37, 37, 37, 0.5);
  backdrop-filter: blur(7px);
}
</style>
