<template>
  <div>
    <v-form refs="form" v-model="formValid">
      <v-row align-content-sm no-gutters class="mt-5">
        <v-col class="pl-1" cols="12">
          <h5
            :class="{
              'red--flag': validationStatues(info.propertyName, nextabClicked)
            }"
            class="mb-2"
          >
            اسم الوحده*
          </h5>
          <v-text-field
            dense
            filled
            hide-details
            placeholder="اسم الوحده"
            require
            :rules="selectRules"
            v-model="info.propertyName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align-content-sm no-gutters class="my-4">
        <v-col class="pl-1" cols="12">
          <h5
            :class="{
              'red--flag': validationStatues(info.moderators, nextabClicked)
            }"
          >
            اسم المسئول*
          </h5>
          <v-select
            :items="employees"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            :placeholder="`المسؤول عن العقار`"
            append-icon="mdi-chevron-down"
            dense
            :rules="selectRules"
            filled
            hide-details
            item-text="name"
            item-value="_id"
            return-object
            clearable
            multiple
            v-model="info.moderators"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>{{ item.name }}</span>
              </v-chip>
              <span
                v-if="index === 2"
                class="text-grey text-caption align-self-center"
              >
                (+{{ info.moderators.length - 2 }} مسؤولين)
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-form>

    <v-card-actions class="pt-4 d-flex align-center">
      <v-spacer />
      <v-btn
        @click="nextTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <span class="mb-1 ml-1">التالي</span>
        <v-icon class="font-16">mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: 'unitDetails',
  props: {
    info: {
      default() {
        return {}
      }
    },
    employees: {
      type: Array,
      required: true
    },
    resetValidation: {
      default: false
    }
  },
  data() {
    return {
      selectRules: [(v) => !!v.length || 'هذا الحقل مطلوب'],
      formValid: false,
      nextabClicked: false
    }
  },
  methods: {
    nextTab() {
      this.nextabClicked = true
      if (this.formValid) {
        this.$emit('setAddPropertyInfo', this.info)
        this.$emit('nextTab')
      } else {
        this.$emit('addNotification')
      }
    }
  },
  computed: {
    validationStatues: () => (status, nextTab) => {
      return nextTab ? !status : false
    }
  },
  watch: {
    resetValidation: {
      handler() {
        this.nextabClicked = false
        if (this.$refs?.form) {
          this.$refs.form.reset()
        }
      }
    }
  }
}
</script>
