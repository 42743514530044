<template>
  <v-card
    v-if="showBluePrintCard"
    width="100%"
    :height="blueprintImage.image ? '510' : undefined"
    class="pb-4 pl-4"
  >
    <v-card-title>
      <div class="font-16 mr-4">مخطط ال{{ realEstateType }}</div>
    </v-card-title>

    <div class="mr-4">
      <div v-if="blueprintImage.image" class="position-relative">
        <img
          :src="blueprintImage.image"
          class="blueprint-image"
          loading="lazy"
        />

        <div class="position-relative mt-3 add-pic-container">
          <img :src="blueprintImage.image" class="add-pic" loading="lazy" />
          <div v-if="!blueprintImage.key" class="lds-ring-container">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <v-icon
            v-if="$hasPermission('realestates', 'realestates', 'add')"
            @click="deleteBlueprint"
            class="pic-icon position-absolute slide-y-transition"
            color="white"
          >
            mdi-close
          </v-icon>
        </div>
      </div>

      <div
        class="pic-wrapper d-flex align-center w-100 pa-6 mt-3 flex-wrap"
        @drop.prevent="addPropertyBlueprint($event)"
        @dragleave.prevent="dragLeave('pic')"
        @dragover.prevent="dragOver('pic')"
        @dragenter.prevent
        ref="pic"
        v-else
      >
        <div>
          <v-btn
            @click="uploadBluePrint"
            class="py-2 px-3 rounded-7 font-weight-bold"
            color="general"
            outlined
          >
            <v-icon size="20" color="general"> mdi-plus </v-icon>
            <span class="font-12"> إضافة المخطط </span>
          </v-btn>
          <input
            @change="addPropertyBlueprint($event)"
            accept="image/*"
            ref="inputUpload"
            type="file"
            v-show="false"
          />
        </div>
        <div class="mr-3">
          <h4 class="font-12">
            اسحب الصوره هنا لرفعها أو اضغط على زر إضافة المخطط
          </h4>
          <h5 class="text-gray font-12">يمكنك إضافه مخطط واحد فقط لعقارك</h5>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { realEstateService } from '@/services'
import drag from '@/mixins/drag.js'

export default {
  name: 'BlueprintDetails',
  mixins: [drag],
  props: {
    propertyData: {
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      propertyInfo: {},
      blueprintImage: {
        image: '',
        key: ''
      }
    }
  },
  computed: {
    showBluePrintCard() {
      return this.$hasPermission('realestates', 'realestates', 'add')
        ? this.$hasPermission('realestates', 'realestates', 'add')
        : this.blueprintImage.image.length
    },
    realEstateType() {
      return this.propertyInfo?.type?.nameAr ?? ''
    }
  },
  watch: {
    propertyData: {
      immediate: true,
      handler() {
        this.propertyInfo = {
          ...this.propertyData
        }
        this.blueprintImage.image = this.propertyInfo.bluePrint[0]?.image || ''
        this.blueprintImage.key = this.propertyInfo.bluePrint[0]?.key || ''
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    uploadBluePrint() {
      if (!this.$hasPermission('realestates', 'realestates', 'update')) {
        return this.addNotification({
          text: 'ليس لديك صلاحيه لتعديل العقار',
          color: 'error'
        })
      }
      return this.$refs.inputUpload.click()
    },
    addPropertyBlueprint(e) {
      const files = e.dataTransfer ? e.dataTransfer.files : e.target.files
      if (files.length > 1) {
        this.addNotification({
          text: 'لا يمكن اضافه اكثر من مخطط واحد',
          color: 'error'
        })
      } else {
        const file = files[0]
        const reader = new FileReader()
        reader.onload = () => {
          this.blueprintImage.image = reader.result
        }
        reader.readAsDataURL(file)
        this.uploadBlueprint(file)
      }
    },
    uploadBlueprint(file) {
      const fd = new FormData()
      fd.append('id', this.propertyInfo._id)
      fd.append('blueprint', file, file.name)
      realEstateService.addBlueprint(fd).then((res) => {
        this.$set(this.blueprintImage, 'image', res.data.success.Location)
        this.$set(this.blueprintImage, 'key', res.data.success.key)
        this.$emit('set-blueprint-image', this.blueprintImage)
        this.addNotification({
          text: 'تم إضافه المخطط بنجاح',
          color: 'success'
        })
      })
    },
    deleteBlueprint() {
      const blueprintKey = {
        id: this.propertyInfo._id,
        key: this.blueprintImage.key
      }
      realEstateService.deleteBlueprint(blueprintKey).then(() => {
        this.$set(this.blueprintImage, 'image', '')
        this.$set(this.blueprintImage, 'key', '')
        this.$emit('set-blueprint-image', this.blueprintImage)
        this.addNotification({
          text: 'تم حذف المخطط بنجاح',
          color: 'success'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/lds-ring.scss';

.blueprint-image {
  width: 100%;
  height: 296px;
  display: block;
  border: 1px solid #efebf2;
  border-radius: 10px;
}

.add-pic-container {
  display: inline-block;
}
</style>
