<template>
  <section class="ma-1 mx-4">
    <v-row class="mt-2">
      <v-col>
        <div class="d-flex align-center justify-space-between mb-2">
          <h6 class="text-gray ma-0 font-14">البند</h6>

          <!-- <img src="@/assets/img/clausePlusDetail.png" width="30" /> -->
          <div class="icon-wrapper">
            <v-icon color="#662d91" size="18">mdi-exclamation</v-icon>
          </div>
        </div>
        <h5 class="font-weight-bold">دفعة {{ DueDetails?.installmentType }}</h5>
      </v-col>
    </v-row>
    <v-divider class="mt-3"></v-divider>
    <v-row class="mt-2">
      <v-col>
        <div class="d-flex align-center justify-space-between mb-2">
          <h6 class="text-gray ma-0 font-14">القيمة</h6>
          <div class="icon-wrapper">
            <v-icon color="#662d91" size="18">mdi-currency-usd</v-icon>
          </div>
        </div>
        <h5 class="font-weight-bold">
          {{
            DueDetails?.payment?.toLocaleString('en-US', {
              maximumFractionDigits: 2
            })
          }}
          ريال
        </h5>
      </v-col>
    </v-row>
    <v-divider class="mt-3"></v-divider>
    <v-row class="mt-2">
      <v-col>
        <div class="d-flex align-center justify-space-between mb-2">
          <h6 class="text-gray ma-0 font-14">تاريخ الاستحقاق</h6>
          <div class="icon-wrapper">
            <v-icon color="#662d91" size="18">mdi-clock</v-icon>
          </div>
        </div>
        <h5 class="font-weight-bold">{{ formatDate(DueDetails?.date) }}</h5>
      </v-col>
    </v-row>
    <v-divider class="mt-3"></v-divider>
    <v-row class="mt-2">
      <v-col>
        <div class="d-flex align-center justify-space-between mb-2">
          <h6 class="text-gray ma-0 font-14">حالة الطالبة</h6>
        </div>
        <div
          class="tabel-tag text-center ml-1 my-1"
          style="width: fit-content"
          :class="generateStyleClasses(DueDetails.installmentStatus)"
        >
          <h5 class="font-12 font-weight-medium px-2 ma-0 text-truncate">
            {{ DueDetails.installmentStatus }}
          </h5>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-3"></v-divider>
    <v-row class="mt-2">
      <v-col>
        <div class="d-flex align-center justify-space-between mb-2">
          <h6 class="text-gray ma-0 font-14">حالة الدفع</h6>
          <div>
            <v-icon
              v-if="DueDetails.paymentStatus === 'غير مدفوعة'"
              color="danger"
              size="24"
              >mdi-minus-circle</v-icon
            >
            <v-icon
              v-else-if="DueDetails.paymentStatus === 'مدفوعة'"
              color="success"
              size="24"
              >mdi-check-circle</v-icon
            >
          </div>
        </div>
        <div
          class="tabel-tag ml-1 my-1"
          style="width: fit-content"
          :class="generateStyleClasses(DueDetails.paymentStatus)"
        >
          <h5 class="font-12 font-weight-bold px-2 ma-0 text-truncate pr-2">
            {{ DueDetails.paymentStatus }}
          </h5>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-3"></v-divider>
    <v-row class="mt-2">
      <v-col>
        <div class="d-flex align-center justify-space-between mb-2">
          <h6 class="text-gray ma-0 font-14">الجهة العقارية</h6>
          <v-img
            :max-width="110"
            :src="realStateDestenation.companyLogo"
          ></v-img>
        </div>
        <h5 class="font-weight-bold">{{ realStateDestenation.companyName }}</h5>
      </v-col>
    </v-row>
    <v-divider class="mt-3"></v-divider>
    <v-row class="mt-2">
      <v-col cols="12" class="pl-3">
        <v-card
          class="pa-2 ma-0 d-flex justify-space-between align-center"
          elevation="0"
          @click="addAttachment"
        >
          <p v-if="!clinetsAttachments?.length" class="mb-0">
            إضغط لإضافة إرفاق إشعار دفع (واحد فقط)
          </p>
          <p v-else class="font-weight-bold">
            يوجد مرفق من نوع ({{ clinetsAttachments[0]?.type }})
          </p>
          <div class="d-flex align-center">
            <v-icon
              @click.stop="downloadAttach(clinetsAttachments[0])"
              v-if="clinetsAttachments?.length"
              color="primary"
              class="mr-3"
              size="24"
              >mdi-download</v-icon
            >
            <v-icon
              @click.stop="previewAttach(clinetsAttachments[0])"
              v-if="clinetsAttachments?.length"
              color="primary"
              class="mr-3"
              size="24"
              >mdi-eye</v-icon
            >
            <v-icon
              v-if="clinetsAttachments?.length"
              @click.stop="deleteAttach(clinetsAttachments[0])"
              color="primary"
              class="mr-3"
              size="24"
              >mdi-close</v-icon
            >
            <v-icon v-else color="primary" size="24">mdi-upload</v-icon>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" class="pl-3" style="text-align: center">
        <v-btn
          class="py-1 rounded-7 font-weight-bold w-100"
          color="general"
          hide-details
          outlined
          @click="makeRouterRequest('invoice')"
          >طلب فاتورة</v-btn
        >
        <v-btn
          class="py-1 rounded-7 font-weight-bold w-100"
          color="general"
          hide-details
          outlined
          @click="makeRouterRequest('bonds')"
          >طلب سند</v-btn
        >
      </v-col>
    </v-row>
    <AttachmentsModal
      :attachTypes="['إشعار دفع', 'شيك مصرفي', 'اخري']"
      :dialogState="dialogState"
      @close-dialog="dialogState = false"
      :isLoading="isLoading"
      @selectFile="uploadDuesAttachment"
    />
  </section>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import { requestsService, contractsService } from '@/services'
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'PaymentDetails',
  components: {
    AttachmentsModal: () =>
      import('@/components/contracts/SideDetails/AttachmentsModal.vue')
  },
  props: {
    DueDetails: {
      type: Object,
      required: true
    },
    assignmentTypes: {
      type: Array,
      default: () => []
    }
  },
  mixins: [dateFormat],
  data() {
    return {
      requestTypes: [],
      dialogState: false,
      isLoading: false,
      clinetsAttachments: []
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'companyImages']),
    realStateDestenation() {
      // ! img is not working
      return {
        companyName: `شركة ${this.currentEnv?.owner?.company}`,
        companyLogo: this.currentEnv?.media?.companyLogo.location
      }
    },
    assignmentTypesName() {
      return this.assignmentTypes.map((e) => e.nameAr)
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    generateStyleClasses(value) {
      let classes = ''
      switch (value) {
        case 'مبلغ التأمين':
        case 'دفعة إيجار':
          classes = 'purple--bill'
          break
        case 'غير مدفوعة':
        case 'متأخرة':
        case 'ملغيّة':
          classes = 'red--bill'
          break
        case 'مدفوعة':
        case 'مستقبلية':
        case 'تم التحصيل':
          classes = 'green--bill'
          break
        case 'معلّقة':
          classes = 'orange--bill'
          break
        default:
          classes = 'default--bill'
      }
      return classes
    },
    previewAttach(item) {
      // open the file
      window.open(item.url, '_blank')
    },
    async downloadAttach(item) {
      // download the file
      try {
        const response = await fetch(item.url, { mode: 'cors' })
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', item.description)

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(link.href)
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    addAttachment() {
      if (this.clinetsAttachments.length >= 1) {
        this.addNotification({
          text: 'يجب أن تحتوي المرفقات على عنصر واحد فقط',
          color: 'error'
        })
        return
      }
      this.dialogState = true
    },
    deleteAttach(item, index = 0) {
      this.$root.$confirm
        .open({
          title: 'هل أنت متأكد من حذف المرفق',
          message: 'سيتم حذف المرفق نهائياّ من النظام',
          options: {
            color: 'danger',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteDuesDocument({ ...item, fileIndex: index })
          }
        })
    },
    async uploadDuesAttachment(attachment) {
      try {
        const reader = new FileReader()
        reader.onload = async () => {
          await this.onUpload(attachment)
        }
        reader.readAsDataURL(attachment.files)
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    async onUpload(file) {
      try {
        this.isLoading = true
        const fd = new FormData()
        fd.append('entityId', this.DueDetails._id)
        fd.append('envId', this.currentEnv._id)
        fd.append('entityType', 'assignment')
        fd.append('attachment', file.files)
        fd.append('type', file.type)
        fd.append('number', file.number)
        await contractsService.addAttachment(fd)

        this.addNotification({
          text: 'تم إضافه المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      } finally {
        this.getClientDuesAttachments()
        this.isLoading = false
      }
    },
    async deleteDuesDocument({ _id }) {
      try {
        await contractsService.deleteAttachment(_id)
        this.clinetsAttachments = []
        // .splice(fileIndex, 1)
        this.addNotification({
          text: 'تم حذف المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في حذف المرفق، يرجى المحاولة مجددا',
          color: 'error'
        })
      } finally {
        // this.showSideDetails()
      }
    },
    async getClientDuesAttachments() {
      if (!this.DueDetails._id) {
        return ''
      }
      try {
        const { data } = await contractsService.getAllAttachments(
          this.DueDetails._id
        )
        this.clinetsAttachments = data.attachments
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل مرفقات العقد',
          color: 'error'
        })
      }
    },
    async getRequestTypes() {
      try {
        const {
          data: { requestTypes }
        } = await requestsService.getRequestsTypes()
        this.requestTypes = requestTypes
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    makeRouterRequest(key) {
      // go to request page and opne add request modal and request type will be selected
      this.$router.push({
        name: 'maintenance',
        query: {
          reuquestType: this.requestTypes.find((item) => item.code === key)
        }
      })
    }
  },
  watch: {
    DueDetails(val) {
      if (Object.keys(val).length) {
        this.getClientDuesAttachments()
      }
    }
  },
  created() {
    this.getRequestTypes()
  }
}
</script>

<style lang="scss" scoped>
// .file-container-col {
//   .v-text-field--outlined fieldset {
//     border: 1px dashed #662d91;
//   }
// }
.icon-wrapper {
  background-color: #662d911a;
  padding: 0.2rem 0.3rem;
  border-radius: 50%;
  padding-top: 0;
}
</style>
