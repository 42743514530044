<template>
  <v-container id="bundles" class="mobile__mt__0 pb-10">
    <v-row v-if="!bundles.length" class="mx-0 my-5">
      <v-col class="mb-2 shadow-border-radius" v-for="i in 4" :key="i">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="article"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else class="d-flex align-center justify-center bundles-container">
      <v-col
        :class="[
          {
            'pt-5 special': card.nameEn == styleSpecialBundle,
            'pt-5': card.nameEn != styleSpecialBundle
          },
          'card'
        ]"
        :key="i"
        v-for="(card, i) in bundles"
        col="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          v-if="card.nameAr !== ignoreBundle"
          height="750"
          class="mx-auto px-5 flex flex-column"
        >
          <v-card-title class="px-2 font-20 text-101010 text-center">
            {{ card.nameAr }}
            <p
              class="font-14 my-1"
              v-if="card.code == 'advanced-bundle'"
              :class="{ 'white--text': card.nameEn == styleSpecialBundle }"
            />
          </v-card-title>
          <v-card-text class="mt-0 pb-3 px-2">
            <template>
              <div v-if="bundlePlane == 12">
                <div
                  class="anual__price__div d-flex align-center justify-center"
                >
                  <span class="ml-2 price"
                    >{{ formattedNumbers(card.yearPrice) ?? 0 }}
                  </span>
                  <span class="text--737373">ريال</span>
                </div>
              </div>
              <div v-else>
                <div class="anual__price__div">
                  <span class="ml-1 price">{{ setBundlePlanPrice(card) }}</span>
                  <span class="ml-1">ريال</span>
                  <span class="">/ {{ setBundlePlane }}</span>
                </div>
              </div>
            </template>
          </v-card-text>
          <ul class="feautres">
            <li
              :key="i"
              class="d-flex justify-between my-1"
              v-for="(subItem, i) in card.bundleFeatures"
            >
              <template v-if="card.code !== 'advanced-system'">
                <div class="d-flex">
                  <span class="mt-3 mr-2 ml-3">
                    <v-img
                      max-width="8"
                      min-width="8"
                      max-height="8"
                      min-height="8"
                      :src="renderIcon(card, subItem)"
                    ></v-img>
                  </span>
                  <span
                    class="text-101010 font-12"
                    v-if="subItem[`${Object.keys(subItem)[0]}`]"
                  >
                    {{ subItem[`${Object.keys(subItem)[0]}`].nameAr }}
                  </span>
                </div>
                <div>
                  <span class="text--101010 font-12">
                    {{ isFeatureIncluded(card, subItem) }}
                  </span>
                </div>
              </template>
              <template v-else>
                <div class="d-flex">
                  <span class="mt-3 mr-2 ml-3">
                    <v-img max-width="8" :src="rightCheckMark"></v-img>
                  </span>
                  <span> {{ subItem }} </span>
                </div>
              </template>
            </li>
          </ul>

          <!--  -->
          <v-card-actions>
            <div id="btnContainer" class="text-center w-100 px-6">
              <template v-if="!preview">
                <v-btn
                  :data-test="card.nameEn"
                  data-tests="setBundle"
                  v-if="card.code !== 'advanced-system'"
                  @click="setBundle(card)"
                  :class="[envSetting ? 'px-15' : 'px-8']"
                  height="36"
                  id="trialButton"
                >
                  <span v-if="!envSetting" class="mb-1">
                    <template v-if="card.code !== 'advanced-system'">
                      <span v-if="card.nameEn === selectedBundle">
                        <v-icon>mdi-check</v-icon>
                        تم اختيار الباقه
                      </span>
                      <span v-else> اختر الباقه </span>
                    </template>
                    <template v-else> تواصل معنا </template>
                  </span>
                  <span v-else class="mb-1">
                    {{ generateAvaliableAction(card) }}
                  </span>
                </v-btn>
                <v-btn
                  v-else
                  :class="[envSetting ? 'px-15' : 'px-8']"
                  height="36"
                  id="trialButton"
                  @click="dialogState = true"
                >
                  <span> تواصل معنا </span>
                </v-btn>
              </template>
              <template v-else>
                <router-link
                  :to="goTolink"
                  v-if="card.code !== 'advanced-system'"
                >
                  <v-btn
                    :class="[envSetting ? 'px-15' : 'px-8']"
                    color="text--white w-100"
                    height="40"
                    id="trialButton"
                  >
                    <span>
                      <span v-if="card.nameEn === selectedBundle">
                        <v-icon>mdi-check</v-icon>
                        تم اختيار الباقه
                      </span>
                      <span v-else> اشترك الآن </span>
                    </span>
                  </v-btn>
                </router-link>
                <v-btn
                  :class="[envSetting ? 'px-15' : 'px-8']"
                  color="text--white"
                  height="40"
                  id="trialButton"
                  v-else
                  @click="$emit('openSalesContactDialog')"
                >
                  <span> تواصل معنا </span>
                </v-btn>
              </template>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <contactSalesModel
      v-if="dialogState"
      v-bind="{ dialogState }"
      @changeDialogState="dialogState = $event"
      @closed-add-dialog="dialogState = false"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'subscription',
  components: {
    contactSalesModel: () => import('@/components/modals/contactSalesModel.vue')
  },
  props: {
    envSetting: {
      type: Boolean,
      default: false
    },
    bundles: {
      type: Array,
      default: () => []
    },
    ignoreBundle: {
      type: String,
      default: ''
    },
    bundlePlane: {
      type: Number,
      default: 1
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      test: false,
      dialogState: false,
      rightCheckMark: require('@/assets/img/svgs/rightCheckMark.svg'),
      wrongCheck: require('@/assets/img/svgs/wrongCheck.svg'),
      selectedBundle: ''
    }
  },
  computed: {
    ...mapState('accountState', {
      userBundle: (state) => state.subscription,
      login: (state) => state.status.loggedIn
    }),
    formattedNumbers() {
      return (str) => str?.toLocaleString('en-US', { maximumFractionDigits: 2 })
    },
    goTolink() {
      return this.login
        ? {
            path: 'dashboard/edit-profile/4'
          }
        : {
            path: '/auth/login',
            query: {
              login: 'register'
            }
          }
    },
    // Render the icon based on a feature included in bundle or not
    renderIcon() {
      return (obj, subItem) =>
        this.isFeatureIncluded(obj, subItem) === '-'
          ? this.wrongCheck
          : this.rightCheckMark
    },
    md() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    styleSpecialBundle() {
      if (this.envSetting && this.bundlePlane === this.userBundle?.period) {
        return this.userBundle?.bundle?.nameEn
      }
      return this.selectedBundle
    },
    setBundlePlane() {
      switch (this.bundlePlane) {
        case 1:
          return 'شهري'
        default:
          return 'سنوي'
      }
    }
  },
  methods: {
    // If the feature included in the bundle will return greater than zero
    isFeatureIncluded(obj, item) {
      switch (obj.features[`${Object.keys(item)[0]}`]) {
        case 0:
          return '-'
        case '0 ميجا':
          return '-'
        case 'soon':
          return 'قريبا'
        case 'unlimited':
          return 'غير محدود'
        case 'limited':
          return 'محدود'
        default:
          return Object.keys(item)[0] == 'sms' && this.bundlePlane == 12 // If sms and yearly bundle u have return 12x the value
            ? obj.features[`${Object.keys(item)[0]}`] * 12
            : obj.features[`${Object.keys(item)[0]}`]
      }
    },
    setBundle(bundle) {
      if (!this.envSetting) {
        this.selectedBundle = bundle.nameEn
      }
      if (this.userBundle && bundle?._id !== this.userBundle?.bundle?._id) {
        this.dialogState = true
        return
      }
      this.$emit('setBundle', {
        bundle,
        period: this.userBundle?.period || 12
      })
    },
    setBundlePlanPrice(bundle) {
      if (this.bundlePlane === 1) {
        return bundle.price
      }
      return null
    },
    generateAvaliableAction(bundle) {
      if (
        bundle.nameEn === this.userBundle?.bundle?.nameEn &&
        this.userBundle?.period === this.bundlePlane
      ) {
        return 'تجديد الاشتراك'
      }
      if (this.envSetting) {
        return 'تغيير الباقة'
      }
      return 'اشترك الان'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_env.scss';
@import '@/styles/material-dashboard/_bundles.scss';

@media screen and (max-width: 600px) {
  .card {
    max-width: 100%;
  }
}
.strikediag {
  display: inline-block;
  position: relative;
}

.strikediag::before {
  content: '';
  position: absolute;
  left: -0.1em;
  right: -0.1em;
  top: 0.38em;
  bottom: 0.38em;
  background: linear-gradient(
    to left top,
    transparent 45.5%,
    currentColor 47.5%,
    currentColor 52.5%,
    transparent 54.5%
  );
  pointer-events: none;
}
</style>
