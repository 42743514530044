<template>
  <div>
    <v-card class="px-5 py-6">
      <v-form ref="form" v-model="isValid">
        <v-row no-gutters>
          <v-col class="pl-3 mobile__pl__0" cols="12" md="6">
            <h6 class="mb-2 font-12 mobile__mt__0">الاسم*</h6>
            <v-text-field
              data-test="userName"
              filled
              v-model="userData.name"
              :rules="[(v) => !!v || 'من فضلك ادخل الاسم']"
              placeholder="الاسم"
              required
              dense
              class="setting__input__fields"
            ></v-text-field>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="12" md="6">
            <h6 class="mb-2 font-12 mobile__mt__0">رقم الهاتف*</h6>
            <v-text-field
              data-test="phoneNumber"
              v-model="userData.phoneNumber"
              filled
              required
              dense
              readonly
              disabled
              @click="phoneNumberClick"
              class="setting__input__fields"
            ></v-text-field>
          </v-col>
          <v-col class="pl-3" cols="12" sm="6">
            <h6 class="mb-2 mt-0">القسم (إختياري)</h6>
            <v-select
              ref="department"
              :menu-props="{ bottom: true, offsetY: true }"
              chips
              dense
              :items="departmnets"
              item-text="name"
              item-value="_id"
              filled
              hide-details
              placeholder="القسم"
              v-model="userData.environments[0].department"
            >
            </v-select>
          </v-col>
          <v-col class="pl-3" cols="12" sm="6">
            <h6 class="mb-2 mt-0">المسمى الوظيفي (إختياري)</h6>
            <v-select
              ref="role"
              :menu-props="{ bottom: true, offsetY: true }"
              color="black"
              dense
              filled
              :items="jobTitles"
              item-text="name"
              item-value="_id"
              hide-details
              :loading="isJobsLoading"
              placeholder="المسمي الوظيفي"
              v-model="userData.environments[0].jobId"
            >
            </v-select>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="12" md="6">
            <h6 class="mb-2 font-12 mobile__mt__0">الايميل</h6>
            <v-text-field
              data-test="email"
              filled
              v-model="userData.email"
              :rules="emailRules"
              placeholder="الايميل"
              dense
              class="setting__input__fields"
            ></v-text-field>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="12" md="6" v-if="!isCustomer">
            <h6 class="mb-2 font-12 mobile__mt__0">رقم ترخيص المعلن</h6>
            <v-text-field
              data-test="licenseNumber"
              ref="licenseNumber"
              v-model="userData.licenseNumber"
              placeholder="ادخل رقم ترخيص المعلن (إختياري)"
              filled
              dense
              hide-details
              type="number"
              class="noArrows"
            ></v-text-field>
          </v-col>
          <v-col
            class="pl-3 mobile__pl__0"
            cols="12"
            md="6"
            v-if="isOfficeOrCompany && !isCustomer"
          >
            <h6 class="mb-2 font-12 mobile__mt__0">رقم تسجيل المعلن</h6>
            <v-text-field
              data-test="registrationNumber"
              ref="registrationNumber"
              v-model="userData.registrationNumber"
              placeholder="ادخل رقم تسجيل المعلن (إختياري)"
              filled
              dense
              hide-details
              type="number"
              class="noArrows"
            ></v-text-field>
          </v-col>

          <!-- Theme -->
          <v-col id="env-pic" class="pl-3 mobile__pl__0" cols="12">
            <h6 class="font-12">تعيين لون أيقونه الحساب</h6>
            <v-flex d-flex>
              <div
                class="profile-color-sm ml-9"
                :style="`color:${userTheme}; border: 2px solid ${userTheme};`"
              >
                {{ $subString(user.name) }}
              </div>
              <div class="d-flex align-center mobile__flex__wrap">
                <div
                  v-for="color in colorAvatar"
                  :key="color"
                  data-test="changeColorTheme"
                  :style="`background-color:${color};`"
                  class="color-avatar ml-5 mobile__ml__10px mobile__mt__10px cursor"
                  @click="setColor(color)"
                ></div>
              </div>
            </v-flex>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-layout justify-end class="mt-5">
      <v-btn
        data-test="updateProfileSettings"
        @click="saveUpdatedData"
        color="primary"
        class="rounded-15 px-13 py-3"
      >
        <span class="font-16"> حفظ </span>
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { EnvService } from '../../services'
import { buildQueryString } from '@/utils/general'
import { cloneDeep } from 'lodash'

export default {
  name: 'profile-sittings',
  data() {
    return {
      isValid: false,
      departmnets: [],
      jobTitles: [],
      isJobsLoading: false,
      emailRules: [
        (v) => /.+@.+\..+/.test(v) || !v || 'من فضلك أدخل بريد الكتروني صحيح'
      ],
      userData: {},
      colorAvatar: [
        '#662D91',
        '#12CAD6',
        '#28DF99',
        '#4FB3F9',
        '#1C5FAE',
        '#FC4A4A',
        '#F49300',
        '#ED6237',
        '#E4897B',
        '#202020',
        '#737373',
        '#E2E2E2'
      ],
      userTheme: ''
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    isOfficeOrCompany() {
      return this.userType === 'company' || this.userType === 'office'
    },
    userType() {
      return this.user.environments[0]?.owner?.userType
    },
    isIconColorEdited() {
      return this.userTheme !== this.currentEnv.userTheme
    },
    userDepartment() {
      return this.userData.environments?.[0]?.department
    }
  },
  watch: {
    'userData.email'(val) {
      !val && !this.user.email && delete this.userData.email
    },
    userDepartment: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.getJobTitiles()
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapMutations('accountState', ['updateTheme']),
    ...mapActions('accountState', ['updateUserProfile']),
    async getAllDepartments() {
      this.isPageLoading = true
      try {
        const query = buildQueryString({
          environmentId: this.currentEnv?._id
        })
        const {
          data: { envDepartments }
        } = await EnvService.getDepartments(query)
        this.departmnets = envDepartments
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل الأقسام, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async getJobTitiles() {
      this.isJobsLoading = true
      try {
        const query = buildQueryString({
          departmentId: this.userData?.environments[0]?.department
        })
        const { data } = await EnvService.getJobTitles(query)
        this.jobTitles = data.envJobTitles
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل المسمي الوظيفي, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.isJobsLoading = false
      }
    },
    async saveUpdatedData() {
      if (this.isValid) {
        if (this.checkEditableFields() && !this.isIconColorEdited) {
          this.addNotification({
            text: 'لم يتم تغيير البيانات',
            color: 'error'
          })
        } else {
          await this.updateUserData()
        }
      } else {
        this.addNotification({
          text: 'من فضلك تأكد من استكمال جميع الحقول بشكل صحيح',
          color: 'error'
        })
      }
    },
    phoneNumberClick() {
      this.addNotification({
        text: 'نأسف لا يمكنك تغير رقم الهاتف في الوقت الحالي',
        color: 'error'
      })
    },
    setColor(color) {
      this.userTheme = color
    },
    checkEditableFields() {
      return JSON.stringify(this.userData) === JSON.stringify(this.user)
    },
    async updateUserData() {
      try {
        const user = { ...this.userData }
        if (this.isIconColorEdited) {
          const currentEnvIndex = user.environments.findIndex(
            (env) => env._id === this.currentEnv._id
          )
          if (currentEnvIndex !== -1) {
            user.environments[currentEnvIndex].userTheme = this.userTheme
          }
        }
        await this.updateUserProfile(user)
        if (this.isIconColorEdited) {
          this.updateTheme(this.userTheme)
        }
      } catch {}
    }
  },
  async created() {
    this.userData = cloneDeep(this.user)
    this.userTheme = this.currentEnv.userTheme
    await this.getAllDepartments()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_env.scss';
</style>
