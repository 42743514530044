import { render, staticRenderFns } from "./ForgetPasswordStep2.vue?vue&type=template&id=64a99cef"
import script from "./ForgetPasswordStep2.vue?vue&type=script&lang=js"
export * from "./ForgetPasswordStep2.vue?vue&type=script&lang=js"
import style0 from "./ForgetPasswordStep2.vue?vue&type=style&index=0&id=64a99cef&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports