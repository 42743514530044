<template>
  <div>
    <v-sheet
      data-test="comment-container"
      @mouseleave="setActiveComment(null)"
      color="#FAFAFA"
      @mouseover="setActiveComment(comment._id)"
      class="pointerCursor rounded-15 pa-3 mb-4 mobile__mb__0"
    >
      <v-layout align-center justify-space-between>
        <div class="d-flex ml-8">
          <h6
            :class="[
              currentUserId === comment.author._id ? 'brub--tag' : 'blue--tag'
            ]"
            class="bill-md ma-0 font-12 ml-2"
          >
            {{ $subString(comment.author.name) }}
          </h6>
          <h5 class="font-weight-medium ma-0">
            {{ comment.author.name }}
          </h5>
        </div>
        <v-spacer />
        <div
          v-if="comment.author._id === currentUserId"
          class="d-flex justify-space-between align-center ml-4"
        >
          <v-icon
            data-test="edit-comment"
            :color="pencilColor"
            @click="openTextArea"
            @mouseleave="setPencilColor('grey')"
            @mouseover="setPencilColor('green')"
            class="ma-0 mx-2"
            size="17"
            v-show="comment._id === commentId"
          >
            mdi-pencil-outline
          </v-icon>
          <v-icon
            data-test="delete-comment"
            :color="trashColor"
            @click="deleteComment"
            @mouseleave="setTrashColor('grey')"
            @mouseover="setTrashColor('red')"
            class="ma-0"
            size="17"
            v-show="comment._id === commentId"
          >
            mdi-trash-can-outline
          </v-icon>
        </div>
        <div class="d-inline-flex">
          <div class="font-12 comment-date" v-if="comment.createdAt">
            {{ formatDate(comment.createdAt) }}
          </div>
        </div>
      </v-layout>
      <div class="commentContent">
        <v-textarea
          data-test="comment-edit-input"
          :ref="`textArea${comment._id}`"
          @blur="updateComment"
          auto-grow
          class-content="font-12 mb-1"
          filled
          hide-details
          no-resize
          placeholder="يمكنك تعديل تعليقك"
          rows="1"
          v-if="activeTextArea === comment._id"
          v-model="commentUpdated"
        ></v-textarea>
        <h5 class="text-gray font-14 text-wrap max-w-400" v-else>
          {{ comment.content }}
        </h5>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import { mapState } from 'vuex'

export default {
  name: 'CommentCard',
  mixins: [dateFormat],
  props: {
    comment: {
      type: Object,
      required: true
    },
    isTerminated: {
      type: Boolean,
      default: false
    },
    commentId: {
      type: String
    },
    activeTextArea: {
      type: String
    }
  },
  data() {
    return {
      trashColor: 'grey',
      pencilColor: 'grey',
      commentUpdated: ''
    }
  },
  computed: {
    ...mapState('accountState', ['user']),
    currentUserId() {
      return this.user?._id
    }
  },
  methods: {
    setActiveComment(commentId) {
      this.$emit('setActiveComment', commentId)
    },

    async openTextArea() {
      await this.$emit('setActiveTextArea', this.comment._id)
      this.commentUpdated = this.comment.content
      this.$nextTick(() => {
        this.$refs[`textArea${this.comment._id}`].focus()
      })
    },
    setPencilColor(color) {
      this.pencilColor = color
    },
    setTrashColor(color) {
      this.trashColor = color
    },
    updateComment() {
      if (
        !this.commentUpdated ||
        this.commentUpdated === this.comment.content
      ) {
        this.$emit('setActiveTextArea', null)
        return
      }
      this.$emit('updateComment', {
        commentId: this.comment._id,
        content: this.commentUpdated
      })
    },
    deleteComment() {
      this.$emit('deleteComment', this.comment._id)
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-date {
  color: #737373;
}
</style>
