var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"480px","persistent":"","value":_vm.dialogState}},[_c('v-card',{staticClass:"add-new-property pa-0"},[_c('v-card-title',{staticClass:"py-0 px-3 mb-0 custom__shadow d-flex justify-space-between"},[_c('h4',{staticClass:"my-2",domProps:{"textContent":_vm._s(_vm.modelTitle)}}),_c('v-btn',{staticClass:"ma-0 mt-2",attrs:{"color":"grey","fab":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.closeDialog.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-container',{staticClass:"mb-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('h4',{staticClass:"font-14"},[_vm._v("تفعيل الموافقات")]),_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"dense":"","hide-details":"auto","inset":"","disabled":!_vm.hasUpdatePermission},model:{value:(_vm.localModule.active),callback:function ($$v) {_vm.$set(_vm.localModule, "active", $$v)},expression:"localModule.active"}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"font-14"},[_vm._v("العمليات")]),_c('v-col',{staticClass:"pt-0 mobile__p__0 employee--select--col pr-0",attrs:{"cols":"12","sm":"12"}},[_c('v-select',{ref:"employees",staticClass:"font-14",attrs:{"menu-props":{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                },"items":_vm.filteredActions,"placeholder":"نوع العملية","append-icon":"mdi-chevron-down","dense":"","item-text":"nameAr","item-value":"nameEn","rules":_vm.selectRules,"filled":"","clearable":"","multiple":"","hide-details":"","disabled":!_vm.hasUpdatePermission},model:{value:(_vm.localModule.actions),callback:function ($$v) {_vm.$set(_vm.localModule, "actions", $$v)},expression:"localModule.actions"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"font-14"},[_vm._v("عدد الموافقات")]),_c('v-col',{staticClass:"pt-0 mobile__p__0 employee--select--col pr-0",attrs:{"cols":"12","sm":"12"}},[_c('v-select',{ref:"employees",staticClass:"font-14",attrs:{"menu-props":{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                },"items":_vm.approvalLevels,"item-text":"title","item-value":"level","rules":_vm.levelRules,"placeholder":"أختر مستوى الموافقه","append-icon":"mdi-chevron-down","dense":"","filled":"","clearable":"","hide-details":"","disabled":!_vm.hasUpdatePermission},model:{value:(_vm.localModule.levels),callback:function ($$v) {_vm.$set(_vm.localModule, "levels", $$v)},expression:"localModule.levels"}})],1)],1),_vm._l((_vm.localModule.levels),function(i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('h4',{staticClass:"font-14"},[_vm._v("\n              المسؤول\n              "+_vm._s(i === 1
                  ? 'الأول'
                  : i === 2
                  ? 'الثاني'
                  : i === 3
                  ? 'الثالث'
                  : 'الرابع')+"\n            ")]),_c('v-col',{staticClass:"pt-0 mobile__p__0 employee--select--col pr-0",attrs:{"cols":"12","sm":"12"}},[_c('v-select',{ref:"employees",refInFor:true,staticClass:"font-14",attrs:{"menu-props":{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                },"items":_vm.jobTitles,"placeholder":"أختر المسمى الوظيفي","append-icon":"mdi-chevron-down","dense":"","rules":[_vm.usersRules],"filled":"","item-text":"name","item-value":"_id","clearable":"","hide-details":""},on:{"change":function($event){return _vm.setJobTitles(`title${i}`, i)}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}]})]},proxy:true}],null,true),model:{value:(_vm.localJobTitle[`title${i}`]),callback:function ($$v) {_vm.$set(_vm.localJobTitle, `title${i}`, $$v)},expression:"localJobTitle[`title${i}`]"}})],1),(!_vm.employeesLevel[`level${i}`]?.length)?_c('v-col',{staticClass:"pt-0 mobile__p__0 employee--select--col pr-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"py-3 px-2"},[_c('h4',{staticClass:"ma-0 font-14",staticStyle:{"color":"#662d91"}},[_vm._v("\n                  لا يوجد موظف مضاف لهذا المسمي الوظيفي\n                ")])])],1):_c('v-col',{staticClass:"pt-0 mobile__p__0 employee--select--col pr-0",attrs:{"cols":"12","sm":"12"}},[_c('v-select',{ref:"employees",refInFor:true,staticClass:"font-14",attrs:{"menu-props":{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                },"items":_vm.employeesLevel[`level${i}`],"placeholder":"أختر المسؤول عن الموافقة","append-icon":"mdi-chevron-down","dense":"","rules":[_vm.usersRules],"filled":"","item-text":"name","item-value":"_id","clearable":"","hide-details":"","disabled":!_vm.hasUpdatePermission},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.onIntersect),expression:"onIntersect"}]})]},proxy:true}],null,true),model:{value:(_vm.localUseres[`user${i}`]),callback:function ($$v) {_vm.$set(_vm.localUseres, `user${i}`, $$v)},expression:"localUseres[`user${i}`]"}})],1)],1)})],2)],1)],1),_c('div',{staticClass:"textTile mt-5 pt-4 pb-5 px-4"},[_c('div',{staticClass:"holder d-flex align-center"},[_c('div',{staticClass:"mr-3 ml-5"},[_c('v-img',{attrs:{"src":_vm.exclamationMarkrounded}})],1),_c('div',{staticClass:"text"},[_c('p',{staticClass:"font-12 font-weight-bold ma-0"},[_vm._v("\n            يجب أن يوافق المستوى الأول لينتقل الطلب للثانى ثم الثالث ثم الرابع\n          ")])]),_c('v-spacer')],1)]),_c('v-card-actions',{staticClass:"pa-1 mt-2"},[_c('v-btn',{staticClass:"ma-0 rounded-7 my-2 ml-3 mr-auto font-14",attrs:{"color":"primary","loading":_vm.isRequestLoading,"disabled":!_vm.isValid || !_vm.hasUpdatePermission},on:{"click":_vm.addApproval}},[_c('span',{staticClass:"ml-1 font-weight-bold"},[_vm._v(" حفظ التغيرات ")]),_c('v-icon',{staticClass:"send-icon font-16"},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }