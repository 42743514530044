<template>
  <v-dialog
    :value="acceptInvitationDialog"
    overlay-opacity="0.5"
    persistent
    max-width="330"
    max-height="278"
    :style="{ zIndex: 200, border: 'none' }"
  >
    <v-card>
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="my-0 pb-4 text-center">
        <v-img
          max-width="55"
          max-height="55"
          class="mx-auto"
          :src="modelIcon"
        />
        <div class="mt-6 font-16 font-weight-medium black--text">
          {{
            isinvitationLoading
              ? 'جاري التحقق من صلاحيه الدعوة'
              : 'تم دعوتك لبيئة عمل هكتار العقارية علي هكتار بلس'
          }}
        </div>
      </v-card-text>
      <v-card-actions class="actionsContainer d-block">
        <v-btn
          @click="acceptInvitation"
          color="primary"
          class="btnActions mt-0 w-100"
          min-width="135"
          min-height="40"
          :disabled="isinvitationLoading"
        >
          <span class="tf">قبول الدعوة</span>
        </v-btn>
        <v-btn
          class="btnActions mr-0 mt-0 w-100 black--text"
          @click="rejectInvitation"
          color="#EFEFEF"
          min-width="135"
          min-height="40"
          :disabled="isinvitationLoading"
        >
          <span class="tf">رفض الدعوه</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InvitationModel',
  props: {
    acceptInvitationDialog: {
      default: false
    },
    isinvitationLoading: {
      default: false
    }
  },
  data() {
    return {
      modelIcon: require('@/assets/img/svgs/invitation.svg')
    }
  },
  computed: {
    dialogState_: {
      get() {
        return this.acceptInvitationDialog
      },
      set(val) {
        this.$emit('setAcceptInvitationDialog', val)
      }
    }
  },
  methods: {
    acceptInvitation() {
      this.$emit('accepteInvitaion')
    },
    rejectInvitation() {
      this.$emit('rejectInvitaion')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';

.v-card .v-card__actions.actionsContainer {
  border: none;

  .btnActions {
    box-shadow: 0px 4px 20px rgba($primary-color, 0.1);
    border-radius: 13px;
  }
}
</style>
