<template>
  <div
    class="d-flex flex-column justify-center align-center text-gray px-4 expense__limit--descp"
  >
    <div class="d-flex justify-space-between w-100">
      <h5 class="font-weight-bold mb-2 font-14">
        لإضافة الحد الأقصي قم بالضغط علي تعديل
      </h5>
      <v-btn
        class="ma-0 mt-1"
        icon
        color="primary"
        dark
        @click="showExpensesLimitModal"
      >
        <v-icon class="font-20" color="grey" v-if="hasAddPermission"
          >mdi-pencil-outline</v-icon
        >
      </v-btn>
    </div>

    <h5
      :class="[
        'mt-4',
        'text-truncate',
        'font-12',
        'text-center',
        expenseLimitStatus.classes
      ]"
    >
      {{ expenseLimitStatus.text }}
    </h5>
  </div>
</template>

<script>
export default {
  name: 'expenseLimit',
  props: {
    expenseLimit: {
      required: true
    },
    hasAddPermission: {
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {
    expenseLimitStatus() {
      if (this.expenseLimit == null) {
        return {
          text: 'مفتوح',
          classes: 'font-weight-medium tag blue--tag'
        }
      } else if (this.expenseLimit === 0) {
        return {
          text: 'مغلق',
          classes: 'font-weight-medium tag red--tag'
        }
      }
      return {
        text: `حد المصروفات:${this.expenseLimit.toLocaleString('en-US')} ريال`,
        classes: 'brub--tag expenses-limit-text'
      }
    }
  },
  methods: {
    showExpensesLimitModal() {
      this.$emit('showExpensesLimitModal')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../src/styles/responsiveness/responsiveness.scss';
@import '@/styles/material-dashboard/_variables.scss';

.filter-underline {
  color: $primary-color;
}
.v-btn::before {
  background-color: transparent;
}
.h-100 {
  height: 100%;
}
.document__bond__contract__list__cards {
  @include max-xl {
    h5,
    .h5 {
      font-size: 0.6rem !important;
    }
    h6,
    .h6 {
      font-size: 0.55rem !important;
    }
  }
}
.font-20 {
  font-size: 20px !important;
}
.expense__limit--descp {
  .tag {
    min-width: 18% !important;
    padding: 3px 8px;
  }
}

.expenses-limit-text {
  font-weight: 900 !important;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
  padding: 5px 18px;
}
</style>
