<template>
  <div class="mt-5">
    <!-- <v-row class="mx-0" v-if="!currentEnv.defaulters">
      <v-col cols="12" class="property-col mb-2 shadow-border-radius px-0">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row> -->

    <DefaultersCard
      v-bind="{ template: defaultersConfig, isActiveOption: true, icon }"
      @openSettingsModal="setSettingsModalState(true)"
    />

    <DefaultersSettingsModal
      v-if="dialogState"
      v-bind="{ dialogState }"
      @closeSettingsModal="setSettingsModalState(false)"
    />
  </div>
</template>

<script>
import DefaultersCard from './modelUint.vue'
import { mapState } from 'vuex'

export default {
  name: 'DefaultersSettings',
  components: {
    DefaultersCard,
    DefaultersSettingsModal: () =>
      import('@/components/modals/DefaultersSettingsModal.vue')
  },
  data: () => ({
    icon: require('@/assets/img/svgs/canceled-request.svg'),
    dialogState: false
  }),
  computed: {
    ...mapState('accountState', ['currentEnv']),
    defaultersConfig() {
      return {
        title: 'إعدادات العملاء المتعثرين',
        description:
          'يمكنك التحكم في تفعيل عملاؤك المتعثرين عن طريق تحدد فترة السماح أو قيمة الحد الأقصى للمتأخرات',
        active: this.currentEnv.defaulters?.active
      }
    }
  },
  methods: {
    setSettingsModalState(val) {
      this.dialogState = val
    }
  }
}
</script>
