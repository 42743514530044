<template>
  <div id="step-1">
    <!-- phone-->
    <div class="step-title">
      <h6 class="submit-section-header black--text font-weight-bold">
        رقم الجوال
      </h6>
    </div>
    <div class="form-row form-row-wide m-0">
      <vue-tel-input-vuetify
        class="phonenumber-field"
        v-model="phoneNumber"
        label="ادخل رقم الجوال"
        :only-countries="['SA']"
        default-country="SA"
        ref="regPhoneNumber"
        placeholder=""
        :filled="true"
        required
        suffix="+966"
        :rules="[(v) => (v && !!isValidPhone) || 'من فضلك تأكد من رقم الهاتف']"
        :valid-characters-only="true"
        :validate-on-blur="true"
        @validate="onValidatePhoneNumber"
        @input="onInputPhoneNumber($event, 'phoneNumber')"
      >
        <template slot="prepend-inner">
          <v-icon>mdi-cellphone</v-icon>
        </template>
      </vue-tel-input-vuetify>
    </div>
    <!-- password-->
    <div class="step-title">
      <h6 class="submit-section-header black--text font-weight-bold">
        كلمة المرور
      </h6>
    </div>
    <div class="password-field text-field mb-5">
      <v-text-field
        data-test="password"
        ref="password"
        v-model="password"
        :rules="passwordRules"
        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
        :type="showPassword ? 'text' : 'password'"
        label="كلمة المرور"
        required
        filled
        hint="يجب الا تقل عن 6 أحرف"
        persistent-hint
        hide-details
        @keydown.enter="validate"
        @click:append="showPassword = !showPassword"
        @input="onInputPhoneNumber($event, 'password')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  name: 'RegisterStep1',
  props: ['phone'],
  data() {
    return {
      phoneNumber: null,
      phoneData: {},
      submitted: false,
      password: '',
      passwordFieldType: 'password',
      enteredOtp: null,
      otb: false,
      showPassword: false,
      passwordRules: [
        (v) => {
          return !!v || 'كلمة المرور مطلوبة'
        },
        (v) => {
          return v.length >= 6 || 'كلمة المرور يجب أن لا تقل عن 6 أحرف'
        }
      ]
    }
  },
  mounted() {
    if (this.validePhonenumber) {
      this.phoneNumber = this.validePhonenumber
      this.$emit('on-validate', this.phoneNumber)
    }
    this.phoneNumber = this.phone
  },
  validations: {
    phoneNumber: {
      required,
      minLength: minLength(10)
    },
    password: {
      required,
      minLength: minLength(6)
    },
    form: ['password', 'phoneNumber']
  },
  computed: {
    ...mapState('userState', ['validePhonenumber']),
    isValidPhone() {
      return this.phoneData.isValid
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapMutations('userState', ['setSpinnerLoading']),
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    validate() {
      this.submitted = true
      this.setSpinnerLoading(true)
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid

      if (isValid && this.isValidPhone) {
        this.phoneNumber = this.phoneData.number.e164.replace(/\+/g, '')
        this.$emit(
          'on-validate',
          { phoneNumber: this.phoneNumber, password: this.password },
          isValid
        )
      } else {
        let message = 'كلمة المرور مطلوبة'
        if (!this.isValidPhone) {
          message = 'من فضلك ادخل  رقم الهاتف'
        } else if (this.password) {
          message = 'لا يجب أن يقل عدد أحرف/أرقام كلمة المرور عن 6'
        }
        this.addNotification({
          text: message,
          color: 'error'
        })
        this.setSpinnerLoading(false)
      }
      return isValid
    },
    onInputPhoneNumber(str, key) {
      this[key] = convertNumbers2English(str)
    }
  }
}
</script>
