<template>
  <v-dialog max-width="720px" persistent :value="dialogState">
    <v-card class="add-new-property mod-modal">
      <v-card-title
        class="px-4 py-2 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0">تعديل قيمة المبالغ الثابتة</h4>
        <v-btn @click.prevent="closeDialog" class="ma-0" color="grey" fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <V-form class="px-4 py-2" v-model="isValid">
        <v-row class="mt-2">
          <v-divider class="mb-1" />
        </v-row>

        <!-- Initial -->
        <v-row class="mt-2">
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">قيمة الكهرباء</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(data?.electricity) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">قيمة المياة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(data?.water) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">قيمه الغاز</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(data?.gas) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">قيمة الخدمات العامة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(data?.generalServices) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">مجموع المبالغ الثابتة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(oldTotalFixedAmount) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">نسبة الضريبة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(data?.utilitiesTax) }}</span>
              <span>%</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">قيمه الضريبة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(oldTaxAmount) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">اجمالي المستحق مع الضريبة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(oldTotalDueWithTaxAmount) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
        </v-row>
        <v-divider class="mt-5" />

        <!-- Add and discount -->
        <div class="py-2 px-4 bg-brub rounded-xl d-flex mt-4">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="-0.000244141"
              width="20"
              height="20"
              rx="10"
              fill="#662D91"
              fill-opacity="0.1"
            />
            <g clip-path="url(#clip0_13864_307)">
              <path
                d="M12.4228 14.5743L12.2598 15.2407C11.7708 15.4337 11.3801 15.5806 11.0892 15.6816C10.798 15.7829 10.4597 15.8333 10.0741 15.8333C9.48212 15.8333 9.02163 15.6883 8.6932 15.4002C8.36478 15.111 8.20051 14.7445 8.20051 14.2999C8.20051 14.1278 8.21243 13.9508 8.23721 13.7704C8.2622 13.5899 8.30194 13.3865 8.35631 13.1592L8.96747 10.996C9.02184 10.7889 9.06806 10.5926 9.10507 10.407C9.14261 10.2226 9.1607 10.0529 9.1607 9.90009C9.1607 9.62384 9.1035 9.43061 8.98964 9.32197C8.87577 9.21364 8.65849 9.15864 8.33592 9.15864C8.17793 9.15864 8.01554 9.18395 7.85002 9.23288C7.68377 9.28203 7.54167 9.3295 7.42236 9.37362L7.58579 8.70673C7.98616 8.54372 8.36885 8.40413 8.73492 8.28827C9.10099 8.1721 9.44688 8.11397 9.77406 8.11397C10.362 8.11397 10.8157 8.25607 11.1343 8.54027C11.4529 8.82467 11.6121 9.19336 11.6121 9.64768C11.6121 9.74168 11.6017 9.9073 11.5792 10.144C11.5573 10.3813 11.5164 10.5987 11.4568 10.7964L10.8482 12.9509C10.7983 13.1239 10.7535 13.3218 10.7145 13.5444C10.6742 13.7655 10.655 13.9345 10.655 14.0478C10.655 14.3339 10.7188 14.5292 10.8467 14.6331C10.9755 14.7369 11.1973 14.7886 11.5125 14.7886C11.6605 14.7886 11.8288 14.7623 12.0159 14.7108C12.2024 14.6592 12.3384 14.6139 12.4228 14.5743ZM12.5771 5.5291C12.5771 5.90448 12.4357 6.22506 12.1514 6.48866C11.8678 6.7532 11.5261 6.88558 11.1264 6.88558C10.7254 6.88558 10.3828 6.7532 10.096 6.48866C9.80971 6.22496 9.66625 5.90448 9.66625 5.5291C9.66625 5.15446 9.80971 4.83335 10.096 4.5664C10.3823 4.29988 10.7255 4.16666 11.1264 4.16666C11.526 4.16666 11.8678 4.30019 12.1514 4.5664C12.4359 4.83335 12.5771 5.15456 12.5771 5.5291Z"
                fill="#662D91"
              />
            </g>
            <defs>
              <clipPath id="clip0_13864_307">
                <rect
                  width="11.6667"
                  height="11.6667"
                  fill="white"
                  transform="translate(4.16638 4.16666)"
                />
              </clipPath>
            </defs>
          </svg>
          <h6 class="text-primary font-12 ma-0 mr-2">
            ستضاف العمليه في سجل النشاطات للتوثيق و المتابعه
          </h6>
        </div>

        <!-- Edit -->
        <v-row class="mt-1">
          <v-col class="pl-3 mobile__pl__0" cols="6">
            <dropdown-alt
              data-test="client-type"
              ref="owner-type"
              :configrations="{ name: 'نوع التعديل' }"
              :listingType="clientType"
              :defaultValue="changes.electricity"
              @filterTypes="getDeductType($event.val, 'electricity')"
              class="client--type--dropdown"
            />
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-0 mt-0">نوع الخدمة</h6>
            <v-text-field
              style="pointer-events: none"
              class="mt-4 font-14"
              dense
              filled
              hide-details
              value="كهرباء"
            ></v-text-field>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">أدخل القيمة</h6>
            <v-text-field
              class="mt-4 font-14"
              dense
              filled
              hide-details
              placeholder="0"
              suffix="ريال"
              :value="electricityValueMask"
              @input="changeValueHandler($event, electricityValueKeys)"
              @keydown="$formatNumberInputs($event, true)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <v-col class="pl-3 mobile__pl__0" cols="6">
            <dropdown-alt
              data-test="client-type"
              ref="owner-type"
              :configrations="{ name: 'نوع التعديل' }"
              :listingType="clientType"
              :defaultValue="changes.water"
              @filterTypes="getDeductType($event.val, 'water')"
              class="client--type--dropdown"
            />
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-0 mt-0">نوع الخدمة</h6>
            <v-text-field
              style="pointer-events: none"
              class="mt-4 font-14"
              dense
              filled
              hide-details
              value="المياة"
            ></v-text-field>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">أدخل القيمة</h6>
            <v-text-field
              class="mt-4 font-14"
              dense
              filled
              hide-details
              placeholder="0"
              suffix="ريال"
              :value="waterValueMask"
              @input="changeValueHandler($event, waterValueKeys)"
              @keydown="$formatNumberInputs($event, true)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <v-col class="pl-3 mobile__pl__0" cols="6">
            <dropdown-alt
              data-test="client-type"
              ref="owner-type"
              :configrations="{ name: 'نوع التعديل' }"
              :listingType="clientType"
              :defaultValue="changes.gas"
              @filterTypes="getDeductType($event.val, 'gas')"
              class="client--type--dropdown"
            />
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-0 mt-0">نوع الخدمة</h6>
            <v-text-field
              style="pointer-events: none"
              class="mt-4 font-14"
              dense
              filled
              hide-details
              value="غاز"
            ></v-text-field>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">أدخل القيمة</h6>
            <v-text-field
              class="mt-4 font-14"
              dense
              filled
              hide-details
              placeholder="0"
              suffix="ريال"
              :value="gasValueMask"
              @input="changeValueHandler($event, gasValueKeys)"
              @keydown="$formatNumberInputs($event, true)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <v-col class="pl-3 mobile__pl__0" cols="6">
            <dropdown-alt
              data-test="client-type"
              ref="owner-type"
              :configrations="{ name: 'نوع التعديل' }"
              :listingType="clientType"
              :defaultValue="changes.generalServices"
              @filterTypes="getDeductType($event.val, 'generalServices')"
              class="client--type--dropdown"
            />
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-0 mt-0">نوع الخدمة</h6>
            <v-text-field
              style="pointer-events: none"
              class="mt-4 font-14"
              dense
              filled
              hide-details
              value="خدمات عامة"
            ></v-text-field>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">أدخل القيمة</h6>
            <v-text-field
              class="mt-4 font-14"
              dense
              filled
              hide-details
              placeholder="0"
              suffix="ريال"
              :value="generalServicesValueMask"
              @input="changeValueHandler($event, generalServicesValueKeys)"
              @keydown="$formatNumberInputs($event, true)"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Totals -->
        <v-row class="mt-2">
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">قيمة الكهرباء الجديدة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(newElectricityAmount) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">قيمة المياة الجديدة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(newWaterAmount) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">قيمه الغاز الجديدة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(newGasAmount) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 style="text-wrap: nowrap" class="mb-2 mt-0">
              قيمة الخدمات العامة الجديدة
            </h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(newGeneralServicesAmount) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 style="text-wrap: nowrap" class="mb-2 mt-0">
              مجموع المبالغ الثابتة الجديدة
            </h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(newTotalFixedAmount) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">نسبة الضريبة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(data?.utilitiesTax) }}</span>
              <span>%</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">قيمه الضريبة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(newTaxAmount) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="3">
            <h6 class="mb-2 mt-0">اجمالي المستحق مع الضريبة</h6>
            <div
              class="d-flex justify-between rounded-lg py-1 px-5 bg-primary font-12 font-weight-medium bg-brub-100"
            >
              <span>{{ formattingNumbers(newTotalDueWithTaxAmount) }}</span>
              <span>ريال</span>
            </div>
          </v-col>
        </v-row>
      </V-form>
      <v-card-actions class="pa-1 mt-4">
        <v-spacer></v-spacer>
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 rounded-7 mx-2"
          color="gery"
        >
          <span>إلغاء</span>
        </v-btn>

        <v-btn
          color="primary"
          class="ma-0 rounded-7 my-3 ml-3"
          :disabled="!validation"
          @click="sendNewData"
        >
          <span class="font-weight-bold"> تأكيد</span>
          <v-icon size="18" class="mt-1">mdi-arrow-left</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.v-application .error--text {
  color: #662d91 !important;
  caret-color: #662d91 !important;
}
</style>
<script>
import DropdownAlt from '@/components/add-unit/dropdownAlt'
import dateFormat from '@/mixins/dateFormat.js'
import inputMasking from '@/mixins/inputMasking.js'
import { convertNumbers2English } from '@/utils/formatters'
import { mapMutations, mapState } from 'vuex'

export default {
  props: ['dialogState', 'data'],
  mixins: [dateFormat, inputMasking],
  components: {
    DropdownAlt
  },
  data() {
    return {
      isValid: false,
      due: {
        bondValue: 0
      },
      newData: {
        electricity: 0,
        water: 0,
        gas: 0,
        generalServices: 0
      },
      changes: {
        electricity: 'add',
        water: 'add',
        gas: 'add',
        generalServices: 'add'
      },
      clientType: [
        {
          _id: 1,
          text: 'خصم',
          val: 'deduct'
        },
        {
          _id: 2,
          text: 'إضافة',
          val: 'add'
        }
      ],
      deductAmount: 'add',

      // Masks
      electricityValueMask: '',
      waterValueMask: '',
      gasValueMask: '',
      generalServicesValueMask: ''
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    electricityValueKeys() {
      return {
        maskValKey: 'electricityValueMask',
        parentKey: 'newData',
        actualValKey: 'electricity'
      }
    },
    waterValueKeys() {
      return {
        maskValKey: 'waterValueMask',
        parentKey: 'newData',
        actualValKey: 'water'
      }
    },
    gasValueKeys() {
      return {
        maskValKey: 'gasValueMask',
        parentKey: 'newData',
        actualValKey: 'gas'
      }
    },
    generalServicesValueKeys() {
      return {
        maskValKey: 'generalServicesValueMask',
        parentKey: 'newData',
        actualValKey: 'generalServices'
      }
    },
    newElectricityAmount() {
      return this.changes.electricity === 'add'
        ? this.data.electricity + this.newData.electricity
        : this.data.electricity - this.newData.electricity
    },
    newWaterAmount() {
      return this.changes.water === 'add'
        ? this.data.water + this.newData.water
        : this.data.water - this.newData.water
    },
    newGasAmount() {
      return this.changes.gas === 'add'
        ? this.data.gas + this.newData.gas
        : this.data.gas - this.newData.gas
    },
    newGeneralServicesAmount() {
      return this.changes.generalServices === 'add'
        ? this.data.generalServices + this.newData.generalServices
        : this.data.generalServices - this.newData.generalServices
    },
    oldTotalDueWithTaxAmount() {
      return this.oldTotalFixedAmount + this.oldTaxAmount
    },
    oldTaxAmount() {
      return (this.oldTotalFixedAmount * this.data?.utilitiesTax) / 100
    },
    oldTotalFixedAmount() {
      return (
        this.data.electricity +
        this.data.water +
        this.data.gas +
        this.data.generalServices
      )
    },
    newTotalFixedAmount() {
      return (
        this.newElectricityAmount +
        this.newWaterAmount +
        this.newGasAmount +
        this.newGeneralServicesAmount
      )
    },
    newTaxAmount() {
      return (this.newTotalFixedAmount * this.data?.utilitiesTax) / 100
    },
    newTotalDueWithTaxAmount() {
      return this.newTotalFixedAmount + this.newTaxAmount
    },
    validation() {
      const data = this.newData
      return data.electricity || data.water || data.gas || data.generalServices
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    changeValueHandler(value, valueKeys) {
      const val = isNaN(value)
        ? Number(convertNumbers2English(value.replaceAll(',', '')))
        : convertNumbers2English(value)

      if (
        this.data[valueKeys.actualValKey] < value &&
        this.changes[valueKeys.actualValKey] === 'deduct'
      ) {
        this.handleValuesChanging(0, valueKeys)
        return
      }

      this.handleValuesChanging(val, valueKeys)
    },
    resetData() {
      this.newData = {
        electricity: 0,
        water: 0,
        gas: 0,
        generalServices: 0
      }
      this.electricityValueMask = ''
      this.waterValueMask = ''
      this.gasValueMask = ''
      this.generalServicesValueMask = ''
      this.changes = {
        electricity: 'add',
        water: 'add',
        gas: 'add',
        generalServices: 'add'
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
      this.resetData()
    },
    formattingNumbers(num) {
      return parseFloat(num).toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    },
    getDeductType(val, type) {
      if (val === 'deduct' && this.data[type] === 0) {
        return
      }
      this.changes[type] = val
      this.handleValuesChanging(0, this[`${type}ValueKeys`])
    },
    sendNewData() {
      this.$emit('update-fixed-amount', {
        electricity: this.newElectricityAmount,
        water: this.newWaterAmount,
        gas: this.newGasAmount,
        generalServices: this.newGeneralServicesAmount,
        fixedPayment: this.newTotalFixedAmount
      })
      this.closeDialog()
    }
  }
}
</script>
