<template>
  <div>
    <v-navigation-drawer
      v-model="drawerValue"
      :mini-variant-width="100"
      :mini-variant="miniDrawerValue"
      :class="{ 'is-responsive': responsive }"
      id="app-drawer"
      app
      fixed
      mobile-breakpoint="900"
      width="260"
      right
    >
      <div class="min-logo-white sidebar__mini__Logo logo-width mb-1">
        <router-link :to="logoRoute">
          <v-img
            :lazy-src="hectareLogo"
            max-height="34"
            max-width="40"
            :src="hectareLogo"
          ></v-img>
        </router-link>
      </div>
      <!-- mini logo  -->
      <v-layout class="row fill-height big-drawer" no-gutters>
        <v-layout
          class="fill-height main-nav"
          :class="{ 'mini-nav': miniDrawerValue }"
          tag="v-list"
          column
        >
          <!-- Toogle To Mini -->
          <v-btn
            v-if="!responsive && showMuneBtn && id"
            fab
            x-small
            class="grey--text z-index-3"
            color="white"
            id="mini-toggle-btn"
            @click.stop="setMiniDrawerValue"
            v-click-outside="clickOutside"
            elevation="0"
          >
            <v-icon
              >mdi-chevron-{{ miniDrawerValue ? 'left' : 'right' }}</v-icon
            >
          </v-btn>
          <!--   responsive-img-switcher-->

          <!--          LISTING-->
          <div>
            <!--            miniDrawerValue-->
            <!--without the sub-links-->
            <div class="right__sidemenu">
              <v-row>
                <v-col :cols="mainMenuCols" class="pa-0">
                  <v-list
                    v-for="item in filteredMainMenuItems"
                    :key="item.text"
                  >
                    <v-list-group
                      v-model="item.active"
                      v-if="isCheckPermissionOn(item)"
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="pb-1">
                          <v-list-item-title
                            v-if="item.subLinks.length"
                            class="newSubLink pa-0"
                          >
                            <v-img
                              v-if="activeItemId == item.id"
                              class="ma-auto active__img"
                              :max-width="16"
                              :max-height="16"
                              :src="item.iconHover"
                            />
                            <v-img
                              v-else
                              class="ma-auto img-onhover"
                              :max-width="16"
                              :max-height="16"
                              :src="item.icon"
                            />

                            <div class="v-list-item__title text-center">
                              {{ item.text }}
                            </div>
                          </v-list-item-title>
                          <v-list-item-title
                            @click="activeItemId = item.id"
                            class="newSubLink pa-0"
                            v-else
                          >
                            <router-link :to="item.to" class="newSubLink">
                              <v-img
                                v-if="activeItemId == item.id"
                                class="ma-auto active__img2"
                                :max-width="16"
                                :max-height="16"
                                :src="item.iconHover"
                              />
                              <v-img
                                v-else
                                class="ma-auto img-onhover"
                                :max-width="16"
                                :max-height="16"
                                :src="item.icon"
                              />
                              <div class="v-list-item__title text-center">
                                {{
                                  item.to.includes('requests') && isCustomer
                                    ? 'الطلبات'
                                    : item.text
                                }}
                              </div>
                            </router-link>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        class="subMenu-list-item"
                        v-for="child in item.subLinks"
                        :key="child.title"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="newSubLink pa-0 subMenuItem"
                            @click="activeItemId = item.id"
                            v-if="isCheckPermissionOn(child)"
                          >
                            <router-link :to="child.to" class="text-center">
                              <!-- <v-img
                              class="ma-auto"
                              :max-width="16"
                              :max-height="16"
                              :src="child.icon"
                            /> -->
                              <div class="v-list-item__title text-center">
                                {{
                                  child.to.includes('dues') && isCustomer
                                    ? 'العمليات المالية'
                                    : child.text
                                }}
                              </div>
                            </router-link>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </v-list>
                </v-col>
                <v-col v-if="subLinksState" cols="8" class="pa-0 sublink">
                  <div v-for="(sublink, key) in link.subLinks" :key="key">
                    <div v-if="link.subLinks && id === link.id">
                      <div class="menuSideTitle font-weight-medium font-14">
                        {{ sublink.title }}
                      </div>
                      <v-list>
                        <v-list-item
                          v-for="child in sublink.items"
                          :key="child.text"
                          class="subLinksVlistItem"
                          :to="child.to"
                        >
                          <v-list-item-action
                            @mouseover="sublinkHovering($event)"
                            @mouseout="sublinkHovering($event)"
                            class="nweSubLink"
                          >
                            <router-link
                              class="sublinkElement font-12"
                              :to="child.to"
                              >{{ child.text }}
                            </router-link>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-if="!miniDrawerValue" class="border-left"></div>
        </v-layout>
      </v-layout>
      <template #append>
        <!-- <v-divider></v-divider> -->
        <div v-for="(link, i) in DOWN" :key="i">
          <!--miniDrawerValue-->
          <v-list-item
            @click="showPop(link.id)"
            exact
            active-class=""
            class="v-list-item drawer-v-list"
          >
            <router-link v-if="link.to" :to="{ path: link.to }" target="_blank">
              <v-list-item-action class="newSubLink">
                <v-img
                  :lazy-src="link.icon"
                  max-height="16"
                  max-width="16"
                  :src="link.icon"
                ></v-img>
                <v-list-item-title
                  class="black--text"
                  v-text="link.text"
                ></v-list-item-title>
              </v-list-item-action>
            </router-link>
            <v-list-item-action v-else class="newSubLink">
              <v-img
                :lazy-src="link.icon"
                max-height="16"
                max-width="16"
                :src="link.icon"
              ></v-img>
              <v-list-item-title
                class="black--text"
                v-text="link.text"
              ></v-list-item-title>
            </v-list-item-action>
          </v-list-item>
          <!--without the sub-links-->
          <div>
            <v-row class="appended-sub-links">
              <v-col
                cols="12"
                class="pa-0"
                v-if="link.subLinks && id === link.id"
              >
                <v-icon @click="closePopupMenu">mdi-close</v-icon>
                <v-list class="pt-3 appended__submenu">
                  <v-list-item
                    v-for="(sublink, i) in link.subLinks"
                    :key="i"
                    class="v-list-item mb-0 d-flex justify-space-between"
                  >
                    <a
                      v-if="sublink.use_A_Tag"
                      :href="sublink.to"
                      :target="sublink.target"
                    >
                      <div>
                        <v-list-item-title
                          class="text-bold black--text"
                          @click="closePopupMenu"
                        >
                          {{ sublink.text }}
                        </v-list-item-title>
                      </div>
                    </a>
                    <router-link
                      v-else
                      :to="goTo(sublink, i)"
                      :target="sublink.target"
                    >
                      <div>
                        <v-list-item-title
                          v-if="!sublink.divider && !sublink.action"
                          class="text-bold black--text"
                          v-text="sublink.text"
                          @click="closePopupMenu"
                        >
                          <v-switch
                            v-if="sublink.switcher"
                            v-model="sublink.switcher"
                          ></v-switch>
                        </v-list-item-title>
                        <v-list-item-title
                          v-if="!sublink.divider && sublink.action"
                          class="text-bold black--text"
                          v-text="sublink.text"
                          @click="setlogout"
                        >
                          <v-switch
                            v-if="sublink.switcher"
                            v-model="sublink.switcher"
                          ></v-switch>
                        </v-list-item-title>
                        <!-- <v-divider v-else></v-divider> -->
                      </div>
                    </router-link>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
// Utilities
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Drawer',
  data() {
    return {
      activeItemId: -1,
      overlay: true,
      hectareLogo: require('@/assets/img/svgs/Union.svg'),
      subLinksState: false,
      mainMenuCols: 12,
      permissionChanged: 0,
      mini: true,
      leftDrawerr: false,
      logo: require('@/assets/img/logo.svg'),
      minLogo: require('@/assets/img/min-logo.png'),
      minWhiteLogo: require('@/assets/img/min-logo-white.png'),
      mainMenuItems: [
        {
          id: 1,
          to: '/dashboard',
          icon: require('@/assets/img/svgs/home.svg'),
          iconHover: require('@/assets/img/svgs/home-active.png'),
          text: 'الرئيسيه',
          miniTitle: 'روابط',
          active: false,
          subLinks: [],
          avaliableEnv: ['production', 'development', 'staging']
        },
        {
          id: 12,
          to: '/dashboard/tasks',
          icon: require('@/assets/img/dashboard-icons/bond.png'),
          iconHover: require('@/assets/img/dashboard-icons/bond-active.png'),
          text: 'المهام',
          active: false,
          subLinks: [],
          avaliableEnv: ['production', 'development', 'staging'],
          acl: {
            domain: 'users',
            route: 'tasks',
            permissions: 'list',
            module: 'tasks'
          },
          avaliableEnv: ['production', 'development', 'staging']
        },
        {
          id: 13,
          to: '/dashboard/requests',
          icon: require('@/assets/img/dashboard-icons/contract-icon.png'),
          iconHover: require('@/assets/img/dashboard-icons/contract-icon-active.png'),
          text: 'طلبات العملاء',
          active: true,
          subLinks: [],
          avaliableEnv: ['production', 'development', 'staging'],
          acl: {
            domain: 'properties',
            route: 'requests',
            permissions: 'list',
            module: 'tasks'
          },
          avaliableEnv: ['production', 'development', 'staging']
        },
        {
          id: 15,
          to: '/dashboard/approval',
          icon: require('@/assets/img/approval/approval.png'),
          iconHover: require('@/assets/img/approval/active-approval.png'),
          text: 'طلبات الموافقات',
          subLinks: [],
          avaliableEnv: ['production', 'development', 'staging'],
          acl: {
            domain: 'users',
            route: 'approvalsSettings',
            permissions: 'list',
            module: 'pm'
          }
        },
        {
          id: 3,
          to: '/dashboard/clients',
          icon: require('@/assets/img/svgs/client.svg'),
          iconHover: require('@/assets/img/svgs/client-active.png'),
          text: 'العملاء',
          miniTitle: 'العملاء',
          active: false,
          avaliableEnv: ['production', 'development', 'staging'],
          acl: {
            domain: 'users',
            route: 'clients',
            permissions: 'list'
          },
          subLinks: [],
          avaliableEnv: ['production', 'development', 'staging']
        },
        {
          id: 20,
          to: '/dashboard/potential-clients',
          icon: require('@/assets/img/potential-clients.png'),
          iconHover: require('@/assets/img/potential-clients-active.png'),
          text: 'العملاء المحتملين',
          active: false,
          avaliableEnv: ['production', 'development', 'staging'],
          acl: {
            domain: 'users',
            route: 'potential-clients',
            permissions: 'list',
            module: 'potential-clients'
          },
          subLinks: []
        },
        {
          id: 24,
          to: '/dashboard/price-quotations',
          icon: require('@/assets/img/priceQuotation/priceQuotationActive.svg'),
          iconHover: require('@/assets/img/priceQuotation/priceQuotation.svg'),
          text: 'عروض الإسعار',
          active: true,
          avaliableEnv: ['development', 'staging'],
          acl: {
            domain: 'realestates',
            route: 'quotations',
            permissions: 'list',
            module: 'quotations'
          },
          subLinks: []
        },
        {
          id: 4,
          to: '/dashboard/properties/0',
          icon: require('@/assets/img/svgs/building.svg'),
          iconHover: require('@/assets/img/svgs/building-active.png'),
          text: 'العقارات والوحدات',
          miniTitle: 'العقارات',
          active: true,
          avaliableEnv: ['production', 'development', 'staging'],
          acl: {
            domain: 'realestates',
            route: 'realestates',
            permissions: 'list',
            module: 'realestates'
          },
          subLinks: [],
          avaliableEnv: ['production', 'development', 'staging']
        },
        {
          id: 11,
          to: '/dashboard/contracts',
          icon: require('@/assets/img/dashboard-icons/contract-icon.png'),
          iconHover: require('@/assets/img/dashboard-icons/contract-icon-active.png'),
          text: 'العقود',
          miniTitle: 'العقود',
          active: true,
          avaliableEnv: ['production', 'development', 'staging'],
          subLinks: [],
          acl: {
            domain: 'properties',
            route: 'contracts',
            permissions: 'list',
            module: 'pm'
          },
          avaliableEnv: ['production', 'development', 'staging']
        },

        {
          id: 12,
          to: '/dashboard/contracts',
          icon: require('@/assets/img/tax-invoices/taxInvoiceIcon.png'),
          iconHover: require('@/assets/img/tax-invoices/taxInvoiceIcon-active.png'),
          text: ' المالية ',
          miniTitle: ' المالية ',
          active: false,
          avaliableEnv: ['production', 'development', 'staging'],
          acl: {
            sublinks: true,
            domain: 'properties',
            route: ['contracts', 'bonds'],
            permissions: 'list',
            module: 'pm'
          },
          subLinks: [
            {
              to: '/dashboard/bonds',
              icon: require('@/assets/img/dashboard-icons/bond.png'),
              iconHover: require('@/assets/img/dashboard-icons/bond-active.png'),
              text: 'السندات',
              acl: {
                domain: 'properties',
                route: 'bonds',
                permissions: 'list',
                module: 'pm'
              }
            },
            {
              to: '/dashboard/dues',
              icon: require('@/assets/img/dashboard-icons/contract-icon.png'),
              iconHover: require('@/assets/img/dashboard-icons/contract-icon-active.png'),
              text: 'المطالبات',
              acl: {
                domain: 'properties',
                route: 'assignments',
                permissions: 'list',
                module: 'pm'
              },
              miniTitle: 'المطالبات'
            },
            {
              to: '/dashboard/taxInvoices',
              icon: require('@/assets/img/dashboard-icons/contract-icon.png'),
              iconHover: require('@/assets/img/dashboard-icons/contract-icon-active.png'),
              text: 'الفواتير الضريبيه ',
              acl: {
                domain: 'properties',
                route: 'taxInvoices',
                permissions: 'list',
                module: 'taxBills'
              },
              miniTitle: 'الفواتير الضريبيه'
            }
          ],
          avaliableEnv: ['production', 'development', 'staging']
        },
        {
          id: 2,
          to: '/dashboard/reports',
          icon: require('@/assets/img/reports/3.svg'),
          iconHover: require('@/assets/img/reports/3-active.png'),
          text: 'التقارير',
          miniTitle: 'التقارير',
          active: false,
          avaliableEnv: ['production', 'development', 'staging'],
          acl: {
            domain: 'users',
            route: 'reports',
            permissions: 'list',
            module: 'reports'
          },
          subLinks: [],
          avaliableEnv: ['production', 'development', 'staging']
        },

        {
          id: 14,
          icon: require('@/assets/img/svgs/legal defulat icon.svg'),
          iconHover: require('@/assets/img/svgs/legal selected icon.svg'),
          text: 'الشئون القانونية',
          miniTitle: 'الشئون القانونية',
          active: false,
          avaliableEnv: ['development', 'staging'],
          acl: {
            sublinks: true,
            domain: 'users',
            route: ['defaulters'],
            permissions: 'list',
            module: 'clients'
          },
          subLinks: [
            {
              to: '/dashboard/defaulters',
              icon: require('@/assets/img/dashboard-icons/contract-icon.png'),
              iconHover: require('@/assets/img/dashboard-icons/contract-icon-active.png'),
              text: 'العملاء المتعثرين',
              acl: {
                domain: 'users',
                route: 'defaulters',
                permissions: 'list',
                module: 'clients'
              }
            }
          ],
          avaliableEnv: ['development', 'staging']
        },
        {
          id: 15,
          to: '/dashboard/associations',
          icon: require('@/assets/img/assoication/assoication.png'),
          iconHover: require('@/assets/img/assoication/assoication-active.png'),
          text: 'إتحاد الملاك',
          avaliableEnv: ['development', 'staging'],
          acl: {
            domain: 'associations',
            route: 'associations',
            permissions: 'list'
          },
          miniTitle: 'الجمعيات',
          active: false,
          subLinks: [
            {
              to: '/dashboard/associations',
              icon: require('@/assets/img/dashboard-icons/contract-icon.png'),
              iconHover: require('@/assets/img/dashboard-icons/contract-icon-active.png'),
              text: 'الجمعيات',
              acl: {
                domain: 'properties',
                route: 'associations',
                permissions: 'list',
                module: 'pm'
              }
            }
          ],
          avaliableEnv: ['development', 'staging']
        }
      ],
      editProfileMenutitles: [
        {
          to: '/dashboard/edit-profile/',
          icon: 'office-building-outline',
          text: 'إعدادات الحساب',
          componentNameEn: 'profile',
          isCustomer: true
        },
        {
          to: '/dashboard/edit-profile/',
          icon: 'office-building-outline',
          text: 'بوابة العملاء',
          componentNameEn: 'InvitationSettings',
          isCustomer: false
        },
        {
          to: '/dashboard/edit-profile/',
          icon: 'office-building-outline',
          text: 'الفريق',
          componentNameEn: 'TeamSettings',
          isCustomer: false
        },
        {
          to: '/dashboard/edit-profile/',
          icon: 'office-building-outline',
          text: 'العقود',
          componentNameEn: 'contractSetting',
          isCustomer: false
        },
        {
          to: '/dashboard/edit-profile/',
          icon: 'office-building-outline',
          text: 'المالية',
          componentNameEn: 'FinantionalSettings',
          isCustomer: false
        },
        {
          to: '/dashboard/edit-profile/',
          icon: 'office-building-outline',
          text: 'الموافقات',
          componentNameEn: 'ApprovalTabs',
          isCustomer: false
        },
        {
          to: '/dashboard/edit-profile/',
          icon: 'office-building-outline',
          text: 'الخدمات',
          componentNameEn: 'Services',
          isCustomer: false
        },
        {
          to: '/dashboard/edit-profile/',
          icon: 'office-building-outline',
          text: 'الإشعارات',
          componentNameEn: 'NotificationsModels',
          isCustomer: false
        },
        {
          to: '/dashboard/edit-profile/',
          icon: 'office-building-outline',
          text: 'الفوترة والإشتراك',
          componentNameEn: 'SubscriptionSettings',
          isCustomer: false
        }
      ],
      responsive: false,
      id: null,
      newId: null,
      showDrawer2: false,
      linkText: '',
      appendedSubLinks: false,
      showMuneBtn: false
    }
  },
  computed: {
    ...mapState('appState', ['drawer', 'miniDrawer']),
    ...mapState('accountState', ['subscription', 'currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    filteredMainMenuItems() {
      return this.mainMenuItems.filter((menuItem) => {
        return menuItem.avaliableEnv.includes(process.env.NODE_ENV)
      })
    },
    goTo() {
      return (sublink, i) => ({
        path: sublink.action ? `${sublink.to}` : `${sublink.to}${i}`
      })
    },
    logoRoute() {
      return `/`
    },
    isSubscribed() {
      const today = new Date()
      const subScriptionEndDate = new Date(this.subscription?.endDate)
      return subScriptionEndDate.getTime() <= today.getTime()
    },
    isOwner() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    },
    availableTabsItem() {
      if (this.isCustomer) {
        return this.editProfileMenutitles.filter(({ isCustomer }) => {
          return isCustomer
        })
      } else if (!this.isOwner) {
        return this.editProfileMenutitles.filter(({ componentNameEn }) => {
          return !this.avaliableTabsForTeamMembers.includes(componentNameEn)
        })
      }
      return this.editProfileMenutitles
    },
    avaliableTabsForTeamMembers() {
      let excludedTeamMemberTabs = ['bills']

      if (!this.hasInvitationPermission) {
        excludedTeamMemberTabs = [
          ...excludedTeamMemberTabs,
          'InvitationSettings'
        ]
      }
      if (!this.hasNotificationPermission) {
        excludedTeamMemberTabs = [
          ...excludedTeamMemberTabs,
          'NotificationsModels'
        ]
      }
      return excludedTeamMemberTabs
    },
    hasInvitationPermission() {
      return !!(
        (this.subscription.bundle.modules.team ||
          this.subscription.bundle.modules.includes('team')) &&
        !this.isCustomer
      )
    },
    hasNotificationPermission() {
      return !!this.$hasPermission('users', 'notificationsSettings', 'update')
    },
    DOWN() {
      return [
        {
          id: 20,
          to: '/help-center',
          icon: require('@/assets/img/svgs/help-center.svg'),
          iconHover: require('@/assets/img/svgs/help-center-active.png'),
          text: 'مركز المساعدة',
          miniTitle: 'مساعدة'
        },
        {
          id: 14,
          icon: require('@/assets/img/svgs/setting.svg'),
          iconHover: require('@/assets/img/svgs/setting-active.png'),
          text: 'الإعدادات',
          miniTitle: 'الإعدادات',
          subLinks: [
            ...this.availableTabsItem,
            {
              to: 'https://hectar.io/terms&conditions',
              icon: 'office-building-outline',
              text: 'الشروط والأحكام',
              target: '_blank',
              use_A_Tag: true
            },
            {
              divider: true
            },
            {
              icon: 'office-building-outline',
              text: 'تسجيل الخروج',
              action: true
            }
          ]
        }
      ]
    },
    drawerValue: {
      get() {
        return this.drawer
      },
      set(val) {
        this.setDrawer(val)
      }
    },
    miniDrawerValue: {
      get() {
        return this.miniDrawer
      },
      set(val) {
        this.setMiniDrawer(val)
      }
    }
  },
  watch: {
    id(newV) {
      this.newId = newV
    }
  },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    this.miniDrawerValue = true
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapActions('accountState', ['logout']),
    ...mapMutations('appState', [
      'setDrawer',
      'setMiniDrawer',
      'toggleMiniDrawer',
      'setSync'
    ]),
    isCheckPermissionOn(item) {
      return this.isCustomer ? this.checkPermission(item) : true
    },
    daysLeftCalc() {
      const currentDate = new Date()
      const daysLeft = new Date(
        JSON.parse(localStorage.getItem('tokenData')).createdAt
      )

      return (
        Math.round(
          (currentDate.getTime() - daysLeft.getTime()) / (1000 * 3600 * 24)
        ) + 1
      )
    },
    checkPermission(item) {
      if (!item.acl) {
        return true
      } else if (item.acl && item.acl.sublinks) {
        let checkItemExistance = false
        item.subLinks.map((subLink) => {
          if (
            this.$hasPermission(
              subLink.acl.domain,
              subLink.acl.route,
              subLink.acl.permissions
            ) &&
            this.$checkBundleModules(subLink.acl.module)
          ) {
            checkItemExistance = true
          }
        })
        return checkItemExistance
      }
      return (
        this.$hasPermission(
          item.acl.domain,
          item.acl.route,
          item.acl.permissions
        ) && this.$checkBundleModules(item.acl.module)
      )
    },
    reRenderSideNav() {
      this.$forceUpdate()
    },
    setMiniDrawerValue() {
      if (this.miniDrawerValue) {
        this.mainMenuCols = 4
        this.subLinksState = true
      } else {
        this.subLinksState = false
        this.mainMenuCols = 12
      }
      this.miniDrawerValue = !this.miniDrawerValue
    },
    clickOutside() {
      this.miniDrawerValue = true
      this.mainMenuCols = 12
      this.subLinksState = false
    },
    onResponsiveInverted() {
      if (window.innerWidth < 900) {
        this.responsive = true
        this.miniDrawerValue = true
      } else {
        this.responsive = false
      }
    },
    onClickBtn() {
      this.setDrawer(this.drawer)
    },
    setDrawer2(id, item, e) {
      this.id = id
      if (item.hasOwnProperty('subLinks') && e.type === 'click') {
        this.showMuneBtn = true
      } else {
        this.miniDrawerValue = true
        this.showMuneBtn = false
        this.mainMenuCols = 12
        this.subLinksState = false
      }
    },
    showPop(id) {
      this.id = id
      this.appendedSubLinks = !this.appendedSubLinks
    },
    closePopupMenu() {
      this.id = null
      this.appendedSubLinks = false
      this.activeItemId = -1
    },
    setlogout() {
      this.logout().then(() => {
        this.$router.push('/auth/login')
        this.setSync(false)
      })
    },
    sublinkHovering(e) {
      const vListItem = e.target.parentElement.parentElement
      vListItem.classList.toggle('sublinkHovering')
    }
  }
}
</script>
<style lang="scss">
.v-navigation-drawer--open.d2 {
  right: 250px;
  box-shadow: 0 0 0 0;
  background-color: rgba(101, 49, 141, 0.7) !important;
}

.text-bold {
  font-weight: 100 !important;
}

.row.appended-sub-links {
  .col {
    position: relative;

    .v-icon {
      position: absolute;
      left: 7px;
      top: 5px;
    }
  }
}

.iconsContainer {
  .v-image__image--cover {
    background-size: contain !important;
  }
}

// iconsContainer
</style>
