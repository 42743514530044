<template>
  <v-dialog max-width="850" persistent v-model="dialogState_">
    <v-card class="add-new-property">
      <v-card-title
        class="cardTitle pa-0 px-4 mb-4 d-flex justify-space-between custom__shadow"
      >
        <h4 class="" v-if="tab == 0">
          {{ member._id ? 'تعديل' : 'ارسال الدعوة' }}
        </h4>
        <h4 v-else>الأذونات والصلاحيات</h4>

        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs-items v-model="tab" class="mt-6" touchless>
        <v-tab-item>
          <!-- Details -->
          <v-card-text class="pa-0">
            <v-form ref="form" v-model="isValid">
              <v-container class="pa-0 px-6">
                <v-row no-gutters>
                  <v-row class="mt-0">
                    <v-col class="pl-3" cols="12" sm="6">
                      <h6 class="mb-2 mt-0">نوع الدعوة*</h6>
                      <v-select
                        ref="role"
                        :items="roles"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="[(v) => !!v || 'من فضلك اختر نوع الدعوة']"
                        color="black"
                        dense
                        filled
                        item-value="value"
                        hide-details
                        placeholder="نوع الدعوة"
                        required
                        v-model="member.role"
                        @change="turnAllPermessionsON(member.role)"
                      ></v-select>
                    </v-col>

                    <v-col class="pl-3" cols="12" sm="6">
                      <h6 class="mb-2 mt-0">القسم (إختياري)</h6>
                      <v-select
                        ref="department"
                        :items="departmnets"
                        :menu-props="{ bottom: true, offsetY: true }"
                        chips
                        dense
                        item-text="name"
                        item-value="_id"
                        filled
                        hide-details
                        placeholder="القسم"
                        v-model="member.department"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pl-3" cols="12" sm="6">
                      <h6 class="mb-2 mt-0">المسمى الوظيفي (إختياري)</h6>
                      <!-- jobId -->
                      <v-select
                        ref="role"
                        :items="jobTitles"
                        :menu-props="{ bottom: true, offsetY: true }"
                        color="black"
                        dense
                        filled
                        item-text="name"
                        item-value="_id"
                        hide-details
                        placeholder="المسمي الوظيفي"
                        v-model="member.jobId"
                      >
                      </v-select>
                    </v-col>
                    <v-col class="pl-3 pb-0" cols="12" sm="6">
                      <h6 class="mb-2 mt-0">رقم الجوال*</h6>
                      <v-text-field
                        v-if="isEditMood"
                        data-test="phoneNumber"
                        dense
                        filled
                        min
                        v-model="member.phoneNumber"
                        type="number"
                        suffix="+996"
                        :disabled="true"
                        height="55"
                        @input="formatNumberAExistance"
                      ></v-text-field>
                      <vue-tel-input-vuetify
                        v-else
                        style="text-align: right"
                        :disabled="!member.role"
                        :loading="checkPhoneNumberLoader"
                        :error-messages="getPhoneNumberErrorMessage"
                        :success-messages="getPhoneNumberSuccessMesage"
                        class="phonenumber-field font-14"
                        v-model="member.phoneNumber"
                        label=""
                        :only-countries="['SA']"
                        default-country="SA"
                        ref="regPhoneNumber"
                        placeholder="رقم الجوال"
                        :filled="true"
                        required
                        suffix="+966"
                        :rules="[
                          (v) =>
                            (v && !!isValidPhone) ||
                            'من فضلك تأكد من رقم الهاتف'
                        ]"
                        :valid-characters-only="true"
                        :validate-on-blur="true"
                        @keydown="$formatNumberInputs"
                        @validate="onValidatePhoneNumber"
                        @input="formatNumberAExistance"
                      />
                    </v-col>
                    <v-col class="pl-3 pb-0 pt-0" cols="12" sm="6">
                      <h6 class="mb-2 mt-0">البريد الإلكتروني (إختياري)</h6>
                      <v-text-field
                        data-test="email"
                        dense
                        filled
                        hide-details
                        placeholder="البريد الإلكتروني"
                        v-model="member.email"
                        :rules="emailRules"
                        :disabled="isEditMood || !isValidPhone"
                        height="55"
                        @input="debounceCheckUserExistance('email')"
                      ></v-text-field>
                      <p
                        class="font-12 mt-1 mr-3 error--text"
                        :class="{ 'success--text': emailSuccessMesage.length }"
                      >
                        {{ emailSuccessMesage }}
                        {{ emailErrorMessage }}
                      </p>
                    </v-col>
                  </v-row>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-1 mt-4">
            <v-spacer></v-spacer>
            <v-btn
              data-test="nextTab"
              :disabled="
                isEditMood
                  ? false
                  : !isValid || !phoneNumberValid || !emailRules
              "
              :loading="isLoading"
              @click="isValid ? (tab = 1) : 0"
              class="ma-0 rounded-7 my-3 ml-3"
              color="primary"
            >
              <span class="mobile__mb__0 ml-1 font-weight-bold font-12"
                >التالي</span
              >
              <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <!-- Details -->
          <v-card-text class="py-0 px-6 overflow-x-hidden max-height-65vh">
            <div>
              <v-layout justify-space-between>
                <v-container class="pa-0 border-radius overflow-x-auto">
                  <table class="mw-100">
                    <tr class="py-5 mobile__px__10px">
                      <td class="th"></td>
                      <td
                        class="th py-5 text-center"
                        v-for="(value, i) in headers"
                        :key="i"
                      >
                        {{ value }}
                      </td>
                    </tr>
                    <tr
                      class="py-5"
                      v-for="(value, key) in permissions"
                      :key="key"
                    >
                      <td class="py-5 mobile__px__10px">
                        <div class="d-flex">
                          <img
                            class="ml-3"
                            :src="permissionsIcons[key]"
                            loading="lazy"
                          />
                          <div>
                            <h5 class="ma-0 ml-3">{{ value.routeName }}</h5>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="d-flex mb-4">
                          <v-switch
                            data-test="memberRole"
                            :disabled="member.role === 'admin'"
                            :value="key"
                            class="mx-auto"
                            color="primary"
                            hide-details
                            size="sm"
                            v-model="checkAll"
                          ></v-switch>
                        </span>
                      </td>

                      <td v-for="val in value.permissions" :key="val.id">
                        <span class="d-flex mx-5" v-if="val.isActive">
                          <v-checkbox
                            data-test="checkPermission"
                            :disabled="member.role === 'admin'"
                            :value="val.id"
                            class="mx-auto"
                            dense
                            hide-details
                            size="md"
                            v-model="selectedPermissions"
                          />
                        </span>
                      </td>
                    </tr>
                  </table>
                </v-container>
              </v-layout>
            </div>
          </v-card-text>
          <v-card-actions class="cardActions pa-1 mt-4 pl-5">
            <v-btn
              @click="tab--"
              class="border-1 ma-0 rounded-7 my-3 mr-3 px-5"
              color="primary"
            >
              <v-icon class="send-icon">mdi-arrow-left</v-icon>
              <span class="mb-2 ml-1 font-weight-bold"> السابق </span>
            </v-btn>
            <v-spacer />
            <v-btn
              :disabled="!isValid || !selectedPermissions.length"
              :loading="isLoading"
              data-test="sendInvite"
              @click="editMemberId ? updateTeamMember() : addTeamMember()"
              class="border-1 ma-0 rounded-7 my-3 mr-3 px-5"
              color="primary"
            >
              <span class="mb-2 ml-1 font-weight-bold">
                {{ member._id ? 'تعديل' : 'ارسال الدعوة' }}
              </span>
              <v-icon class="send-icon">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EnvService } from '../../services'
import { debounce } from '../../helpers/debounce'
import { convertNumbers2English } from '@/utils/formatters'
import { buildQueryString } from '@/utils/general'
export default {
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    memberData: {
      default() {
        return {}
      }
    },
    editMemberId: {
      type: String
    }
  },
  data() {
    return {
      checkPhoneNumberLoader: false,
      tab: 0,
      member: {},
      selectedPermissions: [],
      jobTitles: [],
      isValid: false,
      phoneNumberValid: false,
      emailValid: false,
      isLoading: false,
      permissions: {},
      phoneNumberErrorMessage: '',
      phoneNumberSuccessMesage: '',
      emailSuccessMesage: '',
      emailErrorMessage: '',
      phoneData: {},
      departmnets: [],
      roles: [
        {
          text: 'مدير',
          value: 'admin'
        },
        {
          text: 'مشرف',
          value: 'supervisor'
        },
        {
          text: 'موظف',
          value: 'member'
        }
      ],
      headers: ['كل الصلاحيات', 'إضافة', 'حذف', 'عرض', 'تعديل'],
      checkAll: [],
      emailRules: [(v) => /.+@.+\..+/.test(v) || !v || ''],
      permissionsKeys: [
        'financial',
        'bonds',
        'clients',
        'contracts',
        'invitations',
        'realestates',
        'reports',
        'taxInvoices',
        'tasks',
        'statistics',
        'requests',
        'notifications',
        'approvalsSettings',
        'assignments',
        'notificationsSettings',
        'contractsSettings',
        'bankAccountsSettings',
        'servicesSettings'
      ],
      permissionsIcons: {
        realestates: require(`@/assets/img/svgs/realestates.svg`),
        invitations: require(`@/assets/img/svgs/invitations.svg`),
        contracts: require(`@/assets/img/svgs/contracts.svg`),
        clients: require(`@/assets/img/svgs/clients.svg`),
        bonds: require(`@/assets/img/svgs/contracts.svg`),
        reports: require(`@/assets/img/svgs/contracts.svg`),
        taxInvoices: require('@/assets/img/svgs/money.svg'),
        tasks: require('@/assets/img/svgs/working.svg'),
        statistics: require('@/assets/img/svgs/working.svg'),
        requests: require('@/assets/img/svgs/contracts.svg'),
        financial: require('@/assets/img/svgs/money.svg'),
        notifications: require('@/assets/img/svgs/lightBell.svg'),
        approvalsSettings: require('@/assets/img/svgs/setting.svg'),
        assignments: require('@/assets/img/svgs/contracts.svg'),
        notificationsSettings: require('@/assets/img/svgs/setting.svg'),
        contractsSettings: require('@/assets/img/svgs/setting.svg'),
        bankAccountsSettings: require('@/assets/img/svgs/setting.svg'),
        servicesSettings: require('@/assets/img/svgs/setting.svg')
      }
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'currentEnv']),
    isMemberPhoneNumber() {
      return !!this.member?.phoneNumber
    },
    getPhoneNumberErrorMessage() {
      return !this.isEditMood ? this.phoneNumberErrorMessage : ''
    },
    getPhoneNumberSuccessMesage() {
      return !this.isEditMood ? this.phoneNumberSuccessMesage : ''
    },
    isAdmin() {
      if (this.member.role === 'admin') {
        return this.getAllPermissionsIds()
      }
      return false
    },
    isValidPhone() {
      return this.editMemberId ? true : this.phoneData.isValid
    },
    isEditMood() {
      return !!Object.keys(this.memberData).length
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  watch: {
    dialogState: {
      handler(val) {
        if (val) {
          if (Object.keys(this.memberData).length) {
            this.$nextTick(() => {
              this.tab = 1
            })
          } else {
            this.member = {}
          }
        }
      }
    },
    memberData: {
      immediate: true,
      deep: true,
      handler() {
        if (Object.keys(this.memberData).length) {
          this.member = {
            ...this.memberData
          }

          this.$set(
            this.member,
            'department',
            this.memberData?.environments[0].jobId?.departmentId?._id ?? ''
          )
          this.member.jobId = this.memberData?.environments[0]?.jobId?._id ?? ''
          this.member.phoneNumber = this.member.phoneNumber.replace('966', '0')
          if (this.member && this.configs) {
            this.setPermissions()
            this.selectedPermissions = []
            this.formatPermissions()
            this.watchSwitchers(this.selectedPermissions)
          }
        }
      }
    },
    'member.department': {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.getJobTitiles()
        }
      }
    },
    'member.environments': {
      immediate: true,
      handler(val) {
        if (val) {
          this.$set(this.member, 'role', val[0].employeeRole)
        }
      }
    },
    checkAll(val, old) {
      this.setAllActive(val, old)
    },
    selectedPermissions(newVal, oldVal) {
      this.watchSwitchers(newVal, oldVal)
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    turnAllPermessionsON(role) {
      if (role === 'admin') {
        this.getAllPermissionsIds()
      } else {
        this.selectedPermissions = []
      }
      if (this.isMemberPhoneNumber) {
        this.checkUserExistance('phoneNumber')
      }
    },
    formatPermissions() {
      const permissions = this.memberData.environments[0].permissions
      for (const i in permissions) {
        for (const j in permissions[i]) {
          for (const index in permissions[i][j]) {
            const onePrmission = this.configs.permissions.find((permission) => {
              return (
                permission.domain === i &&
                permission.route === j &&
                permission.permission === permissions[i][j][index]
              )
            })
            if (typeof onePrmission !== 'undefined') {
              this.selectedPermissions.push(onePrmission._id)
            }
          }
        }
      }
    },
    checkUserExistance(key) {
      let phoneNumber = this.member.phoneNumber ?? ''
      phoneNumber = phoneNumber?.replace(/ /g, '')
      if (phoneNumber.length !== 10 && key === 'phoneNumber') {
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = 'من فضلك تأكد من رقم الهاتف'
      } else if (!/.+@.+\..+/.test(this.member.email) && key === 'email') {
        this.emailSuccessMesage = ''
        this.emailErrorMessage = this.member.email.length
          ? 'من فضلك تأكد من البريد الإلكتروني'
          : ''
      } else if (this.isValidPhone || /.+@.+\..+/.test(this.member.email)) {
        if (key === 'phoneNumber') {
          this.checkPhoneNumberLoader = true
          this.phoneNumberSuccessMesage = ''
          this.phoneNumberErrorMessage = ''
          phoneNumber = phoneNumber.replace('0', '966')
        } else if (key === 'email') {
          this.checkEmailLoader = true
          this.emailSuccessMesage = ''
          this.emailErrorMessage = ''
          phoneNumber = phoneNumber.replace('0', '966')
        }
        EnvService.checkEmployee(
          this.member.role,
          this.currentEnv._id,
          phoneNumber,
          this.member.email
        )
          .then((response) => {
            if (response.data.available && key === 'phoneNumber') {
              this.phoneNumberSuccessMesage = 'الموظف متاح للدعوة'
              this.phoneNumberValid = true
              this.phoneNumberErrorMessage = null
            } else if (key === 'email') {
              this.emailValid = true
              this.emailSuccessMesage = 'البريد متاح للدعوه'
            }
          })
          .catch((error) => {
            if (key === 'phoneNumber') {
              this.phoneNumberErrorMessage = error.response.data.error
              this.phoneNumberValid = false
            } else if (key === 'email') {
              this.emailErrorMessage = error.response.data.error
              this.emailValid = false
            }
          })
          .finally(() => {
            this.checkPhoneNumberLoader = false
          })
      }
    },
    debounceCheckUserExistance: debounce(function () {
      this.checkUserExistance('phoneNumber')
    }, 500),
    formatNumberAExistance(val) {
      this.member.phoneNumber = convertNumbers2English(val)
      this.debounceCheckUserExistance()
    },
    getAllPermissionsIds() {
      const that = this
      that.selectedPermissions = []
      for (const key of Object.keys(that.permissions)) {
        that.selectedPermissions = [
          // eslint-disable-next-line
          ...that.selectedPermissions,
          // eslint-disable-next-line
          ...that.permissions[key].permissions.map(
            (permission) => permission.id
          )
        ]
      }
    },
    closeDialog() {
      this.tab = 0
      this.$emit('closed-add-dialog')
    },
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    async addTeamMember() {
      try {
        this.member.permissions = this.selectedPermissions
        const _data = {
          environmentId: this.currentEnv._id,
          ...this.member,
          registered: 0,
          phoneNumber: this.member.phoneNumber
            .replace(/ /g, '')
            .replace('0', '966')
        }
        this.isLoading = true

        const { data } = await EnvService.inviteEmployee(_data)

        this.phoneNumberSuccessMesage = ''
        this.isLoading = this.phoneNumberValid = this.emailValid = false
        this.phoneNumberErrorMessage = null
        this.emailErrorMessage = this.emailSuccessMesage = ''
        this.closeDialog()
        if (data && data.user) {
          this.addNotification({
            text: 'تم ارسال الدعوة بنجاح',
            color: 'success'
          })
          this.$emit('refresh-team-list')
          this.tab = 0
          return
        }
        this.addNotification({
          text:
            data.error === 'user_exists'
              ? 'عفواً! هذا المستخدم موجود مسبقا'
              : 'عفواً! البيئة المدخلة غير موجودة',
          color: 'error'
        })
        this.tab = 0
      } catch (err) {
        this.isLoading = false
        this.closeDialog()
        this.addNotification({
          text:
            err.response?.data?.error === 'user_exists'
              ? 'عفواً! هذا المستخدم موجود مسبقا'
              : 'عفواً! البيئة المدخلة غير موجودة',
          color: 'error'
        })
        this.tab = 0
      }
    },
    updateTeamMember() {
      this.member.permissions = this.selectedPermissions
      this.member.phoneNumber = this.member.phoneNumber
        .replace(/ /g, '')
        .replace('0', '966')
      const data = {
        environmentId: this.currentEnv._id,
        role: this.member.role,
        jobId: this.member.jobId,
        department: this.member?.department,
        permissions: this.selectedPermissions,
        _id: this.member?._id
      }
      this.isLoading = true
      EnvService.updateInvite(data)
        .then((res) => {
          this.isLoading = false
          this.phoneNumberSuccessMesage = ''
          this.phoneNumberValid = this.emailValid = false
          this.phoneNumberErrorMessage = null
          this.closeDialog()
          if (res.data && res.data.user) {
            this.addNotification({
              text: 'تم تعديل بيانات العضو بنجاح',
              color: 'success'
            })
            this.$parent.loadEmployees()

            this.tab = 0
            return
          }
          this.addNotification({
            text:
              res.data.error === 'user_exists'
                ? 'عفواً! هذا المستخدم موجود مسبقا'
                : 'عفواً! البيئة المدخلة غير موجودة',
            color: 'error'
          })
          this.tab = 0
        })
        .catch((err) => {
          this.closeDialog()

          this.addNotification({
            text:
              err.response.data.error === 'user_exists'
                ? 'عفواً! هذا المستخدم موجود مسبقا'
                : 'عفواً! البيئة المدخلة غير موجودة',
            color: 'error'
          })
          this.tab = 0
        })
    },
    setAllActive(val, old) {
      const pushedPermissions = val
      const oldPermissions = old
      if (val.length > old.length) {
        const newDifference = pushedPermissions.filter(
          (x) => !oldPermissions.includes(x)
        )
        const Ids = this.permissions[newDifference]?.permissions
          .filter((permission) => permission.isActive)
          .map(({ id }) => id)
        this.selectedPermissions = [
          ...new Set([...Ids, ...this.selectedPermissions])
        ]
      } else {
        const Old = oldPermissions.filter((x) => !pushedPermissions.includes(x))
        const Ids = this.permissions[Old]?.permissions.map(
          (permission) => permission.id
        )
        this.selectedPermissions = this.selectedPermissions.filter(
          (id) => !Ids?.includes(id)
        )
      }
    },
    watchSwitchers(newVal) {
      this.permissionsKeys.map((type) => {
        let flag = 0 // flag for checking if all item checkboxes checked or not
        this.permissions[type].permissions.map((p) => {
          if (newVal.includes(p.id)) {
            flag++
          }
        })
        if (
          flag ===
          this.permissions[type].permissions.filter((p) => p.isActive).length
        ) {
          if (!this.checkAll.includes(type)) {
            this.checkAll.push(type)
          }
        } else if (
          flag !==
            this.permissions[type].permissions.filter((p) => p.isActive)
              .length &&
          this.checkAll.includes(type)
        ) {
          if (this.checkAll.indexOf(type) > -1) {
            this.checkAll.splice(this.checkAll.indexOf(type), 1)
          }
        }
      })
    },
    async getJobTitiles() {
      try {
        const query = buildQueryString({
          departmentId: this.member?.department
        })
        const { data } = await EnvService.getJobTitles(query)
        this.jobTitles = data.envJobTitles
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل المسمي الوظيفي, حاول مره أخري',
          color: 'error'
        })
      }
    },
    async getAllDepartments() {
      this.isPageLoading = true
      try {
        const query = buildQueryString({
          environmentId: this.currentEnv?._id
        })
        const {
          data: { envDepartments }
        } = await EnvService.getDepartments(query)
        this.departmnets = envDepartments
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل الأقسام, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    setPermissions() {
      const permissions = JSON.parse(
        localStorage.getItem('configrations')
      ).permissions

      // if permissions exists format it to be compatible with the term-permit component
      if (permissions) {
        const permessionFormat = permissions.reduce((result, item) => {
          // Get app object corresponding to current item from result (or insert if not present)
          const items = (result[item.route] = result[item.route] || {})
          items.routeName = item.routeNameAr
          items.permissions = items[item.route] = items[item.route] || []
          items.permissions.push({
            id: item._id,
            permission: item.permission,
            name: item.nameAr,
            isActive: item.isActive
          })
          return result
        }, {})
        this.reSortingPermessionsAction(permessionFormat)
      }
    },
    reSortingPermessionsAction(permessionFormat) {
      Object.values(permessionFormat).map((item) => {
        item.permissions.sort((a, b) => (a.permission > b.permission ? 1 : -1))
      })
      this.permissions = permessionFormat
    }
  },
  async created() {
    await this.getAllDepartments()
    if (!this.isEditMood) {
      this.setPermissions()
    } else {
      this.departmnets.push(
        this.memberData?.environments[0].jobId?.departmentId
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_add-new-property.scss';

.cardTitle {
  background: #ffffff;
  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.07);
}

.cardActions {
  border: none !important;
}

.border-1 {
  border: 1px solid #efebf2;
}

table {
  border: none;
  border-collapse: collapse;

  tr:first-child {
    td:nth-child(2) {
      border-top-right-radius: 10% !important;
    }

    td:last-child {
      border-top-left-radius: 10% !important;
    }
  }

  tr:last-child {
    td:first-child {
      border-bottom: none;
    }

    td:nth-child(2) {
      border-bottom-right-radius: 10% !important;
    }

    td:last-child {
      border-bottom-left-radius: 10% !important;
    }
  }

  td {
    border: 1px solid #efebf2;
  }

  td:first-child {
    border-right: none;
    padding-right: 6px;
  }

  td.th {
    border-bottom: none;
  }

  td.th:first-child {
    opacity: 0;
    border-top: none;
  }

  td.th {
    color: #101010;
    font-size: 14px;
    font-weight: bold;
  }
}
.max-height-65vh {
  max-height: 65vh;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #efebf2;
}

::-webkit-scrollbar-thumb {
  background-color: #662d91;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #662d91;
}
</style>
<style>
.phonenumber-field input {
  text-align: right;
}
</style>
