<template>
  <div>
    <!-- Accept and reject offer -->
    <template v-if="quotation.status === 'new'">
      <v-btn
        class="actions px-8 mx-2"
        color="#64C567"
        max-width="145"
        @click="confirmHandler"
      >
        <span>
          <v-icon class="ml-2">mdi-check</v-icon>
          وافق العميل
        </span>
      </v-btn>
      <v-btn
        max-width="145"
        class="px-8 border-radius-7 rejected-btn"
        @click="$emit('reject')"
      >
        <span>
          <v-icon size="20">mdi-close-circle-outline</v-icon>
          رفض العميل
        </span>
      </v-btn>
    </template>

    <v-btn
      v-if="quotation.status === 'draft'"
      class="actions px-8 mx-2"
      color="#12CAD6"
      max-width="145"
      @click="$emit('openOfferModel')"
    >
      <span>
        <v-icon class="ml-2">mdi-publish</v-icon>
        نشر
      </span>
    </v-btn>

    <v-btn
      v-if="canceledBtnStatus && quotation.status !== 'draft'"
      class="white--text px-8 mx-2 border-radius-7"
      color="primary"
      max-width="145"
      @click="$emit('openPDF')"
    >
      <v-icon class="ml-2" small>mdi-export-variant</v-icon>
      <span> بي دي اف </span>
    </v-btn>

    <!-- Cancel offer -->
    <v-btn
      v-if="!forCancel && canceledBtnStatus"
      class="white--text border-radius-7"
      color="red"
      max-width="145"
      @click="$emit('cancelled')"
    >
      <span>
        <v-icon class="ml-2">mdi-close</v-icon>
        إلغاء العرض
      </span>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    quotation: {
      type: Object,
      required: true
    },
    canceledBtnStatus: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    forCancel() {
      const status = this.quotation.status
      return status === 'cancelled' || status === 'expired'
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    confirmHandler() {
      if (!this.$hasPermission('realestates', 'quotations', 'update')) {
        this.addNotification({
          text: 'ليس لديك صلاحية الموافقة على عرض سعر',
          color: 'error'
        })
        return
      }
      this.$emit('accept')
    }
  }
}
</script>

<style lang="scss" scoped>
.border-radius-7 {
  border-radius: 7px;
}
.rejected-btn {
  color: red !important;
  box-shadow: none !important;
  border: 1px solid #0000007d !important;
}
</style>
