<template>
  <div class="quotation--paper2 quotation--paper5">
    <h1>الموافقة والتوقيع</h1>
    <h2 class="ma-0">
      يتم الموافقة على الشروط والأحكام التالية بالموافقة الإلكترونية على هذا
      العرض
    </h2>

    <!-- <div
      class="quotation--paper9__card quotation--paper9__card--mod py-2 d-flex"
    >
      <div>
        <h3 class="ma-0 mb-2 font-20">الشروط والأحكام</h3>
        <p class="ma-0 font-16">- الشروط والاحكام هنا</p>
        <p class="ma-0 font-16">- الشروط والاحكام هنا</p>
        <p class="ma-0 font-16">- الشروط والاحكام هنا</p>
        <p class="ma-0 font-16">- الشروط والاحكام هنا</p>
        <p class="ma-0 font-16">- الشروط والاحكام هنا</p>
      </div>
    </div> -->
    <div
      class="quotation--paper9__card quotation--paper10__card py-2 d-flex pb-10"
    >
      <div>
        <h3 class="ma-0 mb-2 font-20">ممثل المستأجر</h3>
        <p class="ma-0 py-1 font-16">{{ quotation?.client?.name }}</p>
        <p class="ma-0 font-16">{{ dateToday }}</p>
      </div>
      <div class="d-flex align-end quotation--paper10__card__sign">
        <p class="ma-0 font-16 mx-5 font-weight-bold">التوقيع هنا</p>
        <div class="quotation--paper10__card__sign__seal">
          <p class="ma-0 font-16 mx-10 font-weight-bold">الختم هنا</p>
          <!-- <img :src="companyImages?.companySeal?.location" alt="seal" /> -->
        </div>
      </div>
    </div>
    <div
      class="quotation--paper9__card quotation--paper10__card py-2 d-flex pb-10"
    >
      <div>
        <h3 class="ma-0 mb-2 font-20">ممثل التأجير</h3>
        <p class="ma-0 py-1 font-16">
          {{ quotation?.rentReprsentitive?.name }}
        </p>
        <p class="ma-0 font-16">{{ dateToday }}</p>
      </div>
      <div class="d-flex align-end quotation--paper10__card__sign">
        <p class="ma-0 font-16 mx-5 font-weight-bold">التوقيع هنا</p>
        <div class="quotation--paper10__card__sign__seal">
          <p class="ma-0 font-16 mx-10 font-weight-bold">الختم هنا</p>
          <img
            v-if="signatures?.companySeal"
            :src="companyImages?.companySeal?.location"
            alt="seal"
          />
        </div>
      </div>
    </div>

    <v-row class="mt-5" v-if="isCustomer">
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <v-file-input
          label=""
          placeholder="إضغط لإرفاق التوقيع (واحد فقط)"
          outlined
          :rules="selectRules"
          class="rounded-lg"
          prepend-icon=""
          required
          dense
          accept="image/*"
          v-model="attachment.signature"
          @change="setAttachment"
        >
          <template v-slot:append>
            <v-icon color="primary">mdi-upload</v-icon>
          </template>
        </v-file-input>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <v-file-input
          label=""
          placeholder="إضغط لإرفاق الختم (واحد فقط)"
          outlined
          :rules="selectRules"
          class="rounded-lg"
          prepend-icon=""
          required
          dense
          accept="image/*"
          v-model="attachment.seal"
          @change="setAttachment"
        >
          <template v-slot:append>
            <v-icon color="primary">mdi-upload</v-icon>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.quotation--paper2 .v-text-field--outlined fieldset {
  border: 1px dashed;
}
.quotation--paper2 .v-input__slot {
  min-height: 60px !important;
}
.quotation--paper2 .mdi-close::before {
  position: relative;
  top: 10px;
}
</style>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Paper10',
  props: {
    quotation: {
      type: Object,
      default: () => {}
    },
    companyImages: {
      type: Object,
      required: true
    },
    signatures: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      attachment: {},
      selectRules: [(v) => !!v || '']
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    ...mapState('accountState', ['user']),
    dateToday() {
      return new Date().toLocaleDateString('ar-AS', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }
  },
  methods: {
    setAttachment() {}
  }
}
</script>

<style lang="scss" scoped>
.mdi-upload::before {
  opacity: 0.5;
  position: relative;
  top: 10px;
}
</style>
