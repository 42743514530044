<template>
  <v-data-table
    :class="{ 'hectar-table-sm': tableSm }"
    style="cursor: pointer"
    :items-per-page="clients.length"
    :headers="headers"
    :items="clients"
    class="hectar-table clients__list__view mt-0 pt-0"
    hide-default-footer
    mobile-breakpoint="0"
  >
    <template v-slot:[`item.checked`]="{ item }">
      <v-checkbox
        v-if="isDeletable(item)"
        v-model="item.checked"
        @click.stop
        class="reset-all ma-0"
        dense
        hide-details
      />
    </template>
    <template v-slot:[`item.clientType`]="{ item }">
      <div v-if="item" class="d-flex flex-grow-0 justify-center">
        <h6 class="mx-auto">
          {{ getArNames(item.clientType) }}
        </h6>
      </div>
    </template>
    <template v-slot:[`item.requestsCount`]="{ item }">
      <div v-if="item" class="d-flex flex-grow-0 justify-center">
        <h6 class="mx-auto">
          {{ item.requestsCount ?? 0 }}
        </h6>
      </div>
    </template>
    <template v-slot:[`item.number`]="{ item }">
      <div v-if="item" class="d-flex flex-grow-0 justify-center">
        <h6>
          {{ item.number ?? '-' }}
        </h6>
      </div>
    </template>
    <template
      v-if="$hasPermission('users', 'clients', 'list')"
      v-slot:[`item.name`]="{ item }"
    >
      {{ item.name }}
    </template>
    <template v-slot:[`item.environments`]="{ item }">
      <div class="d-flex flex-grow-0 align-center justify-center">
        <div v-if="!item?.environments[0]?.role?.length">-</div>
        <div v-else class="d-flex flex-grow-0 justify-center">
          <div
            class="mx-2"
            v-for="role in item?.environments[0]?.role"
            :key="role"
          >
            <h6 :class="`${setRoleLabelStyle(role)}`">
              {{ getArNames(role) }}
            </h6>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:[`item.userId`]="{ item }">
      <div v-if="item.userId" class="d-flex flex-grow-0 justify-center">
        <span v-if="item.userId">
          <v-icon small class="ml-1">mdi-account</v-icon>
        </span>
      </div>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="my-auto mobile__ml__10px">
        <v-menu
          left
          content-class="dropdown-menu"
          offset-x
          transition="slide-x-transition"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="options-btn"
              color="lamb"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon v-if="!isCustomer">mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="!item.userId" @click="converToClint(item)">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>تحويل لعميل</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="showRequests(item)">
              <v-list-item-icon>
                <img
                  class="cursor mobile__m__0 view--change--btn"
                  src="@/assets/img/dashboard-icons/contract-icon.png"
                  width="14"
                  loading="lazy"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>عرض الطلبات</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="$emit('del-client', [item._id])"
              v-if="$hasPermission('users', 'reports', 'delete')"
            >
              <v-list-item-icon>
                <v-icon color="danger">mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="danger--text">حذف</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex'
import { setRoleLabelStyle } from '@/constans/ptClients'
import { getLocalNames } from '@/utils/general'
export default {
  props: {
    isCustomer: {
      type: Boolean,
      default: false
    },
    clients: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    tableSm: {
      defaults: false
    }
  },
  data: () => ({}),
  computed: {
    ...mapState('accountState', ['user', 'currentEnv'])
  },
  methods: {
    isDeletable(client) {
      if (
        client._id === this.user._id ||
        (client.environments?.length && client.environments[0]?.isOwner) ||
        !this.$hasPermission('users', 'clients', 'delete')
      ) {
        return false
      }
      return true
    },
    converToClint(item) {
      this.$emit('convert-to-client', item._id)
    },
    showRequests(item) {
      // this.$router.push({ name: 'maintenance' }) // ! after finish filter in request send item[name] with router
      this.$emit('show-requests', item)
    },
    setRoleLabelStyle(role) {
      return setRoleLabelStyle(getLocalNames(role))
    },
    getArNames(name) {
      return getLocalNames(name)
    }
  }
}
</script>
