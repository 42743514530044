<template>
  <v-dialog persistent v-model="dialogState_" max-width="580px">
    <v-card class="add-new-message">
      <v-card-title class="pa-2 d-flex justify-space-between">
        <h4 class="ma-0">إرسال رساله</h4>
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <!-- reciver Type -->
        <v-tab-item>
          <v-card-text class="pt-5">
            <span class="msgTo">رساله الي</span>
            <v-container class="mt-2 px-0" fluid>
              <v-row>
                <v-col class="py-0" cols="12" sm="4" md="4">
                  <v-chip class="pa-5 pr-1" color="#fff">
                    <v-checkbox
                      v-model="selectedVal"
                      value="team"
                      label="الفريق"
                    ></v-checkbox>
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-1">
            <v-spacer></v-spacer>
            <v-btn
              @click="tab++"
              class="rounded-7 my-3 mx-3 px-8"
              color="primary"
            >
              <span class="mb-2 ml-1"> التالي </span>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <v-card-text class="pt-5">
            <v-row>
              <v-col class="pa-0">
                <v-form ref="form" v-model="isValid">
                  <v-container>
                    <v-row no-gutters>
                      <!-- ***realtype****-->
                      <v-col cols="12">
                        <h5>{{ getReciverType.name }}</h5>
                        <v-select
                          :items="getReciverType.data"
                          :menu-props="{
                            transition: 'slide-y-transition',
                            bottom: true,
                            offsetY: true
                          }"
                          :placeholder="`إبحث ب${getReciverType.name}`"
                          append-icon="mdi-chevron-down"
                          dense
                          filled
                          item-text="name"
                          item-value="_id"
                          required
                          return-object
                          v-model="selectedClient"
                          :rules="[(v) => !!v || 'من فضلك اختر العميل']"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <h5>محتوي الرساله</h5>
                        <v-textarea
                          class="textArea"
                          outlined
                          v-model="messageContent"
                          no-resize
                          rows="2"
                          :rules="[(v) => !!v || 'من فضلك ادخل محتوي الرساله']"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-1">
            <v-btn
              @click="tab--"
              class="rounded-7 my-3 mx-3 px-8 prevBtn"
              color="#fff"
              depressed
            >
              <v-icon color="#737373">mdi-arrow-right</v-icon>
              <span class="mb-2 ml-1" style="color: #737373"> السابق </span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="validate"
              class="rounded-7 my-3 mx-3 px-8"
              color="primary"
              :loading="addBtnLoading"
              :disabled="addBtnLoading"
            >
              <span class="mb-2 ml-1"> إرسال </span>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_add-new-property.scss'
import { EnvService } from '../../services'
import { messageService } from '@/services/messagae.service'

export default {
  props: ['dialogState'],
  components: {},
  data() {
    return {
      selectedVal: '',
      selectedClient: '',
      messageContent: '',
      tab: 0,
      isValid: false,
      clients: [],
      team: [],
      hectarMembers: [],
      env: JSON.parse(localStorage.getItem('currentEnv')),
      currentUser: JSON.parse(localStorage.getItem('tokenData')),
      addBtnLoading: false
    }
  },
  computed: {
    getReciverType() {
      const reciverType = { name: '', data: [] }
      if (this.selectedVal === 'client') {
        reciverType.name = 'اسم العميل'
        reciverType.data = [...this.clients]
      } else if (this.selectedVal === 'team') {
        reciverType.name = 'اسم العضو'
        reciverType.data = [...this.team]
      } else if (this.selectedVal === 'hectar') {
        reciverType.name = 'اسم عضو هكتار'
        reciverType.data = [...this.hectarMembers]
      }
      return reciverType
    },
    messageToSend() {
      const MessageToSend = {
        content: this.messageContent,
        isRead: 'false',
        isDeleted: 'false',
        senderData: {
          _id: this.currentUser._id,
          name: this.currentUser.name
        },
        reciverId: this.selectedClient._id,
        type: this.selectedVal
      }
      return MessageToSend
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.tab = 0
      this.selectedVal = ''
      this.$emit('closed-message-dialog')
    },
    validate() {
      this.$refs.form.validate()
      if (this.isValid && this.messageContent.replace(/\s/g, '')) {
        this.addMsg()
      }
    },
    async loadClients() {
      try {
        const res = await EnvService.getAllClients(this.env._id)
        this.clients = res?.data?.users ?? []
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل العملاء',
          color: 'error'
        })
      }
    },
    loadEmployees() {
      EnvService.getAllEmployee(this.env._id, '&registered=1')
        .then((res) => {
          this.team = res.data.users
        })
        .catch(() => {
          this.addNotification({
            text: 'خطأ في تحميل الموظفين',
            color: 'error'
          })
        })
    },
    addMsg() {
      this.addBtnLoading = true
      this.messageContent = this.messageContent.trim()
      messageService
        .addMessage(this.messageToSend)
        .then(() => {
          this.$emit('closed-message-dialog')
          this.addBtnLoading = false
          this.addNotification({
            text: 'تم إرسال الرساله',
            color: 'success'
          })
          this.$refs.form.reset()
          this.tab--
          this.selectedVal = ''
        })
        .catch(() => {
          this.addBtnLoading = false
          this.addNotification({
            text: 'نواجه مشكله في ارسال الرساله يمكنك المحاوله لاحقا',
            color: 'error'
          })
        })
    }
  },
  created() {
    this.loadClients()
    this.loadEmployees()
  }
}
</script>
<style>
.add-new-message .v-chip {
  display: flex;
  margin: 0 auto;
  border: 1px solid #efebf2 !important;
  border-radius: 7px !important;
}
.add-new-message .v-label {
  color: #101010 !important;
  font-size: 12px !important;
}
.add-new-message .msgTo {
  color: #101010;
  font-size: 14px;
}
.add-new-message .v-text-field--outlined fieldset {
  border: 1px solid rgb(239, 235, 242) !important;
}
.add-new-message .prevBtn {
  border: 1px solid #efebf2 !important;
}
.add-new-message.v-card .v-card__actions {
  border: none !important;
}
.add-new-message.v-card .v-input--checkbox,
.add-new-message.v-card .v-chip__content {
  width: 100% !important;
}
</style>
