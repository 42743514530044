<template>
  <v-dialog max-width="800px" persistent :value="dialogState">
    <v-card class="add-new-property pa-0">
      <!-----------1# Header----------->
      <v-card-title
        class="py-0 px-3 mb-0 custom__shadow d-flex justify-space-between"
      >
        <!----------Title----------->
        <h4 class="my-2" v-text="`شروط وأحكام ${service.title}`" />

        <!----------Close----------->
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-----------2# Actions----------->
      <v-container class="mb-0">
        <div v-if="!editMode">
          <div
            v-if="!termsAndConditions"
            class="d-flex flex-column justify-center align-center gap-10px text-center"
            style="height: 300px"
          >
            <img :src="docsIcon" alt="docs icon" width="90" />
            <h3 class="grey--text">ليس هناك شروط وأحكام مضافة لهذة الخدمة</h3>
          </div>
          <template v-else>
            <div
              class="content-block overflow-y-auto font-14 grey--text"
              v-html="termsAndConditions"
            ></div>
          </template>
        </div>
        <div v-else>
          <quill-editor
            ref="quillEditor"
            v-model="termsAndConditions"
            class="quillEditor"
            :options="editorOptions"
            @change="forceEditorOptions"
          />
        </div>
      </v-container>
      <!----------3# Save----------->
      <v-card-actions class="pa-1 mt-2">
        <v-layout justify-end class="mt-5">
          <template v-if="!editMode">
            <v-btn
              v-if="!termsAndConditions"
              @click="editMode = true"
              color="primary"
              class="ma-0 rounded-7 my-2 ml-3 mr-auto font-14"
            >
              <span class="font-16"> إضافة </span>
            </v-btn>
            <v-btn
              v-else
              @click="editMode = true"
              color="primary"
              class="ma-0 rounded-7 my-2 ml-3 mr-auto font-14"
            >
              <span class="font-16"> تعديل </span>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              color="primary"
              class="ma-0 rounded-7 my-2 ml-3 font-14"
              :loading="submitBtnIsLoading"
              @click="validateTermsAndConditions"
            >
              <span class="font-16"> حفظ </span>
            </v-btn>
          </template>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import { cloneDeep } from 'lodash'
export default {
  name: 'TermsAndConditionsModel',
  components: { quillEditor },
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    service: {
      type: Object,
      default: () => {}
    },
    hasUpdatePermission: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    docsIcon: require(`@/assets/img/svgs/docs-gray.svg`),
    termsAndConditions: '',
    editMode: false,
    submitBtnIsLoading: false,
    editorOptions: {
      modules: {
        toolbar: [
          [{ direction: 'rtl' }],
          [{ list: 'bullet' }],
          ['link'],
          ['bold', 'italic', 'underline', 'strike']
        ]
      },
      placeholder: 'اكتب هنا ...'
    }
  }),
  computed: {
    ...mapState('accountState', ['currentEnv'])
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapActions('accountState', ['updateCurrentEnv']),
    forceEditorOptions(event) {
      if (event.text === '\n') {
        this.termsAndConditions = ''
        return
      }
      const editor = this.$refs.quillEditor.quill
      const formats = editor.getFormat()
      if (!formats.direction || formats.direction !== 'rtl') {
        editor.format('direction', 'rtl')
      }
      if (!formats.list || formats.list !== 'bullet') {
        editor.format('list', 'bullet')
      }
    },
    async popUpConfirm(data) {
      return await this.$root.$confirm.open(data)
    },
    async validateTermsAndConditions() {
      if (this.termsAndConditions === '') {
        await this.removeTermsAndConditionsCheck()
      } else {
        this.updateEnv()
      }
    },
    async removeTermsAndConditionsCheck() {
      const popUpInfo = {
        title: 'حذف الشروط والأحكام',
        message: 'هل أنت متأكد من حذف الشروط والأحكام',
        options: { color: 'red' }
      }
      const confirm = await this.popUpConfirm(popUpInfo)
      if (confirm) {
        this.updateEnv()
      }
    },
    updateEnv() {
      const newService = {
        ...this.service,
        termsAndConditions: this.termsAndConditions
      }
      this.$emit('updateServiceSettings', newService)
      this.closeDialog()
    },
    closeDialog() {
      this.editMode = false
      this.$emit('toggleModel', {
        dialogState: false
      })
    }
  },
  watch: {
    service: {
      deep: true,
      handler() {
        this.termsAndConditions = cloneDeep(this.service.termsAndConditions)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-block {
  max-height: 300px;
  padding-right: 10px;
  ::v-deep {
    ul {
      list-style-position: inside;
    }
  }
}
.quillEditor {
  ::v-deep {
    .ql-editor {
      text-align: right;
    }
    .ql-direction,
    .ql-list {
      display: none;
    }
  }
}
</style>
