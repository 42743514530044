<template>
  <div
    v-if="Object.keys(item).length"
    class="show-detailes contentSection contracts__bonds__side__deatails"
  >
    <v-slide-x-transition>
      <v-layout>
        <v-flex class="whiteSide flex-wrap" md8>
          <!-------------side contract header----------->
          <Loader
            v-if="isSideDetailsLoading"
            v-bind="{ ...loaderProps, numberOfLines: 1 }"
            class="my-3"
          />
          <div
            v-else
            class="justify-between align-center"
            :class="[!getBreakPoint['smAndDown'] ? 'd-flex' : 'd-block']"
          >
            <div
              class="align-center"
              :class="[!getBreakPoint['smAndDown'] ? 'd-flex' : 'd-block']"
            >
              <v-img
                :lazy-src="exitArrow"
                max-height="15"
                max-width="15"
                :src="exitArrow"
                class="mt-3 ml-6"
                @click="closeDetailsPage"
              />
              <div
                v-for="{ data, key, className = '' } in headerData"
                :key="key"
                class="mr-8"
              >
                <div
                  v-if="
                    item[key] ||
                    (key.includes('.') &&
                      item[key.split('.')[0]][key.split('.')[1]])
                  "
                >
                  <h6 class="text-center">{{ data }}</h6>
                  <div class="text-center ml-1 font-12 my-1" :class="className">
                    <h5
                      v-if="!key.includes('.')"
                      class="font-12 font-weight-medium pa-0 ma-0 text-truncate"
                    >
                      {{ item[key] }}
                    </h5>
                    <h5
                      v-else
                      class="font-12 font-weight-medium pa-0 ma-0 text-truncate"
                    >
                      {{ item[key.split('.')[0]][key.split('.')[1]] }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isNonEditable" class="d-flex">
              <v-btn
                @click="deleteDue"
                color="red"
                max-width="120"
                class="actions px-8 mb-1 danger--text cancel mx-auto"
              >
                <v-icon color="white"> mdi-close </v-icon>
                <span class="font-12 font-weight-bold" style="color: white">
                  إلغاء المطالبة
                </span>
              </v-btn>
            </div>
          </div>
          <v-divider class="mt-3"></v-divider>
          <v-layout class="sidedetails__layout">
            <Loader
              v-if="isSideDetailsLoading"
              v-bind="loaderProps"
              class="my-3 px-5"
            />
            <v-flex
              v-else
              class="px-5"
              :class="{ 'details-container': getBreakPoint['mdAndUp'] }"
              md8
            >
              <!-------------------------------------------->
              <!-------------contract details--------------->
              <!-------------------------------------------->
              <!-- <v-layout class="sidedetails__layout"> -->
              <!-- <v-flex :md8="!isCustomer" :md12="isCustomer" no-gutters> -->
              <v-tabs
                class="tabs-container"
                color="primary"
                align-with-title
                right
                v-model="componentTab"
              >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                  v-for="detailTab in detailsTabs"
                  :key="detailTab.title"
                  color="#737373"
                  class="font-14 mr-0"
                >
                  {{ detailTab.title }}
                </v-tab>
              </v-tabs>
              <div style="height: 90vh" class="contentSection">
                <v-tabs-items v-model="componentTab">
                  <v-tab-item
                    v-for="tab in detailsTabs"
                    :key="tab.title"
                    :eager="true"
                  >
                    <!-- :headers="headers" -->
                    <!-- :bonsSubtypes="bonsSubtypes" -->
                    <component
                      :is="tab.component"
                      :item="item"
                      :isNonEditable="isNonEditable"
                      :assignments="assignments"
                      :isClaimRentType="isClaimRentType"
                      :hasAddPermission="hasAddPermission"
                      :logs="logs"
                      :isLoading="isLoading"
                      :duesAttachments="duesAttachments"
                      :assignmentTypes="assignmentTypes"
                      :assignmentStatus="assignmentStatus"
                      @selectFile="uploadDuesAttachment"
                      @delete-document="deleteDuesDocument"
                      @agreeToDeleteInstallment="agreeToDeleteInstallment"
                      @changeEditTaxModal="changeEditStatus"
                      @addCollection="changeDueModal"
                      @editTypeKey="changeTaxTypeKey"
                      @changeEditDateModal="changeEditInstallmentDateModal"
                      @changeDiscountDeductModal="changeDiscountDeductModal"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </div>
              <!-- </v-flex> -->
              <!-- </v-layout> -->
            </v-flex>
            <!-------------------------------------------->
            <!-------------comments section--------------->
            <!-------------------------------------------->
            <v-flex md4 class="mr-2">
              <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab> التعليقات </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <div
                    class="commentsSection ma-0 d-flex flex-column"
                    style="height: 90vh"
                  >
                    <!-------------------------------------------->
                    <!-------------comments loader---------------->
                    <!-------------------------------------------->
                    <v-row class="mx-0" v-if="commentsAreLoading">
                      <v-col cols="12" v-for="i in 3" :key="i">
                        <v-skeleton-loader
                          class="background-transparent"
                          width="300"
                          type="list-item-avatar-two-line"
                        ></v-skeleton-loader>
                      </v-col>
                    </v-row>
                    <!-------------------------------------------->
                    <!-------------no comments-------------------->
                    <!-------------------------------------------->
                    <div class="ma-auto" v-else-if="!comments.length">
                      <v-sheet>
                        <span>
                          <v-img
                            class="ma-auto"
                            width="72"
                            height="72"
                            :src="noComments"
                          ></v-img>
                          <span> ليست هناك اي تعليقات </span>
                        </span>
                      </v-sheet>
                    </div>
                    <!-------------------------------------------->
                    <!-------------comments data------------------>
                    <!-------------------------------------------->
                    <div v-else class="comments mb-auto mr-2 mt-7">
                      <CommentCard
                        v-for="(comment, index) in comments"
                        :key="comment._id"
                        v-bind="generateCommentProps(comment)"
                        @setActiveComment="setActiveComment"
                        @setActiveTextArea="setActiveTextArea"
                        @updateComment="updateComment($event, index)"
                        @deleteComment="deleteComment($event, index)"
                      />
                    </div>
                    <v-sheet
                      class="mb-1 mr-4"
                      v-if="$hasPermission('properties', 'contracts', 'add')"
                    >
                      <v-form>
                        <v-textarea
                          @keydown.enter.exact.prevent
                          @keyup.enter.exact="addComment"
                          auto-grow
                          class-content="commentTextArea font-12"
                          filled
                          hide-details
                          no-resize
                          placeholder="أدخل تعليقك هنا"
                          rows="1"
                          v-model="commentContent"
                        >
                          <template v-slot:append>
                            <img
                              @click="addComment"
                              alt="send text"
                              class="px-3 pointerCursor"
                              src="@/assets/img/svgs/send.svg"
                              loading="lazy"
                            />
                          </template>
                        </v-textarea>
                      </v-form>
                    </v-sheet>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-slide-x-transition>
    <!-- modals -->
    <AddDue
      :dialog-state="addDueModal"
      @closeDialog="addDueModal = false"
      :assignment="item"
      :isClaimRentType="isClaimRentType"
      @addDue="addDueCollection"
    />
    <DeletDue
      :dialog-state="DeletDueModal"
      @closeDialog="DeletDueModal = false"
      @agreeOrCancel="agreeToDelete"
    />
    <DiscountDeduct
      v-bind="discountDeductProps"
      @closeDialog="DiscountDeductModal = false"
      @edit-due-value="rentValueUpdate"
    />
    <ModFixedAmountModal
      v-bind="modFixedAmountProps"
      @closeDialog="fixedAmountStatus = false"
      @update-fixed-amount="updateFixedAmount"
    />
    <EditTax
      v-bind="editTaxProps"
      @closeDialog="EditTaxModal = false"
      @tax-update="taxUpdate"
    />
    <EditInstallmentDate
      :dialog-state="EditInstallmentDateModal"
      :currentDuedate="item.date"
      @closeDialog="EditInstallmentDateModal = false"
      @date-update="dateUpdate"
    />
    <!--Cancel bond Modal-->
    <cancelBondModel
      v-if="CancelBondDialogState"
      :dialogState="CancelBondDialogState"
      :cancelBtnLoading="cancelBtnLoading"
      :relatedBonds="relatedBonds"
      @deleteBond="agreeToDelete"
      @changeDialogState="CancelBondDialogState = $event"
    />
  </div>
</template>

<script>
import Loader from '@/components/helper/loader.vue'
import confirmationMixin from '@/mixins/confirmationMixin.js'
import dateFormat from '@/mixins/dateFormat.js'
import sanitizers from '@/mixins/sanitizers.js'
import {
  bondsService,
  contractsService,
  duesService,
  messageService
} from '@/services'
import { mapMutations, mapState } from 'vuex'
import CommentCard from '../contracts/CommentCard.vue'
import AddDue from '../modals/dues/AddDue.vue'
import DeletDue from '../modals/dues/DeletDue.vue'
import DiscountDeduct from '../modals/dues/DiscountDeduct.vue'
import EditInstallmentDate from '../modals/dues/EditInstallmentDate.vue'
import EditTax from '../modals/dues/EditTax.vue'
import ModFixedAmountModal from '../modals/ModFixedAmountModal.vue'

export default {
  name: 'sideDuesDetailes',
  mixins: [sanitizers, dateFormat, confirmationMixin],
  components: {
    CommentCard,
    AddDue,
    DeletDue,
    DiscountDeduct,
    ModFixedAmountModal,
    EditInstallmentDate,
    EditTax,
    Loader,
    CancelBondModel: () => import('@/components/modals/cancelBondModel.vue')
  },
  props: {
    isSideDetailsLoading: {
      type: Boolean,
      default: false
    },
    assignmentTypes: {
      type: Array,
      default() {
        return []
      }
    },
    nonEditableDuesStatus: {
      type: Array,
      default() {
        return []
      }
    },
    assignmentId: {
      type: String,
      required: true,
      default: ''
    },
    assignmentStatus: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      taxTypeKey: 'tax',
      paymentTypeKey: '',
      isLoading: false,
      headerData: [
        {
          key: 'installmentType',
          data: 'نوع المطالبة',
          className: 'brub--tag tabel-tag'
        },
        {
          key: 'number',
          data: 'رقم المطالبة'
        },
        {
          key: 'user.name',
          data: 'إسم العميل'
        }
      ],
      cancelCross: require('@/assets/img/svgs/cancelCross.svg'),
      commentsAreLoading: false,
      statusModel: false,
      activeComment: null,
      activeTextArea: null,
      comments: [],
      commentContent: '',
      noComments: require('@/assets/img/svgs/noComments.svg'),
      tab: 0,
      componentTab: 0,
      duesAttachments: [],
      exitArrow: require('@/assets/img/svgs/right-arrow.svg'),
      addDueModal: false,
      DeletDueModal: false,
      DiscountDeductModal: false,
      fixedAmountStatus: false,
      EditInstallmentDateModal: false,
      EditTaxModal: false,
      bondModal: false,
      item: {},
      assignments: [],
      logs: [],
      relatedBonds: [],
      cancelBtnLoading: false,
      CancelBondDialogState: false
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    hasAddPermission() {
      return !!this.$hasPermission('properties', 'bonds', 'add')
    },
    detailsTabs() {
      return [
        {
          title: 'تفاصيل المطالبة',
          component: () => import('./DuesDetails.vue')
        },
        {
          title: 'التحصيل',
          component: () => import('./DuesClaims.vue')
        },
        {
          title: 'السندات',
          component: () => import('./DuesBonds.vue')
        },
        {
          title: 'سجل النشاطات',
          component: () => import('./DuesLogs.vue')
        },
        {
          title: 'المرفقات',
          component: () => import('./DuesAttachments.vue')
        },
        {
          title: 'الفواتير الضريبية',
          component: () => import('./DuesTaxInvoice.vue')
        }
      ]
    },
    editTaxProps() {
      return {
        dialogState: this.EditTaxModal,
        DueTax: this.item[`${this.taxTypeKey}`]
      }
    },
    discountDeductProps() {
      return {
        dialogState: this.DiscountDeductModal,
        item: this.isCombined
          ? {
              _id: 1,
              taxAmount:
                this.paymentTypeKey === 'rent'
                  ? this.item.details.rentTaxAmount
                  : this.item.details.utilitiesTaxAmount,
              value: this.item.details[this.paymentTypeKey] || 0,
              remaining: this.item.remaining,
              tax:
                this.paymentTypeKey === 'rent'
                  ? this.item.tax
                  : this.item.utilitiesTax
            }
          : {
              _id: this.item._id,
              value: this.item.payment,
              tax: this.item.tax,
              taxAmount: this.item.taxAmount,
              remaining: this.item.remaining
            }
      }
    },
    modFixedAmountProps() {
      return {
        dialogState: this.fixedAmountStatus,
        data: { ...this.item?.details, utilitiesTax: this.item?.utilitiesTax }
      }
    },
    isCombined() {
      return !!this.item?.isCombined
    },
    isClaimRentType() {
      return this.item?.installmentType === 'إيجار' || this.isCombined
    },
    loaderProps() {
      return {
        numberOfLines: 8,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    isValidComment: () => (commentContent) => {
      return !!commentContent.length
    },
    getBreakPoint() {
      return this.$vuetify.breakpoint || {}
    },
    isNonEditable() {
      return !this.nonEditableDuesStatus.includes(this.item?.paymentStatus)
    }
  },
  watch: {
    assignmentId(id) {
      this.getSingleDue(id)
    },
    item: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal?._id && oldVal?._id !== newVal?._id) {
          this.tab = 0
          this.loadComments()
          this.getLogs()
          if (this.item?._id) {
            this.getContractAttachments()
          }
        }
      }
    }
  },
  beforeDestroy() {
    localStorage.removeItem('sideDetailsItem')
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),

    hasDuePermission(action) {
      return !!this.$hasPermission('properties', 'assignments', action)
    },
    changeDueModal(val) {
      this.addDueModal = val
    },
    updateFixedAmount(data) {
      this.$emit('update-fixed-amount', {
        _id: this.assignmentId,
        details: { ...data }
      })
    },
    async getRelatedBonds({ _id }, collectionId) {
      try {
        const { data } = await bondsService.getRelatedBonds(_id)
        this.relatedBonds = data.bonds ?? []
        if (!this.relatedBonds.length) {
          this.showConfirmDeletionButton(collectionId)
        } else {
          this.CancelBondDialogState = true
        }
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل السندات المرتبطة بهذا السند',
          color: 'error'
        })
      }
    },

    changeEditInstallmentDateModal(val) {
      this.EditInstallmentDateModal = val
    },
    changeDiscountDeductModal({ type, status }) {
      if (type === 'rent') {
        this.DiscountDeductModal = status
      }
      if (type === 'utilitiesTotal') {
        this.fixedAmountStatus = status
      }

      this.paymentTypeKey = type
    },
    changeTaxTypeKey(val) {
      this.taxTypeKey = val
    },
    openDiscountDeductModal(val = 'rent') {
      if (!this.hasDuePermission('update')) {
        this.addNotification({
          text: 'ليس لديك صلاحيه لتعديل المطالبه',
          color: 'error'
        })
        return
      }
      this.DiscountDeductModal = true
      this.paymentTypeKey = val
    },
    taxUpdate(tax) {
      this.$emit('due-update', {
        _id: this.assignmentId,
        [this.taxTypeKey]: tax
      })
      this.changeEditStatus(false)
      this.taxTypeKey = ''
    },
    changeEditStatus(val) {
      this.EditTaxModal = val
    },
    rentValueUpdate(newValue) {
      const key = this.isCombined ? this.paymentTypeKey : 'payment'
      this.$emit('due-update', { _id: this.assignmentId, [key]: newValue })
      this.DiscountDeductModal = false
      this.paymentTypeKey = ''
    },
    dateUpdate(date) {
      this.$emit('due-update', { _id: this.assignmentId, date })
      this.EditInstallmentDateModal = false
    },
    generateCommentProps(comment) {
      return {
        comment,
        commentId: this.activeComment,
        activeTextArea: this.activeTextArea
      }
    },
    async loadComments() {
      try {
        this.commentsAreLoading = true
        const query = {
          entityId: this.item._id
        }
        const { data } = await messageService.getComments(query)
        this.comments = data.items
        this.commentContent = ''
      } catch {
        this.addNotification({
          text: 'خطا في تحميل تعليقات العقد',
          color: 'error'
        })
      } finally {
        this.commentsAreLoading = false
      }
    },
    async addComment() {
      if (this.isValidComment(this.commentContent)) {
        const commentToSend = {
          content: this.commentContent,
          entityType: 'assignment',
          entityId: this.item._id,
          author: {
            _id: this.user._id,
            name: this.user.name,
            email: this.user.email,
            phoneNumber: this.user.phoneNumber,
            type: this.user.type
          },
          context: {
            platform: 'hectarPlus'
          }
        }
        this.commentContent = ''
        await messageService.addComment(commentToSend)
        this.loadComments()
      }
    },
    async deleteComment(commentId, commentIndex) {
      await messageService.deleteComment(commentId).then(() => {
        this.comments.splice(commentIndex, 1)
      })
    },
    updateComment(newComment, commentIndex) {
      if (this.isValidComment(newComment.content)) {
        const updatedCommentToSend = {
          content: newComment.content,
          _id: newComment.commentId
        }
        messageService.updateComment(updatedCommentToSend).then(() => {
          this.$set(this.comments[commentIndex], 'content', newComment.content)
        })
      }
      this.activeTextArea = null
    },
    setActiveComment(commentId) {
      this.activeComment = commentId
    },
    setActiveTextArea(commentId) {
      this.activeTextArea = commentId
    },
    deleteDue() {
      if (!this.hasDuePermission('delete')) {
        this.addNotification({
          text: 'ليس لديك صلاحيه لإلغاء المطالبه',
          color: 'error'
        })
        return
      }
      this.DeletDueModal = true
    },
    resetSingleDue() {
      this.item = Object.assign({})
    },
    closeDetailsPage(presistDue = {}) {
      this.$emit('close-side-details', presistDue)
    },
    async agreeToDelete() {
      this.cancelBtnLoading = true
      try {
        await duesService.deleteDue({
          assignment: this.item._id,
          environment: this.currentEnv._id
        })
        this.addNotification({
          text: ' تم حذف  بيانات المطالبه بشكل صحيح',
          color: 'success'
        })
        this.$emit('refreshDues')
        this.closeDetailsPage()
        this.resetSingleDue()
      } catch {
        this.addNotification({
          text: 'لم يتم حذف  بيانات المطالبه بشكل صحيح',
          color: 'error'
        })
      } finally {
        this.DeletDueModal = false
        this.CancelBondDialogState = false
        this.relatedBonds = []
        this.cancelBtnLoading = false
      }
    },
    async agreeToDeleteInstallment({ _id, bond }) {
      if (!this.hasDuePermission('delete')) {
        this.addNotification({
          text: 'ليس لديك صلاحيه لإلغاء عمليه التحصيل',
          color: 'error'
        })
        return
      }
      this.$emit('toggleSideDetails')
      try {
        await this.getRelatedBonds(bond, _id)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل السندات المرتبطه بعمليه التحصيل',
          color: 'error'
        })
      }
    },
    async getContractAttachments() {
      try {
        const { data } = await contractsService.getAllAttachments(this.item._id)
        this.duesAttachments = data.attachments
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل مرفقات العقد',
          color: 'error'
        })
      }
    },
    async uploadDuesAttachment(attachment) {
      try {
        const reader = new FileReader()
        reader.onload = async () => {
          await this.onUpload(attachment)
        }
        reader.readAsDataURL(attachment.files)
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    async onUpload(file) {
      try {
        this.isLoading = true
        const fd = new FormData()
        fd.append('entityId', this.item._id)
        fd.append('envId', this.currentEnv._id)
        fd.append('entityType', 'assignment')
        fd.append('attachment', file.files)
        fd.append('type', file.type)
        fd.append('number', file.number)
        await contractsService.addAttachment(fd)
        this.addNotification({
          text: 'تم إضافه المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      } finally {
        this.getContractAttachments()
        this.isLoading = false
      }
    },
    async deleteDuesDocument({ _id, fileIndex }) {
      try {
        await contractsService.deleteAttachment(_id)
        this.duesAttachments.splice(fileIndex, 1)
        this.addNotification({
          text: 'تم حذف المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في حذف المرفق، يرجى المحاولة مجددا',
          color: 'error'
        })
      } finally {
        this.showSideDetails()
      }
    },
    showConfirmDeletionButton(collectionId) {
      this.$root.$confirm
        .open({
          title: `حذف بيانات`,
          message: `هل أنت متأكد من حذف تفاصيل التحصيل`,
          options: {
            color: 'red'
          }
        })
        .then(async (confirm) => {
          if (confirm) {
            try {
              await duesService.deleteInstallment({
                collection: collectionId,
                environment: this.currentEnv._id
              })
              this.addNotification({
                text: ' تم حذف بيانات عمليه التحصيل بشكل صحيح',
                color: 'success'
              })
              this.getSingleDue(this.item?._id)
              this.$emit('toggleSideDetails')
            } catch {
              this.addNotification({
                text: 'لم يتم حذف بيانات عمليه التحصيل بشكل صحيح',
                color: 'error'
              })
            }
          }
        })
    },
    async getSingleDue(id) {
      if (!id) {
        this.$emit('setSideDetailsLoading')
        return
      }
      try {
        const {
          data: { details }
        } = await duesService.getSingleDue(id)
        this.item = details.installment
        this.assignments = details.assignments
      } catch {
        this.addNotification({
          text: 'لم يتم تحميل بيانات المطالبه بشكل صحيح',
          color: 'error'
        })
      } finally {
        this.$emit('setSideDetailsLoading')
      }
    },
    async getLogs() {
      try {
        const log = {
          entityId: this.item._id,
          environment: this.currentEnv._id
        }
        const {
          data: { items }
        } = await duesService.getlogs(log)
        this.logs = items
      } catch {
        this.addNotification({
          text: 'لم يتم تحميل   سجل النشاطات بشكل صحيح',
          color: 'error'
        })
      }
    },
    setInstallmentStatus(assignmentStatus, item) {
      this.$emit('setInstallmentStatus', { ...item, assignmentStatus })
    },
    async addDueCollection(data) {
      try {
        const { data: res } = await duesService.addDueCollection(data)
        this.addNotification({
          text: 'تم اضافه عمليه التحصيل بشكل صحيح',
          color: 'success'
        })
        this.addDueModal = false
        if (res.collection.needsApproval) {
          this.showConfirmationPopupMixin()
        }

        // Due to an issue in the time of updating the due data we have to dely those next apis
        setTimeout(() => {
          this.getSingleDue(this.assignmentId)
          this.$emit('refreshDues')
        }, 200)
      } catch {
        this.addNotification({
          text: 'لم يتم اضافه عمليه التحصيل بشكل صحيح',
          color: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_sideDetails.scss';
.whiteSide {
  min-width: clamp(90%, 70vw, 80%) !important;
}
.setting__page__tabs {
  ::v-deep {
    .v-tab--active {
      font-weight: 500 !important;
      color: #101010 !important;
    }
  }
}
.details-container {
  border-left: 1px solid #e0e0e0 !important;
}
.tabs-container {
  border-bottom: 1px solid #efebf2;
  ::v-deep {
    .v-slide-group__content {
      justify-content: space-between;
    }
  }
}
.border__card {
  border: 1px solid #e8e8e8;
}

button#assigmnet-header {
  flex-direction: row-reverse !important;
  i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
    margin-left: 30px !important;
  }
}
</style>
