<template>
  <v-layout
    align-center
    justify-space-between
    class="flex-wrap sidedetail__header"
  >
    <v-layout align-center flex-wrap v-if="bond">
      <v-img
        data-test="close-details"
        :lazy-src="exitArrow"
        :src="exitArrow"
        @click="closeSideDetails"
        max-height="15"
        max-width="15"
      ></v-img>
      <span class="font-16 font-weight-bold mx-3">
        {{ bond.bondAddress }}
      </span>
      <h5
        class="font-weight-medium font-16 font-weight-bold text-truncate max-width-250 mobile__m__0 white__space__normal"
      >
        {{ bond.title }}
      </h5>
      <h5
        class="ma-0 mx-5 brub--tag tag-sm px-1 text-center py-0 pb-1 mobile__ml__10px"
      >
        <span class="font-12 font-weight-bold my-auto text-truncate">{{
          bond.bondType.nameAr
        }}</span>
      </h5>
      <h5 class="ma-0 font-weight-medium line__height__fixes">
        <div
          class="d-flex justify-space-between align-center"
          v-if="terminated"
        >
          <div class="d-flex flex-column">
            <div
              class="px-5 mb-1 red--tag tabel-tag text-center font-12 font-weight-bold d-flex align-center"
            >
              <v-sheet class="dotted ma-0 pa-0 ml-2" color="#EB4545"></v-sheet>
              <h6 class="ma-0 pb-1 font-12 font-weight-bold text-truncate px-2">
                ملغي
              </h6>
            </div>
          </div>
        </div>
        <div v-else class="d-flex justify-space-between align-center">
          <div class="d-flex flex-column">
            <div
              class="px-5 mb-1 green--tag tabel-tag d-flex align-center px-4"
            >
              <v-sheet class="dotted ma-0 pa-0 ml-2" color="#65C467"></v-sheet>
              <h6 class="ma-0 pb-1 font-12 font-weight-bold text-truncate px-2">
                ساري
              </h6>
            </div>
          </div>
        </div>
      </h5>
    </v-layout>

    <v-layout
      justify-end
      class="mobile__justify__start"
      v-if="!terminated && needAnApprovalAndNotApproved"
    >
      <v-btn
        data-test="open-pdf"
        max-width="120"
        class="actions px-8 ml-2"
        color="#662D91"
        @click="exportPdfContract"
      >
        <v-img
          class="mt-1 ml-1"
          max-height="16"
          max-width="16"
          :src="pdfIcon"
        ></v-img>
        <span class="font-12 font-weight-bold"> بي دي اف </span>
      </v-btn>

      <v-btn
        data-test="share-pdf"
        max-width="120"
        class="actions px-8 ml-2"
        color="#12CAD6"
        @click="$emit('sharePdf')"
      >
        <v-img
          class="mt-1 ml-1"
          max-height="16"
          max-width="16"
          :src="shareIcon"
        ></v-img>
        <span class="font-12 font-weight-bold"> مشاركة </span>
      </v-btn>
      <v-btn
        data-test="terminate-bond"
        max-width="120"
        color="#EB4545"
        class="actions cancel px-8"
        @click="$emit('bondDel')"
        v-if="$hasPermission('properties', 'bonds', 'delete')"
      >
        <v-icon size="20" color="white">mdi-close</v-icon>
        <span class="font-12" style="color: white"> إلغاء السند </span>
      </v-btn>
    </v-layout>
  </v-layout>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import { mapMutations } from 'vuex'

export default {
  name: 'side-detailes-header',
  props: {
    bond: {},
    terminated: {}
  },
  mixins: [dateFormat],
  data() {
    return {
      cancelIcon: require('@/assets/img/svgs/cancel.svg'),
      shareIcon: require('@/assets/img/svgs/white-share-pdf.svg'),
      exitArrow: require('@/assets/img/svgs/right-arrow.svg'),
      pdfIcon: require('@/assets/img/svgs/pdf-white.svg'),
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أكتوبر',
        'نوفمبر',
        'ديسمبر'
      ],
      backToPreviousPath: false
    }
  },
  created() {
    this.backToPreviousPath = !!this.$route.query?.backToCurrentPath
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    exportPdfContract() {
      this.$emit('exportPdfContract')
    },
    closeSideDetails() {
      if (this.backToPreviousPath) {
        this.$router.back()
      }
      this.$emit('closeDetails')
    }
  },
  computed: {
    needAnApprovalAndNotApproved() {
      return !this.bond?.needsApproval
    },
    bondCode() {
      return this.bond?.number ?? ''
    },
    bondCreatorName() {
      return this.bond?.createdBy?.name ?? ''
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly
    }
  }
}
</script>
