import config from '../config'
import axios from 'axios'
import store from '../store/modules/account.state'

const currentEnv = store.state.currentEnv

//  get All Threads for a spacific user
const getAllThreads = function (userId, context, query = '') {
  const url = `${config.messagesUrl}/messages/getThreads?user=${userId}&context=${context}&${query}`
  return axios.get(url)
}

//  get Messages for a single thread
const getMessages = function (threadId) {
  const url = `${config.messagesUrl}/messages?thread=${threadId}`
  return axios.get(url)
}

//  post a new message
const addNewMessage = function (data) {
  const url = `${config.messagesUrl}/messages`
  return axios.post(url, data)
}

//  set Messages as seen
const setMessageSeen = function (threadId) {
  const url = `${config.messagesUrl}/messages/set-as-seen/${threadId}`
  return axios.put(url)
}

//  delete single thread
const deleteThread = function (threadId) {
  const url = `${config.messagesUrl}/messages/delete-thread/${threadId}`
  return axios.delete(url)
}

//  post a new message
const addMessage = function (data) {
  const url = `${config.messagesUrl}/messages/add`
  return axios.post(url, data)
}

//  Add Comment
const addComment = function (data) {
  const url = `${config.messagesUrl}/comments`
  return axios.post(url, data)
}

//  get Comments
const getComments = function (query) {
  const url = `${config.messagesUrl}/comments/filter`
  return axios.post(url, query)
}

//  delete comment
const deleteComment = function (commentId) {
  const url = `${config.messagesUrl}/comments/${commentId}`
  return axios.delete(url)
}

//  update comment
const updateComment = function (comment) {
  const url = `${config.messagesUrl}/comments/${comment._id}`
  return axios.put(url, comment)
}

//  get activity logs
const getActivityLogs = function (data) {
  const url = `${config.messagesUrl}/logs/filter`
  return axios.post(url, data)
}

// get notifications
const getNotifications = function (data) {
  const url = `${config.messagesUrl}/notification/settings/get`
  return axios.post(url, data)
}

// update notifications
const updateNotifications = function (notificationId, data) {
  const url = `${config.messagesUrl}/notification/settings/${notificationId}`
  return axios.put(url, data)
}

// get sms messages for a client
const getSmsMessages = function (query) {
  const url = `${config.messagesUrl}/smslogs?${query}`
  return axios.get(url)
}

//  send-message-to-client
const sendMessageToClient = function (payload) {
  const url = `${config.usersUrl}/clients/send-message-to-client`
  axios.post(url, payload)
}
//  get all client sms messages
const getClientSmsTexts = function (query) {
  const url = `${config.messagesUrl}/sentNotifications?${query}`
  return axios.get(url)
}

const resendSmsMessage = function (body) {
  const url = `${config.messagesUrl}/sentNotifications/resend`
  return axios.post(url, body)
}

const realestateNotificationStatus = function (realEstateId) {
  const url = `${config.messagesUrl}/muteNotifications/${currentEnv?._id}?realEstate=${realEstateId}`
  return axios.get(url)
}

const PutRealestateNotificationStatus = function (body) {
  const url = `${config.messagesUrl}/muteNotifications`
  return axios.put(url, { ...body, environment: currentEnv?._id })
}

const getNotificationTypes = function () {
  const url = `${config.messagesUrl}/notification/settings/types`
  return axios.get(url)
}

const updateConfigration = function () {
  const url = `${config.messagesUrl}/broadcast-update-event`
  return axios.get(url)
}

export const messageService = {
  getAllThreads,
  updateConfigration,
  getMessages,
  addNewMessage,
  setMessageSeen,
  deleteThread,
  addMessage,
  addComment,
  getComments,
  deleteComment,
  updateComment,
  getActivityLogs,
  getNotifications,
  updateNotifications,
  getSmsMessages,
  resendSmsMessage,
  sendMessageToClient,
  getClientSmsTexts,
  realestateNotificationStatus,
  PutRealestateNotificationStatus,
  getNotificationTypes
}
