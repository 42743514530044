<template>
  <div>
    <v-card-text class="pa-0">
      <v-container class="pa-0">
        <dropdown-alt
          :configrations="{ name: 'بيانات مالك العقار المكلف ', cols: 6 }"
          :listingType="clientType"
          :validate="validate"
          @filterTypes="switchOwnerState($event)"
          class="mt-3"
          defaultValue="عميل مسجل ومضاف"
        />
        <v-col v-if="!isNewOwner" class="pb-1 mobile__p__0" cols="12" sm="12">
          <h5
            :class="{ 'red--flag': validateForm ? !isAddedOwnerValid : false }"
            class="mb-2 font-14"
          >
            اختر العميل*
          </h5>
          <autocomplete
            :itemData="true"
            :items="AllUsers"
            :noData="noData"
            :placeholder="selectedRelestatePlaceholder"
            :validateString="validateString"
            :returnObject="true"
            @nextPageTrigger="getNextData"
            @emitSearchKey="getDataBySearchKey"
            v-model="addTaxInfo.owner"
            :loading="clientIsLoading"
            @input="checkClientData"
          />

          <v-form v-if="addTaxInfo.owner && isTaxOrIdMissing">
            <v-row>
              <v-col class="pl-3" cols="12" sm="6">
                <h6
                  :class="[
                    'mb-2',
                    'font-12',
                    { 'red--flag': !addTaxInfo.owner.taxNumber }
                  ]"
                >
                  الرقم الضريبي للمالك*
                </h6>
                <v-text-field
                  placeholder="الرقم الضريبي"
                  dense
                  filled
                  hide-details
                  min="0"
                  @keydown="$formatNumberInputs"
                  v-model="localTaxNumber"
                  @input="formatArabicNumber($event, 'taxNumber')"
                ></v-text-field>
              </v-col>
              <v-col class="pl-3" cols="12" sm="6" v-if="typeCheck">
                <h6
                  :class="[
                    'mb-2',
                    'font-12',
                    { 'red--flag': !addTaxInfo.owner.idNumber }
                  ]"
                >
                  {{ typeCheck }}*
                </h6>
                <v-text-field
                  :placeholder="typeCheck"
                  dense
                  filled
                  hide-details
                  min="0"
                  @keydown="$formatNumberInputs"
                  v-model="localIdNumber"
                  @input="formatArabicNumber($event, 'idNumber')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-row v-if="isNewOwner">
          <v-col>
            <NewAgentForm
              :index="0"
              :isAqarForm="true"
              :isDisabled="false"
              :phoneNumberSuccessMesage="phoneNumberSuccessMesage"
              :phoneNumberErrorMessage="phoneNumberErrorMessage"
              :checkPhoneNumberLoader="checkPhoneNumberLoader"
              :isValidPhone="isValidPhone"
              :isOptionalInfoRequired="true"
              @checkUserExistance="checkUserExistance"
              @onValidatePhoneNumber="onValidatePhoneNumber"
              @new_user_added="newUserAdded"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="pa-1 mt-4 d-flex align-center justify-space-between">
      <v-btn
        @click="previousTab"
        class="ma-0 rounded-7 my-3 ml-3"
        color="primary"
      >
        <v-icon class="send-icon font-16">mdi-arrow-left</v-icon>
        <span class="mobile__mb__0 ml-1 font-weight-bold font-12">
          السابق
        </span>
      </v-btn>

      <v-btn
        :loading="isUpdateLoading"
        @click="nextTab"
        class="ma-0 rounded-7 my-3 ml-3"
        color="primary"
      >
        <span class="mobile__mb__0 ml-1 font-weight-bold font-12">
          التالي
        </span>
        <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import DropdownAlt from '../../add-unit/dropdownAlt'
import { EnvService } from '../../../services'
import autocomplete from '@/components/forms/autocomplete'
import NewAgentForm from '@/components/common/new-agent/NewAgentForm'
import checkClientData from '@/mixins/checkClientData.js'
import { mapState } from 'vuex'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  name: 'owner-step',
  mixins: [checkClientData],
  components: { DropdownAlt, autocomplete, NewAgentForm },
  props: {
    clientType: {},
    setBuildingDetails: {},
    validate: {},
    resetValidation: {
      default: false
    },
    taxToBeEditValid: {},
    taxToBeEdit: { default: {} }
  },
  data() {
    return {
      selectedRelestatePlaceholder: 'اختر العميل',
      noData: 'لا يوجد عملاء متاحه لهذا البحث',
      validateString: 'من فضلك ادخل اسم العميل',
      selectRules: [(v) => !!v || 'هذا الحقل مطلوب'],
      users: [],
      AllUsers: [],
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      localTaxNumber: '',
      localIdNumber: '',
      searchKey: '',
      addTaxInfo: {},
      otherInfo: {},
      ownerState: 'addedOwner',
      newOwner: {},
      isValid: false,
      validateForm: false,
      newOwnerValid: false,
      clientIsLoading: false,
      phoneNumberSuccessMesage: '',
      phoneNumberErrorMessage: '',
      checkPhoneNumberLoader: false,
      phoneData: {}
    }
  },
  watch: {
    resetValidation: {
      handler() {
        this.addTaxInfo = {}
        this.newOwner = {}
        this.isValid = false
        this.validateForm = false
      }
    },
    taxToBeEdit: {
      immediate: true,
      handler() {
        this.addTaxInfo = { ...this.taxToBeEdit }
        if (this.taxToBeEditValid) {
          this.isValid = true
        }
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    isValidPhone() {
      return this.phoneData.isValid ?? false
    },
    isOwnerSelected() {
      return this.addTaxInfo?.owner
    },
    selectedOwner() {
      return this.addTaxInfo.owner?.name
    },
    filterOwner() {
      return this.users.filter((user) => user.name.match(this.searchClient))
    },
    isNewOwner() {
      return this.ownerState === 'newOwner'
    },
    isAddedOwnerValid() {
      return (
        !!this.addTaxInfo?.owner &&
        this.addTaxInfo?.owner?.idNumber &&
        this.addTaxInfo?.owner?.taxNumber
      )
    },
    isTabValid() {
      return this.isAddedOwnerValid || this.newOwnerValid
    },
    commercialNumberValidation() {
      return this.validateForm ? !this.$refs?.commercial?.valid : false
    },
    taxNumberValidation() {
      return this.validateForm ? !this.$refs?.taxNumber?.valid : false
    },
    taxValidation() {
      return this.validateForm ? !this.$refs?.tax?.valid : false
    },
    nameValidation() {
      return this.validateForm ? !this.$refs?.name?.valid : false
    }
  },
  methods: {
    setDefaultData() {
      if (
        this.taxToBeEdit &&
        Object.keys(this.taxToBeEdit).length &&
        this.taxToBeEdit.hasOwnProperty('owner')
      ) {
        this.AllUsers.push(this.taxToBeEdit?.owner)
      }
    },
    formatArabicNumber(val, key) {
      if (key === 'idNumber') {
        this.localIdNumber = convertNumbers2English(val)
        this.addTaxInfo.owner.idNumber = this.localIdNumber
      } else {
        this.localTaxNumber = convertNumbers2English(val)
        this.addTaxInfo.owner.taxNumber = this.localTaxNumber
      }
    },
    checkClientDataIfExists() {
      this.checkClientData(this.addTaxInfo.owner)
    },
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    checkUserExistance(phoneNumber) {
      phoneNumber = phoneNumber.replace(/ /g, '')
      if (phoneNumber.length !== 10) {
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = 'من فضلك تأكد من رقم الهاتف'
      } else if (this.isValidPhone) {
        this.checkPhoneNumberLoader = true
        this.$emit('createContractBtnLoading', this.checkPhoneNumberLoader)
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = ''
        phoneNumber = phoneNumber.replace('0', '966')

        const body = {
          environment: this.currentEnv._id,
          phoneNumber
        }

        EnvService.checkClient(body)
          .then((response) => {
            if (response.data.available) {
              this.phoneNumberSuccessMesage = 'العميل متاح للإضافه'
              this.phoneNumberErrorMessage = null
            }
          })
          .catch(() => {
            this.phoneNumberErrorMessage = ' الرقم مُسجل بإسم عميل أخر'
          })
          .finally(() => {
            this.checkPhoneNumberLoader = false
            this.$emit('createContractBtnLoading', this.checkPhoneNumberLoader)
          })
      }
    },
    newUserAdded(val) {
      this.newOwnerValid = val.isValid
      this.newOwner = val.obj
    },
    getDataBySearchKey(e) {
      this.searchKey = e
      if (this.searchKey) {
        this.loadClients()
        this.clientIsLoading = true
      } else {
        this.clientIsLoading = false
      }
    },
    getNextData() {
      if (this.pagination.pageNumber < this.pagination.pagesCount) {
        this.pagination.pageNumber += 1
        this.clientIsLoading = true
        this.loadClients()
      } else {
      }
    },
    setOwnerData(e) {
      this.$set(this.addTaxInfo, 'owner', e)
      this.isValid = true
      this.validateForm = false
    },
    menuStateSwitcher(menu) {
      return menu?.isActive
    },
    // load client
    async loadClients(query) {
      let data = 'role=owner&'
      if (this.searchKey) {
        data += `&search=${this.searchKey}`
      } else {
        data += `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
      }
      try {
        const res = await EnvService.getAllClients(
          this.currentEnv._id,
          query,
          data
        )
        if (res?.data && res?.data?.users) {
          this.pagination.pagesCount = Math.ceil(
            res?.data?.count / this.pagination.pageSize
          )
          this.users = res?.data?.users ?? []
          this.pagination.count = res?.data?.count ?? 0
          this.AllUsers = [...this.AllUsers, ...this.users]

          // For get idNumber and taxNumber
          // This becouse owner object in realEstate not includes idNumber and taxNumber
          const { idNumber, taxNumber } = this.AllUsers.find(
            (user) => user._id === this.taxToBeEdit.owner._id
          )
          // eslint-disable-next-line vue/no-mutating-props
          this.taxToBeEdit.owner = Object.assign(this.taxToBeEdit.owner, {
            idNumber,
            taxNumber
          })
        }
      } catch {
      } finally {
        this.clientIsLoading = false
      }
    },
    switchOwnerState(e) {
      this.validateForm = false
      this.ownerState = e.code
      if (this.isNewOwner) {
        this.addTaxInfo = {}
        this.isValid = false
      } else {
        this.newOwner = {}
      }
    },
    async nextTab() {
      this.validateForm = true
      if (this.isTabValid) {
        if (this.isNewOwner) {
          this.$emit('addUser', { newUser: this.newOwner, role: 'owner' })
          this.addTaxInfo.owner = this.newOwner
        } else if (this.isTaxOrIdMissing) {
          this.$emit('addUser', {})
          const payload = {
            taxNumber: Number(this.addTaxInfo.owner.taxNumber),
            idNumber: this.addTaxInfo.owner.idNumber
          }
          await this.updateClient(payload, this.addTaxInfo.owner._id)
          if (!this.isUpdatingClientSucceded) {
            return
          }
        }

        this.$emit('setAddTaxInfo', this.addTaxInfo)
        this.$emit('nextTab')
      } else {
        this.$emit('addNotification')
      }
    },
    previousTab() {
      this.$emit('previousTab')
    }
  },
  created() {
    this.loadClients()
  }
}
</script>
