<template>
  <div>
    <v-card class="px-5 py-6">
      <v-form ref="form" v-model="isValid">
        <v-row no-gutters>
          <v-col class="pl-3 mobile__pl__0" cols="12" md="6">
            <h6 class="mb-2 font-12">كلمه المرور الحاليه*</h6>
            <v-text-field
              data-test="currentPassword"
              ref="currentPassword"
              filled
              :rules="[
                () =>
                  (!!typedCurrentPassword &&
                    typedCurrentPassword.length >= 6) ||
                  'من فضلك ادخل كلمه المرور الحاليه'
              ]"
              v-model="typedCurrentPassword"
              placeholder="كلمه المرور الحاليه"
              required
              dense
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="toggleShowPassword"
              :type="showPassword ? 'text' : 'password'"
              class="setting__input__fields"
              @input="onInputPhoneNumber($event, 'typedCurrentPassword')"
            ></v-text-field>
          </v-col>
          <v-col class="pl-3 mobile__pl__0" cols="12" md="6">
            <h6 class="mb-2 font-12">كلمه المرور الجديده*</h6>
            <v-text-field
              data-test="newPassword"
              filled
              :rules="[
                () =>
                  (!!newPassword && newPassword.length >= 6) ||
                  'من فضلك ادخل كلمه المرور الجديده'
              ]"
              v-model="newPassword"
              placeholder="كلمه المرور الجديده"
              required
              dense
              :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="toggleShowNewPassword"
              :type="showNewPassword ? 'text' : 'password'"
              class="setting__input__fields"
              @input="onInputPhoneNumber($event, 'newPassword')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <v-layout justify-end class="mt-5">
      <v-btn
        data-test="updatePassword"
        v-on:click="passwordUpdate"
        color="primary"
        class="rounded-15 px-13 py-3"
      >
        <span class="font-16"> حفظ </span>
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { userService } from '@/services'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  name: 'password-reset',
  data() {
    return {
      isValid: false,
      typedCurrentPassword: '',
      newPassword: '',
      showPassword: false,
      showNewPassword: false
    }
  },
  computed: {
    ...mapState('accountState', ['user'])
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
    toggleShowNewPassword() {
      this.showNewPassword = !this.showNewPassword
    },
    passwordUpdate() {
      if (this.isValid) {
        if (this.typedCurrentPassword === this.newPassword) {
          this.addNotification({
            text: 'كلمه المرور الجديده مطابقه لكلمه المرور الحاليه التي ادخلتها',
            color: 'error'
          })
        } else {
          userService
            .checkPassword(
              this.user.accountId,
              this.typedCurrentPassword,
              this.newPassword
            )
            .then(
              () => {
                this.addNotification({
                  text: 'تم تغيير كلمه المرور بنجاح',
                  color: 'success'
                })
                this.showPassword = false
                this.showNewPassword = false
                this.$refs.form.reset()
              },
              () => {
                this.addNotification({
                  text: 'كلمه المرور التي أدخلتها غير صحيحه',
                  color: 'error'
                })
              }
            ) // Update user Data and save it
            .catch(() => {
              this.addNotification({
                text: 'لا يمكنك تغيير كلمه المرور في الوقت الحالي',
                color: 'error'
              })
            })
        }
      } else {
        this.addNotification({
          text: 'من فضلك تأكد من استكمال جميع الحقول بشكل صحيح',
          color: 'error'
        })
      }
    },
    onInputPhoneNumber(str, field) {
      this[field] = convertNumbers2English(str)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_env.scss';
</style>
