<template>
  <v-snackbar
    :color="snackbarColor"
    :multi-line="true"
    :timeout="snackbarTimeout"
    class="mt-8"
    left
    shaped
    top
    transition="slide-y-transition"
    v-model="snackbarState"
  >
    <span>{{ snackbarText }}</span>
    <v-btn @click="setSnackbar(false)" dark fab icon small>
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState('appState', [
      'snackbar',
      'snackbarText',
      'snackbarColor',
      'snackbarTimeout'
    ]),
    snackbarState: {
      get() {
        return this.snackbar
      },
      set(val) {
        this.setSnackbar(val)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['setSnackbar'])
  }
}
</script>

<style lang="scss">
.v-snack:not(.v-snack--absolute) {
  z-index: 10001 !important;
}
</style>
