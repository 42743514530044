import { convertNumbers2English } from '@/utils/formatters'
/**
 * Handles the changing of values based on the provided keys and optional maskWrapper.
 * Converts the input value to English numbers, sets the masked value in the context object,
 * and updates the actual value in the parent object.
 *
 * @param {any} value - The value to be processed and updated.
 * @param {object} keys - An object containing keys for accessing values in the context and parent objects.
 * @param {string} keys.maskValKey - The key to access the masked value in the context object.
 * @param {string} keys.parentKey - The key to access the parent object containing the actual value.
 * @param {string} keys.actualValKey - The key to access the actual value in the parent object.
 * @param {string} [maskWrapper=false] - Optional. The name of the maskWrapper object if the context is nested within it.
 * @returns {void}
 */

export default {
  methods: {
    handleValuesChanging(value, keys, maskWrapper = false) {
      const { maskValKey, parentKey, actualValKey } = keys
      const context = maskWrapper ? this[maskWrapper] : this

      // Convert Arabic numbers to English and set the masked value in the context
      const formattedValue = convertNumbers2English(value)
      context[maskValKey] ??= formattedValue

      // If the formatted value is empty, set the actual value in the parent object to 0
      if (!formattedValue?.toString().length) {
        this.$set(this[parentKey], actualValKey, 0)
        return
      }

      // Convert the formatted value to a numeric value and set it in the parent object
      const numericValue = Number(formattedValue.toString().replace(/,/g, ''))
      this.$set(this[parentKey], actualValKey, numericValue)

      // Format the numeric value with commas and set it as the masked value in the context
      context[maskValKey] = numericValue?.toLocaleString('en-US')
    }
  }
}
