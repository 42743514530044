<template>
  <v-card>
    <v-card-title class="mb-3">
      <!-- circular ----- Tilte -->
      <v-row
        class="d-flex align-center mb-3"
        :class="{ 'justify-center': xs, 'justify-start': !xs }"
      >
        <div
          class="align-center pt-3"
          :class="{ 'd-block': xs, 'd-flex ': !xs }"
        >
          <div class="d-flex align-center mx-auto">
            <div
              class="titleDot d-flex justify-center align-center ml-2"
              :style="`background-color:${progressData.tabs[0].rows?.[0].color};`"
            >
              <v-img
                :src="getIcons(progressData)"
                max-width="15"
                max-height="15"
              />
            </div>
            <h5
              class="ma-0 font-weight-regular font-14 text-truncate"
              :class="{ 'text-20': smAndUp }"
            >
              {{ progressData.title }}
            </h5>
          </div>
          <h4
            class="text-center ma-0 font-weight-bold font-14 text-truncate"
            :class="{ 'mr-6 mt-0': !xs, 'mr-0 mt-3': xs }"
          >
            {{ progressData.tabs[0].total }}
          </h4>
          <p v-if="progressData.leftTitle" class="mr-4 mt-4 font-12">
            {{ progressData.leftTitle }}
          </p>
        </div>
      </v-row>
      <!-- circular ----- Progress -->
      <v-row class="d-flex align-center">
        <v-col class="px-0 d-flex" lg="4" md="4" sm="4" :cols="xs ? '' : '4'">
          <DonutChart
            :data="chartData"
            :colors="chartColors"
            :width="chartWidth"
            :cutoutPercentage="90"
          />
        </v-col>
        <v-col class="px-0" lg="8" md="8" sm="8">
          <div class="d-flex justify-center flex-column">
            <div
              v-for="(indicator, i) in progressData.tabs[0].rows"
              :key="i"
              class="justify-between align-center mb-3 d-flex"
              :class="{ 'd-flex ': !md && !isAddModel }"
            >
              <!-- dots and names -->
              <div class="d-flex align-center">
                <div
                  class="subTitleDot ml-2"
                  :style="`background-color:${indicator.color};`"
                ></div>
                <h5 class="ma-0 h4 font-weight-regular text-truncate">
                  {{ indicator.field.name }}
                </h5>
              </div>
              <!-- values -->
              <h5
                v-if="
                  isAddModel || indicator.field.value.toString().length < 18
                "
                class="ma-0 font-14 font-weight-bold"
              >
                <span
                  :style="i === 0 ? `color:${indicator.color};` : 'color: #ddd'"
                >
                  {{ indicator.percentage }}
                </span>
                <span style="color: #ddd"> | </span> {{ indicator.field.value }}
              </h5>
              <v-tooltip
                content-class="v-tool"
                color="black"
                min-width="95"
                bottom
                v-else
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="cursor">
                    <h5
                      class="ma-0 font-weight-bold mr-6 text-truncate max-w-170 font-14"
                    >
                      ...{{ indicator.field.value.slice(0, 14) }} ريال
                    </h5>
                  </div>
                </template>
                <span class="tool--tip">
                  {{ indicator.field.value }}
                </span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>
import close from '@/assets/img/reports/close.svg'
import rightCheck from '@/assets/img/reports/right-check.svg'
import realestateWhite from '@/assets/img/reports/realestate-white.svg'
import DonutChart from './DonutChart.vue'
export default {
  name: 'progressCircularCard',
  components: { DonutChart },
  props: {
    isAddModel: {
      default: false
    },
    progressData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      close,
      rightCheck,
      realestateWhite,
      chartWidth: 150
    }
  },
  methods: {
    getIcons({ title }) {
      switch (title) {
        // case 'إجمالي الإيرادات':
        // case 'صافي المستحقات':
        //   return this.rightCheck
        case 'المتأخرات':
          return this.close
        // case 'الوحدات':
        //   return this.realestateWhite
        default:
          return this.rightCheck
      }
      // return this.close
    }
  },
  computed: {
    chartColors() {
      const hexColorPattern = /#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})\b/g
      const hexColors =
        this.progressData.tabs[0].progress?.match(hexColorPattern)
      if (!hexColors) {
        return []
      }
      return hexColors
    },
    chartData() {
      return this.progressData.tabs[0].rows?.map((row) =>
        Number(row.percentage.replace('%', ''))
      )
    },
    isArrears() {
      return this.progressData.title === 'المتأخرات'
    },
    smAndUp() {
      return this.$vuetify.breakpoint.smAndUp
    },
    xs() {
      return this.$vuetify.breakpoint.xs
    },
    md() {
      return this.$vuetify.breakpoint.md
    },
    lg() {
      return this.$vuetify.breakpoint.lg
    }
  }
}
</script>

<style lang="scss">
.v-progress-circular__overlay {
  stroke-linecap: round;
}
.opacity-half {
  opacity: 0.5;
}
@import '@/styles/report/progressCircularCard.scss';
</style>
