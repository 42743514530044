<template>
  <div id="informativeMenuTip" class="text-center">
    <v-menu
      ref="tipMenu"
      :close-on-content-click="menuStatus"
      content-class="tipMenu"
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <img
          @click="menuStatus = false"
          v-bind="attrs"
          v-on="on"
          class="cursor"
          :src="toolTipIcon"
          width="80"
          loading="lazy"
        />
      </template>
      <div class="d-flex">
        <span @click="menuStatus = true" class="slide-left close-slide-btn">
          <img
            class="cursor ml-0"
            :src="toolTipIconClose"
            width="100"
            loading="lazy"
          />
        </span>
        <v-card
          class="py-6 px-8 slide-left rounded-0"
          id="informativeCard"
          width="310"
        >
          <div class="titleTip">
            {{ title }}
          </div>
          <div class="description">
            {{ description }}
          </div>
        </v-card>
      </div>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'informativeToolTip',
  props: {
    title: { default: '' },
    description: { default: '' }
  },
  data: () => {
    return {
      toolTipIcon: require('@/assets/img/svgs/toolTipIcon.svg'),
      toolTipIconClose: require('@/assets/img/svgs/toolTipIconClose.svg'),
      menuStatus: false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_informativeToolTip.scss';
</style>
