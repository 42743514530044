<template>
  <section class="why__unique--section" id="adv">
    <div class="why__unique--content">
      <div class="container">
        <div class="why__unique--title">
          <h3 class="title">أهم مميزات بوابة الأعمال</h3>
          <h4 class="sub-title">
            يتيح لك حسابك في بوابة الأعمال الوصول لهذا وأكثر
          </h4>
        </div>
        <div class="why__unique--descp">
          <ul class="descp">
            <li>
              <span class="descp-icon"
                ><img
                  src="@/assets/img/customer-landingPage/box-icon.svg"
                  alt="Box Icon"
              /></span>
              لوحة تحكم سهلة الإستخدام والتصفح
            </li>
            <li>
              <span class="descp-icon"
                ><img
                  src="@/assets/img/customer-landingPage/sheild-icon.svg"
                  alt="Sheild Icon"
              /></span>
              عرض وتداول المعلومات بصورة متكاملة وأمنة
            </li>
            <li>
              <span class="descp-icon"
                ><img
                  src="@/assets/img/customer-landingPage/calender-icon.svg"
                  alt="Calender Icon"
              /></span>
              خصائص متعددة لتلبة جميع الإجراءات والعمليات
            </li>
            <li>
              <span class="descp-icon"
                ><img
                  src="@/assets/img/customer-landingPage/message-icon.svg"
                  alt="Message Icon"
              /></span>
              أتمتة للإجراءات بطريقة ذكية
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- why__unique--content -->
  </section>
</template>

<script>
export default {
  name: 'FeautesSection',
  data: () => {
    return {
      dashboardIcon: require('@/assets/img/icons/dashboard.svg'),
      messageIcon: require('@/assets/img/icons/message-icon.svg'),
      browserIcon: require('@/assets/img/icons/browser.svg'),
      checkLogoIcon: require('@/assets/img/icons/checkLogo.svg')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_landing-page.scss';
</style>
