<template>
  <v-dialog max-width="600px" persistent v-model="dialogState_">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="py-0 px-5 mb-4 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0 font-16">انشاء عقد</h4>
        <h4 v-if="contractObj.realEstate">{{ contractTitle }}</h4>
        <v-btn
          @click.prevent="closeDialog"
          data-test="close-contract"
          class="ma-0 mt-2 font-14"
          color="grey"
          fab
          text
        >
          <v-icon class="font-12">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs-items class="px-5 create__contract__tab" v-model="tab" touchless>
        <v-tab-item>
          <!-- Details -->
          <v-card-text class="pa-0">
            <v-form ref="form" v-model="isValid.firstStep">
              <v-container class="pa-0">
                <v-row no-gutters>
                  <h6 class="mb-2 mt-4">نوع العقد</h6>
                  <v-layout class="w-100" justify-space-between>
                    <v-card
                      v-for="contract in contractTypes"
                      :key="contract._id"
                      :class="{ 'brub--tag-border': contract.checked }"
                      width="170"
                    >
                      <v-checkbox
                        @change="handleContractTypeChange(contract.nameEn)"
                        class="ma-0 contract mr-1"
                        dense
                        hide-details
                        :readonly="contract.checked"
                        v-model="contract.checked"
                      ></v-checkbox>

                      <div class="text-center">
                        <img
                          :src="contract.pic"
                          alt=""
                          v-if="!contract.checked"
                        />
                        <img
                          :src="contract.activePic"
                          alt=""
                          v-else
                          loading="lazy"
                        />
                        <h6
                          :class="{ 'text-primary': contract.checked }"
                          class="font-weight-medium mt-0"
                        >
                          {{ contract.name }}
                        </h6>
                      </div>
                    </v-card>
                  </v-layout>
                  <v-layout
                    class="w-100 mt-3"
                    justify-space-between
                    v-if="isMaintenanceContract"
                  >
                    <v-card
                      :class="{ 'brub--tag-border': maintenanceType.checked }"
                      v-for="maintenanceType in maintenanceContractTypes"
                      :key="maintenanceType._id"
                      width="130"
                    >
                      <v-checkbox
                        :value="maintenanceType._id"
                        class="ma-0 contract mr-1"
                        dense
                        hide-details
                        v-model="contractSuptypes"
                        @change="handleContractSubTypeChange"
                      >
                        <template v-slot:label>
                          <div class="text-center">
                            <h6
                              :class="{
                                'text-primary': maintenanceType.checked
                              }"
                              class="font-weight-medium mt-0 mb-2"
                            >
                              {{ maintenanceType.name }}
                            </h6>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-card>
                  </v-layout>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-1 mt-4">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="
                !isValid.firstStep ||
                !selectedContractType ||
                !subTypesValidation
              "
              @click="tab++"
              class="ma-0 rounded-7 my-3 ml-3"
              color="primary"
            >
              <span class="mb-2 ml-1 font-weight-bold">التالي</span>
              <v-icon class="send-icon">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <v-tab-item>
          <v-form ref="form" v-model="isValid.secondsStep">
            <!-- Details -->
            <h6 class="ma-0 mb-4">اطراف التعاقد</h6>
            <v-row no-gutters>
              <v-col md="12">
                <v-layout class="mb-4" justify-space-between>
                  <v-layout>
                    <v-icon class="ml-1" color="primary">domain</v-icon>
                    <h4
                      class="font-weight-medium font-16 ma-0"
                      v-if="isRentContract"
                    >
                      طرف أول (مالك)
                    </h4>
                    <h4 class="font-weight-medium font-16 ma-0" v-else>
                      طرف أول
                    </h4>
                  </v-layout>
                </v-layout>
                <v-layout align-center class="mb-4" justify-center>
                  <v-card
                    class="d-flex justify-center align-center flex-column"
                    height="90"
                    width="90"
                  >
                    <v-icon color="primary" size="64px">domain</v-icon>
                  </v-card>
                  <v-col class="pt-0">
                    <autocomplete
                      :itemData="true"
                      :items="allEnvOwners"
                      :noData="noDataClient"
                      :placeholder="selectedClientPlaceholder"
                      :validateString="validateStringClient"
                      :returnObject="true"
                      @nextPageTrigger="getClientNextData"
                      @emitSearchKey="getClientDataBySearchKey"
                      @input="onSelectFirstParty($event)"
                      v-model="contractObj.firstParty"
                      v-if="isRentContract"
                      :disabled="
                        defaultValuesCheck && isRentContract && !isOnlyRenter
                      "
                    />

                    <v-form v-if="isRentContract && isTaxOrIdMissing">
                      <v-row>
                        <v-col class="pl-3" cols="12" sm="6">
                          <h6
                            :class="[
                              'mb-2',
                              'font-12',
                              { 'red--flag': !contractObj.firstParty.taxNumber }
                            ]"
                          >
                            الرقم الضريبي للمالك*
                          </h6>
                          <v-text-field
                            placeholder="الرقم الضريبي"
                            dense
                            filled
                            hide-details
                            min="0"
                            @keydown="$formatNumberInputs"
                            v-model="contractObj.firstParty.taxNumber"
                            class="font-14"
                            @input="
                              formatArabicNumber(
                                $event,
                                'contractObj.firstParty.taxNumber'
                              )
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col class="pl-3" cols="12" sm="6" v-if="typeCheck">
                          <h6
                            :class="[
                              'mb-2',
                              'font-12',
                              { 'red--flag': !contractObj.firstParty.idNumber }
                            ]"
                          >
                            {{ typeCheck }}*
                          </h6>
                          <v-text-field
                            :placeholder="typeCheck"
                            dense
                            filled
                            hide-details
                            type="number"
                            min="0"
                            @keydown="$formatNumberInputs"
                            v-model="contractObj.firstParty.idNumber"
                            class="font-14"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>

                    <div v-else>
                      <h4 class="font-weight-medium font-16 ma-0">
                        اسم الشركة
                      </h4>
                      <h4>{{ firstPartyName || 'لا يوجد اسم' }}</h4>
                    </div>
                  </v-col>
                </v-layout>

                <v-expansion-panels
                  :disabled="expansionPanelsStatus"
                  accordion
                  v-model="panels"
                >
                  <v-expansion-panel
                    :key="i"
                    v-for="(item, i) in contractObj.otherParties"
                  >
                    <v-expansion-panel-header class="no-border rounded-15">
                      <v-layout class="mb-4" justify-space-between>
                        <v-layout class="flex-column">
                          <div class="d-flex">
                            <v-icon class="ml-1" color="primary">person</v-icon>
                            <h4 class="font-weight-medium font-16 ma-0">
                              {{ partiesTypesMap[i] }}
                              ({{ isRentContract ? 'مستأجر' : 'مالك عقار' }})
                            </h4>
                          </div>
                        </v-layout>
                      </v-layout>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row align-content-sm no-gutters>
                        <v-form ref="form" class="w-100">
                          <v-col cols="12" sm="12" class="mobile__p__0">
                            <dropdown-alt
                              ref="clientType"
                              :defaultValue="currentUser"
                              :configrations="{ name: '' }"
                              :listingType="clientType"
                              :disable="
                                defaultValuesCheck &&
                                !defaultRealestateValuesCheck
                                  ? true
                                  : false
                              "
                              @filterTypes="onUserTypeChange($event)"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            v-if="!isNewOwner"
                            class="mobile__p__0"
                          >
                            <h5 class="ma-0 mb-3 font-12">اسم المستأجر*</h5>
                            <autocomplete
                              ref="autoComplete"
                              :itemData="true"
                              :items="AllavailableUsers"
                              :noData="noDataClient"
                              :placeholder="selectedClientPlaceholder"
                              :validateString="validateStringClient"
                              :returnObject="true"
                              @nextPageTrigger="getRenterNextData"
                              @emitSearchKey="getRenterDataBySearchKey"
                              v-model="contractObj.otherParties[i]"
                              @input="
                                onSelectOtherParty(
                                  $event,
                                  selectedContractType,
                                  i
                                )
                              "
                              :disabled="
                                (defaultValuesCheck && !isRentContract) ||
                                (defaultValuesCheck &&
                                  isRentContract &&
                                  isOnlyRenter)
                              "
                              class="font-14"
                            />

                            <v-form
                              v-if="
                                contractObj.otherParties[0] &&
                                showOtherPartyMissingData(i)
                              "
                            >
                              <v-row>
                                <v-col class="pl-3" cols="12" sm="6">
                                  <h6
                                    :class="[
                                      'mb-2',
                                      'font-12',
                                      {
                                        'red--flag':
                                          !contractObj.otherParties[0].taxNumber
                                      }
                                    ]"
                                  >
                                    الرقم الضريبي للمالك*
                                  </h6>
                                  <v-text-field
                                    placeholder="الرقم الضريبي"
                                    dense
                                    filled
                                    hide-details
                                    min="0"
                                    @keydown="$formatNumberInputs"
                                    v-model="
                                      contractObj.otherParties[0].taxNumber
                                    "
                                    class="font-14"
                                    @input=""
                                  ></v-text-field>
                                </v-col>
                                <v-col class="pl-3" cols="12" sm="6">
                                  <h6
                                    :class="[
                                      'mb-2',
                                      'font-12',
                                      {
                                        'red--flag':
                                          !contractObj.otherParties[0].idNumber
                                      }
                                    ]"
                                  >
                                    {{ contractObj.otherParties[i].typeCheck }}*
                                  </h6>
                                  <v-text-field
                                    :placeholder="
                                      contractObj.otherParties[i].typeCheck
                                    "
                                    dense
                                    filled
                                    hide-details
                                    type="number"
                                    min="0"
                                    @keydown="$formatNumberInputs"
                                    v-model="
                                      contractObj.otherParties[0].idNumber
                                    "
                                    class="font-14"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-col>
                        </v-form>
                      </v-row>
                      <v-row v-if="isNewOwner">
                        <v-col>
                          <NewAgentForm
                            ref="newAgent"
                            :index="0"
                            :isAqarForm="true"
                            :isDisabled="false"
                            :isOptionalInfoRequired="
                              isRentContract ? true : false
                            "
                            @new_user_added="newUserAdded"
                          />
                        </v-col>
                      </v-row>
                      <!---------------------------------------------------------->
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <v-card-actions class="pa-1 mt-4">
              <v-btn
                @click="tab--"
                class="ma-0 rounded-7"
                color="gery"
                text
                x-large
              >
                <v-icon class="ma-0">mdi-arrow-right</v-icon>
                <span class="mb-3">السابق</span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="isRentContract"
                :disabled="!partiesTabValid"
                @click="saveAsDraft"
                class="ma-0 rounded-7 my-3 ml-3"
                color="grey"
              >
                <span class="ml-1 font-weight-bold">حفظ كمسوده</span>
              </v-btn>
              <v-btn
                :disabled="!partiesTabValid"
                :loading="updateClientsIsLoading"
                @click="completePartiesTab"
                class="ma-0 rounded-7 my-3 ml-3"
                color="primary"
              >
                <span class="mb-2 ml-1 font-weight-bold">التالي</span>
                <v-icon class="send-icon">mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab-item>
          <v-form ref="form" v-model="isValid.thirdStep">
            <!-- Details -->
            <h6 class="ma-0 mb-4">تفاصيل التعاقد</h6>
            <v-row no-gutters>
              <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
                <h6 class="ma-0 mb-2">* عقار</h6>
                <autocomplete
                  :items="availableRealEstates"
                  :noData="noData"
                  :placeholder="selectedRelestatePlaceholder"
                  :validateString="validateString"
                  :returnObject="true"
                  :itemText="propertyToShow"
                  v-model="contractObj.realEstate"
                  :hideDetails="true"
                  @input="getPmContractCompantPercentage($event)"
                  :disabled="defaultRealestateValuesCheck"
                />
              </v-col>
              <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
                <h6 class="ma-0 mb-2">* مده العقد</h6>
                <v-select
                  :items="contractIntervals"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  :placeholder="`اختر مدة العقد`"
                  :rules="[
                    (v) => Object.keys(v || {}).length > 0 || `اختر مدة العقد`
                  ]"
                  @input="collectionInterval"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="nameAr"
                  item-value="_id"
                  required
                  return-object
                  v-model="contractObj.duration"
                ></v-select>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
                <h6 class="mt-4 mb-2">* تاريخ البدايه</h6>
                <v-dialog
                  :return-value.sync="contractObj.start"
                  ref="startAtDialog"
                  v-model="startAtModal"
                  width="320px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :rules="[(v) => !!v || 'من فضلك ادخل تاريخ البدايه']"
                      append-icon="mdi-calendar-month-outline"
                      dense
                      filled
                      hide-details
                      placeholder="تاريخ البدايه"
                      readonly
                      required
                      v-bind="attrs"
                      v-model="contractObj.start"
                      v-on="on"
                      class="font-14"
                    ></v-text-field>
                  </template>
                  <v-date-picker color="primary" scrollable v-model="start">
                    <v-spacer></v-spacer>
                    <v-btn @click="startAtModal = false" color="primary" text>
                      الغاء
                    </v-btn>
                    <v-btn
                      @click="$refs.startAtDialog.save(start)"
                      color="primary"
                      text
                      >حفظ
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col class="pl-3" cols="12" sm="6" v-if="isMaintenanceContract">
                <h6 class="mb-2">* السعر المتفق علبه</h6>
                <v-text-field
                  :required="isMaintenanceContract"
                  :rules="[(v) => !!v || 'من فضلك أدخل السعر']"
                  dense
                  filled
                  hide-details
                  placeholder="السعر المتفق علبه"
                  suffix="ريال"
                  v-model="contractObj.amount"
                  class="font-14"
                ></v-text-field>
              </v-col>
              <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
                <h6 class="mt-4 mb-2">
                  {{ collectionIntervalsText }}
                </h6>
                <v-select
                  :disabled="!contractObj.duration"
                  :items="collectionIntervals"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  :placeholder="collectionIntervalsPlaceholder"
                  :rules="[(v) => !!v || `اختر الفترة`]"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="nameAr"
                  item-value="_id"
                  required
                  return-object
                  v-model="contractObj.collectionInterval"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="mb-2" v-if="isManagementContract">
              <v-col cols="12">
                <h5>العمولات</h5>
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-card class="px-2" width="30%" height="49">
                  <h6 class="mx-1">نسبه الشركه</h6>
                  <v-switch
                    class="ma-auto client__filter__switch mt-2 maintenance-switch"
                    v-model="companyPercentage"
                    inset
                    dense
                    small
                    hide-details="auto"
                  ></v-switch>
                </v-card>
                <v-card class="mr-2 px-2" width="30%" height="49">
                  <h6 class="mx-1">العموله الثابته</h6>
                  <v-switch
                    class="ma-auto client__filter__switch mt-2 maintenance-switch"
                    v-model="fixedCommission"
                    inset
                    dense
                    small
                    hide-details="auto"
                  ></v-switch>
                </v-card>
              </v-col>
              <v-col
                v-if="companyPercentage"
                cols="12"
                class="pl-3 mobile__pl__0"
              >
                <h6 class="mb-2">*نسبه الشركه من المحصلات</h6>
                <v-text-field
                  :disabled="!contractObj.collectionInterval"
                  :rules="[
                    (v) =>
                      companyPercentage ? !!v && !!contractObj.percentage : true
                  ]"
                  dense
                  filled
                  hide-details
                  placeholder="نسبه الشركه من المحصلات"
                  required
                  suffix="%"
                  v-model="contractObj.percentage"
                  class="font-14"
                ></v-text-field>
              </v-col>
              <template v-if="fixedCommission">
                <v-col class="pl-3 mobile__pl__0" cols="6">
                  <h6 class="mb-2">*مبلغ العموله الثابته</h6>
                  <v-text-field
                    :disabled="!contractObj.collectionInterval"
                    :rules="[
                      (v) =>
                        fixedCommission ? !!v && !!contractObj.commission : true
                    ]"
                    dense
                    filled
                    hide-details
                    placeholder="مبلغ العموله الثابته"
                    required
                    suffix="SAR"
                    v-model="contractObj.commission"
                    class="font-14"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-3 mobile__pl__0" cols="6">
                  <h6 class="mb-2">فترة تحصيل العموله الثابته</h6>
                  <v-select
                    :disabled="!contractObj.duration"
                    :items="collectionIntervals"
                    :menu-props="{
                      transition: 'slide-y-transition',
                      bottom: true,
                      offsetY: true
                    }"
                    placeholder="فترة تحصيل العموله الثابته"
                    :rules="[
                      (v) =>
                        fixedCommission
                          ? !!v && !!contractObj.commissionPeriod
                          : true
                    ]"
                    append-icon="mdi-chevron-down"
                    dense
                    filled
                    hide-details
                    item-text="nameAr"
                    item-value="_id"
                    required
                    return-object
                    v-model="contractObj.commissionPeriod"
                  ></v-select>
                </v-col>
              </template>
              <v-col
                class="mt-2"
                :class="{ 'maintenance-switch-container': sm }"
                cols="12"
              >
                <v-card class="px-2" width="25%" height="49">
                  <h6 class="mx-1">الصيانة</h6>
                  <v-switch
                    class="ma-auto client__filter__switch mt-2 maintenance-switch"
                    v-model="managementMaintenance"
                    inset
                    dense
                    small
                    hide-details="auto"
                  ></v-switch>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                class="mt-4"
                v-if="managementMaintenance"
              >
                <v-layout justify-space-between>
                  <v-card
                    :class="{ 'brub--tag-border': maintenanceType.checked }"
                    v-for="maintenanceType in maintenanceContractTypes"
                    :key="maintenanceType._id"
                    width="130"
                  >
                    <v-checkbox
                      :value="maintenanceType._id"
                      class="ma-0 contract mr-1"
                      dense
                      hide-details
                      v-model="contractSuptypes"
                      @change="handleContractSubTypeChange"
                    >
                      <template v-slot:label>
                        <div class="text-center">
                          <h6
                            :class="{
                              'text-primary': maintenanceType.checked
                            }"
                            class="font-weight-medium mt-0 mb-2"
                          >
                            {{ maintenanceType.name }}
                          </h6>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-card>
                </v-layout>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-4" v-if="isRentContract">
              <v-col class="pl-3 mobile__pl__0 pt-0" cols="12" sm="6">
                <h6 class="ma-0 mb-2">*قيمة الايجار الكليه</h6>
                <v-text-field
                  :disabled="!contractObj.collectionInterval"
                  :required="isRentContract"
                  :rules="[(v) => !!v || 'من فضلك ادخل قيمة الايجار']"
                  dense
                  filled
                  hide-details
                  placeholder="قيمة الايجار الكلي"
                  suffix="ريال"
                  v-model="contractObj.rentValue"
                  type="number"
                  class="font-14"
                ></v-text-field>
              </v-col>
              <v-col class="pl-3 mobile__pl__0 pt-0" cols="12" sm="6">
                <h6 class="ma-0 mb-2">*قيمة دفعه التحصيل</h6>
                <v-text-field
                  :disabled="true"
                  :required="isRentContract"
                  :rules="[(v) => !!v || 'من فضلك ادخل قيمة الايجار']"
                  dense
                  filled
                  hide-details
                  placeholder="قيمة الدفعه الواحده"
                  suffix="ريال"
                  v-model="payOnceValue"
                  class="font-14"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              class="mb-2 d-flex algin-center px-5"
              v-if="contractObj.rentValue"
            >
              <v-col class="px-0 d-flex align-center" cols="3" sm="3">
                <v-divider></v-divider>
              </v-col>
              <v-col class="px-2" cols="12" sm="6">
                <div class="borderedContainer text-center py-1">
                  <p class="ma-0 pa-0 font-14">
                    قيمه نسبه الشركه من {{ pmContractCompanyPrecentage }} %
                  </p>
                  <p class="ma-0 pa-0 font-14 font-weight-bold">
                    {{ companyValueInRyal }} ريال
                  </p>
                </div>
              </v-col>
              <v-col class="px-0 d-flex align-center" cols="3" sm="3">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <!-- <v-row class="my-3" v-if="isRentContract">
              <v-col class="mobile__pl__0 pa-0 pl-3" cols="12" sm="6">
                <v-card class="d-flex justify-space-between px-2">
                  <h6 class="mx-1">عقد متجدد</h6>
                  <v-switch
                    class="my-auto"
                    dense
                    hide-details="auto"
                    inset
                    small
                  ></v-switch>
                </v-card>
              </v-col>
              <v-col class="mobile__pl__0 pa-0 pl-5" cols="12" sm="6">
                <v-card class="d-flex justify-space-between px-2">
                  <h6 class="mx-1">التأجير من الباطن</h6>
                  <v-switch
                    class="my-auto"
                    dense
                    hide-details="auto"
                    inset
                    small
                  ></v-switch>
                </v-card>
              </v-col>
            </v-row> -->

            <v-row class="mb-2" v-if="isManagementContract || isRentContract">
              <v-col cols="12" sm="12">
                <v-card class="informativeCard pa-2">
                  <div class="holder d-flex align-center">
                    <div class="mr-3 ml-5">
                      <v-img :src="exclamation" />
                    </div>
                    <div class="text">
                      <span>
                        <p class="headTitle font-14 font-weight-bold ma-0">
                          {{ companyPercentageText }}
                        </p>
                      </span>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-card-actions class="pa-1 mt-4">
              <v-btn
                @click="tab--"
                class="ma-0 rounded-7"
                color="gery"
                text
                x-large
              >
                <v-icon class="ma-0">mdi-arrow-right</v-icon>
                <span class="mb-3">السابق</span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="isRentContract"
                @click="saveAsDraft"
                class="ma-0 rounded-7 my-3 ml-3"
                color="grey"
              >
                <span class="ml-1 font-weight-bold">حفظ كمسوده</span>
              </v-btn>
              <v-btn
                :disabled="thirdTabValidation"
                @click="tab++"
                class="ma-0 rounded-7 my-3 ml-3"
                color="primary"
              >
                <span class="ml-1 font-weight-bold">التالي</span>
                <v-icon class="send-icon">mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab-item v-if="isRentContract">
          <v-checkbox v-model="pendingContract">
            <template v-slot:label>
              <span class="text-primary font-weight-bold">
                العقد يتم خلال موافقه الطرفين
              </span>
            </template>
          </v-checkbox>
          <v-row class="mb-2">
            <v-col cols="12" sm="12">
              <v-card class="informativeCard pa-2">
                <div class="holder d-flex align-center">
                  <div class="mr-3 ml-5">
                    <v-img :src="exclamation" />
                  </div>
                  <div class="text">
                    <span>
                      <p class="headTitle font-14 font-weight-bold ma-0">
                        في حاله التفعيل يتم إرسال إشعار إلي الطرفين بطلب قبول
                        العقد المبرم, ولن يتم تفعيل العقد إلا إذا تم الموافقه من
                        خلال الطرفين في مده أقصاها 7 أيام
                      </p>
                    </span>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-card-actions class="pa-1 mt-4">
            <v-btn
              @click="tab--"
              class="ma-0 rounded-7"
              color="gery"
              text
              x-large
            >
              <v-icon class="ma-0">mdi-arrow-right</v-icon>
              <span class="mb-3">السابق</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isRentContract"
              @click="saveAsDraft"
              class="ma-0 rounded-7 my-3 ml-3"
              color="grey"
            >
              <span class="ml-1 font-weight-bold">حفظ كمسوده</span>
            </v-btn>
            <v-btn
              @click="tab++"
              class="ma-0 rounded-7 my-3 ml-3"
              color="primary"
            >
              <span class="ml-1 font-weight-bold">التالي</span>
              <v-icon class="send-icon">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <v-tab-item v-if="isRentContract">
          <v-form ref="form" v-model="isValid.fourthStep">
            <v-container>
              <h6 class="ma-0 mb-4">تفاصيل إضافيه (إختياريه)</h6>
              <v-row no-gutters class="mt-4">
                <v-col class="pa-0" cols="12" sm="6">
                  <h6 class="ma-0 mb-2">قيمة التأمين</h6>
                  <v-text-field
                    class="pl-3 mobile__pl__0 font-14"
                    dense
                    filled
                    hide-details
                    placeholder="قيمة التأمين"
                    suffix="ريال"
                    v-model="contractObj.insurance"
                  ></v-text-field>
                </v-col>

                <v-col class="pl-3" cols="12" sm="6">
                  <h6 class="mt-0 mb-2">قيمه خدمه الكهرباء</h6>
                  <v-text-field
                    class="pb-2 font-14"
                    dense
                    filled
                    hide-details
                    placeholder="قيمه خدمه الكهرباء"
                    v-model="contractObj.electricity"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-3" cols="12" sm="6">
                  <h6 class="mt-0 mb-2">فتره تحصيل الدفعه</h6>
                  <v-select
                    :rules="
                      contractObj.electricity
                        ? [(v) => !!v || `اختر الفترة`]
                        : [true]
                    "
                    :disabled="!contractObj.electricity"
                    :items="contractIntervals"
                    :menu-props="{
                      transition: 'slide-y-transition',
                      bottom: true,
                      offsetY: true
                    }"
                    placeholder="فتره تحصيل الدفعه"
                    append-icon="mdi-chevron-down"
                    dense
                    filled
                    hide-details
                    item-text="nameAr"
                    item-value="_id"
                    required
                    return-object
                    v-model="contractObj.electricityPayment"
                  ></v-select>
                </v-col>
                <v-col class="pl-3" cols="12" sm="6">
                  <h6 class="mt-0 mb-2">قيمه خدمه المياه</h6>
                  <v-text-field
                    class="pb-2 font-14"
                    dense
                    filled
                    hide-details
                    placeholder="قيمه خدمه المياه"
                    v-model="contractObj.water"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-3" cols="12" sm="6">
                  <h6 class="mt-0 mb-2">فتره تحصيل الدفعه</h6>
                  <v-select
                    :rules="
                      contractObj.water ? [(v) => !!v || `اختر الفترة`] : [true]
                    "
                    :disabled="!contractObj.water"
                    :items="contractIntervals"
                    :menu-props="{
                      transition: 'slide-y-transition',
                      bottom: true,
                      offsetY: true
                    }"
                    placeholder="فتره تحصيل الدفعه"
                    append-icon="mdi-chevron-down"
                    dense
                    filled
                    hide-details
                    item-text="nameAr"
                    item-value="_id"
                    required
                    return-object
                    v-model="contractObj.waterPayment"
                  ></v-select>
                </v-col>
                <v-col class="pl-3" cols="12" sm="6">
                  <h6 class="mt-0 mb-2">قيمه خدمه الغاز</h6>
                  <v-text-field
                    class="pb-2 font-14"
                    dense
                    filled
                    hide-details
                    placeholder="قيمه خدمه الغاز"
                    v-model="contractObj.gas"
                  ></v-text-field>
                </v-col>
                <v-col class="pl-3" cols="12" sm="6">
                  <h6 class="mt-0 mb-2">فتره تحصيل الدفعه</h6>
                  <v-select
                    :rules="
                      contractObj.gas ? [(v) => !!v || `اختر الفترة`] : [true]
                    "
                    :disabled="!contractObj.gas"
                    :items="contractIntervals"
                    :menu-props="{
                      transition: 'slide-y-transition',
                      bottom: true,
                      offsetY: true
                    }"
                    placeholder="فتره تحصيل الدفعه"
                    append-icon="mdi-chevron-down"
                    dense
                    filled
                    hide-details
                    item-text="nameAr"
                    item-value="_id"
                    required
                    return-object
                    v-model="contractObj.gasPayment"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="pl-3" cols="12" sm="6">
                  <h6 class="mt-0 mb-2">هل توجد ضريبة ؟</h6>
                  <v-card class="d-flex justify-space-between px-2">
                    <h6 class="mx-1">ضريبة</h6>
                    <v-switch
                      class="my-auto"
                      dense
                      hide-details="auto"
                      inset
                      small
                      v-model="hasTax"
                    ></v-switch>
                  </v-card>
                </v-col>

                <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
                  <h6 class="mt-0 mb-2">قيمة الضريبة</h6>
                  <v-text-field
                    dense
                    filled
                    hide-details
                    placeholder="قيمة الضريبة"
                    suffix="%"
                    v-model="contractObj.tax"
                    :disabled="true"
                    class="font-14"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions class="pa-1 mt-4">
              <v-btn
                @click="tab--"
                class="ma-0 rounded-7"
                color="gery"
                text
                x-large
              >
                <v-icon class="ma-0">mdi-arrow-right</v-icon>
                <span class="mb-3">السابق</span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="isRentContract"
                @click="saveAsDraft"
                class="ma-0 rounded-7 my-3 ml-3"
                color="grey"
              >
                <span class="ml-1 font-weight-bold">حفظ كمسوده</span>
              </v-btn>
              <v-btn
                :disabled="!isValid.fourthStep"
                @click="tab++"
                class="ma-0 rounded-7 my-3 ml-3"
                color="primary"
              >
                <span class="mb-2 ml-1 font-weight-bold">التالي</span>
                <v-icon class="send-icon">mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab-item v-if="isRentContract">
          <h6 class="ma-0 mb-4 pt-2">تفاصيل العقد على منصة إيجار</h6>
          <v-form>
            <v-row no-gutters>
              <v-col sm="6" cols="12" class="pl-3 mobile__pl__0 mt-1">
                <h6 class="mt-0 mb-2">رقم العقد في منصة إيجار</h6>
                <v-text-field
                  class="pb-2 font-14"
                  dense
                  filled
                  hide-details
                  placeholder="رقم العقد في منصة إيجار"
                  v-model="contractObj.electronicRent.electronicNumber"
                >
                </v-text-field>
              </v-col>

              <v-col class="pl-3 mobile__pl__0 mt-1" sm="6" cols="12">
                <h6 class="mt-0 mb-2">رسوم العقد</h6>
                <v-text-field
                  class="pb-2 font-14"
                  dense
                  filled
                  hide-details
                  placeholder="رسوم العقد"
                  type="number"
                  min="0"
                  v-model="contractObj.electronicRent.rentValue"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" class="pl-3 mobile__pl__0 mt-1">
                <h6 class="mt-0 mb-2">أرفاق صورة العقد</h6>
                <div
                  v-if="!contractImage.image"
                  @drop.prevent="addContractImage($event)"
                  @dragleave.prevent="dragLeave('pic')"
                  @dragover.prevent="dragOver('pic')"
                  @dragenter.prevent
                  ref="pic"
                  class="pic-wrapper d-flex align-center w-100 pa-6 my-4 flex-wrap"
                >
                  <div>
                    <v-btn
                      @click="$refs.inputUpload.click()"
                      class="py-2 px-3 rounded-7 font-weight-bold"
                      color="general"
                      outlined
                    >
                      <v-icon color="general"> mdi-plus </v-icon>
                      <span class="font-12"> إضافة صورة العقد </span>
                    </v-btn>
                    <input
                      @change="addContractImage($event)"
                      accept="image/*"
                      multiple
                      ref="inputUpload"
                      type="file"
                      v-show="false"
                    />
                  </div>
                  <div :class="{ 'mr-3': !xs }">
                    <h4 class="font-12">
                      اسحب الصوره هنا لرفعها أو اضغط على زر إضافة صوره العقد
                    </h4>
                  </div>
                </div>
                <div v-else class="position-relative add-pic">
                  <img
                    :src="contractImage.image"
                    class="add-pic"
                    loading="lazy"
                  />
                  <div
                    v-if="!contractImage.key"
                    class="lds-ring-container top-layer"
                  >
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>

                  <v-icon
                    @click="deleteContractImage"
                    class="pic-icon position-absolute slide-y-transition"
                    color="white"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>

              <v-col class="my-2" cols="12" sm="12">
                <v-card class="informativeCard pa-2">
                  <div class="holder d-flex align-center">
                    <div class="mr-3 ml-5">
                      <v-img :src="exclamation" />
                    </div>
                    <div class="text">
                      <span>
                        <p class="headTitle font-14 font-weight-bold ma-0">
                          سيتم حساب رسوم عقد منصة إيجار كمصروفات خاصة بالعقار
                          محل هذا العقد
                        </p>
                      </span>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>

            <v-card-actions class="pa-1 mt-4">
              <v-btn
                @click="tab--"
                class="ma-0 rounded-7"
                color="gery"
                text
                x-large
              >
                <v-icon class="ma-0">mdi-arrow-right</v-icon>
                <span class="mb-3">السابق</span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="isRentContract"
                @click="saveAsDraft"
                class="ma-0 rounded-7 my-3 ml-3"
                color="grey"
              >
                <span class="ml-1 font-weight-bold">حفظ كمسوده</span>
              </v-btn>
              <v-btn
                :disabled="contractImage.imageIsLoading"
                @click="tab++"
                class="ma-0 rounded-7 my-3 ml-3"
                color="primary"
              >
                <span class="mb-2 ml-1 font-weight-bold">التالي</span>
                <v-icon class="send-icon">mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab-item v-if="selectedContractType">
          <v-card-text class="py-0 px-2">
            <h6 class="mb-2">تفاصيل التعاقد (اختياري)</h6>
            <quill-editor ref="myQuillEditor" v-model="contractObj.body" />
          </v-card-text>
          <v-card-actions class="pa-1 mt-4">
            <v-btn
              @click="tab--"
              class="ma-0 rounded-7"
              color="gery"
              text
              x-large
            >
              <v-icon class="ma-0">mdi-arrow-right</v-icon>
              <span class="mb-3">السابق</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isRentContract"
              @click="saveAsDraft"
              class="ma-0 rounded-7 my-3 ml-3"
              color="grey"
            >
              <span class="ml-1 font-weight-bold">حفظ كمسوده</span>
            </v-btn>
            <v-btn
              :loading="isLoading"
              @click="onAddContract"
              class="ma-0 rounded-7 my-3 ml-3"
              color="primary"
            >
              <span class="mb-2 ml-1 font-weight-bold">انشاء العقد</span>
              <v-icon class="send-icon">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { mapMutations, mapState } from 'vuex'
import {
  contractsService,
  EnvService,
  realEstateService,
  templatesService
} from '../../services'
import NewAgent from '@/components/common/new-agent/NewAgent'
import NewAgentForm from '@/components/common/new-agent/NewAgentForm'
import autocomplete from '@/components/forms/autocomplete'
import drag from '@/mixins/drag.js'
import checkClientData from '@/mixins/checkClientData.js'
import DropdownAlt from '@/components/add-unit/dropdownAlt'
import config from '@/config'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  name: 'AddContractModal',
  mixins: [drag, checkClientData],
  components: {
    DropdownAlt,
    NewAgentForm,
    NewAgent,
    quillEditor,
    autocomplete
  },
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    defaultData: {
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      currentUser: 'حالي',
      draftContract: false,
      pendingContract: false,
      pmContractCompanyPrecentage: 0,
      exclamation: require('@/assets/img/svgs/exclamationMark.svg'),
      panels: [],
      expansionPanels: true,
      propertyToShow: 'propertyName',
      selectedRelestatePlaceholder: 'اختر العقار',
      noData: 'لا توجد عقارات متاحه لهذا البحث',
      validateString: 'من فضلك ادخل اسم العقار',
      selectedClientPlaceholder: 'عميل حالي',
      noDataClient: 'لا يوجد عملاء متاحين لهذا البحث',
      validateStringClient: 'من فضلك ادخل اسم العميل',
      paginationRenter: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationClient: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      envOwners: [],
      allEnvOwners: [],
      clientSearchKey: '',
      renterSearchKey: '',
      newAgentFormValid: false,
      initialContractData: {
        currentUser: {},
        electronicRent: {
          rentValue: '',
          electronicNumber: '',
          electronicImage: ''
        },
        insurance: 0,
        newUserChecked: false,
        otherParties: [{}]
      },
      contractObj: {
        currentUser: {},
        firstParty: {},
        electronicRent: {
          rentValue: '',
          electronicNumber: '',
          electronicImage: ''
        },
        insurance: null,
        newUserChecked: false,
        otherParties: [{}],
        realEstate: null
      },
      availableRealEstates: [],
      availableUsers: [],
      AllavailableUsers: [],
      template: {},
      isValid: {
        firstStep: false,
        secondsStep: false,
        thirdStep: false,
        fourthStep: false
      },
      tab: 0,
      contractTypes: [],
      maintenanceContractTypes: [],
      contractSuptypes: [],
      contractIntervals: [],
      start: new Date().toISOString().substr(0, 10),
      startAtModal: false,
      isLoading: false,
      contractType: null,
      partiesTypesMap: {
        0: 'الطرف الثاني',
        1: 'الطرف الثالث',
        2: 'الطرف الثالث'
      },
      currentEnv: JSON.parse(localStorage.getItem('currentEnv')),
      uploadIcon: require('@/assets/img/svgs/contract/uploadIcon.svg'),
      newUser: {},
      collectionIntervals: [],
      hasTax: false,
      managementMaintenance: false,
      companyPercentage: false,
      fixedCommission: false,
      contractTypeAr: '',
      contractImage: {
        id: '',
        image: '',
        key: '',
        imageIsLoading: false
      },
      updateOtherPartyFailed: false,
      updateOtherPartyIsLoading: false,
      ownerIsRenter: false
    }
  },
  computed: {
    ...mapState('accountState', ['user']),
    clientType() {
      return config.clientType
    },
    isNewOwner() {
      return !!this.contractObj?.newUserChecked
    },
    getContractStatus() {
      if (this.IsItDraftContract) {
        return 'draft'
      } else if (this.IsItPendingContract) {
        return 'pending'
      }
      return 'active'
    },
    IsItPendingContract() {
      return this.pendingContract
    },
    IsItDraftContract() {
      return this.draftContract
    },
    isOnlyRenter() {
      const activeEnv = this.defaultData?.environments?.find((env) => {
        return env._id === this.currentEnv?._id
      })
      const clientRoles = activeEnv?.role
      return !!(clientRoles?.length === 1 && clientRoles?.includes('renter'))
    },
    thirdTabValidation() {
      return !this.isValid.thirdStep || this.additionalRules
    },
    additionalRules() {
      return !this.commessionRules || !this.maintenanceIsChecked
    },
    maintenanceIsChecked() {
      return this.isManagementContract && this.managementMaintenance
        ? !!this.contractSuptypes.length
        : true
    },
    commessionRules() {
      return this.isManagementContract
        ? this.companyPercentage || this.fixedCommission
        : true
    },
    defaultValuesCheck() {
      return !!Object.keys(this.defaultData).length
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set() {
        this.$emit('changeDialogState', true)
      }
    },
    defaultRealestateValuesCheck() {
      return this.defaultValuesCheck && this.defaultData.hasOwnProperty('owner')
    },
    companyValueInRyal() {
      return (this.payOnceValue * this.companyPrecentage).toFixed(3) || 0
    },
    companyPrecentage() {
      return this.pmContractCompanyPrecentage / 100 || 0
    },
    isRentContract() {
      return !!(this.selectedContractType === 'rent')
    },
    isMaintenanceContract() {
      return !!(this.selectedContractType === 'maintenance')
    },
    isManagementContract() {
      return !!(this.selectedContractType === 'propertyManagement')
    },
    payOnceValue() {
      const collectionPeriod =
        this.getPeriodNmonths(this.contractObj?.duration?.nameEn) /
        this.getPeriodNmonths(this.contractObj?.collectionInterval?.nameEn)
      const result = (this.contractObj?.rentValue / collectionPeriod).toFixed(3)
      return !isNaN(result) ? result : 0
    },
    expansionPanelsStatus() {
      if (this.isRentContract) {
        return this.expansionPanels
      }
      return false
    },
    selectedContractType() {
      const type = this.contractTypes.find((c) => c.checked === true)
      if (type) {
        return type.nameEn
      }
      return null
    },
    subTypesValidation() {
      if (this.isMaintenanceContract) {
        return !!this.contractSuptypes.length
      }
      return true
    },
    firstPartyName() {
      return this.currentEnv?.owner?.company || this.currentEnv?.name
    },
    partiesTabValid() {
      return (
        (this.newAgentFormValidStatus || this.otherPartiesStatus) &&
        this.firstPartyIsValid
      )
    },
    newAgentFormValidStatus() {
      return this.newAgentFormValid
    },
    contractTitle() {
      return (
        (this.contractObj?.title ?? '') +
        (this.contractObj?.realEstate?.propertyName ?? '')
      )
    },
    collectionIntervalsText() {
      if (this.isRentContract) {
        return '* فترة التحصيل'
      } else if (this.isMaintenanceContract) {
        return '* فترة إستلام المبلغ المتفق عليه'
      }
      return '* فترة دفع محصلات المالك'
    },
    collectionIntervalsPlaceholder() {
      if (this.isRentContract) {
        return 'فترة التحصيل'
      } else if (this.isMaintenanceContract) {
        return 'فترة إستلام المبلغ المتفق عليه'
      }
      return 'فترة دفع محصلات المالك'
    },
    contractToAdd() {
      const dataToSend = {
        _id: this.contractObj._id,
        title: this.contractTitle,
        description: this.contractObj.body,
        status: this.getContractStatus,
        notes: this.contractObj.notes,
        type: this.contractTypes.find((c) => c.checked === true)._id,
        typeName: this.contractTypes.find((c) => c.checked === true).nameEn,
        collectionMethod: 'percentage',
        ...(this.contractObj.percentage && {
          percentage: Number(this.contractObj.percentage)
        }),
        ...(this.contractObj.commission && {
          fixed: Number(this.contractObj.commission),
          commissionPeriod: this.contractObj.commissionPeriod?.value
        }),
        moderator: {
          _id: this.user?._id,
          name: this.user?.name,
          phoneNumber: this.user?.phoneNumber
        },
        firstParty: {
          _id: this.contractObj.firstParty?._id || this.user._id,
          name: this.contractObj.firstParty?.name || this.user.name,
          phoneNumber:
            this.contractObj.firstParty?.phoneNumber || this.user.phoneNumber,
          type: this.contractObj.firstParty?._id
            ? 'owner'
            : this.user.type || 'owner',
          idNumber: this.contractObj.firstParty?.idNumber,
          taxNumber: Number(this.contractObj.firstParty?.taxNumber)
        },
        // TEMP CHANGE
        otherParties: this.contractObj.otherParties.map((party) => {
          const obj = {
            type: this.isRentContract ? 'renter' : 'owner',
            typeCheck: undefined,
            isTaxOrIdMissing: undefined
          }
          if (party?._id === this.contractObj.firstParty._id) {
            obj.idNumber = this.contractObj.firstParty.idNumber
            obj.taxNumber = Number(this.contractObj.firstParty.taxNumber)
          } else {
            obj.taxNumber = Number(party.taxNumber)
          }
          return Object.assign({}, party, obj)
        }),
        environment: this.currentEnv._id,
        realEstate: {
          propertyName: this.contractObj.realEstate?.propertyName,
          _id: this.contractObj.realEstate?._id,
          address:
            this.contractObj.realEstate?.address ||
            this.contractObj.realEstate?.propertyAddress?.address,
          owner: this.contractObj.realEstate?.owner?._id,
          parentId: this.contractObj.realEstate?.parentId
        },
        start: new Date(this.contractObj.start),
        ...(this.contractObj.collectionInterval && {
          collectionInterval: this.contractObj.collectionInterval.value
        }),
        ...(this.contractObj.water && {
          water: Number(this.contractObj.water),
          waterPayment: this.contractObj.waterPayment.value
        }),
        ...(this.contractObj.electricity && {
          electricity: Number(this.contractObj.electricity),
          electricityPayment: this.contractObj.electricityPayment.value
        }),
        ...(this.contractObj.gas && {
          gas: Number(this.contractObj.gas),
          gasPayment: this.contractObj.gasPayment.value
        }),
        ...(this.contractObj.duration && {
          duration: this.contractObj.duration.value
        }),
        ...(this.contractObj.rentValue && {
          rentValue: Number(this.contractObj.rentValue)
        }),
        ...(this.contractObj.amount && {
          amount: Number(this.contractObj.amount)
        }),
        electronicRent: {
          hasElectronicRent: !this.isElectronicRentExist,
          rentValue:
            Number(this.contractObj.electronicRent.rentValue) || undefined,
          electronicNumber:
            this.contractObj.electronicRent.electronicNumber || undefined,
          electronicImage:
            this.contractObj.electronicRent.electronicImage || undefined
        },
        ...(this.contractObj.insurance && {
          insurance: Number(this.contractObj.insurance)
        }),
        tax: Number(this.contractObj.tax),
        ...(this.contractSuptypes.length && {
          subTypes: this.contractSuptypes
        }),
        createdBy: this.userData
      }

      return dataToSend
    },
    sm() {
      return this.$vuetify.breakpoint.smAndUp
    },
    xs() {
      return this.$vuetify.breakpoint.xs
    },
    isElectronicRentExist() {
      return Object.values(this.contractObj.electronicRent).every((value) => {
        if (value) {
          return false
        }
        return true
      })
    },
    companyPercentageText() {
      if (this.isRentContract) {
        return 'قيمة نسبة الشركة تحسب بناء علي النسبة المضافة في عقد إدارة الأملاك، يمكنك إضافة عقد إدارة الاملاك لاحقا'
      } else if (this.isManagementContract) {
        return 'قيمة نسبة الشركة تحسب بناء علي النسبة المضافة في عقد إدارة الأملاك'
      }
      return null
    },
    userData() {
      return {
        _id: this.user?._id,
        name: this.user?.name,
        phoneNumber: this.user?.phoneNumber
      }
    },
    firstPartyIsValid() {
      return (this.isRentContract && !this.defaultValuesCheck) ||
        (this.defaultValuesCheck && this.isRentContract && this.isOnlyRenter)
        ? !!(
            this.contractObj.firstParty &&
            this.contractObj.firstParty.idNumber &&
            this.contractObj.firstParty.taxNumber
          )
        : true
    },
    otherPartiesStatus() {
      return this.isRentContract && !this.ownerIsRenter && !this.isOnlyRenter
        ? !!(
            this.contractObj.otherParties[0]?.idNumber &&
            this.contractObj.otherParties[0]?.taxNumber
          )
        : !!this.contractObj.otherParties[0]?.name
    },
    updateClientsIsLoading() {
      return this.updateOtherPartyIsLoading || this.isUpdateLoading
    }
  },
  watch: {
    fixedCommission(currentValue) {
      if (!currentValue) {
        delete this.contractObj.commission
        delete this.contractObj.commissionPeriod
      }
    },
    hasTax(val) {
      if (val) {
        this.contractObj.tax = 15
      } else {
        this.contractObj.tax = 0
      }
    },
    'contractObj.newUserChecked'(newValue) {
      if (!newValue) {
        this.newUser = {}
        this.newAgentFormValid = false
      }
    }
  },
  async mounted() {
    await this.getContractTypes()
    await this.getEnvOwners()
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    onUserTypeChange(otherPartyType) {
      if (otherPartyType.text === 'جديد') {
        this.$set(this.contractObj, 'newUserChecked', true)
        this.$set(this.contractObj, 'otherParties', [{}])
      } else {
        this.$set(this.contractObj, 'newUserChecked', false)
        this.newAgentFormValid = false
      }
    },
    async handlingContractDefaultValues() {
      if (this.defaultValuesCheck) {
        if (this.defaultData.hasOwnProperty('owner')) {
          this.setContractRealestateDefaultValues(this.defaultData)
          await this.getPmContractCompantPercentage(this.defaultData)
        } else if (this.defaultData.hasOwnProperty('phoneNumber')) {
          this.setContractClientDefaultValues(this.defaultData)
          await this.getRealEstates(this.defaultData)
        } else {
          await this.getRealEstates(this.defaultData.account)
          this.setContractClientDefaultValues(this.defaultData.account)
        }
      }
    },
    formatArabicNumber(val, key) {
      this[key] = Number(convertNumbers2English(val))
    },

    setContractRealestateDefaultValues(realestate) {
      // Set realestate values as default
      this.availableRealEstates.push(realestate)
      this.contractObj.realEstate = realestate
      const owner = realestate?.owner
      this.setContractClientDefaultValues(owner)
    },
    setContractClientDefaultValues(owner) {
      // Set Owner values as default
      if (!this.isRentContract || (this.isRentContract && this.isOnlyRenter)) {
        this.AllavailableUsers.push(owner)
        this.contractObj.otherParties = []
        this.contractObj.otherParties.push(owner)
      } else {
        this.allEnvOwners.push(owner)
        this.contractObj.firstParty = owner
        this.expansionPanels = false
      }
    },
    async getPmContractCompantPercentage(realestate) {
      if (this.isRentContract) {
        let query = `realEstate=${realestate._id}&environment=${this.currentEnv._id}`
        query += realestate?.parentId?.length
          ? `&parentId=${realestate?.parentId}`
          : ''
        const response = await contractsService.getPmContract(query)
        this.pmContractCompanyPrecentage =
          response?.data?.contract?.percentage || 0
      }
    },
    getPeriodNmonths(periodName) {
      switch (periodName) {
        case 'monthly':
          return 1
        case 'quarterly':
          return 3
        case 'biannual':
          return 6
        case 'yearly':
          return 12
        case 'twoYears':
          return 24
      }
      return null
    },
    getClientDataBySearchKey(e) {
      this.clientSearchKey = e
      if (this.clientSearchKey) {
        this.getEnvOwners()
      }
    },
    getClientNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.getEnvOwners()
      } else {
      }
    },
    getRenterDataBySearchKey(e) {
      this.renterSearchKey = e
      if (this.renterSearchKey) {
        this.getOtherParties()
      }
    },
    getRenterNextData() {
      if (this.paginationRenter.pageNumber < this.paginationRenter.pagesCount) {
        this.paginationRenter.pageNumber += 1
        this.getOtherParties()
      } else {
      }
    },
    openInput() {
      const el = document.getElementById('uploadFile')
      el.click()
    },
    collectionInterval() {
      const indexOfInterval = this.contractIntervals.indexOf(
        this.contractObj.duration
      )
      this.collectionIntervals = this.contractIntervals.slice(
        0,
        indexOfInterval + 1
      )
    },
    closeDialog() {
      // eslint-disable-next-line vue/no-mutating-props
      this.contractObj = this.initialContractData
      this.$emit('close-contract-dialog', 'close')
    },
    slugify(text) {
      return text
        .toString() // Cast to string
        .toLowerCase() // Convert the string to lowercase letters
        .normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
        .trim() // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
    },
    async getContractTypes() {
      try {
        const [contractTypesResponse, contractIntervalsResponse] =
          await Promise.all([
            await contractsService.getContractTypes(),
            await contractsService.getContractIntervals()
          ])
        this.contractIntervals = contractIntervalsResponse.data.intervals
        this.contractTypes = contractTypesResponse.data.contractTypes.map(
          (c) => ({
            _id: c._id,
            name: c.nameAr,
            nameEn: c.nameEn,
            pic: require(`@/assets/img/svgs/contract/${this.slugify(
              c.nameEn
            )}.svg`),
            activePic: require(`@/assets/img/svgs/contract/${this.slugify(
              c.nameEn
            )}-active.svg`),
            checked: false
          })
        )
        const maintenanceContract =
          contractTypesResponse.data.contractTypes.find((cType) => {
            return cType.nameEn === 'maintenance'
          })
        this.maintenanceContractTypes = maintenanceContract.subTypes.map(
          (c) => ({
            _id: c._id,
            name: c.nameAr,
            nameEn: c.nameEn,
            value: c.code,
            checked: false
          })
        )
      } catch (error) {
        this.addNotification({
          text:
            error.response?.data?.errors?.[0] ||
            error.message ||
            'something went wrong',
          color: 'error'
        })
      }
    },
    async getEnvOwners() {
      let data = '&role=owner'
      if (this.clientSearchKey) {
        data += `&search=${this.clientSearchKey}`
      } else {
        data += `&pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}`
      }
      try {
        const availableOwnersResponse = await EnvService.getAllClients(
          this.currentEnv._id,
          data
        )
        this.paginationClient.pagesCount = Math.ceil(
          availableOwnersResponse?.data?.count / this.paginationClient.pageSize
        )
        this.paginationClient.count = availableOwnersResponse?.data?.count
        this.envOwners = availableOwnersResponse?.data?.users ?? []
        this.allEnvOwners = [...this.allEnvOwners, ...this.envOwners]
      } catch (error) {
        this.addNotification({
          text:
            error.response?.data?.errors?.[0] ||
            error.message ||
            'something went wrong',
          color: 'error'
        })
      }
    },
    async addNewClient() {
      const clientPayload = {
        name: this.newUser.name,
        idNumber: this.newUser.idNumber,
        phoneNumber: this.newUser.phoneNumber.replace(/\s/g, ''),
        clientType: this.newUser.clientType,
        taxNumber: this.newUser.taxNumber,
        environment: {
          _id: this.currentEnv._id,
          name: 'مستاجر عقار',
          role: ['renter']
        }
      }
      const newClient = await EnvService.addClient(clientPayload)
      return newClient.data.user
    },
    async onAddContract() {
      try {
        const contractArgs = this.contractToAdd
        const otherParties = contractArgs.otherParties.map((op) => ({
          ...(op._id && { _id: op._id }),
          ...(op.idNumber && { idNumber: op.idNumber }),
          ...(op.name && { name: op.name }),
          ...(op.phoneNumber && { phoneNumber: op.phoneNumber }),
          ...(op.type && { type: op.type }),
          ...(op.taxNumber && { taxNumber: op.taxNumber })
        }))
        if (!this.hasTax) {
          delete contractArgs.tax
        }
        if (this.contractObj.newUserChecked) {
          const userResponse = await this.addNewClient()
          otherParties[0] = {
            idNumber: userResponse.idNumber,
            name: userResponse.name,
            phoneNumber: userResponse.phoneNumber,
            type: 'renter',
            _id: userResponse._id,
            taxNumber: userResponse.taxNumber
          }
        }
        await contractsService.addContract({ ...contractArgs, otherParties })
        // eslint-disable-next-line vue/no-mutating-props
        this.contractObj = this.initialContractData
        this.$emit('close-contract-dialog')
      } catch (error) {
        if (error.response?.data?.message.includes('already have')) {
          this.addNotification({
            text: `يوجد عقد ${this.contractTypeAr} ساري مسبقا لهذا العقار`,
            color: 'error'
          })
        } else {
          this.addNotification({
            text:
              error.response?.data?.errors?.[0] ||
              error.response?.data?.message ||
              error.message ||
              'حدث خطأ اثناء إضافة العقد يرجى المحاولة مجددا',
            color: 'error'
          })
        }
      }
    },
    async getRealEstates(selectedUser) {
      const realEstatesWithNoContracts =
        await realEstateService.getAvailableRealEstates({
          environment: this.currentEnv._id,
          user: selectedUser._id || selectedUser.id,
          contractType: this.selectedContractType
        })
      this.availableRealEstates =
        realEstatesWithNoContracts.data.availableRealEstates
      if (this.contractObj.realEstate) {
        this.contractObj.realEstate = null
      }
    },
    async onSelectFirstParty(user) {
      this.expansionPanels = false
      this.checkClientData(user)
      if (!Object.keys(user).length && !this.defaultValuesCheck) {
        this.expansionPanels = true
        this.panels = []
        this.contractObj.otherParties = [{}]
      } else if (Object.keys(user).length) {
        if (
          this.ownerIsRenter &&
          this.contractObj?.otherParties[0]?._id &&
          this.isRentContract
        ) {
          this.onSelectOtherParty(this.contractObj.otherParties[0], 'rent', 0)
        }
        await this.getRealEstates(user)
      }
    },
    async onSelectOtherParty(user, selectedContractType, index) {
      this.contractObj.newUserChecked = false
      if (user && selectedContractType !== 'rent' && !this.defaultValuesCheck) {
        await this.getRealEstates(user)
      } else if (user && selectedContractType === 'rent') {
        this.ownerIsRenter = user._id === this.contractObj.firstParty._id
        const isTaxOrIdMissing =
          !!!(user.idNumber && user.taxNumber) && !this.ownerIsRenter
        const typeCheck =
          user.clientType === 'individual' ? 'رقم الهويه' : 'السجل التجاري'
        this.$set(
          this.contractObj.otherParties[index],
          'isTaxOrIdMissing',
          isTaxOrIdMissing
        )
        this.$set(this.contractObj.otherParties[index], 'typeCheck', typeCheck)
      }
    },
    handleContractSubTypeChange() {
      this.maintenanceContractTypes.map((m) => {
        if (this.contractSuptypes.includes(m._id)) {
          m.checked = true
        } else {
          m.checked = false
        }
      })
    },
    async getOtherParties() {
      // get OtherParties
      let data = this.isRentContract ? '&role=renter' : '&role=owner'
      data += `&pageSize=${this.paginationRenter.pageSize}&pageNumber=${this.paginationRenter.pageNumber}`
      const availableClientsResponse = await EnvService.getAllClients(
        this.currentEnv._id,
        data
      )
      this.paginationRenter.pagesCount = Math.ceil(
        availableClientsResponse?.data?.count / this.paginationRenter.pageSize
      )
      this.paginationRenter.count = availableClientsResponse?.data?.count
      this.availableUsers = availableClientsResponse?.data?.users ?? []
      this.AllavailableUsers = [
        ...this.AllavailableUsers,
        ...this.availableUsers
      ]
    },
    async getContractTemplate(contractType) {
      contractType = contractType === 'propertyManagement' ? 'pm' : contractType
      const template = await templatesService.getTemplate(contractType)
      const active = template?.data?.template
        ? template?.data?.template.active
        : false
      this.contractObj.body = active ? template.data.template.body : ''
    },
    async resetDataOnContractTypeChange() {
      this.expansionPanels = true
      this.panels = []
      this.contractSuptypes = []
      this.handleContractSubTypeChange()
      this.AllavailableUsers = []
      this.paginationClient.pageNumber = 1
      this.paginationRenter.pageNumber = 1
      this.contractObj.firstParty = {}
      this.contractObj.otherParties = [{}]
      this.contractObj.newUserChecked = false
      this.managementMaintenance = false
      await this.handlingContractDefaultValues()
    },
    async handleContractTypeChange(nameEn) {
      try {
        // first handle checkbox logic
        this.contractTypes = this.contractTypes.map((ct) => {
          if (ct.nameEn !== nameEn) {
            ct.checked = false
          } else {
            this.contractTypeAr = ct.name
          }
          return ct
        })

        this.$set(
          this.contractObj,
          'title',
          `عقد ${this.contractTypeAr} عقار - `
        )

        this.resetDataOnContractTypeChange()
        if (
          !this.defaultValuesCheck ||
          (this.defaultValuesCheck && this.isRentContract)
        ) {
          this.getOtherParties()
        }
        this.getContractTemplate(nameEn)
      } catch (error) {
        this.addNotification({
          text:
            error.response?.data?.errors?.[0] ||
            error.message ||
            'something went wrong',
          color: 'error'
        })
      }
    },
    newUserAdded(val) {
      this.newUser = val.obj
      this.newAgentFormValid = val.isValid
    },
    addContractImage(e) {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        this.contractImage.image = reader.result
        this.onUpload(file)
      }
      reader.readAsDataURL(file)
    },
    onUpload(file) {
      this.$set(this.contractImage, 'imageIsLoading', true)
      const fd = new FormData()
      fd.append('attachment', file, file.name)
      fd.append('entityType', 'contract')
      fd.append('envId', this.currentEnv._id)
      contractsService
        .addAttachment(fd)
        .then((res) => {
          this.$set(this.contractImage, 'id', res.data.id)
          this.$set(this.contractImage, 'image', res.data.Location)
          this.$set(this.contractImage, 'key', res.data.Key)
          this.$set(
            this.contractObj.electronicRent,
            'electronicImage',
            this.contractImage.image
          )
          this.addNotification({
            text: 'تم إضافة صورة العقد بنجاح',
            color: 'success'
          })
        })
        .catch(() => {
          this.$set(this.contractImage, 'image', '')
          this.addNotification({
            text: 'خطأ في إضافة صورة العقد يرجى المحاولة مجددا',
            color: 'error'
          })
        })
        .finally(() => {
          this.$set(this.contractImage, 'imageIsLoading', false)
        })
    },
    async deleteContractImage() {
      try {
        await contractsService.deleteAttachment(this.contractImage.id)
        this.contractImage = {}
        this.$set(this.contractObj.electronicRent, 'electronicImage', '')
        this.addNotification({
          text: 'تم حذف صورةالعقد بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في حذف صورة العقد',
          color: 'error'
        })
      }
    },
    async completePartiesTab() {
      if (this.isRentContract) {
        if (this.isTaxOrIdMissing) {
          const firstParty = {
            idNumber: this.contractObj.firstParty.idNumber,
            taxNumber: this.contractObj.firstParty.taxNumber
          }
          await this.updateClient(firstParty, this.contractObj.firstParty._id)
          if (!this.isUpdatingClientSucceded) {
            return
          }
        }
        for (let i = 0; i < this.contractObj.otherParties.length; i++) {
          await this.updateOtherParty(this.contractObj.otherParties[i], i)
        }
        if (this.updateOtherPartyFailed) {
          return
        }
      }

      this.tab++
    },
    showOtherPartyMissingData(index) {
      return !!(
        this.isRentContract &&
        !this.contractObj.newUserChecked &&
        this.contractObj.otherParties[index] &&
        this.contractObj.otherParties[index].isTaxOrIdMissing
      )
    },
    async updateOtherParty(party, index) {
      if (
        party._id === this.contractObj.firstParty._id ||
        !party.isTaxOrIdMissing
      ) {
        return
      }
      try {
        this.updateOtherPartyIsLoading = true
        const partyPayload = {
          idNumber: party.idNumber,
          taxNumber: party.taxNumber
        }
        await EnvService.editClient(partyPayload, party._id)
        this.$set(
          this.contractObj.otherParties[index],
          'isTaxOrIdMissing',
          false
        )
        this.addNotification({
          text: 'تم تحديث بيانات الطرف الثاني بنجاح',
          color: 'success'
        })
      } catch {
        this.updateOtherPartyFailed = true
        this.addNotification({
          text: 'حدث خطأ اثناء تحديث بيانات الطرف الثاني ، يرجى المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.updateOtherPartyIsLoading = false
      }
    },
    saveAsDraft() {
      this.draftContract = true
      this.onAddContract()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_add-new-property.scss';

.uploadContainer {
  background: #ffffff;
  border: 1px dashed #efebf2;
  border-radius: 7px;
}

.informativeCard {
  .holder {
    .text {
      .headTitle {
        color: #101010;
      }

      .subTitle {
        color: #737373;
      }
    }
  }
}

.borderedContainer {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.maintenance-switch-container {
  height: 78px;
  display: flex;
  align-items: flex-end;
}

.maintenance-switch {
  position: absolute;
  top: 0;
  left: 0;
}

.top-layer {
  z-index: 9999;
}
</style>
