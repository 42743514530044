export default {
  actions: [
    // Contract TAYPES [ACTIVE, INACTIOVE, RECORDED, DRAFT, PENDING]
    {
      title: 'تفاصيل',
      eligibleContracts: [
        'active',
        'recorded',
        'draft',
        'pending',
        'declined',
        'expired',
        'terminated'
      ],
      clickAction: {
        methodTitle: 'showDetails'
      },
      icon: 'mdi-eye-outline',
      sideDetailsAction: false
    },
    {
      title: 'تأكيد التجديد',
      eligibleContracts: ['approved'],
      clickAction: {
        methodTitle: 'confirmRenew'
      },
      icon: 'mdi-check',
      sideDetailsAction: true,
      color: '#12CAD6',
      excludeCustomer: true
    },
    {
      title: 'تجديد العقد',
      eligibleContracts: ['expired'],
      clickAction: {
        methodTitle: 'confirmExpiredRenew'
      },
      icon: 'mdi-check',
      sideDetailsAction: true,
      color: '#00897b',
      excludeCustomer: true
    },
    {
      title: 'تعديل',
      eligibleContracts: ['pendingRenewal'],
      clickAction: {
        methodTitle: 'openContractModel'
      },
      icon: 'mdi-autorenew',
      sideDetailsAction: true,
      color: '#662D91',
      excludeCustomer: true
    },
    {
      title: 'إعادة إرسال',
      eligibleContracts: ['pending'],
      clickAction: {
        methodTitle: 'resendToClient'
      },
      icon: 'mdi-send',
      sideDetailsAction: true,
      color: '#12CAD6',
      excludeCustomer: true
    },
    {
      title: 'نشر',
      eligibleContracts: ['draft'],
      clickAction: {
        methodTitle: 'openContractModel'
      },
      icon: 'mdi-publish',
      sideDetailsAction: true,
      color: '#12CAD6'
    },
    {
      title: 'بي دي اف',
      eligibleContracts: [
        'active',
        'recorded',
        'pending',
        'expired',
        'terminated'
      ],
      clickAction: {
        methodTitle: 'openPDF'
      },
      icon: 'mdi-export-variant',
      sideDetailsAction: true,
      color: '#662D91'
    },
    {
      title: 'مشاركة',
      eligibleContracts: ['active', 'recorded', 'expired', 'terminated'],
      clickAction: {
        methodTitle: 'sharePdf'
      },
      icon: 'mdi-share',
      sideDetailsAction: true,
      color: '#12CAD6'
    },
    {
      title: 'إنهاء العقد',
      icon: 'mdi-minus-circle-outline',
      color: '',
      iconColor: 'danger',
      eligibleContracts: ['active', 'recorded', 'pending'],
      clickAction: {
        methodTitle: 'terminateContract'
      },
      // img: require('@/assets/img/svgs/cancel.svg'),
      class: 'danger--text cancel',
      sideDetailsAction: true,
      excludeCustomer: true
    },
    {
      title: 'إلغاء التجديد',
      eligibleContracts: ['pendingRenewal', 'approved'],
      clickAction: {
        methodTitle: 'deleteContract'
      },
      img: require('@/assets/img/svgs/cancelCross.svg'),
      sideDetailsAction: true,
      class: 'danger--text cancel',
      color: '',
      excludeCustomer: true
    },
    {
      title: 'إلغاء العقد',
      eligibleContracts: [
        'declined',
        'expired',
        'terminated',
        'active',
        'recorded',
        'pending'
      ],
      clickAction: {
        methodTitle: 'deleteContract'
      },
      icon: 'mdi-close-circle-outline',
      sideHeaderIcon: 'mdi-close',
      // img: require('@/assets/img/svgs/cancelCross.svg'),
      class: ' danger--text',
      sideDetailsAction: true,
      sideHeaderColor: 'white',
      iconColor: 'danger',
      color: 'danger',
      excludeCustomer: true
    },
    {
      title: 'حذف',
      eligibleContracts: ['draft'],
      clickAction: {
        methodTitle: 'deleteContract'
      },
      img: require('@/assets/img/svgs/cancel.svg'),
      class: 'danger--text cancel',
      sideDetailsAction: true,
      color: '',
      excludeCustomer: true
    },
    {
      title: 'ٌقبول',
      eligibleContracts: ['pending'],
      clickAction: {
        methodTitle: 'acceptRejectPendingContract'
      },
      icon: 'mdi-check',
      sideDetailsAction: true,
      color: '#65c467',
      includeCustomer: true,
      isApproved: true
    },
    {
      title: 'رفض',
      eligibleContracts: ['pending'],
      clickAction: {
        methodTitle: 'acceptRejectPendingContract'
      },
      icon: 'mdi-close',
      sideDetailsAction: true,
      color: 'red',
      includeCustomer: true,
      isApproved: false
    }
  ]
}
