<template>
  <v-menu
    bottom
    content-class="globalSearchMenu"
    offset-y
    transition="slide-y-transition "
  >
    <template #activator="{ on, attrs }">
      <v-form>
        <v-text-field
          class="g_search"
          dense
          filled
          hide-details
          placeholder="إبحث عن العقارات والعملاء..."
          v-bind="attrs"
          v-model="search"
          v-on="on"
          @input="$emit('search', search)"
        >
          <template v-slot:append>
            <div class="pointerCursor mt-1">
              <figure>
                <img
                  src="../../assets/img/svgs/search-icon-colored.svg"
                  alt="Search Icon"
                  loading="lazy"
                />
              </figure>
              <!-- search-icon-colored  -->
            </div>
          </template>
        </v-text-field>
      </v-form>
    </template>
    <div v-if="hitsLength">
      <realestateSearcComp :realestates="hits.realestate" />
      <v-divider class="mx-8"></v-divider>
      <clientSearcComp :clients="hits.client" />
      <v-divider class="mx-8"></v-divider>
      <clientSearcComp :clients="hits.team" :team="true" />
      <v-divider class="mx-8"></v-divider>
      <contract-search-comp :bonds="hits.bond" />
      <v-divider class="mx-8"></v-divider>
      <contract-search-comp :bonds="hits.contract" />
      <v-divider class="mx-8"></v-divider>
      <reportsSearchComp :reports="hits.report" />
    </div>
    <div
      class="pa-10 d-flex align-center justify-center flex-column bg-primary"
      v-else
    >
      <span class="grey--text text--lighten-1 mb-4" style="font-size: 80px">
        <img
          src="../../assets/img/svgs/Hectar icon/r-building.svg"
          alt="NO Data Building Image"
          width="50"
          loading="lazy"
        />
      </span>
      <h3 class="grey--text text--darken-3 my-0">
        لا يوجد بحث متاحه بهذا الاسم
      </h3>
    </div>
  </v-menu>
</template>

<script>
import realestateSearcComp from './realestateSearcComp.vue'
import clientSearcComp from './clientSearchComp.vue'
import ContractSearchComp from './contractSearchComp'
import reportsSearchComp from './reportsSearchComp'

export default {
  components: {
    ContractSearchComp,
    realestateSearcComp,
    clientSearcComp,
    reportsSearchComp
  },
  name: 'global-search',
  props: ['hits'],
  data() {
    return {
      search: ''
    }
  },
  computed: {
    hitsLength() {
      const rsults = Object.values(this.hits).find((hit) => hit.length > 0)
      return !!rsults
    }
  }
}
</script>
<style lang="scss" scoped>
.g_search {
  input::placeholder {
    color: #101010 !important;
    opacity: 1;
  }
}

.globalSearchMenu {
  max-height: 740px;
  background: #fff;
  border: 1px solid #efebf2;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: 10px !important;
}
</style>
