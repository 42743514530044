import config from '../config'
import axios from 'axios'

// clients-overdues
const clientOverdue = function (data) {
  const url = `${config.bonds}/contracts/clients-overdues?${data}`
  return axios.get(url)
}

const getClientsCountByStatus = function (accountId) {
  const url = `${config.usersUrl}/clients/count/${accountId}`
  return axios.get(url)
}

const getConfigrations = function () {
  const url = `${config.apiUrl}/config/info`
  return axios.get(url).then((configs) => {
    return configs
  })
}
// Approvals
const getApprovalSettings = function (envId) {
  const url = `${config.usersUrl}/approvals/settings/${envId}`
  return axios.get(url)
}

const updateApprovalSettings = function (data) {
  const url = `${config.usersUrl}/approvals/settings`
  return axios.put(url, data)
}

const getApprovals = function ({ envId }, query = '') {
  const url = `${config.usersUrl}/approvals/${envId}${query}`
  return axios.get(url)
}

const getSingleApproval = function ({ approvalId, envId }) {
  const url = `${config.usersUrl}/approvals/${envId}/id/${approvalId}`
  return axios.get(url)
}
const updateApproval = function (body) {
  const url = `${config.usersUrl}/approvals/${body.id}`
  return axios.put(url, body)
}

const login = function (phoneNumber, password) {
  const data = { phoneNumber, password }
  const url = `${config.accountsUrl}/loginUser`
  return axios.post(url, data)
}

const resetEnvironmentId = function () {
  const url = `${config.accountsUrl}/logout`
  return axios.post(url)
}

const setEnvironmentId = function (environmentId, role = 'employee') {
  const url = `${config.usersUrl}/users/set-logged-user-env`
  return axios.post(url, { environmentId, role })
}

const checkClient = function (body) {
  const url = `${config.usersUrl}/clients/check-client-existing`
  return axios.post(url, body)
}

// Regsiter new user
const checkPhoneNumber = function (phoneNumber) {
  const url = `${config.accountsUrl}/checkUser`
  return axios.post(url, phoneNumber)
}

const confirmPhoneNumber = function (data) {
  const url = `${config.accountsUrl}/confirmRegister`
  return axios.post(url, data)
}

const register = function (user) {
  const url = `${config.accountsUrl}/addUser`
  return axios.post(url, user)
}

const userDetails = function (user) {
  const userData = JSON.parse(localStorage.getItem('tokenData'))
  if (userData && userData.token) {
    const url = `${config.usersUrl}/users/add`
    return axios.post(url, user)
  }
  return null
}

// Forget/Reset Password
const checkPhoneNumberForResetPassword = function (phoneNumber) {
  const url = `${config.accountsUrl}/forgetPassword`
  return axios.post(url, phoneNumber)
}

const confirmForgetCode = function (data) {
  const url = `${config.accountsUrl}/confirmForgetCode`
  return axios.post(url, data)
}

const resetPassword = function (data) {
  const url = `${config.accountsUrl}/resetPassword`
  return axios.post(url, data)
}

const checkPassword = function (accountId, password, newPassword) {
  const reqBody = {
    accountId,
    password,
    newPassword
  }
  const url = `${config.accountsUrl}/password/check`
  return axios.post(url, reqBody)
}

// Get user envs ------------------------------------------------
const getUserEnvs = function (token) {
  if (token) {
    const url = `${config.usersUrl}/userEnvironment`
    return axios.get(url).then((envs) => {
      return envs
    })
  }
  return null
}

// userEnvironment
const userEnvironment = function (id) {
  const url = `${config.usersUrl}/users/getByAccountId/${id}`
  return axios.get(url).then((envs) => {
    return envs
  })
}

// get Clients in Enviroment
const getAllClients = function (envId, params = null) {
  const requestOptions = {
    ...(params && { params })
  }
  const url = envId
    ? `${config.usersUrl}/users/getAll?environmentId=${envId}`
    : `${config.usersUrl}/users/getAll`
  return axios.get(url, requestOptions)
}
// get user types
const getUserTypes = function () {
  const url = `${config.usersUrl}/userTypes/getAll`
  return axios.get(url)
}

// GET SINGLE CLIENTS
const getSingleClient = function (clientId) {
  const url = `${config.usersUrl}/users/get/${clientId}`
  return axios.get(url)
}

// get client types
const getClientTypes = function () {
  const url = `${config.usersUrl}/clienttypes/getAll`
  return axios.get(url)
}

// User -------------------------------------------------------
const getAllUsers = function () {
  const url = `${config.apiUrl}/getAllUsers`
  return axios.get(url)
}

const updateProfile = async function (user) {
  const url = `${config.usersUrl}/users/update/${user._id}`
  return await axios.put(url, user)
}

const updateUser = async function (user, userId) {
  const url = `${config.usersUrl}/users/update/${userId}`
  return await axios.put(url, user)
}

const delUser = async function (userId) {
  const url = `${config.usersUrl}/users/delete/${userId}`
  return await axios.delete(url)
}

const updateEnv = async function (env) {
  const url = `${config.usersUrl}/environments/update/${env._id}`
  return await axios.put(url, env)
}

const getUserInvitation = function ({ invitationId, envId }) {
  const url = `${config.usersUrl}/invitations/get/${invitationId}/${envId}`
  return axios.get(url)
}

const getAllPermissions = function () {
  const url = `${config.usersUrl}/acls/get-permissions`
  return axios.get(url)
}

// bundles
const getBundles = function () {
  const url = `${config.usersUrl}/bundles`
  return axios.get(url)
}

const getUserSubscription = function (environmentId) {
  const url = `${config.usersUrl}/subscriptions/get?environmentId=${environmentId}`
  return axios.get(url)
}

const getUserBundle = function (environmentId) {
  const url = `${config.usersUrl}/subscriptions/get-user-bundle?environmentId=${environmentId}`
  return axios.get(url)
}

const addDocument = function (data) {
  const url = `${config.usersUrl}/attachments`
  return axios.post(url, data)
}

const getDocuments = function (id) {
  const url = `${config.usersUrl}/attachments/${id}`
  return axios.get(url)
}

const deleteDocument = function (id) {
  const url = `${config.usersUrl}/attachments/${id}`
  return axios.delete(url)
}

// Claims
const getAllClaims = function (query, body) {
  const url = `${config.bonds}/contracts/pendingClaims?${query}`
  return axios.post(url, body)
}

//  contact Sales
const contactSales = function (data) {
  const url = `${config.usersUrl}/admins/lead/add`
  return axios.post(url, data)
}

const downloadExcelSheet = function (ExcelSheetType = 'clients') {
  // fixed api coz this on has no "api" string need to fix it
  const url = `https://users-development.herokuapp.com/import-data/templates/${ExcelSheetType}.xlsx`
  return axios.get(url, { responseType: 'blob' })
}

const UploadExcelSheet = function (file) {
  const url = `${config.usersUrl}/data-export`
  return axios.post(url, file)
}

// service Settings
const updateServiceSettings = function ({
  environmentId,
  serviceCode,
  ...body
}) {
  const url = `${config.usersUrl}/environments/${environmentId}/services/${serviceCode}`
  return axios.patch(url, body)
}

const getPotentialClientTypes = function () {
  const url = `${config.usersUrl}/potential-clients/types`
  return axios.get(url)
}
const checkExistingClient = function (query) {
  const url = `${config.usersUrl}/potential-clients/exists${query}`
  return axios.get(url)
}

const addPotentialClient = function (body) {
  const url = `${config.usersUrl}/clients/add`
  // add isLead= true
  return axios.post(url, body)
}
const getPTClientsCountByStatus = function (accountId) {
  const url = `${config.usersUrl}/clients/count/${accountId}?leadsOnly=1`
  return axios.get(url)
}

const getPotentialClients = function (query) {
  const url = `${config.usersUrl}/users/getAll?leadsOnly=1&${query}`
  return axios.get(url)
}
const deletePotentialClients = function (ids) {
  const url = `${config.usersUrl}/potential-clients`
  return axios.delete(url, { data: ids })
}

const converPtToClinet = function (body) {
  const url = `${config.usersUrl}/potential-clients/to-client`
  return axios.post(url, body)
}
export const userService = {
  getConfigrations,
  getPotentialClientTypes,
  addPotentialClient,
  getPotentialClients,
  checkExistingClient,
  login,
  updateServiceSettings,
  setEnvironmentId,
  resetEnvironmentId,
  checkPhoneNumber,
  checkPhoneNumberForResetPassword,
  confirmPhoneNumber,
  confirmForgetCode,
  resetPassword,
  checkPassword,
  register,
  getAllUsers,
  updateProfile,
  updateEnv,
  getUserEnvs,
  getAllClients,
  userDetails,
  userEnvironment,
  getClientTypes,
  getUserTypes,
  getUserInvitation,
  updateUser,
  getSingleClient,
  delUser,
  getAllPermissions,
  getBundles,
  getUserSubscription,
  getUserBundle,
  getClientsCountByStatus,
  clientOverdue,
  addDocument,
  getDocuments,
  deleteDocument,
  contactSales,
  getAllClaims,
  checkClient,
  downloadExcelSheet,
  UploadExcelSheet,
  updateApprovalSettings,
  getApprovals,
  getSingleApproval,
  updateApproval,
  getApprovalSettings,
  converPtToClinet,
  deletePotentialClients,
  getPTClientsCountByStatus
}
