<template>
  <v-dialog
    max-width="580px"
    persistent
    v-model="dialogState_"
    class="reports__v__dialog"
  >
    <v-card class="add-new-property pb-0 add__reports__modal">
      <v-card-title
        class="pt-2 px-4 mb-5 d-flex justify-space-between popup__header"
      >
        <h4 class="ma-0">تحميل البيانات</h4>
        <v-btn class="ma-0" color="grey" fab text @click="closeModel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div class="px-5 pb-5">
        <v-row no-gutters class="mb-6" v-if="!isExcelSheetUploaded">
          <div
            ref="pic"
            @click="handleAttachFile"
            @drop.prevent="handleExport({ file: $event })"
            @dragleave.prevent="dragLeave('pic')"
            @dragover.prevent="dragOver('pic')"
            @dragenter.prevent
            class="pointerCursor pic-wrapper d-flex justify-center align-center w-100 pa-6 mt-3"
          >
            <div class="text-center">
              <v-img
                class="mx-auto"
                max-width="20"
                :src="upload"
                :lazy-src="upload"
              />
              <div class="d-flex">
                <form class="mx-auto" enctype="multipart/form-data">
                  <v-file-input
                    class="mx-auto ma-0 pa-0"
                    ref="inputUpload"
                    @change="handleExport({ file: $event })"
                    type="file"
                    hide-input
                    prepend-icon=""
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </form>
              </div>
              <h4 class="font-14 text--purple">
                إضغط هنا لإدراج الملف الذي تريد تحميله
              </h4>
            </div>
          </div>
        </v-row>
        <v-row
          no-gutters
          class="mb-6 d-flex justify-center align-center"
          v-else
        >
          <div>
            <v-img
              width="55"
              class="mx-auto"
              :lazy-src="afterUploadedMsg.icon"
              :src="afterUploadedMsg.icon"
            ></v-img>
            <h4 class="font-16 text-center max-w-290">
              {{ afterUploadedMsg.text }}
            </h4>
            <h4 class="mt-5 font-14 text-center">
              سوف يتم اعاده توجيهك الي
              <router-link
                :to="{ path: `/dashboard/${this.type}` }"
                class="ml-1 text--underline text--purple"
              >
                قائمه {{ pageType }}
              </router-link>
              بعد
              {{ restTime }}
              ثواني
            </h4>
          </div>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { userService } from '../../services'
import drag from '@/mixins/drag.js'
import { mapState } from 'vuex'

export default {
  name: 'dataExport',
  props: {
    dialogState: {
      type: Boolean
    },
    type: {
      default: 'clients'
    }
  },
  mixins: [drag],
  data: () => {
    return {
      restTime: 5,
      upload: require('@/assets/img/export-data/upload-grey.svg'),
      closeImg: require('@/assets/img/svgs/closeImg.svg'),
      isExcelSheetUploaded: false,
      afterUploadedMsg: {
        text: 'تم إرسال البيانات للمعالجة, برجاء الإنتظار حتي يتم مراجعة البيانات',
        icon: require('@/assets/img/export-data/processingLogo.svg')
      },
      uploadingProcessOn: false,
      timeOutId: 0,
      interval: 0
    }
  },
  methods: {
    closeModel() {
      clearTimeout(this.timeOutId)
      this.dialogState_ = false
    },
    handleAfterUploading() {
      this.interval = setInterval(() => {
        if (this.restTime > 0) this.restTime--
        else return
      }, 1000)
      this.timeOutId = setTimeout(() => {
        this.dialogState_ = false
        this.$router.push(`/dashboard/${this.type}`)
      }, 5000)
    },
    handleAttachFile() {
      if (!this.uploadingProcessOn) this.$refs.inputUpload.$refs.input.click()
    },
    handleFileToUpload(file) {
      const fd = new FormData()
      fd.append('attachments', new Blob([file]), file.name)
      fd.append('phoneNumber', this.user.phoneNumber)
      fd.append('name', this.user.name)
      fd.append('environmentName', this.user.environments[0]?.name)
      fd.append('userType', this.user.type)
      fd.append('subject', 'export')

      return fd
    },
    async handleExport({ file }) {
      try {
        this.uploadingProcessOn = true
        const filePayload = this.handleFileToUpload(file)
        await userService.UploadExcelSheet(filePayload)
        this.isExcelSheetUploaded = true
      } catch (err) {
        this.afterUploadedMsg.text =
          'لم نتمكن من تحميل الملف, برجاء المحاوله مره أخري'
        this.afterUploadedMsg.icon = this.closeImg
      } finally {
        this.uploadingProcessOn = false
        this.handleAfterUploading()
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  computed: {
    ...mapState('accountState', ['user']),
    pageType() {
      return this.type == 'clients' ? 'العملاء' : 'العقارات'
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  }
}
</script>
