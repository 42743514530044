var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:{ userView: _vm.isCustomer }},[_c('tabTableDetails',_vm._b({on:{"addAction":_vm.addAttachment,"preview":_vm.previewAttach,"download":_vm.downloadAttach,"delete":_vm.deleteAttach}},'tabTableDetails',_vm.tabTableDetailsProps,false)),_c('AttachmentsModal',_vm._g({attrs:{"dialogState":_vm.dialogState,"attachTypes":[
      'إشعار دفع',
      'تحويل بنكي',
      'شيك مصرفي',
      'تحويل بنكي - منصة إيجار',
      'اخري'
    ],"isLoading":_vm.isLoading},on:{"close-dialog":function($event){_vm.dialogState = false}}},_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }