export default {
  data() {
    return {
      storageNames: {
        setTableView: 'tableView'
      },
      tableView: {}
    }
  },
  methods: {
    updateSetTableView(type, status) {
      this.tableView[type] = !status
      localStorage.setItem(
        this.storageNames.setTableView,
        JSON.stringify(this.tableView)
      )
    },
    initTableView() {
      const tableView = localStorage.getItem(this.storageNames.setTableView)
      if (tableView) {
        this.tableView = JSON.parse(tableView)
        this.gridView = !this.tableView[this.$route.name]
      }
    }
  },
  created() {
    this.initTableView()
  }
}
