<template>
  <v-col class="mobile__pl__0 d-flex align-center" cols="12" style="gap: 2rem">
    <v-text-field
      :rules="[(v) => !!v ?? 'من فضلك ادخل سبب الرفض']"
      dense
      outlined
      filled
      hide-details
      placeholder="من فضلك ادخل سبب الرفض"
      required
      v-model="reason"
      @input="handelReson"
    ></v-text-field>
  </v-col>
</template>

<script>
export default {
  name: 'RejectReson',
  data: () => ({
    reason: ''
  }),
  methods: {
    handelReson(val) {
      this.$emit('reason', val)
    }
  }
}
</script>
