<template>
  <v-card v-if="showImageCard" class="mb-6 px-6" width="100%">
    <v-card-title
      class="pa-0 py-4 px-5 d-flex align-center justify-content-between"
    >
      <div class="d-flex align-center">
        <div class="font-16 ml-4">صور العقار</div>
      </div>
    </v-card-title>
    <div>
      <v-carousel
        animation
        id="carouselGrid"
        class="mb-4 px-4"
        cycle
        height="300"
        hide-delimiter-background
        hide-delimiters
        v-model="model"
        v-if="allImages.length"
      >
        <template v-slot:prev="{ on, attrs }">
          <button class="carousel-arrows mr-5" v-bind="attrs" v-on="on">
            <v-icon color="#9F9FAC">mdi-chevron-right</v-icon>
          </button>
        </template>
        <template v-slot:next="{ on, attrs }">
          <button class="carousel-arrows next-arrow" v-bind="attrs" v-on="on">
            <v-icon color="#9F9FAC">mdi-chevron-left</v-icon>
          </button>
        </template>

        <v-carousel-item v-for="(image, i) in allImages" :key="i">
          <div
            :style="`height: 300px;
                            background-size: cover !important;
                            background: url('${
                              image.normalImage ? image.normalImage.path : ''
                            }') no-repeat center;
                              border-radius: 10px;
                            `"
          ></div>
        </v-carousel-item>
      </v-carousel>
    </div>

    <v-row class="mb-5 px-5">
      <v-col
        md="2"
        sm="3"
        cols="6"
        v-for="(image, i) in oldImages"
        :key="image.normalImage.path"
      >
        <div class="position-relative add-pic-container">
          <img :src="image.smallImage.path" class="add-pic" loading="lazy" />
          <v-icon
            @click="deleteImage(image, i, true)"
            class="pic-icon position-absolute slide-y-transition"
            color="white"
            v-if="$hasPermission('realestates', 'realestates', 'add')"
          >
            mdi-close
          </v-icon>
        </div>
      </v-col>
      <v-col
        md="2"
        sm="3"
        cols="6"
        v-for="(image, i) in images"
        :key="image._id"
      >
        <div class="position-relative add-pic-container">
          <img :ref="`image`" class="add-pic" />
          <div v-if="!image.file.smallImage" class="lds-ring-container">
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>

          <v-icon
            @click="deleteImage(image.file, i, false)"
            class="pic-icon position-absolute slide-y-transition"
            color="white"
            v-if="$hasPermission('realestates', 'realestates', 'delete')"
          >
            mdi-close
          </v-icon>
        </div>
      </v-col>
      <v-col
        class="d-flex justify-center align-center pic-wrapper"
        md="2"
        sm="3"
        cols="6"
        v-if="
          (images.length || oldImages.length) &&
          $hasPermission('realestates', 'realestates', 'add')
        "
      >
        <v-btn @click="$refs.inputUpload2.click()" class="brub--border" icon>
          <v-icon color="general"> mdi-plus </v-icon>
        </v-btn>
        <input
          @change="addFiles($event)"
          multiple
          accept="image/*"
          ref="inputUpload2"
          type="file"
          v-show="false"
        />
      </v-col>
      <div
        class="pic-wrapper d-flex align-center w-100 pa-6 my-16 flex-wrap"
        @drop.prevent="addFiles($event)"
        @dragleave.prevent="dragLeave('propertyImages')"
        @dragover.prevent="dragOver('propertyImages')"
        @dragenter.prevent
        ref="propertyImages"
        v-else-if="$hasPermission('realestates', 'realestates', 'add')"
      >
        <div>
          <v-btn
            @click="uploadImage"
            class="py-2 px-3 rounded-7 font-weight-bold"
            color="general"
            outlined
          >
            <v-icon color="general"> mdi-plus </v-icon>
            <span class="font-12"> إضافة الصور </span>
          </v-btn>
          <input
            @change="addFiles($event)"
            accept="image/*"
            multiple
            ref="inputUpload"
            type="file"
            v-show="false"
          />
        </div>
        <div class="mr-3">
          <h4 class="font-12">
            اسحب الصور هنا لرفعها أو اضغط على زر إضافة الصور
          </h4>
          <h5 class="text-gray font-12">يمكنك الإضافة حتى ١٠ صور لعقارك</h5>
        </div>
      </div>
    </v-row>
  </v-card>
</template>

<script>
import { realEstateService } from '@/services'
import { mapMutations } from 'vuex'
import drag from '@/mixins/drag.js'

export default {
  name: 'detailsSlider',
  mixins: [drag],
  props: {
    propertyData: {
      required: true
    }
  },
  data() {
    return {
      propertyInfo: {},
      model: 0,
      filterStatus: ['الصور', 'الفيديوهات', 'صور 360'],
      filters: [],
      images: [],
      oldImages: []
    }
  },
  computed: {
    showImageCard() {
      return this.$hasPermission('realestates', 'realestates', 'add')
        ? this.$hasPermission('realestates', 'realestates', 'add')
        : this.images.length || this.oldImages.length
    },
    newImages() {
      return this.images.map((image) => {
        return {
          smallImage: image.file.smallImage,
          normalImage: image.file.normalImage
        }
      })
    },
    allImages() {
      return [...this.oldImages, ...this.newImages]
    }
  },
  watch: {
    propertyData: {
      immediate: true,
      handler() {
        this.propertyInfo = { ...this.propertyData }
        this.oldImages = this.propertyInfo.images || []
        this.images = []
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    uploadImage() {
      if (!this.$hasPermission('realestates', 'realestates', 'update')) {
        return this.addNotification({
          text: 'ليس لديك صلاحيه لتعديل العقار',
          color: 'error'
        })
      }
      return this.$refs.inputUpload.click()
    },
    filterTypes(payload) {
      if (this.filters.find((item) => item === payload)) {
        const index = this.filters.indexOf(payload)
        this.filters.splice(index, 1)
      } else {
        this.filters.push(payload)
      }
      this.getProperties()
    },
    addFiles(e) {
      const files = e.dataTransfer ? e.dataTransfer.files : e.target.files
      const totalAddedImagesExceeded =
        this.oldImages.length + this.images.length + files.length > 10
      if (files.length > 10 || totalAddedImagesExceeded) {
        this.addNotification({
          text: 'لا يمكن اضافه اكثر من 10 صور',
          color: 'error'
        })
      } else {
        const selectedFiles = Object.values(files)
        selectedFiles.forEach((file) => {
          this.images.push({ file, _id: String(Date.now()) + Math.random() })
          const imagesLength = this.images.length - 1
          const reader = new FileReader()
          reader.onload = () => {
            this.$refs.image[imagesLength].src = reader.result
            this.onUpload(file, imagesLength)
          }
          reader.readAsDataURL(file)
        })
      }
    },
    onUpload(image, imageIndex) {
      const fd = new FormData()
      fd.append('id', this.propertyInfo._id)
      fd.append('image', image, image.name)
      realEstateService
        .uploadImages(fd)
        .then((res) => {
          const imageObject = {
            normalImage: res.data.success.normalImage,
            smallImage: res.data.success.smallImage
          }
          this.$set(this.images[imageIndex], 'file', imageObject)
          this.$emit('update-property-image', this.allImages)
          this.addNotification({
            text: 'تم إضافه الصورة بنجاح',
            color: 'success'
          })
        })
        .catch(() => {
          this.images.splice(imageIndex, 1)
          this.addNotification({
            text: 'حدث خطأ ما برجاء إعاده المحاوله',
            color: 'error'
          })
        })
    },
    deleteImage(image, imageIndex, isOldImage) {
      const imageKey = {
        id: this.propertyInfo._id,
        imageKey: image.normalImage.key,
        smallImageKey: image.smallImage.key
      }
      realEstateService.deleteImage(imageKey).then(() => {
        if (isOldImage) {
          this.oldImages.splice(imageIndex, 1)
        } else {
          this.images.splice(imageIndex, 1)
        }
        this.$emit('update-property-image', this.allImages)
        this.addNotification({
          text: 'تم حذف الصورة بنجاح',
          color: 'success'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/lds-ring.scss';

.add-pic-container {
  display: inline-block;
}

//carousel
#carouselGrid {
  .carousel-arrows {
    min-width: 30px !important;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid #efebf2;
    border-radius: 50%;
    padding: 0px !important;
  }
  .next-arrow {
    margin-left: 48px;
  }
}
</style>
