import config from '../config'
import axios from 'axios'
import store from '@/store/index'

axios.defaults.headers.client = 'HectarPlus'

const getContracts = function (query, data = 'pageSize=10&pageNumber=1') {
  const url = `${config.bonds}/contracts/filter?${data}`
  const listContractPermission = store.getters['accountState/hasPermission'](
    'properties',
    'contracts',
    'list'
  )
  if (listContractPermission) {
    return axios.post(url, query).catch((err) => {
      throw new Error(err.response.data.errors[0] || 'حدث خطأ ما')
    })
  }
  return {}
}

const addContract = function (query) {
  const url = `${config.bonds}/contracts`
  return axios.post(url, query)
}

const updateContract = function (query) {
  const url = `${config.bonds}/contracts`
  return axios.put(url, query)
}

const publishContract = function (query) {
  const url = `${config.bonds}/contracts/publish`
  return axios.put(url, query)
}

const getAvailableRealEstates = function (query) {
  const url = `${config.bonds}/contracts/availableForContract`
  return axios.post(url, query)
}
const getInstallmentsPreview = function (query) {
  const url = `${config.bonds}/installments/preview`
  return axios.post(url, query)
}

const terminateContract = function (query) {
  const url = `${config.bonds}/contracts/terminate`
  return axios.post(url, query)
}

const approveRejectContract = function (query) {
  const url = `${config.bonds}/contracts/approve`
  return axios.put(url, query)
}

const getContractTypes = function () {
  const url = `${config.bonds}/contractTypes`
  return axios.get(url)
}

const getContractIntervals = function () {
  const url = `${config.bonds}/contractIntervals`
  return axios.get(url)
}

const getContractsVersions = function (contractId) {
  const url = `${config.bonds}/contracts/${contractId}/versions`
  return axios.get(url)
}
// contract notes /////
const addContractNote = function (query) {
  const url = `${config.bonds}/contracts/notes`
  return axios.post(url, query)
}

const updateContractNote = function (id, query) {
  const url = `${config.bonds}/contracts/notes/${id}`
  return axios.put(url, query)
}

const getContractCountByType = function (query) {
  const url = `${config.bonds}/contracts/count?${query}`
  return axios.get(url)
}

// Pm Contract Company Percentage
const getPmContract = function (queryParams) {
  const url = `${config.bonds}/contracts/get-pm-contract?${queryParams}`
  return axios.get(url)
}

// getContractPayments
const getContractPayments = function (id) {
  const url = `${config.bonds}/contracts/utilities/${id}`
  return axios.get(url)
}

// User Financial cards
const getFinancialData = function (clientId, paramsObj) {
  const url = `${config.bonds}/clients/details/${clientId}`
  return axios.get(url, { params: { ...paramsObj } })
}

// attachments for pm microservice
const getAllAttachments = function (id) {
  const url = `${config.bonds}/attachments/${id}`
  return axios.get(url)
}

const addAttachment = function (body, objData = null) {
  const requestConfig = {}
  if (objData) {
    requestConfig.onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        objData.progress = (progressEvent.loaded / progressEvent.total) * 100
      }
    }
  }
  const url = `${config.bonds}/attachments`
  return axios.post(url, body, requestConfig)
}

const deleteAttachment = function (attachmentId) {
  const url = `${config.bonds}/attachments/${attachmentId}`
  return axios.delete(url)
}

const resendToCLient = function (contractId) {
  const url = `${config.bonds}/contracts/resend/${contractId}`
  return axios.post(url)
}

const deleteContractNote = function (contractId, noteId) {
  const url = `${config.bonds}/contracts/notes/${contractId}`
  return axios.delete(url, { data: { notesIds: [noteId] } })
}

const getContractAmenities = function () {
  const url = `${config.bonds}/admins/contract-amenities`
  return axios.get(url)
}

const getContractFacilities = function () {
  const url = `${config.bonds}/admins/contract-facilities`
  return axios.get(url)
}

const getContractsStatuses = function () {
  const url = `${config.bonds}/contract-statuses`
  return axios.get(url)
}

const checkPmContracts = function (body) {
  const url = `${config.bonds}/contracts/check-pm-contract`
  return axios.post(url, body)
}

// renew contract
const renewContract = function (body) {
  const url = `${config.bonds}/contracts/renew`
  return axios.put(url, body)
}

// renew expired contract
const renewExpiredContract = function (body) {
  const url = `${config.bonds}/contracts/manual-renew`
  return axios.put(url, body)
}

// approve - reject contract
const setApproveRejectContract = function (body) {
  const url = `${config.bonds}/contracts/set-approval`
  return axios.put(url, body)
}

// reasons - for contract cancellation
const contractCancellationReasons = function () {
  const url = `${config.bonds}/contracts/termination-reasons`
  return axios.get(url)
}

const getSingleContract = function (contractId) {
  const url = `${config.bonds}/contracts/filter`
  return axios.post(url, {
    id: contractId
  })
}
const getOneContractDetails = function (contractId) {
  const url = `${config.bonds}/contracts/${contractId}`
  return axios.get(url)
}

export const contractsService = {
  getContracts,
  getContractTypes,
  getContractIntervals,
  addContract,
  getSingleContract,
  getOneContractDetails,
  updateContract,
  publishContract,
  getContractsVersions,
  terminateContract,
  approveRejectContract,
  addContractNote,
  updateContractNote,
  getAvailableRealEstates,
  getContractCountByType,
  getPmContract,
  getContractPayments,
  getFinancialData,
  addAttachment,
  deleteAttachment,
  getAllAttachments,
  resendToCLient,
  deleteContractNote,
  getContractAmenities,
  getContractFacilities,
  getContractsStatuses,
  checkPmContracts,
  getInstallmentsPreview,
  setApproveRejectContract,
  renewContract,
  renewExpiredContract,
  contractCancellationReasons
}
