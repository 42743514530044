<template>
  <v-list class="px-4 py-2 ma-0 scroll" v-if="bonds.length">
    <div class="px-0 d-flex align-center">
      <figure>
        <img
          src="../../assets/img/svgs/report.svg"
          alt="Report Icon"
          loading="lazy"
        />
      </figure>
      <!-- Report icon  -->
      <h5 class="primary--text font-12 mr-2">العقود والسندات</h5>
    </div>
    <div
      :key="i"
      class="realEstatListItem pointerCursor px-1 d-flex align-center"
      v-for="(bond, i) in bonds"
    >
      <img :height="25" :src="propTempPic" :width="30" loading="lazy" />
      <v-list-item-content class="mr-2">
        <h6 class="black--text font-weight-bold font-12 ma-0">
          {{ bond.name }}
        </h6>
        <p class="font-12 ma-0 text-gray">
          {{ bond.type }}
        </p>
      </v-list-item-content>
    </div>
  </v-list>
</template>

<script>
export default {
  name: 'contractSearchComp',
  props: {
    bonds: []
  },
  data() {
    return {
      propTempPic: require('@/assets/img//svgs/bondsV3.svg')
    }
  }
}
</script>
<style lang="scss" scoped>
.realEstatListItem {
  min-height: 56px !important;
  height: 56px !important;
}

.realEstatListItem:hover {
  background: #f9f9f9;
  border-radius: 7px;
}
</style>
