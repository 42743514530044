<template>
  <v-dialog max-width="600px" persistent :value="dialogState">
    <v-card class="add-new-property">
      <v-card-title
        class="px-4 py-2 mb-5 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0">تعديل نسبة الضريبة</h4>
        <v-btn @click.prevent="closeDialog" class="ma-0" color="grey" fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!--content gose here -->
      <div class="px-4 py-2">
        <v-col class="pl-3 mobile__pl__0" cols="12">
          <h6 class="mb-2 mt-0">نسبة الضريبة</h6>
          <v-select
            :items="taxPercentages"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            :placeholder="`${tax}%`"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            class="font-12"
            suffix="%"
            v-model="tax"
          ></v-select>
        </v-col>
      </div>
      <v-card-actions class="pa-1 mt-4">
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 rounded-7"
          color="gery"
          text
          x-large
        >
          <span class="mb-3">إلغاء</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-0 rounded-7 my-3 ml-3"
          @click="$emit('tax-update', tax)"
        >
          <span class="mb-2 ml-1 font-weight-bold"> تأكيد</span>
          <v-icon class="send-icon">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    DueTax: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      taxPercentages: [0, 5, 15],
      tax: null
    }
  },
  watch: {
    dialogState() {
      this.tax = this.DueTax
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>
