export default {
  methods: {
    dragOver(refName) {
      this.$refs[refName].classList.add('border__dashed')
    },
    dragLeave(refName) {
      this.$refs[refName].classList.remove('border__dashed')
    }
  }
}
