<template>
  <v-data-table
    v-if="!gridView"
    :headers="headers"
    :items="bonds"
    hide-default-footer
    mobile-breakpoint="0"
    class="hectar-table"
  >
    <template v-slot:[`item.number`]="{ item }">
      <span class="black--text">
        {{ item.number || '-' }}
      </span>
    </template>

    <template v-slot:[`item.title`]="{ item }">
      <h6
        @click="
          $emit('showDetails', {
            visible: true,
            bond: item,
            terminated: item.terminated.isTerminated
          })
        "
        class="cursor font-14 ma-0 text-truncate max-w-300"
      >
        {{ item.title }}
      </h6>
    </template>

    <template v-slot:[`item.bondType`]="{ item }">
      <div class="d-flex">
        <h5 class="brub--tag mx-auto ma-0 tag-sm text-center py-0 pb-1">
          <span
            class="font-12 font-weight-bold text-truncate"
            v-if="item.bondType.nameAr"
          >
            {{ item.bondType.nameAr }}
          </span>
        </h5>
      </div>
    </template>

    <template v-slot:[`item.realEstate`]="{ item }">
      <h6 class="font-14 ma-0 text-truncate max-w-300">
        {{ item.realEstate.propertyName }}
      </h6>
    </template>

    <template v-slot:[`item.createdAt`]="{ item }">
      <h6 class="font-14 ma-0 text-truncate max-w-300">
        {{ formatDate(item.createdAt) }}
      </h6>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <div class="d-flex">
        <div
          v-if="item.terminated.isTerminated"
          class="mx-auto red--tag mb-1 tabel-tag text-center font-12 font-weight-bold d-flex align-center"
        >
          <v-sheet class="dotted ma-0 pa-0 ml-2" color="#FC4A4A"></v-sheet>
          <span class="mb-1 text-truncate">ملفي</span>
        </div>
        <div v-else class="mx-auto d-flex align-center">
          <div
            class="px-5 green--tag mb-1 tabel-tag text-center font-12 font-weight-bold d-flex align-center"
          >
            <v-sheet class="dotted ma-0 pa-0 ml-2" color="#65C467"></v-sheet>
            <span class="mb-1 text-truncate">ساري</span>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="my-auto mobile__ml__10px">
        <v-menu
          bottom
          content-class="dropdown-menu"
          offset-x
          transition="slide-x-transition"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="options-btn"
              color="lamb"
              icon
              v-bind="attrs"
              v-on="on"
              :disabled="item.terminated.isTerminated"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0 ma-0">
            <v-list-item
              @click="
                $emit('showDetails', {
                  visible: true,
                  bond: item,
                  terminated: item.terminated.isTerminated
                })
              "
            >
              <v-list-item-icon>
                <v-icon>mdi-eye-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>تفاصيل</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item
              @click="$emit('openDialog', item)"
              v-if="$hasPermission('properties', 'bonds', 'update')"
            >
              <v-list-item-icon>
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>تعديل</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <v-list-item
              @click="$emit('downloadPDF', { visible: true, bond: item })"
            >
              <v-list-item-icon>
                <v-icon>mdi-export-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>بي دي اف</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="$emit('sharePdf', item)"
              data-test="social-sharing"
            >
              <v-list-item-icon>
                <v-icon>mdi-share</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>مشاركة</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              @click="$emit('bondDel', item._id)"
              v-if="
                $hasPermission('properties', 'bonds', 'delete') &&
                !item.terminated.isTerminated
              "
            >
              <v-list-item-icon>
                <v-icon color="danger">mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="danger--text"
                  >الغاء السند</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'data-table-bonds',
  mixins: [dateFormat],
  props: {
    bonds: {},
    gridView: {},
    headers: {}
  }
}
</script>
