<template>
  <div>
    <div class="my-6 d-flex justify-space-between align-center">
      <h4>الموافقات ({{ approvel?.levels }})</h4>
      <div class="d-flex justify-end">
        <div class="d-flex">
          <span>
            قام
            <span class="font-weight-bold">
              {{ approvel?.createdBy?.name }}
            </span>
            بإلغاء الطلب
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    approvel: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
