<template>
  <v-card
    class="mainCard mainContractCard px-4 py-5 my-3 justify-space-between"
  >
    <v-row no-gutters>
      <v-col lg="2" class="d-flex">
        <div
          @click="showDetails({ quotation })"
          class="cursor d-flex justify-space-between"
        >
          <div class="ml-3 mt-4">
            <v-img
              style="width: 40px"
              :src="quotationIcon"
              alt="price-quotation"
            />
          </div>
          <div class="min-w-120">
            <h5
              class="mb-3 mt-0 font-20 line__height__1-3 mobile__mw__100 text-truncate max-w-170 min-w-170"
            >
              عرض سعر
            </h5>

            <v-layout align-center>
              <h5
                :class="specifyColor(quotation.status).color"
                class="ma-0 tag-sm text-center py-0 pb-1"
              >
                <span class="font-12 font-weight-bold my-auto text-truncate">
                  {{ specifyColor(quotation.status).nameAr }}
                </span>
              </h5>
            </v-layout>
          </div>
        </div>
      </v-col>
      <v-col
        lg="1"
        class="me-5 d-flex flex-column justify-space-around mobile__ml__15px"
      >
        <h6 class="text-gray ma-0 text-truncate font-12">اسم العميل</h6>
        <h5
          class="mb-3 ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
        >
          {{ quotation.client.name }}
        </h5>

        <h6 class="text-gray ma-0 text-truncate font-12">نوع العرض</h6>
        <h5
          class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
        >
          تأجير
        </h5>
      </v-col>
      <v-col
        class="d-flex me-10 flex-column justify-space-around mobile__ml__15px"
      >
        <h6 class="text-gray ma-0 text-truncate font-12">نوع العقار</h6>
        <p class="ma-0 mb-3 font-weight-medium text-truncate max-w-120">
          {{ quotation.realEstate?.type?.nameAr }}
        </p>
        <template>
          <h6 class="text-gray ma-0 text-truncate font-12">الغرض من العقار</h6>
          <h5
            class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
          >
            {{ quotation.realEstate?.purpose?.nameAr }}
          </h5>
        </template>
      </v-col>
      <v-col
        v-if="notSideView && !getBreakPoint['sm']"
        class="d-flex me-14 justify-space-between align-center mobile__ml__15px"
      >
        <div class="d-flex flex-column h-100 justify-space-around">
          <template>
            <h6 class="text-gray ma-0 text-truncate font-12">يبدا من</h6>
            <h5
              class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
            >
              {{ formatDate(quotation.startDate) }}
            </h5>
          </template>
          <template>
            <h6 class="text-gray ma-0 mt-3 text-truncate font-12">ينتهي في</h6>
            <h5
              class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
            >
              {{ formatDate(quotation.endDate) }}
            </h5>
          </template>
        </div>
      </v-col>
      <v-col
        class="d-flex me-14 flex-column justify-space-around mobile__ml__15px"
      >
        <h6 class="text-gray ma-0 text-truncate font-12">بواسطة</h6>
        <h5
          class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
        >
          {{ user.name }}
        </h5>

        <template>
          <h6 class="text-gray ma-0 mt-3 text-truncate font-12">
            تاريخ الإنشاء
          </h6>
          <h5
            class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
          >
            {{ formatDate(quotation.createdAt) }}
          </h5>
        </template>
      </v-col>
      <v-col
        lg="2"
        class="d-flex flex-column justify-space-around mobile__ml__15px"
      >
        <h6 class="text-gray ma-0 text-truncate font-12">مبلغ العرض</h6>
        <p class="ma-0 font-weight-medium text-truncate max-w-120">
          {{ quotation.details.amount?.toLocaleString() }} ريال
        </p>
        <template>
          <h6 class="text-gray ma-0 mt-3 text-truncate font-12">
            قيمة الضريبة (15%)
          </h6>
          <h5
            class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
          >
            {{ quotation.details.taxAmount?.toLocaleString() }}
          </h5>
        </template>
      </v-col>
      <v-col class="d-flex flex-column justify-space-around mobile__ml__15px">
        <h6 class="text-gray ma-0 text-truncate font-12">
          الإجمالي مع الضريبة
        </h6>
        <p class="ma-0 font-weight-medium text-truncate max-w-120">
          {{ quotation.details.total?.toLocaleString() }} ريال
        </p>
        <template>
          <h6 class="text-gray ma-0 mt-3 text-truncate font-12">
            العمولة المحتسبة
            <span
              >({{
                quotation.commissionPercentage?.toLocaleString() || 0
              }}%)</span
            >
          </h6>
          <h5
            class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
          >
            {{ quotation.commision?.toLocaleString() || 0 }}
          </h5>
        </template>
      </v-col>

      <v-col class="my-auto" v-if="noEdit">
        <v-menu
          content-class="dropdown-menu"
          transition="slide-x-transition"
          bottom
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="lamb"
              class="options-btn"
              icon
              v-bind="attrs"
              v-on="on"
              :disabled="!validActions.length"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(action, i) in validActions"
              @click="callActionFun({ action, quotation })"
              :key="i"
            >
              <v-list-item-icon v-if="action.icon">
                <v-icon :color="action?.iconColor">{{ action.icon }}</v-icon>
              </v-list-item-icon>
              <v-img
                v-if="action.img"
                :src="action.img"
                class="mr-1 ml-2"
                loading="lazy"
                max-width="20"
              />
              <v-list-item-content>
                <v-list-item-title :class="action.class">{{
                  action.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <!-- Multiple selections for delete -->
    <slot />
  </v-card>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import offerSharedData from '@/mixins/offerSharedData'
import { mapState } from 'vuex'

export default {
  name: 'ContractCard',
  props: {
    quotation: {
      type: Object,
      required: true
    },
    noEdit: {
      default: true
    },
    notSideView: {
      default: true
    },
    contractIntervals: {
      default: [],
      required: true
    }
  },
  mixins: [dateFormat, offerSharedData],
  data() {
    return {
      quotationIcon: require('@/assets/img/priceQuotation/priceQuotation.svg')
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'configs']),
    validActions() {
      return this.actions.filter((action) => {
        return this.isAvalidAction({ quotation: this.quotation, action })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_main-card-list.scss';
</style>
