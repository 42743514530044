<template>
  <div>
    <figure class="position-absolute half-circle-1">
      <img :src="halfcircle1" loading="lazy" />
    </figure>
    <figure class="position-absolute half-circle-2">
      <img :src="halfcircle2" loading="lazy" />
    </figure>
    <figure
      class="position-absolute z-index-n1"
      style="top: 2rem; left: 15rem; width: 10rem; height: 10rem"
    >
      <img class="img-fluid" :src="dots" loading="lazy" />
    </figure>
  </div>
</template>

<script>
export default {
  data: () => ({
    halfcircle1: require('@/assets/img/half-circle-1.svg'),
    halfcircle2: require('@/assets/img/half-circle-2.svg'),
    dots: require('@/assets/img/dots-3.svg'),
    dots2: require('@/assets/img/dots-1.svg')
  })
}
</script>
