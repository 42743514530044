<template>
  <v-dialog v-model="userRoleDialog_" persistent max-width="490">
    <v-card class="add-new-property">
      <v-card-title
        class="pl-2 pr-4 pt-3 pb-5 mb-5 d-flex justify-space-between custom__shadow"
      >
        <span class="headline ft">تعديل صلاحية العضو</span>

        <v-btn
          fab
          text
          color="grey"
          class="ma-0 mt-2"
          @click.prevent="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="isValid" onSubmit="return false;">
          <v-select
            v-model="role"
            :rules="[() => !!role || 'من فضلك دور العضو']"
            label="اختر دور العضو"
            prepend-inner-icon="mdi-account-outline"
            :items="['مدير', 'موظف']"
            required
            filled
            rounded
            hide-details
            @keydown.enter="validate"
          ></v-select>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          x-large
          rounded
          :loading="isLoading"
          width="200"
          class="ma-0"
          color="success"
          @click="validate"
        >
          <span class="tf">تعديل</span>
          <v-icon class="send-icon mr-2">mdi-send</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_add-new-property.scss'

export default {
  props: ['userRoleDialog', 'userId'],
  data() {
    return {
      role: null,
      isValid: false,
      isLoading: false
    }
  },
  computed: {
    userRoleDialog_: {
      get() {
        return this.userRoleDialog
      },
      set(value) {
        this.$emit('changeUserRoleDialog', value)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('close-userRole-dialog')
    },
    validate() {
      this.$refs.form.validate()
      if (this.isValid === true) {
        this.editRole()
      }
    },
    editRole() {
      console.log('userIdToEditRole:', this.userId)

      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.closeDialog()
        this.addNotification({
          text: 'تم تعديل صلاحية العضو بنجاح',
          color: 'success'
        })
      }, 1000)
      return false
    }
  }
}
</script>
