<template>
  <v-container v-if="subscriptionData" class="pa-0">
    <v-row class="my-8 tab__px__10px mx-0">
      <v-col class="first slide" :class="bundleCode">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              :color="'rgba(255, 255, 255, 0.1)'"
              class="align-center mx-4 cardsIconContainer"
            >
              <img
                height="17"
                width="17"
                :lazy-src="settingLight"
                :src="settingLight"
                loading="lazy"
              />
            </v-sheet>
            <h4 class="white--text">الإشتراكات</h4>
          </span>
          <v-card-text class="pr-6 ma-0 pa-0">
            <div class="text-right white--text">{{ subscriptionPeriod }}</div>
            <div class="text-rigth white--text">
              {{ bundleNameAr }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide" v-if="isFreeTrialNotEnded">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              :color="'rgba(102, 45, 145, 0.1)'"
              class="align-center mx-4 cardsIconContainer"
            >
              <img
                height="17"
                width="17"
                :lazy-src="calendar"
                :src="calendar"
                loading="lazy"
              />
            </v-sheet>
            <h4>الفتره التجريبيه</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div style="color: black">
              <b class="font-16 upperText"
                >{{ subscription.daysSpentInFreeTrial }} من
                <span class="black--text">{{
                  subscription.totalFreeTrialDays
                }}</span>
              </b>
              <div class="font-12">
                <span class="font-14">متبقي لك </span>
                <b class="font-16">{{ subscription.daysLefttInFreeTrial }}</b>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide" v-if="subscription.daysLeftInSubscription > 0">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              class="align-center mx-4 cardsIconContainer"
              :color="'rgba(101, 196, 103, 0.1)'"
            >
              <img height="16" width="16" :lazy-src="wallet" :src="wallet" />
            </v-sheet>
            <h4>رصيد حساب</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div>
              <span class="font-14 upperText">
                <b class="font-16 black--text"
                  >{{ subscription.daysLeftInSubscription }} يوم</b
                >
                <span class="font-14"> متبقي</span>
              </span>
              <div>
                <b class="font-16 black--text"
                  >{{ subscription.netTotal }} ريال</b
                >
                <span class="font-14"> لتجديد الباقه</span>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              class="align-center mx-4 cardsIconContainer"
              :color="'rgba(18, 202, 214, 0.1)'"
            >
              <img
                height="17"
                width="17"
                :lazy-src="realestate"
                :src="realestate"
              />
            </v-sheet>
            <h4>العقارات</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div style="color: black">
              <div
                v-if="realestateData.max == 'unlimited'"
                style="color: black"
              >
                <span class="font-14 upperText">العقارات</span>
                <div>
                  <b class="font-16"> عدد مفتوح </b>
                </div>
              </div>
              <div v-else-if="realestateData.remaining > 0">
                <div>
                  <b class="font-16 upperText"
                    >{{ realestateData.max - realestateData.remaining }} من
                    <span class="black--text">{{ realestateData.max }}</span>
                  </b>
                </div>
                <div class="font-12">
                  <span class="font-14">متبقي لك </span>
                  <b class="font-16">{{ realestateData.remaining }}</b>
                </div>
              </div>
              <div v-else>
                <div>
                  <b class="font-16 upperText"
                    >{{ realestateData.max }} من
                    <span class="black--text">{{ realestateData.max }}</span>
                  </b>
                </div>
                <div>
                  <b class="font-16">استنفذت عدد العقارات</b>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              class="align-center mx-4 cardsIconContainer"
              :color="'rgba(244, 147, 0, 0.1)'"
            >
              <img height="17" width="17" :lazy-src="client" :src="client" />
            </v-sheet>
            <h4>العملاء</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div style="color: black">
              <div v-if="clientData.max == 'unlimited'" style="color: black">
                <span class="font-14 upperText">العملاء</span>
                <div>
                  <b class="font-16"> عدد مفتوح </b>
                </div>
              </div>
              <div v-else-if="clientData.remaining > 0">
                <div>
                  <b class="font-16 upperText"
                    >{{ clientData.max - clientData.remaining }} من
                    <span class="black--text">{{ clientData.max }}</span>
                  </b>
                </div>
                <div class="font-12">
                  <span class="font-14">متبقي لك </span>
                  <b class="font-16">{{ clientData.remaining }}</b>
                </div>
              </div>
              <div v-else>
                <div>
                  <b class="font-16 upperText"
                    >{{ clientData.max }} من
                    <span class="black--text">{{ clientData.max }}</span>
                  </b>
                </div>
                <div>
                  <b class="font-16">استنفذت عدد العملاء </b>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide" v-if="bundleCode != 'basic-bundle'">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              :color="'rgba(252, 74, 74, 0.1)'"
              class="align-center mx-4 cardsIconContainer"
            >
              <img height="18" width="18" :lazy-src="users" :src="users" />
            </v-sheet>
            <h4>فريق التشغيل</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div style="color: black">
              <div v-if="teamData.max == 'unlimited'" style="color: black">
                <span class="font-14 upperText">فريق التشغيل</span>
                <div>
                  <b class="font-16"> عدد مفتوح </b>
                </div>
              </div>
              <div v-else-if="teamData.remaining > 0">
                <div>
                  <b class="font-16 upperText"
                    >{{ teamData.max - teamData.remaining }} من
                    <span class="black--text">{{ teamData.max }}</span>
                  </b>
                </div>
                <div class="font-12">
                  <span class="font-14">متبقي لك </span>
                  <b class="font-16">{{ teamData.remaining }}</b>
                </div>
              </div>
              <div v-else>
                <div>
                  <b class="font-16 upperText"
                    >{{ teamData.max }} من
                    <span class="black--text">{{ teamData.max }}</span>
                  </b>
                </div>
                <div>
                  <b class="font-16">استنفذت عدد الأعضاء </b>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              :color="'rgba(102, 45, 145, 0.1)'"
              class="align-center mx-4 cardsIconContainer"
            >
              <img height="19" width="19" :lazy-src="report" :src="report" />
            </v-sheet>
            <h4>العقود</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div style="color: black">
              <div v-if="contractData.max == 'unlimited'" style="color: black">
                <span class="font-14 upperText">العقود</span>
                <div>
                  <b class="font-16"> عدد مفتوح </b>
                </div>
              </div>
              <div v-else-if="contractData.remaining > 0">
                <div>
                  <b class="font-16 upperText"
                    >{{ contractData.max - contractData.remaining }} من
                    <span class="black--text">{{ contractData.max }}</span>
                  </b>
                </div>
                <div class="font-12">
                  <span class="font-14">متبقي لك </span>
                  <b class="font-16">{{ contractData.remaining }}</b>
                </div>
              </div>
              <div v-else>
                <div>
                  <b class="font-16 upperText"
                    >{{ contractData.max }} من
                    <span class="black--text">{{ contractData.max }}</span>
                  </b>
                </div>
                <div>
                  <b class="font-16">استنفذت عدد العقود </b>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              :color="'rgba(102, 45, 145, 0.1)'"
              class="align-center mx-4 cardsIconContainer"
            >
              <img height="19" width="19" :lazy-src="report" :src="report" />
            </v-sheet>
            <h4>السندات</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div style="color: black">
              <div v-if="bondData.max == 'unlimited'" style="color: black">
                <span class="font-14 upperText">السندات</span>
                <div>
                  <b class="font-16"> عدد مفتوح </b>
                </div>
              </div>
              <div v-else-if="bondData.remaining > 0">
                <div>
                  <b class="font-16 upperText"
                    >{{ bondData.max - bondData.remaining }} من
                    <span class="black--text">{{ bondData.max }}</span>
                  </b>
                </div>
                <div class="font-12">
                  <span class="font-14">متبقي لك </span>
                  <b class="font-16">{{ bondData.remaining }}</b>
                </div>
              </div>
              <div v-else>
                <div>
                  <b class="font-16 upperText"
                    >{{ bondData.max }} من
                    <span class="black--text">{{ bondData.max }}</span>
                  </b>
                </div>
                <div>
                  <b class="font-16">استنفذت عدد السندات </b>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide">
        <v-card
          height="121"
          width="210"
          v-if="bundleNameAr !== 'الباقة الأساسية'"
        >
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              class="align-center mx-4 cardsIconContainer"
              :color="'rgba(18, 202, 214, 0.1)'"
            >
              <img height="17" width="17" :lazy-src="info" :src="info" />
            </v-sheet>
            <h4>التقارير</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div style="color: black">
              <div v-if="reportData.max == 'unlimited'" style="color: black">
                <span class="font-14 upperText">التقارير</span>
                <div>
                  <b class="font-16"> عدد مفتوح </b>
                </div>
              </div>
              <div v-else-if="reportData.remaining > 0">
                <div>
                  <b class="font-16 upperText"
                    >{{ reportData.max - reportData.remaining }} من
                    <span class="black--text">{{ reportData.max }}</span>
                  </b>
                </div>
                <div class="font-12">
                  <span class="font-14">متبقي لك </span>
                  <b class="font-16">{{ reportData.remaining }}</b>
                </div>
              </div>
              <div v-else>
                <div>
                  <b class="font-16 upperText"
                    >{{ reportData.max }} من
                    <span class="black--text">{{ reportData.max }}</span>
                  </b>
                </div>
                <div>
                  <b class="font-16">استنفذت عدد السندات </b>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              class="align-center mx-4 cardsIconContainer"
              :color="'rgba(244, 147, 0, 0.1)'"
            >
              <img
                height="18"
                width="18"
                :lazy-src="taxBillsIcon"
                :src="taxBillsIcon"
              />
            </v-sheet>
            <h4>الفواتير الضريبيه</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div style="color: black">
              <span class="font-14 upperText">الفواتير الضريبيه</span>
              <div>
                <b class="font-16"> عدد مفتوح </b>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              :color="'rgba(252, 74, 74, 0.1)'"
              class="align-center mx-4 cardsIconContainer"
            >
              <img height="18" width="18" :lazy-src="cloud" :src="cloud" />
            </v-sheet>
            <h4>المرفقات</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div style="color: black">
              <div
                v-if="attachmentsData.max == 'unlimited'"
                style="color: black"
              >
                <span class="font-14 upperText">المرفقات</span>
                <div>
                  <b class="font-16"> عدد مفتوح </b>
                </div>
              </div>
              <div v-else-if="attachmentsData.remaining > 0">
                <div>
                  <b class="font-16 upperText"
                    >{{
                      parseInt(attachmentsData.max - attachmentsData.remaining)
                    }}
                    من
                    <span class="black--text">{{ attachmentsData.max }}</span>
                  </b>
                </div>
                <div class="font-12">
                  <span class="font-14">متبقي لك </span>
                  <b class="font-16">{{
                    parseInt(attachmentsData.remaining)
                  }}</b>
                </div>
              </div>
              <div v-else>
                <div>
                  <b class="font-16 upperText"
                    >{{ attachmentsData.max }} من
                    <span class="black--text">{{ attachmentsData.max }}</span>
                  </b>
                </div>
                <div>
                  <b class="font-16">استنفذت المساحة </b>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="slide">
        <v-card height="121" width="210">
          <span class="d-flex align-center mt-1 mb-2">
            <v-sheet
              :color="'rgba(252, 74, 74, 0.1)'"
              class="align-center mx-4 cardsIconContainer"
            >
              <img height="18" width="18" :lazy-src="mobile" :src="mobile" />
            </v-sheet>
            <h4>الرسائل</h4>
          </span>
          <v-card-text class="pr-4 ma-0 pa-0">
            <div style="color: black">
              <div v-if="smsData.max == 'unlimited'" style="color: black">
                <span class="font-14 upperText">الرسائل</span>
                <div>
                  <b class="font-16"> عدد مفتوح </b>
                </div>
              </div>
              <div v-else-if="smsData.remaining > 0">
                <div>
                  <b class="font-16 upperText"
                    >{{ smsData.max - smsData.remaining }}
                    من
                    <span class="black--text">{{ smsData.max }}</span>
                  </b>
                </div>
                <div class="font-12">
                  <span class="font-14">متبقي لك </span>
                  <b class="font-16">{{ smsData.remaining }}</b>
                </div>
              </div>
              <div v-else>
                <div>
                  <b class="font-16 upperText"
                    >{{ smsData.max }} من
                    <span class="black--text">{{ smsData.max }}</span>
                  </b>
                </div>
                <div>
                  <b class="font-16">استنفذت الرسائل </b>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'HomeCards',
  data() {
    return {
      settingLight: require('@/assets/img/svgs/settingLight.svg'),
      calendar: require('@/assets/img/svgs/calendar.svg'),
      wallet: require('@/assets/img/svgs/wallet.svg'),
      realestate: require('@/assets/img/svgs/realestate.svg'),
      client: require('@/assets/img/svgs/coloredClient.svg'),
      report: require('@/assets/img/svgs/report.svg'),
      users: require('@/assets/img/svgs/coloredUsers.svg'),
      info: require('@/assets/img/svgs/info.svg'),
      upload: require('@/assets/img/svgs/upload.svg'),
      taxBillsIcon: require('@/assets/img/svgs/fileWithArrow.svg'),
      mobile: require('@/assets/img/svgs/mobile.svg'),
      twitter: require('@/assets/img/svgs/twitter.svg'),
      build: require('@/assets/img/svgs/build.svg'),
      cloud: require('@/assets/img/svgs/cloud.svg'),
      file: require('@/assets/img/svgs/file.svg')
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'subscription', 'bundleFeautres']),
    subscriptionData() {
      return this.subscription?.bundle
    },
    remainBundleData() {
      return this.bundleFeautres
    },
    bundleCode() {
      return this.subscriptionData?.code
    },
    bundleNameAr() {
      return this.subscriptionData?.nameAr
    },
    realestateData() {
      return {
        max: this.subscriptionData?.features?.realestates,
        remaining: this.remainBundleData?.realestates
      }
    },
    clientData() {
      return {
        max: this.subscriptionData?.features?.clients,
        remaining: this.remainBundleData?.clients
      }
    },
    teamData() {
      return {
        max: this.subscriptionData?.features?.team,
        remaining: this.remainBundleData?.team
      }
    },
    contractData() {
      return {
        max: this.subscriptionData?.features?.contracts,
        remaining: this.remainBundleData?.contracts
      }
    },
    bondData() {
      return {
        max: this.subscriptionData?.features?.bonds,
        remaining: this.remainBundleData?.bonds
      }
    },
    reportData() {
      return {
        max: this.subscriptionData?.features?.reports,
        remaining: this.remainBundleData?.reports
      }
    },
    attachmentsData() {
      return {
        max: this.subscriptionData?.features?.attachments,
        remaining: this.remainBundleData?.attachments
      }
    },
    smsData() {
      return {
        max: this.subscriptionData?.features?.sms,
        remaining: this.remainBundleData?.sms
      }
    },
    xl() {
      return this.$vuetify.breakpoint.xlOnly
    },
    lg() {
      return this.$vuetify.breakpoint.lgOnly
    },
    md() {
      return this.$vuetify.breakpoint.mdOnly
    },
    sm() {
      return this.$vuetify.breakpoint.smOnly
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly
    },
    subscriptionPeriod() {
      return this.subscription?.period === 12 ? 'اشتراك سنوي' : 'اشتراك شهري'
    },
    isFreeTrialNotEnded() {
      return (
        this.subscription?.isFreeTrial &&
        this.subscription?.daysSpentInFreeTrial <=
          this.subscription?.totalFreeTrialDays
      )
    }
  },
  methods: {
    getUserBundle() {
      this.$store.dispatch('accountState/getUserBundle')
    }
  },
  created() {
    this.getUserBundle()
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_statistics-cards.scss';
@import '@/styles/responsiveness/responsiveness.scss';
.slide {
  flex-grow: 0 !important;
  padding: 0;
  margin-inline-end: 1.5rem;
  margin-bottom: 1.5rem;
  animation: slide-left;
  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      animation-duration: calc($i/10) + s;
    }
  }
  @for $i from 5 through 5 {
    &:nth-child(#{$i}) {
      margin-inline-end: 0;
      @include max-xl {
        margin-inline-end: 1.5rem;
      }
    }
  }
  @for $i from 10 through 10 {
    &:nth-child(#{$i}) {
      margin-inline-end: 0;
      @include max-xl {
        margin-inline-end: 1.5rem;
      }
    }
  }
  @include max-xxl {
    margin-inline-end: 1rem;
    &.col {
      @for $i from 5 through 5 {
        &:nth-child(#{$i}) {
          margin-inline-end: 1rem;
        }
      }
      // @for $i from 10 through 10 {
      //     &:nth-child(#{$i}) {
      //       margin-inline-end: 1rem;
      //   }
      // }
      // .v-card {
      //   &.v-sheet {
      //     width: 192px !important;
      //   }
      // }
    }
  }
  @include max-xl {
    &.col {
      @for $i from 4 through 4 {
        &:nth-child(#{$i}) {
          margin-inline-end: 0;
        }
        @include max-lg {
          &:nth-child(#{$i}) {
            margin-inline-end: 1rem;
          }
        }
      }

      @for $i from 8 through 8 {
        &:nth-child(#{$i}) {
          margin-inline-end: 0;
        }
        @include max-lg {
          &:nth-child(#{$i}) {
            margin-inline-end: 1rem;
          }
        }
      }
      @for $i from 9 through 9 {
        @include max-lg {
          &:nth-child(#{$i}) {
            margin-inline-end: 0;
          }
        }
      }
      .v-card {
        &.v-sheet {
          width: 204px !important;
          @include max-lg {
            width: 236px !important;
            @include max-md {
              width: 255px !important;
              @include max-sm {
                width: 100% !important;
                min-width: 280px;
              }
            }
            //  @include max-sm
          }
          //  @include max-md
        }
      }
    }
    // .col
  }
  //  @include max-xl
}
@keyframes slide-left {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
