<template>
  <div class="d-flex flex-column">
    <MessageCard
      v-for="message in messages"
      :key="message._id"
      :threadData="message"
      :container="true"
    />
  </div>
</template>

<script>
import MessageCard from './MessageCard.vue'

export default {
  name: 'MessageCardContainer',
  components: {
    MessageCard
  },
  props: {
    messages: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss"></style>
