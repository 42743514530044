<template>
  <v-dialog max-width="600px" persistent :value="dialogState">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="py-1 px-5 mb-3 d-flex justify-space-between custom__shadow"
      >
        <h3 class="ma-0 font-weight-light black--text py-3">
          أرسل رسالة ل{{ clientName }}
        </h3>
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container>
        <v-row no-gutters>
          <v-col class="pl-3 pr-4" cols="12" sm="12">
            <v-form v-model="isValid">
              <v-card-text class="pa-0">
                <h4 class="font-weight-light mt-0 mb-5 font-18">نص الرسالة*</h4>
                <v-textarea
                  v-model="message.messageContent"
                  dense
                  filled
                  hide-details="auto"
                  placeholder="ادخل محتوى الرسالة"
                  auto-grow
                  no-resize
                  rows="3"
                  :rules="[(v) => !!v || 'من فضلك ادخل محتوى الرساله']"
                ></v-textarea>
                <h4 class="font-weight-light mb-5 font-18">إرسال من خلال*</h4>
                <v-select
                  class="mb-5"
                  :rules="[(v) => !!v || `من فضلك قم بتحديد قناه الإرسال`]"
                  :items="messageOptions"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  placeholder="إرسال من خلال"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="nameAr"
                  item-value="nameEn"
                  required
                  v-model="message.messageOption"
                ></v-select>
              </v-card-text>
            </v-form>
            <v-card-actions class="pa-0 mt-2">
              <v-btn
                :disabled="!isValid"
                :loading="sendInPeogress"
                class="w-100"
                height="50"
                elevation="0"
                color="primary"
                @click="sendMessage"
              >
                <span> إرسال الرسالة </span>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { messageService } from '@/services'

export default {
  name: 'SendMessageModal',
  props: {
    client: {
      default() {
        return {}
      }
    },
    dialogState: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isValid: false,
      sendInPeogress: false,
      messageOptions: [
        { nameAr: 'رسالة نصية', nameEn: 'sms' },
        { nameAr: 'رسالة داخلية', nameEn: 'local' }
        // { nameAr: 'رسالة واتساب', nameEn: 'whatsapp' }
      ],
      message: {}
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    clientName() {
      return this.client?.name ?? 'هذا العميل'
    },
    messagePayload() {
      const message = {
        environment: this.currentEnv._id,
        body: this.message?.messageContent,
        ...this.getMessagePayload
      }
      return message
    },
    getMessagePayload() {
      if (this.message.messageOption == 'sms') {
        return {
          channel: 'sms',
          phoneNumber: this.client.phoneNumber
        }
      } else if (this.message.messageOption == 'local') {
        return {
          channel: 'local',
          sender: {
            id: this.user._id,
            name: this.user.name,
            phoneNumber: this.user.phoneNumber
          },
          receiver: {
            id: this.client._id,
            name: this.client.name,
            phoneNumber: this.client.phoneNumber
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    async sendMessage() {
      try {
        this.sendInPeogress = true
        await messageService.sendMessageToClient(this.messagePayload)
      } catch {
        this.addNotification({
          text: 'حدث خطأ أثناء ارسال الرساله, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.closeDialog()
        this.sendInPeogress = false
        this.$root.$confirm.open({
          title: 'تم إرسال الرسالة النصية بنجاح',
          cancelButtonStatus: false,
          confirmTxt: 'تم',
          img: {
            path: require('@/assets/img/svgs/sms-success.svg'),
            width: 58,
            height: 59
          }
        })
      }
    },
    closeDialog() {
      this.$emit('closeMessageModal')
    },
    changeMessageOption(messageOption) {
      this.message.messageOption = messageOption
    }
  }
}
</script>
