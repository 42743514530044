<template>
  <v-dialog
    class="backdrop"
    overlay-opacity="0.5"
    persistent
    v-model="extraUnitsState_"
    width="570px"
  >
    <v-card class="add-new-property pt-0">
      <v-card-title
        class="pa-0 pr-5 pl-1 mb-5 d-flex justify-space-between align-center custom__shadow"
      >
        <div class="d-flex align-center">
          <h4>اضافه المزيد من الوحدات داخليه</h4>
        </div>

        <v-btn
          data-test="close"
          @click.prevent="closeModel"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-form ref="form" v-model="isValid" class="px-5 pb-4">
            <v-row align-content-sm no-gutters>
              <v-col
                v-for="unit in realestateUnits"
                :key="unit._id"
                class="pl-1"
                :cols="6"
                :sm="6"
              >
                <h6 :class="{ 'red--flag': validationFiring }" class="mb-2">
                  {{ unit.title || unit.nameAr }}
                </h6>
                <v-text-field
                  :data-test="unit.key"
                  dense
                  filled
                  hide-details="auto"
                  :placeholder="unit.title"
                  type="number"
                  min="0"
                  :rules="positiveRule"
                  v-model="unitsData[`${unit.key}`]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <v-card-actions
            class="pa-4 d-flex align-center justify-space-between"
          >
            <v-spacer />
            <v-btn
              data-test="addUnits"
              @click="addUnits"
              class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
              text
            >
              <span class="mb-1 ml-1">إضافه الوحدات</span>
              <v-icon class="font-16">mdi-arrow-left</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'addExtraUnits',
  props: {
    extraUnitsState: {
      type: Boolean,
      required: true
    },
    buildingType: {
      type: Object,
      required: true
    },
    realEstatePurpose: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isValid: false,
      tab: 0,
      positiveRule: [
        (val) => {
          if (val && (!Number.isInteger(Number(val)) || Number(val) < 0)) {
            return 'من فضلك تأكد من الرقم'
          }
          return true
        }
      ],
      unitsData: {},
      UpdateBtnClicked: false
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeModel() {
      this.unitsData = {}
      this.UpdateBtnClicked = false
      this.$emit('closeModel')
    },
    addUnits() {
      if (this.isValid) {
        this.UpdateBtnClicked = true
        if (this.isValidForm) {
          this.$emit('addUnits', this.dataToSend)
          this.UpdateBtnClicked = false
        } else {
          this.addNotification({
            text: 'يجب علي الاقل اضافه بيانات حقل واحد',
            color: 'error'
          })
        }
      } else {
        this.addNotification({
          text: 'من فضلك تأكد من صحة البيانات',
          color: 'error'
        })
      }
    }
  },
  watch: {
    extraUnitsState(val) {
      if (!val) {
        this.unitsData = {}
      }
    }
  },
  computed: {
    realestateUnits() {
      return this.realEstatePurpose.length
        ? this.buildingType?.ref[this.realEstatePurpose]
        : []
    },
    unitsPayload() {
      let obj = {}
      this.realestateUnits.map((unit) => {
        obj = {
          ...obj,
          [unit.key]: this.unitsData[unit.key]
            ? Number(this.unitsData[unit.key])
            : 0
        }
      })
      return obj
    },
    dataToSend() {
      const dataToSend = {
        ...this.unitsPayload
      }
      return dataToSend
    },
    isValidForm() {
      const atLeastOneUnit = Object.values(this.unitsData).find((u) => !!u)
      return !!atLeastOneUnit
    },
    validationFiring() {
      return this.UpdateBtnClicked ? !this.isValidForm : false
    },
    extraUnitsState_: {
      get() {
        return this.extraUnitsState
      },
      set(value) {
        this.$emit('changeExtraUnitsState', value)
      }
    }
  }
}
</script>
