<template>
  <v-tabs align-with-title color="primary" right show-arrows v-model="tab">
    <v-tabs-slider color="primary"></v-tabs-slider>
    <v-tab
      v-for="item in notificationsTabs"
      :key="item.title"
      :ripple="false"
      class="mr-6 rounded-15"
    >
      {{ item.template.title }}
    </v-tab>

    <v-tabs-items class="mt-5 profile-tabs" v-model="tab">
      <v-tab-item
        :eager="true"
        v-for="notification in notificationsTabs"
        :key="notification.title"
      >
        <component
          :is="notification.component"
          :template="notification.template"
          :team="notification.team"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import InternalNotifications from '@/components/profile/InternalNotifications'

export default {
  name: 'NotificationsModels',
  components: { InternalNotifications },
  data() {
    return {
      notificationsTabs: [
        {
          template: {
            title: 'إشعارات العملاء',
            description:
              'يمكنك تفعيل إشعارات الفريق ، لتكون على تواصل دائم مع فريقك'
          },
          team: false,
          component: 'InternalNotifications'
        },
        {
          template: {
            title: 'إشعارات الفريق',
            description:
              'يمكنك تفعيل إشعارات الفريق ، لتكون على تواصل دائم مع فريقك'
          },
          team: true,
          component: 'InternalNotifications'
        }
      ],
      // items: ['إشعارات العملاء'],
      tab: 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_env.scss';
</style>
