<template>
  <div>
    <div class="px-5 py-6 mt-5 mobile__pt__0">
      <h3 class="font-weight-bold mt-0 mb-4">العملاء</h3>

      <!-- If basic package -->
      <CardExceedLimit
        v-if="featureNotIncluded"
        v-bind="{ title: notIncluded, subTitle: exceedSubTitle }"
        @changeTab="$emit('routeToSubscription')"
      />

      <UpgradeView v-if="!listPermission" />

      <!-- Loader -->
      <v-row class="mx-0" v-else-if="isClientsLoading">
        <v-col
          cols="12"
          class="property-col mb-2 shadow-border-radius px-0"
          v-for="i in 6"
          :key="i"
        >
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <!--no-team-->

      <div v-else-if="!clients.length" class="no-team">
        <h3>لا توجد عملاء مضافه لديك</h3>
      </div>

      <!--data-table-team-->
      <data-table-client
        v-else
        :headers="headers"
        :users="clients"
        @setCustomerData="setCustomerDataIntoInvitationModel"
      />

      <!--Pagination-->
      <div class="text-center mt-6 mb-5" v-if="viewPagination">
        <Pagination :pagination="pagination" @onChange="loadClients" />
      </div>

      <customerInviteModel
        :dialogState_="dialogState_"
        :customer="customer"
        :isLoading="isLoading"
        @inviteClient="inviteClient"
        @closeDialog="closeInviteDialog"
      />
    </div>
  </div>
</template>

<script>
import DataTableClient from '@/components/clients/DataTableClient.vue'
import Pagination from '@/components/helper/pagination.vue'
import UpgradeView from '@/components/helper/upgradeView.vue'
import CardExceedLimit from '@/components/messages/cardExceedLimit'
import customerInviteModel from '@/components/profile/customer-invite-model.vue'
import { EnvService, customersService } from '@/services'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'invite-sittings',
  components: {
    DataTableClient,
    Pagination,
    customerInviteModel,
    CardExceedLimit,
    UpgradeView
  },
  created() {
    if (this.listPermission) {
      this.loadClients()
    }
  },
  data() {
    return {
      dialogState_: false,
      isLoading: false,
      customer: {},
      clients: [],
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 8,
        count: 0
      },
      isClientsLoading: true,
      dialogStateForClient: false,
      exceedSubTitle:
        'يمكنك ترقية الباقة  الحالية  لباقة أعلى أو التواصل معنا مباشرة في حال كان اشتراكك الحالي في أعلى باقة متاحة',
      notIncluded:
        'هذه الخاصية غير متاحة في الباقة الحالية، يمكنك ترقية الباقة لتتمكن من استخدامها',
      avaliableCustomerModules: {
        reports: ['list'],
        taxInvoices: ['list'],
        bonds: ['list', 'update'],
        assignments: ['list', 'update'],
        contracts: ['list', 'update'],
        realestates: ['list', 'update'],
        requests: ['list', 'add', 'update', 'delete'],
        quotations: ['list']
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'configs', 'subscription']),
    listPermission() {
      return this.$hasPermission('users', 'clients', 'list')
    },
    customerPermissions() {
      const customerPermissions = []
      this.configs.permissions.map((module) => {
        if (
          this.avaliableCustomerModules.hasOwnProperty(module.route) &&
          this.avaliableCustomerModules[module.route].includes(
            module.permission
          )
        ) {
          customerPermissions.push(module._id)
        }
      })
      return customerPermissions
    },
    viewPagination() {
      return this.pagination.pagesCount > 1
    },
    inviteBody() {
      const { phoneNumber, _id, email } = this.customer
      const body = {
        phoneNumber,
        email,
        environmentId: this.currentEnv._id,
        customerId: _id,
        permissions: this.customerPermissions
      }
      return body
    },
    hasPermission() {
      return this.$hasPermission('users', 'clients', 'add')
    },
    featureNotIncluded() {
      return this.subscription.bundle.code === 'basic-bundle'
    },
    headers() {
      const headers = [
        {
          text: 'اسم العميل',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'حاله العميل', value: 'clientType' },
        { text: 'نوع العميل', value: 'environments' },
        { text: 'رقم الهوية/السجل التجاري', value: 'idNumber' },
        { text: 'رقم الجوال', value: 'phoneNumber' }
      ]
      !this.featureNotIncluded &&
        headers.push({ text: 'الحالة', value: 'invite', align: 'center' })
      return headers
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeInviteDialog() {
      this.dialogState_ = false
    },
    recalculatePagination(res) {
      this.pagination.pagesCount = Math.ceil(
        res?.count / this.pagination.pageSize
      )
      this.pagination.count = res?.count
    },
    async loadClients() {
      try {
        const query = `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
        const { data } = await EnvService.getAllClients(
          this.currentEnv._id,
          '',
          query
        )
        this.recalculatePagination(data ?? {})
        this.clients = data?.users
          ? data?.users?.map((user) => ({ ...user, checked: false }))
          : []
      } catch {
        this.addNotification({
          text: 'خطا في تحميل العملاء',
          color: 'error'
        })
      } finally {
        this.isClientsLoading = false
      }
    },
    async clientInvitationStatus() {
      try {
        const { data } = await customersService.clientInvitationStatus(
          this.client._id,
          this.currentEnv?._id
        )
        this.statusInviteClient = data
      } catch {}
    },
    setCustomerDataIntoInvitationModel(customer) {
      this.customer = customer
      this.dialogState_ = true
    },
    async inviteClient({ email }) {
      this.customer.email = email
      this.isLoading = true
      try {
        await customersService.inviteCustomer(this.inviteBody)
        this.addNotification({
          text: 'تم ارسال الدعوه',
          color: 'success'
        })
        this.loadClients()
      } catch {
        this.addNotification({
          text: 'لم نتمكن من ارسال الدعوه برجاء المحاوله لاحقا',
          color: 'error'
        })
      } finally {
        this.isLoading = false
        this.closeInviteDialog()
      }
    },
    refreshClientsData() {
      this.pagination.pageNumber = 1
      this.loadClients()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
</style>
