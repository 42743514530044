<template>
  <v-sheet
    v-if="bundles.length"
    id="env-pic"
    class="pt-2"
    :class="{ 'bundles-container': preview }"
  >
    <!--header-->
    <img
      v-if="!envSetting && !preview"
      src="@/assets/img/logo.svg"
      width="160"
      loading="lazy"
      class="mr-10"
    />
    <div class="d-flex flex-wrap justify-center mobile__mt__10px">
      <v-spacer class="mobile__d__none" />
      <!-- <v-spacer v-for="i in 2" :key="i" class="mobile__d__none"></v-spacer> -->
      <div>
        <h3 class="ma-0 font-weight-medium">باقات بوابة الأعمال</h3>
        <p class="text--1e1e1e ma-0 font-14">
          30 يوم فترة مجانية علي الباقات المدفوعة
        </p>
      </div>
      <v-spacer class="mobile__d__none" />
      <!-- <v-card
        id="toggle-plane"
        class="d-flex justify-space-between align-center"
      >
        <h6
          data-test="monthly"
          :class="{ 'active-plan': bundlePlane === 1 }"
          @click="setBundlesPlane(1)"
          class="ma-0 font-weight-medium"
        >
          شهري
        </h6>
        <h6
          data-test="yearly"
          :class="{ 'active-plan': bundlePlane === 12 }"
          @click="setBundlesPlane(12)"
          class="ma-0 font-weight-medium"
        >
          سنوى
        </h6>
      </v-card> -->
    </div>
    <!-- <div class="free_trial_div d-flex align-center justify-center">
      <h6
        class="ma-0 text-center py-3 px-4 green_bg d-flex justify-center free_trial_title"
      >
        <figure>
          <img
            src="../../assets/img/svgs/percentage-icon.svg"
            alt="Percentage Icon"
            class="ml-2 free_trial_div__percentage--icon img--default--height--width"
            loading="lazy"
            height="18"
            width="18"
          />
        </figure> -->
    <!-- percentage icon  -->
    <!-- وفر شهرين عند اشتراكك في إحدى الباقات السنوية
      </h6>
    </div> -->
    <v-flex d-flex class="mt-10 mobile__mt__0">
      <!--bundles-->
      <subscription
        ref="subscription"
        :bundlePlane="bundlePlane"
        :bundles="bundles"
        :envSetting="envSetting"
        :preview="preview"
        :ignoreBundle="ignoreBundle"
        @setBundle="setSelectedBundle"
        @openSalesContactDialog="$emit('openSalesContactDialog')"
      />
    </v-flex>
  </v-sheet>
</template>

<script>
import { userService } from '@/services'
import { mapMutations } from 'vuex'
import Subscription from './Subscription'

export default {
  name: 'bundles',
  props: {
    envWizard: {
      type: Boolean,
      default: false
    },
    envSetting: {
      type: Boolean,
      default: false
    },
    bundleDuration: {
      type: Number
    },
    preview: {
      type: Boolean,
      default: false
    },
    ignoreBundle: {
      type: String,
      default: ''
    }
  },
  components: {
    Subscription
  },
  data() {
    return {
      bundles: [],
      bundlePlane: 12,
      bundleSlected: false,
      bundle: {}
    }
  },
  watch: {
    bundleDuration: {
      immediate: true,
      handler(val) {
        if (val) {
          this.bundlePlane = val
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    validate() {
      const valid = this.bundleSlected

      if (!valid) {
        this.addNotification({
          text: 'من فضلك تأكد من اختيار الباقه ',
          color: 'error'
        })
      } else {
        this.$emit(
          'on-validate',
          {
            bundle: this.bundle,
            bundlePlane: this.bundlePlane
          },
          !!valid
        )
      }

      return !!valid
    },
    setBundlesPlane(val) {
      this.bundlePlane = val
      this.$emit('bundlePlane', this.bundlePlane)
    },
    async getBundlesData() {
      try {
        const { data } = await userService.getBundles()
        this.bundles = data
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل الباقات المتاحة, بإمكانك التواصل مع المبيعات',
          color: 'error'
        })
      }
    },
    setSelectedBundle({ bundle, period }) {
      if (bundle) {
        this.bundle = bundle
        this.bundleSlected = true
        if (this.envSetting) {
          this.$emit('setBundle', {
            bundle,
            period: this.bundlePlane ?? period
          })
        }
      }
    }
  },
  created() {
    this.getBundlesData()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_env.scss';
</style>
