<template>
  <v-dialog max-width="600px" persistent :value="dialogState">
    <v-card class="add-new-property">
      <v-card-title
        class="px-4 py-2 mb-5 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0">تعديل تاريخ الإستحقاق</h4>
        <v-btn @click.prevent="closeDialog" class="ma-0" color="grey" fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!--content gose here -->
      <div class="px-4 py-2">
        <v-col class="pl-3 mobile__pl__0" cols="12">
          <h6 class="mb-2 mt-0">تاريخ التحصيل*</h6>
          <v-dialog
            :return-value.sync="updatedDate"
            ref="startAtDialog"
            v-model="startAtModal"
            width="320px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :rules="[(v) => !!v || 'من فضلك ادخل تاريخ التحصيل']"
                append-icon="mdi-calendar-month-outline"
                dense
                filled
                hide-details
                placeholder="تاريخ التحصيل"
                v-bind="attrs"
                v-model="updatedDate"
                v-on="on"
                class="date--dropdown font-14"
              ></v-text-field>
            </template>
            <v-date-picker color="primary" scrollable v-model="updatedDate">
              <v-spacer></v-spacer>
              <v-btn @click="startAtModal = false" color="primary" text>
                الغاء
              </v-btn>
              <v-btn
                @click="$refs.startAtDialog.save(updatedDate)"
                color="primary"
                text
                >حفظ
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </div>
      <v-card-actions class="pa-1 mt-4">
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 rounded-7"
          color="gery"
          text
          x-large
        >
          <span class="mb-3">إلغاء</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-0 rounded-7 my-3 ml-3"
          @click="$emit('date-update', updatedDate)"
        >
          <span class="mb-2 ml-1 font-weight-bold"> تأكيد</span>
          <v-icon class="send-icon">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialogState', 'currentDuedate'],
  data() {
    return {
      startAtModal: false,
      start: new Date().toISOString().substr(0, 10),
      updatedDate: new Date().toISOString().substr(0, 10)
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  },
  watch: {
    dialogState(val) {
      if (val) {
        this.updatedDate = new Date(this.currentDuedate)
          .toISOString()
          .substr(0, 10)
      }
    }
  }
}
</script>
