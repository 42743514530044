<template>
  <div>
    <date-picker
      :popup-style="datePickerStyle"
      v-model="time"
      :range="range"
      :shortcuts="range ? shortcuts : []"
      value-type="format"
      format="YYYY-MM-DD"
      ref="range"
      :default-value="new Date()"
      :disabled-date="disabledBeforeTodayAndAfterAWeek"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ar-sa'

export default {
  props: {
    disabledDate: {
      type: Object,
      default: () => {
        return {}
      }
    },
    range: {
      type: Boolean,
      default: true
    },
    datePickerStyle: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      time: [],
      shortcuts: [
        {
          text: 'اليوم',
          code: 'day',
          checked: false,
          onClick() {
            const date = new Date()
            setTimeout(() => {
              this.checked = !this.checked
            })

            return [date, date]
          }
        },
        {
          text: 'هذا الإسبوع',
          code: 'week',
          checked: false,
          onClick() {
            const date = new Date()
            date.setTime(date.getTime() - 7 * 24 * 60 * 60 * 1000)
            setTimeout(() => {
              this.checked = !this.checked
            })
            return [date, new Date()]
          }
        },
        {
          text: 'هذا الشهر',
          code: 'month',
          checked: false,
          onClick() {
            const date = new Date()
            date.setMonth(date.getMonth() - 1)
            setTimeout(() => {
              this.checked = !this.checked
            })
            return [date, new Date()]
          }
        },
        {
          text: 'ربع سنوي',
          code: 'Quarterly',
          checked: false,
          onClick() {
            const date = new Date()
            date.setMonth(date.getMonth() - 3)
            setTimeout(() => {
              this.checked = !this.checked
            })
            return [date, new Date()]
          }
        },
        {
          text: 'نصف سنوي',
          code: 'Biannual',
          checked: false,
          onClick() {
            const date = new Date()
            date.setMonth(date.getMonth() - 6)
            setTimeout(() => {
              this.checked = !this.checked
            })
            return [date, new Date()]
          }
        },
        {
          text: 'سنوي',
          code: 'annual',
          checked: false,
          onClick() {
            const date = new Date()
            date.setMonth(date.getMonth() - 12)
            setTimeout(() => {
              this.checked = !this.checked
            })
            return [date, new Date()]
          }
        },
        {
          text: 'إعادة تعيين',
          code: 'reset',
          checked: false,
          onClick() {
            setTimeout(() => {
              this.checked = !this.checked
            })
            return []
          }
        }
      ],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      }
    }
  },
  components: {
    DatePicker
  },
  watch: {
    time(newDateRange) {
      this.shortcuts.forEach((shortcut) => {
        shortcut.checked = false
      })
      setTimeout(() => {
        const shortCut = this.shortcuts.find(
          (shortcut) => shortcut.checked === true
        )
        if (shortCut?.code === 'reset') {
          this.RangePlaceholder = 'تحديد الفترة'
          this.$emit('date-picker-filter-reset')
        } else {
          this.RangePlaceholder = shortCut?.text
            ? shortCut?.text
            : `من ${newDateRange[0]} إلي ${newDateRange[1]}`
          this.$emit('date-picker-filter-text', this.RangePlaceholder, 'date')
        }
        this.$emit('date-picker-filter-text', newDateRange[0], 'startDate')
        this.$emit('date-picker-filter-text', newDateRange[1], 'endDate')
        if (this.disabledDate?.date) {
          this.$emit('set-new-date')
        }
      })
    }
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek(date) {
      if (this.disabledDate?.date) {
        return (
          date < new Date(this.disabledDate.date) ||
          date > new Date(this.disabledDate.end)
        )
      }
      return false
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/material-dashboard/datepicker.scss';
</style>
