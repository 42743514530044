<template>
  <main>
    <!-----------Report Details------------>
    <ReportDetails
      class="page-main-bg"
      :statusSharePdf="true"
      :clickedReport="clickedReport"
    >
      <!-----------Toolbar------------->
      <div class="d-flex grey--text">
        <p class="mb-8 font-14 cursor" @click="$emit('backToReports')">
          التقارير السابقه
        </p>
        <p class="mx-3">></p>
        <p>{{ clickedReport.title }}</p>
      </div>
    </ReportDetails>
  </main>
</template>

<script>
import ReportDetails from '@/components/reports/ReportDetails'

export default {
  name: 'report',
  components: { ReportDetails },
  props: {
    clickedReport: {
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
  }
}
</script>
