<template>
  <v-container class="section-3 mobile__pt__15px mobile__pb__0" id="feautres">
    <v-row>
      <v-col :cols="mdAndDown ? 12 : 5">
        <div>
          <div class="d-flex">
            <p class="section3-title">خصائص بوابة الأعمال</p>
            <div class="line my-auto mr-3"></div>
          </div>
          <p class="section3-description">
            يتيح لك حساب هكتار المجاني الوصول إلى كل هذا وأكثر
          </p>
        </div>
        <div elevation="0" variant="flat" class="mx-auto border-none">
          <v-carousel
            cycle
            :continuous="false"
            hide-delimiters
            v-model="model"
            height="250"
          >
            <v-carousel-item v-for="(paragraph, i) in carouslaContent" :key="i">
              <div class="d-flex">
                <div class="slider-content">
                  <div class="slider-header-content">
                    <div>
                      <div class="icon-container d-flex">
                        <v-img
                          max-width="24"
                          :src="paragraph.icon"
                          alt="building"
                          class="mx-auto my-auto"
                        />
                      </div>
                      <p class="slider-title my-auto">
                        {{ paragraph.title }}
                      </p>
                    </div>
                    <div class="my-auto">
                      <div @click="model--" class="mx-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="34"
                          height="12"
                          viewBox="0 0 34 12"
                          fill="none"
                          class="iconHovered"
                        >
                          <path
                            :opacity="model == 0 ? '0.2' : '1'"
                            d="M32.7989 6.53033C33.0918 6.23744 33.0918 5.76256 32.7989 5.46967L28.0259 0.696699C27.733 0.403806 27.2581 0.403806 26.9653 0.696699C26.6724 0.989593 26.6724 1.46447 26.9653 1.75736L31.2079 6L26.9653 10.2426C26.6724 10.5355 26.6724 11.0104 26.9653 11.3033C27.2581 11.5962 27.733 11.5962 28.0259 11.3033L32.7989 6.53033ZM0.768555 6.75H32.2686V5.25H0.768555V6.75Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div @click="model++" class="mx-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="33"
                          height="12"
                          viewBox="0 0 33 12"
                          fill="none"
                          class="iconHovered"
                        >
                          <path
                            d="M0.469669 6.53033C0.176777 6.23744 0.176777 5.76256 0.469669 5.46967L5.24264 0.696699C5.53553 0.403806 6.01041 0.403806 6.3033 0.696699C6.59619 0.989593 6.59619 1.46447 6.3033 1.75736L2.06066 6L6.3033 10.2426C6.59619 10.5355 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53553 11.5962 5.24264 11.3033L0.469669 6.53033ZM32.5 6.75H1V5.25H32.5V6.75Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="slider-description-content my-2">
                    <p class="text-wrap">
                      {{ paragraph.subtitle }}
                    </p>
                  </div>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
          <div class="slider-action-content">
            <router-link
              :to="{ path: '/auth/login', query: { auth: 'register' } }"
              v-if="!isLoggedIn"
            >
              <v-btn class="mx-0 register" height="50px" width="200px" text>
                <span class="tf white--text">إبدأ فترتك المجانية</span>
              </v-btn>
            </router-link>
            <router-link :to="{ path: '/dashboard' }" v-else>
              <v-btn outlined rounded class="mx-0 dashboard px-7 py-5">
                <span class="tf">لوحة التحكم</span>
              </v-btn>
            </router-link>
            <v-progress-linear color="primary" v-model="progressBarVal" />
          </div>
        </div>
      </v-col>
      <v-col cols="7" v-if="!mdAndDown">
        <div>
          <v-img :src="vetrImg" class="vert-move" loading="lazy" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FeautesSliderSection',
  data: () => {
    return {
      model: 0,
      vetrImg: require('@/assets/img/running-min.webp'),
      carouslaContent: [
        {
          title: 'العقارات والوحدات',
          icon: require('@/assets/img/svgs/build.svg'),
          subtitle:
            'إضافة العقارات والوحدات وإداراتها وما يتعلق بها من معلومات ، مع الوصول لجميع البيانات الخاصة بالأنشطة المالية والتعاقدات'
        },
        {
          title: 'العملاء',
          icon: require('@/assets/img/svgs/build.svg'),
          subtitle:
            'إضافة العملاء بأنواع متعددة مثل الملاك والمستأجرين ، مع إمكانية إدارة جميع بياناتهم والوثائق الخاصة بهم بطريقة سهلة'
        },
        {
          title: 'العقود',
          icon: require('@/assets/img/svgs/build.svg'),
          subtitle:
            'إنشاء العقود وإدراتها بطريقة سهلة وبخيارات متعددة تضمن دقة البيانات وما ينتج عنها من عمليات مالية متنوعة '
        },
        {
          title: 'السندات',
          icon: require('@/assets/img/svgs/build.svg'),
          subtitle:
            'إصدار سندات قبض وصرف لأنواع متعددة من العمليات والنشاطات لجميع العملاء بناءا على المعلومات المدرجة والعمليات التعقادية المبرمة'
        },
        {
          title: 'التقارير',
          icon: require('@/assets/img/svgs/build.svg'),
          subtitle:
            ' إنشاء تقارير متنوعة بخطوات سهلة ومختصرة مع إمكانية عرض وتحميل ومشاركة التقارير التي تم إنشاءها مسبقا بطريقة عرض مميزة ونماذج عرض متنوعة'
        },
        {
          title: 'المهام',
          icon: require('@/assets/img/svgs/build.svg'),
          subtitle:
            'إدارة المهام الخاصة بأعضاء فريق التشغيل مع إمكانية المتابعة الدقيقة لها عن طريقة تحديد الأولويات وإضافة الوثائق وتبادل الرسائل ووالتواصل الداخلي'
        },
        {
          title: 'مركز المساعدة',
          icon: require('@/assets/img/svgs/build.svg'),
          subtitle:
            'التواصل السريع مع مركز المساعدة  مع إمكانية التواصل الفوري مع خدمة العملاء عن طريق الرسائل الفورية و طلبات الإتصال'
        }
      ]
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    progressBarVal() {
      return (this.model + 1) * (100 / this.carouslaContent.length)
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isLoggedIn() {
      return !!this.currentEnv?._id
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_landing-page.scss';
</style>
