<template>
  <v-card class="pa-0 pb-6">
    <v-card-title>
      <v-row class="py-2 font-16">
        <div class="d-flex align-center">
          <img alt="profile icon" :src="profileIcon" />
          <p class="mr-2 mb-0 account-info font-weight-medium">
            معلومات الحساب
          </p>
        </div>
        <v-spacer />
        <router-link class="profile-link" to="/dashboard/edit-profile/0">
          الملف الشخصي
          <v-icon class="mr-1 font-16">mdi-chevron-left</v-icon></router-link
        >
      </v-row>
    </v-card-title>

    <v-row class="mt-2 details-container">
      <v-col
        class="d-flex justify-space-between align-center"
        v-for="(data, index) in customerData"
        :key="data.label"
      >
        <div>
          <p class="details-title">{{ data.label }}</p>
          <h5 class="font-weight-regular text-truncate">{{ data.value }}</h5>
        </div>
        <v-divider
          v-if="index + 1 !== customerData.length && !xs"
          vertical
        ></v-divider>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CustomerCard',
  data() {
    return {
      profileIcon: require('@/assets/img/customer-landingPage/profile.svg')
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'customerDefaultRole']),
    clientType() {
      return this.user.clientType === 'individual' ? 'فرد' : 'شركة'
    },
    customerData() {
      return [
        {
          label: 'نوع الحساب',
          value: this.clientType
        },
        {
          label: 'رقم الهاتف',
          value: this.user.phoneNumber?.replace('966', '0') || '-'
        },
        {
          label: 'البريد الإلكتروني',
          value: this.user.email || '-'
        },
        {
          label: 'نوع العميل',
          value: this.customerDefaultRole === 'renter' ? 'مستأجر' : 'مالك'
        },
        {
          label: 'الرقم الضريبي',
          value: this.user.taxNumber || '-'
        },
        {
          label: 'السجل التجاري',
          value: this.user.idNumber || this.user.registrationNumber || '-'
        }
      ]
    },
    xs() {
      return this.$vuetify.breakpoint.xs
    }
  }
}
</script>

<style scoped lang="scss">
.account-info {
  color: #737373 !important;
  font-size: 16px !important;
  font-weight: 400;
}
.profile-link {
  font-size: 12px;
  color: #737373 !important;
  font-weight: 500;
}
.details-container {
  padding-right: 30px;
  padding-left: 30px;
  .details-title {
    font-size: 14px !important;
    line-height: 15px;
    color: #737373;
  }
  h5 {
    color: #101010;
    font-size: 16px !important;
  }
  .v-divider--vertical {
    min-height: 85%;
    max-height: 85%;
  }
}
</style>
