const breadcrumbs = [
  {
    text: 'قائمة العملاء المحتملين ',
    disabled: true,
    to: { path: '/dashboard/potential-clients' }
  }
]
const noData = {
  title: 'لا يوجد لديك عملاء محتملين حتى الآن',
  subTitle:
    'يظهر جميع العملاء المحتملين في هذة القائمة مع إمكانية عرض الطلبات الخاصة بكل عميل '
}

const pagination = {
  pageNumber: 1,
  pagesCount: 0,
  pageSize: 10,
  count: 0
}

const headers = [
  {
    text: 'تحديد',
    value: 'checked'
  },
  {
    text: 'اسم العميل',
    align: 'start',
    sortable: false,
    value: 'name'
  },

  {
    text: 'رقم العميل',
    align: 'center',
    value: 'number'
  },
  { text: 'رقم الجوال', value: 'phoneNumber', align: 'center' },

  {
    text: 'نوع الحساب',
    align: 'center',
    value: 'clientType'
  },
  {
    text: 'عدد الطلبات',
    align: 'center',
    sortable: true,
    value: 'requestsCount'
  },
  {
    text: 'صفة العميل',
    align: 'center',
    value: 'environments'
  },
  {
    text: '',
    align: 'center',
    value: 'userId',
    sortable: false
  },
  { text: '', align: 'center', value: 'actions', sortable: false }
]

// PotentialClients
export { breadcrumbs, noData, pagination, headers }

const clientType = [
  {
    _id: 1,
    text: 'فرد',
    code: 'individual'
  },
  {
    _id: 2,
    text: 'شركة',
    code: 'company'
  }
]

// PTModal
export { clientType }

const setRoleLabelStyle = (role) => {
  let baseStyle = 'tabel-tag text-center font-12 px-4 '
  const roleStyles = {
    مالك: 'orange--tag',
    مستأجر: 'brub--tag'
  }
  baseStyle += roleStyles[role] ?? 'grey--tag'
  return baseStyle
}

// PTDataTable
export { setRoleLabelStyle }
