/**
 * Converts Arabic (Eastern) numerals in a string to English numerals.
 * @param {string} string - The input string containing Arabic numerals.
 * @returns {string} A new string with Arabic numerals replaced by their English counterparts.
 */
const convertNumbers2English = (string) => {
  return String(string)?.replace(
    /[\u0660-\u0669\u06f0-\u06f9-]/g,
    (c) => c.charCodeAt(0) & 0xf
  )
}
const ordinalsAr = function (number, isFeminine = false) {
  const num = number % 100 // only handle the lowest 2 digits (1-99) ignore others
  const the = 'ال' // set this to "" if you don't want the output prefixed with letters "ال"
  const unit = num % 10
  const ordinal =
    the +
    [
      ,
      'أولى',
      'ثانية',
      'ثالثة',
      'رابعة',
      'خامسة',
      'سادسة',
      'سابعة',
      'ثامنة',
      'تاسعة',
      'عاشرة'
    ][num === 10 ? num : unit] // letters for lower part of ordinal string
  const female = isFeminine ? 'ة' : '' // add letter "ة" if Feminine
  const ones = (unit === 1 ? `${the}حادي` : ordinal) + female // special cases for 21, 31, 41, etc.
  return num < 11
    ? ordinal + (isFeminine && num === 1 ? 'ى' : female) // from 1 to 10
    : num < 20
    ? `${ones} عشر${female}` // from 11 to 19
    : `${
        unit ? `${ones} و` : '' // else 20 to 99
      }ال${
        // always add "ال"
        [, , 'عشر', 'ثلاث', 'أربع', 'خمس', 'ست', 'سبع', 'ثمان', 'تسع'][ // letters for 20, 30, 40...
          ~~(num / 10)
        ]
      }ون`
}

export { convertNumbers2English, ordinalsAr }
