<template>
  <div>
    <ServicesCard
      v-for="item in servicesConfigs"
      :key="item.id"
      v-bind="{ template: item, isActiveOption: true, icon }"
      @openSettingsModal="toggleModal"
      @closeContractSettingModal="toggleModal"
    />
    <contractSettingModal
      :contractRenewmentProp="servicesConfigs[activeId]"
      :dialogState="dialogState"
      :submitBtnIsLoading="submitBtnIsLoading"
      :hasUpdatePermission="hasUpdatePermission"
      @updateEnv="updateEnv"
      @closeContractSettingModal="toggleModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'contractSetting',
  components: {
    ServicesCard: () => import('../modelUint.vue'),
    contractSettingModal: () => import('./contractSettingModal.vue')
  },
  data: () => ({
    icon: require('@/assets/img/svgs/amenities-checkmark.svg'),
    dialogState: false,
    activeId: -1,
    submitBtnIsLoading: false
  }),
  computed: {
    ...mapState('accountState', ['currentEnv']),
    hasUpdatePermission() {
      return !!this.$hasPermission('users', 'contractsSettings', 'update')
    },
    servicesConfigs() {
      return [
        {
          id: 0,
          title: 'تجديد عقود الإيجار تلقائياً',
          description:
            'يمكن هذا الخيار من تجديد عقود التأجير ألياً من غير الحوجة لإنشاء عقود جديده يديوياً',
          active: this.currentEnv?.rentContractAutoRenewal ?? false,
          days: this.currentEnv?.rentContractAutoRenewalDays ?? 0,
          method: this.currentEnv?.rentContractAutoRenewalMethod ?? 'after',
          type: 'rent'
        }
        // {
        //   id: 1,
        //   title: 'تجديد عقود إدارة الأملاك تلقائياً',
        //   description:
        //     'يمكن هذا الخيار من تجديد عقود إدارة ألياً الأملاك من غير الحوجة لإنشاء عقود جديده يديوياً',
        //   active: this.currentEnv?.pmContractAutoRenewal ?? false,
        //   days: this.currentEnv?.pmContractAutoRenewalDays ?? 0,
        //   method: this.currentEnv?.pmContractAutoRenewalMethod ?? 'after',
        //   type: 'pm'
        // }
      ]
    }
  },
  methods: {
    ...mapActions('accountState', ['updateCurrentEnv']),
    toggleModal(notificationsSettings) {
      this.activeId = !this.dialogState ? notificationsSettings?.id : -1
      this.dialogState = !this.dialogState
    },
    async updateEnv(updatedData) {
      try {
        this.submitBtnIsLoading = true
        await this.updateCurrentEnv(updatedData)
      } catch {
        this.addNotification({
          text: 'خطأ في تحديث بيانات تجديد اعدادت العقد',
          color: 'error'
        })
      } finally {
        this.submitBtnIsLoading = false
        this.toggleModal()
      }
    }
  }
}
</script>
