<template>
  <div class="pa-0 d-flex" v-if="!sm">
    <h6 class="font-weight-light ml-2">ترتيب بواسطة</h6>
    <v-menu
      content-class="vMenuFilter elevation-0"
      offset-y
      transition="slide-y-transition"
      width="150"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center">
          <v-slide-x-transition>
            <v-card
              v-on="on"
              v-bind="attrs"
              class="d-flex justify-space-between px-2"
              width="110"
              @click="toggleShowSortOptions"
            >
              <h6 class="mr-2">
                {{ currentSort }}
              </h6>
              <v-icon
                >{{ showSortOptions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-card>
          </v-slide-x-transition>
        </div>
      </template>
      <v-list
        class="contractVmenuList pa-0"
        v-for="(option, i) in sortOptions"
        :key="i"
        :width="110"
      >
        <v-list-item-group>
          <v-list-item class="px-1">
            <v-list-item-title
              @click="handleSortChange(option.nameEn)"
              class="font-14 mr-1 mb-2 font-weight-medium t"
            >
              {{ option.nameAr }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    sortOptions: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    showSortOptions: false,
    filterQuery: {
      createdAt: 1
    },
    sort: 'Newest'
  }),
  computed: {
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    },
    currentSort() {
      return this.sortOptions.find((sort) => sort.nameEn === this.sort).nameAr
    }
  },
  methods: {
    toggleShowSortOptions() {
      this.showSortOptions = !this.showSortOptions
    },
    handleSortChange(sort) {
      if (sort === this.sort) {
        return
      }
      if (sort === 'Newest') {
        this.filterQuery.createdAt = -1
      } else {
        this.filterQuery.createdAt = 1
      }
      this.sort = sort
      this.$emit('handleSort', this.filterQuery)
    }
  }
}
</script>

<style lang="scss" scoped></style>
