import axios from 'axios'
import config from '../config'

const inviteCustomer = function (body) {
  const url = `${config.usersUrl}/invitations/customer`
  return axios.post(url, body)
}

const accepteRejectEmpInvitaion = function (userId, body) {
  const url = `${config.usersUrl}/invitations/respond/${userId}`
  return axios.post(url, body)
}

const accepteRejectCustomerInvitaion = function (response, body) {
  const url = `${config.usersUrl}/invitations/customer/${response}-invitation`
  return axios.post(url, body)
}

const clientInvitationStatus = function (id, environmentId) {
  const url = `${config.usersUrl}/invitations/account-status/${id}?environment=${environmentId}`
  return axios.get(url)
}

const getRegistredCustomers = function (envId, data) {
  const url = `${config.usersUrl}/users/${envId}/customers?${data}`
  return axios.get(url)
}

export const customersService = {
  inviteCustomer,
  accepteRejectCustomerInvitaion,
  accepteRejectEmpInvitaion,
  clientInvitationStatus,
  getRegistredCustomers
}
