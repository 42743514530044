var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"d-flex flex-column document__bond__contract__list__cards fixed_height"},[_c('v-card-title',{staticClass:"d-flex justify-space-between flex-row pa-4 pb-0 mobile__mb__0"},[_c('div',{class:[
        'font-weight-bold',
        'font-16',
        'text-truncate',
        { 'd-flex': _vm.isSmsMessages },
        'flex-grow-1'
      ]},[_vm._v("\n      "+_vm._s(_vm.title)+"\n      "),(_vm.isSmsMessages && _vm.messagesCount)?_c('span',{staticClass:"mr-1"},[_vm._v("\n        ( "+_vm._s(_vm.messagesCount)+" )\n      ")]):_vm._e()]),(_vm.isAddAction && _vm.showAddRequestForCustomer)?_c('v-btn',{staticClass:"add-btn add__property__btn px-1 py-3 my-3 mx-1",attrs:{"data-test":`file-list-${_vm.subTitle}`,"depressed":"","x-small":""},on:{"click":function($event){return _vm.onOpenModal(_vm.modal)}}},[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-plus")])],1):_vm._e()],1),(_vm.filters.length)?_c('div',[_c('v-slide-group',{attrs:{"show-arrows":"","active-class":"deep-purple--text"}},_vm._l((_vm.filters),function(filter){return _c('v-slide-item',{key:filter,attrs:{"active-class":"deep-purple--text"},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-btn',{staticClass:"white",class:{
            'black--text': !active,
            'deep-purple--text': active,
            'filter-underline': active,
            'font-weight-bold': active
          },attrs:{"depressed":"","input-value":active},on:{"click":toggle}},[_vm._v("\n          "+_vm._s(filter)+"\n        ")])]}}],null,true)})}),1)],1):_vm._e(),(_vm.fileListLoading)?_c('v-row',{staticClass:"d-flex align-center mx-0 pa-0"},[_c('v-progress-circular',{staticClass:"ma-auto",attrs:{"size":40,"color":"primary","indeterminate":""}})],1):(_vm.componentToShow)?_c('div',{class:[
      _vm.filesContainerClass,
      'pt-0',
      'mb-4',
      'flex-grow-1',
      'overflow-y-auto',
      'mobile__mb__0'
    ]},[_c('component',_vm._g(_vm._b({on:{"nextPageTrigger":function($event){return _vm.$emit('nextPageTrigger', _vm.title)},"financialActiveTabChanged":function($event){return _vm.$emit('financialActiveTabChanged', $event)}},scopedSlots:_vm._u([{key:"bankAccountsContent",fn:function(){return [_vm._t("bankAccountsContent")]},proxy:true}],null,true)},'component',_vm.dynamicComponentProps,false),_vm.$listeners))],1):_c('div',{staticClass:"d-flex flex-column justify-center align-center h-100 text-gray"},[_c('h5',[_vm._v("\n      "+_vm._s(_vm.hasListPermission ? _vm.noData : 'ليس لديك صلاحيه لعرض هذه المعلومات')+"\n    ")])]),(_vm.link && _vm.link.name)?_c('v-card-actions',[_c('router-link',{staticClass:"text-center w-100 py-2 font-12",class:[_vm.customerHome ? 'font-weight-medium' : 'font-weight-bold'],attrs:{"to":_vm.redirectLink}},[_c('span',[_vm._v("\n        "+_vm._s(_vm.link.name)+"\n        "),_c('v-icon',{class:{ 'font-16': _vm.customerHome },attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.linkIconName))])],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }