<template>
  <v-autocomplete
    :autofocus="autofocus"
    :item-text="itemText"
    :items="items"
    :loading="loading"
    :multiple="!!multiple"
    :no-data-text="noData"
    :placeholder="placeholder"
    :rules="rules"
    @input="handleInput(content)"
    @blur="$emit('blur')"
    :append-icon="appendIcon"
    :chips="chips"
    deletable-chips
    :hide-details="hideDetails"
    dense
    filled
    item-value="_id"
    loader-height="3"
    v-model="content"
    ref="autoCompleteScrolling"
    :return-object="!!returnObject"
    :class="styleClass"
    :disabled="disabled"
    :id="componentId"
  >
    <template v-slot:no-data>
      <h5 class="px-5">
        {{ noData }}
      </h5>
    </template>
    <template v-slot:append-item>
      <div v-intersect="endIntersect" />
    </template>

    <template v-slot:item="data" v-if="itemData || contractStatus">
      <v-list-item-content>
        <v-list-item-title
          :class="{
            'justify-space-between align-center  pa-0': contractStatus
          }"
          class="minHeight d-flex"
        >
          <p
            v-if="itemData"
            class="ma-0 red--tag bill font-12 font-weight-bold my-auto"
          >
            {{ $subString(data.item[`${itemText}`]) }}
          </p>
          <span class="mx-2 text-black">
            {{ data.item[`${itemText}`] }}
          </span>
          <div v-if="contractStatus" class="d-flex align-center">
            <div
              :class="statusTagColor(data.item?.status)"
              class="bg--65C467 tabel-tag text-center font-12 font-weight-bold d-flex align-center"
            >
              <v-sheet
                :color="statusColor(data.item?.status)"
                class="text--FC4A4A dotted ma-0 pa-0 ml-2"
              />
              <span class="mb-1 text-truncate">{{
                statusNameAr(data.item?.status)
              }}</span>
            </div>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item, index }" v-if="selection">
      <v-chip v-if="index < slectionIndex && slectionIndex === 2">
        <span>{{ item[`${itemText}`] }}</span>
      </v-chip>
      <span v-else-if="index < slectionIndex">{{ item[`${itemText}`] }}</span>
      <span
        v-if="index === slectionIndex"
        class="text-grey text-caption align-self-center"
      >
        (+{{ value.length - slectionIndex }} {{ selectionTemplate }})
      </span>
    </template>
    <template v-slot:selection="{ index }" v-else-if="showLengthData.status">
      {{ getSelectionText(index) }}
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from '../../helpers/debounce'
import { mapGetters } from 'vuex'

export default {
  name: 'autocomplete',
  props: {
    autoCompleteReset: {
      default: false
    },
    componentId: {
      default: ''
    },
    disabled: {
      default: false
    },
    itemData: {
      default: false
    },
    contractStatus: {
      default: false
    },
    styleClass: {
      default: ''
    },
    loading: {
      default: false
    },
    rules: {
      default() {
        return [
          (v) =>
            !!(v && v.length) ||
            !!(v && Object.keys(v).length) ||
            this.validateString
        ]
      }
    },
    value: {},
    autofocus: {
      default: false
    },
    appendIcon: {
      default: 'mdi-chevron-down'
    },
    chips: {
      default: true
    },
    hideDetails: {
      default: false
    },
    items: {
      default: () => {
        return []
      }
    },
    multiple: {
      default: false,
      type: Boolean
    },
    returnObject: {
      default: false,
      type: Boolean
    },
    noData: {
      default: 'لايوجد بيانات متاحه لهذا البحث',
      type: String
    },
    validateString: {
      default: 'هذا الحقل مطلوب',
      type: String
    },
    itemText: {
      default: 'name',
      type: String
    },
    placeholder: {
      default: 'اسم الحقل',
      type: String
    },
    selection: {
      default: false,
      type: Boolean
    },
    selectionTemplate: {
      default: 'أخرون',
      type: String
    },
    slectionIndex: {
      default: 2
    },
    showLengthData: {
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      content: null
    }
  },
  computed: {
    ...mapGetters('accountState', ['contractsStatuses'])
  },
  watch: {
    value(newValue) {
      this.content = newValue
    },
    autoCompleteReset() {
      this.$refs.autoCompleteScrolling?.reset()
      this.$refs.autoCompleteScrolling?.resetValidation()
    }
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.autoCompleteScrolling?.internalSearch
      },
      (val) => {
        this.emitSearcKey(val)
      },
      (this.content = this.value ? this.value : null)
    )
  },
  methods: {
    statusTagColor(status) {
      const { tagColor } = this.contractsStatuses[status]
      return tagColor
    },
    statusColor(status) {
      const { color } = this.contractsStatuses[status]
      return color
    },
    statusNameAr(status) {
      const { nameAr } = this.contractsStatuses[status]
      return nameAr
    },
    getSelectionText(index) {
      if (this.showLengthData.showAllText) {
        return this.showLengthData.allText
      } else if (index === 0) {
        return `${this.showLengthData.length} وحدة / وحدات`
      }
      return ''
    },
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$emit('nextPageTrigger')
      }
    },
    handleInput(e) {
      this.$emit('input', e)
    },
    emitSearcKey: debounce(function (val) {
      // eslint-disable-next-line no-invalid-this
      this.$emit('emitSearchKey', val)
    }, 600)
  }
}
</script>
<style lang="scss" scoped>
.minHeight {
  min-height: 25px;
  padding-top: 5px;
}
.text-black {
  color: #101010;
}
</style>
