<template>
  <div>
    <v-form v-model="isValid" class="w-100">
      <v-row class="my-2" no-gutters v-if="!isLand && !isStorehouse">
        <!-- Rooms -->
        <v-col class="px-1" v-if="!isOffice" cols="6" sm="6">
          <h6 class="mb-2 font-12">
            عدد الغرف
            <span class="grey--text">(إختياري)</span>
          </h6>
          <v-text-field
            data-test="numberOfRooms"
            v-model="addPropertyInfo.numberOfRooms"
            dense
            filled
            hide-details
            placeholder="عدد الغرف"
            required
            @input="formatEnglishToArabic($event, 'numberOfRooms')"
            min="0"
            @keydown="$formatNumberInputs"
            class="font-14"
          ></v-text-field>
        </v-col>
        <!-- Reseptions -->
        <v-col class="px-1" cols="6" :sm="isOffice ? 4 : 6">
          <h6 class="mb-2 font-12">
            عدد الصالات
            <span class="grey--text">(إختياري)</span>
          </h6>
          <v-text-field
            data-test="numberOfLivingRoom"
            v-model="addPropertyInfo.numberOfLivingRoom"
            dense
            filled
            hide-details="auto"
            placeholder="عدد الصالات"
            required
            @input="formatEnglishToArabic($event, 'numberOfLivingRoom')"
            min="0"
            @keydown="$formatNumberInputs"
            class="font-14"
          ></v-text-field>
        </v-col>
        <!-- Kitchen -->
        <v-col class="px-1" cols="4" :sm="isOffice ? 4 : 6" v-if="!isStore">
          <h6 class="mb-2 font-12">
            عدد المطابخ
            <span class="grey--text">(إختياري)</span>
          </h6>
          <v-text-field
            data-test="numberOfKitchenUnit"
            v-model="addPropertyInfo.numberOfKitchenUnit"
            dense
            filled
            hide-details="auto"
            placeholder="عدد المطابخ"
            required
            @input="formatEnglishToArabic($event, 'numberOfKitchenUnit')"
            min="0"
            @keydown="$formatNumberInputs"
            class="font-14"
          ></v-text-field>
        </v-col>
        <!-- Bath rooms -->
        <v-col class="px-1" cols="4" :sm="isOffice ? 4 : 6">
          <h6 class="mb-2 font-12">
            عدد دورات المياه
            <span class="grey--text">(إختياري)</span>
          </h6>
          <v-text-field
            data-test="numberOfBathRoom"
            v-model="addPropertyInfo.numberOfBathRoom"
            dense
            filled
            hide-details="auto"
            placeholder="عدد دورات المياه"
            required
            @input="formatEnglishToArabic($event, 'numberOfBathRoom')"
            min="0"
            @keydown="$formatNumberInputs"
            class="font-14"
          ></v-text-field>
        </v-col>

        <!-- For office only -->
        <v-row v-if="isOffice" no-gutters class="mt-4">
          <!-- Finishing -->
          <v-col class="pt-0 px-1 mobile__p__0" cols="12" sm="4">
            <h6 class="ma-0 mb-3 font-12">اختر نوع التشطيب</h6>
            <v-select
              :items="finishing"
              :menu-props="{
                transition: 'slide-y-transition',
                bottom: true,
                offsetY: true
              }"
              :placeholder="`لا يوجد`"
              append-icon="mdi-chevron-down"
              dense
              filled
              hide-details
              item-text="name"
              item-value="value"
              return-value
              v-model="addPropertyInfo.finishing"
              class="font-14"
            />
          </v-col>

          <!-- Furnishing -->
          <v-col class="pt-0 px-1 mobile__p__0" cols="12" sm="4">
            <h6 class="ma-0 mb-3 font-12">مؤثثة ؟</h6>
            <v-select
              :items="furnishing"
              :menu-props="{
                transition: 'slide-y-transition',
                bottom: true,
                offsetY: true
              }"
              :placeholder="`لا يوجد`"
              append-icon="mdi-chevron-down"
              dense
              filled
              hide-details
              item-text="name"
              item-value="value"
              return-value
              v-model="addPropertyInfo.furnishing"
              class="font-14"
            />
          </v-col>

          <!-- Conditioning -->
          <v-col class="pt-0 px-1 mobile__p__0" cols="12" sm="4">
            <h6 class="ma-0 mb-3 font-12">مكيفة ؟</h6>
            <v-select
              :items="conditioning"
              :menu-props="{
                transition: 'slide-y-transition',
                bottom: true,
                offsetY: true
              }"
              :placeholder="`لا يوجد`"
              append-icon="mdi-chevron-down"
              dense
              filled
              hide-details
              item-text="name"
              item-value="value"
              return-value
              v-model="addPropertyInfo.conditioning"
              class="font-14"
            />
          </v-col>
        </v-row>
      </v-row>

      <!-- Floor number -->
      <v-row no-gutters class="my-2">
        <v-col
          class="px-1"
          cols="6"
          md="4"
          v-if="!isLand && !isStorehouse && !isOffice"
        >
          <h6 class="mb-2 font-12">
            رقم الدور
            <span class="grey--text">(إختياري)</span>
          </h6>
          <v-text-field
            data-test="numberOfFloors"
            v-model="addPropertyInfo.numberOfFloors"
            dense
            filled
            hide-details="auto"
            placeholder="رقم الدور"
            required
            @input="formatEnglishToArabic($event, 'numberOfFloors')"
            min="0"
            @keydown="$formatNumberInputs"
            class="font-14"
          ></v-text-field>
        </v-col>
        <v-col
          class="px-1"
          cols="12"
          sm="4"
          v-if="!isFlat && !isChalet && !isGuestHouse"
        >
          <h6
            class="mb-2 font-12"
            :class="{ 'red--flag': nextabClicked ? !isValid : false }"
          >
            عرض الشارع*
            <span class="grey--text">(بالأمتار)</span>
          </h6>
          <v-text-field
            data-test="streetWidth"
            v-model="addPropertyInfo.streetWidth"
            dense
            filled
            hide-details
            placeholder="عرض الشارع بالأمتار"
            required
            @input="formatEnglishToArabic($event, 'streetWidth')"
            min="0"
            @keydown="$formatNumberInputs($event, true)"
            :rules="selectRules"
            class="font-14"
          ></v-text-field>
        </v-col>

        <!--  -->
        <v-col class="px-1" cols="12" sm="4">
          <h6 class="mb-2 font-12">
            العرض
            <span class="grey--text">(إختياري)</span>
          </h6>
          <v-text-field
            data-test="width"
            v-model="addPropertyInfo.width"
            dense
            filled
            hide-details="auto"
            placeholder="العرض"
            required
            @input="formatEnglishToArabic($event, 'width')"
            min="0"
            @keydown="$formatNumberInputs($event, true)"
            class="font-14"
          ></v-text-field>
        </v-col>
        <v-col class="px-1" cols="12" sm="4">
          <h6 class="mb-2 font-12">
            الطول
            <span class="grey--text">(إختياري)</span>
          </h6>
          <v-text-field
            data-test="length"
            v-model="addPropertyInfo.length"
            dense
            filled
            hide-details="auto"
            placeholder="الطول"
            required
            @input="formatEnglishToArabic($event, 'length')"
            min="0"
            @keydown="$formatNumberInputs($event, true)"
            class="font-14"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        class="my-2"
        v-if="!isLand && !isStorehouse && !isOffice"
      >
        <v-col class="px-1" cols="12" sm="6">
          <h6 class="mb-2 font-12">
            تاريخ إنشاء العقار
            <span class="grey--text">(إختياري)</span>
          </h6>
          <v-dialog
            :return-value.sync="addPropertyInfo.builtAt"
            ref="startAtDialog"
            v-model="startAtModal"
            width="320px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                append-icon="mdi-calendar-month-outline"
                dense
                filled
                hide-details
                placeholder="تاريخ إنشاء العقار"
                readonly
                required
                v-bind="attrs"
                v-model="addPropertyInfo.builtAt"
                v-on="on"
                class="font-14"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="start"
              color="primary"
              scrollable
              v-model="start"
            >
              <v-spacer></v-spacer>
              <v-btn @click="startAtModal = false" color="primary" text>
                الغاء
              </v-btn>
              <v-btn
                @click="$refs.startAtDialog.save(start)"
                data-test="datePicker"
                color="primary"
                text
                >حفظ
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col class="px-1" cols="12" sm="6">
          <h6 class="mb-2 font-12">
            عمر العقار
            <span class="grey--text">(بالسنوات)</span>
          </h6>
          <v-text-field
            :disabled="true"
            dense
            filled
            hide-details="auto"
            placeholder="عمر العقار يتم حسابه تلقائيا"
            required
            @input="formatEnglishToArabic($event, 'age')"
            v-model="addPropertyInfo.age"
            class="font-14"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="my-2"
        v-if="!isFlat && !isChalet && !isGuestHouse"
      >
        <v-col class="mr-3">
          <h6 class="mb-2">{{ textBasedOnPropType }}:</h6>
          <v-card class="d-flex">
            <v-checkbox
              multiple
              class="ma-0 mr-3"
              dense
              hide-details
              v-model="addPropertyInfo.selectedSides"
              :value="dir.code"
              data-test="checkDirection"
              v-for="(dir, i) in directions"
              :key="i"
            >
              <template v-slot:label>
                <span class="pt-1 pb-2">
                  <h6 class="ma-0 text-right">
                    {{ dir.text }}
                  </h6>
                </span>
              </template>
            </v-checkbox>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-2 mb-6">
        <v-col class="pl-1" cols="12" sm="12">
          <h6 class="mb-2 font-12">
            تفاصيل إضافيه
            <span class="grey--text">(إختياري)</span>
          </h6>
          <v-textarea
            data-test="notes"
            v-model="addPropertyInfo.notes"
            dense
            filled
            hide-details="auto"
            placeholder="تفاصيل إضافيه"
            auto-grow
            no-resize
            rows="2"
            class="font-14"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>

    <v-card-actions class="pt-4 px-0 d-flex align-center justify-space-between">
      <v-btn
        @click="previousTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <v-icon class="font-16">mdi-arrow-right</v-icon>
        <span class="mb-1 ml-1">السابق</span>
      </v-btn>
      <v-btn
        data-test="nextTab"
        @click="nextTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <span class="mb-1 ml-1">التالي</span>
        <v-icon class="font-16">mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { innerDetails } from '@/constans/realEstates'
import { convertNumbers2English } from '@/utils/formatters'
export default {
  props: {
    resetValidation: {
      default: false
    },
    info: {
      default() {
        return {}
      }
    },
    propertyInfo: {
      default() {
        return {}
      }
    },
    propertyInfoValid: {},
    isLand: {},
    isStorehouse: {},
    isStore: {},
    isFlat: {},
    isGuestHouse: {},
    isChalet: {}
  },
  data() {
    return {
      ...innerDetails,
      start: new Date().toISOString().substr(0, 10),
      startAtModal: false,
      directions: [
        {
          text: 'الشماليه',
          code: 'north',
          checked: false
        },
        {
          text: 'الجنوبيه',
          code: 'south',
          checked: false
        },
        {
          text: 'الشرقيه',
          code: 'east',
          checked: false
        },
        {
          text: 'الغربيه',
          code: 'west',
          checked: false
        }
      ],
      selectRules: [(v) => !!v || 'هذا الحقل مطلوب'],
      isValid: false,
      nextabClicked: false,
      addPropertyInfo: {}
    }
  },
  computed: {
    textBasedOnPropType() {
      return this.isLand
        ? 'الأرض مُطلة على شارع من الناحية'
        : 'العقار مُطل على شارع من الناحية'
    },
    isOffice() {
      return this.addPropertyInfo?.type?.nameEn === 'office'
    }
  },
  methods: {
    nextTab() {
      this.nextabClicked = true
      if (this.isValid) {
        this.$emit('setAddPropertyInfo', this.addPropertyInfo)
        this.$emit('nextTab')
      } else {
        this.$emit('addNotification')
      }
    },
    previousTab() {
      this.$emit('previousTab')
    },
    formatEnglishToArabic(val, key) {
      this.addPropertyInfo[key] = Number(convertNumbers2English(val))
    }
  },
  watch: {
    resetValidation: {
      handler() {
        if (!this.propertyInfoValid) {
          this.nextabClicked = false
          this.addPropertyInfo = { ...this.info }
        }
      }
    },
    propertyInfo: {
      immediate: true,
      handler() {
        this.addPropertyInfo = { ...this.propertyInfo }
      }
    },
    'addPropertyInfo.builtAt': {
      handler(val) {
        const currentDate = new Date()
        const builtAt = new Date(val)
        const Difference_In_Time = !val
          ? 0
          : currentDate.getTime() - builtAt.getTime()
        this.addPropertyInfo.age = (
          Difference_In_Time /
          (1000 * 3600 * 24) /
          365
        ).toFixed(1)
      }
    }
  },
  mounted() {
    if (
      Object.keys(this.propertyInfo).length === 0 &&
      this.propertyInfo.constructor === Object
    ) {
      this.addPropertyInfo = { ...this.info }
    }
  }
}
</script>
