<template>
  <v-form ref="form" v-model="isValid">
    <!-- Row 1 -->
    <v-row no-gutters>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="ma-0 font-weight-bold mb-2">
          تفعيل رسوم خدمة الوساطة والرسوم الإدارية ؟
        </h6>
        <v-card class="d-flex justify-space-between px-2">
          <h6 class="mx-1 font-14 font-weight-bold">
            {{ hasBrokerageFees ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            dense
            hide-details="auto"
            inset
            small
            v-model="hasBrokerageFees"
          />
        </v-card>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="mb-2 mt-0 font-weight-bold">طريقة استقطاع الرسوم</h6>
        <v-select
          :items="methodsDeductingCommissionFee"
          :menu-props="{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          }"
          :disabled="!hasBrokerageFees"
          placeholder="اختر طريقة استقطاع الرسوم"
          :rules="hasBrokerageFees ? [(v) => !!v || ''] : []"
          append-icon="mdi-chevron-down"
          dense
          filled
          item-text="nameAr"
          item-value="value"
          hide-details
          required
          v-model="methodDeductingCommissionFee"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Rows depend on marketers count -->
    <v-row no-gutters class="mt-3">
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="mb-2 mt-0 font-weight-bold">طريقة حساب الرسوم</h6>
        <v-select
          :items="methodsCalculatingCommissionFee"
          :menu-props="{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          }"
          :disabled="!hasBrokerageFees || !methodDeductingCommissionFee"
          placeholder="اختر طريقة حساب الرسوم"
          :rules="hasBrokerageFees ? [(v) => !!v || ''] : []"
          append-icon="mdi-chevron-down"
          dense
          filled
          item-text="nameAr"
          item-value="value"
          hide-details
          required
          v-model="methodCalculatingCommissionFee"
        ></v-select>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <v-row no-gutters>
          <v-col class="pl-3 mobile__pl__0 pt-0 mb-2" cols="12" sm="6">
            <h6 class="ma-0 mb-2 font-weight-bold">نسبة الرسوم الكلية</h6>
            <v-text-field
              class="font-14"
              :rules="hasBrokerageFees ? [(v) => !!v || ''] : []"
              dense
              filled
              hide-details
              required
              :disabled="
                !methodCalculatingCommissionFee ||
                methodCalculatingCommissionFee === 'fixed'
              "
              placeholder="نسبة الرسوم الكلية"
              suffix="%"
              :value="feesPercentageValueMask"
              @input="updateTotalAmount($event)"
              @keydown="$formatNumberInputs($event, true)"
            ></v-text-field>
          </v-col>
          <v-col
            :class="[
              'pl-3 mobile__pl__0 pt-0 mb-2',
              { 'order--1': methodCalculatingCommissionFee === 'fixed' }
            ]"
            cols="12"
            sm="6"
          >
            <h6 class="ma-0 mb-2 font-weight-bold">قيمة الرسوم الكلية</h6>
            <v-text-field
              :rules="hasBrokerageFees ? [(v) => !!v || ''] : []"
              class="font-14"
              dense
              filled
              required
              :disabled="
                !methodCalculatingCommissionFee ||
                methodCalculatingCommissionFee === 'percentage'
              "
              hide-details
              placeholder="قيمة الرسوم الكلية"
              suffix="ريال"
              :value="feesValueMask"
              @input="updatePercentage($event)"
              @keydown="$formatNumberInputs($event, true)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- informativeCard -->
    <v-row no-gutters class="margin-block mt-5">
      <v-col class="py-2 px-4 bg-brub rounded-xl d-flex">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="-0.000244141"
            width="20"
            height="20"
            rx="10"
            fill="#662D91"
            fill-opacity="0.1"
          />
          <g clip-path="url(#clip0_13864_307)">
            <path
              d="M12.4228 14.5743L12.2598 15.2407C11.7708 15.4337 11.3801 15.5806 11.0892 15.6816C10.798 15.7829 10.4597 15.8333 10.0741 15.8333C9.48212 15.8333 9.02163 15.6883 8.6932 15.4002C8.36478 15.111 8.20051 14.7445 8.20051 14.2999C8.20051 14.1278 8.21243 13.9508 8.23721 13.7704C8.2622 13.5899 8.30194 13.3865 8.35631 13.1592L8.96747 10.996C9.02184 10.7889 9.06806 10.5926 9.10507 10.407C9.14261 10.2226 9.1607 10.0529 9.1607 9.90009C9.1607 9.62384 9.1035 9.43061 8.98964 9.32197C8.87577 9.21364 8.65849 9.15864 8.33592 9.15864C8.17793 9.15864 8.01554 9.18395 7.85002 9.23288C7.68377 9.28203 7.54167 9.3295 7.42236 9.37362L7.58579 8.70673C7.98616 8.54372 8.36885 8.40413 8.73492 8.28827C9.10099 8.1721 9.44688 8.11397 9.77406 8.11397C10.362 8.11397 10.8157 8.25607 11.1343 8.54027C11.4529 8.82467 11.6121 9.19336 11.6121 9.64768C11.6121 9.74168 11.6017 9.9073 11.5792 10.144C11.5573 10.3813 11.5164 10.5987 11.4568 10.7964L10.8482 12.9509C10.7983 13.1239 10.7535 13.3218 10.7145 13.5444C10.6742 13.7655 10.655 13.9345 10.655 14.0478C10.655 14.3339 10.7188 14.5292 10.8467 14.6331C10.9755 14.7369 11.1973 14.7886 11.5125 14.7886C11.6605 14.7886 11.8288 14.7623 12.0159 14.7108C12.2024 14.6592 12.3384 14.6139 12.4228 14.5743ZM12.5771 5.5291C12.5771 5.90448 12.4357 6.22506 12.1514 6.48866C11.8678 6.7532 11.5261 6.88558 11.1264 6.88558C10.7254 6.88558 10.3828 6.7532 10.096 6.48866C9.80971 6.22496 9.66625 5.90448 9.66625 5.5291C9.66625 5.15446 9.80971 4.83335 10.096 4.5664C10.3823 4.29988 10.7255 4.16666 11.1264 4.16666C11.526 4.16666 11.8678 4.30019 12.1514 4.5664C12.4359 4.83335 12.5771 5.15456 12.5771 5.5291Z"
              fill="#662D91"
            />
          </g>
          <defs>
            <clipPath id="clip0_13864_307">
              <rect
                width="11.6667"
                height="11.6667"
                fill="white"
                transform="translate(4.16638 4.16666)"
              />
            </clipPath>
          </defs>
        </svg>
        <h6 class="text-primary font-12 ma-0 mr-2 font-weight-bold">
          يمكن حساب عمولة مدرج العقار وعمولات المسوقين عن طريق قسم عمولات
          التسويق والإدراج بعد قبول العميل للعرض
        </h6>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import inputMasking from '@/mixins/inputMasking.js'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  name: 'CommissionFee',
  mixins: [inputMasking],
  props: {
    updatedPriceOffer: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    },
    insts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isValid: false,
      hasBrokerageFees: false,
      priceOfferInfo: {},
      methodsCalculatingCommissionFee: [
        {
          value: 'percentage',
          nameAr: 'نسبة'
        },
        {
          value: 'fixed',
          nameAr: 'مبلغ ثابت'
        }
      ],
      methodsDeductingCommissionFee: [
        {
          value: 'rentFromFirstYear',
          nameAr: 'قيمة الإيجار من السنة الاولى'
        },
        {
          value: 'combinedRentFromFirstYear',
          nameAr: 'قيمة الإيجار والمبالغ الثابتة من السنة الاولى'
        }
      ],
      methodCalculatingCommissionFee: '',
      methodDeductingCommissionFee: '',

      // Masks
      feesPercentageValueMask: '',
      feesValueMask: ''
    }
  },
  computed: {
    feesPercentageValueKeys() {
      return {
        maskValKey: 'feesPercentageValueMask',
        parentKey: 'priceOfferInfo',
        actualValKey: 'feesPercentage'
      }
    },
    feesAmountValueKeys() {
      return {
        maskValKey: 'feesValueMask',
        parentKey: 'priceOfferInfo',
        actualValKey: 'fees'
      }
    },
    rentFromFirstYear() {
      return this.insts[0].payment
    },
    combinedRentFromFirstYear() {
      return this.insts[0].payment + this.insts[0].fixedPayment
    },
    amount() {
      return this.methodDeductingCommissionFee === 'rentFromFirstYear'
        ? this.rentFromFirstYear
        : this.combinedRentFromFirstYear
    }
  },
  methods: {
    updateTotalAmount(value) {
      const val = Number(convertNumbers2English(value))

      const formatTotalValue = (Number(this.amount) * val) / 100
      this.handleValuesChanging(formatTotalValue, this.feesAmountValueKeys)
      this.handleValuesChanging(value, this.feesPercentageValueKeys)
    },
    updatePercentage(value) {
      const val = Number(convertNumbers2English(value))

      const formatTotalValue = (val / Number(this.amount)) * 100
      this.handleValuesChanging(formatTotalValue, this.feesPercentageValueKeys)
      this.handleValuesChanging(value, this.feesAmountValueKeys)
    },
    reset() {
      this.methodCalculatingCommissionFee = ''
      this.feesPercentageValueMask = ''
      this.feesValueMask = ''
      this.priceOfferInfo = {}
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        this.methodCalculatingCommissionFee = this.updatedPriceOffer.feesType
        this.hasBrokerageFees = this.updatedPriceOffer.hasBrokerageFees
        if (this.hasBrokerageFees) {
          this.methodDeductingCommissionFee =
            this.updatedPriceOffer.commissionType
          this.updateTotalAmount(this.updatedPriceOffer.feesPercentage)
          this.updatePercentage(this.updatedPriceOffer.fees)
        }
      }
    },
    presetData() {
      this.editMoodPresetData()
    },
    dataToBeBinded() {
      this.priceOfferInfo.feesType = this.methodCalculatingCommissionFee
      this.priceOfferInfo.hasBrokerageFees = this.hasBrokerageFees
      this.priceOfferInfo.commission = this.methodDeductingCommissionFee

      return this.priceOfferInfo
    },
    bindContractData() {
      this.$emit('bindPriceOfferData', this.dataToBeBinded())
    }
  },
  watch: {
    methodDeductingCommissionFee(newVal, oldVal) {
      if (newVal !== oldVal && oldVal) {
        this.reset()
      }
    },
    insts: {
      immediate: true,
      handler(val) {
        if (!val[0]?.fixedPayment) {
          this.methodsDeductingCommissionFee.pop()
        }
      }
    },
    hasBrokerageFees(val) {
      if (!val) {
        this.reset()
        this.methodDeductingCommissionFee = ''
      }
    },
    methodCalculatingCommissionFee(val) {
      if (!val) {
        this.handleValuesChanging('', this.feesAmountValueKeys)
        this.handleValuesChanging('', this.feesPercentageValueKeys)
        this.feesPercentageValueMask = ''
        this.feesValueMask = ''
      }
    },
    isValid(val) {
      this.$emit('setValdationStatus', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.order--1 {
  order: -1;
}
</style>
