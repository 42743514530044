<template>
  <div>
    <v-form ref="form" v-model="isValid">
      <v-row no-gutters>
        <v-col class="pl-3 mobile__pl__0" cols="12">
          <h6 class="mb-2 font-12">اسم بيئه العمل*</h6>
          <v-text-field
            data-test="name"
            filled
            v-model="accountInfoEnv.name"
            :rules="[(v) => !!v || 'من فضلك ادخل اسم بيئه العمل']"
            placeholder="اسم بيئه العمل"
            required
            dense
            class="setting__input__fields"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="pl-3 mobile__pl__0 pb-0 mobile__pt__0" cols="12" md="6">
          <div class="d-flex align-center">
            <h6 class="font-12">رقم الهاتف</h6>
          </div>
          <v-text-field
            filled
            v-model="accountInfoUser.phoneNumber"
            placeholder="رقم الهاتف"
            dense
            disabled
            class="setting__input__fields"
          />
        </v-col>
        <v-col class="pl-3 mobile__pl__0 pb-0 mobile__pt__0" cols="12" md="6">
          <h6 class="mb-2 font-12">رقم الواتساب*</h6>
          <v-text-field
            class="setting__input__fields"
            filled
            dense
            placeholder="رقم الواتساب"
            :rules="phoneNumberRules"
            v-model="accountInfoEnv.whatsAppNumber"
          />
        </v-col>
        <v-col
          v-if="showCompanyData"
          class="pl-3 mobile__pl__0 pb-0 mobile__pt__0"
          cols="12"
          md="6"
        >
          <h6 class="mb-2 font-12">اسم الشركه*</h6>
          <v-text-field
            data-test="companyName"
            filled
            v-model="accountInfoUser.company"
            :rules="[(v) => !!v || 'من فضلك ادخل اسم الشركة']"
            placeholder="اسم الشركه"
            dense
            class="setting__input__fields"
          />
        </v-col>

        <v-col
          v-if="showCompanyData"
          class="pl-3 mobile__pl__0 pb-0 mobile__pt__0"
          cols="12"
        >
          <h6 class="mb-2 font-12">عن الشركه</h6>
          <v-textarea
            @keydown.enter.exact.prevent
            @keyup.enter.exact="accountInfoEnv.about"
            auto-grow
            class-content="commentTextArea font-12"
            class="aboutCompany"
            filled
            hide-details
            no-resize
            placeholder="ادخل تعريف عن الشركة"
            rows="2"
            v-model="accountInfoEnv.about"
          >
            <template v-slot:append>
              <v-btn
                @click="checkAboutCompany"
                rounded
                color="#662d9119"
                class="reset-about-btn"
                >اعادة تعيين</v-btn
              >
            </template>
          </v-textarea>
        </v-col>

        <v-col v-if="showCompanyData" md="12">
          <google-maps-search
            :currentAddress="fullAddress"
            @onCheckAddress="checkAddress"
            mapStatus="new"
            mapTitle="الشركة او المكتب"
          />
        </v-col>
      </v-row>
      <v-layout justify-end class="mt-5">
        <v-btn
          data-test="updatePassword"
          @click="updateData"
          color="primary"
          class="rounded-15 px-13 py-3"
        >
          <span class="font-16"> حفظ </span>
        </v-btn>
      </v-layout>
    </v-form>
  </div>
</template>

<style>
.aboutCompany .v-input__slot {
  flex-direction: column;
}
</style>
<script>
import GoogleMapsSearch from '../../map/GoogleMapsSearch.vue'
export default {
  name: 'AccountInfo',
  components: {
    GoogleMapsSearch
  },
  props: {
    userData: {
      default() {
        return {}
      }
    },
    env: {
      default() {
        return {}
      }
    },
    locationInfo: {
      default() {
        return {
          location: {},
          envAddress: {
            address: ''
          }
        }
      }
    }
  },
  data() {
    return {
      isValid: false,
      accountInfoEnv: {},
      accountInfoUser: {},
      phoneNumberRules: [
        (v) => !!v || 'من فضلك ادخل رقم الجوال',
        (v) =>
          /^(05)([0-9]{9})$/.test(v) ||
          /^(966)([0-9]{9})$/.test(v) ||
          'صيغة رقم الجوال غير صحيحة'
      ]
    }
  },
  computed: {
    userPhoneNumber() {
      return this.userData?.phoneNumber || ''
    },
    fullAddress() {
      return {
        ...this.locationInfo.location,
        ...this.locationInfo.envAddress
      }
    },
    showCompanyData() {
      return this.userData.type === 'company' || this.userData.type === 'office'
    }
  },
  watch: {
    accountInfoEnv: {
      deep: true,
      handler() {
        this.updateData()
      }
    },
    accountInfoUser: {
      deep: true,
      handler() {
        this.updateData()
      }
    }
  },
  methods: {
    checkAddress(locationOptions) {
      this.$emit('checkAddress', locationOptions)
    },
    checkAboutCompany() {
      this.$emit('checkAboutCompany', this.accountInfoEnv.about)
    },
    updateData() {
      setTimeout(() => {
        this.$emit('setValidation', {
          isValid: this.isValid,
          userData: this.accountInfoUser,
          env: this.accountInfoEnv
        })
      }, 100)
    }
  },
  created() {
    this.accountInfoEnv = { ...this.env }
    this.accountInfoUser = { ...this.userData }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_env.scss';
.reset-about-btn {
  border-radius: 47px;
  color: #662d91 !important;
  box-shadow: none;
  font-size: 12px;
}
</style>
