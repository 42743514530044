import { userService } from '../../services'

const state = {
  allUsers: {},
  spinnerLoading: false,
  validePhonenumber: '',
  activeLignTab: 0
}

const getters = {
  activeLignTab: (fromState) => {
    return fromState.activeLignTab
  }
}

const actions = {
  delete({ commit }, id) {
    commit('deleteRequest', id)
    userService.delete(id).then(
      () => commit('deleteSuccess', id),
      (error) => commit('deleteFailure', { id, error: error.toString() })
    )
  }
}

const mutations = {
  deleteRequest(fromState, id) {
    // add 'deleting:true' property to user being deleted
    fromState.allUsers.items = fromState.allUsers.items.map((user) =>
      user.id === id ? { ...user, deleting: true } : user
    )
  },
  deleteSuccess(fromState, id) {
    // remove deleted user from state
    fromState.allUsers.items = fromState.allUsers.items.filter(
      (user) => user.id !== id
    )
  },
  deleteFailure(fromState, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    fromState.allUsers.items = fromState.items.map((user) => {
      if (user.id === id) {
        // make copy of user without 'deleting:true' property
        // const { deleting, ...userCopy } = user;
        // return copy of user with 'deleteError:[error]' property
        // return { ...userCopy, deleteError: error }
        return { deleteError: error }
      }

      return user
    })
  },
  setSpinnerLoading(fromState, isLoading) {
    fromState.spinnerLoading = isLoading
  },
  setValidePhonenumber(fromState, number) {
    fromState.validePhonenumber = number
  },
  setActiveLignTab(fromState, tab) {
    fromState.activeLignTab = tab
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
