<template>
  <v-list class="px-4 py-2 ma-0 scroll" v-if="clients.length">
    <div class="px-0 d-flex align-center">
      <figure>
        <img
          src="../../assets/img/svgs/user-s.svg"
          alt="User Icon"
          loading="lazy"
        />
      </figure>
      <!-- user icon  -->
      <h6 class="primary--text font-12 mr-2">
        {{ team ? 'التشغيل' : 'العملاء' }}
      </h6>
    </div>
    <v-list-item
      :key="i"
      class="my-1 px-1 pointerCursor clientListItem"
      v-for="(client, i) in clients"
    >
      <div class="ml-8">
        <router-link :to="`/dashboard/client/${client._id}`" class="d-flex">
          <h6
            :class="tagColor(i)"
            class="red--tag bill-md ma-0 font-12 ml-2"
            v-if="!team"
          >
            {{ $subString(client.name) }}
          </h6>
          <h5 class="font-12 font-weight-bold ma-0">
            {{ client.name }}
          </h5>
        </router-link>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'client-search-comp',
  props: {
    team: {
      default: false
    },
    clients: []
  },
  data() {
    return {
      client: require('@/assets/img/svgs/user-s.svg')
    }
  },
  computed: {
    tagColor: () => (i) => {
      return i % 2 === 0 ? 'red--tag' : 'blue--tag'
    }
  }
}
</script>
<style lang="scss" scoped>
.clientListItem:hover {
  background: #f9f9f9;
  border-radius: 7px;
}
</style>
