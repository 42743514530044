<template>
  <v-row class="mb-4 mt-6">
    <v-col>
      <v-card height="133" width="202">
        <v-sheet
          :color="'rgba(18, 202, 214, 0.1)'"
          class="ma-4 cardsIconContainer"
        >
          <v-img
            class="mt-2"
            max-height="16"
            max-width="16"
            :lazy-src="unitsNumber"
            :src="unitsNumber"
          ></v-img>
        </v-sheet>
        <v-card-text class="pr-4 ma-0 pa-0">
          <div style="color: black">
            <span class="font-14 upperText"> عدد الوحدات </span>
            <div class="font-12">
              <b class="font-16">12</b>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card height="133" width="202">
        <v-sheet
          :color="'rgba(102, 45, 145, 0.1)'"
          class="ma-4 cardsIconContainer"
        >
          <v-img
            class="mt-2"
            max-height="16"
            max-width="16"
            :lazy-src="report"
            :src="report"
          ></v-img>
        </v-sheet>
        <v-card-text class="pr-4 ma-0 pa-0">
          <div style="color: black">
            <span class="font-14 upperText">عدد العقود </span>
            <div class="font-12">
              <b class="font-16">10 </b>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card height="133" width="202">
        <v-sheet
          class="ma-4 cardsIconContainer"
          :color="'rgba(101, 196, 103, 0.1)'"
        >
          <v-img
            class="mt-2"
            max-height="16"
            max-width="16"
            :lazy-src="wallet"
            :src="wallet"
          ></v-img>
        </v-sheet>
        <v-card-text class="pr-4 ma-0 pa-0">
          <div style="color: black">
            <span class="font-14 upperText">الرصيد الحالي </span>
            <div class="font-12">
              <b class="font-16">11</b>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card height="133" width="202">
        <v-sheet
          class="ma-4 cardsIconContainer"
          :color="'rgba(48, 117, 227, 0.1)'"
        >
          <v-img
            class="mt-2"
            max-height="16"
            max-width="16"
            :lazy-src="trueIcon"
            :src="trueIcon"
          ></v-img>
        </v-sheet>
        <v-card-text class="pr-4 ma-0 pa-0">
          <div style="color: black">
            <span class="font-14 upperText">رصيد المحصلات </span>
            <div class="font-12">
              <b class="font-16">1000 </b>
              <span class="font-14">ريال</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card height="133" width="202">
        <v-sheet
          class="ma-4 cardsIconContainer"
          :color="'rgba(252, 74, 74, 0.1)'"
        >
          <v-img
            class="mt-2"
            max-height="16"
            max-width="16"
            :lazy-src="falseIcon"
            :src="falseIcon"
          ></v-img>
        </v-sheet>
        <v-card-text class="pr-4 ma-0 pa-0">
          <div style="color: black">
            <span class="font-14 upperText">المتأخرات </span>
            <div class="font-12">
              <b class="font-16">1500 </b>
              <span class="font-14">ريال</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'ClientDetailsCards',
  data() {
    return {
      unitsNumber: require('@/assets/img/svgs/unitsNumber.svg'),
      report: require('@/assets/img/svgs/report.svg'),
      wallet: require('@/assets/img/svgs/wallet.svg'),
      trueIcon: require('@/assets/img/svgs/trueIcon.svg'),
      falseIcon: require('@/assets/img/svgs/falseIcon.svg')
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification'])
  },
  computed: {
    xl() {
      return this.$vuetify.breakpoint.xlAndDown
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_statistics-cards.scss';
</style>
