<template>
  <div id="step-2">
    <!-- phone-->
    <div class="step-title">
      <h6 class="submit-section-header black--text font-weight-bold">
        رقم الجوال
      </h6>
    </div>
    <div class="form-row form-row-wide m-0 font-weight-bold">
      <v-card
        class="pa-3 d-flex justify-space-between align-center"
        color="lightGery"
      >
        {{ phoneNumber | filterPhoneNumber }}
      </v-card>
    </div>

    <!-- otp-->
    <div class="step-title mb-5">
      <h6 class="submit-section-header black--text font-weight-bold">
        التحقق من رقم الجوال
      </h6>
      <p>
        فضلا قم بإدخال رمز التحقيق المكون من ٤ أرقام المرسل إلى جوالك رقم
        <strong>{{ phoneNumber | filterPhoneNumber }}</strong>
      </p>
    </div>
    <div id="vie-otp-input-wrap" class="mb-5">
      <vie-otp-input
        ref="otpInput"
        input-classes="otp-input"
        :num-inputs="4"
        separator=""
        :should-auto-focus="true"
        @on-complete="handleOnComplete"
      />
    </div>
    <v-row class="mb-5 ma-0">
      <v-spacer />
      <v-btn @click="resendCode" :disabled="TimePassed" color="primary">
        <span class="mb-1">اعاده ارسال الرمز</span>
        <span v-if="timeRun > 0" class="mb-1 mx-1 font-weight-bold">{{
          timeRun
        }}</span>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import VieOtpInput from '@bachdgvn/vue-otp-input'
import { mapActions, mapMutations } from 'vuex'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  components: {
    VieOtpInput
  },
  props: ['phoneNumber', 'password', 'confirmationCode'],
  data() {
    return {
      TimePassed: true,
      timeRun: 30,
      timerInterval: null,
      enteredOtp: null
    }
  },
  methods: {
    ...mapActions('accountState', ['register']),
    ...mapMutations('accountState', [
      'registerSuccess',
      'loginSuccess',
      'registerFailure',
      'setCurrentEnv',
      'setConfigrations'
    ]),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapMutations('appState', ['addNotification']),

    handleOnComplete(otp) {
      const englishOTP = convertNumbers2English(otp)
      this.enteredOtp = englishOTP
      this.$emit('on-validate', {
        phoneNumber: this.phoneNumber,
        confirmationCode: englishOTP,
        showMsg: false
      })
      return false
    },

    resendCode() {
      this.timeRun = 30
      this.TimePassed = true
      this.$emit('resendVerficationCode', this.phoneNumber)
    },
    timer() {
      this.timerInterval = setInterval(() => {
        this.timeRun--
      }, 1000)
    },
    clearTimer(val) {
      clearInterval(val)
      this.TimePassed = false
    },
    resetTimer() {
      clearInterval(this.timerInterval)
      this.timeRun = 30
      this.TimePassed = true
    }
  },
  watch: {
    timeRun(newValue) {
      if (newValue === 0) {
        this.clearTimer(this.timerInterval)
      }
    }
  }
}
</script>

<style>
#vie-otp-input-wrap > div {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 -10px;
}

#vie-otp-input-wrap > div > div {
  flex-grow: 1;
  margin: 0 10px;
}

.otp-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.otp-input.error {
  border: 1px solid red !important;
}
</style>
