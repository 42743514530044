<template>
  <v-dialog max-width="480px" persistent :value="dialogState">
    <v-card class="add-new-property pa-0">
      <!-----------1# Header----------->
      <v-card-title
        class="py-0 px-3 mb-0 custom__shadow d-flex justify-space-between"
      >
        <!----------Title----------->
        <h4 class="my-2" v-text="modelTitle" />

        <!----------Close----------->
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-----------2# Actions----------->
      <v-form ref="form" v-model="isValid">
        <v-container class="mb-0">
          <v-row no-gutters>
            <v-col class="d-flex justify-space-between align-center" cols="12">
              <h4 class="font-14">{{ localtemplateDate.title }}</h4>
              <v-switch
                v-model="localtemplateDate.active"
                class="ma-0 pa-0"
                dense
                hide-details="auto"
                inset
                :disabled="!hasUpdatePermission"
              />
            </v-col>
          </v-row>
          <v-divider class="my-5" />
          <v-row no-gutters>
            <v-col cols="12">
              <h4 class="font-14">الخدمات الفرعية</h4>
            </v-col>
            <v-col v-for="service in realestatePurpose" :key="service._id">
              <div class="center-switch" style="gap: 15px">
                <h5 class="font-10">
                  {{ service.nameAr }}
                </h5>
                <v-switch
                  v-model="RSPurposes[service?.nameEn]"
                  dense
                  input-value="true"
                  hide-details
                  inset
                  :disabled="!hasUpdatePermission"
                />
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-5" />
          <v-row no-gutters>
            <v-col cols="12">
              <h4 class="font-14">أنواع العقارات المتاحة فى الخدمة</h4>
            </v-col>
            <v-col v-for="realState in realestateTypes" :key="realState._id">
              <div class="center-switch">
                <h5 class="font-10" style="width: 50px">
                  {{ realState.nameAr }}
                </h5>
                <v-switch
                  v-model="RSTypes[realState?.nameEn]"
                  dense
                  hide-details
                  inset
                  :disabled="!hasUpdatePermission"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <!----------3# Save----------->
      <v-card-actions class="pa-1 mt-2">
        <v-btn
          @click.prevent="saveNewSettings"
          class="ma-0 rounded-7 my-2 ml-3 mr-auto font-14"
          color="primary"
          :disabled="!isValid || !hasUpdatePermission"
        >
          <span class="ml-1 font-weight-bold"> حفظ التغيرات </span>
          <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'ServiceModel',
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    templateDate: {
      type: Object,
      default: () => {}
    },
    hasUpdatePermission: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      RSPurposes: {},
      RSTypes: {},
      localtemplateDate: {},
      loading: false,
      newSettings: {},
      isValid: false,
      modelTitle: 'إعدادات الخدمة'
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'currentEnv']),
    realestateTypes() {
      return [...this.configs.realestateTypes]
    },
    realestatePurpose() {
      return [...this.configs.realestatePurpose].filter((realestatePurpose) => {
        const neededType = [
          'Residential',
          'Commercial',
          'Residential_Commercial'
        ]
        return neededType.includes(realestatePurpose.nameEn)
      })
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapActions('accountState', ['updateCurrentEnv']),
    async closeDialog() {
      this.$emit('toggleServiceModel', {
        dialogState: false,
        template: {}
      })
    },
    testRealStateTypes() {
      const selectedService = this.currentEnv.services.find(
        (service) => service.code === this.localtemplateDate.code
      )

      this.RSTypes = selectedService?.realEstateTypes ?? {}
      this.RSPurposes = selectedService?.purposes ?? {}
    },
    createObject() {
      const service = this.currentEnv.services.find(
        (ser) => ser.code === this.localtemplateDate.code
      )

      this.RSPurposes = this.realestatePurpose.reduce((acc, purpose) => {
        acc[purpose.nameEn] = service?.purposes[purpose.nameEn] ?? null
        return acc
      }, {})

      this.RSTypes = this.realestateTypes.reduce((acc, type) => {
        acc[type.nameEn] = service?.realEstateTypes[type.nameEn] ?? null
        return acc
      }, {})
    },
    updateServiceSettingss() {
      const newServices = {
        code: this.localtemplateDate.code,
        isActive: this.localtemplateDate.active,
        purposes: this.RSPurposes,
        realEstateTypes: this.RSTypes
      }
      this.$emit('updateServiceSettingss', newServices)
    },
    async saveNewSettings() {
      this.updateServiceSettingss()
      this.closeDialog()
    }
  },
  watch: {
    templateDate: {
      deep: true,
      handler() {
        this.localtemplateDate = { ...this.templateDate }
        this.createObject()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.content-type {
  text-align: center;
  width: 140px;
  border: 1px solid #e1e1e1;
  border-radius: 30px;
  cursor: pointer;
}
.content-type:nth-child(2) {
  margin-right: 10px;
}

.active-content-type {
  border-color: $primary-color;
  color: $primary-color;
}

.font-13 {
  font-size: 13px !important;
}
.center-switch {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
</style>

<style lang="scss">
#notificationsTextarea {
  font-size: 13px !important;
  color: #101010;
  margin-bottom: 0px !important;
}
</style>
