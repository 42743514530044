<template>
  <v-data-table
    :headers="headers"
    :items="bills"
    class="hectar-table dataTaskTable task__table"
    hide-default-footer
  >
    <!-- mobile-breakpoint="0" -->
    <template v-slot:[`item.createdAt`]="{ item }">
      <h6 class="font-14 font-weight-medium ml-md-6">
        {{ formatDate(item.createdAt) }}
      </h6>
    </template>

    <template v-slot:[`item.subscription`]="{ item }">
      <h6>{{ bundleName(item.subscription) }}</h6>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <div
        class="mx-4 d-flex align-center tabel-tag text-center d-flex justify-center font-12"
        :class="paymentStatus(item.status).tagColor"
      >
        <v-sheet
          class="dotted ml-2"
          :color="paymentStatus(item.status).color"
        ></v-sheet>
        <span class="mb-1 mr-1">
          {{ paymentStatus(item.status).text }}
        </span>
      </div>
    </template>

    <template v-slot:[`item.attachment`]="{ item }">
      <a v-if="item.attachment" :href="item.attachment" target="_blank">
        <div
          class="d-flex tabel-tag text-center d-flex justify-center black--text"
        >
          <v-icon>mdi-eye</v-icon>
        </div>
      </a>
    </template>
    <template v-slot:[`item.pdf`]="{ item }">
      <v-btn data-test="downloadBill" icon @click="downloadBill(item)">
        <v-icon color="primary">mdi-download</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'data-table-bill',
  mixins: [dateFormat],
  props: {
    bills: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    bundles: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    paymentStatus: () => (app) => {
      switch (app) {
        case 'pending':
          return {
            color: '#662D91',
            text: 'قيد المعالجة',
            tagColor: 'brub--tag'
          }
        case 'unpaid':
          return {
            color: 'orange',
            text: 'تحت المراجعه',
            tagColor: 'orange--tag'
          }
        case 'rejected':
          return {
            text: 'مرفوض',
            color: 'red',
            tagColor: 'red--tag'
          }
        case 'paid':
          return {
            text: 'مقبول',
            color: 'green',
            tagColor: 'green--tag'
          }
        default:
          return {
            text: 'غير معروف',
            color: 'grey',
            tagColor: ''
          }
      }
    }
  },
  methods: {
    bundleName(subscription) {
      const bundle = this.bundles.find(
        (bundle_) => bundle_._id === subscription?.bundle?._id
      )
      return bundle ? bundle.nameAr : 'غير محدد'
    },
    downloadBill(bill) {
      this.$emit('setClickedBill', bill)
    }
  }
}
</script>
