<template>
  <v-dialog max-width="850" persistent v-model="dialogState_">
    <v-card class="add-new-property">
      <v-card-title
        class="cardTitle pa-0 px-4 mb-4 d-flex justify-space-between custom__shadow"
      >
        <h4>{{ editDepartmentName._id ? 'تعديل' : 'إضافة' }} قسم</h4>

        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container>
          <v-row no-gutters>
            <v-col>
              <h6 class="mb-2 mt-0">أسم القسم</h6>
              <v-text-field
                v-model="departmentName"
                placeholder="ادخل اسم القسم"
                dense
                filled
                hide-details
                class="font-14"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="cardActions pa-1 mt-4 pl-5">
        <!-- :disabled="!isValid || !selectedPermissions.length" -->
        <!-- :loading="isLoading" -->
        <!-- @click="editMemberId ? updateTeamMember() : addTeamMember()" -->
        <v-spacer />

        <v-btn
          data-test="sendInvite"
          class="border-1 ma-0 rounded-7 my-3 mr-3 px-5"
          color="primary"
          @click="handleAction"
        >
          <span class="mb-2 ml-1 font-weight-bold">
            {{ editDepartmentName._id ? 'تعديل' : 'اضافة' }}
          </span>
          <v-icon class="send-icon">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EnvService } from '@/services'
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    editDepartmentName: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      departmentName: ''
    }
  },
  watch: {
    editDepartmentName(value) {
      if (value._id) {
        this.departmentName = value.name
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },

  methods: {
    ...mapMutations('appState', ['addNotification']),
    openDialog() {
      this.dialogState_ = true
    },
    closeDialog() {
      this.dialogState_ = false
    },
    async handleAction() {
      // eslint-disable-next-line
      this.editDepartmentName._id
        ? await this.updateDepartment()
        : await this.addDepartment()
    },
    async addDepartment() {
      try {
        await EnvService.addNewDepartment({
          name: this.departmentName,
          environmentId: this.currentEnv._id
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من إضافة الأقسام, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.closeDialog()
        this.$emit('changeDialogState', false)
      }
    },
    async updateDepartment() {
      try {
        await EnvService.updateDepartment(this.editDepartmentName._id, {
          name: this.departmentName
        })
        this.addNotification({
          text: 'تم تحديث القسم بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تعديل الأقسام, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.closeDialog()
        this.$emit('changeDialogState', false)
      }
    }
  }
}
</script>
