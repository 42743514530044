<template>
  <div
    class="left__side__logs"
    :class="{ active__left__sidebar: showActivityLogs }"
  >
    <div class="logs__page">
      <v-card class="pointerCursor">
        <!------------------------->
        <!---------Toolbar--------->
        <!------------------------->

        <v-toolbar
          height="40px"
          color="elevation-0"
          id="page-tollbar"
          class="mb-5 narrow-toolbar px-4"
        >
          <v-icon
            color="primary"
            class="mt-7 ml-4 sidebar__hide__icon"
            :class="{ active__left__sidebar: showActivityLogs }"
            @click="closeActivityLogs"
          >
            mdi-arrow-right</v-icon
          >
          <span class="mt-4 font-weight-bold">سجل النشاطات</span>
        </v-toolbar>

        <!------------------------------------>
        <!---------Activity logs card--------->
        <!------------------------------------>

        <div class="logs__content__div px-8 pb-4 mobile__px__10px">
          <div class="logs__content">
            <div class="logs__body pt-3" v-if="ifData">
              <ActivityCard
                v-for="log in activityLogs"
                :key="log._id"
                :activity-log="log"
                :addDate="true"
              />
              <!-- For lazy loading -->
              <InfiniteLoading @infinite="infiniteScroll">
                <div slot="no-more">
                  <h4 class="font-12">لا توجد نشاطات اخرى</h4>
                </div>
                <div slot="no-results" />
              </InfiniteLoading>
            </div>
            <div class="text-center" v-else>
              <h6>لا توجد نشاطات سابقة</h6>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ActivityCard from '@/components/ActivityLogs/ActivityCard'
import InfiniteLoading from 'vue-infinite-loading'
import { messageService } from '@/services'

export default {
  name: 'ActivityLogsSideDetails',
  props: {
    showActivityLogs: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'users'
    }
  },
  data() {
    return {
      pagination: {
        pageNumber: 1
      },
      activityLogs: [],
      totalCount: 0
    }
  },
  created() {
    this.getActivityLogs()
  },
  components: {
    ActivityCard,
    InfiniteLoading
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    ifData() {
      return this.activityLogs.length !== 0
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeActivityLogs() {
      this.$emit('closeActivityLogs')
    },
    // Activity logs
    // eslint-disable-next-line consistent-return
    async getActivityLogs() {
      try {
        const body = {
          pageNumber: this.pagination.pageNumber,
          pageSize: 10,
          environmentId: this.currentEnv?._id
        }
        //
        if (this.type === 'users') {
          body.userId = this.$route.params.id
        } else {
          body.entityId = this.$route.params.id
        }

        const { data } = await messageService.getActivityLogs(body)
        this.totalCount = data.totalCount
        this.activityLogs.push(...data.items)
        return data.items
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء المحاوله لاحقا',
          color: 'error'
        })
      }
    },
    // Infinity scroll
    async infiniteScroll($state) {
      if (this.pagination.pageNumber === 1) {
        this.pagination.pageNumber = 2
      }
      await this.getActivityLogs()
      if (this.totalCount > this.activityLogs.length) {
        this.pagination.pageNumber++
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/activityLog/activity-log.scss';
</style>
