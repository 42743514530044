export default {
  data() {
    return {
      si: [
        { value: 1, symbol: ' ريال' },
        { value: 1e3, symbol: ' الف ريال' }, // K
        { value: 1e6, symbol: ' مليون ريال' }, // M
        { value: 1e9, symbol: ' مليار ريال' }, // G
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' }
      ]
    }
  },
  methods: {
    numberFormatter(num, digits = 2, isToLocalString = false) {
      let i
      for (i = this.si.length - 1; i > 0; i--) {
        if (num >= this.si[i].value) {
          break
        }
      }

      if (!isToLocalString) {
        const digitsNumber = (num / this.si[i].value)
          .toFixed(digits)
          .includes('.000')
          ? 0
          : digits

        const result =
          (num / this.si[i].value).toFixed(digitsNumber) + this.si[i].symbol
        return result
      } else {
        const result = !isNaN(num)
          ? num?.toLocaleString('en-US').split('.')[0] + this.si[i].symbol
          : num
        return result
      }
    }
  }
}
