<template>
  <v-btn outlined v-if="envs && envs.length > 1">
    <template>
      <v-row justify="center">
        <v-menu
          bottom
          content-class="envSwitching elevation-0"
          offset-x
          transition="slide-y-transition"
          min-width="160"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="grey--text"
              >mdi-home</v-icon
            >
          </template>
          <v-list class="pa-0">
            <v-list-item
              class="pointer"
              @click="changeEnv(item)"
              :key="i"
              v-for="(item, i) in envs"
            >
              <v-icon class="primary white--text">mdi-home</v-icon>
              <v-list-item-title class="font-14 mr-3 font-weight-medium">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </template>
  </v-btn>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'envSwitching',
  data() {
    return {}
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    changeEnv(env) {
      if (this.activeEnvId === env._id) {
        this.addNotification({
          text: `انت متواجد بالفعل في هذه البيئه`,
          color: 'grey'
        })
      } else {
        localStorage.setItem('currentEnv', JSON.stringify(env))
        location.reload()
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    user() {
      return JSON.parse(localStorage.getItem('tokenData'))
    },
    envs() {
      const avaliableEnvs = this.user?.environments?.filter((env) => {
        return env.customerRegistered == true
      })
      return avaliableEnvs
    },
    activeEnvId() {
      return this.currentEnv?._id ?? ''
    }
  }
}
</script>

<style></style>
