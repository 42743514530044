<template>
  <div class="mt-6">
    <div class="my-6 d-flex justify-space-between align-center flex-wrap">
      <h4>الموافقات ({{ approvel?.levels }})</h4>
      <div
        class="d-flex justify-between align-center font-weight-bold flex-wrap"
      >
        <span
          class="ma-0 font-14 font-weight-bold tabel-tag px-6"
          :class="`${generateStyleClasses(approvel?.status)}--bill`"
        >
          {{ localizeStatus(approvel?.status) }}
        </span>
        <div
          v-if="
            approvel?.status === 'pending' &&
            currentApprovalOwner.status === 'pending'
          "
          class="d-flex"
        >
          <span v-if="approvel?.canApprove">
            هذة العملية تتطلب منك الموافقة
          </span>
          <span v-else> هذة العملية تتطلب الموافقة </span>

          <span
            v-if="approvel?.canApprove"
            class="d-flex justify-center align-center"
          >
            <v-btn @click="$emit('approved')" class="btn g-brder" small>
              <v-icon class="mr-2 green">mdi-check</v-icon>
            </v-btn>
            <v-btn @click="$emit('rejected')" class="btn r-brder" small>
              <v-icon class="mr-2 red">mdi-close</v-icon>
            </v-btn>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { localizeStatus, generateStyleClasses } from '@/constans/approval'
export default {
  props: {
    approvel: {
      type: Object,
      default: () => {}
    },
    currentApprovalOwner: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    localizeStatus(status) {
      return localizeStatus(status)
    },
    generateStyleClasses(status) {
      return generateStyleClasses(status)
    }
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-btn.v-btn--has-bg {
  padding: 0 2rem;
}

.lable {
  background-color: #f44336;
  color: white;
  padding: 0.3rem 1.8rem;
  border-radius: 8rem;
  font-size: 0.8rem;
  pointer-events: none;
}

.b-b {
  border-bottom: 1px solid #e0e0e0;
}

.btn {
  padding: 1px 8px !important;
  margin: 0 8px;
  font-size: 8px;
  min-width: auto !important;
  box-shadow: 0 0 0 0 !important;
  background-color: transparent !important;
  i {
    font-size: 18px;
    margin: 0 !important;

    &.red {
      color: #f44336;
      background-color: transparent !important;
    }
    &.green {
      color: #4caf50;
      background-color: transparent !important;
    }
  }
  &.g-brder {
    border: 1px solid #4caf4f75;
  }
  &.r-brder {
    border: 1px solid #f4433670;
  }
}
</style>
