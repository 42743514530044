<template>
  <v-dialog persistent v-model="dialogState_" max-width="900px">
    <v-card class="contract-modal">
      <v-card-title
        class="pl-2 pr-4 pt-3 pb-5 mb-5 d-flex justify-space-between"
      >
        <div>
          <div class="headline ft">
            عقد
            {{ contract && contract.type === 'rent' ? 'ايجار' : 'ادارة املاك' }}
          </div>
          <v-subheader class="pa-0 mt-2 line-height-1">{{
            contract && contract.realEstate.type.nameAr
          }}</v-subheader>
        </div>

        <v-spacer></v-spacer>

        <v-btn
          fab
          text
          color="grey"
          class="ma-0 mt-2"
          @click.prevent="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="property-info-list">
          <v-list-item>
            <v-list-item-icon class="ml-5 mt-3">
              <v-icon color="info">mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>العقار</v-list-item-subtitle>
              <v-list-item-title>{{
                contract.realEstate.type.nameAr +
                ' ' +
                contract.realEstate.address.address
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon class="ml-5 mt-3">
              <v-icon color="info">mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>السعر</v-list-item-subtitle>
              <v-list-item-title>{{ contract.price }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon class="ml-5 mt-3">
              <v-icon color="info">mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>{{
                contract.type === 'rent' ? 'المستأجر' : 'المالك'
              }}</v-list-item-subtitle>
              <v-list-item-title>{{ contract.client.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon class="ml-5 mt-3">
              <v-icon color="info">mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>تاريخ بداية العقد</v-list-item-subtitle>
              <v-list-item-title>{{
                new Date(contract.dateStart).toISOString().substr(0, 10)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon class="ml-5 mt-3">
              <v-icon color="info">mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>تاريخ انشاء العقد</v-list-item-subtitle>
              <v-list-item-title>{{
                new Date(contract.createdAt).toISOString().substr(0, 10)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon class="ml-5 mt-3">
              <v-icon color="info">mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle> مدة العقد </v-list-item-subtitle>
              <v-list-item-title v-if="this.duration">{{
                this.duration.name
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon class="ml-5 mt-3">
              <v-icon color="info">mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle> الدفع كل </v-list-item-subtitle>
              <v-list-item-title v-if="this.payType">{{
                this.payType.name
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon class="ml-5 mt-3">
              <v-icon color="info">mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle> تم انشاء العقد </v-list-item-subtitle>
              <v-list-item-title>{{
                this.contract.DoneBy.name
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon class="ml-5 mt-3">
              <v-icon color="info">mdi-home-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-subtitle> الخدمات </v-list-item-subtitle>
              <v-list-item-content>
                <v-list-item>
                  <v-list-item-title>{{
                    this.contract.services.map((i) => i.serviceId.nameAr)
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          x-large
          rounded
          min-width="300"
          :loading="isLoading"
          class="mb-4"
          color="info"
          @click="downloadContract"
        >
          <v-icon class="send-icon ml-2">mdi-cloud-download-outline</v-icon>
          <span class="tf">تحميل صورة من العقد</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import '@/styles/material-dashboard/_add-new-property.scss'

export default {
  props: ['dialogState', 'contract', 'durations'],
  mounted() {
    // alert()
    setTimeout(() => {
      const arr = [...this.durations]
      const index = arr.findIndex((i) => i._id === this.contract.payType)
      const durationIndex = arr.findIndex(
        (i) => i._id === this.contract.duration
      )
      console.log('mounted -> index', index)
      this.payType = arr[index]
      this.duration = arr[durationIndex]
      console.log('dfgdfg', this.durations, this.contract)
    }, 500)
  },

  data() {
    return {
      isLoading: false,
      payType: null,
      duration: null,
      userInfo: [
        {
          name: 'العقار',
          value: 'شقة رقم ١٣٤',
          icon: 'home-outline'
        },
        {
          name: 'المكتب',
          value: 'مكتب الصفا والمروة للعقارات',
          icon: 'office-building-outline'
        },
        {
          name: 'السعر/النسبة المتفق عليها',
          value: '5%',
          icon: 'account-cash-outline'
        },
        {
          name: 'الخدمات',
          value: 'فواتير، إدارة أملاك، تحصيل، حراسة، تشطيب، صيانة',
          icon: 'cog-outline'
        },
        {
          name: 'الموظف المنفذ',
          value: 'جابر الأبيض',
          icon: 'account-outline'
        },
        {
          name: 'الموظف المسؤول',
          value: 'عبود الأحمر',
          icon: 'account-outline'
        },
        {
          name: 'المالك',
          value: 'فهد الأسدي',
          icon: 'account-outline'
        },
        {
          name: 'طريقة تحصيل المستحقات',
          value: 'نصف سنوي',
          icon: 'cash-multiple'
        },
        {
          name: 'فترة العقد',
          value: '٧ عقود',
          icon: 'calendar'
        },
        {
          name: 'ارشيف الدفعات',
          value: 'قيمة الحقل',
          icon: 'archive-outline'
        },
        {
          name: 'تاريخ بداية العقد',
          value: '12/09/2020',
          icon: 'calendar-check-outline'
        },
        {
          name: 'تاريخ إنشاء العقد  ',
          value: '12/09/2020',
          icon: 'calendar-check-outline'
        },
        {
          name: 'سندات الصرف ',
          value: 'امكانية الارفاق أو اصدار/انشاء',
          icon: 'cash-register'
        }
      ]
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'user', 'status', 'loggedIn']),
    firstName() {
      if (this.user && this.user.name) {
        const name = this.user.name
        return name.replace(/ .*/, '')
      }
      return null
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('close-contract-dialog')
    },
    downloadContract() {}
  }
}
</script>

<style lang="scss">
.contract-modal .property-info-list {
  .v-list-item {
    flex-basis: 50%;
  }
}

.contract-modal .v-card__title {
  border-bottom: 1px solid #eff2f7;

  .headline,
  .v-subheader {
    line-height: 1 !important;
  }

  .v-subheader {
    height: auto;
  }
}
</style>
