<template>
  <form-wizard
    :start-index="startIndex"
    back-button-text="رجوع"
    color="#3cab74"
    error-color="#e74c3c"
    finish-button-text="تسجيل"
    id="register-wizard"
    next-button-text="التالي"
    ref="regWizard"
    shape="circle"
    step-size="xs"
    subtitle
    title
  >
    <!-- Step #1 ----------------------------------------------------->
    <tab-content :before-change="() => validateStep('step1')">
      <register-step1
        :phone="this.query.phoneNumber"
        @on-validate="onCheckPhoneNumber"
        page="register"
        ref="step1"
      >
      </register-step1>
    </tab-content>

    <!-- Step #2 ----------------------------------------------------->
    <tab-content :before-change="() => validateStep('step2')">
      <register-step2
        :confirmationCode="finalModel.confirmationCode"
        :password="finalModel.password"
        :phone-number="trimedPhone"
        @on-validate="onConfirmPhoneNumber"
        @sendUserConfirmCode="sendNewCodeOTP"
        page="register"
        ref="step2"
        v-if="Object.keys(finalModel).length"
      >
      </register-step2>
    </tab-content>

    <!-- Step #3 ----------------------------------------------------->
    <tab-content :before-change="() => validateStep('step3')">
      <register-step3
        :password="finalModel.password"
        :phone-number="trimedPhone"
        :userEmail="query.email"
        :userName="query.name"
        @changePassword="finalModel.password = $event"
        @add-user="userDetailsStep"
        @location-step="setLocationStep"
        @on-validate="collectData"
        @termsConditionValidation="setTermsConditionsStatus"
        page="register"
        ref="step3"
        v-if="Object.keys(finalModel).length"
      >
      </register-step3>
    </tab-content>

    <!-- Step #4 ----------------------------------------------------->
    <tab-content
      class="register-step-4"
      :before-change="() => validateStep('step4')"
    >
      <register-step4
        @add-user="userDetailsStep"
        @on-validate="collectData"
        page="register"
        ref="step4"
        v-if="Object.keys(finalModel).length"
      >
      </register-step4>
    </tab-content>

    <template slot="footer" slot-scope="props">
      <div class="d-flex align-center justify-space-between my-3">
        <!---------Check terms and condation----------->
        <v-flex
          v-if="props.activeTabIndex === 2"
          d-flex
          justify-space-between
          align-self-center
        >
          <div class="d-flex align-self-center">
            <v-checkbox
              class="ma-0 pa-0"
              data-test="checkTermsConditions"
              hide-details="auto"
              v-model="termsConditions.status"
              @change="setTermsConditionsStatus"
            ></v-checkbox>
            <h6 class="ma-0">
              <a
                class="black--text"
                :href="termsConditions.path"
                :target="termsConditions.target"
              >
                {{ termsConditions.title }}
              </a>
            </h6>
          </div>
        </v-flex>

        <!---------Go to help center----------->
        <!-- <div v-if="props.activeTabIndex > 0">
          <h6>
            <router-link
              class="black--text d-flex"
              to="/help-center"
              target="_blank"
            >
              <img :src="helpCenterIcon" loading="lazy" />
              <span class="mr-2">مركز المساعده</span>
            </router-link>
          </h6>
        </div> -->
      </div>

      <div class="wizard-footer-left mt-4 w-100">
        <v-row class="d-flex" v-if="stepper === 'next' && !props.isLastStep">
          <v-col cols="3" v-if="props.activeTabIndex != 2">
            <v-btn
              data-test="backTab"
              @click.prevent="
                props.activeTabIndex == 0
                  ? $emit('backToLogin')
                  : props.prevTab()
              "
              block
              class="wizard-footer-right ma-0"
              color="grey"
              large
            >
              <!-- : validateStep('step' + props.activeTabIndex) -->
              <span class="tf"> السابق</span>
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-btn
              :loading="spinnerLoading"
              data-test="nextTab"
              @click.prevent="nextTab(props)"
              block
              class="wizard-footer-right ma-0"
              color="primary"
              large
              :disabled="
                (props.activeTabIndex == 0 &&
                  $refs['step1'] &&
                  $refs['step1'].phoneData &&
                  !$refs['step1'].phoneData.valid) ||
                (props.activeTabIndex == 2 &&
                  $refs['step3'] &&
                  !$refs['step3'].isValid) ||
                (statusStepWriteInfo && !termsConditionChecked) ||
                props.activeTabIndex == 1
              "
            >
              <span class="tf"> التالي</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-btn
          :disabled="!termsConditionChecked"
          :loading="spinnerLoading"
          data-test="submit"
          @click.prevent="submit(props.isLastStep)"
          block
          class="wizard-footer-right finish-button ma-0"
          color="primary"
          large
          v-else
        >
          <span class="tf">تسجيل حساب جديد</span>
        </v-btn>
      </div>
    </template>
    <EnvsModal
      ref="envsModal"
      @changeDialogState="envsModal = $event"
      @closed-add-dialog="selectedEnvClick"
      v-bind="envModalProps"
    ></EnvsModal>
  </form-wizard>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import RegisterStep1 from '@/components/login-register/RegisterStep1'
import RegisterStep2 from '@/components/login-register/RegisterStep2'
import RegisterStep3 from '@/components/login-register/RegisterStep3'
import RegisterStep4 from '@/components/login-register/RegisterStep4'
import EnvsModal from '@/components/modals/EnvsModal.vue'
import { customersService, userService } from '@/services'

export default {
  props: ['query'],
  components: {
    FormWizard,
    TabContent,
    RegisterStep1,
    RegisterStep2,
    RegisterStep3,
    RegisterStep4,
    EnvsModal
  },
  data() {
    return {
      EnvsModalBtnLoading: false,
      termsConditions: {
        title:
          'بإكمال عملية التسجيل أكون قد اتطلعت على الشروط و الأحكام , سياسية الخصوصية و وافقت علي إتفاقية الإشتراك و الإستخدام',
        path: 'https://hectar.io/terms&conditions',
        target: '_blank',
        status: false
      },
      helpCenterIcon: require('@/assets/img/svgs/register-help-center.svg'),
      termsConditionChecked: false,
      statusStepWriteInfo: false,
      errorMessages: 'Incorrect login info',
      color: 'general',
      showPassword: false,
      finalModel: {},
      startIndex: 0,
      phoneData: {},
      submitted: false,
      stepper: 'next',
      envsModal: false,
      envs: [],
      oldPhoneNumber: null,
      OTP: {
        newCode: false,
        isCodeValid: false
      }
    }
  },
  provide() {
    return {
      OTP: this.OTP
    }
  },
  created() {
    // check if thier is a signed in token
    // forward to the user details registetration step
    const account = JSON.parse(localStorage.getItem('tokenData'))
    if (!account) {
      return
    }
    if (!account.registered) {
      if (account.environments) {
        const inArray = account.environments.find((environment) => {
          return (
            environment.employeeRole === 'member' ||
            environment.employeeRole === 'admin'
          )
        })
        if (inArray) {
          localStorage.setItem('user-invitation', JSON.stringify(account))
        }
      }
      // if the user role is admin or member show the invited user step
      // registration incomplete show form
      this.statusStepWriteInfo = true
      this.startIndex = 2
      this.finalModel = {
        phoneNumber: account.phoneNumber
      }
    }
  },
  computed: {
    ...mapState('accountState', ['status']),
    ...mapState('userState', ['spinnerLoading']),
    ...mapGetters('accountState', ['isCustomer']),
    envModalProps() {
      return {
        dialogState: this.envsModal,
        envsData: this.envs,
        btnLoading: this.EnvsModalBtnLoading
      }
    },
    getCurrentCustomerRole() {
      return this.getActiveCustomerRole || this.getDefaultcustomerRole
    },
    getActiveCustomerRole() {
      return JSON.parse(localStorage.getItem('activeCustomerRole'))
    },
    getDefaultcustomerRole() {
      return localStorage.getItem('user-invitation')
        ? JSON.parse(localStorage.getItem('user-invitation')).environments[0]
            ?.role[0]
        : []
    },
    isItCustomerInvitation() {
      const userInvitation = JSON.parse(localStorage.getItem('user-invitation'))
      const customerInvited = userInvitation?.environments[0]?.customerInvited
      return customerInvited ? customerInvited : false
    },
    isItEmployeeInvitation() {
      const userInvitation = JSON.parse(localStorage.getItem('user-invitation'))
      const invitedAs = userInvitation?.environments[0]?.invitedAs
      return invitedAs ? invitedAs : false
    },
    trimedPhone() {
      return this.finalModel.phoneNumber.replace(/ /g, '')
    },
    user() {
      return {
        password: this.finalModel.password,
        phoneNumber: this.finalModel.phoneNumber
      }
    },
    validateTermsCheckbox() {
      const checkBoxStatus = JSON.parse(
        localStorage.getItem('tokenData')
      )?.active
      return checkBoxStatus ? false : !this.termsConditionChecked
    }
  },
  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    ...mapActions('accountState', [
      'login',
      'checkPhoneNumber',
      'confirmPhoneNumber',
      'register',
      'userDetails',
      'updateUserDetails'
    ]),
    ...mapMutations('accountState', [
      'otpConfirmed',
      'loginSuccess',
      'loginFailure',
      'loginRequest',
      'setLoggedInStatus',
      'setCustomerRole'
    ]),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapMutations('appState', ['addNotification', 'setSync']),
    nextTab(props) {
      const invitaion = JSON.parse(localStorage.getItem('user-invitation'))
      if (invitaion?._id && props.activeTabIndex === 0) {
        return this.validateStep('step1')
      }
      if (invitaion?._id && props.activeTabIndex === 1) {
        return this.validateStep('step2')
      }
      if (props.activeTabIndex === 0) {
        return this.validateStep('step1')
      }
      if (props.activeTabIndex === 1) {
        return this.validateStep('step2')
      }
      return props.nextTab()
    },
    handlingOtpError(errMsg) {
      return errMsg === 'الرقم المدخل خاطئ'
        ? 'رمز التحقق الذي ادخلته خاطئ، يرجى المحاولة مجددا'
        : 'حدث خطأ أثناء اضافه المستخدم, يرجي المحاوله لاحقا'
    },
    submit(isLastStep) {
      if (this.$route.query.invitation) {
        const invitation = JSON.parse(localStorage.getItem('user-invitation'))
        if (!this.isItCustomerInvitation) {
          this.validateStep('step3')
        }
        this.userDetailsStep(invitation)
      } else {
        if (isLastStep) {
          this.validateStep('step4')
        }
        this.validateStep('step3')
      }
    },
    setTermsConditionsStatus(emitedTermsStatus) {
      this.termsConditionChecked = emitedTermsStatus
    },

    validateStep(name) {
      const refToValidate = this.$refs[name]
      if (refToValidate.validate()) {
        this.$emit('stepName', name)
        return true
      }
      return false
    },

    collectData(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model)
      }
    },

    // Register form ---------------------------------
    async onCheckPhoneNumber({ phoneNumber, password }) {
      const phone = { phoneNumber: phoneNumber.replace(/ /g, '') }
      const passwordCode = { password }
      this.collectData(phone, true)
      this.collectData(passwordCode, true)
      this.setSpinnerLoading(true)

      try {
        if (this.finalModel.phoneNumber !== this.oldPhoneNumber) {
          const { data } = await this.checkPhoneNumber(phone)
          this.collectData({ confirmationCode: data.confirmationCode }, true)
          this.addNotification({
            text: 'تم إرسال الرقم التأكيدي لهاتفك بنجاح!',
            color: 'success'
          })
          this.oldPhoneNumber = this.finalModel.phoneNumber
          this.OTP.newCode = true
        }
        // Go to next step
        this.$refs.regWizard.changeTab(0, 1)
        this.setSpinnerLoading(false)
      } catch {
        this.setSpinnerLoading(false)
        this.addNotification({
          text: 'رقم الهاتف مُسجل من قبل، ادخل رقم جديد او قم بتسجيل الدخول مباشرةً بهذا الرقم!',
          color: 'blue-grey'
        })
        this.$refs.regWizard.changeTab(1, 0)
      }
    },
    sendNewCodeOTP() {
      this.oldPhoneNumber = null
      this.onCheckPhoneNumber(this.finalModel)
    },
    async onConfirmPhoneNumber(data) {
      try {
        this.collectData(data, true)
        this.setSpinnerLoading(true)

        const response = await this.confirmPhoneNumber(data)

        if (response.data.success) {
          this.addNotification({
            text: 'تم التحقق من رقم هاتفك',
            color: 'green'
          })
          this.statusStepWriteInfo = true
          this.registerStep(this.user)
          if (!this.isItCustomerInvitation) {
            this.$refs.regWizard.changeTab(1, 2)
          }
          this.setSpinnerLoading(false)
        } else {
          this.addNotification({
            text: 'رقم التحقق غير صحيح',
            color: 'error'
          })
          this.setSpinnerLoading(false)
        }
      } catch (error) {
        this.addNotification({
          text: this.handlingOtpError(error.response?.data?.message),
          color: 'error'
        })
        this.setSpinnerLoading(false)
      }
    },
    async accepteInvitaion(accountId) {
      const invitation = JSON.parse(localStorage.getItem('user-invitation'))
      const body = {
        accountId,
        customerId: invitation._id,
        envId: this.$route.query?.env
      }
      try {
        await customersService.accepteRejectCustomerInvitaion('accept', body)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من قبول الدعوه برجاء المحاوله لاحقاً',
          color: 'error'
        })
      }
    },
    async handleAccepteRejectEmpInvitaion(response = 'accept') {
      const invitation = JSON.parse(localStorage.getItem('user-invitation'))
      const body = {
        environmentId: this.$route.query?.env,
        response
      }
      try {
        await customersService.accepteRejectEmpInvitaion(invitation?._id, body)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من قبول الدعوه برجاء المحاوله لاحقاً',
          color: 'error'
        })
      }
    },
    async registerStep(val) {
      try {
        const { data } = await this.register(val)
        if (data?.user?.token) {
          this.loginRequest(data.user)
        }
        if (data?.env && data.env?.owner) {
          localStorage.setItem('currentEnv', JSON.stringify(data.env))
        }

        if (this.isItCustomerInvitation) {
          await this.accepteInvitaion(data.user._id)
        } else if (this.isItEmployeeInvitation) {
          await this.handleAccepteRejectEmpInvitaion()
        }
        // set Customer Role
        if (this.getDefaultcustomerRole) {
          this.setCustomerRole(this.getCurrentCustomerRole)
        }
        this.submit()
        this.addNotification({
          text: 'تم تسجيل حسابك بنجاح، شكراً لك',
          color: 'success'
        })
        this.OTP.isCodeValid = true
        this.setSpinnerLoading(false)
        // Auto login after registration
        this.loginSuccess(data.user)
      } catch (error) {
        this.setSpinnerLoading(false)
        this.addNotification({
          text: error,
          color: 'error'
        })
      }
    },
    userDetailsStep(invitation) {
      const val = this.finalModel
      this.setSpinnerLoading(true)
      // check if user type is seeker then  should be updated
      const shouldUpdate = JSON.parse(localStorage.getItem('should-update'))
      if (shouldUpdate) {
        val._id = shouldUpdate._id

        this.updateUserDetails(val)
          .then((res) => {
            const oldData = JSON.parse(localStorage.getItem('tokenData'))
            const newData = Object.assign({}, oldData, res.data.user)
            localStorage.setItem('tokenData', JSON.stringify(newData))
            // Check for environments
            const envs = res.data.user.environments
            if (envs.length === 1) {
              localStorage.setItem('currentEnv', JSON.stringify(envs[0]))
            }
            if (envs.length > 1) {
              this.envs = envs
              this.envsModal = true
            }

            // set user to vuex
            this.loginSuccess(newData)
            this.setLoggedInStatus(true)
            // clear update flag from localstorage
            localStorage.removeItem('should-update')
            this.setSpinnerLoading(false)

            // get user subscription
            this.$store
              .dispatch('accountState/getUserSubscription')
              .then((subscription) => {
                localStorage.setItem(
                  'subscription',
                  JSON.stringify(subscription.subscription)
                )
              })
            // redirect to home or env
            return this.$router.push('/dashboard')
          })
          .catch((err) => {
            this.setSpinnerLoading(false)
          })
      }
      if (invitation) {
        val.registered = 1
        this.updateUserDetails(val)
          .then(async (res) => {
            const oldData = JSON.parse(localStorage.getItem('tokenData'))
            const newData = Object.assign({}, oldData, res.data.user)
            localStorage.setItem('tokenData', JSON.stringify(newData))
            // Check for environments
            const envs = res.data.user.environments
            if (envs.length === 1) {
              localStorage.setItem('currentEnv', JSON.stringify(envs[0]))
            }
            if (envs.length > 1) {
              this.envs = envs
              this.envsModal = true
            }

            // set user to vuex
            this.loginSuccess(newData)
            this.setLoggedInStatus(true)
            // clear invitaion from localstorage
            localStorage.removeItem('user-invitation')
            this.setSpinnerLoading(false)
            // get user subscription
            this.$store
              .dispatch('accountState/getUserSubscription')
              .then((userSubscription) => {
                localStorage.setItem(
                  'subscription',
                  JSON.stringify(userSubscription.subscription)
                )
              })
            const role = envs[0]?.employeeRole?.length ? 'employee' : 'customer'
            const { data } = await userService.setEnvironmentId(
              envs[0]?._id,
              role
            )
            await this.loginRequest({
              ...newData,
              userEnvToken: data.userEnvToken
            })
            // redirect to home or env
            this.$router.push('/dashboard').then(() => {
              // To add configuration to local storage
              location.reload()
            })
          })
          .catch((err) => {
            this.setSpinnerLoading(false)
          })
      }
      if (!invitation && !shouldUpdate) {
        this.userDetails(val)
          .then((res) => {
            if (res.data.user) {
              const oldDate = JSON.parse(localStorage.getItem('tokenData'))
              const newDate = Object.assign({}, oldDate, res.data.user)
              localStorage.setItem('tokenData', JSON.stringify(newDate))
              this.setSpinnerLoading(false)

              // set user to vuex
              this.loginSuccess(newDate)
              this.setLoggedInStatus(true)
              // get user subscription
              this.$store
                .dispatch('accountState/getUserSubscription')
                .then((res_) => {
                  if (res_) {
                    localStorage.setItem(
                      'subscription',
                      JSON.stringify(res_.subscription)
                    )
                  }
                })
              // redirect to home or env
              return this.$router.push('/setup-env')
            }
            return null
          })
          .catch((err) => {
            this.addNotification({
              text: err.response.data,
              color: 'error'
            })
            this.setSpinnerLoading(false)
          })
      }
    },
    setLocationStep(value) {
      this.stepper = value
    },
    selectedEnvClick(currentEnv) {
      this.EnvsModalBtnLoading = false
      localStorage.setItem('currentEnv', JSON.stringify(currentEnv))
      this.$store.commit('accountState/setCurrentEnv', currentEnv)
      // set Customer Role
      this.getDefaultcustomerRole &&
        this.setCustomerRole(this.getCurrentCustomerRole)
      this.$router
        .push(
          this.$route.query.redirectFrom ||
            `/dashboard?isCustomer=${this.isCustomer}`
        )
        .then(() => {
          this.envsModal = false
          this.EnvsModalBtnLoading = false
        })
      return location.reload()
    }
  }
}
</script>

<style>
.wizard-card-footer {
  display: flex;
  flex-direction: column;
}
</style>
