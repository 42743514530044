<template>
  <div>
    <no-data v-if="!users?.length" v-bind="noDataProps" />
    <v-data-table
      v-else
      :headers="headers"
      :items="users"
      class="hectar-table"
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.name`]="{ item }">
        <span>{{ item.name }} </span>
      </template>
      <template v-slot:[`item.titles`]="{ item }">
        <span>{{ item.department.name }} </span>
      </template>
      <template v-slot:[`item.jobTitlesCount`]="{ item }">
        <span>{{ item?.jobTitlesCount }} </span>
      </template>
      <template v-slot:[`item.text`]="{ item }">
        <span>{{ item.counts?.member }} </span>
      </template>
      <template v-slot:[`item.approved`]="{ item }">
        <span>{{ item.counts?.supervisor }} </span>
      </template>
      <template v-slot:[`item.controls`]="{ item }">
        <span>{{ item.counts?.admin }} </span>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon color="gray" @click="editDepartment(item)"
          >mdi-pencil-outline</v-icon
        >
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-icon
          :disabled="!item.isDeletable"
          color="error"
          @click="$emit('delete', item)"
          >mdi-trash-can</v-icon
        >
      </template>
    </v-data-table>

    <!----------FastActions-------->

    <div class="d-flex align-center justify-end ml-md-8 fast-actions">
      <v-btn
        @click="openAddDialog"
        color="primary"
        large
        class="font-weight-bold fast-actions-btn pa-5 font-12"
      >
        <img src="@/assets/img/svgs/user-w.svg" class="mx-2" loading="lazy" />
        {{ tabName === 'الأقسام' ? 'اضافة قسم' : 'اضافة وظيفة' }}
      </v-btn>

      <!----------------------------->
      <!-------Fast actions---------->
      <!----------------------------->
      <fastActions />
    </div>
    <!-- Add Department Modal -->
    <AddDepartmentModal
      v-if="tabName === 'الأقسام'"
      :dialogState="dialogState"
      :editDepartmentName="item"
      @changeDialogState="closeAddDialog"
    />
    <!-- Add Title Modal -->
    <AddTitleModal
      v-else
      :departmnets="departmnets"
      :dialogState="dialogState"
      :editTitleName="item"
      @changeDialogState="closeAddDialog"
    />
  </div>
</template>

<script>
import noData from '@/components/helper/noData.vue'
import fastActions from '@/components/helper/fastActions'
import AddDepartmentModal from '../components/modals/AddDepartmentModal.vue'
import AddTitleModal from '../components/modals/AddTitleModal.vue'

export default {
  name: 'DepartmentSettings',
  components: {
    fastActions,
    AddDepartmentModal,
    noData,
    AddTitleModal
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    departmnets: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    tabName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogState: false,

      item: {},
      noDataConfig: {
        noDataText: 'لا يوجد بيانات مضافه حتي الأن',
        noDataSubTitle: 'تظهر جميع البيانات المضافة في هذه القائمة ',
        noDataIcon: require('@/assets/img/dashboard-icons/contract-icon.png')
      }
    }
  },

  computed: {
    noDataProps() {
      return this.noDataConfig
    }
  },
  methods: {
    openAddDialog() {
      this.dialogState = true
    },
    closeAddDialog() {
      this.dialogState = false
      this.$emit('reloadDepartments')
    },
    editDepartment(item) {
      this.openAddDialog()
      this.item = item
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/material-dashboard/fastactions.scss';
</style>
