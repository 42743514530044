<template>
  <v-row class="mt-6 justify-space-between flex-wrap">
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">القسم</h6>
      <div class="font-12 my-1">
        <h5 class="font-14 font-weight-medium pa-0 ma-0 text-truncate">
          {{ approval?.typeName?.ar ?? '-' }}
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">اسم العقار</h6>
      <div class="font-12 my-1">
        <h5 class="font-14 font-weight-medium pa-0 ma-0 text-truncate">
          {{ approval?.realEstate?.[0]?.name ?? '-' }}
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">رقم الوحدة</h6>
      <div class="font-12 my-1">
        <h5 class="font-14 font-weight-medium pa-0 ma-0 text-truncate">
          {{ approval?.unitNum ?? '-' }}
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">منشئ الطلب</h6>
      <div class="font-12 my-1">
        <h5 class="font-14 font-weight-medium pa-0 ma-0 text-truncate">
          {{ approval?.createdBy?.name ?? '-' }}
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">تاريخ الطلب</h6>
      <div class="font-12 my-1">
        <h5 class="font-14 font-weight-medium pa-0 ma-0 text-truncate">
          {{ new Date(approval?.updatedAt).toLocaleDateString('en-CA') ?? '-' }}
        </h5>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    approval: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
