<template>
  <v-container>
    <v-row v-if="bankAccounts.length">
      <h6 class="ma-0 text--101010 font-14">بيانات حساب البنك</h6>
    </v-row>
    <v-form class="my-2" v-model="isValid" v-if="!bankAccountsLoading">
      <v-row v-for="(bankAccount, i) in bankAccounts" :key="i">
        <v-col class="mobile__pl__0 pr-0 my-auto">
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="إسم البنك"
            v-model="bankAccounts[i].bankName"
            :rules="[(v) => !!v || '']"
          />
        </v-col>
        <v-col class="mobile__pl__0 pr-0 my-auto">
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="إسم الحساب"
            v-model="bankAccounts[i].name"
            :rules="[(v) => !!v || '']"
          />
        </v-col>
        <v-col class="mobile__pl__0 pr-0 my-auto">
          <v-text-field
            class="pb-2"
            dense
            filled
            hide-details
            placeholder="رقم الإيبان الخاص بالحساب"
            v-model="bankAccounts[i].iban"
            :rules="[
              (v) =>
                /^[A-Z]{2}(?:[ ]?[0-9]){22}$/.test(v) ||
                'يرجي التحقق من ال IBAN'
            ]"
          />
        </v-col>
        <div class="d-flex justify-end align-center">
          <v-icon
            class="mx-2 pointerCursor"
            size="25"
            @click="removeFormRecord"
          >
            mdi-trash-can-outline
          </v-icon>
        </div>
      </v-row>
    </v-form>
    <Loader v-else v-bind="loaderProps" class="my-3" />
    <v-row class="mt-0">
      <v-btn
        color="primary"
        class="rounded-7 mt-1"
        :disabled="!isValid"
        @click="addAnotherBankAccount"
      >
        <div class="d-flex">
          <p class="font-12 ma-0 mx-1">إضافة حساب بنكي أخر</p>
          <v-icon size="18">mdi-plus</v-icon>
        </div>
      </v-btn>
    </v-row>
    <v-row class="mt-0 justify-end" v-if="isBankAccountSaveBtn">
      <v-btn
        data-test="updateEnvSettings"
        color="primary"
        class="rounded-15 px-13 py-3"
        @click="bindBankAccountsData"
        :disabled="!isValid || !bankAccounts.length"
        :loading="addBanksDataLoading"
      >
        <span class="font-16"> حفظ </span>
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import Loader from '@/components/helper/loader.vue'
export default {
  name: 'banksAccountsForm',
  components: {
    Loader
  },
  props: {
    isBankAccountSaveBtn: {
      default: false,
      type: Boolean
    },
    addBanksDataLoading: {
      default: false,
      type: Boolean
    },
    bankAccountsLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      isValid: false,
      bankAccounts: [{}],
      bankData: {
        bankName: '',
        name: '',
        iban: ''
      }
    }
  },
  watch: {
    isValid(val) {
      this.$emit('setBankAccountValidation', val)
    }
  },
  computed: {
    loaderProps() {
      return {
        numberOfLines: 4,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    }
  },
  methods: {
    bindBankAccountsData() {
      this.$emit('bindBankAccountsData', this.bankAccounts)
      this.bankAccounts = [{}]
    },
    removeFormRecord() {
      this.bankAccounts.pop()
    },
    addAnotherBankAccount() {
      this.bankAccounts = [...this.bankAccounts, { ...this.bankData }]
    }
  }
}
</script>
