<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="approvals"
      hide-default-footer
      mobile-breakpoint="0"
      class="hectar-table noScroll pointerCursor"
      @click:row="handleClick"
    >
      <template v-slot:[`item.actionName`]="{ item }">
        <div
          class="justify-center align-center d-flex flex-wrap"
          v-if="item.actionName?.ar"
        >
          <div class="brub--tag tabel-tag text-center ml-1 font-12 my-1 w-100">
            <h5 class="font-12 font-weight-medium pa-0 ma-0 text-truncate">
              {{ item.actionName?.ar ?? '-' }}
            </h5>
          </div>
        </div>
      </template>

      <template v-slot:[`item.typeName`]="{ item }">
        <h6 class="font-14 font-weight-medium ml-md-6 ma-4">
          {{ item.typeName?.ar ?? '-' }}
        </h6>
      </template>
      <template v-slot:[`item.realEstate`]="{ item }">
        <h6 class="font-14 font-weight-medium ml-md-6 ma-4">
          {{ item.realEstate?.[0]?.name ?? '-' }}
        </h6>
      </template>
      <template v-slot:[`item.unit`]="{ item }">
        <h6 class="font-14 font-weight-medium ml-md-6 ma-4">
          {{ item?.unit ?? '-' }}
        </h6>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <h6 class="font-14 font-weight-medium ml-md-6 ma-4">
          {{ new Date(item?.createdAt).toLocaleDateString('en-CA') ?? '-' }}
        </h6>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div
          class="justify-center align-center d-flex flex-wrap"
          v-if="item?.status"
        >
          <div
            class="tabel-tag text-center ml-1 font-12 my-1 d-flex justify-between align-center"
            :class="`${generateStyleClasses(item?.status)}--bill`"
          >
            <h5 class="font-12 font-weight-medium px-4 ma-0 text-truncate">
              {{ localizeStatus(item?.status) ?? '-' }}
            </h5>
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          content-class="dropdown-menu"
          elevation-0
          right
          transition="slide-y-transition"
          :value="threeDotsState"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="">
              <v-btn
                class="mobile__ml__"
                color="lamb"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </div>
          </template>
          <v-list>
            <ItemActions
              :item="item"
              @accept="$emit('accept', { item, status: 'approved' })"
              @reject="$emit('reject', { item, status: 'rejected' })"
              @show-details="$emit('show-details', item)"
            />
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ItemActions from './ItemActions.vue'
import { localizeStatus, generateStyleClasses } from '@/constans/approval'
import { ApprovalDataTable } from '@/constans/approval'
export default {
  name: 'ApprovalDataTable',
  components: {
    ItemActions
  },
  props: {
    approvals: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      threeDotsState: false,
      ...ApprovalDataTable
    }
  },
  methods: {
    handleClick(item) {
      this.$emit('openDetails', item)
    },
    localizeStatus(status) {
      return localizeStatus(status)
    },
    generateStyleClasses(status) {
      return generateStyleClasses(status)
    }
  }
}
</script>
