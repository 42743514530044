<template>
  <v-form ref="form" v-model="isValid">
    <!-- informativeCard -->
    <v-row no-gutters>
      <v-col class="py-2 px-4 bg-brub rounded-xl d-flex">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="-0.000244141"
            width="20"
            height="20"
            rx="10"
            fill="#662D91"
            fill-opacity="0.1"
          />
          <g clip-path="url(#clip0_13864_307)">
            <path
              d="M12.4228 14.5743L12.2598 15.2407C11.7708 15.4337 11.3801 15.5806 11.0892 15.6816C10.798 15.7829 10.4597 15.8333 10.0741 15.8333C9.48212 15.8333 9.02163 15.6883 8.6932 15.4002C8.36478 15.111 8.20051 14.7445 8.20051 14.2999C8.20051 14.1278 8.21243 13.9508 8.23721 13.7704C8.2622 13.5899 8.30194 13.3865 8.35631 13.1592L8.96747 10.996C9.02184 10.7889 9.06806 10.5926 9.10507 10.407C9.14261 10.2226 9.1607 10.0529 9.1607 9.90009C9.1607 9.62384 9.1035 9.43061 8.98964 9.32197C8.87577 9.21364 8.65849 9.15864 8.33592 9.15864C8.17793 9.15864 8.01554 9.18395 7.85002 9.23288C7.68377 9.28203 7.54167 9.3295 7.42236 9.37362L7.58579 8.70673C7.98616 8.54372 8.36885 8.40413 8.73492 8.28827C9.10099 8.1721 9.44688 8.11397 9.77406 8.11397C10.362 8.11397 10.8157 8.25607 11.1343 8.54027C11.4529 8.82467 11.6121 9.19336 11.6121 9.64768C11.6121 9.74168 11.6017 9.9073 11.5792 10.144C11.5573 10.3813 11.5164 10.5987 11.4568 10.7964L10.8482 12.9509C10.7983 13.1239 10.7535 13.3218 10.7145 13.5444C10.6742 13.7655 10.655 13.9345 10.655 14.0478C10.655 14.3339 10.7188 14.5292 10.8467 14.6331C10.9755 14.7369 11.1973 14.7886 11.5125 14.7886C11.6605 14.7886 11.8288 14.7623 12.0159 14.7108C12.2024 14.6592 12.3384 14.6139 12.4228 14.5743ZM12.5771 5.5291C12.5771 5.90448 12.4357 6.22506 12.1514 6.48866C11.8678 6.7532 11.5261 6.88558 11.1264 6.88558C10.7254 6.88558 10.3828 6.7532 10.096 6.48866C9.80971 6.22496 9.66625 5.90448 9.66625 5.5291C9.66625 5.15446 9.80971 4.83335 10.096 4.5664C10.3823 4.29988 10.7255 4.16666 11.1264 4.16666C11.526 4.16666 11.8678 4.30019 12.1514 4.5664C12.4359 4.83335 12.5771 5.15456 12.5771 5.5291Z"
              fill="#662D91"
            />
          </g>
          <defs>
            <clipPath id="clip0_13864_307">
              <rect
                width="11.6667"
                height="11.6667"
                fill="white"
                transform="translate(4.16638 4.16666)"
              />
            </clipPath>
          </defs>
        </svg>
        <h6 class="text-primary font-12 ma-0 mr-2 font-weight-bold">
          سيتم إنشاء مسودة عقد إيجار بعد إنشاء العرض والموافقة علية من قبل
          العميل، يجب مراجعة العقد بعد ذلك وتأكيده ليتحول لنشط بحالة ساري
        </h6>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col class="mobile__pl__0 mt-3" cols="12" md="6">
        <h6 class="mt-0">هل تود إضافة فترة سماح ؟</h6>
        <v-card class="d-flex justify-space-between px-2">
          <h6 class="mx-1 font-14">
            {{ gracePeriodStatus ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            dense
            hide-details="auto"
            inset
            small
            v-model="gracePeriodStatus"
          />
        </v-card>
      </v-col>
      <v-col class="pr-4 mobile__pl__0" cols="12" sm="6">
        <v-row no-gutters>
          <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
            <h6 class="mt-4 mb-2">تاريخ بداية فتره السماح</h6>
            <v-dialog
              :return-value.sync="priceOfferInfo.startGracePeriod"
              ref="startAtDialogGracePeriod"
              v-model="startAtGraceModal"
              width="320px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :disabled="!gracePeriodStatus"
                  append-icon="mdi-calendar-month-outline"
                  dense
                  filled
                  hide-details
                  placeholder="تاريخ بداية فتره السماح"
                  readonly
                  v-bind="attrs"
                  v-model="startGracePeriod"
                  v-on="on"
                  class="date--dropdown font-14"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                scrollable
                :min="minDate"
                v-model="startGracePeriod"
              >
                <v-spacer></v-spacer>
                <v-btn @click="startAtGraceModal = false" color="primary" text>
                  الغاء
                </v-btn>
                <v-btn
                  @click="$refs.startAtDialogGracePeriod.save(startGracePeriod)"
                  color="primary"
                  text
                  >حفظ
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col class="mobile__pl__0" cols="12" sm="6">
            <h6 class="mt-4 mb-2">مدة فترة السماح</h6>
            <v-text-field
              :rules="[validateDayForGracePeriod]"
              :disabled="!gracePeriodStatus || !startGracePeriod"
              dense
              filled
              placeholder="0"
              suffix="يوم"
              class="font-14"
              v-model="daysGrace"
              @input="formatArabicNumberForGracePeriod($event, 'daysGrace')"
              @keydown="$formatNumberInputs"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- For dates -->
    <v-row no-gutters style="margin-top: -30px">
      <v-col class="pl-3 mobile__pl__0" cols="6" sm="3">
        <h6 class="mt-4 mb-2">تاريخ بداية التأجير</h6>
        <v-dialog
          :return-value.sync="priceOfferInfo.start"
          ref="startAtDialog"
          v-model="startAtModal"
          width="320px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :rules="[(v) => !!v || 'من فضلك ادخل تاريخ بداية التأجير']"
              append-icon="mdi-calendar-month-outline"
              dense
              :disabled="gracePeriodStatus"
              filled
              hide-details
              placeholder="تاريخ بداية التأجير"
              readonly
              v-bind="attrs"
              v-model="priceOfferInfo.start"
              v-on="on"
              class="date--dropdown font-14"
            ></v-text-field>
          </template>
          <v-date-picker color="primary" scrollable v-model="start">
            <v-spacer></v-spacer>
            <v-btn @click="startAtModal = false" color="primary" text>
              الغاء
            </v-btn>
            <v-btn @click="$refs.startAtDialog.save(start)" color="primary" text
              >حفظ
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col class="pl-3 mobile__pl__0" cols="6" sm="3">
        <h6 class="mt-4 mb-2">تاريخ نهاية التأجير</h6>
        <v-dialog
          :return-value.sync="priceOfferInfo.end"
          ref="endAtDialog"
          v-model="endAtModal"
          width="320px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :rules="[(v) => !!v || 'من فضلك ادخل تاريخ نهاية التأجير']"
              append-icon="mdi-calendar-month-outline"
              dense
              filled
              hide-details
              placeholder="تاريخ نهاية التأجير"
              readonly
              v-bind="attrs"
              v-model="priceOfferInfo.end"
              v-on="on"
              class="date--dropdown font-14"
              :disabled="!priceOfferInfo.start"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            :min="minEndDate"
            scrollable
            v-model="end"
            @change="handleEndDateChange"
          >
            <v-spacer></v-spacer>
            <v-btn @click="endAtModal = false" color="primary" text>
              الغاء
            </v-btn>
            <v-btn @click="$refs.endAtDialog.save(end)" color="primary" text
              >حفظ
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col class="pr-4 mobile__pl__0" cols="12" sm="6">
        <h6 class="ma-0 mt-4 mb-2">فترة التأجير</h6>
        <v-row>
          <v-col cols="4 pl-1">
            <v-text-field
              :rules="[validateYear]"
              dense
              filled
              placeholder="0"
              suffix="سنة"
              class="font-14"
              v-model="years"
              :disabled="!priceOfferInfo.start"
              @input="formatArabicNumber($event, 'years')"
              @keydown="$formatNumberInputs"
            />
          </v-col>
          <v-col cols="4 px-1">
            <v-text-field
              :rules="[validateMonth]"
              dense
              filled
              placeholder="0"
              suffix="شهر"
              class="font-14"
              v-model="months"
              :disabled="!priceOfferInfo.start"
              @input="formatArabicNumber($event, 'months')"
              @keydown="$formatNumberInputs"
            />
          </v-col>
          <v-col cols="4 pr-1">
            <v-text-field
              :rules="[validateDay]"
              dense
              filled
              placeholder="0"
              suffix="يوم"
              class="font-14"
              v-model="days"
              :disabled="!priceOfferInfo.start"
              @input="formatArabicNumber($event, 'days')"
              @keydown="$formatNumberInputs"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- informativeCard -->
    <v-row no-gutters class="margin-block">
      <v-col class="py-2 px-4 bg-brub rounded-xl d-flex">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="-0.000244141"
            width="20"
            height="20"
            rx="10"
            fill="#662D91"
            fill-opacity="0.1"
          />
          <g clip-path="url(#clip0_13864_307)">
            <path
              d="M12.4228 14.5743L12.2598 15.2407C11.7708 15.4337 11.3801 15.5806 11.0892 15.6816C10.798 15.7829 10.4597 15.8333 10.0741 15.8333C9.48212 15.8333 9.02163 15.6883 8.6932 15.4002C8.36478 15.111 8.20051 14.7445 8.20051 14.2999C8.20051 14.1278 8.21243 13.9508 8.23721 13.7704C8.2622 13.5899 8.30194 13.3865 8.35631 13.1592L8.96747 10.996C9.02184 10.7889 9.06806 10.5926 9.10507 10.407C9.14261 10.2226 9.1607 10.0529 9.1607 9.90009C9.1607 9.62384 9.1035 9.43061 8.98964 9.32197C8.87577 9.21364 8.65849 9.15864 8.33592 9.15864C8.17793 9.15864 8.01554 9.18395 7.85002 9.23288C7.68377 9.28203 7.54167 9.3295 7.42236 9.37362L7.58579 8.70673C7.98616 8.54372 8.36885 8.40413 8.73492 8.28827C9.10099 8.1721 9.44688 8.11397 9.77406 8.11397C10.362 8.11397 10.8157 8.25607 11.1343 8.54027C11.4529 8.82467 11.6121 9.19336 11.6121 9.64768C11.6121 9.74168 11.6017 9.9073 11.5792 10.144C11.5573 10.3813 11.5164 10.5987 11.4568 10.7964L10.8482 12.9509C10.7983 13.1239 10.7535 13.3218 10.7145 13.5444C10.6742 13.7655 10.655 13.9345 10.655 14.0478C10.655 14.3339 10.7188 14.5292 10.8467 14.6331C10.9755 14.7369 11.1973 14.7886 11.5125 14.7886C11.6605 14.7886 11.8288 14.7623 12.0159 14.7108C12.2024 14.6592 12.3384 14.6139 12.4228 14.5743ZM12.5771 5.5291C12.5771 5.90448 12.4357 6.22506 12.1514 6.48866C11.8678 6.7532 11.5261 6.88558 11.1264 6.88558C10.7254 6.88558 10.3828 6.7532 10.096 6.48866C9.80971 6.22496 9.66625 5.90448 9.66625 5.5291C9.66625 5.15446 9.80971 4.83335 10.096 4.5664C10.3823 4.29988 10.7255 4.16666 11.1264 4.16666C11.526 4.16666 11.8678 4.30019 12.1514 4.5664C12.4359 4.83335 12.5771 5.15456 12.5771 5.5291Z"
              fill="#662D91"
            />
          </g>
          <defs>
            <clipPath id="clip0_13864_307">
              <rect
                width="11.6667"
                height="11.6667"
                fill="white"
                transform="translate(4.16638 4.16666)"
              />
            </clipPath>
          </defs>
        </svg>
        <h6 class="text-primary font-12 ma-0 mr-2 font-weight-bold">
          يجب توفر مساحة العقار وسعر المتر وقيمة الإيجار لاكمال العرض ، تحديث
          المعلومات يتطلب الصلاحية لذلك وينعكس على معلومات العقار
        </h6>
      </v-col>
    </v-row>

    <!-- For edit -->
    <v-row no-gutters class="edit justify-center">
      <v-col cols="12" md="3" class="edit__input edit__input--mod">
        <v-layout flex-column align-center>
          <div class="d-flex align-center">
            <h6 class="ma-0 me-1">مساحة العقار</h6>
            <span
              v-if="isRealEstateExist"
              @click="edit('space')"
              class="edit__icon"
            >
              <img :src="editIcon" alt="edit" />
            </span>
          </div>
          <v-text-field
            ref="space"
            :class="[!status.space && 'pointer-none']"
            class="font-14"
            dense
            filled
            hide-details
            suffix="م²"
            :value="spaceValueMask"
            @input="handleValuesChanging($event, spaceValueKeys)"
            @keydown="$formatNumberInputs($event, true)"
          ></v-text-field>
        </v-layout>
      </v-col>
      <v-col cols="12" md="3" class="edit__input edit__input--mod mx-13">
        <v-layout flex-column align-center>
          <div class="d-flex align-center">
            <h6 class="ma-0 me-1">سعر المتر</h6>
            <span
              v-if="isRealEstateExist"
              @click="edit('meter')"
              class="edit__icon"
            >
              <img :src="editIcon" alt="edit" />
            </span>
          </div>
          <v-text-field
            ref="meter"
            class="font-14"
            :class="[!status.meter && 'pointer-none']"
            dense
            filled
            hide-details
            suffix="ريال"
            @input="handleValuesChanging($event, pricePerMeterValueKeys)"
            :value="pricePerMeterValueMask"
            @keydown="$formatNumberInputs($event, true)"
          ></v-text-field>
        </v-layout>
      </v-col>
      <v-col cols="12" md="3" class="edit__input">
        <v-layout flex-column align-center>
          <div class="d-flex align-center">
            <h6 class="ma-0 me-1">قيمة الإيجار السنوي</h6>
            <span
              v-if="isRealEstateExist"
              @click="edit('totalRentAmount')"
              class="edit__icon"
            >
              <img :src="editIcon" alt="edit" />
            </span>
          </div>
          <v-text-field
            ref="totalRentAmount"
            class="font-14"
            :class="[!status.totalRentAmount && 'pointer-none']"
            dense
            filled
            hide-details
            suffix="ريال"
            @input="handleValuesChanging($event, priceValueKeys)"
            :value="priceValueMask"
            @keydown="$formatNumberInputs($event, true)"
          ></v-text-field>
        </v-layout>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="3">
        <h6 class="mb-2 mt-0">مساحة العقار</h6>
        <v-text-field
          class="font-14"
          dense
          filled
          disabled
          hide-details
          placeholder="مساحة العقار"
          suffix="م²"
          :value="spaceValueMask"
          @keydown="$formatNumberInputs($event, true)"
        ></v-text-field>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="3">
        <h6 class="ma-0 mb-2">قيمة الإيجار السنوي</h6>
        <v-text-field
          class="font-14"
          dense
          filled
          disabled
          hide-details
          placeholder="قيمة الإيجار السنوي"
          suffix="ريال/م"
          :value="priceValueMask"
          @keydown="$formatNumberInputs($event, true)"
        ></v-text-field>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="3">
        <h6 class="ma-0 mb-2">سعر المتر</h6>
        <v-text-field
          class="font-14"
          dense
          filled
          disabled
          hide-details
          placeholder="سعر المتر"
          suffix="ريال"
          :value="pricePerMeterValueMask"
          @keydown="$formatNumberInputs($event, true)"
        ></v-text-field>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="3">
        <h6 class="ma-0 mb-2">قيمة الإيجار الكلية</h6>
        <v-text-field
          class="font-14"
          :disabled="!ifRealEstateInfoExist || !isDate"
          :rules="[(v) => !!v || `اختر الفترة`]"
          dense
          filled
          required
          hide-details
          placeholder="قيمة الإبجار الكلية"
          suffix="ريال"
          @input="updateAnuualRateData($event)"
          :value="totalRentValueMask"
          @keydown="$formatNumberInputs($event, true)"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="3">
        <h6 class="mb-2 mt-0">آلية التحصيل</h6>
        <v-select
          :items="collectionIntervals"
          :menu-props="{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          }"
          placeholder=" آلية التحصيل"
          :rules="[(v) => !!v || `اختر الفترة`]"
          append-icon="mdi-chevron-down"
          dense
          filled
          :disabled="!collectionIntervals.length"
          hide-details
          item-text="nameAr"
          item-value="value"
          required
          v-model="priceOfferInfo.collectionInterval"
        ></v-select>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="3">
        <h6 class="ma-0 mb-2">قيمة دفعه التحصيل</h6>
        <v-text-field
          :disabled="true"
          :rules="[(v) => !!v || 'من فضلك ادخل قيمة الايجار']"
          dense
          filled
          hide-details
          placeholder="قيمة الدفعه الواحده"
          suffix="ريال"
          :value="payOnceValue"
          class="font-14"
        ></v-text-field>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="ma-0 mb-2">
          إضافة قيمة الضريبة المضافة لقيم الايجارات المحصلة
        </h6>
        <v-card class="d-flex justify-space-between px-2">
          <h6 class="mx-1 font-14">
            {{ priceOfferInfo.tax ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            dense
            hide-details="auto"
            inset
            small
            v-model="tax"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
        <h6 class="ma-0 mb-2">هل توجد زيادة سنوية على مبلغ الإيجار ؟</h6>
        <v-card class="d-flex justify-space-between px-2">
          <h6 class="mx-1 font-14">
            {{ annualRentAmountStatus ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            dense
            hide-details="auto"
            inset
            small
            v-model="annualRentAmountStatus"
          />
        </v-card>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="3">
        <h6 class="mb-2 mt-0">نوع الزيادة السنوي</h6>
        <v-select
          :items="flexibleInstallments"
          :menu-props="{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          }"
          placeholder="نوع الزيادة السنوي"
          append-icon="mdi-chevron-down"
          dense
          filled
          hide-details
          item-text="nameAr"
          item-value="nameEn"
          :disabled="!annualRentAmountStatus"
          v-model="priceOfferInfo.increaseType"
        ></v-select>
      </v-col>
      <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="3">
        <h6 class="ma-0 mb-2">
          {{ showPercentage ? 'نسبة الزيادة السنوية' : 'قيمة الزيادة السنوية' }}
        </h6>
        <v-text-field
          class="font-14"
          dense
          filled
          hide-details
          :placeholder="
            showPercentage ? 'نسبة الزيادة السنوية' : 'قيمة الزيادة السنوية'
          "
          :suffix="showPercentage ? '%' : 'ريال'"
          :disabled="!annualRentAmountStatus || !priceOfferInfo.increaseType"
          @input="handleValuesChanging($event, increaseValueKeys)"
          :value="increaseValueMask"
          @keydown="$formatNumberInputs($event, true)"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import inputMasking from '@/mixins/inputMasking.js'
import { convertNumbers2English } from '@/utils/formatters'
import { add, format, intervalToDuration } from 'date-fns'
import { mapMutations } from 'vuex'

export default {
  name: 'RentalInformation',
  props: {
    intervals: {
      default: [],
      required: true
    },
    updatedPriceOffer: {
      default() {
        return {}
      }
    },
    realEstateExist: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    }
  },
  mixins: [inputMasking],
  data() {
    return {
      isValid: false,
      realestateEyeStatus: false,
      taxStatus: false,
      annualRentAmountStatus: false,
      annualRate: 0,
      status: {
        space: false,
        meter: false,
        totalRentAmount: false
      },
      gracePeriodStatus: false,
      tax: false,
      priceOfferInfo: {},
      flexibleInstallments: [
        {
          nameAr: 'زيادة بمبلغ ثابت',
          nameEn: 'fixed'
        },
        {
          nameAr: 'زيادة بنسبة ثابتة',
          nameEn: 'percentage'
        }
      ],
      collectionIntervals: [],
      startAtModal: false,
      startAtGraceModal: false,
      endAtModal: false,
      start: new Date().toISOString().substr(0, 10),
      startGracePeriod: '',
      end: new Date().toISOString().substr(0, 10),
      minDate: new Date().toISOString().substr(0, 10),
      daysGrace: 0,
      years: 0,
      months: 0,
      days: 0,
      formattedEndDate: new Date(),
      editIcon: require('@/assets/img/icons/editicon.svg'),
      //
      annualRentValueMask: '',
      pricePerSquareMeterValueMask: '',
      totalRentalValueValueMask: '',
      collectionPaymentAmountValueMask: '',
      increaseValueMask: '',
      totalRentalValueMask: '',
      totalRentValueMask: 0,
      spaceValueMask: 0,
      pricePerMeterValueMask: 0,
      priceValueMask: 0
    }
  },
  computed: {
    increaseValueKeys() {
      return {
        maskValKey: 'increaseValueMask',
        parentKey: 'priceOfferInfo',
        actualValKey: 'increase'
      }
    },
    totalValueKeys() {
      return {
        maskValKey: 'totalRentalValueMask',
        parentKey: 'priceOfferInfo',
        actualValKey: 'annualRate'
      }
    },
    spaceValueKeys() {
      return {
        maskValKey: 'spaceValueMask',
        parentKey: 'realEstateExist',
        actualValKey: 'space'
      }
    },
    pricePerMeterValueKeys() {
      return {
        maskValKey: 'pricePerMeterValueMask',
        parentKey: 'realEstateExist',
        actualValKey: 'pricePerMeter'
      }
    },
    priceValueKeys() {
      return {
        maskValKey: 'priceValueMask',
        parentKey: 'realEstateExist',
        actualValKey: 'price'
      }
    },
    rentValueKeys() {
      return {
        maskValKey: 'totalRentValueMask',
        parentKey: 'priceOfferInfo',
        actualValKey: 'rentValue'
      }
    },
    showPercentage() {
      return this.priceOfferInfo.increaseType === 'percentage'
    },
    calculateAnnualRent() {
      return (
        this.realEstateExist.pricePerMeter * this.realEstateExist.space || 0
      )
    },
    minEndDate() {
      const specificDate = new Date(this.start)
      specificDate.setDate(specificDate.getDate() + 1)
      return specificDate.toISOString().split('T')[0]
    },
    calculateDuration() {
      return (
        Number(this.years) * 12 +
        Number(this.months) +
        Number(this.days) * 0.0333
      )
    },
    calculateTotalRent() {
      const value = this.realEstateExist.price / 12
      return this.calculateDuration && value
        ? value * this.calculateDuration
        : 0
    },
    payOnceValue() {
      const value = this.realEstateExist?.price / 12
      const result = value * this.priceOfferInfo?.collectionInterval
      return isNaN(result)
        ? 0
        : result.toLocaleString('en-US', {
            maximumFractionDigits: 2
          })
    },
    ifRealEstateInfoExist() {
      const info = this.realEstateExist
      return !!(info.space && info.price && info.pricePerMeter)
    },
    isDate() {
      return !!(this.years || this.months || this.days)
    },
    isRealEstateExist() {
      return !!Object.keys(this.realEstateExist).length
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    handleTotalValue(totalValue) {
      const formatTotalValue = convertNumbers2English(totalValue)
      this.handleValuesChanging(formatTotalValue, this.totalValueKeys)
      let val = 0
      if (this.calculateDuration === 0.0333) {
        val = Number(this.priceOfferInfo?.annualRate) / 365
      } else if (this.calculateDuration <= 12) {
        val =
          Number(this.priceOfferInfo?.annualRate) /
          (12 / this.calculateDuration)
      } else {
        val =
          Number(this.priceOfferInfo?.annualRate) *
          (this.calculateDuration / 12)
      }
      this.handleValuesChanging(val, this.rentValueKeys)
    },
    formatNumberAExistance(val, key) {
      this.priceOfferInfo[key] = convertNumbers2English(val)

      if (key === 'phoneNumber') {
        this.debounceCheckUserExistance(val)
      }
    },
    validateYear(v) {
      const stringValue = String(v)
      return (
        ((stringValue || stringValue === 0) &&
          (!stringValue.length ||
            (!!stringValue.length && stringValue >= 0 && stringValue <= 99))) ||
        '0 - 99'
      )
    },
    validateMonth(v) {
      const stringValue = String(v)
      return (
        ((stringValue || stringValue === 0) &&
          (!stringValue.length ||
            (!!stringValue.length && stringValue >= 0 && stringValue <= 11))) ||
        '0 - 11'
      )
    },
    validateDay(v) {
      const stringValue = String(v)
      return (
        ((stringValue || stringValue === 0) &&
          (!stringValue.length ||
            (!!stringValue.length && stringValue >= 0 && stringValue <= 30))) ||
        '0 - 30'
      )
    },
    validateDayForGracePeriod(v) {
      const stringValue = String(v)
      return (
        ((stringValue || stringValue === 0) &&
          (!stringValue.length ||
            (!!stringValue.length &&
              stringValue >= 0 &&
              stringValue <= 500))) ||
        '0 - 500'
      )
    },
    formatArabicNumber(val, key, child = '') {
      if (!child) {
        this[key] = Number(convertNumbers2English(val).toLocaleString('en-US'))
        this.calculateEndDate()
      } else {
        this[key][child] = Number(
          convertNumbers2English(val).toLocaleString('en-US')
        )
      }
      this.totalRentValueMask = 0
    },
    formatArabicNumberForGracePeriod(val, key) {
      this[key] = Number(convertNumbers2English(val).toLocaleString('en-US'))
      this.calculateGracePeriodDate()
    },
    calculateEndDateForGracePeriod() {
      const res = add(new Date(this.end), {
        years: this.yearsGrace ?? 0,
        months: this.monthsGrace ?? 0,
        days: this.daysGrace + 2,
        seconds: -1,
        minutes: new Date().getTimezoneOffset()
      })
      this.formattedEndDate = res.toISOString()
      this.$set(
        this.priceOfferInfo,
        'startGracePeriod',
        format(res, 'yyyy-MM-dd')
      )
    },
    calculatePeriodForGracePeriod() {
      const { days } = intervalToDuration({
        start: new Date(this.priceOfferInfo.startGracePeriod),
        end: new Date(this.priceOfferInfo.endGracePeriod)
      })
      this.daysGrace = days
    },
    calculatePeriod() {
      const { days, months, years } = intervalToDuration({
        start: new Date(this.start),
        end: new Date(this.end)
      })

      this.years = years
      this.months = days === 29 ? months + 1 : months

      if (days === 29) {
        this.days = 0
      } else if (days === 30 && months === 0) {
        this.days = 0
        this.months = 1
      } else {
        this.days = days
      }

      this.formattedEndDate = new Date(this.end).toISOString()
    },
    handleEndDateChange() {
      this.calculatePeriod()
      this.collectionInterval()
      this.$emit('setContractDuration', this.collectionIntervals)
    },
    collectionInterval() {
      delete this.priceOfferInfo.collectionInterval
      this.collectionIntervals = this.intervals?.filter((interval) => {
        return interval.value <= 12 && this.calculateDuration >= interval.value
      })
      return this.collectionIntervals
    },
    calculateEndDate() {
      const res = add(new Date(this.start), {
        years: this.years ?? 0,
        months: this.months ?? 0,
        days: this.days ? this.days + 1 : 0,
        seconds: -1,
        minutes: new Date().getTimezoneOffset()
      })
      this.end = format(res, 'yyyy-MM-dd')

      this.formattedEndDate = res.toISOString()
      this.$set(this.priceOfferInfo, 'end', this.end)
      this.collectionInterval()
      this.$emit('setContractDuration', this.collectionIntervals)
    },
    calculateGracePeriodDate() {
      const res = add(new Date(this.startGracePeriod), {
        days: this.daysGrace
      })
      const period = format(res, 'yyyy-MM-dd')
      this.$set(this.priceOfferInfo, 'endGracePeriod', period)
      this.$set(this.priceOfferInfo, 'start', period)
      this.start = period
    },
    setData() {
      this.spaceValueMask = this.realEstateExist.space?.toLocaleString() || 0
      this.pricePerMeterValueMask =
        this.realEstateExist.pricePerMeter?.toLocaleString() || 0
      this.priceValueMask = this.realEstateExist.price?.toLocaleString() || 0
    },
    edit(type) {
      if (!this.$hasPermission('realestates', 'realestates', 'update')) {
        this.addNotification({
          text: 'ليس لديك صلاحيه لتعديل بيانات العقار',
          color: 'error'
        })
        return
      }
      this.status[type] = !this.status[type]

      if (this.status[type]) {
        this.$refs[type].focus()
      }
    },
    dataToBeBinded() {
      this.priceOfferInfo.realEstateExist = this.realEstateExist
      if (!this.gracePeriodStatus) {
        this.priceOfferInfo.startGracePeriod = null
      }

      return {
        ...this.priceOfferInfo,
        realEstateId: this.realEstateExist._id,
        price: this.realEstateExist.price,
        pricePerMeter: this.realEstateExist.pricePerMeter,
        space: this.realEstateExist.space,
        hasTax: !!this.tax,
        duration: this.calculateDuration
      }
    },
    bindContractData() {
      this.$emit('bindPriceOfferData', this.dataToBeBinded())
    },
    presetData() {
      this.editMoodPresetData()
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        const {
          increaseType,
          increase,
          rentValue,
          collectionInterval,
          tax,
          start,
          end
        } = this.updatedPriceOffer.details
        const { startGracePeriod, endGracePeriod } = this.updatedPriceOffer

        this.$set(this.priceOfferInfo, 'start', start.split('T')[0])
        this.$set(this.priceOfferInfo, 'end', end.split('T')[0])
        this.start = this.priceOfferInfo.start
        this.end = this.priceOfferInfo.end

        if (startGracePeriod) {
          this.startGracePeriod = startGracePeriod.split('T')[0]
          this.$set(
            this.priceOfferInfo,
            'startGracePeriod',
            startGracePeriod.split('T')[0]
          )
          this.$set(
            this.priceOfferInfo,
            'endGracePeriod',
            endGracePeriod.split('T')[0]
          )
          this.calculatePeriodForGracePeriod()
          this.gracePeriodStatus = true
        }
        this.tax = tax
        if (increaseType) {
          this.annualRentAmountStatus = true
          this.$set(this.priceOfferInfo, 'increaseType', increaseType)
          this.handleValuesChanging(increase, this.increaseValueKeys)
        }
        this.handleEndDateChange()
        this.handleValuesChanging(rentValue, this.rentValueKeys)
        this.$set(this.priceOfferInfo, 'collectionInterval', collectionInterval)
      }
    },
    updateAnuualRateData(val) {
      const formatTotalValue = isNaN(val)
        ? Number(convertNumbers2English(val.replaceAll(',', '')))
        : val
      if (typeof formatTotalValue === 'undefined') {
        return
      }
      this.handleValuesChanging(formatTotalValue, this.rentValueKeys)
      if (!this.calculateDuration || !this.priceOfferInfo.rentValue) {
        this.annualRate = 0
      } else if (this.calculateDuration === 0.0333) {
        this.annualRate = this.priceOfferInfo.rentValue * 365
      } else if (this.calculateDuration <= 12) {
        this.annualRate =
          this.priceOfferInfo.rentValue * (12 / this.calculateDuration)
      } else {
        this.annualRate = Number(
          (
            this.priceOfferInfo.rentValue /
            (this.calculateDuration / 12)
          ).toFixed(3)
        )
      }
    }
  },
  watch: {
    gracePeriodStatus(val) {
      if (!val) {
        this.daysGrace = 0
        this.startGracePeriod = ''
        this.priceOfferInfo.startGracePeriod = ''
        this.priceOfferInfo.endGracePeriod = ''
      }
    },
    calculateTotalRent(val) {
      this.handleValuesChanging(val, this.rentValueKeys)
    },
    calculateAnnualRent(val) {
      this.handleValuesChanging(val, this.priceValueKeys)
    },
    annualRentAmountStatus(val) {
      if (!val) {
        this.priceOfferInfo.increaseType = ''
        this.handleValuesChanging(0, this.increaseValueKeys)
      }
    },
    realEstateExist: {
      immediate: true,
      handler() {
        this.setData()
      }
    },
    isValid(val) {
      this.$emit('setValdationStatus', val)
    }
  }
}
</script>

<style lang="scss">
.edit {
  position: relative;

  &__input {
    position: relative;
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    z-index: 1;

    .v-input {
      max-width: 120px;
    }

    &--mod {
      .v-input {
        max-width: 120px;
      }
    }

    .v-input__slot {
      font-weight: bold;
    }

    .v-text-field__slot {
      margin-bottom: 0 !important;
    }
  }

  .v-select .v-input__control .v-input__slot,
  .v-text-field--filled .v-input__control .v-input__slot {
    border: 0 !important;
  }

  &__icon {
    cursor: pointer;
    img {
      width: 15px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #dcdcdc;
    top: 50%;
    transform: translateY(-50%);
  }
}

.margin-block {
  margin-top: -10px !important;
  margin-bottom: 25px !important;
}
.pointer-none {
  pointer-events: none;
}
</style>
