import config from '../config'
import axios from 'axios'

// Get all notifications
const getNotifications = function (query) {
  const url = `${config.messagesUrl}/notifications/filter`
  return axios.post(url, query)
}

// Get notification specific id
const getNotification = function (id) {
  const url = `${config.messagesUrl}/notifications/get/${id}`
  return axios.get(url)
}

// Add notification
const addNotification = function (data) {
  const url = `${config.messagesUrl}/notifications`
  return axios.post(url, data)
}

// Update notification
const updateNotification = function (id) {
  const query = {
    _ids: [id]
  }
  const url = `${config.messagesUrl}/notifications/set-as-read`
  return axios.post(url, query)
}

// Delete notification
const deleteNotification = function (id) {
  const url = `${config.messagesUrl}/notifications/${id}`
  return axios.delete(url)
}

export const notificationService = {
  addNotification,
  getNotifications,
  getNotification,
  deleteNotification,
  updateNotification
}
