<template>
  <v-card class="overflow-hidden main-header inner-header">
    <v-app-bar absolute color="white" elevate-on-scroll id="core-toolbar">
      <v-container fluid grid-list-md text-xs-center>
        <v-btn
          @click.stop="toggleDrawer"
          class="default v-btn--simple main-nav-toggler"
          icon
        >
          <v-img :src="burgerMenu" class="site-logo" contain height="20" />
        </v-btn>
        <v-spacer />
        <transition v-if="screenWidthType != 'xs' && !isCustomer" name="fade">
          <v-card max-height="460" width="514">
            <globalSearch :hits="hits" @search="setSearchKey" />
          </v-card>
        </transition>
        <v-spacer />
        <v-menu
          bottom
          content-class="menuGlobalSearch elevation-0"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
          max-width="100%"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="screenWidthType == 'xs' && !isCustomer"
              color="general"
              fab
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-img max-width="16" :src="searchIcon"></v-img>
            </v-btn>
          </template>
          <transition v-if="screenWidthType == 'xs' && !isCustomer" name="fade">
            <v-card class="searchCard" max-height="460" width="514">
              <globalSearch :hits="hits" @search="setSearchKey" />
            </v-card>
          </transition>
        </v-menu>
        <envSwitching />
        <v-btn outlined class="px-3">
          <v-row justify="center">
            <v-menu
              bottom
              :nudge-right="mdAndUp ? 180 : 0"
              content-class="notification-context elevation-0 notification__box"
              offset-y
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="isCustomer" fab text :to="smsMessagesRoute">
                  <v-icon size="20" color="#9F9FAC"> mdi-email-outline </v-icon>
                </v-btn>
                <!--icon for customr notification page-->

                <v-btn
                  v-if="!isCustomer"
                  fab
                  text
                  to="/dashboard/ActivityLog"
                  @click="isMessage = false"
                >
                  <v-img
                    max-width="16"
                    :src="activityLogIcon"
                    class="activity__log__icon"
                  ></v-img>
                  <span
                    v-show="isMessage"
                    class="notification-context__unread notification-context__unread--message mt-2 mr-2"
                  >
                  </span>
                </v-btn>
                <!-- for activity log icon -->

                <!-- Unread -->
                <span
                  class="notification-context__unread mt-2 mr-2"
                  v-show="ifUnread && !isCustomer"
                >
                </span>
                <v-btn
                  v-show="!isCustomer"
                  class="notification"
                  color="general"
                  fab
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-img
                    max-width="16"
                    :src="notificationIcon"
                    class="bell__icon"
                  ></v-img>
                </v-btn>

                <v-btn
                  fab
                  text
                  to="/dashboard/messaging"
                  @click="isMessage = false"
                >
                  <v-img
                    max-width="16"
                    :src="messagesIcon"
                    class="message__icon"
                  ></v-img>
                  <span
                    v-show="isMessage"
                    class="notification-context__unread notification-context__unread--message mt-2 mr-2"
                  >
                  </span>
                </v-btn>
              </template>
              <v-card elevation="1" min-width="330" max-width="448">
                <v-toolbar class="toolbar__content pt-1" flat>
                  <v-toolbar-title>
                    <span class="notificationPanalTitle font-weight-medium">
                      التنبيهات
                    </span>
                  </v-toolbar-title>
                </v-toolbar>
                <div
                  class="notification__dropdown pt-1"
                  v-if="notifications.length"
                >
                  <div
                    class="d-flex align-center pointerCursor mb-5"
                    :class="{ 'pl-2': screenWidthType === 'xs' }"
                    :key="notification._id"
                    @click.stop="updateNotificationAsRead(notification._id, i)"
                    v-for="(notification, i) in notifications"
                  >
                    <div class="mx-3">
                      <img
                        max-height="32"
                        max-width="32"
                        :src="icons[notification.context.serviceName]"
                        loading="lazy"
                      />
                    </div>
                    <div class="w-100">
                      <div class="d-flex message__title__div">
                        <div>
                          <h4
                            class="font-14 font-weight-medium my-0 text-truncate w-310"
                          >
                            {{ notification.body }}
                          </h4>
                        </div>
                        <span
                          class="circle mt-2 mr-2"
                          v-if="!notification.isRead"
                        >
                        </span>
                      </div>
                      <!-- message__title__div -->
                      <div class="d-flex align-center line-height-1">
                        <div class="message__descp__div">
                          <h6
                            class="content font-12 grey--text my-0 text-truncate w-310"
                          >
                            {{ generateTimeDifference(notification.createdAt) }}
                          </h6>
                        </div>
                        <!-- message__descp__div -->
                      </div>
                    </div>
                    <!-- w-100 -->
                    <div
                      class="font-12 ml-5 mr-3 md-18 remove__notification__icon d-flex align-end"
                      :class="!notification.isRead ? 'brub--flag' : ''"
                    >
                      <div class="message__time__div pl-2 line-height-1"></div>
                      <!-- message__time__div -->
                      <span
                        class="material-icons md-18"
                        @click.stop="deleteNotification(notification._id)"
                        >close</span
                      >
                    </div>
                    <!-- close notification icon  -->
                  </div>
                  <!-- Infinity scroll -->
                  <infinite-loading @infinite="infiniteScroll">
                    <div slot="no-more">
                      <h4 class="font-12">لا توجد اشعارات اخرى</h4>
                    </div>
                    <div slot="no-results">
                      <h4 class="font-12">لا توجد اشعارات اخرى</h4>
                    </div>
                  </infinite-loading>
                </div>
                <div
                  class="notification__area pa-5 d-flex align-center justify-center flex-column"
                  height="60vh"
                  v-else
                >
                  <v-img
                    :src="notificationIcon"
                    class="mx-auto"
                    max-height="60"
                    min-width="60"
                  />
                  <h5
                    class="grey--text empty__notification__text text--darken-3 mt-2 mb-5"
                  >
                    لا توجد تنبيهات في الوقت الحالي
                  </h5>
                </div>
                <v-divider class="my-2"></v-divider>
                <router-link to="/dashboard/notifications">
                  <div
                    class="text-center mb-2 font-weight-medium show__all__notifications"
                  >
                    إظهار كل التنبيهات
                    <v-icon color="primary">mdi-chevron-left</v-icon>
                  </div>
                </router-link>
              </v-card>
            </v-menu>
          </v-row>
        </v-btn>
        <v-divider
          v-if="isCustomer ? screenWidthType !== 'xs' : true"
          class="mr-3 vertical__divider"
          :class="[isCustomer ? 'my-4' : 'my-6']"
          vertical
        ></v-divider>
        <v-btn v-if="!isCustomer" outlined class="googleDots">
          <template>
            <v-row justify="center">
              <v-menu
                bottom
                content-class="hectarType elevation-0"
                offset-x
                transition="slide-y-transition"
                width="170"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="general" fab text v-bind="attrs" v-on="on">
                    <v-img
                      :max-width="16"
                      :src="googleDots"
                      class="google__dot__icon"
                    ></v-img>
                  </v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item-group>
                    <v-list-item
                      :href="item.link"
                      :key="i"
                      v-for="(item, i) in hectarType"
                    >
                      <v-img
                        :src="item.icon"
                        class="m-auto"
                        min-width="30"
                      ></v-img>
                      <v-list-item-title
                        class="font-14 mr-3 font-weight-medium t"
                      >
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-row>
          </template>
        </v-btn>
        <span
          id="profile-theme"
          class="ml-1"
          v-if="currentEnv && currentEnv.userTheme && !isCustomer"
          :style="`color:${currentEnv.userTheme}; border: 2px solid ${currentEnv.userTheme};`"
          >{{ $subString(fullName) }}</span
        >
        <span
          v-if="screenWidthType != 'xs' && !isCustomer"
          class="tf grey--text text--darken-1 text-caption text-center font-weight-bold logedin--user--name"
        >
          <span class="font-weight-bold d-block font-14">حيّاك الله،</span>
          {{ fullName }}
        </span>
        <!--Customer-->
        <div v-if="isCustomer" class="d-flex align-center">
          <div
            class="user-letter-container d-flex justify-center align-center font-weight-medium"
            v-if="screenWidthType !== 'xs'"
          >
            {{ user.name[0] }}
          </div>
          <span
            class="tf grey--text text--darken-1 text-caption text-center font-weight-bold logedin--user--name mr-1"
            v-if="screenWidthType !== 'xs'"
          >
            {{ fullName }}
          </span>
          <v-menu
            v-if="currentEnv.role.length > 1"
            ref="notificationsMenu"
            bottom
            content-class="notification-context elevation-0 notification__box"
            offset-y
            transition="slide-y-transition"
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="py-1 pr-1 pl-4 d-flex align-center default-role-container"
                :class="[screenWidthType === 'xs' ? 'mr-1' : 'mr-5']"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon class="mt-1" color="primary">{{
                  menuStateSwitcher($refs?.notificationsMenu)
                    ? 'mdi-chevron-up'
                    : 'mdi-chevron-down'
                }}</v-icon>
                <span
                  >{{ customerRole }}
                  {{ companyName ? `، ${companyName}` : '' }}</span
                >
              </div>
            </template>
            <v-card elevation="1" min-width="330" max-width="448">
              <v-radio-group
                class="d-flex flex-column roles-card"
                :value="selectedCustomerRole"
                @change="setDefaultCustomerRole"
              >
                <div
                  v-for="role in customerRoles"
                  :key="role.value"
                  class="pr-3 pl-8 py-1 role-container"
                >
                  <v-radio dense hide-details :value="role.value">
                    <template #label>
                      <div
                        class="tabel-tag text-center d-flex justify-center align-center brub--tag py-0"
                        :class="[
                          role.value === 'renter' ? 'brub--tag' : 'orange--tag'
                        ]"
                      >
                        <span class="ma-0 font-12 font-weight-medium">{{
                          role.title
                        }}</span>
                      </div>
                      <v-divider v-if="companyName" class="mx-4"></v-divider>
                      <span
                        v-if="companyName"
                        class="font-12 font-weight-medium"
                        >{{ companyName }}</span
                      >
                    </template>
                  </v-radio>
                </div>
              </v-radio-group>
            </v-card>
          </v-menu>
          <div
            v-else
            class="py-1 px-4 d-flex align-center default-role-container"
            :class="[screenWidthType === 'xs' ? 'mr-1' : 'mr-3']"
          >
            <span
              >{{ customerRole }}
              {{ companyName ? `، ${companyName}` : '' }}</span
            >
          </div>
        </div>
      </v-container>
    </v-app-bar>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import globalSearch from '../globalSearch/GlobalSearch.vue'
import { notificationsDropdown } from '@/mixins/notifications'
import timeDifferenceCalc from '@/mixins/timeDifferenceCalc.js'
import notificationsIcon from '@/config/notifications.js'
import InfiniteLoading from 'vue-infinite-loading'
import { debounce } from '../../helpers/debounce'
import envSwitching from '@/components/core/envSwitching.vue'

export default {
  components: {
    globalSearch,
    InfiniteLoading,
    envSwitching
  },
  mixins: [notificationsDropdown, timeDifferenceCalc],
  data: () => ({
    searchComponent: false,
    activeBtn: 1,
    icons: notificationsIcon.icons,
    logo: require('@/assets/img/logo.svg'),
    burgerMenu: require('@/assets/img/svgs/burgerMenu.svg'),
    title: 'مزامنة مع هكتار',
    responsive: false,
    responsiveInput: false,
    hectarType: [
      {
        title: 'منصه هكتار',
        icon: require('@/assets/img/svgs/HectarPlatform.svg'),
        link: 'https://www.hectar.io/'
      }
    ],
    dropdownItems: [
      {
        title: 'الملف الشخصي',
        link: '/dashboard/edit-profile',
        icon: 'mdi-account-edit'
      },
      {
        title: 'الإشتراكات',
        link: '/dashboard/subscriptions',
        icon: 'mdi-account-edit'
      },
      {
        title: 'الدعوات',
        link: '/dashboard/Invitations',
        icon: 'mdi-account-plus-outline'
      },
      {
        title: 'تغيير كلمة المرور',
        link: '/dashboard/change-password',
        icon: 'mdi-account-key'
      }
    ],
    googleDots: require('@/assets/img/svgs/googleDots.svg'),
    notificationIcon: require('@/assets/img/svgs/lightBell.svg'),
    messagesIcon: require('@/assets/img/svgs/messages/messages-icon.svg'),
    activityLogIcon: require('@/assets/img/activity-log-icons/activity-log-icon.png'),
    searchIcon: require('@/assets/img/svgs/searchIcon.svg'),
    searchKey: '',
    hits: {
      realestate: [],
      client: [],
      bond: [],
      contract: [],
      report: []
    },
    customerRoles: [
      {
        title: 'مستأجر',
        value: 'renter'
      },
      {
        title: 'مالك',
        value: 'owner'
      }
    ],
    selectedCustomerRole: ''
  }),
  computed: {
    ...mapGetters(['authorized']),
    ...mapState('accountState', [
      'status',
      'user',
      'currentEnv',
      'customerDefaultRole'
    ]),
    ...mapState('appState', ['drawer']),
    fullName() {
      return this.user?.name ?? 'اسم المستخدم'
    },
    isValidPhone() {
      return this.phoneData.valid ? this.phoneData.valid : false
    },
    isLoggedIn() {
      return this.status?.loggedIn
    },
    screenWidthType() {
      return this.$vuetify.breakpoint ? this.$vuetify.breakpoint.name : null
    },
    isSmallScreen() {
      return !(
        this.screenWidthType === 'xl' ||
        this.screenWidthType === 'lg' ||
        this.screenWidthType === 'md'
      )
    },
    customerRole() {
      return this.customerDefaultRole === 'owner' ? 'مالك' : 'مستأجر'
    },
    mdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    companyName() {
      const company = this.currentEnv?.owner?.company
      return company ? `شركة ${company}` : ''
    },
    smsMessagesRoute() {
      return {
        path: '/dashboard/smsMessages',
        query: {
          phone: this.user?.phoneNumber,
          name: this.user?.name,
          clientId: this.user?._id
        }
      }
    }
  },
  watch: {
    $route(val) {
      this.title = val.meta.name
    },
    searchKey: debounce(function (val) {
      // eslint-disable-next-line no-invalid-this
      if (val.length) {
        this.initSearch(val)
      } else {
        this.hits = {
          realestate: [],
          client: [],
          bond: [],
          contract: [],
          report: [],
          team: []
        }
      }
    }, 600)
  },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('appState', [
      'addNotification',
      'setDrawer',
      'toggleDrawer'
    ]),
    ...mapMutations('accountState', ['setCustomerRole']),
    ...mapActions('accountState', ['logout']),
    initSearch(key) {
      const algoliasearch = require('algoliasearch')
      const client = algoliasearch(
        process.env.VUE_APP_AppID,
        process.env.VUE_APP_ApiKey
      )
      const index = client.initIndex(process.env.VUE_APP_indexName)
      const currentEnv = JSON.parse(localStorage.getItem('currentEnv'))

      index
        .search(key, {
          filters: `environmentId:${currentEnv._id}`
        })
        .then(({ hits }) => {
          this.hits = {
            realestate: [],
            client: [],
            bond: [],
            contract: [],
            report: [],
            team: []
          }
          hits.forEach((hit) => {
            if (hit.category === 'realestate') {
              this.hits.realestate.push(hit)
            }
            if (hit.category === 'client') {
              this.hits.client.push(hit)
            }
            if (hit.category === 'bond') {
              this.hits.bond.push(hit)
            }
            if (hit.category === 'contract') {
              this.hits.contract.push(hit)
            }
            if (hit.category === 'report') {
              this.hits.report.push(hit)
            }
            if (hit.category === 'team') {
              this.hits.team.push(hit)
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setSearchKey(val) {
      this.searchKey = val
    },
    onClickBtn() {
      this.setDrawer(this.drawer)
    },
    setlogout() {
      this.logout().then(() => {})
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    },
    setDefaultCustomerRole(val) {
      this.selectedCustomerRole = val
      if (this.selectedCustomerRole !== this.customerDefaultRole) {
        this.setCustomerRole(this.selectedCustomerRole)
      }
    },
    generateTimeDifference(notificationTime) {
      const timeDifference = this.timeDifferenceCalc(notificationTime)
      return timeDifference === 'الأن'
        ? timeDifference
        : `منذ ${timeDifference}`
    },
    menuStateSwitcher(menu) {
      return menu?.isActive
    }
  },
  created() {
    this.isCustomer && (this.selectedCustomerRole = this.customerDefaultRole)
  }
}
</script>
<style lang="scss">
@import '@/styles/notification/notification.scss';
@import '@/styles/responsiveness/responsiveness.scss';
@import '@/styles/material-dashboard/_variables.scss';

.menuGlobalSearch {
  margin-top: 16px !important;
}

.searchCard {
  border: none !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.googleDots {
  border-right: 1px solid #efebf2;

  @include max-sm {
    padding-right: 3px;
  }

  // @include max-sm{}
}

.w-310 {
  max-width: 310px !important;
}

.circle {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $primary-color;
  box-shadow: 0px 1px 4px rgba($primary-color, 0.3);
  // rgba($primary-color, .3);
}

.betaversion {
  white-space: nowrap;
}

.notification__dropdown {
  max-height: 370px;
  overflow: auto;

  .remove__notification__icon {
    span {
      font-weight: 600;
      color: #737373;
      font-size: 16px;
      margin-bottom: 2px;
    }
  }

  // remove__notification__icon
}

// notification__dropdown
.line-height-1 {
  line-height: 1;
}

.toolbar__content {
  .notificationPanalTitle {
    font-size: 20px;
  }
}

.v-toolbar__content {
  .v-btn {
    .v-btn__content {
      @include max-md {
        .activity__log__icon,
        .bell__icon,
        .message__icon {
          max-width: 20px !important;
        }

        // activity__log__icon
        @include max-sm {
          .message__icon {
            max-width: 19px !important;
          }

          // message__icon
        }

        //  @include max-sm
      }

      //  @include max-md
    }

    // v-btn__content
  }

  // v-btn
  .vertical__divider {
    @include max-sm {
      &.mr-3 {
        margin-right: 2px !important;
      }

      & .my-6 {
        margin-top: 16px !important;
        margin-bottom: 16px !important;
      }
    }
  }

  // vertical__divider
}

// v-toolbar__content

// Notification count
.notification-context {
  position: relative;

  &.v-menu__content--fixed {
    position: fixed;
  }

  &__unread {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $primary-color;
    -webkit-box-shadow: 0px 1px 4px rgb(102 45 145 / 30%);
    box-shadow: 0px 1px 4px rgb(102 45 145 / 30%);
    top: -8px;
    left: 15px;

    @include max-sm {
      top: -20px;
      left: 10px;
    }

    // @include max-sm{}

    &--message {
      top: -19px;
      left: 31px;
    }
  }
}

#profile-theme {
  border-radius: 18px;
  width: 54px;
  height: 54px;
  font-size: 16px;
  line-height: inherit;
  text-align: center;
  letter-spacing: -0.05em;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;

  @include max-md {
    border-radius: 18px;
    width: 70px;
    height: 50px;

    @include max-sm {
      border-radius: 12px;
      width: 40px;
      height: 40px;
      line-height: 32px;
    }

    //  @include max-sm
  }

  // @include max-md
}

.logedin--user--name {
  min-width: 80px;
}
// custom media queries for i pads
/* iPad Mini in portrait only */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #core-toolbar {
    .v-toolbar__content {
      padding: 0;
    }
  }
  // #core-toolbar

  #profile-theme {
    border-radius: 14px;
    width: 50px;
    height: 46px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Apple iPad Air (2020) covers portrait width.
@media all and (device-width: 820px) and (device-height: 1180px) and (orientation: portrait) {
  #core-toolbar {
    .v-toolbar__content {
      padding: 0;
    }
  }
  // #core-toolbar

  span.tf.grey--text.text--darken-1.text-caption {
    min-width: 80px;
  }
  #profile-theme {
    border-radius: 14px;
    width: 50px;
    height: 46px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

<style lang="scss" scoped>
.user-letter-container {
  width: 32px;
  height: 32px;
  background: #efebf2;
  border-radius: 10px;
  border: 2px solid #efebf2;
  margin-right: 20px;
  color: #662d91;
  font-size: 14px;
}
.default-role-container {
  border-radius: 10px;
  background: rgba(102, 45, 145, 0.1);
  color: #662d91;
  font-size: 13px;
}
.roles-card {
  ::v-deep {
    .v-input--radio-group__input {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .role-container {
        border-radius: 10px;
        &:hover {
          background: rgba(211, 211, 211, 0.5);
        }
      }
      .tabel-tag {
        width: 70px;
      }
      hr {
        max-width: 10px;
        border-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}
</style>
