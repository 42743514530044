<template>
  <div class="mt-6">
    <div
      class="d-flex justify-space-between align-start mb-2 flex-wrap gap-10px"
    >
      <div
        class="d-flex flex-column justify-start flex-wrap gap-10px mobile__mw__100"
      >
        <!-------------------------->
        <!---- Filter - Part 1 ----->
        <!-------------------------->

        <div
          class="d-flex align-center flex-wrap gap-top-bottom-10px mobile__mw__100"
        >
          <!-- Filter choose from all - owner - renter -->
          <v-slide-x-transition v-if="!defaultersClients && forFilterTypes">
            <v-card
              class="d-flex justify-space-between px-2 ml-3 mobile__mx__0 mobile__w__100 mobile__mw__100"
              max-width="320"
              min-width="270"
              height="40"
            >
              <h6
                :class="[
                  'cursor d-flex justify-space-between align-center',
                  { 'border-black': filterType === status.code }
                ]"
                v-for="status in filterStatus"
                :key="status.code"
                ref="filterBtn"
                @click="filterList(status.code)"
              >
                {{ status.nameAr }}
                <span class="propCounter mx-2 px-2 font-12 mobile__px__4px">
                  {{ counts[status.code] }}
                </span>
              </h6>
            </v-card>
          </v-slide-x-transition>

          <div
            v-if="forHaveContracts"
            class="max-width-150 mx-2 mobile__mw__100 mobile__flex__auto mobile__mx__0"
            :class="{ 'max-width-250': defaultersClients }"
          >
            <v-text-field
              class="search__by__customer__name"
              height="40"
              filled
              dense
              hide-details="auto"
              placeholder="ابحث بإسم العميل"
              :value="search"
              @input="searchForClients"
            ></v-text-field>
          </div>

          <!-- Clients have contracts -->
          <div v-if="forHaveContracts && !defaultersClients">
            <v-card
              class="d-flex justify-space-between px-2 ml-2 mobile__w__100"
              v-if="!md"
              width="130"
              height="40"
            >
              <h6 class="mx-1">لديه عقود</h6>
              <v-switch
                class="ma-auto client__filter__switch mt-2 mobile__pt__6px mobile__mx__0"
                data-test="switchHaveContracts"
                v-model="haveContracts"
                @change="handleHaveContractsSwitch"
                inset
                dense
                small
                hide-details="auto"
              ></v-switch>
            </v-card>
          </div>

          <!-- Show or hidden clients overdue -->
          <div v-if="forOverdue && !defaultersClients" class="mobile__w__100">
            <v-card
              class="d-flex justify-space-between px-2 ml-2 mobile__w__100"
              width="180"
              height="40"
            >
              <h6 class="mx-1">متأخرات المستأجرين</h6>
              <v-switch
                ref="backLogRef"
                class="ma-auto client__filter__switch mt-2 mobile__mx__0 align-items-center mobile__pt__6px ml-0"
                v-model="backlog"
                @change="handleOverdueSwitch"
                data-test="overdue"
                inset
                dense
                small
                hide-details="auto"
              ></v-switch>
            </v-card>
          </div>
        </div>

        <!-- For multiple selections -->
        <slot />
      </div>

      <!-------------------------->
      <!---- Filter - Part 2 ----->
      <!-------------------------->

      <div class="d-flex align-center position-relative mobile__w__100">
        <!-- Grid to show data choose the way to the view what you prefer card or list -->
        <v-slide-x-transition>
          <v-card
            width="72"
            height="40"
            class="d-flex justify-space-between align-center px-2 mr-3 grid__list__view__btns"
          >
            <div
              class="d-flex justify-center align-center grid-view"
              :class="{ 'selected-grid-view': gridView }"
            >
              <img
                class="cursor mobile__m__0 view--change--btn"
                width="14"
                :src="ifGridCol"
                @click="changeGridView(true)"
                loading="lazy"
              />
            </div>
            <div
              class="d-flex justify-center align-center grid-view"
              :class="{ 'selected-grid-view': !gridView }"
            >
              <img
                class="cursor mobile__m__0 view--change--btn"
                :src="ifGridRow"
                width="14"
                @click="changeGridView(false)"
                loading="lazy"
              />
            </div>
          </v-card>
        </v-slide-x-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import { userService } from '@/services'
import { bus } from '@/helpers/eventBus'
import { debounce } from '../../helpers/debounce'

export default {
  name: 'ClientFilter',
  props: {
    gridView: {
      type: Boolean,
      default: false
    },
    status: {
      default() {
        return {}
      }
    },
    statusRestore: {
      type: Boolean,
      default: false
    },
    defaultersClients: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showFilterTypes: false,
      claims: false,
      backlog: false,
      haveContracts: false,
      counts: {
        total: 0,
        renter: 0,
        owner: 0
      },
      filterStatus: [
        {
          nameAr: 'الكل',
          code: 'total'
        },
        {
          nameAr: 'مالك',
          code: 'owner'
        },
        {
          nameAr: 'مستأجر',
          code: 'renter'
        }
      ],
      filterType: 'total',
      colIconColored: require('@/assets/img/svgs/colIconColored.svg'),
      colIcon: require('@/assets/img/svgs/colIcon.svg'),
      rowIcon: require('@/assets/img/svgs/rowIcon.svg'),
      rowIconColored: require('@/assets/img/svgs/rowIconColored.svg'),
      search: ''
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    arrowIcons() {
      return this.showFilterTypes ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    ifGridCol() {
      return this.gridView ? this.colIconColored : this.colIcon
    },
    ifGridRow() {
      return this.gridView ? this.rowIcon : this.rowIconColored
    },
    md() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    showclaimsForOwner() {
      return !!(this.filterType === 'owner' || this.filterType === '')
    },
    showOverdueForRenter() {
      return !!(this.filterType === 'renter' || this.filterType === '')
    },
    filtersDropdownClass() {
      return this.md ? 'filters-dropdown-md' : 'filters-dropdown-lg'
    },
    forclaims() {
      return (
        this.showclaimsForOwner &&
        !this.backlog &&
        !this.statusRestore &&
        !this.status.haveContracts
      )
    },
    forHaveContracts() {
      return (
        !this.status?.claims && !this.status?.backLog && !this.statusRestore
      )
    },
    forOverdue() {
      return (
        this.showOverdueForRenter &&
        !this.statusRestore &&
        !this.status.claims &&
        !this.status.haveContracts
      )
    },
    forFilterTypes() {
      return (
        !this.backlog &&
        !this.statusRestore &&
        !this.status.claims &&
        !this.status.haveContracts
      )
    }
  },
  created() {
    // 1 - Get clients count
    this.getClientsCountByStatus()
    // 2 - If user add new client will get clients count again with emit (clientChanged)
    bus.$on('clientsChanged', () => this.getClientsCountByStatus())
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    filterList(type) {
      // 1 - Run function filterChanged from parent and send code name
      this.$parent.filterChanged(type)
      // 2 - Set new filterType when clicked on filter
      this.filterType = type

      this.getClientsCountByStatus()
    },
    async getClientsCountByStatus() {
      try {
        const { data } = await userService.getClientsCountByStatus(
          this.currentEnv._id
        )

        data.result.map((key) => {
          this.counts[key.role] =
            this.filterType === key.role || this.filterType === 'total'
              ? key.count
              : 0
        })

        this.counts.total =
          this.filterType === 'total'
            ? data.totalCount
            : this.counts[this.filterType]
      } catch {
        this.addNotification({
          text: 'خطا في تحميل عدد العملاء',
          color: 'error'
        })
      }
    },
    handleOverdueSwitch() {
      this.$emit('switched', { name: 'overDue', value: this.backlog })
    },
    handleclaimsSwitch() {
      this.$emit('switched', { name: 'claims', value: this.claims })
    },
    handleHaveContractsSwitch() {
      this.$emit('switched', { name: 'contracts', value: this.haveContracts })
    },
    changeGridView(status) {
      this.$parent.changeGridView(status)
      this.showFilterTypes = false
    },
    searchForClients: debounce(function (searchText) {
      this.search = searchText
      this.$emit('search', this.search)
    }, 600)
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.border-black {
  padding-bottom: 10px;
  border-bottom: 2px solid $primary-color;
  border-radius: 0 !important;
  margin-bottom: 0;
}

.propCounter {
  background: rgba($primary-color, 0.05);
  border: 1px solid #efebf2;
  border-radius: 10px;
}

.min-button:hover {
  background-color: #9c27b0 !important;
  color: white !important;
  transition: all 0.4s ease-out;
}

.filters-dropdown-md {
  top: 50px;
  right: 0px;
}

.filters-dropdown-lg {
  top: 50px;
  right: 35px;
}

.max-width-150 {
  max-width: 150px;
}

.max-width-250 {
  max-width: 250px;
}

.grid-view {
  width: 28px;
  height: 28px;
}
.selected-grid-view {
  border-radius: 50% !important;
  background: rgba(102, 45, 145, 0.1);
}
.filters-switch {
  margin-top: 7px !important;
  width: 40px !important;
}
::v-deep {
  .client__filter__switch {
    .v-input__control {
      .v-input--selection-controls__input {
        margin-left: 0;
      }
    }
  }
  .search__by__customer__name {
    .v-text-field__slot {
      margin-bottom: 6px !important;
    }
  }
}
</style>
