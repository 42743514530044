export default {
  actions: [
    {
      title: 'تفاصيل',
      eligibleOffers: [
        'rejected',
        'expired',
        'new',
        'draft',
        'cancelled',
        'accepted'
      ],
      clickAction: {
        methodTitle: 'showDetails'
      },
      icon: 'mdi-eye-outline',
      sideDetailsAction: false,
      excludeCustomer: true
    },
    {
      title: 'نشر',
      eligibleOffers: ['draft'],
      clickAction: {
        methodTitle: 'openOfferModel'
      },
      icon: 'mdi-publish',
      sideDetailsAction: true,
      color: '#12CAD6',
      excludeCustomer: true
    },
    {
      title: 'بي دي اف',
      eligibleOffers: ['rejected', 'new', 'accepted'],
      clickAction: {
        methodTitle: 'openPDF'
      },
      icon: 'mdi-export-variant',
      sideDetailsAction: true,
      color: '#662D91'
    },
    {
      title: 'إلغاء العرض',
      eligibleOffers: ['rejected', 'expired', 'new', 'draft', 'accepted'],
      clickAction: {
        methodTitle: 'cancelled'
      },
      icon: 'mdi-close-circle-outline',
      sideHeaderIcon: 'mdi-close',
      class: ' danger--text',
      sideDetailsAction: true,
      sideHeaderColor: 'white',
      iconColor: 'danger',
      color: 'danger',
      excludeCustomer: true
    }
  ]
}
