<template>
  <v-dialog persistent max-width="506" :value="dialogState">
    <v-card class="add-new-property pa-0">
      <v-card-title class="px-4 custom__shadow card--title title-container">
        <div class="d-flex align-center justify-space-between">
          <h4 class="ma-0 font-16 font-weight-medium titles-color">
            {{ modelTitles.title }}
          </h4>
          <v-btn
            class="ma-0 closeModal"
            color="grey"
            fab
            text
            @click="closeModal"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <div class="mt-5 mb-8 pr-4 pl-5">
        <h3 class="font-14 font-weight-medium titles-color">
          {{ modelTitles.subTitle }}
        </h3>
        <v-textarea
          class="note-textarea"
          dense
          filled
          hide-details="auto"
          placeholder="ادخل محتوى الملاحظة"
          auto-grow
          no-resize
          :readonly="!!note"
          rows="5"
          v-model="noteContent"
        />
      </div>
      <div class="d-flex justify-end pl-4 mb-6">
        <v-btn
          class="ma-0 rounded-7 d-flex justify-center"
          color="primary"
          width="110"
          height="32"
          :disabled="!noteIsValid"
          :loading="isAddNoteLoading"
          @click="addNote"
        >
          <span class="font-12 font-weight-medium ml-3">{{
            modelTitles.btnText
          }}</span>
          <v-img
            class="save-icon"
            max-width="16"
            max-height="16"
            :src="saveArrow"
          ></v-img>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddNoteModal',
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    isAddNoteLoading: {
      type: Boolean,
      required: true
    },
    note: {
      type: String,
      default: ''
    },
    modelTitles: {
      type: Object,
      default: () => ({
        title: 'إضافة ملاحظة',
        subTitle: 'أدخل ملاحظتك هنا',
        btnText: 'حفظ'
      })
    }
  },
  data() {
    return {
      saveArrow: require('@/assets/img/svgs/save-arrow.svg'),
      noteContent: this.note
    }
  },
  computed: {
    noteIsValid() {
      return !!this.noteContent.trim()
    }
  },
  methods: {
    closeModal() {
      // Prevent user from closing modal while add note api is pending
      if (this.isAddNoteLoading) {
        return null
      }
      this.$emit('closeNoteModal')
    },
    addNote() {
      if (this.modelTitles.btnText === 'اغلاق') {
        this.closeModal()
        return
      }
      this.$emit('addNote', this.noteContent)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../src/styles/responsiveness/responsiveness.scss';
.title-container {
  padding-top: 10px;
  padding-bottom: 14px;
  .add-note-title {
    color: #101010 !important;
  }
  .closeModal {
    @include max-sm {
      &.v-btn.ma-0 {
        margin-left: 4px !important;
      }
    }
    i {
      font-size: 16px !important;
    }
  }
  .titles-color {
    color: #101010 !important;
  }
}
.note-textarea {
  ::v-deep {
    textarea {
      font-size: 12px !important;
      color: #101010 !important;
    }
  }
}
.save-icon {
  margin-top: 2px;
}
</style>
