<template>
  <div>
    <v-card-text class="pa-0">
      <v-container class="pa-0">
        <v-col
          class="px-2 py-0 mobile__p__0 tax__invoices__add__popup"
          cols="12"
          sm="12"
        >
          <h5
            :class="{ 'red--flag': validateForm ? !isAddedPropValid : false }"
            class="mb-2 font-14"
          >
            اختر العقار
          </h5>
          <autocomplete
            :items="AllProperties"
            :noData="!realestateIsLoading ? noData : searchingForPropeties"
            :placeholder="selectedRelestatePlaceholder"
            :returnObject="true"
            :rules="[(v) => true]"
            :itemText="propertyToShow"
            @nextPageTrigger="getNextData"
            @emitSearchKey="getDataBySearchKey"
            @input="setRealestateParties"
            v-model="addTaxInfo.realEstate"
            :loading="realestateIsLoading"
          />
        </v-col>
      </v-container>
    </v-card-text>
    <v-card-actions class="pa-1 mt-4">
      <v-spacer></v-spacer>
      <v-btn
        :loading="realestateIsLoading"
        @click="nextTab"
        class="ma-0 rounded-7 my-3 ml-3"
        color="primary"
      >
        <span class="mobile__mb__0 ml-1 font-weight-bold font-12">
          التالي
        </span>
        <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { realEstateService } from '../../../services'
import autocomplete from '@/components/forms/autocomplete'

export default {
  name: 'real-estat-step',
  components: { autocomplete },
  props: {
    taxRealEstateType: {},
    realestateTypes: {},
    validate: {},
    resetValidation: {
      default: false
    },
    taxToBeEditValid: {},
    taxToBeEdit: { default: {} }
  },
  data() {
    return {
      realestateIsLoading: false,
      propertyToShow: 'propertyName',
      selectedRelestatePlaceholder: 'اختر العقار',
      noData: 'لا توجد عقارات متاحه لهذا البحث',
      searchingForPropeties: 'جاري البحث عن عقارات',
      buildingState: 'addedProp',
      allDropDownValidation: {
        type: {
          status: false,
          required: false
        }
      },
      dropdownValidate: {
        type: {
          text: 'type',
          val: false
        }
      },
      properties: [],
      AllProperties: [],
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 12,
        count: 0
      },
      addTaxInfo: {},
      propertyInfo: {},
      validateForm: false,
      isValid: false,
      nextTabClicked: false
    }
  },
  computed: {
    selectedRelestates() {
      return (
        this.addTaxInfo.realEstate?.propertyName ||
        this.addTaxInfo.realEstate?.name
      )
    },
    isNewProp() {
      return this.buildingState === 'newProp'
    },
    isAddedPropValid() {
      return !!this.isValid
    },
    isNewPropValid() {
      let propertyNames = Object.values(this.allDropDownValidation)
      propertyNames = propertyNames.filter((i) => i.required)
      return !!(
        this.propertyInfo?.address &&
        this.propertyInfo?.propertyName &&
        propertyNames.every((i) => i.status)
      )
    },
    isTabValid() {
      return this.isAddedPropValid || this.isNewPropValid
    },
    addressValidation() {
      return this.validateForm ? !this.propertyInfo?.address : false
    },
    propertyNameValidation() {
      return this.validateForm ? !this.propertyInfo?.propertyName : false
    },
    realEstateTypeDefaultValue() {
      return this.isNewProp ? 'عقار جديد' : 'عقار مسجل ومضاف'
    },
    addPropertyInfoType() {
      return this.propertyInfo?.type?.nameAr ?? ''
    }
  },
  created() {
    this.setDefaultData()
    this.getProperties()
  },
  watch: {
    resetValidation: {
      handler() {
        this.addTaxInfo = {}
        this.propertyInfo = {}
        this.$refs.realestateStatus.id = 2
        this.buildingState = 'addedProp'
        this.validateForm = false
        this.dropdownValidate.type.val = false
        this.isValid = false
        this.nextTabClicked = false
        if (this.$refs.realestateTypes) {
          this.$refs.realestateTypes.id = ''
          this.$refs.realestateTypes.checked = false
          this.allDropDownValidation.type.required = false
          this.allDropDownValidation.type.status = false
        }
        this.$emit('resetTaxToBeEdit')
      }
    },
    taxToBeEdit: {
      immediate: true,
      handler() {
        if (this.taxToBeEditValid && !this.nextTabClicked) {
          this.addTaxInfo = { ...this.taxToBeEdit }
          this.isValid = true
          if (
            Object.keys(this.addTaxInfo.realEstate)?.length &&
            !this.addTaxInfo.realEstate?._id
          ) {
            this.propertyInfo = {
              ...this.addTaxInfo.realEstate,
              propertyName: this.addTaxInfo.realEstate.name
            }
            this.buildingState = 'newProp'
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setDefaultData() {
      if (
        this.taxToBeEdit &&
        Object.keys(this.taxToBeEdit).length &&
        this.taxToBeEdit.hasOwnProperty('realEstate')
      ) {
        this.AllProperties.push({
          propertyName: this.taxToBeEdit?.realEstate?.name,
          ...this.taxToBeEdit?.realEstate
        })
      }
    },
    setRealestateParties(realEstate) {
      this.$emit('setRealestateParties', realEstate)
    },
    getDataBySearchKey(e) {
      this.searchKey = e
      if (this.searchKey) {
        this.getProperties(e)
      }
    },
    getNextData() {
      if (this.pagination.pageNumber < this.pagination.pagesCount) {
        this.pagination.pageNumber += 1
        this.getProperties()
      }
    },
    setRealestateData(e) {
      this.$set(this.addTaxInfo, 'realEstate', e)
    },
    menuStateSwitcher(menu) {
      return menu?.isActive
    },
    setInputValidation() {
      this.isValid = true
    },
    // load realEsate
    async getProperties(searchKey = '') {
      this.realestateIsLoading = true
      const env = JSON.parse(localStorage.getItem('currentEnv'))
      const query = {
        account: env?.owner?.id
      }
      query.parentId = null
      let _data
      if (searchKey) {
        _data = `&search=${searchKey}`
      } else {
        _data = `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
      }

      try {
        const { data } = await realEstateService.getRealEstates(query, _data)
        this.pagination.pagesCount = Math.ceil(data?.count / data?.pageSize)
        this.properties = data?.realEstates ?? []
        this.pagination.count = data?.count
        this.AllProperties = [...this.AllProperties, ...this.properties]
      } catch (error) {
        this.addNotification({
          text: error,
          color: 'error'
        })
      } finally {
        this.realestateIsLoading = false
      }
    },
    setBuildingDetails(e, key) {
      this.$set(this.propertyInfo, key, e)
      this.allDropDownValidation[key].status = true
    },
    switchBuildingState(e) {
      this.buildingState = e.code
      this.validateForm = false
      this.dropdownValidate.type.val = false
      if (this.isNewProp) {
        this.addTaxInfo = {}
        this.isValid = false
        this.allDropDownValidation.type.required = true
      } else {
        this.propertyInfo = {}
        this.allDropDownValidation.type.required = false
        this.allDropDownValidation.type.status = false
        this.$refs.realestateTypes.id = ''
        this.$refs.realestateTypes.checked = false
        delete this.addTaxInfo.realEstate
      }
    },
    nextTab() {
      this.nextTabClicked = true
      if (this.isNewProp) {
        this.addTaxInfo.realEstate = this.propertyInfo
      } else if (this.addTaxInfo.realEstate?.type) {
        delete this.addTaxInfo.realEstate.type.__v
      }
      this.$emit('setAddTaxInfo', this.addTaxInfo)
      this.$emit('nextTab')
    }
  }
}
</script>
