<!-- eslint-disable vue/valid-v-for -->
<template>
  <div>
    <ReportHeaderCard :generalReportsHeaderData="generalReportsHeaderData" />

    <v-tabs
      align-with-title
      color="primary"
      right
      show-arrows
      v-model="tab"
      class="report__page__tabs mt-10"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab
        :ripple="false"
        class="mx-6 rounded-15"
        v-for="tab in availableTabsItem"
        :kei="tab.title"
      >
        {{ tab.title }}
      </v-tab>

      <v-tabs-items v-model="tab" class="bg-f7f7f7">
        <v-tab-item v-for="item in availableTabsItem">
          <component
            :is="item.component"
            :tables="getTables(item.tableCode)"
            :reports="reports"
            :headers="headers"
            :totalTableKeys="totalTableKeys"
            :generateTooltipData="generateTooltipData"
            :headersInit="headersInit"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import ReportHeaderCard from './SubComponents/reportHeaderCard.vue'

export default {
  name: 'OwnerReportsView',
  components: {
    ReportHeaderCard,
    InfoGraphicSummary: () =>
      import('@/components/reports/OwnerReportTabs/InfoGraphicSummary.vue'),
    DataTableDetails: () =>
      import('@/components/reports/OwnerReportTabs/DataTableDetails.vue'),
    GeneralSummary: () =>
      import('@/components/reports/OwnerReportTabs/GeneralSummary.vue')
  },
  props: {
    generalReportsHeaderData: {
      default() {
        return []
      },
      required: true
    },
    mainReports: {
      default() {
        return []
      },
      required: true
    },
    headers: {
      default() {
        return []
      },
      required: true
    },
    reports: {
      default() {
        return []
      },
      required: true
    },
    totalTableKeys: {
      default() {
        return []
      },
      required: true
    },
    generateTooltipData: {
      default() {
        return {}
      },
      required: true
    },
    headersInit: {
      default() {
        return []
      },
      required: true
    }
  },
  data() {
    return {
      tab: 0,
      availableTabsItem: [
        {
          component: 'InfoGraphicSummary',
          title: 'ملخص تصويري',
          tableCode: ['charts']
        },
        {
          component: 'GeneralSummary',
          title: 'الملخص العام للتقرير',
          tableCode: ['ownersStatement', 'ownersCredit']
        },
        {
          component: 'DataTableDetails',
          title: 'تفاصيل العقود',
          tableCode: ['tenantsStatement']
        },
        {
          component: 'DataTableDetails',
          title: 'المتأخرات',
          tableCode: ['dues']
        },
        {
          component: 'DataTableDetails',
          title: 'الإيرادات',
          tableCode: ['rentersStatement']
        },
        {
          component: 'DataTableDetails',
          title: 'المصروفات',
          tableCode: ['expenses']
        },
        {
          component: 'DataTableDetails',
          title: 'مستحقات الوحدات',
          tableCode: ['netDues']
        }
      ]
    }
  },
  methods: {
    getTables(codes) {
      const tables = []
      if (codes) {
        codes.map((code) => {
          if (this.restructureReportTables[code]) {
            tables.push(this.restructureReportTables[code])
          }
        })
      }
      return tables
    }
  },
  computed: {
    restructureReportTables() {
      return this.mainReports.reduce(
        (obj, item) => Object.assign(obj, { [item.code]: item }),
        {}
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-f7f7f7 {
  background-color: #f7f7f7;
}

.report__page__tabs {
  background-color: #f7f7f7 !important;

  :deep(.v-item-group) {
    background-color: #f7f7f7 !important;
  }
}
</style>
