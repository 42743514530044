<template>
  <v-container>
    <v-row no-gutters>
      <v-col v-for="i in 8" md="6" :key="i" cols="12" class="pa-1">
        <sync-item />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import syncItem from './syncItem'

export default {
  name: 'synced',
  components: { syncItem }
}
</script>
