<template>
  <div>
    <v-container class="section-1 pt-10 mobile__pt__0">
      <v-row>
        <v-col class="pa-0">
          <p class="title">بوابة الأعمال</p>
          <p class="quote ma-0">
            نظام متكامل لإدارة الأعمال و إجراءات التشغيل للجهات العقارية
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="section-2 pb-10">
      <v-container>
        <v-row>
          <v-col>
            <img :src="backgroundLogo" class="vert-move" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'headerSection',
  data: () => {
    return {
      backgroundLogo: require('@/assets/img/svgs/landingPageCover1.webp')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_landing-page.scss';
</style>
