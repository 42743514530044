<template>
  <div class="d-flex flex-column requests-container mt-4">
    <RequestCard
      v-for="request in requests"
      :key="request._id"
      :request="request"
    />
    <div v-intersect="endIntersect"></div>
  </div>
</template>

<script>
import RequestCard from './RequestCard'

export default {
  name: 'RequestCardContainer',
  components: {
    RequestCard
  },
  props: {
    requests: {
      type: Array,
      required: true
    }
  },
  methods: {
    endIntersect(isIntersecting) {
      if (isIntersecting[0].isIntersecting) {
        this.$emit('nextPageTrigger')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.requests-container {
  gap: 20px;
}
</style>
