<template>
  <v-sheet id="env-nameing">
    <img width="160" src="@/assets/img/logo.svg" class="mb-13" loading="lazy" />
    <h3 class="ma-0 font-weight-medium mb-8">إنشاء بيئة العمل</h3>
    <h6 class="h6-gery pb-1">
      يمثل الإسم الافتراضي لبيئة العمل والذي سيجمع جميع من له علاقة بالعمل
      وسيسهم في اعمال التشغيل من فريق أو عميل وليس بالضرورة أن يكون مماثل لاسم
      الشركة ويفضل ألا يزيد عن ثلاث كلمات
    </h6>
    <v-text-field
      data-test="name"
      v-model="name"
      type="text"
      label=" إسم بيئة العمل"
      required
      filled
      color="secondary"
      hide-details="auto"
    />
    <h6 class="font-weight-medium h6-gery pb-1 mt-8">عدد مستخدمين البيئة*</h6>
    <div class="d-flex justify-space-between align-center">
      <v-card
        v-for="(env, index) in envSize"
        :key="index"
        class="px-4 py-1"
        data-test="envSize"
        @click="selectedEnv(env.val)"
        :class="{ active: envNumber === env.val }"
      >
        <h6>{{ env.name }}</h6>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'nameing',
  data() {
    return {
      name: '',
      envNumber: '',
      envSize: [
        { name: 'انا فقط', val: 'only_me' },
        { name: '5-2', val: '5-2' },
        { name: '10-6', val: '10-6' },
        { name: '25-11', val: '25-11' },
        { name: '50-26', val: '50-26' },
        { name: '200-51', val: '200-51' },
        { name: 'لا ادري', val: 'idk' }
      ]
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    validate() {
      const valid = this.name !== '' && this.envNumber !== ''
      if (!valid) {
        this.addNotification({
          text: 'من فضلك تأكد من جميع الحقول',
          color: 'error'
        })
      } else {
        this.$emit('on-validate', { name: this.name }, !!valid)
      }
      return !!valid
    },
    selectedEnv(val) {
      this.envNumber = val
    }
  }
}
</script>
