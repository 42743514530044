<template>
  <v-card class="property-card team-card" :class="classes">
    <v-list-item class="card-title" :to="`/dashboard/user/${options._id}`">
      <v-list-item-content class="user-info">
        <v-list-item-title class="headline">{{
          options.name ? options.name : options.user.name
        }}</v-list-item-title>
        <v-list-item-subtitle>{{ options.role }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <div class="team-info property-info">
      <v-list-item dense class="phone-number">
        <v-list-item-content>
          <v-list-item-subtitle>الهاتف</v-list-item-subtitle>
          <v-list-item-title>{{
            options.phoneNumber ? options.phoneNumber : options.user.phoneNumber
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="options.user.email" dense>
        <v-list-item-content>
          <v-list-item-subtitle>عدد العقارات</v-list-item-subtitle>
          <v-list-item-title
            >{{ options.properties || 0 }} عقار</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-subtitle>الحالة</v-list-item-subtitle>
          <v-list-item-title>
            <v-chip small :color="options.user.email ? 'green' : 'grey'" dark
              ><span class="tf"
                >{{ options.user.email ? 'نشط' : 'بانتظار قبول الدعوة' }}
              </span></v-chip
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-subtitle> الايميل</v-list-item-subtitle>
          <v-list-item-title>{{
            options.user.email ? options.user.email : options.user
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item dense class="delete-cell">
        <v-menu content-class="dropdown-menu" transition="slide-x-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="general"
              small
              class="ma-0"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="openUserRoleDialog(options._id)">
              <v-list-item-icon>
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>تعديل صلاحية الموظف</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="deleteConfirm">
              <v-list-item-icon>
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>إلغاء الدعوة</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: ['usedFor', 'classes', 'options'],
  data() {
    return {
      dropdownItems: [
        { title: 'تعديل صلاحية الموظف', icon: 'mdi-square-edit-outline' },
        { title: 'حذف الموظف', icon: 'mdi-trash-can-outline' }
      ],
      isAdminLoadding: false,
      isAdminValue: false
    }
  },
  computed: {},
  methods: {
    ...mapMutations('appState', ['addNotification']),
    onSwitchChange(userId) {
      this.isAdminLoadding = true

      setTimeout(() => {
        this.isAdminLoadding = false
        this.isAdminValue = !this.isAdminValue

        if (this.isAdminValue) {
          this.addNotification({
            text: 'تم منح العضو صلاحية مدير',
            color: 'success'
          })
        } else {
          this.addNotification({
            text: 'تم حذف صلاحية مدير من العضو',
            color: 'success'
          })
        }
      }, 1000)
    },

    deleteConfirm() {
      this.$root.$confirm
        .open({
          title: 'حذف العضو',
          message: 'هل أنت متأكد من حذف العضو، العملية لا يمكن استرجاعها',
          options: { color: 'danger', width: 500, zIndex: 200 }
        })
        .then(() => {
          // this.deleteMember(id);
        })
    },

    openUserRoleDialog(id) {
      this.$emit('open-userRole-dialog', id)
    }
  }
}
</script>
