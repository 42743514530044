<template>
  <div>
    <v-tabs
      align-with-title
      color="primary"
      right
      v-model="tab"
      class="setting__page__tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <template>
        <v-tab
          :ripple="false"
          class="mr-6 rounded-15"
          v-for="item in availableTabs"
          :key="item.title"
        >
          {{ item.title }}
        </v-tab>
      </template>
      <v-tabs-items class="setting__page__tabs" v-model="tab">
        <v-tab-item
          v-for="item in availableTabs"
          :key="item.title"
          :eager="true"
        >
          <component :is="item.name" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import loader from '@/components/helper/loader.vue'
import AutoRenew from './AutoRenew.vue'

export default {
  name: 'contractSetting',
  components: {
    AutoRenew,
    loader
  },
  data: () => ({
    tab: 0,
    availableTabs: [
      {
        title: 'التجديد التلقائي',
        name: 'AutoRenew'
      }
    ]
  }),
  computed: {},
  methods: {}
}
</script>
