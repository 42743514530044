<template>
  <v-dialog
    class="backdrop"
    max-width="512px"
    overlay-opacity="0.5"
    persistent
    v-model="dialogState_"
  >
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="pa-0 px-5 mb-4 d-flex justify-space-between align-center custom__shadow"
      >
        <h4>تفاصيل الوحده</h4>
        <v-btn
          @click.prevent="closeModel"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs-items v-model="tab" class="pa-5 pt-0">
        <v-tab-item>
          <unitDetails
            v-bind="unitDetailsProps"
            @nextTab="nextTab"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
        <v-tab-item>
          <propertyInnerDetails
            v-bind="innerDetailsProps"
            @addNotification="pushValidationNotification"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
        <v-tab-item>
          <filesUploading
            :title="propImage"
            :resetValidation="resetValidationStatus"
            :propertyInfo="addPropertyInfo"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
        <v-tab-item>
          <showInHectar
            v-bind="showInHectarProps"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
        <v-tab-item>
          <propertyRolechart
            v-bind="propertyRolechartProps"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @updateRealEstate="updateRealEstate"
            @addNotification="pushValidationNotification"
            @setAddPropertyInfo="setAddPropertyInfo"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import showInHectar from './showInHectar.vue'
import propertyInnerDetails from './propertyInnerDetails.vue'
import filesUploading from './filesUploading.vue'
import propertyRolechart from './propertyRolechart.vue'
import unitDetails from './unitDetails.vue'
import config from '@/config'
import { realEstateService } from '@/services'

export default {
  name: 'singleUnitModel',
  components: {
    propertyInnerDetails,
    filesUploading,
    propertyRolechart,
    unitDetails,
    showInHectar
  },
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    propertyInfo: {
      type: Object,
      required: true
    },
    employees: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tab: 0,
      propImage: 'صور العقار',
      rolChartpImage: 'صور المخطط',
      currentEnv: JSON.parse(localStorage.getItem('currentEnv')),
      resetValidationStatus: false,
      addPropertyInfo: {}
    }
  },
  computed: {
    ...mapState('accountState', ['configs']),
    getPropertyOwner() {
      return this.addPropertyInfo?.owner
    },
    unitDetailsFirstTab() {
      const returnedData = {
        propertyName: this.addPropertyInfo?.propertyName,
        moderators: [...this.addPropertyInfo?.moderators],
        space: this.addPropertyInfo?.space,
        price: this.addPropertyInfo?.price,
        pricePerMeter: this.addPropertyInfo?.pricePerMeter,
        payType: this.addPropertyInfo?.payType
      }
      return returnedData
    },
    unitDetailsSecondTab() {
      const returnedData = {
        numberOfRooms: this.addPropertyInfo?.numberOfRooms,
        numberOfLivingRoom: this.addPropertyInfo?.numberOfLivingRoom,
        numberOfKitchenUnit: this.addPropertyInfo?.numberOfKitchenUnit,
        numberOfBathRoom: this.addPropertyInfo?.numberOfBathRoom,
        numberOfFloors: this.addPropertyInfo?.numberOfFloors,
        notes: this.addPropertyInfo?.notes,
        streetWidth: this.addPropertyInfo?.streetWidth,
        length: this.addPropertyInfo?.length,
        width: this.addPropertyInfo?.width,
        builtAt: this.addPropertyInfo?.builtAt,
        age: this.addPropertyInfo?.age,
        selectedSides: this.addPropertyInfo?.selectedSides
      }
      return returnedData
    },
    payTypeOptions() {
      return config.payTypeOptions
    },
    realestatePopulations() {
      return this.configs.realestatePopulations
    },
    isForRent() {
      return (
        this.addPropertyInfo?.status?.nameAr === 'للإيجار' ||
        this.addPropertyInfo?.status?.nameAr === 'شاغرة'
      )
    },
    accommodationType() {
      return this.isForRent && (this.isFlat || this.isFloor || this.isVilla)
    },
    propertyToSend() {
      if (this.currentEnv && this.currentEnv._id) {
        const dataToAdd = {
          type: this.addPropertyInfo.type?._id,
          status: this.addPropertyInfo.status?._id,
          price: this.addPropertyInfo.price,
          pricePerMeter: this.addPropertyInfo?.pricePerMeter,
          space: this.addPropertyInfo.space,
          propertyAddress: {
            address:
              this.addPropertyInfo.location.address ||
              this.addPropertyInfo.propertyAddress.address,
            city:
              this.addPropertyInfo?.location?.city ||
              this.addPropertyInfo?.propertyAddress?.city
          },
          location: {
            type: this.addPropertyInfo.location.type,
            coordinates: {
              lat: this.addPropertyInfo.location.coordinates[1],
              lng: this.addPropertyInfo.location.coordinates[0]
            }
          },
          owner: {
            _id: this.getPropertyOwner?._id,
            phoneNumber: this.getPropertyOwner?.phoneNumber,
            name: this.getPropertyOwner?.name
          },
          numberOfKitchenUnit: Number(this.addPropertyInfo.numberOfKitchenUnit)
            ? Number(this.addPropertyInfo.numberOfKitchenUnit)
            : 0,
          numberOfRooms: Number(this.addPropertyInfo.numberOfRooms)
            ? Number(this.addPropertyInfo.numberOfRooms)
            : 0,
          numberOfBathRoom: Number(this.addPropertyInfo.numberOfBathRoom)
            ? Number(this.addPropertyInfo.numberOfBathRoom)
            : 0,
          numberOfLivingRoom: Number(this.addPropertyInfo.numberOfLivingRoom)
            ? Number(this.addPropertyInfo.numberOfLivingRoom)
            : 0,
          numberOfFloors: Number(this.addPropertyInfo.numberOfFloors)
            ? Number(this.addPropertyInfo.numberOfFloors)
            : 0,
          age: Number(this.addPropertyInfo.age)
            ? Number(this.addPropertyInfo.age)
            : 0,
          showInHectar: this.addPropertyInfo.showInHectar || false,
          propertyName: this.addPropertyInfo.propertyName,
          images: this.addPropertyInfo.images || [],
          bluePrint: this.addPropertyInfo.bluePrint || {},
          moderators: [...this.addPropertyInfo.moderators],
          account:
            this.addPropertyInfo.showHectarAccount || this.currentEnv.owner,
          tags: this.addPropertyInfo.tags,
          environment: this.currentEnv._id,
          streetWidth: Number(this.addPropertyInfo.streetWidth),
          _id: this.addPropertyInfo._id,
          fromOwner: this.addPropertyInfo.hasOwnProperty('fromOwner')
            ? this.addPropertyInfo.fromOwner
            : true,
          length: Number(this.addPropertyInfo.length),
          width: Number(this.addPropertyInfo.width),
          builtAt: this.addPropertyInfo.builtAt,
          selectedSides: this.addPropertyInfo.selectedSides,
          notes: this.addPropertyInfo.notes
        }
        if (this.accommodationType) {
          dataToAdd.populationType = this.addPropertyInfo.populationType?._id
        }
        if (this.isForRent) {
          dataToAdd.payType = this.addPropertyInfo.payType?._id
        }
        if (this.addPropertyInfo?.propertyPurpose?._id) {
          dataToAdd.purpose = this.addPropertyInfo?.propertyPurpose?._id
        }
        if (!this.addPropertyInfo.fromOwner) {
          dataToAdd.authorizationNumber =
            this.addPropertyInfo.authorizationNumber
        }
        return dataToAdd
      }
      return null
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    },
    unitDetailsProps() {
      return {
        info: this.unitDetailsFirstTab,
        employees: this.employees,
        resetValidation: this.resetValidationStatus
      }
    },
    propertyRolechartProps() {
      return {
        title: this.rolChartpImage,
        resetValidation: this.resetValidationStatus,
        propertyInfo: this.addPropertyInfo,
        propertyInfoValid: true
      }
    },
    isStorehouse() {
      return this.addPropertyInfo?.type?.nameEn === 'Storehouse'
    },
    isFlat() {
      return this.addPropertyInfo?.type?.nameEn === 'flat'
    },
    isStore() {
      return this.addPropertyInfo?.type?.nameEn === 'store'
    },
    isVilla() {
      return this.addPropertyInfo?.type?.nameEn === 'villa'
    },
    isFloor() {
      return this.addPropertyInfo?.type?.nameEn === 'floor'
    },
    showInHectarProps() {
      return {
        propertyInfoValid: true,
        resetValidation: this.resetValidationStatus,
        propertyInfo: this.addPropertyInfo,
        payTypeOptions: this.payTypeOptions,
        realestatePopulations: this.realestatePopulations,
        realEstateConfig: {
          isLand: this.isLand,
          isStorehouse: this.isStorehouse,
          isMultiBuilding: this.isMultiBuilding,
          isFlat: this.isFlat,
          isFloor: this.isFloor,
          isChalet: this.isChalet,
          isGuestHouse: this.isGuestHouse
        }
      }
    },
    innerDetailsProps() {
      return {
        info: this.unitDetailsSecondTab,
        resetValidation: this.resetValidationStatus,
        isFlat: this.isFlat,
        isStorehouse: this.isStorehouse,
        isStore: this.isStore
      }
    }
  },
  watch: {
    propertyInfo: {
      immediate: true,
      handler() {
        this.addPropertyInfo = {
          ...this.propertyInfo
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setAddPropertyInfo(innerObj) {
      this.addPropertyInfo = {
        ...this.addPropertyInfo,
        ...innerObj
      }
    },
    pushValidationNotification() {
      this.addNotification({
        text: 'يجب ملئ جميع الحقول المطلوبه',
        color: 'error'
      })
    },
    closeModel() {
      this.tab = 0
      this.$emit('closeDialog', 'close')
      this.resetValidationStatus = !this.resetValidationStatus
    },
    previousTab() {
      this.tab--
    },
    nextTab() {
      this.tab++
    },
    async updateRealEstate() {
      realEstateService
        .updateRealEstate(this.propertyToSend)
        .then(() => {
          this.tab = 0
          this.$emit('closeDialog', 'unit')
          this.$emit('refresh-properties-list')
          this.resetValidationStatus = !this.resetValidationStatus
        })
        .catch((error) => {
          this.addNotification({
            text: error.message || 'حدث خطأ اثناء تحديث العقار',
            color: 'error'
          })
          this.resetValidationStatus = !this.resetValidationStatus
        })
    }
  }
}
</script>
