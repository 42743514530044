<template>
  <div
    :class="[
      'task-details contentSection contracts__bonds__side__deatails',
      { 'show-detailes': !reqModalState }
    ]"
  >
    <v-slide-x-transition>
      <v-layout v-show="!reqModalState">
        <v-flex class="greySide" md4></v-flex>
        <v-flex
          class="whiteSide flex-wrap"
          :style="{ 'min-width': hasUpdatePermission ? '80vw' : '60vw' }"
        >
          <!-- SideRequestsDetailesHeader -->
          <sideRequestsDetailesHeader
            v-bind="headerProps"
            @closeDetails="closeSideDetails"
          />

          <!-- Spreated -->
          <v-divider class="mt-2"></v-divider>

          <v-layout class="sidedetails__layout mobile__px__15px">
            <!----- right section ----->
            <v-flex
              :class="{
                'details-container': getBreakPoint['mdAndUp'],
                md8: hasUpdatePermission
              }"
            >
              <v-tabs
                class="tabs-container"
                color="primary"
                align-with-title
                right
                v-model="tab"
              >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                  v-for="tab in filteredTabs"
                  :key="tab.component"
                  color="#737373"
                  class="font-14 mr-0"
                >
                  {{ tab.title }}
                </v-tab>
              </v-tabs>

              <v-tabs-items class="mt-3" v-model="tab">
                <v-tab-item
                  v-for="tab in filteredTabs"
                  :key="tab.component"
                  :eager="true"
                >
                  <component
                    :is="tab.component"
                    v-bind="generateTabProps(tab)"
                    @setRequestDetails="setRequestDetails"
                    @openReqModal="reqModalState = $event"
                    @addExpense="addExpense"
                    @deleteExpense="deleteExpense"
                    @updateRequest="$emit('updateRequest', $event)"
                    @uploadAttachment="uploadDocument"
                    @deleteAttachment="deleteDocument"
                    @selectFile="uploadDocument"
                    @delete-document="deleteDocument"
                    @cancel-delete-document="showMaintenanceRequest"
                    v-on="$listeners"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-flex>

            <!----- comment section ----->
            <v-flex md4 class="mr-2" v-if="hasUpdatePermission">
              <CommentsSection
                v-bind="commentsSectionProps"
                @addComment="generateAddCommentBody"
                @updateComment="updateComment"
                @deleteComment="deleteComment"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-slide-x-transition>

    <!-- --------------------------- -->
    <!-- ---Maintenance Req Modal--- -->
    <!-- --------------------------- -->
    <AddReqModal
      v-if="reqModalState"
      :dialogState="reqModalState"
      @addExpense="addExpense"
      @close-client-dialog="reqModalState = false"
    />
  </div>
</template>

<script>
import sideRequestsDetailesHeader from './sideRequestsDetailesHeader.vue'
import Requests from '@/constans/Requests'
import CommentsSection from '@/components/helper/commentsSection.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { messageService, requestsService, userService } from '@/services'
import RequestDetails from './RequestDetails.vue'
import RequestExpenses from './RequestExpenses.vue'
import RequestAttachments from './RequestAttachments.vue'

export default {
  name: 'sideRequestsDetailes',
  components: {
    CommentsSection,
    sideRequestsDetailesHeader,
    RequestDetails,
    RequestExpenses,
    RequestAttachments,
    AddReqModal: () => import('@/components/modals/AddReqModal.vue')
  },
  props: {
    requestTypes: {
      default() {
        return []
      }
    },
    requestInfo: {
      type: Object,
      required: true
    },
    requestIndex: {
      type: Number,
      required: true
    },
    isExpenseRequestRefreshed: {
      type: Boolean,
      required: true
    },
    allEmployees: {
      default() {
        return []
      }
    }
  },
  emits: ['closeDetails'],
  data() {
    return {
      files: [],
      comments: [],
      expenses: [],
      closeAttachmentModal: true,
      ...Requests,
      reqModalState: false,
      requestDetails: {},
      editableFields: [
        'title',
        'description',
        'status',
        'priority',
        'reason',
        'moderator'
      ],
      isCommentsLoading: false,
      addCommentStatus: '',
      addExpenseStatus: '',
      tabs: [
        {
          title: 'تفاصيل الطلب',
          component: 'RequestDetails',
          requiredProps: [
            'requestInfo',
            'allEmployees',
            'hasUpdatePermission',
            'isCustomer'
          ],
          isCustomer: true
        },
        {
          title: 'طلبات الصرف',
          component: 'RequestExpenses',
          requiredProps: [
            'requestId',
            'expenses',
            'addExpenseStatus',
            'hasUpdatePermission'
          ],
          isCustomer: false
        },
        {
          title: 'المرفقات',
          component: 'RequestAttachments',
          requiredProps: [
            'files',
            'hasUpdatePermission',
            'closeAttachmentModal'
          ],
          isCustomer: false
        }
      ],
      newExpense: {},
      tab: 0
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    async uploadDocument(attachment) {
      try {
        const reader = new FileReader()
        reader.onload = async () => {
          await this.onUpload(attachment)
        }
        reader.readAsDataURL(attachment.files)
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      } finally {
        this.closeAttachmentModal = false
      }
    },
    async onUpload(file) {
      try {
        const fd = new FormData()
        fd.append('entityType', 'request')
        fd.append('attachment', file.files)
        fd.append('type', file.type)
        fd.append('envId', this.currentEnv._id)
        // fd.append('description', file.description)
        fd.append('entityId', this.requestDetails._id)
        await userService.addDocument(fd)
        this.addNotification({
          text: 'تم إضافه المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      } finally {
        this.getRequestAttachments()
      }
    },
    async deleteDocument(payload) {
      try {
        await userService.deleteDocument(payload._id)
        if (payload.fromPayTable) {
          this.$set(this.expenses[payload.index], 'progress', 0)
          this.$set(this.expenses[payload.index], 'attachment', null)
        } else {
          this.files.splice(payload.fileIndex, 1)
        }
        this.addNotification({
          text: 'تم حذف المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في حذف المرفق، يرجى المحاوله مجددا',
          color: 'success'
        })
      } finally {
        this.showMaintenanceRequest()
      }
    },
    closeSideDetails() {
      // if (!!this.requestDetails._id) {
      //   console.log(this.requestDetails, this.requestInfo)
      //   const isUpdated =
      //     JSON.stringify(this.requestDetails) !==
      //     JSON.stringify(this.requestInfo)
      //   // Check if original value was canceled or rejected and the new status is not canceled or rejected to delete the reason of canceling and rejection
      //   if (
      //     !isUpdated &&
      //     this.isOldRequestCanceledOrRejected &&
      //     !this.isCanceledOrRejected
      //   ) {
      //     this.requestDetails.reason = ''
      //   }
      //   // this.$emit('closeDetails', {
      //   //   isUpdated,
      //   //   fromDetails: true,
      //   //   updatedData: this.requestDetails,
      //   //   index: this.requestIndex
      //   // })
      // } else {
      this.$emit('closeDetails', {})
      // }
    },
    async getRequestAttachments() {
      try {
        const { data } = await userService.getDocuments(this.requestDetails._id)
        this.files = data.result
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل مرفقات الطلب، يرجى المحاوله مجددا',
          color: 'error'
        })
      }
    },
    async getComments() {
      const body = {
        entityId: this.requestDetails._id,
        entityType: 'maintenanceRequest'
      }
      try {
        this.isCommentsLoading = true
        const { data } = await messageService.getComments(body)
        this.comments = data.items
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل تعليقات الطلب، يرجى المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.isCommentsLoading = false
      }
    },
    async generateAddCommentBody(commentContent) {
      const commentObj = {
        content: commentContent,
        entityType: 'maintenanceRequest',
        entityId: this.requestDetails._id,
        author: this.currentUser,
        receiver: this.requestDetails.createdBy,
        context: {
          platform: 'hectarPlus',
          serviceName: 'propertyManagement'
        }
      }
      await this.addComment(commentObj)
    },
    async addComment(commentBody) {
      this.addCommentStatus = 'pending'
      try {
        const { data } = await messageService.addComment(commentBody)
        this.comments.push(data.comment)
        this.addCommentStatus = 'success'
      } catch {
        this.addCommentStatus = 'failure'
        this.addNotification({
          text: 'خطأ في إضافة التعليق، يرجى المحاوله مجدا',
          color: 'error'
        })
      }
    },
    async updateComment({ newContent, index }) {
      try {
        const updatedComment = { ...this.comments[index], content: newContent }
        await messageService.updateComment(updatedComment)
        this.$set(this.comments[index], 'content', newContent)
      } catch {
        this.addNotification({
          text: 'خطأ في تعديل التعليق، يرجى المحاوله مجددا',
          color: 'error'
        })
      }
    },
    async deleteComment({ commentId, index }) {
      try {
        await messageService.deleteComment(commentId)
        this.comments.splice(index, 1)
      } catch {
        this.addNotification({
          text: 'خطأ في حذف التعليق، يرجى المحاوله مجددا',
          color: 'error'
        })
      }
    },
    async getExpenses() {
      try {
        const { data } = await requestsService.getExpenses(
          this.requestDetails._id
        )
        this.expenses = data.data.map((expense) => {
          return { ...expense, progress: 0 }
        })
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل طلبات الصرف، يرجى المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.isExpenseRequestRefreshed &&
          this.$emit('refreshExpenseRequests', false)
      }
    },
    async addExpense(expenseData) {
      this.addExpenseStatus = 'pending'

      try {
        const addedExpense = {
          ...expenseData,
          maintenanceRequest: this.requestDetails._id,
          environmentId: this.currentEnv._id,
          realestateId: this.requestDetails.realEstate?._id
        }
        await requestsService.addExpense(addedExpense)
        this.addExpenseStatus = 'success'
        this.getExpenses()
        this.reqModalState = false
      } catch {
        this.addExpenseStatus = 'failure'
      }
    },
    async deleteExpense(payload) {
      try {
        await requestsService.deleteExpense(payload.expenseId)
        this.expenses.splice(payload.index, 1)
        this.addNotification({
          text: 'تم حذف طلب الصرف بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في حذف طلب الصرف، يرجى المحاوله مجددا',
          color: 'error'
        })
      }
    },
    async getRequestData() {
      if (this.showExpenses) {
        this.getExpenses()
      } else {
        this.expenses = []
      }
      this.getRequestAttachments()
      this.getComments()
    },
    showMaintenanceRequest() {
      this.$emit('showMaintenanceRequest')
    },
    generateTabProps(tab) {
      const requiredProps = {}
      tab.requiredProps.forEach((prop) => {
        requiredProps[prop] = this[prop]
      })
      return requiredProps
    },
    setRequestDetails(requestDetails) {
      this.requestDetails = { ...this.requestDetails, ...requestDetails }
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    getBreakPoint() {
      return this.$vuetify.breakpoint || {}
    },
    customerTabs() {
      return this.tabs.filter((tab) => {
        return tab.isCustomer
      })
    },
    showTabExpectAttachments() {
      //  in case requestInfo.type is not maintenance then not show attachment component
      return this.tabs.filter((tab) => {
        if (this.requestDetails.type?.nameAr === ' طلب صيانة') {
          return true
        } else if (
          this.requestDetails.type?.nameAr !== ' طلب صيانة' &&
          tab.component === 'RequestAttachments'
        ) {
          return false
        }
        return true
      })
    },
    isCustomerTabs() {
      return this.isCustomer ? this.customerTabs : this.showTabExpectAttachments
    },
    filteredTabs() {
      return this.showExpenses
        ? this.isCustomerTabs
        : this.isCustomerTabs.filter(
            (tab) =>
              tab.component !== 'RequestExpenses' ||
              tab.component !== 'RequestAttachments'
          )
    },
    hasUpdatePermission() {
      return this.$hasPermission('properties', 'requests', 'update')
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndDown
    },
    currentUser() {
      return {
        _id: this.user._id,
        name: this.user.name,
        phoneNumber: this.user.phoneNumber,
        type: this.user.type,
        email: this.user.email
      }
    },
    commentsSectionProps() {
      return {
        comments: this.comments,
        commentsLoading: this.isCommentsLoading,
        addCommentStatus: this.addCommentStatus
      }
    },
    isTypeRequest() {
      return this.requestDetails.type === 'maintenance'
    },
    isRequestHasRealEstate() {
      return !!this.requestDetails.realEstate?._id
    },
    isCanceledOrRejected() {
      return (
        this.requestDetails.status === 'canceled' ||
        this.requestDetails.status === 'rejected'
      )
    },
    isOldRequestCanceledOrRejected() {
      // check if original value passed as a prop has status canceled or rejected
      return (
        this.requestInfo.status === 'canceled' ||
        this.requestInfo.status === 'rejected'
      )
    },
    totalExpenses() {
      let total = 0
      this.expenses.forEach((expense) => (total += expense.total))
      return total
    },
    showExpenses() {
      return this.isTypeRequest && this.isRequestHasRealEstate
    },
    headerProps() {
      return {
        requestInfo: this.requestInfo,
        totalExpenses: this.totalExpenses,
        showExpenses: this.showExpenses,
        requestTypes: this.requestTypes,
        isCustomer: this.isCustomer
      }
    },
    requestId() {
      return this.requestDetails?._id ?? ''
    },
    payRequestsTableProps() {
      return {
        expenses: this.expenses,
        addExpenseStatus: this.addExpenseStatus,
        requestId: this.requestDetails._id
      }
    }
  },
  watch: {
    requestInfo: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        const isRequestChanged = val?._id && val._id !== oldVal?._id
        this.requestDetails = { ...val }

        if (isRequestChanged) {
          this.tab = 0
          this.getRequestData()
        }
      }
    },
    isExpenseRequestRefreshed(val) {
      val && this.getExpenses()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_sideDetails.scss';
@import '@/styles/responsiveness/responsiveness.scss';
.rightHolder {
  overflow-y: auto;
  height: 90vh;
  @include max-sm {
    overflow: hidden;
    &.pl-4 {
      padding-left: 0 !important;
    }
  }
}
.tabs-container {
  border-bottom: 1px solid #efebf2;
}
.cancel-reason-text {
  height: 46px;
  display: flex;
  align-items: center;
  background-color: rgba(252, 74, 74, 0.2);
  border: 1px solid transparent;
  border-radius: 17px 0px 18px 17px;
}
.cancel-reason-input {
  .v-input {
    ::v-deep .v-input__slot {
      border: 1px solid #efebf2 !important;
      padding-right: 10px !important;
    }
    ::v-deep .v-input__slot input {
      font-weight: normal !important;
    }
  }
}
.details-container {
  border-left: 1px solid #e0e0e0 !important;
}
</style>
