<template>
  <div>
    <ServicesCard
      v-for="service in formatServices"
      :key="service.title"
      v-bind="{ template: service, isActiveOption: true, icon }"
      @openSettingsModal="openModel"
    />

    <TermsAndConditionsModel
      :dialogState="dialogState"
      :service="selectedService"
      @updateServiceSettings="updateServiceSettings"
      @toggleModel="close"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { servicesCodes } from '@/constans/services'
import TermsAndConditionsModel from './TermsAndConditionsModel.vue'

export default {
  name: 'TermsAndConditions',
  components: {
    ServicesCard: () => import('../modelUint.vue'),
    TermsAndConditionsModel
  },
  data: () => ({
    icon: require('@/assets/img/svgs/docs-blue.svg'),
    selectedService: {},
    dialogState: false
  }),
  computed: {
    ...mapState('accountState', ['currentEnv']),
    servicesConfig() {
      return this.currentEnv.services
    },
    formatServices() {
      const formattedServices = this.servicesConfig.map((service) => {
        return {
          code: service.code,
          title: servicesCodes[service.code] ?? '-',
          description:
            'يمكن التعديل على الشروط والأحكام لهذه الخدمة من خلال الضغط علي زر التعديل',
          active: service.isActive,
          termsAndConditions: service?.termsAndConditions
        }
      })
      return formattedServices
    }
  },
  methods: {
    ...mapActions('accountState', ['updateEnvSettings', 'updateCurrentEnv']),
    openModel(val) {
      this.selectedService = val
      this.dialogState = true
    },
    async updateServiceSettings(newServices) {
      const services = this.currentEnv.services
      const updatedServices = services.map((service) => {
        if (service.code === newServices.code) {
          return newServices
        }
        return service
      })

      await this.updateCurrentEnv({ services: updatedServices })
      close()
    },
    close() {
      this.dialogState = false
    }
  }
}
</script>
