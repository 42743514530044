<template>
  <div>
    <v-tabs
      align-with-title
      color="primary"
      right
      v-model="tab"
      class="setting__page__tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <template>
        <v-tab
          :ripple="false"
          class="mr-6 rounded-15"
          v-for="item in availableTabs"
          :key="item.name"
        >
          {{ item.title }}
        </v-tab>
      </template>
      <v-tabs-items class="setting__page__tabs" v-model="tab">
        <v-tab-item
          v-for="item in availableTabs"
          :key="item.name"
          :eager="true"
        >
          <v-card class="px-5 py-6 mt-4" v-if="item.hasPermission">
            <component
              :ref="item.name"
              :is="$route.query.currComponent ?? item.name"
              :env="currentEnv"
              :userData="user"
              :locationInfo="locationInfo"
              :isBankAccountSaveBtn="true"
              :addBanksDataLoading="addBanksDataLoading"
              :bankAccountsDetails="bankAccountsDetails"
              @setValidation="setDataAndValidation"
              @checkAddress="checkAddress"
              @checkAboutCompany="checkAboutCompany"
              @toggleServiceModel="toggleServiceModel"
              @bindBankAccountsData="bindBankAccountsData"
              @updateBankAccountsList="getEnvBankAccounts"
              @openApprovalModal="toggleApprovalModel"
              v-on="$listeners"
            />
            <v-layout justify-end class="mt-5" v-if="item.isSaveBtn">
              <v-btn
                data-test="updateEnvSettings"
                @click="saveUpdatedData"
                color="primary"
                class="rounded-15 px-13 py-3"
                :disabled="tab == 0 ? !isValid : false"
              >
                <span class="font-16"> حفظ </span>
              </v-btn>
            </v-layout>
          </v-card>
          <upgradeView v-else />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <ServiceModel
      :dialogState="serviceDialogState"
      :templateDate="templateDate"
      :hasUpdatePermission="hasUpdatePermission('servicesSettings')"
      @toggleServiceModel="toggleServiceModel"
      @updateServiceSettingss="updateServiceSettingss"
    />
    <ApprovalModal
      :dialogState="approvalDialogState"
      :allEmployees="allEmployees"
      :module="module"
      :hasUpdatePermission="hasUpdatePermission('approvalsSettings')"
      :jobTitles="jobTitles"
      @setJobTitles="setJobId"
      @closeDialog="toggleApprovalModel"
      @loadMoreEmployees="getEmployeesNextData"
    />
  </div>
</template>

<script>
import { EnvService, userService } from '@/services'
import { buildQueryString } from '@/utils/general'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'env-settings',
  components: {
    AccountInfo: () =>
      import('@/components/profile/env-setting-comps/AccountInfo.vue'),
    FinancialSettings: () =>
      import('@/components/profile/env-setting-comps/FinancialSettings.vue'),
    Services: () =>
      import('@/components/profile/env-setting-comps/Services.vue'),
    ServiceModel: () =>
      import('@/components/profile/env-setting-comps/ServiceModel.vue'),
    bankAccounts: () => import('@/components/details/bankAccounts.vue'),
    contractSetting: () =>
      import('@/components/profile/env-setting-comps/contractSetting.vue'),
    Approvement: () =>
      import('@/components/profile/env-setting-comps/Approvement.vue'),
    ApprovalModal: () => import('./env-setting-comps/ApprovalModal.vue'),
    upgradeView: () => import('@/components/helper/upgradeView.vue')
  },
  data() {
    return {
      serviceDialogState: false,
      templateDate: {},
      approvalDialogState: false,
      tab: 0,
      isValid: true,
      userData: {},
      env: {},
      envEditableFields: [
        'name',
        'CommercialNumber',
        'whatsAppNumber',
        'address',
        'hasCommissionTax',
        'showBankAccounts',
        'about'
      ],
      userEditableFields: ['company', 'taxNumber'],
      locationInfo: {
        location: {},
        envAddress: {
          address: ''
        }
      },
      module: {},
      allEmployees: [],
      jobTitles: [],
      jobId: '',
      employees: [],
      paginationEmployees: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      addBanksDataLoading: false,
      bankAccountsDetails: []
    }
  },
  watch: {
    currentEnv() {
      this.env = { ...this.currentEnv }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    availableTabs() {
      return [
        {
          title: 'معلومات الشركه',
          name: 'AccountInfo',
          hasPermission: !!this.isOwner,
          isSaveBtn: true
        },
        // {
        //   title: 'إعدادات مالية',
        //   name: 'FinancialSettings',
        //   hasPermission: !!this.isOwner || this.hasListPermission('financial'),
        //   isSaveBtn: true
        // },
        {
          title: 'الخدمات',
          name: 'Services',
          hasPermission:
            !!this.isOwner || this.hasListPermission('servicesSettings'),
          isSaveBtn: false
        },
        // {
        //   title: 'الحسابات البنكية',
        //   name: 'bankAccounts',
        //   hasPermission:
        //     !!this.isOwner || this.hasListPermission('bankAccountsSettings'),
        //   isSaveBtn: false
        // },

        {
          title: 'الموافقات',
          name: 'Approvement',
          hasPermission:
            !!this.isOwner || this.hasListPermission('approvalsSettings'),
          isSaveBtn: false
        }
      ]
    },
    isOwner() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    },
    getEnvParameters() {
      const envParameters = {
        currentData: this.env,
        sotredData: this.currentEnv,
        fileds: this.envEditableFields
      }
      return envParameters
    },
    getUserParameters() {
      const userParameters = {
        currentData: this.userData,
        sotredData: this.user,
        fileds: this.userEditableFields
      }
      return userParameters
    },
    isWhatsAppNumberChanged() {
      return this.formattedWhatsAppNumber !== this.env?.whatsAppNumber
    },
    formattedWhatsAppNumber() {
      return this.userData.environments[
        this.currentEnvIndex
      ].whatsAppNumber?.replace('966', '0')
    },
    currentEnvIndex() {
      return this.userData.environments.findIndex(
        (env) => env._id === this.env._id
      )
    },
    newUserEnv() {
      const newEnv = {
        ...this.userData.environments[this.currentEnvIndex],
        ...this.env,
        phoneNumber: this.user?.phoneNumber,
        whatsAppNumber: this.env?.whatsAppNumber?.replace('0', '966')
      }
      return newEnv
    }
  },
  methods: {
    ...mapActions('accountState', ['updateEnvSettings', 'updateCurrentEnv']),
    ...mapMutations('appState', ['addNotification']),
    hasListPermission(module) {
      return !!this.$hasPermission('users', module, 'list')
    },
    setJobId(id) {
      this.jobId = id
      this.loadAllEmployees()
    },
    hasUpdatePermission(module) {
      return !!this.$hasPermission('users', module, 'update')
    },
    async getEnvBankAccounts() {
      try {
        const { data } = await EnvService.getEnvOrClientBankAccounts(
          this.currentEnv?._id
        )
        this.bankAccountsDetails = data.bankAccounts
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل بيانات الحساب',
          color: 'error'
        })
      }
    },
    updateServiceSettingss(newServices) {
      const services = this.currentEnv.services
      const updatedServices = services.map((service) => {
        if (service.code === newServices.code) {
          return newServices
        }
        return service
      })

      this.updateCurrentEnv({ services: updatedServices })
    },
    resetQuery() {
      const isThereQuery = Object.keys(this.$route.query).length
      if (isThereQuery) {
        this.$router.replace({ query: null })
      }
    },
    async getEmployeesNextData() {
      if (
        this.paginationEmployees.pageNumber <
        this.paginationEmployees.pagesCount
      ) {
        this.paginationEmployees.pageNumber += 1
        await this.loadAllEmployees()
      }
    },
    calculatePagination(data) {
      this.paginationEmployees.pagesCount = Math.ceil(
        data?.count / this.paginationEmployees.pageSize
      )
      this.paginationEmployees.count = data?.count ?? 0
    },
    updateEmployees(data) {
      this.employees = data.users ?? []
      // this.allEmployees = [...this.allEmployees, ...this.employees]
      this.allEmployees = this.employees
    },
    async getAllJobsTitles() {
      const query = buildQueryString({
        environmentId: this.currentEnv?._id
      })
      try {
        const { data } = await EnvService.getJobTitles(query)
        this.jobTitles = data.envJobTitles
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل الموظفين',
          color: 'error',
          duration: 4000
        })
      }
    },
    async loadAllEmployees() {
      try {
        const param = buildQueryString(
          {
            pageSize: this.paginationEmployees.pageSize,
            pageNumber: this.paginationEmployees.pageNumber,
            registered: 1,
            ...(this.jobId && { jobId: this.jobId })
          },
          '&'
        )
        const { data } = await EnvService.getAllEmployee(
          this.currentEnv._id,
          param
        )
        this.calculatePagination(data)
        this.updateEmployees(data)
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل الموظفين',
          color: 'error',
          duration: 4000
        })
      }
    },
    async bindBankAccountsData(bankAccountsData) {
      try {
        this.addBanksDataLoading = true
        const payload = bankAccountsData.map((account) => {
          return {
            ...account,
            entityId: this.currentEnv?._id,
            entityType: 'Environment',
            environmentId: this.currentEnv?._id
          }
        })
        await EnvService.addEnvBankAccount({ bankAccounts: payload })
        this.getEnvBankAccounts()
      } catch {
        this.addNotification({
          text: 'لم نتمكن من إضافة حسابك البنكي, من فضلك حاول في وقت لاحق',
          color: 'error'
        })
      } finally {
        this.addBanksDataLoading = false
      }
    },
    toggleServiceModel(state) {
      this.serviceDialogState = state.dialogState
      this.templateDate = state.template
    },
    toggleApprovalModel(state) {
      this.approvalDialogState = state.dialogState
      if (state.approval) {
        this.module = state.approval
      }
    },
    setDataAndValidation({ isValid, userData, env }) {
      this.isValid = isValid
      this.userData = { ...this.userData, ...userData }
      this.env = { ...this.env, ...env }
    },
    checkEditableFields({ currentData, sotredData, fileds }) {
      const isNotEdited = fileds.every((field) => {
        return !!(currentData[field] === sotredData[field])
      })
      return isNotEdited
    },
    async saveUpdatedData() {
      if (this.isValid) {
        if (
          this.checkEditableFields({ ...this.getUserParameters }) &&
          this.checkEditableFields({ ...this.getEnvParameters })
        ) {
          this.addNotification({
            text: 'لم يتم تغيير البيانات',
            color: 'error'
          })
          return
        }

        try {
          const envRes = await userService.updateEnv(this.newUserEnv)
          if (envRes.status === 200) {
            this.userData.environments[this.currentEnvIndex] = {
              ...this.env,
              environmentRole: '' // to avoid switching from account owner to customer after updating
            }
          }
          const userRes = await userService.updateProfile(this.userData)
          if (userRes.status === 201) {
            this.updateEnvSettings({ ...this.userData })
          }
          this.addNotification({
            text: 'تم تعديل إعدادات الحساب بنجاح',
            color: 'success'
          })
        } catch {
          this.addNotification({
            text: 'حدث خطأ أثناء تحديث بيانات البيئة, حاول في وقت لاحق',
            color: 'error'
          })
        }
      } else {
        this.addNotification({
          text: 'من فضلك تأكد من استكمال جميع الحقول بشكل صحيح',
          color: 'error'
        })
      }
    },
    checkAboutCompany(aboutCompany) {
      this.$set(this.env, 'about', aboutCompany)
      this.saveUpdatedData()
    },
    checkAddress(options) {
      this.locationInfo.location = {
        coordinates: [options?.place?.lng, options?.place?.lat],
        type: 'Point'
      }
      this.locationInfo.envAddress.address = options?.place?.address
      this.env.location = this.locationInfo.location
      this.env.address = this.locationInfo.envAddress.address
      this.userData.location = this.locationInfo.location
      this.userData.address = this.locationInfo.envAddress.address
    },
    setEnvSettingsData() {
      this.env = { ...this.currentEnv }
      this.userData = { ...this.user }
      if (this.env.address) {
        this.locationInfo.location = this.env.location
        this.locationInfo.envAddress.address = this.env.address
      } else {
        this.locationInfo.location = this.userData.location
        this.locationInfo.envAddress.address = this.userData.address
      }
    },
    setActiveTab() {
      const currentComponent = this.$route.query.currComponent
      if (currentComponent === 'Approvement') {
        this.tab = 5
      }
    }
  },
  async created() {
    this.setActiveTab()
    this.resetQuery()
    this.setEnvSettingsData()
    this.getEnvBankAccounts()
    // await this.loadAllEmployees()
    await this.getAllJobsTitles()
  }
}
</script>
