<template>
  <div class="show-detailes contentSection contracts__bonds__side__deatails">
    <v-slide-x-transition>
      <v-layout>
        <v-flex @click="closeSideVersion" class="greySide" md3></v-flex>
        <v-flex class="whiteSide flex-wrap" md9>
          <v-row class="my-2 mr-1">
            <v-img
              :lazy-src="exitArrow"
              :src="exitArrow"
              @click="$emit('closeDetails')"
              max-height="15"
              max-width="15"
            ></v-img>
          </v-row>
          <v-row class="mx-0" v-if="isLoading">
            <v-col
              cols="12"
              class="property-col mb-2 shadow-border-radius px-0"
              v-for="i in 3"
              :key="i"
            >
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="list-item-avatar-two-line"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-layout class="sidedetails__layout" v-else>
            <v-flex>
              <bond-card
                v-for="(i, j) in versions"
                :key="j"
                :bond="i"
                :versionsLength="versions.length"
                @showDetails="setDetails"
                :sideVersions="true"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-slide-x-transition>
  </div>
</template>
<script>
import { bondsService } from '@/services'
import { mapMutations } from 'vuex'
import BondCard from '@/components/bonds/BondCard'

export default {
  name: 'side-versions',
  components: { BondCard },
  props: ['bond'],
  data() {
    return {
      exitArrow: require('@/assets/img/svgs/right-arrow.svg'),
      versions: [],
      isLoading: true
    }
  },
  watch: {
    bond: {
      immediate: true,
      async handler() {
        try {
          const bondId = this.$props.bond
          if (bondId) {
            const allVersions = await bondsService.getBondVersions(bondId)
            this.versions = allVersions.data.bondsVersions
            this.isLoading = false
          }
        } catch (error) {
          this.addNotification({
            text: error,
            color: 'error'
          })
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setDetails({ visible, bond, terminated }) {
      this.$emit('showDetails', { visible, bond, terminated })
      this.$emit('showVersions', { visible: false, id: bond._id })
    },
    closeSideVersion() {
      this.isLoading = true
      this.$emit('showVersions', { visible: false, id: this.bond })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_sideVersionPages.scss';
</style>
