<template>
  <v-dialog
    persistent
    v-model="dialog"
    :max-width="options.width"
    :max-height="options.height"
    :style="{ zIndex: options.zIndex, border: 'none' }"
    @keydown.esc="closeDialog"
  >
    <v-card style="border: 0 !important">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog" :width="16">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text class="my-0 pb-4 text-center">
        <v-img
          :max-width="img.width"
          :max-height="img.height"
          class="mx-auto"
          :src="img.path"
          v-if="!statusRestore"
        ></v-img>
        <GSvg v-else style="width: 65px; height: 65px" nameIcon="restore-big" />
        <div v-show="!!title" class="mt-6 font-18 font-weight-medium mainTitle">
          {{ title }}
        </div>
        <div
          v-show="!!message"
          class="mt-3 font-12 font-weight-medium subTitle"
        >
          {{ message }}
        </div>
        <div v-if="isInput">
          <component ref="component" :is="isInput"></component>
        </div>
      </v-card-text>
      <v-card-actions class="pt-0 px-0 actionsContainer">
        <v-spacer />
        <v-btn
          v-if="cancelButtonStatus"
          class="btnActions cancel mx-2 mt-0 pdf__action__btns"
          @click.native="agreeOrCancel(false)"
          min-width="135"
          min-height="40"
        >
          <span class="tf">{{ cancelText }}</span>
        </v-btn>
        <v-btn
          :class="[{ 'w-75': !cancelButtonStatus }]"
          :color="statusRestore ? options.color2 : options.color"
          @click.native="agreeOrCancel(true)"
          class="btnActions mx-2 mt-0 pdf__action__btns"
          min-width="135"
          min-height="40"
        >
          <span class="tf">{{ confirmTxt }}</span>
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RejectResonApproval from '@/components/approval/RejectReson.vue'
export default {
  components: {
    RejectResonApproval
  },
  data: () => ({
    cancelButtonStatus: true,
    img: {
      path: require('@/assets/img/svgs/closeImg.svg'),
      width: 56,
      height: 56
    },
    exclamation: require('@/assets/img/svgs/exclamationMark.svg'),
    dialog: false,
    resolve: null,
    reject: null,
    isInput: false,
    message: null,
    title: null,
    confirmTxt: null,
    cancelText: null,
    statusRestore: false,
    options: {
      color: 'primary',
      color2: 'purple',
      width: 328,
      height: 278,
      zIndex: 200
    }
  }),
  methods: {
    open({
      title,
      message,
      options,
      input = false,
      confirmTxt = 'نعم',
      statusRestore = this.statusRestore,
      img = {
        path: require('@/assets/img/svgs/closeImg.svg'),
        width: 56,
        height: 56
      },
      cancelButtonStatus = true,
      cancelText = 'إلغاء'
    }) {
      this.dialog = true
      this.title = title
      this.isInput = input
      this.message = message
      this.statusRestore = statusRestore
      this.confirmTxt = confirmTxt
      this.cancelText = cancelText
      this.options = Object.assign(this.options, options)
      this.img = img
      this.cancelButtonStatus = cancelButtonStatus
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    close() {
      this.dialog = false
      this.statusRestore = false
    },
    agreeOrCancel(payload) {
      this.resolve(payload)
      this.close()
    },
    closeDialog() {
      this.reject()
      this.close()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/responsiveness/responsiveness.scss';
@import '@/styles/material-dashboard/_variables.scss';
.mainTitle {
  color: #101010;
}
.subTitle {
  color: #737373;
}
.v-card .v-card__actions.actionsContainer {
  border: none;
  .btnActions {
    box-shadow: 0px 4px 20px rgba($primary-color, 0.1);
    border-radius: 13px;
  }
  .w-75 {
    width: 90%;
  }
  .cancel {
    background: #9f9fac;
  }
  @include max-sm {
    .pdf__action__btns {
      min-width: 110px !important;
      min-height: 30px !important;
    }
  }
  // @include max-sm
}
</style>
