<template>
  <div class="d-flex justify-space-between">
    <div class="d-flex">
      <v-img
        class="mt-1"
        max-height="24"
        max-width="24"
        :src="noteIcon"
        alt="Note icon"
      ></v-img>
      <div class="mr-2 d-flex flex-column note-text">
        <span class="font-12">{{ note.body }}</span>
        <span class="font-12 grey--text">
          {{ formatDate(note.createdAt) }}
        </span>
      </div>
    </div>
    <v-img
      class="cursor mt-2 ml-2"
      width="16"
      max-width="16"
      max-height="16"
      :src="deleteNoteIcon"
      @click="deleteNote"
    ></v-img>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'NoteCard',
  mixins: [dateFormat],
  props: {
    note: {
      type: Object,
      required: true
    },
    noteIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      noteIcon: require('@/assets/img/svgs/txt-file-icon.svg'),
      deleteNoteIcon: require('@/assets/img/svgs/delete-icon-grey.svg')
    }
  },
  methods: {
    deleteNote() {
      this.$emit('deleteNote', { id: this.note._id, index: this.noteIndex })
    }
  }
}
</script>

<style lang="scss" scoped>
.note-text {
  line-height: 16px;
  max-width: 80%;
  text-align: justify;
}
</style>
