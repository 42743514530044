<template>
  <v-row>
    <v-col class="pa-0">
      <v-form @submit.prevent="addItem" ref="form" v-model="isValid">
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model.trim="itemName"
                :rules="[(v) => (!!v && !!v.trim()) || inputPlaceholder]"
                dense
                filled
                hide-details
                :placeholder="inputPlaceholder"
                required
                data-test="addTasksInput"
              >
                <template class="ma-0" v-slot:append>
                  <v-btn
                    @click="addItem"
                    :disabled="!isValid"
                    depressed
                    class="addBtn ma-0 pa-0 px-5 primary"
                    :class="{ notReady: !itemName, isReady: itemName }"
                    rounded
                    data-test="addTasks"
                  >
                    <span class="mt-2 white--text">إضافه</span>
                  </v-btn>
                </template></v-text-field
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'AddNewItem',
  props: {
    hasPermission: {
      default: true
    },
    maintenanceRequests: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isValid: false,
      itemName: ''
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    inputPlaceholder() {
      return this.maintenanceRequests ? 'إسم الطلب' : 'إسم المهمه'
    },
    itemToAdd() {
      // Set Default Values for new Task
      const itemToAdd = {
        name: this.itemName,
        priority: 'منخفض',
        status: {
          nameEn: 'new',
          nameAr: 'جديده'
        },
        createdBy: this.user._id,
        environment: this.currentEnv._id
      }
      return itemToAdd
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    addItem() {
      if (!this.hasPermission) {
        this.addNotification({
          text: 'ليس لديك صلاحية لإضافة مهمهات',
          color: 'error'
        })
        this.$refs.form.reset()
        return
      }
      this.$emit('addNewItem', this.itemToAdd)
      this.$refs.form.reset()
    }
  }
}
</script>
