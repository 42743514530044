<template>
  <div class="col-md-6">
    <div>
      <div class="component-title">
        <h5 class="text-truncate font-16 mb-1">الإحصائيات عامة</h5>
      </div>
      <div class="general__stats-cards d-flex py-2">
        <div
          class="right__side--card card v-card v-sheet theme--light ml-2 px-4 align-center"
        >
          <div class="right__side--chart">
            <div class="right__side--top--logo">
              <img
                height="26"
                width="26"
                lazy-src="@/assets/img/svgs/house-outline.svg"
                src="@/assets/img/svgs/house-outline.svg"
              />
            </div>
            <!-- top-logo -->
            <div class="right__side--chart-circle">
              <div class="box px-5">
                <svg>
                  <circle cx="50" cy="50" r="45"></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    class="progress"
                    data-value="100"
                    data-stroke="#12cad6"
                    data-time="10"
                  ></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    class="progress"
                    data-value="70"
                    data-stroke="#12cad6"
                    data-time="100"
                  ></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    class="progress"
                    data-value="40"
                    data-stroke="#f49300"
                    data-time="100"
                  ></circle>
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    class="progress"
                    data-value="10"
                    data-stroke="#f49300"
                    data-time="100"
                  ></circle>
                </svg>
                <span class="data-progresss unit-position">الوحدات</span>
                <span class="data-progresss total-position">
                  {{ totalRealEstates }}
                </span>
              </div>
            </div>
            <!-- chart-circle -->
            <div class="chart-data" v-if="isRealEstateExist">
              <div class="top line-height-1_1 mt-7">
                <v-row>
                  <v-col cols="6">
                    <div class="d-flex align-center">
                      <div class="dot available-dot"></div>
                      <span class="text font-14 fw-500 mr-1"> الشاغرة </span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="d-flex align-center">
                      <span class="text fw-500">
                        {{ generalStats.realEstate[0].numOfProperties }}
                      </span>
                      <span class="count font-12 grey--text mr-1">
                        ({{ generalStats.realEstate[0] | percentage }}%)
                      </span>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="6">
                    <div class="d-flex align-center">
                      <div class="dot rented-dot"></div>
                      <span class="text font-14 fw-500 mr-1"> المؤجرة </span>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="d-flex align-center">
                      <span class="text fw-500">
                        {{ generalStats.realEstate[2].numOfProperties }}
                      </span>
                      <span class="count font-12 grey--text mr-1">
                        ({{ generalStats.realEstate[2] | percentage }}%)
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <!-- chart-data -->
          </div>
          <!-- chart-div -->
        </div>
        <!-- right-side-card -->
        <div class="left__side-cards w-100">
          <div
            v-if="this.generalStats.totalClients"
            class="financial__statistics--cards general__stats-cards left__side--top"
          >
            <div
              class="card v-card mx-0 v-sheet theme--light card-1 mb-2 px-3 py-5 justify-space-between flex-column align-start v_card"
              v-for="contract in generalStats.contracts"
              :key="contract.type"
            >
              <div class="general__stats--icon">
                <div class="icon icon__purple">
                  <img
                    height="14"
                    width="14"
                    lazy-src="@/assets/img/svgs/report.svg"
                    src="@/assets/img/svgs/report.svg"
                  />
                </div>
              </div>
              <div
                class="card-title text-container general__stats--cards--desp"
              >
                <div>
                  <div class="text">{{ contract.type | contractType }}</div>
                  <h5 class="col-value" v-text="contract.total" />
                </div>
                <div class="general__stats-cards--ul">
                  <ul>
                    <li class="d-flex justify-between">
                      <span class="text grey-color"> نشط </span>
                      <span
                        class="count dark-blue-shade-color"
                        v-text="contract.active"
                      />
                    </li>
                    <li class="d-flex justify-between">
                      <span class="text grey-color"> منتهي </span>
                      <span class="count red--flag" v-text="contract.expired" />
                    </li>
                    <li class="d-flex justify-between">
                      <span class="text grey-color"> ينتهي قريبا </span>
                      <span
                        class="count orange-color"
                        v-text="contract.expiringSoon"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              class="card v-card v-sheet theme--light card-1 px-3 pt-3 pb-2 justify-space-between align-start mb-0"
            >
              <div class="left__side--top">
                <div class="d-flex">
                  <div class="icon icon__purple">
                    <img
                      height="28"
                      width="28"
                      lazy-src="@/assets/img/svgs/notifications/users.svg"
                      src="@/assets/img/svgs/notifications/users.svg"
                    />
                  </div>
                  <!-- icon -->
                  <div class="">
                    <div class="text">الملاك</div>
                    <!-- text -->
                    <div class="d-flex pr-3">
                      <h5
                        class="col-value ma-0 f-18"
                        v-text="generalStats.totalOwners"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- left__side--top -->
            </div>
            <div
              class="card v-card v-sheet theme--light card-1 px-3 pt-3 pb-2 justify-space-between align-start mb-0"
            >
              <div class="left__side--top">
                <div class="d-flex">
                  <div class="icon icon__purple">
                    <img
                      height="14"
                      width="14"
                      lazy-src="@/assets/img/svgs/report.svg"
                      src="@/assets/img/svgs/report.svg"
                    />
                  </div>
                  <!-- icon -->
                  <div class="">
                    <div class="text">المستأجرين</div>
                    <!-- text -->
                    <div class="d-flex pr-3">
                      <h5
                        class="col-value ma-0 f-18"
                        v-text="generalStats.totalRenters"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- left__side--top -->
            </div>
          </div>
        </div>
        <!-- left__side-cards -->
      </div>
      <!-- general__stats-cards -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneralStatistics',
  props: {
    generalStats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      value: 0,
      colorsSVG: {
        sold: '#642d87',
        sale: '#7feac3',
        rent: '#15c3c7',
        rented: '#f29400'
      }
    }
  },
  filters: {
    percentage(val) {
      return val.percentage?.toFixed(0) ?? 0
    },
    contractType(val) {
      if (val === 'rent') {
        return 'عقود الايجار'
      }
      if (val === 'propertyManagement') {
        return 'عقود ادارة إملاك'
      }
      return 'عقود صيانة'
    }
  },
  computed: {
    isRealEstateExist() {
      return this.generalStats.realEstate?.length > 0
    },
    totalRealEstates() {
      let total = 0
      this.generalStats?.realEstate?.forEach((realEstate) => {
        total += realEstate.numOfProperties
      })
      return total
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: 5.8rem;
  height: 5.8rem;
  transform: rotate(270deg);
  transform-origin: center;
}

circle {
  stroke-width: 0.4rem;
  fill: none;
  transition: all 0.5s ease;
}

circle:nth-child(1) {
  stroke: rgba(43, 26, 106, 0.218);
  filter: drop-shadow(0 0 0.001rem #eee);
}

.progress {
  stroke: var(--stroke);
  position: relative;
  z-index: 1;
  transition: var(--transition);
  stroke-dasharray: var(--stroke-dasharray);
  stroke-dashoffset: var(--stroke-dasharray);
  animation: progress 10s ease-in forwards;
  --webkit-stroke-position: inside;
  stroke-linecap: round;
}

.box {
  position: relative;
}

.data-progresss {
  position: absolute;
  color: black;
  font-size: 14px;
  font-weight: 700;
  left: 50%;
  transform: translate(-50%, -50%);
}
.unit-position {
  top: 40%;
}
.total-position {
  top: 60%;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.available-dot {
  background-color: #12cad6;
}
.rented-dot {
  background-color: #f49300;
}
</style>
