<template>
  <v-row class="mx-2 mt-2">
    <v-layout class="d-flex justify-space-between align-center">
      <v-icon data-test="close-pdf" @click="$emit('closePDF')"
        >mdi-arrow-right</v-icon
      >
      <v-spacer></v-spacer>
      <template v-if="tab + 1 === maxTab">
        <div
          v-for="(sign, i) in signatures"
          :key="i"
          class="d-flex justify-space-between align-center"
        >
          <h6 class="font-12 ma-0 ml-1">{{ sign.name }}</h6>
          <v-switch
            dense
            inset
            v-model="sign.status"
            class="mt-5"
            @change="
              $emit('signatureStatus', {
                type: sign.title,
                status: sign.status
              })
            "
          />
        </div>
      </template>
      <v-btn
        class="d-flex align-center primary px-4 py-4"
        @click="$emit('downloadPDF')"
      >
        <v-icon class="ml-2" small>mdi-export-variant</v-icon>
        <span class="font-14 mb-1"> بي دي اف </span>
      </v-btn>
      <slot />
    </v-layout>
  </v-row>
</template>

<script>
export default {
  name: 'PDFHeader',
  props: {
    tab: {
      type: Number,
      required: true
    },
    maxTab: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      logo: require('@/assets/img/svgs/logo.svg'),
      signatures: [
        {
          title: 'rentSignature',
          name: 'توقيع ممثل التأجير',
          status: false
        },
        {
          title: 'companySeal',
          name: 'ختم الشركة',
          status: false
        }
      ]
    }
  }
}
</script>
<style lang="scss"></style>
