<template>
  <v-footer
    v-if="isFooterExist"
    id="core-footer"
    app
    height="82"
    :class="{ customer_landing_page: isCustomerLandingPage }"
  >
    <div class="v-footer-inner">
      <div class="copyRight">
        <v-img :src="HLogo" class="site-logo" height="50" contain />
        <span
          class="my-auto mr-6 font-weight-light copyright white--text font-14"
        >
          جميع الحقوق محفوظة &copy;
          {{ new Date().getFullYear() }}
        </span>
      </div>

      <v-spacer />
      <div class="footer-items">
        <a href="https://hectar.io/about" target="_blank">
          <v-btn text dense class="mx-2">
            <span> عن هكتار </span>
          </v-btn>
        </a>
        <v-btn @click="setDialogStatus(true)" text dense class="mx-2">
          <span> تواصل مع المبيعات</span>
        </v-btn>
        <!-- <router-link :to="{ name: 'customerLandingPage' }">
          <v-btn text dense class="mx-2">
            <span> بوابة العملاء </span>
          </v-btn>
        </router-link>
        <a href="https://hectar.io/">
          <v-btn text dense class="mx-2">
            <span> بوابة الخدمات </span>
          </v-btn>
        </a> -->
      </div>
    </div>
    <contactSalesModel
      v-if="dialogState"
      :dialogState="dialogState"
      @changeDialogState="setDialogStatus($event)"
      @closed-add-dialog="setDialogStatus(false)"
    />
  </v-footer>
</template>

<script>
export default {
  components: {
    contactSalesModel: () => import('../modals/contactSalesModel.vue')
  },
  data: () => ({
    dialogState: false,
    HLogo: require('@/assets/img/Hectar_Logos/HLogo-white.png'),
    // Add Paths name that you want exclude footer from it
    exlcudedFooterPaths: ['login', 'setup-env']
  }),
  computed: {
    isCustomerLandingPage() {
      return !!(this.$route.name === 'customerLandingPage')
    },
    isFooterExist() {
      return !!!this.exlcudedFooterPaths.includes(this.$route?.name)
    }
  },
  methods: {
    setDialogStatus(val) {
      this.dialogState = val
    }
  }
}
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
