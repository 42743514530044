<template>
  <v-container :class="{ userView: isCustomer }">
    <div v-if="items?.length && showTaxIvoicesTable">
      <ContractTapsHeader :title="title" @addAction="$emit('addAction')" />
      <v-data-table
        :headers="headers"
        :items="slicedItems"
        hide-default-footer
        class="tab-table-details"
      >
        <template v-slot:[`item.serviceType`]="{ item }">
          <div
            :class="generateStyleClasses(item.serviceType)"
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>
              {{ item.serviceType }}
            </h6>
          </div>
        </template>
        <template v-slot:[`item.payment`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>{{ item.payment ?? '0' }} ريال</h6>
          </div>
        </template>

        <template v-slot:[`item.meter`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>{{ item.meter ?? '-' }}</h6>
          </div>
        </template>

        <template v-slot:[`item.yearlyPayment`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>{{ item.yearlyPayment ?? 0 }} ريال - سنوياً</h6>
          </div>
        </template>

        <!-- Financial details -->
        <template v-slot:[`item.type`]="{ item }">
          <div
            :class="generateStyleClasses(item.type)"
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>
              {{ item.type }}
            </h6>
          </div>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <span class="font-14 text-color">
            {{ generateFormattedValue(item.amount) }} ريال
          </span>
        </template>

        <template v-slot:[`item.order`]="{ item }">
          <p class="ma-0 font-14">
            {{ item.order ?? '-' }}
          </p>
        </template>

        <template v-slot:[`item.year`]="{ item }">
          <p class="ma-0 font-14">
            {{ item.year ?? '-' }}
          </p>
        </template>

        <template v-slot:[`item.remaining`]="{ item }">
          <p class="ma-0 font-14">
            {{ generateFormattedValue(item.remaining) }}
          </p>
        </template>

        <template v-slot:[`item.paid`]="{ item }">
          <p class="ma-0 font-14">
            {{ generateFormattedValue(item.paid) }}
          </p>
        </template>

        <template v-slot:[`item.date`]="{ item }">
          <span class="font-14 text-color">{{ generateDueDate(item) }} </span>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <span class="font-14 text-color"
            >{{ new Date(item.createdAt).toLocaleDateString('en-CA') }}
          </span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div
            :class="generateStyleClasses(item.status)"
            class="d-flex justify-center align-center font-weight-medium bill--itself mx-auto"
          >
            <h6>
              {{ item.status }}
            </h6>
          </div>
        </template>

        <template v-slot:[`item.assignmentStatus`]="{ item }">
          <div
            :class="generateStyleClasses(item.assignmentStatus)"
            class="d-flex justify-center align-center font-weight-medium bill--itself mx-auto"
          >
            <h6>
              {{ item.assignmentStatus }}
            </h6>
          </div>
        </template>

        <template v-slot:[`item.installmentStatus`]="{ item }">
          <div
            :class="generateStyleClasses(item.installmentStatus)"
            class="d-flex justify-center align-center font-weight-medium bill--itself mx-auto"
          >
            <h6>
              {{ item.installmentStatus ?? 'غير معروفه' }}
            </h6>
          </div>
        </template>

        <template v-slot:[`item.installmentType`]="{ item }">
          <div
            :class="generateStyleClasses(item.installmentType)"
            class="d-flex justify-center align-center font-weight-medium bill--itself mx-auto"
          >
            <h6>
              {{ item.installmentType ?? 'غير معروفه' }}
            </h6>
          </div>
        </template>

        <template v-slot:[`item.attachmentType`]="{ item }">
          <h6 class="font-14 ma-0" style="font-weight: 500">
            {{ item.type ?? '-' }}
          </h6>
        </template>

        <template v-slot:[`item.creator`]="{ item }">
          <h6 class="font-14 ma-0" style="font-weight: 500">
            {{ item.creator ?? '-' }}
          </h6>
        </template>

        <template v-slot:[`item.attachment`]="{ item }">
          <h6 class="font-14 ma-0" style="font-weight: 500">
            {{ item.attachment ?? '-' }}
          </h6>
        </template>

        <template v-slot:[`item.number`]="{ item }">
          <span class="font-14 ma-0">
            {{ item.number === 'undefined' ? '-' : item.number }}
          </span>
        </template>

        <template v-slot:[`item.createdBy`]="{ item }">
          <span class="font-14 ma-0">
            {{ item.createdBy?.name ?? '-' }}
          </span>
        </template>

        <template v-slot:[`item.taxAmount`]="{ item }">
          <span class="font-14 ma-0">
            {{ item?.taxAmount?.toFixed(2) ?? '-' }}
          </span>
        </template>

        <template v-slot:[`item.preview`]="{ item, index }">
          <v-icon class="cursor-pointer" @click="$emit('preview', item, index)">
            mdi-eye</v-icon
          >
        </template>
        <template v-slot:[`item.download`]="{ item }">
          <v-icon
            class="cursor-pointer"
            color="purple"
            @click="$emit('download', item)"
          >
            mdi-download</v-icon
          >
        </template>
        <template v-slot:[`item.delete`]="{ item, index }">
          <v-icon
            class="cursor-pointer"
            color="red"
            @click="$emit('delete', item, index)"
          >
            mdi-delete</v-icon
          >
        </template>

        <!-- Claims Details-->

        <template v-slot:[`item.endDate`]="{ item }">
          <span class="font-14 text-color">
            {{ generateDueDate(item) }}
          </span>
        </template>
      </v-data-table>
      <!--Pagination-->
      <div
        class="text-center mt-6 mb-5"
        v-if="items?.length > pagination.pageSize"
      >
        <Pagination
          :pagination="{ pagesCount, ...pagination }"
          @onChange="loadNextPage($event)"
        />
      </div>
    </div>
    <div v-else>
      <ContractTapsHeader :title="title" @addAction="$emit('addAction')" />
      <!--Defaulters are not displayed while settings is not active-->
      <NoData v-bind="noDataConfig" />
    </div>
  </v-container>
</template>

<script>
import Pagination from '@/components/helper/pagination.vue'
import NoData from '@/components/helper/noData.vue'
import dateFormat from '@/mixins/dateFormat.js'
import ContractTapsHeader from './ContractTapsHeader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'tabTableDetails',
  components: { Pagination, NoData, ContractTapsHeader },
  mixins: [dateFormat],

  props: {
    items: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    title: {
      default: '',
      type: String
    },
    noDate: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      pagination: {
        pageNumber: 1,
        pageSize: 5,
        count: 0
      },
      slicedItems: []
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    noDataConfig() {
      return {
        noDataText: this.noDate.text,
        noDataSubTitle: '',
        isbgPrimary: false,
        noDataIcon: this.noDate.icon,
        ...(this.noDate.widthHeight && { widthHeight: this.noDate.widthHeight })
      }
    },
    pagesCount() {
      return Math.ceil(this.items?.length / this.pagination.pageSize)
    },
    showTaxIvoicesTable() {
      if (this.items?.length === 0) {
        return false
      }
      if (this.items?.some((e) => e?.payment === 0)) {
        return !this.items.every(
          (item) => item?.payment === 0 || item?.payment === undefined
        )
      }
      return true
    }
  },
  methods: {
    generateDueDate({ startDate }) {
      return startDate ? `${this.formatDate(startDate)}` : 'غير معروف'
    },
    generateFormattedValue(val) {
      return val
        ? val.toLocaleString('en-US', {
            maximumFractionDigits: 2
          })
        : 0
    },
    generateStyleClasses(value) {
      let classes = ''
      switch (value) {
        case 'الكهرباء':
        case 'مبلغ التأمين':
        case 'دفعة إيجار':
          classes = 'purple--bill'
          break
        case 'المياه':
        case 'متأخر':
        case 'غير مدفوعة':
        case 'لم يتم الدفع':
          classes = 'red--bill'
          break
        case 'الغاز':
        case 'مستقبلي':
        case 'معلّقة':
          classes = 'orange--bill'
          break
        case 'الخدمات العامة':
          classes = 'default--bill'
          break
        case 'مدفوعة':
        case 'تم الدفع':
          classes = 'green--bill'
          break
        default:
          classes = 'default--bill'
      }
      return classes
    },
    loadNextPage(pageNumber) {
      this.pagination.pageNumber = pageNumber
      const { pageSize } = this.pagination
      this.slicedItems = this.items?.slice(
        pageNumber * pageSize - pageSize,
        pageNumber * pageSize
      )
    }
  },
  watch: {
    items: {
      handler() {
        this.loadNextPage(this.pagination.pageNumber)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style>
.tab-table-details table thead tr th span {
  font-size: 16px !important;
  color: #b2b2b2;
}
.container.userView {
  min-width: 100%;
  margin-right: 0 !important;
}
</style>
