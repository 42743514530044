export default {
  data() {
    return {
      statusRestore: false
    }
  },
  computed: {
    // All modules
    selections() {
      const { name } = this.$route
      switch (name) {
        case 'tasks':
          return this.tasks
        case 'properties':
          return this.properties
        case 'reports':
          return this.reports
        case 'taxInvoices':
          return this.allTaxInvoices
        case 'edit-profile':
          return this.team
        case 'clients':
          return this.users
        case 'potential-clients':
          return this.clients
        default:
          return []
      }
    },
    // Get all ids if user checked for delete
    getAllChecked() {
      const allChecked = this.selections.filter(
        (select) => select.checked && select?._id != this.user?._id
      )
      const allIds = allChecked.map(({ _id }) => _id)
      return allIds
    },
    showSelectAll() {
      return this.getAllChecked.length > 1
    },
    showBtnDelete() {
      return this.getAllChecked.length !== 0
    },
    multipleSelectionsData() {
      return {
        showSelectAll: this.showSelectAll,
        showBtnDelete: this.showBtnDelete,
        deleteItems: this.deleteItems,
        restoreDelete: this.restoreDelete,
        statusRestore: this.statusRestore,
        deleteTitle: this.deleteTitle,
        restoreTitle: this.restoreTitle
      }
    }
  },
  methods: {
    // If selectAll changes will change checked
    selectAllChanged(status) {
      this.selections.forEach((select) => {
        select.checked = status
      })
    }
  }
}
