import offerAction from '@/constans/offers/offerAction.js'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      ...offerAction,
      cancelIcon: require('@/assets/img/svgs/cancel.svg')
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    specifyColor(type) {
      switch (type) {
        case 'draft':
          return {
            nameAr: 'مسوده',
            color: 'orange--tag'
          }
        case 'new':
          return {
            nameAr: 'جديد',
            color: 'brub--tag'
          }
        case 'accepted':
          return {
            nameAr: 'مقبول',
            color: 'brub--tag'
          }
        case 'expired':
          return {
            nameAr: 'منتهي',
            color: 'red--tag'
          }
        case 'cancelled':
          return {
            nameAr: 'ملغي',
            color: 'red--tag'
          }
        case 'rejected':
          return {
            nameAr: 'مرفوض',
            color: 'red--tag'
          }
      }
      return null
    },
    isAvalidAction({ quotation, action }) {
      if (this.isCustomer) {
        return (
          action.eligibleOffers.includes(quotation.status) &&
          !action.excludeCustomer
        )
      }
      return action.eligibleOffers.includes(quotation.status)
    },
    callActionFun({ action, quotation }) {
      this[action.clickAction.methodTitle]({ action, quotation })
    },
    cancelled({ quotation }) {
      this.$emit('cancelled', { status: 'cancelled', quotation })
    },
    openPDF() {
      this.$emit('openPDF')
    },
    showDetails(quotation) {
      this.$emit('showDetails', quotation)
    },
    openOfferModel(data) {
      this.$emit('openOfferModel', data)
    },
    statusTagColorContract(item) {
      const { tagColor = 'blue--tag' } = this.contractsStatuses[item?.status]
      return tagColor
    },
    statusColorContract(item) {
      const { color = '#4fb3f9' } = this.contractsStatuses[item?.status]
      return color
    },
    statusNameArContract(item) {
      const { nameAr } = this.contractsStatuses[item?.status]
      return nameAr
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    ...mapGetters('accountState', ['isCustomer', 'contractsStatuses']),
    getBreakPoint() {
      return this.$vuetify.breakpoint || {}
    }
  }
}
