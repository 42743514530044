/*eslint-disable*/
/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
import store from '../store'
export default [
  {
    path: '*',
    meta: {
      name: '404-error',
      requiresAuth: false
    },
    component: () => import('@/views/404-error.vue')
  },
  // This  allows you to have pages apart of the app but no rendered inside the dash
  {
    path: '/',
    meta: {
      name: 'home',
      requiresAuth: false
    },
    component: () => import('@/layouts/Home.vue'),
    children: [
      {
        path: '/',
        name: 'landingPage',
        meta: {
          name: 'home',
          requiresAuth: false
        },
        component: () =>
          import(/* webpackChunkName:"LandingPage" */ '@/views/landingPage.vue')
      },
      {
        path: '/customer-landingPage',
        name: 'customerLandingPage',
        component: () =>
          import(
            /* webpackChunkName:"LandingPage" */ '@/views/landingPage-customer/customer-landingPage.vue'
          )
      }
    ]
  },
  {
    path: '/auth',
    name: 'login',
    meta: {
      name: 'login',
      requiresAuth: false
    },
    component: () => import('@/layouts/Home.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/Login.vue')
      },
      {
        path: 'invitation',
        name: 'invitation',
        component: () => import('@/views/invitation.vue')
      }
    ]
  },
  {
    path: '/setup-env',
    name: 'setup-env',
    meta: {
      requiresAuth: false
    },
    component: () => import('@/views/env-creation/env-wizered.vue')
  },
  {
    path: '/error-page',
    name: '404Error',
    component: () => import('@/views/404-error.vue'),
    meta: {
      title: 'خطأ 404',
      requiresAuth: false
    }
  },
  {
    path: '/help-center',
    name: 'help-center',
    component: () => import('@/views/helpCenter.vue'),
    meta: {
      title: 'مركز المساعده',
      informativeTip: {
        title: 'المساعده ',
        description:
          'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
      },
      requiresAuth: false
    }
  },
  // temp path for invoice pdf to create the InvoicePDF UI to be deleted after complete the ui
  {
    path: '/InvoicePdf',
    name: 'InvoicePdf',
    meta: {
      name: 'InvoicePdf',
      requiresAuth: true
    },
    component: () => import('@/components/Invoice-pdf/Invoice-pdf.vue')
  },
  {
    path: '/InvoicePdfNew',
    name: 'InvoicePdfNew',
    meta: {
      name: 'InvoicePdfNew',
      requiresAuth: true
    },
    component: () => import('@/components/Invoice-pdf/Invoice-pdf-new.vue')
  },
  {
    path: '/dashboard',
    meta: {
      name: 'Dashboard View',
      requiresAuth: true
    },
    component: () => import('@/layouts/DashboardView.vue'),
    children: [
      {
        path: '/',
        name: 'homeDetails',
        meta: {
          informativeTip: {
            title: 'الصفحه الرئيسيه ',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          }
        },
        component: () => import('@/views/homePage.vue')
      },
      {
        path: 'ActivityLog',
        name: 'ActivityLog',
        meta: {
          name: 'ActivityLog',
          requiresAuth: true
        },
        component: () =>
          import(/* webpackChunkName:"ActivityLog" */ '@/views/ActivityLog.vue')
      },
      {
        path: 'statistics',
        name: 'statistics',
        meta: {
          informativeTip: {
            title: 'عنوان المعلومه الفرعيه',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          }
        },
        component: () => import('@/views/Statistics.vue')
      },
      {
        path: 'edit-profile/:tab',
        name: 'edit-profile',
        meta: {
          informativeTip: {
            title: 'عنوان معلومات البيئه',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          }
        },
        component: () => import('@/views/EditProfile.vue')
      },
      {
        path: 'change-password',
        name: 'change-password',
        meta: {
          informativeTip: {
            title: 'عنوان المعلومه الفرعيه',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          }
        }
        // component: () => import('@/views/ChangePassword.vue')
      },
      {
        path: 'properties/:addNewProperty',
        name: 'properties',
        meta: {
          name: 'properties',
          informativeTip: {
            title: 'العقارات',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'realestates',
            route: 'realestates',
            permissions: 'list',
            module: 'realestates'
          }
        },
        component: () => import('@/views/Properties.vue')
      },
      {
        path: 'property-details/:id',
        name: 'PropertyDetails',
        meta: {
          name: 'PropertyDetails',
          informativeTip: {
            title: 'الصفحه الداخليه للعقار',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'realestates',
            route: 'realestates',
            permissions: 'list',
            module: 'realestates'
          }
        },
        component: () => import('@/views/PropertyDetails.vue')
      },
      {
        path: 'clients',
        name: 'clients',
        meta: {
          name: 'clients',
          informativeTip: {
            title: 'العملاء',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'users',
            route: 'clients',
            permissions: 'list',
            module: 'clients'
          }
        },
        component: () => import('@/views/Clients.vue')
      },
      {
        path: 'client/:id',
        name: 'client',
        meta: {
          name: 'client',
          informativeTip: {
            title: 'الصفحه الداخليه للعميل',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'users',
            route: 'clients',
            permissions: 'list',
            module: 'clients'
          }
        },
        component: () => import('@/views/Client.vue')
      },
      {
        path: 'defaulters',
        name: 'defaulters',
        meta: {
          name: 'defaulters',
          informativeTip: {
            title: 'العملاء المتعثرين',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'users',
            route: 'defaulters',
            permissions: 'list',
            module: 'clients'
          }
        },
        component: () => import('@/views/defaulters-clients.vue')
      },
      {
        path: 'sync',
        name: 'sync',
        meta: {
          name: 'sync',
          informativeTip: {
            title: 'عنوان المعلومه الفرعيه',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true
        },
        component: () => import('@/views/SyncwithHectar.vue')
      },
      {
        path: 'messaging',
        meta: {
          name: 'messaging',
          informativeTip: {
            title: 'الرسائل ',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true
        },
        component: () => import('@/views/Messaging.vue')
      },
      {
        path: 'notifications',
        meta: {
          name: 'notifications',
          informativeTip: {
            title: 'الاشعارات ',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true
        },
        component: () => import('@/views/Notifications.vue')
      },
      {
        path: 'letters',
        meta: {
          name: 'letters',
          informativeTip: {
            title: 'الحروف',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: false
        },
        component: () => import('@/views/Letters.vue')
      },
      {
        path: 'approval',
        name: 'approval',
        meta: {
          name: 'approval',
          informativeTip: {
            title: 'طلبات الموافقات',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: false,
          moduleAuth: true,
          acl: {
            domain: 'properties',
            route: 'bonds',
            permissions: 'list',
            module: 'bonds'
          }
        },
        component: () => import('@/views/Approval.vue')
      },
      {
        path: 'bonds',
        name: 'bonds',
        meta: {
          name: 'bonds',
          informativeTip: {
            title: 'السندات ',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: false,
          moduleAuth: true,
          acl: {
            domain: 'properties',
            route: 'bonds',
            permissions: 'list',
            module: 'bonds'
          }
        },
        component: () => import('@/views/Bonds.vue')
      },
      {
        path: 'dues',
        name: 'dues',
        meta: {
          name: 'bonds',
          informativeTip: {
            title: 'المطالبات '
          },
          requiresAuth: false,
          moduleAuth: true,
          acl: {
            domain: 'properties',
            route: 'assignments',
            permissions: 'list',
            module: 'bonds'
          }
        },
        component: () => import('@/views/Dues.vue')
      },
      {
        path: 'contracts',
        name: 'contracts',
        meta: {
          name: 'contracts',
          informativeTip: {
            title: 'العقود',
            description:
              'العقد المبرم  تبني عليه الحسابات والعمليات المتعلقة بالعملية التعاقدية ولا يعتبر وثيقة او مستند رسمي معتمد'
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'properties',
            route: 'contracts',
            permissions: 'list',
            module: 'contracts'
          }
        },
        component: () => import('@/views/Contracts.vue')
      },
      {
        path: 'associations',
        name: 'associations',
        meta: {
          name: 'associations',
          informativeTip: {
            title: 'الجمعيات',
            description: ''
          },
          // TODO remove comments
          // requiresAuth: true,
          // moduleAuth: true,
          acl: {
            domain: 'associations',
            route: 'associations',
            permissions: 'list',
            module: 'associations'
          }
        },
        component: () => import('@/views/Associations.vue')
      },
      {
        path: 'associations/:id',
        name: 'associationsDetails',
        meta: {
          name: 'associationsDetails',
          informativeTip: {
            title: 'تفاصيل الجمعية',
            description: ''
          },
          // TODO remove comments
          // requiresAuth: true,
          // moduleAuth: true,
          acl: {
            domain: 'associationsDetails',
            route: 'associationsDetails',
            permissions: 'list',
            module: 'associationsDetails'
          }
        },
        component: () => import('@/views/AssociationsDetails.vue')
      },
      {
        path: 'calendar',
        meta: {
          name: 'calendar',
          informativeTip: {
            title: 'عنوان المعلومه ',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true
        },
        component: () => import('@/views/Calendar.vue')
      },
      {
        path: 'tasks',
        name: 'tasks',
        meta: {
          name: 'tasks',
          informativeTip: {
            title: 'المهمات ',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'users',
            route: 'tasks',
            permissions: 'list',
            module: 'tasks'
          }
        },
        component: () => import('@/views/tasks.vue')
      },
      {
        path: 'requests',
        name: 'maintenance',
        meta: {
          name: 'MaintenanceRequests',
          informativeTip: {
            title: 'طلبات الصيانة',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'properties',
            route: 'requests',
            permissions: 'list',
            module: 'tasks'
          }
        },
        component: () => import('@/views/Requests.vue')
      },
      {
        path: 'complaints',
        meta: {
          name: 'complaints',
          informativeTip: {
            title: 'الشكاوي ',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true
        },
        component: () => import('@/views/complaints.vue')
      },
      {
        path: 'reports',
        name: 'reports',
        meta: {
          name: 'reports',
          informativeTip: {
            title: 'عنوان التقارير ',
            description:
              'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان '
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'users',
            route: 'reports',
            permissions: 'list',
            module: 'reports'
          }
        },
        component: () => import('@/views/reports.vue')
      },
      {
        path: 'potential-clients',
        name: 'potential-clients',
        meta: {
          name: 'potential-clients',
          informativeTip: {
            title: 'العملاء المحتملين',
            description:
              'الأشخاص الذين قد يصبحون عملاء في المستقبل. يمكن أن يكونوا مهتمين بمنتجاتك أو خدماتك، ولكن لم يتخذوا قرارًا نهائيًا بالشراء بعد. في السياق التجاري، يعتبر العملاء المحتملين هدفًا هامًا للتسويق والمبيعات، حيث يمكن تحويلهم إلى عملاء فعليين من خلال استراتيجيات ملائمة.'
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'users',
            route: 'potential-clients',
            // route: 'clients',
            permissions: 'list',
            // module: 'potential-clients'
            module: 'clients'
          }
        },
        component: () => import('@/views/PotentialClints.vue')
      },
      {
        path: 'price-quotations',
        name: 'price-quotations',
        meta: {
          name: 'price-offers',
          informativeTip: {
            title: 'عروض الإسعار',
            description: 'عروض الإسعار'
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'realestates',
            route: 'quotations',
            permissions: 'list',
            module: 'quotations'
          }
        },
        component: () => import('@/views/PriceOffers.vue')
      },
      {
        path: 'taxInvoices',
        name: 'taxInvoices',
        meta: {
          name: 'taxInvoices',
          informativeTip: {
            title: 'الفواتير الضريبيه',
            description:
              'يتم اضافة الفواتير الضريبية اتوماتيكيا بحالة مسودة بعد إنشاء عقد التأجبر ويكون تغير الحالة الى ملغية او سارية متاح بعد ذلك لمالك الحساب فقط'
          },
          requiresAuth: true,
          moduleAuth: true,
          acl: {
            domain: 'properties',
            route: 'taxInvoices',
            permissions: 'list',
            module: 'taxBills'
          }
        },
        component: () => import('@/views/taxInvoices.vue')
      },
      {
        path: 'smsMessages',
        meta: {
          name: 'smsMessages',
          informativeTip: {
            title: 'الرسائل النصية',
            description:
              'يتم إرسال رسائل نصية اوتوماتيكيا للعميل ومن باقي العملاء'
          },
          requiresAuth: true
        },
        component: () => import('@/views/smsMessages.vue')
      },
      {
        path: '/upgrade',
        name: 'upgrade',
        component: () => import('@/components/helper/upgradeView.vue')
      },
      {
        path: '/export-data',
        name: 'export-data',
        component: () => import('@/views/exportData.vue')
      }
    ]
  },
  {
    path: '/view-pdf',
    name: 'viewPdf',
    component: () => import('@/layouts/ViewPdf.vue')
  }
]
