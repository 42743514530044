<template>
  <v-row class="mt-6">
    <!-------------Loader---------------->
    <Loader v-bind="loaderProps" v-if="statsLoading" />
    <template v-else>
      <ClientDetailsTile
        :final="true"
        :key="index"
        :stat="stat"
        :userType="userType"
        :clientId="clientId"
        data-test="clientDetailsTile"
        v-for="(stat, index) in stats"
        :class="generateTileClass(index)"
      />
    </template>
  </v-row>
</template>

<script>
import ClientDetailsTile from '@/components/details/ClientDetailsTile'
import { statsService } from '@/services'
import { mapState, mapMutations } from 'vuex'
import Loader from '@/components/helper/loader.vue'

export default {
  name: 'ClientDetailsTiles',
  components: { ClientDetailsTile, Loader },
  props: {
    realestateOwner: {
      type: String
    },
    clientId: {
      type: String
    },
    userType: {
      type: String
    },
    owner: {
      type: String
    },
    fromDetails: {
      type: Boolean,
      default: false
    },
    maintenanceRequests: {
      type: Boolean,
      default: false
    },
    refreshState: {
      type: Number,
      default: 0
    },
    datesQuery: {
      default() {
        return {}
      }
    }
  },
  data: () => ({
    stats: [],
    statsLoading: false,
    clientRoles: {
      renter: 'tenet',
      owner: 'owner'
    }
  }),
  computed: {
    ...mapState('accountState', ['currentEnv']),
    statsCardsBody() {
      return {
        environment: this.currentEnv?._id,
        startDate: this.datesQuery.startDate,
        endDate: this.datesQuery.endDate,
        userType: this.clientRoles[this.userType],
        apiKey: this.userType
      }
    },
    loaderProps() {
      return {
        type: 'card',
        numberOfLines: 4,
        laoderClasses: 'mx-auto'
      }
    }
  },
  watch: {
    datesQuery: {
      deep: true,
      handler() {
        if (!this.clientId) {
          this.getStats()
        }
      }
    },
    async owner() {
      await this.getOwnerRenterStats()
    },
    refreshState() {
      this.getOwnerRenterStats()
    }
  },
  async created() {
    if (this.realestateOwner) {
      await this.getStats()
    } else if (this.clientId) {
      if (this.owner === 'owner') {
        await this.getOwnerRenterStats()
      } else {
        await this.getOwnerRenterStats()
      }
    } else if (this.maintenanceRequests) {
      await this.getRequestsStats()
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    async getStats() {
      try {
        this.statsLoading = true
        const statsResponse = await statsService.getRealEstateStats({
          realEstate: this.$route.params.id,
          ...this.statsCardsBody
        })
        this.stats = statsResponse.data.stats || []
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل الاحصائيات ',
          color: 'error'
        })
      } finally {
        this.statsLoading = false
      }
    },
    async getOwnerRenterStats() {
      try {
        this.statsLoading = true
        const { data } = await statsService.getOwnerRenterStats({
          user: this.clientId,
          ...this.statsCardsBody
        })
        this.stats = data.stats || []
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل احصائيات المالك ',
          color: 'error'
        })
      } finally {
        this.statsLoading = false
      }
    },
    generateTileClass(index) {
      if (this.fromDetails && index === 0) {
        return 'pr-0'
      } else if (this.fromDetails && index === this.stats.length - 1) {
        return 'pl-0'
      }
      return ''
    },
    formatRequestsStats(data) {
      const allRequestsStat = {
        nameAr: 'كل الطلبات',
        nameEn: 'totalRequests',
        value: data.totalRequests
      }
      // TODO: we removed the totalExpenses until a futher change
      // const allExpensesStat = {
      //   nameAr: 'مجموع المصروفات',
      //   value: data.totalExpenses
      // }
      const requestStats = data.stats.map((stat) => {
        const newStat = { nameEn: `${stat.status}Requests`, value: stat.total }
        switch (stat.status) {
          case 'new':
            newStat.nameAr = 'الطلبات الجديده'
            break
          case 'rejected':
            newStat.nameAr = 'الطلبات المرفوضة'
            break
          case 'canceled':
            newStat.nameAr = 'الطلبات الملغية'
            break
          case 'done':
            newStat.nameAr = 'الطلبات المنتهية'
            break
        }
        return newStat
      })
      this.stats = [allRequestsStat, ...requestStats]
    },
    async getRequestsStats() {
      try {
        const { data } = await statsService.getRequestsStats(
          this.currentEnv._id
        )
        this.formatRequestsStats(data)
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل احصائيات طلبات الصيانه',
          color: 'error'
        })
      }
    }
  }
}
</script>
