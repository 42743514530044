<template>
  <v-dialog
    max-width="680px"
    class="associations__modal"
    persistent
    :value="dialogState"
  >
    <!-------------------------------------->
    <!-------------Modal-------------------->
    <!-------------------------------------->

    <v-card class="add-new-property pa-0 add__customer__popup">
      <!-------------------------------------->
      <!-------------Head modal--------------->
      <!-------------------------------------->

      <v-card-title
        class="py-0 pr-4 pl-0 mb-4 d-flex justify-space-between pb-1 mobile__py__6px mobile__px__15px"
      >
        <!------------------Title-------------------->
        <h4 class="ma-0" v-text="title" />

        <!-------------Close modal-------------------->
        <v-btn
          @click.prevent="closeModal"
          data-test="closeClientModal"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" @submit.prevent="submit" v-model="isValid">
        <!-------------------------------------->
        <!-------------Details modal------------>
        <!-------------------------------------->
        <v-card-text class="px-5 pb-0">
          <v-row no-gutters>
            <!-- Request name -->
            <v-col class="pl-3" cols="12" sm="6">
              <h6 class="mb-2 font-12 font-weight-bold black--text">
                اسم الطلب
              </h6>
              <v-text-field
                v-model="newExpense.name"
                :rules="rules.requestName"
                dense
                filled
                placeholder="إسم الطلب"
                required
                class="font-14"
              />
            </v-col>

            <!-- Request type -->
            <v-col cols="12" sm="6">
              <h6 class="mb-2 font-12 font-weight-bold black--text">النوع</h6>
              <v-select
                v-model="newExpense.type"
                :items="requestTypes"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="rules.requestType"
                append-icon="mdi-chevron-down"
                color="black"
                dense
                filled
                item-text="nameAr"
                item-value="nameEn"
                placeholder="نوع الطلب"
                required
                class="font-14"
              ></v-select>
            </v-col>

            <!-- Quantity -->
            <v-col cols="12" sm="3" class="pl-3">
              <h6 class="mb-2 font-12 font-weight-bold black--text">الكمية</h6>
              <v-text-field
                v-model.number="newExpense.quantity"
                :rules="rules.requestQuantity"
                dense
                filled
                min="1"
                placeholder="كمية الطلب"
                required
                class="font-14"
                @input="onInputHandle($event, 'quantity')"
                @keydown="$formatNumberInputs"
              />
            </v-col>

            <!-- Unit price -->
            <v-col cols="12" sm="3" class="pl-3">
              <h6 class="mb-2 font-12 font-weight-bold black--text">
                سعر الوحدة
              </h6>
              <v-text-field
                v-model="priceMask"
                dense
                filled
                min="1"
                placeholder="ادخل سعر الوحدة"
                required
                class="font-14"
                @input="handleValuesChanging($event, priceKeys)"
                @keydown="$formatNumberInputs($event, true)"
              />
            </v-col>

            <!-- Unit price -->
            <v-col cols="12" sm="6">
              <h6 class="mb-2 font-12 font-weight-bold black--text">القيمة</h6>
              <v-text-field
                :value="valueBeforeTax"
                dense
                filled
                min="1"
                placeholder="ادخل القيمة الكليه"
                required
                class="font-14"
                readonly
              />
            </v-col>

            <!-- Tax amount -->
            <v-col class="pl-3" cols="12" sm="6">
              <div class="d-flex justify-space-between">
                <h6
                  class="mb-2 font-12 font-weight-bold black--text"
                  style="white-space: nowrap"
                >
                  قيمة الضريبة (15%)
                </h6>
                <v-switch
                  class="my-auto"
                  dense
                  hide-details="auto"
                  inset
                  small
                  v-model="toggleTaxAmount"
                />
              </div>
              <v-text-field
                data-test="bond-description"
                class="mb-0 pa-0"
                height="44"
                filled
                :value="taxAmount | formatNumber"
                placeholder="قيمة الضريبة المضافة"
                dense
                readonly
                hide-details
              />
            </v-col>

            <!-- TOTAL -->
            <v-col class="pl-3" cols="12" sm="6">
              <h6 class="mb-2 font-12 font-weight-bold black--text">
                المجموع الكلي
              </h6>
              <v-text-field
                data-test="bond-description"
                class="mb-0 pa-0"
                height="44"
                filled
                :value="totalValue"
                placeholder="المجموع الكلي"
                dense
                hide-details
                readonly
                min="1"
              />
            </v-col>

            <!-- attachment -->
            <v-col cols="12" class="pb-0">
              <h6 class="mb-2 font-12 font-weight-bold black--text">
                المرفقات
              </h6>
              <div
                v-if="!attachment.image"
                @drop.prevent="addExpenssesAttachement($event)"
                @dragleave.prevent="dragLeave('pic')"
                @dragover.prevent="dragOver('pic')"
                @dragenter.prevent
                ref="pic"
                class="pic-wrapper d-flex align-center w-100 pa-6 my-4 flex-wrap"
              >
                <div>
                  <v-btn
                    @click="$refs.inputUpload.click()"
                    class="py-2 px-3 rounded-7 font-weight-bold"
                    color="general"
                    outlined
                  >
                    <v-icon color="general"> mdi-plus </v-icon>
                    <span class="font-12">إرفاق الملف</span>
                  </v-btn>
                  <input
                    @change="addExpenssesAttachement($event)"
                    accept="image/*"
                    multiple
                    ref="inputUpload"
                    type="file"
                    v-show="false"
                  />
                </div>
                <div :class="{ 'mr-3': !xs }">
                  <h4 class="font-12">
                    اسحب الصوره هنا لرفعها أو اضغط على زر إرفاق الملف
                  </h4>
                </div>
              </div>
              <div v-else class="position-relative add-pic">
                <img :src="attachment.image" class="add-pic" loading="lazy" />
                <div
                  v-if="!attachment.imageIsLoading"
                  class="lds-ring-container top-layer"
                >
                  <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>

                <v-icon
                  @click="deleteAttachment"
                  class="pic-icon position-absolute slide-y-transition"
                  color="white"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <!------------------------------>
        <!-------------Submit----------->
        <!------------------------------>
        <v-card-actions
          class="pa-1 mt-4 add__customer__action border-0"
          style="border: none"
        >
          <v-spacer></v-spacer>
          <v-btn
            :loading="isLoading"
            type="submit"
            class="ma-0 rounded-7 my-3 ml-3 px-5"
            color="primary"
          >
            <span class="mb-1 mobile__mb__0 ml-1 font-weight-bold font-12"
              >إضافة</span
            >
            <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { contractsService } from '@/services'
import { convertNumbers2English } from '@/utils/formatters'
import inputMasking from '@/mixins/inputMasking.js'

export default {
  name: 'AddMaintenanceReqModal',
  props: {
    date: {
      type: Object,
      default: () => {}
    },
    dialogState: {
      type: Boolean,
      default: false
    }
  },
  mixins: [inputMasking],
  data() {
    return {
      title: 'إضافة طلب صرف',
      priceMask: '',
      rules: {
        requestType: [(v) => (v && !!v.length) || 'اختر نوع الطلب'],
        requestName: [(v) => (!!v && !!v.trim()) || 'ادخل اسم الطلب'],
        requestQuantity: [(v) => !!v || 'ادخل الكميه'],
        requestPrice: [(v) => !!v || 'ادخل سعر الوحدة']
      },
      requestTypes: [
        { nameAr: 'بند شراء', nameEn: 'purchase' },
        { nameAr: 'أجره عماله', nameEn: 'fees' }
      ],
      attachment: {
        id: '',
        image: '',
        key: '',
        imageIsLoading: false
      },
      newExpense: {},
      isLoading: false,
      isValid: false,
      toggleTaxAmount: false
    }
  },
  filters: {
    formatNumber(value) {
      return value && value.toLocaleString('en-US')
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    priceKeys() {
      return {
        maskValKey: 'priceMask',
        parentKey: 'newExpense',
        actualValKey: 'price'
      }
    },

    xs() {
      return this.$vuetify.breakpoint.xs
    },
    taxAmount() {
      return this.valueBeforeTax && this.toggleTaxAmount
        ? this.valueBeforeTax.replace(/[^0-9]/g, '') * 0.15
        : 0
    },
    totalValue() {
      const totalValue = (
        this.newExpense.price * this.newExpense.quantity +
        this.taxAmount
      ).toLocaleString('en-US')
      return this.taxAmount ? totalValue : this.valueBeforeTax
    },
    valueBeforeTax() {
      const totaValueBeforeTax =
        this.newExpense.price * this.newExpense.quantity
      return isNaN(totaValueBeforeTax)
        ? 0
        : totaValueBeforeTax.toLocaleString('en-US')
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    submit() {
      this.$refs.form.validate()
      if (this.isValid) {
        this.isLoading = true
        this.$emit('addExpense', {
          ...this.newExpense,
          taxAmount: this.taxAmount
        })
      }
    },
    onInputHandle(e, key) {
      this.newExpense[key] = Number(convertNumbers2English(e))
    },
    // For Attachments
    addExpenssesAttachement(e) {
      const files = e.dataTransfer ? e.dataTransfer.files : e.target.files
      if (files.length > 1) {
        this.addNotification({
          text: 'لا يمكن اضافه اكثر من صورة',
          color: 'error'
        })
      } else {
        const file = files[0]
        const reader = new FileReader()
        reader.onload = () => {
          this.attachment.image = reader.result
        }
        reader.readAsDataURL(file)
        this.uploadExpenssesAttachement(file)
      }
    },
    async uploadExpenssesAttachement(file) {
      const fd = new FormData()
      fd.append('attachment', file, file.name)
      fd.append('entityType', 'expense')
      fd.append('envId', this.currentEnv._id)
      const { data } = await contractsService.addAttachment(fd, this.attachment)
      this.$set(this.attachment, 'file', data.Location)
      this.$set(this.attachment, 'id', data.id)
      this.$set(this.attachment, 'imageIsLoading', true)
    },
    async deleteAttachment() {
      try {
        await contractsService.deleteAttachment(this.attachment.id)
        this.$set(this.attachment, 'progress', 0)
        this.$set(this.attachment, 'file', '')
        this.$set(this.attachment, 'id', '')
        this.$set(this.attachment, 'image', '')
        this.$set(this.attachment, 'imageIsLoading', false)
        this.addNotification({
          text: 'تم الحذف بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في حذف المرفق، يرجى المحاوله مجددا',
          color: 'error'
        })
      }
    },
    //
    closeModal() {
      this.$emit('close-client-dialog', 'close')
      this.$refs.form.reset()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/associations/associations.scss';

.text-black {
  color: black !important;
}
.v-select.v-autocomplete >>> .v-select__slot {
  padding: 0.3125rem 0.625rem;
}
</style>
