<template>
  <v-dialog v-model="AddNewNotiDialog_" persistent max-width="490">
    <v-card class="add-new-property">
      <v-btn
        fab
        text
        color="grey"
        class="ma-2 position-absolute"
        style="left: 0"
        @click.prevent="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="text-center pt-16 pb-10">
        <AnimatedCheckmark type="check"></AnimatedCheckmark>
        <template v-if="addAnotherUnit">
          <h4 class="green--text">تمت إضافة الوحدة بنجاح</h4>
          <p class="">
            الآن يمكنك إضافة المزيد من الوحدات لعقارك،<br />
            اضغط على الزر بالأسفل لإضافة وحدة أخرى
          </p>
          <v-btn
            x-large
            rounded
            min-width="300"
            class="ma-0"
            color="success"
            @click="addNewUnit(parentPropertyId)"
          >
            <v-icon class="send-icon ml-2">mdi-plus</v-icon>
            <span class="tf">اضف وحدة أخرى</span>
          </v-btn>
        </template>

        <template v-else-if="hasUnits">
          <h4 class="green--text">تمت إضافة عقارك بنجاح</h4>
          <p class="">
            الآن يمكنك إضافة وحدات للعقار الذي اضفته للتو، انتقل لصفحة العقار
            وابدأ بإضافة الوحدات
          </p>
          <v-btn
            x-large
            rounded
            min-width="300"
            class="ma-0"
            color="success"
            @click="goToProperty(parentPropertyId)"
          >
            <v-icon class="send-icon ml-2">mdi-plus</v-icon>
            <span class="tf">اضف وحدة للعقار</span>
          </v-btn>
        </template>

        <template v-else>
          <h4 class="green--text">تمت إضافة عقارك بنجاح</h4>
          <p class="">
            يمكنك الآن إضافة المزيد من العقارات، اضغط بالأسفل<br />لإضافة عقار
            جديد
          </p>
          <v-btn
            x-large
            rounded
            min-width="300"
            class="ma-0"
            color="success"
            @click="addAnotherProperty"
          >
            <v-icon class="send-icon ml-2">mdi-plus</v-icon>
            <span class="tf">اضف عقارا اخر</span>
          </v-btn>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import '@/styles/material-dashboard/_add-new-property.scss'
import AnimatedCheckmark from '@/components/helper/AnimatedCheckmark'

export default {
  props: ['AddNewNotiDialog', 'hasUnits', 'addAnotherUnit', 'parentPropertyId'],
  components: {
    AnimatedCheckmark
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog')
    },
    addNewUnit(id) {
      this.closeDialog()
      this.$emit('add-new-unit', id)
    },
    addAnotherProperty() {
      this.closeDialog()
      this.$emit('add-another-property')
    },
    goToProperty(parentId) {
      this.$router.push({
        name: 'singleProperty',
        params: { id: parentId, addNewUnit: 1 }
      })
    }
  },
  computed: {
    AddNewNotiDialog_: {
      get() {
        return this.AddNewNotiDialog
      },
      set(value) {
        this.$emit('changeAddNewNotiDialog', value)
      }
    }
  }
}
</script>
