<template>
  <v-card class="pa-3 pa-sm-4 d-flex rounded-15">
    <v-sheet
      class="d-flex flex-column flex-sm-row justify-start align-center pa-0 w-100"
    >
      <div v-if="!xs">
        <v-checkbox class="ma-0" color="rib" v-model="checkbox"></v-checkbox>
      </div>
      <!--carousel-->
      <v-carousel
        class="sync-card"
        height="140"
        hide-delimiter-background
        show-arrows-on-hover
        v-model="model"
      >
        <v-carousel-item v-for="color in colors" :key="color">
          <div
            style="
              background: url('https://images.unsplash.com/photo-1582407947304-fd86f028f716?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1459&q=80');
              height: 140px;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            "
          ></div>
        </v-carousel-item>
      </v-carousel>
      <!--end carousel-->
      <div class="pt-3 pt-sm-0 pr-4 w-100">
        <div class="d-flex justify-space-between align-center">
          <div>
            <h6 class="text-light font-weight-light ma-0">نوع العقار</h6>
            <h5 class="ma-0">شقة</h5>
          </div>
          <div>
            <h6 class="text-light font-weight-light ma-0">الحالة</h6>
            <h5 class="ma-0 tag green--tag text-center pb-1">تم الإيجار</h5>
          </div>
        </div>
        <div class="my-2">
          <h6 class="text-light font-weight-light ma-0">العنوان</h6>
          <h5 style="max-width: 140px" class="ma-0 text-truncate">
            شقة للإيجار بحي الرياض
          </h5>
        </div>
        <div>
          <h6 class="text-light font-weight-light ma-0">تاريخ الاضافه</h6>
          <h5 class="ma-0">12 مارس 2020</h5>
        </div>
      </div>

      <div class="w-100 px-3 mt-3 pt-2 border-top mb-n4" v-if="xs">
        <v-checkbox class="ma-0" color="rib" v-model="checkbox"></v-checkbox>
      </div>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  name: 'syncItem',
  data: () => ({
    model: 0,
    colors: ['primary', 'secondary', 'tertiary', 'error', 'success'],
    checkbox: false
  }),
  computed: {
    xs() {
      return this.$vuetify.breakpoint.xs
    }
  }
}
</script>

<style lang="scss">
.v-window__prev,
.v-window__next {
  background: unset !important;
  font-size: 12px !important;
}

.v-carousel__controls__item {
  margin: 0 !important;

  i {
    font-size: 10px !important;
  }
}
</style>
