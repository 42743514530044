<template>
  <div v-if="files.length">
    <div v-if="isBankAccounts" class="w-100">
      <slot name="bankAccountsContent" />
    </div>
    <div v-else>
      <div
        class="d-flex justify-space-between my-1"
        v-for="(file, index) in files"
        :key="index"
      >
        <SmsMessageCard v-if="isSmsMessages" :message="file" />
        <button
          @click.capture="handleOpenItem(file)"
          @click.right="handleOpenItem(file)"
          v-else-if="!showDocuments"
        >
          <router-link
            :to="{
              path: `${itemType}`,
              query: {
                visible: true,
                backToCurrentPath: true
              }
            }"
            class="black--text d-flex mb-4 flex-grow-0"
          >
            <v-img
              :width="icon.width"
              :height="icon.height"
              :src="icon.src"
              alt=""
              class="mt-2"
            />
            <div class="mr-3">
              <h5 v-if="isTaxType" class="text-right font-weight-medium ma-0">
                {{ file.realEstate.name }} - {{ file.owner.name }}
              </h5>
              <h5
                v-else
                class="text-right font-weight-medium ma-0 text-truncate max-w-300"
              >
                {{
                  file.name ||
                  file.bondAddress ||
                  file.title ||
                  file.body ||
                  'بلا عنوان'
                }}
              </h5>
              <div>
                <h6 class="text-gray ma-0 font-12" v-if="file.lastModifiedDate">
                  {{ formatDate(file.lastModifiedDate) }}
                </h6>
                <h6 class="text-gray ma-0 font-12" v-if="file.lastMessage">
                  {{ file.lastMessage }}
                </h6>
              </div>
              <div class="d-flex align-center">
                <h6 class="text-gray ma-0 font-12 ml-2">
                  {{ generateFileDate(file) }}
                </h6>

                <h6
                  class="text-gray ma-0 font-12"
                  v-if="generateFileEndDate(file)"
                >
                  <span class="ml-2"> - </span>
                  {{ generateFileEndDate(file) }}
                </h6>
                <!---------------------- For Bonds Status ---------------------->
                <div class="table__tags__div mr-2" v-if="isBondType">
                  <div
                    class="red--tag mb-1 tabel-tag text-center font-12 d-flex align-center"
                    v-if="file.terminated.isTerminated"
                  >
                    <span class="mb-1 text-truncate font-12">ملغي</span>
                  </div>
                  <div
                    class="green--tag mb-1 tabel-tag text-center font-12 d-flex align-center"
                    v-else
                  >
                    <span class="mb-1 text-truncate font-12">ساري</span>
                  </div>
                </div>
                <!---------------------- For Contract Status ---------------------->
                <div class="table__tags__div mr-2" v-if="isContractType">
                  <div
                    class="red--tag mb-1 tabel-tag text-center font-12 d-flex align-center"
                    :class="contractsStatuses[file?.status]?.tagColor"
                  >
                    <span class="mb-1 text-truncate font-12">
                      {{ contractsStatuses[file?.status]?.nameAr }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </button>
        <div v-else class="d-flex justify-space-between document-data">
          <a
            class="black--text d-flex mb-4 flex-grow-0"
            ref="attachment"
            :href="file.url || file.Location"
            :target="fileIconAttributes.target"
            :download="fileIconAttributes.download"
          >
            <v-img
              :width="icon.width"
              :height="icon.height"
              :src="icon.src"
              alt=""
              class="mt-2"
            />
            <div class="mr-3">
              <h5 class="font-weight-medium ma-0">
                {{ `وثيقه ${index + 1}` }}
              </h5>
              <h6 class="text-gray ma-0 font-12" v-if="file.createdAt">
                {{ formatDate(file.createdAt) }}
              </h6>
            </div>
          </a>
          <v-btn
            v-if="!fromSideDetails"
            x-small
            fab
            elevation="1"
            class="delete-document-btn ml-n1"
            @click="deleteDocument(file._id || file.id, index)"
          >
            <v-img
              max-width="16"
              max-height="16"
              :src="deleteDocumentIcon"
            ></v-img>
          </v-btn>
          <div v-else class="d-flex align-center ml-1">
            <v-img
              class="cursor ml-5"
              @click="downloadAttachment(index)"
              width="16"
              height="16"
              :src="downloadIcon"
            ></v-img>
            <v-img
              class="cursor"
              height="16"
              width="16"
              :src="deleteDocumentSideDetails"
              @click="deleteDocument(file._id || file.id, index)"
            ></v-img>
          </div>
        </div>
      </div>
    </div>
    <div v-intersect="endIntersect"></div>
  </div>
  <div class="text-center" v-else>
    <v-sheet color="#FAFAFA" class="rounded-15 pa-3 mb-4">
      <span> ليست هناك اي مرفقات </span>
    </v-sheet>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import SmsMessageCard from '@/components/messages/SmsMessageCard'
import contractsSharedData from '@/mixins/contractsSharedData'
import { mapGetters } from 'vuex'

export default {
  name: 'ClientDetailsFileList',
  components: { SmsMessageCard },
  mixins: [dateFormat, contractsSharedData],

  props: {
    files: {},
    isBankAccounts: {
      default: false,
      type: Boolean
    },
    icon: {
      default() {
        return {
          src: require('@/assets/img/svgs/contracts.svg'),
          width: 25,
          height: 25
        }
      }
    },
    itemType: {
      type: String
    },
    showDocuments: {
      type: Boolean,
      default: false
    },
    fromSideDetails: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tempSidedetails: true,
      deleteDocumentIcon: require('@/assets/img/svgs/messages/delete-conversation.svg'),
      deleteDocumentSideDetails: require('@/assets/img/svgs/delete-icon-grey.svg'),
      downloadIcon: require('@/assets/img/svgs/download-document.svg')
    }
  },
  computed: {
    ...mapGetters('accountState', ['contractsStatuses']),
    isTaxType() {
      return !!this.itemType?.includes('taxInvoices')
    },
    isSmsMessages() {
      return !!this.itemType?.includes('sms')
    },
    isContractType() {
      return !!this.itemType?.includes('contracts')
    },
    isBondType() {
      return !!this.itemType?.includes('bonds')
    },
    fileIconAttributes() {
      return {
        target: '_blank',
        download: !!this.fromSideDetails
      }
    }
  },
  methods: {
    handleOpenItem(file) {
      localStorage.setItem('sideDetailsItem', JSON.stringify(file))
    },
    endIntersect(isIntersecting) {
      if (isIntersecting[0].isIntersecting) {
        this.$emit('nextPageTrigger')
      }
    },
    deleteDocument(fileId, fileIndex) {
      this.$emit('hide-task')
      this.$root.$confirm
        .open({
          title: 'حذف وثيقه',
          message: 'هل أنت متأكد من حذف هذه الوثيقه؟',
          options: {
            color: 'red'
          }
        })
        .then((confirm) => {
          if (confirm) {
            this.$emit('delete-document', {
              fileId, // ! fix: it always delete the first file
              fileIndex
            })
          } else {
            this.$emit('cancel-delete-document')
          }
        })
    },
    generateFileDate(file) {
      if (this.isTaxType) {
        return this.formatDate(file.dateFrom)
      } else if (this.isContractType) {
        return this.formatDate(file.start)
      }
      return this.formatDate(file.createdAt || file.sentAt)
    },
    generateFileEndDate(file) {
      if (this.isBondType) {
        return ''
      } else if (this.isTaxType) {
        return this.formatDate(file.dateTo)
      } else if (this.isContractType) {
        return this.formatDate(file.end)
      }
      return this.formatDate(file.end || file.dateTo)
    },
    downloadAttachment(index) {
      this.$refs.attachment[index].click()
    }
  }
}
</script>

<style lang="scss" scoped>
.document-data {
  width: 100% !important;
  .delete-document-btn {
    margin-top: -1px;
  }
}
</style>
