<template>
  <v-data-table
    :class="['cursor-pointer', { 'hectar-table-sm': tableSm }]"
    :headers="headers"
    :items="assoications"
    class="hectar-table clients__list__view"
    hide-default-footer
    mobile-breakpoint="0"
    @click:row="handleClick($event)"
  >
  </v-data-table>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'data-table-client',
  props: {
    assoications: {},
    headers: {},
    tableSm: {
      defaults: false
    }
  },
  computed: {
    ...mapState('accountState', ['user'])
  },
  methods: {
    handleClick(item) {
      this.$router.push(`/dashboard/client/${item._id}`)
    }
  }
}
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
