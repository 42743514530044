<template>
  <v-layout flex-wrap class="sidedetail__header align-center">
    <v-layout class="flex-wrap py-4">
      <v-img
        :lazy-src="exitArrow"
        max-height="15"
        max-width="15"
        :src="exitArrow"
        class="mt-3 me-4"
        @click="$emit('closeDetails')"
      ></v-img>

      <div class="mr-4 mobile__mr__0 ml-3 d-flex">
        <h5 class="ma-0 font-20 font-weight-bold">عرض سعر تأجير</h5>
        <h5 class="ma-0 font-20 font-weight-bold mx-1">
          {{ quotation?.realEstate?.type?.nameAr }}
        </h5>
        <h5 class="ma-0 mx-3 rounded-pill brub--tag text-center py-1 px-6">
          <span class="font-14 my-auto text-truncate"> تأجير </span>
        </h5>
        <h5
          :class="specifyColor(quotation.status).color"
          class="ma-0 tag-sm d-flex justify-center text-center py-0 pb-1"
        >
          <span class="font-14 font-weight-bold my-auto text-truncate">
            {{ specifyColor(quotation.status).nameAr }}
          </span>
        </h5>
      </div>
    </v-layout>

    <!-- Actions -->
    <Actions
      :quotation="quotation"
      @accept="$emit('accept')"
      @reject="$emit('reject')"
      @openPDF="$emit('openPDF')"
      @openOfferModel="$emit('openOfferModel')"
      @cancelled="$emit('cancelled')"
    />
  </v-layout>
</template>

<script>
import Actions from '@/components/priceOffers/sideDetails/Actions.vue'
import offerSharedData from '@/mixins/offerSharedData'

export default {
  name: 'DetailsHeader',
  props: {
    quotation: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [offerSharedData],
  components: {
    Actions
  },
  data() {
    return {
      exitArrow: require('@/assets/img/svgs/right-arrow.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-btn.v-btn--has-bg {
  background-color: #f44336;
  padding: 0 1.4rem;
  border-radius: 10px;
}
</style>
