<template>
  <div class="partners-container">
    <div class="text-center">
      <h4 class="partners-title font-weight-medium">شركاء النجاح</h4>
      <h5 class="partners-description font-weight-medium">
        نتطلع دائما لشراكات إستراتيجية مع رواد القطاع العقاري
      </h5>
    </div>
    <v-sheet class="mx-auto my-10" elevation="0" max-width="100%">
      <v-slide-group
        prev-icon="mdi-arrow-left"
        next-icon="mdi-arrow-right"
        v-model="model"
        class="pa-4"
        center-active
        show-arrows
      >
        <v-slide-item
          v-for="(logo, company) in partnersLogos"
          :key="logo"
          v-slot="{ toggle }"
        >
          <div @click="toggle" class="img-container d-flex">
            <v-img :alt="`${company} logo`" :src="logo" class="my-auto mx-10" />
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'PartnersSection',
  data() {
    return {
      model: null,
      partnersLogos: {
        theqa: require('@/assets/img/svgs/partners/theqa.png'),
        alosol: require('@/assets/img/svgs/partners/alosol.svg'),
        sian: require('@/assets/img/svgs/partners/sian.svg'),
        yader: require('@/assets/img/svgs/partners/yader.svg'),
        taqder: require('@/assets/img/svgs/partners/taqder.svg'),
        alammar: require('@/assets/img/svgs/partners/alammar.svg'),
        teld: require('@/assets/img/svgs/partners/teld.svg'),
        fath: require('@/assets/img/svgs/partners/fath.svg'),
        attqe: require('@/assets/img/svgs/partners/attqe.png'),
        avaz: require('@/assets/img/svgs/partners/avaz.png'),
        asalm: require('@/assets/img/svgs/partners/asalm.png'),
        asar: require('@/assets/img/svgs/partners/asar.png')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.partners-container {
  padding-top: 74px;
  padding-bottom: 75px;
  background: white;
  width: 90%;
  margin: auto;
  .partners-title {
    font-size: 30px !important;
  }
  .partners-description {
    font-size: 16px !important;
    margin-bottom: 74px !important;
    color: #3e3e3e;
  }

  .img-container {
    img {
      max-width: 230px;
    }
  }
}
</style>
