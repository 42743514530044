<template>
  <div
    :class="[
      'w-100',
      'd-flex flex-column mb-2 sms-card px-2 py-4',
      containerClass
    ]"
    class="px-5"
  >
    <div class="mr-2 mt-1" v-if="!innerPage || xs">
      <p class="text--808080cc ma-0 font-12">
        {{ messageDateTime }}
      </p>
    </div>
    <!-- ICON AND TITLE -->
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <span
          class="font-weight-bold ma-0 text--7938AA ml-2 text-truncate"
          :class="[innerPage ? 'font-18' : 'font-14']"
        >
          {{ message.title }}
        </span>
        <div
          v-if="message?.sms?.reSentTimes"
          class="font-weight-medium font-16 bg--662D911A rounded-circle px-2 py-1"
        >
          <p class="ma-0 font-12 text--7938AA font-weight-bold">
            {{ message?.sms?.reSentTimes }}
          </p>
        </div>
        <div class="mr-2 mt-1" v-if="innerPage && !xs">
          <p class="text--808080cc ma-0 font-12">
            {{ messageDateTime }}
          </p>
        </div>
      </div>

      <!-- MENU INCLUDES ALL ACTIONS -->
      <v-menu
        v-if="!isCustomer"
        content-class="dropdown-menu"
        transition="slide-y-transition"
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            class="ma-0 px-0 options-btn dots-btn"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="px-0 mx-0" color="#000">mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-1">
          <!--  -->
          <v-list-item
            v-for="item in items"
            :key="item.title"
            @click="handler(item.method)"
          >
            <img :src="item.icon" />
            <v-list-item-content class="mr-2">
              <v-list-item-title class="black--text" v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-if="innerPage">
      <p class="font-14 my-2 ml-8 ma-0 font-weight-bold">
        {{ message?.body ?? '' }}
      </p>
    </div>
    <div class="d-flex align-center mb-2" v-if="innerPage">
      <p class="text--3E3E3E font-weight-normal ma-0 mt-1 font-14">
        طريقة الإرسال
      </p>
      <div
        class="d-flex align-center pointerCursor"
        v-for="(sendMethod, i) in message.sendMethods"
        :key="i"
      >
        <h6 class="ma-0 text--3E3E3E px-2 pt-1 font-weight-medium">
          {{ sendMethodHandler(sendMethod) }}
        </h6>
        <p class="ma-0" v-if="i != message.sendMethods.length - 1">-</p>
      </div>
    </div>
    <!-- MESSAGE PDF -->
    <!-- TODO: after the ingration with back we should put it back  -->
    <div class="d-flex" v-if="message?.link">
      <a
        :href="message.link"
        target="_blank"
        class="text-decoration-underlaine text--7938AA mx-3 ma-0 mt-1 font-14 pointerCursor"
      >
        تحميل الخطاب
      </a>
    </div>
    <div class="d-flex" v-else-if="!message?.link && !isCustomer">
      <p
        class="text-decoration-underlaine text--7938AA ma-0 mt-1 font-14 pointerCursor"
      >
        لم يتم إرسال خطاب مع الإشعار
      </p>
    </div>
  </div>
</template>

<script>
import { messageService } from '@/services'
import { mapState, mapMutations, mapGetters } from 'vuex'
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'SmsMessageCard',
  mixins: [dateFormat],
  props: {
    message: {
      type: Object,
      required: true
    },
    innerPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pdfIcon: require('@/assets/img/svgs/pdf-white.svg'),
      smsMessagePDF: require('@/assets/img/svgs/pdf-file-icon.svg'),
      items: [
        {
          title: 'إعادة إرسال',
          icon: require('@/assets/img/svgs/send1.svg'),
          method: 'resendMessage'
        }
      ],
      isResendLoading: false
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    containerClass() {
      return this.innerPage ? 'my-3 sms-message-container' : 'position-relative'
    },
    messageDate() {
      return this.formatDate(this.message?.updatedAt)
    },
    messageTime() {
      let messageHour = new Date(this.message?.updatedAt).getHours()
      let messageMinutes = new Date(this.message?.updatedAt).getMinutes()
      messageMinutes = (messageMinutes < 10 ? '0' : '') + messageMinutes
      const pmAm = messageHour >= 12 ? 'م' : 'ص'
      messageHour %= 12
      messageHour = messageHour ? messageHour : 12
      return `${messageHour}:${messageMinutes} ${pmAm}`
    },
    messageDateTime() {
      return `${this.messageTime} - ${this.messageDate}`
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    handler(method) {
      this[method]()
    },
    sendMethodHandler(method) {
      return method === 'sms' ? ' رساله SMS' : 'عبر البوابه'
    },
    async resendMessage() {
      try {
        this.isResendLoading = true
        const body = {
          notification: this.message._id,
          environment: this.currentEnv._id
        }
        await messageService.resendSmsMessage(body)
        this.$root.$confirm.open({
          title: 'تم إرسال الرسالة النصية بنجاح',
          cancelButtonStatus: false,
          confirmTxt: 'تم',
          img: {
            path: require('@/assets/img/svgs/sms-success.svg'),
            width: 58,
            height: 59
          }
        })
      } catch {
        this.addNotification({
          text: 'خطأ في إعادة إرسال الرساله النصية، يرجى المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.isResendLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sms-card {
  background-color: #f8f8f8;
  border-radius: 10px;
}

.sms-message-container {
  background-color: white;
  border: 1px solid #eff2f7;
  border-radius: 10px;
}

.sms-message-border {
  border-bottom: 2px solid #efebf2;
}

.sms-message-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: white;
}

.message-date {
  font-size: 12px;
  color: rgba(grey, 0.8);
  line-height: 1;
}

.download:hover {
  text-decoration: underline;
}

.v-list-item {
  min-height: 36px !important;
}

.message-text {
  width: 95%;
  text-align: justify;
  color: rgba(black, 0.8);
}
.message-tag {
  background-color: white;
  padding: 8px;
  border-radius: 15px;
  margin-left: 4px;
}
.min-height-45 {
  min-height: 45px;
}
.text-decoration-underlaine {
  text-decoration: underline;
}
</style>
