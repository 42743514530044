<template>
  <v-row class="mb-4 mt-2 py-4 cardContainer" no-gutters v-if="cardsInfoState">
    <!-------------Loader---------------->
    <Loader v-bind="loaderProps" v-if="statsLoading" />
    <template v-else>
      <ClientDetailsTile
        data-test="detailsTile"
        :class="realestates ? 'mx-1' : 'ml-3'"
        v-for="(stat, index) in filterdStats"
        :key="index"
        v-bind="generateDetailsTileProps(stat)"
      />
    </template>
  </v-row>
</template>
<script>
import ClientDetailsTile from '../details/ClientDetailsTile'
import { statsService } from '@/services'
import { bus } from '@/helpers/eventBus'
import { mapMutations, mapState, mapGetters } from 'vuex'
import Loader from '@/components/helper/loader.vue'

export default {
  name: 'realEstatStat',
  components: { ClientDetailsTile, Loader },
  props: {
    cardsInfoState: {
      type: Boolean,
      default: true
    },
    statesType: {
      default: 'realestates'
    },
    datesQuery: {
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('currentEnv')),
      stats: [],
      statsLoading: false
    }
  },
  watch: {
    datesQuery: {
      deep: true,
      handler() {
        this.loadStates(this.statesType)
      }
    },
    customerDefaultRole() {
      this.handleGetRealEstateStats()
    }
  },
  created() {
    this.loadStates(this.statesType)

    bus.$on('buildingOrCompoundAdded', () => {
      this.getRealEstateStats()
    })
  },
  computed: {
    loaderProps() {
      return {
        type: 'card',
        numberOfLines: 4,
        laoderClasses: 'mx-auto'
      }
    },
    filterdStats() {
      const pmContracts = this.stats.find((state) => {
        return state.nameEn === 'pmContracts'
      })
      return pmContracts && pmContracts.value === 0
        ? this.stats.filter((state) => state.nameEn !== 'receipts')
        : this.stats
    },
    ...mapState('accountState', ['user', 'customerDefaultRole']),
    ...mapGetters('accountState', ['isCustomer'])
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    loadStates(statesType) {
      switch (statesType) {
        case 'realestates':
          return this.handleGetRealEstateStats()
        case 'clients':
          return this.getClientStats()
        case 'maintenance':
          return this.getMaintenances()
        case 'tasks':
          return this.getTaskStats()
        case 'requests':
          return this.getReqStats()
        default:
          return ''
      }
    },
    generateDetailsTileProps(stat) {
      return {
        final: true,
        stat
      }
    },
    async getReqStats() {
      try {
        this.statsLoading = true
        const {
          data: { stats }
        } = await statsService.getRequestStats(this.userData._id)
        this.stats = stats || []
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل احصائيات الطلبات',
          color: 'error'
        })
      } finally {
        this.statsLoading = false
      }
    },
    async getRealEstateStats() {
      try {
        this.statsLoading = true
        const statsResponse = await statsService.getRealEstateStat({
          environment: this.userData?._id,
          ...(this.datesQuery.startDate && {
            startDate: this.datesQuery.startDate
          }),
          ...(this.datesQuery.endDate && {
            endDate: this.datesQuery.endDate
          }),
          ...(this.isCustomer && {
            user: this.user._id,
            userType: 'owner'
          })
        })
        const RealEstateforPropertyStats =
          await statsService.getRealEstateforPropertyStats({
            environment: this.userData?._id,
            ...(this.datesQuery.startDate && {
              startDate: this.datesQuery.startDate
            }),
            ...(this.datesQuery.endDate && {
              endDate: this.datesQuery.endDate
            }),
            ...(this.isCustomer && {
              user: this.user._id,
              userType: 'owner'
            })
          })
        this.stats =
          [...RealEstateforPropertyStats.data, ...statsResponse.data.stats] ||
          []
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل احصائيات العقارات',
          color: 'error'
        })
      } finally {
        this.statsLoading = false
      }
    },
    async getClientStats() {
      try {
        this.statsLoading = true
        const statsResponse = await statsService.getClientStats({
          environment: this.userData._id,
          ...(this.datesQuery.startDate && {
            startDate: this.datesQuery.startDate
          }),
          ...(this.datesQuery.endDate && {
            endDate: this.datesQuery.endDate
          })
        })
        this.stats = statsResponse.data.stats || []
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل احصائيات العملاء',
          color: 'error'
        })
      } finally {
        this.statsLoading = false
      }
    },
    async getTaskStats() {
      try {
        this.statsLoading = true
        const {
          data: { stats }
        } = await statsService.getTasksStats({
          environment: this.userData._id
        })
        this.stats = stats || []
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل احصائيات المهام',
          color: 'error'
        })
      } finally {
        this.statsLoading = false
      }
    },
    async getOwnerRenterStats() {
      try {
        this.statsLoading = true
        const { data } = await statsService.getOwnerRenterStats({
          apiKey: 'renter',
          environment: this.userData._id,
          user: this.user._id,
          userType: 'tenet'
        })
        this.stats =
          data.stats?.filter((stat) => stat.nameEn !== 'openingCredit') || []
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل احصائيات المستأجر ',
          color: 'error'
        })
      } finally {
        this.statsLoading = false
      }
    },
    handleGetRealEstateStats() {
      if (this.isCustomer && this.customerDefaultRole === 'renter') {
        this.getOwnerRenterStats()
      } else {
        this.getRealEstateStats()
      }
    }
  }
}
</script>
