<template>
  <v-dialog max-width="680px" persistent :value="dialogState">
    <v-card class="add-new-property pa-0">
      <!-----------1# Header----------->
      <v-card-title
        class="py-0 px-3 mb-0 custom__shadow d-flex justify-space-between"
      >
        <!----------Title----------->
        <h4 class="my-2" v-text="notificationTitle" />

        <!----------Close----------->
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
          :disabled="submitBtnIsLoading"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-----------2# Actions----------->
      <v-container class="mb-0">
        <div>
          <div class="d-flex justify-space-between align-center mb-2">
            <h4 class="font-13">تشغيل آلي</h4>
            <v-switch
              class="ma-0"
              dense
              hide-details="auto"
              inset
              @change="syncNotificationSettings($event, 'status')"
              v-model="notificationSettings.status"
            />
          </div>
          <div v-if="isFinancial" class="mb-2 mr-2">
            <h6 class="grey--text font-13 mt-0 mb-0">
              يتم إرسال الإشعار بطريقة ألية للعميل بناءا علي خيارات الإرسال
              المفعلة
            </h6>
          </div>
          <template v-for="(notificationOpt, i) in switchButtons">
            <div
              v-if="isFinancial ? true : !notificationOpt.isOnlyFinancial"
              :key="i"
            >
              <div class="d-flex justify-space-between align-center my-2">
                <div class="d-flex align-center ma-0">
                  <v-icon size="20" class="ml-2 mt-2">
                    {{ notificationOpt.icon }}
                  </v-icon>
                  <span class="mt-1 font-13">
                    {{ notificationOpt.title }}
                  </span>
                </div>
                <v-switch
                  class="ma-0"
                  dense
                  hide-details="auto"
                  inset
                  @change="
                    syncNotificationSettings($event, notificationOpt.key)
                  "
                  v-model="notificationSettings[`${notificationOpt.key}`]"
                />
              </div>
              <div
                class="d-flex justify-space-between mt-2"
                v-if="
                  notificationSettings[`${notificationOpt.key}`] &&
                  notificationOpt?.children?.length
                "
              >
                <div
                  class="d-flex justify-space-between align-center"
                  v-for="(sign, i) in notificationOpt.children"
                  :key="i"
                >
                  <div class="d-flex align-center ma-0 mx-1 my-auto">
                    <span class="font-13 mt-1"> {{ sign.title }} </span>
                  </div>
                  <v-switch
                    class="ma-0 my-auto"
                    dense
                    hide-details="auto"
                    inset
                    v-model="notificationSettings[`${sign.key}`]"
                  />
                </div>
              </div>
            </div>
          </template>

          <v-divider
            class="my-6"
            v-if="!team && notificationSettings.category"
          />

          <v-row v-if="!team && notificationSettings.category">
            <v-col cols="12">
              <div class="d-flex justify-space-between align-center">
                <h6 class="grey--text font-13 mt-0 mb-1">
                  {{ contentTypeText }}
                </h6>
                <v-btn
                  v-if="isContentChanged"
                  fab
                  width="25"
                  height="25"
                  class="d-flex justify-content-center align-center mt-0"
                  @click="resetContent"
                >
                  <img :src="resetIcon" />
                </v-btn>
              </div>
              <v-textarea
                id="notificationsTextarea"
                dense
                filled
                hide-details="auto"
                :placeholder="contentTypeText"
                auto-grow
                no-resize
                rows="2"
                :disabled="true"
                v-model="notificationSettings.content"
              />
            </v-col>
          </v-row>

          <v-row v-if="notificationSettings.category === 'financial'">
            <v-col cols="12" sm="6">
              <h6 class="font-13 font-weight-bold mt-0 mb-2">قبل او بعد</h6>
              <v-select
                :items="dueDate"
                item-text="nameAr"
                item-value="nameEn"
                :menu-props="{ bottom: true, offsetY: true }"
                append-icon="mdi-chevron-down"
                color="black"
                dense
                filled
                hide-details
                placeholder="قبل او بعد موعد الاستحقاق"
                v-model="notificationSettings.method"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <h6 class="font-13 font-weight-bold mt-0 mb-2">عدد الأيام</h6>
              <v-select
                :items="monthDays"
                :menu-props="{ bottom: true, offsetY: true }"
                append-icon="mdi-chevron-down"
                color="black"
                dense
                filled
                hide-details
                placeholder="عدد الأيام"
                v-model="notificationSettings.days"
              ></v-select>
            </v-col>
          </v-row>
          <!-- <div
            class="d-flex justify-space-between align-center mt-5"
            v-if="!team && notificationSettings.category"
          >
            <div class="d-flex align-center ma-0">
              <span class="mt-1 font-13"> ارفاق رابط الخطاب مع الاشعار</span>
            </div>
            <v-switch
              class="ma-0"
              dense
              hide-details="auto"
              inset
              v-model="notificationSettings.attachment"
            />
          </div> -->
        </div>
      </v-container>

      <!----------3# Save----------->
      <v-card-actions class="pa-1 mt-2">
        <v-btn
          :loading="submitBtnIsLoading"
          @click="saveNotificationSettings"
          class="ma-0 rounded-7 my-2 ml-3 mr-auto font-14"
          color="primary"
        >
          <span class="ml-1 font-weight-bold"> حفظ التغيرات </span>
          <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'NotificationsModelsEdit',
  props: {
    switchButtons: {
      default() {
        return []
      },
      type: Array
    },
    dialogState: {
      type: Boolean,
      required: true
    },
    team: {
      type: Boolean,
      required: true
    },
    notificationsSettings: {
      type: Object,
      default() {
        return {}
      }
    },
    activeNotification: {
      type: Number,
      required: true
    },
    submitBtnIsLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      notificationSettings: {
        attachment: false
      },
      messagesIcon: require('@/assets/img/svgs/messages/messages-icon.svg'),
      notificationIcon: require('@/assets/img/svgs/lightBell.svg'),
      smsIcon: require('@/assets/img/svgs/mobile.svg'),
      emailIcon: require('@/assets/img/svgs/money.svg'),
      assoicationNoData: require('@/assets/img/svgs/assoicationNoData.svg'),
      resetIcon: require('@/assets/img/svgs/reset-icon.svg'),
      contentTypes: [
        {
          nameAr: 'رسالة نصية',
          nameEn: 'sms'
        },
        {
          nameAr: 'خطاب',
          nameEn: 'letter'
        }
      ],
      dueDate: [
        {
          nameAr: 'قبل',
          nameEn: 'before'
        },
        {
          nameAr: 'بعد',
          nameEn: 'after'
        }
      ]
    }
  },
  watch: {
    notificationsSettings: {
      immediate: true,
      handler() {
        this.setNotificationsSettings()
      }
    },
    'notificationSettings.includeLink': {
      handler(value) {
        if (!value) {
          this.resetChildrenValues('includeLink')
        }
      }
    }
  },
  computed: {
    contentTypeText() {
      return this.notificationSettings.contentType === 'sms'
        ? 'محتوى الرسالة'
        : 'محتوى الإشعار'
    },
    notificationTitle() {
      return this.team
        ? 'إشعارات الفريق'
        : this.notificationSettings.title || 'إشعارات العملاء'
    },
    isFinancial() {
      return (
        this.notificationSettings.category === 'financial' ||
        this.notificationSettings.category === 'contract' ||
        this.notificationSettings.category === 'bonds'
      )
    },
    monthDays() {
      const days = []
      for (let day = 1; day <= 60; day++) {
        days.push(day)
      }
      return days
    },
    isContentChanged() {
      return (
        !!this.notificationSettings.defaultContent &&
        this.notificationSettings.content !==
          this.notificationSettings.defaultContent
      )
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    resetChildrenValues(key) {
      const requiredSettingOpt = this.switchButtons.find((opt) => {
        return opt.key === key
      })
      if (requiredSettingOpt?.children?.length) {
        requiredSettingOpt.children.map((sign) => {
          this.notificationSettings[`${sign.key}`] = false
        })
      }
    },
    syncNotificationSettings(event, type) {
      switch (type) {
        case 'status':
          this.switchButtons.map((opt) => {
            this.notificationSettings[`${opt.key}`] = event
          })
          break
        default:
          this.notificationSettings.status = this.switchButtons.some((opt) => {
            return this.notificationSettings[`${opt.key}`]
          })
          break
      }
    },
    closeDialog() {
      this.$emit('closeNotificationModal')
    },
    saveNotificationSettings() {
      this.$emit('notificationsChanged', {
        notification: this.notificationSettings,
        fromModal: true,
        index: this.activeNotification
      })
    },
    setNotificationsSettings() {
      this.notificationSettings = { ...this.notificationsSettings }
    },
    generateActiveClass(content) {
      return this.notificationSettings.contentType === content.nameEn
        ? 'active-content-type'
        : 'font-weight-regular'
    },
    setContentType(content) {
      this.$set(this.notificationSettings, 'contentType', content.nameEn)
    },
    resetContent() {
      // Cloning default content to content
      this.$set(
        this.notificationSettings,
        'content',
        this.notificationSettings.defaultContent.replace()
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.content-type {
  text-align: center;
  width: 140px;
  border: 1px solid #e1e1e1;
  border-radius: 30px;
  cursor: pointer;
}
.content-type:nth-child(2) {
  margin-right: 10px;
}

.active-content-type {
  border-color: $primary-color;
  color: $primary-color;
}

.font-13 {
  font-size: 13px !important;
}
</style>

<style lang="scss">
#notificationsTextarea {
  font-size: 13px !important;
  color: #101010;
  margin-bottom: 0px !important;
}
</style>
