<template>
  <div>
    <v-form v-model="isValidForm" class="mb-4">
      <v-row no-gutters>
        <v-col cols="12" md="6" class="px-1 py-2">
          <h6 class="ma-0 mb-2">هل يتطلب العقد موافقة أطراف التعاقد ؟</h6>
          <v-card class="informativeCard pa-1 ml-3 pb-2">
            <div class="holder d-flex align-center pr-2">
              <div class="d-flex justify-between align-center w-100">
                <p class="headTitle font-14 font-weight-bold ma-0">
                  {{ pendingContract ? 'نعم' : 'لا' }}
                </p>
                <v-switch
                  class="my-auto"
                  dense
                  hide-details="auto"
                  inset
                  small
                  v-model="pendingContract"
                />
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="px-1 py-2">
          <h6 class="mt-0 mb-2">الأطراف التي يجب موافقتها</h6>
          <v-select
            :rules="pendingContract ? [(v) => (v && v.length) || ''] : [true]"
            :items="ownerOrRenter"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            placeholder="اختر واحد او الاثنين معاً"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            item-text="nameAr"
            item-value="value"
            required
            v-model="contractInfo.pendingParties"
            @change="setPendingContract"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-card class="informativeCard pa-2">
            <div class="holder d-flex align-center">
              <div class="mr-3 ml-5">
                <v-img :src="exclamation" />
              </div>
              <div class="text">
                <span>
                  <p class="headTitle font-14 font-weight-bold ma-0">
                    في حاله التفعيل يتم إرسال إشعار إلي الطرفين بطلب قبول العقد
                    المبرم, ولن يتم تفعيل العقد إلا إذا تم الموافقه من خلال
                    الطرفين في مده أقصاها 7 أيام
                  </p>
                </span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'contracendingParties',
  props: {
    exclamation: {
      default: require('@/assets/img/svgs/exclamationMark.svg')
    }
  },
  data() {
    return {
      contractInfo: {},
      isValidForm: false,
      pendingContract: false,
      ownerOrRenter: [
        {
          value: 'owner',
          nameAr: 'مالك'
        },
        {
          value: 'renter',
          nameAr: 'مستأجر'
        },
        {
          value: 'both',
          nameAr: 'الاثنين معا'
        }
      ]
    }
  },
  watch: {
    isValidForm: {
      handler() {
        this.emitValidationStatus()
      }
    }
  },
  methods: {
    setPendingContract() {
      this.$emit('setPendingContract', this.pendingContract)
    },
    emitValidationStatus() {
      this.$emit('setValdationStatus', this.isValidForm)
    },
    async bindContractData() {
      this.$emit('bindContractData', {})
    },
    resetData() {
      this.contractInfo = {}
    },
    presetData() {
      this.editMoodPresetData()
      this.emitValidationStatus()
      this.$emit('setLastTabStatus', true)
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        // edit mood
      }
    }
  }
}
</script>
