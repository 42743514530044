<template>
  <v-container class="landing-page__banar" v-if="isThereAnOffer">
    <v-row
      class="landing-page__banar__wrapper primary white--text align-center px-4 py-0"
    >
      <!-- Discount -->
      <v-col
        role="discount"
        class="landing-page__banar__discount text-center align-center justify-center d-none"
        md="12"
        lg="2"
      >
        <div>
          <span class="landing-page__banar__discount__title mb-1 d-block"
            >خصم
          </span>
          {{ discount }}
        </div>
      </v-col>

      <!-- Description -->
      <v-col>
        <p
          role="description"
          class="landing-page__banar__description text-center ma-0"
          v-text="content"
        />
      </v-col>

      <!-- Go to register -->
      <div role="button" class="landing-page__banar__join-us justify-center">
        <router-link v-if="!isLoggedIn" :to="goToLink">
          <v-btn class="landing-page__banar__join-us__btn ma-0">
            <span class="tf">انضم الان</span>
          </v-btn>
        </router-link>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Banar',
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      discount: '50%',
      content: ` الان ولفتره محدودة احصل على شهرين مجانا عند اشتراكك سنويا في إحدي باقات هكتار بلس`
    }
  },
  computed: {
    goToLink() {
      return {
        path: '/#bundels'
      }
    },
    isThereAnOffer() {
      return this.$store.getters['appState/isThereAnOffer']
    }
  }
}
</script>

<style lang="scss" scoped>
.landing-page__banar {
  width: 72%;
  margin-top: 30px;

  &__wrapper {
    overflow: hidden;
    height: 75px;
    border-radius: 200px;
    font-weight: 500;

    @media only screen and (max-width: 1376px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      border-radius: 20px;
    }
  }

  &__discount {
    display: flex;
    align-self: stretch;
    font-size: 1.6rem;
    color: #12c9d7;
    padding: 0;
    margin-bottom: 5px;

    &__title {
      font-size: 0.7em;
    }

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  &__description {
    font-size: 1rem !important;
  }

  &__join-us {
    &__btn {
      height: 45px !important;
      width: 154px;
      font-size: 1.1rem !important;
      border-radius: 100px;
      background-color: #12c9d7 !important;

      @media only screen and (max-width: 992px) {
        font-size: 0.9rem !important;
        width: 120px;
        height: 35px !important;
      }
    }
  }
}
</style>
