<template>
  <v-dialog width="850px" persistent v-model="priceOffertModalState_">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="py-0 pr-5 pl-md-1 pl-3 mb-4 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0 font-16">{{ tabsTitles[tab] }}</h4>
        <v-btn
          @click.prevent="closeDialog"
          data-test="close-contract"
          class="ma-0 mt-1"
          color="grey"
          fab
          text
        >
          <v-icon class="font-20">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Price offer Modal Tabs-->
      <v-tabs-items class="px-5" v-model="tab" touchless>
        <v-tab-item>
          <TypeAndDuration
            v-bind="typeAndDurationProps"
            @getClientNextData="getClientNextData"
            @getClientDataBySearchKey="getClientDataBySearchKey"
            @getRealEstates="getRealEstates"
            @getRealEstatesNextData="getRealEstatesNextData"
            @getRealEstateSearchKey="getRealEstateSearchKey"
            @resetRealEstatesUnits="resetRealEstatesUnits"
            @setValdationStatus="setValdationStatus($event)"
            @bindPriceOfferData="bindPriceOfferData($event)"
          />
        </v-tab-item>
        <v-tab-item>
          <RentalInformation
            v-bind="rentalInformationProps"
            @setValdationStatus="setValdationStatus($event)"
            @bindPriceOfferData="bindPriceOfferData($event)"
            @setContractDuration="setContractDuration"
          />
        </v-tab-item>
        <v-tab-item>
          <PriceOfferAdditionaldetails
            v-bind="additionaldetailsProps"
            @bindContractData="bindPriceOfferData($event)"
            @setValdationStatus="setValdationStatus($event)"
          />
        </v-tab-item>
        <v-tab-item>
          <PriceOfferFlexibleInstallments
            v-bind="flexibleInstallmentsProps"
            @bindContractData="bindPriceOfferData($event)"
            @openFixedAmountModal="setFixedAmountData"
            @setValdationStatus="setValdationStatus($event)"
          />
        </v-tab-item>
        <v-tab-item>
          <PriceOfferFinancialClaims
            v-bind="financialClaimsProps"
            @bindContractData="bindPriceOfferData($event)"
            @setValdationStatus="setValdationStatus($event)"
          />
        </v-tab-item>
        <v-tab-item>
          <CommissionFee
            v-bind="commissionProps"
            @bindPriceOfferData="bindPriceOfferData($event)"
            @setValdationStatus="setValdationStatus($event)"
          />
        </v-tab-item>
        <v-tab-item>
          <TermsAndCondition
            v-bind="termsAndConditionProps"
            @bindPriceOfferData="bindPriceOfferData($event)"
            @setValdationStatus="setValdationStatus($event)"
            @setLastTabStatus="setLastTab($event)"
          />
        </v-tab-item>
      </v-tabs-items>

      <!-- Fixed Amount Modal -->
      <ModFixedAmountModal
        v-bind="modFixedAmountProps"
        @update-fixed-amount="updateFixedAmount"
        @closeDialog="closeUpdateFixedAmount"
      />

      <!-- Actions -->
      <v-card-actions class="px-4 py-3">
        <v-btn
          @click="previousTab"
          v-if="tab > 0"
          class="ma-0 rounded-7 font-14"
          color="grey"
        >
          <v-icon class="send-icon font-16">mdi-arrow-left</v-icon>
          <span class="ml-1 font-weight-bold font-14">السابق</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="tab > 0 && !isEditMood"
          :disabled="!tabsValidation[`tab${tab}`]"
          @click="handleAddOfferAction('draft')"
          class="ma-0 rounded-7 my-3 ml-3"
          color="grey"
        >
          <span class="ml-1 font-weight-bold">حفظ كمسوده</span>
        </v-btn>
        <v-btn
          v-if="isEditMood && tab >= 1"
          :disabled="!tabsValidation[`tab${tab}`]"
          @click="handleAddOfferAction('draft')"
          class="ma-0 rounded-7 my-3 ml-3"
          color="grey"
        >
          حفظ
        </v-btn>
        <v-btn
          :disabled="!tabsValidation[`tab${tab}`]"
          @click="nextTab"
          class="ma-0 rounded-7 font-14"
          color="primary"
          :loading="createOfferBtnLoading"
        >
          <span v-if="!isLastTap">
            <span class="ml-1 font-weight-bold font-14">التالي</span>
            <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
          </span>
          <span v-else-if="isLastTap">
            <span class="ml-1 font-weight-bold font-14">إنشاء العرض</span>
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PriceOfferAdditionaldetails from '@/components/add-contract/contractModalTabs/contractAdditionalDetails'
import PriceOfferFinancialClaims from '@/components/add-contract/contractModalTabs/contractFinancialClaims'
import PriceOfferFlexibleInstallments from '@/components/add-contract/contractModalTabs/contractFlexibleInstallments.vue'
import ModFixedAmountModal from '@/components/modals/ModFixedAmountModal.vue'
import CommissionFee from '@/components/modals/priceOffers/CommissionFee.vue'
import RentalInformation from '@/components/modals/priceOffers/RentalInformation.vue'
import TermsAndCondition from '@/components/modals/priceOffers/TermsAndCondition.vue'
import TypeAndDuration from '@/components/modals/priceOffers/TypeAndDuration.vue'
import confirmationMixin from '@/mixins/confirmationMixin.js'
import { EnvService, realEstateService, userService } from '@/services'
import { buildQueryString } from '@/utils/general'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'priceQuotationModal',
  components: {
    TypeAndDuration,
    RentalInformation,
    PriceOfferAdditionaldetails,
    PriceOfferFlexibleInstallments,
    PriceOfferFinancialClaims,
    CommissionFee,
    TermsAndCondition,
    ModFixedAmountModal
  },
  mixins: [confirmationMixin],
  props: {
    priceOffertModalState: {
      default: false,
      required: true
    },
    defaultData: {
      default() {
        return {}
      }
    },
    updatedPriceOffer: {
      default() {
        return {}
      }
    },
    preSelectedContractType: {
      default() {
        return {}
      }
    },
    contractIntervals: {
      default: [],
      required: true
    }
  },
  emits: ['closePriceOfferModal', 'refreshContracts'],
  data() {
    return {
      paginationClient: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      tabsTitles: [
        'إضافة عرض سعر (نوع ومدة العرض)',
        'إضافة عرض سعر (معلومات التأجير)',
        'إضافة عرض سعر (تفاصيل الخدمات)',
        'إضافة عرض سعر (تفاصيل الدفعات)',
        'إضافة عرض سعر (مستحقات إضافية)',
        'إضافة عرض سعر (رسوم خدمة الوساطة والرسوم الإدارية)',
        'إضافة عرض سعر (الشروط والأحكام والتوقيعات)'
      ],
      intervals: [
        {
          _id: '6114ed4be2f4d556425f5a0b',
          nameEn: 'daily',
          nameAr: 'يومي',
          __v: 0,
          value: 0.0333
        },
        {
          _id: '6114ed4be2f4d556425f5a0c',
          nameEn: 'monthly',
          nameAr: 'شهري',
          __v: 0,
          value: 1
        },
        {
          _id: '6486f9c7d37b34d8ae26dd4b',
          nameEn: '2 months',
          nameAr: 'شهرين',
          value: 2
        },
        {
          _id: '6114ed83e2f4d556425f5a0d',
          nameEn: 'quarterly',
          nameAr: 'ربع سنوي',
          __v: 0,
          value: 3
        },
        {
          _id: '6114ed94e2f4d556425f5a0e',
          nameEn: 'biannual',
          nameAr: 'نصف سنوي',
          __v: 0,
          value: 6
        },
        {
          _id: '6114eda7e2f4d556425f5a0f',
          nameEn: 'yearly',
          nameAr: 'سنوي',
          __v: 0,
          value: 12
        }
      ],
      priceOfferInfo: {},
      allPTClients: [],
      allClients: [],
      isUnitLoading: false,
      isRealEstateLoading: false,
      contractDuration: [],
      team: [],
      exclamation: require('@/assets/img/svgs/exclamationMark.svg'),
      tab: 0,
      tabsValidation: {},
      contractInfo: {},
      availableRealEstates: [],
      availableUnits: [{ name: 'كامل العقار', allocated: true }],
      subContractTypes: [],
      pendingContract: false,
      draftOffer: false,
      LastTab: false,
      addAction: 'new',
      previousClicked: false,
      createOfferBtnLoading: false,
      attachment: {},
      fixedAmountStatus: false,
      fixedAmountData: {},
      newFixedAmountData: {},
      instId: null,
      updatedContractExcludedKeys: [
        'type',
        'typeName',
        'status',
        '__v',
        '_id',
        'terminated',
        'reminderSentAt',
        'reminderSent',
        'isSynchronized',
        'synchronizedAt',
        'number',
        'createdAt',
        'updatedAt',
        'checked',
        'notes',
        'companions',
        'year',
        'month',
        'day',
        'typeAr',
        'hasCommissionTax',
        'valuePerYear',
        'version',
        'hijri',
        'previousContract',
        'previousContracts',
        'renewalProcessed',
        'renewalProcessedAt',
        'okay',
        'pmContract',
        'contractUtilities'
      ],
      allowedKayFalsyValues: [
        'payment',
        'tax',
        'taxAmount',
        'hasCommissionTax',
        'hasFlexibleInstallments'
      ]
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user', 'configs']),
    typeAndDurationProps() {
      return {
        ref: 'tab0',
        allClients: this.allClients,
        allPTClients: this.allPTClients,
        availableRealEstates: this.availableRealEstates,
        isRealEstateLoading: this.isRealEstateLoading,
        updatedPriceOffer: this.updatedPriceOffer,
        availableUnitsForParent: this.availableUnits,
        isUnitLoading: this.isUnitLoading,
        isEditMood: this.isEditMood
      }
    },
    rentalInformationProps() {
      return {
        ref: 'tab1',
        updatedPriceOffer: this.updatedPriceOffer,
        isEditMood: this.isEditMood,
        realEstateExist: this.priceOfferInfo.realEstateExist,
        intervals: this.intervals
      }
    },
    additionaldetailsProps() {
      return {
        ref: 'tab2',
        updatedContract: this.updatedPriceOffer.details,
        isEditMood: this.isEditMood,
        contractIntervals: this.contractDuration,
        duration: this.priceOfferInfo.duration
      }
    },
    flexibleInstallmentsProps() {
      return {
        ref: 'tab3',
        contractInfo: this.priceOfferInfo,
        previousClicked: this.previousClicked,
        updatedContract: this.updatedPriceOffer.details,
        updateInstasId: this.instId,
        newFixedAmountData: this.newFixedAmountData
      }
    },
    financialClaimsProps() {
      return {
        ref: 'tab4',
        updatedContract: this.updatedPriceOffer.details,
        isEditMood: this.isEditMood,
        exclamation: this.exclamation,
        excludeInput: 'brokerageFees',
        envHasCommissionTax: this.envHasCommissionTax
      }
    },
    commissionProps() {
      return {
        ref: 'tab5',
        insts: this.priceOfferInfo.insts,
        updatedPriceOffer: this.updatedPriceOffer,
        isEditMood: this.isEditMood
      }
    },
    termsAndConditionProps() {
      return {
        ref: 'tab6',
        updatedPriceOffer: this.updatedPriceOffer,
        isEditMood: this.isEditMood,
        team: this.team,
        attachment: this.attachment
      }
    },
    modFixedAmountProps() {
      const details = this.fixedAmountData?.fixedDetails

      return {
        dialogState: this.fixedAmountStatus,
        data: {
          electricity: details?.electricity || 0,
          water: details?.water || 0,
          gas: details?.gas || 0,
          generalServices: details?.generalServices || 0,
          utilitiesTax: details?.fixedTax || 15
        }
      }
    },
    envHasCommissionTax() {
      return this.currentEnv?.hasCommissionTax || false
    },
    realEstateStatus() {
      return this.configs.realEstateStatus
        .filter(
          (status) => status.nameEn === 'rent' || status.nameEn === 'sale'
        )
        .map((status) => status._id)
    },
    isLastTap: {
      get() {
        return this.LastTab
      },
      set(val) {
        this.LastTab = val
      }
    },
    priceOffertModalState_: {
      get() {
        return this.priceOffertModalState
      },
      set(value) {
        this.$emit('closePriceOfferModal', value)
      }
    },
    isEditMood() {
      return !!Object.keys(this.updatedPriceOffer).length
    },
    // eslint-disable-next-line complexity
    collectData() {
      const info = this.priceOfferInfo
      const updateInfo = this.updatedPriceOffer

      return {
        type: info.typeOffer || updateInfo?.type,
        status: this.addAction,
        environment: this.currentEnv._id,
        realEstate: info?.realEstateId,
        title: info?.titleForOffer,
        reserveRealestate: info?.reserveRealestate,
        startDate: info?.startOffer,
        endDate: info?.endOffer,
        client: {
          ...info?.user,
          new: info?.selectedWay === 'new'
        },
        price: info?.price || updateInfo?.price,
        pricePerMeter: info?.pricePerMeter || updateInfo?.pricePerMeter,
        space: info?.space || updateInfo?.space,
        commision: info?.totalAmount || updateInfo?.totalAmount,
        commissionPercentage: info?.percentage || updateInfo?.percentage,
        companyCommissionPercentage:
          info?.percentageCompany || updateInfo?.percentageCompany,
        marketersCommission:
          info?.commissionAmount || updateInfo?.commissionAmount,
        marketersCommissionPercentage:
          info?.precentageMarkterCommission ||
          updateInfo?.precentageMarkterCommission,
        companyCommission:
          info?.commissionAmount || updateInfo?.commissionAmount,
        rentReprsentitive:
          info?.rentReprsentitive || updateInfo?.rentReprsentitive,
        hasCompanySeal: info?.hasCompanySeal,
        startGracePeriod: info?.startGracePeriod,
        endGracePeriod: info?.endGracePeriod,
        hasBrokerageFees: info?.hasBrokerageFees,
        feesPercentage: info?.feesPercentage || undefined,
        fees: info?.fees || undefined,
        hasPolicy: info?.hasPolicy,
        renterRepresentative:
          info?.renterRepresentative || updateInfo?.renterRepresentative,
        renterRepresentativePhoneNubmber:
          info?.renterRepresentativePhoneNubmber ||
          updateInfo?.renterRepresentativePhoneNubmber,
        sendEContract: info?.sendEContract,
        commissionType: info?.commission || undefined,
        feesType: info?.feesType || undefined,
        details: {
          insts: info?.insts || updateInfo?.details?.insts,
          water: info?.water || updateInfo?.details?.water,
          waterYearlyPayment:
            info?.waterYearlyPayment || updateInfo?.details?.waterYearlyPayment,
          waterPayment: info?.waterPayment || updateInfo?.details?.waterPayment,
          generalServices:
            info?.generalServices || updateInfo?.details?.generalServices,
          generalServicesPayment:
            info?.generalServicesPayment ||
            updateInfo?.details?.generalServicesPayment,
          electricityYearlyPayment:
            info?.electricityYearlyPayment ||
            updateInfo?.details?.electricityYearlyPayment,
          gasYearlyPayment:
            info?.gasYearlyPayment || updateInfo?.details?.gasYearlyPayment,
          generalServicesYearlyPayment:
            info?.generalServicesYearlyPayment ||
            updateInfo?.details?.generalServicesYearlyPayment,
          electricity: info?.electricity || updateInfo?.details?.electricity,
          electricityPayment:
            info?.electricityPayment || updateInfo?.details?.electricityPayment,
          gas: info?.gas || updateInfo?.details?.gas,
          gasPayment: info?.gasPayment || updateInfo?.details?.gasPayment,
          utilitiesTax: info?.utilitiesTax || updateInfo?.details?.utilitiesTax,
          hasCombinedRent:
            info?.hasCombinedRent || updateInfo?.details?.hasCombinedRent,
          collectionInterval: info?.collectionInterval,
          rentValue: info?.rentValue,
          duration: info?.duration,
          increaseType: info?.increaseType,
          increase: info?.increase,
          start: info?.start,
          end: info?.end,
          hasTax: !!info?.hasTax,
          brokerageFees: info?.brokerageFees || updateInfo?.brokerageFees,
          engineeringSupervisionFees:
            info?.engineeringSupervisionFees ||
            updateInfo?.engineeringSupervisionFees,
          insurance: info?.insurance || updateInfo?.insurance,
          openingBalance: info?.openingBalance || updateInfo?.openingBalance,
          remnantRemovalFees:
            info?.remnantRemovalFees || updateInfo?.remnantRemovalFees,
          rentFees: info?.rentFees || updateInfo?.rentFees,
          unitPreparementFees:
            info?.unitPreparementFees || updateInfo?.unitPreparementFees
        }
      }
    },
    dataForNewUser() {
      const user = this.priceOfferInfo.user

      return {
        isLead: true,
        name: user.name,
        phoneNumber: (user.phoneNumber || '')
          .replace(/\s/g, '')
          ?.replace('0', '966'),
        clientType: user?.clientType,
        email: user?.email,
        environment: {
          _id: this.currentEnv._id,
          name: this.currentEnv.name,
          role: user?.clientRole,
          whatsAppNumber: this.priceOfferInfo?.whatsAppNumber
        }
      }
    },
    IsItDraftOffer: {
      get() {
        return this.draftOffer
      },
      set(val) {
        this.draftOffer = val
      }
    },
    addOfferNotificationText() {
      if (this.IsItDraftOffer && this.addAction === 'draft') {
        return this.isEditMood
          ? 'تم تحديث المسودة بنجاح'
          : 'تم حفظ العرض كمسودة بنجاح'
      }
      return 'تم إضافه عرض السعر بنجاح'
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setLastTab(isLastTabVal) {
      this.isLastTap = isLastTabVal
    },
    setContractDuration(avaliableDurations) {
      if (
        this.$refs.tab2 &&
        avaliableDurations.length < this.contractDuration.length
      ) {
        this.$refs.tab2.resetDurations()
      }
      this.contractDuration = avaliableDurations
    },
    closeDialog() {
      this.priceOffertModalState_ = false
      this.priceOfferInfo = {}
    },
    setValdationStatus(isValidStatus) {
      this.$set(this.tabsValidation, `tab${this.tab}`, isValidStatus)
    },
    nextTab() {
      if (this.isLastTap) {
        this.handleAddOfferAction()
      } else {
        this.$refs[`tab${this.tab}`].bindContractData()
        this.tab++

        setTimeout(() => {
          if (this.tab === 3) {
            this.$refs.tab3.getInstallmentsPreview(this.priceOfferInfo)
            this.$refs.tab3.resetNewInstsPayment()
          }
          this.$refs[`tab${this.tab}`].presetData()
          if (this.tab === 2) {
            this.setValdationStatus(this.$refs[`tab${this.tab}`]?.isValid)
          }
        })
      }
    },
    previousTab() {
      if (this.tab === 3) {
        this.previousClicked = !this.previousClicked
        this.confirmationPopup()
        return
      }
      // if (this.tab === 5) {
      //   this.$refs.tab5.resetData()
      // }
      this.tab--
      this.isLastTap = false
    },
    confirmationPopup() {
      this.$root.$confirm
        .open({
          title: 'تنبيه',
          message: ' الضغط على تأكيد سيؤدي إلى فقدان التعديلات ',
          confirmTxt: 'تاكيد',
          cancelTxt: 'إلغاء',
          options: {
            color: 'danger',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            this.tab--
          }
        })
    },
    setFixedAmountData(data) {
      this.instId = null
      this.newFixedAmountData = {}
      this.fixedAmountStatus = true
      this.fixedAmountData = { id: data.id, ...data.data }
    },
    updateFixedAmount(data) {
      this.instId = this.fixedAmountData.id - 1
      this.newFixedAmountData = data
    },
    closeUpdateFixedAmount() {
      this.fixedAmountStatus = false
    },
    bindPriceOfferData(data) {
      this.priceOfferInfo = { ...this.priceOfferInfo, ...data }
    },
    resetRealEstatesUnits() {
      this.availableUnits = [{ name: 'كامل العقار', allocated: true }]
    },
    // eslint-disable-next-line consistent-return
    async uploadAttachment() {
      try {
        const fd = new FormData()
        fd.append(
          'attachment',
          this.priceOfferInfo.attachment,
          this.priceOfferInfo.attachment.name
        )
        fd.append('envId', this.currentEnv._id)
        fd.append('entityType', 'quotation')
        fd.append('type', 'signature')

        return await realEstateService.addNewAttachmentForQuotation(fd)
      } catch {
        this.addNotification({
          text: 'حدث خطا ما يرجى المحاولة لاحقا',
          color: 'error'
        })
      }
    },
    async updateQuotation() {
      try {
        this.createOfferBtnLoading = true
        const collectData = await this.addNewClient()
        if (this.priceOfferInfo.clearAttachment) {
          const { data } = await this.uploadAttachment()
          if (data.file?._id) {
            collectData.attachment = data.file._id
          }
        }
        const { data } = await realEstateService.updateQuotation(
          this.updatedPriceOffer._id,
          collectData
        )
        if (data.quotation?.needsApproval) {
          this.showConfirmationPopupMixin()
        }
        this.$emit('refreshData')
        this.addNotification({
          text: this.addOfferNotificationText,
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطا ما يرجى المحاولة لاحقا',
          color: 'error'
        })
      } finally {
        this.createOfferBtnLoading = false
      }
    },
    async getRealEstates(parentId) {
      this.availableUnits = []
      this.isUnitLoading = true
      try {
        const res = await realEstateService.getRealEstates({
          environments: [this.currentEnv._id],
          parentId,
          statuses: this.realEstateStatus
        })

        if (parentId) {
          this.availableUnits = res.data.realEstates
          this.availableUnits.unshift({
            name: 'كامل العقار',
            allocated: true
          })
        } else {
          this.availableRealEstates = res.data.realEstates
        }
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل العقارات المتاحه, برجاء المحاوله لاحقا',
          color: 'error'
        })
      } finally {
        this.isUnitLoading = false
      }
    },
    async addNewClient() {
      const ifNewUser = this.priceOfferInfo.selectedWay === 'new'

      let collectData = this.collectData

      if (ifNewUser && this.addAction !== 'draft') {
        const { data: user } = await EnvService.addClient(
          this.dataForNewUser,
          this.currentEnv._id
        )
        collectData = {
          ...this.collectData,
          client: {
            _id: user.user._id,
            name: user.user.name,
            phoneNumber: user.user.phoneNumber,
            email: user.user?.email,
            status: 'lead'
          }
        }
      }

      return collectData
    },
    async addOffer() {
      try {
        const collectData = await this.addNewClient()
        if (this.priceOfferInfo?.attachment) {
          const { data } = await this.uploadAttachment()
          if (data?.file?._id) {
            collectData.attachment = data.file._id
          }
        }
        const { data: res } = await realEstateService.addQuotation(collectData)

        if (res?.quotation?.needsApproval) {
          this.showConfirmationPopupMixin()
        }
        this.$emit('refreshData')
        this.addNotification({
          text: `${this.addOfferNotificationText}`,
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من انشاء عرضك الان, حاول في وقت لاحق',
          color: 'error'
        })
      }
    },
    runFnDependOnMood() {
      return this.isEditMood ? this.updateQuotation() : this.addOffer()
    },
    async handleAddOfferAction(addAction = '') {
      if (addAction === 'draft') {
        this.IsItDraftOffer = true
        this.addAction = 'draft'
      }
      try {
        this.createOfferBtnLoading = true
        await this.$refs[`tab${this.tab}`].bindContractData()
        await this.runFnDependOnMood()
      } catch (error) {
        this.addNotification({
          text: error.response?.data?.message,
          color: 'error'
        })
      } finally {
        this.closeDialog()
      }
    },
    getClientNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.loadClients()
      }
    },
    getRealEstatesNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.getProperties()
      }
    },
    getClientDataBySearchKey(e) {
      this.clientSearchKey = e
      if (this.clientSearchKey) {
        this.loadClients()
      }
    },
    getRealEstateSearchKey(e) {
      this.realEstateSearchKey = e
      if (this.realEstateSearchKey) {
        this.resetPagination()
        this.getProperties()
      }
    },
    resetPagination() {
      this.paginationClient = {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      }
    },
    async loadClients(query) {
      let data
      if (this.clientSearchKey) {
        data = `search=${this.clientSearchKey}`
      } else {
        data = `pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}`
      }
      try {
        const res = await EnvService.getAllClients(
          this.currentEnv._id,
          query,
          data
        )
        this.paginationClient.pagesCount = Math.ceil(
          res?.data?.count / this.paginationClient.pageSize
        )
        this.paginationClient.count = res?.data?.count ?? 0
        this.clients = res?.data?.users ?? []
        this.allClients = [...this.allClients, ...this.clients]
      } catch {
        this.pushNotification('خطأ في تحميل العملاء', 'error')
      }
    },
    async loadPTClients() {
      this.isPageLoading = true
      const query = buildQueryString(
        {
          pageNumber: this.paginationClient.pageNumber,
          pageSize: this.paginationClient.pageSize,
          environmentId: this.currentEnv._id
        },
        ''
      )
      try {
        const { data } = await userService.getPotentialClients(query)
        this.allPTClients = data.users
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل العملاء المحتملين',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async loadEmployees() {
      try {
        const { data } = await EnvService.getAllEmployee(
          this.currentEnv._id,
          '&registered=1'
        )
        this.team = data.users
        const currentEmp = this.team.find((e) => {
          return e._id === this.user._id
        })
        currentEmp.name += ` (أنا)`
      } catch {
        this.addNotification({
          text: 'حدث خطا',
          color: 'error'
        })
      }
    },
    getPropertiesDependOnRealEstateType() {
      this.availableRealEstates = []
      this.resetPagination()
      this.getProperties()
    },
    async getProperties() {
      this.availableUnits = []
      this.isRealEstateLoading = true
      let _data = `pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}`
      if (this.realEstateSearchKey) {
        _data += `&search=${this.realEstateSearchKey}`
      }

      try {
        const res = await realEstateService.getRealEstates(
          {
            environments: [this.currentEnv._id],
            parentId: null,
            statuses: this.realEstateStatus
          },
          _data
        )

        this.paginationClient.pagesCount = Math.ceil(
          res?.data?.count / this.paginationClient.pageSize
        )
        this.paginationClient.count = res?.data?.count ?? 0
        this.availableRealEstates = [
          ...this.availableRealEstates,
          ...res.data.realEstates
        ]
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء المحاوله مره أخرى',
          color: 'error'
        })
      } finally {
        this.isRealEstateLoading = false
      }
    }
  },
  created() {
    this.loadClients()
    this.loadPTClients()
    this.loadEmployees()
    this.getProperties()
  }
}
</script>
