<template>
  <v-dialog
    class="backdrop"
    overlay-opacity="0.5"
    persistent
    v-model="dialogState_"
    width="950px"
  >
    <v-card class="add-new-property pt-0">
      <v-card-title
        class="pa-0 pr-5 pl-1 mb-5 d-flex justify-space-between align-center custom__shadow"
      >
        <div class="d-flex align-center">
          <h4>
            {{ isEdit ? 'تعديل بيانات الحساب البنكي' : 'إضافة حساب بنكي' }}
          </h4>
        </div>

        <v-btn
          data-test="close"
          @click.prevent="closeModel"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <!-- <v-row>
            <h6 class=" font-12">بيانات حساب البنك</h6>
          </v-row> -->
          <v-form class="my-2" v-model="isValid">
            <v-row>
              <v-col class="mobile__pl__0 pb-0 pr-0" cols="12" sm="6">
                <h6 class="font-12 mb-2">اسم البنك</h6>

                <v-text-field
                  class=""
                  dense
                  filled
                  hide-details
                  placeholder="إسم البنك"
                  v-model="updatedBankAccountDetails.bankName"
                  :rules="[(v) => !!v || 'إسم العميل']"
                />
              </v-col>
              <v-col class="mobile__pl__0 pr-0 pb-0" cols="12" sm="6">
                <h6 class="font-12 mb-2">اسم الحساب</h6>

                <v-text-field
                  class="pb-0"
                  dense
                  filled
                  hide-details
                  placeholder="إسم الحساب"
                  v-model="updatedBankAccountDetails.name"
                  :rules="[(v) => !!v || 'إسم العميل']"
                />
              </v-col>
              <v-col class="mobile__pl__0 pr-0" cols="12" sm="6">
                <h6 class="font-12 mb-2">رقم الإيبان</h6>

                <v-text-field
                  class="pb-2"
                  dense
                  filled
                  placeholder="رقم الإيبان الخاص بالحساب"
                  v-model="updatedBankAccountDetails.iban"
                  :rules="[
                    (v) =>
                      /^[A-Z]{2}(?:[ ]?[0-9]){22}$/.test(v) ||
                      'يرجي التحقق من ال IBAN'
                  ]"
                />
              </v-col>

              <!--  <v-col class="pl-3 file-container-col pt-3" cols="12" sm="6">
                <h6 class="mb-2 font-12">إضافة المرفق</h6>
                <v-file-input
                  label=""
                  placeholder="إضغط لإضافة مرفق (واحد فقط)"
                  outlined
                  :rules="selectRules"
                  class="rounded-lg"
                  @change="uploadAttachment"
                  prepend-icon=""
                  required
                  dense
                  v-model="attachment"
                >
                  <template v-slot:append>
                    <v-icon color="primary">mdi-upload</v-icon>
                  </template>
                </v-file-input>
              </v-col> -->
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary darken-1"
          :disabled="!isValid"
          @click.native="hadnelAction"
        >
          {{ isEdit ? 'حفظ التعديلات' : 'اضافة' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EnvService } from '@/services'
export default {
  name: 'addExtraUnits',
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    bankAccountDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isValid: false,
      // selectRules: [(v) => !!v || 'هذا الحقل مطلوب'],
      UpdateBtnClicked: false,
      attachment: null,
      selectedAttachment: null,
      updatedBankAccountDetails: {}
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeModel() {
      this.updatedBankAccountDetails = {}
      this.dialogState_ = false
    },
    uploadAttachment(file) {
      this.selectedAttachment = file
    },
    hadnelAction() {
      this.isEdit ? this.updateBankAccount() : this.addBankAccount()
    },
    async addBankAccount() {
      this.$emit('addBankAccount', this.updatedBankAccountDetails)
    },
    async updateBankAccount() {
      try {
        const { bankName, name, iban } = this.updatedBankAccountDetails
        await EnvService.updateBankAccount(
          this.updatedBankAccountDetails?._id,
          { bankName, name, iban }
        )
        this.$emit('updateBankAccountsList')
        this.addNotification({
          text: 'تم تحديث بيانات الحساب',
          color: 'success'
        })
        this.closeModel()
      } catch (error) {
        const { errors } = error.response.data
        this.addNotification({
          text: errors.iban
            ? 'من فضلك تحقق من ال IBAN'
            : 'لم نتمكن من تحديث بيانات الحساب, حاول في وقت لاحق',
          color: 'error'
        })
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    },
    isEdit() {
      return !!this.bankAccountDetails?._id
    }
  },
  created() {
    this.updatedBankAccountDetails = { ...this.bankAccountDetails }
  }
}
</script>
<style lang="scss">
.v-text-field--outlined .v-input__slot {
  font-size: 12px;
  color: #d2d2d2;
}
.file-container-col .v-text-field--outlined fieldset {
  border: 1px dashed #662d91;
}
</style>
