<template>
  <div id="step-1">
    <!-- phone-->
    <div class="step-title">
      <h6 class="submit-section-header black--text font-weight-bold">
        ادخل رقم الجوال المسجل لدينا
      </h6>
    </div>
    <div class="form-row form-row-wide m-0">
      <vue-tel-input-vuetify
        class="phonenumber-field"
        v-model="phoneNumber"
        label="ادخل رقم الجوال"
        :only-countries="['SA']"
        default-country="SA"
        ref="regPhoneNumber"
        placeholder=""
        :filled="true"
        required
        suffix="+966"
        :rules="[(v) => (v && !!isValidPhone) || 'من فضلك تأكد من رقم الهاتف']"
        :valid-characters-only="true"
        :validate-on-blur="true"
        @validate="onValidatePhoneNumber"
        @input="onInputPhoneNumber"
      >
        <template slot="prepend-inner">
          <v-icon>mdi-cellphone</v-icon>
        </template>
      </vue-tel-input-vuetify>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import { convertNumbers2English } from '@/utils/formatters'
export default {
  props: ['phone'],
  data() {
    return {
      phoneNumber: '',
      phoneData: {},
      enteredOtp: null
    }
  },
  mounted() {
    if (this.validePhonenumber) {
      this.phoneNumber = this.validePhonenumber
      this.$emit('on-validate', this.phoneNumber)
    }
    this.phoneNumber = this.phone
  },
  validations: {
    phoneNumber: {
      required,
      minLength: minLength(10)
    },
    form: ['phoneNumber']
  },
  computed: {
    ...mapState('userState', ['validePhonenumber']),
    isValidPhone() {
      return this.phoneData.isValid
    }
  },

  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapActions('accountState', ['checkPhoneNumberForResetPassword']),
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    onInputPhoneNumber(data) {
      this.phoneNumber = convertNumbers2English(data)
    },
    validate() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      let isRegisteredPhoneNumber = false

      if (isValid && this.isValidPhone) {
        const phoneNumber = this.phoneData.number.e164.replace(/\+/g, '')
        const data = { phoneNumber }
        this.setSpinnerLoading(true)
        this.checkPhoneNumberForResetPassword(data)
          .then((res) => {
            this.$emit(
              'on-validate',
              {
                phoneNumber,
                confirmationCode: res.data.confirmationCode
              },
              isValid
            )
            isRegisteredPhoneNumber = true
            this.addNotification({
              text: 'تم إرسال الرقم التأكيدي لهاتفك بنجاح!',
              color: 'success'
            })
          })
          .catch(() => {
            this.addNotification({
              text: 'عفواً، الرقم الذي تحاول استرجاع كلمة مروره غير مُسجل',
              color: 'error'
            })
          })
          .finally(() => {
            this.setSpinnerLoading(false)
          })
      } else {
        this.addNotification({
          text: 'من فضلك تأكد من رقم الهاتف',
          color: 'error'
        })
        this.setSpinnerLoading(false)
      }
      return isValid && isRegisteredPhoneNumber
    }
  }
}
</script>
