<template>
  <div>
    <!-----------Footer--------->
    <div class="invoice_pdf_footer invoice__pdf__footer__block">
      <div class="footer_text w-100 invoice__pdf__footer__block__text">
        <h4 class="mt-0">منشئ التقرير: {{ userName }}</h4>
      </div>
      <div class="logo_div invoice__pdf__footer__block__logo">
        <div class="min-logo-white sidebar__mini__Logo">
          <img
            src="../../assets/img/logo.png"
            alt=""
            style="max-height: 80px; max-width: 80px"
            loading="lazy"
          />
        </div>
      </div>
      <div class="footer_text w-100 invoice__pdf__footer__block__text">
        <h4 class="mt-0">منصه هكتار بلس لإدارة الأملاك العقارية</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userName: {
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/responsiveness/responsiveness.scss';

/* invoice pdf footer */
.invoice_pdf_footer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #eeeeee;
  padding: 8px 0px;
  @include max-sm {
    padding: 0 10px;
  }
  /*  */
  .logo_div {
    position: relative;
    right: 20px;
    @include max-sm {
      right: 0;
    }
  }
  .footer_text {
    text-align: center;
    color: #151515;
    h4 {
      font-size: 0.9rem !important;
      @include max-sm {
        font-size: 0.8rem !important;
      }
    }
  }
}
</style>
