<template>
  <v-dialog persistent v-model="dialogState_" max-width="580px">
    <v-card class="add-new-property">
      <v-card-title
        class="px-4 py-2 mb-4 custom__shadow d-flex justify-space-between"
      >
        <span class="font-16">إرسال إشعار</span>

        <v-btn
          fab
          text
          color="grey"
          class="ma-0 mt-2"
          @click.prevent="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-4">
        <v-form ref="form" v-model="isValid">
          <v-row>
            <v-col>
              <h6 class="grey--text">إرسال إلي</h6>
              <v-select
                :items="allClients"
                :menu-props="{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }"
                :placeholder="`عميل حالي`"
                append-icon="mdi-chevron-down"
                class="px-1"
                dense
                filled
                hide-details
                item-text="name"
                item-value="_id"
                return-object
                v-model="notification.reciever"
                :rules="selectRules"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h6 class="grey--text">عنوان الإشعار</h6>
              <v-text-field
                filled
                v-model="notification.title"
                :rules="selectRules"
                placeholder="عنوان الإشعار"
                required
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h6 class="grey--text">محتوي الإشعار</h6>
              <v-textarea
                dense
                filled
                hide-details
                placeholder="محتوي الإشعار"
                required
                auto-grow
                no-resize
                rows="2"
                v-model="notification.body"
                id="font-12"
                :rules="selectRules"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-0 rounded-7 my-3 ml-3 px-5"
          :disabled="!isValid"
          @click="sendNotification"
        >
          <span class="mb-2 ml-1 font-weight-bold">إرسال الشعار</span>
          <v-icon class="send-icon">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_add-new-property.scss'
import { EnvService } from '@/services'

export default {
  props: ['dialogState'],
  data() {
    return {
      isValid: false,
      allClients: [],
      currentEnv: JSON.parse(localStorage.getItem('currentEnv')),
      user: JSON.parse(localStorage.getItem('tokenData')),
      notification: {},
      selectRules: [(v) => !!v || 'هذا الحقل مطلوب']
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getAllClients() {
      EnvService.getAllClients(this.currentEnv._id).then((res) => {
        this.allClients = res.data.users
      })
    },
    closeDialog() {
      this.$emit('closed-add-dialog')
    },
    sendNotification() {
      this.$parent.sendNotification(this.notificationToAdd)
    }
  },
  computed: {
    notificationToAdd() {
      const notificationToAdd = {
        title: this.notification.title,
        body: this.notification.body,
        context: {
          platform: 'hectarPlus'
        },
        user: {
          _id: this.notification.reciever?._id ?? {},
          name: this.notification.reciever?.name ?? {}
        }
      }

      return notificationToAdd
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  created() {
    this.getAllClients()
  }
}
</script>
