import config from '../config'
import axios from 'axios'

// Find Task with id
const findTask = function (tasksArry, TaskId) {
  return tasksArry.find((item) => item.id === TaskId)
}

// get All Tasks
const getAllTasks = function (query) {
  const url = `${config.usersUrl}/tasks/getAll`
  return axios.post(url, query)
}

// get Task
const getTask = function (taskid) {
  const url = `${config.usersUrl}/tasks/get/${taskid}`
  return axios.get(url)
}

//  Adding Tasks
const addTask = function (data) {
  const url = `${config.usersUrl}/tasks/add`
  return axios.post(url, data)
}

// Deleting Task
const deleteTask = function (id) {
  const url = `${config.usersUrl}/tasks/delete/${id}`
  return axios.delete(url)
}

// Editing Task
const editTask = function (data, id) {
  const url = `${config.usersUrl}/tasks/update/${id}`
  return axios.put(url, data)
}

//  Adding Task Comments
const addTaskComment = function (data) {
  const url = `${config.usersUrl}/taskComments/add`
  return axios.post(url, data)
}

// gettin All Task Comments
const getAllTaskComments = function (taskId) {
  const url = `${config.usersUrl}/taskComments/get/${taskId}`
  return axios.get(url)
}

// Deleting Task Comment - unused function
const deleteTaskComments = function (id) {
  const url = `${config.usersUrl}/taskComments/delete/${id}`
  return axios.delete(url)
}

// Adding file - unused function
const addFile = function (data, taskId) {
  const formData = new FormData()
  formData.append('file', data)
  const url = `${config.usersUrl}/file/upload/${taskId}`
  return axios.post(url, formData)
}

export const taskManagerService = {
  findTask,
  getAllTasks,
  addTask,
  deleteTask,
  editTask,
  getTask,
  addTaskComment,
  getAllTaskComments,
  deleteTaskComments,
  addFile
}
