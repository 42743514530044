<template>
  <div>
    <v-tabs
      align-with-title
      color="primary"
      right
      v-model="tab"
      class="setting__page__tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab
        :ripple="false"
        class="mr-6 rounded-15"
        v-for="tab in availableTabs"
        :key="tab"
      >
        {{ tab }}
      </v-tab>

      <v-tabs-items class="mt-5 profile-tabs" v-model="tab">
        <v-tab-item :eager="true">
          <ProfileSittings />
        </v-tab-item>
        <v-tab-item :eager="true">
          <PasswordReset />
        </v-tab-item>
        <v-tab-item :eager="true">
          <v-card class="px-5 py-6 mt-4">
            <AccountInfo
              :env="currentEnv"
              :userData="user"
              :locationInfo="locationInfo"
              @setValidation="setDataAndValidation"
              @checkAddress="checkAddress"
              @checkAboutCompany="checkAboutCompany"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item :eager="true">
          <branding />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import ProfileSittings from '@/components/profile/profile-sittings'
import PasswordReset from '@/components/profile/password-reset'
import { mapActions, mapMutations, mapState } from 'vuex'
import { EnvService, userService } from '@/services'

export default {
  name: 'Profile',
  components: {
    ProfileSittings,
    PasswordReset,
    branding: () => import('@/components/profile/branding'),
    AccountInfo: () =>
      import('@/components/profile/env-setting-comps/AccountInfo.vue')
  },
  data() {
    return {
      userData: {},
      isValid: true,

      locationInfo: {
        location: {},
        envAddress: {
          address: ''
        }
      },
      env: {},
      availableTabs: [
        'البيانات الشخصيه',
        'تغيير كلمة المرور',
        'معلومات الشركة',
        'الشعار والتوقيعات'
      ],
      tab: 0
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    newUserEnv() {
      const newEnv = {
        ...this.userData.environments[this.currentEnvIndex],
        ...this.env,
        phoneNumber: this.user?.phoneNumber,
        whatsAppNumber: this.env?.whatsAppNumber?.replace('0', '966')
      }
      return newEnv
    }
  },
  watch: {
    currentEnv() {
      this.env = { ...this.currentEnv }
    }
  },
  methods: {
    ...mapActions('accountState', ['updateEnvSettings', 'updateCurrentEnv']),
    ...mapMutations('notificationState', ['addNotification']),
    setDataAndValidation({ isValid, userData, env }) {
      this.isValid = isValid
      this.userData = { ...this.userData, ...userData }
      this.env = { ...this.env, ...env }
    },
    checkEditableFields({ currentData, sotredData, fileds }) {
      const isNotEdited = fileds.every((field) => {
        return !!(currentData[field] === sotredData[field])
      })
      return isNotEdited
    },
    checkAboutCompany(aboutCompany) {
      this.$set(this.env, 'about', aboutCompany)
      this.saveUpdatedData()
    },
    async saveUpdatedData() {
      if (this.isValid) {
        if (
          this.checkEditableFields({ ...this.getUserParameters }) &&
          this.checkEditableFields({ ...this.getEnvParameters })
        ) {
          this.addNotification({
            text: 'لم يتم تغيير البيانات',
            color: 'error'
          })
          return
        }

        try {
          const envRes = await userService.updateEnv(this.newUserEnv)
          if (envRes.status === 200) {
            this.userData.environments[this.currentEnvIndex] = {
              ...this.env,
              environmentRole: '' // to avoid switching from account owner to customer after updating
            }
          }
          const userRes = await userService.updateProfile(this.userData)
          if (userRes.status === 201) {
            this.updateEnvSettings({ ...this.userData })
          }
          this.addNotification({
            text: 'تم تعديل إعدادات الحساب بنجاح',
            color: 'success'
          })
        } catch {
          this.addNotification({
            text: 'حدث خطأ أثناء تحديث بيانات البيئة, حاول في وقت لاحق',
            color: 'error'
          })
        }
      } else {
        this.addNotification({
          text: 'من فضلك تأكد من استكمال جميع الحقول بشكل صحيح',
          color: 'error'
        })
      }
    },
    checkAddress(options) {
      this.locationInfo.location = {
        coordinates: [options?.place?.lng, options?.place?.lat],
        type: 'Point'
      }
      this.locationInfo.envAddress.address = options?.place?.address
      this.env.location = this.locationInfo.location
      this.env.address = this.locationInfo.envAddress.address
      this.userData.location = this.locationInfo.location
      this.userData.address = this.locationInfo.envAddress.address
    },
    setEnvSettingsData() {
      this.env = { ...this.currentEnv }
      this.userData = { ...this.user }
      if (this.env.address) {
        this.locationInfo.location = this.env.location
        this.locationInfo.envAddress.address = this.env.address
      } else {
        this.locationInfo.location = this.userData.location
        this.locationInfo.envAddress.address = this.userData.address
      }
    }
  }
}
</script>
