import config from '../config'
import axios from 'axios'

axios.defaults.headers.client = 'HectarPlus'

const getRealEstateStats = function (query) {
  const url = `${config.bonds}/stats/internal/realEstate`
  return axios.post(url, query)
}

const getOwnerRenterStats = function ({ apiKey, ...query }) {
  const url = `${config.bonds}/stats/internal/${apiKey}`
  return axios.post(url, query)
}

const getRealEstateStat = function (query) {
  const url = `${config.bonds}/stats/realEstates`
  return axios.post(url, query)
}

const getClientStats = function (query) {
  const url = `${config.bonds}/stats/clients`
  return axios.post(url, query)
}

const getRealEstateforPropertyStats = function (query) {
  const url = `${config.realEstateUrl}/realestates/stats`
  return axios.post(url, query)
}

const getRequestStats = function (envId) {
  const url = `${config.usersUrl}/requests/stats/${envId}`
  return axios.get(url)
}

const getRequestsStats = function (envId) {
  const url = `${config.bonds}/requests/stats/${envId}`
  return axios.get(url)
}

const getTasksStats = function (query) {
  const url = `${config.usersUrl}/tasks/stats`
  return axios.post(url, query)
}

export const statsService = {
  getRealEstateStats,
  getOwnerRenterStats,
  getRealEstateStat,
  getTasksStats,
  getClientStats,
  getRealEstateforPropertyStats,
  getRequestsStats,
  getRequestStats
}
