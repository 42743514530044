<template>
  <div>
    <v-menu
      bottom
      right
      content-class="dropdown-menu"
      offset-y
      transition="slide-y-transition"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          class="SwitchBtn"
          outlined
          color="primary"
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          <div class="cursor d-flex align-center justify-center ml-3 mr-2">
            <h5 class="ma-0 pa-0">{{ formatUserRole(customerDefaultRole) }}</h5>
            <v-icon class="mr-3" color="primary" small> mdi-arrow-down </v-icon>
          </div>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(role, index) in customerRoles"
          :key="index"
          @click="handleChangingCustomerRole(role)"
          class="role"
        >
          <v-list-item-title class="cursor">{{
            formatUserRole(role)
          }}</v-list-item-title>
          <v-list-item-icon>
            <v-icon
              v-if="isRoleSelected(role)"
              color="primary"
              small
              class="mt-2"
            >
              mdi-check-circle
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'CustomerFilter',
  computed: {
    ...mapState('accountState', ['user', 'customerDefaultRole']),
    customerRoles() {
      return this.user?.environments ? this.user.environments[0]?.role : ''
    }
  },
  methods: {
    ...mapMutations('accountState', ['setCustomerRole']),
    isRoleSelected(role) {
      return !!(this.customerDefaultRole === role)
    },
    handleChangingCustomerRole(role) {
      //execute only when role change
      if (role != this.customerDefaultRole) {
        this.setCustomerRole(role)
      }
    },
    formatUserRole(role) {
      switch (role) {
        case 'renter':
          return 'مستأجر'
        case 'owner':
          return 'مالك'
      }
    }
  }
}
</script>

<style lang="scss">
.role:hover {
  background-color: #f7f7f7;
  color: #fff !important;
}
.SwitchBtn {
  background-color: #fff;
  border-radius: 10px !important;
}
</style>
