<template>
  <div class="d-flex justify-space-between align-center">
    <div class="d-flex items-center">
      <div class="icon-container d-flex justify-center align-center mt-1">
        <img alt="request icon" :src="requestIcon" />
      </div>
      <div>
        <p class="text--101010 mb-0">{{ requestTitle }}</p>
        <p class="font-14 text--737373 mb-0 mt-0">{{ realestateName }}</p>
      </div>
    </div>

    <div
      :class="`tabel-tag text-center d-flex justify-center align-center ${requestStatusColor}`"
    >
      <v-sheet
        class="dotted ml-2"
        :color="
          requestStatusesColors[getLocalNamesLocal(request.status.nameAr)]
        "
      />
      <h6 class="mb-1 ma-0 font-12">{{ request.status.nameAr }}</h6>
    </div>
  </div>
</template>

<script>
import Requests from '../../constans/Requests'
import { getLocalNames } from '@/utils/general'

export default {
  name: 'RequestCard',
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ...Requests,
      requestIcon: require('@/assets/img/svgs/request-card.svg'),
      requestStatusesColors: {
        new: '#FF8000',
        inProgress: '#662D91',
        underReview: '#662D91',
        cancelled: '#fc4a4a',
        done: '#65C467',
        rejected: '#383838'
      }
    }
  },
  computed: {
    requestType() {
      switch (this.request.type) {
        case 'maintenance':
          return 'طلب صيانة'
        case 'realEstate':
          return 'عقار'
        case 'report':
          return 'تقرير'
        case 'priceInquery':
          return 'عرض سعر'
        case 'claims':
          return 'طلب مستحقات'
        case 'procedure':
          return 'طلب إجراء'
        default:
          return '-'
      }
    },
    requestCategory() {
      switch (this.request.type.category[0]?.nameAr) {
        case 'electricity':
          return 'كهرباء'
        case 'plumbing':
          return 'سباكة'
        case 'cleaness':
          return 'نظافة'
        case 'painting':
          return 'دهان'
        case 'elevators':
          return 'مصاعد'
        case 'civilDefense':
          return 'دفاع مدني'
        case 'rent':
          return 'إيجار'
        case 'purchase':
          return 'شراء'
        case 'statement':
          return 'تقرير كشف حساب'
        case 'generalReport':
          return 'تقرير مالي عام'
        case 'forRent':
          return 'تقرير شواغر'
        case 'renters':
          return 'تقرير مستأجربن'
        case 'rented':
          return 'تقرير مؤجرة'
        case 'pm':
          return 'إدارة أملاك'
        case 'marketing':
          return 'تسويق عقاري'
        case 'utilities':
          return 'إيراد خدمات'
        case 'otherIncomes':
          return 'إيرادات اخرى'
        case 'rentAdvance':
          return 'مقدم إبجار'
        case 'dues':
          return 'صافي مستحفات'
        case 'insurance':
          return 'تأمين'
        case 'rentDues':
          return 'إيراد تأجير'
        case 'contractRenew':
          return 'تجديد عقد'
        case 'contractCancel':
          return 'إلغاء عقد'
        case 'contractTermination':
          return 'إنهاء العقد'
        default:
          return '-'
      }
    },
    requestTitle() {
      return `${this.request.type?.nameAr} ${this.request.type.category[0]?.nameAr}`
    },
    realestateName() {
      return this.request.realEstate?.propertyName ?? ''
    },
    requestStatusColor() {
      switch (this.getLocalNamesLocal(this.request.status.nameAr)) {
        case 'underReview':
          return 'brub--tag'
        case 'new':
          return 'orange--tag'
        case 'inProgress':
          return 'brub--tag'
        case 'done':
          return 'green--tag'
        case 'cancelled':
          return 'red--tag'
        case 'rejected':
          return 'black--tag'
      }
      return null
    },
    requestStatusTitle() {
      return (
        this.requestStates.find(
          (status) => this.request.status === status.nameEn
        )?.nameAr ?? ''
      )
    }
  },
  methods: {
    getLocalNamesLocal(val) {
      return getLocalNames(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  width: 32px;
  height: 32px;
  background: #efebf2;
  border-radius: 10px;
  margin-left: 15px;
}
</style>
