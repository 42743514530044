<template>
  <div style="width: 150%">
    <v-row
      class="align-center py-5 overflow-y-hidden overflow-x-auto px-5 w-100"
      :class="[sm || xs ? 'd-block' : 'd-flex']"
    >
      <v-row
        class="d-flex filter-container align-center"
        style="gap: 6px"
        :class="{ 'flex-nowrap': mdAndUp, 'flex-wrap': smAndDown }"
      >
        <!-- FILTERS DROPDOWNS -->
        <div class="range--card px-0" :class="{ 'mt-1': smAndDown }">
          <v-card
            class="d-flex justify-space-between pt-1 mx-1"
            @click="openRangePicker"
          >
            <h6 class="mx-1 mr-2 w-fit text-no-wrap">
              {{ RangePlaceholder }}
            </h6>
            <v-icon size="20" color="#b9b9b9"> mdi-calendar-range </v-icon>
            <v-icon class="ml-2">
              {{ isRangeOpened ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-card>
          <date-picker-rage
            ref="datePickerRage"
            class="range--card__date-picker"
            @date-picker-filter-text="setFiltervalueForInput"
            @date-picker-filter-reset="resetDateFilter"
          />
        </div>
        <div class="px-0" v-for="(filter, idx) in duesConfig" :key="idx">
          <v-menu
            content-class="vMenuFilter elevation-0 w-fit"
            offset-y
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="d-flex align-center mx-1"
                :class="{ 'mt-1': smAndDown }"
              >
                <v-slide-x-transition>
                  <v-card
                    ref="sortListActivator"
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex justify-space-between px-2 pt-1"
                    @click="changeIconDir(idx)"
                    width="130"
                  >
                    <h6 class="mx-2 w-fit text-no-wrap text-truncate">
                      {{ ConfigPlaceholder[idx] }}
                    </h6>
                    <v-icon
                      >{{
                        filtersCards[idx]
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-card>
                </v-slide-x-transition>
              </div>
            </template>
            <v-list
              class="contractVmenuList pa-0"
              v-for="(option, i) in duesConfig[idx]"
              :key="i"
            >
              <v-list-item-group>
                <v-list-item class="px-1">
                  <v-list-item-title
                    ref="sortOption"
                    class="font-14 mr-1 mb-2 font-weight-medium"
                    @click="setFiltervalue(option, idx)"
                  >
                    {{ option.nameAr }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <!-- FILTERS INPUTS -->
        <span @click="handleLoadData('isClientsCalled', 'loadClients')">
          <autocomplete
            v-if="!isCustomer"
            style="width: 210px"
            v-bind="clientProps"
            @nextPageTrigger="getClientNextData"
            @emitSearchKey="getClientDataBySearchKey"
            @input="searchDebounce($event, 'users')"
            class="contract__page__dropdown"
          />
        </span>
        <!-- <v-text-field
          v-if="!isCustomer"
          class="search__by__customer__name mx-1 font-14"
          :class="{ 'mt-1': smAndDown }"
          height="45"
          filled
          dense
          hide-details="auto"
          placeholder="ابحث بإسم العميل"
          @input="searchDebounce($event, 'users')"
          v-model="filtersQuery.users"
        /> -->
        <!-- <v-text-field
          class="mx-1 font-14"
          :class="{
            'mt-1': smAndDown,
            'customer-portal-input': isCustomer && !smAndDown,
            'flex-grow-0': smAndDown
          }"
          height="45"
          filled
          dense
          hide-details="auto"
          placeholder="ابحث بإسم العقار أو اسم الوحدة"
          @input="searchDebounce($event, 'realEstate')"
          v-model="filtersQuery.realEstate"
        /> -->
        <span @click="handleLoadData('isPropertiesCalled', 'loadProperties')">
          <autocomplete
            v-if="!isCustomer"
            class="mr-3 contract__page__dropdown"
            v-bind="propertiesProps"
            style="width: 210px"
            @nextPageTrigger="getPropertyNextData"
            @emitSearchKey="getPropertyDataBySearchKey"
            @input="searchDebounce($event, 'realEstate')"
          />
        </span>
        <div
          v-if="isCustomer"
          class="d-flex align-center"
          :class="{ 'mx-2': isCustomer }"
        >
          <h6 class="font-weight-light ml-2">ترتيب بواسطة</h6>
          <v-menu
            content-class="vMenuFilter elevation-0"
            offset-y
            transition="slide-y-transition"
            width="150"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center">
                <v-slide-x-transition>
                  <v-card
                    ref="sortListActivator"
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex justify-space-between px-2"
                    width="110"
                    @click="toggleShowSortOptions"
                  >
                    <h6 class="mr-2">
                      {{ currentSort }}
                    </h6>
                    <v-icon
                      >{{
                        showSortOptions ? 'mdi-chevron-up' : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-card>
                </v-slide-x-transition>
              </div>
            </template>
            <v-list
              class="contractVmenuList pa-0"
              v-for="option in sortOptions"
              :key="option.value"
              :width="110"
            >
              <v-list-item-group>
                <v-list-item class="px-1">
                  <v-list-item-title
                    ref="sortOption"
                    @click="handleSortChange(option.value)"
                    class="font-14 mr-1 mb-2 font-weight-medium t"
                  >
                    {{ option.nameAr }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </v-row>
    </v-row>
    <div class="d-flex mt-4">
      <template v-for="(filter, idx) in filters">
        <v-card
          class="d-flex pa-1 flex-grow-0 justify-between align-center mx-1"
          v-if="filter"
          :key="idx"
        >
          <h5 class="ma-0 mx-2 text--purple font-12 text-no-wrap py-2">
            {{ filter }}
          </h5>
          <v-icon
            class="font-weight-medium font-14 pl-1"
            @click="restFilters(idx)"
          >
            mdi-close
          </v-icon>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import autocomplete from '@/components/forms/autocomplete'
import { debounce } from '@/helpers/debounce'
import { EnvService, realEstateService } from '@/services'
import { mapGetters } from 'vuex'
import DatePickerRage from '../core/DatePickerRage.vue'

export default {
  name: 'DuesFilter',
  props: {
    duesConfig: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    DatePickerRage,
    autocomplete
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    ...mapGetters('appState', ['getClientTypeForRoute']),
    xs() {
      return this.$vuetify.breakpoint.xs
    },
    sm() {
      return this.$vuetify.breakpoint.sm
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    },
    mdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    propertiesProps() {
      return {
        itemData: true,
        itemText: 'propertyName',
        items: this.allProperties,
        noData: this.isPropertiesLoading
          ? 'جاري البحث عن عقارات...'
          : 'لا توجد عقارات متاحه لهذا البحث',
        placeholder: 'العقارات',
        returnObject: true,
        hideDetails: true,
        rules: [true],
        loading: this.isPropertiesLoading,
        value: this.filtersQuery.realEstate
      }
    },
    clientProps() {
      return {
        itemData: true,
        items: this.allClients,
        noData: 'لا يوجد عملاء متاحه لهذا البحث',
        placeholder: 'العملاء',
        hideDetails: true,
        returnObject: true,
        rules: [true],
        loading: this.isClientsLoading,
        value: this.filtersQuery.users
      }
    },
    currentSort() {
      return (
        this.sortOptions.find(
          (sort) => sort.value === this.filtersQuery.sortByCreatedAt
        )?.nameAr || 'الأحدث'
      )
    }
  },
  data() {
    return {
      users: '',
      isPropertiesLoading: false,
      realEstate: '',
      allProperties: [],
      properties: [],
      filtersCards: {
        assignmentStatus: false,
        installmentType: false,
        installmentStatus: false,
        paymentStatus: false
      },
      allClients: [],
      clientSearchKey: '',
      propertySearchKey: '',
      isClientsLoading: false,
      isRangeOpened: false,
      defaultConfigPlaceholder: {
        installmentType: 'نوع المطالبة',
        assignmentStatus: 'حالة التحصيل',
        installmentStatus: 'حالة المطالبة',
        paymentStatus: 'حالة الدفع'
      },
      ConfigPlaceholder: {
        installmentType: 'نوع المطالبة',
        assignmentStatus: 'حالة التحصيل',
        installmentStatus: 'حالة المطالبة',
        paymentStatus: 'حالة الدفع'
      },
      paginationProperty: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      filters: {
        assignmentStatus: '',
        installmentType: '',
        installmentStatus: '',
        paymentStatus: '',
        users: '',
        realEstate: ''
      },
      paginationClient: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      currentEnv: JSON.parse(localStorage.getItem('currentEnv')),
      clients: [],
      filtersQuery: {
        assignmentStatus: '',
        installmentType: '',
        installmentStatus: '',
        paymentStatus: '',
        users: '',
        realEstate: '',
        startDate: '',
        endDate: '',
        sortByCreatedAt: -1
      },
      RangePlaceholder: 'تحديد الفترة',
      sortOptions: [
        { nameAr: 'الأحدث', nameEn: 'Newest', value: -1 },
        { nameAr: 'الأقدم', nameEn: 'Oldest', value: 1 }
      ],
      showSortOptions: false
    }
  },
  watch: {
    filters(UpdatedFilters) {
      this.$emit('updateFilters', UpdatedFilters)
    },
    filtersQuery: {
      deep: true,
      handler(UpdatedFilters) {
        const { users, realEstate } = UpdatedFilters
        this.$emit('queryBuilder', {
          ...UpdatedFilters,
          users: users._id,
          realEstate: realEstate._id
        })
      }
    }
  },
  methods: {
    setFiltervalue(filter, idx) {
      this.$emit('resetPagination')
      this.ConfigPlaceholder[idx] = filter.nameAr
      this.$set(this.filters, idx, filter.nameAr)
      this.$set(this.filtersQuery, idx, filter.code)
    },
    changeIconDir(idx) {
      this.resetFiltersArrows(idx)
    },
    resetFiltersArrows(idx) {
      Object.keys(this.filtersCards).map((key) => {
        if (idx === key) {
          this.filtersCards[key] = !this.filtersCards[key]
        } else {
          this.filtersCards[key] = false
        }
      })
      this.isRangeOpened = false
    },
    setFiltervalueForInput(sreachKey, input) {
      if (input === 'date') {
        this.isRangeOpened = !this.isRangeOpened
        this.RangePlaceholder = sreachKey
      }
      if (input === 'endDate' || input === 'startDate') {
        return this.$set(this.filtersQuery, input, sreachKey)
      }
      if (input === 'realEstate' || input === 'users') {
        this.$set(this.filtersQuery, input, sreachKey)
        return this.$set(this.filters, input, sreachKey.name)
      }

      this.$set(this.filters, input, sreachKey)
      this.$set(this.filtersQuery, input, sreachKey)
    },
    resetDateFilter() {
      this.RangePlaceholder = 'تحديد الفترة'
      delete this.filters.date
    },
    getClientNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.loadClients()
      }
    },
    async loadClients(query = '') {
      this.isClientsLoading = true
      let data = ''
      if (this.clientSearchKey) {
        data += `&search=${this.clientSearchKey}`
      } else {
        data += `pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}`
      }
      try {
        const res = await EnvService.getAllClients(
          this.currentEnv._id,
          query,
          data
        )
        this.paginationClient.pagesCount = Math.ceil(
          res?.data?.count / this.paginationClient.pageSize
        )
        this.paginationClient.count = res?.data?.count ?? 0
        this.clients = res?.data?.users ?? []
        this.allClients = [...this.allClients, ...this.clients]

        // Filter by client
        if (this.getClientTypeForRoute !== '' && this.$route.query.id) {
          this.allClients.push(this.getClientForRoute)
        }
      } catch {
        this.addNotification({
          text: 'خدث خطأ أثناء تحميل العملاء',
          color: 'error'
        })
      } finally {
        this.isClientsLoading = false
      }
    },
    getClientDataBySearchKey(e) {
      if (e) {
        this.clientSearchKey = e
        this.loadClients()
      }
    },
    handleLoadData(isCalled, methodToBeCalled) {
      // to call data for only first time
      if (!this[`${isCalled}`]) {
        this[`${methodToBeCalled}`]()
        this[`${isCalled}`] = !this[`${isCalled}`]
      }
    },
    async loadProperties() {
      this.isPropertiesLoading = true
      let data = ''
      if (this.propertySearchKey) {
        data += `&search=${this.propertySearchKey}`
      } else {
        data += `pageSize=${this.paginationProperty.pageSize}&pageNumber=${this.paginationProperty.pageNumber}`
      }
      const filters = {
        account: this.currentEnv?.owner?.id
      }
      if (this.filtersQuery.users?._id) {
        filters.user = this.filtersQuery.users?._id
      }
      try {
        const res = await realEstateService.getRealEstates(filters, data)
        this.paginationProperty.pagesCount = Math.ceil(
          res?.data?.count / this.paginationProperty.pageSize
        )
        this.paginationProperty.count = res?.data?.count ?? 0
        this.properties = res?.data?.realEstates ?? []
        this.allProperties = [...this.allProperties, ...this.properties]
      } catch {
        this.addNotification({
          text: 'خدث خطأ أثناء تحميل العقارات',
          color: 'error'
        })
      } finally {
        this.isPropertiesLoading = false
      }
    },
    getPropertyNextData() {
      if (
        this.paginationProperty.pageNumber < this.paginationProperty.pagesCount
      ) {
        this.paginationProperty.pageNumber += 1
        this.loadProperties()
      } else {
      }
    },
    getPropertyDataBySearchKey(e) {
      if (e) {
        this.propertySearchKey = e
        this.loadProperties()
      }
    },
    openRangePicker() {
      this.isRangeOpened = !this.isRangeOpened
      if (this.isRangeOpened) {
        this.$refs.datePickerRage.$children[0].openPopup()
      } else {
        this.$refs.datePickerRage.$children[0].closePopup()
      }
    },
    restFilters(idx) {
      this.filters[idx] = ''
      this.filtersQuery[idx] = ''
      if (idx === 'date') {
        this.filtersQuery.endDate = ''
        this.filtersQuery.startDate = ''
        this.RangePlaceholder = 'تحديد الفترة'
      }
      this.ConfigPlaceholder[idx] = this.defaultConfigPlaceholder[idx]
    },
    searchDebounce: debounce(function ($event, filedName) {
      this.setFiltervalueForInput($event, filedName)
    }, 900),
    toggleShowSortOptions() {
      this.showSortOptions = !this.showSortOptions
    },
    handleSortChange(val) {
      this.$emit('resetPagination')
      this.filtersQuery.sortByCreatedAt = val
    }
  }
}
</script>

<style scoped lang="scss">
.filter-container {
  row-gap: 10px;
  .customer-portal-input {
    ::v-deep {
      .v-input__slot {
        width: 225px;
      }
    }
  }
}
</style>
