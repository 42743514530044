<template>
  <v-dialog max-width="450px" class="px-2" persistent :value="dialogState">
    <!-------------------------------------->
    <!-------------Modal-------------------->
    <!-------------------------------------->

    <v-card class="pa-0">
      <!-------------------------------------->
      <!-------------Head modal--------------->
      <!-------------------------------------->

      <v-card-title
        class="py-0 pl-0 pr-0 d-flex justify-space-between pb-1 mobile__py__6px mobile__px__15px"
      >
        <!-------------Close modal-------------------->
        <v-btn
          @click.prevent="$emit('closeModelInviteClient')"
          data-test="closeClientModal"
          class="mr-auto"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-------------Details modal------------>
      <v-card-text class="px-0 pb-0 d-flex flex-column align-center">
        <!-- 1 - Logo -->
        <v-img max-width="70px" :src="logo" :lazy-src="logo" />

        <!-- 2 - Title -->
        <v-card-title
          class="black--text text-center card-text my-3"
          v-text="title"
        />

        <!-- 3 - Name client -->
        <p class="subtitle-1">دعوة العميل {{ client.name }}</p>
      </v-card-text>

      <!-------------Send------------>
      <div class="mt-2 mb-5 ma-5">
        <v-btn block color="primary">ارسال</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModelInviteClient',
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    client: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: 'ارسال دعوة للاشتراك في هكتار بلس',
      logo: require('@/assets/img/hectarplus.svg')
    }
  }
}
</script>
<style scoped>
.card-text {
  padding: 0 100px 0 100px !important;
}
</style>
