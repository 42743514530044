import axios from 'axios'
import config from '../config'

axios.defaults.headers.client = 'HectarPlus'
axios.interceptors.request.use(
  (headerscConfig) => {
    headerscConfig.metadata = { startTime: new Date() }
    return headerscConfig
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    response.config.metadata.endTime = new Date()
    response.duration =
      response.config.metadata.endTime - response.config.metadata.startTime
    axios.defaults.headers.common.duration = response.duration
    return response
  },
  (error) => {
    error.config.metadata.endTime = new Date()
    error.duration =
      error.config.metadata.endTime - error.config.metadata.startTime
    return Promise.reject(error)
  }
)

const addReports = function (data) {
  const url = `${config.reportsUrl}/reports`
  return axios.post(url, data)
}

const getReportsTypes = function () {
  const url = `${config.reportsUrl}/reportTypes`
  return axios.get(url)
}

const previewReports = function (data) {
  const url = `${config.reportsUrl}/reports/preview`
  return axios.post(url, data)
}

const getReports = function (query, id) {
  const url = `${config.reportsUrl}/reports/${query}&user=${id}`
  return axios.get(url)
}

const delReport = function (reportId) {
  const url = `${config.reportsUrl}/reports/${reportId}`
  return axios.delete(url)
}
const changeReportVisibility = function (reportId, body) {
  const url = `${config.reportsUrl}/reports/${reportId}`
  return axios.put(url, body)
}
const getSingleReport = function (reportId) {
  const url = `${config.reportsUrl}/reports/report/${reportId}`
  return axios.get(url)
}

const getReportFilters = function (envId) {
  const url = `${config.reportsUrl}/reports/count-reports?environment=${envId}`
  return axios.get(url)
}

const generateReportData = function (reportId, query = '', responseType = '') {
  const url = `${config.reportsUrl}/reports/generate/${reportId}?${query}`
  return axios.get(url, { responseType })
}

const getReportChart = function (query) {
  const url = `${config.reportsUrl}/general-reports/charts${query}`
  return axios.get(url)
}

const getReportsCount = function () {
  const url = `${config.reportsUrl}/reports/count`
  return axios.get(url)
}

const editReports = function (data) {
  const url = `${config.usersUrl}/reports`
  return axios.put(url, data)
}

export const reportService = {
  getReportsTypes,
  getReports,
  getReportChart,
  getSingleReport,
  getReportsCount,
  changeReportVisibility,
  addReports,
  editReports,
  delReport,
  getReportFilters,
  previewReports,
  generateReportData
}
