<template>
  <div class="mt-16 right__side__fixed__btns tab__mt__0" v-if="ifEnvironment">
    <!-- Roles -->
    <v-card
      v-for="role in getRoles"
      :key="role"
      @click="clientChange(role)"
      :class="[
        'oneSideBorder mb-2 pt-2 d-flex justify-center align-center ma-0 tag text-center pb-1',
        { 'pointer-events': ifRolesEqualOne },
        setRoleLabelStyle(role)
      ]"
      height="38"
      width="115"
    >
      <h5 class="cursor ml-2">
        {{ role | formatUserRole }}
      </h5>
      <v-icon class="pb-1" color="#fff" small v-if="clickedTag === role"
        >mdi-check-circle</v-icon
      >
    </v-card>

    <!-- If roles equal 1 will show this -->
    <v-card
      v-if="ifRolesEqualOne && !isCustomer"
      @click="openInfoClientModal"
      class="oneSideBorder pt-1 text-center cursor"
      height="38"
      width="115"
    >
      <h5 class="text-center text-primary ma-0 d-inline-block">اضافة صفة</h5>
      <v-icon
        class="mb-0 font-weight-bolder bg-primary rounded-7 mr-1 d-inline-block font-16"
        color="primary"
        >mdi-plus
      </v-icon>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SideAction',
  props: {
    client: {
      type: Object,
      required: true
    },
    clickedTag: {
      type: String,
      required: true
    },
    clientChange: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    ifEnvironment() {
      return this.client?.environments && this.client.environments.length
    },
    getRoles() {
      return this.client.environments[0].role
    },
    ifRolesEqualOne() {
      return this.client.environments[0].role.length === 1
    },
    setRoleLabelStyle() {
      return (role) => {
        let style = ''
        switch (role) {
          case 'renter':
            style += 'brub--tag'
            break
          case 'owner':
            style += 'orange--tag'
            break
          case 'buyer':
            style += 'green--tag'
            break
          case 'lead-buyer':
            style += 'blue--tag'
            break
          case 'lead-renter':
            style += 'red--tag'
            break
          default:
            style += 'gery--tag'
            break
        }
        return style
      }
    }
  },
  filters: {
    formatUserRole(role) {
      let { clientTypes } = JSON.parse(localStorage.getItem('configrations'))
      const clientType = clientTypes.find((c) => c.code === role)
      return clientType.nameAr || ''
    }
  },
  methods: {
    openInfoClientModal() {
      this.$emit('openInfoClientModal')
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/material-dashboard/_variables.scss';
.pointer-events {
  pointer-events: none;
}
.oneSideBorder:hover {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: #fff;
  h5 {
    color: #fff !important;
  }
}
</style>
