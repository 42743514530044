var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column justify-center align-center text-gray px-4 expense__limit--descp"},[_c('div',{staticClass:"d-flex justify-space-between w-100"},[_c('h5',{staticClass:"font-weight-bold mb-2 font-14"},[_vm._v("\n      لإضافة الحد الأقصي قم بالضغط علي تعديل\n    ")]),_c('v-btn',{staticClass:"ma-0 mt-1",attrs:{"icon":"","color":"primary","dark":""},on:{"click":_vm.showExpensesLimitModal}},[(_vm.hasAddPermission)?_c('v-icon',{staticClass:"font-20",attrs:{"color":"grey"}},[_vm._v("mdi-pencil-outline")]):_vm._e()],1)],1),_c('h5',{class:[
      'mt-4',
      'text-truncate',
      'font-12',
      'text-center',
      _vm.expenseLimitStatus.classes
    ]},[_vm._v("\n    "+_vm._s(_vm.expenseLimitStatus.text)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }