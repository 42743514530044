<template>
  <v-dialog
    :max-width="tab === 2 && !isPageLoading ? '1700px' : '1000px'"
    max-height="1500px"
    persistent
    v-model="dialogState_"
    class="reports__v__dialog"
  >
    <v-card class="add-new-property pb-0 add__reports__modal">
      <v-card-title
        class="py-2 px-4 mb-5 d-flex justify-space-between popup__header custom__shadow"
      >
        <h4 class="ma-0" v-if="editMood && hasReportId">تعديل تقرير</h4>
        <h4 class="ma-0" v-else>إنشاء تقرير جديد</h4>
        <div class="d-flex justify-between w-100 align-center">
          <div v-if="reportTitle" v-html="reportTitle" />
          <v-btn
            @click.prevent="closeDialog"
            class="ma-0"
            color="grey"
            fab
            text
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-tabs-items v-model="tab" class="px-5 py-2" touchless>
        <v-tab-item>
          <!-- Details -->
          <v-card-text class="pa-0">
            <v-form ref="form" v-model="isValid">
              <v-container class="pa-0">
                <v-row no-gutters>
                  <v-col class="pl-3" cols="12" sm="12">
                    <h6 class="mb-2 text-gray">عنوان التقرير*</h6>
                    <v-text-field
                      :disabled="editMood && hasReportId"
                      :rules="[(v) => !!v || 'من فضلك ادخل عنوان التقرير']"
                      dense
                      filled
                      hide-details
                      placeholder="عنوان التقرير"
                      required
                      v-model="reportObj.title"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col class="pa-0 ma-0 mt-5" cols="12" sm="12">
                    <h6 class="mb-2 text-gray">نوع التقرير *</h6>
                    <dropdown-alt
                      :configrations="{ name: '', cols: 3 }"
                      :listingType="reportTypes"
                      ref="ReportTypes"
                      :defaultValue="reportType"
                      @filterTypes="setreportDetails($event, 'subSections')"
                      @setValidate="
                        setDropdownValidation(dropdownValidate.subSections.text)
                      "
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-1 mt-4 border-0">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!stepOneValidation"
              @click="tab++"
              class="ma-1 rounded-7"
              color="primary"
            >
              <span class="mb-2 ml-1">معاينة التقرير </span>
              <v-icon class="send-icon">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <!-- Details -->
          <v-container>
            <!-- here -->
            <v-row no-gutter>
              <div class="d-flex align-center">
                <v-checkbox
                  class="ma-0 contract mr-1 rounded-15 selectall__checkbox"
                  dense
                  hide-details
                  :label="selectAllTitle"
                  v-model="selectAll"
                  @change="selectAllChange($event)"
                ></v-checkbox>
                <h6>البيانات المتوفرة</h6>
              </div>
              <v-layout
                v-if="!isItAGeneralReport || isOwnerFinancial"
                class="w-100 mt-3 pa-3"
                flex-wrap
                align-center
              >
                <v-card
                  :class="{ 'brub--tag-border': report.checked }"
                  class="d-flex align-center mx-2 mb-3 pl-2 border-radius-50px"
                  :key="'sub-section-' + i"
                  height="30"
                  v-for="(report, i) in subFiled"
                  min-width="120px"
                  max-width="240px"
                >
                  <v-checkbox
                    class="ma-0 contract mr-1 rounded-15"
                    dense
                    hide-details
                    v-model="report.checked"
                  ></v-checkbox>

                  <div class="text-center">
                    <h6
                      :class="{ 'text-primary': report.checked }"
                      class="font-weight-medium ma-0 text-truncate font-16"
                    >
                      {{ report.nameAr }}
                    </h6>
                  </div>
                </v-card>
              </v-layout>
              <v-layout v-else class="w-100 mt-3 pa-3 flex-wrap align-center">
                <v-row
                  class="w-100"
                  v-for="(mainReport, i) in mainReports"
                  :key="i"
                >
                  <div class="d-flex flex-wrap">
                    <h5
                      :class="{ 'text-primary': report.checked }"
                      class="font-weight-bold ma-0 text-truncate font-16"
                    >
                      {{ mainReport.nameAr }} :
                    </h5>
                    <div
                      v-for="(report, i) in subFiled"
                      :key="'sub-section-' + i"
                    >
                      <v-card
                        :class="{ 'brub--tag-border': report.checked }"
                        class="mb-3 d-flex align-center mx-2 pl-2 border-radius-50px"
                        height="30"
                        min-width="120px"
                        max-width="240px"
                        v-if="
                          mainReport.code === report.categoryCode ||
                          mainReport.code === report.code
                        "
                      >
                        <v-checkbox
                          class="ma-0 contract mr-1 rounded-15"
                          dense
                          hide-details
                          v-model="report.checked"
                        ></v-checkbox>

                        <div class="text-center">
                          <h5
                            :class="{ 'text-primary': report.checked }"
                            class="font-weight-medium ma-0 text-truncate font-16 text-truncate"
                          >
                            {{ report.nameAr }}
                          </h5>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </v-row>
              </v-layout>

              <!-- ------------------------------------------------->
              <!-- Under legal lction ltatus for dues report only -->
              <!-- ------------------------------------------------->
              <div v-if="ifDuesReport" class="d-flex align-center ps-3">
                <v-checkbox
                  :class="[
                    'ma-0 contract font-weight-medium mr-1 rounded-15',
                    { 'change-label-color': underLegalActionStatus }
                  ]"
                  dense
                  hide-details
                  label="إضافة المتأخرات التي قيد الإجراء القانوني"
                  v-model="underLegalActionStatus"
                />
              </div>

              <!-- -------------- -->
              <!-- REPORTS FILTER -->
              <!-- -------------- -->
              <ReportsFilter
                class="w-100"
                :data="reportsFilterData"
                :isOwnerFinancial="isOwnerFinancial"
                @getUnits="getUnits"
                @setSelectedUnits="setSelectedUnits"
              />

              <!-- ----- -->
              <!-- DATES -->
              <!-- ----- -->
              <v-col cols="12" v-if="isDateAvailable" class="d-flex pa-0">
                <v-col class="pl-3 pt-0 pr-0" cols="12" sm="6">
                  <h6 :class="['mb-2', startDateTextClass, 'mt-0']">
                    {{ startDateText }}
                  </h6>
                  <v-dialog
                    :return-value.sync="reportObj.start"
                    ref="startAtDialog"
                    v-model="startAtModal"
                    width="320px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="[(v) => !!v || 'من فضلك ادخل تاريخ البدايه']"
                        append-icon="mdi-calendar-month-outline"
                        dense
                        filled
                        required
                        hide-details
                        :placeholder="startDateText"
                        readonly
                        v-bind="attrs"
                        v-model="reportObj.start"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      scrollable
                      v-model="start"
                      :max="today"
                    >
                      <v-spacer></v-spacer>
                      <v-btn @click="startAtModal = false" color="primary" text>
                        الغاء
                      </v-btn>
                      <v-btn
                        @click="$refs.startAtDialog.save(start)"
                        color="primary"
                        text
                        >حفظ
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col class="pt-0 pr-0" cols="12" sm="6">
                  <h6 :class="['mb-2', endDateTextClass, 'mt-0']">
                    {{ endDateText }}
                  </h6>
                  <v-dialog
                    :return-value.sync="reportObj.end"
                    ref="endAtDialog"
                    v-model="endAtModal"
                    width="320px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="[(v) => !!v || 'من فضلك ادخل تاريخ الانتهاء']"
                        append-icon="mdi-calendar-month-outline"
                        dense
                        required
                        filled
                        hide-details
                        :placeholder="endDateText"
                        readonly
                        v-bind="attrs"
                        v-model="reportObj.end"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="start"
                      color="primary"
                      scrollable
                      v-model="end"
                    >
                      <v-spacer></v-spacer>
                      <v-btn @click="endAtModal = false" color="primary" text>
                        الغاء
                      </v-btn>
                      <v-btn
                        @click="$refs.endAtDialog.save(end)"
                        color="primary"
                        text
                        >حفظ
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-col>
            </v-row>
            <v-row v-if="isAvailableClient">
              <v-col cols="12" sm="6">
                <v-card class="textTile pb-3 px-4 mt-2">
                  <div class="holder d-flex align-center pt-2">
                    <div class="mr-1 ml-2">
                      <v-img :src="exclamationMarkrounded" />
                    </div>
                    <div class="text">
                      <p class="font-14 font-weight-bold ma-0">
                        هذا {{ userType }} {{ isClineRegistered }} حساب فى بوابة
                        العملاء
                      </p>
                    </div>
                    <v-spacer />
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card
                  class="pb-3 px-4 mt-2 py-1 d-flex ga-2 align-center"
                  :disabled="isClineRegistered.includes('ليس لديه')"
                >
                  <v-switch
                    class="my-auto"
                    dense
                    hide-details="auto"
                    inset
                    small
                    v-model="reportObj.accessibleToClient"
                  />
                  <span
                    class="font-weight-bold font-14"
                    :class="{ 'text-gray': !reportObj.accessibleToClient }"
                  >
                    <!-- text-gray -->
                    إظهار التقرير لقائمة التقارير فى حساب {{ userType }}
                  </span>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <!--  -->
          <v-card-actions class="pa-1 mt-4 border-0">
            <v-spacer />
            <v-btn
              :disabled="!stepTwoValidation"
              @click="handleNextTab"
              class="ma-1 rounded-7"
              color="primary"
            >
              <span class="mobile__mb__0 ml-1 font-weight-bold font-12"
                >التالي</span
              >
              <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <div
            class="overflow-x-hidden max-height-50vh"
            :class="[isPageLoading ? 'overflow-y-hidden' : 'overflow-auto']"
          >
            <!-- If no data in General Financial report owner and renter -->
            <no-data
              v-if="isThereNoDataForOwnerAndRenterReports && !isPageLoading"
              v-bind="noDataProps"
            />
            <!-- Details -->
            <!-- LODER -->
            <v-row
              class="min-height-195 d-flex align-center mx-0 pa-3"
              v-else-if="isPageLoading"
            >
              <Loader :completion="reportCompletion">
                <template #filedName>
                  <h4
                    class="font-weight-medium mt-14"
                    v-if="reportFiledNameToBeDisplayedWithLoader"
                  >
                    يتم الان تحميل
                    <span class="text-primary font-weight-bold">
                      {{ reportFiledNameToBeDisplayedWithLoader }}
                    </span>
                    ....
                  </h4>
                </template>
              </Loader>
            </v-row>
            <v-row class="d-block" v-else-if="isItAGeneralReport">
              <v-layout
                v-if="!isThereNoDataForOwnerAndRenterReports"
                class="w-100 mt-3 pa-3"
                flex-wrap
                align-center
              >
                <template v-for="(mainReport, i) in subFiled">
                  <v-card
                    :class="{ 'brub--tag-border': mainReport.checked }"
                    class="d-flex align-center mx-2 mb-3 pl-2 border-radius-50px"
                    :key="'sub-section-' + i"
                    height="30"
                    min-width="120px"
                    max-width="240px"
                  >
                    <v-checkbox
                      class="ma-0 contract mr-1 rounded-15"
                      dense
                      hide-details
                      v-model="mainReport.checked"
                    ></v-checkbox>

                    <div class="text-center">
                      <h6
                        :class="{ 'text-primary': mainReport.checked }"
                        class="font-weight-medium ma-0 text-truncate font-16 text-truncate"
                      >
                        {{ mainReport.nameAr }}
                      </h6>
                    </div>
                  </v-card>
                </template>
              </v-layout>
              <div
                class="mx-5"
                v-for="(mainreport, i) in avaliableReportTables"
                :key="i"
              >
                <div
                  v-if="
                    headers[mainreport.code] &&
                    headers[mainreport.code].length &&
                    reports[mainreport.code] &&
                    (reports[mainreport.code]?.body?.length ||
                      reports[mainreport.code]?.length)
                  "
                >
                  <div v-if="mainreport.code === 'charts'">
                    <div class="transaction__detail__title">
                      <h3 class="mt-8 mb-0">{{ mainreport.nameAr }}</h3>
                    </div>
                    <InfoGraphicSummary :isAddModel="true" :reports="reports" />
                  </div>
                  <div v-else-if="isSummaryTable(reports[mainreport.code])">
                    <div
                      v-if="!isThereNoDataForOwnerAndRenterReports"
                      class="after_table_div after__table__block"
                    >
                      <div class="after_table_content">
                        <div
                          class="transaction_detail after__table__details__block"
                        >
                          <div
                            v-for="(report, i) in reports[mainreport.code]"
                            :key="i"
                            class="d-block result__total align-items-center"
                          >
                            <div v-if="isTotalTable(report)">
                              <div class="transaction__detail__title">
                                <h3 class="mt-8 mb-0">
                                  {{ report.title || 'الملخص العام ' }}
                                </h3>
                              </div>
                              <div class="d-flex">
                                <h5 class="mw-28" />
                                <h5
                                  v-for="key in totalTableKeys"
                                  :key="key"
                                  class="grey--text fw-500 text-center my-2 mw-24"
                                >
                                  {{ key }}
                                </h5>
                              </div>
                              <div v-for="(record, i) in report" :key="i">
                                <template v-if="i === 'totals'">
                                  <div v-for="(data, j) in record" :key="j">
                                    <div class="d-flex mb-1">
                                      <h6
                                        class="fw-400 mt-8 mb-5 mw-28 text-h5 d-flex justify-space-between align-center"
                                        :class="data.type"
                                      >
                                        {{ data.name || '-' }}
                                        <v-tooltip
                                          v-if="
                                            generateTooltipData(data.name)
                                              .status
                                          "
                                          content-class="v-tool"
                                          color="black"
                                          min-width="95"
                                          bottom
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <div v-bind="attrs" v-on="on">
                                              <img
                                                class="mt-1"
                                                :src="tooltipIcon"
                                              />
                                            </div>
                                          </template>
                                          <span
                                            class="tool--tip font-12 font-weight-medium"
                                            >{{
                                              generateTooltipData(data.name)
                                                .text
                                            }}</span
                                          >
                                        </v-tooltip>
                                      </h6>
                                      <h6
                                        class="fw-500 text-center text-h5 mt-8 mb-5 mw-24"
                                      >
                                        {{ data.amount || '-' }}
                                      </h6>
                                      <h6
                                        class="fw-500 text-center text-h5 mt-8 mb-5 mw-24"
                                      >
                                        {{ data.tax || '-' }}
                                      </h6>
                                      <h6
                                        class="fw-500 text-center text-h5 mt-8 mb-5 mw-24"
                                      >
                                        {{ data.total || '-' }}
                                      </h6>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="!isThereNoDataForOwnerAndRenterReports">
                    <h3>{{ mainreport.nameAr }}</h3>
                    <data-table-reports
                      :forPreviewing="true"
                      :headers="restrctureHeaders(headers[mainreport.code])"
                      :reports="reDesignReportBody(mainreport)"
                      tableClasses="totalTable"
                    />
                  </div>
                </div>
              </div>
            </v-row>
            <v-row v-else no-gutters>
              <v-col v-if="reports.length" class="property-col" cols="12">
                <v-layout class="w-100" flex-wrap align-center>
                  <v-card
                    v-for="(header, i) in lastTabHeaders"
                    :class="{ 'brub--tag-border': header.checked }"
                    class="d-flex align-center mx-2 mb-3 pl-2 border-radius-50px"
                    :key="`header-${i}`"
                    height="30"
                    min-width="120px"
                    max-width="240px"
                  >
                    <v-checkbox
                      class="ma-0 contract mr-1 rounded-15"
                      dense
                      hide-details
                      v-model="header.checked"
                    ></v-checkbox>
                    <div class="text-center">
                      <h6
                        :class="{ 'text-primary': header.checked }"
                        class="font-weight-medium ma-0 text-truncate font-16 text-truncate"
                      >
                        {{ header.text }}
                      </h6>
                    </div>
                  </v-card>
                  <v-card
                    v-if="isRowTotal"
                    :class="{ 'brub--tag-border': totlaRowBtn.checked }"
                    class="d-flex align-center mx-2 mb-3 pl-2 border-radius-50px"
                    height="30"
                    min-width="120px"
                    max-width="240px"
                  >
                    <v-checkbox
                      class="ma-0 contract mr-1 rounded-15"
                      dense
                      hide-details
                      v-model="totlaRowBtn.checked"
                    ></v-checkbox>
                    <div class="text-center">
                      <h6
                        :class="{ 'text-primary': totlaRowBtn.checked }"
                        class="font-weight-medium ma-0 text-truncate font-16 text-truncate"
                      >
                        {{ totlaRowBtn.text }}
                      </h6>
                    </div>
                  </v-card>
                </v-layout>
                <data-table-reports v-bind="dataTableReports" />
              </v-col>
              <!--no reports-->
              <no-data v-else v-bind="noDataProps" />
            </v-row>
          </div>

          <v-card-actions class="pa-1 mt-4 border-0">
            <v-btn
              @click="handleBackTab"
              class="ma-0 rounded-7"
              color="gery"
              text
              x-large
            >
              <v-icon class="ma-0">mdi-arrow-right</v-icon>
              <span class="mb-3">السابق</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="reports.length == 0"
              @click="editReport"
              class="ma-0 rounded-7"
              color="primary"
              v-if="editMood && hasReportId"
            >
              <span class="mobile__mb__0 ml-1 font-weight-bold font-12"
                >تعديل التقرير</span
              >
              <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
            </v-btn>
            <v-btn
              :disabled="statusDisableGenerateReportBtn"
              :loading="addBtnLoading"
              @click="addRport"
              class="ma-0 rounded-7"
              color="primary"
              v-else
            >
              <span class="mobile__mb__0 ml-1 font-weight-bold font-12"
                >انشاء التقرير</span
              >
              <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import '@/styles/material-dashboard/_variables.scss';
.change-label-color {
  & .v-label {
    color: $primary-color !important;
  }
}
</style>

<script>
import DropdownAlt from '@/components/add-unit/dropdownAlt'
import Loader from '@/components/common/Loader.vue'
import noData from '@/components/helper/noData.vue'
import ReportsFilter from '@/components/reports/ReportsFilter'
import config from '@/config'
import reportTotalArabicKeys from '@/constans/reportTotalArabicKeys.js'
import confirmationMixin from '@/mixins/confirmationMixin.js'
import dateFormat from '@/mixins/dateFormat.js'
import { hasValue } from '@/utils/general.js'
import { mapActions, mapMutations, mapState } from 'vuex'
import { EnvService, realEstateService } from '../../services'
import { reportService } from '../../services/report.service'
import DataTableReports from '../reports/data-table-reports'

export default {
  components: {
    DataTableReports,
    DropdownAlt,
    noData,
    ReportsFilter,
    Loader,
    InfoGraphicSummary: () =>
      import('@/components/reports/OwnerReportTabs/InfoGraphicSummary.vue')
  },
  mixins: [dateFormat, confirmationMixin],
  props: {
    reportCode: {
      default: ''
    },
    reportTypes: {
      default() {
        return []
      },
      type: Array
    },
    dialogState: {
      type: Boolean
    },
    report: {
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      addBtnLoading: false,
      exclamationMarkrounded: require('@/assets/img/svgs/exclamationMarkrounded.svg'),
      selectedUnits: [],
      availableUnits: [],
      underLegalActionStatus: false,
      tooltipIcon: require('@/assets/img/svgs/exclamation-white-rounded.svg'),
      totalTableKeys: [
        'المبلغ',
        'قيمه الضريبه',
        'الإجمـــــــــــــــــالــــــي'
      ],
      generalReportsKeys: ['generalRenterFinancial', 'generalOwnerFinancial'],
      totlaRowBtn: {
        checked: true,
        text: 'المجموع',
        value: 'total'
      },
      totalRowData: {},
      isPageLoading: false,
      dropdownValidate: {
        subSections: {
          text: 'subSections',
          val: false
        }
      },
      autoCompleteReset: false,
      initQueryBody: {},
      clientIsLoading: false,
      unitIsLoading: false,
      realestateIsLoading: false,
      reportObj: {
        sections: {}
      },
      isValid: false,
      tab: 1,
      reportsTypes: [],
      headers: [],
      reports: [],
      today: new Date().toISOString().substr(0, 10),
      start: new Date().toISOString().substr(0, 10),
      end: new Date().toISOString().substr(0, 10),
      startAtModal: false,
      endAtModal: false,
      subSections: [],
      subFiled: [],
      selectAll: false,
      employees: [],
      addClientType: false,
      editMood: false,
      users: [],
      AllUsers: [],
      clientSearchKey: '',
      unitSearchKey: '',
      reportSelectedCriteria: {
        user: [],
        realEstate: [],
        units: []
      },
      paginationClient: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationProperty: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationUnits: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      properties: [],
      AllProperties: [],
      propertySearchKey: '',
      propertyToShow: 'propertyName',
      reportFilter: false,
      switchToRealEstate: false,
      envId: JSON.parse(localStorage.getItem('currentEnv'))._id,
      noDataConfig: {
        text: 'لا توجد تقارير متاحه',
        icon: require('@/assets/img/reports/3.svg'),
        subTitle: 'لا  يمكنك انشاء تقرير بدون بيانات',
        height: '20vh'
      },
      mainReports: [],
      reportTotalArabicKeys: reportTotalArabicKeys.arabicKeys,
      reportFiledNameToBeDisplayedWithLoader: '',
      reportCompletion: false,
      reportsForTenents: [
        'generalRenterFinancial',
        'renterAccountStatement',
        'renterStatement',
        'rented',
        'claims'
      ],
      reportsForNonChild: [
        'expenses',
        'tenantsStatement',
        'dues',
        'vacancies',
        'units'
      ]
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'status', 'loggedIn', 'currentEnv']),
    getPropertiesQuery() {
      return {
        environments: [this.currentEnv._id],
        ...(this.selectedClientsIds.length && {
          users: this.selectedClientsIds
        }),
        ...(this.reportsForTenents.includes(this.reportCode) && {
          userType: 'tenet'
        }),
        ...(!this.switchToRealEstate &&
          !this.reportsForTenents.includes(this.reportCode) && {
            userType: 'owner',
            child: false
          }),
        ...(this.reportsForNonChild.includes(this.reportCode) && {
          child: false
        })
      }
    },
    isAvailableClient() {
      return this.options?.clients.available
    },
    userType() {
      return this.options?.clients.type === 'owner' ? 'المالك' : 'المستأجر'
    },
    isOwnerFinancial() {
      return !!(this.reportCode === 'generalOwnerFinancial')
    },
    isRenterFinancial() {
      return !!(this.reportCode === 'generalRenterFinancial')
    },
    reportTypesfilterdOnlyByClient() {
      return ['claims', 'tenantsStatement', 'rented']
    },
    isThereNoDataForOwnerAndRenterReports() {
      let isThereData = 0
      if (!this.isItAGeneralReport) {
        return false
      }

      Object.values(this.reports).forEach((item) => {
        isThereData += Object.keys(item).length
      })
      return !isThereData
    },
    statusDisableGenerateReportBtn() {
      return (
        this.reports.length === 0 ||
        this.isThereNoDataForOwnerAndRenterReports ||
        (this.isItAGeneralReport && !this.isThereAtLeastMainReportChecked)
      )
    },
    dateTypeText() {
      const dates = config.reportPrefixes.dates
      return dates.titles[dates.types[this.reportCode]]
    },
    startDateText() {
      return this.reportCode === 'pmCommissionsRevenue'
        ? 'تاريخ بداية حساب الأتعاب'
        : !this.dateTypeText
        ? 'تاريخ البداية'
        : `تاريخ بداية ${this.dateTypeText}`
    },
    isUserRegistered() {
      return this.reportSelectedCriteria.user
    },
    maxEndDate() {
      if (this.reportCode === 'claims') {
        const date = new Date()
        date.setFullYear(date.getFullYear() + 9)
        return date.toISOString().substr(0, 10)
      }
      if (this.reportCode !== 'tenantsStatement') {
        return this.today
      }

      return null
    },
    endDateText() {
      return this.reportCode === 'pmCommissionsRevenue'
        ? 'تاريخ نهاية حساب الأتعاب'
        : !this.dateTypeText
        ? 'تاريخ النهاية'
        : `تاريخ نهاية ${this.dateTypeText}`
      // return !this.dateTypeText
      //   ? 'تاريخ النهاية'
      //   : this.reportCode === 'pmCommissionsRevenue'
      //   ? 'تاريخ نهاية حساب الأتعاب'
      //   : `تاريخ نهاية ${this.dateTypeText}`
    },
    isThereAtLeastMainReportChecked() {
      const availablMainReports = []
      this.mainReports.map((R) => {
        if (R.code && this.headers[R.code]?.length) {
          availablMainReports.push(R)
        }
      })
      return availablMainReports.some((R) => R.checked)
    },
    avaliableReportTables() {
      const result = this.subFiled.filter((R) => R.checked)
      return result
    },
    isItAGeneralReport() {
      return !!this.generalReportsKeys.includes(this.reportCode)
    },
    clientMultipleReportsKeys() {
      return [
        ...this.generalReportsKeys,
        'ownerAccountStatement',
        'renterStatement'
      ]
    },
    isClientFilterMultiple() {
      return !!this.clientMultipleReportsKeys.includes(this.reportCode)
    },
    subFiledsTotalChecking() {
      let subFiledsTotalCheck = [...this.reStructureSubFileds]
      if (!this.totlaRowBtn.checked && subFiledsTotalCheck.length) {
        subFiledsTotalCheck = subFiledsTotalCheck.filter((filed) => {
          return filed.code !== 'total'
        })
      }
      return subFiledsTotalCheck
    },
    dataTableReports() {
      return {
        forPreviewing: true,
        headers: this.previewedHeader,
        reports: this.reports,
        totalRowData: this.totalRowData,
        totlaRowBtn: this.totlaRowBtn.checked,
        tableClasses: this.tableClasses
      }
    },
    tableClasses() {
      return this.isRowTotal && this.totlaRowBtn.checked ? 'totalTable' : ''
    },
    isRowTotal() {
      const isFounded = this.reStructureSubFileds.find((filed) => {
        return filed.code === 'total'
      })
      return !!(isFounded && Object.keys(isFounded).length)
    },
    previewedHeader() {
      if (this.headers.length) {
        return this.headers.filter(
          (header) => header.checked && header.value !== 'total'
        )
      }
      return []
    },
    lastTabHeaders() {
      if (this.headers.length) {
        return this.headers.filter((header) => header.value !== 'total')
      }
      return []
    },
    noDataProps() {
      return {
        noDataText: this.noDataConfig.text,
        noDataIcon: this.noDataConfig.icon,
        noDataSubTitle: this.noDataConfig.subTitle,
        height: this.noDataConfig.height
      }
    },
    getReportTypeCategoryName() {
      return this.reportObj?.reprtsTypeData?.categoryNameEn ?? ''
    },
    stepOneValidation() {
      const reportTypesNames = Object.values(this.dropdownValidate)
      const isReportTypeVaild = reportTypesNames.every((v) => v.val)
      return this.isValid && isReportTypeVaild
    },
    reportType() {
      return this.reportObj?.sections?.subSections?.name ?? ''
    },
    reportTitle() {
      return (
        `<h5 class="ma-0">
          ${this.reportObj?.title}
        </h5>
        <h6 class="ma-0">
          ${this.formatDate(this.start)} - ${this.formatDate(this.end)}
        </h6>` ?? ''
      )
    },
    hasReportId() {
      return !!this.report?._id?.length
    },
    selectedOption() {
      return !this.switchToRealEstate ? this.generatePrefix?.title : 'عقار'
    },
    selectedReportType() {
      let selectedReports = []
      selectedReports = this.reportsTypes.filter((c) => c.checked === true)
      return !!selectedReports.length
    },
    reportOption() {
      return this.reportTypesfilterdOnlyByClient.includes(this.reportCode)
        ? config.reportOption.filter((option) => option.code !== 'realestate')
        : config.reportOption
    },
    team() {
      return config.team
    },
    stepTwoValidation() {
      if (this.reportObj?.reprtsTypeData?.sectionFields) {
        return this.isDateAvailable
          ? !!(
              this.reportObj.start &&
              this.reportObj.end &&
              this.sectionFieldsValidation &&
              this.clientRequirement &&
              this.realestateRequiemrnt
            )
          : !!(
              this.sectionFieldsValidation &&
              this.clientRequirement &&
              this.realestateRequiemrnt
            )
      }
      return false
    },
    selectAllTitle() {
      return this.selectAll ? 'الغاء الكل' : 'تحديد الكل'
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    },
    reStructureSubFileds() {
      const data = []
      if (this.headers.length) {
        this.headers.map((singleFiled) => {
          data.push({
            nameAr: singleFiled.text,
            nameEn: singleFiled.value,
            code: singleFiled.value,
            checked: singleFiled.checked
          })
        })
      } else if (this.reportObj?.sectionFields?.length) {
        this.reportObj?.sectionFields.map((singleFiled) => {
          data.push({
            nameAr: singleFiled.nameAr,
            nameEn: singleFiled.nameEn,
            code: singleFiled.code,
            categoryAr: singleFiled.categoryAr,
            categoryCode: singleFiled.categoryCode,
            categoryEn: singleFiled.categoryEn,
            checked: singleFiled.checked
          })
        })
      }
      return data ?? []
    },
    reportToBeEdit() {
      const data = {
        _id: this.reportObj._id,
        user: this.reportObj.user?.length ? this.reportObj.user : null,
        realEstate: this.reportObj.realEstate?.length
          ? this.reportObj.realEstate
          : null,
        sections: {
          name: this.reportObj.sections?.name,
          subSections: {
            reportsType:
              this.reportObj.sections?.subSections?.reportsType ||
              this.reportObj.sections?.subSections?._id ||
              this.getSubSectionId,
            name: this.reportObj.sections?.subSections?.name,
            sectionFields: this.reStructureSubFileds.filter(
              (field) => field.checked
            )
          }
        },
        title: this.reportObj?.title,
        start: this.reportObj?.start,
        end: this.reportObj?.end,
        moderator: {
          _id: this.reportObj?.moderator._id,
          name: this.reportObj?.moderator.name
        },
        environment: this.envId
      }
      return data
    },
    reportToAdd() {
      const reportData = {
        reportsType: this.reportObj.reportsType,
        sectionFields: this.subFiledsTotalChecking.filter((e) => e.checked),
        title: this.reportObj.title,
        start: this.reportObj.start,
        end: this.reportObj.end,
        moderator: this.reportObj.moderator,
        environment: this.reportObj.environment,
        realEstate: this.initQueryBody.realEstate,
        user: this.initQueryBody.user,
        accessibleToClient: this.reportObj.accessibleToClient,
        includeUnderLegalActionArrears: this.underLegalActionStatus
      }
      return reportData
    },
    getSubSectionId() {
      // temporary till the back end remove reportsType property from the report model
      const subSection = this.reportTypes.find(
        (R) => R.name === this.reportObj?.sections?.subSections?.name
      )

      return subSection?._id
    },
    reportsClinetMandatoryKeys() {
      // Add report code here for any report we want to make client input required
      return [
        ...this.generalReportsKeys,
        'ownerAccountStatement',
        'renterStatement'
      ]
    },
    reportsRealestaeMandatoryKeys() {
      // Add report code here for any report we want to make realstate input required
      return this.generalReportsKeys
    },
    isItReportRealestateMandatory() {
      return this.reportsRealestaeMandatoryKeys.includes(this.reportCode)
    },
    isItReportClientMandatory() {
      return this.reportsClinetMandatoryKeys.includes(this.reportCode)
    },
    clientRequirement() {
      return this.isItReportClientMandatory && !this.switchToRealEstate
        ? !!this.reportSelectedCriteria.user &&
            (Object.keys(this.reportSelectedCriteria.user).length ||
              this.reportSelectedCriteria.user.length)
        : true
    },
    realestateRequiemrnt() {
      return this.isItReportRealestateMandatory && this.switchToRealEstate
        ? !!this.reportSelectedCriteria.realEstate &&
            Object.keys(this.reportSelectedCriteria.realEstate).length
        : true
    },
    sectionFieldsValidation() {
      return this.subFiled.some((sub) => sub.checked)
    },
    startDateTextClass() {
      return this.reportObj.start ? 'text-gray' : 'red--flag'
    },
    endDateTextClass() {
      return this.reportObj.end ? 'text-gray' : 'red--flag'
    },
    reportsFilterData() {
      return {
        showRealEstats: this.showRealEstats,
        showClients: this.showClients,
        showUnits: this.showUnits,
        clientIsLoading: this.clientIsLoading,
        unitIsLoading: this.unitIsLoading,
        allProperties: this.AllProperties,
        availableUnitsForParent: this.availableUnits,
        allUsers: this.AllUsers,
        realestateIsLoading: this.realestateIsLoading,
        getClientDataBySearchKey: this.getClientDataBySearchKey,
        getUnitDataBySearchKey: this.getUnitDataBySearchKey,
        isItAGeneralReport: this.isItAGeneralReport,
        isClientFilterMultiple: this.isClientFilterMultiple,
        getReportTypeCategoryName: this.getReportTypeCategoryName,
        clientRequirement: this.clientRequirement,
        realestateRequiemrnt: this.realestateRequiemrnt,
        getClientNextData: this.getClientNextData,
        reportSelectedCriteria: this.reportSelectedCriteria,
        getPropertyNextData: this.getPropertyNextData,
        getPropertyDataBySearchKey: this.getPropertyDataBySearchKey,
        generatePrefix: this.generatePrefix,
        selectedOption: this.selectedOption,
        reportOption: this.reportOption,
        onReportOptionChange: this.onReportOptionChange,
        showSwitch: this.showSwitch
      }
    },
    options() {
      return this.report?.reprtsTypeData?.options
    },
    clientType() {
      return this.options?.clients?.type !== 'all'
    },
    showSwitch() {
      return (
        this.options?.realestates.available && this.options?.clients.available
      )
    },
    generatePrefix() {
      return config?.reportPrefixes[this.options?.clients?.type]
    },
    showRealEstats() {
      return this.options?.realestates.available && this.switchToRealEstate
    },
    showClients() {
      return this.options?.clients.available && !this.switchToRealEstate
    },
    showUnits() {
      return this.options?.units?.available ?? false
    },
    isDateAvailable() {
      return this.options?.dates.available
    },
    selectedClientsIds() {
      if (
        this.reportSelectedCriteria.user?.length === 0 ||
        typeof this.reportSelectedCriteria.user === 'undefined'
      ) {
        return []
      }
      return this.isClientFilterMultiple
        ? [this.reportSelectedCriteria.user._id]
        : this.reportSelectedCriteria.user.map((user) => user._id)
    },
    ifDuesReport() {
      return this.reportCode === 'dues'
    },
    isClineRegistered() {
      return this.reportSelectedCriteria?.user?.environments?.[0]
        .customerRegistered
        ? 'لديه'
        : 'ليس لديه'
    }
  },
  watch: {
    report(newValue) {
      if (!Object.keys(newValue).length) {
        return
      }
      this.dropdownValidate.subSections.val = true
      this.reportSubTypeChange()
      this.reportObj = { ...newValue }
      this.editMood = true
      if (this.checkIfIsIrExistedReport(newValue)) {
        const selectedType = this.getAllSubfileds(newValue?.reportsType)
        const checkedCodes = []
        newValue?.reprtsTypeData.sectionFields.map((subfiled) => {
          checkedCodes.push(subfiled.code)
        })
        selectedType?.sectionFields.map((filed) => {
          if (checkedCodes.includes(filed.code)) {
            filed.checked = true
          }
        })
        newValue.reprtsTypeData.sectionFields = [...selectedType.sectionFields]
      }
      this.subFiled = newValue?.reprtsTypeData.sectionFields
      if (this.isItAGeneralReport) {
        this.generatingComplexReportObj(this.subFiled)
      }
      const subFiledChecks = []
      this.subFiled.map((sub) => {
        subFiledChecks.push(sub.checked ? sub.checked : false)
      })
      this.selectAll = subFiledChecks.every((val) => val === true)
      if (
        Object.keys(this.reportSelectedCriteria)?.length &&
        this.reportSelectedCriteria?.user.length
      ) {
        this.reportSelectedCriteria.user = [...newValue.user]
      }
      if (
        Object.keys(this.reportSelectedCriteria)?.length &&
        this.reportSelectedCriteria?.realEstate.length
      ) {
        this.reportSelectedCriteria.realEstate = [...newValue.realEstate]
      }
    },
    switchToRealEstate: {
      handler(val) {
        this.resetPaginationNumber()
        if (val) {
          this.reportSelectedCriteria.realEstate = []
          this.AllUsers = []
          this.getProperties()
        } else {
          this.reportSelectedCriteria.user = []
          this.AllProperties = []
          this.reportSubTypeChange()
        }
      }
    },
    'reportSelectedCriteria.user'() {
      if (!this.switchToRealEstate) {
        this.reportSelectedCriteria.realEstate = []
        this.AllProperties = []
        this.paginationProperty.pageNumber = 1
        this.selectedClientsIds.length && this.getProperties()
      }
    },
    'reportSelectedCriteria.realEstate'(val) {
      if (this.switchToRealEstate && !hasValue(val)) {
        this.reportSelectedCriteria.user = []
        this.AllUsers = []
      }
      if (!hasValue(val) || typeof val === 'undefined') {
        return
      }
      if (this.isOwnerFinancial && this.switchToRealEstate) {
        this.AllUsers = []
        this.reportSelectedCriteria.user = val.owner
      }
      if (this.reportCode === 'dues') {
        val.forEach((user) => this.AllUsers.push(...user.tenants))
      } else if (val.length) {
        val.forEach((user) => this.AllUsers.push(user.owner))
      } else {
        this.AllUsers.push(val.owner)
      }
    },
    dialogState_(val) {
      this.headers = [] // reset headers when the pop up changes as it is effecting the table preview
      this.setAllFilesToActive()

      if (!val) {
        this.reportSelectedCriteria = {
          user: [],
          realEstate: []
        }
        this.resetPaginationNumber()
      }
    }
  },
  created() {
    this.loadEmployees()
  },
  methods: {
    ...mapActions('accountState', ['getAllClients']),
    ...mapMutations('appState', ['addNotification']),
    reDesignReportBody({ code }) {
      const { headers, body, ...totals } = this.reports[code]

      if (body) {
        return [...this.reports[code].body, { ...totals }]
      }
      return this.reports[code]
    },
    isSummaryTable(table) {
      return !!table[1]?.totals
    },
    isTotalTable(table) {
      return !!table?.totals?.length
    },
    setAllFilesToActive() {
      setTimeout(() => {
        this.selectAll = true
        this.selectAllChange(this.selectAll)
      }, 700)
    },
    setSelectedUnits(units) {
      if (units?.length && units[0]?._id?.length) {
        this.selectedUnits = units
      } else {
        this.selectedUnits = []
      }
    },
    async getUnits(realestates) {
      if (!realestates.length) {
        this.availableUnits = []
        return
      }
      try {
        this.unitIsLoading = true
        const body = {
          environments: [this.currentEnv._id],
          parentId: realestates[0]._id
        }
        let _data = `pageSize=${this.paginationUnits.pageSize}&pageNumber=${this.paginationUnits.pageNumber}`
        this.unitSearchKey && (_data += `&search=${this.unitSearchKey}`)
        const { data } = await realEstateService.getRealEstates(body, _data)
        this.paginationUnits.pagesCount = Math.ceil(
          data?.count / data?.pageSize
        )
        this.availableUnits = data?.realEstates
        this.availableUnits.unshift({
          propertyName: 'كل الوحدات',
          allocated: true
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل العقارات المتاحه, برجاء المحاوله لاحقا',
          color: 'error'
        })
        this.closeDialog()
      } finally {
        this.unitIsLoading = false
        this.selectedUnits = []
      }
    },
    generateTooltipData(cellName) {
      const isRentIncome = cellName === 'إيرادات الإيجار'
      const isServicesIncome = cellName === 'إيرادات الخدمات'
      return {
        status: isRentIncome || isServicesIncome,
        text: isRentIncome
          ? 'إجمالي قيم التأجير المستلمة من المستأجرين'
          : 'إجمالي قيم الخدمات المستلمة من المستأجرين من مياه وغاز وكهرباء'
      }
    },
    generatingComplexReportObj(data) {
      const subFieldsCopy = [...data]
      subFieldsCopy.map((filed) => {
        this.mainReports.push({
          code: this.isOwnerFinancial ? filed.code : filed.categoryCode,
          nameAr: this.isOwnerFinancial ? filed.nameAr : filed.categoryAr,
          nameEn: this.isOwnerFinancial ? filed.nameEn : filed.categoryEn,
          checked: true
        })
      })
      const codes = this.mainReports.map((R) => R.code)
      this.mainReports = this.mainReports.filter(
        ({ code }, index) => !codes.includes(code, index + 1)
      )
    },
    bindTotalData(response) {
      const totalRow = {}
      _.map(response, (val, key) => {
        if (key !== 'headers' && key !== 'body') {
          totalRow[key] = val
        }
      })
      if (this.reports.length && Object.keys(totalRow).length) {
        this.reports.push(totalRow)
        this.totalRowData = totalRow
      }
    },
    checkIfIsIrExistedReport(report) {
      for (const key in report) {
        return key === '_id'
      }
      return false
    },
    getAllSubfileds(reportTypeId) {
      const selectedType = this.reportTypes.find((type) => {
        return type._id === reportTypeId
      })
      return selectedType
    },
    handleBackTab() {
      this.tab--
      this.headers = []
      this.reports = []
      this.isPageLoading = true
      this.totlaRowBtn.checked = true
    },
    // reportInit
    async reportInit() {
      const initQueryObj = {}
      if (this.selectedUnits?.length) {
        initQueryObj.realEstate = this.selectedUnits.map((unit) => unit._id)
      } else if (this.reportSelectedCriteria.realEstate.length) {
        initQueryObj.realEstate = this.reportSelectedCriteria.realEstate.map(
          (realEstate) => realEstate._id
        )
      } else if (this.reportSelectedCriteria.realEstate?._id) {
        initQueryObj.realEstate = [this.reportSelectedCriteria.realEstate?._id]
      }
      if (this.isClientFilterMultiple) {
        if (this.reportSelectedCriteria?.user?._id) {
          initQueryObj.user = [this.reportSelectedCriteria?.user?._id]
        }
      } else {
        initQueryObj.user = this.reportSelectedCriteria.user.map(
          (user) => user._id
        )
      }
      this.initQueryBody = initQueryObj

      await this.triggerService(this.reportToAdd)
    },
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds))
    },
    async triggerService(reqBody) {
      for (const reportToAdd of this.mainReports.filter((R) => R.checked)) {
        this.reportCompletion = false
        this.reportFiledNameToBeDisplayedWithLoader = reportToAdd.nameAr
        this.sleep(100)
      }
      try {
        const reportData = await reportService.previewReports(reqBody)
        this.reports = reportData.data.body
        this.headers = reportData.data.headers
        this.bindTotalData(reportData.data)
        if (!this.isItAGeneralReport) {
          this.headers = this.restrctureHeaders()
        }
      } catch {
        this.addNotification({
          text: 'حدث خطأ أثناء عرض التقارير المتاحه',
          color: 'error'
        })
      } finally {
        this.reportCompletion = true
        this.isPageLoading = false
      }
    },
    // restrctureHeaders
    restrctureHeaders(headers = this.headers) {
      headers = headers?.map((header) => {
        return {
          text: header.nameAr,
          value: header.nameEn,
          sortable: false,
          align: 'center',
          checked: true
        }
      })
      return headers
    },
    setDropdownValidation(key) {
      this.$set(this.dropdownValidate[key], 'val', true)
    },
    selectAllChange(value) {
      return this.subFiled?.forEach((filed) =>
        this.$set(filed, 'checked', value)
      )
    },
    handleNextTab() {
      this.isPageLoading = true
      this.tab++
      this.reportInit()
    },
    onReportOptionChange(e) {
      this.switchToRealEstate = e.code !== 'client'
    },
    reportSubTypeChange() {
      this.AllUsers = []
      if (this.clientType) {
        this.addClientType = true
      }
      if (this.showUnits) {
        this.getProperties()
      } else {
        this.loadClients()
      }
      config.reportOption[0].text = this.generatePrefix?.title
    },
    getUnitDataBySearchKey(e) {
      this.unitSearchKey = e
      if (this.unitSearchKey) {
        this.getUnits()
      }
    },
    getClientDataBySearchKey(e) {
      this.clientSearchKey = e
      if (this.clientSearchKey) {
        this.loadClients()
      }
    },
    getPropertyDataBySearchKey(e) {
      this.propertySearchKey = e
      if (this.propertySearchKey) {
        this.getProperties()
      }
    },
    getClientNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.loadClients()
      }
    },
    getPropertyNextData() {
      if (
        this.paginationProperty.pageNumber < this.paginationProperty.pagesCount
      ) {
        this.paginationProperty.pageNumber += 1
        this.getProperties()
      }
    },
    closeDialog() {
      this.dropdownValidate.subSections.val = false
      this.autoCompleteReset = !this.autoCompleteReset
      this.switchToRealEstate = false
      this.selectAllChange(false)
      this.subFiled = []
      this.reports = []
      this.mainReports = []
      this.editMood = false
      this.reportObj = { sections: {} }
      this.addClientType = false
      this.tab = 1
      this.totlaRowBtn.checked = true
      this.dialogState_ = false
      this.today =
        this.start =
        this.end =
          new Date().toISOString().substr(0, 10)
      this.paginationClient.pageNumber = 1
    },
    // add Report
    addRport() {
      this.addBtnLoading = true
      const payload = {
        ...this.reportToAdd,
        includeUnderLegalActionArrears: this.underLegalActionStatus,
        sectionFields: this.reportToAdd.sectionFields.filter(
          (filed) => filed.checked
        )
      }
      reportService
        .addReports(payload)
        .then(({ data: { newReportType } }) => {
          this.closeDialog()
          this.$emit('refresh-reports')
          this.selectAll = false
          this.subFiled = []
          this.mainReports = []
          this.reports = []
          this.isPageLoading = true
          this.underLegalActionStatus = false
          newReportType.needsApproval && this.showConfirmationPopupMixin()
        })
        .catch(() => {
          this.addNotification({
            text: 'حدث خطاء اثناء اضافه  هذا التقرير',
            color: 'error'
          })
        })
        .finally(() => {
          this.$emit('togglepreviousReportsView')
          this.addBtnLoading = false
        })
    },
    // edit Report
    editReport() {
      reportService
        .editReports(this.reportToBeEdit)
        .then(() => {
          this.addNotification({
            text: 'تم تعديل  هذا التقرير بنجاح',
            color: 'green'
          })
          this.closeDialog()
          this.$parent.getReports()
          this.selectAll = false
          this.subFiled = []
          this.mainReports = []
        })
        .catch(() => {
          this.addNotification({
            text: 'حدث خطاء اثناء تعديل  هذا التقرير',
            color: 'error'
          })
        })
    },
    // loadEmployees
    loadEmployees() {
      EnvService.getAllEmployee(this.envId, '&registered=1')
        .then((res) => {
          this.employees = res.data.users
          const currentEmp = this.employees.find((e) => {
            return e._id === this.user._id
          })
          if (currentEmp) {
            currentEmp.name += ` (أنا)`
          }
        })
        .catch((err) => console.log('err', err))
    },
    // load client
    async loadClients() {
      this.clientIsLoading = true

      let data = ''
      if (this.addClientType) {
        data = `${this.options?.clients?.type}sOnly=1&`
      }
      if (this.clientSearchKey) {
        data += `search=${this.clientSearchKey}`
      } else {
        data += `pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}`
      }
      try {
        const res = await EnvService.getAllClients(this.envId, '', data)
        if (res?.data && res?.data?.users) {
          this.paginationClient.pagesCount = Math.ceil(
            res?.data?.count / this.paginationClient.pageSize
          )
          this.users = res.data.users ?? []
          this.paginationClient.count = res?.data?.count ?? 0
          this.AllUsers = [...this.AllUsers, ...this.users]
        }
      } catch (err) {
        this.addNotification({
          text: err,
          color: 'error'
        })
      } finally {
        this.clientIsLoading = false
      }
    },
    // load realEsate
    async getProperties() {
      this.realestateIsLoading = true
      let data = ''
      if (this.propertySearchKey) {
        data = `&search=${this.propertySearchKey}`
      } else {
        data = `pageSize=${this.paginationProperty.pageSize}&pageNumber=${this.paginationProperty.pageNumber}&${this.options?.realestates.type}sOnly=1`
      }
      try {
        const res = await realEstateService.getRealEstates(
          this.getPropertiesQuery,
          data
        )
        this.paginationProperty.pagesCount = Math.ceil(
          res?.data?.count / res?.data?.pageSize
        )
        this.properties = res?.data?.realEstates ?? []
        this.paginationProperty.count = res?.data?.count ?? 0
        this.AllProperties = [...this.AllProperties, ...this.properties]
      } catch (error) {
        this.addNotification({
          text: error,
          color: 'error'
        })
      } finally {
        this.realestateIsLoading = false
      }
    },
    resetPaginationNumber() {
      this.paginationClient.pageNumber = 1
      this.paginationProperty.pageNumber = 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/responsiveness/responsiveness.scss';
@import '@/styles/material-dashboard/_variables.scss';
@import '@/styles/report/totalTable.scss';
// $purple-color: #67328e;
.max-height-50vh {
  max-height: 50vh;
}

.min-height-195 {
  min-height: 195px;
}
.after_table_content {
  padding-top: 20px;
  padding-bottom: 30px;

  @include max-sm {
    padding-top: 15px;
    flex-wrap: wrap;

    .qr_div {
      width: 100%;
      text-align: left;
    }
  }

  .transaction_detail {
    h4 {
      padding: 15px 25px;
      font-size: 1.2rem !important;
      margin: 0 0 1px !important;

      @include max-sm {
        width: 100%;
        font-size: 0.9rem !important;
        padding: 12px 15px;
      }

      &:first-child {
        background-color: $primary-color;
        color: #fff;
      }

      &:last-child {
        background-color: #f7f7f7;
      }
    }
  }

  // .transaction_detail
}

// after_table_content
.f-normal {
  font-weight: normal;
}

.mw-26 {
  min-width: 26%;
}

.mw-32 {
  min-width: 32%;
}

.selectall__checkbox {
  direction: ltr;

  .v-label {
    font-size: 10px !important;
  }
}

.add__reports__modal {
  &.add-new-property {
    > .v-card__title {
      font-size: 18px !important;

      @include max-sm {
        font-size: 16px !important;
      }
    }
  }

  // .add-new-property
  .popup__header {
    .v-btn {
      height: 40px;
      width: 40px;

      .v-btn__content {
        .v-icon {
          height: 20px;
          font-size: 20px;
          width: 20px;
        }

        // v-icon
      }

      // v-btn__content
    }
  }

  // popup__header
  .report__popup__icon {
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 16px;

    &.active {
      color: $primary-color;
    }
  }

  // report__popup__icon
  ::v-deep {
    .v-select.v-input--dense {
      .v-select__selection--comma {
        font-weight: 500;
      }

      // v-select__selection--comma
    }

    //  .v-select.v-input--dense
  }

  // v-deep
  .border-0 {
    border: 0;
  }

  // border
  .border-radius-50px {
    border-radius: 50px !important;
  }

  // border-radius-50px
  .border-1px-gray {
    border: 1px solid #efebf2;
  }

  // border-1px-gray
}

// add__reports__modal
::v-deep {
  .v-dialog--active {
    @include max-sm {
      margin: 10px;
    }

    // @include max-sm{}
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #efebf2;
}

::-webkit-scrollbar-thumb {
  background-color: #662d91;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #662d91;
}

@media screen and (max-width: 600px) {
  .sm {
    flex-direction: column;
  }
}
</style>
