<template>
  <v-dialog
    max-width="506px"
    :value="dialogState"
    @click:outside="closeSettingsModal"
  >
    <v-card class="add-new-property pa-0">
      <!-----------1# Header----------->
      <v-card-title
        class="py-2 px-4 mb-0 custom__shadow d-flex justify-space-between align-center"
      >
        <!----------Title----------->
        <h4 class="font-16">إعدادات العملاء المتعثرين</h4>

        <!----------Close----------->
        <v-btn color="grey" fab text @click="closeSettingsModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div class="mt-7">
        <div class="switch-container">
          <div
            class="d-flex justify-space-between align-center content-spacing pl-4"
          >
            <h6 class="font-14 mb-0">تفعيل العملاء المتعثرين</h6>
            <v-switch
              dense
              hide-details="auto"
              inset
              small
              v-model="defaultersSettings.active"
            ></v-switch>
          </div>
        </div>
        <div class="mt-5 content-spacing pl-4">
          <DropdownAlt
            class="px-0"
            v-bind="dropDownProps"
            @filterTypes="setDefaultersSettingsType"
          />
          <v-form v-model="isValid">
            <div v-if="defaultersSettings.type">
              <h6 class="font-12 label-color">
                {{ defaultersInputConfig.title }}
              </h6>
              <v-text-field
                filled
                :placeholder="defaultersInputConfig.placeholder"
                required
                :suffix="defaultersInputConfig.suffix"
                type="number"
                min="1"
                @keydown="$formatNumberInputs"
                hide-details="auto"
                dense
                :rules="defaultersInputConfig.rules"
                :disabled="!defaultersSettings.active"
                v-model.number="defaultersSettings.value"
              ></v-text-field>
            </div>
          </v-form>
          <div class="my-8 d-flex justify-end">
            <v-btn
              class="ma-0 rounded-7 font-14"
              color="primary"
              :disabled="!isSettingsValid"
              :loading="isRequestLoading"
              @click="setDefaultersSettings"
            >
              <span class="ml-1 font-weight-bold"> حفظ التغيرات </span>
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DropdownAlt from '@/components/add-unit/dropdownAlt.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DefaultersSettingsModal',
  components: { DropdownAlt },
  props: {
    dialogState: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    defaultersOptions: [
      {
        _id: 'days',
        text: 'عدد أيام السماح'
      },
      {
        _id: 'intervals',
        text: 'عدد الدفعات المتأخرة'
      }
    ],
    isValid: false,
    defaultersSettings: {},
    isRequestLoading: false
  }),
  computed: {
    ...mapState('accountState', ['currentEnv']),
    dropDownProps() {
      return {
        configrations: { name: 'تحديد العملاء المتعثرين عن طريق' },
        listingType: this.defaultersOptions,
        disable: {
          status: !this.defaultersSettings.active,
          message: 'يجب تفعيل العملاء المتعثرين اولا لتغيير الإعدادات',
          messageType: 'text'
        },
        defaultValue:
          this.defaultersOptions.find(
            (option) => option._id === this.defaultersSettings.type
          )?.text ?? ''
      }
    },
    isTypeDays() {
      return this.defaultersSettings.type === 'days'
    },
    defaultersInputConfig() {
      return {
        title: this.isTypeDays ? 'عدد الأيام' : 'عدد الدفعات المتأخرة',
        placeholder: this.isTypeDays
          ? 'أدخل عدد أيام السماح'
          : 'أدخل عدد الدفعات المتأخرة',
        rules: this.defaultersSettings.active
          ? [(v) => !!v || `هذا الحقل مطلوب`]
          : [],
        suffix: this.isTypeDays ? 'يوم' : 'دفعة'
      }
    },
    isSettingsValid() {
      return this.defaultersSettings.active
        ? !!(this.defaultersSettings.type && this.isValid)
        : true
    }
  },
  methods: {
    ...mapActions('accountState', ['updateCurrentEnv']),
    closeSettingsModal() {
      this.$emit('closeSettingsModal')
    },
    setDefaultersSettingsType({ _id }) {
      this.$set(this.defaultersSettings, 'type', _id)
    },
    setDefaultersSettings() {
      this.isRequestLoading = true
      this.updateCurrentEnv({ defaulters: this.defaultersSettings })
        .then(() => {
          this.closeSettingsModal()
        })
        .catch(() => {
          this.isRequestLoading = false
        })
    }
  },
  created() {
    this.defaultersSettings = { ...this.currentEnv.defaulters }
  }
}
</script>

<style lang="scss" scoped>
.switch-container {
  padding-bottom: 34px;
  border-bottom: 4px solid #f7f7f7;
}
.content-spacing {
  padding-right: 17px;
  .label-color {
    color: #737373;
  }
}
</style>
