<template>
  <v-card class="px-0" style="height: 100%">
    <v-card-title class="text-center">
      <!-- Row 1 -->
      <v-row class="py-2 font-16 justify-between">
        <!-- Col 1 -->
        <v-col cols="8" class="pa-0 text-right">
          <span>معلومات العقد</span>
          <button v-if="false" class="mx-2">
            <span class="color-primary font-14">عقد متعدد</span>
            <v-icon small class="mr-1" color="grey">mdi-pencil-outline</v-icon>
          </button>
        </v-col>
        <!-- Col 2 -->
        <v-col v-if="false" cols="1" class="pa-0">
          <v-btn
            class="add-btn add__property__btn px-1 py-3 ma-0"
            depressed
            x-small
          >
            <v-icon color="purple">mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- Row 2 -->
      <v-row class="flex-column align-center justify-center">
        <!-- Col 1 -->
        <v-col class="pa-0">
          <button>
            <span class="color-primary font-14">نوع العقد</span>
            <v-icon small class="mr-1" color="grey">mdi-pencil-outline</v-icon>
          </button>
          <span class="font-14 d-block text--secondary">متعدد أو موحد</span>
        </v-col>
        <!-- Col 2 -->
        <v-col v-if="false" class="pa-0 mt-3">
          <v-row no-gutters class="align-center">
            <img :src="contractIcon" alt="contract" />
            <v-col class="d-flex flex-column align-start mx-1">
              <h6 class="ma-0">لا يوجد عقد</h6>
              <p class="ma-0">الرجاء إضافة عقد بواسطة ايقونة إضافة</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'AssociationContracts',
  data() {
    return {
      contractIcon: require('@/assets/img/svgs/contract1.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/associations/associations.scss';
</style>
