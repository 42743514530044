<template>
  <div>
    <div v-for="file in files" :key="file.title">
      <div class="d-flex align-center mb-4">
        <div class="w-100">
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex">
              <img
                src="@/assets/img/svgs/speech.svg"
                class="ml-2 tabel-tag-sm blue--tag pa-2"
                alt="speech"
                loading="lazy"
              />
              <h5 class="font-weight-medium ma-0 mb-2 text-truncate">
                {{ file.title }}
              </h5>
            </div>
            <p class="font-weight-light ma-0 ml-1 text-general">
              {{ timeDifferenceCalc(file.createdAt) }}
            </p>
          </div>
          <h6 class="font-weight-medium ma-0 text-gray">
            {{ file.message }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeDifferenceCalc from '@/mixins/timeDifferenceCalc.js'

export default {
  name: 'NotificationListCard',
  mixins: [timeDifferenceCalc],
  props: {
    files: {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.v-btn.add-btn {
  background: rgba($primary-color, 0.1);
  border-radius: 7px;
}

.filter-underline {
  color: $primary-color;
}

.v-btn::before {
  background-color: transparent;
}
</style>
