<template>
  <v-dialog width="850px" persistent v-model="contractModalDialogState_">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="py-0 pr-5 pl-md-1 pl-3 mb-4 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0 font-16">{{ tabsTitles[tab] }}</h4>
        <v-btn
          @click.prevent="closeDialog"
          data-test="close-contract"
          class="ma-0 mt-1"
          color="grey"
          fab
          text
        >
          <v-icon class="font-20">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Contract Modal Tabs-->
      <v-tabs-items class="px-5" v-model="tab" touchless>
        <v-tab-item>
          <contract-types
            v-bind="contractTypesProps"
            @setValdationStatus="setValdationStatus($event)"
            @bindContractData="bindContractData($event)"
            @setSubContractTypes="setSubContractTypes"
            @resetData="resetModalData"
            @presetIsEditMoodData="nextTab"
          />
        </v-tab-item>
        <v-tab-item>
          <contract-parties
            v-bind="contractPartiesProps"
            @setValdationStatus="setValdationStatus($event)"
            @bindContractData="bindContractData($event)"
            @getRealEstates="getRealEstates($event)"
            @resetRelaestate="resetRelaestate"
            @createContractBtnLoading="setCreateBtnLoader"
            @presetIsEditMoodData="nextTab"
          />
        </v-tab-item>
        <v-tab-item>
          <contract-details
            v-bind="contractDetailsProps"
            @setValdationStatus="setValdationStatus($event)"
            @bindContractData="bindContractData($event)"
            @getRealEstates="getRealEstates(contractInfo.firstParty, $event)"
            @pushToAvailableRealEstates="pushToAvailableRealEstates"
            @setContractDuration="setContractDuration"
            @setLastTabStatus="setLastTab($event)"
            @resetRealEstatesUnits="resetRealEstatesUnits"
          />
        </v-tab-item>
        <v-tab-item v-if="!isMaintenanceContract">
          <contract-additionaldetails
            v-if="isRentContract"
            v-bind="contractAdditionaldetailsProps"
            @bindContractData="bindContractData($event)"
            @setValdationStatus="setValdationStatus($event)"
          />
          <contract-pm-additional-details
            v-else
            v-bind="contractPMAdditionaldetailsProps"
            @bindContractData="bindContractData($event)"
            @setValdationStatus="setValdationStatus($event)"
          />
        </v-tab-item>
        <v-tab-item v-if="isRentContract">
          <contract-flexible-installments
            v-if="isRentContract"
            ref="tab4"
            :contractInfo="contractInfo"
            :previousClicked="previousClicked"
            :updateInstasId="instId"
            :newFixedAmountData="newFixedAmountData"
            @bindContractData="bindContractData($event)"
            @openFixedAmountModal="setFixedAmountData"
            @setValdationStatus="setValdationStatus($event)"
          />
        </v-tab-item>
        <v-tab-item v-if="isRentContract || isPmContract">
          <contract-financial-claims
            v-bind="contractFinancialClaimsProps"
            @bindContractData="bindContractData($event)"
            @setValdationStatus="setValdationStatus($event)"
          />
        </v-tab-item>
        <v-tab-item v-if="isRentContract || isPmContract">
          <contract-financial-dues
            v-bind="contractFinancialDuesProps"
            @bindContractData="bindContractData($event)"
            @setValdationStatus="setValdationStatus($event)"
            @setLastTabStatus="setLastTab($event)"
          />
        </v-tab-item>
        <v-tab-item v-if="isRentContract">
          <contract-pending-parties
            :exclamation="exclamation"
            ref="tab7"
            @bindContractData="bindContractData($event)"
            @setPendingContract="setPendingContract($event)"
            @setValdationStatus="setValdationStatus($event)"
            @setLastTabStatus="setLastTab($event)"
          />
        </v-tab-item>
      </v-tabs-items>

      <!-- Fixed Amount Modal -->
      <ModFixedAmountModal
        v-bind="modFixedAmountProps"
        @update-fixed-amount="updateFixedAmount"
        @closeDialog="closeUpdateFixedAmount"
      />

      <v-card-actions class="px-4 py-3">
        <v-btn
          v-if="isPreviousBtnVisible"
          @click="previousTab"
          class="ma-0 rounded-7 font-14"
          color="grey"
        >
          <v-icon class="send-icon font-16">mdi-arrow-left</v-icon>
          <span class="ml-1 font-weight-bold font-14">السابق</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            isRentContract &&
            isPreviousBtnVisible &&
            tab > 1 &&
            !this.IsItPendingRenewalContract
          "
          :disabled="!tabsValidation[`tab${tab}`]"
          @click="handleAddContractAction('draft')"
          class="ma-0 rounded-7 my-3 ml-3"
          color="grey"
        >
          <span class="ml-1 font-weight-bold">حفظ كمسوده</span>
        </v-btn>
        <v-btn
          v-else-if="isRentContract && isPreviousBtnVisible && tab > 1"
          :disabled="!tabsValidation[`tab${tab}`]"
          @click="UpdateContract()"
          class="ma-0 rounded-7 my-3 ml-3"
          color="grey"
        >
          حفظ
        </v-btn>
        <v-btn
          :disabled="!tabsValidation[`tab${tab}`]"
          @click="nextTab"
          class="ma-0 rounded-7 font-14"
          color="primary"
          :loading="createContractBtnLoading"
        >
          <span v-if="!isLastTap">
            <span class="ml-1 font-weight-bold font-14">التالي</span>
            <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
          </span>
          <span v-else-if="isLastTap && !isEditMood">
            <span class="ml-1 font-weight-bold font-14">إنشاء العقد</span>
          </span>
          <span v-else>
            <span class="ml-1 font-weight-bold font-14">{{
              puplishRenewBtn
            }}</span>
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import contractAdditionaldetails from '@/components/add-contract/contractModalTabs/contractAdditionalDetails'
import contractDetails from '@/components/add-contract/contractModalTabs/contractDetails'
import contractFinancialClaims from '@/components/add-contract/contractModalTabs/contractFinancialClaims'
import contractFinancialDues from '@/components/add-contract/contractModalTabs/contractFinancialDues'
import contractParties from '@/components/add-contract/contractModalTabs/contractParties'
import contractTypes from '@/components/add-contract/contractModalTabs/contractTypes'
import ModFixedAmountModal from '@/components/modals/ModFixedAmountModal.vue'
// import contractAmenities from '@/components/add-contract/contractModalTabs/contractAmenities'
import confirmationMixin from '@/mixins/confirmationMixin.js'
import { contractsService, EnvService, realEstateService } from '@/services'
import { mapMutations, mapState } from 'vuex'
import contractFlexibleInstallments from './contractModalTabs/contractFlexibleInstallments.vue'
import contractPendingParties from './contractModalTabs/contractPendingParties.vue'
import contractPmAdditionalDetails from './contractModalTabs/contractPmAdditionalDetails.vue'

export default {
  name: 'contractModal',
  components: {
    contractTypes,
    contractParties,
    contractDetails,
    contractAdditionaldetails,
    contractFinancialDues,
    contractFinancialClaims,
    contractFlexibleInstallments,
    contractPmAdditionalDetails,
    contractPendingParties,
    ModFixedAmountModal
  },
  mixins: [confirmationMixin],
  props: {
    contractModalState: {
      default: false,
      required: true
    },
    defaultData: {
      default() {
        return {}
      }
    },
    updatedContract: {
      default() {
        return {}
      }
    },
    preSelectedContractType: {
      default() {
        return {}
      }
    },
    contractIntervals: {
      default: [],
      required: true
    }
  },
  emits: ['closeContractModal', 'refreshContracts'],
  data() {
    return {
      tabsTitles: [
        'نوع العقد',
        'أطراف التعاقد',
        'تفاصيل العقد',
        'تفاصيل الخدمات',
        'تفاصيل الدفعات',
        'مستحقات اضافيه',
        'مطالبات اضافيه',
        'موافقه اطراف التعاقد'
      ],
      isUnitLoading: false,
      contractDuration: [],
      exclamation: require('@/assets/img/svgs/exclamationMark.svg'),
      tab: 0,
      tabsValidation: {},
      contractInfo: {},
      availableRealEstates: [],
      availableUnits: [{ name: 'كامل العقار', allocated: true }],
      subContractTypes: [],
      pendingContract: false,
      draftContract: false,
      LastTab: false,
      previousClicked: false,
      createContractBtnLoading: false,
      updatedContractExcludedKeys: [
        'type',
        'typeName',
        'status',
        '__v',
        '_id',
        'terminated',
        'reminderSentAt',
        'reminderSent',
        'isSynchronized',
        'synchronizedAt',
        'number',
        'createdAt',
        'updatedAt',
        'checked',
        'notes',
        'companions',
        'year',
        'month',
        'day',
        'typeAr',
        'hasCommissionTax',
        'valuePerYear',
        'version',
        'hijri',
        'previousContract',
        'previousContracts',
        'renewalProcessed',
        'renewalProcessedAt',
        'okay',
        'pmContract',
        'contractUtilities'
      ],
      allowedKayFalsyValues: [
        'payment',
        'tax',
        'taxAmount',
        'hasCommissionTax',
        'hasFlexibleInstallments'
      ],
      fixedAmountStatus: false,
      fixedAmountData: {},
      newFixedAmountData: {},
      instId: null
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    modFixedAmountProps() {
      const details = this.fixedAmountData?.fixedDetails

      return {
        dialogState: this.fixedAmountStatus,
        data: {
          electricity: details?.electricity || 0,
          water: details?.water || 0,
          gas: details?.gas || 0,
          generalServices: details?.generalServices || 0,
          utilitiesTax: details?.fixedTax || 15
        }
      }
    },
    isContractRnewable() {
      switch (this.contractInfo.typeName) {
        case 'rent':
          return this.currentEnv?.rentContractAutoRenewal
        case 'propertyManagement':
          return this.pmContractAutoRenewal
        default:
          return false
      }
    },
    envHasCommissionTax() {
      return this.currentEnv?.hasCommissionTax || false
    },
    realestatePurpose() {
      return this.contractInfo?.realEstate?.purpose || {}
    },
    isCmmercialRealestate() {
      return !!(this.realestatePurpose?.nameEn === 'Commercial')
    },
    firstPartyData() {
      return this.contractInfo?.firstParty || {}
    },
    otherPartyData() {
      return this.contractInfo?.otherParties &&
        this.contractInfo?.otherParties.length
        ? this.contractInfo?.otherParties[0]
        : {}
    },
    addContractNotificationText() {
      if (this.IsItDraftContract) {
        return this.isEditMood ? 'تم تحديث بيانات العقد' : 'تم حفظ العقد كمسوده'
      }
      if (this.IsItPendingRenewalContract) {
        return 'تم إعتماد العقد يمكنك الأن تجديده'
      }
      return 'تم إضافه العقد بنجاح'
    },
    isEditMood() {
      return !!Object.keys(this.updatedContract).length
    },
    clientToAdd() {
      const client = {
        name: this.contractInfo.otherParties[0]?.name,
        idNumber: this.contractInfo.otherParties[0]?.idNumber,
        registrationNumber:
          this.contractInfo.otherParties[0]?.registrationNumber || '',
        phoneNumber: this.contractInfo.otherParties[0]?.phoneNumber?.replace(
          '0',
          '966'
        ),
        email: this.contractInfo.otherParties[0]?.email,
        clientType: 'individual',
        taxNumber: this.contractInfo.otherParties[0]?.taxNumber,
        environment: {
          _id: this.currentEnv?._id,
          name: this.currentEnv?.name,
          role: ['renter'],
          whatsAppNumber: this.contractInfo.otherParties[0]?.whatsAppNumber
        },
        userAddress: {
          address: this.contractInfo.otherParties[0].userAddress?.address,
          city: this.contractInfo.otherParties[0].userAddress?.city,
          district: this.contractInfo.otherParties[0].userAddress?.district
        }
      }
      return client
    },
    contractTypesProps() {
      return {
        ref: 'tab0',
        updatedContract: Object.keys(this.updatedContract).length
          ? this.updatedContract
          : this.preSelectedContractType,
        isEditMood: this.isEditMood,
        exclamation: this.exclamation,
        defaultData: this.defaultData
      }
    },
    contractPartiesProps() {
      return {
        ref: 'tab1',
        isRentContract: this.isRentContract,
        defaultData: this.defaultData,
        updatedContract: this.updatedContract,
        isEditMood: this.isEditMood,
        exclamation: this.exclamation,
        contrcatType: this.selectedContract,
        envHasCommissionTax: this.envHasCommissionTax,
        isPendingRenewal: this.IsItPendingRenewalContract
      }
    },
    contractDetailsProps() {
      return {
        ref: 'tab2',
        isRentContract: this.isRentContract,
        isMaintenanceContract: this.isMaintenanceContract,
        isPmContract: this.isPmContract,
        availableRealEstates: this.availableRealEstates,
        availableUnitsForParent: this.availableUnits,
        defaultData: this.defaultData,
        subContractTypes: this.subContractTypes,
        exclamation: this.exclamation,
        contractIntervals: this.contractIntervals,
        updatedContract: this.updatedContract,
        isEditMood: this.isEditMood,
        contrcatType: this.selectedContract,
        isUnitLoading: this.isUnitLoading,
        isPendingRenewal: this.IsItPendingRenewalContract
      }
    },
    contractAdditionaldetailsProps() {
      return {
        ref: 'tab3',
        isRentContract: this.isRentContract,
        updatedContract: this.updatedContract,
        isEditMood: this.isEditMood,
        contractIntervals: this.contractDuration.length
          ? this.contractDuration
          : this.contractIntervals,
        duration: this.contractInfo.duration
      }
    },
    contractPMAdditionaldetailsProps() {
      return {
        ref: 'tab3',
        contractIntervals: this.contractIntervals,
        envHasCommissionTax: this.envHasCommissionTax
      }
    },
    contractFinancialClaimsProps() {
      return {
        ref: this.isRentContract ? 'tab5' : 'tab4',
        updatedContract: this.updatedContract,
        isEditMood: this.isEditMood,
        exclamation: this.exclamation,
        isRentContract: this.isRentContract,
        envHasCommissionTax: this.envHasCommissionTax
      }
    },
    contractFinancialDuesProps() {
      return {
        ref: this.isRentContract ? 'tab6' : 'tab5',
        updatedContract: this.updatedContract,
        isEditMood: this.isEditMood,
        exclamation: this.exclamation,
        duration: this.contractInfo.duration,
        collectionInterval: this.contractInfo.collectionInterval,
        isCmmercialRealestate: this.isCmmercialRealestate,
        isPmContract: this.isPmContract,
        firstPartyData: this.firstPartyData,
        otherPartyData: this.otherPartyData
      }
    },
    contractAmenitiesProps() {
      return {
        ref: 'tab6',
        updatedContract: this.updatedContract,
        isEditMood: this.isEditMood
      }
    },
    isLastTap: {
      get() {
        return this.LastTab
      },
      set(val) {
        this.LastTab = val
      }
    },
    IsItPendingContract: {
      get() {
        return this.pendingContract
      },
      set(val) {
        this.pendingContract = val
      }
    },
    IsItDraftContract: {
      get() {
        return this.draftContract
      },
      set(val) {
        this.draftContract = val
      }
    },
    IsItPendingRenewalContract() {
      return !!(this.updatedContract?.status === 'pendingRenewal')
    },
    getContractStatus() {
      if (this.IsItDraftContract) {
        return 'draft'
      } else if (this.IsItPendingContract) {
        return 'pending'
      }
      return 'active'
    },
    puplishRenewBtn() {
      return this.IsItPendingRenewalContract ? 'إعتماد العقد' : 'نشر العقد'
    },
    selectedContract() {
      return this.contractInfo?.typeName ?? 'rent'
    },
    showFlexibleInstallments() {
      return this.contractInfo.hasFlexibleInstallments
    },
    isRentContract() {
      return !!(this.selectedContract === 'rent')
    },
    isMaintenanceContract() {
      return !!(this.selectedContract === 'maintenance')
    },
    isPmContract() {
      return !!(this.selectedContract === 'propertyManagement')
    },
    isPreviousBtnVisible() {
      return !(this.tab === 0 || (this.tab <= 2 && this.isEditMood))
    },
    contractModalDialogState_: {
      get() {
        return this.contractModalState
      },
      set(value) {
        this.$emit('closeContractModal', value)
      }
    },
    userData() {
      return {
        _id: this.user?._id,
        name: this.user?.name,
        phoneNumber: this.user?.phoneNumber
      }
    },
    commonContractData() {
      const data = {
        ...(this.isEditMood && {
          id: this.updatedContract?._id
        }),
        createdBy: this.userData,
        collectionMethod: 'percentage',
        hasCommissionTax: this.envHasCommissionTax,
        environment: this.currentEnv._id,
        status: this.getContractStatus
      }
      return data
    },
    contractToAdd() {
      let dataToAdd = {
        ...this.contractInfo,
        ...this.commonContractData,
        isRenewable: this.isContractRnewable
      }
      dataToAdd = this.cleanObject(dataToAdd)
      return dataToAdd
    },
    contractToUpdate() {
      let dataToUpdate = {
        ...this.updatedContract,
        ...this.contractInfo,
        ...this.commonContractData,
        isRenewable: this.isContractRnewable
      }
      this.updatedContractExcludedKeys.map((key) => {
        delete dataToUpdate[key]
      })
      dataToUpdate = this.cleanObject(dataToUpdate)
      return dataToUpdate
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    resetRealEstatesUnits() {
      this.availableUnits = [{ name: 'كامل العقار', allocated: true }]
    },
    setFixedAmountData(data) {
      this.instId = null
      this.newFixedAmountData = {}
      this.fixedAmountStatus = true
      this.fixedAmountData = { id: data.id, ...data.data }
    },
    updateFixedAmount(data) {
      this.instId = this.fixedAmountData.id - 1
      this.newFixedAmountData = data
    },
    closeUpdateFixedAmount() {
      this.fixedAmountStatus = false
    },
    setContractDuration(avaliableDurations) {
      if (
        this.$refs.tab4 &&
        avaliableDurations.length < this.contractDuration.length
      ) {
        // reset only if user decrease duration options
        this.$refs.tab4.resetDurations()
      }
      this.contractDuration = avaliableDurations
    },
    setCreateBtnLoader(loadingVal) {
      this.createContractBtnLoading = loadingVal
    },
    resetRelaestate() {
      this.$emit('resetRelaestate')
    },
    cleanObject(obj) {
      let cleanObj = JSON.parse(
        JSON.stringify(obj, (key, value) => {
          if (this.allowedKayFalsyValues.includes(key)) {
            return value
          }
          return !value ? undefined : value
        })
      )
      cleanObj = JSON.parse(
        JSON.stringify(cleanObj, (key, value) => {
          if (this.allowedKayFalsyValues.includes(key) || value === undefined) {
            return value
          }
          return typeof value === 'object' && !Object.keys(value ?? {}).length
            ? undefined
            : value
        })
      )
      return cleanObj
    },
    resetModalData() {
      this.contractInfo = {}
      for (const i in this.tabsValidation) {
        if (i !== 'tab0') {
          this.$refs[i].resetData()
        }
      }
    },
    pushToAvailableRealEstates(realEstate) {
      this.availableRealEstates.push(
        realEstate && Object.keys(realEstate).length
          ? realEstate
          : this.updatedContract?.realEstate
      )
    },
    setLastTab(isLastTabVal) {
      this.isLastTap = isLastTabVal
    },
    setPendingContract(pendingVal) {
      this.IsItPendingContract = pendingVal
    },
    setSubContractTypes(subTypes) {
      this.subContractTypes = subTypes
    },
    closeDialog() {
      this.contractModalDialogState_ = false
      this.contractInfo = {}
    },
    setValdationStatus(isValidStatus) {
      this.$set(this.tabsValidation, `tab${this.tab}`, isValidStatus)
    },
    nextTab() {
      if (this.isLastTap) {
        this.handleAddContractAction()
      } else {
        this.$refs[`tab${this.tab}`].bindContractData()
        this.tab++
        setTimeout(() => {
          if (this.tab === 4 && this.isRentContract) {
            this.$refs.tab4.getInstallmentsPreview(this.contractInfo)
            this.$refs.tab4.resetNewInstsPayment()
          }
          this.$refs[`tab${this.tab}`].presetData()
          if (this.tab === 2) {
            this.setValdationStatus(this.$refs[`tab${this.tab}`]?.isValid)
          }
        })
      }
    },
    previousTab() {
      if (this.tab === 4 && this.isRentContract) {
        this.previousClicked = !this.previousClicked
        this.confirmationPopup()
        return
      }
      this.tab--
      this.isLastTap = false
    },

    confirmationPopup() {
      this.$root.$confirm
        .open({
          title: 'تنبيه',
          message: ' الضغط على تأكيد سيؤدي إلى فقدان التعديلات ',
          confirmTxt: 'تاكيد',
          cancelTxt: 'إلغاء',
          options: {
            color: 'danger',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            this.tab--
          }
        })
    },
    bindContractData(data) {
      this.contractInfo = { ...this.contractInfo, ...data }
    },
    async getRealEstates(selectedUser, parentId) {
      this.isUnitLoading = true
      this.createContractBtnLoading = true
      const user = {
        environment: this.currentEnv._id,
        user: selectedUser._id,
        contractType: this.contractInfo?.typeName || 'rent'
      }
      if (parentId) {
        user.parentId = parentId.parentId
      }
      try {
        const realEstatesWithNoContracts =
          await realEstateService.getAvailableRealEstates(user)

        // if we have units for the realEstate then we will load
        if (parentId) {
          this.availableUnits =
            realEstatesWithNoContracts.data.availableRealEstates
          this.availableUnits.unshift({
            name: 'كامل العقار',
            allocated: true
          })
        } else {
          this.availableRealEstates =
            realEstatesWithNoContracts.data.availableRealEstates
        }
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل العقارات المتاحه, برجاء المحاوله لاحقا',
          color: 'error'
        })
        this.closeDialog()
      } finally {
        this.$refs.tab1.setAvaliableRealestate(this.availableRealEstates.length)
        this.createContractBtnLoading = false
        if (parentId) {
          this.setValdationStatus(false)
        }
        this.isUnitLoading = false
      }
    },
    async addContractAction() {
      delete this.contractToUpdate.methodTitle
      if (!this.isEditMood) {
        const {
          data: { contract }
        } = await contractsService.addContract(this.contractToAdd)
        if (contract.needsApproval) {
          this.showConfirmationPopupMixin()
        }
        return ''
      }

      return this.IsItDraftContract || this.IsItPendingRenewalContract
        ? contractsService.updateContract(this.contractToUpdate)
        : contractsService.publishContract(this.contractToAdd)
    },
    async UpdateContract() {
      try {
        await this.$refs[`tab${this.tab}`].bindContractData()
        await this.handleAddNewClient()
        await this.handleMergeContracts()
        // TODO ASK ABOUT THIS BE
        await contractsService.updateContract(this.contractToUpdate)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحديث بيانات العقد, حاول في وقت لاحق',
          color: 'error'
        })
      } finally {
        this.$emit('refreshContracts')
        this.closeDialog()
      }
    },
    async addNewClient() {
      try {
        const newClient = await EnvService.addClient(this.clientToAdd)
        return newClient.data.user
      } catch {
        this.addNotification({
          text: 'لم نتمكن من اضافه الطرف الثاني للعقد, برجاء المحاوله لاحقا',
          color: 'error'
        })
        this.closeDialog()
        return {}
      }
    },
    async handleAddNewClient() {
      if (!this.contractInfo?.otherParties[0]?.hasOwnProperty('_id')) {
        try {
          this.createContractBtnLoading = true
          const userResponse = await this.addNewClient()
          if (Object.keys(userResponse).length) {
            this.contractInfo.otherParties[0] = {
              idNumber: userResponse.idNumber,
              name: userResponse.name,
              phoneNumber: userResponse.phoneNumber,
              type: 'renter',
              _id: userResponse._id,
              taxNumber: userResponse.taxNumber,
              ...(userResponse.userAddress && {
                userAddress: {
                  address: userResponse.userAddress.address,
                  city: userResponse.userAddress.city,
                  district: userResponse.userAddress.district
                }
              })
            }
          }
        } catch {
          this.addNotification({
            text: 'حدث خطأ أثناء اضافه الطرف الثاني للعقد حاول مره أخري',
            color: 'error'
          })
        } finally {
          this.createContractBtnLoading = false
        }
      }
    },
    async handleMergeContracts() {
      if (this.contractInfo?.availableUnits?.length >= 2) {
        try {
          this.createContractBtnLoading = true

          const mergeContractsBody = {
            realestates: this.contractInfo.availableUnits.map(
              (unit) => unit._id
            ),
            parentId: this.contractInfo.realEstate._id,
            environment: this.currentEnv._id,
            type: this.contractInfo.availableUnits[0].type
          }
          const mergeRealEstate = await realEstateService.mergeRealEstate(
            mergeContractsBody
          )
          this.contractInfo.realEstate =
            this.$refs.tab2.transformRealEstateObject(
              mergeRealEstate.data.realEstate
            )
          this.contractInfo.realEstate.owner =
            this.contractInfo.realEstate.owner._id
        } catch {
          this.addNotification({
            text: 'حدث خطاء اثناء دمج العقارات الرجاء المحاوله مره اخري',
            color: 'error'
          })
        } finally {
          this.createContractBtnLoading = false
        }
      } else if (
        this.contractInfo?.availableUnits?.length === 1 &&
        this.contractInfo?.availableUnits[0].name !== 'كامل العقار'
      ) {
        this.contractInfo.realEstate = this.contractInfo?.availableUnits[0]

        this.contractInfo.realEstate.owner =
          this.contractInfo.realEstate.owner._id
      }
      delete this.contractInfo.availableUnits
      delete this.contractInfo.realEstate.type
    },
    async handleAddContractAction(addAction = '') {
      if (addAction === 'draft') {
        this.IsItDraftContract = true
      }
      try {
        this.createContractBtnLoading = true
        await this.$refs[`tab${this.tab}`].bindContractData()
        await this.handleAddNewClient()
        await this.handleMergeContracts()
        await this.addContractAction()
        this.addNotification({
          text: `${this.addContractNotificationText}`,
          color: 'success'
        })
        if (this.IsItPendingRenewalContract) {
          try {
            await contractsService.setApproveRejectContract({
              id: this.updatedContract?._id,
              environment: this.currentEnv?._id,
              isApproved: true
            })
          } catch {
            this.addNotification({
              text: 'لم نتمكن من تجديد العقد, حاول في وقت لاحق',
              color: 'error'
            })
          }
        }
      } catch (error) {
        if (error.response?.data?.message.includes('already have')) {
          this.addNotification({
            text: `يوجد عقد ${this.contractInfo?.title} ساري مسبقا لهذا العقار`,
            color: 'error'
          })
        } else {
          this.addNotification({
            text: error.response?.data?.message,
            color: 'error'
          })
        }
      } finally {
        this.$emit('refreshContracts')
        this.closeDialog()
      }
    }
  }
}
</script>
