<template>
  <div>
    <v-row align-content-sm no-gutters class="mt-2">
      <h4 class="font-14">
        اضف مخطط دوري
        <span class="grey--text">(إختياري)</span>
      </h4>
    </v-row>
    <div v-if="rolechart" data-test="form">
      <v-row no-gutters align-content-sm>
        <v-form class="w-100" v-model="isValid">
          <v-col class="pa-0 pl-1" cols="12" sm="12">
            <h5
              class="mt-3 mr-1"
              :class="{ 'red--flag': nextabClicked ? !isValid : false }"
            >
              اسم الدور*
            </h5>
            <v-text-field
              data-test="blueprintTitle"
              v-model="bluePrint.title"
              :rules="selectRules"
              class="mb-0 pa-0 font-14"
              dense
              filled
              hide-details
              placeholder="اسم الدور"
              required
            ></v-text-field>
          </v-col>
        </v-form>
      </v-row>
      <v-row align-content-sm no-gutters class="my-4">
        <v-col class="pl-1" cols="12" sm="12">
          <h6 class="ma-0 mb-2">
            وصف المخطط
            <span class="grey--text">(إختياري)</span>
          </h6>
          <v-textarea
            data-test="blueprintDesc"
            v-model="bluePrint.desc"
            content-class="font-12"
            dense
            filled
            hide-details="auto"
            placeholder="وصف المخطط"
            required
            auto-grow
            no-resize
            rows="2"
            class="font-14"
          ></v-textarea>
        </v-col>
      </v-row>
      <filesUploading
        ref="filesUploading"
        :title="title"
        :actionsStatus="actionsStatus"
        :rolechart="bluePrint"
        @set-blueprint-image="setBlueprintImage"
      />
    </div>
    <v-row align-content-sm no-gutters class="mb-6 mt-5">
      <v-btn
        @click="openAddForm"
        data-test="openAddForm"
        color="white"
        depressed
        class="ma-0 pa-0"
      >
        <v-img
          class="mt-1 ml-1"
          max-height="16"
          max-width="16"
          :src="cancelPrup"
        ></v-img>
        <span class="font-14 brub--flag">
          {{ !rolechart ? 'إضافه' : 'اخفاء' }} مخطط دور
        </span>
      </v-btn>
    </v-row>

    <v-card-actions class="pt-4 px-0 d-flex align-center justify-space-between">
      <v-btn
        @click="previousTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <v-icon class="font-16">mdi-arrow-right</v-icon>
        <span class="mb-1 ml-1">السابق</span>
      </v-btn>
      <v-btn
        :loading="addingBtnLoading"
        @click="addRealEstate"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <span class="mb-1 ml-1">
          {{ propertyInfoValid ? 'تحديث' : 'إضافه' }}
        </span>
        <v-icon class="font-16">mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import filesUploading from './filesUploading.vue'

export default {
  components: {
    filesUploading
  },
  props: {
    title: {},
    resetValidation: {
      default: false
    },
    propertyInfo: {
      default() {
        return {}
      }
    },
    propertyInfoValid: {},
    addingBtnLoading: {
      default: false
    }
  },
  data() {
    return {
      cancelPrup: require('@/assets/img/svgs/cancel-Prup.svg'),
      actionsStatus: false,
      selectRules: [(v) => !!v || 'هذا الحقل مطلوب'],
      rolechart: false,
      nextabClicked: false,
      bluePrint: {
        title: '',
        desc: '',
        image: '',
        key: ''
      },
      addPropertyInfo: {},
      isValid: false
    }
  },
  methods: {
    setBlueprintImage(blueprintImage) {
      this.bluePrint.image = blueprintImage.image
      this.bluePrint.key = blueprintImage.key
    },
    previousTab() {
      this.$emit('previousTab')
    },
    addRealEstate() {
      if (this.rolechart) {
        this.nextabClicked = true
        if (this.isValid) {
          this.addPropertyInfo.bluePrint = this.bluePrint
          this.$emit('setAddPropertyInfo', this.addPropertyInfo)
          if (!this.propertyInfoValid) {
            this.$emit('addRealEstate')
          } else {
            this.$emit('updateRealEstate')
          }
        } else {
          this.$emit('addNotification')
        }
      } else {
        this.$emit('setAddPropertyInfo', this.addPropertyInfo)
        if (!this.propertyInfoValid) {
          this.$emit('addRealEstate')
        } else {
          this.$emit('updateRealEstate')
        }
      }
    },
    openAddForm() {
      this.rolechart = !this.rolechart
    }
  },
  watch: {
    propertyInfo: {
      immediate: true,
      handler() {
        this.addPropertyInfo = { ...this.propertyInfo }
        if (this.addPropertyInfo.bluePrint?.length > 0) {
          this.bluePrint = { ...this.addPropertyInfo.bluePrint[0] }
          if (this.bluePrint.title) {
            this.rolechart = true
          }
        }
      }
    },
    resetValidation: {
      handler() {
        this.nexTabClicked = false
        this.isValid = false
        this.rolechart = false
        this.addPropertyInfo = {}
      }
    }
  }
}
</script>
