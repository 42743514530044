import axios from 'axios'
import config from '../config'

axios.defaults.headers.client = 'HectarPlus'

// get duesFilters
const getDuesFilters = function (filter = '') {
  const isFilter = filter.length ? `filter=${filter}` : ''
  const url = `${config.bonds}/assignments/configs?${isFilter}`
  return axios.get(url)
}

const getAllDues = function (query, env) {
  const url = `${config.bonds}/assignments/${env}`
  return axios.get(url, {
    params: query
  })
}

const getSingleDue = function (id) {
  const url = `${config.bonds}/assignments/get/${id}`
  return axios.get(url)
}

const getlogs = function (data) {
  const url = `${config.messagesUrl}/logs/filter`
  return axios.post(url, data)
}

const updateDue = function (data, dueId) {
  const url = `${config.bonds}/assignments/${dueId}`
  return axios.put(url, { ...data })
}

const deleteDue = function (data) {
  const url = `${config.bonds}/assignments`
  return axios.delete(url, { data })
}

const addDueCollection = function (data) {
  const url = `${config.bonds}/assignments/collection`
  return axios.post(url, data)
}
const getInstallments = function (query) {
  const url = `${config.bonds}/assignments/select?${query}`
  return axios.get(url)
}

const deleteInstallment = function (data) {
  const url = `${config.bonds}/assignments/collections`
  return axios.delete(url, { data })
}
export const duesService = {
  getDuesFilters,
  getAllDues,
  updateDue,
  deleteDue,
  getSingleDue,
  addDueCollection,
  getlogs,
  getInstallments,
  deleteInstallment
}
