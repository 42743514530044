import config from '../config'
import axios from 'axios'
import store from '@/store/index'

//  Adding Tax
const addTax = function (data) {
  const url = `${config.bonds}/taxBills`
  return axios.post(url, data)
}
const getSingleTaxBill = function (billId) {
  const url = `${config.bonds}/taxBills/get/${billId}`
  return axios.get(url)
}

// Get All Tax bills
const getAllTaxBills = function (data) {
  const url = `${config.bonds}/taxBills?${data}`
  const listTaxPermission = store.getters['accountState/hasPermission'](
    'properties',
    'taxInvoices',
    'list'
  )
  return listTaxPermission ? axios.get(url) : []
}

// update tax bill status
const updateTaxBillStatus = function (query, id) {
  const url = `${config.bonds}/taxBills/set-status/${id}`
  return axios.put(url, query)
}

// update tax bill
const updateTaxBill = function (query, id) {
  const url = `${config.bonds}/taxBills/${id}`
  return axios.put(url, query)
}

// create money notes
const addPaymentNotes = function (query) {
  const url = `${config.bonds}/paymentNotes`
  return axios.post(url, query)
}

// delete tax bill
const deleteTaxBill = function (query, id) {
  const url = `${config.bonds}/taxBills/${id}`
  return axios.delete(url, query)
}

export const taxBillsService = {
  addTax,
  getSingleTaxBill,
  getAllTaxBills,
  updateTaxBillStatus,
  updateTaxBill,
  addPaymentNotes,
  deleteTaxBill
}
