<template>
  <div class="quotation--paper2 quotation--paper5">
    <h1>معلومات العقار</h1>
    <h2 class="ma-0">التفاصيل والمميزات</h2>

    <div class="quotation--paper5__tags">
      <div v-for="(value, key, i) in features" :key="i">
        <span>{{ value }} {{ titles(key) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Paper5',
  props: {
    quotation: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    features() {
      const {
        numberOfBathRoom,
        numberOfFloors,
        numberOfKitchenUnit,
        numberOfLivingRoom,
        numberOfRooms,
        numberOfUnit,
        numberOfVillas
      } = this.quotation.realEstate

      return {
        numberOfBathRoom,
        numberOfFloors,
        numberOfKitchenUnit,
        numberOfLivingRoom,
        numberOfRooms,
        numberOfUnit,
        numberOfVillas
      }
    }
  },
  methods: {
    titles(key) {
      switch (key) {
        case 'numberOfBathRoom':
          return 'حمام'
        case 'numberOfFloors':
          return 'ادوار'
        case 'numberOfKitchenUnit':
          return 'مطابخ'
        case 'numberOfLivingRoom':
          return 'غرفة معيشة'
        case 'numberOfRooms':
          return 'غرفة'
        case 'numberOfUnit':
          return 'وحدة'
        case 'numberOfVillas':
          return 'فيلا'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
