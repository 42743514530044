<template>
  <v-layout class="flex-wrap sidedetail__header">
    <span class="d-flex align-center">
      <v-img
        :lazy-src="exitArrow"
        :src="exitArrow"
        @click="$emit('closeDetails')"
        max-height="15"
        max-width="15"
      ></v-img>
    </span>
    <v-layout d-flex class="flex-wrap mt-1">
      <v-col
        class="d-flex justify-space-between align-items-center mt-1"
        lg="5"
        md="6"
        sm="12"
      >
        <div class="mr-4 mobile__mr__25px">
          <p class="grey--text ma-0 font-10">نوع الطلب</p>
          <h5 class="ma-0 font-weight-medium text-truncate max-width">
            <span> {{ requestInfo.type?.nameAr }} </span>
          </h5>
        </div>

        <div class="mr-4 mobile__mr__25px">
          <p class="grey--text ma-0 font-10">القسم</p>
          <h5 class="ma-0 font-weight-medium text-truncate max-width">
            <span> {{ requestInfo.category?.nameAr }} </span>
          </h5>
        </div>

        <div class="mr-4 mobile__mr__25px">
          <p class="grey--text ma-0 font-10">رقم الطلب</p>
          <h5 class="ma-0 font-weight-medium text-truncate max-width">
            <span> {{ requestInfo.number ?? '-' }} </span>
          </h5>
        </div>

        <div class="mobile__mr__25px mobile__mb__0">
          <p class="grey--text ma-0 font-10">تاريخ الإنشاء</p>
          <h5 class="ma-0 font-weight-medium">
            <span> {{ requestDate }} </span>
          </h5>
        </div>

        <div class="mobile__mr__25px mobile__mb__0" v-if="!isCustomer">
          <p class="grey--text ma-0 font-10">المسئول</p>
          <h5 class="ma-0 font-weight-medium">
            <span> {{ requestCreatorName }} </span>
          </h5>
        </div>
      </v-col>

      <v-col
        class="mr-4 show-expenses"
        v-if="showExpenses && !isCustomer"
        lg="4"
        md="6"
        sm="12"
      >
        <div
          class="mobile__mr__25px mobile__mb__0 mobile__mt__20px d-inline-block"
        >
          <p class="grey--text ma-0 font-10">
            مجموع المصروفات الكلية لهذا العقار
          </p>
          <h5 class="tag text-center brub--tag px-3 py-1 mt-1">
            {{ totalExpenses }} ريال
          </h5>
        </div>
        <div
          class="mr-6 mobile__mr__25px mobile__mb__0 mobile__mt__20px d-inline-block"
        >
          <p class="grey--text ma-0 font-10">حد المصروفات</p>
          <h5 class="tag text-center brub--tag px-3 py-1 mt-1">
            {{ expensesLimitText }}
          </h5>
        </div>
      </v-col>
    </v-layout>
  </v-layout>
</template>

<script>
import formatDate from '@/mixins/dateFormat.js'

export default {
  name: 'side-detailes-header',
  mixins: [formatDate],
  props: {
    isCustomer: {
      type: Boolean,
      default: false
    },
    requestTypes: {
      default() {
        return []
      }
    },
    requestInfo: {
      type: Object,
      required: true
    },
    totalExpenses: {
      type: Number,
      required: true
    },
    showExpenses: {
      type: Boolean,
      required: true
    }
  },
  emits: ['closeDetails'],
  data() {
    return {
      exitArrow: require('@/assets/img/svgs/right-arrow.svg')
    }
  },
  computed: {
    getRequestType() {
      return this.requestTypes.find(
        (request) => request.code === this.requestInfo.type
      )
    },
    requestCategoryArName() {
      return (
        this.getRequestType?.categories?.find(
          (request) => request.code === this.requestInfo.category
        )?.nameAr ?? 'غير محدد'
      )
    },
    requestDate() {
      return this.requestInfo.createdAt
        ? this.formatDate(this.requestInfo.createdAt, false)
        : 'غير محدد'
    },
    requestCreatorName() {
      return this.requestInfo.createdBy ?? 'غير محدد'
    },
    expenseLimit() {
      return this.requestInfo.expenseLimit ?? null
    },
    expensesLimitText() {
      switch (this.expenseLimit) {
        case null:
          return 'مفتوح'
        case 0:
          return 'مغلق'
        default:
          return `${this.expenseLimit} ريال`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.show-expenses {
  margin-top: 2px;
}
</style>
