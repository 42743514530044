<template>
  <div
    class="commentsSection ma-0 pt-5 d-flex flex-column"
    style="height: 90vh"
  >
    <!------ Title ------>
    <p class="font-14 mr-2">التعليقات</p>

    <!------ Loader ------>
    <v-row class="mx-0" v-if="commentsLoading">
      <v-col cols="12" v-for="i in 3" :key="i">
        <v-skeleton-loader
          class="background-transparent"
          width="300"
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <!------ If no there comments ------>
    <div v-else-if="comments.length === 0" class="ma-auto">
      <v-sheet>
        <v-img class="ma-auto" width="72" height="72" :src="noComments"></v-img>
        <span> ليست هناك اي تعليقات </span>
      </v-sheet>
    </div>

    <!------ Comments ------>
    <div v-else class="comments mb-auto mr-2">
      <CommentCard
        v-for="(comment, index) in comments"
        :key="comment._id"
        v-bind="generateCommentProps(comment)"
        @setActiveComment="setActiveComment"
        @setActiveTextArea="setActiveTextArea"
        @updateComment="updateComment($event, index)"
        @deleteComment="deleteComment($event, index)"
      />
    </div>

    <!------ Add comments ------>
    <v-sheet class="mb-2 send__message__textarea">
      <v-form @submit.prevent="addComment">
        <v-textarea
          filled
          v-model="commentContent"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="addComment"
          placeholder="أدخل تعليقك هنا"
          auto-grow
          hide-details
          class-content="font-12"
          no-resize
          rows="1"
          :disabled="isAddCommentLoading"
        >
          <template v-slot:append>
            <img
              @click="addComment"
              alt="add comment"
              :class="[
                'pt-3',
                'pointerCursor',
                { 'disable-add-comment': isAddCommentLoading }
              ]"
              src="@/assets/img/svgs/send.svg"
              loading="lazy"
            />
          </template>
        </v-textarea>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import CommentCard from '@/components/contracts/CommentCard'

export default {
  name: 'CommentsSection',
  components: { CommentCard },
  props: {
    commentsLoading: {
      type: Boolean,
      default: true
    },
    comments: {
      type: Array,
      default() {
        return []
      }
    },
    addCommentStatus: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    commentContent: '',
    noComments: require('@/assets/img/svgs/noComments.svg'),
    activeComment: null,
    activeTextArea: null,
    isAddCommentLoading: false
  }),
  computed: {
    isValidComment() {
      return !!this.commentContent.length
    }
  },
  watch: {
    addCommentStatus(val) {
      if (val === 'success') {
        this.commentContent = ''
        this.isAddCommentLoading = false
      } else if (val === 'failure') {
        this.isAddCommentLoading = false
      }
    }
  },
  methods: {
    addComment() {
      if (this.isValidComment) {
        this.isAddCommentLoading = true
        this.$emit('addComment', this.commentContent)
      }
    },
    updateComment(newComment, commentIndex) {
      this.$emit('updateComment', {
        newContent: newComment.content,
        index: commentIndex
      })
      this.activeTextArea = null
    },
    deleteComment(commentId, commentIndex) {
      this.$emit('deleteComment', {
        commentId,
        index: commentIndex
      })
    },
    generateCommentProps(comment) {
      return {
        comment,
        commentId: this.activeComment,
        isTerminated: this.isDoneTask,
        activeTextArea: this.activeTextArea
      }
    },
    setActiveComment(commentId) {
      this.activeComment = commentId
    },
    setActiveTextArea(commentId) {
      this.activeTextArea = commentId
    }
  }
}
</script>

<style lang="scss" scoped>
.disable-add-comment {
  pointer-events: none;
}
</style>
