<template>
  <v-container class="app-section">
    <div class="row">
      <div class="col-md-6 col-12">
        <div>
          <p class="download">حمل تطبيق هكتار</p>
          <p class="text">
            البحث عن مقدمي الخدمات العقارية والتواصل معهم بسهوله وبسرعه
          </p>
        </div>
        <div class="d-flex stores-container">
          <a
            href="https://play.google.com/store/apps/details?id=com.hectar.hectarapp&hl=ar&gl=US&pli=1"
            target="_blank"
          >
            <v-btn
              :width="mdAndDown ? '130' : '146'"
              text
              height="46"
              class="d-flex play button justify-center"
            >
              <img src="@/assets/img/play-store.svg" class="my-auto" alt="" />
              <span class="mb-1">متجر جوجل</span>
            </v-btn>
          </a>
          <a
            href="https://apps.apple.com/us/app/hectar-%D9%87%D9%83%D8%AA%D8%A7%D8%B1/id1560571291"
            target="_blank"
          >
            <v-btn
              :width="mdAndDown ? '130' : '146'"
              text
              height="46"
              class="d-flex apple button justify-center"
            >
              <img src="@/assets/img/apple-store.svg" class="my-auto" alt="" />
              <span class="mb-1">متجر أبل</span>
            </v-btn>
          </a>
        </div>
      </div>
      <div class="col-md-6 live-ss col-12">
        <div class="d-flex">
          <img src="@/assets/img/phone1.png" alt="" />
          <img src="@/assets/img/phone2.png" alt="" />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'appSection',
  computed: {
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown
    }
  }
}
</script>

<style lang="scss" scoped>
.app-section {
  padding-bottom: 6rem;
  padding-top: 6rem;

  @media (max-width: 425px) {
    padding-top: 20px !important;
    .download {
      text-align: center !important;
    }
    .info {
      text-align: center !important;
    }
  }

  @media (max-width: 768px) {
    padding-top: 0rem;
  }
  .download {
    color: #3e3e3e;
    text-align: right;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  .text {
    color: #000;
    text-align: right;
    font-size: 30px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-width: 450px;
    @media (max-width: 425px) {
      text-align: center;
    }
  }

  .stores-container {
    margin-top: 55px;

    @media (max-width: 425px) {
      margin-bottom: 55px;
      justify-content: space-between;
      .button {
        display: flex !important;
        padding: 10px !important;
      }
    }
    .play {
      background-color: #fff;
      border-radius: 6px;
      padding: 0 22px;
      span {
        color: #000;
        text-align: right;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .play:hover {
      background-color: #e7e7e7;
    }

    .apple {
      margin-right: 16px;
      background-color: #101010;
      border-radius: 6px;
      padding: 0 22px;
      span {
        color: #fff;
        text-align: right;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .apple:hover {
      background-color: #000000d9;
    }
  }

  .live-ss {
    div {
      justify-content: end;
    }
    @media (max-width: 425px) {
      img {
        margin: 0px 5px !important;
      }
      div {
        justify-content: center;
      }
    }
    img:not(:first-child) {
      margin-right: 40px;
    }
  }
}
</style>
