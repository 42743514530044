import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import colors from 'vuetify/lib/util/colors'

// This is here just in case you prefer to have all your plugins in a folder like this, otherwise its currently unused

Vue.use(Vuetify)

export default new Vuetify({
  rtl: true,
  lang: {
    current: 'ar'
  },
  icons: {
    iconfont: 'mdi' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#662d91', //#662d91
        general: '#66328e',
        secondary: '#59bac0',
        tertiary: '#495057',
        accent: '#82B1FF',
        error: '#EE6B43',
        danger: '#f55a4e',
        info: '#3075E3',
        green: '#3CB97D',
        warning: '#ffbe3d',
        success: '#5cc9a7',
        brown: '#d6937c',
        purble: '#da6eec',
        lamb: '#9F9FAC',
        black: '#1d1d1d',
        rib: '#34ABB3',
        gery: '#737373',
        lightGery: 'rgba(159,159,172,0.04)',
        white: '#fff',
        turquoise: '#6BA2DE'
      }
    }
  }
})
