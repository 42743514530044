<template v-if="optionsLength">
  <v-card
    class="pa-2 rounded-15"
    :to="`/dashboard/property-details/${options._id}?parent=${
      options.parentId ? options.parentId : options._id
    }`"
    id="propertyCard"
  >
    <div class="pa-0 w-100">
      <div class="position-relative" v-if="!!showDetails">
        <!--carousel-->
        <v-carousel
          class="sync-card"
          id="carouselGrid"
          height="140"
          hide-delimiter-background
          hide-delimiters
          show-arrows-on-hover
          v-if="options.images && options.images.length > 0"
          v-model="model"
        >
          <template v-slot:prev="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click.stop.prevent="on"
              color="#FFFFFF"
              >mdi-chevron-right</v-icon
            >
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click.stop.prevent="on"
              color="#FFFFFF"
              >mdi-chevron-left</v-icon
            >
          </template>
          <v-carousel-item
            :key="image.smallImage.path"
            v-for="image in options.images"
          >
            <div
              :style="`
                height: 140px;
                background: url(${image.smallImage.path}) no-repeat center;
                border-radius: 10px;
                background-size: cover;
              `"
            ></div>
          </v-carousel-item>
        </v-carousel>
        <div
          :style="`
                        height: 140px;
                        background: url(${proprty}) no-repeat;
                        background-size: cover;
                        border-radius: 10px;
                       `"
          v-else
        ></div>
      </div>

      <!--end carousel-->
      <div class="mt-3 px-2 w-100 property-card-pointer">
        <div class="property__card__mh">
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex align-center">
              <img :src="home" alt="home" class="ml-2" loading="lazy" />
              <h5 class="ma-0 font-weight-medium" v-if="options.type">
                {{ options.type.nameAr }}
              </h5>
            </div>
            <div>
              <h5
                v-if="options.status"
                :class="statusClasses"
                class="ma-0 tag text-center"
              >
                {{ options.status.nameAr }}
              </h5>
            </div>
          </div>
          <div class="d-flex align-center">
            <img :src="location" alt="location" class="ml-2" loading="lazy" />
            <h5 class="ma-0 text-truncate font-weight-medium text--darken-3">
              {{ propertyTitle }}
            </h5>
          </div>
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex align-center">
              <img :src="square" alt="square" class="ml-2" loading="lazy" />
              <h5 class="ma-0 font-weight-medium text--darken-3">
                {{ options.space }} متر
              </h5>
            </div>
            <div class="d-flex align-center">
              <img :src="money" alt="money" class="ml-2" loading="lazy" />
              <h5 class="ma-0 font-weight-medium text--darken-3 mb-1">
                {{ optionPrice }} ريال
              </h5>
            </div>
          </div>
        </div>
        <!-- property__card__mh -->
        <v-divider class="my-2"></v-divider>
        <div class="d-flex justify-space-between align-center">
          <div v-if="!$route.query.parent">
            <h6 class="text-light font-weight-light ma-0 line-height-1">
              المالك
            </h6>
            <h5
              class="ma-0 font-weight-bold text--darken-3 text-truncate mw-75"
              v-if="owner"
            >
              {{ options.owner.name }}
            </h5>
            <h5 class="ma-0 font-weight-bold text--darken-3" v-else>لايوجد</h5>
          </div>
          <div v-if="moderator">
            <h5
              v-if="!$route.query.parent"
              class="bill-sm font-12 d-flex align-center justify-center my-2 text-center red--tag font-weight-bold"
            >
              {{ $subString(options.moderator.name) }}
            </h5>
            <div v-else>
              <h6 class="text-light font-weight-light ma-0 line-height-1">
                المسئول
              </h6>
              <h5
                class="ma-0 font-weight-bold text--darken-3 text-truncate mw-75"
                v-if="owner"
              >
                {{ options.moderator.name }}
              </h5>
            </div>
          </div>
          <div>
            <h6 class="text-light font-weight-light ma-0 line-height-1">
              {{ ownerType }}
            </h6>
            <h5
              class="ma-0 text--darken-3"
              :class="{
                'text-truncate font-weight-bold mw-75':
                  !isFullyOrPartiallyRented
              }"
            >
              {{ tenetName }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'PropertyCard',
  props: {
    options: {
      required: true,
      default: () => {
        return {}
      }
    },
    propertyName: {},
    parentId: {},
    showDetails: {
      default: true
    },
    isFromDetails: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    model: 0,
    home: require('@/assets/img/icons/home.png'),
    location: require('@/assets/img/icons/location.png'),
    money: require('@/assets/img/svgs/money.svg'),
    square: require('@/assets/img/svgs/square.svg'),
    proprty: require('@/assets/img/proprty.png')
  }),
  computed: {
    optionsLength() {
      return !!Object.keys(this.options).length
    },
    owner() {
      return this.options?.owner
    },
    moderator() {
      return this.options?.moderator
    },
    ownerType() {
      return this.options?.status?.nameEn === 'sale' ||
        this.options?.status?.nameEn === 'sold'
        ? 'المشتري'
        : 'المستأجر'
    },
    optionPrice() {
      return (
        this.options.price?.toLocaleString('en-US', {
          maximumFractionDigits: 2
        }) ?? 'غير محدد'
      )
    },
    propertyTitle() {
      if (this.isFromDetails) {
        const propertyName = this.options.propertyName.split(/ - (.+)/)
        if (propertyName.length > 1) {
          return propertyName[1]
        }
        return this.options.propertyName
      }
      return this.options.propertyName
    },
    isRealEstateHasUnits() {
      return (
        this.options.type?.nameEn === 'building' ||
        this.options.type?.nameEn === 'compound' ||
        this.options.type?.nameEn === 'Tower' ||
        this.options.type?.nameEn === 'Building' ||
        this.options.type?.nameEn === 'villa'
      )
    },
    tenetName() {
      if (this.ownerType === 'المستأجر' && this.isRealEstateHasUnits) {
        if (!this.options.unitsCount) {
          return this.options.tenet?.name ?? 'لايوجد'
        } else if (!this.options.rentedUnitsCount) {
          return 'لا يوجد'
        } else if (this.options.rentedUnitsCount < this.options.unitsCount) {
          return 'مؤجرة جزئيا'
        }
        return 'مؤجرة بالكامل'
      }
      return this.options.tenet?.name ?? 'لايوجد'
    },
    isFullyOrPartiallyRented() {
      return (
        this.tenetName === 'مؤجرة جزئيا' || this.tenetName === 'مؤجرة بالكامل'
      )
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly
    },
    statusClasses() {
      let classes = this.xs ? 'pa-1 ' : 'pb-1 '
      switch (this.options?.status.nameAr) {
        case 'مؤجر':
          classes += 'green--tag'
          break
        case 'مؤجر':
          classes += 'orange--tag'
          break
        case 'للبيع':
          classes += 'brub--tag'
          break
        case 'للإيجار':
        case 'شاغرة':
          classes += 'blue--tag'
          break
        case 'ايجار':
          classes += 'gery--tag'
          break
      }
      return classes
    }
  }
}
</script>

<style lang="scss">
@import '../../../src/styles/responsiveness/responsiveness.scss';
#propertyCard {
  .cardBtn {
    bottom: 40%;
    right: 19%;
    display: none;
  }

  &:hover {
    .cardBtn {
      display: block;
    }
  }
}

.mw-75 {
  max-width: 75px;
}
#propertyCard {
  .tag {
    border-radius: 30px;
    min-width: 65px;
  }
  .line-height-1 {
    line-height: 1 !important;
  }
  // line-height-1
  .bill-sm {
    width: 28px;
    height: 28px;
  }
  .property-card-pointer {
    cursor: pointer;
    @include max-xl {
      h5,
      .h5 {
        font-size: 0.6rem !important;
      }
      h6,
      .h6 {
        font-size: 0.55rem !important;
      }
    }
    // @include max-xl
    .property__card__mh {
      height: 75px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
// propertyCard

//carousel
#carouselGrid {
  overflow: hidden !important;
  div.v-window__prev {
    position: absolute !important;
    margin: 0px !important;
    top: calc(50% - 20px) !important;
    right: 0px !important;
    z-index: 1 !important;
  }

  div.v-window__next {
    position: absolute !important;
    margin: 0 !important;
    top: calc(50% - 20px) !important;
    left: 0px !important;
    z-index: 1 !important;
    width: 24px !important;
  }
}
</style>
