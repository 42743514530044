<!-- eslint-disable vue/valid-v-for -->
<template>
  <div>
    <ReportHeaderCard :generalReportsHeaderData="generalReportsHeaderData" />

    <GeneralSummary
      :tables="totalTables"
      :reports="reports"
      :headers="headers"
      :totalTableKeys="totalTableKeys"
      :generateTooltipData="generateTooltipData"
      :headersInit="headersInit"
    />

    <DataTableDetails
      :reports="reports"
      :headers="headers"
      :headersInit="headersInit"
      :tables="nonTotalTables"
    />
  </div>
</template>

<script>
import DataTableDetails from '@/components/reports/OwnerReportTabs/DataTableDetails'
import ReportHeaderCard from './SubComponents/reportHeaderCard.vue'
import GeneralSummary from '@/components/reports/OwnerReportTabs/GeneralSummary'

export default {
  name: 'GeneralReportView',
  components: {
    ReportHeaderCard,
    GeneralSummary,
    DataTableDetails
  },
  props: {
    generalReportsHeaderData: {
      default() {
        return []
      },
      required: true
    },
    mainReports: {
      default() {
        return []
      },
      required: true
    },
    headers: {
      default() {
        return []
      },
      required: true
    },
    reports: {
      default() {
        return []
      },
      required: true
    },
    totalTableKeys: {
      default() {
        return []
      },
      required: true
    },
    generateTooltipData: {
      default() {
        return {}
      },
      required: true
    },
    headersInit: {
      default() {
        return []
      },
      required: true
    }
  },
  data() {
    return {
      tooltipIcon: require('@/assets/img/svgs/exclamation-white-rounded.svg'),
      totalTablesKeys: [],
      nonTotalTablesKeys: [],
      totalTables: [],
      nonTotalTables: []
    }
  },
  methods: {
    setTables() {
      this.mainReports.map((table) => {
        if (this.reports[table.code][1]?.totals) {
          this.totalTablesKeys.push(table)
        } else {
          this.nonTotalTablesKeys.push(table)
        }
      })

      // eslint-disable-next-line no-unused-expressions
      this.totalTablesKeys?.map(({ code }) => {
        this.totalTables.push(this.restructureReportTables[code])
      })

      // eslint-disable-next-line no-unused-expressions
      this.nonTotalTablesKeys?.map(({ code }) => {
        this.nonTotalTables.push(this.restructureReportTables[code])
      })
    }
  },
  computed: {
    restructureReportTables() {
      return this.mainReports.reduce(
        (obj, item) => Object.assign(obj, { [item.code]: item }),
        {}
      )
    }
  },
  created() {
    this.setTables()
  }
}
</script>
