<template>
  <div class="mt-5">
    <modelUint
      v-for="(template, i) in notificationsModel"
      :key="i"
      :template="template"
      :icon="template.icon"
      @changeTemplate="template = $event"
    />
    <modelsEdit
      :dialogState="dialogState"
      @changeDialogState="dialogState = $event"
      :data="template"
      @changeData="template = $event"
      @closed-add-dialog="dialogState = !dialogState"
    />
  </div>
</template>

<script>
import modelUint from './modelUint'
import modelsEdit from './modelsEdit'

export default {
  name: 'notifications',
  components: { modelUint, modelsEdit },
  data() {
    return {
      dialogState: false,
      template: {},
      notificationsModel: [
        {
          title: 'ياسر محمد يريد فسخ التعاقد',
          body: 'نسعد بزيارتك في الوقت المناسب والضغط علي الاشعار لمشاهده المزيد',
          icon: require('@/assets/img/svgs/closeBG.svg')
        },
        {
          title: 'ياسر محمد يريد فسخ التعاقد',
          body: 'نسعد بزيارتك في الوقت المناسب والضغط علي الاشعار لمشاهده المزيد',
          icon: require('@/assets/img/svgs/speech-bubbleBG.svg')
        },
        {
          title: 'ياسر محمد يريد فسخ التعاقد',
          body: 'نسعد بزيارتك في الوقت المناسب والضغط علي الاشعار لمشاهده المزيد',
          icon: require('@/assets/img/svgs/walletBG.svg')
        },
        {
          title: 'ياسر محمد يريد فسخ التعاقد',
          body: 'نسعد بزيارتك في الوقت المناسب والضغط علي الاشعار لمشاهده المزيد',
          icon: require('@/assets/img/svgs/closeBG.svg')
        }
      ]
    }
  }
}
</script>
