<template>
  <div>
    <v-tabs
      align-with-title
      color="primary"
      right
      v-model="tab"
      class="setting__page__tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <template>
        <v-tab
          :ripple="false"
          class="mr-6 rounded-15"
          v-for="item in availableTabs"
          :key="item.name"
        >
          {{ item.title }}
        </v-tab>
      </template>
      <v-tabs-items class="setting__page__tabs" v-model="tab">
        <v-tab-item
          v-for="item in availableTabs"
          :key="item.name"
          :eager="true"
        >
          <component :is="item.name" v-on="$listeners" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Team from '@/views/Team.vue'
import customerInviteSetting from './customer-invite-setting.vue'
import DefaultersSettings from './DefaultersSettings.vue'

export default {
  name: 'InvitationSettings',
  components: { Team, customerInviteSetting, DefaultersSettings },
  data() {
    return {
      availableTabs: [
        {
          title: 'الدعوات',
          name: 'customerInviteSetting'
        }
      ],
      tab: 0
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'subscription']),
    bundle() {
      return this.subscription?.bundle
    }
  }
}
</script>
