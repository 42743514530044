<template>
  <div class="show-detailes contentSection tax__invoices__side__details">
    <v-slide-x-transition>
      <v-layout>
        <v-flex
          v-if="!md"
          @click="$emit('showDetails', { visible: false, taxInvoice: null })"
          class="greySide"
          md4
        ></v-flex>
        <v-flex class="whiteSide" :cols="md ? 8 : 12">
          <sideDetailesTaxInvoiceHeader
            class="header__layout mobile__pr__15px"
            :item="tax"
            :isCancelledTax="isCancelledTax"
            @closeAddModelsEdit="$emit('closeAddModelsEdit', { tax: tax })"
            @closeDetails="
              $emit('showDetails', { visible: false, taxInvoice: null })
            "
            @sharePdf="sharePdf"
            @editTax="editTax"
            @deleteTax="deleteTax"
            @changeToApproved="changeTaxToApproved"
          />
          <v-divider class="mt-3"></v-divider>
          <v-layout class="tab__flex__wrap">
            <v-flex md8 class="mobile__pr__15px">
              <v-layout column class="contentSection">
                <h4 class="mt-6 font-14 font-weight-medium mobile__mt__10px">
                  تفاصيل الفاتوره الضريبيه
                </h4>
                <v-layout class="mt-5 mb-8 mobile__flex__wrap mobile__mb__0">
                  <v-layout column class="ml-10">
                    <div class="">
                      <h6 class="text-gray ma-0">اسم المالك</h6>
                      <h5 class="ma-0 font-weight-medium text-truncate w-200">
                        {{ taxOwner }}
                      </h5>
                    </div>
                    <div class="mt-3">
                      <h6 class="text-gray ma-0">اسم المستأجر</h6>
                      <h5 class="ma-0 font-weight-medium text-truncate w-200">
                        {{ taxRenter }}
                      </h5>
                    </div>
                  </v-layout>
                  <v-layout column class="ml-10">
                    <div class="">
                      <h6 class="text-gray ma-0">عقار</h6>
                      <h5 class="ma-0 font-weight-medium">
                        {{ taxRealestate }}
                      </h5>
                    </div>
                    <div class="mt-3">
                      <h6 class="text-gray ma-0">تاريخ الإنشاء</h6>
                      <h5 class="ma-0 font-weight-medium">
                        {{ formatDate(taxCreatedDate) }}
                      </h5>
                    </div>
                  </v-layout>
                  <v-layout column class="ml-10">
                    <div class="">
                      <h6 class="text-gray ma-0">المبلغ</h6>
                      <h5 class="ma-0 font-weight-medium">
                        {{ taxPayment }} ريال
                      </h5>
                    </div>
                    <div class="mt-3">
                      <h6 class="text-gray ma-0">نسبه الضريبه المضافه</h6>
                      <h5 class="ma-0 font-weight-medium">{{ taxOfTax }} %</h5>
                    </div>
                  </v-layout>
                  <v-layout column class="ml-10">
                    <div class="">
                      <h6 class="text-gray ma-0">من</h6>
                      <h5 class="ma-0 font-weight-medium">
                        {{ taxInvoiceDateFrom }}
                      </h5>
                    </div>
                    <div class="mt-3">
                      <h6 class="text-gray ma-0">إلي</h6>
                      <h5 class="ma-0 font-weight-medium">
                        {{ taxInvoiceDateTo }}
                      </h5>
                    </div>
                  </v-layout>
                  <div class="d-flex align-center ml-4">
                    <div class="justify-space-between align-center">
                      <div>
                        <h6 class="text-gray ma-0 text-truncate">
                          قيمه الضريبه المضافه
                        </h6>
                        <h5
                          class="text-truncate ma-0 font-weight-medium text-truncate w-135px"
                        >
                          {{ taxTotal }} ريال
                        </h5>
                      </div>
                    </div>
                  </div>
                </v-layout>
              </v-layout>
            </v-flex>
            <v-divider vertical class="mx-4 mobile__d__none"></v-divider>
            <v-flex md4>
              <div
                class="commentsSection ma-0 d-flex flex-column"
                style="height: 90vh"
              >
                <p class="font-14 mt-4 mr-2">التعليقات</p>
                <v-row class="mx-0" v-if="!commentsLoaded">
                  <v-col cols="12" v-for="i in 3" :key="i">
                    <v-skeleton-loader
                      class="background-transparent"
                      width="300"
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>

                <div
                  class="ma-auto"
                  v-else-if="!comments.length && commentsLoaded"
                >
                  <v-sheet>
                    <span>
                      <v-img
                        class="ma-auto"
                        width="72"
                        height="72"
                        :src="noComments"
                      ></v-img>
                      <span class="comments__info__msg">
                        ليست هناك اي تعليقات
                      </span>
                    </span>
                  </v-sheet>
                </div>

                <div v-else class="comments mb-auto mr-2">
                  <CommentCard
                    v-for="(comment, index) in comments"
                    :key="comment._id"
                    v-bind="generateCommentProps(comment)"
                    @setActiveComment="setActiveComment"
                    @setActiveTextArea="setActiveTextArea"
                    @updateComment="updateComment($event, index)"
                    @deleteComment="deleteComment($event, index)"
                  />
                </div>

                <v-sheet
                  class="mb-2 mr-4 input__box mobile__m__0 mobile__mb__10px"
                  v-if="$hasPermission('properties', 'taxBills', 'add')"
                >
                  <v-form @submit.prevent="addComment">
                    <v-textarea
                      filled
                      :disabled="isCancelledTax"
                      v-model="commentContent"
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="addComment"
                      placeholder="أدخل تعليقك هنا"
                      auto-grow
                      class-content="font-12"
                      hide-details
                      no-resize
                      rows="1"
                    >
                      <template v-slot:append>
                        <img
                          @click="addComment"
                          alt="add comment"
                          class="px-3 pointerCursor"
                          src="@/assets/img/svgs/send.svg"
                          loading="lazy"
                        />
                      </template>
                    </v-textarea>
                  </v-form>
                </v-sheet>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-slide-x-transition>
  </div>
</template>

<script>
import sideDetailesTaxInvoiceHeader from '@/components/TaxInvoices/sideDetailesTaxInvoiceHeader.vue'
import CommentCard from '@/components/contracts/CommentCard.vue'
import dateFormat from '@/mixins/dateFormat.js'
import { messageService } from '@/services/messagae.service'

export default {
  name: 'sideTaxInvoiceDetails',
  components: { sideDetailesTaxInvoiceHeader, CommentCard },
  props: {
    item: {
      default() {
        return {}
      }
    }
  },
  mixins: [dateFormat],
  data() {
    return {
      commentsLoaded: false,
      activeComment: null,
      activeTextArea: null,
      comments: [],
      commentContent: '',
      noComments: require('@/assets/img/svgs/noComments.svg'),
      tax: {}
    }
  },
  watch: {
    item(newVal) {
      this.tax = this.item
      if (newVal) {
        this.loadComments()
      } else {
        this.commentsLoaded = false
      }
    }
  },
  computed: {
    isValidComment: () => (commentContent) => {
      return !!commentContent.length
    },
    md() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isCancelledTax() {
      return this.tax?.deleted
    },
    isApprovedTax() {
      return !!(this.tax?.status === 'approved')
    },
    isDraftTax() {
      return !!(this.tax?.status === 'draft')
    },
    currentUser() {
      return JSON.parse(localStorage.getItem('tokenData'))
    },
    taxOwner() {
      return this.tax?.owner?.name ?? 'المالك'
    },
    taxRenter() {
      return this.tax?.renter?.name ?? 'المستأجر'
    },
    taxRealestate() {
      return this.tax?.realEstate?.name ?? 'اسم العقار'
    },
    taxCreatedDate() {
      return this.tax?.createdAt
    },
    taxPayment() {
      return this.tax?.payment?.toLocaleString('en-US') ?? ''
    },
    taxOfTax() {
      return this.tax?.tax
    },
    taxDateFrom() {
      return this.tax?.dateFrom
    },
    taxDateTo() {
      return this.tax?.dateTo
    },
    taxTotal() {
      return this.tax?.total?.toFixed(2)?.toLocaleString('en-US') ?? ''
    },
    taxInvoiceDateFrom() {
      return this.tax?.hijri
        ? this.formatToHijriDate(this.taxDateFrom)
        : this.formatDate(this.taxDateFrom)
    },
    taxInvoiceDateTo() {
      return this.tax?.hijri
        ? this.formatToHijriDate(this.taxDateTo)
        : this.formatDate(this.taxDateTo)
    }
  },
  beforeDestroy() {
    localStorage.removeItem('sideDetailsItem')
  },
  methods: {
    closeDetails() {
      this.$emit('closeDetails')
    },
    editTax(e) {
      this.closeDetails()
      if (this.isApprovedTax) {
        this.$emit('editApprovedTax', e)
      } else {
        this.$emit('editDraftTax', e)
      }
    },
    deleteTax() {
      this.$emit('deleteTax', this.item)
    },
    async loadComments() {
      const query = {
        entityId: this.tax?._id
      }
      if (query?.entityId?.length) {
        const res = await messageService.getComments(query)
        this.comments = res.data.items
        this.commentContent = ''
      }
      this.commentsLoaded = true
    },
    async addComment() {
      if (this.isValidComment(this.commentContent)) {
        const CommentToSend = {
          content: this.commentContent,
          entityType: 'taxBill',
          entityId: this.tax._id,
          author: {
            _id: this.currentUser._id,
            name: this.currentUser.name,
            email: this.currentUser.email,
            phoneNumber: this.currentUser.phoneNumber,
            type: this.currentUser.type
          },
          receiver: {
            _id: this.item.owner._id,
            name: this.item.owner.name
          },
          context: {
            platform: 'hectarPlus'
          }
        }
        this.commentContent = ''
        await messageService.addComment(CommentToSend)
        this.loadComments()
      }
    },
    async deleteComment(commentId, commentIndex) {
      await messageService.deleteComment(commentId).then(() => {
        this.comments.splice(commentIndex, 1)
      })
    },
    updateComment(newComment, commentIndex) {
      if (this.isValidComment(newComment.content)) {
        const updatedCommentToSend = {
          content: newComment.content,
          _id: newComment.commentId
        }
        messageService.updateComment(updatedCommentToSend).then(() => {
          this.$set(this.comments[commentIndex], 'content', newComment.content)
        })
      }
      this.activeTextArea = null
    },
    setActiveComment(commentId) {
      this.activeComment = commentId
    },
    setActiveTextArea(commentId) {
      this.activeTextArea = commentId
    },
    generateCommentProps(comment) {
      return {
        comment,
        commentId: this.activeComment,
        isTerminated: this.isCancelledTax,
        activeTextArea: this.activeTextArea
      }
    },
    sharePdf() {
      this.$parent.sharePdf(this.item)
    },
    changeTaxToApproved(tax) {
      this.$emit('exportTax', { taxInvoice: tax, fromDetails: true })
    }
  },
  created() {
    this.tax = this.item
    this.loadComments()
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_sideDetails.scss';
.whiteSide {
  min-width: 80vw;
}
</style>
