<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :max-height="options.height"
    :style="{ zIndex: options.zIndex, border: 'none' }"
    @keydown.esc="cancel"
  >
    <v-card style="border: 0 !important">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-icon @click="cancelLogout" :width="16">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text class="my-0 pb-4 text-center">
        <v-img
          :max-width="img.width"
          :max-height="img.height"
          class="mx-auto"
          :src="img.path"
        ></v-img>
        <div class="mt-6 font-18 font-weight-medium mainTitle">حسابك مسجل</div>
        <div class="mt-3 font-14 font-weight-medium subTitle">
          هل تريد تسجيل الخروج من حسابك الحالي و متابعه قبول الدعوده
        </div>
      </v-card-text>
      <v-card-actions class="actionsContainer">
        <v-btn
          class="btnActions cancel mx-2 mt-0"
          @click.native="cancelLogout"
          min-width="135"
          min-height="40"
        >
          <span class="tf">إالغاء</span>
        </v-btn>
        <!--@click.native="agree"-->
        <v-btn
          @click="setLogout"
          :color="options.color"
          class="btnActions mx-2 mt-0"
          min-width="135"
          min-height="40"
        >
          <span class="tf">موافق</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  data() {
    return {
      img: {
        path: require('@/assets/img/svgs/exported.svg'),
        width: 55,
        height: 55
      },
      options: {
        color: 'primary',
        width: 330,
        height: 278,
        zIndex: 200
      }
    }
  },
  computed: {
    ...mapState('appState', ['dialog']),
    dialogState: {
      get() {
        return this.dialog
      },
      set(val) {
        this.setDialog(val)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['setDialog']),
    ...mapActions('accountState', ['logout']),
    async setLogout() {
      this.setDialog(false)
      await this.logout()
      location.reload()
    },
    cancelLogout() {
      this.setDialog(false)
      this.$router.push({
        path: '/dashboard'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.v-card .v-card__actions.actionsContainer {
  border: none;
  .btnActions {
    box-shadow: 0px 4px 20px rgba($primary-color, 0.1);
    border-radius: 13px;
  }
  .cancel {
    background: #9f9fac;
  }
}
</style>
