<template>
  <div>
    <div class="d-flex justify-space-between align-center mb-2">
      <div class="d-flex" :class="{ 'w-100': sm }">
        <v-slide-x-transition>
          <v-card
            :class="{ 'w-100': sm }"
            class="d-flex justify-space-around px-2"
          >
            <h6
              :class="{ 'border-black': activeFilter == filter.nameEn }"
              @click="handleFilterChange(filter)"
              class="cursor mx-3"
              v-for="(filter, i) in availableFilters"
              :key="i"
              data-test="contractFilter"
            >
              {{ filter.nameAr }}
            </h6>
          </v-card>
        </v-slide-x-transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contracts-filters',
  props: {
    availableFilters: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeFilter: null
    }
  },
  computed: {
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    handleFilterChange(filter) {
      this.activeFilter = filter.nameEn
      this.$emit('handleFilters', filter.nameEn)
    }
  }
}
</script>
