<template>
  <v-dialog max-width="760px" persistent v-model="dialogState_">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="pt-0 pb-1 px-4 mb-5 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0">
          {{ taxToBeEditValid ? 'تحديث بيانات فاتوره' : 'اضافة فاتوره ضريبيه' }}
        </h4>
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-tabs-items v-model="tab" class="px-4" touchless>
        <!-- real-estate-step -->
        <v-tab-item>
          <real-estate-step
            ref="realestateStep"
            :resetValidation="resetValidationStatus"
            :realestate-types="realestateTypes"
            :taxRealEstateType="taxRealEstateType"
            :validate="validate"
            :taxToBeEdit="addTaxInfo"
            :taxToBeEditValid="taxToBeEditValid"
            @nextTab="nextTab"
            @setAddTaxInfo="setAddTaxInfo"
            @addNotification="pushValidationNotification"
            @resetTaxToBeEdit="handleResetModel"
            @setRealestateParties="setRealestateParties"
          />
        </v-tab-item>
        <!-- owner-step -->
        <v-tab-item>
          <owner-step
            ref="ownerStep"
            :resetValidation="resetValidationStatus"
            :client-type="clientType"
            :validate="validate"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @setAddTaxInfo="setAddTaxInfo"
            @addNotification="pushValidationNotification"
            @addUser="isNewOwner = $event"
            :taxToBeEdit="addTaxInfo"
            :taxToBeEditValid="taxToBeEditValid"
          />
        </v-tab-item>
        <!-- renter-step -->
        <v-tab-item>
          <renter-step
            ref="renterStep"
            :resetValidation="resetValidationStatus"
            :client-type="clientType"
            :validate="validate"
            @previousTab="previousTab"
            @nextTab="nextTab"
            @setAddTaxInfo="setAddTaxInfo"
            @addNotification="pushValidationNotification"
            @addUser="isNewRenter = $event"
            :taxToBeEdit="addTaxInfo"
            :taxToBeEditValid="taxToBeEditValid"
          />
        </v-tab-item>
        <!-- info-step -->
        <v-tab-item>
          <info-step
            ref="infoStep"
            :resetValidation="resetValidationStatus"
            @previousTab="previousTab"
            @setAddTaxInfo="setAddTaxInfo"
            @exportTax="exportTax"
            @updateTax="updateTax"
            @addNotification="pushValidationNotification"
            :taxToBeEdit="addTaxInfo"
            :taxToBeEditValid="taxToBeEditValid"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import config from '@/config'
import RealEstateStep from '../TaxInvoices/addFreeTax/RealEstateStep'
import OwnerStep from '../TaxInvoices/addFreeTax/OwnerStep'
import RenterStep from '../TaxInvoices/addFreeTax/RenterStep'
import InfoStep from '../TaxInvoices/addFreeTax/InfoStep'
import { taxBillsService } from '@/services/taxBills.service.js'
import { EnvService } from '@/services'
import checkClientData from '@/mixins/checkClientData.js'
import confirmationMixin from '@/mixins/confirmationMixin.js'

export default {
  name: 'AddTaxInvoices',
  mixins: [checkClientData, confirmationMixin],
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    taxToBeEdit: {
      default() {
        return {}
      }
    },
    taxToBeEditValid: {
      default: false
    },
    resetValidationStatus: {
      default: false
    },
    fromTaxInvoicesPage: {
      type: Boolean,
      default: true
    }
  },
  components: { InfoStep, RenterStep, OwnerStep, RealEstateStep },
  data() {
    return {
      tab: 0,
      isValid: false,
      validate: false,
      addTaxInfo: {},
      tabsComponentsRefs: [
        'realestateStep',
        'ownerStep',
        'renterStep',
        'infoStep'
      ],
      isNewOwner: {},
      isNewRenter: {}
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'user', 'currentEnv']),
    activeComponentRef() {
      return this.tabsComponentsRefs[this.tab]
    },
    clientType() {
      return config?.taxClientType
    },
    taxRealEstateType() {
      return config?.taxRealEstateType
    },
    realestateTypes() {
      return this.configs?.realestateTypes
    },
    infoToSend() {
      const dataToAdd = {
        title: this.addTaxInfo?.taxInfo?.title,
        realEstate: {
          name: this.addTaxInfo?.realEstate?.propertyName,
          _id: this.addTaxInfo?.realEstate?._id,
          type: this.addTaxInfo?.realEstate?.type,
          address: this.addTaxInfo?.realEstate?.address,
          moderators: this.addTaxInfo?.realEstate?.moderators,
          parentId: this.addTaxInfo?.realEstate?.parentId
        },
        owner: {
          name: this.addTaxInfo?.owner?.name,
          _id: this.addTaxInfo?.owner?._id,
          taxNumber: Number(this.addTaxInfo?.owner?.taxNumber),
          idNumber: this.addTaxInfo?.owner?.idNumber,
          phoneNumber: this.addTaxInfo?.owner?.phoneNumber,
          clientType: this.generateClientType(
            this.addTaxInfo?.owner?.clientType || this.addTaxInfo?.owner?.type
          )
        },
        renter: {
          name: this.addTaxInfo?.renter?.name,
          _id: this.addTaxInfo?.renter?._id,
          taxNumber: Number(this.addTaxInfo?.renter?.taxNumber),
          idNumber: this.addTaxInfo?.renter?.idNumber,
          phoneNumber: this.addTaxInfo?.renter?.phoneNumber,
          clientType: this.generateClientType(
            this.addTaxInfo?.renter?.clientType || this.addTaxInfo?.renter?.type
          )
        },
        payment: this.addTaxInfo?.taxInfo?.payment,
        tax: this.addTaxInfo?.taxInfo?.tax,
        environment: this.currentEnv._id
      }
      if (this.addTaxInfo?.taxInfo?.hijri) {
        dataToAdd.hijri = this.addTaxInfo.taxInfo.hijri
      } else {
        dataToAdd.dateFrom = this.addTaxInfo?.taxInfo?.dateFrom
        dataToAdd.dateTo = this.addTaxInfo?.taxInfo?.dateTo
      }
      return dataToAdd
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('resetValidationStatusToggeling', value)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setRealestateParties({ owner, tenet }) {
      if (!this.taxToBeEditValid) {
        this.addTaxInfo = { ...this.addTaxInfo, owner }
        this.addTaxInfo = { ...this.addTaxInfo, renter: tenet }
      }
    },
    async addUser(payload) {
      if (payload && !Object.keys(payload).length) {
        return
      }
      try {
        const userToSend = {
          clientType: payload.newUser?.clientType,
          idNumber: payload.newUser?.idNumber,
          name: payload.newUser?.name,
          phoneNumber: payload.newUser?.phoneNumber,
          taxNumber: payload.newUser?.taxNumber,
          userAddress: {
            address: payload.newUser.address,
            city: payload.newUser.city,
            district: payload.newUser.district
          },
          environment: {
            _id: this.currentEnv._id,
            name: this.currentEnv.name,
            role: [payload.role]
          },
          phoneNumber: payload.newUser.phoneNumber
            .replace(/ /g, '')
            ?.replace('0', '966')
        }
        const { data } = await EnvService.addClient(userToSend)
        if (data && data.user) {
          this.$set(this.addTaxInfo, payload.role, data.user)
          this.addNotification({
            text:
              payload.role == 'owner'
                ? 'تم اضافة المالك بنجاح'
                : 'تم اضافة المستأجر بنجاح',
            color: 'green'
          })
        }
      } catch {
        this.addNotification({
          text: 'لم نتمكن من اضافه العميل, حاول في وقت لاحق',
          color: 'red'
        })
      }
    },
    handleResetModel() {
      this.$emit('resetTaxToBeEdit')
      this.$emit('close-tax-dialog')
    },
    pushValidationNotification() {
      this.addNotification({
        text: 'يجب ملئ جميع الحقول المطلوبه',
        color: 'error'
      })
    },
    closeDialog() {
      this.tab = 0
      this.$emit('resetValidationStatusToggeling')
      this.$emit('close-tax-dialog')
    },
    setAddTaxInfo(innerObj) {
      this.addTaxInfo = { ...this.addTaxInfo, ...innerObj }
    },
    async exportTax() {
      this.$refs.infoStep.addUpdateBtnLoading = true
      await Promise.all([
        await this.addUser(this.isNewOwner),
        await this.addUser(this.isNewRenter)
      ])
      taxBillsService
        .addTax(this.infoToSend)
        .then(({ data: { taxBill } }) => {
          this.addTaxInfo = taxBill
          // eslint-disable-next-line no-unused-expressions
          taxBill.needsApproval
            ? this.showConfirmationPopupMixin()
            : this.$root.$confirm
                .open({
                  title: `تم إصدار فاتوره ضريبيه حره`,
                  message:
                    'تم إنشاء الفاتوره تحت مسئوليتك الخاصه بالبيانات المرفقه',
                  options: {
                    color: 'primary',
                    width: 400,
                    zIndex: 200
                  },
                  confirmTxt: 'تصدير الفاتوره',
                  img: {
                    path: require('@/assets/img/svgs/exported.svg'),
                    width: 55,
                    height: 55
                  },
                  cancelText: 'تصفح الفاتوره'
                })
                .then((exportConfirm) => {
                  this.closeDialog()
                  if (exportConfirm) {
                    this.fromTaxInvoicesPage
                      ? this.$emit('exportPdfTax', { tax: this.addTaxInfo })
                      : this.$router.push({
                          path: '/dashboard/taxInvoices',
                          query: { item: this.addTaxInfo }
                        })
                  } else {
                    this.fromTaxInvoicesPage
                      ? this.$emit('showDetails', {
                          visible: true,
                          taxInvoice: this.addTaxInfo
                        })
                      : this.$router.push({
                          path: '/dashboard/taxInvoices',
                          query: {
                            visible: true,
                            item: this.addTaxInfo
                          }
                        })
                  }
                })
          // .catch(() => {
          // })
          this.$emit('close-tax-dialog')
          this.$emit('refreshTaxes')
        })
        .catch(() => {
          this.addNotification({
            text: 'لم نتكمن من اضافه الفاتوره في الوقت الحالي',
            color: 'error'
          })
        })
        .finally(() => {
          this.$refs.infoStep.addUpdateBtnLoading = false
        })
    },
    updateTax() {
      const id = this.addTaxInfo._id
      delete this.infoToSend.environment
      this.$refs.infoStep.addUpdateBtnLoading = true
      taxBillsService
        .updateTaxBill(this.infoToSend, id)
        .then((res) => {
          this.addTaxInfo = res.data.taxBill
          this.$emit('refreshTaxes')
          // eslint-disable-next-line no-unused-expressions
          res.data.taxBill.needsApproval
          if (res.data.taxBill.needsApproval) {
            this.showConfirmationPopupMixin()
            this.$emit('close-tax-dialog')
          } else {
            this.$root.$confirm
              .open({
                title: `تم تحديث الفاتوره`,
                message:
                  'تم تحديث الفاتوره تحت مسئوليتك الخاصه بالبيانات المرفقه',
                options: {
                  color: 'primary',
                  width: 400,
                  zIndex: 200
                },
                confirmTxt: 'تصدير الفاتوره',
                img: {
                  path: require('@/assets/img/svgs/exported.svg'),
                  width: 55,
                  height: 55
                },
                cancelText: 'تصفح الفاتوره'
              })
              .then((exportConfirm) => {
                this.closeDialog()
                if (exportConfirm) {
                  this.$emit('exportPdfTax', { tax: this.addTaxInfo })
                } else {
                  this.$emit('showDetails', {
                    visible: true,
                    taxInvoice: this.addTaxInfo
                  })
                }
              })
              .catch(() => {
                this.$emit('close-tax-dialog')
              })
          }
        })
        .catch(() => {
          this.addNotification({
            text: 'لم نتكمن من نحديث الفاتوره في الوقت الحالي',
            color: 'error'
          })
        })
        .finally(() => {
          this.$refs.infoStep.addUpdateBtnLoading = false
        })
    },
    nextTab() {
      this.tab++
      this.setDefaultData()
      this.fireCheckingClient()
    },
    previousTab() {
      this.tab--
    },
    setDefaultData() {
      setTimeout(() => {
        switch (this.activeComponentRef) {
          case 'ownerStep':
          case 'renterStep':
            this.$refs[`${this.activeComponentRef}`].setDefaultData()
        }
      })
    },
    fireCheckingClient() {
      setTimeout(() => {
        switch (this.activeComponentRef) {
          case 'ownerStep':
          case 'renterStep':
            // we need to call checkClientDataIfExists methods every time we change te realestate
            this.$refs[`${this.activeComponentRef}`].checkClientDataIfExists()
          default:
        }
      })
    }
  },
  watch: {
    taxToBeEdit: {
      immediate: true,
      handler() {
        if (this.taxToBeEditValid) {
          this.addTaxInfo = this.taxToBeEdit
        }
      }
    }
  }
}
</script>
