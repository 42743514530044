import { render, staticRenderFns } from "./ServiceField.vue?vue&type=template&id=373b28bc&scoped=true"
import script from "./ServiceField.vue?vue&type=script&lang=js"
export * from "./ServiceField.vue?vue&type=script&lang=js"
import style0 from "./ServiceField.vue?vue&type=style&index=0&id=373b28bc&prod&lang=scss&scoped=true"
import style1 from "./ServiceField.vue?vue&type=style&index=1&id=373b28bc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "373b28bc",
  null
  
)

export default component.exports