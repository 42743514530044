var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mt-0"},[_c('div',{staticClass:"summary-cards__wrapper"},[_c('v-card',{staticStyle:{"height":"265px","position":"relative"}},[_c('div',{staticClass:"d-flex justify-end pl-3 pt-3",staticStyle:{"gap":"10px"}},_vm._l((_vm.tabsCount),function(i){return _c('span',{key:i,style:(`
            background-color: ${_vm.activeTab === i ? '#662d91' : '#e0e0e0'};
            height: 3px;
            width: 50px;
            cursor: pointer;
          `),on:{"click":function($event){_vm.activeTab = i}}},[_vm._v("\n           \n        ")])}),0),_c('v-card-title',{staticClass:"mb-3"},[_c('v-row',{staticClass:"mb-3"},[_c('div',{staticClass:"d-flex pt-3 align-baseline w-100",class:{ 'justify-space-between': !_vm.progressData.leftTitle }},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"titleDot d-flex justify-center align-center ml-2",style:(`background-color:${_vm.progressData.tabs[0]?.rows[0]?.color};`)},[_c('v-img',{attrs:{"src":_vm.getIcons(_vm.progressData),"max-width":"15","max-height":"15"}})],1),_c('h5',{staticClass:"ma-0 font-weight-bold font-16 text-truncate"},[_vm._v("\n                "+_vm._s(_vm.progressData?.title)+"\n              ")])]),_c('div',{staticClass:"d-flex align-center"},[_c('h4',{staticClass:"font-weight-bold font-14 text-truncate d-flex align-center",class:{ ' mt-0': !_vm.xs, 'mr-0': _vm.xs }},[(_vm.progressData.leftTitle)?_c('p',{staticClass:"mr-2 font-12"},[_vm._v("\n                  "+_vm._s(_vm.progressData.leftTitle)+"\n                ")]):_vm._e(),_c('AnimatedCounter',{staticClass:"mb-3 mr-8",attrs:{"targetNumber":_vm.progressData.tabs[_vm.activeTab - 1]?.total.replace(',', '')}})],1)])])]),_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{staticClass:"px-0"},[_c('div',{staticClass:"d-flex justify-center flex-column w-100"},_vm._l((_vm.progressData.tabs[_vm.activeTab - 1]
                  ?.rows),function(indicator,i){return _c('div',{key:i,staticClass:"justify-space-between align-baseline mb-3 d-flex w-100"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"subTitleDot ml-2",style:(`background-color:${indicator?.color};`)}),[_c('h5',{staticClass:"ma-0 h4 font-weight-regular text-truncate"},[_vm._v("\n                      "+_vm._s(indicator?.field?.name)+"\n                    ")]),(_vm.dountTap === _vm.activeTab)?_c('h6',{staticClass:"mr-4",style:(`color:${indicator?.color}; font-size: 14px; height: 8px;`)},[_vm._v("\n                      "+_vm._s(indicator?.percentage)+"\n                    ")]):_vm._e()]],2),_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[(indicator?.field?.value?.toString().length < 18)?_c('h5',{staticClass:"ma-0 font-14 font-weight-bold d-flex align-center"},[(_vm.dountTap === _vm.activeTab)?_c('span',{staticClass:"ml-2",style:(`color:${indicator?.color};`)},[_c('AnimatedCounter',{attrs:{"targetNumber":indicator?.percentage}})],1):_vm._e(),_c('span',{style:(`color: ${indicator?.color}`)},[_vm._v("\n                      "+_vm._s(indicator?.field?.value)+"\n                    ")])]):_vm._e(),(_vm.dountTap === _vm.activeTab)?_c('v-col',{staticClass:"pa-0 d-flex ma-0",staticStyle:{"position":"absolute","top":"94px","left":"10px"},attrs:{"lg":"4","md":"4","sm":"4","cols":_vm.xs ? '' : '4'}},[_c('DonutChart',{attrs:{"data":_vm.chartData,"colors":_vm.chartColors,"width":_vm.chartWidth,"cutoutPercentage":90}})],1):_vm._e()],1)],1)}),0)])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }