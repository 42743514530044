<template>
  <v-dialog width="550px" persistent v-model="dialogState_">
    <v-card class="add-new-property envs_model pa-5">
      <v-card-title class="d-flex pa-0">
        <p class="font-18 text--101010 ma-0 excluded">
          مرحباً بعودتك, {{ firstName }}
        </p>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-list class="pa-0">
          <p class="font-14 px-0 line-height-18 text--757575 mt-1 mb-3">
            يوجد لديك أكثر من حساب في منصة هكتار بلس, برجاء اختيار حساب واحد
            للدخول
          </p>
          <v-divider />
          <v-radio-group v-model="selectedEnv" class="ma-0 pa-0 mt-5">
            <div
              v-for="(value, envName) in envRoles"
              :key="value?._id"
              class="mb-4"
            >
              <p
                class="text--101010 font-18 text-right ma-0 font-weight-medium mb-3"
              >
                {{ envName }}
              </p>
              <v-row no-gutters>
                <v-col v-for="(role, i) in value" :key="i" class="ml-5 pa-0">
                  <v-radio :value="role" class="ma-0">
                    <template v-slot:label>
                      <div class="d-flex">
                        <v-list-item-content class="py-0">
                          <v-list-item-title>
                            <p class="font-16 ma-0 text--101010">
                              {{ getUserRole(role) }}
                            </p>
                          </v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </template>
                  </v-radio>
                </v-col>
              </v-row>
            </div>
          </v-radio-group>
        </v-list>
      </v-card-text>

      <v-card-actions class="mt-3 pa-0 d-flex justify-center align-center">
        <v-btn
          data-test="proceedWithSelectedEnv"
          rounded
          :disabled="isLoginInDisable"
          :loading="btnLoading"
          class="py-6 w-100 ma-0"
          color="primary"
          @click="proceedWithSelectedEnv"
        >
          <span>تسجيل دخول</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import '@/styles/material-dashboard/_add-new-property.scss'

export default {
  props: ['dialogState', 'envsData', 'btnLoading'],
  data() {
    return {
      selectedEnv: {},
      envRoles: {}
    }
  },
  watch: {
    dialogState(val) {
      if (val) {
        this.getEnvsRoles(this.envsData)
      }
    }
  },
  computed: {
    ...mapState('accountState', ['user']),
    isLoginInDisable() {
      return this.selectedEnv && !!!Object.keys(this.selectedEnv).length
    },
    firstName() {
      return this.user?.name ? this.user.name.replace(/ .*/, '') : ''
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  methods: {
    getEnvsRoles(envs) {
      envs.map((env) => {
        this.envRoles[this.getEnvName(env)] = []
        const { employeeRole = '', customerRegistered = false } = env
        if (customerRegistered && employeeRole.length) {
          const ownerEnv = { ...env }
          delete ownerEnv.customerRegistered
          this.envRoles[env.name] = [ownerEnv]
        }
        this.envRoles[env.name].push(env)
      })
    },
    getEnvName(item) {
      return this.isCompanyOrOffice(item)
        ? item?.owner?.company ?? item?.name
        : item?.name
    },
    isCompanyOrOffice(item) {
      return !!(
        item?.owner?.userType === 'company' ||
        item?.owner?.userType === 'office'
      )
    },
    closeDialog() {
      this.$emit('closed-add-dialog')
    },
    proceedWithSelectedEnv() {
      this.$emit('closed-add-dialog', this.selectedEnv)
    },
    getUserRole(item) {
      if (item?.customerRegistered) {
        return 'بوابة العملاء'
      } else if (item?.employeeRole === 'owner') {
        return 'بوابة الأعمال (مالك الحساب)'
      } else if (item?.employeeRole === 'member') {
        return 'بوابة الأعمال (عضو)'
      } else if (item?.employeeRole === 'admin') {
        return 'بوابة الأعمال (مشرف)'
      }
      return '(عميل)'
    }
  }
}
</script>

<style lang="scss">
.envs_model.v-card {
  .v-card__actions,
  .v-card__title {
    border: none !important;
  }
  .v-messages {
    display: none;
  }
}
</style>
