<template>
  <v-sheet id="env-pic">
    <img width="160" src="@/assets/img/logo.svg" class="mb-13" loading="lazy" />
    <h3 class="ma-0 font-weight-medium mb-8">إنشاء بيئة العمل</h3>
    <h6 class="font-weight-medium h6-gery mb-6">
      تمييز إسم بيئة العمل بالأحرف الأولى وتعيين ألوان لها يساعدك في إعطاء ميزة
      إضافة لها وذلك بإختصار الإسم وتمييزها بلون خاص يسهل الوصول له والتفريق
      بينها وبين أسماء اخرى في حال تواجدك في أكثر من بيئة عمل واحدة
    </h6>
    <v-flex d-flex>
      <div
        class="profile-color ml-9"
        :style="`color:${theme}; border: 2px solid ${theme};`"
      >
        {{ $subString(envName.name) }}
      </div>
      <div class="d-flex align-center">
        <div
          class="round ml-5 cursor"
          v-for="(color, index) in colorAvatar"
          :key="index"
        >
          <input
            class="cb"
            @click="setColor(color, $event)"
            :style="setCheckboxStyle(color)"
            type="checkbox"
            :id="`checkbox-${index}`"
          />
          <label
            :style="setCheckboxStyle(color)"
            :for="`checkbox-${index}`"
          ></label>
        </div>
      </div>
    </v-flex>
  </v-sheet>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'envPic',
  props: ['envName'],
  data() {
    return {
      theme: '',
      colorAvatar: [
        '#662D91',
        '#12CAD6',
        '#28DF99',
        '#4FB3F9',
        '#1C5FAE',
        '#FC4A4A',
        '#F49300',
        '#ED6237',
        '#E4897B',
        '#202020',
        '#737373',
        '#E2E2E2'
      ]
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    validate() {
      const valid = this.theme !== ''
      if (!valid) {
        this.addNotification({
          text: 'من فضلك تأكد من اختيار لون الخلفية',
          color: 'error'
        })
      } else {
        this.$emit('on-validate', { theme: this.theme }, !!valid)
      }
      return !!valid
    },
    setColor(color, event) {
      const cbs = document.getElementsByClassName('cb')
      for (let i = 0; i < cbs.length; i++) {
        cbs[i].checked = false
      }
      event.target.checked = true
      this.theme = color
    },
    setCheckboxStyle(color) {
      return `background-color: ${color};  border-color: ${color};`
    }
  }
}
</script>

<style scoped>
.round {
  position: relative;
}
.cb {
  margin-left: 12px;
  margin-right: 12px;
}
.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type='checkbox'] {
  visibility: hidden;
}

.round input[type='checkbox'] + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type='checkbox']:checked + label:after {
  opacity: 1;
}
</style>
