<template>
  <v-dialog
    class="backdrop"
    :max-width="tab === 1 ? '400px' : '500px'"
    overlay-opacity="0.5"
    persistent
    v-model="dialogState_"
  >
    <v-card class="add-new-property px-4">
      <!-- TITLE -->
      <v-card-title class="px-0 py-0 mb-3 border-bottom">
        <div class="d-flex align-center justify-space-between">
          <div
            data-test="back"
            @click="tab--"
            class="ma-0 pa-0 ml-1 pointerCursor"
            v-if="tab > 0"
          >
            <v-icon left>mdi-arrow-right</v-icon>
          </div>
          <div>
            <h4 class="font-weight-medium ma-0" v-text="setTitle" />
          </div>
          <v-btn
            data-test="closeModel text-gray"
            @click.prevent="closeModel"
            class="ma-0 mt-2 w-auto"
            color="grey"
            fab
            text
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <!--  -->
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <!-- ENTER COPOUN AND APPLY -->
          <v-row no-gutters class="align-end justify-between pl-1">
            <v-col cols="9" sm="9" class="copoun">
              <h5 class="ma-0 mb-2 font-weight-medium font-12">كوبون الخصم</h5>
              <div class="copoun__input">
                <v-text-field
                  class="border-radius pl-4"
                  dense
                  filled
                  hide-details
                  placeholder="ادخل كود الخصم"
                  required
                  type="text"
                  height="20px"
                  :value="coupon"
                  @input="couponInput"
                />
                <span @click="clearCouponInput" v-show="coupon">
                  <img height="25px" :src="removeIcon" alt="مسح" />
                </span>
              </div>
            </v-col>
            <v-col cols="3" sm="3">
              <v-btn
                class="mt-0 border-radius btn"
                color="primary"
                block
                outlined
                :disabled="!coupon"
                :loading="isApplyCouponLoading"
                @click="applyCoupon"
              >
                <span class="font-weight-medium font-12">تطبيق</span>
              </v-btn>
            </v-col>
          </v-row>

          <!-- If coupon not valid -->
          <p v-if="couponNotValid" class="red--text font-16">
            هذا الكوبون غير صحيح
          </p>

          <!-- ------------------ -->
          <!-- CALCULATION BUNDLE -->
          <!-- ------------------ -->
          <CalculationBundle :data="dataCalculationBundle" />

          <h6 class="font-12 text-gray ma-0 my-3">
            يمكنك الدفع للحسابات الاتيه وارفاق اشعار الدفع
          </h6>
          <v-card-text class="pa-0">
            <v-card
              class="d-flex align-center pa-3 mb-2 cursor"
              v-for="bank in banks"
              :key="bank._id"
            >
              <v-checkbox
                data-test="selectedBankData"
                @change="setSelectedBankData($event, bank)"
                v-model="bank.checked"
              />
              <div class="ml-4 radius-12">
                <v-img
                  :height="60"
                  :width="60"
                  loading="lazy"
                  :src="bank.logo"
                  alt=""
                />
              </div>

              <div>
                <h5 class="ma-0 mb-2">{{ bank.nameAr }}</h5>
                <h6
                  class="ma-0 font-12 font-weight-bold text-gray mobile__wb-break-word"
                >
                  رقم الحساب {{ bank.accountNo }}
                </h6>
              </div>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="primary"
              v-if="
                currentSelectedBank && Object.keys(currentSelectedBank).length
              "
              @click="tab++"
            >
              التالي
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item>
          <v-card-text class="pa-0 pt-4">
            <v-col class="pa-0" cols="12">
              <h5 class="ma-0 mb-2">رقم التحويل</h5>
              <v-text-field
                data-test="trxId"
                dense
                filled
                hide-details
                placeholder=" رقم التحويل"
                required
                type="text"
                v-model="attachments.trxId"
              ></v-text-field>
            </v-col>
            <h6 class="font-12 font-weight-bold">ارفاق اشعار التحويل</h6>
            <div class="position-relative add-pic" v-if="attachmentImage.image">
              <img
                :src="attachmentImage.image"
                class="add-pic"
                loading="lazy"
              />
              <div v-if="!attachmentImage.key" class="lds-ring-container">
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <v-icon
                @click="deleteAttachment"
                class="pic-icon position-absolute slide-y-transition"
                color="white"
              >
                mdi-close
              </v-icon>
            </div>

            <div
              class="pic-wrapper d-flex align-center justify-center w-100 py-4 my-3"
              @drop.prevent="addFiles($event)"
              @dragleave.prevent="dragLeave('pic')"
              @dragover.prevent="dragOver('pic')"
              @dragenter.prevent
              ref="pic"
              v-else
            >
              <div>
                <input
                  @change="addFiles($event)"
                  accept="image/*"
                  ref="inputUpload"
                  type="file"
                  v-show="false"
                />
              </div>
              <div class="d-flex flex-column">
                <img
                  height="20px"
                  alt="رفع الصورة"
                  :src="uploadIcon"
                  loading="lazy"
                  class="mt-3"
                />
                <h5 class="text-gray font-12 font-weight-medium">
                  اسحب الصوره هنا لرفعها أو
                  <span
                    @click="$refs.inputUpload.click()"
                    class="text-primary font-weight-bold text-decoration-underline cursor"
                  >
                    اضغط هنا</span
                  >
                </h5>
              </div>
            </div>
          </v-card-text>

          <!-- SUBMIT -->
          <div class="pa-1 w-100 d-flex align-center">
            <v-btn
              data-test="submit"
              :disabled="!tabIsValid"
              @click="submit"
              block
              color="primary"
            >
              <span class="mb-1 font-14 font-weight-medium"
                >إرفاق إشعار الدفع</span
              >
            </v-btn>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { subscriptionService, realEstateService } from '@/services'
import { mapMutations, mapState } from 'vuex'
import CalculationBundle from '@/components/bills/CalculationBundle'
import drag from '@/mixins/drag.js'

export default {
  name: 'add-bundle',
  mixins: [drag],
  components: {
    CalculationBundle
  },
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    bundle: {
      type: Object,
      required: true
    },
    renewedSubscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tab: 0,
      attachments: {},
      attachmentImage: {
        image: '',
        key: ''
      },
      banks: [],
      coupon: '',
      couponObj: {},
      couponNotValid: false,
      currentSelectedBank: {},
      uploadIcon: require('@/assets/img/svgs/upload2.svg'),
      removeIcon: require('@/assets/img/svgs/remove.svg'),
      isApplyCouponLoading: false
    }
  },
  computed: {
    ...mapState('accountState', ['subscription', 'currentEnv']),
    dataToSend() {
      const data = {
        subscription: this.subscription?._id,
        attachment: this.attachmentImage.image,
        trxId: this.attachments.trxId,
        bankId: this.currentSelectedBank._id,
        environment: this.currentEnv._id,
        couponCode: this.couponObj?.code
      }
      return data
    },
    tabIsValid() {
      return !!(this.attachments.trxId && this.attachmentImage.key)
    },
    amount() {
      return this.couponObj.amount ? this.couponObj.amount : this.bundlePrice
    },
    addedTax() {
      return Math.floor(
        this.renewedSubscription?.tax?.amount *
          (this.renewedSubscription?.tax?.percentage / 100)
      )
    },
    total() {
      return this.amount + this.addedTax
    },
    dataCalculationBundle() {
      return {
        titleBundlePrice: this.titleBundlePrice,
        couponObj: this.couponObj,
        addedTax: this.addedTax,
        bundlePrice: this.bundlePrice,
        total: this.renewedSubscription?.total,
        percentage: this.renewedSubscription?.tax?.percentage,
        amount: this.renewedSubscription?.tax?.amount
      }
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    },
    bundleSubscription() {
      if (this.subscription?.period === 12) {
        return `إشتراك سنوي في ${this.bundle.nameAr}`
      }
      return `إشتراك شهري في ${this.bundle.nameAr}`
    },
    titleBundlePrice() {
      if (this.subscription?.period === 12) {
        return `سعر الإشتراك السنوي للـ ${this.bundle.nameAr}`
      }
      return `سعر الإشتراك الشهري للـ ${this.bundle.nameAr}`
    },
    setTitle() {
      return this.tab === 1 ? 'ارفاق إشعار الدفع' : this.bundleSubscription
    },
    bundlePrice() {
      return this.renewedSubscription?.bundlePrice
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setSelectedBankData(checkedStatus, selectedBank) {
      this.banks.map((bank) => {
        if (bank._id === selectedBank._id) {
          bank.checked = true
        } else {
          bank.checked = false
        }
      })
      if (checkedStatus) {
        this.tab++
        this.currentSelectedBank = selectedBank
      } else {
        this.currentSelectedBank = {}
      }
    },
    async getAvailableBanks() {
      try {
        const { data } = await subscriptionService.getBanks()
        this.banks = data.banks
      } catch {}
    },
    deleteAttachment() {
      const attachmentKey = {
        key: this.attachmentImage.key
      }
      realEstateService
        .deleteBlueprint(attachmentKey)
        .then(() => {
          this.$set(this.attachmentImage, 'image', '')
          this.$set(this.attachmentImage, 'key', '')
          this.addNotification({
            text: 'تم حذف اشعار التحويل بنجاح',
            color: 'success'
          })
        })
        .catch(() => {
          this.addNotification({
            text: 'خطأ في حذف اشعار التحويل، يرجى المحاوله مجددا',
            color: 'error'
          })
        })
    },
    async applyCoupon() {
      try {
        this.isApplyCouponLoading = true
        const reqBody = {
          environment: this.currentEnv._id,
          couponCode: this.coupon,
          subscription: this.subscription._id
        }
        const { data } = await subscriptionService.getSubscriptionPayment(
          reqBody
        )
        this.$emit('applyCopoun', data)
        this.couponObj = data
        this.couponObj.code = this.coupon
        this.coupon = ''
        this.addNotification({
          text: 'تم تطبيق كود الخصم بنجاح',
          color: 'success'
        })
      } catch {
        this.couponNotValid = true
        this.addNotification({
          text: 'كوبون الخصم المدخل غير صحيح, يرجي التأكد من الكوبون وإعاده المحاولة',
          color: 'error'
        })
      } finally {
        this.isApplyCouponLoading = false
      }
    },
    async submit() {
      try {
        await subscriptionService.addPayment(this.dataToSend)
        this.closeModel()
        this.$emit('routeToBills')
      } catch {
        this.addNotification({
          text: 'خطأ في ارفاق اشعار الدفع، يرجى المحاول مجددا',
          color: 'error'
        })
      }
    },
    couponInput(val) {
      this.coupon = val
      if (this.couponNotValid) {
        this.couponNotValid = false
      }
    },
    closeModel() {
      this.resetAddBundleModel()
      this.couponNotValid = false
      this.coupon = ''
      this.couponObj = {}
    },
    clearCouponInput() {
      this.coupon = ''
      this.couponNotValid = false
    },
    resetAddBundleModel() {
      this.$emit('close-add-dialog')
      this.tab = 0
      this.attachments = {}
      this.currentSelectedBank = {}
      this.banks.map((bank) => {
        bank.checked = false
      })
      this.$set(this.attachmentImage, 'image', '')
      this.$set(this.attachmentImage, 'key', '')
    },
    addFiles(e) {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0]
      const reader = new FileReader()
      reader.onload = () => {
        this.attachmentImage.image = reader.result
        this.onUpload(file)
      }
      reader.readAsDataURL(file)
    },
    async onUpload(file) {
      try {
        const fd = new FormData()
        fd.append('image', file)
        fd.append('isAttachment', 1)
        fd.append('environmentId', this.currentEnv._id)

        const { data } = await subscriptionService.AddpaymentAttachment(fd)

        this.$set(this.attachmentImage, 'image', data.imageUrl.Location)
        this.$set(this.attachmentImage, 'key', data.imageUrl.key)
        this.addNotification({
          text: 'تم إضافه اشعار التحويل بنجاح',
          color: 'success'
        })
      } catch {
        this.$set(this.attachmentImage, 'image', '')
        this.addNotification({
          text: 'خطأ في اضافه اشعار التحويل، يرجى المحاوله مجددا',
          color: 'error'
        })
      }
    }
  },
  created() {
    this.getAvailableBanks()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/lds-ring.scss';

.v-card {
  box-shadow: none !important;
}
.border-bottom {
  border-bottom: 1px solid #efebf2;
}
.btn {
  box-shadow: 0px 4px 20px rgba(102, 45, 145, 0.1);
  margin-bottom: 1px;
}
.radius-12 {
  border-radius: 12px;
  overflow: hidden;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
  border-radius: 8px;
}
.theme--light .v-sheet.v-card:not(.v-sheet--outlined) {
  border: 1px solid #efebf2;
}
.border-radius {
  border-radius: 10px;
}
.w-auto {
  width: auto;
}
.copoun {
  &__input {
    position: relative;
  }
  & span {
    position: absolute;
    top: 50%;
    inset-inline-end: 25px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
.strikediag {
  display: inline-block;
  position: relative;
}
.strikediag::before {
  content: '';
  position: absolute;
  left: -0.1em;
  right: -0.1em;
  top: 0.38em;
  bottom: 0.38em;
  background: linear-gradient(
    to left top,
    transparent 45.5%,
    currentColor 47.5%,
    currentColor 52.5%,
    transparent 54.5%
  );
  pointer-events: none;
}
</style>
