<template>
  <div>
    <div
      v-if="charts.length"
      class="mt-10 d-flex align-center justify-center flex-column rounded-15"
      height="60vh"
    >
      <v-row>
        <v-col
          class="ma-0 pa-2"
          cols="12"
          lg="6"
          md="6"
          sm="12"
          v-for="(cardData, i) in charts"
          :key="i"
        >
          <progress-circular-card
            :progressData="cardData"
            :isAddModel="isAddModel"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <NoData v-bind="noDataConfig" />
    </div>
  </div>
</template>

<script>
import progressCircularCard from '@/components/reports/progressCircularCard.vue'
import NoData from '@/components/helper/noData.vue'

export default {
  name: 'InfoGraphicSummary',
  components: {
    progressCircularCard,
    NoData
  },
  props: {
    isAddModel: {
      default: false
    },
    reports: {
      default() {
        return []
      },
      required: true
    }
  },
  computed: {
    charts() {
      const colors = ['#12CAD6', '#662D91', '#FC4A4A', '#3DBA7E']
      return this.reports.charts.map((chart, idx) => {
        return { ...chart, color: colors[idx] }
      })
    },
    noDataConfig() {
      return {
        noDataText: 'لا توجد بيانات متاحة لهذا التقرير',
        noDataSubTitle: '',
        isbgPrimary: false
      }
    }
  }
}
</script>
