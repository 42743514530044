<template>
  <v-row class="mt-6">
    <v-col
      cols="4"
      md="2"
      v-if="item?.realEstate?.propertyName || item?.realEstate?.name"
    >
      <h6 class="font-12 text-gray">تفاصيل المطالبة</h6>
      <div class="font-12 my-1">
        <h5 class="font-14 font-weight-medium pa-0 ma-0 text-truncate">
          {{ item.realEstate.propertyName ?? item.realEstate.name }}
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray d-flex">
        تاريخ الإستحقاق
        <v-icon
          v-if="isNonEditable && item.paymentStatus !== 'مدفوعة'"
          class="mr-1 font-14 pointerCursor"
          @click="updateDate"
          >mdi-pencil-outline</v-icon
        >
      </h6>
      <div class="text-center font-12 my-1">
        <h5
          class="font-14 font-weight-medium pa-0 ma-0 text-truncate text-right"
        >
          {{ formatDate(item.date) }}
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">حالة التحصيل</h6>
      <v-menu
        content-class="dataTableMenus"
        elevation-0
        offset-y
        v-if="
          item.assignmentStatus &&
          !nonEditableDuesStatus.includes(item.paymentStatus)
        "
        v-model="statusModel"
      >
        <template v-slot:activator="{ on: menu }">
          <div v-on="{ ...menu }">
            <div
              class="tabel-tag text-center ml-1 font-12 d-flex justify-center pointerCursor align-center"
              :class="generateStyleClasses(item.assignmentStatus)"
            >
              <h5
                class="font-12 font-weight-medium pa-0 ma-0 ml-2 text-truncate my-auto"
              >
                {{ item.assignmentStatus }}
              </h5>
              <div
                class="font-14 rotate-180 my-auto"
                :class="{
                  'rotate-0': statusModel
                }"
              >
                <v-icon
                  :color="getChevronColor(item.assignmentStatus)"
                  size="15"
                >
                  mdi-chevron-down
                </v-icon>
              </div>
            </div>
          </div>
        </template>
        <v-list id="assignmentStatus" class="ma-0 pa-0">
          <v-list-item
            class="px-3 pointerCursor"
            v-for="(link, i) in assignmentStatus"
            :key="i"
            @click="setInstallmentStatus(link.nameAr, item)"
          >
            <v-list-item-title class="font-14 font-weight-medium ma-0">
              {{ link.nameAr }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div
        v-else
        class="tabel-tag text-center ml-1 font-12 d-flex justify-center align-center"
        :class="generateStyleClasses(item.assignmentStatus)"
      >
        <h5
          class="font-12 font-weight-medium pa-0 ma-0 ml-2 text-truncate pr-2"
        >
          {{ item.assignmentStatus }}
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">اسم المحصل</h6>
      <div
        class="tabel-tag text-center ml-1 font-12 my-1 py-2 d-flex justify-center align-center"
        :class="generateStyleClasses(item?.user?.name)"
      >
        <h5 class="font-12 font-weight-medium pa-0 ma-0 text-truncate">
          {{ item?.user?.name }}
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">حالة المطالبة</h6>
      <div
        class="tabel-tag text-center ml-1 font-12 my-1 py-2 d-flex justify-center align-center"
        :class="generateStyleClasses(item.installmentStatus)"
      >
        <h5 class="font-12 font-weight-medium pa-0 ma-0 text-truncate">
          {{ item.installmentStatus }}
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">حالة الدفع</h6>
      <div
        class="tabel-tag text-center ml-1 font-12 my-1 py-2 d-flex justify-center align-center"
        :class="generateStyleClasses(item.paymentStatus)"
      >
        <h5 class="font-12 font-weight-medium pa-0 ma-0 text-truncate">
          {{ item.paymentStatus }}
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray d-flex">
        {{ isCombined ? 'قيمة الإيجار' : 'القيمة' }}
        <v-icon
          v-if="isNonEditable && item.paymentStatus !== 'مدفوعة'"
          class="mr-1 font-14 pointerCursor"
          @click="openDiscountDeductModal()"
          >mdi-pencil-outline</v-icon
        >
      </h6>
      <div class="text-center font-12 my-1">
        <h5
          class="font-14 font-weight-medium pa-0 ma-0 text-truncate text-right"
        >
          {{
            isCombined
              ? item?.details?.rent.toLocaleString('en-US', {
                  maximumFractionDigits: 2
                })
              : item?.payment?.toLocaleString('en-US', {
                  maximumFractionDigits: 2
                })
          }}
          ريال
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray d-flex">
        نسبة الضريبة
        <v-icon
          v-if="isNonEditable && item.paymentStatus !== 'مدفوعة'"
          class="mr-1 font-14 pointerCursor"
          @click="openEditTaxModal()"
          >mdi-pencil-outline</v-icon
        >
      </h6>
      <div class="text-center font-12 my-1">
        <h5
          class="font-14 font-weight-medium pa-0 ma-0 text-truncate text-right"
        >
          {{ item.tax }} %
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">قيمة الضريبة</h6>
      <div class="text-center font-12 my-1">
        <h5
          class="font-14 font-weight-medium pa-0 ma-0 text-truncate text-right"
        >
          {{
            isCombined
              ? item.details.rentTaxAmount.toLocaleString('en-US', {
                  maximumFractionDigits: 2
                })
              : item.taxAmount.toLocaleString('en-US', {
                  maximumFractionDigits: 2
                })
          }}
          ريال
        </h5>
      </div>
    </v-col>
    <template v-if="isCombined">
      <v-col cols="4" md="2">
        <h6 class="font-12 text-gray d-flex">
          قيمة المبالغ الثابته
          <v-icon
            v-if="isNonEditable && item.paymentStatus !== 'مدفوعة'"
            class="mr-1 font-14 pointerCursor"
            @click="openDiscountDeductModal('utilitiesTotal')"
            >mdi-pencil-outline</v-icon
          >
        </h6>
        <div class="text-center font-12 my-1">
          <h5
            class="font-14 font-weight-medium pa-0 ma-0 text-truncate text-right"
          >
            {{
              item?.details?.utilitiesTotal
                ? item?.details.utilitiesTotal.toLocaleString('en-US', {
                    maximumFractionDigits: 2
                  })
                : 0
            }}
            ريال
          </h5>
        </div>
      </v-col>
      <v-col cols="4" md="2">
        <h6 class="font-12 text-gray d-flex">
          نسبة الضريبة
          <v-icon
            v-if="isNonEditable && item.paymentStatus !== 'مدفوعة'"
            class="mr-1 font-14 pointerCursor"
            @click="openEditTaxModal('utilitiesTax')"
            >mdi-pencil-outline</v-icon
          >
        </h6>
        <div class="text-center font-12 my-1">
          <h5
            class="font-14 font-weight-medium pa-0 ma-0 text-truncate text-right"
          >
            {{ item?.utilitiesTax ?? 0 }} %
          </h5>
        </div>
      </v-col>
      <v-col cols="4" md="2">
        <h6 class="font-12 text-gray">قيمة الضريبة</h6>
        <div class="text-center font-12 my-1">
          <h5
            class="font-14 font-weight-medium pa-0 ma-0 text-truncate text-right"
          >
            {{
              item?.details?.utilitiesTaxAmount
                ? item.details.utilitiesTaxAmount.toLocaleString('en-US', {
                    maximumFractionDigits: 2
                  })
                : 0
            }}
            ريال
          </h5>
        </div>
      </v-col>
    </template>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">الإجمالي</h6>
      <div class="text-center font-12 my-1">
        <h5
          class="font-14 font-weight-medium pa-0 ma-0 text-truncate text-right"
        >
          {{
            item.payment.toLocaleString('en-US', {
              maximumFractionDigits: 2
            })
          }}
          ريال
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">المدفوع</h6>
      <div class="text-center font-12 my-1">
        <h5
          class="font-14 font-weight-medium pa-0 ma-0 text-truncate text-right"
        >
          {{
            item.paid.toLocaleString('en-US', {
              maximumFractionDigits: 2
            })
          }}
          ريال
        </h5>
      </div>
    </v-col>
    <v-col cols="4" md="2">
      <h6 class="font-12 text-gray">المتبقي</h6>
      <div class="text-center font-12 my-1">
        <h5
          class="font-14 font-weight-medium pa-0 ma-0 text-truncate text-right"
        >
          {{
            item?.remaining?.toLocaleString('en-US', {
              maximumFractionDigits: 2
            }) ?? 0
          }}
          ريال
        </h5>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
export default {
  props: {
    item: {
      type: Object
    },
    nonEditableDuesStatus: {
      type: Array,
      default() {
        return []
      }
    },
    assignmentStatus: {
      type: Array
    }
  },
  mixins: [dateFormat],
  data: () => ({
    statusModel: false
  }),
  computed: {
    isNonEditable() {
      return !this.nonEditableDuesStatus.includes(this.item?.paymentStatus)
    },
    isCombined() {
      return !!this.item?.isCombined
    }
  },
  methods: {
    hasDuePermission(action) {
      return !!this.$hasPermission('properties', 'assignments', action)
    },
    generateStyleClasses(value) {
      let classes = ''
      switch (value) {
        case 'مبلغ التأمين':
        case 'دفعة إيجار':
          classes = 'purple--bill'
          break
        case 'غير مدفوعة':
        case 'متأخرة':
        case 'ملغيّة':
          classes = 'red--bill'
          break
        case 'مدفوعة':
        case 'مستقبلية':
        case 'تم التحصيل':
          classes = 'green--bill'
          break
        case 'معلّقة':
          classes = 'orange--bill'
          break
        default:
          classes = 'default--bill'
      }
      return classes
    },
    getChevronColor(status) {
      switch (status) {
        case 'معلّقة':
          return '#f49300'
        default:
          return '#fff'
      }
    },
    updateDate() {
      if (!this.hasDuePermission('update')) {
        this.addNotification({
          text: 'ليس لديك صلاحيه لتعديل المطالبه',
          color: 'error'
        })
        return
      }
      this.$emit('changeEditDateModal', true)
    },
    openDiscountDeductModal(type = 'rent') {
      this.$emit('changeDiscountDeductModal', { type, status: true })
    },
    openEditTaxModal(val = 'tax') {
      if (!this.hasDuePermission('update')) {
        this.addNotification({
          text: 'ليس لديك صلاحيه لتعديل المطالبه',
          color: 'error'
        })
        return
      }
      this.$emit('changeEditTaxModal', true)
      this.$emit('editTypeKey', val)
    }
  }
}
</script>

<style lang="scss" scoped></style>
