<template>
  <v-container>
    <div v-if="contract?.financialClaims?.length">
      <h4 class="font-16 font-weight-medium financial-title">
        المستحقات المالية
      </h4>
      <v-data-table
        :headers="headers"
        :items="financialClaims"
        class="mt-6 hectar-table noScroll"
        id="financialDetailsTable"
        hide-default-footer
      >
        <template v-slot:[`item.type`]="{ item }">
          <div
            :class="generateStyleClasses(item.type)"
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>
              {{ item.type }}
            </h6>
          </div>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <span class="font-12 text-color">
            {{ generateFormattedAmount(item.amount) }}
          </span>
        </template>

        <template v-slot:[`item.endDate`]="{ item }">
          <span class="font-12 text-color">
            {{ generateDueDate(item) }}
          </span>
        </template>

        <template v-slot:[`item.paid`]="{ item }">
          <span class="font-12 text-color">
            {{ item.paid.toFixed(2) }} ريال
          </span>
        </template>

        <template v-slot:[`item.remaining`]="{ item }">
          <span class="font-12 text-color">
            {{ generateFormattedAmount(item.remaining) }}
          </span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div
            :class="generateStyleClasses(item.status)"
            class="d-flex justify-center align-center font-weight-medium bill--itself mx-auto"
          >
            <h6>
              {{ item.status }}
            </h6>
          </div>
        </template>

        <template v-slot:[`item.installmentStatus`]="{ item }">
          <div
            :class="generateStyleClasses(item.installmentStatus)"
            class="d-flex justify-center align-center font-weight-medium bill--itself mx-auto"
          >
            <h6>
              {{ item.installmentStatus ?? 'غير معروفه' }}
            </h6>
          </div>
        </template>

        <template v-slot:no-data>
          <span>لا توجد بيانات مالية لهذا العقد</span>
        </template>
      </v-data-table>
      <!--Pagination-->
      <div
        class="text-center mt-6 mb-5"
        v-if="contract?.financialClaims?.length > pagination.pageSize"
      >
        <Pagination
          :pagination="{ pagesCount, ...pagination }"
          @onChange="loadContractInstallment($event)"
        />
      </div>
    </div>
    <div v-else>
      <!--Defaulters are not displayed while settings is not active-->
      <NoData v-bind="noDataConfig" />
    </div>
  </v-container>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import Pagination from '@/components/helper/pagination.vue'
import NoData from '@/components/helper/noData.vue'

export default {
  name: 'ContractClaimsDetails',
  components: { Pagination, NoData },
  mixins: [dateFormat],
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noData: {
        title: 'لا توجد بيانات ماليه لهذا العقد'
      },
      headers: [
        {
          text: 'نوع المطالبة',
          sortable: false,
          value: 'type',
          align: 'center'
        },
        {
          text: 'القيمة',
          sortable: false,
          value: 'amount',
          align: 'center'
        },
        {
          text: 'تاريخ الإستحقاق',
          sortable: false,
          value: 'endDate',
          align: 'center'
        },
        {
          text: 'المدفوع',
          sortable: false,
          value: 'paid',
          align: 'center'
        },
        {
          text: 'المتبقي',
          sortable: false,
          value: 'remaining',
          align: 'center'
        },
        {
          text: 'حالة الصرف',
          sortable: false,
          value: 'installmentStatus',
          align: 'center'
        },
        {
          text: 'حالة الدفع',
          sortable: false,
          value: 'status',
          align: 'center'
        }
      ],
      financialClaims: [],
      pagination: {
        pageNumber: 1,
        pageSize: 5,
        count: 0
      }
    }
  },
  computed: {
    pagesCount() {
      return Math.ceil(
        this.contract?.financialClaims?.length / this.pagination.pageSize
      )
    },
    noDataConfig() {
      return {
        noDataText: 'لا توجد بيانات مالية لهذا العقد',
        noDataSubTitle: '',
        isbgPrimary: false
      }
    }
  },
  methods: {
    loadContractInstallment(pageNumber) {
      this.pagination.pageNumber = pageNumber
      const { pageSize } = this.pagination
      this.financialClaims = this.contract?.financialClaims?.slice(
        pageNumber * pageSize - pageSize,
        pageNumber * pageSize
      )
    },
    generateStyleClasses(value) {
      let classes = ''
      switch (value) {
        case 'مبلغ التأمين':
        case 'دفعة إيجار':
          classes = 'purple--bill'
          break
        case 'غير مدفوعة':
        case 'متأخر':
          classes = 'red--bill'
          break
        case 'مدفوعة':
        case 'مستقبلي':
          classes = 'green--bill'
          break
        case 'معلّقة':
          classes = 'orange--bill'
          break
        default:
          classes = 'default--bill'
      }
      return classes
    },
    generateDueDate({ endDate }) {
      return endDate ? `${this.formatDate(endDate)}` : 'غير معروف'
    },
    generateFormattedAmount(amount) {
      return amount
        ? `${amount.toLocaleString('en-US', { maximumFractionDigits: 2 })} ريال`
        : 0
    }
  },
  created() {
    this.loadContractInstallment(this.pagination.pageNumber)
  }
}
</script>

<style lang="scss" scoped>
.financial-title {
  color: #101010;
}
#financialDetailsTable {
  ::v-deep {
    table {
      background-color: #ffffff !important;
      td {
        font-size: 12px !important;
      }
    }
    .v-data-table-header {
      background-color: #ffffff !important;
      th span {
        color: #757575 !important;
      }
    }
    tbody tr {
      height: 40px;
      border-radius: 10px;
    }
  }
  .text-color {
    color: #101010;
  }
}
</style>
