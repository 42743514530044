<template>
  <v-dialog max-width="680px" persistent :value="dialogState">
    <v-card>
      <!-----------1# Header----------->
      <v-card-title
        class="py-0 px-3 mb-0 custom__shadow d-flex justify-space-between"
      >
        <!----------Title----------->
        <h4 class="my-2 text--101010">إرسال إشعار</h4>

        <!----------Close----------->
        <v-btn @click="closeModal" class="ma-0 mt-2" color="grey" fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-----------2# Content----------->
      <v-container class="mb-0 px-5">
        <v-form v-model="isValid">
          <v-row>
            <v-col cols="12">
              <h6 class="ma-0 mb-1 text--101010">إختر نوع الإشعار</h6>
              <v-select
                :items="filteredNotificationTypes"
                v-model="notification.type"
                :loading="isNotificationTypesLoading"
                placeholder="إختر نوع الإشعار"
                :rules="[(v) => !!v || `إختر نوع الإشعار`]"
                :menu-props="{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }"
                append-icon="mdi-chevron-down"
                dense
                filled
                hide-details
                item-text="title"
                return-object
                @input="handleResetFiled('type')"
              >
                <template v-slot:no-data>
                  <h5 class="px-5">
                    {{
                      !isNotificationTypesLoading
                        ? 'لا توجد أنواع إشعارات متاحة'
                        : 'جاري تحميل الإشعارات...'
                    }}
                  </h5>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <h6 class="ma-0 mb-1 text--101010">إختر العقار / الوحده*</h6>
              <autocomplete
                ref="autoCompleteRealEstates"
                :items="realEstates"
                v-model="notification.realEstate"
                v-bind="RealEstateAutoCompleteProps"
                @input="handleRealEstateSelection"
                @nextPageTrigger="getRealestateNextData"
                @emitSearchKey="getRealestateDataBySearchKey"
                hideDetails
              />
            </v-col>
            <v-col class="pl-2" cols="12">
              <h6 class="mb-2 font-14">إختر العقد*</h6>
              <autocomplete
                ref="autoCompleteContracts"
                class="the-contract-dropdown"
                v-bind="ContractAutoCompleteProps"
                v-model="notification.contract"
                @nextPageTrigger="getContractNextData"
                @emitSearchKey="getContractDataBySearchKey"
                @input="handleContractSelection"
                hideDetails
              />
            </v-col>
            <v-col cols="12" v-if="isReminderNotification">
              <h6 class="ma-0 mb-1 text--101010">إختر نوع المطالبة</h6>
              <v-select
                :items="filteredClaimsTypes"
                :disabled="!this.notification.contract"
                v-model="notification.installmentType"
                placeholder="إختر نوع المطالبة"
                :rules="[(v) => !!v || ``]"
                :menu-props="{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }"
                append-icon="mdi-chevron-down"
                dense
                filled
                hide-details
                item-text="nameAr"
                item-value="code"
                @input="getAllInstallments"
              >
                <template v-slot:no-data>
                  <h5 class="px-5">لا توجد أنواع مطالبات متاحة</h5>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" v-if="isReminderNotification">
              <h6 class="ma-0 mb-1 text--101010">إختر المطالبة</h6>
              <v-select
                :items="installments"
                :disabled="!this.notification.installmentType"
                v-model="notification.installments"
                placeholder="إختر المطالبة"
                :rules="[(v) => (v && !!v.length) || ``]"
                :menu-props="{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }"
                append-icon="mdi-chevron-down"
                dense
                filled
                hide-details
                multiple
                item-text="madeUpTitle"
                item-value="_id"
                @change="pushNotification({ items: $event, preview: true })"
              >
                <template v-slot:no-data>
                  <h5 class="px-5">لا توجد مطالبات متاحة</h5>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    {{ item.madeUpTitle }}
                  </v-chip>
                  <h5 class="px-2" v-else-if="index === 1">
                    (+{{ notification.installments.length - 1 }}
                    أخري)
                  </h5>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-space-between align-center">
                <h6 class="text--101010 mt-0 mb-1">محتوي الإشعار</h6>
                <div
                  class="d-flex align-center pointerCursor"
                  v-if="isContentChanged"
                  @click="resetNotificationMessage"
                >
                  <v-btn
                    fab
                    icon
                    width="15"
                    height="15"
                    class="d-flex justify-content-center align-center ma-0 mx-2"
                  >
                    <img :src="resetIcon" />
                  </v-btn>
                  <h6 class="text--7938AA font-12 mt-0 mb-1">
                    إسترجاع المحتوي
                  </h6>
                </div>
              </div>
              <v-textarea
                id="notificationsTextarea"
                dense
                filled
                hide-details="auto"
                placeholder="محتوي الإشعار"
                auto-grow
                no-resize
                rows="2"
                v-model="notification.message"
              />
            </v-col>
          </v-row>
          <v-row class="mt-5" no-gutters>
            <v-col
              cols="12"
              v-for="(notificationOpt, i) in filteredSwitchButtons"
              :key="i"
            >
              <div class="d-flex justify-space-between align-center my-2">
                <div class="d-flex align-center ma-0">
                  <v-icon size="20" class="ml-2 mt-2">
                    {{ notificationOpt.icon }}
                  </v-icon>
                  <span class="mt-1 font-14">
                    {{ notificationOpt.title }}
                  </span>
                </div>
                <v-switch
                  class="ma-0"
                  dense
                  hide-details="auto"
                  inset
                  v-model="notification[`${notificationOpt.key}`]"
                />
              </div>
              <div
                class="d-flex justify-space-between mt-2"
                v-if="
                  notification[`${notificationOpt.key}`] &&
                  notificationOpt?.children?.length
                "
              >
                <div
                  class="d-flex justify-space-between align-center"
                  v-for="(sign, i) in notificationOpt.children"
                  :key="i"
                >
                  <div class="d-flex align-center ma-0 mx-1 my-auto">
                    <span class="font-12 mt-1"> {{ sign.title }} </span>
                  </div>
                  <v-switch
                    class="ma-0 my-auto"
                    dense
                    hide-details="auto"
                    inset
                    v-model="notification[`${sign.key}`]"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>

      <!----------3# Actions----------->
      <v-card-actions class="px-5 py-1 mt-2">
        <v-spacer />
        <v-btn
          class="rounded-7 my-2 font-14 px-8"
          color="primary"
          @click="pushNotification"
          :loading="pushBtnLoading"
          :disabled="!isValid || !iSOneChannelAtLeastActive"
        >
          <span class="ml-1 font-weight-bold"> إرسال </span>
          <v-icon class="send-icon font-16">mdi-arrow-left</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  messageService,
  contractsService,
  realEstateService,
  duesService,
  bondsService
} from '../../services'
import autocomplete from '@/components/forms/autocomplete'
import { mapState, mapMutations } from 'vuex'
import dateFormat from '@/mixins/dateFormat.js'
import switchButtons from '@/constans/notifications/index.js'
import { buildQueryString } from '@/utils/general'

export default {
  name: 'sendNotificationToClientModal',
  components: {
    autocomplete
  },
  mixins: [dateFormat],
  props: {
    client: {
      type: Object,
      default() {
        return {}
      }
    },
    setClickedTag: {
      type: String,
      default: 'owner'
    },
    clientRoles: {
      type: Object,
      default() {
        return {
          renter: 'tenet',
          owner: 'owner'
        }
      }
    },
    existedNotificationTypes: {
      type: Array,
      default() {
        return []
      }
    },
    existedClaimsTypes: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      // add here any new key input ordering is matter based on the layout
      notificationKeys: [
        'type',
        'realEstate',
        'contract',
        'installmentType',
        'installments',
        'message'
      ],
      isValid: false,
      // Icons
      resetIcon: require('@/assets/img/svgs/reset-icon-purple.svg'),

      // constants
      switchButtons,

      // template usage data
      dialogState: true,
      notification: {},
      notificationTypes: [],
      claimsTypes: [],
      installments: [],
      realEstates: [],
      isNotificationTypesLoading: false,
      pushBtnLoading: false,
      defaultMessage: '',

      // RealEstate Field
      paginationRealestate: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      isRealestateLoading: false,
      realestateSearchKey: '',
      // Contract Field
      contracts: [],
      paginationContracts: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      isContractLoading: false,
      contractSearchKey: ''
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),

    filteredClaimsTypes() {
      return this.claimsTypes?.filter((subType) => {
        return this.notification.contract?.hasCombinedRent
          ? subType?.isCombined ?? true
          : true
      })
    },
    // Structuring notification paylod
    notificationPayload() {
      return {
        code: this.notification.type?.code,
        environment: this.currentEnv?._id,
        title: this.notification?.type?.title,
        realEstate: {
          name: this.notification.realEstate.name,
          _id: this.notification.realEstate._id,
          parentName: this.notification.realEstate.parentName ?? undefined,
          parentID: this.notification.realEstate.parentID ?? undefined
        },
        contract: this.notification.contract,
        ...(this.installments.length && {
          installments: [...this.notification?.installments]
        }),
        viaSms: this.notification.viaSMS,
        viaEmail: this.notification.viaEmail,
        viaPlatform: this.notification.viaPlatform,
        includeLink: this.notification.includeLink,
        displayFinancialSignature: this.notification.displayFinancialSignature,
        displayCompanySeal: this.notification.displayCompanySeal,
        displayDirectorSignature: this.notification.displayDirectorSignature,
        displayPropertyManagementManagerSignature:
          this.notification.displayPropertyManagementManagerSignature,
        message: this.notification.message,
        receiver: {
          _id: this.client?._id,
          name: this.client?.name,
          phoneNumber: this.client?.phoneNumber,
          email: this.client?.email
        }
      }
    },

    // Check Computed
    iSOneChannelAtLeastActive() {
      let isSwitcheBtnsValid = false
      for (const property in this.notification) {
        if (property.includes('via') && this.notification[property]) {
          isSwitcheBtnsValid = true
        }
      }
      return isSwitcheBtnsValid
    },
    isReminderNotification() {
      const whiteList = ['financial', 'bonds']
      return whiteList.includes(this.notification?.type?.category)
    },
    isContentChanged() {
      return !(this.notification.message === this.notification.defaultMessage)
    },

    // Filteration Computed
    filteredNotificationTypes() {
      return this.notificationTypes.filter((type) => {
        return !type.userType || !!(type.userType === this.setClickedTag)
      })
    },
    filteredSwitchButtons() {
      return this.switchButtons.filter((btn) => {
        return this.isReminderNotification ? true : !btn.isOnlyFinancial
      })
    },

    // Realestate Field
    RealEstateAutoCompleteProps() {
      return {
        placeholder: 'اختر العقار',
        noData: 'لا توجد عقارات متاحه',
        validateString: 'من فضلك ادخل اسم العقار',
        itemText: 'name',
        loading: this.isRealestateLoading,
        disabled: !this.notification.type,
        items: this.realEstates,
        returnObject: true
      }
    },
    // Contract Field
    ContractAutoCompleteProps() {
      return {
        placeholder: 'اختر العقد',
        noData: !this.isContractLoading
          ? 'لا توجد عقود متاحه'
          : 'جاري البحث عن عقود...',
        validateString: 'من فضلك ادخل اسم العقد',
        itemText: 'title',
        loading: this.isContractLoading,
        disabled: !this.notification.realEstate?._id,
        items: this.contracts,
        contractStatus: !!this.contracts.length
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    // handle Reset inputs that depend on changed input
    handleResetFiled(type) {
      const index = this.notificationKeys.findIndex((key) => key === type)
      for (let i = index + 1; i < this.notificationKeys.length; i++) {
        delete this.notification[`${this.notificationKeys[i]}`]
      }
    },
    resetNotificationMessage() {
      this.$set(
        this.notification,
        'message',
        this.notification.defaultMessage.replace()
      )
    },
    closeModal() {
      this.$emit('changeDialogState')
    },
    async pushNotification({ items = [], preview = false }) {
      if (preview && !items.length && this.isReminderNotification) {
        this.handleResetFiled('installments')
        return
      }
      const paylaod = {
        ...this.notificationPayload,
        preview
      }
      this.pushBtnLoading = true
      try {
        const { data } = await bondsService.sendNotification(paylaod)
        this.$set(this.notification, 'message', data.message)
        this.$set(this.notification, 'defaultMessage', data.message)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من إرسال الإشعار, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.pushBtnLoading = false
        if (!preview) {
          this.$emit('refreshNotification')
          this.closeModal()
        }
      }
    },
    async getNotificationTypes() {
      if (this.existedNotificationTypes.length) {
        this.notificationTypes = [...this.existedNotificationTypes]
        return
      }
      this.isNotificationTypesLoading = true
      try {
        const { data } = await messageService.getNotificationTypes()
        this.notificationTypes = data.notificationTypes ?? []
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل أنواع الإشعارات, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.isNotificationTypesLoading = false
      }
    },
    async getClaimsTypes() {
      if (this.existedClaimsTypes.length) {
        this.claimsTypes = [...this.existedClaimsTypes]
        return
      }
      try {
        const { data } = await duesService.getDuesFilters('installmentType')
        this.claimsTypes = data
        this.$emit('setConstantDataModel', {
          claimsTypes: this.claimsTypes,
          notificationTypes: this.notificationTypes
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل أنواع المطالبات, حاول مره أخري',
          color: 'error'
        })
      }
    },
    async getAllInstallments() {
      const queryString = buildQueryString(
        {
          environment: this.currentEnv?._id,
          contract: this.notification.contract,
          installmentType: this.notification.installmentType,
          code: this.notification.type?.code,
          ...(this.notification.type.category === 'bonds' && { status: 'paid' })
        },
        ''
      )
      try {
        const { data } = await duesService.getInstallments(queryString)
        this.installments = data.installments
        this.installments.map((installment) => {
          installment.madeUpTitle = `${installment.installmentType} - ${
            installment.remaining
          } ريال - ${this.formatDate(installment.date)} - ${this.formatDate(
            installment.endDate
          )}`
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل المطالبات, حاول مره أخري',
          color: 'error'
        })
      }
    },
    // Realestate Field
    getRealestateNextData() {
      if (
        this.paginationRealestate.pageNumber <
        this.paginationRealestate.pagesCount
      ) {
        this.paginationRealestate.pageNumber += 1
        this.loadRealEstate()
      }
    },
    getRealestateDataBySearchKey(e) {
      this.realestateSearchKey = e
      this.paginationRealestate.pageNumber = 1
      if (this.realestateSearchKey) {
        this.loadRealEstate()
      }
    },
    async handleRealEstateSelection(realEstate) {
      if (!realEstate?._id) {
        this.handleResetFiled('realEstate')
        this.contracts = []
        return
      }
      this.isContractLoading = true
      const query = {
        realEstate: this.notification.realEstate?._id,
        users: this.client?._id,
        environment: this.currentEnv._id,
        userType: this.clientRoles[this.setClickedTag],
        types: ['rent'],
        statuses: ['approved', 'active', 'expired', 'terminated', 'recorded']
      }
      let contractQuery = `pageSize=${this.paginationContracts.pageSize}&pageNumber=${this.paginationContracts.pageNumber}`
      if (this.contractSearchKey) {
        contractQuery += `&search=${this.contractSearchKey}`
      }
      try {
        const { data } = await contractsService.getContracts(
          query,
          contractQuery
        )
        this.contracts = [...this.contracts, ...(data?.contracts ?? [])]
        this.paginationContracts.pagesCount = Math.ceil(
          data?.count / this.paginationContracts?.pageSize
        )
        this.paginationContracts.count = data?.count
      } catch {
        this.addNotification({
          text: `لم نتمكن من تحميل عقودات عقار ${this.notification.realEstate.name}`,
          color: 'error'
        })
      } finally {
        this.isContractLoading = false
      }
    },
    async loadRealEstate() {
      this.isRealestateLoading = true
      let query = `pageSize=${this.paginationRealestate.pageSize}&pageNumber=${this.paginationRealestate.pageNumber}`
      if (this.realestateSearchKey) {
        query += `&search=${this.realestateSearchKey}`
      }
      const body = {
        user: this.client?._id,
        userType: this.clientRoles[this.setClickedTag],
        child: false,
        environments: [this.currentEnv._id]
      }
      try {
        const { data } = await realEstateService.getRealEstates(body, query)

        this.paginationRealestate.pagesCount = Math.ceil(
          data?.count / data?.pageSize
        )
        this.realEstates = [...this.realEstates, ...(data?.realEstates ?? [])]
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل العقارات',
          color: 'error'
        })
      } finally {
        this.isRealestateLoading = false
      }
    },

    // Contract Field
    getContractNextData() {
      if (
        this.paginationContracts.pageNumber <
        this.paginationContracts.pagesCount
      ) {
        this.paginationContracts.pageNumber += 1
        this.handleRealEstateSelection()
      }
    },
    getContractDataBySearchKey(e) {
      this.contractSearchKey = e
      if (this.contractSearchKey && this.notification.realEstate?._id) {
        this.handleRealEstateSelection()
      }
    },
    handleContractSelection(contract) {
      if (contract && !this.isReminderNotification) {
        this.pushNotification({ preview: true })
        return
      }
      this.handleResetFiled('contract')
      this.installments = []
    },

    // component intiation
    async componentInit() {
      // Get Notification Type
      await this.getNotificationTypes()
      await this.loadRealEstate()
      await this.getClaimsTypes()
    }
  },
  created() {
    this.componentInit()
  }
}
</script>
