<template>
  <div>
    <div v-if="tables.length && atLeastOneTableHasData()">
      <div v-for="(mainreport, i) in tables" :key="i">
        <div>
          <div class="after_table_div after__table__block">
            <div class="after_table_content report_table">
              <div class="transaction_detail after__table__details__block">
                <div
                  v-for="(report, i) in reports[mainreport.code]"
                  :key="i"
                  class="d-block result__total align-items-center"
                >
                  <div v-if="isTotalTable(report)">
                    <div class="transaction__detail__title">
                      <h3 class="mt-8 mb-0">
                        {{ report.title || 'الملخص العام ' }}
                      </h3>
                    </div>
                    <div class="d-flex">
                      <h5 class="mw-28" />
                      <h5
                        v-for="key in totalTableKeys"
                        :key="key"
                        class="grey--text fw-500 text-center my-2 mw-24"
                      >
                        {{ key }}
                      </h5>
                    </div>
                    <div v-for="(record, i) in report" :key="i">
                      <template v-if="i === 'totals'">
                        <div v-for="(data, j) in record" :key="j">
                          <div
                            class="d-flex mb-1"
                            v-if="Object.keys(record).length"
                          >
                            <h6
                              class="fw-400 mt-8 mb-5 mw-28 text-h5 d-flex justify-space-between align-center"
                              :class="data.type"
                            >
                              {{ data.name || '-' }}
                              <v-tooltip
                                v-if="generateTooltipData(data.name).status"
                                content-class="v-tool"
                                color="black"
                                min-width="95"
                                bottom
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <div v-bind="attrs" v-on="on">
                                    <img class="mt-1" :src="tooltipIcon" />
                                  </div>
                                </template>
                                <span
                                  class="tool--tip font-12 font-weight-medium"
                                  >{{
                                    generateTooltipData(data.name).text
                                  }}</span
                                >
                              </v-tooltip>
                            </h6>
                            <h6
                              class="fw-500 text-center text-h5 mt-8 mb-5 mw-24"
                            >
                              {{ data.amount || '-' }}
                            </h6>
                            <h6
                              class="fw-500 text-center text-h5 mt-8 mb-5 mw-24"
                            >
                              {{ data.tax || '-' }}
                            </h6>
                            <h6
                              class="fw-500 text-center text-h5 mt-8 mb-5 mw-24"
                            >
                              {{ data.total || '-' }}
                            </h6>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <NoData v-bind="noDataConfig" />
    </div>
  </div>
</template>

<script>
import NoData from '@/components/helper/noData.vue'

export default {
  name: 'GeneralSummary',
  components: {
    NoData
  },
  props: {
    tables: {
      default: [],
      required: true
    },
    headers: {
      default() {
        return []
      },
      required: true
    },
    reports: {
      default() {
        return []
      },
      required: true
    },
    totalTableKeys: {
      default() {
        return []
      },
      required: true
    },
    generateTooltipData: {
      default() {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      tooltipIcon: require('@/assets/img/svgs/exclamation-white-rounded.svg')
    }
  },
  computed: {
    noDataConfig() {
      return {
        noDataText: 'لا توجد بيانات متاحة لهذا التقرير',
        noDataSubTitle: '',
        isbgPrimary: false
      }
    }
  },
  methods: {
    isTotalTable(table) {
      return !!table?.totals?.length
    },
    atLeastOneTableHasData() {
      const isAtLeastOneTableHasData = this.reports[this.tables[0].code].filter(
        (table) => {
          return !!this.isTotalTable(table)
        }
      )
      return !!isAtLeastOneTableHasData.length
    }
  }
}
</script>
