import { EnvService } from '@/services'
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      isTaxOrIdMissing: null,
      isUpdateLoading: false,
      isUpdatingClientSucceded: null,
      typeCheck: '',
      individualIdTypes: ['individual', 'owner']
    }
  },
  methods: {
    ...mapMutations('accountState', ['addNotification']),
    checkClientData(client) {
      if (client) {
        if (!client.idNumber || !client.taxNumber) {
          this.isTaxOrIdMissing = true
        } else {
          this.isTaxOrIdMissing = false
        }
        this.setTypeCheck(client)
      } else {
        this.isTaxOrIdMissing = false
        this.setTypeCheck(client)
      }
    },
    async updateClient(payload, clientId) {
      try {
        this.isUpdateLoading = true
        await EnvService.editClient(payload, clientId)
        this.addNotification({
          text: 'تم تحديث بيانات العميل بنجاح',
          color: 'success'
        })
        this.isUpdatingClientSucceded = true
        this.isTaxOrIdMissing = false
      } catch {
        this.addNotification({
          text: 'حدث خطأ اثناء تحديث بيانات العميل ، يرجى المحاوله مجددا',
          color: 'error'
        })
        this.isUpdatingClientSucceded = false
      } finally {
        this.isUpdateLoading = false
      }
    },
    setTypeCheck(client) {
      if (!client) {
        this.typeCheck = ''
        return
      }
      const isIndividual = this.individualIdTypes.includes(
        client.type || client.clientType
      )
      this.typeCheck = isIndividual ? 'رقم الهوية' : 'السجل التجاري'
    },
    generateClientType(type) {
      if (type === 'owner' || type === 'individual') return 'individual'
      else return 'company'
    }
  }
}
