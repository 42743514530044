<template>
  <v-dialog v-model="shareDialog_" persistent max-width="490">
    <v-card class="add-new-property">
      <v-card-title
        class="pl-2 pr-4 pt-3 pb-5 mb-5 d-flex justify-space-between custom__shadow"
      >
        <span class="headline ft">مشاركة العقار</span>

        <v-btn
          fab
          text
          color="grey"
          class="ma-0 mt-2"
          @click.prevent="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="isValid" onSubmit="return false;">
          <p class="">ارسل رابط العقار على هكتار</p>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="ادخل البريد الإلكتروني"
            prepend-inner-icon="mdi-email"
            required
            filled
            rounded
            @keydown.enter="validate"
          ></v-text-field>

          <v-switch
            v-model="switch1"
            color="green"
            class="ma-0"
            label="ارفق ملف PDF بتفاصيل العقار في الرسالة"
          >
          </v-switch>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          x-large
          rounded
          :loading="isLoading"
          width="200"
          class="ma-0"
          color="success"
          @click="validate"
        >
          <span class="tf">شارك الآن</span>
          <v-icon class="send-icon mr-2">mdi-send</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_add-new-property.scss'

export default {
  props: ['shareDialog'],
  data() {
    return {
      email: null,
      isValid: false,
      isLoading: false,
      switch1: null,
      emailRules: [
        (v) => !!v || 'من فضلك أدخل البريد الإلكتروني',
        (v) => /.+@.+\..+/.test(v) || 'من فضلك أدخل بريد الكتروني صحيح'
      ]
    }
  },
  computed: {
    shareDialog_: {
      get() {
        return this.shareDialog
      },
      set(value) {
        this.$emit('changeShareDialog', value)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('closed-share-dialog')
    },
    validate() {
      this.$refs.form.validate()
      if (this.isValid === true) {
        this.sahreProperty()
      }
    },
    sahreProperty() {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
        this.closeDialog()
        this.addNotification({
          text: 'تم مشاركة العقار بنجاح',
          color: 'success'
        })

        this.tab = 0
      }, 1000)
      return false
    }
  }
}
</script>
