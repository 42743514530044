<template>
  <div
    class="show-detailes approval-details contentSection contracts__bonds__side__deatails"
  >
    <v-slide-x-transition>
      <v-flex class="whiteSide flex-wrap">
        <Loader
          v-if="isSideDetailsLoading"
          v-bind="{ ...loaderProps, numberOfLines: 1 }"
          class="my-3"
        />
        <DetailsHeader
          v-else
          :approval="approval"
          :isOwener="isOwener"
          :currentApprovalOwner="currentApprovalOwner"
          @closeDetails="closeDetails"
          @cancelled="$emit('cancelled', $event)"
        />
        <v-divider class="mt-2"></v-divider>
        <v-layout class="sidedetails__layout mobile__screen">
          <Loader
            v-if="isSideDetailsLoading"
            v-bind="loaderProps"
            class="my-3 px-5"
          />
          <v-flex
            v-else
            class="px-5 mobile__wrap"
            :class="{ 'details-container': getBreakPoint['mdAndUp'] }"
            md8
          >
            <ApprovalDetails :approval="approval" />

            <v-divider class="mt-8"></v-divider>

            <ApprovalParteners
              :approvel="approval"
              :currentApprovalOwner="currentApprovalOwner"
              @approved="$emit('approved', $event)"
              @rejected="$emit('rejected', $event)"
            />

            <v-divider class="mt-8"></v-divider>

            <OperationCard
              @click="handelRefrence"
              @reportClicked="handelRefrence"
              :approval="approval"
              :isOwener="isOwener"
            />
          </v-flex>
          <v-divider vertical class="mx-4 mobile__d__none"></v-divider>
          <ApprovalComments
            :isSideDetailsLoading="isSideDetailsLoading"
            :approval="approval"
          />
        </v-layout>
      </v-flex>
    </v-slide-x-transition>
  </div>
</template>

<script>
import { typeServiceMapping, ApprovalSideDetails } from '@/constans/approval'
import DetailsHeader from './Header'
import ApprovalDetails from './ApprovalDetails'
import ApprovalParteners from './Parteners.vue'
import OperationCard from './OperationCard.vue'
import ApprovalComments from './ApprovalComments.vue'
import Loader from '@/components/helper/loader.vue'
import { mapState } from 'vuex'
import config from '@/config'

export default {
  components: {
    DetailsHeader,
    ApprovalDetails,
    ApprovalParteners,
    OperationCard,
    ApprovalComments,
    Loader
  },
  props: {
    approval: {
      type: Object,
      default: () => {}
    },
    isSideDetailsLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      exitArrow: require('@/assets/img/svgs/right-arrow.svg'),
      reference: {},
      tokken: JSON.parse(localStorage.getItem('tokenData')),
      ...ApprovalSideDetails
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'companyImages']),
    lg() {
      return this.$vuetify.breakpoint.lg
    },
    getBreakPoint() {
      return this.$vuetify.breakpoint || {}
    },
    companyLogoPath() {
      return this.companyImages?.companyLogo?.path || ''
    },
    isOwener() {
      return (
        this.approval?.createdBy?.id === this.tokken?.id &&
        this.approval?.status !== 'approved'
      )
    },
    currentApprovalOwner() {
      const ownerId = this.currentEnv?.owner?._id
      return this.approval?.approvals?.find(
        ({ user: { _id } }) => _id === ownerId
      )
    }
  },
  methods: {
    closeDetails() {
      this.$emit('closeDetails')
    },
    handleOpenItem(file) {
      localStorage.setItem('sideDetailsItem', JSON.stringify(file))
    },
    pdfgen() {
      const url = `${config.reportsUrl}/reports/generate/${this.reference?._id}?format=pdf&logo=${this.companyLogoPath}`
      window.open(url, '_blank', 'noreferrer')
    },
    navigateToApprovalType() {
      this.$router.push({
        path: `/dashboard/${this.approval.type}`,
        query: {
          visible: true,
          backToCurrentPath: true
        }
      })
    },
    async handelRefrence() {
      await this.getReference()
      if (this.approval.type === 'reports') {
        this.pdfgen()
        return
      }
      this.handleOpenItem({
        ...this.reference,
        isApproved: this.approval.status
      })
      this.navigateToApprovalType()
    },
    async getReference() {
      const serviceFunction = typeServiceMapping[this.approval.type]

      const entityId = this.approval.entity._id
      const approvalType = this.approval.type.slice(0, -1) // Remove trailing 's'
      const serviceResponse = await serviceFunction(entityId)
      const data = serviceResponse.data

      let referenceEntity
      if (['report', 'taxInvoice'].includes(approvalType)) {
        referenceEntity = data
      } else if (approvalType === 'contract') {
        referenceEntity = data.contracts[0]
      } else {
        referenceEntity = data[approvalType]
      }

      this.reference = referenceEntity

      return null // eslint
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 430px) {
  .mobile__screen {
    flex-direction: column;
  }

  .mobile__wrap {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
