<template>
  <v-navigation-drawer
    v-model="taskPanel"
    width="500"
    absolute
    temporary
    class="task-panel"
  >
    <!-- Edit form -->
    <v-form ref="form" v-model="valid" :lazy-validation="lazy" class="pa-5">
      <v-row v-if="task">
        <v-col cols="12">
          <v-textarea dense label="نص المهمة" v-model="task_.text"></v-textarea>
        </v-col>

        <v-col cols="12">
          <v-select
            v-model="task_.property"
            prepend-icon="mdi-home-outline"
            :items="properties"
            label="اختر عقار معين"
          >
          </v-select>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-dialog
            ref="dialog"
            v-model="dateModal"
            :return-value.sync="task.dueAt"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="task_.dueAt"
                label="تاريخ استحقاق المهمة"
                placeholder="غير محددة التاريخ"
                prepend-icon="event"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="task_.dueAt" color="primary" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateModal = false"
                >الغاء</v-btn
              >
              <v-btn text color="primary" @click="$refs.dialog.save(task.dueAt)"
                >حفظ</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select
            v-model="task_.assignedTo"
            prepend-icon="mdi-account-outline"
            :items="team"
            item-text="name"
            item-value="name"
            label="موجهة إلى"
          >
          </v-select>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-select
            v-model="task_.status"
            prepend-icon="mdi-briefcase-check-outline"
            :items="taskStatus"
            item-text="name"
            return-object
            small-chips
            label="حالة المهمة"
          >
            <template #selection="{ item }">
              <v-chip small :color="item.color" dark>{{ item.name }}</v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select
            v-model="task_.type"
            prepend-icon="mdi-briefcase-variant-outline"
            :items="taskType"
            label="نوع المهمة"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-file-input
            multiple
            small-chips
            show-size
            counter
            v-model="task_.attachments"
            label="مرفقات المهمة"
          >
          </v-file-input>
        </v-col>
      </v-row>
    </v-form>

    <template v-slot:append>
      <v-flex class="py-2 px-5">
        <v-btn large text color="grey" @click="taskPanel = false">
          <span class="tf">الغاء</span>
        </v-btn>

        <v-btn
          :loading="saveTaskLoading"
          color="success"
          large
          :disabled="!valid"
          @click="validate"
        >
          <span class="tf">حفظ</span>
        </v-btn>
      </v-flex>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { EventBus } from '@/services/EventBus'
import config from '@/config'
import { mapMutations } from 'vuex'

export default {
  props: ['task', 'isOpend'],
  data() {
    return {
      taskPanel: false,
      // taskform
      valid: true,
      select: null,
      taskStatus: [
        { name: 'نشطة', color: 'green' },
        { name: 'منتهية', color: 'grey' },
        { name: 'مكتملة', color: 'success' },
        { name: 'هامة جداً', color: 'warning' },
        { name: 'عاجلة جداً', color: 'danger' }
      ],
      taskType: ['النوع ١', 'النوع ٢', 'النوع ٣'],
      checkbox: false,
      lazy: false,
      dateModal: false,
      saveTaskLoading: false
    }
  },
  created() {
    EventBus.$on('openTaskPanel', () => {
      this.taskPanel = true
    })
  },
  watch: {
    isOpend(val) {
      this.taskPanel = val
    }
  },
  computed: {
    properties() {
      return []
    },
    team() {
      return config.team
    },
    task_: {
      get() {
        return this.task
      },
      set(value) {
        this.$emit('changeTask', value)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification', 'toggleDrawer']),

    validate() {
      this.$refs.form.validate()

      this.saveTaskLoading = true
      setTimeout(() => {
        this.saveTaskLoading = false
        this.taskPanel = false
        this.addNotification({
          text: 'تم حفظ المهمة بنجاح',
          color: 'success'
        })
      }, 2000)
    }
  }
}
</script>
