<template>
  <div>
    <div class="d-flex">
      <v-col cols="6" class="ml-1">
        <h5 class="grey--text mt-5 font-14">اسم العميل</h5>
        <p class="black--text font-16">{{ requestClientName }}</p>
        <v-divider />
      </v-col>
      <v-col cols="6">
        <h5 class="grey--text mt-5 font-14">اسم العقار</h5>
        <p class="black--text font-16">{{ requestRealEstateName }}</p>
        <v-divider />
      </v-col>
    </div>
    <div>
      <span class="d-flex">
        <h5 class="grey--text mt-5 font-14">العنوان</h5>
        <v-btn
          icon
          :disabled="!isValidRealEstateLocation"
          @click="gotToAddresOnGoogleMap"
        >
          <v-icon color="primary">mdi-map-marker</v-icon>
        </v-btn>
      </span>
      <div>
        <p class="black--text font-16">
          {{ requestRealEstateAddress }}
        </p>
      </div>
    </div>

    <v-divider />
    <div>
      <h5 class="grey--text mt-5">وصف الطلب</h5>
      <v-textarea
        v-model="requestDetails.description"
        @blur="setRequestDetails('description', requestDetails.description)"
        placeholder="يمكنك إضافه وصف الطلب وسوف يتم حفظها تلقائيا"
        class-content="font-12 mb-1"
        class="textArea"
        no-resize
        rows="2"
        :disabled="!hasUpdatePermission"
      ></v-textarea>
    </div>

    <v-container v-if="!isCustomer">
      <v-flex d-flex justify-space-between flex-wrap class="mb-6 gap-10px">
        <div>
          <h5 class="grey--text font-14">حاله الطلب</h5>
          <v-menu
            :disabled="!hasUpdatePermission"
            auto
            content-class="dataTableMenus"
            elevation-0
            offset-y
          >
            <template v-slot:activator="{ on: menu }">
              <div
                class="cursor tabel-tag text-center align-center d-flex justify-center task__tags font-12"
                :class="getAllTagsColors(requestDetails.status?.nameAr)"
                v-click-outside="closeStatusOptions"
                @click="toggleShowStatusOptions"
                v-on="{ ...menu }"
              >
                <v-sheet
                  class="dotted"
                  :color="requestStatusesColors[requestDetails.status?.nameAr]"
                />

                <span class="ma-0 mb-1 mr-1">
                  {{ requestDetails.status?.nameAr }}
                </span>
                <v-icon
                  class="mr-1"
                  :color="requestStatusesColors[requestDetails.status?.nameAr]"
                  >{{ arrowIcon }}</v-icon
                >
              </div>
            </template>
            <v-list v-show="showStatusOptions" class="py-1">
              <v-list-item
                v-for="status in requestStates"
                :key="status.nameEn"
                @click="setRequestValues('status', status.nameEn)"
              >
                <v-list-item-title>
                  <div
                    class="tabel-tag text-center d-flex justify-center"
                    :class="getAllTagsColors(status.nameEn)"
                  >
                    <v-sheet
                      class="dotted ma-0 pa-0 ml-2 mt-2"
                      :color="requestStatusesColors[status?.nameAr]"
                    />
                    <h6 class="mb-1 ma-0 font-12">
                      {{ status.nameAr }}
                    </h6>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div>
          <h5 class="grey--text font-14">الأولويه</h5>
          <v-menu
            :disabled="!hasUpdatePermission"
            auto
            content-class="dataTableMenus"
            elevation-0
            offset-y
          >
            <template v-slot:activator="{ on: menu }">
              <div v-on="{ ...menu }" class="cursor">
                <span class="ma-0">
                  <v-icon
                    class="mdi-24px"
                    :class="getAllTagsColors(requestPriorityName)"
                  >
                    mdi-flag-variant-outline
                  </v-icon>
                </span>
                <span
                  class="font-weight-bold font-12"
                  :class="getAllTagsColors(requestPriorityName)"
                >
                  {{ requestPriorityName }}
                </span>
              </div>
            </template>
            <v-list id="priority" class="ma-0 pa-0 pl-3">
              <v-list-item
                class="pa-0"
                v-for="priority in requestPriorities"
                :key="priority.nameEn"
                @click="setRequestValues('priority', priority.nameEn)"
              >
                <v-list-item-icon class="ml-0 mr-3">
                  <v-icon
                    class="mdi-18px"
                    :class="'red--flag ' + getAllTagsColors(priority.nameAr)"
                  >
                    mdi-flag-variant-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  class="font-weight-bold font-12"
                  :class="getAllTagsColors(priority.nameAr)"
                >
                  {{ priority.nameAr }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div>
          <h5 class="grey--text font-14">المسؤول</h5>
          <div
            v-if="requestDetails.moderator && !showAutocomplete"
            class="d-flex cursor"
            @click="openAutocomplete"
          >
            <h6
              class="ma-0 d-flex align-center justify-center blue--tag bill-md font-12 ml-1"
            >
              {{ $subString(requestDetails.moderator) }}
            </h6>
            <h5 class="ma-0 font-weight-medium">
              {{ requestDetails.moderator }}
            </h5>
          </div>
          <div v-else>
            <autocomplete
              style="width: 200px"
              ref="moderatorsAutocomplete"
              :hideDetails="true"
              :items="allEmployees"
              :noData="noDataClient"
              :loading="clientIsLoading"
              item-value="_id"
              placeholder="المسؤول"
              :validateString="validateStringClient"
              v-model="requestDetails.moderator"
              @emitSearchKey="getEmployeesDataBySearchKey"
              @nextPageTrigger="getEmployeesNextData"
              @blur="setRequestDetails('moderator', requestDetails.moderator)"
            />
          </div>
        </div>
      </v-flex>
      <div v-if="isCanceledOrRejected">
        <div
          v-if="requestInfo.reason"
          class="pa-3 font-12 mb-3 cancel-reason-text"
        >
          <span class="font-weight-medium">سبب الإلغاء : </span>
          <span class="mr-1"> {{ requestDetails.reason }} </span>
        </div>
        <div v-else class="cancel-reason-input">
          <h5 class="grey--text mb-1">سبب الإلغاء</h5>
          <v-text-field
            placeholder="ادخل هنا سبب الالغاء"
            dense
            filled
            v-model="requestDetails.reason"
            @change="setRequestDetails('reason', requestDetails.reason)"
          ></v-text-field>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Requests from '@/constans/Requests'
import { mapState, mapMutations } from 'vuex'
import autocomplete from '@/components/forms/autocomplete'
import { getLocalNames } from '@/utils/general'

export default {
  name: 'RequestDetails',
  components: {
    autocomplete
  },
  props: {
    isCustomer: {
      default: false
    },
    hasUpdatePermission: {
      default: true,
      type: Boolean
    },
    requestInfo: {
      type: Object,
      required: true
    },
    allEmployees: {
      default() {
        return []
      }
    }
  },
  data() {
    return {
      ...Requests,
      requestStatusesColors: {
        جديدة: '#FF8000',
        جديده: '#FF8000',
        // 'قيد الإنتظار': '#662D91',
        'قيد التنفيذ': '#662D91',
        underReview: '#662D91',
        'تم ملغي': '#fc4a4a',
        ملغي: '#fc4a4a',
        'تم التنفيذ': '#65C467',
        مرفوض: '#383838'
      },
      requestDetails: {},
      requestPriorities: [
        { nameAr: 'طارئ', nameEn: 'urgent' },
        { nameAr: 'عاجل', nameEn: 'high' },
        { nameAr: 'متوسط', nameEn: 'normal' },
        { nameAr: 'منخفض', nameEn: 'low' }
      ],
      isValid: false,
      showAutocomplete: false,
      noDataClient: 'لا يوجد عملاء متاحين',
      validateStringClient: 'من فضلك ادخل اسم العميل',
      clientIsLoading: false,
      showStatusOptions: false
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    getItemStatusAr() {
      return this.requestStates.find(
        (status) => status.nameEn === this.requestDetails.status
      )?.nameAr
    },
    requestClientName() {
      return this.requestDetails.applicant ?? 'غير محدد'
    },
    requestRealEstateName() {
      return this.requestDetails.realEstate?.propertyName ?? 'غير محدد'
    },
    requestRealEstateAddress() {
      return this.requestDetails.realEstate?.address?.address ?? 'غير محدد'
    },
    requestRealEstateLat() {
      return this.requestDetails.realEstate?.location?.lat
    },
    requestRealEstateLng() {
      return this.requestDetails.realEstate?.location?.lng
    },
    isValidRealEstateLocation() {
      return !!(this.requestRealEstateLat && this.requestRealEstateLng)
    },
    arrowIcon() {
      return this.showStatusOptions ? 'mdi-chevron-up' : 'mdi-chevron-down'
    },
    requestPriorityName() {
      return this.requestPriorities.find(
        (priority) => priority.nameAr === this.requestDetails.priority?.nameAr
      )?.nameAr
    },
    isCanceledOrRejected() {
      return (
        this.requestDetails.status === 'canceled' ||
        this.requestDetails.status === 'rejected'
      )
    }
  },
  watch: {
    requestInfo(val, oldVal) {
      this.requestDetails = { ...val }
      if (val?._id !== oldVal?._id) {
        this.showAutocomplete = false
        this.allEmployees.push(this.requestDetails.moderator)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getEmployeesDataBySearchKey(e) {
      this.$emit('getEmployeesDataBySearchKey', e)
    },
    getEmployeesNextData() {
      this.$emit('getEmployeesNextData')
    },
    toggleShowStatusOptions() {
      this.showStatusOptions = !this.showStatusOptions
    },
    closeStatusOptions() {
      this.showStatusOptions = false
    },
    setRequestValues(key, value) {
      this.$set(this.requestDetails, key, { nameAr: getLocalNames(value) })
      this.$emit('updateRequest', {
        requestId: this.requestDetails._id,
        updatedData: { [`${key}`]: value },
        index: 0
      })
      // this.setRequestDetails(key, { nameAr: getLocalNames(value) })
    },
    setRequestDetails(key, value) {
      if (!value) {
        return
      }
      this.$emit('updateRequest', {
        requestId: this.requestDetails._id,
        updatedData: { [`${key}`]: value },
        index: 0
      })
      this.$emit('setRequestDetails', { [`${key}`]: value })
    },
    setShowAutocomplete(val) {
      this.showAutocomplete = val
    },
    openAutocomplete() {
      if (this.hasUpdatePermission) {
        this.setShowAutocomplete(true)
        this.$nextTick(() => {
          this.$refs.moderatorsAutocomplete.$children[0].focus()
        })
      }
    },
    gotToAddresOnGoogleMap() {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${this.requestRealEstateLat},${this.requestRealEstateLng}`,
        '_blank'
      )
    },
    // Generate all Tags Colors using binding class
    getAllTagsColors(priority) {
      switch (priority) {
        case 'طارئ':
          return 'red--flag'
        case 'عاجل':
          return 'lightb--flag'
        case 'متوسط':
          return 'orange--flag'
        case 'منخفض':
          return 'low--flag'
        case 'underReview':
          return 'brub--tag'
        case 'new':
        case 'جديدة':
        case 'جديده':
          return 'orange--tag'
        case 'request':
        case 'inProgress':
        case 'قيد الإنتظار':
        case 'قيد التنفيذ':
          return 'brub--tag'
        case 'mission':
          return 'brub--tag'
        case 'complain':
          return 'lightGreen--tag'
        case 'done':
        case 'تم التنفيذ':
          return 'green--tag'
        case 'cancelled':
        case 'تم الإلغاء':
        case 'ملغي':
          return 'red--tag'
        case 'rejected':
          return 'black--tag'
      }
    },
    setRequestModerator(moderator) {
      this.setRequestValues('moderator', moderator)
      this.setShowAutocomplete(false)
    }
  },
  created() {
    // this.loadClients()
  }
}
</script>

<style></style>
