export default {
  BASE_URL: process.env.VUE_APP_BASE_URL || 'http://localhost:8080/',
  apiUrl: process.env.VUE_APP_API_URL || 'http://localhost:8083/api',
  accountsUrl: process.env.VUE_APP_ACCOUNTS_URL || 'http://localhost:9091/api',
  usersUrl: process.env.VUE_APP_USERS_URL || 'http://localhost:9090/api',
  realEstateUrl:
    process.env.VUE_APP_REALESTATE_URL ||
    'https://realestates.hectar.io:9085/api',
  messagesUrl:
    process.env.VUE_APP_MESSAGES_URL ||
    'http://messagingdev.devstagging.online:9989/api',
  bonds: process.env.VUE_APP_BONDS_URL || 'http://localhost:4000/api',
  reportsUrl: process.env.VUE_APP_REPORTS_URL || 'http://localhost:7788/api',
  googleMapApiKey: 'AIzaSyDLDBsRAjpSHIQ_oQlxhrnWVejpPwjA9V0',
  iosAppUrl: 'https://apps.apple.com/us/app/id1492253360',
  androidAppUrl: 'https://play.google.com/store/apps/details?id=com.hectarapp',
  mapStyles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd'
        }
      ]
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ededed'
        },
        {
          saturation: -20
        }
      ]
    },
    {
      featureType: 'landscape.natural.landcover',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ebe9e6'
        },
        {
          saturation: -30
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'poi.attraction',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.business',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.government',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.medical',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#d4eed4'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.school',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          saturation: -100
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [
        {
          saturation: -100
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      featureType: 'road.highway.controlled_access',
      elementType: 'labels.icon',
      stylers: [
        {
          saturation: -100
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#e1e1e1'
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#e2e2e2'
        }
      ]
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'labels.icon',
      stylers: [
        {
          color: '#888888'
        }
      ]
    },
    {
      featureType: 'transit.station.rail',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#d3e6f2'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    }
  ],
  supportedImageTypes: ['image/png', 'image/jpeg', 'image/jpg'],
  payTypeOptions: [
    { text: 'سنة', _id: 0 },
    { text: 'شهر', _id: 1 },
    { text: 'يوم', _id: 2 }
  ],
  clientType: [
    { text: 'جديد', _id: 1 },
    { text: 'حالي', _id: 2 }
  ],
  reportOption: [
    { text: 'عميل', _id: 1, code: 'client' },
    { text: 'عقار', _id: 2, code: 'realestate' }
  ],
  reportPrefixes: {
    owner: {
      title: 'مالك',
      placeholder: 'ملاك'
    },
    renter: {
      title: 'مستأجر',
      placeholder: 'مستأجرين'
    },
    all: {
      title: 'عميل',
      placeholder: 'عملاء'
    },
    dates: {
      titles: ['الإستحقاق', 'العقد', 'التحصيل'],
      types: {
        dues: 0,
        claims: 0,
        tenantsStatement: 1,
        rentersStatement: 2
      }
    }
  },
  taxClientType: [
    { text: 'عميل جديد', _id: 1, code: 'newOwner' },
    { text: 'عميل مسجل ومضاف', _id: 2, code: 'addedOwner' }
  ],
  taxRealEstateType: [
    { text: 'عقار جديد', _id: 1, code: 'newProp' },
    { text: 'عقار مسجل ومضاف', _id: 2, code: 'addedProp' }
  ],
  taxInvoiceOptions: [
    { nameAr: 'ساريه', active: true, code: 'approved' },
    { nameAr: 'مسوده', active: false, code: 'draft' },
    { nameAr: 'ملغيه', active: false, code: 'cancelled' }
  ],
  selectedSides: {
    1: 'الجهه الشرقية',
    2: 'الجهه الغربية',
    3: 'الجهه الشمالية',
    0: 'الجهه الجنوبية'
  },
  fastActionsList: [
    {
      text: 'إضافه عقار',
      icon: require('@/assets/img/svgs/build.svg'),
      action: 'properties'
    },
    {
      text: 'إضافه عميل',
      icon: require('@/assets/img/svgs/user.svg'),
      action: 'client'
    },
    {
      text: 'إضافه تقرير',
      icon: require('@/assets/img/reports/3-active.png'),
      action: 'report'
    },
    {
      text: 'إضافه عقد',
      icon: require('@/assets/img/reports/reports-active.png'),
      action: 'contract'
    },
    {
      text: 'إضافه سند',
      icon: require('@/assets/img/reports/reports-active.png'),
      action: 'bond'
    }
  ]
}
