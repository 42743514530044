<template>
  <v-dialog
    overlay-opacity="0.5"
    persistent
    v-model="dialogState_"
    width="736px"
  >
    <v-card class="pt-0">
      <v-card-title class="px-5 py-2 d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <h4>هل أنت متأكد من إلغاء السند؟</h4>
        </div>

        <v-btn
          data-test="close"
          @click.prevent="dialogState_ = false"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon size="20">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="py-7 px-5">
        <div class="rounded-15 bg--fc4a4a1a d-flex align-center px-4 py-2 mb-6">
          <div class="bg--fc4a4a1a rounded-circle pa-1 ml-2">
            <v-img width="12" :src="exclamation" />
          </div>
          <div>
            <span>
              <p class="text--FC4A4A font-14 font-weight-bold ma-0">
                سيتم إلغاء جميع السندات المرتبطه بهذا السند
              </p>
            </span>
          </div>
        </div>
        <div v-if="relatedBonds.length">
          <p class="text--101010 font-14 font-weight-medium mx-0 mt-2 mb-4">
            السندات المرتبطة بهذا السند
          </p>
        </div>
        <div
          class="bond-border d-flex justify-between align-center px-6 py-5 my-2"
          v-for="(bond, i) in relatedBonds"
          :key="i"
        >
          <div class="my-auto">
            <p class="text--101010 font-14 font-weight-bold ma-0">
              {{ bond.number }}
            </p>
          </div>
          <div
            v-if="bond?.bondType?.nameAr"
            class="bg--662d911a rounded-pill px-3 py-1"
          >
            <p class="text--662D91 font-14 font-weight-bold ma-0">
              {{ bond.bondType.nameAr }}
            </p>
          </div>
          <div class="my-auto">
            <p class="text--101010 font-14 font-weight-bold ma-0">
              {{ bond?.totalAmount }}
            </p>
          </div>
          <div class="my-auto">
            <p class="text--101010 font-14 font-weight-bold ma-0">
              {{ formatDate(bond.date) }}
            </p>
          </div>
          <div
            :class="
              bond?.terminated.isTerminated ? 'bg--fc4a4a1a' : 'bg--65C46721'
            "
            class="rounded-pill px-3 py-1 d-flex"
          >
            <v-sheet
              class="dotted my-auto mx-0 pa-0 ml-2"
              :color="bond?.terminated.isTerminated ? '#FC4A4A' : '#65C467'"
            />
            <p
              class="font-14 font-weight-bold ma-0"
              :class="
                bond?.terminated.isTerminated ? 'text--FC4A4A' : 'text--65C467'
              "
            >
              {{ bond?.terminated.isTerminated ? 'ملغي' : 'ساري' }}
            </p>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="pa-4 d-flex align-center justify-space-between">
        <v-spacer />
        <v-btn
          color="#9F9FAC"
          class="ma-0 rounded-15 font-14 font-weight-bold mx-3"
          tonal
          width="135px"
          @click="dialogState_ = false"
        >
          <span class="mb-1">إلغاء</span>
        </v-btn>
        <v-btn
          color="#FC4A4A"
          class="ma-0 rounded-15 font-14 font-weight-bold"
          tonal
          width="135px"
          :loading="cancelBtnLoading"
          @click="$emit('deleteBond')"
        >
          <span class="mb-1">تأكيد</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'cancelBondModel',
  mixins: [dateFormat],
  props: {
    dialogState: {
      default: false
    },
    cancelBtnLoading: {
      default: false
    },
    relatedBonds: {
      default() {
        return []
      }
    }
  },
  data() {
    return {
      exclamation: require('@/assets/img/svgs/exclamationRedMark.svg')
    }
  },
  computed: {
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(val) {
        this.$emit('changeDialogState', val)
      }
    }
  }
}
</script>
<style lang="scss">
.bond-border {
  border: 1px solid #e8e8e8;
  border-radius: 15px;
}
</style>
