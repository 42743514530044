<template>
  <v-container :class="{ userView: isCustomer }">
    <tabTableDetails
      v-bind="tabTableDetailsProps"
      @addAction="addAttachment"
      @preview="previewAttach"
      @download="downloadAttach"
      @delete="deleteAttach"
    />
    <AttachmentsModal
      :dialogState="dialogState"
      :attachTypes="[
        'إشعار دفع',
        'تحويل بنكي',
        'شيك مصرفي',
        'تحويل بنكي - منصة إيجار',
        'اخري'
      ]"
      @close-dialog="dialogState = false"
      :isLoading="isLoading"
      v-on="$listeners"
    />
  </v-container>
</template>

<script>
import tabTableDetails from '@/components/contracts/SideDetails/tabTableDetails.vue'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'ContractAttachments',
  components: {
    tabTableDetails,
    AttachmentsModal: () =>
      import('@/components/contracts/SideDetails/AttachmentsModal.vue')
  },
  props: {
    bond: {
      type: Object,
      required: true
    },
    bondAttachments: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogState: false
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    fileListProps() {
      return {
        files: this.bondAttachments,
        showDocuments: true,
        fromSideDetails: true,
        icon: {
          src: require('@/assets/img/svgs/pdf-file-icon.svg'),
          width: 25,
          height: 25
        }
      }
    },
    tabTableDetailsProps() {
      return {
        items: this.bondAttachments,
        title: 'المرفقات',
        headers: [
          {
            text: 'نوع المرفق',
            value: 'attachmentType',
            sortable: false,
            align: 'start'
          },
          {
            text: 'بواسطة',
            value: 'createdBy',
            sortable: false,
            align: 'start'
          },
          {
            text: 'تاريخ المرفق',
            value: 'createdAt',
            sortable: false,
            align: 'start'
          },

          {
            text: 'رقم',
            value: 'number',
            sortable: false,
            align: 'start'
          },
          {
            text: 'عرض',
            value: 'preview',
            sortable: false,
            align: 'left',
            width: 20
          },
          {
            text: 'تحميل',
            value: 'download',
            sortable: false,
            align: 'left',
            width: 20
          },
          {
            text: 'حذف',
            value: 'delete',
            sortable: false,
            align: 'left',
            width: 20
          }
        ],
        noDate: {
          text: 'ليس هناك مرفقات مضافة حتى الآن',
          icon: require('@/assets/img/no-attachment.png'),
          widthHeight: '100'
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    addAttachment() {
      this.dialogState = true
    },
    previewAttach(item) {
      // open the file
      window.open(item.url, '_blank')
    },
    async downloadAttach(item) {
      // download the file
      try {
        const response = await fetch(item.url, { mode: 'cors' })
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', item.description)

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(link.href)
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    deleteAttach(item, index) {
      this.$root.$confirm
        .open({
          title: 'هل أنت متأكد من حذف المرفق',
          message: 'سيتم حذف المرفق نهائياّ من النظام',
          options: {
            color: 'danger',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            this.$emit('delete-document', { ...item, fileIndex: index })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
