var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',{staticClass:"pa-0"},[_c('dropdown-alt',{staticClass:"mt-3",attrs:{"configrations":{ name: 'بيانات مالك العقار المكلف ', cols: 6 },"listingType":_vm.clientType,"validate":_vm.validate,"defaultValue":"عميل مسجل ومضاف"},on:{"filterTypes":function($event){return _vm.switchOwnerState($event)}}}),(!_vm.isNewOwner)?_c('v-col',{staticClass:"pb-1 mobile__p__0",attrs:{"cols":"12","sm":"12"}},[_c('h5',{staticClass:"mb-2 font-14",class:{ 'red--flag': _vm.validateForm ? !_vm.isAddedOwnerValid : false }},[_vm._v("\n          اختر العميل*\n        ")]),_c('autocomplete',{attrs:{"itemData":true,"items":_vm.AllUsers,"noData":_vm.noData,"placeholder":_vm.selectedRelestatePlaceholder,"validateString":_vm.validateString,"returnObject":true,"loading":_vm.clientIsLoading},on:{"nextPageTrigger":_vm.getNextData,"emitSearchKey":_vm.getDataBySearchKey,"input":_vm.checkClientData},model:{value:(_vm.addTaxInfo.owner),callback:function ($$v) {_vm.$set(_vm.addTaxInfo, "owner", $$v)},expression:"addTaxInfo.owner"}}),(_vm.addTaxInfo.owner && _vm.isTaxOrIdMissing)?_c('v-form',[_c('v-row',[_c('v-col',{staticClass:"pl-3",attrs:{"cols":"12","sm":"6"}},[_c('h6',{class:[
                  'mb-2',
                  'font-12',
                  { 'red--flag': !_vm.addTaxInfo.owner.taxNumber }
                ]},[_vm._v("\n                الرقم الضريبي للمالك*\n              ")]),_c('v-text-field',{attrs:{"placeholder":"الرقم الضريبي","dense":"","filled":"","hide-details":"","min":"0"},on:{"keydown":_vm.$formatNumberInputs,"input":function($event){return _vm.formatArabicNumber($event, 'taxNumber')}},model:{value:(_vm.localTaxNumber),callback:function ($$v) {_vm.localTaxNumber=$$v},expression:"localTaxNumber"}})],1),(_vm.typeCheck)?_c('v-col',{staticClass:"pl-3",attrs:{"cols":"12","sm":"6"}},[_c('h6',{class:[
                  'mb-2',
                  'font-12',
                  { 'red--flag': !_vm.addTaxInfo.owner.idNumber }
                ]},[_vm._v("\n                "+_vm._s(_vm.typeCheck)+"*\n              ")]),_c('v-text-field',{attrs:{"placeholder":_vm.typeCheck,"dense":"","filled":"","hide-details":"","min":"0"},on:{"keydown":_vm.$formatNumberInputs,"input":function($event){return _vm.formatArabicNumber($event, 'idNumber')}},model:{value:(_vm.localIdNumber),callback:function ($$v) {_vm.localIdNumber=$$v},expression:"localIdNumber"}})],1):_vm._e()],1)],1):_vm._e()],1):_vm._e(),(_vm.isNewOwner)?_c('v-row',[_c('v-col',[_c('NewAgentForm',{attrs:{"index":0,"isAqarForm":true,"isDisabled":false,"phoneNumberSuccessMesage":_vm.phoneNumberSuccessMesage,"phoneNumberErrorMessage":_vm.phoneNumberErrorMessage,"checkPhoneNumberLoader":_vm.checkPhoneNumberLoader,"isValidPhone":_vm.isValidPhone,"isOptionalInfoRequired":true},on:{"checkUserExistance":_vm.checkUserExistance,"onValidatePhoneNumber":_vm.onValidatePhoneNumber,"new_user_added":_vm.newUserAdded}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"pa-1 mt-4 d-flex align-center justify-space-between"},[_c('v-btn',{staticClass:"ma-0 rounded-7 my-3 ml-3",attrs:{"color":"primary"},on:{"click":_vm.previousTab}},[_c('v-icon',{staticClass:"send-icon font-16"},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"mobile__mb__0 ml-1 font-weight-bold font-12"},[_vm._v("\n        السابق\n      ")])],1),_c('v-btn',{staticClass:"ma-0 rounded-7 my-3 ml-3",attrs:{"loading":_vm.isUpdateLoading,"color":"primary"},on:{"click":_vm.nextTab}},[_c('span',{staticClass:"mobile__mb__0 ml-1 font-weight-bold font-12"},[_vm._v("\n        التالي\n      ")]),_c('v-icon',{staticClass:"send-icon font-16"},[_vm._v("mdi-arrow-right")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }