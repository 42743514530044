<template>
  <div>
    <div class="mb-5 d-flex justify-space-between align-center">
      <div class="d-flex flex-column flex-md-row" style="max-width: 100%">
        <v-card
          v-if="!statusRestore && !isCustomer"
          class="d-flex px-1 ml-4 mb-2 mb-md-0"
        >
          <v-icon
            v-if="isFilterLength"
            @click="scroll('right')"
            id="forward"
            class="cursor"
            >mdi-arrow-right</v-icon
          >
          <div id="filterCard" class="filterCard d-flex px-2">
            <h6
              :class="{ 'border-black': activeFilter == filter._id }"
              class="cursor mx-3 d-flex"
              v-for="(filter, i) in filterTypes"
              @click="handleFilterChange({ filterId: filter._id })"
              :key="i"
            >
              <h6 class="ma-0 text-truncate">
                {{ filter.nameAr }}
              </h6>
              <span v-if="filter.count" class="propCounter px-1 mr-1 font-12">
                {{ filter.count }}
              </span>
            </h6>
          </div>
          <v-icon
            v-if="isFilterLength"
            @click="scroll('left')"
            id="backward"
            class="cursor"
            >mdi-arrow-left</v-icon
          >
        </v-card>

        <v-card
          v-if="isCustomer"
          class="d-flex justify-space-between align-center py-2 px-3"
          width="150"
          @click="openDatePicker"
        >
          <p class="mb-0 font-14">تاريخ الإنشاء</p>
          <img alt="calendar icon" :src="calendarIcon" />

          <DatePicker
            ref="datePicker"
            class="range--card__date-picker_filter"
            :datePickerStyle="sm ? { left: 'auto', right: '10px' } : {}"
            :range="false"
            @change="handleFilterChange({ date: $event })"
          />
        </v-card>

        <!-- For multiple selections -->
        <slot />
      </div>
      <v-spacer />
      <div class="pa-0 d-none d-md-flex" v-if="statusViewCategory">
        <h6 class="font-weight-light ml-2">ترتيب بواسطة</h6>
        <v-menu
          content-class="vMenuFilter elevation-0"
          offset-y
          transition="slide-y-transition"
          width="150"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center">
              <v-slide-x-transition>
                <v-card
                  v-on="on"
                  v-bind="attrs"
                  class="d-flex justify-space-between px-2"
                  width="110"
                >
                  <h6 class="mr-2">
                    {{ filterQuery.sort.nameAr }}
                  </h6>
                  <v-icon
                    >{{
                      showSortOptions ? 'mdi-chevron-up' : 'mdi-chevron-down'
                    }}
                  </v-icon>
                </v-card>
              </v-slide-x-transition>
            </div>
          </template>
          <v-list
            class="contractVmenuList pa-0"
            v-for="(option, i) in sortOptions"
            :key="i"
            :width="110"
          >
            <v-list-item-group>
              <v-list-item class="px-1">
                <v-list-item-title
                  @click="handleFilterChange({ sort: option })"
                  class="font-14 mr-1 mb-2 font-weight-medium t"
                >
                  {{ option.nameAr }}
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from '../core/DatePickerRage.vue'

export default {
  name: 'ReportsFilter',
  props: {
    filterTypes: {
      default: []
    },
    statusViewCategory: {
      type: Boolean,
      default: false
    }
  },
  components: { DatePicker },
  data() {
    return {
      showSortOptions: false,
      activeFilter: '',
      filterQuery: {
        type: '',
        sort: {
          nameAr: 'الأحدث',
          nameEn: '-1'
        },
        createdAt: ''
      },
      sortOptions: [
        { nameAr: 'الأحدث', nameEn: '-1' },
        { nameAr: 'الأقدم', nameEn: '1' }
      ],
      calendarIcon: require('@/assets/img/svgs/calendar-filter.svg'),
      isDatePickerOpened: false
    }
  },
  methods: {
    sideScroll(element, direction, speed, distance, step) {
      let scrollAmount = 0
      const slideTimer = setInterval(() => {
        if (direction === 'left') {
          element.scrollLeft -= step
        } else {
          element.scrollLeft += step
        }
        scrollAmount += step
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer)
        }
      }, speed)
    },
    scroll(direction) {
      // eslint-disable-next-line no-undef
      this.sideScroll(filterCard, direction, 25, 100, 10)
    },
    handleFilterChange({
      sort = this.filterQuery.sort,
      filterId = this.filterQuery.type,
      date = ''
    }) {
      this.activeFilter = filterId
      this.filterQuery.type = filterId ?? ''
      this.filterQuery.sort = sort
      // handle date filter
      if (this.isCustomer && this.filterQuery.createdAt === date) {
        this.filterQuery.createdAt = ''
        this.$refs.datePicker.time = ''
      } else {
        this.filterQuery.createdAt = date
      }
      this.$emit('handleFilterChange', this.filterQuery)
    },
    openDatePicker() {
      this.$refs.datePicker.$children[0].openPopup()
    }
  },
  watch: {
    statusViewCategory() {
      this.activeFilter = ''
    }
  },
  computed: {
    statusRestore() {
      return this.$parent.statusRestore
    },
    isFilterLength() {
      return this.filterTypes.length > 6
    },
    ...mapGetters('accountState', ['isCustomer']),
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>
<style lang="scss" scoped>
.filterCard {
  max-width: 600px;
  overflow-x: scroll;
}
::-webkit-scrollbar {
  display: none;
}
</style>
