<template>
  <div>
    <v-layout column class="requestsDataContainer">
      <v-flex class="requestsDataHolder">
        <v-data-table
          :headers="RestructureHeaders"
          :items="expenses"
          class="elevation-0 payRequest-table"
          hide-default-footer
        >
          <template v-slot:[`item.type`]="{ item }">
            <span>{{ generateItemTypeName(item) }}</span>
          </template>
          <template v-slot:[`item.taxAmount`]="{ item }">
            <span>{{ item.taxAmount || 0 }}</span>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <span>{{ item.value || 0 }}</span>
          </template>
          <template v-slot:[`item.attachments`]="{ item, index }">
            <div v-if="!item.attachment && !item.progress">
              <img
                @click="openExpenseInput(index)"
                class="ml-1 cursor"
                width="20"
                :src="uploadIcon"
              />
              <form
                v-show="false"
                class="mx-auto"
                enctype="multipart/form-data"
              >
                <v-file-input
                  :ref="`expenseAttachmentInput${index}`"
                  truncate-length="15"
                  prepend-icon="mdi-file-upload"
                  hide-input
                  @change="uploadExpenseAttachment($event, item, index)"
                ></v-file-input>
              </form>
            </div>
            <div v-else-if="!item.attachment && item.progress">
              <v-progress-circular
                :rotate="360"
                :size="33"
                :width="1"
                :value="item.progress"
                color="green"
              >
                <span class="font-12"> %{{ item.progress.toFixed(0) }}</span>
              </v-progress-circular>
            </div>
            <div
              v-else
              class="d-flex justify-center"
              @mouseleave="togglePdfActions({ show: false })"
            >
              <a :href="item.attachment.url" target="blank">
                <v-img
                  @mouseover="togglePdfActions({ item, show: true })"
                  max-width="25"
                  :src="pdfIcon"
                />
              </a>
              <transition name="fade" mode="out-in">
                <div class="d-flex" v-if="activeRequestId === item.id">
                  <img
                    class="cursor mr-4"
                    width="20"
                    :src="deleteIcon"
                    @click="deleteExpenseAttachment(item, index)"
                  />
                </div>
              </transition>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item, index }">
            <v-img
              class="mx-auto"
              width="20"
              :src="deleteIcon"
              @click="deleteExpense(item, index)"
            />
          </template>
          <template v-slot:[`item.exchangeBond`]="{ item }">
            <div
              v-if="!item.bond"
              class="d-flex align-center cursor"
              @click="openAddBondModal(item.id)"
            >
              <v-btn
                class="add-btn add__property__btn px-1 py-3 mx-1"
                depressed
                x-small
              >
                <v-icon color="purple">mdi-plus</v-icon>
              </v-btn>
              <span class="font-12 bond-text">إضافة سند</span>
            </div>
            <div
              v-else
              class="d-flex align-center cursor"
              @click="showBondDetails(item.bond)"
            >
              <v-btn
                class="add-btn add__property__btn px-1 py-3 mx-1"
                depressed
                x-small
              >
                <v-icon color="purple">mdi-eye-outline</v-icon>
              </v-btn>

              <span class="mr-2 font-12 primary--text">عرض السند</span>
            </div>
          </template>
          <template v-slot:no-data>
            <span>لا يوجد طلبات صرف مضافة حتى الآن</span>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <div class="px-12" v-if="hasUpdatePermission">
      <!-- Button -->
      <v-btn class="mb-2" icon @click="$emit('openReqModal', true)">
        <h5 class="brub--tag text-center border-radius-7 py-2 px-3 d-flex">
          <v-icon>mdi-plus</v-icon>
          <h5 class="font-weight-bold font-14 ma-0">طلب جديد</h5>
        </h5>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { contractsService } from '@/services'

export default {
  name: 'payRequestsTable',
  props: {
    hasUpdatePermission: {
      default: true,
      type: Boolean
    },
    expenses: {
      type: Array,
      required: true
    },
    addExpenseStatus: {
      type: String,
      default: ''
    },
    requestId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    activeRequestId: 0,
    requestTypes: [
      { nameAr: 'بند شراء', nameEn: 'purchase' },
      { nameAr: 'أجره عماله', nameEn: 'fees' }
    ],
    isValidFormInputs: false,
    newExpense: {},
    addNewExpenseForm: false,
    pdfIcon: require('@/assets/img/svgs/pdf-file-icon.svg'),
    deleteIcon: require('@/assets/img/svgs/delete-icon-grey.svg'),
    uploadIcon: require('@/assets/img/svgs/uploadIcon.svg'),
    newReqadded: [
      {
        title: '',
        type: '',
        quantity: '',
        total: '',
        price: '',
        bondStatus: ''
      }
    ],
    headers: [
      {
        text: 'النوع',
        value: 'type',
        align: 'center',
        sortable: false,
        width: '100'
      },
      {
        text: 'الكميه',
        value: 'quantity',
        align: 'center',
        sortable: false,
        width: '50'
      },
      {
        text: 'سعر الوحدة',
        value: 'price',
        align: 'center',
        sortable: false,
        width: '100'
      },
      {
        text: 'القيمة',
        value: 'grossTotal',
        align: 'center',
        sortable: false,
        width: '100'
      },
      {
        text: 'الضريبة',
        value: 'taxAmount',
        align: 'center',
        sortable: false,
        width: '50'
      },
      {
        text: 'الإجمالي',
        value: 'total',
        align: 'center',
        sortable: false,
        width: '50'
      },
      {
        text: 'سند الصرف',
        value: 'exchangeBond',
        align: 'start',
        sortable: false,
        width: '125',
        needPermission: true
      },
      {
        text: 'المرفقات',
        value: 'attachments',
        align: 'center',
        sortable: false,
        width: '50',
        needPermission: true
      },
      {
        text: '',
        value: 'actions',
        align: 'center',
        sortable: false,
        needPermission: true
      }
    ],
    addExpenseIsLoading: false,
    isValid: false,
    showDeleteAttachment: false,
    attachment: {
      file: '',
      id: '',
      progress: 0
    },
    showBondIcon: require('@/assets/img/svgs/purple-eye.svg')
  }),
  computed: {
    ...mapState('accountState', ['currentEnv']),
    RestructureHeaders() {
      if (this.hasUpdatePermission) return this.headers
      let data = this.headers.filter((header) => {
        return header.needPermission ? false : true
      })
      return data
    },
    addExpenseHeaders() {
      return this.headers.filter((header) => header.value !== 'exchangeBond')
    }
  },
  watch: {
    addExpenseStatus(val) {
      if (val === 'success') {
        this.addNewExpenseForm = false
        this.addExpenseIsLoading = false
      } else if (val === 'failure') {
        this.addExpenseIsLoading = false
      }
    },
    requestId() {
      this.addNewExpenseForm = false
      this.newExpense = {}
    }
  },
  methods: {
    ...mapMutations('accountState', ['addNotification']),
    togglePdfActions({ item, show }) {
      if (show) {
        this.activeRequestId = item.id
      } else {
        this.activeRequestId = 0
      }
    },
    deleteExpense(expense, expenseIndex) {
      this.$emit('deleteExpense', {
        expenseId: expense.id,
        index: expenseIndex
      })
    },
    generateItemTypeName(expense) {
      return this.requestTypes.find((type) => type.nameEn === expense.type)
        ?.nameAr
    },
    openUploadInput() {
      this.$refs.fileUploaderInput.$refs.input.click()
    },
    openExpenseInput(index) {
      this.$refs[`expenseAttachmentInput${index}`].$refs.input.click()
    },
    addExpenseAttachment(file) {
      this.$set(this.attachment, 'file', file)
      this.uploadExpenseAttachment(file)
    },
    async uploadExpenseAttachment(file, item, index) {
      if (!item) {
        try {
          const fd = new FormData()
          fd.append('attachment', file, file.name)
          fd.append('entityType', 'expense')
          fd.append('envId', this.currentEnv._id)
          const { data } = await contractsService.addAttachment(
            fd,
            this.attachment
          )
          this.$set(this.attachment, 'file', data.Location)
          this.$set(this.attachment, 'id', data.id)
        } catch {
          this.$set(this.attachment, 'file', '')
          this.addNotification({
            text: 'خطأ في تحميل المرفق، يرجى المحاوله مجددا',
            color: 'error'
          })
        }
      } else {
        this.$emit('uploadAttachment', {
          file,
          expense: item,
          fromPayTable: true,
          index
        })
      }
    },
    toggleDeleteAttachment(val) {
      this.showDeleteAttachment = val
    },
    async deleteAttachment() {
      try {
        await contractsService.deleteAttachment(this.attachment.id)
        this.$set(this.attachment, 'progress', 0)
        this.$set(this.attachment, 'file', '')
        this.$set(this.attachment, 'id', '')
      } catch {
        this.addNotification({
          text: 'خطأ في حذف المرفق، يرجى المحاوله مجددا',
          color: 'error'
        })
      }
    },
    deleteExpenseAttachment(item, index) {
      this.$emit('deleteAttachment', {
        fileId: item.attachment._id,
        index,
        fromPayTable: true
      })
    },
    openAddBondModal(requestId) {
      this.$emit('openAddBondModal', requestId)
    },
    showBondDetails(bondData) {
      this.$router.push({
        path: '/dashboard/bonds',
        query: { visible: true, item: bondData }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_sideDetails.scss';
.v-input {
  ::v-deep .v-select__selections {
    padding-right: 10px;
  }
  ::v-deep .v-input__append-inner {
    padding-left: 5px;
    padding-top: 3px !important;
  }
  ::v-deep .v-text-field__slot {
    padding-right: 10px !important;
  }
  ::v-deep .v-select__slot {
    padding-top: 5px !important;
  }
}
.v-btn.add-btn {
  background: rgba($primary-color, 0.1) !important;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.03);
  border-radius: 7px !important;
  &.add__property__btn {
    height: 20px;
    min-width: 24px;
    &:hover {
      background: $primary-color !important;
      i {
        color: #fff !important;
      }
    }
    ::v-deep {
      .v-btn__content {
        i {
          font-size: 17px !important;
        }
      }
    }
  }
}
.bond-text {
  color: #662d91;
  margin-right: 6px;
}
.view-icon-container {
  width: 24px;
  height: 24px;
  border-radius: 7px;
  background-color: #eee8f2;
}
</style>
