import {
  bondsService,
  contractsService,
  taxBillsService,
  reportService
} from '@/services'

const popUpBodyTemplates = [
  {
    title: 'هل أنت متأكد من القبول؟',
    confirmTxt: 'تأكيد',
    status: 'approved',
    img: {
      path: require('@/assets/img/svgs/warning.svg'),
      width: 70,
      height: 70
    },
    options: {
      color: 'green'
    }
  },
  {
    title: 'هل أنت متأكد من رفض العملية؟',
    message: 'سبب رفض العمليه',
    status: 'rejected',
    input: 'RejectResonApproval',
    confirmTxt: 'رفض',
    options: {
      color: 'red'
    }
  },
  {
    title: 'هل أنت متأكد من إلغاء العملية؟',
    confirmTxt: 'تأكيد',
    status: 'cancelled',
    options: {
      color: 'red'
    }
  },
  {
    title: 'هذه العملية تحتاج للموافقة',
    message: 'يمكنك متابعة حالة الموافقة من خلال قائمة طلبات الموافقات',
    status: 'inform approval required',
    confirmTxt: 'حسناّ',
    cancelText: 'طلبات الموافقات',
    img: {
      path: require('@/assets/img/svgs/hourglass.svg'),
      width: 60,
      height: 60
    },
    options: {
      color: 'primary'
    }
  }
]

const actions = [
  {
    nameAr: 'إضافة',
    nameEn: 'add'
  },
  {
    nameAr: 'إعتماد',
    nameEn: 'approve',
    access: ['contracts']
  },
  {
    nameAr: 'تجديد',
    nameEn: 'renew',
    access: ['contracts']
  },
  {
    nameAr: 'تأكيد التجديد',
    nameEn: 'confirmRenew',
    access: ['contracts']
  },
  {
    nameAr: 'إلغاء',
    nameEn: 'cancel',
    access: ['contracts', 'quotations']
  },
  {
    nameAr: 'إنهاء',
    nameEn: 'terminate',
    access: ['contracts']
  },
  {
    nameAr: 'إلغاء',
    nameEn: 'delete',
    access: ['bonds', 'taxInvoices']
  },
  {
    nameAr: 'حذف',
    nameEn: 'delete',
    access: ['reports']
  },
  {
    nameAr: 'تعديل',
    nameEn: 'update',
    access: ['taxInvoices']
  },
  {
    nameAr: 'تحويل لسارية',
    nameEn: 'confirm',
    access: ['taxInvoices']
  }
]
const processesTypes = [
  {
    nameAr: 'بالتوازي',
    nameEn: 'parallel',
    desc: 'يتم إرسال كل الموافقات لكل المستويات فى نفس الوقت'
  },
  {
    nameAr: 'بالتوالي',
    nameEn: 'sequential',
    desc: 'يجب أن يوافق المستوى الأول لينتقل الطلب للثانى ثم الثالث'
  }
]
const approvalLevels = [
  { title: '1 مستوى الموافقه', level: 1 },
  { title: '2 مستويات الموافقه', level: 2 },
  { title: '3 مستويات الموافقه', level: 3 },
  { title: '4 مستويات الموافقه', level: 4 }
]
const localUseres = {
  user1: '',
  user2: '',
  user3: '',
  user4: ''
}
const localJobTitle = {
  title1: '',
  title2: '',
  title3: '',
  title4: ''
}
//  ApprovalModal.vue
const ApprovalModal = {
  actions,
  localUseres,
  localJobTitle,
  approvalLevels,
  processesTypes
}

const aprrovalFilters = {
  status: [
    { code: 2, nameAr: 'في انتظار الموافقة', nameEn: 'pending' },
    { code: 3, nameAr: 'مقبولة', nameEn: 'approved' },
    { code: 4, nameAr: 'مرفوضة', nameEn: 'rejected' },
    { code: 5, nameAr: 'ملغية', nameEn: 'cancelled' }
  ],
  type: [
    { code: 1, nameAr: 'العقود', nameEn: 'contracts' },
    { code: 2, nameAr: 'السندات', nameEn: 'bonds' },
    { code: 3, nameAr: 'الفواتير الضريبية', nameEn: 'taxInvoices' },
    { code: 4, nameAr: 'التقارير', nameEn: 'reports' }
  ],
  action: [
    {
      code: 1,
      nameAr: 'إنشاء',
      nameEn: 'add'
    },
    {
      code: 2,
      nameAr: 'حذف',
      nameEn: 'delete'
    },
    {
      code: 3,
      nameAr: 'تجديد',
      nameEn: 'renew'
    },
    {
      code: 4,
      nameAr: 'تعديل',
      nameEn: 'update'
    },
    {
      code: 5,
      nameAr: 'إعتماد',
      nameEn: 'confirm'
    }
  ]
}
const pagination = {
  pageNumber: 1,
  pagesCount: 0,
  pageSize: 10,
  count: 0
}
const noDataConfig = {
  text: 'ليس هناك  طلبات موافقات بعد',
  subTitle:
    'يمكن عرض طلبات الموافقات في هذه القائمة في حال تم تفعيل خاصية الموافقات من الإعدادات',
  icon: require('@/assets/img/approval/approval.png')
}
const breadcrumbs = [{ text: 'طلبات الموافقات', disabled: true }]
// Approval.vue
const Approval = { breadcrumbs, noDataConfig, aprrovalFilters, pagination }

const ConfigPlaceholder = {
  status: 'حالة الموافقة',
  type: 'القسم',
  action: 'كل العمليات'
}
const defaultConfigPlaceholder = {
  status: 'حالة الموافقة',
  type: 'القسم',
  action: 'كل العمليات'
}
const filters = {
  status: '',
  type: '',
  action: '',
  realEstateName: ''
}
const filtersCards = {
  status: false,
  type: false,
  action: false
}
const filtersQuery = {
  status: '',
  type: '',
  action: '',
  realEstateName: ''
}

// ApprovalFilter.vue
const ApprovalFilter = {
  filters,
  filtersCards,
  filtersQuery,
  ConfigPlaceholder,
  defaultConfigPlaceholder
}

const headers = [
  {
    text: 'إسم العملية',
    value: 'actionName',
    sortable: false,
    align: 'center'
  },
  {
    text: 'القسم',
    value: 'typeName',
    sortable: false,
    align: 'center'
  },
  {
    text: 'إسم العقار',
    value: 'realEstate',
    sortable: false,
    align: 'center'
  },
  {
    text: 'رقم الوحدة',
    value: 'unit',
    sortable: false,
    align: 'center'
  },
  {
    text: 'تاريخ العملية',
    value: 'createdAt',
    sortable: false,
    align: 'center'
  },
  {
    text: 'حالة الموافقه',
    value: 'status',
    sortable: false,
    align: 'center'
  },

  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'left'
  }
]
// ApprovalDataTable.vue
const ApprovalDataTable = {
  headers
}

const transformVersion = function (v) {
  const version = {
    0: 'لا يوجد مستويات موافقات',
    1: 'عدد (1) مستويات موافقات',
    2: 'عدد (2) مستويات موافقات',
    3: 'عدد (3) مستويات موافقات',
    4: 'عدد (4) مستويات موافقات'
  }
  return version[v] || v
}

const setIcon = function (code) {
  const icons = {
    users: require('@/assets/img/svgs/client-active.png'),
    realEstates: require('@/assets/img/svgs/building-active.png'),
    bonds: require('@/assets/img/dashboard-icons/bond-active.png'),
    contracts: require('@/assets/img/dashboard-icons/contract-icon-active.png'),
    reports: require('@/assets/img/reports/3-active.png'),
    quotations: require('@/assets/img/reports/3-active.png'),
    taxInvoices: require('@/assets/img/tax-invoices/taxInvoiceIcon-active.png')
  }
  return icons[code] || code
}

const transformTitle = function (code) {
  const titles = {
    users: 'العملاء',
    realEstates: 'العقارات والوحدات',
    bonds: 'السندات',
    contracts: 'العقود',
    reports: 'التقارير',
    taxInvoices: 'الفواتير الضريبية',
    quotations: 'عروض الأسعار'
  }
  return titles[code] || code
}

const transformResponse = function (data) {
  const transformedData = { ...data }
  transformedData.title = transformTitle(data.code)
  transformedData.body = transformVersion(data.levels)
  transformedData.icon = setIcon(data.code)
  return transformedData
}

const localizeStatus = function (status) {
  const statusList = {
    pending: 'في انتظار الموافقة',
    approved: 'تمت الموافقة',
    rejected: 'تم الرفض',
    cancelled: 'ملغي'
  }
  return statusList[status]
}
const generateStyleClasses = function (status) {
  const statusColors = {
    pending: 'orange',
    approved: 'green',
    rejected: 'red',
    cancelled: 'red'
  }
  return statusColors[status] || 'default'
}
// ApprovalSideDetails.vue
const typeServiceMapping = {
  contracts: contractsService.getSingleContract,
  bonds: bondsService.getSingleBond,
  taxInvoices: taxBillsService.getSingleTaxBill,
  reports: reportService.getSingleReport
}
const loaderProps = {
  numberOfLines: 8,
  laoderClasses: 'my-2 shadow-border-radius pa-0',
  type: 'list-item-avatar-two-line',
  cols: 12
}
const ApprovalSideDetails = {
  loaderProps
}

export {
  typeServiceMapping,
  popUpBodyTemplates,
  transformVersion,
  setIcon,
  transformTitle,
  generateStyleClasses,
  localizeStatus,
  transformResponse,
  Approval,
  ApprovalModal,
  ApprovalDataTable,
  ApprovalSideDetails,
  ApprovalFilter
}
