<template>
  <v-container :class="{ userView: isCustomer }">
    <div v-if="contracts.length">
      <v-data-table
        :headers="headers"
        :items="contracts"
        hide-default-footer
        class="tab-table-details"
      >
        <template v-slot:[`item.type`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h5
              :class="tagColor(item.typeName)"
              class="ma-0 tag-sm text-center py-0"
            >
              <span class="font-12 font-weight-bold my-auto text-truncate">
                {{ item.typeAr }}
              </span>
            </h5>
          </div>
        </template>
        <template v-slot:[`item.start`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h5 class="ma-0 tag-sm text-center py-0">
              <span class="font-12 font-weight-bold my-auto text-truncate">
                {{ formatDate(item.start) }}
              </span>
            </h5>
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div class="d-flex align-center">
            <div
              :class="[
                'tabel-tag text-center d-flex align-center justify-center',
                statusTagColorContract(item)
              ]"
            >
              <h6 class="d-flex align-center font-weight-bold ma-0 font-12">
                <v-sheet
                  class="dotted ma-0 pa-0 ml-2"
                  :color="statusColorContract(item)"
                ></v-sheet>
                <span> {{ statusNameArContract(item) }}</span>
              </h6>
            </div>
          </div>
        </template>
        <template v-slot:[`item.moderator`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <div class="d-flex flex-column">
              <div class="d-flex align-centerl">
                <h6
                  class="red--tag bill-md text-center font-12 ma-0 ml-2 text-truncate"
                >
                  {{ moderatorNameSplit(item) }}
                </h6>
                <h5 class="ma-0 font-weight-medium text-truncate">
                  {{ item?.createdBy?.name }}
                </h5>
              </div>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>

    <NoData v-else v-bind="noDataConfig" />
  </v-container>
</template>

<script>
import NoData from '@/components/helper/noData.vue'
import contractsSharedData from '@/mixins/contractsSharedData'
import dateFormat from '@/mixins/dateFormat.js'
import offerSharedData from '@/mixins/offerSharedData'
import { realEstateService } from '@/services'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ContractsDetails',
  mixins: [dateFormat, contractsSharedData, offerSharedData],
  props: {
    quotationId: {
      type: String,
      required: true
    }
  },
  components: {
    NoData
  },
  data() {
    return {
      contracts: [],
      headers: [
        {
          text: 'رقم العقد',
          sortable: false,
          value: 'number',
          align: 'center'
        },
        {
          text: 'عنوان العقد',
          sortable: false,
          value: 'title',
          align: 'center'
        },
        {
          text: 'نوع العقد',
          sortable: false,
          value: 'type',
          align: 'center'
        },
        {
          text: 'تاريخ البداية',
          sortable: false,
          value: 'start',
          align: 'center'
        },
        {
          text: 'بواسطة',
          sortable: false,
          value: 'moderator',
          align: 'center'
        },
        {
          text: 'حالة العقد',
          sortable: false,
          value: 'status',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    noDataConfig() {
      return {
        noDataText: 'سيتم إضافة العقد بصيغة مسودة بعد موافقة العميل على العرض',
        noDataSubTitle: '',
        isbgPrimary: false,
        noDataIcon: require('@/assets/img/dashboard-icons/contract-icon-active.png')
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    async getAllContracts() {
      try {
        const { data } = await realEstateService.getContractQuotation({
          environment: this.currentEnv._id,
          quotation: this.quotationId
        })
        this.contracts = data.contracts
      } catch {
        this.addNotification({
          text: 'حدثت مشكلة في العقود',
          color: 'error'
        })
      }
    }
  },
  created() {
    this.getAllContracts()
  }
}
</script>
<style>
.tab-table-details table thead tr th span {
  font-size: 16px !important;
  color: #b2b2b2;
}
.container.userView {
  min-width: 100%;
  margin-right: 0 !important;
}
</style>
