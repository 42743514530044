<template>
  <div class="show-detailes pdf-summary-container">
    <v-slide-x-transition>
      <v-layout>
        <v-col :cols="4" @click="$emit('closePdf')" class=""></v-col>
        <v-col :cols="4" class="whiteSide pa-0">
          <pdfHeader
            class="mt-1 mx-4"
            ref="pdfHeader"
            v-bind="{ showSigntures: !isCustomer, viewSharedPdf, signatures }"
            @closeDetails="$emit('closePdf')"
            @updateSignatures="Signatures = $event"
            @downloadPdf="downloadPdf"
          />
          <v-divider class="mt-3"></v-divider>
          <v-layout class="d-flex w-100" id="toBePrint">
            <div class="w-100">
              <div class="mx-2" v-if="toBePrint">
                <div class="toBePrintContentBond">
                  <div class="px-3 d-flex justify-space-between align-center">
                    <div class="my-5">
                      <h3 class="ma-0 my-2 font-18 font-weight-bold">
                        {{
                          `سند ${toBePrint.bondType.nameAr}  Bond ${toBePrint.bondType.nameEn}`
                        }}
                      </h3>
                      <h6
                        v-if="bondNumber"
                        class="ma-0 my-2 font-12 font-weight-bold"
                      >
                        {{ bondNumber }}
                      </h6>
                      <h6
                        v-if="bondDate('ar-EG')"
                        class="ma-0 my-2 font-12 grey--text font-weight-bold"
                      >
                        <span class="ml-1">
                          {{ bondDate('ar-EG') }}
                        </span>
                        <span>
                          {{ bondDate('en-US') }}
                        </span>
                      </h6>
                    </div>
                    <v-img
                      v-if="pdfMedia && pdfMedia.companyLogo"
                      :max-width="110"
                      :src="pdfMedia.companyLogo.location"
                    ></v-img>

                    <router-link
                      v-else-if="!isCustomer"
                      to="/dashboard/edit-profile/2"
                    >
                      <div class="dashedBg pointerCursor">
                        <div>
                          <p class="font-14 ma-0 text-center my-1 black--text">
                            يمكنك وضع
                          </p>
                          <p class="font-14 ma-0 text-center my-1 black--text">
                            الشعار هنا
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <v-divider></v-divider>
                  <v-row class="mt-5 bond-data-container px-5" no-gutters>
                    <v-col lg="4" md="6" sm="6" cols="6" v-if="bondRealestate">
                      <h6 class="text-light ma-0 font-12 text-capitalize">
                        {{ receiptOrPay }}
                      </h6>
                      <h5 class="ma-0 font-weight-medium line__height__fixes">
                        {{ toBePrint.thirdParty ?? '-' }}
                      </h5>
                    </v-col>
                    <v-col lg="4" md="6" sm="6" cols="6" v-if="bondRealestate">
                      <h6 class="text-light ma-0 font-12 text-capitalize">
                        العقار Property
                      </h6>
                      <h5 class="ma-0 font-weight-medium line__height__fixes">
                        {{ bondRealestate }}
                      </h5>
                    </v-col>
                    <v-col lg="4" md="6" sm="6" cols="6" v-if="bondUnit">
                      <h6 class="text-light ma-0 font-12">رقم الوحدة</h6>
                      <h5 class="ma-0 font-weight-medium line__height__fixes">
                        {{ bondUnit }}
                      </h5>
                    </v-col>
                    <v-col
                      lg="3"
                      md="6"
                      sm="6"
                      cols="6"
                      v-if="toBePrint.ownerName || toBePrint?.owner?.name"
                    >
                      <h6 class="text-light ma-0 font-12 text-capitalize">
                        المالك Owner
                      </h6>
                      <h5 class="ma-0 font-weight-medium line__height__fixes">
                        {{ toBePrint.ownerName || toBePrint?.owner?.name }}
                      </h5>
                    </v-col>
                    <v-col
                      lg="3"
                      md="6"
                      sm="6"
                      cols="6"
                      v-if="toBePrint?.renterName || toBePrint?.client?.name"
                    >
                      <h6 class="text-light ma-0 font-12 text-capitalize">
                        المستاجر Tenant
                      </h6>
                      <h5 class="ma-0 font-weight-medium line__height__fixes">
                        {{ toBePrint.renterName || toBePrint?.client?.name }}
                      </h5>
                    </v-col>
                  </v-row>
                  <v-col class="my-4" v-if="toBePrint.description">
                    <h6 class="text-light ma-0 font-12 text-capitalize">
                      الوصف Description
                    </h6>
                    <h5 class="ma-0 font-weight-medium">
                      {{ toBePrint.description }}
                    </h5>
                  </v-col>

                  <v-divider></v-divider>

                  <div class="px-5">
                    <v-data-table
                      :headers="headers"
                      :items="bonsSubtypes"
                      class="bond-table mt-7"
                      hide-default-footer
                      mobile-breakpoint="0"
                    />
                    <div class="mt-10">
                      <div class="after_table_div after__table__block">
                        <div class="after_table_content">
                          <div
                            class="transaction_detail pdf after__table__details__block"
                          >
                            <div
                              class="d-block result__total align-items-center"
                            >
                              <div>
                                <div>
                                  <div class="rest-bg">
                                    <div class="d-flex mb-1">
                                      <h6
                                        class="w-100 fw-500 text-h5 mt-8 mb-5 mw-24"
                                      >
                                        {{
                                          toBePrint.amountInWords || 'غير متوفر'
                                        }}
                                        {{
                                          toBePrint.amountInWordsEn ??
                                          'غير متوفر'
                                        }}
                                      </h6>
                                    </div>
                                    <div
                                      class="d-flex mb-1"
                                      v-if="toBePrint?.paymentMethod"
                                    >
                                      <h6
                                        class="w-100 fw-500 text-h5 mt-8 mb-5 mw-24"
                                      >
                                        {{ toBePrint?.payMethod?.nameAr }}
                                        {{ toBePrint?.payMethod?.nameEn }}
                                      </h6>
                                    </div>
                                    <div
                                      class="d-flex mb-1"
                                      v-if="getBondType && toBePrint.thirdParty"
                                    >
                                      <h6
                                        class="fw-400 mt-8 mb-5 mw-100 text-h5"
                                      >
                                        {{ getProviderName }}
                                        <span class="mr-1">
                                          {{
                                            toBePrint.thirdParty || 'غير متوفر'
                                          }}
                                        </span>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="footer">
                    <div class="bond_details px-8">
                      <div
                        class="bond_details__content d-flex justify-space-between mb-10"
                      >
                        <template v-for="(signtaure, i) in Signatures">
                          <div
                            v-if="signtaure.status && signtauresImages[i]"
                            :key="i"
                          >
                            <p class="signatureTtile color__title">
                              {{ signtaure.name }}
                            </p>
                            <img
                              style="max-height: 100px; max-width: 100px"
                              v-if="signtauresImages[i]"
                              :src="signtauresImages[i]"
                              crossorigin="anonymous"
                            />
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="footer d-flex px-5 align-center py-2">
                  <div class="d-flex">
                    <img
                      :src="hectarPdfText"
                      width="40"
                      class="mx-1"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-layout>
        </v-col>
        <v-col :cols="4" @click="$emit('closePdf')" class=""></v-col>
      </v-layout>
    </v-slide-x-transition>
  </div>
</template>
<script>
import pdfHeader from '@/components/contracts/pdfHeader.vue'
import { mapGetters, mapState } from 'vuex'
import sanitizers from '@/mixins/sanitizers.js'
import dateFormat from '@/mixins/dateFormat.js'
import config from '../../config'

export default {
  name: 'BondPdf',
  mixins: [sanitizers, dateFormat],
  props: {
    toBePrint: {
      type: Object,
      required: true
    },
    viewSharedPdf: {
      type: Boolean,
      default: false
    },
    sharedPdfMedia: {
      type: Object,
      default() {
        return {}
      }
    },
    pdfEnvConfig: {
      type: Object,
      default() {
        return {}
      }
    },
    headers: {
      default() {
        return []
      }
    },
    bonsSubtypes: {
      default() {
        return []
      }
    }
  },
  components: {
    pdfHeader
  },
  data() {
    return {
      signatures: [
        {
          title: 'financialSignature',
          name: 'الإدارة الماليه',
          status: false
        },
        {
          title: 'companySeal',
          name: 'ختم الشركة',
          status: false
        },
        {
          title: 'directorSignature',
          name: 'الإدارة التنفيذية',
          status: false
        }
      ],
      hectarPdfText: require('@/assets/img/svgs/hectarPdfText.svg'),
      Signatures: []
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    ...mapGetters('accountState', ['companyImages', 'isCustomer']),
    isTerminated() {
      return this.toBePrint?.terminated?.isTerminated ?? false
    },
    bondNumber() {
      return `${this.toBePrint?.number} #` ?? ''
    },
    bondDate() {
      return (local) =>
        new Date(this.toBePrint?.date).toLocaleDateString(local, {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
    },
    receiptOrPay() {
      return this.toBePrint.bondType.nameEn === 'receipt'
        ? 'إستلمنا من Received from'
        : 'صرفنا لـ Payed to'
    },
    activeSigntures() {
      return this.signatures.filter((sign) => {
        return sign.status
      })
    },
    getBondType() {
      return this.toBePrint?.bondType?.nameEn
    },
    getProviderName() {
      return this.getBondType === 'exchange'
        ? 'تم صرف قيمة السند لـ'
        : 'تم إستلام قيمة السند من'
    },
    paymentMethodTitle() {
      return this.getBondType === 'exchange' ? 'طريقة الصرف' : 'طريقة الإستلام'
    },
    signtauresImages() {
      return [this.financialSignature, this.companySeal, this.directorSignature]
    },
    financialSignature() {
      return this.pdfMedia?.financialSignature?.location || ''
    },
    directorSignature() {
      return this.pdfMedia?.directorSignature?.location || ''
    },
    companySeal() {
      return this.pdfMedia?.companySeal?.location || ''
    },
    bondRealestate() {
      return this.toBePrint?.realEstate?.propertyName?.split('-')[0]
    },
    bondUnit() {
      return this.toBePrint?.realEstate?.propertyName?.split('-')[1] ?? ''
    },
    getBondEndDate() {
      return this.toBePrint?.installmentsDate?.endDate
        ? this.formatDate(this.toBePrint?.installmentsDate?.endDate)
        : ''
    },
    getBondStartDate() {
      return this.toBePrint?.installmentsDate?.startDate
        ? this.formatDate(this.toBePrint?.installmentsDate?.startDate)
        : ''
    },
    companyName() {
      return this.user?.company || ''
    },
    isReceiptBond() {
      return this.toBePrint.bondType.nameEn === 'receipt'
    },
    titlePartyOne() {
      if (this.isReceiptBond) {
        return this.isFixedAmountBond ? 'اسم المالك' : 'اسم المستأجر'
      }
      return 'اسم الشركة'
    },
    titlePartyTwo() {
      return this.isReceiptBond ? 'اسم الشركة' : 'اسم المالك'
    },
    ownerName() {
      const owner = this.pdfEnvData.owner
      return owner?.userType === 'company'
        ? this.user?.company
        : owner?.company || this.companyName
    },
    ownerNameFromCustomerPortal() {
      const owner = this.pdfEnvData.owner
      return owner?.userType === 'company' || owner?.type === 'company'
        ? owner?.company
        : owner?.name
    },
    envData() {
      return this.pdfEnvData.isOwner
        ? this.ownerName
        : this.ownerNameFromCustomerPortal
    },
    phonePartyOne() {
      return this.isReceiptBond
        ? this.toBePrint?.client?.phoneNumber
        : this.pdfEnvData.owner?.phoneNumber
    },
    phonePartyTwo() {
      return !this.isReceiptBond
        ? this.toBePrint?.client?.phoneNumber
        : this.pdfEnvData.owner?.phoneNumber
    },
    namePartyOne() {
      return this.isReceiptBond ? this.toBePrint?.client?.name : this.envData
    },
    namePartyTwo() {
      return this.isReceiptBond ? this.envData : this.toBePrint?.client?.name
    },
    firstPartyName() {
      return this.toBePrint?.contract?.firstParty?.name
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    },
    isFixedAmountBond() {
      return this.toBePrint?.subType?.code === 'fixedAmount'
    },
    pdfMedia() {
      return this.viewSharedPdf ? this.sharedPdfMedia : this.companyImages
    },
    pdfEnvData() {
      return this.viewSharedPdf ? this.pdfEnvConfig : this.currentEnv
    }
  },
  methods: {
    getPayMethodType() {
      switch (this.toBePrint.paymentMethod) {
        case 'cash':
          return 'نقداً'
        case 'check':
          return 'شيك'
        case 'bankTransfer':
          return 'تحويل بنكي'
        default:
          return 'غير متوفر'
      }
    },
    translateDate(date) {
      return new Date(date).toISOString().substr(0, 10)
    },
    getLogoPath(key) {
      return this.companyImages[key]?.path || ''
    },
    getBondPdfUrl() {
      let url = `${config.bonds}/bonds/pdf/${
        this.toBePrint?._id
      }?logo=${this.getLogoPath('companyLogo')}`
      this.activeSigntures.map((activeSign) => {
        url += `&${activeSign.title}=${this.getLogoPath(activeSign.title)}`
      })
      return url
    },
    downloadPdf() {
      window.open(this.getBondPdfUrl(), '_blank', 'noreferrer')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/pdf/_contractPDF.scss';
.transaction_detail .result__total .rest-bg h6 {
  background-color: #f5f5f5 !important;
  color: #000 !important;
  min-width: fit-content;
  text-transform: none;
}
</style>
