<template>
  <v-card
    class="mainCard mainContractCard pa-4 pb-2 my-3 justify-space-between"
  >
    <v-row no-gutters>
      <v-col lg="3" class="d-flex">
        <div
          @click="showDetails({ ...item })"
          class="cursor d-flex justify-space-between"
        >
          <div class="ml-3 mt-4">
            <v-img :src="contractIcon" alt="contractIcon" />
          </div>
          <div class="min-w-120">
            <h5
              class="mb-2 line__height__1-3 mobile__mw__100 text-truncate max-w-170 min-w-170"
            >
              {{ item.title }}
            </h5>
            <h5
              v-if="parentName"
              class="mb-2 line__height__1-3 mobile__mw__100 text-truncate max-w-170"
            >
              {{ realEstateName }}
            </h5>
            <div class="d-flex">
              <v-layout align-center>
                <h5
                  :class="tagColor(item.typeName)"
                  class="ma-0 tag-sm text-center py-0 pb-1"
                >
                  <span class="font-12 font-weight-bold my-auto text-truncate">
                    {{ contractTypeName }}
                  </span>
                </h5>
              </v-layout>
              <div
                v-if="item.prevContracts.length"
                class="d-flex mt-1 align-center"
              >
                <v-tooltip
                  content-class="v-tool"
                  color="black"
                  min-width="95"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        $emit('showVersions', { visible: true, id: item._id })
                      "
                      class="cursor w-100"
                    >
                      <h5
                        class="ma-0 versions font-weight-medium bill-sm ma-auto d-flex justify-center align-center"
                      >
                        <span class="font-16 font-weight-bold">
                          {{ item.prevContracts.length + 1 }}
                        </span>
                      </h5>
                    </div>
                  </template>
                  <span class="tool--tip">عدد إصدارات العقد</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        lg="1"
        class="d-flex flex-column justify-space-around mobile__ml__15px"
      >
        <h6 class="text-gray ma-0 text-truncate font-12">طرف أول</h6>
        <h5
          v-if="item.typeName == 'rent'"
          class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
        >
          {{ firstPartyName(item) }}
        </h5>
        <h5
          v-else
          class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
        >
          {{ companyName }}
        </h5>

        <h6 class="mt-2 text-gray ma-0 text-truncate font-12">طرف ثاني</h6>
        <h5
          class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
          v-if="item.otherParties"
        >
          {{ otherPartyName(item) }}
        </h5>
      </v-col>
      <v-col
        lg="2"
        class="ms-9 d-flex flex-column justify-space-around mobile__ml__15px"
      >
        <h6 class="text-gray ma-0 text-truncate font-12">عقار</h6>
        <p class="ma-0 font-weight-medium text-truncate max-w-120">
          {{ parentName }}
          {{ realEstateName }}
        </p>
        <template>
          <h6 class="text-gray ma-0 text-truncate font-12">مدة العقد</h6>
          <h5
            class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
          >
            {{ contratDuration(item) ?? '-' }}
          </h5>
        </template>
      </v-col>
      <v-col
        lg="2"
        v-if="notSideView && !getBreakPoint['sm']"
        class="d-flex justify-space-between align-center mobile__ml__15px"
      >
        <div class="d-flex flex-column justify-space-around h-100">
          <h6 class="text-gray mt-1 ma-0 text-truncate font-12">عقد بواسطة</h6>
          <div class="d-flex">
            <h6
              class="red--tag bill-md text-center font-12 ma-0 ml-2 text-truncate"
            >
              {{ moderatorNameSplit(item) }}
            </h6>
            <h5 class="ma-0 font-weight-medium text-truncate">
              {{ moderatorName(item) }}
            </h5>
          </div>
          <div class="d-flex flex-column mt-3">
            <h6 class="text-gray ma-0 text-truncate font-12">تاريخ الانشاء</h6>
            <h5
              class="ma-0 mt-3 mb-1 font-weight-medium text-truncate max-w-120 line__height__fixes"
            >
              {{ formatDate(item.createdAt) }}
            </h5>
          </div>
        </div>
      </v-col>
      <v-col
        lg="1"
        v-if="notSideView && !getBreakPoint['sm']"
        class="d-flex justify-space-between align-center mobile__ml__15px"
      >
        <div class="d-flex flex-column h-100 justify-space-around">
          <template v-if="item.start">
            <h6 class="text-gray ma-0 text-truncate font-12">تاريخ البداية</h6>
            <h5
              class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
            >
              {{ formatDate(item.start) }}
            </h5>
          </template>
          <template v-if="item.end">
            <h6 class="text-gray ma-0 text-truncate font-12">تاريخ النهايه</h6>
            <h5
              class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
            >
              {{ formatDate(item.end) }}
            </h5>
          </template>
        </div>
      </v-col>
      <v-col
        lg="2"
        class="ms-2 d-flex flex-column justify-start align-center mobile__ml__15px"
      >
        <div
          :class="statusTagColor"
          class="px-8 py-1 rounded-pill mt-2 text-center font-12 font-weight-bold d-flex align-center"
        >
          <v-sheet class="dotted ma-0 pa-0 ml-2" :color="statusColor"></v-sheet>
          <span class="mb-1 text-truncate">{{ statusNameAr }}</span>
        </div>
      </v-col>
      <v-col lg="1" class="my-auto" v-if="noEdit">
        <v-menu
          content-class="dropdown-menu"
          transition="slide-x-transition"
          bottom
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="lamb"
              class="options-btn"
              icon
              v-bind="attrs"
              v-on="on"
              :disabled="!validActions.length"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(action, i) in validActions"
              @click="callActionFun({ action, item })"
              :key="i"
            >
              <v-list-item-icon v-if="action.icon">
                <v-icon :color="action?.iconColor">{{ action.icon }}</v-icon>
              </v-list-item-icon>
              <v-img
                v-if="action.img"
                :src="action.img"
                class="mr-1 ml-2"
                loading="lazy"
                max-width="20"
              />
              <v-list-item-content>
                <v-list-item-title :class="action.class">{{
                  action.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <!-- Multiple selections for delete -->
    <slot />
  </v-card>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import contractsSharedData from '@/mixins/contractsSharedData'

export default {
  name: 'ContractCard',
  props: {
    item: {
      type: Object,
      required: true
    },
    noEdit: {
      default: true
    },
    notSideView: {
      default: true
    },
    contractIntervals: {
      default: [],
      required: true
    }
  },
  mixins: [dateFormat, contractsSharedData],
  data() {
    return {
      contractIcon: require('@/assets/img/svgs/bonds.svg')
    }
  },
  computed: {
    validActions() {
      return this.actions.filter((action) => {
        return this.isAvalidAction({ item: this.item, action })
      })
    },
    contractTypeName() {
      switch (this.item.typeName) {
        case 'rent':
          return 'ايجار'
        case 'propertyManagement':
          return 'ادارة املاك'
        case 'maintenance':
          return 'صيانة'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_main-card-list.scss';
</style>
