<template>
  <v-dialog max-width="900px" persistent v-model="dialogState_">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="pa-0 px-5 mb-5 d-flex justify-space-between custom__shadow"
      >
        <div class="d-flex align-center">
          <h4 class="ma-0" v-if="editMood">تعديل سند</h4>
          <h4 class="ma-0" v-else>إضافة سند</h4>
          <v-tooltip
            content-class="v-tool"
            color="black"
            min-width="105"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="cursor">
                <h5 class="ma-0">
                  <span class="font-20 font-weight-bold mr-2">
                    <v-icon class="font-20" color="primary">
                      mdi-information-outline</v-icon
                    >
                  </span>
                </h5>
              </div>
            </template>
            <span class="tool--tip">
              كل الحقول التي تحتوي علي * هي حقول إلزميه</span
            >
          </v-tooltip>
        </div>

        <v-btn
          @click.prevent="closeDialog"
          data-test="close-bond"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Details -->
      <v-card-text
        class="px-5 pb-3 overflow-auto overflow-x-hidden max-height-550"
      >
        <v-form ref="form" v-model="isValid">
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col class="pl-2" cols="2">
                <h6 class="mb-2 font-14">اختر النوع*</h6>
                <v-select
                  :disabled="isDefaultValueClient"
                  color="black"
                  filled
                  v-model="clientTypeSelected"
                  :items="clientTypes"
                  item-text="name"
                  item-value="value"
                  :rules="[(v) => !!v || 'اختر النوع']"
                  placeholder="النوع"
                  required
                  dense
                  hideDetails
                  append-icon="mdi-chevron-down"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
              <v-col class="pl-2" cols="4">
                <h6 class="mb-2 font-14">إسم العميل*</h6>
                <autocomplete
                  :disabled="isDefaultValueClient || !clientTypeSelected.length"
                  ref="autoCompleteClients"
                  v-bind="clientAutoCompleteProps"
                  :value="addBond.client"
                  @nextPageTrigger="getClientNextData"
                  @emitSearchKey="getClientDataBySearchKey"
                  @input="clientChanged"
                  hideDetails
                />
              </v-col>
              <v-col class="pl-2" cols="12" :sm="3">
                <h6 class="mb-2 font-14">إسم العقار*</h6>
                <autocomplete
                  ref="autoCompleteProperties"
                  v-bind="PropertyAutoCompleteProps"
                  v-model="addBond.realEstate"
                  @nextPageTrigger="getPropertyNextData"
                  @emitSearchKey="getPropertyDataBySearchKey"
                  :rules="[(v) => !!v || '']"
                />
              </v-col>
              <v-col class="pl-2" cols="12" :sm="3">
                <h6 class="mb-2 font-14">إسم الوحده*</h6>
                <autocomplete
                  v-bind="unitsAutoCompleteDataProps"
                  :rules="[(v) => !!v || '']"
                  @nextPageTrigger="getUnitNextData"
                  @emitSearchKey="getUnitDataBySearchKey"
                  v-model="addBond.unit"
                />
              </v-col>
              <v-col class="pl-2" cols="12" sm="6">
                <h6 class="mb-2 font-14">نوع السند*</h6>
                <v-select
                  :disabled="isExpenseRequestDefault"
                  ref="bondType"
                  color="black"
                  filled
                  v-model="addBond.bondType"
                  :items="bondsType"
                  item-text="nameAr"
                  item-value="_id"
                  :rules="[(v) => !!v || 'من فضلك اختر نوع السند']"
                  placeholder="نوع السند"
                  required
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
              <v-col class="pl-2" cols="12" sm="6">
                <h6 class="mb-2 font-14">العقد الخاص بالسند*</h6>
                <autocomplete
                  ref="autoCompleteContracts"
                  class="the-contract-dropdown"
                  v-bind="ContractAutoCompleteProps"
                  v-model="addBond.contract"
                  @nextPageTrigger="getContractNextData"
                  @emitSearchKey="getContractDataBySearchKey"
                  hideDetails
                />
              </v-col>
              <v-col class="pl-2" cols="12" sm="6">
                <h6 class="mb-2 font-14">نوع السند الفرعي*</h6>
                <v-select
                  ref="bondSubType"
                  color="black"
                  filled
                  :items="bondsSubTypes"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-value="_id"
                  :rules="[(v) => !!v || 'من فضلك اختر نوع السند الفرعي']"
                  append-icon="mdi-chevron-down"
                  required
                  dense
                  hide-details
                  :disabled="
                    !addBond.bondType ||
                    !addBond.contract ||
                    isExpenseRequestDefault
                  "
                  item-text="nameAr"
                  placeholder="نوع السند الفرعي"
                  v-model="addBond.subType"
                />
              </v-col>
              <v-col class="pl-2" cols="12" sm="6">
                <h6 class="mb-2 font-14">{{ getProviderName }}</h6>
                <v-text-field
                  data-test="property-name"
                  class="mb-0 pa-0 font-14"
                  dense
                  filled
                  hide-details
                  :placeholder="getProviderName"
                  v-model="addBond.thirdParty"
                />
              </v-col>
              <v-col
                class="pl-2"
                cols="12"
                sm="6"
                v-if="selectedBondType && subTypeClauses.length"
              >
                <h6 class="mb-2 font-14">
                  {{ clauseText.title }}*
                  <span class="font-14 text--grey">
                    ({{ clauseText.subTitle }})
                  </span>
                </h6>
                <v-select
                  ref="bondSubType"
                  color="black"
                  filled
                  :items="subTypeClauses"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-value="_id"
                  :rules="[(v) => !!v || 'من فضلك اختر النوع  ']"
                  append-icon="mdi-chevron-down"
                  required
                  dense
                  hide-details
                  item-text="nameAr"
                  :placeholder="clauseText.placeholder"
                  v-model="addBond.reference"
                  return-object
                  @input="handlereceiptChanging"
                ></v-select>
              </v-col>
              <v-col
                v-if="showExpensesRequests"
                class="pl-2 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
              >
                <h6 class="mb-2 font-14">طلبات الصرف</h6>
                <v-select
                  :loading="expensesLoading"
                  filled
                  v-model="addBond.expenses"
                  :items="expenses"
                  item-text="name"
                  item-value="id"
                  placeholder="طلبات الصرف"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  multiple
                  @change="handleBondExpensesRequests($event)"
                >
                  <template v-slot:no-data>
                    <div class="text-center font-14 grey--text">
                      <span>لم يتم تحميل طرق الدفع</span>
                    </div>
                  </template>
                  <v-checkbox
                    @change="handleSelectAll"
                    v-model="selectAllAvailableExpenses"
                    class="mt-1 mb-3 mr-5"
                    slot="prepend-item"
                    hide-details
                  >
                    <template v-slot:label>
                      <span class="black--text mb-1">الكل</span>
                    </template>
                  </v-checkbox>
                </v-select>
              </v-col>
              <v-col class="pl-2 mobile__pl__0 mb-2" cols="12" sm="6">
                <h6 class="mb-2 font-14">تاريخ السند*</h6>
                <v-dialog
                  :return-value.sync="addBond.date"
                  ref="startAtDialog"
                  v-model="startAtModal"
                  width="320px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :rules="[(v) => !!v || 'من فضلك ادخل تاريخ البدايه']"
                      append-icon="mdi-calendar-month-outline"
                      dense
                      filled
                      hide-details
                      placeholder="تاريخ البدايه"
                      readonly
                      required
                      v-bind="attrs"
                      v-model="addBond.date"
                      v-on="on"
                      class="date--dropdown font-14"
                    />
                  </template>
                  <v-date-picker color="primary" scrollable v-model="start">
                    <v-spacer></v-spacer>
                    <v-btn @click="startAtModal = false" color="primary" text>
                      الغاء
                    </v-btn>
                    <v-btn
                      @click="$refs.startAtDialog.save(start)"
                      color="primary"
                      text
                      >حفظ
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-row no-gutters>
                <v-col
                  class="pl-2"
                  cols="12"
                  :md="
                    isTaxIncluded
                      ? 5
                      : showCometionsfield && hasCombinedRent
                      ? 3
                      : 12
                  "
                >
                  <h6 class="mb-2 font-14 d-flex align-center">
                    <span class="flex-shrink-0 ml-1">
                      {{ isRentCombined ? 'مستحقات إيجار' : 'قيمة السند' }}
                    </span>
                    <!-- <span class="font-12 flex-shrink-0"
                      >(يمكنك إستخدام القيمة المقترحة او تعديلها)</span
                    > -->
                  </h6>
                  <v-text-field
                    data-test="bond-value"
                    filled
                    @input="updateTotalValue($event)"
                    :value="bondValueMask"
                    :rules="bondValueRules"
                    :placeholder="
                      isRentCombined ? 'مستحقات إيجار' : 'قيمة السند'
                    "
                    required
                    dense
                    suffix="ريال"
                    hide-details
                    @keydown="$formatNumberInputs($event, true)"
                    :loading="isSuggestedValueLoading"
                    :disabled="isSuggestedValueLoading"
                  />
                </v-col>
                <template v-if="hasCombinedRent">
                  <v-col
                    v-if="showCometionsfield"
                    class="pl-2"
                    cols="3"
                    :md="3"
                  >
                    <h6 class="mb-2 font-14 d-flex align-center">
                      <span class="flex-shrink-0 ml-1">
                        ضريبة مستحقات أيجار
                      </span>
                    </h6>
                    <v-text-field
                      data-test="bond-value"
                      filled
                      :value="rentTaxAmount"
                      placeholder="
                        ضريبة مستحقات أيجار
                      "
                      required
                      dense
                      suffix="ريال"
                      hide-details
                      disabled
                    />
                  </v-col>
                  <v-col v-if="showCometionsfield" class="pl-2" cols="3">
                    <h6 class="mb-2 font-14 d-flex align-center">
                      <span class="flex-shrink-0 ml-1">
                        مستحقات مبالغ ثابتة
                      </span>
                    </h6>
                    <v-text-field
                      data-test="bond-value"
                      filled
                      @input="updateTotalValue($event, 'utilities')"
                      :value="utilitiesMask"
                      placeholder="  مستحقات مبالغ ثابتة"
                      required
                      dense
                      suffix="ريال"
                      hide-details
                      @keydown="
                        $allowOnlyDecimalNumbersCommas(
                          $event,
                          addBond.utilities
                        )
                      "
                      :loading="isSuggestedValueLoading"
                      :disabled="isSuggestedValueLoading"
                    />
                  </v-col>
                  <v-col
                    v-if="showCometionsfield"
                    class="pl-2"
                    cols="3"
                    :md="3"
                  >
                    <h6 class="mb-2 font-14 d-flex align-center">
                      <span class="flex-shrink-0 ml-1">
                        ضريبة مستحقات مبالغ ثابتة
                      </span>
                    </h6>
                    <v-text-field
                      data-test="bond-value"
                      filled
                      :value="totalTaxFixedAmound"
                      placeholder="
                        ضريبة مبالع ثابتة
                      "
                      required
                      dense
                      suffix="ريال"
                      hide-details
                      disabled
                    />
                  </v-col>
                </template>
                <template v-if="isTaxIncluded">
                  <v-col class="pl-2 mb-3" cols="12" md="4">
                    <div class="d-flex justify-space-between">
                      <h6 class="mb-2 font-14">نسبة الضريبة</h6>
                      <span
                        class="px-4 text-center font-12 font-weight-bold my-auto tax-status-tag"
                        :class="bondTax.classes"
                        v-if="isReceiptBond"
                      >
                        {{ bondTax.status }}
                      </span>
                      <v-switch
                        class="my-auto"
                        dense
                        hide-details="auto"
                        inset
                        small
                        @change="updateTaxAmount"
                        :disabled="!addBond.bondValue"
                        v-model="taxAmount"
                      />
                    </div>
                    <v-select
                      :disabled="!taxAmount"
                      color="black"
                      filled
                      v-model="addBond.tax"
                      :items="taxPercentages"
                      :options="taxPercentages"
                      :selectable="(item) => item.disabled"
                      item-text="name"
                      item-value="value"
                      placeholder="نسبة الضريبة"
                      dense
                      append-icon="mdi-chevron-down"
                      :menu-props="{ bottom: true, offsetY: true }"
                      height="42"
                      hide-details
                    />
                  </v-col>
                  <v-col class="pl-2" cols="12" md="3">
                    <h6 class="mb-2 font-14">{{ bondTax.text }}</h6>
                    <v-text-field
                      data-test="bond-description"
                      style="pointer-events: none"
                      class="mb-0 pa-0"
                      height="44"
                      filled
                      :value="taxValue"
                      placeholder="قيمة الضريبة المضافة"
                      dense
                      hide-details
                      suffix="ريال"
                    />
                  </v-col>
                </template>
              </v-row>
              <v-row no-gutters v-if="isRentCombined && isReceiptBond">
                <v-col class="pl-2" cols="12" md="5">
                  <h6 class="mb-2 font-14 d-flex align-center">
                    <span class="flex-shrink-0 ml-1"
                      >قيمة المبالغ الثابته*</span
                    >
                    <span class="font-12 flex-shrink-0"
                      >(يمكنك إستخدام القيمة المقترحة او تعديلها)</span
                    >
                  </h6>
                  <v-text-field
                    data-test="bond-value"
                    filled
                    @input="updateTotalValue($event, 'utilities')"
                    :value="utilitiesMask"
                    placeholder="قيمة المبالغ الثابته"
                    required
                    dense
                    suffix="ريال"
                    hide-details
                    @keydown="
                      $allowOnlyDecimalNumbersCommas($event, addBond.utilities)
                    "
                    :loading="isSuggestedValueLoading"
                    :disabled="isSuggestedValueLoading"
                  />
                </v-col>
                <v-col class="pl-2 mb-3" cols="12" md="4">
                  <div class="d-flex justify-space-between">
                    <h6 class="mb-2 font-14">نسبة الضريبة</h6>
                    <span
                      class="px-4 text-center font-12 font-weight-bold my-auto tax-status-tag"
                      :class="utilitiesTaxStatus.classes"
                      v-if="isReceiptBond"
                    >
                      {{ utilitiesTaxStatus.status }}
                    </span>
                    <v-switch
                      class="my-auto"
                      dense
                      hide-details="auto"
                      inset
                      small
                      @change="updateFixedTaxAmount"
                      :disabled="!addBond.utilities"
                      v-model="utilitiesSwitchBtnTax"
                    />
                  </div>
                  <v-select
                    :disabled="!utilitiesSwitchBtnTax"
                    color="black"
                    filled
                    v-model="addBond.utilitiesTax"
                    :items="taxPercentages"
                    :options="taxPercentages"
                    :selectable="(item) => item.disabled"
                    item-text="name"
                    item-value="value"
                    placeholder="نسبة الضريبة"
                    dense
                    append-icon="mdi-chevron-down"
                    :menu-props="{ bottom: true, offsetY: true }"
                    height="42"
                    hide-details
                  />
                </v-col>
                <v-col class="pl-2" cols="12" md="3">
                  <h6 class="mb-2 font-14">{{ utilitiesTaxStatus.text }}</h6>
                  <v-text-field
                    data-test="bond-description"
                    style="pointer-events: none"
                    class="mb-0 pa-0"
                    height="44"
                    filled
                    :value="fixedAmountTax"
                    placeholder="قيمة الضريبة المضافة"
                    dense
                    hide-details
                    suffix="ريال"
                  />
                </v-col>
              </v-row>
              <v-col
                v-if="isRentCombined && !isReceiptBond && !showCometionsfield"
                class="pl-2"
                cols="6"
              >
                <h6 class="mb-2 font-14 d-flex align-center">
                  <span class="flex-shrink-0 ml-1">قيمة المبالغ الثابته*</span>
                  <span class="font-12 flex-shrink-0"
                    >(يمكنك إستخدام القيمة المقترحة او تعديلها)</span
                  >
                </h6>
                <v-text-field
                  data-test="bond-value"
                  filled
                  @input="updateTotalValue($event, 'utilities')"
                  :value="utilitiesMask"
                  placeholder="قيمة المبالغ الثابته"
                  required
                  dense
                  suffix="ريال"
                  hide-details
                  @keydown="
                    $allowOnlyDecimalNumbersCommas($event, addBond.utilities)
                  "
                  :loading="isSuggestedValueLoading"
                  :disabled="isSuggestedValueLoading"
                />
              </v-col>
              <v-col
                class="pl-2"
                cols="12"
                md="6"
                v-if="showCometionsfield && hasCombinedRent"
              >
                <h6 class="mb-2 font-14">مجموع الضريبة المستلمة</h6>
                <v-text-field
                  data-test="bond-description"
                  style="pointer-events: none"
                  class="mb-0 pa-0"
                  height="44"
                  filled
                  :value="totalReceivableTaxValue"
                  disabled
                  placeholder="قيمة الضريبة المضافة"
                  dense
                  hide-details
                  suffix="ريال"
                />
              </v-col>
              <v-col class="pl-2" cols="12" sm="6">
                <h6 class="mb-2 font-14">
                  {{ isRentCombined ? 'قيمة السند الكلية' : 'المجموع الكلي' }}
                </h6>
                <v-text-field
                  data-test="bond-description"
                  class="mb-0 pa-0"
                  height="44"
                  filled
                  @input="updateBondValue"
                  :value="bondTotalValueMask"
                  @keydown="$formatNumberInputs($event, true)"
                  :placeholder="
                    isRentCombined ? 'قيمة السند الكلية' : 'المجموع الكلي'
                  "
                  dense
                  hide-details
                />
              </v-col>

              <!-- Next Installment -->
              <v-col v-if="statusNextInstallment" class="pl-2" cols="12" sm="6">
                <h6 class="mb-2 font-14">فترة الإستحقاق</h6>
                <v-text-field
                  data-test="bond-description"
                  style="pointer-events: none"
                  class="mb-0 pa-0"
                  height="44"
                  filled
                  :value="nextInstallmentDate"
                  placeholder="فترة الإستحقاق"
                  dense
                  hide-details
                />
              </v-col>
              <v-col
                v-if="showIsClaimed"
                class="pl-2 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
              >
                <h6 class="mb-2 font-14">
                  هل تم تسليم مبلغ السند للمالك مباشرة؟
                </h6>
                <v-select
                  :items="isClaimedOption"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  placeholder="الرجاء إختيار نعم أو لا"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="name"
                  item-value="value"
                  return-value
                  v-model="addBond.isClaimed"
                  :rules="[(v) => v != null || '']"
                  class="font-14"
                />
              </v-col>
              <v-col
                v-if="
                  havePmContracts &&
                  addBond.isClaimed === false &&
                  isMarketingPaid &&
                  !isReceiptBond &&
                  !isRentCombined
                "
                class="pl-2 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
              >
                <h6 class="mb-2 font-14">هل تود خصم عمولة التسويق والتأجير؟</h6>
                <v-select
                  :items="isClaimedOption"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  placeholder="الرجاء إختيار نعم أو لا"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="name"
                  item-value="value"
                  return-value
                  v-model="addBond.calculateMarketingFees"
                  class="font-14"
                />
              </v-col>
              <v-col
                v-if="
                  rentAmountAndFixedAmountStatus &&
                  clientTypeSelected === 'renter'
                "
                class="pl-2 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
              >
                <h6 class="mb-2 font-14">
                  هل تود حساب أتعاب إدارة الأملاك علي مبلغ السند؟
                </h6>
                <v-select
                  :items="isClaimedOption"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  placeholder="الرجاء إختيار نعم أو لا"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="name"
                  item-value="value"
                  return-value
                  v-model="addBond.includeCommission"
                  :rules="[(v) => v != null || '']"
                  class="font-14"
                />
              </v-col>
              <v-col
                v-if="
                  addBond.includeCommission &&
                  !addBond.isClaimed &&
                  rentAmountAndFixedAmountStatus
                "
                class="pl-2 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
              >
                <h6 class="mb-2 font-14">
                  هل تود إستلام وإستقطاع مبلغ أتعاب إدارة الأملاك ؟
                </h6>
                <v-select
                  :items="commissionDebtOptions"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  placeholder="الرجاء إختيار نعم أو لا"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="name"
                  item-value="value"
                  return-value
                  v-model="addBond.setCommissionDebt"
                  class="font-14"
                />
                <h6
                  v-if="addBond.setCommissionDebt"
                  class="shake mb-2 font-12 text--purple"
                >
                  تنويه, سيتم إصدار سند قبض أتعاب إدارة أملاك من المالك بطريقة
                  تلقائية
                </h6>
              </v-col>
              <!-- </v-row> -->
              <v-row no-gutters>
                <v-col
                  class="pl-2 mobile__pl__0 mb-2"
                  cols="12"
                  sm="6"
                  :md="status ? 6 : 6"
                  v-if="status"
                >
                  <h6 class="mb-2 font-14">
                    هل تود إستلام وإستقطاع مبلغ أتعاب إدارة الأملاك ؟
                  </h6>
                  <v-select
                    :items="commissionDebtOptions"
                    :menu-props="{
                      transition: 'slide-y-transition',
                      bottom: true,
                      offsetY: true
                    }"
                    placeholder="الرجاء إختيار نعم أو لا"
                    append-icon="mdi-chevron-down"
                    dense
                    filled
                    hide-details
                    item-text="name"
                    item-value="value"
                    return-value
                    v-model="payDuePmAmount"
                    class="font-14"
                  />
                  <h6
                    v-if="payDuePmAmount"
                    class="shake mb-2 font-12 text--purple"
                  >
                    تنويه, سيتم إصدار سند قبض أتعاب إدارة أملاك من المالك بطريقة
                    تلقائية
                  </h6>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  v-if="payDuePmAmount && showCometionsfield && status"
                >
                  <h6 class="mb-2 font-14">
                    قيمة أتعاب إدارة الأملاك
                    <span class="font-12 text--grey"
                      >(شاملة الضريبة إن وجدت)</span
                    >
                  </h6>
                  <v-text-field
                    class="mb-0 pa-0"
                    filled
                    :value="duePmAmount"
                    placeholder="قيمة أتعاب إدارة الأملاك "
                    dense
                    hide-details
                    suffix="ريال"
                    :disabled="true"
                  />
                </v-col>
                <v-col
                  class="pl-2 mobile__pl__0 mb-2"
                  cols="12"
                  sm="6"
                  v-if="showCometionsfield && addBond.bondValue"
                >
                  <!-- {{ payDueMarketingAmount }} -->
                  <h6 class="mb-2 font-14">
                    هل تود إستلام وإستقطاع مبلغ عموله التسويق والتأجير ؟
                  </h6>
                  <v-select
                    :items="commissionDebtOptions"
                    :menu-props="{
                      transition: 'slide-y-transition',
                      bottom: true,
                      offsetY: true
                    }"
                    placeholder="الرجاء إختيار نعم أو لا"
                    append-icon="mdi-chevron-down"
                    dense
                    filled
                    hide-details
                    item-text="name"
                    item-value="value"
                    return-value
                    v-model="payDueMarketingAmount"
                    class="font-14"
                  />
                  <h6
                    v-if="payDueMarketingAmount"
                    class="shake mb-2 font-12 text--purple"
                  >
                    تنويه, سيتم إصدار سند قبض عموله تسويق وتأجير بطريقه تلقائيه
                  </h6>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  v-if="payDueMarketingAmount && showCometionsfield && status"
                >
                  <h6 class="mb-2 font-14">
                    قيمه عموله تسويق والتأجير
                    <span class="font-12 text--grey"
                      >(شاملة الضريبة إن وجدت)</span
                    >
                  </h6>
                  <v-text-field
                    class="mb-0 pa-0"
                    filled
                    :value="dueMarketingAmount"
                    placeholder="قيمه عموله تسويق والتأجير"
                    dense
                    hide-details
                    suffix="ريال"
                    :disabled="true"
                  />
                </v-col>
              </v-row>

              <v-col
                class="pl-2 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
                v-if="showCometionsfield"
              >
                <h6 class="mb-2 font-14">
                  المجموع الكلى للسند (بعد خصم الأتعاب والعمولات)
                </h6>
                <v-text-field
                  class="mb-0 pa-0"
                  filled
                  :value="totalValueInCutting"
                  placeholder="قيمه عموله تسويق والتأجير"
                  dense
                  hide-details
                  suffix="ريال"
                  disabled
                  height="44px"
                />
              </v-col>
              <v-col class="pl-2 mobile__pl__0 mb-2" cols="12" sm="6">
                <h6 class="mb-2 font-14">طريقه الدفع</h6>
                <v-select
                  filled
                  v-model="addBond.paymentMethod"
                  :items="paymentMethods"
                  item-text="nameAr"
                  item-value="code"
                  placeholder="طريقه الدفع"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  height="44px"
                >
                  <template v-slot:no-data>
                    <div class="text-center font-14 grey--text">
                      <span> لم يتم تحميل طرق الدفع</span>
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col
                v-if="showAttachFields"
                cols="12"
                sm="6"
                class="file-continer-col mt-2 pe-2"
              >
                <h6 class="mt-0 mb-2">
                  إرفاق
                  {{
                    addBond.paymentMethod === 'check'
                      ? 'صورة الشيك'
                      : 'إشعار التحويل'
                  }}
                  (إختياري)
                </h6>
                <v-file-input
                  label=""
                  placeholder="إضغط لإضافة مرفق (واحد فقط)"
                  outlined
                  @change="uploadPaymentAttachment"
                  hide-details
                  prepend-icon=""
                  dense
                  append-icon="mdi-upload"
                ></v-file-input>
              </v-col>
              <v-col class="pl-2 mt-2" cols="12" sm="6" v-if="showAttachFields">
                <h6 class="mt-0 font-12">رقم المرفق (إختياري)</h6>
                <v-text-field
                  v-model="attachmentNumber"
                  placeholder="ادخل رقم المرفق"
                  dense
                  filled
                  hide-details
                  class="font-14"
                ></v-text-field>
              </v-col>
              <v-col class="pl-2" cols="12">
                <h6 class="mb-2 font-14">وصف السند</h6>
                <v-text-field
                  data-test="bond-description"
                  class="mb-0 pa-0"
                  height="60"
                  filled
                  v-model="bondDescription"
                  :rules="[(v) => v.length <= 100 || '']"
                  placeholder="وصف السند"
                  dense
                  hide-details
                />
                <div class="d-flex justify-end mt-2 ml-2">
                  <p
                    class="font-14"
                    :class="{
                      'text--f4212e':
                        (isDefaultDes && bondDescription.length > 100) ||
                        (!isDefaultDes && addBond.description.length > 100)
                    }"
                  >
                    {{
                      isDefaultDes
                        ? bondDescription.length
                        : addBond.description.length
                    }}
                    / 100
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-2">
        <v-spacer></v-spacer>
        <v-btn
          data-test="submit"
          color="primary"
          :loading="submitBtnLoading"
          class="ma-0 rounded-7 ml-3 my-1 d-flex"
          :disabled="!isValid || isSuggestedValueLoading"
          @click="handleSubmitBtnAction"
        >
          <span class="mb-1 mobile__mb__0 ml-1 font-weight-bold mt-1 font-12">
            {{ editMood ? 'تعديل سند' : 'إضافة سند' }}</span
          >
          <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import autocomplete from '@/components/forms/autocomplete'
import { bondsStaticData } from '@/constans/bonds'
import { debounce } from '@/helpers/debounce'
import confirmationMixin from '@/mixins/confirmationMixin.js'
import dateFormat from '@/mixins/dateFormat.js'
import inputMasking from '@/mixins/inputMasking.js'
import {
  bondsService,
  contractsService,
  EnvService,
  realEstateService
} from '@/services'
import { buildQueryString } from '@/utils/general'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'AddBondModal',
  props: {
    dialogState: {},
    editedBond: {
      default() {
        return {}
      }
    },
    defaultValues: {
      default() {
        return {}
      }
    }
  },
  components: {
    autocomplete
  },
  mixins: [inputMasking, dateFormat, confirmationMixin],
  data() {
    return {
      duePmAmount: 0,
      // totalReceivableTaxValue: 0,
      payDuePmAmount: true,
      dueMarketingAmount: 0,
      payDueMarketingAmount: true,
      hasCombinedRent: false,
      isDefaultDes: true,
      utilitiesSwitchBtnTax: false,

      havePmContracts: false,
      isMarketingPaid: false,
      bondTotalValueMask: '',
      bondValueMask: '',
      utilitiesMask: '',
      commissionDebtOptions: [
        {
          name: 'نعم, وسيتم إعتباره دين مسدد',
          value: true
        },
        {
          name: 'لا, وسيتم إعتباره دين مستحق',
          value: false
        }
      ],
      isClaimedOption: [
        {
          name: 'نعم',
          value: true
        },
        {
          name: 'لا',
          value: false
        }
      ],
      availableUnitsForParent: [],
      taxAmount: false,
      taxPercentages: [
        {
          name: '%0',
          value: 0,
          disabled: true
        },
        {
          name: '%5',
          value: 5,
          disabled: false
        },
        {
          name: '%15',
          value: 15,
          disabled: false
        }
      ],
      selectAllAvailableExpenses: false,
      contractTax: 0,
      utilitiesTax: 0,
      attachmentNumber: null,
      contractLoading: false,
      receiptBondText: {
        title: 'بند القبض',
        subTitle: 'تُضاف إلي',
        placeholder: 'اختر نوع القبض'
      },
      exchangeBondText: {
        title: 'بند الصرف',
        subTitle: 'تُصرف من',
        placeholder: 'اختر نوع الصرف'
      },
      ...bondsStaticData,
      startAtModal: false,
      bankNamestartAtModal: false,
      start: new Date().toISOString().substr(0, 10),
      clientTypeSelected: '',
      clientTypes: [
        {
          name: 'مالك',
          value: 'owner'
        },
        {
          name: 'مستأجر',
          value: 'renter'
        }
      ],
      addBond: {
        bondType: '',
        subType: '',
        bondValue: 0,
        utilities: 0,
        isClaimed: null,
        calculateMarketingFees: null,
        date: new Date().toISOString().substr(0, 10),
        expenses: null,
        description: '',
        tax: 0,
        utilitiesTax: 0,
        thirdParty: '',
        reference: null,
        paymentMethod: '',
        total: 0,
        includeCommission: null,
        setCommissionDebt: null
      },
      bondsType: [],
      listInstallment: [],
      contracts: [],
      AllContracts: [],
      client: [],
      AllClient: [],
      properties: [],
      AllProperties: [],
      propertySearchKey: '',
      clientSearchKey: '',
      contractSearchKey: '',
      unitSearchKey: '',
      query: {},
      firstCallForSugValFn: false,
      isThereMarktingCut: false,
      isTherePmCut: false,
      showMarktingField: false,
      submitBtnLoading: false,
      isValid: false,
      paginationClient: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationProperty: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationUnit: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationContract: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      isSuggestedValueLoading: false,
      nextInstallmentStart: '',
      nextInstallmentEnd: '',
      subTypesRequireClaim: [
        'rent',
        'rentCombined',
        'electricity',
        'water',
        'gas',
        'insurance',
        'generalServices'
      ],
      receiptBondRequireIncludeCommission: ['dues'],
      expensesLoading: false,
      expenses: [],
      clientRoles: {
        renter: 'tenet',
        owner: 'owner'
      },
      totalCutting: 0,
      paymentMethods: [],
      realEstateId: '',
      getRoleData: {
        owner: {
          name: 'مالك',
          value: 'owner',
          contractType: 'propertyManagement'
        },
        renter: {
          name: 'مستأجر',
          value: 'renter',
          contractType: 'rent'
        }
      }
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    findCode() {
      return this.bondsSubTypes.find(
        (type) => type._id === this.addBond.subType
      )?.code
    },
    status() {
      if (
        this.addBond.bondValue &&
        (this.findCode === 'rentCombinedDues' || this.findCode === 'dues')
      ) {
        return true
      }
      if (
        this.addBond.bondValue &&
        this.clientTypeSelected === 'owner' &&
        this.findCode === 'allDues'
      ) {
        return true
      }

      return false
    },
    rentAmountAndFixedAmountStatus() {
      if (
        (this.clientTypeSelected === 'owner' ||
          this.clientTypeSelected === 'renter') &&
        (this.findCode === 'rent' || this.findCode === 'rentCombined')
      ) {
        return !!this.addBond.bondValue
      }
      if (
        this.clientTypeSelected === 'renter' &&
        this.havePmContracts &&
        (this.findCode === 'rent' || this.findCode === 'rentCombined')
      ) {
        return true
      }
      return false
    },
    isRentCombined() {
      return !!this.selectedSubType?.code.includes('rentCombined')
    },
    rentTaxAmount() {
      return this.addBond.bondValue * (this.addBond?.currentTax / 100)
    },
    totalTaxFixedAmound() {
      return this.addBond.utilities * 0.15
    },
    fixedPaymentAmountTax() {
      return this.addBond.utilities * (this.addBond?.currentTax / 100)
    },
    totalReceivableTaxValue() {
      return this.totalTaxFixedAmound + this.rentTaxAmount || 0
    },
    resetTotalValue() {
      return !this.bondValueMask && !this.utilitiesMask
    },
    totalValueInCutting() {
      const pmFees = this.payDuePmAmount ? this.isTherePmCut : 0
      const marketingFees = this.payDueMarketingAmount
        ? this.isThereMarktingCut
        : 0
      return (
        pmFees +
        marketingFees +
        Number(this.bondTotalValueMask.replace(/,/g, ''))
      )
    },
    envHasCommissionTax() {
      return this.currentEnv?.hasCommissionTax || false
    },
    showCometionsfield() {
      return (
        this.clientTypeSelected === 'owner' &&
        this.isExchangeBond &&
        ['allDues', 'dues', 'rentCombinedDues'].includes(
          this.selectedSubType?.code
        ) &&
        (this.isThereMarktingCut || this.isTherePmCut)
      )
    },

    utilitiesTaxStatus() {
      return {
        text: `قيمة الضريبة`,
        status: this.utilitiesTax ? 'مُفعلة في العقد' : 'غير مُفعلة في العقد',
        classes: this.utilitiesTax ? 'brub--tag' : 'tax-inactive-tag'
      }
    },
    realestateKey() {
      return this.addBond?.unit?._id ? 'unit' : 'realEstate'
    },
    bondDescription: {
      get() {
        return this.selectedSubType?.nameAr
          ? `${this.selectedBondType?.nameAr ?? ''} ${
              this.selectedSubType?.nameAr ?? ''
            } ${this.addBond?.realEstate?.name ?? ''} - ${
              this.addBond?.unit?.name ?? ''
            } ${this.isReceiptBond ? 'مستحق في' : 'بتاريخ'} ${this.formatDate(
              this.addBond?.date,
              'ltr'
            )}`
          : ''
      },
      set(val) {
        this.isDefaultDes = false
        this.addBond.description = val
      }
    },
    fixedAmountTax() {
      return this.addBond?.utilities
        ? (
            this.addBond.utilities *
            (this.addBond?.utilitiesTax / 100)
          ).toLocaleString('en-US', {
            maximumFractionDigits: 2
          })
        : 0
    },
    hasPropertyManagementFees() {
      return !!this.addBond?.contract?.percentage
    },
    showAttachFields() {
      return (
        this.addBond.paymentMethod === 'check' ||
        this.addBond.paymentMethod === 'bankTransfer' ||
        this.addBond.paymentMethod === 'paymentAdvice' ||
        this.addBond.paymentMethod === 'other' ||
        this.addBond.paymentMethod === 'bankTransferEjar'
      )
    },
    bondValueKeys() {
      return {
        maskValKey: 'bondValueMask',
        parentKey: 'addBond',
        actualValKey: 'bondValue'
      }
    },
    utilitiesKeys() {
      return {
        maskValKey: 'utilitiesMask',
        parentKey: 'addBond',
        actualValKey: 'utilities'
      }
    },
    totalValueKeys() {
      return {
        maskValKey: 'bondTotalValueMask',
        parentKey: 'addBond',
        actualValKey: 'total'
      }
    },
    propertyName() {
      return (
        this.addBond[`${this.realestateKey}`]?.name ??
        this.addBond[`${this.realestateKey}`]?.realEstate?.propertyName ??
        ''
      )
    },
    isContractRequired() {
      // Disabled for businees need
      return (
        this.selectedSubType?.contractOptional?.[
          `${this.clientTypeSelected}`
        ]?.[`${this.selectedBondType?.nameEn}`] || false
      )
    },
    contractRules() {
      // Disabled for businees need
      return this.isContractRequired
        ? [(v) => !!v || 'من فضلك اختر العقد الخاص بالسند']
        : [true]
    },
    isTaxIncluded() {
      return (
        !this.selectedSubType?.disableTax?.[
          `${this.selectedBondType.nameEn}`
        ] ?? true
      )
    },
    getProviderName() {
      if (this.bondsType?.length) {
        const bondtype = this.bondsType.find(
          (type) => type._id === this.addBond.bondType
        )
        return bondtype?.nameEn === 'exchange'
          ? ' اسم المستلم (تم صرف قيمة السند لـ)'
          : ' اسم المورد (تم إستلام قيمة السند من)'
      }
      return ''
    },
    isTaxFiled() {
      return (
        (this.isExchangeBond &&
          this.selectedSubType?.nameEn === 'Maintenance') ||
        (this.isReceiptBond && this.selectedSubType?.nameEn === 'Rent')
      )
    },
    clauseText() {
      return this.isReceiptBond ? this.receiptBondText : this.exchangeBondText
    },
    isReceiptBond() {
      return this.selectedBondType?.nameEn === 'receipt'
    },
    isExchangeBond() {
      return this.selectedBondType?.nameEn === 'exchange'
    },
    clientAutoCompleteProps() {
      const { noData, placeholder, validateString, loading } =
        this.clientAutoCompleteData
      return {
        itemData: true,
        returnObject: true,
        items: this.AllClient,
        noData,
        placeholder,
        validateString,
        loading,
        disabled: this.isDefaultValueClient
      }
    },
    unitsAutoCompleteDataProps() {
      const { noData, placeholder, validateString, loading, itemText } =
        this.unitsAutoCompleteData
      return {
        returnObject: true,
        items: this.availableUnitsForParent,
        noData,
        placeholder,
        validateString,
        loading,
        itemText,
        disabled:
          !this.addBond?.client?._id ||
          !this.addBond?.realEstate?._id ||
          this.defaultValues?.unit?._id,
        hideDetails: true
      }
    },
    PropertyAutoCompleteProps() {
      const { noData, placeholder, validateString, loading, itemText } =
        this.propertyAutoCompleteData
      return {
        returnObject: true,
        items: this.AllProperties,
        noData,
        placeholder,
        validateString,
        loading,
        disabled: !this.addBond?.client?._id || this.isDefaultValueProperty,
        itemText,
        hideDetails: true
      }
    },
    contractsWithDefaultVal() {
      return this.AllContracts?.length
        ? this.AllContracts
        : [{ madeUpTitle: 'لا توجد عقود متاحة' }]
    },
    ContractAutoCompleteProps() {
      const {
        noData,
        placeholder,
        validateString,
        loading,
        contractPropertyToShow
      } = this.contractAutoCompleteData
      return {
        returnObject: true,
        items: this.contractsWithDefaultVal,
        noData,
        placeholder,
        validateString,
        loading,
        itemText: contractPropertyToShow,
        contractStatus: !!this.AllContracts.length,
        disabled: this.contractLoading || !this.addBond.realEstate
      }
    },
    nextInstallmentDate() {
      return `من ${this.formatDate(
        this.nextInstallmentStart,
        'ltr'
      )} إلي ${this.formatDate(this.nextInstallmentEnd, 'ltr')}`
    },
    statusNextInstallment() {
      return (
        this.nextInstallmentStart &&
        this.isReceiptBond &&
        this.subTypesRequireClaim.includes(this.selectedSubType?.code)
      )
    },
    bondCreatedAt() {
      return new Date().toISOString().slice(0, 10)
    },
    md() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    allBondSubTypes() {
      let allSubtypes = []
      this.bondsType.map((T) => {
        allSubtypes = [...allSubtypes, ...T.subTypes]
      })
      return allSubtypes
    },
    bondsSubTypes() {
      const isUnitCount = this.addBond.realEstate?.unitsCount
      if (!this.addBond.bondType) {
        return []
      }
      let subTypes = this.bondsType?.find(
        (type) => type._id === this.addBond.bondType
      )?.subTypes

      subTypes = subTypes?.filter((subType) => {
        return subType.userType === this.clientTypeSelected || !subType.userType
      })

      if (
        this.clientTypeSelected === 'owner' &&
        this.isExchangeBond &&
        !this.addBond?.unit?._id &&
        isUnitCount
      ) {
        subTypes = subTypes?.filter((subType) => {
          if (subType.hasOwnProperty('allDues')) {
            return subType.allDues === true
          } else if (!subType.hasOwnProperty('allDues')) {
            return true
          }
          return false
        })
      }
      if (
        this.clientTypeSelected !== 'owner' ||
        !this.isExchangeBond ||
        this.addBond?.unit?._id
      ) {
        subTypes = subTypes?.filter((subType) => {
          return subType.code !== 'allDues'
        })
      }
      if (
        this.addBond.contract?._id &&
        ((this.clientTypeSelected === 'renter' && this.isReceiptBond) ||
          (this.clientTypeSelected === 'owner' && this.isExchangeBond))
      ) {
        return subTypes?.filter((subType) => {
          if (
            !this.hasCombinedRent &&
            (subType.isCombined === false ||
              !subType.hasOwnProperty('isCombined'))
          ) {
            if (
              (!isUnitCount && subType.code !== 'allDues') ||
              (isUnitCount && subType.code !== 'rentCombinedDues')
            ) {
              return true
            }
          }

          if (
            (this.hasCombinedRent &&
              subType.hasOwnProperty('isCombined') &&
              subType.isCombined) ||
            (!subType.hasOwnProperty('isCombined') &&
              subType.code !== 'allDues')
          ) {
            return true
          }
          return false
        })
      }

      return subTypes
    },
    selectedBondType() {
      const bondType = this.bondsType?.find(
        (type) => type._id === this.addBond.bondType
      )
      return bondType
    },
    selectedSubType() {
      const bondSubType = this.bondsSubTypes?.find(
        (subType) => subType._id === this.addBond.subType
      )
      return bondSubType
    },
    bondTitle() {
      return `${this.selectedBondType?.nameAr} ${this.selectedSubType?.nameAr} - ${this.propertyName}`
    },
    ifDefaultValuesExist() {
      return !!Object.keys(this.defaultValues).length
    },
    utilitiesValue() {
      return this.addBond.utilities
    },
    bondValue() {
      return this.addBond.bondValue + this.utilitiesValue
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set() {
        this.$emit('changeDialogState', true)
      }
    },
    editMood() {
      return Object.keys(this.editedBond).length
    },
    combinedDetailsPayload() {
      const { bondValue, tax, utilities, utilitiesTax } = this.addBond
      return {
        rent: bondValue,
        rentTax: tax,
        rentTaxAmount: bondValue * (tax / 100),
        utilities,
        utilitiesTax,
        utilitiesTaxAmount: utilities * (utilitiesTax / 100)
      }
    },
    bondDataToAdd() {
      const bondData = {
        ...this.addBond,
        ...(this.payDuePmAmount && {
          duePmAmount: Number(this.duePmAmount)
        }),
        ...(this.payDuePmAmount && {
          payDuePmAmount: this.payDuePmAmount
        }),
        ...(this.payDueMarketingAmount && {
          dueMarketingAmount: Number(this.dueMarketingAmount)
        }),
        ...(this.payDueMarketingAmount && {
          payDueMarketingAmount: this.payDueMarketingAmount
        }),
        date: new Date(this.addBond?.date)?.toISOString(),
        attachmentNumber: this.attachmentNumber,
        bondValue: this.showCometionsfield
          ? Number(this.totalValueInCutting)
          : this.bondValue, // ! Test again before production
        description: this.isDefaultDes
          ? this.bondDescription
          : this.addBond?.description,
        reference: this.addBond?.reference?._id,
        title: this.bondTitle,
        environment: this.currentEnv._id,
        contract: null,
        ...(this.addBond?.contract?._id && {
          contract: this.addBond?.contract?._id
        }),
        attachment: this.addBond?.attachment,
        realEstate: { ...this.getRealEstateData() },
        client: this.formatUserObj(this.addBond?.client),
        owner: this.formatUserObj(this.user),
        createdBy: this.formatUserObj(this.user),
        includeTax: !!this.taxAmount,
        hasCommissionTax: this.envHasCommissionTax,
        installmentsDate: {
          startDate: new Date(this.nextInstallmentStart),
          endDate: new Date(this.nextInstallmentEnd)
        },
        ...(this.isRentCombined && {
          combinedDetails: this.combinedDetailsPayload
        })
      }
      if (!this.showIsClaimed && this.addBond.isClaimed) {
        // to be revisit after back End integration
        delete bondData.isClaimed
        delete bondData.calculateMarketingFees
        delete bondData.includeCommission
        delete bondData.setCommissionDebt
      }
      if (this.showExpensesRequests && this.expenses.length) {
        bondData.expenses = this.addBond?.expenses || []
      } else if (Object.prototype.hasOwnProperty.call(bondData, 'expenses')) {
        delete bondData.expenses
      }
      // delete bondData.utilities
      delete bondData.utilitiesTax
      delete bondData.utilitiesTaxAmount
      delete bondData.unit
      return bondData
    },
    bondDataToEdit() {
      const bondData = {
        _id: this.addBond._id,
        bondType: this.addBond.bondType,
        bondValue: this.addBond.bondValue,
        subType: this.addBond.subType,
        status: this.addBond.status,
        environment: this.currentEnv._id,
        contract: this.addBond?.contract?._id,
        realEstate: this.addBond?.contract?.realEstate,
        client: this.formatUserObj(this.addBond.client),
        owner: this.formatUserObj(this.addBond.owner),
        title: this.bondTitle,
        description: this.isDefaultDes
          ? this.bondDescription
          : this.addBond?.description
      }
      return bondData
    },
    canGetSuggestedValue() {
      return !!(
        this.addBond.contract?._id &&
        this.addBond.bondType &&
        this.addBond.subType
      )
    },
    sm() {
      return this.$vuetify.breakpoint.smAndUp
    },
    showIsClaimed() {
      const activeSubType = this.bondsSubTypes?.find(
        (subType) => subType._id === this.addBond.subType
      )
      return (
        this.subTypesRequireClaim.includes(activeSubType?.code) &&
        this.isReceiptBond
      )
    },
    showIsIncludeCommission() {
      const subTypeClauses = this.subTypeClauses?.find(
        (type) => type._id === this.addBond.reference?._id
      )

      return (
        (this.receiptBondRequireIncludeCommission.includes(
          subTypeClauses?.code
        ) &&
          this.isReceiptBond) ??
        false
      )
    },
    isDefaultValueClient() {
      return !!Object.keys(this.defaultValues.client || {}).length
    },
    isDefaultValueProperty() {
      return !!Object.keys(this.defaultValues.realEstate || {}).length
    },
    showExpensesRequests() {
      return (
        !!this.addBond.contract?.realEstate?._id &&
        this.selectedSubType?.code === 'maintenanceRequest'
      )
    },
    expensesFilter() {
      return {
        environmentId: this.currentEnv._id,
        realestateId: this.addBond.contract?.realEstate?._id
      }
    },
    bondTax() {
      return {
        text: `قيمة الضريبة`,
        status: this.contractTax ? 'مُفعلة في العقد' : 'غير مُفعلة في العقد',
        classes: this.contractTax ? 'brub--tag' : 'tax-inactive-tag'
      }
    },
    taxValue() {
      return this.addBond?.bondValue
        ? (this.addBond.bondValue * (this.addBond?.tax / 100)).toLocaleString(
            'en-US',
            {
              maximumFractionDigits: 2
            }
          )
        : 0
    },
    bondValueRules() {
      return [
        () =>
          !!this.addBond?.bondValue ||
          !!this.isThereMarktingCut ||
          !!this.isTherePmCut ||
          !!this.utilitiesMask ||
          ''
      ]
    },
    fixedAmountRules() {
      return [() => !!this.addBond?.utilities || '']
    },
    subTypeClauses() {
      const allAvaliableClauses =
        this.selectedSubType?.ref?.[this.selectedBondType.nameEn] ?? []
      const filteredCluses = allAvaliableClauses.filter((clause) => {
        // filter only cluases that having user type equal to slelected client type
        return clause?.userType
          ? clause?.userType === this.clientTypeSelected
          : true
      })
      return filteredCluses ?? []
    },
    isExpenseRequestDefault() {
      return !!this.defaultValues.expenseRequestId
    }
  },
  watch: {
    resetTotalValue(val) {
      if (val) {
        this.addBond.rentTaxAmount = 0
        this.bondTotalValueMask = ''
        this.handleValuesChanging('', this.totalValueKeys)
      }
    },
    payDuePmAmount(val) {
      if (!val) {
        this.query.cutPm = false
      } else {
        delete this.query.cutPm
      }
      this.getSuggestedBondValue()
    },
    payDueMarketingAmount(val) {
      if (!val) {
        this.query.cutMarketing = false
      } else {
        delete this.query.cutMarketing
      }
      this.getSuggestedBondValue()
    },
    editedBond: {
      immediate: true,
      handler(val) {
        if (Object.keys(val).length) {
          this.addBond = {
            ...val
          }
          this.AllClient.push(this.addBond?.client)
          this.AllProperties.push(this.addBond?.realEstate)
          this.loadProperties()
        }
      }
    },
    'addBond.contract': {
      async handler(val) {
        if (val && !val?._id) {
          this.nextInstallmentStart = this.nextInstallmentEnd = ''
          this.addBond.bondValue = 0
          this.addBond.tax = 0
          this.addBond.utilities = 0
          this.addBond.utilitiesTax = 0
          return
        }
        if (this.canGetSuggestedValue && !this.addBond?.paymentMethod) {
          this.getSuggestedBondValue()
        }
        if (this.addBond.expenses && !this.isExpenseRequestDefault) {
          this.addBond.expenses = null
        }
        if (val && val?._id) {
          await this.getNextInstallment()
          if (this.showExpensesRequests) {
            this.getExpensesRequests()
          }
          if (this.clientTypeSelected === 'owner' && this.isExchangeBond) {
            this.hasCombinedRent = await this.checkIfRentContractCombined()
          } else {
            this.hasCombinedRent = val?.hasCombinedRent ?? false
          }
        }
      }
    },
    'addBond.realEstate': {
      async handler(val) {
        this.AllContracts = []
        if (!this.defaultValues?.unit?._id) {
          this.availableUnitsForParent = []
        }
        if (val && Object.keys(val).length) {
          await this.loadUnitsForParent()
          if (this.availableUnitsForParent.length === 1) {
            if (!this.defaultValues?.unit?._id) {
              this.$set(this.addBond, 'unit', this.availableUnitsForParent[0])
            }
            if (this.addBond?.client?._id?.length) {
              this.loadContracts()
            }
          } else {
            this.$set(this.addBond, 'contract', null)
          }
        } else {
          this.$set(this.addBond, 'contract', null)
          this.$set(this.addBond, 'unit', null)
        }
      }
    },
    'addBond.unit': {
      handler(val) {
        if (
          val &&
          Object.keys(val).length &&
          this.addBond?.client?._id?.length
        ) {
          this.loadContracts()
        }
      }
    },
    'addBond.bondType'() {
      !this.isExpenseRequestDefault && (this.addBond.subType = '')
      if (this.canGetSuggestedValue) {
        this.getSuggestedBondValue()
      } else {
        this.addBond.bondValue = 0
        this.addBond.utilities = 0
      }
    },
    'addBond.subType'() {
      if (
        this.addBond?.reference &&
        Object.keys(this.addBond.reference).length
      ) {
        this.$set(this.addBond, 'reference', null)
      }
      this.$set(this, 'duePmAmount', 0)
      this.$set(this, 'dueMarketingAmount', 0)
      if (this.addBond?.contract?._id) {
        this.getNextInstallment()
      }
      if (this.canGetSuggestedValue) {
        this.getSuggestedBondValue()
      }
    },
    defaultValues: {
      immediate: true,
      handler(defaultValues) {
        if (defaultValues.setClickedTag) {
          this.clientTypeSelected =
            this.getRoleData[this.defaultValues?.setClickedTag]?.value
        }
        if (this.isExpenseRequestDefault) {
          this.$set(this.addBond, 'client', defaultValues.client)
          this.$set(this.addBond, 'expenses', [defaultValues.expenseRequestId])
          this.realEstateId = defaultValues.realEstateId
        } else {
          this.addBond = {
            ...this.addBond,
            ...defaultValues,
            client: defaultValues.realEstate?.owner?._id
              ? defaultValues.realEstate?.owner
              : defaultValues?.client
          }
          if (this.addBond.hasOwnProperty('unit') && this.addBond?.unit?._id) {
            this.availableUnitsForParent.push(this.addBond?.unit)
          }
        }
        if (defaultValues.realEstate) {
          this.AllProperties.push(defaultValues.realEstate)
          this.AllClient.push(defaultValues.realEstate?.owner)
          if (!this.addBond.unit?._id) {
            this.loadUnitsForParent()
          }
          this.$set(
            this.addBond,
            'thirdParty',
            defaultValues.realEstate.owner.name
          )
        } else if (defaultValues.client) {
          this.$set(this.addBond, 'thirdParty', defaultValues.client.name)
          this.AllClient.push(defaultValues.client)
          this.loadProperties(defaultValues.client?._id)
        }
      }
    },
    showExpensesRequests(val) {
      val && this.getExpensesRequests()
    },
    clientTypeSelected(value) {
      if (value) {
        const key = value === 'owner' ? value : 'tenet'
        if (this.isDefaultValueProperty) {
          if (this.addBond?.unit?._id && this.addBond?.unit?.[`${key}`]?._id) {
            this.AllClient = [this.addBond?.unit?.[`${key}`]]
          } else if (this.defaultValues.realEstate?.[`${key}`]?._id) {
            this.AllClient = [this.defaultValues.realEstate?.[`${key}`]]
          } else if (this.defaultValues.realEstate?.[`${key}s`].length) {
            this.AllClient = [...this.defaultValues.realEstate?.[`${key}s`]]
          } else {
            this.AllClient = []
          }
        } else {
          delete this.addBond.client
          this.$set(this.addBond, 'contract', null)
          this.AllClient = []
          this.$set(this.paginationClient, 'pageNumber', 1)
          this.loadClients()
        }
      }
    },
    'addBond.utilitiesTax': {
      handler() {
        this.updateTotalValue(this.utilitiesMask, 'utilities')
      }
    },
    'addBond.tax': {
      handler() {
        this.updateTotalValue(this.bondValueMask)
      }
    }
  },
  created() {
    this.getMetaProperty()
    this.getBondPaymentMethods()
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getPaymentMethodNameAr(code) {
      const codes = {
        cash: 'نقداً',
        check: 'شيك',
        bankTransfer: 'تحويل بنكي',
        paymentAdvice: 'إشعار دفع',
        other: 'اخرى'
      }
      return codes[code] ?? code
    },
    async uploadPaymentAttachment(file) {
      const fd = new FormData()
      fd.append('attachment', file, file.name)
      fd.append('envId', this.currentEnv._id)
      fd.append('type', this.getPaymentMethodNameAr(this.addBond.paymentMethod))

      fd.append('entityType', 'bond')
      const {
        data: { id }
      } = await contractsService.addAttachment(fd)
      this.addBond.attachment = id
    },
    async getIsSelectable() {
      const query = {
        user: this.addBond.client?._id,
        environments: [this.currentEnv?._id],
        userType: this.clientRoles[this.clientTypeSelected],
        ids: [this.addBond?.realEstate?._id],
        child: false
      }
      try {
        const { data } = await realEstateService.getRealEstates(query)
        const { isSelectable } = data?.realEstates?.[0] ?? false
        this.addBond.realEstate = {
          ...this.addBond?.realEstate,
          isSelectable
        }
        this.loadUnitsForParent()
      } catch {
        this.addNotification({
          text: 'حدث خطأ أثناء تحميل بيانات العقار',
          color: 'error'
        })
      }
    },
    handlereceiptChanging(value) {
      if (value?.code === 'dues') {
        this.checkPmContracts()
      } else {
        this.havePmContracts = this.isMarketingPaid = false
      }
    },
    async checkPmContracts() {
      try {
        const res = await contractsService.checkPmContracts({
          realestateID:
            this.addBond?.unit?._id ?? this.addBond?.realEstate?._id,
          parentID: this.addBond?.unit?.parentId ?? undefined,
          date: this.nextInstallmentStart,
          rentContract: this.addBond?.contract?._id
        })
        this.havePmContracts = res?.data?.contract
        this.isMarketingPaid = res?.data?.marketing
      } catch {
        this.addNotification({
          text: 'خطأ في معرفة عدد عقود إدارة الأملاك',
          color: 'error'
        })
      }
    },
    async checkIfRentContractCombined() {
      try {
        const { data } = await bondsService.checkCombinedRentContract({
          realestateID:
            this.addBond?.unit?._id ?? this.addBond?.realEstate?._id,
          date: this.nextInstallmentStart
        })
        return data.contract
      } catch {
        return this.addNotification({
          text: 'خطأ في معرفة حاله عقد الايجار',
          color: 'error'
        })
      }
    },
    getRealEstateData() {
      const realestateKey = this.realestateKey
      return {
        _id: this.addBond?.[`${realestateKey}`]?._id,
        owner: this.addBond?.[`${realestateKey}`]?.owner?._id,
        parentId: this.addBond?.[`${realestateKey}`]?.parentId,
        parentName: this.addBond?.[`${realestateKey}`]?.parentName,
        propertyName:
          this.addBond?.[`${realestateKey}`]?.propertyName ??
          this.addBond?.[`${realestateKey}`]?.name,
        name:
          this.addBond?.[`${realestateKey}`]?.name ??
          this.addBond?.[`${realestateKey}`]?.propertyName,
        moderators: this.addBond?.[`${realestateKey}`]?.moderators
      }
    },
    updateTotalValue(val, key = 'bondValue') {
      if (!val && key === 'bondValue') {
        this.bondValueMask = ''
      }
      if (!val && key === 'utilities') {
        this.utilitiesMask = ''
      }

      this.handleValuesChanging(
        val,
        key === 'bondValue' ? this.bondValueKeys : this.utilitiesKeys
      )

      const bondTaxVal =
        this.addBond.bondValue +
        this.addBond.bondValue * (this.addBond?.tax / 100)
      const bondFixedVal =
        this.addBond.utilities +
        this.addBond.utilities * (this.addBond?.utilitiesTax / 100)

      let totalVal = bondTaxVal + bondFixedVal
      if (this.clientTypeSelected === 'owner') {
        totalVal = bondTaxVal + bondFixedVal + this.totalReceivableTaxValue
      }

      this.handleValuesChanging(totalVal, this.totalValueKeys)
    },
    updateBondValue(val) {
      this.handleValuesChanging(val, this.totalValueKeys)
      if (!this.isRentCombined) {
        const bondVal = this.addBond?.total / (this.addBond?.tax / 100 + 1)
        this.handleValuesChanging(bondVal.toFixed(2), this.bondValueKeys)
      } else {
        this.handleUpdatingFixedValue()
      }
    },
    handleUpdatingFixedValue: debounce(function () {
      if (!this.addBond?.total) {
        this.handleValuesChanging(0, this.bondValueKeys)
        this.handleValuesChanging(0, this.utilitiesKeys)
      }
      const diffrence =
        this.addBond?.total -
        (this.addBond.bondValue +
          this.addBond.bondValue * (this.addBond.tax / 100))

      const fixedVal = diffrence / (this.addBond.utilitiesTax / 100 + 1)
      if (fixedVal < 0) {
        let bondVal = this.addBond?.bondValue + fixedVal
        bondVal /= this.addBond.tax / 100 + 1
        this.handleValuesChanging(bondVal.toFixed(2), this.bondValueKeys)
        this.handleValuesChanging(0, this.utilitiesKeys)
        this.utilitiesSwitchBtnTax = false
      } else {
        this.handleValuesChanging(fixedVal.toFixed(2), this.utilitiesKeys)
      }
    }, 500),
    updateFixedTaxAmount(status) {
      this.addBond.utilitiesTax = status ? this.contractTax || 15 : 0
    },
    async loadProperties(clientId = '') {
      this.$set(this.propertyAutoCompleteData, 'loading', true)
      const query = {
        user: clientId.length ? clientId : this.addBond.client?._id,
        environments: [this.currentEnv?._id],
        userType: this.clientRoles[this.clientTypeSelected],
        child: false
      }
      let _data = `pageSize=${this.paginationProperty.pageSize}&pageNumber=${this.paginationProperty.pageNumber}`
      if (this.propertySearchKey) {
        _data += `&search=${this.propertySearchKey}`
      }
      try {
        const { data } = await realEstateService.getRealEstates(query, _data)
        this.properties = data?.realEstates ?? []
        this.AllProperties = [...this.AllProperties, ...this.properties]
        this.paginationProperty.pagesCount = Math.ceil(
          data?.count / this.paginationProperty.pageSize
        )
        this.paginationProperty.count = data?.count ?? 0
      } catch {
        this.addNotification({
          text: 'حدث خطأ أثناء تحميل العقارات',
          color: 'error'
        })
      } finally {
        this.$set(this.propertyAutoCompleteData, 'loading', false)
      }
    },
    IsInstallmentNeedType() {
      const installmentNeedType = ['Rent', 'Water', 'Electricity', 'Gas']
      if (installmentNeedType.includes(this.selectedSubType?.nameEn)) {
        // transform key to a lower case to match the backEnd keys
        const res = `${this.selectedSubType?.nameEn.toLowerCase()}`
        return res
      }
      return false
    },
    getInstallmentQuery() {
      const IsInstallmentNeedType = this.IsInstallmentNeedType()
      const query = {
        envId: this.addBond.contract.environment,
        contractId: this.addBond.contract._id
      }
      if (IsInstallmentNeedType) {
        query.type = IsInstallmentNeedType
      } else {
        delete query.type
      }
      return query
    },
    updateTaxAmount(status) {
      this.addBond.tax = status ? this.contractTax || 15 : 0
    },
    handleSubmitBtnAction() {
      return this.editMood ? this.editBonds() : this.addBonds()
    },
    getClientDataBySearchKey(e) {
      this.clientSearchKey = e
      if (this.clientSearchKey) {
        this.loadClients()
        this.$set(this.clientAutoCompleteData, 'loading', true)
      } else {
        this.$set(this.clientAutoCompleteData, 'loading', false)
      }
    },
    getClientNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.$set(this.clientAutoCompleteData, 'loading', true)
        this.loadClients()
      } else {
      }
    },
    getContractDataBySearchKey(e) {
      this.contractSearchKey = e
      if (this.contractSearchKey && this.addBond.realEstate) {
        this.loadContracts()
        this.$set(this.contractAutoCompleteData, 'loading', true)
      } else {
        this.$set(this.contractAutoCompleteData, 'loading', false)
      }
    },
    getContractNextData() {
      if (
        this.paginationContract.pageNumber < this.paginationContract.pagesCount
      ) {
        this.paginationContract.pageNumber += 1
        this.$set(this.contractAutoCompleteData, 'loading', true)
        this.loadContracts()
      }
    },
    getUnitDataBySearchKey(e) {
      this.unitSearchKey = e
      if (this.unitSearchKey && this.addBond.realEstate?._id) {
        this.loadUnitsForParent()
      }
    },
    getUnitNextData() {
      if (this.paginationUnit.pageNumber < this.paginationUnit.pagesCount) {
        this.paginationUnit.pageNumber += 1
        this.$set(this.unitsAutoCompleteData, 'loading', true)
        this.loadUnitsForParent()
      }
    },
    getPropertyDataBySearchKey(e) {
      this.propertySearchKey = e
      if (this.propertySearchKey && this.addBond.client) {
        this.loadProperties()
      }
    },
    getPropertyNextData() {
      if (
        this.paginationProperty.pageNumber < this.paginationProperty.pagesCount
      ) {
        this.paginationProperty.pageNumber += 1
        this.$set(this.propertyAutoCompleteData, 'loading', true)
        this.loadProperties()
      }
    },
    closeDialog() {
      this.addBond = {}
      this.$emit('closed-bond-dialog', 'close')
    },
    validate() {
      this.$refs.form.validate()
      if (this.isValid) {
        this.tab++
      }
    },
    selctedBondsType(id) {
      const selectedTYpe = this.bondsType.find((type) => type._id === id)
      return selectedTYpe?.nameAr ?? 'النوع'
    },
    async loadUnitsForParent() {
      this.$set(this.unitsAutoCompleteData, 'loading', true)
      try {
        let body = {
          environments: [this.currentEnv._id],
          parentId: this.addBond.realEstate?._id
        }
        if (this.clientTypeSelected === 'renter') {
          body = {
            ...body,
            user: this.addBond.client?._id,
            userType: this.clientRoles[this.clientTypeSelected]
          }
        }
        let _data = `pageSize=${this.paginationUnit.pageSize}&pageNumber=${this.paginationUnit.pageNumber}`
        this.unitSearchKey && (_data += `&search=${this.unitSearchKey}`)
        const { data } = await realEstateService.getRealEstates(body, _data)
        this.paginationUnit.pagesCount = Math.ceil(
          data?.count / this.paginationUnit.pageSize
        )
        this.paginationUnit.count = data?.count ?? 0
        this.availableUnitsForParent = [
          ...this.availableUnitsForParent,
          ...(data?.realEstates ?? [])
        ]
        if (!this.addBond.realEstate.hasOwnProperty('isSelectable')) {
          await this.getIsSelectable()
        }
        if (this.addBond?.realEstate?.isSelectable) {
          if (this.clientTypeSelected === 'renter') {
            this.availableUnitsForParent = [
              {
                name: 'كامل العقار',
                allocated: true
              }
            ]
          } else {
            this.availableUnitsForParent.unshift({
              name: 'كامل العقار',
              allocated: true
            })
          }
        }
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل الوحدات الداخلية',
          color: 'error'
        })
      } finally {
        this.$set(this.unitsAutoCompleteData, 'loading', false)
      }
    },
    async loadContracts() {
      this.$set(this.contractAutoCompleteData, 'loading', true)
      try {
        let dataUrl = ''
        if (this.contractSearchKey) {
          dataUrl = `&search=${this.contractSearchKey}`
        } else {
          dataUrl = `pageSize=${this.paginationContract.pageSize}&pageNumber=${this.paginationContract.pageNumber}`
        }
        const query = {
          realEstate: this.addBond?.[`${this.realestateKey}`]?._id,
          types: [this.getRoleData[this.clientTypeSelected].contractType],
          statuses: ['recorded', 'active', 'expired', 'terminated', 'approved'],
          parentId:
            this.realestateKey === 'unit' && this.clientTypeSelected === 'owner'
              ? this.addBond?.realEstate?._id
              : undefined
        }
        if (this.isExpenseRequestDefault) {
          query.realEstate = this.realEstateId
        }
        const { data } = await contractsService.getContracts(query, dataUrl)
        this.paginationContract.pagesCount = Math.ceil(
          data?.count / this.paginationContract.pageSize
        )
        this.paginationContract.count = data?.count
        this.contracts = data?.contracts ?? []
        this.contracts.map((contract) => {
          contract.madeUpTitle = `${contract.title.split(' - ')[1]} - ${
            contract.title.split(' - ')[2] ?? ''
          } ${contract.realEstate?.name?.split(' - ')[1] || ''} ${
            contract.realEstate?.name?.split(' - ')[3] || ''
          }`
          if (contract.madeUpTitle.length > 43) {
            contract.madeUpTitle = contract.madeUpTitle.slice(0, 43)
            contract.madeUpTitle += '...'
          }
        })
        this.AllContracts = [...this.AllContracts, ...this.contracts]
      } catch {
        this.addNotification({
          text: `حدث خطأ اثناء تحميل العقود الخاصه بالعميل`,
          color: 'error'
        })
      } finally {
        this.$set(this.contractAutoCompleteData, 'loading', false)
      }
    },
    formatUserObj(user) {
      if (user) {
        return {
          _id: user._id,
          name: user.name,
          email: user.email,
          phoneNumber: user.phoneNumber,
          type: this.clientTypeSelected
        }
      }
      return {}
    },
    async addBonds() {
      this.submitBtnLoading = true
      try {
        const {
          data: { addBond }
        } = await bondsService.addBonds(this.bondDataToAdd)
        this.addNotification({
          text: 'تم اضافه السند  بنجاح',
          color: 'success'
        })
        this.$emit('refreshBonds')
        addBond.needsApproval && this.showConfirmationPopupMixin()
      } catch (error) {
        const { message } = error
        this.addNotification({
          text:
            message === 'لا يمكنك دفع دفعات مالية تتضمن ضرائب مختلفة'
              ? message
              : 'حدث خطاء اثناء اضافه السند',
          color: 'error'
        })
      } finally {
        this.submitBtnLoading = false
        this.closeDialog()
      }
    },
    async editBonds() {
      this.submitBtnLoading = true
      try {
        await bondsService.editBonds(this.bondDataToEdit)
        this.addNotification({
          text: 'تم تعديل السند  بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطاء اثناء تعديل السند',
          color: 'error'
        })
      } finally {
        this.submitBtnLoading = false
        this.closeDialog()
      }
    },
    async loadClients(query) {
      let queryparams
      if (this.clientSearchKey) {
        queryparams = `search=${this.clientSearchKey}&${this.clientTypeSelected}sOnly=1`
      } else {
        queryparams = `pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}&${this.clientTypeSelected}sOnly=1`
      }
      try {
        this.$set(this.clientAutoCompleteData, 'loading', true)
        const { data } = await EnvService.getAllClients(
          this.currentEnv._id,
          query,
          queryparams
        )
        if (data && data?.users) {
          this.paginationClient.pagesCount = Math.ceil(
            data?.count / this.paginationClient.pageSize
          )
          this.paginationClient.count = data?.count
          this.client = data.users ?? []
          this.AllClient = [...this.AllClient, ...this.client]
          this.$set(this.clientAutoCompleteData, 'loading', false)
        }
      } catch {
        this.addNotification({
          text: 'حدث خطاء اثناء تحميل العملاء المتاحين',
          color: 'error'
        })
      } finally {
        this.$set(this.clientAutoCompleteData, 'loading', false)
      }
    },
    async getBondSections() {
      try {
        const { data } = await bondsService.bondsType()
        this.bondsType = data.bondsType
        if (this.isExpenseRequestDefault) {
          const exchangeBondType = this.bondsType.find(
            (bondType) => bondType.nameEn === 'exchange'
          )
          const expenseRequestSubType = exchangeBondType?.subTypes?.find(
            (subType) => subType.code === 'maintenanceRequest'
          )
          this.$set(this.addBond, 'bondType', exchangeBondType?._id)
          this.$set(this.addBond, 'subType', expenseRequestSubType?._id)
        }
      } catch {
        this.addNotification({
          text: 'خطأ عند تحميل انواع السند',
          color: 'error'
        })
      }
    },
    async getBondPaymentMethods() {
      try {
        const { data } = await bondsService.getBondPaymentMethods()
        this.paymentMethods = data.paymentMethods
      } catch {
        this.addNotification({
          text: 'خطأ عند تحميل طريقة الدفع للسند',
          color: 'error'
        })
      }
    },
    formatNumbers(number) {
      return number.toFixed(2)
      // .toLocaleString('en-US', {
      //   maximumFractionDigits: 2
      // })
    },
    getMetaProperty() {
      try {
        this.getBondSections()
      } catch (error) {
        this.addNotification({
          text: error,
          color: 'error'
        })
      }
    },
    async getNextInstallment() {
      try {
        const query = this.getInstallmentQuery()
        const { data } = await bondsService.nextInstallment(query)
        const { nextInstallmentDate, installmentEndDate } = data
        this.nextInstallmentStart = nextInstallmentDate
        this.nextInstallmentEnd = installmentEndDate
        this.contractTax = data.nextInstallmentTax
        this.utilitiesTax = data.nextInstallmentUtilitiesTax ?? 0
        this.utilitiesSwitchBtnTax = !!data.nextInstallmentUtilitiesTax
        this.taxAmount = !!data.nextInstallmentTax
        this.addBond.tax = this.contractTax
        this.addBond.utilitiesTax = this.utilitiesTax
        this.addBond.reference = ''
      } catch {
        this.addNotification({
          text: `حدث خطأ اثناء تحميل فترة الإستحقاق`,
          color: 'error'
        })
      }
    },
    clientChanged(client) {
      this.$set(this.addBond, 'client', client)
      this.$set(this.addBond, 'thirdParty', client.name)
      if (!client) {
        this.$set(this.addBond, 'client', null)
        return
      }
      if (!this.isDefaultValueProperty) {
        this.$set(this.addBond, 'realEstate', null)
        this.paginationProperty.pageNumber = 1
        this.AllProperties = []
        this.loadProperties()
      } else {
        this.getIsSelectable()
        this.paginationContract.pageNumber = 1
        this.AllContracts = []
        this.loadContracts()
      }
    },

    async getSuggestedBondValue() {
      try {
        this.isSuggestedValueLoading = true
        this.query = {
          bondType: this.addBond.bondType,
          bondSubType: this.addBond.subType,
          client: this.addBond.client._id,
          environment: this.currentEnv._id,
          contract: this.addBond.contract._id,
          ...this.query,
          isRealestateParent: this.realestateKey === 'realEstate',
          realEstate: this.addBond?.[`${this.realestateKey}`]?._id
        }

        if (this.defaultValues?.expenseRequestId?.length) {
          this.query.expenseRequestId = this.defaultValues?.expenseRequestId
        }
        const { data } = await bondsService.getSuggestedBondValue(
          buildQueryString(this.query, '')
        )
        if (!this.firstCallForSugValFn) {
          // call this just one time
          this.isThereMarktingCut = data.marketingCreditsAmount
          this.isTherePmCut = data.creditsAmount
        }
        if (this.isRentCombined) {
          this.$set(this.addBond, 'bondValue', data?.combinedDetails?.rent ?? 0)
          this.$set(this.addBond, 'tax', data?.combinedDetails?.rentTax ?? 0)
          this.$set(
            this.addBond,
            'utilities',
            data?.combinedDetails?.utilities ?? 0
          )
          this.$set(this.addBond, 'currentTax', data?.currentTax ?? 0)
          this.$set(
            this.addBond,
            'rentTaxAmount',
            data?.combinedDetails?.rentTaxAmount ?? 0
          )

          this.handleValuesChanging(this.addBond.utilities, this.utilitiesKeys)
          this.addBond.utilitiesTaxAmount =
            data?.combinedDetails?.utilitiesTaxAmount ?? 0
          this.$set(
            this,
            'utilitiesTax',
            data?.combinedDetails?.utilitiesTax ?? 0
          )
          this.addBond.utilitiesTax = this.utilitiesTax
          this.utilitiesSwitchBtnTax = !!this.utilitiesTax
        } else {
          this.$set(this.addBond, 'bondValue', data.value)
          this.$set(this.addBond, 'tax', data.tax)
        }
        this.$set(
          this,
          'duePmAmount',
          this.formatNumbers(0 - data?.creditsAmount) ?? 0
        )
        this.$set(this, 'totalCutting', this.formatNumbers(data?.total))
        this.$set(
          this,
          'dueMarketingAmount',
          this.formatNumbers(0 - data?.marketingCreditsAmount) ?? 0
        )
        this.updateTotalValue(this.addBond.bondValue)
      } finally {
        this.isSuggestedValueLoading = false
        this.firstCallForSugValFn = true
      }
    },
    async getExpensesRequests() {
      try {
        this.expensesLoading = true
        const { data } = await bondsService.getExpensesRequests(
          this.expensesFilter
        )
        this.expenses = data.data || []
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل طلبات الصرف',
          color: 'error'
        })
      } finally {
        this.expensesLoading = false
      }
    },
    handleBondExpensesRequests() {
      this.selectAllAvailableExpenses = !!(
        this.addBond.expenses.length === this.expenses.length
      )
    },
    handleSelectAll(val) {
      this.addBond.expenses = val ? this.expenses : []
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_add-new-property.scss';
.claim-switch {
  width: 1px !important;
  margin-top: 1px !important;
}
.tax-status-tag {
  border-radius: 30px;
}
.tax-inactive-tag {
  background-color: #efefef;
  color: #737373;
}
.max-height-550 {
  max-height: 550px !important;
}
.file-continer {
  &-col {
    .v-text-field--outlined {
      font-size: 12px;
      & fieldset {
        border: 1px dashed #ddd;
        color: #662d91;
      }
    }
  }
}
</style>
