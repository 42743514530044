<template>
  <div>
    <bankAccountsListing
      class="mb-10"
      :bankAccountsDetails="bankAccountsDetails"
      v-if="bankAccountsDetails.length"
      @setBankAccountDetails="setBankAccountDetails"
      @updateBankAccountsList="getEnvBankAccounts"
      v-on="$listeners"
    />
    <NoData v-else v-bind="noDataConfig" />
    <bankAcountsEditModel
      v-if="dialogState"
      :bankAccountDetails="bankAccountDetails"
      :dialogState="dialogState"
      @changeDialogState="changeDialogState($event)"
      @updateBankAccountsList="getEnvBankAccounts"
      @addBankAccount="addBankAccount"
    />
    <!-- v-on="$listeners" -->
    <div
      class="d-flex align-center justify-end ml-md-8 fast-actions rounded-lg"
    >
      <v-btn
        data-test="openPaymentDialog"
        class="font-weight-bold fast-actions-btn pa-5 font-12"
        color="primary"
        large
        @click="changeDialogState(true)"
      >
        <span class="">إضافة حساب </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import NoData from '@/components/helper/noData.vue'
import { EnvService } from '@/services'

export default {
  name: 'bankAccounts',
  components: {
    NoData,
    bankAccountsListing: () =>
      import('@/components/details/bankAccountsListing.vue'),
    bankAcountsEditModel: () =>
      import('@/components/core/bankAcountsEditModel.vue')
  },
  props: {
    isBankAccountSaveBtn: {
      default: false,
      type: Boolean
    }
  },
  data: () => {
    return {
      dialogState: false,
      addBanksDataLoading: false,
      bankAccountsDetails: {},
      noBankAccounts: require('@/assets/img/noBankAccouns.png'),
      bankAccountDetails: {}
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    hasAddBankAccPermission() {
      return !!this.$hasPermission('users', 'bankAccountsSettings', 'add')
    },
    noDataConfig() {
      return {
        noDataText: 'لا توجد حسابات بنكية حتى الأن',
        noDataSubTitle: '',
        isbgPrimary: false,
        noDataIcon: this.noBankAccounts,
        widthHeight: '80'
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setBankAccountDetails(value) {
      this.dialogState = true
      this.bankAccountDetails = { ...value }
    },
    async getEnvBankAccounts() {
      try {
        const { data } = await EnvService.getEnvOrClientBankAccounts(
          this.currentEnv?._id
        )
        this.bankAccountsDetails = data.bankAccounts
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل بيانات الحساب',
          color: 'error'
        })
      }
    },
    async addBankAccount(bankAccountsData) {
      try {
        this.addBanksDataLoading = true
        const payload = {
          ...bankAccountsData,
          entityId: this.currentEnv?._id,
          entityType: 'Environment',
          environmentId: this.currentEnv?._id
        }
        delete payload._id
        await EnvService.addEnvBankAccount({ bankAccounts: [payload] })
        this.getEnvBankAccounts()
      } catch {
        this.addNotification({
          text: 'لم نتمكن من إضافة حسابك البنكي, من فضلك حاول في وقت لاحق',
          color: 'error'
        })
      } finally {
        this.addBanksDataLoading = false
        this.changeDialogState(false)
      }
    },
    changeDialogState(value) {
      this.dialogState = value
      this.bankAccountDetails = {
        bankName: '',
        name: '',
        iban: '',
        _id: ''
      }
    }
  },
  mounted() {
    this.getEnvBankAccounts()
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
</style>
