const innerDetails = {
  finishing: [
    { name: 'تشطيب كامل', value: 'full' },
    { name: 'تشطيب جزئي', value: 'partial' },
    { name: 'غير مشطبة', value: 'empty' }
  ],
  furnishing: [
    {
      name: 'نعم',
      value: true
    },
    {
      name: 'لا',
      value: false
    }
  ],
  conditioning: [
    {
      name: 'نعم',
      value: true
    },
    {
      name: 'لا',
      value: false
    }
  ]
}

export { innerDetails }
