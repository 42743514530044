<template>
  <v-dialog
    class="backdrop"
    overlay-opacity="0.5"
    persistent
    v-model="dialogState_"
    width="530px"
  >
    <v-card class="add-new-property pt-0">
      <v-card-title
        class="pa-0 pr-5 pl-1 mb-5 d-flex justify-space-between align-center custom__shadow"
      >
        <div class="d-flex align-center">
          <h4>اختيار الحسابات البنكيه المرتبطه بالعقار</h4>
        </div>

        <v-btn
          data-test="close"
          @click.prevent="closeModel"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form class="my-2" v-model="isValid">
            <v-row>
              <v-col class="mobile__pl__0 pr-0" cols="12" sm="12">
                <h6 class="mt-0 mb-2">
                  اختر الحسابات البنكية المعتمدة لهذا العقار
                </h6>
                <v-select
                  :items="bankAccountsTypes"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  hide-details
                  item-text="nameAr"
                  item-value="value"
                  return-object
                  v-model="bankAccountsType_"
                  @input="changeBankAccountsList"
                  :disabled="!hasAddBankAccountsPermission"
                />
              </v-col>
              <Loader v-bind="loaderProps" v-if="isLoading" />
              <template v-else-if="currentshownBankAccounts?.length">
                <v-col
                  class="mobile__pl__0 pr-0 ma-0 py-1"
                  cols="12"
                  sm="12"
                  v-for="(account, i) in currentshownBankAccounts"
                  :key="i"
                >
                  <div class="d-flex align-center justify-space-between">
                    <div class="d-flex">
                      <v-checkbox
                        dense
                        hide-details
                        class="my-auto"
                        :value="account._id"
                        v-model="selectedBankAccounts"
                        :disabled="!hasAddBankAccountsPermission"
                      />
                      <p class="my-auto font-14 text--101010">
                        {{ account?.bankName }}
                      </p>
                    </div>
                    <p class="my-auto font-14 text--101010">
                      {{ account?.name }}
                    </p>
                    <p class="my-auto font-14 text--101010">
                      {{ account?.iban }}
                    </p>
                  </div>
                </v-col>
              </template>
              <div v-else class="text-center pt-5 mx-auto">
                <p class="font-18 text-101010">
                  {{ noAccountsText.title }}
                </p>
                <p class="font-14 text-101010">
                  {{ noAccountsText.subTitle }}
                </p>
              </div>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary darken-1"
          @click.native="updateRealestateBankAccount"
          :loading="updateLoadingBtn"
          :disabled="!hasAddBankAccountsPermission"
        >
          حفظ التغييرات
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EnvService } from '@/services'
import Loader from '@/components/helper/loader.vue'
export default {
  name: 'bankAcountsSelectModel',
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    realEstateBankAccounts: {
      type: Array,
      default() {
        return []
      },
      required: true
    },
    realEstate: {
      default() {
        return {}
      }
    }
  },
  components: {
    Loader
  },
  data() {
    return {
      bankAccountsType: {},
      bankAccountsTypes: [
        {
          id: 0,
          nameAr: 'حسابات الشركة'
        },
        {
          id: 1,
          nameAr: 'حسابات المالك'
        }
      ],
      isValid: false,
      selectedBankAccounts: [],
      currentshownBankAccounts: [],
      allBankAccounts: {
        company: [],
        owner: []
      },
      isOwnerAccountsLoaded: false,
      isLoading: true,
      updateLoadingBtn: false
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    changeBankAccountsList(value) {
      if (!value.id) {
        this.currentshownBankAccounts = this.allBankAccounts.company
      } else {
        // eslint-disable-next-line no-unused-expressions
        !this.isOwnerAccountsLoaded
          ? this.getOwnerBankAccounts()
          : (this.currentshownBankAccounts = this.allBankAccounts.owner)
      }
      this.selectedBankAccounts = []
    },
    async getOwnerBankAccounts() {
      try {
        this.isLoading = true
        const { data } = await EnvService.getEnvOrClientBankAccounts(
          this.currentEnv?._id,
          this.realEstate?.owner?._id
        )
        this.currentshownBankAccounts = data.bankAccounts ?? []
        this.allBankAccounts.owner = data.bankAccounts
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل بيانات حسابات العميل, حاول في وقت لاحق',
          color: 'error'
        })
      } finally {
        this.isOwnerAccountsLoaded = true
        this.isLoading = false
      }
    },
    async getCompanyBankAccounts() {
      try {
        const { data } = await EnvService.getEnvOrClientBankAccounts(
          this.currentEnv?._id
        )
        this.currentshownBankAccounts = data.bankAccounts
        this.allBankAccounts.company = this.currentshownBankAccounts
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل بيانات حسابات الشركه, حاول في وقت لاحق',
          color: 'error'
        })
      } finally {
        this.setCurrentBankAccountsData()
      }
    },
    setCurrentBankAccountsData() {
      // Checked all current realestate bank accounts
      this.selectedBankAccounts = this.realEstateBankAccounts.map(
        (account) => account._id
      )

      // disable loading state after getting data
      this.isLoading = false

      // Check if the current realestate bank accounts choasen from company or client
      if (this.realEstateBankAccounts.length) {
        const isFounded = this.allBankAccounts.company?.find((companyAcc) => {
          return this.realEstateBankAccounts[0]?._id === companyAcc?._id
        })
        if (!isFounded) {
          this.bankAccountsType_ = this.bankAccountsTypes[1]
          this.getOwnerBankAccounts()
        }
      }
    },
    closeModel() {
      this.dialogState_ = false
      this.currentshownBankAccounts = []
    },
    async updateRealestateBankAccount() {
      this.updateLoadingBtn = true
      try {
        const payload = {
          realEstateId: this.realEstate?._id,
          bankAccounts: [...this.selectedBankAccounts]
        }
        await EnvService.updateRealestateBankAccounts(payload)
        this.$emit('refreshBankAccounts')
        this.closeModel()
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحديث بيانات الحساب, حاول في وقت لاحق',
          color: 'error'
        })
      } finally {
        this.updateLoadingBtn = false
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    hasAddBankAccountsPermission() {
      return !!this.$hasPermission('users', 'bankAccountsSettings', 'add')
    },
    loaderProps() {
      return {
        numberOfLines: 3,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'table-heading',
        cols: 12
      }
    },
    noAccountsText() {
      return !this.bankAccountsType_.id
        ? {
            title: 'لا توجد حسابات مضافة لمالك هذا العقار',
            subTitle:
              'يمكنك إضافة حسابات بنكية لهذا العميل من خلال الصفحه الخاصه به'
          }
        : {
            title: 'لا توجد حسابات مضافة للشركه',
            subTitle:
              'يمكنك إضافة حسابات بنكية للشركة من خلال إعدادات الحساب في الإعدادات'
          }
    },
    bankAccountsType_: {
      get() {
        return Object.keys(this.bankAccountsType).length
          ? this.bankAccountsType
          : this.bankAccountsTypes[0]
      },
      set(value) {
        this.bankAccountsType = value
      }
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  created() {
    this.getCompanyBankAccounts()
  }
}
</script>
