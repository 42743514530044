<template>
  <v-container class="px-0">
    <v-card class="mb-3 clients__realestate mobile__mb__10px" width="100%">
      <v-card-title class="d-flex pa-4">
        <v-row class="align-center mobile__justify__between pa-2">
          <div class="font-16" v-if="client">عقارات العميل</div>
          <v-slide-x-transition>
            <v-card
              @click="showFilter = !showFilter"
              class="d-flex justify-space-between px-2 cursor mr-4 mobile__w__auto mobile__my__15px mobile__ml__10px"
              v-click-outside="setOutSidetypes"
              width="105"
            >
              <h6>نوع العقار</h6>
              <v-icon>{{
                showFilter ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
            </v-card>
          </v-slide-x-transition>

          <v-slide-x-transition v-if="selectedRole !== 'renter'">
            <v-card
              class="d-flex justify-space-between px-2 mx-3 mobile__my__15px mobile__ml__0 mobile__mr__0"
            >
              <h6
                :class="{ 'border-black': filteredStatusIds.length == 0 }"
                @click="resetFilterStatus"
                class="cursor mx-3"
              >
                الكل
              </h6>

              <h6
                :class="{
                  'border-black': filteredStatusIds.includes(status._id)
                }"
                @click="filterStatus(status._id)"
                class="cursor mx-3"
                v-for="status in realEstateStatuses"
                :key="status._id"
              >
                {{ status.nameAr }}
              </h6>
            </v-card>
          </v-slide-x-transition>

          <v-card
            class="position-absolute z-index-3 pt-0 top_50 mobile__w__auto mobile__pr__4px"
            v-if="showFilter"
            width="230"
          >
            <v-row align="center" class="pa-3" no-gutters>
              <v-col
                class="py-0"
                md="4"
                v-for="type in configs.realestateTypes"
                :key="type._id"
              >
                <h6
                  :class="{
                    'brub--tag-border': filteredTypes.includes(type._id)
                  }"
                  @click="filterTypes(type._id)"
                  class="text-center roundeed-tag px-3 py-1 cursor ml-1 mobile__wb-normal"
                >
                  {{ type.nameAr }}
                </h6>
              </v-col>
              <v-col class="text-left" md="8">
                <a
                  @click="resetTypes"
                  class="text-decoration-underline font-12 black--text"
                  >إعادة تعيين</a
                >
              </v-col>
            </v-row>
          </v-card>
          <div
            class="mobile__mt__10px d-flex mobile__mt__15px"
            :class="{
              'max-w-170':
                getBreakPoint['md'] ||
                getBreakPoint['sm'] ||
                getBreakPoint['xs']
            }"
          >
            <v-text-field
              height="40"
              width="100"
              dense
              filled
              placeholder="ابحث بإسم الوحده..."
              :hide-details="true"
              v-model="search"
              @input="searchForRealEstates"
            />
          </div>
          <v-spacer v-for="i in 4" :key="i" class="mobile__d__none" />
          <div class="mobile__mt__10px d-flex" v-if="realEstates.length">
            <h6
              class="mobile__mb__0"
              v-if="getBreakPoint['lgAndUp'] && !getBreakPoint['lgOnly']"
            >
              <span class="text-gray"> عدد العقارات المتاحه من المعروض</span>
              <span class="mr-1 ml-5">{{ pagination.count }}</span>
            </h6>
            <!-- ---------------------------- -->
            <!-- SWITCH BETWEEN GRID AND CARD -->
            <!-- ---------------------------- -->
            <div class="d-flex align-center">
              <v-slide-x-transition>
                <v-card
                  width="55"
                  height="40"
                  class="d-flex justify-space-between px-2 mr-3"
                >
                  <div
                    class="d-flex justify-center align-center grid-view selected-grid-view"
                  >
                    <img
                      class="cursor"
                      :src="noGrid"
                      width="14"
                      @click="setGridView(true)"
                      loading="lazy"
                    />
                  </div>
                  <div class="d-flex justify-center align-center grid-view">
                    <img
                      class="cursor"
                      :src="grid"
                      width="14"
                      @click="setGridView(false)"
                      loading="lazy"
                    />
                  </div>
                </v-card>
              </v-slide-x-transition>
            </div>
          </div>
        </v-row>
      </v-card-title>
    </v-card>

    <!----------Properties Loader--------->
    <loader v-if="isRealestateLoding" v-bind="loaderProps" class="mt-2" />

    <!----------No Realestates-------->
    <v-sheet
      class="pa-10 d-flex align-center justify-center flex-column bg-primary ma-auto"
      v-else-if="!realEstates.length"
    >
      <span class="grey--text text--lighten-1 mb-4" style="font-size: 80px">
        <img
          src="../../assets/img/svgs/Hectar icon/r-building.svg"
          alt="NO Data Building Image"
          width="50"
          loading="lazy"
        />
      </span>
      <h3 v-if="client" class="grey--text text--darken-3 my-0">
        لا توجد عقارات مضافة لهذا العميل حتى الآن
      </h3>
      <h3 v-else class="grey--text text--darken-3 my-0">
        لا توجد وحدات مضافة {{ propertyType }}
      </h3>
    </v-sheet>

    <!-- SLIDER FOR CLIENTS PROPERTIES -->
    <v-carousel
      v-else-if="showSlider"
      :light="!$vuetify.theme.dark"
      class="client mobile__mb__0"
      height="388"
      hide-delimiter-background
      hide-delimiters
    >
      <template v-slot:prev="{ on, attrs }">
        <div
          class="arrowOutlined d-flex align-center mx-3"
          v-bind="attrs"
          v-on="on"
          v-if="!stateRealEstatesLength"
        >
          <img :src="leftArrow" alt="leftArrow" class="cursor" loading="lazy" />
        </div>
      </template>
      <template v-slot:next="{ on, attrs }">
        <div
          v-if="!stateRealEstatesLength"
          class="arrowOutlined d-flex align-center"
          v-bind="attrs"
          v-on="on"
        >
          <img
            :src="rightArrow"
            alt="rightArrow"
            class="cursor"
            loading="lazy"
          />
        </div>
      </template>
      <template v-for="(item, index) in realEstates">
        <v-carousel-item
          class="pt-8"
          :key="index"
          v-if="(index + 1) % columns === 1 || columns === 1"
        >
          <div class="row flex-nowrap pa-0 ma-0">
            <v-col
              :md="columns - 1"
              v-for="(n, i) in columns"
              :key="i"
              v-show="index + i < realEstates.length"
              :class="generateCardClasses(n)"
            >
              <PropertyCardGrid
                :class="{
                  activePropertyCard:
                    showActive && realEstateId === realEstates[index + i]._id
                }"
                :options="realEstates[index + i]"
                propertyName="العقار"
                usedFor="property"
                :showDetails="!!client"
                @del-property="deleteConfirm"
                @edit-property="editProperty"
                v-if="index + i < realEstates.length"
              >
              </PropertyCardGrid>
            </v-col>
          </div>

          <v-card
            class="pa-2 d-flex justify-space-between w-100 mobile__w__auto mobile__mr__10px mobile__ml__10px mobile__mb__0 max-height"
          >
            <div class="w-100 d-flex justify-space-around">
              <h4
                :class="{ 'border-black': filterID === clientId }"
                @click="getPropertyMeta()"
                class="font-12 cursor mb-0"
              >
                الكل
              </h4>
              <h4
                :md="columns - 1"
                v-for="(n, i) in columns"
                :key="i"
                class="slide"
                :class="[
                  'font-12 cursor mb-0',
                  {
                    'border-black':
                      filterID === getRealEstateId(realEstates[index + i])
                  }
                ]"
                @click="
                  getPropertyMeta(
                    getRealEstateId(realEstates[index + i]),
                    'realEstate'
                  )
                "
              >
                {{ getRealEstateName(realEstates[index + i]) }}
              </h4>
            </div>
          </v-card>
        </v-carousel-item>
      </template>
    </v-carousel>

    <div v-else>
      <!-- ------------------- -->
      <!-- DATA TABLE PROPERTY -->
      <!-- ------------------- -->
      <v-col cols="12" class="pa-0" v-if="!gridView">
        <DataTableProperty
          class="mt-2"
          :properties="realEstates"
          :headers="tableHeaders"
          @updateRealEstate="updateRealEstate"
        />
      </v-col>

      <!-- ---------------------------- -->
      <!-- PROPERTY CARD FOR REALESTATS -->
      <!-- ---------------------------- -->
      <div v-else-if="gridView && !type" class="w-100">
        <v-row>
          <v-col
            :key="item._id"
            :lg="3"
            :md="4"
            :sm="6"
            cols="12"
            v-for="item in realEstates"
          >
            <PropertyCardGrid
              :options="item"
              :showDetails="false"
              @del-property="deleteConfirm"
              @edit-property="editProperty"
              propertyName="العقار"
              usedFor="property"
              :is-from-details="isFromDetails"
            >
            </PropertyCardGrid>
          </v-col>
        </v-row>
      </div>
      <div
        class="mt-6 position-relative container"
        v-if="pagination.pagesCount > 1"
      >
        <Pagination
          :pagination="pagination"
          @onChange="getOwnerRealEstates()"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { realEstateService } from '@/services'
import PropertyCardGrid from '@/components/properties/PropertyCardGrid'
import DataTableProperty from '@/components/properties/DataTableProperty'
import Pagination from '@/components/helper/pagination.vue'
import { debounce } from '../../helpers/debounce'
import loader from '@/components/helper/loader.vue'
import setView from '@/mixins/setView'

export default {
  name: 'ClientDetailsRealEstateList',
  components: { PropertyCardGrid, Pagination, DataTableProperty, loader },
  mixins: [setView],

  props: {
    filterID: {
      type: String,
      default: ''
    },
    clientId: {
      type: String
    },
    cols: { default: 4 },
    data: [],
    owner: {},
    user: {},
    PropertyId: {},
    envId: {},
    client: { default: false },
    pageCount: { default: 1 },
    isFromDetails: {
      type: Boolean,
      default: false
    },
    realEstateType: {
      type: String
    },
    getPropertyMeta: {
      type: Function
    },
    selectedRole: {
      type: String
    },
    type: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    isRealestateLoding: false,
    expand: false,
    realEstates: [],
    sliderArr: [],
    filteredTypes: [],
    filteredStatusIds: [],
    showFilter: false,
    gridView: true,
    model: 0,
    timer: null,
    currentIndex: 0,
    leftArrow: require('@/assets/img/svgs/roundedArrow.svg'),
    rightArrow: require('@/assets/img/svgs/rightArr.svg'),
    colIcon: require('@/assets/img/svgs/colIcon.svg'),
    colIconColored: require('@/assets/img/svgs/colIconColored.svg'),
    rowIcon: require('@/assets/img/svgs/rowIcon.svg'),
    rowIconColored: require('@/assets/img/svgs/rowIconColored.svg'),
    filters: {},
    showActive: false,
    realEstateId: '',
    tableHeaders: [
      { text: ' ', value: 'active' },

      { text: 'اسم الوحدة', value: 'name' },
      {
        text: 'نوع العقار',
        align: 'center',
        sortable: false,
        value: 'type'
      },
      { text: 'المساحه', value: 'space', align: 'center' },
      { text: 'السعر', value: 'price', align: 'center' },
      { text: 'المسؤول', value: 'moderators', align: 'center' },
      { text: 'المالك', value: 'owner.name', align: 'center' },
      { text: 'المستأجر', value: 'tenet.name', align: 'center' },
      { text: 'الحاله', value: 'status', width: 70 }
    ],
    pagination: {
      pageNumber: 1,
      pagesCount: 0,
      pageSize: 12,
      count: 0
    },
    search: '',
    clientRoles: {
      renter: 'tenet',
      owner: 'owner'
    }
  }),
  async created() {
    if (!this.client) {
      await this.getOwnerRealEstates()
    }
  },
  watch: {
    pageCount(val) {
      if (
        this.pageCount >
        Math.ceil(this.pagination.count / this.pagination.pageSize)
      ) {
        return
      }
      this.pagination.pageNumber = val
      this.getOwnerRealEstates()
    },
    filteredStatus() {
      this.getOwnerRealEstates()
    },
    selectedRole() {
      this.getOwnerRealEstates()
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'currentEnv']),
    loaderProps() {
      return {
        numberOfLines: 4,
        laoderClasses: 'mx-auto',
        type: 'card'
      }
    },
    getBreakPoint() {
      return this.$vuetify.breakpoint || {}
    },
    columns() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 1
        case 'sm':
          return 2
        default:
          return 4
      }
    },
    grid() {
      return this.gridView ? this.rowIcon : this.rowIconColored
    },
    noGrid() {
      return this.gridView ? this.colIconColored : this.colIcon
    },
    showSlider() {
      return (
        this.realEstates.length &&
        !this.PropertyId &&
        this.gridView &&
        this.type
      )
    },
    stateRealEstatesLength() {
      return this.realEstates.length <= 2
    },
    filteredStatus() {
      return this.filteredStatusIds[0]
    },
    propertyType() {
      if (this.realEstateType === 'مجمع') {
        return `لهذا ال${this.realEstateType}`
      }
      return `لهذه ال${this.realEstateType}`
    },
    realEstateStatuses() {
      return (
        this.configs.realEstateStatus?.map((status) => {
          const realEstateStatus = { ...status }
          if (
            realEstateStatus.nameEn === 'rented' ||
            realEstateStatus.nameEn === 'sold'
          ) {
            realEstateStatus.nameAr += 'ة'
          }
          return realEstateStatus
        }) ?? []
      )
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    propertyToSend(addPropertyInfo) {
      const dataToAdd = {
        name: addPropertyInfo.name,
        type: addPropertyInfo.type?._id,
        status: addPropertyInfo.status?._id,
        price: addPropertyInfo.price,
        space: addPropertyInfo.space,
        propertyAddress: {
          address:
            addPropertyInfo.location.address ||
            addPropertyInfo.propertyAddress.address,
          city:
            addPropertyInfo?.location?.city ||
            addPropertyInfo?.propertyAddress?.city
        },
        location: {
          type: addPropertyInfo.location.type,
          coordinates: {
            lat: addPropertyInfo.location.coordinates[1],
            lng: addPropertyInfo.location.coordinates[0]
          }
        },
        owner: addPropertyInfo.owner,
        numberOfKitchenUnit: Number(addPropertyInfo.numberOfKitchenUnit)
          ? Number(addPropertyInfo.numberOfKitchenUnit)
          : 0,
        numberOfRooms: Number(addPropertyInfo.numberOfRooms)
          ? Number(addPropertyInfo.numberOfRooms)
          : 0,
        numberOfBathRoom: Number(addPropertyInfo.numberOfBathRoom)
          ? Number(addPropertyInfo.numberOfBathRoom)
          : 0,
        numberOfLivingRoom: Number(addPropertyInfo.numberOfLivingRoom)
          ? Number(addPropertyInfo.numberOfLivingRoom)
          : 0,
        numberOfFloors: Number(addPropertyInfo.numberOfFloors)
          ? Number(addPropertyInfo.numberOfFloors)
          : 0,
        age: Number(addPropertyInfo.age) ? Number(addPropertyInfo.age) : 0,
        showInHectar: addPropertyInfo.showInHectar || false,
        images: addPropertyInfo.images || [],
        bluePrint: addPropertyInfo.bluePrint || {},
        moderators: [...addPropertyInfo.moderators],
        account: addPropertyInfo.showHectarAccount || this.currentEnv.owner,
        tags: addPropertyInfo.tags,
        environment: this.currentEnv?._id,
        streetWidth: Number(addPropertyInfo.streetWidth),
        _id: addPropertyInfo?._id,
        fromOwner: addPropertyInfo.hasOwnProperty('fromOwner')
          ? addPropertyInfo.fromOwner
          : true,
        length: Number(addPropertyInfo.length),
        width: Number(addPropertyInfo.width),
        builtAt: addPropertyInfo.builtAt,
        selectedSides: addPropertyInfo.selectedSides,
        notes: addPropertyInfo.notes
      }
      if (this.accommodationType) {
        dataToAdd.populationType = addPropertyInfo.populationType?._id
      }
      if (this.isForRent) {
        dataToAdd.payType = addPropertyInfo.payType?._id
      }
      if (addPropertyInfo?.propertyPurpose?._id) {
        dataToAdd.purpose = addPropertyInfo?.propertyPurpose?._id
      }
      if (!addPropertyInfo.fromOwner) {
        dataToAdd.authorizationNumber = addPropertyInfo.authorizationNumber
      }
      return dataToAdd
    },
    async updateRealEstate(realEsate) {
      try {
        await realEstateService.updateRealEstate(this.propertyToSend(realEsate))
      } catch {
        this.addNotification({
          text: 'حدث خطأ اثناء تحديث العقار',
          color: 'error'
        })
      }
    },
    getRealEstateName(item) {
      const realestate = this.realEstates.find(
        (realEstate) => realEstate._id === item?._id
      )
      return realestate?.propertyName
    },
    getRealEstateId(item) {
      const realestate = this.realEstates.find(
        (realEstate) => realEstate._id === item?._id
      )
      return realestate?._id
    },
    setOutSidetypes() {
      this.showFilter = false
    },
    filterTypes(typeId) {
      const typeIndex = this.filteredTypes.findIndex((type) => type === typeId)
      if (typeIndex !== -1) {
        this.filteredTypes.splice(typeIndex, 1)
      } else {
        this.filteredTypes.push(typeId)
      }
      this.getOwnerRealEstates()
    },
    filterStatus(statusId) {
      this.filteredStatusIds = []
      this.filteredStatusIds.push(statusId)
      this.pagination.pageNumber = 1
    },
    resetTypes() {
      this.filteredTypes = []
      this.getOwnerRealEstates()
    },
    resetFilterStatus() {
      this.filteredStatusIds = []
      this.pagination.pageNumber = 1
    },
    changeInPropertyFilter() {
      if (this.user) {
        this.filters.user = this.user
        this.filters.userType = this.clientRoles[this.selectedRole]
      }
      if (this.PropertyId) {
        this.filters.parentId = this.PropertyId
      }
      if (this.envId) {
        this.filters.environments = this.envId
      }
      if (this.filteredTypes.length > 0) {
        this.filters.types = this.filteredTypes
      } else {
        delete this.filters.types
      }

      if (!this.client || this.selectedRole === 'owner') {
        if (this.filteredStatusIds.length > 0) {
          this.filters.statuses = this.filteredStatusIds
        } else {
          delete this.filters.statuses
        }
      }

      if (this.selectedRole === 'renter') {
        const rentedFilter = this.configs?.realEstateStatus.find(
          (status) => status.nameEn === 'rented'
        )
        this.filters.statuses = [rentedFilter?._id]
        delete this.filters.child
      } else if (this.selectedRole === 'owner') {
        this.filters.child = false
      }
    },
    async getOwnerRealEstates() {
      this.changeInPropertyFilter()
      try {
        this.isRealestateLoding = true
        let query = `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
        this.search && (query += `&search=${this.search}`)
        const body = { ...this.filters, environments: [this.currentEnv._id] }
        const { data } = await realEstateService.getRealEstates(body, query)

        const statusOwnerRealEstates =
          // eslint-disable-next-line no-unneeded-ternary
          data?.realEstates.length === 0 ? false : true
        this.pagination.pagesCount = Math.ceil(data?.count / data?.pageSize)
        this.realEstates = data?.realEstates ?? []
        this.pagination.count = data?.count
        const realEstatesResponseObj = {
          realestateLength: this.realEstates.length,
          realEstatesCount: this.pagination.count
        }
        this.$emit('showOwnerRealEstates', statusOwnerRealEstates)
        this.$emit('realEstatesResponse', realEstatesResponseObj)
        this.$emit('realEstates', this.realEstates)
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل العقارات',
          color: 'error'
        })
      } finally {
        this.isRealestateLoding = false
      }
    },
    setGridView(status) {
      this.updateSetTableView(this.$route.name, status)
      this.gridView = status
    },
    editProperty(val) {
      this.$parent.dialogState = true
      this.$parent.realEstate = val
    },
    deleteConfirm(id) {
      this.$root.$confirm
        .open({
          title: 'حذف العقار',
          message: 'هل أنت متأكد من حذف العقار، العملية لا يمكن استرجاعها',
          options: {
            color: 'danger',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            this.delProperty(id)
          }
        })
    },
    delProperty(id) {
      realEstateService
        .deleteRealEstate(id)
        .then(() => {
          this.addNotification({
            text: `تم الحذف بنجاح`,
            color: 'success'
          })
          this.getProperties()
        })
        .catch(() => {
          this.addNotification({
            text: `حدث خطاء`,
            color: 'error'
          })
        })
    },
    generateCardClasses(n) {
      if (this.columns > 1 && n === 1) {
        return 'pr-0'
      } else if (
        (this.columns === 4 && n === 4) ||
        (this.columns === 2 && n === 2)
      ) {
        return 'pl-0'
      }
    },
    searchForRealEstates: debounce(function (searchText) {
      this.getOwnerRealEstates()
    }, 600)
  }
}
</script>
<style lang="scss">
@import '@/styles/material-dashboard/_Carousal-customization.scss';
@import '@/styles/material-dashboard/_variables.scss';

.border-black {
  padding-bottom: 3px;
  border-bottom: 3px solid $primary-color;
  border-radius: 0 !important;
  margin-bottom: 0;
}

.top_50 {
  top: 70px;
}
</style>
