<template>
  <div
    class="show-detailes approval-details contentSection contracts__bonds__side__deatails"
  >
    <v-slide-x-transition>
      <v-flex class="whiteSide flex-wrap">
        <DetailsHeader
          :quotation="quotation"
          @accept="acceptHandler"
          @reject="$emit('reject', 'rejected')"
          @openPDF="$emit('openPDF')"
          @openOfferModel="$emit('openOfferModel', { quotation })"
          @cancelled="$emit('cancelled', 'cancelled')"
          @closeDetails="closeDetails"
        />
        <v-divider class="mt-2"></v-divider>
        <v-layout class="sidedetails__layout mobile__screen">
          <v-layout class="sidedetails__layout">
            <v-flex class="pl-5">
              <v-tabs
                class="tabs-container me-5"
                color="primary"
                align-with-title
                right
                v-model="tab"
              >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                  v-for="tab in tabs"
                  :key="tab.title"
                  color="#737373"
                  class="font-14 me-5"
                >
                  {{ tab.title }}
                </v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <h5 class="ma-0 font-20 mt-5 mx-10">
                {{ tabs.slice(0, tabs.length - 1)[tab]?.title }}
              </h5>
              <div class="contentSection">
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="{ title, component, data } in tabs"
                    :key="title"
                    :eager="true"
                  >
                    <component
                      :is="component"
                      :title="titleForAttachment"
                      :openAttachmentStatus="openAttachmentStatus"
                      :attachTypes="attachTypes"
                      :quotation="data"
                      :realEstats="data"
                      :quotationId="data"
                      @restAttachmentData="restAttachmentData"
                      @accept="$emit('accept', 'accepted')"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-flex>
          </v-layout>
          <!-- Comments -->
          <v-divider vertical class="mx-4 mobile__d__none"></v-divider>
          <PriceOfferComments v-bind="{ isSideDetailsLoading, quotation }" />
        </v-layout>
      </v-flex>
    </v-slide-x-transition>
  </div>
</template>

<script>
import Attachments from './Attachments'
import PriceOfferComments from './Comments.vue'
import DetailsHeader from './Header'

export default {
  name: 'SideDetailsPriceOffer',
  components: {
    DetailsHeader,
    PriceOfferComments,
    Attachments,
    Details: () => import('./Details'),
    ContractsDetails: () => import('./ContractsDetails'),
    RealEstatsDetails: () => import('./RealEstatsDetails'),
    CommissionsDetails: () => import('./CommissionsDetails')
  },
  props: {
    quotation: {
      type: Object,
      default: () => {}
    },
    isSideDetailsLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      titleForAttachment: '',
      openAttachmentStatus: false,
      attachTypes: [],
      tab: 0
    }
  },
  computed: {
    tabs() {
      return [
        {
          title: 'تفاصيل العرض',
          component: 'Details',
          data: this.quotation
        },
        {
          title: 'العقد',
          component: 'ContractsDetails',
          data: this.quotation._id
        },
        {
          title: 'العقار',
          component: 'RealEstatsDetails',
          data: this.quotation
        },
        {
          title: 'العمولات',
          component: 'CommissionsDetails',
          data: this.quotation
        },
        {
          title: 'المرفقات',
          component: 'Attachments',
          data: this.quotation
        }
      ]
    }
  },
  methods: {
    acceptHandler() {
      this.attachTypes = ['عرض عقد وساطة تأجير']
      this.titleForAttachment =
        'إرفاق العرض موقع ومختوم من قبل العميل أو من يمثله لتأكيد الموافقة'
      this.openAttachmentStatus = true
    },
    restAttachmentData() {
      this.titleForAttachment = 'إضافة مرفق'
      this.attachTypes = ['عقد منصة إيجار', 'اخرى']
      this.openAttachmentStatus = false
    },
    closeDetails() {
      this.$emit('closeDetails')
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 430px) {
  .mobile__screen {
    flex-direction: column;
  }

  .mobile__wrap {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
