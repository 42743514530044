<template>
  <v-list-item>
    <v-list-item-action>
      {{ invitee }}
    </v-list-item-action>
    <v-scroll-x-transition>
      <v-progress-circular
        v-if="loading"
        :width="3"
        color="green"
        indeterminate
      ></v-progress-circular>
      <div v-else>
        <v-icon :color="iconColor">
          {{ inviteResultIcon }} mdi-check-bold
        </v-icon>
        <v-list-item-action-text :class="textColor">
          {{ inviteResultMessage }}
        </v-list-item-action-text>
      </div>
    </v-scroll-x-transition>
  </v-list-item>
</template>
<script>
import { EnvService } from '../../services'
export default {
  props: ['invitee', 'environmentId'],
  data() {
    return {
      loading: true,
      inviteResultIcon: 'loading',
      iconColor: '',
      inviteResultMessage: '',
      textColor: ''
    }
  },
  watch: {
    invitee: {
      immediate: true,
      handler(value) {
        const data = {
          environmentId: JSON.parse(localStorage.getItem('currentEnv'))._id,
          role: 'member'
        }
        if (!isNaN(Number(value))) {
          data.phoneNumber = value.replace('0', '966')
        } else {
          data.email = value
        }
        EnvService.inviteEmployee(data)
          .then((response) => {
            this.loading = false
            if (response.data.user) {
              this.setInvitationResult(true, 'تم ارسال الدعوة بنجاح')
            }
          })
          .catch((error) => {
            this.loading = false
            if (error.response.data.error === 'user_exists') {
              this.setInvitationResult(
                false,
                'المستخدم موجود مسبقاً في بيئة أخرى، تعذر إرسال الدعوة'
              )
              return
            }
            this.setInvitationResult(false, 'عغواً، تعذر إرسال الدعوة')
          })
      }
    }
  },
  methods: {
    setInvitationResult(isSent, message = '') {
      this.inviteResultMessage = message
      if (isSent) {
        this.inviteResultIcon = 'mdi-check-bold'
        this.iconColor = 'green'
        this.textColor = 'green--text'
        return
      }
      this.inviteResultIcon = 'mdi-close-thick'
      this.iconColor = 'red'
      this.textColor = 'red--text'
    }
  }
}
</script>
