<template>
  <div>
    <div class="my-6 d-flex justify-between pointerCursor">
      <h4>تفاصيل التحصيل</h4>
      <div
        v-if="isNonEditable && hasAddPermission"
        class="d-flex justify-between align-center text-primary pointerCursor"
        @click="addCollection"
      >
        <div class="brub--tag px-2 rounded-lg ml-2">+</div>
        إضافة عملية
      </div>
    </div>
    <div v-if="assignments.length">
      <v-row class="mb-2 ml-2 mr-8">
        <v-col cols="2" class="font-14 text-center px-0">
          القيمة من غير ضريبة</v-col
        >
        <v-col cols="2" class="font-14 text-center"> نسبة الضريبة</v-col>
        <v-col cols="2" class="font-14 text-center"> قيمة الضريبة</v-col>
        <v-col cols="2" class="font-14 text-center"> الإجمالي مع الضريبة</v-col>
        <v-col cols="2" class="font-14 text-center">القيمة المستلمة</v-col>
        <v-col cols="2" class="font-14 text-right">المتبقي</v-col>
      </v-row>
      <v-expansion-panels>
        <v-expansion-panel
          class="elevation-0 my-1"
          v-for="assignment in assignments"
          :key="assignment.id"
        >
          <v-expansion-panel-header id="assigmnet-header">
            <v-row>
              <v-col class="d-flex justify-center px-0 align-center">
                {{ formattingNumbers(assignment.bond.bondValue) }}
              </v-col>
              <v-col class="d-flex justify-center px-0 align-center"
                >{{ assignment.bond.tax }}%
              </v-col>
              <v-col class="d-flex justify-center px-0 align-center">
                {{ formattingNumbers(assignment.bond.taxAmount) }}
              </v-col>
              <v-col class="d-flex justify-center px-0 align-center">
                {{ formattingNumbers(assignment.bond.totalAmount) }}
              </v-col>
              <v-col class="d-flex justify-center px-0 align-center">
                {{ formattingNumbers(assignment.bond.bondValue) }}
              </v-col>
              <v-col class="px-0 pr-4">
                <div class="d-flex justify-center align-center mx-auto">
                  <div class="w-100 text-center">
                    <p class="font-16 ma-0 mt-1">
                      {{ formattingNumbers(assignment?.remaining ?? 0) }}
                    </p>
                  </div>
                  <div class="d-flex justify-center w-100">
                    <!-- <v-icon color="#FC4A4A"> mdi-close </v-icon> -->
                    <v-btn
                      v-if="!assignment.isCanceled"
                      max-width="120"
                      class="actions rounded mb-1 danger--text cancel mx-auto"
                      @click.native.stop="
                        $emit('agreeToDeleteInstallment', assignment)
                      "
                    >
                      <span class="font-12 font-weight-bold"> إلغاء </span>
                    </v-btn>
                    <div
                      v-else
                      class="red--bill tabel-tag text-center ml-1 font-12 my-1 py-2 d-flex justify-center align-center"
                    >
                      <h5
                        class="font-12 font-weight-medium pa-0 ma-0 text-truncate"
                      >
                        ملغيه
                      </h5>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row no-gutters>
              <v-col cols="6" md="3">
                <h6 class="font-12 text-gray">تاريخ التحصيل</h6>
                <div class="font-12 my-1">
                  <h5
                    class="font-14 font-weight-medium pa-0 ma-0 text-truncate"
                  >
                    {{ formatDate(assignment.bond.date) }}
                  </h5>
                </div>
              </v-col>
              <v-col cols="6" md="3">
                <h6 class="font-12 text-gray">اسم المحصل</h6>
                <div class="font-12 my-1">
                  <h5
                    class="font-14 font-weight-medium pa-0 ma-0 text-truncate"
                  >
                    {{ assignment.user?.name }}
                  </h5>
                </div>
              </v-col>
              <v-col
                cols="6"
                md="3"
                v-if="
                  assignment?.bond?.isClaimed &&
                  assignment?.bond?.isClaimed !== 'undefined'
                "
              >
                <h6 class="font-12 text-gray">طريقة إستلام قيمة المطالبة</h6>
                <div class="font-12 my-1">
                  <h5
                    class="font-14 font-weight-medium pa-0 ma-0 text-truncate"
                  >
                    {{
                      assignment.bond.isClaimed
                        ? 'قيمة مستلمة مباشرة'
                        : 'قيمة مستلمة  غير مباشرة'
                    }}
                  </h5>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-row no-gutters v-if="isClaimRentType">
              <v-col
                cols="6"
                md="3"
                v-if="
                  assignment?.bond?.includeCommission &&
                  assignment?.bond?.includeCommission !== 'undefined'
                "
              >
                <h6 class="font-12 text-gray">إحتساب أتعاب إدارة الأملاك</h6>
                <div class="font-12 my-1">
                  <h5
                    class="font-14 font-weight-medium pa-0 ma-0 text-truncate"
                  >
                    {{ assignment.bond.includeCommission ? 'نعم' : 'لا' }}
                  </h5>
                </div>
              </v-col>
              <v-col
                cols="6"
                md="3"
                v-if="
                  assignment?.bond?.setCommissionDebt &&
                  assignment?.bond?.setCommissionDebt !== 'undefined'
                "
              >
                <h6 class="font-12 text-gray">
                  إستلام قيمة أتعاب إدارة الأملاك
                </h6>
                <div class="font-12 my-1">
                  <h5
                    class="font-14 font-weight-medium pa-0 ma-0 text-truncate"
                  >
                    {{
                      assignment.bond.setCommissionDebt
                        ? 'نعم وسيتم إعتباره دين مسدد'
                        : 'لا وسيتم إعتباره دين مسدد'
                    }}
                  </h5>
                </div>
              </v-col>
              <v-col cols="6" md="3">
                <h6 class="font-12 text-gray">
                  هل تود إستلام وإستقطاع مبلغ أتعاب إدارة الأملاك ؟
                </h6>
                <div class="font-12 my-1">
                  <h5
                    class="font-14 font-weight-medium pa-0 ma-0 text-truncate"
                  >
                    {{ assignment.bond.setCommissionDebt ? 'نعم' : 'لا' }}
                  </h5>
                </div>
              </v-col>
              <v-col
                cols="6"
                md="3"
                v-if="
                  assignment?.bond?.calculateMarketingFees &&
                  assignment?.bond?.calculateMarketingFees !== 'undefined'
                "
              >
                <h6 class="font-12 text-gray">خصم عمولة التسويق والتأجير؟</h6>
                <div class="font-12 my-1">
                  <h5
                    class="font-14 font-weight-medium pa-0 ma-0 text-truncate"
                  >
                    {{ assignment.bond.calculateMarketingFees ? 'نعم' : 'لا ' }}
                  </h5>
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div class="ma-auto" v-else>
      <NoData v-bind="noDataConfig" />
    </div>
  </div>
</template>

<script>
import NoData from '@/components/helper/noData.vue'
import dateFormat from '@/mixins/dateFormat.js'
import { mapMutations } from 'vuex'

export default {
  components: { NoData },
  props: {
    assignments: {
      type: Array
    },
    isNonEditable: {
      type: Boolean
    },
    hasAddPermission: {
      type: Boolean
    },
    isClaimRentType: {
      type: Boolean
    }
  },
  mixins: [dateFormat],
  data: () => ({
    noClaims: require('@/assets/img/claims/claim.png')
  }),
  computed: {
    noDataConfig() {
      return {
        noDataText: 'ليست هناك اي عمليات تحصيل',
        noDataIcon: this.noClaims,
        noDataSubTitle: '',
        isbgPrimary: false,
        widthHeight: '80'
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    addCollection() {
      if (!this.hasDuePermission('add')) {
        this.addNotification({
          text: 'ليس لديك صلاحيه لتعديل المطالبه',
          color: 'error'
        })
        return
      }
      this.$emit('addCollection', true)
    },
    hasDuePermission(action) {
      return !!this.$hasPermission('properties', 'assignments', action)
    },
    formattingNumbers(num) {
      return parseFloat(num).toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    }
  }
}
</script>

<style lang="scss">
.v-expansion-panel-header__icon {
  position: absolute;
  right: 5px;
}
</style>
