<template>
  <material-card class="v-card--material-stats">
    <div
      class="icon-wrap"
      :class="shadowClass"
      :style="{ backgroundColor: color }"
    >
      <v-icon dark>{{ icon }}</v-icon>
    </div>

    <div class="text-xs-right">
      <h3 class="title black--text font-weight-bold">{{ value }}</h3>
      <p v-text="title" />
    </div>
  </material-card>
</template>

<script>
import Card from './Card'

export default {
  inheritAttrs: false,

  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'secondary'
    },
    value: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    shadowClass: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss">
.v-card--material-stats {
  text-align: center;
  padding: 0;

  .v-card__text {
    display: flex;
    align-items: center;
    padding: 15px 20px;

    h3 {
      margin: 0;
      line-height: 1 !important;
    }

    p {
      margin: 0 0 8px;
      line-height: 1;
    }

    div:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: right;
    }
  }

  .icon-wrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    border-radius: 0.375rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 24px;
    margin-left: 20px;
    flex-shrink: 0;
  }
}
</style>
