<template>
  <v-snackbar
    color="transparent"
    :timeout="snackbarTimeout"
    :multi-line="true"
    class="mt-16 realTimeSnackbar"
    left
    top
    transition="slide-y-transition"
    v-model="snackbarState"
    elevation="0"
    content-class="realTimeSnackbar"
  >
    <div class="notifyCard">
      <div class="card black--text">
        <div class="card__icon">
          <img
            width="45"
            height="45"
            :src="icons[realTimeNotificationIcon]"
            loading="lazy"
          />
        </div>
        <div class="card__message">
          <div class="d-flex">
            <div>
              <h2 class="font-16 mb-0">{{ snackbarText }}</h2>
              <p class="font-14 mt-0">الأن</p>
            </div>
            <v-btn
              class="mt-1"
              @click="setRealTimeSnackbar(false)"
              dark
              fab
              icon
              small
            >
              <v-icon color="grey" size="15">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import notificationsIcon from '@/config/notifications.js'

export default {
  data() {
    return {
      icons: notificationsIcon.icons
    }
  },
  computed: {
    ...mapState('appState', [
      'realTimeSnackbar',
      'snackbarTimeout',
      'snackbarText',
      'realTimeNotificationIcon'
    ]),
    snackbarState: {
      get() {
        return this.realTimeSnackbar
      },
      set(val) {
        this.setRealTimeSnackbar(val)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['setRealTimeSnackbar'])
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.notifyCard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px !important;
  background-color: #eef7fe;
}
.card {
  -webkit-box-shadow: 0px 0px 24px 0px rgba(173, 171, 173, 0.8);
  -moz-box-shadow: 0px 0px 24px 0px rgba(173, 171, 173, 0.8);
  box-shadow: 0px 0px 24px 0px rgba(173, 171, 173, 0.8);
  border: 2px solid #f2f2f2;
  background: #fff;
  padding: 0px 10px;
  border-radius: 12px;
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: center;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #fcfcfc;
  }
  &__message {
    h2 {
      font-weight: 500;
      margin: 5px;
    }
    p {
      color: $primary-color;
      margin: 5px;
    }
  }
}
</style>
