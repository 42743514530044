<template>
  <div
    class="d-flex align-center justify-space-between flex-wrap mobile__justify__start"
  >
    <!--For title-->
    <slot />
    <div>
      <v-btn
        v-for="btn in buttons"
        :key="btn.name"
        v-show="btn.status"
        @click="btn.action"
        :color="btn.color"
        class="px-6 py-5 pdf__btns mr-3"
        depressed
        small
      >
        <v-img
          class="ml-1"
          max-height="16"
          max-width="16"
          :src="btn.icon"
        ></v-img>
        <span class="mr-1" v-text="btn.name" />
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportButtons',
  props: {
    excelgen: {
      type: Function,
      required: true
    },
    pdfgen: {
      type: Function,
      required: true
    },
    sharePdf: {
      type: Function,
      required: false
    },
    statusPdfgen: {
      type: Boolean,
      default: true
    },
    statusSharePdf: {
      type: Boolean,
      default: false
    },
    statusExcelgen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      buttons: [
        // {
        //   name: ' إكسيل شيت',
        //   color: '#10793F',
        //   icon: require('@/assets/img/svgs/file-excel.svg'),
        //   action: this.excelgen,
        //   status: this.statusExcelgen
        // },
        {
          name: ' بي دي اف',
          color: '#662D91',
          icon: require('@/assets/img/svgs/pdf-white.svg'),
          action: this.pdfgen,
          status: this.statusPdfgen
        },
        {
          name: ' مشاركه',
          color: '#12CAD6',
          icon: require('@/assets/img/svgs/white-share-pdf.svg'),
          action: this.sharePdf,
          status: this.statusSharePdf
        }
      ]
    }
  }
}
</script>

<style></style>
