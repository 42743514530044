import axios from 'axios'
import config from '../config'
import store from '@/store/index'

axios.defaults.headers.client = 'HectarPlus'

const getAllBonds = function (query, data = 'pageSize=10&pageNumber=1') {
  const url = `${config.bonds}/bonds/filter?${data}`
  const listBondPermission = store.getters['accountState/hasPermission'](
    'properties',
    'bonds',
    'list'
  )
  return listBondPermission ? axios.post(url, query) : {}
}

const nextInstallment = (query) => {
  let url = `${config.bonds}/installments/${query.envId}/${query.contractId}/next-installment`
  if (query.type) {
    url += `?type=${query.type}`
  }
  return axios.get(url)
}

const getTerminateContractPreview = function (body) {
  const url = `${config.bonds}/contracts/terminate-preview`
  return axios.post(url, body)
}

//  add Bonds
const addBonds = function (data) {
  const url = `${config.bonds}/bonds/`
  return axios.post(url, data)
}

//  edit Bonds
const editBonds = function (data) {
  const url = `${config.bonds}/bonds`
  return axios.put(url, data).catch((err) => {
    throw new Error(err.response.data.errors[0])
  })
}

//  del Bonds
const deleteBonds = function (id) {
  const url = `${config.bonds}/bonds`
  return axios.delete(url, {
    data: {
      id
    }
  })
}

//  terminatBonds
const terminatBonds = function (id) {
  const url = `${config.bonds}/bonds/terminate`
  return axios.post(url, {
    id
  })
}

// get bondsSection
const bondsSection = function () {
  const url = `${config.bonds}/bondSections/`
  return axios.get(url)
}

// get '/bondsType/getAll'
const bondsType = function () {
  const url = `${config.bonds}/bondTypes/`
  return axios.get(url)
}

const getBondVersions = function (bondId) {
  const url = `${config.bonds}/bonds/${bondId}/versions`
  return axios.get(url)
}

const getPMBundle = function (environmentId) {
  const url = `${config.bonds}/subscriptions/get-user-bundle?environmentId=${environmentId}`
  return axios.get(url)
}

// bond notes /////
const addBondNote = function (query) {
  const url = `${config.bonds}/bonds/notes`
  return axios.post(url, query)
}

const updateBondNote = function (id, query) {
  const url = `${config.bonds}/bonds/notes/${id}`
  return axios.put(url, query)
}

const getBondCountByType = function (query) {
  const url = `${config.bonds}/bonds/count?${query}`
  return axios.get(url)
}

const getSuggestedBondValue = function (query) {
  const url = `${config.bonds}/bonds/suggested-value?${query}`
  return axios.get(url)
}

const createShareCopy = function (data) {
  const url = `${config.bonds}/share`
  return axios.post(url, data)
}

const getShareCopy = function (shareCopyId) {
  const url = `${config.bonds}/share/${shareCopyId}`
  return axios.get(url)
}

const getExpensesRequests = function (body) {
  const url = `${config.bonds}/expenses/filter`
  return axios.post(url, body)
}
const getBondPaymentMethods = function (query) {
  const url = `${config.bonds}/paymentMethods`
  return axios.get(url)
}

const getRelatedBonds = function (bondId) {
  const url = `${config.bonds}/bonds/dependant-bonds/${bondId}`
  return axios.get(url)
}

const sendNotification = function (payload) {
  const url = `${config.bonds}/notifications/post`
  return axios.post(url, payload)
}

const checkCombinedRentContract = function (payload) {
  const url = `${config.bonds}/contracts/check-combined-rentcontract`
  return axios.post(url, payload)
}

const getSingleBond = function (bondId) {
  const url = `${config.bonds}/bonds/${bondId}`
  return axios.get(url)
}

export const bondsService = {
  getAllBonds,
  getSingleBond,
  addBonds,
  editBonds,
  deleteBonds,
  bondsSection,
  bondsType,
  getBondVersions,
  getPMBundle,
  terminatBonds,
  addBondNote,
  updateBondNote,
  getBondCountByType,
  getSuggestedBondValue,
  nextInstallment,
  createShareCopy,
  getShareCopy,
  getExpensesRequests,
  getBondPaymentMethods,
  getRelatedBonds,
  getTerminateContractPreview,
  sendNotification,
  checkCombinedRentContract
}
