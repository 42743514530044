<template>
  <div class="show-detailes contentSection contracts__bonds__side__deatails">
    <v-layout>
      <v-flex @click="closeDetails" class="greySide" md4 v-if="!lg" />
      <v-flex class="whiteSide flex-wrap" md8>
        <sideHeader
          :bond="item"
          :terminated="terminated"
          @closeDetails="closeDetails"
          @sharePdf="sharePdf"
          @bondDel="$emit('deleteBond', item._id)"
          @exportPdfContract="exportPdfContract"
        />

        <v-divider class="mt-3"></v-divider>
        <v-layout class="sidedetails__layout">
          <v-flex :md8="!isCustomer" :md12="isCustomer" no-gutters>
            <v-tabs
              class="tabs-container"
              color="primary"
              align-with-title
              right
              v-model="tab"
            >
              <v-tab
                v-for="tab in detailsTabs"
                :key="tab.title"
                color="#737373"
                class="font-14 mr-0"
              >
                {{ tab.title }}
              </v-tab>
            </v-tabs>
            <div style="height: 90vh" class="contentSection">
              <v-tabs-items v-model="tab">
                <v-tab-item
                  v-for="tab in detailsTabs"
                  :key="tab.title"
                  :eager="true"
                >
                  <v-divider></v-divider>
                  <component
                    :is="tab.component"
                    :item="item"
                    :headers="headers"
                    :bond="item"
                    :bonsSubtypes="bonsSubtypes"
                    :isLoading="isLoading"
                    :bondAttachments="bondAttachments"
                    @selectFile="uploadBondAttachment"
                    @delete-document="deleteBondDocument"
                  />
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-flex>
          <v-divider v-if="!isCustomer" class="mr-3" vertical></v-divider>
          <v-flex v-if="!isCustomer" md4 class="mr-2">
            <div
              class="commentsSection ma-0 d-flex flex-column mt-2"
              style="height: 90vh"
            >
              <p class="font-14 mt-4 mr-2">التعليقات</p>
              <v-row class="mx-0" v-if="!commentsLoaded">
                <v-col cols="12" v-for="i in 3" :key="i">
                  <v-skeleton-loader
                    class="background-transparent"
                    width="300"
                    type="list-item-avatar-two-line"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>

              <div class="ma-auto" v-if="!comments?.length && commentsLoaded">
                <v-sheet>
                  <span>
                    <v-img
                      class="ma-auto"
                      width="72"
                      height="72"
                      :src="noComments"
                    ></v-img>
                    <span> ليست هناك اي تعليقات </span>
                  </span>
                </v-sheet>
              </div>
              <div class="comments mb-auto mt-2" v-else>
                <CommentCard
                  v-for="(comment, index) in comments"
                  :key="comment._id"
                  v-bind="generateCommentProps(comment)"
                  @setActiveComment="setActiveComment"
                  @setActiveTextArea="setActiveTextArea"
                  @updateComment="updateComment($event, index)"
                  @deleteComment="deleteComment($event, index)"
                />
              </div>

              <v-sheet
                v-if="
                  !terminated && $hasPermission('properties', 'bonds', 'add')
                "
                class="mb-1 mr-4"
              >
                <v-form>
                  <v-textarea
                    data-test="comment-input"
                    @keydown.enter.exact.prevent
                    @keyup.enter.exact="addComment"
                    auto-grow
                    class-content="commentTextArea font-12"
                    filled
                    hide-details
                    no-resize
                    placeholder="أدخل تعليقك هنا"
                    rows="1"
                    v-model="commentDes"
                  >
                    <template v-slot:append>
                      <img
                        @click="addComment"
                        alt="send text"
                        class="px-3 pointerCursor"
                        src="@/assets/img/svgs/send.svg"
                        loading="lazy"
                      />
                    </template>
                  </v-textarea>
                </v-form>
              </v-sheet>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <AddNoteModel
      :dialogState="dialogState"
      :noteToEdit="noteToEdit"
      @closeNoteDialog="closeNoteModal"
      @handleNotes="handleNotes($event)"
    />
  </div>
</template>
<script>
import dateFormat from '@/mixins/dateFormat.js'
import sanitizers from '@/mixins/sanitizers.js'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { bondsService, messageService, contractsService } from '@/services'
import CommentCard from '../contracts/CommentCard'
import AddNoteModel from '../modals/AddNoteModel.vue'
import sideHeader from './sideHeader'
// import BondDetails from './BondDetails.vue'

export default {
  name: 'side-detailes',
  mixins: [sanitizers, dateFormat],
  components: {
    sideHeader,
    AddNoteModel,
    CommentCard
    // BondDetails
  },
  props: ['item', 'terminated', 'contractIntervals', 'headers', 'bonsSubtypes'],
  data() {
    return {
      commentsLoaded: false,
      comments: [],
      isLoading: false,
      commentDes: '',
      commentContent: '',
      noteToEdit: {},
      notes: [],
      dialogState: false,
      mdiPlus: require('@/assets/img/svgs/mdi-plus.svg'),
      note: '',
      noteId: '',
      tab: null,
      activeComment: null,
      activeTextArea: null,
      commentId: '',
      Signatures: [],
      bondAttachments: [],
      noComments: require('@/assets/img/svgs/noComments.svg')
    }
  },
  created() {
    this.getComments()
    this.getBondAttachments()
    this.notes = this.item?.notes ? this.item.notes : []
  },
  computed: {
    ...mapState('accountState', {
      currentUser: (state) => state.user,
      currentEnv: (state) => state.currentEnv
    }),
    detailsTabs() {
      return [
        {
          title: 'تفاصيل السند',
          component: () => import('./BondDetails.vue')
        },
        {
          title: 'المرفقات',
          component: () => import('./BondAttachments.vue')
        }
      ]
    },
    ...mapGetters('accountState', ['isCustomer']),
    bondInstallmentStartDate() {
      const startDate = this.item?.installmentsDate?.startDate
      return startDate ? this.formatDate(startDate) : ''
    },
    createdByName() {
      return this.item?.createdBy?.name || ''
    },
    bondInstallmentEndDate() {
      const endDate = this.item?.installmentsDate?.endDate
      return endDate ? this.formatDate(endDate) : ''
    },
    getBondType() {
      return this.item?.bondType?.nameEn
    },
    getProviderName() {
      return this.getBondType === 'exchange'
        ? 'تم صرف قيمة السند لـ'
        : 'تم إستلام قيمة السند من'
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndDown
    },
    bondSubType() {
      return this.item?.subType?.nameAr
    },
    isValidComment: () => (commentContent) => {
      return !!commentContent?.length
    },
    isDueDateExists() {
      return !!(
        this.item?.installmentsDate?.startDate &&
        this.item?.installmentsDate?.endDate
      )
    },
    contractCardProps() {
      return {
        item: this.item?.contract,
        contractIntervals: this.contractIntervals,
        noEdit: false,
        notSideView: false
      }
    }
  },
  beforeDestroy() {
    localStorage.removeItem('sideDetailsItem')
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getPayMethodType() {
      switch (this.item.paymentMethod) {
        case 'cash':
          return 'نقداً'
        case 'check':
          return 'شيك مصرفي'
        case 'bankTransfer':
          return 'تحويل بنكي'
        default:
          return 'غير متوفر'
      }
    },
    getOwnerName(item) {
      return item.contract
        ? item?.ownerName ?? item.owner.name
        : item.client.name
    },
    closeDetails() {
      this.$emit('showDetails', { visible: false, bond: null })
    },
    openNotesDialog(note) {
      if (note.body) {
        this.noteToEdit = note ? note : {}
      }
      this.dialogState = true
    },
    handleNotes(note) {
      if (note?._id) {
        const updatedNote = {
          body: note.body,
          postedBy: note.postedBy,
          noteId: note._id
        }
        this.updateNote(updatedNote)
      } else {
        const newNote = {
          id: this.item._id,
          body: note.body,
          postedBy: this.currentUser._id
        }
        this.addNote(newNote)
      }
    },
    async addComment() {
      try {
        if (this.isValidComment(this.commentDes)) {
          const commentObj = {
            content: this.commentDes,
            entityType: 'bond',
            entityId: this.item?._id,
            author: {
              _id: this.currentUser._id,
              name: this.currentUser.name,
              phoneNumber: this.currentUser.phoneNumber,
              type: this.currentUser.type,
              email: this.currentUser.email
            },
            receiver: {
              _id: this.item.owner._id,
              name: this.item.owner.name
            },
            context: {
              platform: 'hectarPlus'
            }
          }
          this.commentDes = ''
          const { data } = await messageService.addComment(commentObj)
          this.comments.push(data.comment)
        }
      } catch {
        this.addNotification({
          text: 'حدث خطأ اثناء إضافة التعليق، يرجى المحاولة مجددا',
          color: 'error'
        })
      }
    },
    async uploadBondAttachment(attachment) {
      try {
        const reader = new FileReader()
        reader.onload = async () => {
          await this.onUpload(attachment)
        }
        reader.readAsDataURL(attachment.files)
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    async onUpload(file) {
      try {
        this.isLoading = true
        const fd = new FormData()
        fd.append('entityId', this.item._id)
        fd.append('envId', this.currentEnv._id)
        fd.append('entityType', 'bond')
        fd.append('attachment', file.files)
        fd.append('type', file.type)
        // fd.append('description', file.description)
        fd.append('number', file.number)
        await contractsService.addAttachment(fd)
        this.addNotification({
          text: 'تم إضافه المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      } finally {
        this.getBondAttachments()
        this.isLoading = false
      }
    },
    async getBondAttachments() {
      try {
        const { data } = await contractsService.getAllAttachments(this.item._id)
        this.bondAttachments = data.attachments
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل مرفقات العقد',
          color: 'error'
        })
      }
    },
    generateCommentProps(comment) {
      return {
        comment,
        commentId: this.activeComment,
        isTerminated: this.terminated,
        activeTextArea: this.activeTextArea
      }
    },
    async getComments() {
      try {
        const query = {
          entityType: 'bond',
          entityId: this.item._id
        }
        const res = await messageService.getComments(query)
        this.comments = res.data.items
        this.commentContent = ''
        this.commentsLoaded = true
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل تعليقات السند، يرجى المحاولة مجددا',
          color: 'error'
        })
      }
    },
    async deleteComment(commentId, commentIndex) {
      await messageService.deleteComment(commentId).then(() => {
        this.comments.splice(commentIndex, 1)
      })
    },
    updateComment(newComment, commentIndex) {
      if (this.isValidComment(newComment.content)) {
        const updatedCommentToSend = {
          content: newComment.content,
          _id: newComment.commentId
        }
        messageService.updateComment(updatedCommentToSend).then(() => {
          this.$set(this.comments[commentIndex], 'content', newComment.content)
        })
      }
      this.activeTextArea = null
    },
    setActiveComment(commentId) {
      this.activeComment = commentId
    },
    setActiveTextArea(commentId) {
      this.activeTextArea = commentId
    },
    sharePdf() {
      this.$parent.sharePdf(this.item)
    },
    exportPdfContract() {
      this.$emit('exportPdfContract', this.item)
    },
    async addNote(newNote) {
      try {
        const { data } = await bondsService.addBondNote(newNote)
        this.notes = data.notes
        this.$emit('updateNotes', {
          notes: this.notes,
          bondId: this.item._id
        })
        this.dialogState = false
      } catch {
        this.addNotification({
          text: 'خطأ في إضافة الملاحظة، يرجى المحاولة مجددا',
          color: 'error'
        })
      }
    },
    async deleteBondDocument({ _id, fileIndex }) {
      try {
        await contractsService.deleteAttachment(_id)
        this.bondAttachments.splice(fileIndex, 1)
        this.addNotification({
          text: 'تم حذف المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في حذف المرفق، يرجى المحاولة مجددا',
          color: 'error'
        })
      } finally {
        this.showSideDetails()
      }
    },
    async updateNote(updatedNote) {
      try {
        await bondsService.updateBondNote(this.item._id, updatedNote)
        const noteIndex = this.notes.findIndex(
          (note) => note._id === updatedNote.noteId
        )
        noteIndex !== -1 &&
          this.$set(this.notes[noteIndex], 'body', updatedNote.body)
        this.$emit('updateNotes', {
          notes: this.notes,
          bondId: this.item._id
        })
        this.closeNoteModal()
      } catch {
        this.addNotification({
          text: 'خطأ في تعديل الملاحظة، يرجى المحاولة مجددا',
          color: 'error'
        })
      }
    },
    closeNoteModal() {
      this.noteToEdit = {}
      this.dialogState = false
    },
    showContractDetails({ contract, contractUtilities }) {
      this.$router.push({
        path: '/dashboard/contracts',
        query: { visible: true, item: contract, contractUtilities, from: true }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/material-dashboard/_sideDetails.scss';
@import '@/styles/responsiveness/responsiveness.scss';
@import '@/styles/report/totalTable.scss';

.transaction_detail .result__total .rest-bg h6 {
  background-color: #f5f5f5 !important;
  color: #000 !important;
  min-width: fit-content;
  text-transform: none;
}
</style>
