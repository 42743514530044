<template>
  <v-layout flex-wrap class="sidedetail__header align-center">
    <v-layout class="flex-wrap py-4">
      <v-img
        :lazy-src="exitArrow"
        max-height="15"
        max-width="15"
        :src="exitArrow"
        class="mt-3"
        @click="$emit('closeDetails')"
      ></v-img>

      <div class="mr-4 mobile__mr__0 ml-3">
        <p class="grey--text ma-0 mb-2 font-12">نوع العملية</p>
        <h5 class="brub--tag ma-0 font-14 font-weight-medium tabel-tag px-6">
          <span>{{ approval?.actionName?.ar ?? '-' }}</span>
        </h5>
      </div>

      <div class="mr-4 mobile__mr__0">
        <p class="grey--text ma-0 font-12 mb-2">رقم العملية</p>
        <h5 class="ma-0 font-14 font-weight-medium">
          <span>{{ approval?.entity?.number ?? '-' }}</span>
        </h5>
      </div>
    </v-layout>

    <span
      v-if="approval?.status === 'cancelled'"
      class="ma-0 font-14 font-weight-bold tabel-tag px-6 red--bill"
      >ملغي</span
    >
    <v-btn
      class="white--text"
      color="red"
      v-else-if="isOwener && isOnwerCanclled === 'pending'"
      @click="
        $emit('cancelled', { item: { ...approval, status: 'cancelled' } })
      "
    >
      <span>
        <v-icon class="ml-2">mdi-close</v-icon>
        إلغاء الطلب
      </span>
    </v-btn>
  </v-layout>
</template>

<script>
export default {
  name: 'DetailsHeader',
  props: {
    approval: {
      type: Object,
      default: () => {}
    },
    isOwener: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      exitArrow: require('@/assets/img/svgs/right-arrow.svg')
    }
  },
  computed: {
    isOnwerCanclled() {
      return this.approval?.approvals?.find(
        ({ user: { _id } }) => _id === this.approval?.createdBy?.id
      )?.status
    }
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-btn.v-btn--has-bg {
  background-color: #f44336;
  padding: 0 1.4rem;
  border-radius: 10px;
}
</style>
