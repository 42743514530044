const statusTagsColor = {
  draft: {
    color: '#662D91',
    tagColor: 'brub--tag'
  },
  active: {
    color: '#65C467',
    tagColor: 'green--tag'
  },
  approved: {
    color: '#65C467',
    tagColor: 'green--tag'
  },
  pending: {
    color: '#ed6237',
    tagColor: 'orange--tag'
  },
  declined: {
    color: '#FF6600',
    tagColor: 'red--tag'
  },
  recorded: {
    color: '#4fb3f9',
    tagColor: 'blue--tag'
  },
  pendingRenewal: {
    color: '#4fb3f9',
    tagColor: 'blue--tag'
  },
  expired: {
    color: '#fc4a4a',
    tagColor: 'red--tag'
  },
  terminated: {
    color: '#fc4a4a',
    tagColor: 'red--tag'
  },
  cancelled: {
    color: '#fc4a4a',
    tagColor: 'red--tag'
  }
}

const restrucureContractSatuses = (statusesResponse) => {
  const restructureStatuses = {}
  statusesResponse.map((status) => {
    restructureStatuses[`${status.code}`] = {
      ...status,
      ...statusTagsColor[status.code]
    }
  })
  return restructureStatuses
}

export { restrucureContractSatuses }
