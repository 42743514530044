<template>
  <div>
    <google-maps-search
      ref="addressRef"
      class="mb-6"
      v-bind="googleMapsProps"
      @onCheckAddress="checkAddress"
      @setPropertyName="setPropertyName"
    >
    </google-maps-search>

    <v-card-actions class="pt-4 px-0 d-flex align-center justify-space-between">
      <v-btn
        data-test="previousTab"
        @click="previousTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <v-icon class="font-16">mdi-arrow-right</v-icon>
        <span class="mb-1 ml-1">السابق</span>
      </v-btn>
      <v-btn
        data-test="next-tab"
        @click="nextTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <span class="mb-1 ml-1">التالي</span>
        <v-icon class="font-16">mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import GoogleMapsSearch from '@/components/map/GoogleMapsSearch.vue'

export default {
  components: {
    GoogleMapsSearch
  },
  props: {
    resetValidation: {
      default: false
    },
    propertyInfo: {
      default() {
        return {}
      }
    },
    propertyInfoValid: {}
  },
  data() {
    return {
      addressInnerDetails: true,
      isValid: false,
      isAddressVlaid: false,
      nexTabClicked: false,
      addPropertyInfo: {}
    }
  },
  methods: {
    checkAddress(options) {
      this.addPropertyInfo.location = {
        coordinates: [options.place.lng, options.place.lat],
        address: options.place.address,
        type: 'Point',
        city: options.place?.city,
        province: options.place?.province,
        district: options.place?.district
      }
      this.addPropertyInfo.propertyAddress = {}
      this.addPropertyInfo.propertyAddress.address = options?.place?.address
      this.addPropertyInfo.propertyAddress.city = options?.place?.city
      this.addPropertyInfo.propertyAddress.province = options?.place?.province
      this.addPropertyInfo.propertyAddress.district = options?.place?.district
      this.isAddressVlaid = true
    },
    nextTab() {
      this.nexTabClicked = true
      if (this.$refs.addressRef.componentValidation) {
        this.$emit('setAddPropertyInfo', this.addPropertyInfo)
        this.$emit('nextTab')
      } else {
        this.$emit('addNotification')
      }
    },
    previousTab() {
      this.$emit('previousTab')
    },
    setPropertyName(propertyName) {
      this.addPropertyInfo.propertyName = propertyName
    }
  },
  computed: {
    fullAddress() {
      return {
        ...this.addPropertyInfo.location,
        ...this.addPropertyInfo.propertyAddress
      }
    },
    propertyName() {
      return this.addPropertyInfo?.propertyName
    },
    tabValidation() {
      return !!this.isAddressVlaid
    },
    googleMapsProps() {
      return {
        addressInnerDetails: this.addressInnerDetails,
        currentAddress: this.fullAddress,
        realEstateName: this.propertyName,
        mapStatus: 'new',
        isValid: this.nexTabClicked ? this.isAddressVlaid : true,
        nexTabClicked: this.nexTabClicked,
        propertyInfoValid: this.propertyInfoValid
      }
    }
  },
  watch: {
    resetValidation: {
      handler() {
        if (!this.propertyInfoValid) {
          this.nexTabClicked = false
          this.isAddressVlaid = false
          this.addPropertyInfo = {}
        }
      }
    },
    propertyInfo: {
      immediate: true,
      handler() {
        this.addPropertyInfo = { ...this.propertyInfo }
        this.isAddressVlaid = true
      }
    }
  }
}
</script>
