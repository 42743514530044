<template>
  <div
    class="show-detailes task-details contentSection contracts__bonds__side__deatails"
  >
    <v-slide-x-transition>
      <v-layout>
        <v-flex
          v-if="!lg"
          @click="updateTaskAfterCloseSideDetails"
          class="greySide"
          md4
        ></v-flex>
        <v-flex class="whiteSide flex-wrap">
          <!-------------------------------->
          <!---------Header details--------->
          <!-------------------------------->

          <side-detailes-header
            v-bind="{ task: taskData, taskTitles }"
            @showDetails="updateTaskAfterCloseSideDetails"
            @deleteTaskFromSideDetails="deleteTask(task._id)"
          />

          <!-- Spreated -->
          <v-divider class="mt-2"></v-divider>

          <v-layout
            class="sidedetails__layout mobile__px__15px mobile__flex__wrap"
          >
            <!------------------>
            <!----- Part 1 ----->
            <!------------------>

            <v-flex md8>
              <v-form ref="form" v-model="isValid">
                <!------ 1 - Task name ------>
                <v-text-field
                  v-model="task.name"
                  placeholder="إسم المهمه"
                  class="mt-5"
                  dense
                  filled
                  required
                  :disabled="isDoneTask"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-form>

              <!------ 2 - Task description ------>
              <h5 class="grey--text mt-5">وصف المهمة</h5>
              <v-textarea
                v-model="task.description"
                placeholder="يمكنك إضافه وصف للمهمه وسوف يتم حفظها تلقائيا"
                class-content="font-12 mb-1"
                class="textArea"
                no-resize
                :disabled="isDoneTask"
              ></v-textarea>

              <!------ 3 - Task file loader ------>
              <filesUploader
                ref="filesUploading"
                @selectFile="uploadDocument"
                :disabled="isDoneTask"
              />

              <!------ 4 - Task list files ------>
              <FileList
                v-bind="{ files, showDocuments: true }"
                v-on="$listeners"
                @delete-document="deleteDocument"
                @cancel-delete-document="showTaskDetails"
              />
            </v-flex>

            <!-- Spreated -->
            <v-divider vertical class="mx-4 mobile__d__none"></v-divider>

            <!------------------>
            <!----- Part 2 ----->
            <!------------------>

            <v-flex md4>
              <div
                class="commentsSection ma-0 d-flex flex-column"
                style="height: 90vh"
              >
                <!------ Title ------>
                <p class="font-14 mt-4 mr-2 task--edit--title">التعليقات</p>

                <!------ Loader ------>
                <v-row class="mx-0" v-if="!commentsLoaded">
                  <v-col cols="12" v-for="i in 3" :key="i">
                    <v-skeleton-loader
                      class="background-transparent"
                      width="300"
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>

                <!------ If no there comments ------>
                <div v-if="isNoComments" class="ma-auto">
                  <v-sheet>
                    <v-img
                      class="ma-auto"
                      width="72"
                      height="72"
                      :src="noComments"
                    ></v-img>
                    <span> ليست هناك اي تعليقات </span>
                  </v-sheet>
                </div>

                <!------ Comments ------>
                <div v-else class="comments mb-auto mr-2">
                  <CommentCard
                    v-for="(comment, index) in comments"
                    :key="comment._id"
                    v-bind="generateCommentProps(comment)"
                    @setActiveComment="setActiveComment"
                    @setActiveTextArea="setActiveTextArea"
                    @updateComment="updateComment($event, index)"
                    @deleteComment="deleteComment($event, index)"
                  />
                </div>

                <!------ Add comments ------>
                <v-sheet class="mb-2 send__message__textarea">
                  <v-form @submit.prevent="addComment">
                    <v-textarea
                      data-test="commentInput"
                      filled
                      :disabled="isDoneTask"
                      v-model="commentContent"
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="addComment"
                      placeholder="أدخل تعليقك هنا"
                      auto-grow
                      hide-details
                      class-content="font-12"
                      no-resize
                      rows="1"
                    >
                      <template v-slot:append>
                        <img
                          data-test="addCommentBtn"
                          @click="addComment"
                          alt="add comment"
                          class="pt-3 pointerCursor"
                          src="@/assets/img/svgs/send.svg"
                          loading="lazy"
                        />
                      </template>
                    </v-textarea>
                  </v-form>
                </v-sheet>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-slide-x-transition>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { messageService, userService } from '@/services'
import dateFormat from '@/mixins/dateFormat.js'
import SideDetailesHeader from '@/components/task-manager/SideDetailesHeader'
import FileList from '@/components/details/FileList'
import filesUploader from '@/components/helper/filesUploader'
import CommentCard from '@/components/contracts/CommentCard'

export default {
  name: 'SideDetailes',
  mixins: [dateFormat],
  components: { SideDetailesHeader, FileList, filesUploader, CommentCard },
  props: {
    sideDetailes: {
      type: Boolean,
      default: false
    },
    taskData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isValid: false,
      rules: {
        required: (value) => !!value || 'من فضلك ادخل إسم الطلب'
      },
      commentsLoaded: false,
      activeComment: null,
      activeTextArea: null,
      commentContent: '',
      noComments: require('@/assets/img/svgs/noComments.svg'),
      task: {},
      comments: [],
      files: [],
      taskTitles: {
        name: 'عقار',
        type: 'النوع',
        date: 'تاريخ الإنشاء',
        clientName: 'إسم العميل',
        from: 'موجهه من',
        to: 'المسئول'
      }
    }
  },
  watch: {
    sideDetailes(val) {
      if (val) {
        this.task = { ...this.taskData }
        this.getAllDocuments(this.task._id)
        this.loadComments()
      } else {
        this.task = {}
        this.files = []
        this.comments = []
      }
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    currentUserId() {
      return this.user._id
    },
    isDoneTask() {
      return this.taskData?.status?.nameEn === 'done'
    },
    lg() {
      return this.$vuetify.breakpoint.lgAndDown
    },
    commentToSend() {
      return {
        entityType: 'task',
        entityId: this.taskData._id,
        author: {
          _id: this.user._id,
          name: this.user.name,
          phoneNumber: this.user.phoneNumber,
          type: this.user.type
        },
        receiver: {
          _id: this.taskData.createdBy._id,
          name: this.taskData.createdBy.name
        },
        context: {
          platform: 'hectarPlus'
        }
      }
    },
    // Make sure field is not empty
    isValidComment() {
      return (commentContent) => !!commentContent.length
    },
    isNoComments() {
      return !this.comments.length && this.commentsLoaded
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    /*   Comments   */
    // ------------ //
    // Load
    async loadComments() {
      const query = { entityId: this.taskData._id }
      const response = await messageService.getComments(query)
      const { data } = response ? response : {}
      this.comments = data ? data.items : []
      this.commentContent = ''
      this.commentsLoaded = true
    },
    // Add
    async addComment() {
      if (this.isValidComment(this.commentContent)) {
        const commentToSend = {
          content: this.commentContent,
          ...this.commentToSend
        }
        this.commentContent = ''
        await messageService.addComment(commentToSend)
        this.loadComments()
      }
    },
    // Update
    async updateComment(newComment, commentIndex) {
      if (this.isValidComment(newComment.content)) {
        try {
          const updatedCommentToSend = {
            content: newComment.content,
            _id: newComment.commentId
          }

          await messageService.updateComment(updatedCommentToSend)
          this.$set(this.comments[commentIndex], 'content', newComment.content)
        } catch {
          this.addNotification({
            text: 'لم يتم تحديث التعليق يرجى المحاولة مره اخرى',
            color: 'error'
          })
        }
      }
      this.activeTextArea = null
    },
    // Delete
    async deleteComment(commentId, commentIndex) {
      try {
        await messageService.deleteComment(commentId)
        this.comments.splice(commentIndex, 1)
        this.addNotification({
          text: 'تم حذف التعليق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم يتم حذف التعليق يرجى المحاولة مره اخرى',
          color: 'error'
        })
      }
    },
    generateCommentProps(comment) {
      return {
        comment,
        commentId: this.activeComment,
        isTerminated: this.isDoneTask,
        activeTextArea: this.activeTextArea
      }
    },
    setActiveComment(commentId) {
      this.activeComment = commentId
    },

    /*   Document   */
    // ------------ //
    // Get
    async getAllDocuments(taskId) {
      const response = await userService.getDocuments(taskId)
      const { data } = response ? response : {}
      this.files = data ? data.result : []
    },
    async uploadDocument({ files }) {
      const selectedFiles = Object.values(files)
      selectedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onload = async () => {
          await this.onUpload(file)
        }
        reader.readAsDataURL(file)
      })
    },
    async onUpload(file) {
      try {
        const fd = new FormData()
        fd.append('attachment', file)
        fd.append('entityId', this.task._id)
        fd.append('envId', this.currentEnv._id)
        await userService.addDocument(fd)
        this.addNotification({
          text: 'تم إضافه المرفق بنجاح',
          color: 'success'
        })
      } catch (error) {
        if (error.response?.data?.error === 'feature not allowed') {
          this.addNotification({
            text: 'اضافة الوثائق غير متاحه في الباقة الحالية، يجب الترقية لتتمكن من اضافة الوثائق',
            color: 'error'
          })
        } else {
          this.addNotification({
            text: 'لم يتم إضافة الوثيقة يرجى المحاولة مره اخرى',
            color: 'error'
          })
        }
      } finally {
        this.getAllDocuments(this.task._id)
      }
    },
    // Delete
    async deleteDocument(fileDetails) {
      try {
        await userService.deleteDocument(fileDetails.fileId)
        this.files.splice(fileDetails.fileIndex, 1)
        this.addNotification({
          text: 'تم حذف الوثيقه بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم يتم حذف الوثيقة يرجى المحاولة مره اخرى',
          color: 'error'
        })
      } finally {
        this.showTaskDetails()
      }
    },

    /*     Other    */
    // ----------- //
    deleteTask(taskId) {
      this.$emit('deleteTaskFromSideDetails', taskId)
    },
    setActiveTextArea(commentId) {
      this.activeTextArea = commentId
    },
    updateTaskAfterCloseSideDetails() {
      if (this.isValid) {
        this.$emit('showDetails', false)
        if (
          this.task?.name !== this.taskData?.name ||
          this.task?.description !== this.taskData?.description
        ) {
          this.taskData.name = this.task.name
          this.taskData.description = this.task.description
          this.$emit('updateTask', { item: this.task })
        }
      }
    },
    showTaskDetails() {
      this.$emit('showTask', this.taskData)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_sideDetails.scss';
@media screen and (max-width: 480px) {
  .sidedetails__layout {
    flex-direction: column;
  }
}
</style>
