/* eslint-disable */
/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import NProgress from 'nprogress'
import Vue from 'vue'
import Meta from 'vue-meta'
import Router from 'vue-router'
import store from '../store'

// Routes
import axios from 'axios'
import config from '../config'
import paths from './paths'

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'current',
  linkExactActiveClass: 'exact-current',
  routes: paths,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const token = JSON.parse(localStorage.getItem('tokenData'))
  const currentEnv = JSON.parse(localStorage.getItem('currentEnv'))
  let subscription = JSON.parse(localStorage.getItem('subscription'))
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const moduleAuth = to.meta.moduleAuth || false

  function redirectTo(route, params) {
    next({
      name: route,
      params: { tab: params }
    })
  }

  function checkBundleModules(module) {
    return !!subscription.bundle.modules[module]
  }

  function checkUserPermessionsRules() {
    if (currentEnv?.emlpoyeeRole === 'owner') {
      return !checkBundleModules(to.meta?.acl?.module)
    } else {
      return (
        !checkBundleModules(to.meta?.acl?.module) ||
        !Vue.prototype.$hasPermission(
          to.meta?.acl?.domain,
          to.meta?.acl?.route,
          to.meta?.acl?.permissions
        )
      )
    }
  }
  // If User already loggedIn
  if (
    to.name == 'login' &&
    token?.token &&
    token?.name &&
    !to.query?.invitation?.length
  ) {
    return next({ name: 'setup-env', query: { redirectFrom: to.fullPath } })
  }

  // If user already has an Enviroment
  if (to.name == 'setup-env' && currentEnv && currentEnv?._id) {
    return next('/dashboard')
  }

  // If User Have no Permession to view the route or the module is out of registred bundle
  if (to.name !== 'upgrade' && moduleAuth && currentEnv) {
    try {
      store.dispatch('accountState/getUserBundle')
    } catch (e) {
      store.commit('appState/addNotification', {
        text: 'خطأ في تحميل بيانات الباقه',
        color: 'error'
      })
    }
    checkUserPermessionsRules() ? next('/upgrade') : next()
  }

  // If this isn't an initial page load...
  if (from.name !== null) {
    NProgress.start()
  }

  if (requiresAuth && !currentEnv && to.path !== '/auth/login') {
    return redirectTo('login')
  }

  // check if no subscription is set, retrieve it
  //get user subscription

  if (currentEnv) {
    const subscriptionData = await store.dispatch(
      'accountState/getUserSubscription'
    )
    const tokenData = await store.dispatch('accountState/getUserTokenData')
    let status = subscriptionData.subscription.status
    let subscriptionEndDate = subscriptionData.subscription.endDate
    if (
      (status != 'suspended' && status != subscription?.status) ||
      subscriptionEndDate != subscription.endDate
    ) {
      localStorage.setItem(
        'subscription',
        JSON.stringify(subscriptionData.subscription)
      )
      subscription = subscriptionData.subscription
    } else if (
      status === 'suspended' ||
      tokenData?.active != token?.active ||
      tokenData?.inactive != token?.inactive
    ) {
      store.dispatch('accountState/logout')
      store.commit('appState/addNotification', {
        text: 'من فضلك قم بتسجيل الدخول مره أخري أو تواصل مع خدمه العملاء',
        color: 'error'
      })
    }
  }

  //check for  user subscription
  //get user subscription

  if (subscription && subscription?.status === 'active') {
    try {
      store.dispatch('accountState/getUserBundle')
    } catch (e) {
      store.commit('appState/addNotification', {
        text: 'خطأ في تحميل بيانات الباقه',
        color: 'error'
      })
    }
  } else if (subscription && subscription?.status === 'inactive') {
    if (token?.token && to.path !== '/dashboard/edit-profile/0') {
      store.commit('appState/addNotification', {
        text: 'عذرا، لقد انتهى اشتراكك الحالى،الرجاء الدفع و تجديد الإشتراك',
        color: 'error'
      })
      return redirectTo('edit-profile', 0)
    }
  }

  //redirectTo edit-profile in case the user did not pay

  if (
    (subscription?.status === 'pending' ||
      subscription?.status === 'expired') &&
    to.path !== '/dashboard/edit-profile/0'
  ) {
    store.commit('appState/addNotification', {
      text: 'الرجاء دفع الفاتوره لتفعيل الحساب مره اخري',
      color: 'error'
    })
    return redirectTo('edit-profile', 0)
  }

  //get permissions for this current user if he is not the owner

  if (currentEnv && token) {
    if (!currentEnv.emlpoyeeRole === 'owner') {
      try {
        if (!axios.defaults.headers.common['Authorization']) {
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${token.token}`
        }
        const response = await axios.get(
          `${config.usersUrl}/acls/users/get-permissions/${token._id}/${currentEnv._id}`
        )

        if (response.data.permissions) {
          // Check if permissions  changed
          if (currentEnv.permissions.uuid !== response.data.permissions.uuid) {
            currentEnv.permissions = response.data.permissions
            localStorage.setItem('currentEnv', JSON.stringify(currentEnv))
            store.commit('accountState/setCurrentEnv', currentEnv)
          }
        }
      } catch (error) {
        return redirectTo('login')
      }
    }
  }

  if (to.query.invitation && token) {
    return store.commit('appState/loggedInConfirm')
  }

  next()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach((to) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

Vue.use(Meta)

export default router
