<template>
  <v-dialog max-width="400px" persistent :value="dialogState">
    <v-card style="border: 0 !important">
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog" :width="16">mdi-close</v-icon>
      </v-toolbar>
      <v-card-text class="my-0 pb-4 text-center">
        <v-img
          :max-width="img.width"
          :max-height="img.height"
          class="mx-auto"
          :src="img.path"
        ></v-img>
        <div class="mt-6 font-18 font-weight-medium mainTitle">
          هل أنت متأكد من إلغاء المطالبة؟
        </div>
        <div class="mt-3 font-12 font-weight-medium subTitle">
          سيتم إلغاءالدفعة والعمليات والسندات المترطبه بهذة المطالبة
        </div>
      </v-card-text>
      <v-card-actions class="pt-0 px-0 actionsContainer">
        <v-spacer />
        <v-btn
          color="red"
          @click.native="agreeOrCancel(true)"
          class="btnActions mx-2 mt-0 pdf__action__btns"
          min-width="135"
          min-height="40"
        >
          <span class="tf">تأكيد</span>
        </v-btn>
        <v-btn
          class="btnActions cancel mx-2 mt-0 pdf__action__btns"
          @click.native="closeDialog()"
          min-width="135"
          min-height="40"
          color="gray"
        >
          <span class="tf"> الغاء</span>
        </v-btn>

        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialogState'],
  data() {
    return {
      due: {},
      img: {
        path: require('@/assets/img/svgs/closeImg.svg'),
        width: 56,
        height: 56
      }
    }
  },
  watch: {},
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    agreeOrCancel() {
      this.$emit('agreeOrCancel')
    }
  },
  computed: {}
}
</script>
<style lang="scss" scoped>
.mainTitle {
  color: #101010;
}
.subTitle {
  color: #737373;
}
.v-card .v-card__actions.actionsContainer {
  border: none;
  .btnActions {
    border-radius: 13px;
  }
  .cancel {
    background: #9f9fac;
  }
}
</style>
