<template>
  <div id="invoice_pdf_page">
    <div class="invoice_pdf_content">
      <!--------Invoice header--------->
      <div class="invoice_top border-bottom">
        <div class="invoice_top__logo mb-10">
          <!----------Logo---------------->
          <div class="logo_div">
            <div
              class="min-logo-white sidebar__mini__Logo"
              style="height: 50px"
            >
              <img
                src="../../assets/img/logo.svg"
                alt=""
                style="max-height: 100px; max-width: 100px"
                loading="lazy"
              />
            </div>
          </div>
          <!-- Invoice Status  -->
          <div class="invoice_status_div d-flex align-items-center">
            <div class="invoice_status_btn">
              <span :class="bill.status">
                {{ paymentStatus(this.bill.status) }}
              </span>
            </div>
          </div>
          <!-- invoice_status_div -->
          <!----------Title and date---------------->
          <div class="invoice_date font-14 fw-600">
            <span class="mb-0">{{ formatDate(new Date()) }}</span>
            <div class="invoice_creation_date mt-0">إيصال</div>
          </div>
        </div>
        <!-- <div class="invoice_top__info">
        <div class="invoice_title">
          <h4 class="font-16 mt-0">Company name here</h4>
        </div>
      </div> -->
      </div>
      <!-----Invoice info----->
      <div class="invoice_details border-bottom">
        <div class="invoice_details__content">
          <div class="amount_div">
            <span class="font-14 color__title">فاتورة</span>
            <h6 class="font-15 text-truncate">{{ bill.number || '' }}</h6>
            <h6 class="font-15 text-truncate invoice_created_date">
              {{ formatDate(bill.createdAt || new Date()) }}
            </h6>
          </div>
          <div class="invoice_company_name">
            <span class="font-14 color__title">من</span>
            <h6 class="font-15 text-truncate">
              شركة تطبيق هكتار للتسويق الإلكتورني اسم الشركة:
            </h6>
            <h6 class="font-15 text-truncate">
              العنوان : 4554 طريق انس بن مالك الرياض 13524 ، المملكة العربية
              السعودية
            </h6>
            <h6 class="font-15 text-truncate">السجل التجاري : 1010639544</h6>
            <h6 class="font-15 text-truncate invoice_for">
              الرقم الضريبي : 310592007800003
            </h6>
          </div>
          <div class="from_to_date">
            <span class="font-14 color__title">الى</span>
            <h6
              class="font-15 text-truncate max"
              v-for="(val, key) in userData"
              :key="key"
            >
              {{ getNameAr(key) }}: {{ val }}
            </h6>
          </div>
        </div>
      </div>
      <!-----------For invoice details datatable---------->
      <div class="invoice_table">
        <v-data-table
          :headers="headers"
          :items="invoices"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:[`item.total`]="{ item }">
            <span class="subtitle-1 font-weight-black">{{ item.total }}</span>
            <span class="font-12 px-2">ريال سعودي</span>
          </template>
          <template v-slot:[`item.descp`]="{ item }">
            <span v-if="item.descp">{{ item.descp }}</span>
            <div v-else>
              <span class="d-inline-block ml-2 body-1 font-weight-bold">{{
                item.totalDescps
              }}</span>
              <span class="font-12 font-weight-medium d-inline-block mr-2"
                >شامل قيمة الضريبة المضافة</span
              >
            </div>
          </template>
        </v-data-table>
      </div>
      <!-- invoice_table -->
      <!-- after table content starts  -->
      <div class="after_table_div">
        <div class="after_table_content d-flex justify-space-between">
          <div class="transaction_detail">
            <div class="transaction_detail_titles">
              <div class="transaction_number_title">
                <span class="font-14 color__title">
                  رقم التحويل (Transaction Number):</span
                >
              </div>
              <!-- transaction_number_title -->
              <div class="transaction_date">
                <span class="font-14 color__title"> التاريخ (Date):</span>
              </div>
              <!-- transaction_date -->
              <div class="bank_name">
                <!-- <img class="bank_logo" :src="bill.bankId.logo" /> -->
                <span class="font-14 color__title">
                  اسم البنك (Bank Name):</span
                >
              </div>
              <!-- bank name -->
            </div>
            <!-- transaction_detail_titles -->
            <div class="transaction_detail_descp">
              <div v-if="bill.bankId" class="transaction_number_desp">
                <h6 class="font-15 text-truncate">
                  {{ bill.trxId }}
                </h6>
              </div>
              <!-- transaction_number_descp -->
              <div v-if="bill.bankId" class="transaction_date">
                <h6 class="font-15 text-truncate">
                  {{ formatDate(bill.createdAt) }}
                </h6>
              </div>
              <!-- transaction_date -->
              <div v-if="bill.bankId" class="bank_name mt-3">
                <h6 class="font-15 text-truncate">{{ bill.bankId.nameAr }}</h6>
              </div>
              <!-- bank name -->
            </div>
            <!-- transaction_detail_descp -->
          </div>
          <!-- transaction_detail -->
          <div class="qr_div">
            <div class="qr_scanner">
              <qrcode
                v-if="qrInfo"
                :value="qrInfo"
                :options="{ width: 150 }"
              ></qrcode>
            </div>
            <!-- qr_scanner -->
          </div>
          <!-- qr_div -->
        </div>
        <!-- after_table_content -->
      </div>
      <!-- after table content Ends  -->
    </div>
    <!-- invoice_pdf_content -->
    <!-----------Footer--------->
    <div class="invoice_pdf_footer">
      <div class="logo_div">
        <div class="min-logo-white sidebar__mini__Logo mb-1">
          <img
            src="../../assets/img/min-logo-white.png"
            alt=""
            style="max-height: 30px; max-width: 30px"
            loading="lazy"
          />
        </div>
      </div>
      <div class="footer_text w-100 text-center">
        <h5 class="f-normal">جميع الحقوق محفوظة</h5>
      </div>
    </div>
  </div>
  <!-- invoice_pdf_page -->
</template>
<script>
import dateFormat from '@/mixins/dateFormat.js'
import { mapState } from 'vuex'

export default {
  name: 'InvoicePdf',
  mixins: [dateFormat],
  props: {
    bill: {
      default() {
        return {}
      }
    },
    bundle: {
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // for table
      headers: [
        {
          text: 'الوصف (Description)',
          value: 'descp'
        },
        {
          text: 'السعر (Price)',
          value: 'price'
        },
        {
          text: 'الخصم (Discount)',
          value: 'discount'
        },
        {
          text: 'المجموع (ريال سعودي)',
          value: 'total'
        }
      ]
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'subscription', 'currentEnv']),
    userData() {
      return {
        company: this.user?.company || 'لا يوجد',
        address: this.user?.address || 'لا يوجد',
        taxNumber: this.user?.taxNumber || 'لا يوجد',
        commercialNumber: this.currentEnv?.CommercialNumber || 'لا يوجد'
      }
    },
    getNameAr: () => (text) => {
      switch (text) {
        case 'company':
          return 'إسم الشركه' || ''
        case 'name':
          return 'إسم العميل' || ''
        case 'address':
          return 'العنوان' || ''
        case 'taxNumber':
          return 'الرقم الضريبي'
        case 'commercialNumber':
          return 'السجل التجاري'
        default:
          ''
      }
    },
    infoInvoice() {
      return {
        price: this.bill.subscription?.netTotal,
        discount: this.bill.couponId ? `${this.bill.couponPercentage}%` : '-',
        discountedYearPrice: this.bill.amountAfterDiscount,
        titleTotalDiscount: `ضريبة قيمة مضافة (${this.bill.tax}%)`,
        totalDiscount: this.bill.taxAmount,
        total: this.bill.amount
      }
    },
    invoices() {
      // this part need to be visited after backEnd task done
      return [
        {
          descp: `تجديد الاشتراك في ${this.subscription.bundle.nameAr}`,
          price: this.infoInvoice.price,
          discount: this.infoInvoice.discount,
          total: this.infoInvoice.discountedYearPrice
        },
        {
          descp: this.infoInvoice.titleTotalDiscount,
          total: this.infoInvoice.totalDiscount
        },
        {
          totalDescps: 'الإجمالي',
          total: this.infoInvoice.total
        }
      ]
    },
    paymentStatus: () => (app) => {
      switch (app) {
        case 'pending':
          return 'تحت المراجعه'
        case 'rejected':
          return 'مرفوض'
        case 'paid':
          return 'مقبول'
        default:
          return 'غير معروف'
      }
    },
    qrInfo() {
      return `رقم التحويل : ${
        this.bill?.bankId?.accountNo
      } \nالتاريخ : ${this.formatDate(this.bill?.createdAt)} \nاسم البنك : ${
        this.bill?.bankId?.nameAr
      } \nرقم الفاتوره : ${this.bill?.trxId}`
    }
  }
}
</script>
<style lang="scss">
@import '../../styles/responsiveness/responsiveness.scss';
$purple-color: #662b8c;
$green-color: #3cb97d;
$orange-color: #f49300;
$red-color: #fc4a4a;
$white-color: #fff;

#invoice_pdf_page {
  background-color: $white-color;
  padding: 0px;
  height: 100%;
  @include max-lg {
    padding: 30px;
  }
  @include max-sm {
    padding: 10px;
  }
  .invoice_pdf_content {
    direction: rtl;
    padding: 50px 20px;
    background-color: $white-color;
    @include max-md {
      padding: 30px;
    }
    @include max-sm {
      padding: 10px;
    }
    /*  */
    .mb-0 {
      margin-bottom: 0 !important;
    }
    .mt-0 {
      margin-top: 0 !important;
    }
    .invoice_status_div {
      .invoice_status_btn {
        span {
          border: 2px solid $green-color;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 120px;
          padding: 2px 6px 8px;
          font-weight: 700;
          color: $green-color;
          border-radius: 4px;
          @include max-sm {
            min-width: 100px;
            padding: 0px 4px 6px;
          }
        }
        span.paid {
          border: 2px solid $green-color;
          color: $green-color;
        }
        span.pending {
          border: 2px solid $orange-color;
          color: $orange-color;
        }
        span.rejected {
          border: 2px solid $red-color;
          color: $red-color;
        }
      }
      // invoice_status_btn
    }
    // invoice_status_div
    .invoice_top {
      @include max-sm {
        padding-top: 4px;
      }
      &__logo,
      &__info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include max-sm {
          &.mb-5 {
            margin-bottom: 12px !important;
          }
        }
      }
      @include max-sm {
        .invoice_status_div {
          order: 1;
          width: 100%;
          text-align: center;
          justify-content: center;
          margin-top: 10px;
        }
        // .invoice_status_div
        .invoice_date {
          text-align: left;
        }
        // .invoice_date
      }
    }
    .border-bottom {
      border-bottom: 2px solid #ddd;
    }
    .invoice_details {
      padding: 35px 0 35px;
      @include max-sm {
        padding: 20px 0 10px;
      }
      &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 20px;
        /*  */
        & > * {
          flex-basis: calc(99% / 3);
          flex-shrink: 0;
        }
      }
    }
    .color__title {
      color: #a09f9e;
      font-weight: 700;
      margin-bottom: 6px;
      display: flex;
    }
  }
  .invoice_table {
    padding: 50px 0px;
    @include max-sm {
      padding-top: 20px;
    }
    table {
      thead {
        @include max-sm {
          display: none;
        }
        tr {
          background-color: $purple-color !important;
          th {
            color: $white-color;
            padding-bottom: 14px;
            padding-top: 14px;
            border: 1px solid $white-color;
            border-collapse: collapse;
            text-align: center !important;
            @include max-md {
              padding-bottom: 0;
              padding-top: 6px;
              padding-left: 6px;
              padding-right: 6px;
            }
            span {
              font-weight: 700 !important;
              font-size: 13px !important;
            }
          }
          // th
        }
        // tr
      }
      // thead
      tbody {
        tr {
          &:last-of-type {
            background-color: $purple-color !important;
          }
          background-color: #c0b8cc;
          &:first-child {
            border: 1px solid $white-color;
            border-collapse: collapse;
            @include max-sm {
              border: 0;
            }

            td {
              border: 1px solid $white-color;
              @include max-sm {
                border: 0;
              }
            }
          }
          td {
            color: $white-color;
            text-align: center !important;
            font-weight: 700 !important;
            padding-bottom: 14px;
            padding-top: 14px;
            @include max-md {
              padding-top: 6px;
              padding-bottom: 6px;
              padding-top: 6px;
              font-size: 0.75rem;
            }
            &:first-child {
              text-align: right !important;
              padding-right: 40px;
              @include max-md {
                padding-right: 20px;
              }
            }
          }
          // td
        }
        // tr
      }
      // tbody
    }
    // table
  }
  .after_table_content {
    padding-top: 50px;
    display: flex !important;
    justify-content: space-between;
    @include max-sm {
      padding-top: 30px;
      flex-wrap: wrap;
      .qr_div {
        width: 100%;
        text-align: left;
      }
    }
    .transaction_detail {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .transaction_detail_descp {
        h6 {
          margin-bottom: 10px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin-top: 6px;
          font-weight: 600;
        }
        // h6
      }
      // transaction_detail_descp

      .bank_logo {
        width: 30px;
        height: 30px;
        border: 1px solid transparent;
        border-radius: 50%;
      }
    }
    // .transaction_detail
  }
  // after_table_content
  /* invoice pdf footer */
  .invoice_pdf_footer {
    margin-top: 36px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $purple-color;
    padding: 8px 40px;
    @include max-sm {
      padding: 0 10px;
    }
    /*  */
    .logo_div {
      position: relative;
      right: 20px;
      @include max-sm {
        right: 0;
      }
    }
    .footer_text {
      text-align: center;
      color: $white-color;
    }
  }
  .f-normal {
    font-weight: normal;
  }
}
// #invoice_pdf_page
</style>
