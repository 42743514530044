<template>
  <v-data-table
    v-if="!gridView"
    style="
      cursor: pointer;
      position: relative;
      align-items: center;
      justify-content: center;
    "
    :items-per-page="properties.length"
    :headers="headers"
    :items="properties"
    hide-default-footer
    mobile-breakpoint="0"
    class="hectar-table"
  >
    <template v-slot:[`item.checked`]="{ item }">
      <v-checkbox
        v-model="item.checked"
        @click.stop
        class="reset-all ma-0"
        dense
        hide-details
      />
    </template>
    <template v-slot:[`item.number`]="{ item }">
      <span class="black--text"> {{ item.number || '-' }} </span>
    </template>
    <template v-slot:[`item.active`]="{ item }">
      <v-icon
        class="send-icon font-16"
        @click.stop="editPropertyName(item._id)"
      >
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <v-text-field
        v-model="item.name"
        v-if="editMood && editedPropertyId == item._id"
        dense
        filled
        hide-details
        class="pa-0 ma-0 font-12"
        @blur="setNewPropertyName(item)"
      />
      <span class="black--text" v-else @click.prevent.stop="handleClick(item)">
        {{ item.name }}
      </span>
    </template>
    <template v-slot:[`item.propertyName`]="{ item }">
      <span
        class="black--text text-truncate text-decoration-underline"
        @click="handleClick(item)"
      >
        {{ item.propertyName }}
      </span>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <div
        :class="['tabel-tag', 'text-center', setStatusTagStyle(item.status)]"
      >
        <h6 class="mb-1 ma-0">
          {{ item.status.nameAr }}
        </h6>
      </div>
    </template>
    <template v-slot:[`item.tenet.name`]="{ item }">
      <h5 class="font-12 font-weight-bold text-truncate" v-if="item.tenet">
        {{ item.tenet.name }}
      </h5>
      <h5 class="font-12 font-weight-bold text-truncate" v-else>لايوجد</h5>
    </template>
    <template v-slot:[`item.owner.name`]="{ item }">
      <h5 class="font-12 font-weight-bold text-truncate" v-if="item.owner">
        {{ item.owner.name }}
      </h5>
      <h5 class="font-12 font-weight-bold text-truncate" v-else>لايوجد</h5>
    </template>
    <template v-slot:[`item.price`]="{ item }">
      <h5 class="font-12 font-weight-bold text-truncate" v-if="item.price">
        {{ formattedNumbers(item.price) }}
      </h5>
      <h5 class="font-12 font-weight-bold text-truncate" v-else>-</h5>
    </template>
    <template v-slot:[`item.space`]="{ item }">
      <h5 class="font-12 font-weight-bold text-truncate" v-if="item.space">
        {{ item.space }}
      </h5>
      <h5 class="font-12 font-weight-bold text-truncate" v-else>-</h5>
    </template>
    <template v-slot:[`item.type`]="{ item }">
      <h5 class="font-12 font-weight-bold text-truncate">
        {{ item.type.nameAr }}
      </h5>
    </template>

    <template v-slot:[`item.moderators`]="{ item }">
      <v-row class="justify-center" v-if="item.moderators">
        <v-col
          v-for="(moderator, i) in item.moderators.slice(0, 2)"
          :key="i"
          cols="3"
          class="pa-0"
        >
          <v-tooltip bottom color="black" content-class="v-tool" min-width="50">
            <template slot="activator" slot-scope="{ on: tooltip }">
              <div v-on="{ ...tooltip }">
                <div class="d-flex align-center mx-auto">
                  <h5
                    class="mx-auto red--tag bill font-12 px-1 mb-2 font-weight-bold white-space-nowrap"
                  >
                    {{ $subString(moderator.name) }}
                  </h5>
                </div>
              </div>
            </template>
            <span class="tool--tip" v-if="moderator">{{ moderator.name }}</span>
          </v-tooltip>
        </v-col>
        <p class="my-auto" v-if="item.moderators.length > 2">
          (+{{ item.moderators.length - 2 }} أخرون)
        </p>
      </v-row>
      <h5 class="font-12 font-weight-bold text-truncate" v-else>-</h5>
    </template>

    <template v-slot:[`item.customerModerators`]="{ item }">
      <v-row class="justify-center" v-if="item.moderators">
        <h5 class="font-12 font-weight-bold text-truncate">
          {{ item.moderators[0].name }}
        </h5>
        <p class="my-auto mr-1" v-if="item.moderators.length > 1">
          (+{{ item.moderators.length - 1 }} أخرون)
        </p>
      </v-row>
      <h5 class="font-12 font-weight-bold text-truncate" v-else>-</h5>
    </template>

    <template v-slot:[`item.nextInstallmentDate`]="{ item }">
      <h5
        v-if="item.nextInstallmentDate && item.nextInstallmentDate !== '-'"
        class="font-12 font-weight-bold"
      >
        {{ ToGregorianDateInArabic(item.nextInstallmentDate) }}
      </h5>
      <h5 v-else class="font-12 font-weight-bold">-</h5>
    </template>

    <template v-slot:[`item.netAnnualUtilities`]="{ item }">
      <h5 v-if="item.netAnnualUtilities" class="font-12 font-weight-bold">
        {{ item.netAnnualUtilities }}
      </h5>
      <h5 v-else class="font-12 font-weight-bold">-</h5>
    </template>

    <template v-slot:[`item.netAnnualRent`]="{ item }">
      <h5 v-if="item.netAnnualRent" class="font-12 font-weight-bold">
        {{ item.netAnnualRent }}
      </h5>
      <h5 v-else class="font-12 font-weight-bold">-</h5>
    </template>

    <template v-slot:[`item.contractStatus`]="{ item }">
      <h5 v-if="item.contractStatus" class="font-12 font-weight-bold">
        {{ item.contractStatus }}
      </h5>
      <h5 v-else class="font-12 font-weight-bold">-</h5>
    </template>
  </v-data-table>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'data-table-property',
  mixins: [dateFormat],
  props: {
    properties: {
      type: Array,
      required: true
    },
    gridView: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      editMood: false,
      editedPropertyId: ''
    }
  },
  computed: {
    formattedNumbers() {
      return (str) => str?.toLocaleString('en-US', { maximumFractionDigits: 2 })
    }
  },
  methods: {
    handleClick(item) {
      this.$router.push(
        `/dashboard/property-details/${item._id}?parent=${
          item.parentId && item.parentId !== '-' ? item.parentId : item._id
        }`
      )
    },
    editPropertyName(id) {
      this.editMood = !this.editMood
      this.editedPropertyId = id
    },
    setNewPropertyName(item) {
      this.$emit('updateRealEstate', item)
      this.editPropertyName(item._id)
    },
    setStatusTagStyle(status) {
      switch (status.nameEn) {
        case 'sold':
          return 'orange--tag'
        case 'rented':
          return 'green--tag'
        case 'sale':
          return 'brub--tag'
        case 'rent':
          return 'blue--tag'
        default:
          return 'grey--tag'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  tr {
    display: flex;
  }
  th,
  td {
    display: flex;
    width: 170px;
  }
}
</style>
