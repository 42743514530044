<template>
  <div
    class="mt-6 mb-4 justify-between align-center"
    :class="light ? 'd-flex' : 'd-bllock'"
  >
    <h5 class="ma-0">المرفقات</h5>
    <div class="add-file-btn ma-0 px-0 focus-none" v-if="light">
      <form enctype="multipart/form-data">
        <v-file-input
          truncate-length="15"
          prepend-icon="mdi-plus"
          hide-input
          @change="$emit('selectFile', { files: $event })"
          :disabled="disabled"
          multiple
        />
      </form>
    </div>
    <div v-else>
      <div v-if="attachments.length">
        <v-row class="position-relative mt-2">
          <v-col
            v-for="(attach, index) in attachments"
            :key="attach.Key"
            class="d-flex justify-center align-center"
            sm="3"
            cols="6"
          >
            <div class="position-relative" v-if="attach.Location">
              <img :src="attach.Location" class="add-pic" loading="lazy" />
              <div
                v-if="!attach.Key && attach.Location"
                class="lds-ring-container"
              >
                <div class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <v-icon
                @click="deleteAttachment(attach.id, index)"
                data-test="deleteBlueprint"
                class="pic-icon position-absolute slide-y-transition"
                color="white"
              >
                mdi-close
              </v-icon>
            </div>
          </v-col>

          <v-col sm="3" cols="6">
            <div
              class="w-100 d-flex pic-wrapper d-flex justify-center align-center py-8"
            >
              <v-btn
                @click="openUplaodAttachMenu"
                class="brub--border rounded-7"
                icon
              >
                <v-icon color="general"> mdi-plus </v-icon>
              </v-btn>
              <input
                @change="addAttachment($event)"
                accept="image/*"
                ref="inputUpload"
                type="file"
                v-show="false"
                multiple
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <div
        v-else
        @drop.prevent="addAttachment($event)"
        @dragleave.prevent="dragLeave('pic')"
        @dragover.prevent="dragOver('pic')"
        @dragenter.prevent
        ref="pic"
        class="pic-wrapper d-flex align-center w-100 pa-6 mt-3"
      >
        <div>
          <v-btn
            @click="$refs.inputUpload.click()"
            class="py-2 px-3 rounded-7 font-weight-bold"
            color="general"
            outlined
          >
            <v-icon size="20" color="general"> mdi-plus </v-icon>
            <span class="font-12"> إضافه مرفق </span>
          </v-btn>
          <input
            @change="addAttachment($event)"
            accept="image/*"
            ref="inputUpload"
            type="file"
            v-show="false"
            multiple
          />
        </div>
        <div class="mr-3">
          <h4 class="font-12">إسحب المرفق هنا أو إضغط علي إضافة مرفق</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drag from '@/mixins/drag.js'

export default {
  name: 'filesUploader',
  props: {
    disabled: {
      default: false
    },
    light: {
      default: true
    },
    attachments: {
      default() {
        return []
      }
    }
  },
  mixins: [drag],
  methods: {
    addAttachment(files) {
      this.$emit('uploadAttachment', { files })
    },
    deleteAttachment(id, index) {
      this.$emit('deleteAttachment', { id, index })
    },
    openUplaodAttachMenu() {
      this.$refs.inputUpload.click()
    }
  }
}
</script>
