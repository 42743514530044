import config from '../config'
import axios from 'axios'

axios.defaults.headers.client = 'HectarPlus'

// get all requests
const getAllRequests = function (query = 'pageSize=10&pageNumber=1') {
  // ! change to config.usersUrl
  const url = `${config.usersUrl}/requests${query}`
  return axios.get(url)
}

// add request
const addRequest = function (data) {
  const url = `${config.usersUrl}/requests`
  return axios.post(url, data)
}

// update request
const updateRequest = function (requestId, data) {
  const url = `${config.usersUrl}/requests/${requestId}`
  return axios.put(url, data)
}

// expenses
const getExpenses = function (requestId) {
  const url = `${config.bonds}/expenses/getAll/${requestId}`
  return axios.get(url)
}

const addExpense = function (data) {
  const url = `${config.bonds}/expenses`
  return axios.post(url, data)
}

const editExpense = function (expense) {
  const url = `${config.bonds}/expenses/${expense._id}`
  return axios.put(url, expense)
}

const getRequestsTypes = function () {
  const url = `${config.usersUrl}/request-types`
  return axios.get(url)
}
const deleteExpense = function (expenseId) {
  const url = `${config.bonds}/expenses/${expenseId}`
  return axios.delete(url)
}

const getRequestsConfig = function () {
  const url = `${config.bonds}/requests/configs`
  return axios.get(url)
}

export const requestsService = {
  getAllRequests,
  addRequest,
  updateRequest,
  getExpenses,
  addExpense,
  editExpense,
  deleteExpense,
  getRequestsConfig,
  getRequestsTypes
}
