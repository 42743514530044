<template>
  <v-card-text class="pa-0">
    <!---------Loader------------>
    <Loader v-if="isPageLoading" v-bind="loaderProps" />
    <v-form v-else v-model="isValid">
      <h6 class="mb-2 mt-4">نوع العقد</h6>
      <v-layout class="d-flex justify-space-between radioGroup w-100">
        <v-radio-group
          :rules="contractTypeRule"
          class="mx-auto my-0"
          row
          v-model="selectedContractType"
          :disabled="isEditMood"
        >
          <v-radio
            v-for="contract in contractTypes"
            :key="contract._id"
            :value="contract"
            class="mx-1"
            @change="setValdationStatus"
          >
            <template slot="label">
              <v-card
                class="mobile__w__auto mobile__mw__260px"
                :class="{
                  'brub--tag-border':
                    contract.nameEn === selectedContractTypeNameEn
                }"
                width="210"
                height="100"
              >
                <div class="mt-6 text-center">
                  <img
                    :src="
                      contract.nameEn === selectedContractTypeNameEn
                        ? contract.activePic
                        : contract.pic
                    "
                    :lazy-src="contract.pic"
                  />
                  <h6
                    :class="{
                      'text-primary':
                        contract.nameEn === selectedContractTypeNameEn
                    }"
                    class="font-weight-medium mt-0"
                  >
                    {{ contract.nameAr }}
                  </h6>
                </div>
              </v-card>
            </template>
          </v-radio>
        </v-radio-group>
      </v-layout>
      <v-layout
        class="d-flex justify-space-around w-100 mb-1"
        v-if="isMaintenanceContract"
      >
        <v-card
          :class="{
            'brub--tag-border': isSubTypeContractChecked(maintenanceType._id)
          }"
          v-for="maintenanceType in subContractTypes"
          :key="maintenanceType._id"
          width="190"
        >
          <v-checkbox
            :value="maintenanceType._id"
            class="ma-0 contract mr-1"
            dense
            hide-details
            v-model="selectedContractSuptypes"
            :rules="subContractTypeRule"
            @change="setValdationStatus"
          >
            <template v-slot:label>
              <div class="text-center">
                <h5
                  :class="{
                    'text-primary': isSubTypeContractChecked(
                      maintenanceType._id
                    )
                  }"
                  class="font-weight-medium mt-0 pt-1 mb-2"
                >
                  {{ maintenanceType.nameAr }}
                </h5>
              </div>
            </template>
          </v-checkbox>
        </v-card>
      </v-layout>
    </v-form>
  </v-card-text>
</template>

<script>
import { contractsService } from '@/services'
import Loader from '@/components/helper/loader.vue'
import slugify from '@/mixins/slugify.js'

export default {
  name: 'contractType',
  props: {
    updatedContract: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    },
    exclamation: {
      default: require('@/assets/img/svgs/exclamationMark.svg')
    }
  },
  components: { Loader },
  mixins: [slugify],
  data() {
    return {
      contractInfo: {},
      contractTypes: [],
      subContractTypes: [],
      isValid: false,
      isPageLoading: true,
      selectedContractType: {},
      selectedContractSuptypes: []
    }
  },
  watch: {
    selectedContractType: {
      deep: true,
      handler(val) {
        if (val.nameEn !== 'maintenance') {
          this.selectedContractSuptypes = []
        }
      }
    }
  },
  methods: {
    bindContractData() {
      this.contractInfo = { ...this.contractToAdd }
      this.$emit('bindContractData', this.contractInfo)
    },
    setValdationStatus() {
      this.$emit('resetData')
      this.$nextTick(() => {
        this.$emit('setValdationStatus', this.isValid)
      })
    },
    isSubTypeContractChecked(id) {
      return !!this.selectedContractSuptypes.includes(id)
    },
    async getContractTypes() {
      try {
        const contractTypesResponse = await contractsService.getContractTypes()
        this.contractTypes = contractTypesResponse.data.contractTypes.map(
          (c) => ({
            _id: c._id,
            nameAr: c.nameAr,
            nameEn: c.nameEn,
            pic: require(`@/assets/img/svgs/contract/${this.slugify(
              c.nameEn
            )}.svg`),
            activePic: require(`@/assets/img/svgs/contract/${this.slugify(
              c.nameEn
            )}-active.svg`)
          })
        )
        const maintenanceContract =
          contractTypesResponse.data.contractTypes.find((cType) => {
            return cType.nameEn === 'maintenance'
          })
        this.subContractTypes = maintenanceContract.subTypes.map((c) => ({
          _id: c._id,
          nameAr: c.nameAr,
          nameEn: c.nameEn,
          value: c.code,
          checked: false
        }))
        this.$emit('setSubContractTypes', this.subContractTypes)
      } catch {
      } finally {
        this.isPageLoading = this.isEditMood
      }
    },
    getContractTypeObject(contractTypeNameEn) {
      return this.contractTypes.find((T) => {
        return contractTypeNameEn === T.nameEn
      })
    },
    getContractSubTypeObject(subTypesArr = []) {
      const returnedSubTypes = []
      subTypesArr.map((S) => {
        returnedSubTypes.push(S._id)
      })
      this.selectedContractSuptypes = [...returnedSubTypes]
    },
    editMoodPresetData() {
      if (this.isEditMood || Object.keys(this.updatedContract).length) {
        const typeObj = this.getContractTypeObject(
          this.updatedContract?.typeName
        )
        this.getContractSubTypeObject(this.updatedContract?.subTypes)
        this.selectedContractType = { ...typeObj }
        if (Object.keys(this.selectedContractType).length) {
          this.setValdationStatus()
          this.$emit('presetIsEditMoodData')
        }
      }
    }
  },
  computed: {
    isMaintenanceContract() {
      return !!(this.selectedContractType?.nameEn === 'maintenance')
    },
    contractTypeRule() {
      return [Object.keys(this.selectedContractType).length > 0 || '']
    },
    subContractTypeRule() {
      return [this.selectedContractSuptypes.length > 0 || '']
    },
    selectedContractTypeNameEn() {
      const { nameEn } = this.selectedContractType
      return nameEn ?? ''
    },
    selectedContractType_Id() {
      const { _id } = this.selectedContractType
      return _id ?? ''
    },
    contractToAdd() {
      const contractData = {
        type: this.selectedContractType_Id,
        typeName: this.selectedContractTypeNameEn,
        ...(this.selectedContractSuptypes.length && {
          subTypes: this.selectedContractSuptypes
        })
      }
      return contractData
    },
    loaderProps() {
      return {
        laoderClasses: 'mx-auto',
        type: 'card',
        cols: 4,
        numberOfLines: 3
      }
    }
  },
  async created() {
    await this.getContractTypes()
    this.editMoodPresetData()
  }
}
</script>

<style lang="scss">
.radioGroup {
  .v-input--radio-group__input {
    justify-content: center;
    gap: 10px;
    .v-radio {
      position: relative;
      margin: 0px !important;
      .v-input--selection-controls__input {
        position: absolute !important;
        z-index: 1000;
        top: 8px;
        right: 15px;
      }
    }
  }
  // .v-input--radio-group__input
}
// .radioGroup

.subTyperadioGroup {
  .v-radio {
    position: relative;
    margin: 0px !important;
    .v-input--selection-controls__input {
      position: absolute !important;
      z-index: 1000;
      top: 5px;
      right: 20px;
    }
  }
}
</style>
