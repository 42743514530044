<template>
  <div class="quotation--paper2">
    <h1>التعاقد والتأجير</h1>
    <h2 class="ma-0">معلومات التعاقد والتأجير</h2>

    <div class="quotation--paper2__table">
      <div class="quotation--paper2__table__row d-flex">
        <div>
          <p>اسم العميل Client Name</p>
          <p>{{ quotation?.client?.name }}</p>
        </div>
        <div>
          <p>رقم التواصل Contact Number</p>
          <p>{{ quotation?.client?.phoneNumber.replace('966', '0') }}</p>
        </div>
      </div>
      <div class="quotation--paper2__table__row d-flex">
        <div>
          <p>رقم الوحدة Unit Number</p>
          <p>-</p>
        </div>
        <div>
          <p>نوع الوحده Unit Type</p>
          <p>{{ quotation.realEstate?.type?.nameAr }}</p>
        </div>
      </div>
      <div class="quotation--paper2__table__row d-flex">
        <div>
          <p>الغرض Purpose</p>
          <p>{{ quotation.realEstate?.purpose?.nameAr }}</p>
        </div>
        <div>
          <p>المساحة Area</p>
          <p>{{ quotation.realEstate?.space }}</p>
        </div>
      </div>
      <div class="quotation--paper2__table__row d-flex">
        <div>
          <p>سعر المتر Rate</p>
          <p>
            {{ quotation.realEstate?.pricePerMeter?.toLocaleString() }} ريال
          </p>
        </div>
        <div>
          <p>الإيجار السنوي Annual Rate</p>
          <p>{{ quotation.realEstate?.price?.toLocaleString() }} ريال</p>
        </div>
      </div>
      <div class="quotation--paper2__table__row d-flex">
        <div>
          <p>مدة التأجير Lease Period</p>
          <p>{{ daysBetween }} يوم</p>
        </div>
        <div>
          <p>فترة السماح Grace Period</p>
          <p>{{ gracePeriod ? `${gracePeriod} يوم` : '-' }}</p>
        </div>
      </div>
      <div class="quotation--paper2__table__row d-flex">
        <div>
          <p>خدمات الوساطة Brokerage Fees</p>
          <p>-</p>
        </div>
        <div>
          <p>خدمات التشغيل Utility Service</p>
          <p>-</p>
        </div>
      </div>
      <div class="quotation--paper2__table__row d-flex">
        <div>
          <p>تاريخ بداية التأجير Lease Start Date</p>
          <p>{{ quotation.details?.start | formatDate }}</p>
        </div>
        <div>
          <p>مبلغ التأمين Insurance Amount</p>
          <p>-</p>
        </div>
      </div>
      <div class="quotation--paper2__table__row d-flex">
        <div>
          <p>المطلوب شاملا الضريبة Due Amount Vat Included</p>
          <p>{{ quotation.details?.total?.toLocaleString() }} ريال</p>
        </div>
        <div>
          <p>تاريخ نهاية العرض Offer End Date</p>
          <p>{{ quotation?.endDate | formatDate }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Paper2',
  props: {
    quotation: {
      type: Object,
      default: () => {}
    }
  },
  filters: {
    formatDate(value) {
      return new Date(value).toLocaleDateString('ar-AS', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }
  },
  computed: {
    startDate() {
      return new Date(this.quotation?.details.start)
    },
    endDate() {
      return new Date(this.quotation?.details.end)
    },
    daysBetween() {
      const timeDifference = this.endDate - this.startDate
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24)
      return Math.abs(Math.round(daysDifference))
    },
    gracePeriod() {
      const gracePeriod = this.quotation?.gracePeriod
      const timeDifference = this.endDate - gracePeriod
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24)
      return gracePeriod ? Math.abs(Math.round(daysDifference)) : 0
    }
  }
}
</script>

<style lang="scss" scoped></style>
