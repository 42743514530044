<template>
  <v-data-table
    :headers="headers"
    :items="users"
    class="hectar-table"
    hide-default-footer
    mobile-breakpoint="0"
  >
    <template v-slot:[`item.checked`]="{ item }">
      <v-checkbox
        v-if="checkDeleteAvailability(item)"
        v-model="item.checked"
        @click.stop
        class="reset-all ma-0"
        dense
        hide-details
      />
    </template>
    <template v-slot:[`item.environments`]="{ item }">
      <div
        v-if="getRole(item.environments[0])"
        class="brub--border tabel-tag d-flex flex-column align-center justify-center"
      >
        <h6 class="ma-0 pb-1 d-inline font-12 font-weight-bold text-truncate">
          {{ getRole(item.environments[0]) }}
        </h6>
      </div>
      <div class="d-flex" v-else>
        <h6 class="mx-auto">-</h6>
      </div>
    </template>
    <template v-slot:[`item.tags`]="{ item }">
      <div class="align-center d-flex flex-wrap">
        <div
          v-for="tag in generateItemTags(item).shownTags"
          :key="tag"
          class="text-center ml-1 font-12 my-1"
        >
          <h5 class="font-12 font-weight-medium pa-0 ma-0 text-truncate">
            {{ tag }}
          </h5>
        </div>
        <div v-if="generateItemTags(item).hiddenTags.length">
          <v-menu
            open-on-hover
            bottom
            offset-y
            transition="slide-y-transition"
            content-class="dataTableMenus"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="text-center ml-1 font-12 my-1"
              >
                <h5
                  class="font-12 font-weight-medium py-0 px-1 ma-0 text-truncate"
                >
                  أخرى
                </h5>
              </div>
            </template>
            <v-list>
              <v-list-item
                v-for="tag in generateItemTags(item).hiddenTags"
                :key="tag"
              >
                <div class="blue--tag tabel-tag text-center ml-1 font-12 my-1">
                  <h5
                    class="font-12 font-weight-medium pa-0 ma-0 text-truncate"
                  >
                    {{ tag }}
                  </h5>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </template>
    <template v-slot:[`item.phoneNumber`]="{ item }">
      <div class="d-flex align-center">
        <p class="font-14 mb-0">
          {{ removeSpaces(item.phoneNumber) }}
        </p>
      </div>
    </template>
    <template v-slot:[`item.email`]="{ item }">
      <v-tooltip content-class="v-tool" color="black" min-width="95" bottom>
        <template v-slot:activator="{ on, attrs }">
          <h5
            v-on="on"
            v-bind="attrs"
            class="text-truncate font-weight-regular max-width-100"
          >
            {{ item.email }}
          </h5>
        </template>
        <span class="tool--tip">{{ item.email }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.registered`]="{ item }">
      <div
        class="brub--border tabel-tag d-flex flex-column align-center justify-center"
      >
        <h6 class="ma-0 pb-1 d-inline font-12 font-weight-bold text-truncate">
          {{ getInvitationStatus(item) }}
        </h6>
      </div>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <div class="align-center d-flex">
        <h6
          v-if="item.name"
          class="red--tag bill text-center font-12 ml-2 text-truncate"
        >
          {{ $subString(item.name) }}
        </h6>
        <h6 v-else class="mx-auto">-</h6>
        <h5 class="font-weight-medium text-truncate ml-2">
          {{ setName(item) }}
        </h5>
      </div>
    </template>
    <template v-slot:[`item.active`]="{ item }">
      <div
        :class="[
          'tabel-tag',
          'red--tag',
          'text-center',
          'font-12',
          'font-weight-bold',
          'd-flex',
          'align-center',
          setStatusClass(item)
        ]"
      >
        <v-sheet
          v-if="item.active"
          class="dotted ma-0 pa-0 ml-2"
          color="#65C467"
        >
        </v-sheet>
        <v-sheet v-else class="dotted ma-0 pa-0 ml-2" color="#fc4a4a">
        </v-sheet>

        <span class="mb-1">{{ item.active ? 'مفعّل' : 'معطّل' }}</span>
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item, index }">
      <v-flex align-center d-flex>
        <v-btn
          v-if="currentUser(item)"
          data-test="navigateToProfile"
          @click="navigateToProfile()"
          class="options-btn"
          color="lamb"
          icon
        >
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-menu
          v-if="!currentUser(item) && !isOwner(item)"
          bottom
          content-class="dropdown-menu"
          offset-x
          transition="slide-x-transition"
          :value="vMenuStatus"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="options-btn"
              color="lamb"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="
                item.active && $hasPermission('users', 'invitations', 'update')
              "
              data-test="editMemeber"
              @click="$emit('edit-member', item)"
            >
              <v-list-item-icon>
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>تعديل</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              data-test="statusEmployee"
              @click="toggleUserState(item, index)"
            >
              <v-list-item-icon>
                <v-icon>
                  {{ item.active ? 'mdi-account-off' : 'mdi-account-check' }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.active ? 'تعطيل' : 'تفعيل' }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              data-test="deleteEmployee"
              @click="deleteItem(item)"
              v-if="$hasPermission('users', 'invitations', 'delete')"
            >
              <v-list-item-icon>
                <v-icon color="danger">mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="danger--text">حذف</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-flex>

      <confirm-dialog ref="confirm"></confirm-dialog>
    </template>
  </v-data-table>
</template>

<script>
import ConfirmDialog from '../ConfirmDialog.vue'
import { EnvService, userService, MultipleDeletion } from '@/services'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'data-table-team',
  components: { ConfirmDialog },
  props: {
    users: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  },
  data: () => {
    return {
      tags: [
        'إدارة الأملاك',
        'الصيانة',
        'التسويق',
        'التحصيل',
        'الإدارة التنفيذية',
        'الشؤون القانونية',
        'الحسابات'
      ],
      vMenuStatus: false
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    removeSpaces: () => (item) => {
      return item.replace(/ /g, '')
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    isOwner(val) {
      return val.environments[0].emloyeeRole === 'owner'
    },
    currentUser(user) {
      return user._id === this.user._id
    },
    getRole(val) {
      if (val.emloyeeRole === 'owner') {
        return 'مالك الحساب'
      } else if (val.emloyeeRole === 'member') {
        return 'عضو'
      } else if (val.emloyeeRole === 'admin') {
        return 'مدير'
      } else if (val.emloyeeRole === 'supervisor') {
        return 'مشرف'
      }
      return ''
    },
    setName(val) {
      if (this.user._id === val._id) {
        return `${val.name} (أنا)`
      }
      return val.name
    },
    navigateToProfile() {
      this.$emit('routeToProfile')
    },
    getInvitationStatus(user) {
      const activeEnv = user.environments.find((env) => {
        return this.currentEnv?._id === env?._id
      })
      if (activeEnv?.invitedAs) {
        return 'قيد الانتظار'
      }
      return 'مٌسجل'
    },
    setStatusClass(user) {
      if (user.active) {
        return 'green--tag'
      }
      ;('gery--tag')
    },
    async popUpConfirm(data) {
      return await this.$root.$confirm.open(data)
    },
    async toggleUserState(item, itemIndex) {
      if (!this.$hasPermission('users', 'invitations', 'update')) {
        this.addNotification({
          text: 'غير مسموح بتعديل الحالة',
          color: 'error'
        })
        return
      }
      const payload = {
        active: null
      }
      let confirmData = {}

      if (item.active) {
        payload.active = 0
        confirmData = {
          confirmMessage: 'سيتم تعطيل العضو، متابعة؟',
          notificationMessage: 'تم تعطيل العضو بنجاح',
          errorMessage: 'تعذر تعطيل العضو',
          color: 'red',
          icon: require('@/assets/img/svgs/closeImg.svg')
        }
      } else {
        payload.active = 1
        confirmData = {
          confirmMessage: 'سيتم تفعيل العضو، متابعة؟',
          notificationMessage: 'تم تنشيط العضو بنجاح',
          errorMessage: 'تعذر تفعيل العضو',
          color: 'purple',
          icon: require('@/assets/img/svgs/rightCheckMark.svg')
        }
      }

      const popUpInfo = {
        title: confirmData.confirmMessage,
        options: {
          color: confirmData.color,
          width: 400,
          zIndex: 200
        },
        img: {
          path: confirmData.icon,
          width: 56,
          height: 56
        }
      }
      const confirm = await this.popUpConfirm(popUpInfo)

      if (confirm) {
        try {
          const { data } = await userService.updateUser(payload, item._id)

          if (data.user) {
            this.$emit('toggle-status', {
              memberIndex: itemIndex,
              active: payload.active
            })
            this.addNotification({
              text: confirmData.notificationMessage,
              color: 'success'
            })
          }
        } catch {
          this.addNotification({
            text: confirmData.errorMessage,
            color: 'error'
          })
        }
      }
    },
    async deleteItem(item) {
      const popUpInfo = {
        title: 'حذف عضو فريق',
        message: 'هل أنت متأكد من حذف بيانات هذا العضو',
        options: { color: 'red' }
      }
      const confirm = await this.popUpConfirm(popUpInfo)
      if (confirm) {
        if (item.active) {
          this.addNotification({
            text: 'لا يمكنك حذف عضو مفعّل',
            color: 'error'
          })
        } else {
          try {
            const body = { ids: [item._id] }
            const { data } = await MultipleDeletion.deleteTeams(
              this.currentEnv._id,
              body
            )
            if (data && data.success) {
              this.addNotification({
                text: 'تم حذف العضو بنجاح',
                color: 'success'
              })
              this.$emit('refresh-team-list')
            }
          } catch {
            this.addNotification({
              text: 'تعذر حذف العضو',
              color: 'error'
            })
          }
        }
      }
    },
    checkDeleteAvailability(user) {
      if (user._id === this.currentEnv?.owner?.id || this.currentUser(user)) {
        return false
      }
      return true
    },
    generateItemTags(teamMember) {
      const tags = this.isOwner(teamMember) ? this.tags : teamMember.tags
      const tagsExceededLimit = tags.length > 2
      return {
        shownTags: tagsExceededLimit ? tags.slice(0, 2) : tags,
        hiddenTags: tagsExceededLimit ? tags.slice(2) : []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.max-width-100 {
  max-width: 100px;
}
</style>
