<template>
  <div>
    <google-maps-search
      :currentAddress="fullAddress"
      @onCheckAddress="setAddress"
      mapStatus="new"
      ref="addressRef"
      :mapTitle="compantLocationText"
    >
    </google-maps-search>
  </div>
</template>

<script>
import GoogleMapsSearch from '@/components/map/GoogleMapsSearch.vue'
import { mapMutations } from 'vuex'

export default {
  components: {
    GoogleMapsSearch
  },

  data() {
    return {
      address: {},
      isValid: false,
      compantLocationText: 'الشركه',
      helpCenterIcon: require('@/assets/img/svgs/register-help-center.svg')
    }
  },
  mounted() {
    // check if thier is already saved data for the user and fill the form with it
    const userDetails = JSON.parse(localStorage.getItem('should-update'))
    if (userDetails) {
      this.address = {
        coordinates: userDetails.location.coordinates,
        address: userDetails.address
      }
      console.log(this.address)
    }
  },
  computed: {
    fullAddress() {
      return this.address
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setAddress(location) {
      if (location) {
        this.address = {
          lat: location.place.lat,
          lng: location.place.lng,
          address: location.place.address,
          city: location.place.city
        }
        this.isValid = true
      }
    },
    async validate() {
      if (!this.isValid) {
        this.addNotification({
          text: 'من فضلك تأكد من جميع الحقول',
          color: 'error'
        })
      } else {
        this.$emit('on-validate', this.address, this.isValid)
        this.$emit('add-user')
      }

      return this.isValid
    }
  }
}
</script>
<style lang="scss">
@import '../../../src/styles/responsiveness/responsiveness.scss';
</style>
