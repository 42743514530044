<template>
  <v-container class="pa-0" id="general-statistics">
    <v-row class="">
      <h1>Marketing Estate component</h1>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'marketing-estate',
  data: () => ({
    breadcrumbs: [
      {
        text: ' ',
        disabled: true
      }
    ]
  }),
  methods: {
    callMethodInChildComponent() {
      //As an instance property
      this.$eventBus.$emit('callMethodInChild')
      //As an ES6 module.
      EventBus.$emit('callMethodInChild')
    }
  },
  computed: {}
}
</script>
