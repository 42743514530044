<template>
  <div
    v-if="item"
    class="show-detailes contentSection contracts__bonds__side__deatails"
  >
    <v-slide-x-transition>
      <v-layout>
        <v-flex class="greySide" md4 v-if="!getBreakPoint['lg']"></v-flex>
        <v-flex class="whiteSide flex-wrap">
          <!-------------------------------------------->
          <!-------------side contract header----------->
          <!-------------------------------------------->
          <side-detailes-contract-header
            :contract-titles="contractTitles"
            :item="item"
            @sharePdf="sharePdf"
            @onTerminate="$emit('onTerminate', { ...$event })"
            v-on="$listeners"
          />

          <v-divider />

          <v-layout class="sidedetails__layout">
            <v-flex
              class="pl-5"
              :class="{
                'details-container': getBreakPoint['mdAndUp'] && !isCustomer
              }"
              :md8="!isCustomer"
              :md12="isCustomer"
            >
              <v-tabs
                class="tabs-container"
                color="primary"
                align-with-title
                right
                v-model="tab"
              >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                  v-for="tab in detailsTabs"
                  :key="tab.title"
                  color="#737373"
                  class="font-14 mr-0"
                >
                  {{ tab.title }}
                </v-tab>
              </v-tabs>
              <!-------------------------------------------->
              <!-------------contract details--------------->
              <!-------------------------------------------->
              <div style="height: 90vh" class="contentSection">
                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="tab in detailsTabs"
                    :key="tab.title"
                    :eager="true"
                  >
                    <component
                      :is="tab.component"
                      v-bind="generateTabProps(tab)"
                      @selectFile="uploadContractAttachment"
                      @delete-document="deleteContractDocument"
                      @cancel-delete-document="showSideDetails"
                      v-on="$listeners"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-flex>
            <!-------------------------------------------->
            <!-------------comments section--------------->
            <!-------------------------------------------->
            <v-flex v-if="!isCustomer" md4 class="mr-2">
              <div
                class="commentsSection ma-0 d-flex flex-column"
                style="height: 90vh"
              >
                <p class="font-14 mt-3 mr-2">التعليقات</p>

                <!-------------------------------------------->
                <!-------------comments loader---------------->
                <!-------------------------------------------->
                <v-row class="mx-0" v-if="commentsAreLoading">
                  <v-col cols="12" v-for="i in 3" :key="i">
                    <v-skeleton-loader
                      class="background-transparent"
                      width="300"
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>

                <!-------------------------------------------->
                <!-------------no comments-------------------->
                <!-------------------------------------------->
                <div class="ma-auto" v-if="!comments.length">
                  <v-sheet>
                    <span>
                      <v-img
                        class="ma-auto"
                        width="72"
                        height="72"
                        :src="noComments"
                      ></v-img>
                      <span> ليست هناك اي تعليقات </span>
                    </span>
                  </v-sheet>
                </div>

                <!-------------------------------------------->
                <!-------------comments data------------------>
                <!-------------------------------------------->
                <div v-else class="comments mb-auto mr-2">
                  <CommentCard
                    v-for="(comment, index) in comments"
                    :key="comment._id"
                    v-bind="generateCommentProps(comment)"
                    @setActiveComment="setActiveComment"
                    @setActiveTextArea="setActiveTextArea"
                    @updateComment="updateComment($event, index)"
                    @deleteComment="deleteComment($event, index)"
                  />
                </div>

                <v-sheet
                  class="mb-1 mr-4"
                  v-if="$hasPermission('properties', 'contracts', 'add')"
                >
                  <v-form>
                    <v-textarea
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="addComment"
                      auto-grow
                      class-content="commentTextArea font-12"
                      filled
                      hide-details
                      no-resize
                      placeholder="أدخل تعليقك هنا"
                      rows="1"
                      v-model="commentContent"
                    >
                      <template v-slot:append>
                        <img
                          @click="addComment"
                          alt="send text"
                          class="px-3 pointerCursor"
                          src="@/assets/img/svgs/send.svg"
                          loading="lazy"
                        />
                      </template>
                    </v-textarea>
                  </v-form>
                </v-sheet>
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-slide-x-transition>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import SideDetailesContractHeader from './SideDetails/SideDetailesContractHeader.vue'
import CommentCard from './CommentCard.vue'
import { contractsService, messageService } from '@/services'
import sanitizers from '@/mixins/sanitizers.js'
import ContractDetails from './SideDetails/ContractDetails.vue'
import contractsSharedData from '@/mixins/contractsSharedData'

export default {
  name: 'side-contracts-detailes',
  mixins: [sanitizers, contractsSharedData],
  components: {
    SideDetailesContractHeader,
    CommentCard,
    ContractDetails,
    ContractTemplate: () => import('./SideDetails/ContractTemplate.vue'),
    ContractAmenities: () => import('./SideDetails/ContractAmenities.vue'),
    ContractAttachments: () => import('./SideDetails/ContractAttachments.vue'),
    ContractNotes: () => import('./SideDetails/ContractNotes.vue'),
    tabTableDetails: () => import('./SideDetails/tabTableDetails.vue')
  },
  props: {
    item: {
      required: true
    },
    contractIntervals: {
      default() {
        return []
      },
      required: true
    }
  },
  data() {
    return {
      commentsAreLoading: false,
      isLoading: false,
      activeComment: null,
      activeTextArea: null,
      comments: [],
      commentContent: '',
      contractTitles: {
        date: 'تاريخ الإنشاء',
        by: 'عقد بواسطه'
      },
      noComments: require('@/assets/img/svgs/noComments.svg'),
      tab: 0,
      contractAttachments: [],
      contractServiceKeys: ['electricity', 'water', 'gas', 'generalServices'],
      billsTitle: 'الفواتير',
      duesTitle: 'المستحقات المالية',
      claimsTitle: 'المطالبات المالية'
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    contractClaimsDetails() {
      return this.item?.financialDetails ?? []
    },
    noDataClaims() {
      return {
        text: 'ليس هناك مطالبات مالية متاحة لهذا العقد',
        icon: require('@/assets/img/no-dues.png'),
        widthHeight: '100'
      }
    },
    noDataDues() {
      return {
        text: 'ليس هناك مستحقات مالية متاحة لهذا العقد',
        icon: require('@/assets/img/no-dues.png'),
        widthHeight: '100'
      }
    },
    noDataInvoices() {
      return {
        text: 'ليس هناك فواتير متاحة لهذا العقد',
        icon: require('@/assets/img/no-taxinvoice.png'),
        widthHeight: '100'
      }
    },
    noDataNotes() {
      return {
        text: 'ليس هناك ملاحظات مضافة حتى الآن',
        icon: require('@/assets/img/no-comments.png'),
        widthHeight: '80'
      }
    },
    claimsDetailsHeaders() {
      return [
        {
          text: 'نوع المطالبة',
          sortable: false,
          value: 'type',
          align: 'center'
        },
        {
          text: 'القيمة',
          sortable: false,
          value: 'amount',
          align: 'center'
        },
        {
          text: 'قسط',
          sortable: false,
          value: 'order',
          align: 'center'
        },
        {
          text: 'السنة',
          sortable: false,
          value: 'year',
          align: 'center'
        },
        {
          text: 'تاريخ المطالبة',
          sortable: false,
          value: 'date',
          align: 'center'
        },
        {
          text: 'المدفوع',
          sortable: false,
          value: 'paid',
          align: 'center'
        },
        {
          text: 'المتبقي',
          sortable: false,
          value: 'remaining',
          align: 'center'
        },
        {
          text: 'حالة المطالبة',
          sortable: false,
          value: 'installmentStatus',
          align: 'center'
        },
        {
          text: 'حالة الدفع',
          sortable: false,
          value: 'status',
          align: 'center'
        }
      ]
    },
    contractDuesDetails() {
      return this.item?.financialClaims ?? []
    },
    duesDetailsHeaders() {
      return [
        {
          text: 'نوع المطالبة',
          sortable: false,
          value: 'type',
          align: 'center'
        },
        {
          text: 'القيمة',
          sortable: false,
          value: 'amount',
          align: 'center'
        },
        {
          text: 'تاريخ الإستحقاق',
          sortable: false,
          value: 'endDate',
          align: 'center'
        },
        {
          text: 'المدفوع',
          sortable: false,
          value: 'paid',
          align: 'center'
        },
        {
          text: 'المتبقي',
          sortable: false,
          value: 'remaining',
          align: 'center'
        },
        {
          text: 'حالة الصرف',
          sortable: false,
          value: 'installmentStatus',
          align: 'center'
        },
        {
          text: 'حالة الدفع',
          sortable: false,
          value: 'status',
          align: 'center'
        }
      ]
    },
    billsHeaders() {
      return [
        {
          text: 'نوع الخدمه',
          sortable: false,
          value: 'serviceType',
          align: 'center'
        },
        {
          text: 'القيمه السنويه',
          sortable: false,
          value: 'yearlyPayment',
          align: 'center'
        },
        {
          text: 'القيمه الكليه',
          sortable: false,
          value: 'payment',
          align: 'center'
        },
        {
          text: 'رقم الإشتراك',
          sortable: false,
          value: 'meter',
          align: 'center'
        }
      ]
    },
    contractViewTabs() {
      return [
        {
          title: 'تفاصيل التعاقد',
          component: 'ContractDetails',
          extraData: { contractIntervals: 'contractIntervals' },
          avaliableForContracts: [
            'rent',
            'propertyManagement',
            'maintenance',
            'isCustomer'
          ]
        },
        {
          title: 'المطالبات المالية',
          component: 'tabTableDetails',
          extraData: {
            items: 'contractClaimsDetails',
            headers: 'claimsDetailsHeaders',
            title: 'claimsTitle',
            noDate: 'noDataClaims'
          },
          avaliableForContracts: ['rent', 'propertyManagement', 'isCustomer']
        },
        {
          title: 'المستحقات المالية',
          component: 'tabTableDetails',
          extraData: {
            items: 'contractDuesDetails',
            headers: 'duesDetailsHeaders',
            title: 'duesTitle',
            noDate: 'noDataDues'
          },
          avaliableForContracts: ['rent', 'propertyManagement', 'isCustomer']
        },
        {
          title: 'الفواتير',
          component: 'tabTableDetails',
          avaliableForContracts: ['rent', 'isCustomer'],
          extraData: {
            items: 'contractUtilityVal',
            headers: 'billsHeaders',
            title: 'billsTitle',
            noDate: 'noDataInvoices'
          }
        },
        // TODO:to be added once we have a dic on it
        // { title: 'مرافق العقار', component: 'ContractAmenities', avaliableForContracts: ['rent', 'propertyManagement'] },
        {
          title: 'المرفقات',
          component: 'ContractAttachments',
          avaliableForContracts: [
            'rent',
            'propertyManagement',
            'maintenance',
            'isCustomer'
          ],
          extraData: {
            contractAttachments: 'contractAttachments',
            isLoading: 'isLoading'
          }
        },
        {
          title: 'الملاحظات',
          component: 'ContractNotes',
          avaliableForContracts: ['rent', 'propertyManagement', 'maintenance'],
          extraData: { noDate: 'noDataNotes' }
        }
        // { title: 'نموذج العقد', component: 'ContractTemplate' }
      ]
    },
    contractUtilityVal() {
      return this.contractServiceKeys.map((serviceKey) => {
        return {
          serviceType: this.contractUtilityNameAr(serviceKey),
          payment: this.item?.[serviceKey],
          yearlyPayment: this.item?.[`${serviceKey}YearlyPayment`],
          meter: this.item?.[`${serviceKey}Meter`]
        }
      })
    },
    isValidComment: () => (commentContent) => {
      return !!commentContent.length
    },
    detailsTabs() {
      return this.contractViewTabs.filter((tab) => {
        if (this.isCustomer) {
          return (
            tab.avaliableForContracts.includes(this.item.typeName) &&
            tab.avaliableForContracts.includes('isCustomer')
          )
        }
        return tab.avaliableForContracts.includes(this.item.typeName)
      })
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal?._id && oldVal?._id !== newVal?._id) {
          this.tab = 0
          this.loadComments()
          this.getContractAttachments()
        }
      }
    }
  },
  beforeDestroy() {
    localStorage.removeItem('sideDetailsItem')
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    contractUtilityNameAr(text) {
      switch (text) {
        case 'electricity':
          return 'الكهرباء'
        case 'generalServices':
          return 'الخدمات العامة'
        case 'water':
          return 'المياه'
        case 'gas':
          return 'الغاز'
        default:
          return ''
      }
    },
    sharePdf() {
      this.$parent.sharePdf(this.item)
    },
    generateCommentProps(comment) {
      return {
        comment,
        commentId: this.activeComment,
        isTerminated: this.isTerminated(this.item),
        activeTextArea: this.activeTextArea
      }
    },
    async loadComments() {
      try {
        this.commentsAreLoading = true
        const query = {
          entityId: this.item._id
        }
        const { data } = await messageService.getComments(query)
        this.comments = data.items
        this.commentContent = ''
      } catch {
        this.addNotification({
          text: 'خطا في تحميل تعليقات العقد',
          color: 'error'
        })
      } finally {
        this.commentsAreLoading = false
      }
    },
    async addComment() {
      if (this.isValidComment(this.commentContent)) {
        const commentToSend = {
          content: this.commentContent,
          entityType: 'contract',
          entityId: this.item._id,
          author: {
            _id: this.user._id,
            name: this.user.name,
            email: this.user.email,
            phoneNumber: this.user.phoneNumber,
            type: this.user.type
          },
          receiver: {
            _id: this.item.moderator._id,
            name: this.item.moderator.name
          },
          context: {
            platform: 'hectarPlus'
          }
        }
        this.commentContent = ''
        await messageService.addComment(commentToSend)
        this.loadComments()
      }
    },
    async deleteComment(commentId, commentIndex) {
      await messageService.deleteComment(commentId).then(() => {
        this.comments.splice(commentIndex, 1)
      })
    },
    updateComment(newComment, commentIndex) {
      if (this.isValidComment(newComment.content)) {
        const updatedCommentToSend = {
          content: newComment.content,
          _id: newComment.commentId
        }
        messageService.updateComment(updatedCommentToSend).then(() => {
          this.$set(this.comments[commentIndex], 'content', newComment.content)
        })
      }
      this.activeTextArea = null
    },
    setActiveComment(commentId) {
      this.activeComment = commentId
    },
    setActiveTextArea(commentId) {
      this.activeTextArea = commentId
    },
    generateTabProps(tab) {
      const props = { contract: this.item }
      if (tab.extraData) {
        Object.entries(tab.extraData).map(([key, val]) => {
          props[key] = this[val]
        })
      }
      return props
    },
    async getContractAttachments() {
      try {
        const { data } = await contractsService.getAllAttachments(this.item._id)
        this.contractAttachments = data.attachments
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل مرفقات العقد',
          color: 'error'
        })
      }
    },
    async uploadContractAttachment(attachment) {
      try {
        const reader = new FileReader()
        reader.onload = async () => {
          await this.onUpload(attachment)
        }
        reader.readAsDataURL(attachment.files)
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    async onUpload(file) {
      try {
        this.isLoading = true
        const fd = new FormData()
        fd.append('entityId', this.item._id)
        fd.append('envId', this.currentEnv._id)
        fd.append('entityType', 'contract')
        fd.append('attachment', file.files)
        fd.append('type', file.type)
        // fd.append('description', file.description)
        fd.append('number', file.number)
        await contractsService.addAttachment(fd)
        this.addNotification({
          text: 'تم إضافه المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      } finally {
        this.getContractAttachments()
        this.isLoading = false
      }
    },
    async deleteContractDocument({ _id, fileIndex }) {
      try {
        await contractsService.deleteAttachment(_id)
        this.contractAttachments.splice(fileIndex, 1)
        this.addNotification({
          text: 'تم حذف المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'خطأ في حذف المرفق، يرجى المحاولة مجددا',
          color: 'error'
        })
      } finally {
        this.showSideDetails()
      }
    },
    showSideDetails() {
      this.$emit('showSideDetails')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_sideDetails.scss';
.whiteSide {
  min-width: clamp(90%, 70vw, 80%) !important;
}
.setting__page__tabs {
  ::v-deep {
    .v-tab--active {
      font-weight: 500 !important;
      color: #101010 !important;
    }
  }
}
.details-container {
  border-left: 1px solid #e0e0e0 !important;
}
.tabs-container {
  border-bottom: 1px solid #efebf2;
  ::v-deep {
    .v-slide-group__content {
      // justify-content: space-between;
      .v-tab {
        padding: 0px 30px;
      }
    }
  }
}
</style>
