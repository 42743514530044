<template>
  <div>
    <h4 class="ma-5 font-14" :class="{ 'red--flag': validationFire }">
      هل تريد إضافه ال{{ propertyType }} كوحده كامله او
      {{ propertyType }} بوحدات داخليه ؟*
    </h4>
    <v-layout class="d-block ma-6">
      <v-card
        width="100%"
        class="d-flex my-3"
        :class="{ 'brub--tag-border': type.checked }"
        v-for="(type, i) in buildingTypes"
        :key="i"
      >
        <v-checkbox
          class="ma-0 contract mr-1 w-100"
          dense
          hide-details
          v-model="type.checked"
          data-test="checkbox-building"
          @change="handleBuildingTypeChange(type)"
        >
          <template v-slot:label>
            <span class="pt-2 pb-3">
              <h5
                class="font-weight-bold ma-0 text-right"
                :class="{ 'text-primary': type.checked }"
              >
                {{ propertyType }} {{ type.title }}
              </h5>
              <h6 class="font-12 font-weight-medium ma-0">
                {{ propertyType }} {{ type.subTitle }}
              </h6>
            </span>
          </template>
        </v-checkbox>
      </v-card>
    </v-layout>

    <v-card-actions class="pt-4 d-flex align-center justify-space-between">
      <v-btn
        @click="previousTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <v-icon class="font-16">mdi-arrow-right</v-icon>
        <span class="mb-1 ml-1">السابق</span>
      </v-btn>
      <v-btn
        data-test="next-tab"
        @click="nextTab"
        class="ma-0 px-3 py-2 add-new-property-arrow rounded-7 font-14 font-weight-bold"
        text
      >
        <span class="mb-1 ml-1">التالي</span>
        <v-icon class="font-16">mdi-arrow-left</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  props: {
    resetValidation: {
      default: false
    },
    propertyTypeName: {
      default: ''
    },
    propertyInfo: {
      default() {
        return {}
      }
    },
    propertyInfoValid: {}
  },
  data() {
    return {
      buildingTypes: [
        {
          title: '',
          subTitle: 'بدون وحدات داخليه',
          checked: false,
          code: 'singleUnit'
        },
        {
          title: 'بوحدات داخليه',
          subTitle: 'تحتوي علي وحدات داخليه ولكل وحده تفاصيل داخليه',
          checked: false,
          code: 'multipleUnits'
        }
      ],
      formchecked: false,
      nexTabClicked: false,
      addPropertyInfo: {}
    }
  },
  methods: {
    handleBuildingTypeChange(type) {
      if (type.checked) {
        this.buildingTypes.map((t) => {
          if (t.code !== type.code) {
            t.checked = false
          }
        })
        this.formchecked = true
        this.addPropertyInfo.isSingleOrMultiple = type.code
      } else {
        this.formchecked = false
        delete this.addPropertyInfo.isSingleOrMultiple
      }
    },
    nextTab() {
      this.nexTabClicked = true
      if (this.isValid) {
        this.$emit('setAddPropertyInfo', this.addPropertyInfo)
        this.$emit('nextTab')
      } else {
        this.$emit('addNotification')
      }
    },
    previousTab() {
      this.$emit('previousTab')
    }
  },
  watch: {
    resetValidation: {
      handler() {
        if (!this.propertyInfoValid) {
          this.nexTabClicked = false
          this.formchecked = false
          this.buildingTypes.map((t) => {
            t.checked = false
          })
        }
      }
    },
    propertyInfo: {
      immediate: true,
      handler() {
        this.addPropertyInfo = { ...this.propertyInfo }
        if (this.propertyInfoValid) {
          this.buildingTypes = this.buildingTypes.map((type) => {
            if (type.code === this.addPropertyInfo?.isSingleOrMultiple) {
              type.checked = true
              this.formchecked = true
            }
            return type
          })
        }
      }
    }
  },
  computed: {
    propertyType() {
      if (
        this.propertyTypeName === 'فيلا' ||
        this.propertyTypeName === 'عمارة'
      ) {
        return `${this.propertyTypeName} كامله`
      }
      return `${this.propertyTypeName} كامل`
    },
    isValid() {
      return !!this.formchecked
    },
    validationFire() {
      return this.nexTabClicked ? !this.isValid : false
    }
  }
}
</script>
