<template>
  <v-container :class="{ userView: isCustomer }">
    <div v-if="quotation.marketers.length">
      <v-data-table
        :headers="headers"
        :items="quotation.marketers"
        hide-default-footer
        class="tab-table-details"
      >
        <!-- Headers -->
        <template v-slot:header.commissionPercentage="{ header: { text } }">
          {{ text }} (من {{ quotation.marketersCommissionPercentage }} %)
        </template>
        <template v-slot:header.commission="{ header: { text } }">
          {{ text }} (من
          {{ quotation.marketersCommission?.toLocaleString() }} ريال)
        </template>

        <template v-slot:[`item.user`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h5 class="ma-0 tag-sm text-center py-0 font-weight-medium">
              <span class="font-12 my-auto text-truncate">
                {{ item.user.name }}
              </span>
            </h5>
          </div>
        </template>
        <template v-slot:[`item.commissionPercentage`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h5 class="ma-0 tag-sm text-center py-0 font-weight-medium">
              <span class="font-12 my-auto text-truncate">
                % {{ item.commissionPercentage }}
              </span>
            </h5>
          </div>
        </template>
        <template v-slot:[`item.commission`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h5 class="ma-0 tag-sm text-center py-0 font-weight-medium">
              <span class="font-12 my-auto text-truncate">
                {{ item.commission?.toLocaleString() }} ريال
              </span>
            </h5>
          </div>
        </template>
      </v-data-table>
    </div>

    <NoData v-else v-bind="noDataConfig" />
  </v-container>
</template>

<script>
import NoData from '@/components/helper/noData.vue'
import contractsSharedData from '@/mixins/contractsSharedData'
import dateFormat from '@/mixins/dateFormat.js'
import offerSharedData from '@/mixins/offerSharedData'
import { mapGetters } from 'vuex'

export default {
  name: 'CommissionsDetails',
  mixins: [dateFormat, contractsSharedData, offerSharedData],
  props: {
    quotation: {
      type: Object,
      required: true
    }
  },
  components: {
    NoData
  },
  data() {
    return {
      headers: [
        {
          text: 'اسم المسوق',
          sortable: false,
          value: 'user',
          align: 'center'
        },
        {
          text: 'نسبة العمولة',
          sortable: false,
          value: 'commissionPercentage',
          align: 'center'
        },
        {
          text: 'قيمة العمولة',
          sortable: false,
          value: 'commission',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    noDataConfig() {
      return {
        noDataText: 'لا يوجد مسوقين لهذا العرض حتى الان',
        noDataSubTitle: '',
        isbgPrimary: false,
        noDataIcon: require('@/assets/img/dashboard-icons/contract-icon-active.png')
      }
    }
  }
}
</script>
<style>
.tab-table-details table thead tr th span {
  font-size: 16px !important;
  color: #b2b2b2;
}
.container.userView {
  min-width: 100%;
  margin-right: 0 !important;
}

th {
  color: #b4b1b1 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
</style>
