<template>
  <div>
    <h4>الفواتير الضريبية</h4>
    <div class="ma-auto" v-if="!this.assignments.length">
      <NoData v-bind="noDataConfig" />
    </div>
    <taxInvoiceCard
      v-else
      v-for="tax in assignments"
      :key="tax._id"
      :item="tax.bond.taxInvoice"
      sideVersions
      isReadonly
      @showDetails="handleRediraction"
    />
  </div>
</template>

<script>
import NoData from '@/components/helper/noData.vue'
import taxInvoiceCard from '@/components/TaxInvoices/taxInvoiceCard.vue'

export default {
  components: { NoData, taxInvoiceCard },
  props: {
    assignments: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    noDataConfig() {
      return {
        noDataText: 'ليس هناك أي فواتير ضريبية مضافة حتى الآن',
        noDataIcon: require('@/assets/img/tax-invoices/taxInvoiceIcon.png'),
        noDataSubTitle: '',
        isbgPrimary: false,
        widthHeight: '60'
      }
    }
  },
  methods: {
    handleRediraction(obj) {
      localStorage.setItem('sideDetailsItemss', JSON.stringify(obj.taxInvoice))
      this.$router.push({
        name: 'taxInvoices',
        query: {
          visible: true
        }
      })
    }
  }
}
</script>
