<template></template>

<script>
export default {
  name: 'contractCheckboxparties',
  props: {
    exclamation: {
      default: require('@/assets/img/svgs/exclamationMark.svg')
    }
  },
  data() {
    return {
      pendingContract: false
    }
  },
  methods: {
    setPendingContract() {
      this.$emit('setPendingContract', this.pendingContract)
    },
    emitValidationStatus() {
      this.$emit('setValdationStatus', true)
    },
    bindContractData() {
      this.$emit('bindContractData', {})
    },
    resetData() {
      this.contractInfo = {}
    },
    presetData() {
      this.emitValidationStatus()
    }
  }
}
</script>
