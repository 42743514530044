<template>
  <v-card class="uploadCard px-5 py-5 mt-5">
    <v-layout>
      <v-flex class="setting__page__branding">
        <p class="mb-2 font-16 font-weight-bold">بيانات الشركه</p>
        <v-row>
          <div
            class="text-center mx-3 my-5 branding__cards"
            v-for="(image, index) in companyImagesTitle"
            :key="image.key"
          >
            <p class="ma-0 mb-2 font-12">{{ image.title }}</p>
            <div
              v-if="!companyImages[image.key]"
              class="inner inner-flex cursor"
            >
              <v-progress-circular
                v-if="image.key === changingImageKey && isImageChanging"
                indeterminate
                color="primary"
              />
              <div v-else @click="openInput(index)">
                <v-btn class="ma-0" icon>
                  <v-img :src="uploadIcon" max-width="16"></v-img>
                </v-btn>
                <p class="ma-0 clickHere font-12">اضغط هنا</p>
                <input
                  :id="`element${index}`"
                  data-test="uploadCompanyImages"
                  @change="addImage(image.key, image.title, $event)"
                  accept="image/*"
                  type="file"
                  v-show="false"
                />
              </div>
            </div>

            <div class="inner solid" v-else>
              <v-progress-circular
                v-if="image.key === changingImageKey && isImageChanging"
                indeterminate
                color="primary"
              />
              <div v-else>
                <v-img
                  data-test="deleteImage"
                  @click="removeImage(image.key, image.title)"
                  class="close"
                  :src="removeUploadedFile"
                  max-width="12"
                />
                <img :src="companyImages[image.key].location" loading="lazy" />
              </div>
            </div>
          </div>
        </v-row>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  name: 'branding',
  data() {
    return {
      changingImageKey: 0,
      isImageChanging: false,
      companyImagesTitle: [
        {
          title: 'شعار الشركه',
          key: 'companyLogo'
        },
        {
          title: 'ختم الشركه',
          key: 'companySeal'
        },
        {
          title: 'توقيع المدير المالي',
          key: 'financialSignature'
        },
        {
          title: 'توقيع الرئيس التنفيذي',
          key: 'directorSignature'
        },
        {
          title: 'توقيع مدير إدارة الأملاك',
          key: 'propertyManagementManagerSignature'
        }
      ],
      uploadIcon: require('@/assets/img/svgs/uploadIcon.svg'),
      removeUploadedFile: require('@/assets/img/svgs/removeUploadedFile.svg'),
      currentEnv: JSON.parse(localStorage.getItem('currentEnv'))
    }
  },
  computed: {
    ...mapGetters('accountState', ['companyImages'])
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapActions('accountState', ['addCompanyImages', 'removeCompanyImages']),
    toggleImageLoader(imageKey, value) {
      this.changingImageKey = imageKey
      this.isImageChanging = value
    },
    openInput(elementIndex) {
      const id = `element${elementIndex}`
      const element = document.getElementById(id)
      element.click()
    },
    async addImage(imageKey, imageTitle, e) {
      this.toggleImageLoader(imageKey, true)
      const image = e.target.files[0]
      const fd = new FormData()
      fd.append(imageKey, image)
      const addImagesPayload = {
        fd,
        imageKey,
        envId: this.currentEnv._id,
        imageTitle
      }
      await this.addCompanyImages(addImagesPayload)
      this.toggleImageLoader(imageKey, false)
    },
    async removeImage(imageKey, imageTitle) {
      this.toggleImageLoader(imageKey, true)
      const removeImagesPaylaod = {
        imageKey,
        envId: this.currentEnv._id,
        imageTitle
      }
      await this.removeCompanyImages(removeImagesPaylaod)
      this.toggleImageLoader(imageKey, false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_env.scss';
</style>
