var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"align-center py-5 overflow-y-hidden overflow-x-auto px-5",class:[_vm.sm || _vm.xs ? 'd-block' : 'd-flex']},[_c('v-row',{staticClass:"d-flex",class:{ 'flex-nowrap': _vm.mdAndUp, 'flex-wrap': _vm.smAndDown }},[_vm._l((_vm.approvalConfig),function(filter,idx,i){return _c('div',{key:idx,staticClass:"px-0",staticStyle:{"width":"200px"}},[(i !== 0)?_c('v-menu',{ref:"aprrovalType",refInFor:true,attrs:{"content-class":"vMenuFilter elevation-0 w-fit","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"d-flex align-center mx-1",class:{ 'mt-1': _vm.smAndDown }},[_c('v-slide-x-transition',[_c('v-card',_vm._g(_vm._b({ref:"sortListActivator",refInFor:true,staticClass:"d-flex justify-space-between px-2 pt-1 w-100",attrs:{"width":"130"}},'v-card',attrs,false),on),[_c('h6',{staticClass:"mx-2 w-fit text-no-wrap text-truncate"},[_vm._v("\n                    "+_vm._s(_vm.ConfigPlaceholder[idx])+"\n                  ")]),_c('v-icon',[_vm._v(_vm._s(_vm.menuStateSwitcher(_vm.$refs.aprrovalType?.[i - 1])
                        ? 'mdi-chevron-up'
                        : 'mdi-chevron-down')+"\n                  ")])],1)],1)],1)]}}],null,true)},_vm._l((_vm.approvalConfig[idx]),function(option){return _c('v-list',{key:option.id,staticClass:"contractVmenuList pl-4 pa-0"},[_c('v-list-item-group',[_c('v-list-item',{staticClass:"px-1"},[_c('v-list-item-title',{ref:"sortOption",refInFor:true,staticClass:"font-14 mr-1 mb-2 font-weight-medium",on:{"click":function($event){return _vm.setFiltervalue(option, idx)}}},[_vm._v("\n                  "+_vm._s(option.nameAr)+"\n                ")])],1)],1)],1)}),1):_c('v-menu',{ref:"bondsType",refInFor:true,attrs:{"content-class":"vMenuFilter elevation-0","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-slide-x-transition',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex justify-space-between px-2 pt-1",attrs:{"width":"200px"}},'v-card',attrs,false),on),[_c('h6',{staticClass:"mr-2"},[_vm._v("\n                    "+_vm._s(_vm.ConfigPlaceholder[idx])+"\n                  ")]),_c('v-icon',[_vm._v(_vm._s(_vm.menuStateSwitcher(_vm.$refs?.bondsType?.[i])
                        ? 'mdi-chevron-up'
                        : 'mdi-chevron-down')+"\n                  ")])],1)],1)],1)]}}],null,true)},[_c('v-card',{attrs:{"width":"230"}},[_c('v-row',{staticClass:"pa-3",attrs:{"no-gutters":"","align":"center"}},[_vm._l((_vm.approvalConfig['status']),function(type){return _c('v-col',{key:type._id,staticClass:"py-0",attrs:{"md":"6"}},[_c('h6',{staticClass:"text-center roundeed-tag px-2 py-1 cursor ml-1",class:{
                    'brub--tag-border': _vm.filtersQuery['status'] === type.nameEn
                  },on:{"click":function($event){return _vm.setFiltervalue(type, 'status')}}},[_vm._v("\n                  "+_vm._s(type.nameAr)+"\n                ")])])}),_c('v-col',{staticClass:"text-left",attrs:{"md":"12"}},[_c('a',{staticClass:"text-decoration-underline font-12 black--text",attrs:{"data-test":"rest-filter"},on:{"click":function($event){return _vm.restFilters('status')}}},[_vm._v("إعادة تعيين")])])],2)],1)],1)],1)}),_c('v-text-field',{staticClass:"search__by__customer__name mx-1 font-14",class:{ 'mt-1': _vm.smAndDown },attrs:{"height":"45","filled":"","dense":"","hide-details":"auto","placeholder":"ابحث بإسم العقار أو اسم الوحدة"},model:{value:(_vm.filtersQuery.realEstateName),callback:function ($$v) {_vm.$set(_vm.filtersQuery, "realEstateName", $$v)},expression:"filtersQuery.realEstateName"}}),_c('v-btn',{staticStyle:{"background-color":"white","margin":"0","margin-top":"5px","margin-right":"3rem","box-shadow":"0 0 0 0"}},[_c('router-link',{staticStyle:{"color":"black"},attrs:{"to":{
            path: '/dashboard/edit-profile/5',
            query: { currComponent: 'Approvement' }
          }}},[_c('h6',{staticClass:"mx-2 w-fit text-no-wrap text-truncate"},[_vm._v("\n            الإعدادات\n            "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"gray"}},[_vm._v("mdi-cog")])],1)])],1)],2)],1),_c('div',{staticClass:"d-flex mt-4"},[_vm._l((_vm.filters),function(filter,idx){return [(filter)?_c('v-card',{key:idx,staticClass:"d-flex pa-1 flex-grow-0 justify-between align-center mx-1"},[_c('h5',{staticClass:"ma-0 mx-2 text--purple font-12 text-no-wrap py-2"},[_vm._v("\n          "+_vm._s(filter)+"\n        ")]),_c('v-icon',{staticClass:"font-weight-medium font-14 pl-1",on:{"click":function($event){return _vm.restFilters(idx)}}},[_vm._v("\n          mdi-close\n        ")])],1):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }