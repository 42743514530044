<template>
  <v-card
    :to="
      $hasPermission('users', 'clients', 'list')
        ? `/dashboard/client/${user._id}`
        : '/'
    "
    class="pa-3 rounded-15"
    :class="{ 'owner-border': isAccountOwner || isEnvOwner }"
    :height="paymentsIsDefined ? '280' : '245'"
  >
    <v-sheet class="pa-0 w-100">
      <div class="mt-3 w-100">
        <div class="mb-4">
          <div
            class="d-flex"
            v-if="user.environments && user.environments.length"
          >
            <div v-for="(client, i) in user.environments[0].role" :key="i">
              <h5 :class="setRoleLabelStyle(client)" class="mx-1">
                {{ formatUserRole(client) }}
              </h5>
            </div>
          </div>
          <div v-else-if="user.type">
            <h5 :class="setRoleLabelStyle(user.type)">
              {{ formatUserRole(user.type) }}
            </h5>
          </div>

          <div class="d-flex mt-3" v-if="paymentsIsDefined">
            <img
              alt="arrow_dwon"
              class="ml-2"
              :src="renderIconForPayments"
              loading="lazy"
            />
            <h5 class="ma-0">
              {{ payments }}
            </h5>
          </div>
        </div>
        <div>
          <div>
            <h6 class="text-light font-weight-light ma-0 font-12">
              اسم العميل
            </h6>
            <h5 class="ma-0 font-weight-medium text-truncate">
              {{ user.name }}
            </h5>
          </div>
          <div class="my-4">
            <h6 class="text-light font-weight-light ma-0 font-12">
              رقم العميل
            </h6>
            <h5 class="ma-0 font-weight-medium">
              {{ user.clientNumber ?? '-' }}
            </h5>
          </div>
        </div>
        <div class="d-flex align-center">
          <div>
            <h6 class="text-light font-weight-light ma-0 font-12">
              رقم الجوال
            </h6>
            <h5 class="ma-0 font-weight-bold">
              {{ user.phoneNumber | filterPhoneNumber }}
            </h5>
          </div>
          <div class="mr-auto">
            <h6 class="text-light font-weight-light ma-0 font-12">
              نوع الحساب
            </h6>
            <h5 class="ma-0 font-weight-bold">
              {{ user.clientType === 'individual' ? 'فرد' : ' شركه' }}
            </h5>
          </div>
        </div>
      </div>
    </v-sheet>

    <v-slide-x-transition v-if="isAccountOwner || isEnvOwner">
      <v-card class="text-center py-2 px-2 owner-container" max-width="120">
        <span v-if="isAccountOwner"> حسابك </span>
        <span v-else-if="isEnvOwner"> صاحب الحساب </span>
      </v-card>
    </v-slide-x-transition>
  </v-card>
</template>

<script>
import numberFormatter from '@/mixins/numberFormatter.js'
import { mapState } from 'vuex'

export default {
  name: 'ClientCard',
  props: [
    'gridView',
    'options',
    'propertyName',
    'parentId',
    'user',
    'statusclaims',
    'totalCount'
  ],
  mixins: [numberFormatter],
  computed: {
    ...mapState('accountState', {
      userData: (state) => state.user,
      currentEnv: (state) => state.currentEnv
    }),
    xs() {
      return this.$vuetify.breakpoint.xs
    },
    payments() {
      if (typeof this.totalCount.payments === 'object') {
        return `${
          this.totalCount.payments[Object.keys(this.totalCount.payments)[0]]
        } | ${
          this.totalCount.payments[Object.keys(this.totalCount.payments)[1]]
        }`
      }
      return this.numberFormatter(this.totalCount.payments, 2, true)
    },
    isAccountOwner() {
      return this.totalCount._id === this.userData._id
    },
    isEnvOwner() {
      if (this.totalCount.environments?.length) {
        return this.totalCount.environments[0].isOwner
      }
      return false
    },
    paymentsIsDefined() {
      return this.payments
    },
    renderIconForPayments() {
      return this.statusclaims
        ? require('@/assets/img/svgs/arrow-up.svg')
        : require('@/assets/img/svgs/arrow-down.svg')
    }
  },
  methods: {
    formatUserRole(role) {
      const clientTypes = JSON.parse(
        localStorage.getItem('configrations')
      ).clientTypes
      // get the config options
      const clientType = clientTypes.find((c) => {
        return c.code === role
      })
      return clientType?.nameAr ?? ''
    },
    setRoleLabelStyle(role) {
      let style = 'ma-0 tag text-center pb-1 width-50 '
      switch (role) {
        case 'renter':
          style += 'brub--tag'
          break
        case 'owner':
          style += 'orange--tag'
          break
        case 'buyer':
          style += 'green--tag'
          break

        default:
          style += 'blue--tag'
          break
      }
      return style
    },
    deleteItem(val) {
      this.$parent.deleteItem(val)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.width-50 {
  width: 50%;
}

.owner-border {
  border: 2px solid $primary-color !important;
}

.owner-container {
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  margin: auto;
  color: $primary-color !important;
  background: #f7f4f9 !important;
  border-color: #efebf2 !important ;
}
</style>
