import { render, staticRenderFns } from "./BondSideVersions.vue?vue&type=template&id=4bbff94b&scoped=true"
import script from "./BondSideVersions.vue?vue&type=script&lang=js"
export * from "./BondSideVersions.vue?vue&type=script&lang=js"
import style0 from "./BondSideVersions.vue?vue&type=style&index=0&id=4bbff94b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bbff94b",
  null
  
)

export default component.exports