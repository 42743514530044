<template>
  <v-dialog max-width="680px" persistent v-model="dialogState_">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="py-0 px-3 mb-5 custom__shadow d-flex justify-space-between"
      >
        <h4 class="my-2">
          {{ data.title }}
        </h4>

        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-0 px-5">
        <h6 class="mb-2">تفاصيل التعاقد (اختياري)</h6>
        <quill-editor ref="myQuillEditor" v-model="data_.body" />
      </v-card-text>
      <v-card-actions class="pa-1 mt-4">
        <v-spacer></v-spacer>
        <v-btn
          @click="editTemplates"
          class="ma-0 rounded-7 my-3 ml-3"
          color="primary"
        >
          <span class="mb-2 ml-1 font-weight-bold"> تعديل </span>
          <v-icon class="send-icon">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import { templatesService } from '../../services'
import { mapMutations } from 'vuex'

export default {
  name: 'modelsEdit',
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: { quillEditor },
  data() {
    return {}
  },
  computed: {
    templateToUpdate() {
      const data = {
        active: this.data.active,
        body: this.data.body
      }
      return data
    },
    templateId() {
      return this.data?._id
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        console.log('called')
        this.$emit('changeDialogState', value)
      }
    },
    data_: {
      get() {
        return this.data
      },
      set(value) {
        this.$emit('changeData', value)
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('changeDialogState', false)
    },
    editTemplates() {
      templatesService
        .editTemplates(this.templateToUpdate, this.templateId)
        .then(() => {
          this.addNotification({
            text: 'تم تعديل النموذج',
            color: 'success'
          })
          this.closeDialog()
        })
        .catch(() => {
          this.addNotification({
            text: 'خطأ في تعديل النموذج',
            color: 'error'
          })
        })
    }
  }
}
</script>
