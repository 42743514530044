<template>
  <v-card class="px-5 py-6 mt-4">
    <v-form>
      <v-row no-gutters>
        <v-col class="pl-3 mobile__pl__0 pb-0 mobile__pt__0" cols="12" md="6">
          <h6 class="mb-2 font-12">السجل التجاري</h6>
          <v-text-field
            data-test="commercialNumber"
            filled
            ref="company"
            v-model="accountInfoEnv.CommercialNumber"
            dense
            @keydown="$formatNumberInputs"
            placeholder="السجل التجاري"
            class="setting__input__fields"
            @input="formatArabicNumber($event, 'CommercialNumber')"
            :disabled="!hasUpdateFinancialPermission"
          ></v-text-field>
        </v-col>
        <v-col class="pl-3 mobile__pl__0 pb-0 mobile__pt__0" cols="12" md="6">
          <h6 class="mb-2 font-12">الرقم الضريبي</h6>
          <v-text-field
            data-test="taxNumber"
            filled
            v-model="accountInfoUser.taxNumber"
            placeholder="الرقم الضريبي"
            dense
            class="setting__input__fields"
            @keydown="$formatNumberInputs"
            @input="formatArabicNumber($event, 'taxNumber')"
            :disabled="!hasUpdateFinancialPermission"
          ></v-text-field>
        </v-col>
        <v-col
          class="pl-3 mobile__pl__0 pb-0 mobile__pt__0"
          cols="12"
          md="6"
          @click="checkingAccessibility"
        >
          <h6 class="mb-2 font-12">
            إضافة ضريبة علي الأتعاب والرسوم والعمولات المحتسبة علي العملاء ؟
          </h6>
          <v-select
            :disabled="
              isEnterdCommercialNumberAndTaxNumber ||
              !hasUpdateFinancialPermission
            "
            :items="PmCommissionOptions"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            :placeholder="`لا`"
            append-icon="mdi-chevron-down"
            dense
            filled
            item-text="name"
            item-value="value"
            return-value
            v-model="accountInfoEnv.hasCommissionTax"
            class="font-14"
          />
        </v-col>
        <v-col class="pl-3 mobile__pl__0 pb-0 mobile__pt__0" cols="12" md="6">
          <h6 class="mb-2 font-12">إظهار الحسابات البنكية؟</h6>
          <v-select
            :disabled="
              !accountInfoUser.taxNumber || !hasUpdateFinancialPermission
            "
            :items="PmCommissionOptions"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
            :placeholder="`لا يوجد`"
            append-icon="mdi-chevron-down"
            dense
            filled
            hide-details
            item-text="name"
            item-value="value"
            return-value
            v-model="accountInfoEnv.showBankAccounts"
            class="font-14"
          />
        </v-col>
        <v-col v-if="!hasUpdateFinancialPermission" cols="12" md="6">
          <v-card class="informativeCard pa-1">
            <div class="holder d-flex align-center my-1">
              <div class="mx-3 my-auto">
                <v-img
                  min-height="18px"
                  max-width="20px"
                  :lazy-src="exclamation"
                  :src="exclamation"
                />
              </div>
              <div class="text">
                <span>
                  <p class="headTitle font-14 font-weight-bold ma-0">
                    ليس لديك صلاحيه للتعديل, قم بالتواصل مع صاحب البيئه لطلب
                    الحصول علي اذن
                  </p>
                </span>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-btn
          class="font-weight-bold pa-5 ml-3 mr-auto font-12"
          color="primary"
          large
          @click="saveDataHandler"
        >
          <span class="">حفظ</span>
        </v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  name: 'FinancialSettings',
  props: {
    userData: {
      default() {
        return {}
      }
    },
    env: {
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      exclamation: require('@/assets/img/svgs/exclamationMark.svg'),
      PmCommissionOptions: [
        {
          name: 'نعم',
          value: true
        },
        {
          name: 'لا',
          value: false
        }
      ],
      hasTax: true,
      accountInfoEnv: {},
      accountInfoUser: {}
    }
  },
  computed: {
    hasUpdateFinancialPermission() {
      return !!this.$hasPermission('users', 'financial', 'update')
    },
    isEnterdCommercialNumberAndTaxNumber() {
      return (
        !this.accountInfoEnv.CommercialNumber || !this.accountInfoUser.taxNumber
      )
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    saveDataHandler() {
      this.$emit('updateFinancialData', {
        userData: this.accountInfoUser,
        env: this.accountInfoEnv,
        isValid: true
      })
    },
    handleHasTaxStatus(taxNumber) {
      if (!taxNumber && this.accountInfoEnv.hasCommissionTax) {
        this.accountInfoEnv.hasCommissionTax = false
      }
    },
    formatArabicNumber(val, key) {
      if (key === 'taxNumber') {
        this.accountInfoUser.taxNumber = Number(convertNumbers2English(val))
        this.handleHasTaxStatus(val)
      } else if (key === 'CommercialNumber') {
        this.accountInfoEnv.CommercialNumber = convertNumbers2English(val)
      }
    },
    checkingAccessibility() {
      if (
        !this.accountInfoUser.taxNumber ||
        !this.accountInfoEnv.CommercialNumber
      ) {
        this.addNotification({
          text: !this.hasUpdateFinancialPermission
            ? 'ليس لديك صلاحيه للتعديل'
            : 'الرجاء ادخال السجل التجاري ورقم الضريبي اولاّ للتمكن من تفعيل الضريبة',
          color: 'error'
        })
      }
    }
  },
  created() {
    this.accountInfoEnv = { ...this.env }
    this.accountInfoUser = { ...this.userData }
  }
}
</script>
