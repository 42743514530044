<template>
  <div v-if="!isCustomer && fastActionsList.length">
    <v-menu top offset-y transition="slide-y-transition" ref="fastActionsMenu">
      <template #activator="{ on, attrs }">
        <div class="mr-3">
          <div
            @click="FastActions = !FastActions"
            icon
            v-on="on"
            v-bind="attrs"
            class="cursor fast-actions-m-btn px-2 py-2 add__report__btn"
          >
            <img
              :width="18"
              alt="FastActions"
              :src="
                !fastActionsMenuStateSwitcher($refs.fastActionsMenu)
                  ? require('@/assets/img/svgs/plus.svg')
                  : require('@/assets/img/svgs/x.svg')
              "
              loading="lazy"
            />
          </div>
        </div>
      </template>
      <v-list class="fast-actions-list">
        <v-list-item
          v-for="(btn, i) in fastActionsList"
          :key="i"
          @click="handleFastActionBtns(btn.action)"
        >
          <v-list-item-icon class="ml-1">
            <img :width="20" alt="FastActions" :src="btn.icon" loading="lazy" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ btn.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!--------------------------------------------->
    <!-------------FastActionsModals--------------->
    <!--------------------------------------------->

    <!-------------AddPropertyModal--------------->
    <unit-modal
      :dialogState="dialogStateForUnitModal"
      @closed-property-dialog="closeDialog('properties', $event)"
      v-if="
        dialogStateForUnitModal &&
        $hasPermission('realestates', 'realestates', 'add')
      "
    />

    <!-------------AddTeamMemberModal--------------->
    <add-client-modal
      :clientDetaile="false"
      :dialogState="dialogStateForClient"
      @close-client-dialog="closeDialog('client', $event)"
      v-if="dialogStateForClient && $hasPermission('users', 'clients', 'add')"
    />

    <!-------------AddContractModal--------------->
    <AddContractModal
      :contractIntervals="contractIntervals"
      :contractModalState="dialogStateForContract"
      @closeContractModal="dialogStateForContract = $event"
      v-if="
        dialogStateForContract &&
        $hasPermission('properties', 'contracts', 'add')
      "
    />

    <!-------------AddBondModal--------------->
    <add-bond-modal
      :dialogState="dialogStateForBond"
      @closed-bond-dialog="closeDialog('bond', $event)"
      v-if="dialogStateForBond && $hasPermission('properties', 'bonds', 'add')"
    />
  </div>
</template>
<script>
import config from '@/config'
import { contractsService } from '@/services'
import { mapMutations, mapGetters } from 'vuex'

export default {
  components: {
    AddBondModal: () => import('@/components/modals/AddBondModal.vue'),
    AddClientModal: () => import('@/components/modals/AddClientModal'),
    unitModal: () => import('@/components/add-unit/unitModal'),
    AddContractModal: () => import('@/components/add-contract/contractModal')
  },
  props: {
    customersInvitation: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      dialogStateForClient: false,
      dialogStateForUnitModal: false,
      dialogStateForContract: false,
      dialogStateForBond: false,
      FastActions: false,
      contractIntervals: []
    }
  },
  watch: {
    dialogStateForContract: {
      handler(val) {
        if (val && !this.contractIntervals.length) {
          this.getContractIntervals()
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['toggleDrawer', 'addNotification']),
    async getContractIntervals() {
      try {
        const Response = await contractsService.getContractIntervals()
        this.contractIntervals = Response.data.intervals
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل فترات العقد, برجاء المحاوله لاحقا',
          color: 'error'
        })
      }
    },
    fastActionsMenuStateSwitcher(menu) {
      return menu?.isActive
    },
    handleFastActionBtns(action) {
      switch (action) {
        case 'properties':
          this.dialogStateForUnitModal = true
          break
        case 'client':
          this.dialogStateForClient = true
          break
        case 'report':
          this.$router.push({ name: 'reports' })
          break
        case 'contract':
          this.dialogStateForContract = true
          break
        case 'bond':
          this.dialogStateForBond = true
          break
      }
    },
    closeDialog(type, action) {
      let text = ''
      switch (type) {
        case 'properties':
          this.dialogStateForUnitModal = false
          text = 'عقارك'
          break
        case 'client':
          this.dialogStateForClient = false
          text = 'العميل'
          break
        case 'contract':
          this.dialogStateForContract = false
          text = 'العقد'
          break
        case 'bond':
          this.dialogStateForBond = false
          text = 'السند'
          break
      }
      if (action !== 'close') {
        this.addNotification({
          text: `تم إضافه ${text} بنجاح`,
          color: 'success'
        })
      }
    }
  },
  computed: {
    ...mapGetters('accountState', ['exceededAddingProperty', 'isCustomer']),
    fastActionsList() {
      // 1 - Make sure first not include btn action equal current path
      const fastActionsListFilterd = config?.fastActionsList.filter((btn) => {
        if (this.customersInvitation && btn.action === 'client') {
          return false
        }
        return !this.$route?.path?.includes(btn.action)
      })
      const finalFastActionsListRes = []
      // 2 - Limit user foreach case
      fastActionsListFilterd.forEach((btn, i, array) => {
        if (
          (btn.action === 'properties' &&
            !this.exceededAddingProperty &&
            this.$hasPermission('realestates', 'realestates', 'add')) ||
          (btn.action === 'client' &&
            this.$hasPermission('users', 'clients', 'add')) ||
          (btn.action === 'report' &&
            this.$hasPermission('users', 'reports', 'add')) ||
          (btn.action === 'bond' &&
            this.$hasPermission('properties', 'bonds', 'add')) ||
          (btn.action === 'contract' &&
            this.$hasPermission('properties', 'contracts', 'add'))
        ) {
          finalFastActionsListRes.push(btn)
        }
      })
      return finalFastActionsListRes
    },
    currentRoute() {
      return this.$route
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
</style>
