<template>
  <div>
    <ServicesCard
      v-for="service in formatServices"
      :key="service.title"
      v-bind="{ template: service, isActiveOption: true, icon }"
      @openSettingsModal="openModel"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { servicesCodes } from '@/constans/services'
export default {
  name: 'Services',
  components: {
    ServicesCard: () => import('../modelUint.vue')
  },
  data: () => ({
    icon: require('@/assets/img/svgs/cog.png'),
    dialogState: false
  }),
  computed: {
    ...mapState('accountState', ['currentEnv']),
    servicesConfig() {
      return this.currentEnv.services
    },
    formatServices() {
      const formattedServices = this.servicesConfig.map((service) => {
        return {
          code: service.code,
          title: servicesCodes[service.code] ?? '-',
          description:
            'يمكن التحكم في حالة الخدمة والخدمات الفرعية لها من خلال الضغط علي زر التعديل',
          active: service.isActive
        }
      })
      return formattedServices
    }
  },
  methods: {
    openModel(val) {
      this.$emit('toggleServiceModel', {
        dialogState: true,
        template: val
      })
    }
  }
}
</script>
