<template>
  <div>
    <div v-if="tables.length">
      <div v-for="table in tables" :key="table.code">
        <h3 class="mb-0 mt-10">{{ table.nameAr }}</h3>
        <data-table-reports
          :forPreviewing="true"
          :headers="headersInit(headers[table.code])"
          :reports="reDesignReportBody(table)"
          tableClasses="totalTable"
        />
      </div>
    </div>
    <div v-else>
      <NoData v-bind="noDataConfig" />
    </div>
  </div>
</template>

<script>
import DataTableReports from '@/components/reports/data-table-reports'
import NoData from '@/components/helper/noData.vue'

export default {
  name: 'DataTableDetails',
  components: {
    DataTableReports,
    NoData
  },
  props: {
    tables: {
      default() {
        return {}
      }
    },
    reports: {
      default() {
        return {}
      }
    },
    headersInit: {
      default() {
        return []
      },
      required: true
    },
    headers: {
      default() {
        return []
      },
      required: true
    }
  },
  methods: {
    reDesignReportBody({ code }) {
      const { headers, body, ...totals } = this.reports[code]

      if (body) {
        return [...this.reports[code].body, { ...totals }]
      }
      return this.reports[code]
    }
  },
  computed: {
    noDataConfig() {
      return {
        noDataText: 'لا توجد بيانات متاحة لهذا التقرير',
        noDataSubTitle: '',
        isbgPrimary: false
      }
    }
  }
}
</script>
