<template>
  <v-toolbar
    height="80px"
    id="page-tollbar"
    class="mt-5 narrow-toolbar elevation-0"
  >
    <div class="page-title">
      <!--  breadcrumbs-->
      <v-breadcrumbs
        v-if="breadcrumbs"
        :items="breadcrumbs"
        class="pa-1 pt-3 breadcrumb"
      >
        <template v-slot:divider>
          <v-icon class="mt-1" size="14"> mdi-less-than </v-icon>
        </template>
      </v-breadcrumbs>
      <v-toolbar-title>
        <div v-if="customerHome">
          <h3 class="my-auto toolbar-title font-weight-medium">
            <span class="font-weight-bold">اهلا بك </span>{{ customerName }} في
            <span class="primary--text">بوابة العملاء</span>, إليك بعض المعلومات
            التي تهمك
          </h3>
        </div>
        <h3 v-else class="my-auto" v-text="title" />
      </v-toolbar-title>
    </div>
    <v-spacer></v-spacer>
    <div
      @click="toggleClientCards"
      class="details mx-1"
      v-if="
        showDetailsTab &&
        $hasPermission('statistics', 'statistics', 'list') &&
        status &&
        showDateFilter
      "
    >
      <span class="details font-14 text-primary d-flex py-1">
        <p class="my-auto">تفاصيل إحصائيه</p>
        <v-icon
          class="my-auto"
          color="#662d91"
          :class="{ active: cardsInfoState }"
        >
          mdi-chevron-up
        </v-icon>
      </span>
    </div>
    <div class="details" v-if="showDateFilter">
      <span
        class="details font-14 text-primary d-flex py-1"
        @click="openRangePicker"
      >
        <p class="my-auto">{{ RangePlaceholder }}</p>
        <v-icon
          class="my-auto"
          color="#662d91"
          :class="{ active: isRangeOpened }"
        >
          mdi-chevron-up
        </v-icon>
        <date-picker-rage
          ref="datePickerRage"
          class="range--card__date-picker_filter"
          @date-picker-filter-text="setFiltervalueForInput"
          @date-picker-filter-reset="resetDateFilter"
        />
      </span>
    </div>

    <div>
      <slot />
    </div>
  </v-toolbar>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import DatePickerRage from '../core/DatePickerRage.vue'

export default {
  name: 'tool-bar',
  props: {
    cardsInfoState: {
      type: Boolean,
      default: false
    },
    showDateFilter: {
      type: Boolean,
      default: false
    },
    breadcrumbs: {},
    title: {},
    length: {
      default: 0,
      type: Number
    },
    status: {
      type: Boolean,
      default: true
    },
    subTilte: {},
    customerHome: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DatePickerRage
  },
  data() {
    return {
      isRangeOpened: false,
      RangePlaceholder: 'تحديد الفترة'
    }
  },
  methods: {
    ...mapMutations('appState', ['toggleDrawer']),
    resetDateFilter() {
      this.RangePlaceholder = 'تحديد الفترة'
    },
    setFiltervalueForInput(sreachKey, input) {
      if (input === 'date') {
        this.isRangeOpened = !this.isRangeOpened
        this.RangePlaceholder = sreachKey
      }
      if (input === 'endDate' || input === 'startDate') {
        this.$emit('setDate', { dateType: input, date: sreachKey })
      }
    },
    openRangePicker() {
      this.isRangeOpened = !this.isRangeOpened
      if (this.isRangeOpened) {
        this.$refs.datePickerRage.$children[0].openPopup()
      } else {
        this.$refs.datePickerRage.$children[0].closePopup()
      }
    },
    toggleClientCards() {
      this.$emit('toggleCards')
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    showDetailsTab() {
      const name = this.$route.name
      return !(
        name !== 'clients' &&
        name !== 'client' &&
        name !== 'properties' &&
        name !== 'associations' &&
        name !== 'PropertyDetails'
      )
    },
    customerName() {
      return this.title?.split(' ')[0] ?? ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../src/styles/responsiveness/responsiveness.scss';
@import '@/styles/material-dashboard/_variables.scss';
@import '@/styles/material-dashboard/datepicker.scss';

div.details {
  background-color: #e9e3ee;
  padding-inline: 10px;
  padding-block: 5px;
  border-radius: 8px;
  cursor: pointer;
  @include max-sm {
    .details {
      &.font-14 {
        font-size: 12px !important;
        padding: 6px !important;
      }
    }
    span {
      color: $primary-color;
      background: rgba($primary-color, 0.05);
      border-radius: 10px 10px 0px 0px;
      cursor: pointer;

      .v-icon {
        color: $primary-color;
        @include max-sm {
          font-size: 14px;
        }
      }
    }
  }
}

.v-icon.active {
  transform: rotate(-180deg);
}

.toolbar-title {
  white-space: normal;
}
</style>
