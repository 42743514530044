<template>
  <v-form ref="form" v-model="isValid">
    <v-row class="mt-4">
      <v-col class="pl-2" cols="12" md="6">
        <h6 class="font-14 mt-0">نوع العرض</h6>
        <v-select
          color="black"
          :rules="[(v) => !!v || 'نوع العرض']"
          filled
          required
          v-model="typeOffer"
          :items="typesOffers"
          item-text="nameAr"
          item-value="nameEn"
          placeholder="نوع العرض"
          dense
          hideDetails
          append-icon="mdi-chevron-down"
          :menu-props="{ bottom: true, offsetY: true }"
        >
        </v-select>
      </v-col>

      <v-col class="mobile__pl__0" cols="12" md="6">
        <h6 class="mt-0">هل تود حجز العين العقارية في مدة العرض ؟</h6>
        <v-card
          style="pointer-events: none"
          class="d-flex justify-space-between px-2"
        >
          <h6 class="mx-1 font-14">
            {{ priceOfferInfo.reserveRealestate ? 'نعم' : 'لا' }}
          </h6>
          <v-switch
            class="my-auto"
            dense
            hide-details="auto"
            inset
            small
            v-model="priceOfferInfo.reserveRealestate"
          />
        </v-card>
      </v-col>

      <v-col cols="12" v-if="typeOffer">
        <h6 class="mt-0 mb-2">عنوان العرض</h6>
        <v-textarea
          style="max-height: 50px"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="priceOfferInfo.titleForOffer"
          auto-grow
          class-content="commentTextArea font-16"
          class="custom-textarea mb-5"
          filled
          hide-details
          no-resize
          placeholder="يمكنك كتابة او وصف للعرض هنا"
          rows="0"
          v-model="priceOfferInfo.titleForOffer"
        >
          <template v-slot:append>
            <v-btn
              @click="resetTitle"
              rounded
              color="#662d9119"
              class="reset-about-btn"
              >اعادة تعيين</v-btn
            >
          </template>
        </v-textarea>
      </v-col>
      <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
        <h6 class="ma-0 mb-2">اسم العقار</h6>
        <autocomplete
          @nextPageTrigger="getRealEstatesNextData"
          @emitSearchKey="getRealEstateSearchKey"
          :loading="isRealEstateLoading"
          @input="getAvaliableUnits($event)"
          :rules="[(v) => !!v || 'من فضلك قم بإختيار إسم العميل']"
          filled
          v-model="priceOfferInfo.realEstate"
          :items="availableRealEstates"
          item-text="name"
          :return-object="true"
          placeholder="اسم العقار"
          hide-details
          dense
          append-icon="mdi-chevron-down"
          :menu-props="{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          }"
        />
      </v-col>
      <v-col class="pl-3 mobile__pl__0" cols="12" sm="6">
        <h6 class="ma-0 mb-2">واحدات/وحدة</h6>
        <autocomplete
          v-bind="unitsInputProps"
          v-model="priceOfferInfo.selectedUnit"
        />
      </v-col>
      <v-col class="pl-2" cols="12" md="6">
        <h6 class="font-14 mt-0">حالة العميل</h6>
        <v-select
          color="black"
          filled
          v-model="clientState"
          :items="clientStatus"
          item-text="nameAr"
          item-value="nameEn"
          :rules="[(v) => !!v || 'حالة العميل']"
          placeholder="حالة العميل"
          required
          dense
          hideDetails
          append-icon="mdi-chevron-down"
          :menu-props="{ bottom: true, offsetY: true }"
        >
        </v-select>
      </v-col>

      <v-col
        class="pl-3 mobile__pl__0"
        cols="12"
        sm="6"
        v-if="clientState === 'lead'"
      >
        <h6 class="mt-0 font-14 font-weight-bold black--text">طريقة الإضافة</h6>
        <DropdownAlt
          data-test="client-type"
          ref="clientType"
          :configrations="{ name: '' }"
          :listingType="addWays"
          :defaultValue="typeForAdd"
          class="client--type--dropdown"
          @filterTypes="getClientType"
        />
      </v-col>

      <v-col class="pl-2" cols="12" md="6">
        <h6 class="font-14 mt-0">اسم العميل</h6>
        <v-text-field
          v-if="clientState === 'lead' && selectedWay === 'new'"
          v-model="priceOfferInfo.user.name"
          :rules="[(v) => !!v || 'من فضلك قم بإدخال اسم العميل']"
          dense
          required
          filled
          placeholder="اسم العميل"
          hide-details
        />
        <autocomplete
          v-else
          @nextPageTrigger="getClientNextData"
          @emitSearchKey="getClientDataBySearchKey"
          :disabled="forRenderClientName"
          @input="loadClientProperties($event)"
          :rules="[(v) => !!v || 'من فضلك قم بإختيار إسم العميل']"
          filled
          v-model="priceOfferInfo.user.user"
          :items="usersListToShow"
          item-text="name"
          item-value="_id"
          :return-object="true"
          placeholder="إسم العميل"
          hide-details
          dense
          append-icon="mdi-chevron-down"
          :menu-props="{
            transition: 'slide-y-transition',
            bottom: true,
            offsetY: true
          }"
        />
      </v-col>

      <template v-if="selectedWay === 'new' && clientState === 'lead'">
        <v-col class="pl-2" cols="12" md="6">
          <h6 class="mb-2 mt-0 font-14 font-weight-bold black--text">
            نوع العميل
          </h6>
          <dropdown-alt
            data-test="client-type"
            ref="owner-type"
            :configrations="{ name: '' }"
            :listingType="clientType"
            :defaultValue="userType"
            class="client--type--dropdown"
            @filterTypes="getPTClientType"
          />
        </v-col>

        <v-col class="pl-2" cols="12" md="6">
          <h6 class="mb-2 mt-0 font-14 font-weight-bold black--text">
            صفة العميل
          </h6>
          <v-select
            :rules="[(v) => !!v || 'من فضلك قم بإختيار نوع العميل']"
            filled
            :items="clientRoles"
            v-model="priceOfferInfo.user.clientRole"
            placeholder="صفة العميل"
            hide-details
            item-text="nameAr"
            item-value="code"
            multiple
            dense
            required
            append-icon="mdi-chevron-down"
            :menu-props="{
              transition: 'slide-y-transition',
              bottom: true,
              offsetY: true
            }"
          >
          </v-select>
        </v-col>

        <v-col class="pl-2" cols="12" md="6">
          <h6 class="mb-2 mt-0 font-14 font-weight-bold black--text">
            رقم العميل
          </h6>
          <vue-tel-input-vuetify
            :loading="checkPhoneNumberLoader"
            :error-messages="getPhoneNumberErrorMessage"
            :success-messages="getPhoneNumberSuccessMesage"
            class="phonenumber-field font-14"
            v-model="priceOfferInfo.user.phoneNumber"
            label=""
            :only-countries="['SA']"
            default-country="SA"
            ref="regPhoneNumber"
            placeholder="رقم الجوال"
            :filled="true"
            required
            suffix="+966"
            :rules="[
              (v) => (v && !!isValidPhone) || 'من فضلك تأكد من رقم الهاتف'
            ]"
            :valid-characters-only="true"
            @keydown="$formatNumberInputs"
            @validate="onValidatePhoneNumber"
            @input="formatNumberAExistance($event, 'phoneNumber')"
          />
        </v-col>

        <v-col class="pl-2" cols="12" md="6">
          <h6 class="mb-2 mt-0 font-14 font-weight-bold black--text">
            رقم الواتس اب
          </h6>
          <v-text-field
            dense
            filled
            placeholder="رقم الواتس اب"
            :rules="phoneNumberRules"
            class="font-14"
            :value="whatsAppNumberMask"
            @keydown="$formatNumberInputs"
            @input="formatWhatsAppNumber($event, 'whatsAppNumber')"
          />
        </v-col>

        <v-col class="pl-2" cols="12" md="6">
          <h6 class="mb-2 mt-0 font-14 font-weight-bold black--text">
            البريد الإلكتروني
          </h6>
          <v-text-field
            v-model="priceOfferInfo.user.email"
            dense
            filled
            placeholder="البريد الإلكتروني"
            class="font-14"
            :rules="emailRules"
          />
        </v-col>
      </template>
    </v-row>

    <!-- For dates -->
    <v-row no-gutters>
      <v-col class="pl-3 mobile__pl__0" cols="6" sm="3">
        <h6 class="mt-4 mb-2">بداية العرض</h6>
        <v-dialog
          :return-value.sync="startOfferSelected"
          ref="startAtDialog"
          v-model="startAtModal"
          width="320px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :rules="[(v) => !!v || 'من فضلك ادخل تاريخ بداية العرض']"
              append-icon="mdi-calendar-month-outline"
              dense
              filled
              hide-details
              placeholder="بداية العرض"
              readonly
              v-bind="attrs"
              v-model="startOfferSelected"
              v-on="on"
              class="date--dropdown font-14"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            scrollable
            :max="maxDate"
            v-model="startOffer"
            @change="calculateEndDate"
          >
            <v-spacer></v-spacer>
            <v-btn @click="startAtModal = false" color="primary" text>
              الغاء
            </v-btn>
            <v-btn
              @click="$refs.startAtDialog.save(startOffer)"
              color="primary"
              text
              >حفظ
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col class="pl-3 mobile__pl__0" cols="6" sm="3">
        <h6 class="mt-4 mb-2">نهاية العرض</h6>
        <v-dialog
          :return-value.sync="endOfferSelected"
          ref="endAtDialog"
          v-model="endAtModal"
          width="320px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :rules="[(v) => !!v || 'من فضلك ادخل تاريخ نهاية العرض']"
              append-icon="mdi-calendar-month-outline"
              dense
              filled
              hide-details
              placeholder="نهاية العرض"
              readonly
              v-bind="attrs"
              v-model="endOfferSelected"
              v-on="on"
              class="date--dropdown font-14"
              :disabled="!startOfferSelected"
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            :min="minEndDate"
            scrollable
            v-model="endOffer"
            @change="handleEndDateChange"
          >
            <v-spacer></v-spacer>
            <v-btn @click="endAtModal = false" color="primary" text>
              الغاء
            </v-btn>
            <v-btn
              @click="$refs.endAtDialog.save(endOffer)"
              color="primary"
              text
              >حفظ
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>

      <v-col class="pr-4 mobile__pl__0" cols="12" sm="6">
        <h6 class="ma-0 mt-4 mb-2">مدة العرض</h6>
        <v-row>
          <v-col cols="6 px-1">
            <v-text-field
              :rules="[validateMonth]"
              dense
              filled
              placeholder="0"
              suffix="شهر"
              class="font-14"
              v-model="months"
              :disabled="!startOfferSelected"
              @input="formatArabicNumber($event, 'months')"
              @keydown="$formatNumberInputs"
            />
          </v-col>
          <v-col cols="6 pr-1">
            <v-text-field
              :rules="[validateDay]"
              dense
              filled
              placeholder="0"
              suffix="يوم"
              class="font-14"
              v-model="days"
              :disabled="!startOfferSelected"
              @input="formatArabicNumber($event, 'days')"
              @keydown="$formatNumberInputs"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<style>
body .theme--light.v-input:not(.v-input--is-disabled) textarea {
  font-size: 14px !important;
}
.custom-textarea .v-input__slot {
  height: 50px;
}

.custom-textarea {
  max-height: 30px !important;
}

.custom-textarea .v-input__control {
  max-height: 100%;
}

.custom-textarea .v-textarea__content {
  max-height: 100%;
}

.custom-textarea .v-textarea__textarea {
  max-height: 100%;
}
</style>
<script>
import DropdownAlt from '@/components/add-unit/dropdownAlt'
import autocomplete from '@/components/forms/autocomplete'
import { debounce } from '@/helpers/debounce'
import inputMasking from '@/mixins/inputMasking.js'
import { EnvService } from '@/services'
import { convertNumbers2English } from '@/utils/formatters'
import { add, format, intervalToDuration } from 'date-fns'
import { mapState } from 'vuex'

export default {
  name: 'TypeAndDuration',
  props: {
    allClients: {
      default() {
        return []
      }
    },
    availableUnitsForParent: {
      default() {
        return []
      },
      require: false
    },
    isUnitLoading: {
      default: false
    },
    availableRealEstates: {
      default() {
        return []
      },
      require: true
    },
    allPTClients: {
      default() {
        return []
      }
    },
    isRealEstateLoading: {
      default: false
    },
    updatedPriceOffer: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    }
  },
  mixins: [inputMasking],
  data() {
    return {
      isValid: false,
      checkPhoneNumberLoader: false,
      reserveRealestate: false,
      phoneNumberErrorMessage: '',
      phoneNumberSuccessMesage: '',
      availableUnits: [],
      typeOffer: '',
      clientState: '',
      priceOfferInfo: {
        user: {},
        availableUnits: [{ name: 'كامل العقار', allocated: true }],
        selectedUnit: null,
        realEstate: null
      },
      realEstateExist: {},
      typesOffers: [
        { value: 1, nameAr: 'تأجير', nameEn: 'rent' },
        { value: 2, nameAr: 'وساطة عقارية', nameEn: 'realEstateBrokerage' }
      ],
      addTitleForOffer: false,
      clientRoles: [
        { _id: 1, code: 'owner', nameAr: 'مالك' },
        { _id: 2, code: 'renter', nameAr: 'مستأجر' }
      ],
      emailRules: [
        (v) => /.+@.+\..+/.test(v) || !v || 'من فضلك أدخل بريد الكتروني صحيح'
      ],
      phoneNumberRules: [
        (v) =>
          /^[0-9]*$/.test(v) ||
          (v && v.length === 10) ||
          !v ||
          'صيغة رقم الهاتف غير صحيحة'
      ],
      whatsAppNumberMask: '',
      clientStatus: [
        { nameAr: 'عميل حالي', nameEn: 'regular' },
        { nameAr: 'عميل محتمل', nameEn: 'lead' }
      ],
      phoneData: {},
      propertyToShow: 'name',
      noData: 'لا توجد عقارات متاحه لهذا البحث',
      selectedRelestatePlaceholder: 'اختر العقار',
      addWays: [
        {
          _id: 1,
          text: 'إختيار من القائمة',
          val: 'existing'
        },
        {
          _id: 2,
          text: 'إضافة جديدة',
          val: 'new'
        }
      ],
      clientType: [
        {
          _id: 1,
          text: 'فرد',
          val: 'individual'
        },
        {
          _id: 2,
          text: 'شركة',
          val: 'company'
        }
      ],
      selectedWay: '',
      startAtModal: false,
      endAtModal: false,
      startOffer: new Date().toISOString().substr(0, 10),
      endOffer: new Date().toISOString().substr(0, 10),
      startOfferSelected: '',
      endOfferSelected: '',
      months: 0,
      days: 0,
      maxDate: new Date().toISOString().substr(0, 10),
      formattedEndDate: new Date(),
      oldTitle: ''
    }
  },
  components: {
    autocomplete,
    DropdownAlt
  },
  computed: {
    ...mapState('accountState', ['configs', 'currentEnv']),
    userType() {
      return this.isEditMood ? this.priceOfferInfo.user.clientType : ''
    },
    unitsInputProps() {
      return {
        disabled:
          !this.priceOfferInfo?.realEstate?._id ||
          this.availableUnitsForParent.length === 1,
        loading: this.isUnitLoading,
        itemText: this.propertyToShow,
        items: this.filteredAvailableUnits,
        noData: this.noData,
        placeholder: this.selectedRelestatePlaceholder,
        returnObject: true,
        hideDetails: true
      }
    },
    filteredAvailableUnits() {
      if (
        !this.priceOfferInfo?.availableUnits?.length ||
        this.availableUnits.length
      ) {
        return this.availableUnits
      }
      return [this.priceOfferInfo?.availableUnits[0]]
    },
    typeForAdd() {
      return this.isEditMood ? this.selectedWay : ''
    },
    realestatePurpose() {
      return [...this.configs.realestatePurpose].filter((realestatePurpose) => {
        const neededType = [
          'Residential',
          'Commercial',
          'Residential_Commercial'
        ]

        return neededType.includes(realestatePurpose.nameEn)
      })
    },
    clientStateStatus() {
      return this.clientState === 'regular' || this.clientState === 'lead'
    },
    currentClientExisting() {
      return this.clientState === 'regular' || this.selectedWay === 'existing'
    },
    isValidPhone() {
      return this.phoneData.isValid
    },
    renderRealEstatePurpose() {
      if (!this.priceOfferInfo.realEstateType) {
        return this.realestatePurpose
      }
      const suggestions = [...this.configs.realestatePurpose].filter(
        (realestatePurpose) => {
          const propertyTypes = Object.keys(
            this.priceOfferInfo.realEstateType.ref
          )
          return propertyTypes.includes(realestatePurpose.nameEn)
        }
      )

      return suggestions
    },
    usersListToShow() {
      if (this.clientState === 'lead' && this.selectedWay === 'existing') {
        return this.allPTClients
      }
      return this.allClients
    },
    forRenderClientName() {
      let status = false
      if (
        (this.clientState === 'lead' && this.selectedWay === '') ||
        !this.clientState
      ) {
        status = true
      }
      return status
    },
    getPhoneNumberErrorMessage() {
      return this.phoneNumberErrorMessage
    },
    getPhoneNumberSuccessMesage() {
      return this.phoneNumberSuccessMesage
    },
    minEndDate() {
      return new Date().toISOString().substr(0, 10)
    },
    validation() {
      return this.selectedWay === 'new'
        ? this.isValid &&
            this.priceOfferInfo.user.clientType !== '' &&
            this.priceOfferInfo.user.clientRole?.length
        : this.isValid
    }
  },
  methods: {
    getClientNextData() {
      this.$emit('getClientNextData')
    },
    getClientDataBySearchKey(payload) {
      this.$emit('getClientDataBySearchKey', payload)
    },
    getClientType({ val }) {
      this.priceOfferInfo.user = {
        clientRole: [],
        clientType: ''
      }
      if (val) {
        this.selectedWay = val
      }
    },
    setavailableUnits(availableUnits) {
      this.availableUnits = availableUnits
    },
    async getAvaliableUnits(realestate) {
      if (realestate?._id) {
        this.priceOfferInfo.availableUnits = []
        this.$emit('resetRealEstatesUnits')
        this.$emit('getRealEstates', realestate?._id)
      }
    },
    getRealEstatesNextData() {
      this.$emit('getRealEstatesNextData')
    },
    getRealEstateSearchKey(payload) {
      this.$emit('getRealEstateSearchKey', payload)
    },
    loadClientProperties({ _id }) {
      this.$emit('loadClientProperties', _id)
    },
    getPTClientType({ val }) {
      this.priceOfferInfo.user.clientType = val
    },
    resetTitle() {
      this.priceOfferInfo.titleForOffer = this.oldTitle
    },
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    debounceCheckUserExistance: debounce(function (val) {
      // eslint-disable-next-line no-invalid-this
      this.checkUserExistance('phoneNumber')
      this.whatsAppNumberMask = this.priceOfferInfo.whatsAppNumber =
        val.replace(/ /g, '')
    }, 500),
    formatNumberAExistance(val, key) {
      this.priceOfferInfo[key] = convertNumbers2English(val)

      if (key === 'phoneNumber') {
        this.debounceCheckUserExistance(val)
      }
    },
    checkUserExistance(key) {
      let phoneNumber = this.priceOfferInfo.phoneNumber ?? ''
      phoneNumber = phoneNumber?.replace(/ /g, '')
      if (phoneNumber.length !== 10 && key === 'phoneNumber') {
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = 'من فضلك تأكد من رقم الهاتف'
      } else if (
        !/.+@.+\..+/.test(this.priceOfferInfo.email) &&
        key === 'email'
      ) {
        this.emailSuccessMesage = ''
        this.emailErrorMessage = this.priceOfferInfo.email.length
          ? 'من فضلك تأكد من البريد الإلكتروني'
          : ''
      } else if (
        this.isValidPhone ||
        /.+@.+\..+/.test(this.priceOfferInfo.email)
      ) {
        if (key === 'phoneNumber') {
          this.checkPhoneNumberLoader = true
          this.phoneNumberSuccessMesage = ''
          this.phoneNumberErrorMessage = ''
          phoneNumber = phoneNumber.replace('0', '966')
          // this.priceOfferInfo.phoneNumber = phoneNumber
        } else if (key === 'email') {
          this.checkEmailLoader = true
          this.emailSuccessMesage = ''
          this.emailErrorMessage = ''
          phoneNumber = phoneNumber.replace('0', '966')
        }
        EnvService.checkClient({
          environment: this.currentEnv._id,
          phoneNumber
        })
          .then((response) => {
            if (response.data.available && key === 'phoneNumber') {
              this.phoneNumberSuccessMesage = 'العميل متاح للأضافه'
              this.phoneNumberValid = true
              this.phoneNumberErrorMessage = null
            } else if (key === 'email') {
              this.emailValid = true
              this.emailSuccessMesage = 'البريد متاح للدعوه'
            }
          })
          .catch((error) => {
            if (key === 'phoneNumber') {
              this.phoneNumberErrorMessage = ' الرقم مُسجل بإسم عميل أخر'
              this.phoneNumberValid = false
            } else if (key === 'email') {
              this.emailErrorMessage = error.response.data.error
              this.emailValid = false
            }
          })
          .finally(() => {
            this.checkPhoneNumberLoader = false
          })
      }
    },
    validateMonth(v) {
      const stringValue = String(v)
      return (
        ((stringValue || stringValue === 0) &&
          (!stringValue.length ||
            (!!stringValue.length && stringValue >= 0 && stringValue <= 11))) ||
        '0 - 11'
      )
    },
    validateDay(v) {
      const stringValue = String(v)
      return (
        ((stringValue || stringValue === 0) &&
          (!stringValue.length ||
            (!!stringValue.length && stringValue >= 0 && stringValue <= 30))) ||
        '0 - 30'
      )
    },
    formatArabicNumber(val, key, child = '') {
      const value = isNaN(val)
        ? Number(convertNumbers2English(val.replaceAll(',', '')))
        : Number(convertNumbers2English(val))

      if (Number(value)) {
        if (!child) {
          this[key] = value
          this.calculateEndDate()
        } else {
          this[key][child] = value
        }
      }
    },
    calculatePeriod() {
      const { days, months, years } = intervalToDuration({
        start: new Date(this.startOffer),
        end: new Date(this.endOffer)
      })
      this.years = years
      this.months = months
      this.days = days
      this.formattedEndDate = new Date(this.endOffer).toISOString()
    },
    handleEndDateChange() {
      this.calculatePeriod()
    },
    calculateEndDate() {
      const res = add(new Date(this.startOffer), {
        years: this.years ?? 0,
        months: this.months ?? 0,
        days: this.days ? this.days + 1 : 0,
        seconds: -1,
        minutes: new Date().getTimezoneOffset()
      })

      if (this.months || this.days) {
        this.endOffer = format(res, 'yyyy-MM-dd')
      }

      this.formattedEndDate = res.toISOString()
      this.endOfferSelected = this.endOffer
    },
    presetData() {
      this.editMoodPresetData()
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        this.startOfferSelected = this.updatedPriceOffer.startDate.split('T')[0]
        this.endOfferSelected = this.updatedPriceOffer.endDate.split('T')[0]
        this.startOffer = this.startOfferSelected
        this.endOffer = this.endOfferSelected
        this.priceOfferInfo.reserveRealestate =
          this.updatedPriceOffer.reserveRealestate
        this.$set(
          this.priceOfferInfo,
          'titleForOffer',
          this.updatedPriceOffer?.title
        )
        this.addTitleForOffer = !!this.priceOfferInfo.titleForOffer
        this.typeOffer = this.typesOffers.find(
          ({ nameEn }) => nameEn === this.updatedPriceOffer?.type
        ).nameEn
        this.clientState = this.updatedPriceOffer.client.status

        // RealEstate
        const { realEstate, price, pricePerMeter, space } =
          this.updatedPriceOffer
        const parentExist = !!realEstate.parentId

        if (parentExist) {
          this.$set(this.priceOfferInfo, 'realEstate', {
            ...realEstate.parentId,
            unitsCount: 1
          })
          this.$set(this.priceOfferInfo, 'selectedUnit', {
            ...realEstate,
            price,
            pricePerMeter,
            space
          })
        } else {
          this.$set(this.priceOfferInfo, 'realEstate', {
            ...realEstate,
            price,
            pricePerMeter,
            space
          })
        }

        this.getAvaliableUnits(this.priceOfferInfo.realEstate)

        // User
        if (
          (this.updatedPriceOffer.client.status === 'lead' &&
            !this.updatedPriceOffer.client.new) ||
          this.updatedPriceOffer.client.status === 'regular'
        ) {
          this.selectedWay = 'existing'
          this.$set(
            this.priceOfferInfo.user,
            'user',
            this.updatedPriceOffer.client
          )
        }
        if (
          this.updatedPriceOffer.client.status === 'lead' &&
          this.updatedPriceOffer.client.new
        ) {
          this.selectedWay = 'new'
          this.$set(this.priceOfferInfo, 'user', this.updatedPriceOffer.client)
          this.phoneData.isValid = true
        }

        this.handleEndDateChange()
      }
    },
    dataToBeBinded() {
      this.priceOfferInfo.realEstateExist = this.realEstateExist
      this.priceOfferInfo.typeOffer = this.typeOffer
      this.priceOfferInfo.clientState = this.clientState
      this.priceOfferInfo.startOffer = this.startOfferSelected
      this.priceOfferInfo.endOffer = this.endOfferSelected

      const REGULAR = 'regular'
      const LEAD = 'lead'
      const clientState = this.priceOfferInfo.clientState
      let user = {
        isLead: this.selectedWay === 'new',
        clientRole: this.priceOfferInfo.user.clientRole,
        clientType: this.priceOfferInfo.user.clientType,
        name: this.priceOfferInfo.user.name,
        phoneNumber: this.priceOfferInfo.user.phoneNumber,
        email: this.priceOfferInfo.user?.email,
        status: clientState
      }

      if (
        clientState === REGULAR ||
        (clientState === LEAD && this.selectedWay === 'existing')
      ) {
        user = {
          _id: this.priceOfferInfo.user.user._id,
          name: this.priceOfferInfo.user.user.name,
          phoneNumber: this.priceOfferInfo.user.user.phoneNumber,
          email: this.priceOfferInfo.user.user?.email,
          status: clientState
        }
      }
      return { ...this.priceOfferInfo, user, selectedWay: this.selectedWay }
    },
    bindContractData() {
      this.$emit('bindPriceOfferData', this.dataToBeBinded())
    }
  },
  watch: {
    'priceOfferInfo.titleForOffer'(newVal, oldVal) {
      if (!this.oldTitle && oldVal) {
        this.oldTitle = oldVal
      }
    },
    typeOffer(val) {
      if (!this.isEditMood) {
        this.$set(
          this.priceOfferInfo,
          'titleForOffer',
          this.typesOffers.find((type) => type.nameEn === val).nameAr
        )
      }
    },
    'priceOfferInfo.realEstate'(val) {
      this.realEstateExist = {}
      if (!val) {
        this.priceOfferInfo.selectedUnit = {}
      }

      if (Object.keys(val).length && !val?.unitsCount) {
        this.priceOfferInfo.selectedUnit = {}
        this.realEstateExist = this.priceOfferInfo?.realEstate
        this.priceOfferInfo.selectedUnit = {
          name: 'كامل العقار',
          allocated: true
        }
      }
    },
    'priceOfferInfo.selectedUnit'(val) {
      if (!val) {
        this.realEstateExist = {}
      }
      if (val.allocated) {
        this.realEstateExist = this.priceOfferInfo?.realEstate
      }
      if (!val.allocated) {
        this.realEstateExist = this.priceOfferInfo.selectedUnit
      }
    },
    availableUnitsForParent(val) {
      if (!val.length) {
        this.priceOfferInfo.availableUnits = []
      }
      this.setavailableUnits(val)
    },
    addTitleForOffer(val) {
      if (!val) {
        this.priceOfferInfo.titleForOffer = ''
      }
    },
    clientState(nVal, oVal) {
      if (nVal === 'regular' && oVal === 'lead') {
        this.priceOfferInfo.user = {}
      }
      if (!this.isEditMood) {
        this.priceOfferInfo.user = {}
        this.selectedWay = ''
      }
    },
    validation(val) {
      this.$emit('setValdationStatus', val)

      if (val) {
        this.$emit('setData', this.priceOfferInfo)
      }
    }
  },
  created() {
    this.editMoodPresetData()
  }
}
</script>

<style lang="scss" scoped>
.reset-about-btn {
  border-radius: 47px;
  color: #662d91 !important;
  box-shadow: none;
  font-size: 12px;
  position: relative;
  bottom: 18px;
}
</style>
