<template>
  <div class="show-detailes pdf-summary-container">
    <v-slide-x-transition>
      <v-layout>
        <v-col :cols="3" @click="$emit('closePdf')" class=""> </v-col>

        <v-col :cols="3" class="whiteSide pa-0">
          <div class="mx-4">
            <v-layout class="d-flex justify-space-between align-center">
              <v-icon data-test="close-pdf" @click="$emit('closeDetails')"
                >mdi-arrow-right</v-icon
              >
            </v-layout>
          </div>
          <v-divider class="mt-3"></v-divider>
          <SideDuesContent
            :DueDetails="DueDetails"
            :assignmentTypes="assignmentTypes"
            @closeDetails="closeDetails"
          />
        </v-col>
      </v-layout>
    </v-slide-x-transition>
  </div>
</template>

<script>
import SideDuesContent from './SideDuesContent.vue'
export default {
  components: {
    SideDuesContent
  },
  props: {
    DueDetails: {
      type: Object,
      default: () => {}
    },
    assignmentTypes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showDetails: false
    }
  },
  methods: {
    closeDetails() {
      this.$emit('closeDetails')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/pdf/_contractPDF.scss';

.file-container-col .v-text-field--outlined fieldset {
  border: 1px dashed #662d91;
}
</style>
