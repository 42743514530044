<template>
  <div class="mt-7 amenities">
    <h4 class="font-16 font-weight-medium amenities-text">المرافق الداخلية</h4>
    <div
      v-if="contractAmenities.length"
      class="mt-6 d-flex flex-wrap amenities-container"
    >
      <div
        v-for="amenity in contractAmenities"
        :key="amenity.nameAr"
        class="d-flex align-center amenity"
      >
        <img :src="amenitiesIcon" alt="amenities icon" />
        <span class="amenities-text amenities-name">
          {{ amenity.nameAr }}
        </span>
      </div>
    </div>
    <div v-else class="mt-12 text-center">
      <span class="nodata-text font-16"
        >لا يوجد مرافق داخلية مضافة حتى الآن</span
      >
    </div>
    <h4 class="font-16 font-weight-medium amenities-text">المرافق الخارجية</h4>
    <div
      v-if="contractFacilities.length"
      class="mt-7 d-flex flex-wrap amenities-container"
    >
      <div
        v-for="facility in contractFacilities"
        :key="facility.nameAr"
        class="d-flex align-center amenity"
      >
        <img :src="amenitiesIcon" alt="amenities icon" />
        <span class="amenities-text amenities-name">
          {{ facility.nameAr }}
        </span>
      </div>
    </div>
    <div v-else class="mt-12 text-center">
      <span class="nodata-text font-16"
        >لا يوجد مرافق حارجية مضافة حتى الآن</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContractAmenities',
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      amenitiesIcon: require('@/assets/img/svgs/amenities-checkmark.svg')
    }
  },
  computed: {
    contractAmenities() {
      return this.contract?.amenities ?? []
    },
    contractFacilities() {
      return this.contract?.facilities ?? []
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/responsiveness/responsiveness.scss';
.amenities {
  &-text {
    color: #101010 !important;
  }
  &-container {
    row-gap: 19px;
  }
  &-name {
    margin-right: 10px;
  }
  .nodata-text {
    color: #9f9fac;
  }
}
.amenity {
  flex-basis: 20%;
  @include md {
    flex-basis: 25%;
  }
  @include sm {
    flex-basis: 33.33%;
  }
  @include xs {
    flex-basis: 50%;
  }
}
</style>
