var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isRequestLoading)?_c('div',{staticClass:"d-flex justify-center align-center my-16"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"#662d91","indeterminate":""}})],1):_c('div',[_c('div',{staticClass:"mt-5 pb-8 amenities-container"},[_c('div',{staticClass:"px-5"},[_c('h6',{staticClass:"font-14 font-weight-medium section-title"},[_vm._v("\n          المرافق الداخلية\n        ")]),_c('v-row',_vm._l((_vm.amenities),function(amenity){return _c('v-col',{key:amenity._id,attrs:{"md":"3"}},[_c('div',{staticClass:"d-flex align-center option-container cursor pr-2 py-2",class:{
                'selected-checkbox': _vm.contractInfo.amenities.includes(
                  amenity._id
                )
              }},[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","value":amenity._id},model:{value:(_vm.contractInfo.amenities),callback:function ($$v) {_vm.$set(_vm.contractInfo, "amenities", $$v)},expression:"contractInfo.amenities"}}),_c('span',{staticClass:"font-14 section-title mr-1"},[_vm._v("\n                "+_vm._s(amenity.nameAr)+"\n              ")])],1)])}),1)],1)]),_c('div',{staticClass:"mt-7 px-5 facilities-container"},[_c('h6',{staticClass:"font-14 font-weight-medium section-title"},[_vm._v("\n        المرافق الخارجية\n      ")]),_c('v-row',_vm._l((_vm.facilities),function(facility){return _c('v-col',{key:facility._id,attrs:{"sm":"3"}},[_c('div',{staticClass:"d-flex align-center option-container cursor pr-2 py-2",class:{
              'selected-checkbox': _vm.contractInfo.facilities.includes(
                facility._id
              )
            }},[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","value":facility._id},model:{value:(_vm.contractInfo.facilities),callback:function ($$v) {_vm.$set(_vm.contractInfo, "facilities", $$v)},expression:"contractInfo.facilities"}}),_c('span',{staticClass:"font-14 section-title mr-1"},[_vm._v("\n              "+_vm._s(facility.nameAr)+"\n            ")])],1)])}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }