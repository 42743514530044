<template>
  <v-row class="conversations flex-column">
    <!-- Cards for suggestions -->
    <v-col class="conversations__cards__wrapper">
      <ConversationsCard
        v-for="i in 1"
        :key="i"
        class="mt-3"
        :likeStatus="true"
      />
    </v-col>

    <!-- Write new suggest -->
    <v-col class="conversations__send mb-1">
      <v-form @submit.prevent="addNewSuggest">
        <v-textarea
          filled
          v-model="suggest"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="addNewSuggest"
          placeholder="أدخل اقتراحك هنا"
          auto-grow
          hide-details
          class-content="font-12"
          no-resize
          rows="1"
        >
          <template v-slot:append>
            <img
              alt="add comment"
              :class="[
                'mt-md-1 mx-2',
                'pointerCursor',
                { 'disable-add-comment': addSuggestLoading }
              ]"
              src="@/assets/img/svgs/send.svg"
              loading="lazy"
            />
          </template>
        </v-textarea>
      </v-form>
    </v-col>
  </v-row>
</template>

<style>
.conversations .v-select .v-input__control .v-input__slot,
.conversations .v-text-field--filled .v-input__control .v-input__slot {
  border-radius: 1.125rem !important;
}
</style>
<script>
import ConversationsCard from '@/components/associations/ConversationsCard'
export default {
  name: 'Suggestions',
  data() {
    return {
      suggest: '',
      addSuggestLoading: false
    }
  },
  components: {
    ConversationsCard
  },
  methods: {
    addNewSuggest() {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/associations/associations.scss';
</style>
