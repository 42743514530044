<template>
  <div class="donut-chart-container" :style="{ width: width + 'px' }">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: 'DonutChart',
  props: {
    data: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    width: {
      type: Number,
      default: 400
    },
    cutoutPercentage: {
      type: Number,
      default: 10
    }
  },
  mounted() {
    this.drawChart()
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    drawChart() {
      const canvas = this.$refs.canvas
      const ctx = canvas?.getContext('2d')
      const height = this.width
      const cutoutPercentage = this.cutoutPercentage
      canvas.width = this.width
      canvas.height = height

      const total = this.data?.reduce((sum, value) => sum + value, 0)

      let startAngle = -0.5 * Math.PI

      if (total === 0) {
        ctx.beginPath()
        ctx.moveTo(this.width / 2, height / 2)
        ctx.arc(
          this.width / 2,
          height / 2,
          Math.min(this.width / 2, height / 2),
          0,
          2 * Math.PI
        )
        ctx.closePath()
        ctx.fillStyle = '#E9ECEF'
        ctx.fill()
      } else {
        this.data.forEach((value, index) => {
          const sliceAngle = (value / total) * 2 * Math.PI
          const endAngle = startAngle + sliceAngle

          ctx.beginPath()
          ctx.moveTo(this.width / 2, height / 2)
          ctx.arc(
            this.width / 2,
            height / 2,
            Math.min(this.width / 2, height / 2),
            startAngle,
            endAngle
          )
          ctx.closePath()

          ctx.fillStyle = this.colors[index]
          ctx.fill()

          startAngle = endAngle
        })
      }

      if (cutoutPercentage > 0) {
        const innerRadius =
          Math.min(this.width / 2, height / 2) * (cutoutPercentage / 100)
        ctx.beginPath()
        ctx.arc(this.width / 2, height / 2, innerRadius, 0, 2 * Math.PI)
        ctx.fillStyle = '#FFFFFF'
        ctx.fill()
      }
    },
    handleResize() {
      this.drawChart()
    }
  }
}
</script>

<style scoped>
.donut-chart-container {
  max-width: 100%;
  position: relative;
}
canvas {
  display: block;
  width: 100%;
  height: auto;
}
</style>
