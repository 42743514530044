<template>
  <span>
    <span>
      {{ displayedNumber }}
      <span v-if="nonNumericString">{{ nonNumericString }}</span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    targetNumber: {
      required: true
    },
    duration: {
      type: Number,
      default: 2000 // duration in milliseconds
    }
  },
  data() {
    return {
      displayedNumber: 0,
      nonNumericString: ''
    }
  },
  computed: {
    convertTargetNumber() {
      // exclude number if the targetNumber is string
      if (typeof this.targetNumber === 'string') {
        // Use a regular expression to extract the number
        const numericValue = this.targetNumber.match(/\d+/)[0]

        const regex = /[\p{Script=Arabic}%]/gu
        const matches = this.targetNumber.match(regex)

        // Use a regular expression to remove the number and get the non-numeric part
        this.nonNumericString = matches.join('')
        return numericValue
      }

      return this.targetNumber
    }
  },
  methods: {
    animateNumber() {
      const start = performance.now()
      const initialNumber = this.displayedNumber
      const change = this.convertTargetNumber - initialNumber

      const animate = (time) => {
        const elapsedTime = time - start
        const progress = Math.min(elapsedTime / this.duration, 1)
        this.displayedNumber = Math.floor(initialNumber + change * progress)

        if (progress < 1) {
          requestAnimationFrame(animate)
        }
      }

      requestAnimationFrame(animate)
    }
  },
  watch: {
    targetNumber() {
      this.animateNumber()
    }
  },
  mounted() {
    this.animateNumber()
  }
}
</script>
