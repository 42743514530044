<template>
  <v-dialog max-width="740px" persistent v-model="dialogState_">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="pa-0 px-5 mb-5 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0">إضافة مهمة</h4>
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- Details -->

      <v-card-text class="px-5 pb-3">
        <v-form ref="form" v-model="isValid">
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" lg="12" sm="6">
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  أسم المهمة
                  <span class="red--text">*</span>
                </h6>
                <v-text-field
                  class="mb-0 pa-0"
                  filled
                  v-model.trim="addTask.name"
                  placeholder="ادخل أسم المهمة"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  التصنيف
                  <span class="red--text">*</span>
                </h6>
                <v-select
                  filled
                  v-model="addTask.category"
                  :items="categories"
                  item-text="nameAr"
                  item-value="code"
                  return-object
                  placeholder="التصنيف"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                ></v-select>
              </v-col>
              <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  النوع
                  <span class="red--text">*</span>
                </h6>
                <v-select
                  filled
                  v-model="addTask.type"
                  :items="taskTypes"
                  item-text="nameAr"
                  item-value="code"
                  return-object
                  placeholder="نوع المهمة"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                ></v-select>
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
                v-if="!isCustomer"
              >
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  إسم العميل
                  <span class="red--text">*</span>
                </h6>
                <autocomplete
                  @nextPageTrigger="getClientNextData"
                  @emitSearchKey="getClientDataBySearchKey"
                  @input="loadClientProperties"
                  :rules="[(v) => !!v || 'من فضلك قم بإختيار إسم العميل']"
                  filled
                  v-model="addTask.client"
                  :items="allClients"
                  item-text="name"
                  item-value="_id"
                  :return-object="true"
                  placeholder="إسم العميل"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                />
              </v-col>
              <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  إسم العقار
                  <span class="red--text">*</span>
                </h6>
                <autocomplete
                  :noData="noDataInTheListText"
                  :rules="[(v) => !!v || 'من فضلك قم بإختيار إسم العقار']"
                  filled
                  v-model="addTask.realEstate"
                  :items="allProperties"
                  :disabled="isCustomer ? false : !addTask.client"
                  :loading="realEstateLoading"
                  item-text="propertyName"
                  item-value="_id"
                  return-object
                  placeholder="إسم العقار"
                  hide-details
                  dense
                  @nextPageTrigger="getPropertyNextData"
                  @emitSearchKey="getPropertyDataBySearchKey($event)"
                />
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
                v-if="!isCustomer"
              >
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  المسؤول
                  <span class="red--text">*</span>
                </h6>
                <v-select
                  filled
                  v-model="addTask.assignedTo"
                  :items="allEmployees"
                  item-text="name"
                  item-value="_id"
                  multiple
                  return-object
                  placeholder="المسؤول"
                  clearable
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:no-data>
                    <div class="text-center font-14 grey--text">
                      <span>لم يتم تحميل اي بيانات</span>
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
                v-if="!isCustomer"
              >
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  الأولوية
                  <span class="red--text">*</span>
                </h6>
                <v-select
                  :rules="[(v) => !!v || 'من فضلك قم بإختيار أولويه الطلب']"
                  filled
                  v-model="addTask.priority"
                  :items="taskPriorities"
                  item-text="nameAr"
                  item-value="nameAr"
                  placeholder="اختر أولويه الطلب"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:no-data>
                    <div class="text-center font-14 grey--text">
                      <span>لم يتم تحميل اي اختيارات</span>
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col class="mb-8" cols="12">
                <FilesUploader
                  ref="uploadingComp"
                  @uploadAttachment="uploadAttachment"
                  @deleteAttachment="deleteAttachment"
                  :light="false"
                  :attachments="attachments"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-2 px-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-0 rounded-7 my-1 d-flex px-3"
          :disabled="!isValid"
          :loading="addLoadingStatus"
          @click="addNewTask"
        >
          <span class="mb-1 mobile__mb__0 ml-1 font-weight-bold mt-1 font-12">
            إضافة
          </span>
          <v-icon class="send-icon font-16 mt-1">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import autocomplete from '@/components/forms/autocomplete'
import FilesUploader from '@/components/helper/filesUploader'
import { userService } from '@/services'

export default {
  name: 'AddTaksModal',
  components: { autocomplete, FilesUploader },
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    realEstateLoading: {
      default: true
    },
    allClients: {
      default() {
        return []
      }
    },
    addLoadingStatus: {
      default: false,
      type: Boolean
    },
    allProperties: {
      default() {
        return []
      }
    },
    allEmployees: {
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isValid: false,
      addTask: {},
      attachments: [],
      taskTypes: [
        {
          nameAr: 'تسويق',
          nameEn: 'marketing'
        },
        {
          nameAr: 'صيانه',
          nameEn: 'maintenance'
        },
        {
          nameAr: 'إداره أملاك',
          nameEn: 'propertyManagement'
        },
        {
          nameAr: 'تحصيل',
          nameEn: 'collection'
        }
      ],
      categories: [
        {
          nameAr: 'مهمه',
          nameEn: 'mission'
        },
        {
          nameAr: 'شكوي',
          nameEn: 'complaint'
        },
        {
          nameAr: 'طلب',
          nameEn: 'request'
        }
      ],
      taskPriorities: [
        { nameAr: 'طارئ', nameEn: 'urgent' },
        { nameAr: 'عاجل', nameEn: 'high' },
        { nameAr: 'متوسط', nameEn: 'normal' },
        { nameAr: 'منخفض', nameEn: 'low' }
      ]
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    ...mapState('accountState', ['user', 'currentEnv']),
    activeCustomerRole() {
      return JSON.parse(localStorage.getItem('activeCustomerRole'))
    },
    noDataInTheListText() {
      return !this.realEstateLoading
        ? 'لا توجد عقارات متاحة'
        : 'جاري البحث عن عقارات العميل...'
    },
    getTaskTypesOptions() {
      return this.taskTypes.filter((tskType) => {
        return this.isCustomer
          ? !!tskType?.userType?.includes(this.activeCustomerRole)
          : !!tskType?.role?.includes('business')
      })
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('toggleDialogState', value)
      }
    },
    newTask() {
      const newTask = {
        status: {
          nameEn: 'new',
          nameAr: 'جديدة'
        },
        createdBy: this.user._id,
        environment: this.currentEnv._id,
        date: this.addTask?.date,
        description: this.addTask?.description,
        user: this.addTask?.user,
        attachment: this.attachments.map((attach) => attach.id),
        ...this.addTask
      }
      if (this.addTask?.realEstate) {
        newTask.realEstate = {
          name:
            this.addTask.realEstate.name ||
            this.addTask.realEstate.propertyName,
          _id: this.addTask.realEstate._id,
          owner: this.addTask.realEstate.owner?._id,
          renter: this.addTask.realEstate.tenet?._id || undefined,
          moderators: this.addTask.realEstate.moderators
        }
      }
      return newTask
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.addTask = {}
      this.dialogState_ = false
    },
    async uploadAttachment({ files }) {
      files = files.dataTransfer ? files.dataTransfer.files : files.target.files
      const selectedFiles = Object.values(files)
      selectedFiles.forEach((file, index) => {
        const fileIndex = index + this.attachments.length
        const reader = new FileReader()
        reader.onload = () => {
          this.attachments.splice(fileIndex, 1, { Location: reader.result })
          this.onUpload(file, fileIndex)
        }
        reader.readAsDataURL(file)
      })
    },
    async onUpload(file, fileIndex) {
      this.$emit('setAddLoadingStatus', true)
      try {
        const fd = new FormData()
        fd.append('attachment', file, file.name)
        fd.append('envId', this.currentEnv._id)
        const {
          data: { attachment }
        } = await userService.addDocument(fd)
        const fileObject = {
          Key: attachment.key,
          Location: attachment.url,
          id: attachment._id
        }
        this.attachments.splice(fileIndex, 1, fileObject)
        this.addNotification({
          text: 'تم إضافه المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.attachments.splice(fileIndex, 1)
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      } finally {
        this.$emit('setAddLoadingStatus', false)
      }
    },
    async deleteAttachment({ id, index }) {
      this.$emit('setAddLoadingStatus', true)
      try {
        await userService.deleteDocument(id)
        this.attachments.splice(index, 1)
        this.addNotification({
          text: 'تم حذف المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم يتم حذف المرفق يرجى المحاولة مره اخرى',
          color: 'error'
        })
      } finally {
        this.$emit('setAddLoadingStatus', false)
      }
    },
    getClientNextData() {
      this.$emit('getClientNextData')
    },
    getPropertyNextData() {
      if (this.addTask?.client?._id) {
        this.$emit('getPropertyNextData', this.addTask)
      }
    },
    getClientDataBySearchKey(payload) {
      this.$emit('getClientDataBySearchKey', payload)
    },
    getPropertyDataBySearchKey(e) {
      if (this.addTask?.client?._id) {
        this.$emit('getPropertyDataBySearchKey', {
          e,
          itemData: this.addTask
        })
      }
    },
    loadClientProperties({ _id }) {
      _id && this.$emit('loadClientProperties', _id)
    },
    addNewTask() {
      this.$emit('addNewTask', this.newTask)
    }
  }
}
</script>
