<template>
  <v-dialog persistent v-model="dialogState_" max-width="900px">
    <v-card class="contract-modal">
      <v-card-title
        class="pl-2 pr-4 pt-3 pb-5 mb-5 d-flex justify-space-between"
      >
        <div>
          <div class="headline ft">{{ ediet ? 'تعديل' : 'إضافة' }} سند</div>
        </div>

        <v-spacer></v-spacer>

        <v-btn
          fab
          text
          color="grey"
          class="ma-0 mt-2"
          @click.prevent="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="price"
                  prepend-inner-icon="mdi-account-cash-outline"
                  label="السعر"
                  required
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="vouchersType"
                  prepend-inner-icon="mdi-file-document-edit-outline"
                  :items="vouchersTypes"
                  item-text="name"
                  filled
                  item-value="nameEn"
                  label="نوع السند"
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="notes"
                  prepend-inner-icon="mdi-file-document-outline"
                  label="ملاحظات"
                  required
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          x-large
          rounded
          :loading="isLoading"
          class="mb-4"
          color="info"
          min-width="200px"
          @click="handleAddVouchers"
        >
          <v-icon class="send-icon ml-2">mdi-send-outline</v-icon>
          <span class="tf">{{ ediet ? 'تعديل' : 'انشاء' }} سند</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import config from '@/config'
import { mapState, mapActions, mapMutations } from 'vuex'
import '@/styles/material-dashboard/_add-new-property.scss'
import { realEstateService, userService, EnvService } from '../../services'

export default {
  props: ['dialogState', 'realEstate', 'contract'],
  data() {
    return {
      valid: false,
      office: null,
      property: null,
      priceOrProfit: null,
      price: null,
      notes: null,
      profit: null,
      services: [],
      moderator: null,
      moderator2: null,
      tahsel: null,
      owner: null,
      contractPeriod: null,
      fieldArchive: null,
      startAt: new Date().toISOString().substr(0, 10),
      createdAt: new Date().toISOString().substr(0, 10),
      startAtModal: false,
      createdAtModal: false,
      bonds: [],
      isLoading: false,
      isPageLoading: true,
      properties: [],
      durations: [],
      servicesData: [],
      configLoad: true,
      allClients: [],
      clientsLoad: true,
      vouchersTypes: [
        { name: 'سند قبض', nameEn: 'payment' },
        { name: 'سند صرف', nameEn: 'receipt' }
      ],
      vouchersType: null,
      ediet: false
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'loggedIn']),
    team() {
      return config.team
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },
  mounted() {
    this.getProperties()
  },
  methods: {
    ...mapActions('accountState', ['getAllClients']),
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('close-contract-dialog')
    },

    displayName(i) {
      return `${i.type.nameAr} ${i.address.address}`
    },
    handleAddVouchers() {
      const { price, notes, vouchersType } = this
      const env = JSON.parse(localStorage.getItem('currentEnv'))
      this.isLoading = true
      const data = {
        price,
        _id: this.contract._id,
        env: env._id,
        type: vouchersType,
        comment: notes
      }

      EnvService.addVouchers(data)
        .then(() => {
          this.isLoading = false
          this.$emit('close-contract-dialog')
        })
        .catch(() => {})
    },

    getProperties() {
      const env = JSON.parse(localStorage.getItem('currentEnv'))
      if (this.realEstate && this.realEstate._id) {
        this.property = this.realEstate._id
      }

      realEstateService.getEnvRealEstate(env._id).then(
        (res) => {
          this.properties = res.data.realEstates
          this.isPageLoading = false
          EnvService.getContractConfig()
            .then(() => {
              this.configLoad = false
              this.durations = res.data.durations
              this.servicesData = res.data.services
            })
            .catch(() => {})

          userService.getAllClients(env._id).then(() => {
            this.clientsLoad = false
            this.allClients = res.data.clients
          })
        },
        (error) => {
          this.addNotification({
            text: error,
            color: 'error'
          })
        }
      )
    }
  }
}
</script>

<style lang="scss">
.contract-modal .v-card__title {
  border-bottom: 1px solid #eff2f7;

  .headline,
  .v-subheader {
    line-height: 1 !important;
  }

  .v-subheader {
    height: auto;
  }
}
</style>
