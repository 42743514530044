<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination
            v-model="pagination.pageNumber"
            :length="pagination.pagesCount"
            :total-visible="8"
            @input="$emit('onChange', $event)"
          />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    pagination: {}
  }
}
</script>
