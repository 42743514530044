<template>
  <div>
    <v-data-table
      :class="[
        'cursor__pointer',
        tableClasses,
        {
          'hectar-table-sm': tableSm,
          'cursor-move': stopClicked
        }
      ]"
      :headers="headers"
      :items="reports"
      :items-per-page="reports.length"
      :page.sync="page"
      :search="search"
      class="hectar-table"
      hide-default-footer
      mobile-breakpoint="0"
      @click:row="handleClick($event)"
      :item-class="itemRowBackground"
      hide-default-header
    >
      <!-- <template v-slot:item="row">
        <tr class='special' v-if='row.index==reports.length-1' :key="row.index">

        </tr>
      </template> -->
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th v-for="h in headers" :key="h.text">
              <div :class="{ 'd-flex justify-center': h.value !== 'title' }">
                <span class="font-12 text-gray">{{ h.text }}</span>
              </div>
              <div v-if="h.value == 'revenues'" class="mx-auto">
                <span class="d-flex justify-space-between">
                  <h6 class="text-gray">الإيجار</h6>
                  <h6 class="text-gray mx-2">الضريبة</h6>
                  <h6 class="text-gray mx-2">الخدمات</h6>
                  <h6 class="text-gray">الضريبة</h6>
                </span>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:no-data> لا توجد بيانات حتى الان</template>
      <template v-slot:[`item.propertyAddress.address`]="{ item }">
        <h6 class="font-14 font ma-0 text-truncate">
          {{ item.propertyAddress.address || '-' }}
        </h6>
      </template>
      <template v-slot:[`item.checked`]="{ item }">
        <v-flex class="d-flex justify-content-center">
          <v-checkbox
            v-model="item.checked"
            @click.stop
            class="reset-all ma-0 mx-auto"
            dense
            hide-details
          />
        </v-flex>
      </template>
      <template v-slot:[`item.ownerName`]="{ item }">
        {{ item.ownerName || '-' }}
      </template>
      <template v-slot:[`item.number`]="{ item }">
        {{ item.number || '-' }}
      </template>
      <template v-slot:[`item.receivedRevenues`]="{ item }">
        {{ item.receivedRevenues || '-' }}
      </template>
      <template v-slot:[`item.owner`]="{ item }">
        {{ item.owner || '-' }}
      </template>
      <template v-slot:[`item.tenentName`]="{ item }">
        {{ item.tenentName || '-' }}
      </template>
      <template v-slot:[`item.moderatorName`]="{ item }">
        {{ item.moderatorName || '-' }}
      </template>
      <template v-slot:[`item.propertyName`]="{ item }">
        {{ item.propertyName || '-' }}
      </template>
      <template v-slot:[`item.unitNumber`]="{ item }">
        <h5 class="text-truncate max-w-100">
          {{ item.unitNumber || '-' }}
        </h5>
      </template>
      <template v-slot:[`item.contractTitle`]="{ item }">
        {{ item.contractTitle || '-' }}
      </template>
      <template v-slot:[`item.contractValue`]="{ item }">
        {{ item.contractValue || '-' }}
      </template>
      <template v-slot:[`item.lastClaimAmount`]="{ item }">
        {{ item.lastClaimAmount || '-' }}
      </template>
      <template v-slot:[`item.totalRemaining`]="{ item }">
        {{ item.totalRemaining || '-' }}
      </template>
      <template v-slot:[`item.taxAmount`]="{ item }">
        {{ item.totalTaxAmount ? item.totalTaxAmount : item.taxAmount || '-' }}
      </template>
      <template v-slot:[`item.debtor`]="{ item }">
        <p class="ma-0 font-14" :class="creditorDebtorClass(item, 'debtor')">
          {{ item.debtor || '-' }}
        </p>
      </template>
      <template v-slot:[`item.creditor`]="{ item }">
        <p class="ma-0 font-14" :class="creditorDebtorClass(item, 'creditor')">
          {{ item.creditor || '-' }}
        </p>
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <v-flex class="justify-content-center py-2">
          <h6 class="font-14 font ma-0 text-truncate">
            {{ item.title }}
          </h6>
        </v-flex>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span v-if="item.type">
          {{ item.type.nameAr || item.type || getAccountTypeNameEn(item.type) }}
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:[`item.reportsType`]="{ item }">
        <v-flex class="justify-conent-center">
          <h6 class="font-14 font ma-0 text-truncate">
            {{ getreportMainType(item.reportsType) }}
          </h6>
        </v-flex>
      </template>
      <template v-slot:[`item.revenues`]="{ item }">
        <div class="d-flex justify-space-between">
          <span
            class="min-w-40 ma-0 pa-0 d-flex justify-space-between"
            v-for="(obj, key) in item.revenues"
            :key="key"
          >
            <h6
              class="mx-2 font-14 ma-0 text-truncate"
              v-for="(val, key) in obj"
              :key="key"
            >
              {{ val }}
            </h6>
          </span>
        </div>
      </template>
      <template v-slot:[`item.moderator`]="{ item }">
        <v-flex class="d-flex justify-conent-center">
          <div class="align-center d-flex mx-auto">
            <h6
              class="lightGreen--tag bill font-14 ml-2 text-truncate font-weight-bold"
            >
              {{ $subString(item.moderator.name) }}
            </h6>
            <h6 class="font-14 font ma-0 text-truncate">
              {{ item.moderator.name || '-' }}
            </h6>
          </div>
        </v-flex>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <v-layout align-center>
          <h6 class="font-14 font ma-0 text-truncate mx-auto">
            {{ ToGregorianDateInArabic(item.createdAt) }}
          </h6>
        </v-layout>
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        <v-layout align-center>
          <h6 class="font-14 font ma-0 text-truncate mx-auto">
            {{ item.startDate }}
          </h6>
        </v-layout>
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        <v-layout align-center>
          <h6 class="font-14 font ma-0 text-truncate mx-auto">
            {{ item.endDate }}
          </h6>
        </v-layout>
      </template>
      <template v-slot:[`item.lastClaimDate`]="{ item }">
        <v-layout align-center>
          <h6 class="font-14 font ma-0 text-truncate mx-auto">
            {{ item.lastClaimDate }}
          </h6>
        </v-layout>
      </template>
      <template v-slot:[`item.claimDate`]="{ item }">
        <v-layout align-center>
          <h6 class="font-14 font ma-0 text-truncate mx-auto">
            {{ item.claimDate }}
          </h6>
        </v-layout>
      </template>
      <template v-slot:[`item.accessibleToClient`]="{ item }">
        <v-icon
          color="#662d91"
          v-if="item.accessibleToClient"
          @click.stop="$emit('changeVisibility', item)"
          >mdi-eye</v-icon
        >
        <v-icon
          color="#662d91"
          v-else
          @click.stop="$emit('changeVisibility', item)"
          >mdi-eye-off</v-icon
        >
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon color="danger" @click.stop="$emit('del-report', item._id)"
          >mdi-trash-can-outline</v-icon
        >
        <!-- <div class="my-auto mobile__ml__10px">
          <v-menu
            left
            content-class="dropdown-menu"
            offset-x
            transition="slide-x-transition"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="options-btn"
                color="lamb"
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-if="!isCustomer">mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="edit(item)"
                v-if="$hasPermission('users', 'reports', 'update')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>تعديل</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="$emit('del-report', item._id)"
                v-if="$hasPermission('users', 'reports', 'delete')"
              >
                <v-list-item-icon>
                  <v-icon color="danger">mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="danger--text"
                    >حذف</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>-->
      </template>
      <template v-slot:[`item.reportPeriod`]="{ item }">
        <h6 v-if="item.start && item.end" class="font-14 font ma-0">
          {{
            `${ToGregorianDateInArabic(item.start)} - ${ToGregorianDateInArabic(
              item.end
            )}`
          }}
        </h6>
        <h6 v-else class="font-14 font ma-0">-</h6>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'data-table-reports',
  mixins: [dateFormat],
  props: {
    isCustomer: {
      type: Boolean,
      default: false
    },
    tableClasses: {
      default: ''
    },
    totlaRowBtn: {
      default: false
    },
    totalRowData: {
      default() {
        return {}
      }
    },
    forPreviewing: {
      default: false
    },
    reports: {},
    gridView: {},
    headers: {},
    tableSm: {
      default: false
    },
    showselect: {
      props: false
    },
    reportsTypes: {
      default() {
        return []
      }
    },
    stopClicked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: [],
      search: '',
      page: 1,
      pageCount: 0,
      hectareLogo: require('@/assets/img/svgs/Union.svg'),
      creditorDebtorNonColoredText: ['0 ريال', '-', 0]
    }
  },
  methods: {
    itemRowBackground(item) {
      return `bg-${item.backgroundColor?.slice(1)}`
    },
    getAccountTypeNameEn(typeNameEn) {
      switch (typeNameEn) {
        case 'company':
          return 'شركه'
        case 'individual':
          return 'فرد'
        default:
          return 'حساب'
      }
    },
    getreportMainType(id) {
      const reportType = this.reportsTypes.find(
        (reportType) => reportType._id == id
      )
      return reportType?.categoryName ?? '-'
    },
    edit(i) {
      const data = i
      this.$parent.editReport(data)
    },
    handleClick(item) {
      if (!this.forPreviewing) {
        this.$emit('setReportId', item)
      }
    },
    creditorDebtorClass(item, key) {
      if (
        !this.creditorDebtorNonColoredText.includes(item?.[key]) &&
        item?.value
      ) {
        if (item.value > 0) {
          return 'red--text'
        }
        return 'green--text'
      }
      return ''
    }
  },
  watch: {
    totlaRowBtn: {
      handler(newVal) {
        if (newVal && Object.keys(this.totalRowData).length) {
          if (this.reports.indexOf(this.totalRowData) === -1) {
            this.reports.push(this.totalRowData)
          }
        } else {
          this.reports.pop()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.min-w-40 {
  min-width: 40%;
}
.totalTable > .v-data-table__wrapper > table > tbody > tr:last-child {
  background: rgba($primary-color, 0.07);
  td {
    color: $primary-color;
    font-weight: bold;
  }
}
.cursor__pointer {
  cursor: pointer;
}
.pinter__none {
  pointer-events: none;
}

.cursor-move {
  cursor: move;
}

// Report pdf table
.report_pdf_table {
  min-height: 810px;
  & .hectar-table {
    .v-data-table__wrapper {
      table {
        padding-top: 30px;
        thead {
          &.v-data-table-header {
            background: #67328e !important;
            tr {
              th {
                span,
                i {
                  color: #fff;
                }
              }
            }
          }
        }
        // thead
        tbody {
          tr {
            &:nth-child(even) {
              background: #ededed !important;
            }
            td {
              border-radius: 0 !important;
              text-align: center !important;
            }
          }
        }
        // tbody
      }
      // table
    }
  }
}
h6 {
  font-weight: 400;
}

@media screen and (min-width: 960px) {
  .hectar-table th:first-child,
  td:first-child div {
    width: 150px !important;
  }
}

@media screen and (max-width: 959px) {
  .hectar-table th,
  td {
    min-width: 150px !important;
  }
}
</style>
