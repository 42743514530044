<template>
  <!-------------------New-Agent-Form------------------->
  <NewAgentForm
    v-if="dialogState"
    ref="newAgentForm"
    :clientModel="true"
    :isDisabled="false"
    :phoneNumberSuccessMesage="phoneNumberSuccessMesage"
    :phoneNumberErrorMessage="phoneNumberErrorMessage"
    :checkPhoneNumberLoader="checkPhoneNumberLoader"
    :isValidPhone="isValidPhone"
    :defaultUser="clientData"
    @checkUserExistance="checkUserExistance"
    @onValidatePhoneNumber="onValidatePhoneNumber"
    @new_user_added="newUserAdded"
  />
</template>
<script>
import { userService } from '@/services'
import { mapState } from 'vuex'
import NewAgentForm from '@/components/common/new-agent/NewAgentForm'

export default {
  name: 'clientDetails',
  props: {
    clientData: {
      default() {
        return {}
      }
    },
    dialogState: {
      default: false
    },
    checkPhoneNumberLoader: {
      default: false
    }
  },
  components: { NewAgentForm },
  data() {
    return {
      phoneData: {},
      phoneNumberErrorMessage: '',
      phoneNumberSuccessMesage: '',
      isValid: false
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    isValidPhone() {
      return (
        (this.phoneData?.isValid && this.phoneData?.type === 'mobile') ?? false
      )
    }
  },
  watch: {
    dialogState() {
      this.phoneNumberErrorMessage = ''
      this.phoneNumberSuccessMesage = ''
      this.phoneData = {}
      this.isValid = false
    }
  },
  methods: {
    async checkUserExistance(phoneNumber) {
      phoneNumber = phoneNumber.replace(/ /g, '')
      if (phoneNumber === this.clientData?.phoneNumber?.replace('966', '0')) {
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = ''
      } else if (!this.isValidPhone) {
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = 'من فضلك تأكد من رقم الهاتف'
      } else if (this.isValidPhone) {
        this.$emit('setCheckPhoneNumberLoader', true)
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = ''
        phoneNumber = phoneNumber.replace('0', '966')
        try {
          const response = await userService.checkClient({
            environment: this.currentEnv._id,
            phoneNumber
          })
          if (response.data.available) {
            this.phoneNumberSuccessMesage = 'العميل متاح للإضافه'
            this.phoneNumberErrorMessage = null
          }
        } catch {
          this.phoneNumberErrorMessage = ' الرقم مُسجل بإسم عميل أخر'
        } finally {
          this.$emit('setCheckPhoneNumberLoader', false)
        }
      }
    },
    onValidatePhoneNumber(val) {
      this.phoneData = val
    },
    newUserAdded(val) {
      this.$emit('newUserAdded', val)
    }
  }
}
</script>
