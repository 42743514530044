<template>
  <v-row class="conversations flex-column">
    <!-- Cards for comments -->
    <v-col class="conversations__cards__wrapper">
      <ConversationsCard v-for="i in 2" :key="i" class="mt-3" />
    </v-col>

    <!-- Write new comment -->
    <v-col class="conversations__send mb-1">
      <v-form @submit.prevent="addNewComment">
        <v-textarea
          filled
          v-model="comment"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="addNewComment"
          placeholder="أدخل تعليقك هنا"
          auto-grow
          hide-details
          class-content="font-12"
          no-resize
          rows="1"
        >
          <template v-slot:append>
            <img
              alt="add comment"
              :class="[
                'mt-md-1 mx-2',
                'pointerCursor',
                { 'disable-add-comment': addCommentLoading }
              ]"
              src="@/assets/img/svgs/send.svg"
              loading="lazy"
            />
          </template>
        </v-textarea>
      </v-form>
    </v-col>
  </v-row>
</template>

<style scoped>
.conversations .v-select .v-input__control .v-input__slot,
.conversations .v-text-field--filled .v-input__control .v-input__slot {
  border-radius: 1.125rem !important;
}
</style>
<script>
import ConversationsCard from '@/components/associations/ConversationsCard'
export default {
  name: 'Conversations',
  data() {
    return {
      comment: '',
      addCommentLoading: false
    }
  },
  components: {
    ConversationsCard
  },
  methods: {
    addNewComment() {}
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/associations/associations.scss';
</style>
