<template>
  <v-dialog max-width="850" persistent v-model="dialogState_">
    <v-card class="add-new-property">
      <v-card-title
        class="cardTitle pa-0 px-4 mb-4 d-flex justify-space-between custom__shadow"
      >
        <h4>إضافة وظيفة</h4>

        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container>
          <v-row no-gutters>
            <v-col class="pl-3" cols="12" sm="6">
              <h6 class="mb-2 mt-0">القسم</h6>
              <v-select
                ref="role"
                :items="departmnets"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'من فضلك اختر القسم']"
                color="black"
                dense
                item-text="name"
                item-value="_id"
                filled
                hide-details
                placeholder="القسم"
                required
                v-model="departmentId"
              ></v-select>
            </v-col>
            <v-col class="pl-3 pb-0" cols="12" sm="6">
              <h6 class="mb-2 mt-0">المسمى الوظيفي</h6>
              <v-text-field
                v-model="titleName"
                placeholder="ادخل اسم المسمى الوظيفي"
                dense
                filled
                hide-details
                class="font-14"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="cardActions pa-1 mt-4 pl-5">
        <!-- :disabled="!isValid || !selectedPermissions.length" -->
        <!-- :loading="isLoading" -->
        <!-- @click="editMemberId ? updateTeamMember() : addTeamMember()" -->
        <v-spacer />

        <v-btn
          data-test="sendInvite"
          class="border-1 ma-0 rounded-7 my-3 mr-3 px-5"
          color="primary"
          @click="handleAction"
        >
          <span class="mb-2 ml-1 font-weight-bold">
            {{ editTitleName._id ? 'تعديل' : 'اضافة' }}
          </span>
          <v-icon class="send-icon">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EnvService } from '@/services'
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    editTitleName: {
      type: Object,
      required: true
    },
    departmnets: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      departmentId: null,
      titleName: null
    }
  },
  watch: {
    editTitleName: {
      handler(newVal) {
        this.titleName = newVal.name
        this.departmentId = newVal.departmentId
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(value) {
        this.$emit('changeDialogState', value)
      }
    }
  },

  methods: {
    ...mapMutations('appState', ['addNotification']),

    openDialog() {
      this.dialogState_ = true
    },
    closeDialog() {
      this.dialogState_ = false
    },
    async handleAction() {
      this.editTitleName._id ? this.updateJobTitle() : this.addJobTitle()
    },
    async addJobTitle() {
      try {
        await EnvService.addJobTitle({
          name: this.titleName,
          departmentId: this.departmentId,
          environmentId: this.currentEnv._id
        })
        this.addNotification({
          type: 'success',
          message: 'تمت إضافة المسمي الوظيفي بنجاح'
        })
      } catch {
        this.addNotification({
          type: 'error',
          message: 'لم يتم اضافة مسمي وظيفي'
        })
      } finally {
        this.closeDialog()
        this.$emit('changeDialogState', false)
      }
    },
    async updateJobTitle() {
      try {
        await EnvService.editJobTitle(this.editTitleName._id, {
          name: this.titleName,
          departmentId: this.departmentId
        })
        this.addNotification({
          type: 'success',
          message: 'تم تعديل المسمي الوظيفي بنجاح'
        })
      } catch {
        this.addNotification({
          type: 'error',
          message: 'لم يتم تعديل المسمي الوظيفي'
        })
      } finally {
        this.closeDialog()
        this.$emit('changeDialogState', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
