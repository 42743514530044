<template>
  <div class="mt-5">
    <!-- <modelUint
      v-if="team"
      v-bind="unitModelProps"
      @openNotificationModal="openNotificationModal(notification, 0)"
      @notificationsChanged="saveTeamNotifications"
    /> -->
    <CustomerNotificationCard
      v-for="(notification, i) in notificationsSettings"
      :key="notification._id"
      :notification="notification"
      :notificationIndex="i"
      :isChangeProcessFailed="isChangeNotificationFailed"
      :isNotificationLoading="isNotificationLoading"
      @openNotificationModal="openNotificationModal(notification, i)"
      @notificationsChanged="saveTeamNotifications"
    />
    <NotificationsModelsEdit
      v-if="dialogState"
      v-bind="notificationsModelProps"
      @notificationsChanged="saveTeamNotifications"
      @closeNotificationModal="closeNotificationModal"
    />
  </div>
</template>

<script>
import switchButtons from '@/constans/notifications/index.js'
import { messageService } from '@/services'
import { mapMutations, mapState } from 'vuex'
import CustomerNotificationCard from './CustomerNotificationCard.vue'
import modelUint from './modelUint'

export default {
  name: 'InternalNotifications',

  props: {
    template: {
      type: Object,
      required: true
    },
    team: {
      type: Boolean,
      default: false
    }
  },
  components: {
    modelUint,
    CustomerNotificationCard,
    NotificationsModelsEdit: () =>
      import('@/components/profile/NotificationsModelsEdit')
  },
  data() {
    return {
      switchButtons,
      submitBtnIsLoading: false,
      dialogState: false,
      isChangeNotificationFailed: false,
      isNotificationLoading: false,
      notificationsSettings: {},
      notification: {},
      activeNotificationIndex: 0
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    getNotificationType() {
      return this.team ? 'team' : 'customer'
    },
    unitModelProps() {
      return {
        // notificationsSettings: this.notificationsSettings[0],
        isChangeProcessFailed: this.isChangeNotificationFailed,
        type: 'notification',
        template: this.template,
        icon: require('@/assets/img/svgs/team-notification-icon.svg')
      }
    },
    notificationsModelProps() {
      return {
        submitBtnIsLoading: this.submitBtnIsLoading,
        dialogState: this.dialogState,
        notificationsSettings:
          this.notificationsSettings[this.activeNotificationIndex],
        team: this.team,
        activeNotification: this.activeNotificationIndex,
        switchButtons: this.switchButtons
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getNotificationPaylod(payload) {
      const restructurePayload = {
        __type: this.getNotificationType,
        status: payload.notification.status,
        attachment: payload.notification.attachment,
        viaSMS: payload?.fromModal
          ? payload.notification.viaSMS
          : payload.notification.status,
        viaPlatform: payload?.fromModal
          ? payload.notification.viaPlatform
          : payload.notification.status,
        viaEmail: payload?.fromModal
          ? payload.notification.viaEmail
          : payload.notification.status,
        ...(payload?.notification?.includeLink !== undefined && {
          includeLink: payload.notification.includeLink
        })
      }
      return restructurePayload
    },
    async saveTeamNotifications(payload) {
      try {
        this.submitBtnIsLoading = true
        this.isChangeNotificationFailed = false

        const notificationsPayload = this.getNotificationPaylod(payload)

        if (payload.notification.category) {
          notificationsPayload.contentType = payload.notification.contentType
          notificationsPayload.content = payload.notification.content
        }
        if (
          payload.notification.category === 'financial' ||
          payload.notification.category === 'contract' ||
          payload.notification.category === 'bonds'
        ) {
          notificationsPayload.method = payload.notification.method
          notificationsPayload.days = payload.notification.days
          notificationsPayload.includeAssignmentLink =
            payload.notification.includeAssignmentLink

          const requiredSettingOpt = this.switchButtons.find((opt) => {
            return opt?.isOnlyFinancial === true
          })

          requiredSettingOpt.children.map((sign) => {
            notificationsPayload[`${sign.key}`] =
              payload.notification[`${sign.key}`]
          })
        }

        await messageService.updateNotifications(
          payload.notification._id,
          notificationsPayload
        )

        if (payload.fromModal) {
          this.closeNotificationModal()
        }

        this.addNotification({
          text: `تم تعديل إشعارات ${this.team ? 'الفريق' : 'العملاء'} بنجاح`,
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: `خطأ في تعديل إشعارات ${
            this.team ? 'الفريق' : 'العملاء'
          } الرجاء المحاولة مجددا`,
          color: 'error'
        })
        this.isChangeNotificationFailed = true
      } finally {
        this.submitBtnIsLoading = false
        this.getNotifications()
      }
    },
    async getNotifications() {
      try {
        this.isNotificationLoading = true
        const payload = {
          environmentId: this.currentEnv._id,
          __type: this.getNotificationType
        }

        const { data } = await messageService.getNotifications(payload)

        this.notificationsSettings = data.notificationSetting
        this.isNotificationLoading = false
      } catch {
        this.addNotification({
          text: 'حدث خطأ اثناء تحميل الإشعارات، الرجاء المحاولة مجددا',
          color: 'error'
        })
      }
    },
    openNotificationModal(notification, index) {
      this.notification = notification
      this.activeNotificationIndex = index
      this.dialogState = true
    },
    closeNotificationModal() {
      this.dialogState = false
      this.notification = {}
    }
  },
  created() {
    this.getNotifications()
  }
}
</script>
