<template>
  <div>
    <Loader v-bind="loaderProps" v-if="isPageLoading" />
    <InstallmentsHeader
      v-else-if="!isPageLoading"
      :contractInfo="installmentsHeader"
    />
    <v-dialog
      :ref="`item`"
      v-model.lazy="showInstModal"
      width="320px"
      style="display: inline-block"
    >
      <v-date-picker
        color="primary"
        scrollable
        v-model="selectedItem.date"
        @input="getSelectedDate"
      >
        <!-- :allowed-dates="allowedDates" -->
        <v-spacer></v-spacer>
        <v-btn @click="showInstModal = false" color="primary" text>
          الغاء
        </v-btn>
        <v-btn @click="setDateInRow(selectedItem)" color="primary" text
          >حفظ
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-data-table
      v-if="!isPageLoading"
      :headers="headers"
      :items="testInst"
      :items-per-page="testInst.length"
      item-key="end"
      hide-default-footer
      mobile-breakpoint="0"
      class="hectar-table noScroll"
    >
      <template v-slot:[`item.date`]="{ item }">
        <h6
          class="font-14 font-weight-medium ma-0"
          style="display: inline-block"
        >
          {{ formatDate(item.date) }}
        </h6>
        <v-icon
          v-if="isEditMood"
          class="mr-2 font-14"
          style="cursor: pointer"
          @click="openModal(item)"
          >mdi-pencil-outline</v-icon
        >
      </template>
      <template v-slot:[`item.payment`]="{ item }">
        <div class="d-flex pointerCursor">
          <div v-if="item.id === activeInput" class="d-flex align-center">
            <v-text-field
              v-model="item.payment"
              :key="item.id"
              class="font-14"
              @blur="totalPaymentId = 0"
              @keydown="$formatNumberInputs($event, true)"
            />
            <v-icon
              @click="activeInput = null"
              class="mx-2 font-14 font-bold pointerCursor"
              >mdi-close</v-icon
            >
          </div>
          <h6 class="font-14 font-weight-medium ma-0 mx-auto" v-else>
            {{ formattingNumbers(item.payment) }}
            <v-icon
              v-if="isEditMood"
              class="mr-2 font-14 cursor-pointer"
              @click="togglePayment(item)"
              >mdi-pencil-outline</v-icon
            >
          </h6>
        </div>
      </template>
      <template v-slot:[`item.taxAmount`]="{ item }">
        <h6 class="font-14 font-weight-medium ma-0">
          {{ formattingNumbers(item.taxAmount) }}
        </h6>
      </template>
      <template v-slot:[`item.total`]="{ item }">
        <h6 class="font-14 font-weight-medium ma-0">
          {{ formattingNumbers(item.total) }}
        </h6>
      </template>
      <template
        v-slot:[`item.tax`]="{ item }"
        v-if="clonedContractInfo.hasCombinedRent"
      >
        <h6 class="font-14 font-weight-medium ma-0">
          {{ formattingNumbers(item.fixedPaymentTaxAmount) }}
        </h6>
      </template>
      <template
        v-slot:[`item.fixedPayment`]="{ item }"
        v-if="clonedContractInfo.hasCombinedRent"
      >
        <div
          v-if="item.id === activeFixedAmountInput"
          class="d-flex align-center"
        >
          <v-text-field
            v-model="item.fixedPayment"
            :key="item.id"
            class="font-14"
            @blur="totalPaymentId = 0"
            @keydown="$formatNumberInputs($event, true)"
          />
          <v-icon
            @click="activeFixedAmountInput = null"
            class="mx-2 font-14 font-bold pointerCursor"
            >mdi-close</v-icon
          >
        </div>
        <h6 class="font-14 font-weight-medium ma-0" v-else>
          {{ formattingNumbers(item.fixedPayment) }}
          <v-icon
            v-if="isEditMood"
            class="mr-2 font-14 cursor-pointer"
            @click="toggleFixedPayment(item)"
            >mdi-pencil-outline</v-icon
          >
        </h6>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <h6
          class="font-14 font-weight-medium ma-0"
          style="cursor: pointer"
          @click="deleteRecord(item)"
        >
          <v-icon color="danger">mdi-trash-can-outline</v-icon>
        </h6>
      </template>
    </v-data-table>

    <v-row
      v-if="!isEditMood"
      class="my-2 d-flex px-5"
      style="justify-content: space-between"
    >
      <v-btn
        @click="resetContractToOriginal"
        class="mb-4 rounded-7"
        outlined
        color="primary"
      >
        إعادة تعيين
      </v-btn>
      <v-btn
        @click="editContract"
        class="py-1 px-8 rounded-7 font-weight-bold"
        color="general"
        outlined
        >تعديل</v-btn
      >
    </v-row>
    <v-row v-else class="my-2 d-flex justify-between px-5 align-center">
      <div style="display: flex; align-items: center; gap: 0.5rem">
        <v-btn
          @click="resetContractToOriginal"
          class="py-1 px-4 rounded-7 font-weight-bold"
          outlined
          color="primary"
        >
          إعادة تعيين
        </v-btn>
        <v-btn
          @click="addInstallment"
          class="py-1 px-4 rounded-7 font-weight-bold"
          color="general"
          outlined
        >
          <span>إضافة دفعة </span>
        </v-btn>
      </div>
      <div>
        <span class="ml-2 font-weight-bold" style="color: #662d91"
          >المتبقى</span
        >
        <span
          :class="`ml-2 font-weight-bold ${
            additionalCost < 0 ? 'red--text' : 'green--text'
          }`"
          >{{ additionalCost.toFormat(2) }} ريال</span
        >
      </div>
      <v-btn
        @click="saveEdit"
        class="py-1 px-8 rounded-7 font-weight-bold"
        color="general"
        :disabled="
          !additionalCost.isEqualTo(0) ||
          isThereEmptyInsts ||
          isThereDuplicateDates ||
          isThereInRange
        "
        outlined
        >حفظ</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import Loader from '@/components/helper/loader.vue'
import dateFormat from '@/mixins/dateFormat.js'
import { contractsService } from '@/services'
import { convertNumbers2English, ordinalsAr } from '@/utils/formatters'
import BN from 'bignumber.js'
import { isBefore } from 'date-fns'
import { mapMutations } from 'vuex'
import InstallmentsHeader from './HeaderInstallments.vue'

export default {
  name: 'contractFlexibleInstallments',
  mixins: [dateFormat],
  props: {
    updatedContract: {
      default() {
        return {}
      }
    },
    updateInstasId: {
      type: Number,
      required: false
    },
    newFixedAmountData: {
      type: Object,
      required: false
    },
    exclamation: {
      default: require('@/assets/img/svgs/exclamationMark.svg')
    }
  },
  components: { Loader, InstallmentsHeader },
  data() {
    return {
      clonedContractInfo: {},
      contractTypes: [],
      subContractTypes: [],
      testInst: [],
      showInstModal: false,
      selectedItem: {},
      installmentsHeader: {},
      isValid: false,
      isEditMood: false,
      isPageLoading: true,
      availableDates: [],
      selectedContractType: {},
      activeInput: null,
      activeFixedAmountInput: null,
      activeTaxInput: null,
      netContractValue: null,
      utilitiesTotal: null,
      headersArray: [
        { text: '', value: 'data-table-expand' },
        {
          text: 'التفاصيل',
          align: 'start',
          sortable: false,
          value: 'number'
        },
        {
          text: 'تاريخ الإستحقاق',
          value: 'date',
          width: 100,
          align: 'center',
          sortable: false
        },
        {
          text: 'قيمه الايجار',
          value: 'payment',
          align: 'center',
          sortable: false
        },
        {
          text: 'الضريبة ',
          value: 'taxAmount',
          align: 'center',
          sortable: false
        },
        { text: 'الإجمالي', value: 'total', align: 'center', sortable: false }
      ],
      insts: [],
      totalPayment: BN(0),
      additionalCost: BN(0),
      payment: 0,
      totalPaymentId: '',
      installmentPaymentId: '',
      installmentPaymentNumber: '',
      isThereEmptyInsts: false,
      isRangeOpened: false,
      intervalPatch: {},
      NewInstsPayment: {},
      installmentsStartDates: [],
      isThereDuplicateDates: false,
      isThereInRange: false,
      isMoreThenTotalPayment: false,
      maxPaymentAmountForRaw: 0
    }
  },

  watch: {
    isEditMood(val) {
      if (val && !this.clonedContractInfo.hasCombinedRent) {
        this.headersArray.push({
          text: '',
          value: 'action',
          align: 'center',
          sortable: false
        })
        this.isValid = false
      } else if (!val && !this.clonedContractInfo.hasCombinedRent) {
        this.headersArray.pop()
        this.isValid = true
      } else if (val && this.clonedContractInfo.hasCombinedRent) {
        this.isValid = false
      }
    },
    isValid(val) {
      this.$emit('setValdationStatus', val)
    },
    updateInstasId(id) {
      if (id || id === 0) {
        const { fixedPayment, ...data } = this.newFixedAmountData
        this.insts[id].fixedDetails = { ...data }
        this.insts[id].fixedPayment = fixedPayment
      }
    },
    insts: {
      handler(val) {
        this.testInst = val
        this.testInst.forEach((e, i) => {
          const tax = this.calculateTax(
            new Date(this.clonedContractInfo.start),
            new Date(e.date)
          )
          e.date = e.date.substr(0, 10)
          e.number = `الدفعة ${ordinalsAr(i + 1)}`
          e.id = i + 1
          if (this.clonedContractInfo.tax) {
            e.tax = tax
            e.taxAmount = (e.payment * e.tax) / 100
          }
          if (
            this.clonedContractInfo.hasCombinedRent &&
            this.clonedContractInfo.utilitiesTax
          ) {
            e.fixedPaymentTaxAmount = (e.fixedPayment * tax) / 100
          }
        })
        this.installmentsStartDates = this.testInst.map((e) => e.date)
        const isEmptyDate = this.testInst.some((e) => !e.date)
        if (isEmptyDate) {
          this.isThereEmptyInsts = true
        } else {
          this.isThereEmptyInsts = false
        }
        this.restTotalPayment()

        this.testInst.forEach((installment) => {
          // calculate total payment for all installments
          this.totalPayment = this.totalPayment.plus(installment.total)
          if (this.totalPayment.isGreaterThan(this.netContractValue)) {
            this.isValid = false
            this.isMoreThenTotalPayment = true
          }
          this.additionalCost = this.totalPayment.minus(this.netContractValue)
          if (installment.id === this.activeInput) {
            this.NewInstsPayment[installment.number] = {
              ...this.NewInstsPayment[installment.number]
            }
          }
          this.NewInstsPayment[installment.number] = installment.payment =
            !!installment.payment
              ? convertNumbers2English(installment.payment)
              : '0'
          installment.payment = !!installment.payment
            ? convertNumbers2English(installment.payment)
            : 0
          installment.total =
            Number(installment.payment ?? 0) +
            Number(installment.taxAmount ?? 0) +
            Number(installment.fixedPayment ?? 0) +
            Number(installment.fixedPaymentTaxAmount ?? 0)

          // Number.EPSILON to handle floating point precision issues
          this.additionalCost = BN(
            Math.round(
              (this.additionalCost.toNumber() + Number.EPSILON) * 100
            ) / 100
          )
        })
      },
      deep: true
    }
  },
  computed: {
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    headers() {
      const fixedPayment = [
        ...this.headersArray,
        {
          text: 'المبالغ الثابته ',
          value: 'fixedPayment',
          align: 'center',
          sortable: false
        },
        { text: 'الضريبة ', value: 'tax', align: 'center', sortable: false },
        { text: 'الإجمالي', value: 'total', align: 'center', sortable: false }
      ]
      fixedPayment.splice(5, 1)
      if (this.isEditMood) {
        fixedPayment.push({
          text: '',
          value: 'action',
          align: 'center',
          sortable: false
        })
      }
      return this.clonedContractInfo.hasCombinedRent
        ? fixedPayment
        : this.headersArray
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    allowedDates(val) {
      const testDate = this.testInst.map((e) => e.date)
      return !testDate.includes(val)
    },
    getSelectedDate(selectedDate) {
      this.checkIsThereDuplicateDates(selectedDate)
    },
    openModal(item) {
      this.selectedItem = item
      this.showInstModal = true
    },
    calculateTax(contractStartDate, installmentDate) {
      let taxPercentage = 0
      if (isBefore(contractStartDate, new Date('Jan 1 2018'))) {
        return taxPercentage
      }
      switch (true) {
        case isBefore(installmentDate, new Date('Jul 1 2020')):
          taxPercentage = 5
          break
        // Starting from Jul 1 2020
        default:
          taxPercentage = 15
          break
      }
      return taxPercentage
    },
    addInstallment() {
      this.testInst.push({
        date: '',
        payment: 0,
        tax: this.testInst.at(-1)?.tax ?? 0,
        number: `الدفعة ${ordinalsAr(this.testInst.length + 1)}`,
        taxAmount: this.testInst.at(-1)?.taxAmount ?? 0,
        id: this.testInst.length + 1,
        fixedPayment: 0,
        fixedPaymentTaxAmount: this.testInst.at(-1)?.fixedPaymentTaxAmount ?? 0,
        total: 0
      })
      this.restTotalPayment()
    },
    checkIsThereDuplicateDates(enterDate, isDelete = false) {
      const testDate = this.installmentsStartDates
      this.checkIsTheSelectedDateIsOutOfRange(enterDate)
      if (testDate.includes(enterDate) && !isDelete) {
        this.addNotification({
          color: 'error',
          text: 'هذا التاريخ تم إختياره من قبل'
        })
        this.isThereDuplicateDates = true
      } else {
        this.isThereDuplicateDates = false
      }
    },
    checkIsTheSelectedDateIsOutOfRange(enterDate, isDeleteDate = false) {
      if (!enterDate) {
        return
      }
      const startDate = this.clonedContractInfo.start
      const endDate = this.clonedContractInfo.end
      if ((enterDate < startDate || enterDate > endDate) && !isDeleteDate) {
        this.addNotification({
          color: 'error',
          text: 'هذا التاريخ خارج نطاق مدة العقد'
        })
        this.isThereInRange = true
      } else {
        this.isThereInRange = false
      }
    },
    deleteRecord(item) {
      this.testInst = this.testInst.filter((e) => e.number !== item.number)
      this.restTotalPayment()
      this.checkIsThereDuplicateDates(item.date, true)
      this.checkIsTheSelectedDateIsOutOfRange(item.date, true)
      // update insts
      this.updateInst()
    },
    resetContractToOriginal() {
      // reset Installments back to original
      this.getInstallmentsPreview(this.clonedContractInfo)
    },
    bindContractData() {
      const insts = this.insts.map((list) => {
        return {
          date: list.date,
          payment: Number(list.payment),
          tax: Number(list.tax),
          taxAmount: Number(list.taxAmount),
          fixedPayment: list.fixedPayment,
          fixedDetails: list.fixedDetails
        }
      })
      this.clonedContractInfo = { ...this.clonedContractInfo, insts }
      this.$emit('bindContractData', this.clonedContractInfo)
    },
    restTotalPayment() {
      this.totalPayment = BN(0)
    },
    setValdationStatus() {
      this.$emit('resetData')
      this.$nextTick(() => {
        this.isValid = true
        this.$emit('setValdationStatus', this.isValid)
      })
    },
    presetData() {},
    async getInstallmentsPreview(contractInfo = {}) {
      this.clonedContractInfo = { ...contractInfo }
      this.isPageLoading = true

      if (this.clonedContractInfo?.hasTax) {
        this.clonedContractInfo.hasTax = 15
      }

      try {
        const body = {
          rentValue: this.clonedContractInfo.rentValue,
          tax: this.clonedContractInfo.tax || this.clonedContractInfo?.hasTax,
          duration: this.clonedContractInfo.duration,
          collectionInterval: this.clonedContractInfo.collectionInterval,
          increase: this.clonedContractInfo.increase,
          increaseType: this.clonedContractInfo.increaseType,
          start: this.clonedContractInfo.start,
          end: this.clonedContractInfo.end,
          hasCombinedRent: this.clonedContractInfo.hasCombinedRent,
          utilitiesTax: this.clonedContractInfo?.utilitiesTax ?? null,
          utilities: {
            water: this?.clonedContractInfo?.water ?? null,
            gas: this.clonedContractInfo?.gas ?? null,
            electricity: this.clonedContractInfo?.electricity ?? null,
            generalServices: this.clonedContractInfo?.generalServices ?? null
          }
        }
        const { data } = await contractsService.getInstallmentsPreview(body)
        this.insts = data.installments
        this.installmentsHeader = {
          netContractValue: data.netContractValue,
          rent: data.rent,
          rentTax: data.rentTax,
          utilitiesTotal: data.utilitiesTotal,
          utilitiesTotalTax: data.utilitiesTotalTax
        }
        this.netContractValue = data.netContractValue
        this.isEditMood = false
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل الاستحقاقات',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    editContract() {
      this.isEditMood = true
    },
    saveEdit() {
      this.isEditMood = false
      this.checkIsTotalIsEqualNetContractValue()
      this.activeInput = null
      this.activeFixedAmountInput = null
    },
    checkIsTotalIsEqualNetContractValue() {
      this.isTotalEqualNetContractValue =
        this.totalPayment.toFixed(2) === this.netContractValue.toFixed(2)
      this.isValid = this.isTotalEqualNetContractValue
      this.$emit('setValdationStatus', this.isValid)
    },
    togglePayment(item) {
      this.activeInput = item.id
    },
    toggleFixedPayment(item) {
      this.$emit('openFixedAmountModal', { id: item.id, data: item })
    },
    setDateInRow(item) {
      this.$refs.item.save(item.date)
      // sorting by date
      this.testInst.sort((a, b) => new Date(a.date) - new Date(b.date))
      // reorder number name
      this.testInst.forEach((inst, index) => {
        inst.number = `الدفعة ${ordinalsAr(index + 1)}`
      })
      this.updateInst()
    },
    updateInst() {
      this.insts = this.testInst
      this.clonedContractInfo.insts = this.insts
    },
    resetNewInstsPayment() {
      this.NewInstsPayment = {}
    },
    sumObjectValues(obj) {
      let sum = 0
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          sum += Number(obj[key])
        }
      }
      return sum
    },
    formattingNumbers(num) {
      return parseFloat(num).toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    }
  },
  async created() {
    this.setValdationStatus()
  }
}
</script>

<style lang="scss">
.hectar-table.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
