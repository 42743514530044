var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"740px","persistent":""},model:{value:(_vm.dialogState_),callback:function ($$v) {_vm.dialogState_=$$v},expression:"dialogState_"}},[_c('v-card',{staticClass:"add-new-property pa-0"},[_c('v-card-title',{staticClass:"pa-0 px-5 mb-5 d-flex justify-space-between custom__shadow"},[_c('h4',{staticClass:"ma-0"},[_vm._v("إضافة مهمة")]),_c('v-btn',{staticClass:"ma-0 mt-2",attrs:{"color":"grey","fab":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.closeDialog.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"px-5 pb-3"},[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-3 mobile__pl__0 mb-2",attrs:{"cols":"12","lg":"12","sm":"6"}},[_c('h6',{staticClass:"mb-2 font-14 font-weight-bold black--text"},[_vm._v("\n                أسم المهمة\n                "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('v-text-field',{staticClass:"mb-0 pa-0",attrs:{"filled":"","placeholder":"ادخل أسم المهمة","dense":"","hide-details":""},model:{value:(_vm.addTask.name),callback:function ($$v) {_vm.$set(_vm.addTask, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"addTask.name"}})],1),_c('v-col',{staticClass:"pl-3 mobile__pl__0 mb-2",attrs:{"cols":"12","sm":"6"}},[_c('h6',{staticClass:"mb-2 font-14 font-weight-bold black--text"},[_vm._v("\n                التصنيف\n                "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('v-select',{attrs:{"filled":"","items":_vm.categories,"item-text":"nameAr","item-value":"code","return-object":"","placeholder":"التصنيف","hide-details":"","dense":"","append-icon":"mdi-chevron-down","menu-props":{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }},model:{value:(_vm.addTask.category),callback:function ($$v) {_vm.$set(_vm.addTask, "category", $$v)},expression:"addTask.category"}})],1),_c('v-col',{staticClass:"pl-3 mobile__pl__0 mb-2",attrs:{"cols":"12","sm":"6"}},[_c('h6',{staticClass:"mb-2 font-14 font-weight-bold black--text"},[_vm._v("\n                النوع\n                "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('v-select',{attrs:{"filled":"","items":_vm.taskTypes,"item-text":"nameAr","item-value":"code","return-object":"","placeholder":"نوع المهمة","hide-details":"","dense":"","append-icon":"mdi-chevron-down","menu-props":{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }},model:{value:(_vm.addTask.type),callback:function ($$v) {_vm.$set(_vm.addTask, "type", $$v)},expression:"addTask.type"}})],1),(!_vm.isCustomer)?_c('v-col',{staticClass:"pl-3 mobile__pl__0 mb-2",attrs:{"cols":"12","sm":"6"}},[_c('h6',{staticClass:"mb-2 font-14 font-weight-bold black--text"},[_vm._v("\n                إسم العميل\n                "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('autocomplete',{attrs:{"rules":[(v) => !!v || 'من فضلك قم بإختيار إسم العميل'],"filled":"","items":_vm.allClients,"item-text":"name","item-value":"_id","return-object":true,"placeholder":"إسم العميل","hide-details":"","dense":"","append-icon":"mdi-chevron-down","menu-props":{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }},on:{"nextPageTrigger":_vm.getClientNextData,"emitSearchKey":_vm.getClientDataBySearchKey,"input":_vm.loadClientProperties},model:{value:(_vm.addTask.client),callback:function ($$v) {_vm.$set(_vm.addTask, "client", $$v)},expression:"addTask.client"}})],1):_vm._e(),_c('v-col',{staticClass:"pl-3 mobile__pl__0 mb-2",attrs:{"cols":"12","sm":"6"}},[_c('h6',{staticClass:"mb-2 font-14 font-weight-bold black--text"},[_vm._v("\n                إسم العقار\n                "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('autocomplete',{attrs:{"noData":_vm.noDataInTheListText,"rules":[(v) => !!v || 'من فضلك قم بإختيار إسم العقار'],"filled":"","items":_vm.allProperties,"disabled":_vm.isCustomer ? false : !_vm.addTask.client,"loading":_vm.realEstateLoading,"item-text":"propertyName","item-value":"_id","return-object":"","placeholder":"إسم العقار","hide-details":"","dense":""},on:{"nextPageTrigger":_vm.getPropertyNextData,"emitSearchKey":function($event){return _vm.getPropertyDataBySearchKey($event)}},model:{value:(_vm.addTask.realEstate),callback:function ($$v) {_vm.$set(_vm.addTask, "realEstate", $$v)},expression:"addTask.realEstate"}})],1),(!_vm.isCustomer)?_c('v-col',{staticClass:"pl-3 mobile__pl__0 mb-2",attrs:{"cols":"12","sm":"6"}},[_c('h6',{staticClass:"mb-2 font-14 font-weight-bold black--text"},[_vm._v("\n                المسؤول\n                "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('v-select',{attrs:{"filled":"","items":_vm.allEmployees,"item-text":"name","item-value":"_id","multiple":"","return-object":"","placeholder":"المسؤول","clearable":"","hide-details":"","dense":"","append-icon":"mdi-chevron-down","menu-props":{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center font-14 grey--text"},[_c('span',[_vm._v("لم يتم تحميل اي بيانات")])])]},proxy:true}],null,false,2396565711),model:{value:(_vm.addTask.assignedTo),callback:function ($$v) {_vm.$set(_vm.addTask, "assignedTo", $$v)},expression:"addTask.assignedTo"}})],1):_vm._e(),(!_vm.isCustomer)?_c('v-col',{staticClass:"pl-3 mobile__pl__0 mb-2",attrs:{"cols":"12","sm":"6"}},[_c('h6',{staticClass:"mb-2 font-14 font-weight-bold black--text"},[_vm._v("\n                الأولوية\n                "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('v-select',{attrs:{"rules":[(v) => !!v || 'من فضلك قم بإختيار أولويه الطلب'],"filled":"","items":_vm.taskPriorities,"item-text":"nameAr","item-value":"nameAr","placeholder":"اختر أولويه الطلب","hide-details":"","dense":"","append-icon":"mdi-chevron-down","menu-props":{
                  transition: 'slide-y-transition',
                  bottom: true,
                  offsetY: true
                }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center font-14 grey--text"},[_c('span',[_vm._v("لم يتم تحميل اي اختيارات")])])]},proxy:true}],null,false,2624282835),model:{value:(_vm.addTask.priority),callback:function ($$v) {_vm.$set(_vm.addTask, "priority", $$v)},expression:"addTask.priority"}})],1):_vm._e(),_c('v-col',{staticClass:"mb-8",attrs:{"cols":"12"}},[_c('FilesUploader',{ref:"uploadingComp",attrs:{"light":false,"attachments":_vm.attachments},on:{"uploadAttachment":_vm.uploadAttachment,"deleteAttachment":_vm.deleteAttachment}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"py-2 px-8"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-0 rounded-7 my-1 d-flex px-3",attrs:{"color":"primary","disabled":!_vm.isValid,"loading":_vm.addLoadingStatus},on:{"click":_vm.addNewTask}},[_c('span',{staticClass:"mb-1 mobile__mb__0 ml-1 font-weight-bold mt-1 font-12"},[_vm._v("\n          إضافة\n        ")]),_c('v-icon',{staticClass:"send-icon font-16 mt-1"},[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }