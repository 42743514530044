<template>
  <v-data-table
    :class="`hectar-table dataTaskTable ${noHeader}`"
    :headers="headers"
    :items="items"
    @click:row="getTaskData($event)"
    hide-default-footer
    mobile-breakpoint="0"
  >
    <!-- type -->
    <template v-slot:[`item.type`]="{ item, index }">
      <v-menu
        v-if="!requests"
        auto
        content-class="dataTableMenus"
        elevation-0
        offset-y
        width="150"
      >
        <template v-slot:activator="{ on: menu }">
          <div v-on="{ ...menu }">
            <span v-if="!item.type" class="w-100">-</span>
            <span
              :class="`tabel-tag text-center align-center d-flex justify-center font-12`"
              v-else
            >
              <h6 class="mb-1 ma-0 font-12">
                {{ generateTypeName(item) }}
              </h6>
            </span>
          </div>
        </template>
        <v-list
          class="pa-0"
          v-if="isItemCanBeUpdated(item) && hasUpdatePermessions"
        >
          <v-list-item
            v-for="(link, i) in itemTypes"
            :key="i"
            @click="setNewTaskValues([item, link], 'type', index)"
          >
            <v-list-item-title>
              <div :class="`tabel-tag text-center d-flex justify-center`">
                <h6 class="mb-1 ma-0 font-12">
                  {{ link.nameAr }}
                </h6>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div v-else @click="showItemDetails(item, index)">
        {{ item.type.nameAr }}
      </div>
    </template>
    <!-- category -->
    <template v-slot:[`item.category`]="{ item, index }">
      <span
        v-if="requests"
        :class="`tabel-tag text-center align-center d-flex justify-center font-12 ${getAllTagsColors(
          item.category
        )}`"
      >
        <h6 class="mb-1 ma-0 font-12">
          {{ item.category.nameAr }}
        </h6>
      </span>
      <v-menu
        v-else
        auto
        content-class="dataTableMenus"
        elevation-0
        offset-y
        width="150"
        :value="categoryMenuState"
      >
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom color="black" content-class="v-tool" min-width="95">
            <template slot="activator" slot-scope="{ on: tooltip }">
              <div v-on="{ ...tooltip, ...menu }">
                <span v-if="!item.category">
                  <img alt="" src="@/assets/img/svgs/tag.svg" loading="lazy" />
                </span>
                <span
                  :class="`tabel-tag text-center align-center d-flex justify-center font-12 ${getAllTagsColors(
                    item.category?.nameEn
                  )}`"
                  v-else
                >
                  <h6 class="mb-1 ma-0 font-12">
                    {{ item.category.nameAr }}
                  </h6>
                </span>
              </div>
            </template>
            <span class="tool--tip">إضافه علامه</span>
          </v-tooltip>
        </template>
        <v-list
          class="pa-0"
          v-if="isItemCanBeUpdated(item) && hasUpdatePermessions"
        >
          <v-list-item
            v-for="(link, i) in categories"
            :key="i"
            @click="setNewTaskValues([item, link], 'category', index)"
            data-test="categoryOpt"
          >
            <v-list-item-title>
              <div
                :class="`tabel-tag text-center d-flex justify-center ${getAllTagsColors(
                  link?.nameEn
                )}`"
              >
                <h6 class="mb-1 ma-0 font-12">
                  {{ link.nameAr }}
                </h6>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <!-- realEstate -->
    <template v-slot:[`item.realEstate`]="{ item, index }">
      <div
        v-if="
          requests &&
          (disabledRealestateTypes.includes(item.type) || !item.client?.name)
        "
      >
        {{ item?.realEstate?.name || item?.realEstate?.propertyName || '-' }}
      </div>
      <div v-else @click.stop="showSelectClientNotification(item)">
        <autocomplete
          ref="realestateAutoComplete"
          :loading="realEstateLoading"
          :appendIcon="noIcon"
          :chips="false"
          :hideDetails="true"
          :itemText="propertyToShow"
          :items="allProperties"
          :noData="realEstateLoading ? 'جاري البحث عن عقارات' : noData"
          :placeholder="realestatePlaceholder(item)"
          :returnObject="true"
          :styleClass="center"
          :validateString="validateString"
          @emitSearchKey="getPropertyDataBySearchKey($event, item)"
          @input="
            setNewTaskValues([item, item.realEstate], 'realEstate', index)
          "
          @nextPageTrigger="getPropertyNextData(item)"
          v-if="isInputAvaliable(activeRealestateInput, item)"
          v-model="item.realEstate"
          :componentId="`realestateAutoComplete${item._id}`"
        />
        <div
          data-test="divTest"
          @click="handleSettingActiveComponent(item._id, 'realestate', item)"
          v-else
        >
          <span class="font-14" v-if="!item.realEstate"> - </span>
          <span v-else>
            <p class="ma-0 font-14 text-truncate">
              {{ item.realEstate.name || item.realEstate.propertyName }}
            </p>
          </span>
        </div>
      </div>
    </template>

    <!-- moderator -->
    <!-- <template v-slot:[`item.moderator`]="{ item, index }">
      <autocomplete
        :appendIcon="noIcon"
        :chips="false"
        :hideDetails="true"
        :items="allEmployees"
        :noData="noDataClient"
        placeholder="المسؤول"
        :returnObject="true"
        :styleClass="center"
        :validateString="validateStringClient"
        @emitSearchKey="getEmployeesDataBySearchKey"
        @input="setNewTaskValues([item, item.moderator], 'moderator', index)"
        @nextPageTrigger="getEmployeesNextData"
        v-if="isInputAvaliable(activeModeratorInput, item)"
        v-model="item.moderator"
        :componentId="`moderatorAutoComplete${item._id}`"
      />
      <v-tooltip
        v-else-if="item.moderator.length"
        bottom
        color="black"
        content-class="v-tool"
        min-width="50"
      >
        <template slot="activator" slot-scope="{ on: tooltip }">
          <div v-on="{ ...tooltip }">
            <div class="d-flex align-center mx-auto">
              <h5
                @click="handleSettingActiveComponent(item._id, 'moderator')"
                class="mx-auto red--tag bill font-12 px-1 mb-2 font-weight-bold"
              >
                {{ $subString(item.moderator.name) }}
              </h5>
            </div>
          </div>
        </template>
        <span class="tool--tip">{{ item.moderator[0] }}</span>
      </v-tooltip>
      <span v-else @click="handleSettingActiveComponent(item._id, 'moderator')">
        -
      </span>
    </template> -->

    <template v-slot:[`item.checked`]="{ item }">
      <v-checkbox
        v-model="item.checked"
        @click.stop
        class="reset-all ma-0 pr-3 tasksCheckBoxs"
        dense
        hide-details
      />
    </template>
    <template v-slot:[`item.name`]="{ item, index }">
      <div
        data-test="nameInput"
        @click="showItemDetails(item, index)"
        class="mr-4"
      >
        <h5 class="mr-3 font-14 text-truncate font-weight-medium">
          {{ item.name || item.title }}
        </h5>
      </div>
    </template>
    <template v-slot:[`item.moderator`]="{ item, index }">
      <div data-test="nameInput" class="mr-4">
        <h5 class="mr-3 font-14 text-truncate font-weight-medium">
          {{ item.moderator ?? '-' }}
        </h5>
      </div>
    </template>

    <template v-slot:[`item.createdBy`]="{ item }">
      <span v-if="item.createdBy?.name">
        <p class="ma-0 font-14 text-truncate">
          {{ item.createdBy.name }}
        </p>
      </span>
      <span v-else-if="item.createdBy">
        <p class="ma-0 font-14 text-truncate">
          {{ item.createdBy }}
        </p>
      </span>
    </template>

    <template v-slot:[`item.client`]="{ item, index }">
      <div v-if="requests && !item.client?.name">-</div>
      <div v-else>
        <autocomplete
          ref="clientAutoComplete"
          :appendIcon="noIcon"
          :chips="false"
          :hideDetails="true"
          :items="allClients"
          :noData="noDataClient"
          :placeholder="clientPlaceholder(item)"
          :returnObject="true"
          :styleClass="center"
          :validateString="validateStringClient"
          @emitSearchKey="getClientDataBySearchKey"
          @input="setNewTaskValues([item, item.client], 'client', index)"
          @nextPageTrigger="getClientNextData"
          v-if="isInputAvaliable(activeClientInput, item)"
          v-model="item.client"
          :componentId="`clientAutoComplete${item._id}`"
        />
        <div
          @click="handleSettingActiveComponent(item._id, 'client', item)"
          v-else
        >
          <span v-if="item.client?.name">
            <p class="ma-0 font-14 text-truncate">
              {{ item.client.name }}
            </p>
          </span>
          <span class="font-14" v-else> - </span>
        </div>
      </div>
    </template>

    <template v-slot:[`item.assignedTo`]="{ item, index }">
      <autocomplete
        :appendIcon="noIcon"
        :chips="false"
        :hideDetails="true"
        :itemData="false"
        :multiple="true"
        :slectionIndex="1"
        :items="allEmployees"
        :noData="noDataClient"
        :placeholder="teamMemberPlaceholder(item)"
        :returnObject="true"
        :styleClass="center"
        :selection="true"
        :validateString="validateStringClient"
        @emitSearchKey="getEmployeesDataBySearchKey"
        @input="setNewTaskValues([item, item.assignedTo], 'assignedTo', index)"
        @nextPageTrigger="getEmployeesNextData"
        v-click-outside="clickOutside"
        v-if="
          activeAssignedToInput == item._id &&
          isItemCanBeUpdated(item) &&
          hasUpdatePermessions
        "
        v-model="item.assignedTo"
        :componentId="`assignedToAutoComplete${item._id}`"
      />
      <v-row class="justify-center" v-else-if="item.assignedTo.length">
        <v-col
          v-for="(moderator, i) in item.assignedTo.slice(0, 2)"
          :key="i"
          cols="4"
          class="pa-0"
        >
          <v-tooltip bottom color="black" content-class="v-tool" min-width="50">
            <template slot="activator" slot-scope="{ on: tooltip }">
              <div v-on="{ ...tooltip }">
                <div class="d-flex align-center mx-auto">
                  <h5
                    @click="
                      handleSettingActiveComponent(item._id, 'assignedTo')
                    "
                    class="mx-auto red--tag bill font-12 px-1 mb-2 font-weight-bold white-space-nowrap"
                  >
                    {{ $subString(moderator.name) }}
                  </h5>
                </div>
              </div>
            </template>
            <span class="tool--tip" v-if="moderator">{{ moderator.name }}</span>
          </v-tooltip>
        </v-col>
        <p class="my-auto" v-if="item.assignedTo.length > 2">
          (+{{ item.assignedTo.length - 2 }} أخرون)
        </p>
      </v-row>
      <span
        v-else
        @click="handleSettingActiveComponent(item._id, 'assignedTo')"
        class="px-10 pa-5"
      >
        -
      </span>
    </template>

    <template v-slot:[`item.createdAt`]="{ item }">
      <div>
        <span class="font-14">
          {{ formatDate(item.createdAt) }}
        </span>
      </div>
    </template>

    <template v-slot:[`item.issuedFrom`]="{ item }">
      <div>
        <span class="font-14">
          {{ item.issuedFrom ?? 'بوابة الخدمات' }}
        </span>
      </div>
    </template>

    <template v-slot:[`item.priority`]="{ item, index }">
      <v-menu content-class="dataTableMenus" elevation-0 offset-y>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom color="black" content-class="v-tool" min-width="50">
            <template slot="activator" slot-scope="{ on: tooltip }">
              <div v-on="{ ...tooltip, ...menu }">
                <v-icon
                  :class="`mdi-24px ${getAllTagsColors(item.priority?.nameAr)}`"
                  v-on="{ ...menu }"
                  >mdi-flag-variant-outline
                </v-icon>
              </div>
            </template>
            <span class="tool--tip">{{ item.priority?.nameAr }}</span>
          </v-tooltip>
        </template>

        <v-list
          id="priority"
          class="ma-0 pa-0"
          v-if="isItemCanBeUpdated(item) && hasUpdatePermessions"
        >
          <v-list-item
            class="pa-0"
            v-for="(link, i) in taskPriority"
            :key="i"
            @click="setNewTaskValues([item, link], 'priority', index)"
          >
            <v-list-item-icon class="ml-0 mr-3">
              <v-icon :class="`mdi-18px  ${getAllTagsColors(link)}`"
                >mdi-flag-variant-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              :class="`font-weight-bold font-12 ${getAllTagsColors(link)}`"
            >
              {{ link }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template v-slot:[`item.status`]="{ item, index }">
      <v-menu auto content-class="dataTableMenus" elevation-0 offset-y>
        <template v-slot:activator="{ on: menu }">
          <div
            :class="`tabel-tag text-center align-center d-flex justify-center task__tags font-12 item-status-container ${getAllTagsColors(
              getItemStatus(item)
            )}`"
            v-on="{ ...menu }"
          >
            <v-sheet
              class="dotted"
              :color="requestStatusesColors[getItemStatus(item)]"
            />

            <span class="ma-0 mb-1 mr-1">
              {{ getItemStatusAr(item) }}
            </span>
          </div>
        </template>
        <v-list class="py-1" v-if="hasUpdatePermessions">
          <template v-for="(link, i) in itemStates">
            <v-list-item
              v-if="item.status?.nameEn != link?.nameEn"
              :key="i"
              @click="setNewTaskValues([item, link], 'status', index)"
            >
              <v-list-item-title>
                <div
                  :class="`tabel-tag text-center d-flex justify-center ${getAllTagsColors(
                    link?.nameEn
                  )}`"
                >
                  <v-sheet
                    class="dotted ma-0 pa-0 ml-2 mt-2"
                    :color="requestStatusesColors[link?.nameEn]"
                  />
                  <h6 class="mb-1 ma-0 font-12">{{ link?.nameAr }}</h6>
                </div>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-menu
        bottom
        content-class="dropdown-menu"
        elevation-0
        right
        transition="slide-y-transition"
        :value="threeDotsState"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="ml-4">
            <v-btn
              class="ml-3 mobile__ml__ pl-3"
              color="lamb"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </div>
        </template>
        <v-list>
          <v-list-item @click="$emit('details', item)">
            <v-list-item-icon>
              <v-icon>mdi-eye-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>تفاصيل</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="!requests"
            @click="$emit('taskDelete', item._id)"
            data-test="deleteAction"
          >
            <v-list-item-icon>
              <v-icon color="danger">mdi-trash-can-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="danger--text">حذف</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import autocomplete from '@/components/forms/autocomplete'

export default {
  name: 'data-table-item',
  props: {
    realEstateLoading: {
      default: false,
      type: Boolean
    },
    items: {
      default() {
        return []
      }
    },
    headers: {},
    allEmployees: {
      default() {
        return []
      }
    },
    allClients: {
      default() {
        return []
      }
    },
    allProperties: {
      default() {
        return []
      }
    },
    complaints: { defaults: false },
    noHeader: { default: '' },
    requests: {
      type: Boolean,
      default: false
    },
    itemStates: {
      type: Array,
      default() {
        return [
          {
            nameAr: 'قيد التنفيذ',
            nameEn: 'inProgress'
          },
          {
            nameAr: 'قيد الإنتظار',
            nameEn: 'inProgress'
          },
          {
            nameAr: 'تم التنفيذ',
            nameEn: 'done'
          },
          {
            nameAr: 'ملغي',
            nameEn: 'cancelled'
          }
        ]
      }
    }
  },
  components: { autocomplete },
  data() {
    return {
      disabledRealestateTypes: ['report', 'priceInquery'],
      requestStatusesColors: {
        new: '#FF8000',
        // جديدة: '#FF8000',
        inProgress: '#662D91',
        'قيد الإنتظار': '#662D91',
        'قيد التنفيذ': '#662D91',
        inReview: '#662D91',
        underReview: '#662D91',
        canceled: '#fc4a4a',
        cancelled: '#fc4a4a',
        ملغي: '#fc4a4a',
        done: '#65C467',
        'تم التنفيذ': '#65C467',
        rejected: '#383838'
      },
      threeDotsState: false,
      forUnitTest: false,
      categoryMenuState: false,
      activeRealestateInput: null,
      activeAssignedToInput: null,
      activeClientInput: null,
      activeModeratorInput: null,
      center: 'center',
      noIcon: '',
      noData: 'لا توجد عقارات متاحه لهذا البحث',
      validateString: 'من فضلك ادخل اسم العقار',
      noDataClient: 'لا يوجد عملاء متاحين',
      validateStringClient: 'من فضلك ادخل اسم العميل',
      propertyToShow: 'propertyName',
      onOffMenu: false,
      more: require('@/assets/img/svgs/more.svg'),
      taskPriority: ['طارئ', 'عاجل', 'متوسط', 'منخفض'],
      taskTypes: [
        {
          nameAr: 'تسويق',
          nameEn: 'marketing'
        },
        {
          nameAr: 'صيانه',
          nameEn: 'maintenance'
        },
        {
          nameAr: 'إداره أملاك',
          nameEn: 'propertyMangment'
        },
        {
          nameAr: 'تحصيل',
          nameEn: 'collection'
        }
      ],
      requestTypes: JSON.parse(localStorage.getItem('requestTypes')),
      categories: [
        {
          nameAr: 'مهمه',
          nameEn: 'mission'
        },
        {
          nameAr: 'شكوي',
          nameEn: 'complain'
        },
        {
          nameAr: 'طلب',
          nameEn: 'request'
        }
      ],
      env: JSON.parse(localStorage.getItem('currentEnv')),
      currentUser: JSON.parse(localStorage.getItem('tokenData')),
      task: {},
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سيتمبر',
        'أكتوبر',
        'نوفمبر',
        'ديسمبر'
      ],
      requestPriorities: [
        { nameAr: 'طارئ', nameEn: 'urgent' },
        { nameAr: 'عاجل', nameEn: 'high' },
        { nameAr: 'متوسط', nameEn: 'normal' },
        { nameAr: 'منخفض', nameEn: 'low' }
      ],
      selectedClient: {}
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),

    hasUpdatePermessions() {
      return this.$hasPermission('users', 'tasks', 'update')
    },
    realestatePlaceholder: () => (app) => {
      return app?.realEstate ? app.realEstate.name : '-'
    },
    teamMemberPlaceholder: () => (app) => {
      return app?.assignedTo ? app.assignedTo.name : '-'
    },
    clientPlaceholder: () => (app) => {
      return app.cleint ? app.cleint?.name : '-'
    },
    itemTypes() {
      return this.requests ? this.requestTypes : this.taskTypes
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    getTypeArTitle(type) {
      switch (type) {
        case 'maintenance':
          return 'طلب صيانة'
        case 'realEstate':
          return 'عقار'
        case 'report':
          return 'تقرير'
        case 'priceInquery':
          return 'عرض سعر'
        case 'claims':
          return 'طلب مستحقات'
        case 'procedure':
          return 'طلب إجراء'
        default:
          return '-'
      }
    },
    getCategoryArTitle(category) {
      switch (category) {
        case 'electricity':
          return 'كهرباء'
        case 'plumbing':
          return 'سباكة'
        case 'cleaness':
          return 'نظافة'
        case 'painting':
          return 'دهان'
        case 'elevators':
          return 'مصاعد'
        case 'civilDefense':
          return 'دفاع مدني'
        case 'rent':
          return 'إيجار'
        case 'purchase':
          return 'شراء'
        case 'statement':
          return 'تقرير كشف حساب'
        case 'generalReport':
          return 'تقرير مالي عام'
        case 'forRent':
          return 'تقرير شواغر'
        case 'renters':
          return 'تقرير مستأجربن'
        case 'rented':
          return 'تقرير مؤجرة'
        case 'pm':
          return 'إدارة أملاك'
        case 'marketing':
          return 'تسويق عقاري'
        case 'utilities':
          return 'إيراد خدمات'
        case 'otherIncomes':
          return 'إيرادات اخرى'
        case 'rentAdvance':
          return 'مقدم إبجار'
        case 'dues':
          return 'صافي مستحفات'
        case 'insurance':
          return 'تأمين'
        case 'rentDues':
          return 'إيراد تأجير'
        case 'contractRenew':
          return 'تجديد عقد'
        case 'contractCancel':
          return 'إلغاء عقد'
        case 'contractTermination':
          return 'إنهاء العقد'
        default:
          return '-'
      }
    },
    async handleSettingActiveComponent(
      componentId,
      autoCompleteComponentType,
      item = {}
    ) {
      switch (autoCompleteComponentType) {
        case 'realestate':
          this.setRealestateActiveComp(componentId)
          this.$emit('resetAllProperties')
          this.$emit('resetRealestatePagination')
          break
        case 'client':
          this.setClientActiveComp(componentId)
          break
        case 'assignedTo':
          this.setAssignedToActiveComp(componentId)
          break
        case 'moderator':
          this.setModeratorActiveComp(componentId)
      }
    },
    setRealestateActiveComp(componentId) {
      this.activeRealestateInput = componentId
      this.debounce(() => {
        const selectedEl = document.querySelector(
          `#realestateAutoComplete${componentId}`
        )
        selectedEl && selectedEl.click()
      })
    },
    setClientActiveComp(componentId) {
      this.activeClientInput = componentId
      this.activeRealestateInput = null
      this.debounce(() => {
        const selectedEl = document.querySelector(
          `#clientAutoComplete${componentId}`
        )
        selectedEl.click()
      })
    },
    setAssignedToActiveComp(componentId) {
      this.debounce(() => {
        this.activeAssignedToInput = componentId
        this.debounce(() => {
          const selectedEl = document.querySelector(
            `#assignedToAutoComplete${componentId}`
          )
          selectedEl.click()
        })
      })
    },
    setModeratorActiveComp(componentId) {
      this.debounce(() => {
        this.activeModeratorInput = componentId
        this.debounce(() => {
          const selectedEl = document.querySelector(
            `#moderatorAutoComplete${componentId}`
          )
          selectedEl.click()
        })
      })
    },
    debounce(fn, delatyedTime) {
      setTimeout(fn, delatyedTime)
    },
    clickOutside() {
      this.activeAssignedToInput = ''
    },
    getEmployeesDataBySearchKey(e) {
      this.$emit('getEmployeesDataBySearchKey', e)
    },
    getEmployeesNextData() {
      this.$emit('getEmployeesNextData')
    },
    getClientDataBySearchKey(e) {
      this.$emit('getClientDataBySearchKey', e)
    },
    getPropertyDataBySearchKey(e, itemData) {
      this.$emit('getPropertyDataBySearchKey', { e, itemData })
    },
    getClientNextData() {
      this.$emit('getClientNextData')
    },
    getPropertyNextData(itemData) {
      this.$emit('getPropertyNextData', itemData)
    },
    // Obtain current task data
    setNewTaskValues(arr, key, index) {
      const [item, link] = arr
      if (this.requests) {
        const updatedData = {
          [`${key}`]: this.generateRequestUpdatedValue(link, key)
        }
        if (key === 'client') {
          !!item.realEstate && (updatedData.realEstate = null)
          this.activeRealestateInput = null
        }
        this.$emit('updateRequest', {
          requestId: item._id,
          updatedData,
          index
        })
      } else {
        if (key === 'client') {
          this.activeRealestateInput = null
          item.realEstate = null
        }
        if (key === 'assignedTo') {
        } else {
          item[key] = link
        }
        this.$emit('updateTask', { item, index, key })
        this.activeClientInput = null
      }
      this.onOffMenu = true
      this.activeAssignedToInput = ''
      this.activeModeratorInput = ''
    },
    getTaskData(val) {
      this.task = val
    },
    // Generate used date format
    formatDate(date) {
      const currentDate = new Date(date)
      return `${currentDate.getDate()}  ${this.months[currentDate.getMonth()]}`
    },
    // Generate all Tags Colors using binding class
    getAllTagsColors(priority) {
      switch (priority) {
        case 'طارئ':
          return 'red--flag'
        case 'عاجل':
          return 'lightb--flag'
        case 'متوسط':
          return 'orange--flag'
        case 'منخفض':
          return 'low--flag'
        case 'inReview':
        case 'underReview':
          return 'brub--tag'
        case 'new':
        case 'جديدة':
          return 'orange--tag'
        case 'request':
        case 'inProgress':
        case 'قيد الإنتظار':
        case 'قيد التنفيذ':
          return 'brub--tag'
        case 'mission':
          return 'brub--tag'
        case 'complaint':
          return 'lightGreen--tag'
        case 'done':
        case 'تم التنفيذ':
          return 'green--tag'
        case 'cancelled':
        case 'canceled':
        case 'ملغي':
        case 'rejected':
          return 'red--tag'
      }
      return null
    },
    getItemStatus(item) {
      return this.requests ? item.status?.nameAr : item.status?.nameEn
    },
    getItemStatusAr(item) {
      return this.requests
        ? this.itemStates.find((status) => item.status.nameAr === status.nameAr)
            ?.nameAr
        : item.status?.nameAr
    },
    isItemCanBeUpdated(item) {
      return this.requests ? this.requests : item.status?.nameEn !== 'done'
    },
    isInputAvaliable(activeInput, item) {
      return !!(
        (activeInput === item._id &&
          this.isItemCanBeUpdated(item) &&
          this.hasUpdatePermessions) ||
        this.forUnitTest
      )
    },
    generateTypeName(item) {
      return this.requests
        ? this.itemTypes.find((type) => type.nameEn === item.type)?.nameAr
        : item.type?.nameAr
    },
    generatePriorityName(item, lang = ['En', 'Ar']) {
      return this.requests
        ? this.requestPriorities.find((priority) => {
            return priority[`name${lang[0]}`] === item.priority
          })?.[`name${lang[1]}`]
        : item.priority
    },
    formatRequestRealEstate(realEstate) {
      return {
        _id: realEstate._id,
        owner: realEstate.owner._id,
        address: realEstate?.propertyAddress?.address,
        propertyName: realEstate.propertyName,
        location: {
          lng: realEstate.location.coordinates[0],
          lat: realEstate.location.coordinates[1]
        },
        purpose: realEstate.purpose,
        parentId: realEstate.parentId,
        moderators: realEstate?.moderators
      }
    },
    generateRequestUpdatedValue(value, key) {
      switch (key) {
        case 'realEstate':
          return this.formatRequestRealEstate(value)
        case 'status':
        case 'type':
          return value.nameEn
        case 'priority':
          return this.generatePriorityName({ priority: value }, ['Ar', 'En'])
        default:
          return value
      }
    },
    showItemDetails(item, index) {
      if (this.requests) {
        this.$emit('showRequestDetails', { requestInfo: item, index })
      } else {
        this.$emit('details', item)
      }
    },
    showSelectClientNotification(itemData) {
      if (!itemData.client) {
        this.addNotification({
          text: 'يجب اختيار اسم العميل لتتمكن من اختيار العقار',
          color: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss">
.dataTaskTable.hectar-table .v-data-table__wrapper {
  overflow-x: auto !important;
  .task__tags {
    .dotted {
      &.ml-2 {
        margin-left: 8px !important;
      }
      // ml-2
    }
    // dotted
  }
  // task__tags
}

div.center > div.v-input__control > div.v-input__slot > div.v-select__slot {
  input {
    text-align: center !important;
  }
}

.datePicker {
  .v-picker__body {
    div {
      .v-date-picker-header {
        padding: 0px !important;
      }
    }
  }
}

#datePicker > div {
  padding-bottom: 20px;
}

#datePicker > div > div > div.v-date-picker-header.theme--light {
  padding: 0px 15px;
}

#datePicker > div > div > div.v-date-picker-table {
  padding: 0 16px 20px 16px;
  margin: 0 -5px;

  table {
    td {
      button {
        margin: 0 -5px;

        div.v-btn__content {
          font-size: 12px;
          line-height: 16px;
          color: #101010;
        }
      }

      button.primary {
        div.v-btn__content {
          color: #fff;
          margin: 0 0 2px 1px;
        }
      }
    }
  }
}

#priority.v-list {
  .v-list-item {
    max-height: 10px;
  }
}
.letter__spacing__-1px {
  letter-spacing: -1px;
}
.tasksCheckBoxs {
  .v-input__control {
    .v-input__slot {
      justify-content: center;
    }
  }
}
.white-space-nowrap {
  white-space: nowrap;
}
.item-status-container {
  max-width: 105px;
}
</style>
