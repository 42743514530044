<template>
  <div class="contract_pdf_content">
    <!--------contract header--------->
    <div class="contract_top border-bottom">
      <div class="contract_top__logo">
        <!----------Logo---------------->
        <div class="logo_div" v-if="companyLogo">
          <div class="min-logo-white sidebar__mini__Logo">
            <img
              style="max-height: 100px; max-width: 100px"
              loading="lazy"
              :src="companyLogo"
              crossorigin="anonymous"
            />
          </div>
        </div>
        <!----------Title---------------->
      </div>
      <div class="contract_top__info" v-if="contractTitle">
        <div class="contract_title f-bold">
          {{ contractTitle }}
        </div>
      </div>
      <div class="contract_top__info" v-if="contractTypeNameAr">
        <div class="contract_title f-normal brub--tag tabel-tag">
          {{ contractTypeNameAr }}
        </div>
      </div>
    </div>
    <!-----contract info----->
    <div class="contract__info">
      <span class="font-14">
        {{ contract_info }}
      </span>
    </div>
    <div class="contract__info">
      <div class="contractContent" v-if="item.description">
        <p class="contract_content_title">محتوي التعاقد</p>
        <div>
          <span v-html="sanitize(item.description)" />
        </div>
      </div>
    </div>

    <div>
      <div class="contract_details border-bottom">
        <div class="contract_details__content contract_details__parties">
          <div>
            <span class="color__title">طرف أول</span>
            <h5 class="f-bold">
              {{ firstPartyName(item) }}
            </h5>
            <h5 class="f-bold">
              {{ item.firstParty.idNumber || item.firstParty.phoneNumber }}
            </h5>
          </div>

          <div>
            <span class="color__title mb-0">طرف ثاني</span>
            <h5 class="f-bold">
              {{ otherPartyName(item) }}
            </h5>
            <h5 class="f-bold">
              {{ item.otherParties[0].idNumber }}
            </h5>
          </div>
        </div>
      </div>

      <div class="contract_details">
        <div class="contract_details__content">
          <template v-for="(signtaure, i) in Signatures">
            <div v-if="signtaure.status && signtauresImages[i]" :key="i">
              <p class="signatureTtile color__title">
                {{ signtaure.name }}
              </p>
              <img
                style="max-height: 100px; max-width: 100px"
                v-if="signtauresImages[i]"
                :src="signtauresImages[i]"
                crossorigin="anonymous"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="acceptActionVisibility">
      <v-layout class="px-6">
        <p class="mt-6 font-16">هل توافق علي جميع بنود العقد ؟</p>
        <v-layout
          justify-end
          flex-wrap
          class="mobile__justify__start dots__btn"
        >
          <v-btn
            @click="acceptRejectPendingContract(item, true)"
            max-width="120"
            class="actions px-8 ml-2 mb-1"
            color="#662D91"
          >
            <v-icon>mdi-check</v-icon>
            <span class="font-12 font-weight-bold">قبول</span>
          </v-btn>
        </v-layout>
      </v-layout>
      <div class="text-center mt-10">
        <p class="ma-0 font-16 font-weight-bold">
          ملحوظه: سيتم إلغاء الطلب الموافقه تلقائيا, إذا لم يتم قبول العقد خلال
          7 أيام عمل
        </p>
      </div>
    </div>
    <!-----------Footer--------->
    <div class="contract_pdf_footer">
      <div class="logo_div">
        <div class="min-logo-white sidebar__mini__Logo">
          <img
            src="../../assets/img/min-logo-white.png"
            alt=""
            style="max-height: 30px; max-width: 30px"
            loading="lazy"
          />
        </div>
      </div>
      <div class="footer_text">
        <h5>عقد علي منصه هكتار للخدمات العقاريه</h5>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import sanitizers from '@/mixins/sanitizers.js'
import contractsSharedData from '@/mixins/contractsSharedData'

export default {
  props: {
    item: {
      default() {
        return {}
      }
    },
    pdfMedia: {
      default() {
        return {}
      }
    },
    Signatures: {
      default() {
        return []
      }
    },
    showAction: {
      default: true
    },
    contractIntervals: {
      type: Array,
      default() {
        return []
      }
    }
  },
  mixins: [dateFormat, sanitizers, contractsSharedData],
  computed: {
    companyLogo() {
      return this.pdfMedia?.companyLogo?.location || ''
    },
    signtauresImages() {
      return [this.financialSignature, this.directorSignature, this.companySeal]
    },
    financialSignature() {
      return this.pdfMedia?.financialSignature?.location || ''
    },
    directorSignature() {
      return this.pdfMedia?.directorSignature?.location || ''
    },
    companySeal() {
      return this.pdfMedia?.companySeal?.location || ''
    },
    contractTitle() {
      return this.item?.title || ''
    },
    contractTypeNameAr() {
      return this.item.type.nameAr || ''
    },
    isPmContract() {
      return this.item.typeName == 'propertyManagement'
    },
    contract_info() {
      const pmContractDetails = `
              في يوم
              ${this.formatDate(this.item.start)}
              تم توكيل
              ${this.firstPartyName(this.item)}
              من الطرف الثاني
              ${this.otherPartyName(this.item)}
              رقم هويه
              ${this.item.otherParties[0].idNumber}
              رقم هاتف
              ${this.item.otherParties[0].phoneNumber}
              علي إداره عقار
              ${this.realEstateName}
              مده التعاقد تبدأ من
              ${this.formatDate(this.item.start)}
              ${
                this.item.percentage
                  ? `وقد تم الاتفاق علي نسبه تعاقد
              ${this.item.percentage}% `
                  : ''
              }
              ${
                this.item.fixed
                  ? `بمبلغ عمولة ثابتة ${this.item.fixed} ريال ${
                      this.getDurationNameAr(this.item.commissionPeriod)
                        ?.nameAr ?? ''
                    }`
                  : ''
              }
            `
      const ContractDetails = `
              في يوم ${this.formatDate(this.item.start)} قام الطرف الاول
              ${this.firstPartyName(this.item)} بالاتفاق مع الطرف الثاني
              ${this.otherPartyName(this.item)} علي عقد ${
        this.contractTypeNameAr
      } بتاريخ
              ${this.formatDate(this.item.start)}
            `
      switch (this.item.typeName) {
        case 'propertyManagement':
          return pmContractDetails
        default:
          return ContractDetails
      }
    },
    isRentContract() {
      return this.item.typeName == 'rent'
    },
    isPendingContract() {
      return this.item.status == 'pending'
    },
    acceptActionVisibility() {
      return (
        this.showAction &&
        this.isRentContract &&
        this.isPendingContract &&
        ((this.iscurrentUserSameOwner(this.item) &&
          !this.iscurrentUserSameOwnerAndApprove(this.item)) ||
          (this.iscurrentUserSameRenter(this.item) &&
            !this.iscurrentUserSameRenterAndApprove(this.item)))
      )
    }
  }
}
</script>
<style lang="scss">
.contract_pdf_content {
  direction: rtl;
  padding-top: 20px;
  /*  */
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .contract_top {
    &__logo,
    &__info {
      margin: 10px 0px;
      display: flex;
      justify-content: center;
    }
  }
  .contractContent {
    p.contract_content_title {
      font-size: 18px !important;
      font-weight: bold;
      text-align: center;
    }
  }
  .border-bottom {
    margin: 10px 0;
    border-bottom: 2px solid #ddd;
  }
  .contract__info {
    margin: 15px 0 0 0;
    padding: 0 25px;
  }
  .contract_details {
    padding: 20px 25px;

    &__content {
      display: flex;
      justify-content: space-between;
      /*  */
      & > * {
        flex-shrink: 0;
      }
    }

    &__parties {
      justify-content: space-around;
    }
  }
  .color__title {
    color: rgb(107 100 100);
  }
  .f-normal {
    font-weight: normal;
  }
  .f-bold {
    font-weight: bold;
  }
  .brub--tag {
    color: #67328e !important;
    background: rgba(#67328e, 0.2) !important;
  }
  .tabel-tag {
    border-radius: 30px;
    padding: 1px 25px;
    max-height: 30px;
  }
  .signatureTtile {
    text-align: center;
    font-size: 14px !important;
    padding: 0;
    margin: 0;
  }
}

/* contract pdf footer */
.contract_pdf_footer {
  margin: 20px 0 0 0;
  padding: 0 25px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #67328e;
  justify-content: space-between;
  /*  */

  .logo_div {
    display: flex;
    & img {
      margin: 5px 0 0 0;
    }
  }
  .footer_text {
    text-align: center;
    color: #fff;
  }
}
</style>
