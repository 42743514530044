<template>
  <div class="mb-6">
    <div class="w-100 d-flex justify-space-between">
      <h5 class="grey--text mt-5">طلبات الصرف</h5>
      <div class="d-flex">
        <h5 v-if="false" class="mt-5 tag text-center brub--tag px-3 py-1 ml-1">
          حد المصروفات: 5000 ريال
        </h5>
        <h5
          v-if="expenses.length > 0"
          class="mt-5 tag text-center brub--tag px-3 py-1"
        >
          المجموع: {{ totalExpenses }} ريال
        </h5>
      </div>
    </div>
    <div>
      <PayRequestsTable
        class="w-100"
        v-bind="{ requestId, expenses, addExpenseStatus, hasUpdatePermission }"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import PayRequestsTable from './payRequestsTable.vue'

export default {
  name: 'RequestExpenses',
  components: {
    PayRequestsTable
  },
  props: {
    hasUpdatePermission: {
      default: true,
      type: Boolean
    },
    requestId: {
      type: String,
      required: true
    },
    addExpenseStatus: {
      type: String,
      required: true
    },
    expenses: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    totalExpenses() {
      let total = 0
      this.expenses.forEach((expense) => (total += expense.total))
      return total
    }
  }
}
</script>

<style></style>
