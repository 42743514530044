<template>
  <!--Loader-->
  <v-col>
    <v-sheet
      class="d-flex align-center justify-center flex-column rounded-15"
      :class="{ 'bg-primary': isbgPrimary }"
      :height="height"
    >
      <v-img
        class="w-100 mb-2"
        :max-width="widthHeight"
        :max-height="widthHeight"
        :src="noDataIcon"
      ></v-img>
      <h3 class="grey--text text--darken-3 my-0">
        {{ noDataText }}
      </h3>
      <h5
        v-if="subTitleStatus"
        v-html="sanitize(noDataSubTitle)"
        class="grey--text text-center"
      ></h5>
    </v-sheet>
  </v-col>
</template>

<script>
import sanitizers from '@/mixins/sanitizers.js'

export default {
  name: 'noData',
  mixins: [sanitizers],
  props: {
    isbgPrimary: {
      default: true
    },
    widthHeight: {
      default: '50',
      type: String
    },
    subTitleStatus: {
      type: Boolean,
      default: true
    },
    noDataIcon: {
      default: require('@/assets/img/svgs/contract/noContracts.svg')
    },
    noDataText: {
      default: 'لا توجد بيانات متاحه'
    },
    noDataSubTitle: {
      default: 'يمكنك اضافه بيانات جديده'
    },
    height: {
      default: '60vh'
    }
  }
}
</script>
