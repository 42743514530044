<template>
  <div>
    <v-card class="d-flex justify-center px-2 py-5">
      <v-btn depressed @click="pdfStatusData = true">
        <span class="black--text text-decoration-underline">
          إتفاقيه الإستخدامات والإشتراكات
        </span>
        <v-icon color="primary" class="mr-2"> mdi-file </v-icon>
      </v-btn>
    </v-card>
    <UsageAgreementPdf v-if="pdfStatus" @closePdf="closePdf" />
  </div>
</template>

<script>
import UsageAgreementPdf from '@/components/UsageAgreement/UsageAgreementPdf'
export default {
  name: 'UsageAgreement',
  components: { UsageAgreementPdf },
  data() {
    return {
      pdfStatus: false
    }
  },
  computed: {
    pdfStatusData: {
      get() {
        return this.pdfStatus
      },
      set(data) {
        this.pdfStatus = data
      }
    }
  },
  methods: {
    closePdf() {
      this.pdfStatusData = false
    }
  }
}
</script>
