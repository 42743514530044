<template>
  <v-card class="associations__actions pa-4">
    <!-- Tabs -->
    <v-tabs v-model="tab" bg-color="primary" class="border-bottom mb-2">
      <v-tab v-for="tab in tabs" :key="tab.name" :value="tab.value">
        {{ tab.name }}
        <v-badge v-if="tab.counter" :content="tab.counter" inline />
      </v-tab>
    </v-tabs>

    <!-- Components -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in tabs" :key="tab.name">
        <v-card flat class="associations__actions__comp">
          <component :is="tab.compName" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Conversations from '@/components/associations/Conversations'
import Suggestions from '@/components/associations/Suggestions'
import Decisions from '@/components/associations/Decisions'
export default {
  name: 'AssociationsDetailsActions',
  data() {
    return {
      tab: 0,
      tabs: [
        {
          name: 'المحادثات',
          value: 'one',
          counter: 20,
          compName: 'Conversations'
        },
        {
          name: 'الإقتراحات',
          value: 'two',
          counter: 5,
          compName: 'Suggestions'
        },
        {
          name: 'القرارات',
          value: 'three',
          compName: 'Decisions'
        }
      ]
    }
  },
  components: {
    Decisions,
    Conversations,
    Suggestions
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/associations/associations.scss';
</style>
