<template>
  <div>
    <v-form method="post" class="register" v-model="isValid">
      <div v-if="!invitation">
        <h6>نوع الحساب*</h6>
        <v-select
          v-model="role"
          data-test="accountTypes"
          :items="accountTypes"
          placeholder="إختر نوع الحساب"
          dense
          filled
          hide-details="auto"
          :rules="[(v) => !!v || 'من فضلك اختر نوع الحساب']"
        ></v-select>
      </div>

      <div class="text-field">
        <h6>الإسم*</h6>
        <v-text-field
          ref="name"
          data-test="name"
          v-model="name"
          :rules="[(v) => !!v || 'من فضلك أدخل الإسم']"
          label="الإسم"
          required
          filled
          dense
          hide-details="auto"
        />
      </div>

      <v-expand-transition v-if="!invitation">
        <div class="text-field" v-if="isOfficeOrCompany">
          <h6>اسم {{ roleType }}*</h6>
          <v-text-field
            ref="company"
            data-test="entityName"
            v-model="company"
            :label="`اسم ${roleType}`"
            dense
            filled
            hide-details="auto"
            :rules="[(v) => !!v || `من فضلك أدخل اسم ${roleType}`]"
          />
        </div>
      </v-expand-transition>

      <div class="text-field">
        <h6>البريد الإلكتروني</h6>
        <v-text-field
          ref="email"
          data-test="email"
          v-model="email"
          label="البريد الإلكتروني (إختياري)"
          filled
          dense
          :rules="emailRules"
          hide-details="auto"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'RegisterStep3',
  props: ['phoneNumber', 'userName', 'userEmail', 'password'],
  watch: {
    accountType: {
      immediate: true,
      handler(val) {
        let stepper
        if (val === 'business') {
          stepper = 'next'
        } else {
          this.company = null
          this.role = null
          stepper = 'finish'
        }
        this.$emit('location-step', stepper)
      }
    },
    role: {
      immediate: true,
      handler(val) {
        if (!val) {
          return
        }
        let stepper = 'next'
        if (val === 'owner') {
          this.company = null
          stepper = 'finish'
        }

        this.$emit('location-step', stepper)
      }
    }
  },
  data() {
    return {
      isValid: false,
      emailRules: [
        (v) => /.+@.+\..+/.test(v) || !v || 'من فضلك أدخل بريد الكتروني صحيح'
      ],
      accountType: 'business',
      invitation: false,
      role: null,
      name: '',
      company: '',
      email: '',
      passwordFieldType: 'password'
    }
  },
  mounted() {
    // check if user should be updated
    const userDetails = JSON.parse(localStorage.getItem('should-update'))
    if (userDetails) {
      this.name = userDetails.name
      this.company = userDetails.company
      this.email = userDetails.email
    }

    // check if this is an invitaion
    const invitation = JSON.parse(localStorage.getItem('user-invitation'))
    if (invitation) {
      this.invitation = invitation
      this.accountType = 'employee'
      // set the email input
      this.email = invitation.email
      // set the email as read only
      this.$refs.email.readonly = true
    }
    // Prevent a link inside a label from triggering a checkbox
    const termLink = document.getElementsByClassName('termLink')
    const termClickHandler = function (event) {
      event.stopPropagation()
      event.preventDefault()
      return false
    }
    for (let i = 0; i < termLink.length; i++) {
      termLink[i].addEventListener('click', termClickHandler)
      termLink[i].addEventListener('touchstart', termClickHandler)
    }

    if (this.userEmail) {
      this.email = this.userEmail
    }
    if (this.userName) {
      this.name = this.userName
    }
  },
  computed: {
    ...mapState('accountState', ['configs']),
    ...mapState('userState', ['spinnerLoading']),
    validateTermsCheckbox() {
      return !!this.termsConditions.status
    },
    accountTypes() {
      return [
        { text: 'شركة عقارية', value: 'company' },
        { text: 'مكتب عقاري', value: 'office' },
        { text: 'مالك عقارات', value: 'owner' }
      ]
    },
    user() {
      const user = {
        type: this.role,
        name: this.name,
        phoneNumber: this.phoneNumber,
        accountId: JSON.parse(localStorage.getItem('tokenData'))
          ? JSON.parse(localStorage.getItem('tokenData'))?._id
          : JSON.parse(localStorage.getItem('user-invitation'))?._id
      }
      if (this.email) {
        user.email = this.email
      }
      if (this.company) {
        user.company = this.company
      }

      return user
    },
    roleType() {
      switch (this.role) {
        case 'office':
          return 'المكتب'
        default:
          return 'الشركه'
      }
    },
    isOfficeOrCompany() {
      return this.role === 'company' || this.role === 'office'
    }
  },
  validations: {
    name: {
      required
    },
    company: {
      required: requiredIf(function () {
        // eslint-disable-next-line no-invalid-this
        return ['company', 'office'].includes(this.role)
      })
    },

    form: ['name', 'company']
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    async validate() {
      this.$emit('on-validate', this.user, this.isValid)
      if (!this.isValid) {
        return false
      }
      if (this.invitation) {
        return this.$emit('add-user', this.invitation)
      }
      if (this.role === 'owner') {
        return this.$emit('add-user')
      }
      return this.isValid
    }
  }
}
</script>
<style scoped>
.noArrows >>> input[type='number'] {
  -moz-appearance: textfield;
}
.noArrows >>> input::-webkit-outer-spin-button,
.noArrows >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
