<template>
  <div>
    <div class="my-6 d-flex justify-between">
      <h4>{{ approval?.typeName?.ar ?? '-' }}</h4>
    </div>
    <div v-if="approval?.entity">
      <div
        class="d-flex justify-between align-center pa-4 border__card rounded-lg mb-lg-2 mt-2"
      >
        <span class="brub--tag tabel-tag font-14 font-weight-medium px-6">
          {{ approval?.actionName?.ar ?? '-' }}
        </span>
        <span class="font-14 font-weight-bold">
          {{ approval?.entity?.title ?? '-' }}
        </span>
        <span class="font-14 font-weight-bold">
          {{ approval?.realEstate?.[0]?.name ?? '-' }}</span
        >
        <span class="font-14 font-weight-bold">
          {{ approval?.realEstate?.[0]?.unitName ?? '-' }}
        </span>
        <v-btn
          color="primary darken-1"
          v-if="approval.type !== 'reports'"
          @click="$emit('click')"
        >
          <span class="mb-1 px-5">عرض</span>
        </v-btn>
        <v-btn
          color="primary darken-1"
          v-else
          @click="$emit('reportClicked')"
          :disabled="isOwener"
        >
          <span class="mb-1 px-5">عرض</span>
        </v-btn>
      </div>
    </div>
    <v-sheet v-else>
      <span class="text-center">
        <v-img
          class="ma-auto mb-3"
          width="72"
          height="72"
          :src="noBonds"
        ></v-img>
        <h4 class="ma-auto w-100 text--secondary">ليست هناك اي سندات</h4>
      </span>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    approval: {
      type: Object,
      default: () => {}
    },
    isOwener: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noBonds: require('@/assets/img/bonds/bonds.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.border__card {
  border: 1px solid #e8e8e8;
}
.cursor-pointer {
  cursor: pointer;
}
.b-r {
  border: 1px solid;
}
</style>
