<template>
  <v-card class="property-card team-card" :class="classes">
    <v-list-item class="card-title" :to="`/dashboard/user/${options._id}`">
      <v-list-item-content class="user-info">
        <v-list-item-title class="headline">{{
          options.name
        }}</v-list-item-title>
        <!-- <v-list-item-subtitle>مالك</v-list-item-subtitle> -->
      </v-list-item-content>
    </v-list-item>

    <div class="team-info property-info">
      <v-list-item dense class="phone-number">
        <v-list-item-content>
          <v-list-item-subtitle>الهاتف</v-list-item-subtitle>
          <v-list-item-title>{{ options.phoneNumber }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-subtitle>رقم الهوية</v-list-item-subtitle>
          <v-list-item-title>{{ options.idNumber }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item dense>
        <v-list-item-content>
          <v-list-item-subtitle>ايميل</v-list-item-subtitle>
          <v-list-item-title>{{ options.email }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item dense class="delete-cell">
        <v-menu content-class="dropdown-menu" transition="slide-x-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="general"
              small
              class="ma-0 text-sm-body-2"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editClient(options)">
              <v-list-item-icon>
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>تعديل بيانات العميل</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>توقيف العميل</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="deleteConfirm">
              <v-list-item-icon>
                <v-icon color="danger">mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="danger--text"
                  >حذف العميل</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['classes', 'options'],
  data() {
    return {
      dropdownItems: [
        { title: 'تعديل بيانات العميل', icon: 'mdi-square-edit-outline' },
        { title: 'توقيف العميل', icon: 'mdi-share-variant' },
        { title: 'حذف العميل', icon: 'mdi-trash-can-outline' }
      ]
    }
  },
  computed: {},
  methods: {
    deleteConfirm() {
      this.$root.$confirm
        .open({
          title: 'حذف العضو',
          message: 'هل أنت متأكد من حذف العضو، العملية لا يمكن استرجاعها',
          options: { color: 'danger', width: 500, zIndex: 200 }
        })
        .then(() => {
          // this.deleteMember(id);
        })
    },
    editClient(clientInfo) {
      this.$emit('edit-client', clientInfo)
    }
  }
}
</script>
