export default {
  methods: {
    sanitize(html, tags_ = undefined, attributes_ = undefined) {
      let attributes = attributes_ || [
        { attribute: 'src', tags: '*', regex: /^(?:https|http|\/\/):/ },
        { attribute: 'href', tags: '*', regex: /^(?!javascript:).+/ },
        { attribute: 'width', tags: '*', regex: /^[0-9]+$/ },
        { attribute: 'height', tags: '*', regex: /^[0-9]+$/ },
        { attribute: 'id', tags: '*', regex: /^[a-zA-Z]+$/ },
        { attribute: 'class', tags: '*', regex: /^[a-zA-Z ]+$/ },
        { attribute: 'value', tags: ['INPUT', 'TEXTAREA'], regex: /^.+$/ },
        { attribute: 'checked', tags: ['INPUT'], regex: /^(?:true|false)+$/ },
        {
          attribute: 'placeholder',
          tags: ['INPUT', 'TEXTAREA'],
          regex: /^.+$/
        },
        {
          attribute: 'alt',
          tags: ['IMG', 'AREA', 'INPUT'],
          // "^" and "$" match beggining and end
          regex: /^[0-9a-zA-Z]+$/
        },
        { attribute: 'autofocus', tags: ['INPUT'], regex: /^(?:true|false)+$/ },
        { attribute: 'for', tags: ['LABEL', 'OUTPUT'], regex: /^[a-zA-Z0-9]+$/ }
      ]
      const tags = tags_ || [
        'I',
        'P',
        'B',
        'BODY',
        'HTML',
        'DEL',
        'INS',
        'STRONG',
        'SMALL',
        'A',
        'IMG',
        'CITE',
        'FIGCAPTION',
        'ASIDE',
        'ARTICLE',
        'SUMMARY',
        'DETAILS',
        'NAV',
        'TD',
        'TH',
        'TABLE',
        'THEAD',
        'TBODY',
        'NAV',
        'SPAN',
        'BR',
        'CODE',
        'PRE',
        'BLOCKQUOTE',
        'EM',
        'HR',
        'H1',
        'H2',
        'H3',
        'H4',
        'H5',
        'H6',
        'DIV',
        'MAIN',
        'HEADER',
        'FOOTER',
        'SELECT',
        'COL',
        'AREA',
        'ADDRESS',
        'ABBR',
        'BDI',
        'BDO',
        'U'
      ]

      attributes = attributes.map((el) => {
        if (typeof el === 'string') {
          return { attribute: el, tags: '*', regex: /^.+$/ }
        }
        const output = el
        if (!el.hasOwnProperty('tags')) {
          output.tags = '*'
        }
        if (!el.hasOwnProperty('regex')) {
          output.regex = /^.+$/
        }
        return output
      })
      const el = new DOMParser().parseFromString(html, 'text/html')
      const elements = el.querySelectorAll('*')
      for (let i = 0; i < elements.length; i++) {
        const current = elements[i]
        const attrList = getAttributes(current)
        for (let j = 0; j < attrList.length; j++) {
          const attribute = attrList[j]
          if (!attributeMatches(current, attribute)) {
            current.removeAttribute(attrList[j])
          }
        }
        if (!tags.includes(current.tagName)) {
          current.remove()
        }
      }
      return el.documentElement.innerHTML
      // eslint-disable-next-line func-style
      function attributeMatches(element, attribute) {
        const output = attributes.filter((attr) => {
          const returnval =
            attr.attribute === attribute &&
            (attr.tags === '*' || attr.tags.includes(element.tagName)) &&
            attr.regex.test(element.getAttribute(attribute))
          return returnval
        })

        return output.length > 0
      }
      // eslint-disable-next-line func-style
      function getAttributes(element) {
        const arr = []
        for (
          let i = 0, atts = element.attributes, n = atts.length;
          i < n;
          i++
        ) {
          arr.push(atts[i].nodeName)
        }
        return arr
      }
    }
  }
}
