<template>
  <v-app class="bg-body">
    <transition mode="out-in">
      <router-view @updateInformativeTip="updateInformativeTip($event)" />
    </transition>
    <informativeToolTip
      v-if="ToolTipStatus"
      :description="toolTipDesc"
      :title="toolTipeTitle"
      id="informativeMenuTip"
    />
    <addNotification></addNotification>
    <realTimeNotification></realTimeNotification>
    <loggedInConfirm></loggedInConfirm>
    <confirm ref="confirm"></confirm>
    <SocialSharing ref="socialSharing"></SocialSharing>
    <sync v-if="sync && Object.keys(bundle).length">
      <template v-slot:logo>
        <v-img :src="logo" max-width="56"></v-img>
      </template>
      <template v-slot:title>
        هلا {{ firstName }}, أنت الأن في الفتره المجانيه لـ {{ bundle.nameAr }}
      </template>
      <template v-slot:desc>
        يمكنك إستخدام هكتار بلس لمده شهرين مجاناً
      </template>
    </sync>
    <coupon v-if="coupon" />
  </v-app>
</template>

<style lang="scss" rel="preload">
@import '@/styles/index.scss';
/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
<script>
// Snackbar Notification
import addNotification from '@/components/core/addNotification'
import realTimeNotification from '@/components/core/realTimeNotification'
import loggedInConfirm from '@/components/core/loggedInConfirm'
import Confirm from '@/components/modals/Confirm.vue'
import SocialSharing from '@/components/modals/SocialSharing.vue'
import sync from '@/components/modals/sync'
import coupon from '@/components/modals/coupon'
import axios from 'axios'
import { mapState } from 'vuex'
import informativeToolTip from '@/components/modals/informativeToolTip.vue'

// checks to see if auth jwt token is valid or has expired, if it gets back 401 error log out
export default {
  components: {
    informativeToolTip,
    addNotification,
    realTimeNotification,
    loggedInConfirm,
    Confirm,
    sync,
    coupon,
    SocialSharing
  },
  data() {
    return {
      logo: require('@/assets/img/svgs/crown.svg'),
      toolTipeTitle: 'عنوان المعلومه الفرعيه',
      toolTipDesc:
        'شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان شرح المعلومه الفرعيه يجب ان يقع هنا في هذا المكان ',
      avaliableToolTipViews: ['contracts', 'taxInvoices']
    }
  },
  methods: {
    updateInformativeTip(informativeTip) {
      this.toolTipeTitle = informativeTip.title
      this.toolTipDesc = informativeTip.description
    }
  },
  computed: {
    ...mapState('appState', ['sync', 'coupon']),
    ...mapState('accountState', ['user', 'subscription']),

    firstName() {
      if (this.user && this.user.name) {
        const name = this.user.name
        return name.replace(/ .*/, '')
      }
      return null
    },
    bundle() {
      return this.subscription?.bundle
    },
    ToolTipStatus() {
      return !!this.avaliableToolTipViews.includes(this.$route?.meta.name)
    }
  },
  created() {
    const loggedIn = JSON.parse(localStorage.getItem('tokenData'))
    // Axios set configurations
    if (loggedIn) {
      axios.defaults.headers.common.Authorization = `Bearer ${
        this.user?.token || JSON.parse(localStorage.getItem('tokenData')).token
      }`
      axios.defaults.headers.common['HPlus-Authorization'] = `Bearer ${
        this.user?.userEnvToken ||
        JSON.parse(localStorage.getItem('tokenData')).userEnvToken
      }`
    }
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm
    this.$root.$socialSharing = this.$refs.socialSharing

    const config = !!JSON.parse(localStorage.getItem('configrations'))

    if (!config) {
      this.$store.dispatch('accountState/getConfigrations').then((res) => {
        localStorage.setItem('configrations', JSON.stringify(res))
      })
    } else {
      const types = JSON.parse(localStorage.getItem('configrations'))
      this.$store.commit('accountState/setConfigrations', types)
    }

    // Realtime - update configrations in localstorage
    this.$socket.$subscribe('update_browsers', () => {
      this.$store.dispatch('accountState/getConfigrations').then((res) => {
        localStorage.setItem('configrations', JSON.stringify(res))
        console.log('New configrations')
      })
    })
  }
}
</script>
