<template>
  <div class="d-flex justify-space-between align-center">
    <h5 class="ma-0 pt-4">{{ title }}</h5>
    <v-btn
      class="add-btn add__property__btn px-1 py-3 mx-1"
      depressed
      x-small
      v-if="(title === 'المرفقات' || title === 'الملاحظات') && !isCustomer"
      @click="$emit('addAction')"
    >
      <v-icon color="purple">mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ContractTapsHeader',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';
.v-btn.add-btn {
  background: rgba($primary-color, 0.1) !important;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.03);
  border-radius: 7px !important;
  &.add__property__btn {
    height: 20px;
    min-width: 24px;
    &:hover {
      background: $primary-color !important;
      i {
        color: #fff !important;
      }
    }
    ::v-deep {
      .v-btn__content {
        i {
          font-size: 17px !important;
        }
      }
    }
  }
  // add__property__btn
}
</style>
