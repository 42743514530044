<template>
  <div class="report_pdf_content">
    <!--------Report header--------->
    <div class="report_top border-bottom pdfHeaderPadding">
      <div class="report_top__logo">
        <!----------Logo---------------->
        <div class="logo_div">
          <div class="min-logo-white sidebar__mini__Logo">
            <img
              style="max-height: 50px; max-width: 50px"
              loading="lazy"
              :src="companyLogo"
              crossorigin="anonymous"
            />
            <h4 class="font-16 mt-0 text-center" v-text="user.company" />
          </div>
        </div>

        <!----------Title---------------->
        <div class="report_date font-14">
          <div
            class="report_creation_date f-normal mt-0"
            v-text="reportData.title"
          />
        </div>
      </div>
    </div>

    <!-----Report info----->
    <div class="report_details border-bottom">
      <div class="report_details__content pdfHeaderPadding">
        <div class="amount_div">
          <span class="font-14 color__title mb-0">رقم التقرير</span>
          <h6
            class="font-14 ma-0 mt-0 f-normal text-truncate"
            v-text="reportNumber"
          />
        </div>

        <!-------Moderator name-------->
        <div class="report_company_name" v-if="reportData.moderator">
          <span class="font-14 color__title mb-0">منشئ التقرير</span>
          <h6
            class="font-14 ma-0 mt-0 f-normal text-truncate"
            v-text="reportData.moderator.name"
          />
        </div>

        <!-------Date info-------->
        <div v-if="showReportPeriod" class="from_to_date">
          <span class="font-14 color__title mb-0">المده الزمنية للتقرير</span>
          <h6 class="font-14 ma-0 mt-0 f-normal text-truncate">
            من {{ formatDate(reportData.start) }} الى
            {{ formatDate(reportData.end) }}
          </h6>
        </div>
      </div>
    </div>

    <!-----------For report details datatable---------->
    <slot />

    <!-----------Footer--------->
    <div class="report_pdf_footer">
      <div class="logo_div">
        <div class="min-logo-white sidebar__mini__Logo mb-1">
          <img
            src="../../assets/img/min-logo-white.png"
            alt=""
            style="max-height: 30px; max-width: 30px"
            loading="lazy"
          />
        </div>
      </div>
      <div class="footer_text w-100 text-center">
        <h5>جميع الحقوق محفوظة</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'ReportPdf',
  mixins: [dateFormat],
  props: {
    reportData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    ...mapGetters('accountState', ['companyImages']),
    reportNumber() {
      return this.reportData?.number ?? this.reportData._id
    },
    companyLogo() {
      const logo = this.companyImages?.companyLogo
      return logo ? logo.location : require('@/assets/img/logo.svg')
    },
    showReportPeriod() {
      return !!(this.reportData?.start && this.reportData?.end)
    }
  }
}
</script>

<style lang="scss">
.report_pdf_content {
  direction: rtl;
  padding-top: 20px;
  padding-right: 10px;
  /*  */
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .report_top {
    &__logo,
    &__info {
      display: flex;
      justify-content: space-between;
    }
  }
  .border-bottom {
    border-bottom: 2px solid #ddd;
  }
  .report_details {
    padding: 20px 0 25px;

    &__content {
      display: flex;
      justify-content: space-between;
      /*  */
      & > * {
        flex-shrink: 0;
      }
    }
  }
  .color__title {
    color: rgb(107 100 100);
  }
  .f-normal {
    font-weight: normal;
  }
}

/* Report pdf footer */
.report_pdf_footer {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #67328e;
  /*  */

  .logo_div {
    position: relative;
    right: 20px;

    & img {
      max-width: 70%;
    }
  }
  .footer_text {
    text-align: center;
    color: #fff;
  }
}
::ng-deep {
  table {
    th,
    tr {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
}
.pdfHeaderPadding {
  padding: 0px 250px;
}
.text-center {
  text-align: center;
}
</style>
