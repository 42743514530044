<template>
  <div class="no-messages-container">
    <div class="text-center">
      <img :src="noMessagesIcon" loading="lazy" />
      <h3 class="no-messages">{{ noMessages }}</h3>
      <h6 class="no-messages-hectar">{{ noMessages }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoMessages',
  props: {
    hectarTabActive: {
      type: String,
      default: 'hectarPlusTeam'
    },
    isCustomer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noMessagesIcon: require('@/assets/img/svgs/messages/no-messages.svg')
    }
  },
  computed: {
    noMessages() {
      if (this.isCustomer) {
        return 'لا توجد رسائل'
      }

      switch (this.hectarTabActive) {
        case 'hectar':
          return 'لا يوجد رسائل من عملاء هكتار'
        case 'hectarPlusTeam':
          return 'لا يوجد رسائل من الفريق'
        case 'hectarPlusCustomer':
          return 'لا يوجد رسائل من العملاء'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.no-messages-container {
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  height: 653px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-messages {
  margin-top: 28px;
  color: #101010;
}
.no-messages-hectar {
  margin-top: 12px;
  color: #737373;
}
</style>
