<template>
  <div
    @click="$emit('active-thread', threadData)"
    :class="['py-2', isContainerClasses, setThreadActiveClass]"
  >
    <div class="d-flex">
      <div
        v-if="!xs"
        :class="['name-abbreviation', 'mt-3', hectarTabActiveColor]"
      >
        {{ $subString(this.threadData.name) }}
      </div>
      <div class="mr-2 msg__sender__details">
        <p class="user-name ma-0 d-inline-block">{{ threadData.name }}</p>
        <div
          :class="['unread-badge', unreadBadgeBackground]"
          v-if="messagesUnseen"
        ></div>
        <p class="last-message ma-0">{{ threadData.lastMessage }}</p>
      </div>
    </div>
    <span
      v-if="container"
      :class="['time-last-message', lastMessageTimeColor]"
      >{{ timeDifferenceCalc(threadData.sentAt) }}</span
    >
    <span v-else :class="['time-last-message', lastMessageTimeColor]">{{
      formatDate(threadData.sentAt)
    }}</span>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import timeDifferenceCalc from '@/mixins/timeDifferenceCalc.js'
import { mapState } from 'vuex'

export default {
  name: 'MessageCard',
  mixins: [dateFormat, timeDifferenceCalc],
  props: {
    threadData: {
      type: Object,
      required: true
    },
    threadId: {
      type: [String, Number]
    },
    hectarTabActive: {
      type: Boolean,
      default: false
    },
    container: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('accountState', ['user']),
    setThreadActive() {
      return this.threadId === this.threadData._id
    },
    messagesUnseen() {
      return (
        !this.threadData.seen && this.threadData.receiverId === this.user._id
      )
    },
    setThreadActiveClass() {
      if (this.setThreadActive) {
        return 'active-thread'
      }
      return ''
    },
    hectarTabActiveColor() {
      if (this.hectarTabActive) {
        return 'hectar-messages-color'
      }
      return 'team-messages-color'
    },
    unreadBadgeBackground() {
      if (this.hectarTabActive) {
        return 'hectar-messages-background'
      }
      return 'team-messages-background'
    },
    lastMessageTimeColor() {
      if (this.messagesUnseen && this.hectarTabActive) {
        return 'hectar-messages-color'
      } else if (this.messagesUnseen && !this.hectarTabActive) {
        return 'team-messages-color'
      }
      return ''
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly
    },
    isContainerClasses() {
      return this.container
        ? 'w-100 d-flex justify-space-between mt-2'
        : 'message-card-container px-3 mt-4'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/material-dashboard/message-card.scss';
</style>
