<template>
  <v-dialog max-width="680px" persistent :value="dialogState">
    <v-card class="add-new-property pa-0">
      <!-----------1# Header----------->
      <v-card-title
        class="py-0 px-3 mb-0 custom__shadow d-flex justify-space-between"
      >
        <!----------Title----------->
        <h4 class="my-2">إعدادات تجديد العقد</h4>

        <!----------Close----------->
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
          :disabled="submitBtnIsLoading"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-----------2# Actions----------->
      <v-container class="mb-0">
        <div>
          <v-row>
            <v-col cols="12" class="d-flex justify-space-between align-center">
              <h4 class="font-14">{{ contractRenewmentProp.title }}</h4>
              <v-switch
                class="ma-0"
                dense
                hide-details="auto"
                inset
                v-model="contractRenewmentData.active"
                :disabled="!hasUpdatePermission"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6">
              <h6 class="font-14 font-weight-bold">قبل او بعد</h6>
              <v-select
                :items="dueDate"
                item-text="nameAr"
                item-value="nameEn"
                :menu-props="{ bottom: true, offsetY: true }"
                append-icon="mdi-chevron-down"
                color="black"
                dense
                filled
                hide-details
                placeholder="قبل او بعد موعد الاستحقاق"
                v-model="contractRenewmentData.method"
                :disabled="
                  !contractRenewmentData.active || !hasUpdatePermission
                "
              />
            </v-col>

            <v-col cols="12" sm="6">
              <h6 class="font-14 font-weight-bold">عدد الأيام</h6>
              <v-select
                :items="monthDays"
                :menu-props="{ bottom: true, offsetY: true }"
                append-icon="mdi-chevron-down"
                color="black"
                dense
                filled
                hide-details
                placeholder="عدد الأيام"
                v-model="contractRenewmentData.days"
                :disabled="
                  !contractRenewmentData.active || !hasUpdatePermission
                "
              />
            </v-col>
          </v-row>
        </div>
      </v-container>

      <!----------3# Save----------->
      <v-card-actions class="pa-1 mt-2">
        <v-btn
          :loading="submitBtnIsLoading"
          @click="updateEnv"
          class="ma-0 rounded-7 my-2 ml-3 mr-auto font-14"
          color="primary"
          :disabled="!hasUpdatePermission"
        >
          <span class="ml-1 font-weight-bold"> حفظ التغيرات </span>
          <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'contractSettingModal',
  props: {
    dialogState: {
      type: Boolean,
      default: false
    },
    contractRenewmentProp: {
      type: Object,
      default() {
        return {}
      }
    },
    submitBtnIsLoading: {
      default: false,
      type: Boolean
    },
    hasUpdatePermission: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      contractRenewmentData: {},
      dueDate: [
        {
          nameAr: 'قبل',
          nameEn: 'before'
        },
        {
          nameAr: 'بعد',
          nameEn: 'after'
        }
      ]
    }
  },
  watch: {
    dialogState: {
      immediate: true,
      handler() {
        this.setNotificationsSettings()
      }
    }
  },
  computed: {
    monthDays() {
      const days = []
      for (let day = 1; day <= 90; day++) {
        days.push(day)
      }
      return days
    },
    dataToUpdate() {
      return this.contractRenewmentProp?.type === 'pm'
        ? {
            pmContractAutoRenewal: this.contractRenewmentData?.active ?? false,
            pmContractAutoRenewalDays: this.contractRenewmentData?.days ?? 0,
            pmContractAutoRenewalMethod:
              this.contractRenewmentData?.method ?? 'after'
          }
        : {
            rentContractAutoRenewal:
              this.contractRenewmentData?.active ?? false,
            rentContractAutoRenewalDays: this.contractRenewmentData?.days ?? 0,
            rentContractAutoRenewalMethod:
              this.contractRenewmentData?.method ?? 'after'
          }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    updateEnv() {
      this.$emit('updateEnv', this.dataToUpdate)
    },
    closeDialog() {
      this.$emit('closeContractSettingModal')
    },
    setNotificationsSettings() {
      this.contractRenewmentData = { ...this.contractRenewmentProp }
    }
  }
}
</script>
