<template>
  <v-card class="property-card team-card" :class="classes">
    <v-list-item class="card-title" to="/dashboard/user/1">
      <v-list-item-avatar
        v-if="!gridView"
        class="white--text rounded-circle property-icon"
        tile
        :size="gridView ? 50 : 40"
      >
        <span class="hi-user-icon-div">
          <img
            src="../../assets/img/svgs/Hectar icon/user 2.svg"
            alt=""
            width="50"
            loading="lazy"
          />
        </span>
      </v-list-item-avatar>
      <v-list-item-content class="user-info">
        <v-list-item-subtitle>اسم بيئة العمل</v-list-item-subtitle>
        <v-list-item-title class="headline">{{
          options.name
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div class="team-info property-info">
      <v-list-item dense class="phone-number"> </v-list-item>

      <v-list-item dense> </v-list-item>

      <v-list-item dense v-if="!gridView">
        <v-list-item-content>
          <v-list-item-subtitle> الصلاحية</v-list-item-subtitle>
          <v-list-item-title>{{
            options.inviteList[0].role
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <div style="width= 50px;" class="team-info property-info response">
      <v-btn color="#66328e" @click="response(true)" class="responseAgree">
        الانضمام
      </v-btn>

      <v-btn color="#FF6F61" @click="response(false)" class="responseDec"
        >الرفض</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import { EnvService } from '../../services'
import { mapMutations } from 'vuex'

export default {
  props: ['usedFor', 'classes', 'gridView', 'options'],
  data() {
    return {
      dropdownItems: [
        { title: 'تعديل صلاحية الموظف', icon: 'mdi-square-edit-outline' },
        { title: 'حذف الموظف', icon: 'mdi-trash-can-outline' }
      ]
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    response(result) {
      EnvService.invitationResponse({ _id: this.options._id, accept: result })
        .then((res) => {
          if (res.data && res.data.invitation) {
            const currentEnv = JSON.parse(localStorage.getItem('currentEnv'))
            if (!currentEnv) {
              this.addNotification({
                text: `اهلا بك في بيئة ${res.data.invitation.name}`,
                color: 'success'
              })

              localStorage.setItem(
                'currentEnv',
                JSON.stringify(res.data.invitation)
              )

              this.$router.push(this.$route.query.redirectFrom || '/dashboard')
            }
          }

          this.addNotification({
            text: `تم ${result ? 'قبول' : ' رفض'} الدعوة`,
            color: 'success'
          })
        })
        .catch(() =>
          this.addNotification({
            text: 'حدث خطأ داخلي',
            color: 'error'
          })
        )
    }
  },
  computed: {}
}
</script>
