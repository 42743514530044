<template>
  <v-flex md4 class="mr-2">
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab> التعليقات </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div
          class="commentsSection ma-0 d-flex flex-column"
          style="height: 90vh"
        >
          <!-------------------------------------------->
          <!-------------comments loader---------------->
          <!-------------------------------------------->
          <v-row class="mx-0" v-if="commentsAreLoading || isSideDetailsLoading">
            <v-col cols="12" v-for="i in 3" :key="i">
              <v-skeleton-loader
                class="background-transparent"
                width="300"
                type="list-item-avatar-two-line"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <!-------------------------------------------->
          <!-------------no comments-------------------->
          <!-------------------------------------------->
          <div class="ma-auto" v-else-if="!comments.length">
            <v-sheet>
              <span>
                <v-img
                  class="ma-auto"
                  width="72"
                  height="72"
                  :src="noComments"
                ></v-img>
                <span> ليست هناك اي تعليقات </span>
              </span>
            </v-sheet>
          </div>
          <!-------------------------------------------->
          <!-------------comments data------------------>
          <!-------------------------------------------->
          <div v-else class="comments mb-auto mr-2 mt-7">
            <CommentCard
              v-for="(comment, index) in comments"
              :key="comment._id"
              v-bind="generateCommentProps(comment)"
              @setActiveComment="setActiveComment"
              @setActiveTextArea="setActiveTextArea"
              @updateComment="updateComment($event, index)"
              @deleteComment="deleteComment($event, index)"
            />
          </div>
          <v-sheet
            class="mb-1 mr-4"
            v-if="$hasPermission('properties', 'contracts', 'add')"
          >
            <v-form>
              <v-textarea
                @keyup.enter.exact="addComment"
                auto-grow
                class-content="commentTextArea font-12"
                filled
                hide-details
                no-resize
                placeholder="أدخل تعليقك هنا"
                rows="1"
                v-model="commentContent"
              >
                <template v-slot:append>
                  <img
                    @click="addComment"
                    alt="send text"
                    class="px-3 pointerCursor"
                    src="@/assets/img/svgs/send.svg"
                    loading="lazy"
                  />
                </template>
              </v-textarea>
            </v-form>
          </v-sheet>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-flex>
</template>

<script>
import CommentCard from '@/components/contracts/CommentCard.vue'
import { messageService } from '@/services'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'ApprovalComments',
  components: {
    CommentCard
  },
  props: {
    approval: {
      type: Object,
      default: () => {}
    },
    isSideDetailsLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tab: 0,
      comments: [],
      item: {},
      commentContent: '',
      activeComment: null,
      commentsAreLoading: false,
      noComments: require('@/assets/img/svgs/noComments.svg')
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    isValidComment: () => (commentContent) => {
      return !!commentContent.length
    }
  },
  watch: {
    approval: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal?._id && oldVal?._id !== newVal?._id) {
          this.loadComments()
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setActiveComment(comment) {
      this.activeComment = comment
    },
    generateCommentProps(comment) {
      return {
        comment,
        commentId: this.activeComment,
        activeTextArea: this.activeTextArea
      }
    },
    setActiveTextArea(comment) {
      this.activeTextArea = comment
    },
    updateComment(newComment, commentIndex) {
      if (this.isValidComment(newComment.content)) {
        const updatedCommentToSend = {
          content: newComment.content,
          _id: newComment.commentId
        }
        messageService.updateComment(updatedCommentToSend).then(() => {
          this.$set(this.comments[commentIndex], 'content', newComment.content)
        })
      }
      this.activeTextArea = null
    },
    async deleteComment(commentId, commentIndex) {
      await messageService.deleteComment(commentId).then(() => {
        this.comments.splice(commentIndex, 1)
      })
    },
    async addComment() {
      if (this.isValidComment(this.commentContent)) {
        const commentToSend = {
          content: this.commentContent,
          entityType: 'approval',
          entityId: this.approval._id,
          author: {
            _id: this.user._id,
            name: this.user.name,
            email: this.user.email,
            phoneNumber: this.user.phoneNumber,
            type: this.user.type
          },
          context: {
            platform: 'hectarPlus'
          }
        }
        this.commentContent = ''
        await messageService.addComment(commentToSend)
        this.loadComments()
      }
    },
    async loadComments() {
      try {
        this.commentsAreLoading = true
        const query = {
          entityId: this.approval._id
        }
        const { data } = await messageService.getComments(query)
        this.comments = data.items
        this.commentContent = ''
      } catch {
        this.addNotification({
          text: 'خطا في تحميل تعليقات العقد',
          color: 'error'
        })
      } finally {
        this.commentsAreLoading = false
      }
    }
  },
  mounted() {
    this.item = this.approval
  }
}
</script>
