<template>
  <div class="w-100 position-relative d-flex align-center pb-3 content__row">
    <!--------------------------->
    <!----------Log icon--------->
    <!--------------------------->

    <div class="log__icon ml-3">
      <img max-width="32" max-height="32" :src="logIcon" loading="lazy" />
    </div>

    <!----------------------->
    <!----------Body--------->
    <!----------------------->

    <div class="d-flex align-center">
      <div>
        <h4 class="font-14 mb-0">
          {{ activityLog.body }}
        </h4>
        <div class="d-flex">
          <h6 v-if="addDate" class="content font-12 grey--text mt-0 ml-1">
            {{ logDate(activityLog) }}،
          </h6>
          <h6 class="content font-12 grey--text mt-0">الساعة {{ logTime }}</h6>
        </div>
      </div>
      <v-spacer></v-spacer>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'

export default {
  name: 'ActivityCard',
  mixins: [dateFormat],
  props: {
    activityLog: {
      type: Object,
      required: true
    },
    addDate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      usersIcon: require('@/assets/img/activity-log-icons/user-1.png'),
      realStatesIcon: require('@/assets/img/activity-log-icons/buildings.png'),
      propertyManagementIcon: require('@/assets/img/svgs/purple-contracts.svg'),
      messagesIcon: require('@/assets/img/activity-log-icons/message-icon.png')
    }
  },
  computed: {
    logIcon() {
      switch (this.activityLog?.context?.serviceName) {
        case 'propertyManagement':
          return this.propertyManagementIcon
        case 'realEstates':
          return this.realStatesIcon
        case 'messages':
          return this.messagesIcon
        default:
          return this.usersIcon
      }
    },
    logTime() {
      let logHour = new Date(this.activityLog?.createdAt).getHours()
      let logMinutes = new Date(this.activityLog?.createdAt).getMinutes()
      logMinutes = (logMinutes < 10 ? '0' : '') + logMinutes
      const pmAm = logHour >= 12 ? 'مساءً' : 'صباحاً'
      logHour %= 12
      logHour = logHour ? logHour : 12
      return `${logHour}:${logMinutes} ${pmAm}`
    },
    logDate() {
      return (value) => this.formatDate(value.createdAt)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/activityLog/activity-log.scss';
</style>
