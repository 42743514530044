<template>
  <div>
    <!--filters-->
    <!-- <billsFilters
      class="my-5 mobile__m__0"
      :availableFilters="availableFilters"
      @handleFilters="$emit('handleFilters', $event)"
    /> -->

    <!--no Bills-->
    <v-col v-if="!bills.length">
      <v-sheet
        class="pa-10 d-flex align-center justify-center flex-column rounded-15"
        height="60vh"
      >
        <v-img max-width="80" max-height="90" :src="noBills"></v-img>
        <h3 class="grey--text text--darken-3 my-1">
          ليس هناك فواتير مدفوعات حتى الأن
        </h3>
        <h5 class="grey--text text-center">
          يمكن دفع قيمة تفعيل أو تجديد الإشتراك وعرض حالة الدفع بعد إتمام
          العميلة
        </h5>
      </v-sheet>
    </v-col>

    <dataTableBills
      v-else
      :bills="bills"
      :headers="headers"
      :bundles="bundles"
      @setClickedBill="generatePdf"
    />
    <!--Pagination-->
    <div class="text-center mt-6 mb-5" v-if="pagination.pagesCount > 1">
      <Pagination :pagination="pagination" @onChange="$emit('getBills')" />
    </div>
    <div class="d-flex align-center justify-end ml-md-8 fast-actions">
      <v-btn
        data-test="openPaymentDialog"
        class="font-weight-bold fast-actions-btn pa-5 font-12"
        color="primary"
        large
        @click="openPaymentDialog"
      >
        <span class="">ادفع الآن</span>
      </v-btn>
    </div>

    <!---------Generate PDF------------>
    <VueHtml2pdf v-bind="dataVuePdf" v-show="false">
      <section slot="pdf-content">
        <div class="pdf__content">
          <InvoicePdf v-bind="invoicePdfProps" />
        </div>
      </section>
    </VueHtml2pdf>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import dateFormat from '@/mixins/dateFormat.js'
import dataTableBills from '@/components/bills/dataTableBills'
import billsFilters from '@/components/bills/billsFilters'
import Pagination from '@/components/helper/pagination.vue'
import AddBundle from '@/components/profile/add-bundle'
import VueHtml2pdf from 'vue-html2pdf'
import InvoicePdf from '@/components/Invoice-pdf/Invoice-pdf.vue'

export default {
  name: 'bills',
  mixins: [dateFormat],
  components: {
    Pagination,
    dataTableBills,
    billsFilters,
    AddBundle,
    VueHtml2pdf,
    InvoicePdf
  },
  props: {
    bills: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    bundles: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('accountState', ['subscription']),
    invoicePdfProps() {
      return {
        bill: this.clickedBill,
        bundle: this.getClickedBillBundle
      }
    },
    getClickedBillBundle() {
      const bundle = this.bundles.find(
        (bundle_) => bundle_._id === this.clickedBill.subscription
      )
      return bundle
    },
    dataVuePdf() {
      return {
        showLayout: false,
        enableDownload: true,
        previewModal: false,
        paginateElementsByHeight: 3500,
        pdfQuality: 2,
        manualPagination: false,
        pdfMargin: 0,
        pdfFormat: 'a4',
        ref: 'html2Pdf',
        filename: this.clickedBill?.trxId
      }
    }
  },
  data: () => {
    return {
      clickedBill: {},
      noBills: require('@/assets/img/svgs/contract/noContracts.svg'),
      availableFilters: [
        { nameAr: 'الكل' },
        { nameAr: 'تحت المراجعه', nameEn: 'pending' },
        { nameAr: 'مرفوض', nameEn: 'rejected' },
        { nameAr: 'مقبول', nameEn: 'paid' }
      ],
      headers: [
        {
          text: 'رقم الفاتورة',
          align: 'center',
          value: 'trxId',
          sortable: false
        },
        {
          text: 'تاريخ الفاتورة',
          value: 'createdAt',
          align: 'center',
          sortable: false
        },
        {
          text: 'نوع الفاتورة',
          value: 'subscription',
          align: 'center',
          sortable: false
        },
        {
          text: 'التفاصيل',
          value: 'details',
          align: 'center',
          sortable: false
        },
        {
          text: 'حاله الفاتوره',
          value: 'status',
          align: 'center',
          sortable: false
        },
        {
          text: 'عرض',
          value: 'attachment',
          align: 'center',
          sortable: false
        },
        {
          text: 'عرض',
          value: 'pdf',
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  methods: {
    openPaymentDialog() {
      this.$emit('setBundle', {
        bundle: this.subscription.bundle,
        period: this.subscription?.period
      })
    },
    generatePdf(bill) {
      this.clickedBill = bill
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
</style>
