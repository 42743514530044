import axios from 'axios'
import config from '../config'
import store from '@/store/index'

// User Invitations
const inviteEmployee = function (data) {
  const url = `${config.usersUrl}/invitations/add`
  return axios.post(url, data)
}
// bulk User Invitations
const inviteEmployees = function (data, envId) {
  const body = {
    invitations: data,
    environmentId: envId
  }
  const url = `${config.usersUrl}/invitations/bulk-add`
  return axios.post(url, body)
}
// User Invitations
const updateInvite = function (data) {
  const url = `${config.usersUrl}/invitations/update/${data._id}`
  return axios.put(url, data)
}

const getAllEmployee = function (env, params = '') {
  const url = `${config.usersUrl}/users/getAll?environmentId=${env}&employeesOnly=1${params}`
  return axios.get(url)
}

const getAllEmployeeCount = function (env) {
  const url = `${config.usersUrl}/users/getAll/count?environmentId=${env}&employeesOnly=1`
  return axios.get(url)
}

const checkEmployee = function (role, env, phoneNumber, email) {
  if (!phoneNumber) {
    throw new Error('phoneNumberRequired')
  }
  const url = `${config.usersUrl}/invitations/check-employee?environmentId=${env}&phoneNumber=${phoneNumber}&role=${role}&email=${email}`

  return axios.get(url)
}

const checkClient = function (body) {
  const url = `${config.usersUrl}/clients/check-client-existing`

  return axios.post(url, body)
}

const getUserInvitations = function () {
  const url = `${config.apiUrl}/userInvitation`
  return axios.get(url)
}

const invitationResponse = function (body) {
  const url = `${config.apiUrl}/invitationResponse`
  return axios.post(url, body)
}

//  adding Clients
const addClient = function (data) {
  const url = `${config.usersUrl}/clients/add`
  return axios.post(url, data)
}

//  get Client Info
const getClientInfo = function (userId, envId) {
  const url = `${config.apiUrl}/getClientInfo?envId=${envId}&userId=${userId}`
  return axios.get(url)
}

//  edit Clients
const editClient = function (data, id) {
  const url = `${config.usersUrl}/users/update/${id}`
  return axios.put(url, data)
}

const getAllClients = function (env, query = '', data = '') {
  const queryParams = `${query ? `&${query}` : ''}${data ? `&${data}` : ''}`
  const url = `${config.usersUrl}/users/getAll?clientsOnly=1&environmentId=${env}${queryParams}`
  const listClientPermission = store.getters['accountState/hasPermission'](
    'users',
    'clients',
    'list'
  )
  return listClientPermission ? axios.get(url) : {}
}

const getAllClientsCount = function (env, query = '') {
  const url = `${config.usersUrl}/users/getAll/count?clientsOnly=1&environmentId=${env}${query}`
  return axios.get(url)
}

const deleteTeamMember = function (id, envId) {
  const url = `${config.usersUrl}/invitations/delete/${id}/${envId}`
  return axios.delete(url)
}
// Contracts
// Check real estate Contract

const getRealEstateContract = function (realEstate) {
  const url = `${config.apiUrl}/checkRealEstateContract?realEstate=${realEstate}`
  return axios.get(url)
}

const getContractConfig = function () {
  const url = `${config.apiUrl}/config`
  return axios.get(url)
}

const createContract = function (data) {
  const url = `${config.apiUrl}/createContract`
  return axios.post(url, data)
}

const editContract = function (data) {
  const url = `${config.apiUrl}/editContract`
  return axios.post(url, data)
}

const getContracts = function (id) {
  const url = `${config.apiUrl}/getEnvContracts?env=${id}`
  return axios.get(url)
}

//  Vouchers
const addVouchers = function (data) {
  const url = `${config.apiUrl}/addVouchers`
  return axios.post(url, data)
}

const editVouchers = function (data) {
  const url = `${config.apiUrl}/editVouchers`
  return axios.post(url, data)
}

const getContractDetails = function (envId, _id) {
  const url = `${config.apiUrl}/getContractDetails?envId=${envId}&_id=${_id}`
  return axios.get(url)
}

const createEnv = function (body) {
  const url = `${config.usersUrl}/environments/add`
  return axios.post(url, body)
}

const getEnvData = function (envId) {
  const url = `${config.usersUrl}/environments/get/${envId}`
  return axios.get(url)
}

const addEnvBankAccount = function (payload) {
  const url = `${config.usersUrl}/bank-accounts/many`
  return axios.post(url, payload)
}

const getEnvOrClientBankAccounts = function (envId, id) {
  const url = `${
    config.usersUrl
  }/bank-accounts?environmentId=${envId}&entityId=${id ?? envId}&entityType=${
    id ? 'Client' : 'Environment'
  }`
  return axios.get(url)
}

const deleteBankAccounts = function (bankAccountId) {
  const url = `${config.usersUrl}/bank-accounts/${bankAccountId}`
  return axios.delete(url)
}

const updateBankAccount = function (bankAccountId, payload) {
  const url = `${config.usersUrl}/bank-accounts/${bankAccountId}`
  return axios.put(url, payload)
}

const getRealestateBankAccounts = function ({
  realEstateId,
  parentId = realEstateId,
  environmentId
}) {
  const url = `${config.usersUrl}/realestate-bank-accounts?realEstateId=${realEstateId}&realEstateId=${parentId}&environmentId=${environmentId}`
  return axios.get(url)
}

const updateRealestateBankAccounts = function (payload) {
  const url = `${config.usersUrl}/realestate-bank-accounts`
  return axios.put(url, payload)
}
const getDepartments = function (query) {
  const url = `${config.usersUrl}/env-departments${query}`
  return axios.get(url)
}
const addNewDepartment = function (payload) {
  const url = `${config.usersUrl}/env-departments`
  return axios.post(url, payload)
}
const updateDepartment = function (id, payload) {
  const url = `${config.usersUrl}/env-departments/${id}`
  return axios.patch(url, payload)
}
const delDepartment = function (id) {
  const url = `${config.usersUrl}/env-departments/${id}`
  return axios.delete(url)
}

const addJobTitle = function (payload) {
  const url = `${config.usersUrl}/env-job-titles`
  return axios.post(url, payload)
}
const editJobTitle = function (id, payload) {
  const url = `${config.usersUrl}/env-job-titles/${id}`
  return axios.patch(url, payload)
}
const deleteJobTitle = function (id) {
  const url = `${config.usersUrl}/env-job-titles/${id}`
  return axios.delete(url)
}

const getJobTitles = function (query = '') {
  const url = `${config.usersUrl}/env-job-titles${query}`
  return axios.get(url)
}
export const EnvService = {
  inviteEmployee,
  checkEmployee,
  addNewDepartment,
  addJobTitle,
  editJobTitle,
  deleteJobTitle,
  getJobTitles,
  updateDepartment,
  getDepartments,
  delDepartment,
  checkClient,
  getAllEmployee,
  getAllEmployeeCount,
  getUserInvitations,
  getAllClients,
  getAllClientsCount,
  addClient,
  editClient,
  getClientInfo,
  invitationResponse,
  getRealEstateContract,
  getContractConfig,
  createContract,
  getContracts,
  editContract,
  addVouchers,
  getContractDetails,
  editVouchers,
  createEnv,
  getEnvData,
  deleteTeamMember,
  updateInvite,
  inviteEmployees,
  addEnvBankAccount,
  getEnvOrClientBankAccounts,
  deleteBankAccounts,
  updateBankAccount,
  getRealestateBankAccounts,
  updateRealestateBankAccounts
}
