<template>
  <v-sheet
    class="d-flex align-center justify-center flex-column bg-primary rounded-15"
    height="600"
  >
    <v-img class="vert-move" width="170" max-height="150" :src="upgradeIcon" />
    <h3 class="black--text text--darken-3 my-0">
      {{ viewContent.title }}
    </h3>
    <h5 class="font-16 grey--text text-center">
      {{ viewContent.description }}
    </h5>
    <v-btn
      v-if="isEnvOwner"
      elevation="0"
      depressed
      color="primary rounded-15"
      @click="routeToSubscription"
    >
      <span class="mx-1 font-18"> ترقيه الباقه </span>
    </v-btn>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'upgradeView',
  data() {
    return {
      upgradeIcon: require('@/assets/img/svgs/arrowUpTag.svg')
    }
  },
  methods: {
    routeToSubscription() {
      this.$router.push('/dashboard/edit-profile/4')
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    isEnvOwner() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    },
    viewContent() {
      switch (this.isEnvOwner) {
        case true:
          return {
            title: 'عفواً, هذه الخاصية غير متاحة في الباقة الحالية',
            description:
              'يمكنك ترقية الباقة الحالية حتي تتمكن من إستخدام هذه الخاصية'
          }
        case false:
          return {
            title: 'عفواً، ليس لديك الصلاحيه لتصفح هذه الصفحه',
            description:
              'يمكنك تصفح هذه الصفحة اذا ما قام صاحب البيئه بإعطائك الصلاحيه لذلك'
          }
        default:
          return {
            title: '',
            description: ''
          }
      }
    }
  }
}
</script>
