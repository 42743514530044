<template>
  <div>
    <v-tabs
      align-with-title
      color="primary"
      right
      v-model="tab"
      class="setting__page__tabs"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <template>
        <v-tab
          :ripple="false"
          class="mr-6 rounded-15"
          v-for="item in availableTabs"
          :key="item.title"
        >
          {{ item.title }}
        </v-tab>
      </template>
      <v-tabs-items class="setting__page__tabs" v-model="tab">
        <v-tab-item
          v-for="item in availableTabs"
          :key="item.title"
          :eager="true"
        >
          <loader v-if="isPageLoading" v-bind="loaderProps" class="mt-2" />
          <component
            v-else
            :is="item.name"
            v-on="$listeners"
            :headers="selectedHeader"
            :users="selectedUsers"
            :tabName="item.title"
            :departmnets="departmnets"
            :pagination="pagination"
            @onChange="refreash"
            @reloadDepartments="refreash"
            @delete="handleDeletion"
          />
          <!--Pagination-->
          <div class="text-center mt-6 mb-5" v-if="pagination.pagesCount > 1">
            <Pagination :pagination="pagination" @onChange="refreash" />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>
<script>
import Team from '@/views/Team.vue'
import DepartMentSettings from '@/views/DepartmentSettings.vue'
import loader from '@/components/helper/loader.vue'
import Pagination from '@/components/helper/pagination.vue'
import { EnvService } from '@/services'
import { buildQueryString } from '@/utils/general'
import { mapState, mapMutations } from 'vuex'

export default {
  components: { Team, DepartMentSettings, loader, Pagination },
  data() {
    return {
      tab: 0,
      departmnets: [],
      jobTitiles: [],
      paginationDepartments: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 8,
        count: 0
      },
      paginationJobTitles: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 8,
        count: 0
      },
      isPageLoading: false,
      availableTabs: [
        {
          title: 'أعضاء الفريق',
          name: 'Team'
        },
        {
          title: 'الأقسام',
          name: 'DepartMentSettings'
        },
        {
          title: 'المسميات الوظيفية',
          name: 'DepartMentSettings'
        }
      ]
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    selectedHeader() {
      return this.availableTabs[this.tab].title === 'الأقسام'
        ? this.departmentHeader
        : this.TitleHeaders
    },
    selectedUsers() {
      return this.availableTabs[this.tab].title === 'الأقسام'
        ? this.departmnets
        : this.jobTitiles
    },
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    pagination() {
      return this.availableTabs[this.tab].title === 'الأقسام'
        ? this.paginationDepartments
        : this.paginationJobTitles
    },
    departmentHeader() {
      return [
        { text: 'أسم القسم', value: 'name' },
        { text: 'عدد المسميات الوظيفية', value: 'jobTitlesCount' },
        { text: 'عدد الموظفين', value: 'text' },
        { text: 'عدد المشرفين', value: 'approved' },
        { text: 'عدد المدراء', value: 'controls' },
        { text: 'تعديل', value: 'edit' },
        { text: 'حذف', value: 'delete' }
      ]
    },
    TitleHeaders() {
      return [
        { text: 'المسمي الوظيفي', value: 'name' },
        { text: 'الفسم', value: 'titles' },
        { text: 'عدد الموظفين', value: 'text' },
        { text: 'عدد المشرفين', value: 'approved' },
        { text: 'عدد المدراء', value: 'controls' },
        { text: 'تعديل', value: 'edit' },
        { text: 'حذف', value: 'delete' }
      ]
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    recalculatePagination(res, key) {
      this[key].pagesCount = Math.ceil(res.count / this[key].pageSize)
      this[key].count = res.count
    },
    async refreash() {
      await this.getAllJobTitles()
      await this.getAllDepartments()
    },
    handleDeletion(item) {
      if (item.hasOwnProperty('jobTitlesCount')) {
        this.deleteDepartment(item)
      } else {
        this.deleteJobTitle(item)
      }
    },
    async getAllDepartments() {
      this.isPageLoading = true
      try {
        const query = buildQueryString({
          environmentId: this.currentEnv?._id,
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber
        })
        const { data } = await EnvService.getDepartments(query)
        this.recalculatePagination(data, 'paginationDepartments')

        this.departmnets = data.envDepartments
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل الأقسام, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async updateDepartments(item) {
      this.isPageLoading = true
      try {
        await EnvService.updateDepartment(item._id)
        this.addNotification({
          text: 'تم تحديث الأقسام بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحديث الأقسام, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    deleteDepartment(item) {
      this.$root.$confirm
        .open({
          title: 'هل أنت متأكد من حذف القسم',
          message: 'الرجاء إختيار تأكيد في حال الرغبة في حذف القسم',
          options: {
            color: 'danger',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            EnvService.delDepartment(item._id).then(() => {
              this.getAllDepartments()
            })
          }
        })
        .catch(() => {
          this.addNotification({
            text: 'لم يتم التأكيد على حذف القسم',
            color: 'error'
          })
        })
    },
    async getAllJobTitles() {
      this.isPageLoading = true
      try {
        const query = buildQueryString({
          environmentId: this.currentEnv?._id,
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber
        })
        const { data } = await EnvService.getJobTitles(query)
        this.recalculatePagination(data, 'paginationJobTitles')
        this.jobTitiles = data.envJobTitles
      } catch {
      } finally {
        this.isPageLoading = false
      }
    },
    async updateJobTitles(item) {
      this.isPageLoading = true
      try {
        await EnvService.editJobTitle(item._id)
        this.addNotification({
          text: 'تم تحديث المسمي الوظيفي بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحديث المسمي الوظيفي, حاول مره أخري',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    deleteJobTitle(item) {
      this.$root.$confirm
        .open({
          title: 'هل أنت متأكد من حذف المسمي الوظيفي',
          message: 'الرجاء إختيار تأكيد في حال الرغبة في حذف المسمي الوظيفي',
          options: {
            color: 'danger',
            width: 400,
            zIndex: 200
          }
        })
        .then((confirm) => {
          if (confirm) {
            EnvService.deleteJobTitle(item._id).then(() => {
              this.getAllJobTitles()
            })
          }
        })
        .catch(() => {
          this.addNotification({
            text: 'لم يتم التأكيد على حذف القسم',
            color: 'error'
          })
        })
    }
  },
  async mounted() {
    await this.refreash()
  }
}
</script>
