import { popUpBodyTemplates } from '@/constans/approval'

export default {
  methods: {
    showConfirmationPopupMixin() {
      const popUpBody = popUpBodyTemplates.find(
        (body) => body.status === 'inform approval required'
      )
      this.$root.$confirm.open(popUpBody).then((confirm) => {
        if (!confirm) {
          this.$router.push('/dashboard/approval')
        }
      })
    }
  }
}
