<template>
  <v-card class="d-flex flex-column" :height="240">
    <!-- tabs-card-->
    <v-card-title
      class="d-flex justify-space-between flex-row pa-2 flex-grow-0"
    >
      <v-tabs
        v-model="tab"
        align-with-title
        right
        class="overflow-x-hidden"
        color="black"
        grow
        show-arrows
      >
        <v-tabs-slider color="black"></v-tabs-slider>
        <v-tab
          v-for="item in tabs"
          :key="item"
          color="black"
          class="font-weight-bold h5 ma-0 mr-3 pa-0"
        >
          {{ item.nameAr || item }}
        </v-tab>
      </v-tabs>
    </v-card-title>

    <!-- tabs-items-->
    <v-tabs-items class="my-3" v-model="tab">
      <v-tab-item v-for="(item, i) in tabs" :key="i">
        <div
          v-if="i + 1 != tabs.length"
          class="ml-4 pt-0 mb-4 flex-grow-1"
          style="overflow-y: auto"
        >
          <ownerList :propertyParties="files[i]" />
        </div>
        <div
          class="ma-0 pt-0 flex-grow-1"
          :style="{ 'overflow-y: auto': !realEstateBankAccounts?.length }"
        >
          <div class="px-5 d-flex">
            <div
              class="d-flex align-center"
              v-if="realEstateBankAccountsLoading"
            >
              <v-progress-circular
                :size="40"
                :width="3"
                color="#662d91"
                indeterminate
                class="mx-auto mt-10"
              />
            </div>
            <div
              class="bankAccountsContainer px-1"
              v-else-if="
                realEstateBankAccounts?.length && hasListBankAccountsPermission
              "
            >
              <div
                class="d-flex justify-space-between my-1"
                v-for="account in realEstateBankAccounts"
                :key="account?._id"
              >
                <p class="ma-0 my-auto text--101010 font-14">
                  {{ account.bankName }}
                </p>
                <p class="ma-0 my-auto text--101010 font-14">
                  {{ account.name }}
                </p>
                <p class="ma-0 my-auto text--101010 font-14">
                  {{ account.iban }}
                </p>
                <v-icon
                  @click="$emit('openSelectModel')"
                  v-if="isEditBankAllowed"
                >
                  mdi-pencil-outline
                </v-icon>
              </div>
            </div>
            <div v-else class="d-flex my-auto mx-auto">
              <p class="my-auto mx-5 font-14 font-weight-bold text-center">
                {{
                  hasListBankAccountsPermission
                    ? 'ليست هناك حسابات بنكية معتمدة لهذا العقار'
                    : 'ليس لديك صلاحيه لعرض الحسابات البنكيه'
                }}
              </p>
              <v-icon
                size="20"
                class="my-auto"
                @click="$emit('openSelectModel')"
                v-if="isEditBankAllowed"
              >
                mdi-pencil-outline
              </v-icon>
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-card-actions class="mt-3" v-if="link && link.name">
      <router-link
        :to="link.url"
        class="font-weight-bold text-center w-100 py-4"
      >
        <span>
          {{ link.name }}
          <v-icon color="primary">mdi-chevron-left</v-icon>
        </span>
      </router-link>
    </v-card-actions>
  </v-card>
</template>

<script>
import ownerList from '@/components/details/ownerList'
export default {
  name: 'detailsTabs',
  components: { ownerList },
  computed: {
    hasListBankAccountsPermission() {
      return !!this.$hasPermission('users', 'bankAccountsSettings', 'list')
    },
    md() {
      return this.$vuetify.breakpoint.lg
    }
  },
  props: {
    isEditBankAllowed: {
      default: false,
      type: Boolean
    },
    realEstateBankAccountsLoading: {
      default: true,
      type: Boolean
    },
    files: {
      default: () => []
    },
    title: {},
    filters: {
      default: () => []
    },
    onFilter: {},
    link: {
      default: () => {
        return {}
      }
    },
    tabs: {
      default: () => []
    },
    action: {
      type: String
    },
    realEstateBankAccounts: {
      default() {
        return []
      }
    }
  },
  data() {
    return {
      tab: 0
    }
  }
}
</script>

<style lang="scss">
.bankAccountsContainer {
  max-height: 120px !important;
  width: 100%;
  scroll-behavior: smooth;
  overflow-y: scroll;
  overflow-x: hidden;
}
.h-100 {
  height: 100%;
}

.scroll {
  overflow-x: scroll;
}
</style>
