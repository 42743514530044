<template>
  <div class="show-detailes pdf-summary-container">
    <v-slide-x-transition>
      <v-layout>
        <v-col :cols="4" lg="5" class="whiteSide pa-0">
          <!-- PDF Header -->
          <PDFHeader
            v-bind="{ tab, maxTab }"
            @downloadPDF="downloadPDF"
            @signatureStatus="setSign"
            @closePDF="$emit('closePDF')"
          >
            <!-- <div class="ms-3">
              <Actions
                v-bind="{ quotation, canceledBtnStatus: false }"
                @accept="$emit('accept', 'accepted')"
                @reject="$emit('reject', 'rejected')"
                @cancelled="$emit('cancelled', 'cancelled')"
              />
            </div> -->
          </PDFHeader>
          <v-divider class="mt-3"></v-divider>
          <!-- Content -->
          <QuotationPDF
            v-bind="{ quotation, maxTab, signatures }"
            @changeTab="tab = $event"
            @changeMaxTab="maxTab = $event"
          />
        </v-col>
      </v-layout>
    </v-slide-x-transition>
  </div>
</template>
<script>
import PDFHeader from '@/components/priceOffers/PDF/PDFHeader.vue'
import QuotationPDF from '@/components/priceOffers/PDF/QuotationPDF.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PDF',
  components: {
    PDFHeader,
    QuotationPDF
  },
  props: {
    quotation: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      signatures: {
        companySeal: false,
        rentSignature: false
      },
      tab: 0,
      maxTab: 10
    }
  },
  computed: {
    ...mapGetters('accountState', ['companyImages']),
    dataVuePdf() {
      return {
        showLayout: false,
        enableDownload: true,
        previewModal: false,
        paginateElementsByHeight: 1000,
        pdfQuality: 2,
        manualPagination: true,
        pdfMargin: 5,
        ref: 'html2Pdf',
        // filename: this.quotation.title,
        pdfFormat: 'a4',
        pdfContentWidth: '100%',
        html2canvas: {
          useCORS: true
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    setSign({ type, status }) {
      this.signatures[type] = status
    },
    downloadPDF() {}
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/pdf/_contractPDF.scss';
</style>
