<template>
  <div>
    <v-form>
      <!--  Ensurance -->
      <v-row no-gutters class="mb-5">
        <v-col class="pr-3 mobile__pl__0 py-2" cols="12" sm="6">
          <h6 class="ma-0 mb-2">قيمة المستحقات</h6>
          <v-text-field
            class="pl-3 mobile__pl__0"
            dense
            filled
            hide-details
            placeholder="قيمة المستحقات"
            suffix="ريال"
            @input="handleValuesChanging($event, openingClaimsKeys, 'maskVals')"
            :value="maskVals['openingClaimsMask']"
            @keydown="$formatNumberInputs($event, true)"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="mt-2 mb-10" cols="12" sm="12">
          <v-card class="informativeCard pa-2">
            <div class="holder d-flex align-center">
              <div class="mr-3 ml-5">
                <v-img :src="exclamation" />
              </div>
              <div class="text">
                <span>
                  <p class="headTitle font-14 font-weight-bold ma-0">
                    هذه المستحقات لا تدخل في قيمة العقد الكلية وتحسب لمرة واحدة
                    خلال فترة العقد
                  </p>
                </span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import inputMasking from '@/mixins/inputMasking.js'
export default {
  name: 'contractFinancialDues',
  props: {
    exclamation: {
      default: require('@/assets/img/svgs/exclamationMark.svg')
    },
    updatedContract: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    },
    isPmContract: {
      default: false
    }
  },
  data() {
    return {
      maskVals: {
        openingClaimsMask: ''
      },
      contractInfo: {}
    }
  },
  mixins: [inputMasking],
  methods: {
    // Working on it - tying to find a way to build the obj calling dynamically

    emitValidationStatus() {
      this.$emit('setValdationStatus', true)
    },
    async bindContractData() {
      this.$emit('bindContractData', this.dataToBeBinded)
    },
    resetData() {
      this.contractInfo = {}
    },
    presetData() {
      this.editMoodPresetData()
      this.emitValidationStatus()
      if (this.isPmContract) {
        this.$emit('setLastTabStatus', true)
      }
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        this.$set(
          this.contractInfo,
          'openingClaims',
          this.updatedContract?.openingClaims
        )
        this.maskVals.openingClaimsMask =
          this.updatedContract?.openingClaims.toLocaleString('en-US')
      }
    }
  },
  computed: {
    dataToBeBinded() {
      return this.contractInfo
    },
    openingClaimsKeys() {
      return {
        maskValKey: 'openingClaimsMask',
        parentKey: 'contractInfo',
        actualValKey: 'openingClaims'
      }
    }
  }
}
</script>
