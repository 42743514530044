import config from '../config'
import axios from 'axios'

axios.defaults.headers.client = 'HectarPlus'

const getSubscriptions = function () {
  const url = `${config.usersUrl}/subscriptions/get`
  return axios.get(url)
}

const addSubscription = function (bundle) {
  const url = `${config.usersUrl}/subscriptions/create`
  return axios.post(url, bundle)
}

const updateSubscription = function (bundle) {
  const url = `${config.usersUrl}/subscriptions/change`
  return axios.post(url, bundle)
}

const getPayments = function (query = '') {
  const url = `${config.usersUrl}/payments?${query}`
  return axios.get(url)
}

const addPayment = function (query) {
  const url = `${config.usersUrl}/payments`
  return axios.post(url, query)
}

const AddpaymentAttachment = function (query) {
  const url = `${config.usersUrl}/images/upload`
  return axios.post(url, query)
}

const getBanks = function () {
  const url = `${config.usersUrl}/payments/bank-accounts`
  return axios.get(url)
}

const getSubscriptionPayment = function (params) {
  const url = `${config.usersUrl}/payments/bill`
  return axios.get(url, { params })
}

export const subscriptionService = {
  getSubscriptions,
  addSubscription,
  updateSubscription,
  addPayment,
  AddpaymentAttachment,
  getBanks,
  getPayments,
  getSubscriptionPayment
}
