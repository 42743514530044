<template>
  <div class="col-md-6 mobile__pt__0">
    <div>
      <div class="marketing-estate-withData">
        <div class="component-title">
          <h5 class="text-truncate font-16 mb-1 mobile__mt__0">
            إحصائيات هكتار
          </h5>
        </div>
        <div class="financial__statistics--cards py-2">
          <div
            class="card v-card v-sheet theme--light card-1 mb-2 px-5 py-4 justify-space-between align-start"
            v-for="hectarStat in hectarStats"
            :key="hectarStat.nameEn"
          >
            <div class="financial__statistics--tick--icon icon icon__green">
              <img
                height="15"
                width="15"
                :lazy-src="icons[hectarStat.nameEn] || icons['default']"
                :src="icons[hectarStat.nameEn] || icons['default']"
              />
            </div>
            <div class="card-title text-container">
              <p
                class="title text-truncate green-color"
                v-text="hectarStat.nameAr"
              />
              <h5
                class="col-value font-weight-bold"
                v-text="hectarStat.value"
              />
            </div>
            <div class="bottom-left-img">
              <img
                height="65"
                width="60"
                lazy-src="@/assets/img/svgs/statistics/blur-logo-white.svg"
                src="@/assets/img/svgs/statistics/blur-logo-white.svg"
              />
            </div>
          </div>
        </div>
        <!-- financial__statistics--cards -->
      </div>
      <!-- marketing-estate-status -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'HectarStatistics',
  props: {
    hectarStats: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      icons: {
        totalViews: require('@/assets/img/svgs/statistics/green-eye.svg'),
        totalLikes: require('@/assets/img/svgs/statistics/green-like.svg'),
        totalRatings: require('@/assets/img/svgs/statistics/green-review.svg'),
        totalSharing: require('@/assets/img/svgs/statistics/green-share.svg'),
        totalFavorites: require('@/assets/img/svgs/statistics/green-flag.svg'),
        default: require(`@/assets/img/svgs/building-g.svg`)
      }
    }
  }
}
</script>
