<template>
  <div id="invoice_new_pdf_page">
    <div class="invoice_pdf_content">
      <!-----Invoice info----->
      <div class="invoice_details border-bottom">
        <div class="invoice_details__content invoice__details__block">
          <div class="logo_div">
            <!-------report Logo-------->
            <div class="min-logo-white sidebar__mini__Logo">
              <img
                style="min-height: 100px; max-height: 100px; max-width: 100px"
                loading="lazy"
                :src="companyLogo"
                crossorigin="anonymous"
              />
            </div>
            <!-------report number-------->
            <div class="mt53 amount_div invoice__details__block__amount">
              <span class="font-15 color__title">رقم التقرير</span>
              <h6 class="font-16 text-truncate fw-500">
                {{ reportData.number }}
              </h6>
            </div>
          </div>
          <!----------Title---------------->
          <div class="invoice_date font-16 invoice__top__block__date">
            <div>
              <span class="font-15 color__title">إسم التقرير</span>
              <div class="invoice_creation_date mt-0 fw-500">
                {{ reportTitle }}
              </div>
            </div>
            <!-------Moderator name-------->
            <div
              class="mt100 invoice_company_name invoice__details__block__comopany__name"
            >
              <span v-if="reportUser" class="font-15 color__title">
                {{ reportOwnerRenterTitle }}
              </span>
              <h6 class="font-16 text-truncate fw-500">
                {{ reportUser }}
              </h6>
            </div>
          </div>
          <!-------Date info-------->
          <div class="from_to_date invoice__details__block__date">
            <div>
              <span class="font-15 color__title">المده الزمنية للتقرير</span>
              <h6 class="font-16 text-truncate fw-500">
                من {{ formatDate(reportData.start) }} الي
                {{ formatDate(reportData.end) }}
              </h6>
            </div>
            <!-------Created Date info-------->
            <div class="mt100 from_to_date invoice__details__block__date">
              <span class="font-15 color__title">تاريخ التقرير</span>
              <h6 class="font-16 text-truncate fw-500">
                {{ formatDate(reportData.createdAt) }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <slot />
      <!-- <invoicePdfFooter :userName="user.name" /> -->
    </div>
  </div>
  <!-- invoice_new_pdf_page -->
</template>
<script>
import dateFormat from '@/mixins/dateFormat.js'
import { mapState, mapGetters } from 'vuex'
import invoicePdfFooter from '@/components/Invoice-pdf/invoice-Pdf-footer.vue'

export default {
  name: 'InvoicePdfNew',
  components: {
    invoicePdfFooter
  },
  props: {
    reportData: {
      default() {
        return {}
      }
    },
    reportUser: {
      default: ''
    }
  },
  mixins: [dateFormat],
  computed: {
    ...mapState('accountState', ['user']),
    ...mapGetters('accountState', ['companyImages']),
    reportTitle() {
      return this.reportData.title.split(' - ')[0]
    },
    reportOwnerRenterTitle() {
      return this.reportData?.title?.includes('مالك')
        ? 'إسم المالك'
        : 'إسم المستأجر'
    },
    companyLogo() {
      const logo = this.companyImages?.companyLogo
      return logo ? logo.location : require('@/assets/img/logo.svg')
    }
  }
}
</script>
<style lang="scss">
@import '../../styles/responsiveness/responsiveness.scss';
$purple-color: #67328e;
$green-color: #3cb97d;
$white-color: #fff;
$page-bg-color: #ededed;
.mt100 {
  margin-top: 100px;
}
.mt53 {
  margin-top: 53px;
}
#invoice_new_pdf_page {
  background-color: $page-bg-color;
  height: 100%;
  @include max-lg {
    padding: 30px;
  }
  @include max-sm {
    padding: 10px;
  }
  .invoice_pdf_content {
    direction: rtl;
    padding: 40px 60px;
    background-color: $white-color;
    box-shadow: 8px 6px 20px #d6ccdc;
    @include max-md {
      padding: 30px;
    }
    @include max-sm {
      padding: 10px;
    }
    /*  */
    .mb-0 {
      margin-bottom: 0 !important;
    }
    .mt-0 {
      margin-top: 0 !important;
    }
    .invoice_status_div {
      .invoice_status_btn {
        span {
          border: 2px solid $green-color;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 120px;
          padding: 2px 6px 8px;
          font-weight: 500;
          color: $green-color;
          border-radius: 4px;
          @include max-sm {
            min-width: 100px;
            padding: 0px 4px 6px;
          }
        }
      }
      // invoice_status_btn
    }
    // invoice_status_div
    .invoice_top {
      padding: 30px 0 25px;
      @include max-sm {
        padding-top: 4px;
      }
      &__logo,
      &__info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include max-sm {
          &.mb-5 {
            margin-bottom: 12px !important;
          }
        }
      }
      @include max-sm {
        .invoice_status_div {
          order: 1;
          width: 100%;
          text-align: center;
          justify-content: center;
          margin-top: 10px;
        }
        // .invoice_status_div
        .invoice_date {
          text-align: left;
        }
        // .invoice_date
      }
    }
    .border-bottom {
      border-bottom: 2px solid #ddd;
    }
    .invoice_details {
      padding: 30px 0 25px;
      @include max-sm {
        padding: 20px 0 10px;
      }
      &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /*  */
        & > * {
          flex-shrink: 0;
        }
      }
    }
    .color__title {
      color: #a09f9e;
      margin-bottom: 6px;
      display: flex;
    }
  }
  .invoice_table {
    padding-top: 50px;
    @include max-sm {
      padding-top: 20px;
    }
    table {
      thead {
        @include max-sm {
          display: none;
        }
        tr {
          background-color: $purple-color !important;
          th {
            color: $white-color;
            padding-bottom: 14px;
            padding-top: 14px;
            @include max-md {
              padding-bottom: 0;
              padding-top: 6px;
              padding-left: 6px;
              padding-right: 6px;
              font-size: 0.85rem !important;
            }
            span {
              font-weight: normal !important;
              @include max-md {
                font-size: 0.9rem !important;
              }
            }
          }
          // th
        }
        // tr
      }
      // thead
      tbody {
        tr {
          &:nth-child(even) {
            background-color: #f7f7f7 !important;
          }
          td {
            padding-bottom: 14px;
            padding-top: 14px;
            font-weight: 500;
            max-width: 200px;
            @include max-md {
              padding-top: 6px;
              padding-bottom: 6px;
              padding-top: 6px;
              font-size: 0.75rem;
              @include max-sm {
                max-width: 100%;
              }
              // @include max-sm
            }
            // @include max-md
            &:first-child {
              text-align: right !important;
              @include max-md {
                padding-right: 20px;
              }
            }
          }
          // td
        }
        // tr
      }
      // tbody
    }
    // table
  }
  .after_table_content {
    padding-top: 20px;
    padding-bottom: 30px;
    @include max-sm {
      padding-top: 15px;
      flex-wrap: wrap;
      .qr_div {
        width: 100%;
        text-align: left;
      }
    }
    .transaction_detail {
      h4 {
        padding: 15px 25px;
        font-size: 1.2rem !important;
        margin: 0 0 1px !important;
        @include max-sm {
          width: 100%;
          font-size: 0.9rem !important;
          padding: 12px 15px;
        }
        &:first-child {
          background-color: $purple-color;
          color: #fff;
        }
        &:last-child {
          background-color: #f7f7f7;
        }
      }
    }
    // .transaction_detail
  }
  // after_table_content
  .tenant__name__block {
    .tenant__name__element {
      margin-top: 40px;
      padding: 8px;
      background: #f7f7f7;
      @include max-sm {
        margin-top: 20px;
      }
      // @include max-sm
    }
    // .tenant__name__element
  }
  // .tenant__name__block
  .f-normal {
    font-weight: normal;
  }
  .mw-26 {
    min-width: 26%;
  }
}
// #invoice_new_pdf_page
</style>
