<template>
  <div>
    <div
      v-if="isRequestLoading"
      class="d-flex justify-center align-center my-16"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="#662d91"
        indeterminate
      ></v-progress-circular>
    </div>

    <div v-else>
      <div class="mt-5 pb-8 amenities-container">
        <div class="px-5">
          <h6 class="font-14 font-weight-medium section-title">
            المرافق الداخلية
          </h6>
          <v-row>
            <v-col md="3" v-for="amenity in amenities" :key="amenity._id">
              <div
                class="d-flex align-center option-container cursor pr-2 py-2"
                :class="{
                  'selected-checkbox': contractInfo.amenities.includes(
                    amenity._id
                  )
                }"
              >
                <v-checkbox
                  dense
                  hide-details
                  :value="amenity._id"
                  v-model="contractInfo.amenities"
                ></v-checkbox>
                <span class="font-14 section-title mr-1">
                  {{ amenity.nameAr }}
                </span>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="mt-7 px-5 facilities-container">
        <h6 class="font-14 font-weight-medium section-title">
          المرافق الخارجية
        </h6>
        <v-row>
          <v-col sm="3" v-for="facility in facilities" :key="facility._id">
            <div
              class="d-flex align-center option-container cursor pr-2 py-2"
              :class="{
                'selected-checkbox': contractInfo.facilities.includes(
                  facility._id
                )
              }"
            >
              <v-checkbox
                dense
                hide-details
                :value="facility._id"
                v-model="contractInfo.facilities"
              ></v-checkbox>
              <span class="font-14 section-title mr-1">
                {{ facility.nameAr }}
              </span>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { contractsService } from '@/services'

export default {
  name: 'ContractAmenities',
  props: {
    updatedContract: {
      default() {
        return {}
      }
    },
    isEditMood: {
      default: false
    }
  },
  data() {
    return {
      amenities: [],
      facilities: [],
      contractInfo: {
        amenities: [],
        facilities: []
      },
      isRequestLoading: false
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    async getContractAmenitiesFacilities() {
      try {
        this.isRequestLoading = true
        const [contractAmenities, contractFacilities] = await Promise.all([
          await contractsService.getContractAmenities(),
          await contractsService.getContractFacilities()
        ])
        this.amenities = contractAmenities.data.amenities
        this.facilities = contractFacilities.data.facilities
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل مرافق العقد',
          color: 'error'
        })
      } finally {
        this.isRequestLoading = false
      }
    },
    emitValidationStatus() {
      this.$emit('setValdationStatus', true)
    },
    editMoodPresetData() {
      if (this.isEditMood) {
        const amenities = this.updatedContract.amenities.map((amn) => amn._id)
        const facilities = this.updatedContract.facilities.map((fac) => fac._id)

        this.$set(this.contractInfo, 'amenities', amenities || [])
        this.$set(this.contractInfo, 'facilities', facilities || [])
      }
    },
    presetData() {
      this.editMoodPresetData()
      this.emitValidationStatus()
      this.$emit('setLastTabStatus', true)
    },
    bindContractData() {
      this.$emit('bindContractData', this.contractInfo)
    }
  },
  async created() {
    await this.getContractAmenitiesFacilities()
  }
}
</script>

<style lang="scss" scoped>
.amenities-container {
  border-bottom: 4px solid #f7f7f7;
}
.section-title {
  color: #101010 !important;
}
.option-container {
  height: 28px;
  border: 1px solid #efebf2;
  border-radius: 12px;
  ::v-deep {
    .v-input,
    .v-input--selection-controls__input,
    .v-input--selection-controls__ripple {
      margin-top: -2px !important;
      margin-left: 5px !important;
      width: 14px !important;
      height: 14px !important;
    }
  }
}
.selected-checkbox {
  background: rgba(48, 44, 51, 0.1);
  border: 1px solid #662d91;
}
.facilities-container {
  margin-bottom: 100px;
}
</style>
