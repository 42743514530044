<template>
  <v-row class="conversations__card px-5 py-2 ma-0">
    <v-col cols="12">
      <v-row class="align-center flex-column">
        <!-- Header -->
        <v-col class="d-flex align-center pa-0">
          <v-avatar
            class="conversations__card__avatar font-12"
            rounded
            size="35"
            tile
            >ع إ</v-avatar
          >
          <h6 class="mx-2 font-14">عبد الله</h6>
          <p class="ma-0 mr-auto conversations__card__date">١٢ مارس ٢٠٢٣</p>
        </v-col>
        <!-- Body -->
        <v-col class="d-flex">
          <v-row class="justify-space-between">
            <v-col cols="12" sm="8" class="pa-0">
              <p class="conversations__card__desc font-12 mt-3">
                هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما
              </p>
            </v-col>
            <v-col
              v-if="likeStatus"
              cols="12"
              sm="2"
              class="pa-0 d-flex justify-end align-end mb-3"
            >
              <v-badge content="4" inline left>
                <img :src="likeFillIcon" alt="like" class="mr-1" />
              </v-badge>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ConversationsCard',
  props: {
    likeStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      likeIcon: require('@/assets/img/svgs/like.svg'),
      likeFillIcon: require('@/assets/img/svgs/like-fill.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/associations/associations.scss';
</style>
