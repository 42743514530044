<template>
  <v-card class="associations__members px-0">
    <v-card-title class="text-center mb-4">
      <!-- Header -->
      <v-row
        class="justify-space-between align-center font-16 border-bottom mb-3"
      >
        <v-col cols="5" class="pa-0 text-right">
          <v-badge content="4" inline>أعضاء الجمعية</v-badge>
        </v-col>
        <v-col cols="1" class="pa-0">
          <v-btn class="add-btn add__property__btn px-1 py-3" depressed x-small>
            <v-icon color="purple">mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- Body -->
      <v-row class="associations__members__wrapper align-content-start">
        <v-col
          cols="12"
          class="d-flex align-center justify-between pa-0 mb-3"
          v-for="test in forTest"
          :key="test.name"
        >
          <span class="font-14" v-text="test.name" />
          <span
            :class="[
              'associations__members__badge font-14 d-flex align-center justify-center px-3',
              { 'color-primary': test.status }
            ]"
            v-text="test.type"
          />
        </v-col>
      </v-row>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'AssociationsMembers',
  data() {
    return {
      forTest: [
        { name: 'عبد الله', type: 'عضو', status: false },
        { name: 'محمد يوسف', type: 'رئيس الجمعية', status: true },
        { name: 'يوسف حسين', type: 'رئيس الجمعية', status: true },
        { name: 'عبد الله', type: 'عضو', status: false }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/associations/associations.scss';
</style>
