<template>
  <div>
    <v-list-item
      v-if="item.status === 'pending' && item.canApprove"
      @click="$emit('accept')"
      data-test="acceptAction"
    >
      <v-list-item-icon>
        <v-icon color="green">mdi-check</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="green--text">قبول</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      v-if="item.status === 'pending' && item.canApprove"
      @click="$emit('reject')"
      data-test="rejectAction"
    >
      <v-list-item-icon>
        <v-icon color="danger">mdi-close</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="danger--text">رفض</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item @click="$emit('show-details')" data-test="showDetails">
      <v-list-item-icon>
        <v-icon class="ml-2">mdi-eye</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="grey--text">التفاصيل</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'ItemActions',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
