<template>
  <v-form ref="form" v-model="isValid">
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col class="pl-3" cols="12" sm="6">
          <h6 class="mb-2 font-12">إسم العميل*</h6>
          <v-text-field
            data-test="clientName"
            v-model.trim="newUser.name"
            :rules="clientNameRules"
            dense
            filled
            hide-details
            placeholder="إسم العميل"
            required
            class="font-14"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <dropdown-alt
            data-test="client-type"
            ref="owner-type"
            :configrations="{ name: 'نوع العميل*' }"
            :listingType="clientType"
            :defaultValue="userType"
            @filterTypes="getClientType"
            class="client--type--dropdown"
          />
        </v-col>
        <v-col class="pl-3" cols="12" sm="6">
          <h6 class="mb-2 font-12">رقم الجوال*</h6>
          <vue-tel-input-vuetify
            :loading="checkPhoneNumberLoader"
            :error-messages="getPhoneNumberErrorMessage"
            :success-messages="getPhoneNumberSuccessMesage"
            class="phonenumber-field font-14"
            v-model="newUser.phoneNumber"
            label=""
            :only-countries="['SA']"
            default-country="SA"
            ref="regPhoneNumber"
            placeholder="رقم الجوال"
            :filled="true"
            required
            dense
            suffix="+966"
            :rules="[
              (v) => (v && !!isValidPhone) || 'من فضلك تأكد من رقم الهاتف'
            ]"
            :valid-characters-only="true"
            @keydown="$formatNumberInputs"
            @input="formatNumberAExistance($event, 'phoneNumber')"
            @validate="onValidatePhoneNumber"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <h6 class="mb-2 font-12">رقم الواتس اب</h6>
          <v-text-field
            data-test="clientName"
            dense
            filled
            placeholder="رقم الواتس اب"
            :rules="phoneNumberRules"
            class="font-14"
            :value="whatsAppNumberMask"
            @keydown="$formatNumberInputs"
            @input="formatWhatsAppNumber($event, 'whatsAppNumber')"
          />
        </v-col>
        <v-col class="pl-3" cols="12" sm="6">
          <h6 class="mb-2 font-12 mt-0">البريد الإلكتروني</h6>
          <v-text-field
            data-test="clientName"
            dense
            filled
            placeholder="البريد الإلكتروني"
            class="font-14"
            :rules="emailRules"
            v-model="newUser.email"
          />
        </v-col>
        <v-col v-if="clientModel" cols="12" sm="6" class="add__customer__col">
          <h6 class="mb-2 font-12 mt-0">صفة العميل *</h6>
          <v-select
            v-model="newUser.role"
            :items="clientTypes"
            item-disabled="disable"
            :menu-props="{ bottom: true, offsetY: true }"
            :rules="[(v) => (v && !!v.length) || 'من فضلك اختر نوع العميل']"
            append-icon="mdi-chevron-down"
            data-test="client-status"
            chips
            color="black"
            dense
            filled
            hide-details
            item-text="nameAr"
            item-value="code"
            multiple
            placeholder="صفة العميل"
            required
            class="font-14"
          ></v-select>
        </v-col>
        <v-col class="pl-3" cols="12" sm="6">
          <h6 class="mb-2 font-12 mt-0">
            الرقم الضريبي <span v-if="isOptionalInfoRequired">*</span>
          </h6>
          <v-text-field
            data-test="taxNum"
            v-model="newUser.taxNumber"
            placeholder="الرقم الضريبي"
            dense
            filled
            hide-details
            min="0"
            @keydown="$formatNumberInputs"
            class="font-14"
            :rules="taxNumberRules"
            @input="formatNumberAExistance($event, 'taxNumber')"
          ></v-text-field>
        </v-col>
        <v-col class="" cols="12" sm="6">
          <h6 class="mb-2 font-12 mt-0">
            {{ setTypeCheck.title }}
            <span v-if="isOptionalInfoRequired">*</span>
          </h6>
          <v-text-field
            :data-test="`${setTypeCheck.key}`"
            v-model="newUser[`${setTypeCheck.key}`]"
            :placeholder="setTypeCheck.title"
            :disabled="!typeCheck.length"
            dense
            filled
            hide-details
            min="0"
            @keydown="$formatNumberInputs"
            class="font-14"
            :rules="idNumberRules"
            @input="formatNumberAExistance($event, setTypeCheck.key)"
          />
        </v-col>
      </v-row>
      <p class="font-14 mt-5 mb-0">
        العنوان الوطني (يظهر العنوان في الفاتوره الإلكترونيه)
      </p>
      <v-row class="my-0">
        <v-col cols="12" sm="4">
          <h6 class="mb-2 font-12">العنوان</h6>
          <v-text-field
            v-model="newUser.address"
            placeholder="العنوان"
            dense
            filled
            hide-details
            class="font-14"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <h6 class="mb-2 font-12">المنطقه</h6>
          <v-text-field
            v-model="newUser.district"
            placeholder="المنطقه"
            dense
            filled
            hide-details
            class="font-14"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <h6 class="mb-2 font-12">المدينه</h6>
          <v-text-field
            v-model="newUser.city"
            placeholder="المدينه"
            dense
            filled
            hide-details
            class="font-14"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import DropdownAlt from '@/components/add-unit/dropdownAlt'
import { debounce } from '@/helpers/debounce'
import { mapState } from 'vuex'
import { convertNumbers2English } from '@/utils/formatters'

export default {
  name: 'NewAgentForm',
  props: {
    defaultUser: {
      default() {
        return {}
      }
    },
    clientModel: {
      default: false
    },
    isDisabled: {},
    ResetnewAgentForm: {},
    isOptionalInfoRequired: {
      type: Boolean,
      default: false
    },
    checkPhoneNumberLoader: {
      default: false
    },
    phoneNumberErrorMessage: {
      default: ''
    },
    phoneNumberSuccessMesage: {
      default: ''
    },
    isValidPhone: {
      default: false
    }
  },
  components: {
    DropdownAlt
  },
  watch: {
    isValid() {
      this.handleChange()
    },
    newUser: {
      deep: true,
      handler() {
        if (this.$refs.form.validate()) {
          this.handleChange()
        }
      }
    },
    ResetnewAgentForm(val) {
      if (val) {
        this.newUser = {}
        this.isValid = false
      }
    }
  },
  computed: {
    ...mapState('accountState', ['configs', 'currentEnv']),
    userType() {
      return this.isEditMood ? this.newUser?.clientType : ''
    },
    isEditMood() {
      return Object.keys(this.defaultUser)?.length || 0
    },
    getPhoneNumberErrorMessage() {
      return this.phoneNumberErrorMessage || ''
    },
    getPhoneNumberSuccessMesage() {
      return this.phoneNumberSuccessMesage || ''
    },
    // Get client types owner or renter or both
    clientTypes() {
      return this.configs?.clientTypes
        .filter((type) => type.code === 'owner' || type.code === 'renter')
        .map((type) => ({ ...type, disable: this.handleCheckUserType(type) }))
    },
    setTypeCheck() {
      return this.typeCheck === 'individual'
        ? {
            title: 'رقم الهوية',
            key: 'idNumber'
          }
        : {
            title: 'رقم المنشأة / السجل التجاري',
            key: 'registrationNumber'
          }
    },
    taxNumberRules() {
      return this.isOptionalInfoRequired
        ? [(v) => !!v || 'الرقم الضريبي للعميل']
        : []
    },
    idNumberRules() {
      return this.isOptionalInfoRequired
        ? [(v) => !!v || `${this.setTypeCheck.title} للعميل`]
        : []
    }
  },
  data() {
    return {
      phoneNumberRules: [
        (v) =>
          /^[0-9]*$/.test(v) ||
          (v && v.length === 10) ||
          !v ||
          'صيغة رقم الهاتف غير صحيحة'
      ],
      emailRules: [
        (v) => /.+@.+\..+/.test(v) || !v || 'من فضلك أدخل بريد الكتروني صحيح'
      ],
      clientRoles: {
        Renter: {
          code: 'Tenant',
          checkKeys: ['contractsCount']
        },
        Owner: {
          code: 'Owner',
          checkKeys: ['realestatesCount']
        }
      },
      newUser: {},
      whatsAppNumberMask: '',
      city: '',
      address: '',
      district: '',
      clientNameRules: [(v) => !!v?.trim() || 'إسم العميل'],
      clientType: [
        {
          _id: 1,
          text: 'فرد',
          val: 'individual'
        },
        {
          _id: 2,
          text: 'شركة',
          val: 'company'
        }
      ],
      typeCheck: '',
      clientTypeCheck: '',
      isValid: false
    }
  },
  methods: {
    handleCheckUserType({ nameEn }) {
      let isDisabled = false
      const { checkKeys } = this.clientRoles[nameEn]
      checkKeys.map((key) => {
        if (this.defaultUser?.hasOwnProperty(key)) {
          isDisabled =
            isDisabled ||
            !!this.defaultUser?.[`${key}`]?.[
              `as${this.clientRoles[nameEn].code}`
            ]
        }
      })
      return isDisabled
    },
    debounceCheckUserExistance: debounce(function (val) {
      this.$emit('checkUserExistance', val)
      this.whatsAppNumberMask = this.newUser.whatsAppNumber = val.replace(
        / /g,
        ''
      )
    }, 500),

    formatNumberAExistance(val, key) {
      this.newUser[key] = convertNumbers2English(val)
      if (key === 'phoneNumber') {
        this.debounceCheckUserExistance(val)
      }
    },
    formatWhatsAppNumber(val) {
      // ! this must as whatsapp number in newUser object have been mutated by formatNumberAExistance function
      this.whatsAppNumberMask = convertNumbers2English(val)
    },
    onValidatePhoneNumber(val) {
      this.$emit('onValidatePhoneNumber', val)
    },
    handleChange() {
      if (this.clientTypeCheck.length) {
        this.newUser.clientType = this.clientTypeCheck
        return this.$emit('new_user_added', {
          obj: {
            ...this.newUser,
            phoneNumber: this.newUser.phoneNumber?.replace(/ /g, '')
          },
          isValid: this.isValid
        })
      }
      return ''
    },
    getClientType(payload) {
      if (!payload) {
        return
      }
      this.clientTypeCheck = payload.val
      this.typeCheck = payload.val
      delete this.newUser.registrationNumber
      delete this.newUser.idNumber
      this.handleChange()
    },
    handleEditMood() {
      if (this.isEditMood) {
        this.newUser = {
          ...this.defaultUser,
          role: this.defaultUser?.environments[0]?.role,
          address: this.defaultUser.userAddress?.address || '',
          city: this.defaultUser.userAddress?.city || '',
          district: this.defaultUser.userAddress?.district || ''
        }
        this.clientTypeCheck = this.newUser.clientType
        this.typeCheck = this.newUser.clientType
        const currentActiveEnv = this.newUser.environments.find(
          (env) => env?._id === this.currentEnv?._id
        )
        this.newUser.whatsAppNumber = currentActiveEnv?.whatsAppNumber
      }
    }
  },
  created() {
    this.handleEditMood()
  }
}
</script>
