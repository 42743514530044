// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// Components
import './components'

// Plugins
import './plugins'

//Filters
import './filters'

// Config File
import config from '@/config'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import vuetify from './plugins/vuetify'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import GSvg from '@/components/helper/GSvg.vue'

// VueSocket
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
const socket = io(process.env.VUE_APP_SOCKET_URL)
Vue.use(VueSocketIOExt, socket)

// Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
Vue.use(VueTelInputVuetify, {
  vuetify
})

// VueTypedJs
import VueTypedJs from 'vue-typed-js'
Vue.use(VueTypedJs)

// Social sharing
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing)

// Map
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    region: 'SA',
    key: config.googleMapApiKey, // Add your here your google map api key
    libraries: 'places'
    // v: 3.38,
    // language: store.getters.selectedLocale.locale
  },
  installComponents: true
})

// Qrcode
Vue.component(VueQrcode.name, VueQrcode)
// Svg
Vue.component(GSvg.name, GSvg)

// Sync store with router
sync(store, router)

Vue.config.productionTip = false

/* eslint-disable */
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
