import store from '@/store/index'
import axios from 'axios'
import config from '../config'

axios.defaults.headers.client = 'HectarPlus'

const RealEstateStatus = function () {
  const url = `${config.realEstateUrl}/realestateStatus/getAll`
  return axios.get(url)
}

// get realestatefeatures
const realestateFeatures = function (val) {
  const url = `${config.realEstateUrl}${val}`
  return axios.get(url)
}

// Add RealEstate
const addRealEstate = function (realEstateData) {
  const url = `${config.realEstateUrl}/realEstates/add`
  return axios.post(url, realEstateData)
}

// Add New Unit
const addUnit = function (unitData) {
  const url = `${config.realEstateUrl}/realEstates/addUnit`
  return axios.post(url, unitData)
}

// Update RealEstate
const updateRealEstate = function (realEstateData) {
  const url = `${config.realEstateUrl}/realEstates/edit`
  return axios.post(url, realEstateData)
}

// Get Env RealEstates
const getEnvRealEstate = function () {
  const url = `${config.realEstateUrl}/realEstates/`
  return axios.get(url)
}

// Get RealEstates Count
const getEnvRealEstateCount = function (envId) {
  const url = `${config.realEstateUrl}/realEstates/count?environment=${envId}`
  return axios.get(url)
}

// Delete RealEstate
const deleteRealEstate = function (id) {
  const data = { id }
  const url = `${config.realEstateUrl}/realEstates/delete`
  return axios.post(url, data)
}

// Add Units
const addUnits = function (data) {
  const url = `${config.realEstateUrl}/realEstates/addUnits`
  return axios.post(url, data)
}

// Delete Unit
const deleteUnit = function (id, parentId) {
  const data = { id }
  const url = `${config.realEstateUrl}/realEstate/deleteUnit?id=${id}&parent=${parentId}`
  return axios.post(url, data)
}

// Get Single Property Details
const getSingleProperty = function (propertyId) {
  const url = `${config.realEstateUrl}/realEstates/${propertyId}`
  return axios.get(url)
}

const getRealEstates = function (query, data = 'pageSize=10') {
  const url = `${config.realEstateUrl}/realEstates/filter?${data}`
  const listRealEstatePermission = store.getters['accountState/hasPermission'](
    'realestates',
    'realestates',
    'list'
  )
  return listRealEstatePermission ? axios.post(url, query) : {}
}

const getAvailableRealEstates = function (query) {
  const url = `${config.realEstateUrl}/realEstates/availableForContract`
  return axios.post(url, query)
}

const getRealEstateCountByStatus = function (accountId, query) {
  const url = `${config.realEstateUrl}/realEstates/count-by-status/${accountId}`
  return axios.post(url, query)
}

// HelpCenter
const contactUs = function (info) {
  const url = `${config.apiUrl}/addUserQuestion`
  return axios.post(url, info)
}

// HelpCenter
const getFAQs = function () {
  const url = `${config.apiUrl}/getFAQs`
  return axios.get(url)
}

const getRealEstateBundle = function getRealEstateBundle(environmentId) {
  const url = `${config.realEstateUrl}/subscriptions/get-user-bundle?environmentId=${environmentId}`
  return axios.get(url)
}

const addDocument = function (data) {
  const url = `${config.realEstateUrl}/attachments`
  return axios.post(url, data)
}

const getDocuments = function (id) {
  const url = `${config.realEstateUrl}/attachments/${id}`
  return axios.get(url)
}

const deleteDocument = function (id) {
  const url = `${config.realEstateUrl}/attachments/${id}`
  return axios.delete(url)
}

const toggleVisibility = function (payload) {
  const url = `${config.realEstateUrl}/realestate/toggle-visibility/${payload.realEstateId}`
  return axios.put(url, payload.accountId)
}

const uploadImages = function (data) {
  const url = `${config.realEstateUrl}/realEstates/upload-image`
  return axios.post(url, data)
}

const deleteImage = function (data) {
  const url = `${config.realEstateUrl}/realEstates/images/delete`
  return axios.put(url, data)
}

const addBlueprint = function (data) {
  const url = `${config.realEstateUrl}/realEstates/upload-blueprint`
  return axios.post(url, data)
}

const deleteBlueprint = function (data) {
  const url = `${config.realEstateUrl}/realEstates/blueprint/delete`
  return axios.put(url, data)
}

const getReceivedDeposit = function (query) {
  const url = `${config.bonds}/credits/total`
  return axios.get(url, { params: query })
}
const mergeRealEstate = function (query) {
  const url = `${config.realEstateUrl}/realEstates/merge`
  return axios.post(url, query)
}

const getCustomerRealEstates = function (body) {
  const url = `${config.reportsUrl}/real-estates`
  return axios.post(url, body)
}

// Quotations
const addQuotation = function (body) {
  const url = `${config.realEstateUrl}/quotations`
  return axios.post(url, body)
}
const allQuotations = function (envId, query) {
  const url = `${config.realEstateUrl}/quotations/${envId}?${query}`
  return axios.get(url)
}
const updateQuotation = function (id, data) {
  const url = `${config.realEstateUrl}/quotations/${id}`
  return axios.put(url, data)
}
const updateQuotationWithCustomer = function (id, data) {
  const url = `${config.realEstateUrl}/user-approval/${id}`
  return axios.put(url, data)
}

const getContractQuotation = function (data) {
  const url = `${config.bonds}/contracts/filter`
  return axios.post(url, data)
}

const getAllAttachmentsForQuotation = function (id, query = '') {
  const url = `${config.realEstateUrl}/attachments/${id}${query}`
  return axios.get(url)
}

const addNewAttachmentForQuotation = function (data) {
  const url = `${config.realEstateUrl}/attachments`
  return axios.post(url, data)
}

const deleteAttachmentForQuotation = function (id, data) {
  const url = `${config.realEstateUrl}/attachments/${id}`
  return axios.delete(url, data)
}

export const realEstateService = {
  addRealEstate,
  addUnit,
  updateRealEstate,
  deleteUnit,
  deleteRealEstate,
  getEnvRealEstate,
  getEnvRealEstateCount,
  getSingleProperty,
  contactUs,
  getFAQs,
  realestateFeatures,
  getRealEstates,
  RealEstateStatus,
  getRealEstateBundle,
  getRealEstateCountByStatus,
  getAvailableRealEstates,
  addUnits,
  addDocument,
  getDocuments,
  deleteDocument,
  toggleVisibility,
  uploadImages,
  deleteImage,
  addBlueprint,
  deleteBlueprint,
  getReceivedDeposit,
  mergeRealEstate,
  getCustomerRealEstates,
  addQuotation,
  allQuotations,
  updateQuotation,
  updateQuotationWithCustomer,
  getContractQuotation,
  getAllAttachmentsForQuotation,
  addNewAttachmentForQuotation,
  deleteAttachmentForQuotation
}
