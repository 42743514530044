<template>
  <v-container :class="{ userView: isCustomer }">
    <div>
      <v-data-table
        :headers="headers"
        :items="[realEstats]"
        hide-default-footer
        class="tab-table-details"
      >
        <template v-slot:[`item.type`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>
              {{ item.realEstate?.type?.nameAr }}
            </h6>
          </div>
        </template>
        <template v-slot:[`item.purpose`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>{{ item.realEstate?.purpose?.nameAr }}</h6>
          </div>
        </template>
        <template v-slot:[`item.startDate`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>{{ formatDate(item.startDate) }}</h6>
          </div>
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>{{ formatDate(item.endDate) }}</h6>
          </div>
        </template>
        <template v-slot:[`item.daysBetween`]>
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h6>{{ daysBetween }} يوم</h6>
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div
            class="d-flex justify-center align-center font-weight-medium bill--itself -tag mx-auto"
          >
            <h5
              :class="specifyColor(item.status).color"
              class="ma-0 tag-sm text-center py-0 pb-1"
            >
              <span class="font-14 font-weight-bold my-auto text-truncate">
                {{ specifyColor(item.status).nameAr }}
              </span>
            </h5>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import offerSharedData from '@/mixins/offerSharedData'
import { mapState } from 'vuex'

export default {
  name: 'Details',
  props: {
    realEstats: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'نوع العقار',
          sortable: false,
          value: 'type',
          align: 'center'
        },
        {
          text: 'الغرض من العقار',
          sortable: false,
          value: 'purpose',
          align: 'center'
        },
        {
          text: 'تاريخ بداية العرض',
          sortable: false,
          value: 'startDate',
          align: 'center'
        },
        {
          text: 'تاريخ نهاية العرض',
          sortable: false,
          value: 'endDate',
          align: 'center'
        },
        {
          text: 'مدة العرض',
          sortable: false,
          value: 'daysBetween',
          align: 'center'
        },
        {
          text: 'حالة العرض',
          sortable: false,
          value: 'status',
          align: 'center'
        }
      ]
    }
  },
  mixins: [dateFormat, offerSharedData],
  computed: {
    ...mapState('accountState', ['user', 'configs']),
    startDate() {
      return new Date(this.realEstats?.startDate)
    },
    endDate() {
      return new Date(this.realEstats?.endDate)
    },
    daysBetween() {
      const timeDifference = this.endDate - this.startDate
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24)
      return Math.abs(Math.round(daysDifference))
    }
  }
}
</script>

<style lang="scss" scoped></style>
