<template>
  <v-dialog max-width="740px" persistent v-model="dialogState_">
    <v-card class="add-new-property pa-0">
      <v-card-title
        class="pa-0 px-5 mb-5 d-flex justify-space-between custom__shadow"
      >
        <h4 class="ma-0">إضافة طلب</h4>
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Details -->
      <v-card-text class="px-5 pb-3">
        <v-form ref="form" v-model="isValid">
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  نوع الطلب
                  <span class="red--text">*</span>
                </h6>
                <v-select
                  filled
                  v-model="addRequest.type"
                  :items="getRequestTypesOptions"
                  item-text="nameAr"
                  item-value="code"
                  return-object
                  placeholder="نوع الطلب"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
              <v-col class="pl-3 mobile__pl__0 mb-2" cols="12" sm="6">
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  القسم
                  <span class="red--text">*</span>
                </h6>
                <v-select
                  filled
                  v-model="addRequest.category"
                  :items="getRequestSectionOptions"
                  item-text="nameAr"
                  item-value="code"
                  placeholder="إختر القسم"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:no-data>
                    <div class="text-center font-14 grey--text">
                      <span>من فضلك قم بإختيار نوع الطلب</span>
                    </div>
                  </template>
                </v-select>
              </v-col>
              <ServiceField
                :showPropertType="showPropertType"
                :showPropertyDetails="showPropertyDetails"
                :isNotarization="isNotarization"
                @serviceRequest="serviceRequest"
                @getLocation="getLocation"
              />
              <v-col
                class="pl-3 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
                v-if="showPropertType && !isCustomer"
              >
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  حالة العميل
                  <span class="red--text">*</span>
                </h6>
                <!-- @input="loadClientProperties" -->

                <autocomplete
                  :rules="[(v) => !!v || 'من فضلك قم بإختيار حالة العميل']"
                  filled
                  v-model="addRequest.clientStatus"
                  :items="clientStatus"
                  placeholder="حالة العميل"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                />
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
                v-if="
                  addRequest.clientStatus === 'عميل محتمل' && showPropertType
                "
              >
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  طريقة الإضافة <span class="red--text">*</span>
                </h6>
                <dropdown-alt
                  data-test="client-type"
                  ref="owner-type"
                  :configrations="{ name: '' }"
                  :listingType="addWays"
                  class="client--type--dropdown"
                  @filterTypes="getClientType"
                />
                <!-- :defaultValue="userType" -->
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
                v-if="!isCustomer"
              >
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  إسم العميل
                  <span class="red--text">*</span>
                </h6>

                <autocomplete
                  v-if="!isNewClient"
                  @nextPageTrigger="getClientNextData"
                  @emitSearchKey="getClientDataBySearchKey"
                  @input="loadClientProperties($event)"
                  :rules="[(v) => !!v || 'من فضلك قم بإختيار إسم العميل']"
                  filled
                  v-model="addRequest.client"
                  :items="usersListToShow"
                  item-text="name"
                  item-value="_id"
                  :return-object="true"
                  placeholder="إسم العميل"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                />
                <v-text-field
                  v-else
                  data-test="clientName"
                  v-model.trim="addRequest.PTClientName"
                  :rules="clientNameRules"
                  dense
                  filled
                  hide-details
                  placeholder="إسم العميل"
                  required
                  class="font-14"
                />
              </v-col>
              <v-col class="" cols="12" sm="6" v-if="isNewClient">
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  نوع العميل <span class="red--text">*</span>
                </h6>
                <dropdown-alt
                  data-test="client-type"
                  ref="owner-type"
                  :configrations="{ name: '' }"
                  :listingType="clientType"
                  class="client--type--dropdown"
                  @filterTypes="getPTClientType"
                />
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
                v-if="isNewClient"
              >
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  صفة العميل
                  <span class="red--text">*</span>
                </h6>
                <!-- v-model="addRequest.clientType" -->
                <v-select
                  :rules="[(v) => !!v || 'من فضلك قم بإختيار نوع العميل']"
                  filled
                  :items="clientRoles"
                  v-model="addRequest.clientKind"
                  placeholder="صفة العميل"
                  hide-details
                  item-text="nameAr"
                  item-value="code"
                  multiple
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0"
                cols="12"
                sm="6"
                v-if="isNewClient"
              >
                <h6 class="font-14 font-weight-bold black--text">
                  رقم الجوال
                  <span class="red--text">*</span>
                </h6>
                <vue-tel-input-vuetify
                  :loading="checkPhoneNumberLoader"
                  :error-messages="getPhoneNumberErrorMessage"
                  :success-messages="getPhoneNumberSuccessMesage"
                  class="phonenumber-field font-14"
                  v-model="addRequest.phoneNumber"
                  label=""
                  :only-countries="['SA']"
                  default-country="SA"
                  ref="regPhoneNumber"
                  placeholder="رقم الجوال"
                  :filled="true"
                  required
                  suffix="+966"
                  :rules="[
                    (v) => (v && !!isValidPhone) || 'من فضلك تأكد من رقم الهاتف'
                  ]"
                  :valid-characters-only="true"
                  :validate-on-blur="true"
                  @keydown="$formatNumberInputs"
                  @validate="onValidatePhoneNumber"
                  @input="formatNumberAExistance($event, 'phoneNumber')"
                />
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0"
                cols="12"
                sm="6"
                v-if="isNewClient"
              >
                <h6 class="font-14 font-weight-bold black--text pl-8">
                  رقم الواتس اب
                </h6>
                <v-text-field
                  data-test="clientName"
                  dense
                  filled
                  placeholder="رقم الواتس اب"
                  :rules="phoneNumberRules"
                  class="font-14"
                  :value="whatsAppNumberMask"
                  @keydown="$formatNumberInputs"
                  @input="formatWhatsAppNumber($event, 'whatsAppNumber')"
                />
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0"
                cols="12"
                sm="6"
                v-if="isNewClient"
              >
                <h6 class="font-14 font-weight-bold black--text">
                  البريد الإلكتروني
                </h6>
                <v-text-field
                  data-test="clientName"
                  dense
                  filled
                  placeholder="البريد الإلكتروني"
                  class="font-14"
                  :rules="emailRules"
                  v-model="addRequest.email"
                />
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
                v-if="!showPropertType"
              >
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  إسم العقار
                  <span class="red--text">*</span>
                </h6>
                <autocomplete
                  :noData="noDataInTheListText"
                  :rules="[(v) => !!v || 'من فضلك قم بإختيار إسم العقار']"
                  filled
                  v-model="addRequest.realEstate"
                  :items="allProperties"
                  :disabled="isCustomer ? false : !addRequest.client"
                  :loading="realEstateLoading"
                  item-text="propertyName"
                  item-value="_id"
                  return-object
                  placeholder="إسم العقار"
                  hide-details
                  dense
                  @nextPageTrigger="getPropertyNextData"
                  @emitSearchKey="getPropertyDataBySearchKey($event)"
                />
              </v-col>
              <v-col class="pl-3" cols="12" sm="6" v-if="!isCustomer">
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  المسئول
                </h6>
                <v-select
                  filled
                  v-model="addRequest.moderator"
                  :items="allEmployees"
                  item-text="name"
                  item-value="_id"
                  placeholder="المسئول"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 2">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span
                      v-if="index === 2"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ addRequest.moderator?.length - 2 }} مسؤولين)
                    </span>
                  </template>
                  <template v-slot:no-data>
                    <div class="text-center font-14 grey--text">
                      <span>لم يتم تحميل اي بيانات</span>
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col
                class="pl-3 mobile__pl__0 mb-2"
                cols="12"
                sm="6"
                v-if="!isCustomer"
              >
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  الأولوية
                  <span class="red--text">*</span>
                </h6>
                <v-select
                  :rules="[(v) => !!v || 'من فضلك قم بإختيار أولويه الطلب']"
                  filled
                  v-model="addRequest.priority"
                  :items="requestPriorities"
                  item-text="nameAr"
                  item-value="nameEn"
                  placeholder="اختر أولويه الطلب"
                  hide-details
                  dense
                  append-icon="mdi-chevron-down"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:no-data>
                    <div class="text-center font-14 grey--text">
                      <span>لم يتم تحميل اي اختيارات</span>
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col class="pl-3 mb-2" cols="12">
                <h6 class="mb-2 font-14 font-weight-bold black--text">
                  وصف الطلب
                </h6>
                <v-text-field
                  class="mb-0 pa-0"
                  height="60"
                  filled
                  v-model="addRequest.description"
                  placeholder="ادخل وصف الطلب"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col class="mb-8" cols="12" v-if="isMaintenanceRequest">
                <FilesUploader
                  ref="uploadingComp"
                  @uploadAttachment="uploadAttachment"
                  @deleteAttachment="deleteAttachment"
                  :light="false"
                  :attachments="attachments"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-2 px-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ma-0 rounded-7 my-1 d-flex px-3"
          :disabled="!isValid"
          :loading="addLoadingStatus"
          @click="addMaintenanceRequest"
        >
          <span class="mb-1 mobile__mb__0 ml-1 font-weight-bold mt-1 font-12">
            إضافة
          </span>
          <v-icon class="send-icon font-16 mt-1">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import autocomplete from '@/components/forms/autocomplete'
import FilesUploader from '@/components/helper/filesUploader'
import { EnvService, userService } from '@/services'
import DropdownAlt from '@/components/add-unit/dropdownAlt'
import { debounce } from '../../helpers/debounce'
import { convertNumbers2English } from '@/utils/formatters'
import { hasValue } from '@/utils/general'
import ServiceField from '../requests/ServiceField.vue'

export default {
  name: 'AddMaintenanceRequestModal',
  components: { autocomplete, FilesUploader, DropdownAlt, ServiceField },
  props: {
    requestTypes: {
      default() {
        return []
      }
    },
    addLoadingStatus: {
      default: false,
      type: Boolean
    },
    dialogState: {
      type: Boolean,
      default: false
    },
    allClients: {
      default() {
        return []
      }
    },
    allPTClients: {
      default() {
        return []
      }
    },
    routerReqType: {
      default: () => {},
      type: Object
    },
    allProperties: {
      default() {
        return []
      }
    },
    realEstateLoading: {
      default: true
    },
    allEmployees: {
      default() {
        return []
      }
    }
  },
  data() {
    return {
      attachments: [],
      checkPhoneNumberLoader: false,
      phoneNumberValid: false,
      emailValid: false,
      isLoading: false,
      clientNameRules: [(v) => !!v?.trim() || 'إسم العميل'],
      phoneNumberRules: [
        (v) =>
          /^[0-9]*$/.test(v) ||
          (v && v.length === 10) ||
          !v ||
          'صيغة رقم الهاتف غير صحيحة'
      ],
      emailRules: [
        (v) => /.+@.+\..+/.test(v) || !v || 'من فضلك أدخل بريد الكتروني صحيح'
      ],
      phoneNumberErrorMessage: '',
      whatsAppNumberMask: '',
      phoneNumberSuccessMesage: '',
      emailSuccessMesage: '',
      phoneData: {},
      emailErrorMessage: '',
      ptClientId: null,
      btnTitle: 'إضافة المرفقات',
      uploadingText: 'إسحب الملف هنا لرفعه أو إضعط علي زر إضافة المرفقات',
      upladingDescription: '',
      validatePropertyString: 'من فضلك أدخل أختر العقار',
      isValid: false,
      addRequest: {},
      requestPriorities: [
        { nameAr: 'طارئ', nameEn: 'urgent' },
        { nameAr: 'عاجل', nameEn: 'high' },
        { nameAr: 'متوسط', nameEn: 'normal' },
        { nameAr: 'منخفض', nameEn: 'low' }
      ],
      clientStatus: ['عميل حالي', 'عميل محتمل'],
      clientRoles: [
        { _id: 1, code: 'owner', nameAr: 'مالك' },
        { _id: 2, code: 'renter', nameAr: 'مستأجر' }
      ],
      clientType: [
        {
          _id: 1,
          text: 'فرد',
          code: 'individual'
        },
        {
          _id: 2,
          text: 'شركة',
          code: 'company'
        }
      ],
      addWays: [
        {
          _id: 1,
          text: 'إختيار من القائمة',
          val: 'existing'
        },
        {
          _id: 2,
          text: 'إضافة جديدة',
          val: 'new'
        }
      ],
      selectedWay: ''
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    ...mapGetters('accountState', ['isCustomer']),
    activeCustomerRole() {
      return JSON.parse(localStorage.getItem('activeCustomerRole'))
    },
    isRealestateInput() {
      return !!this.addRequest?.type?.realEstate ?? false
    },
    isNewClient() {
      return (
        this.selectedWay === 'new' &&
        this.addRequest.clientStatus === 'عميل محتمل'
      )
    },
    showPropertType() {
      return (
        this.addRequest?.type?.code === 'serviceRequest' &&
        this.addRequest?.type?.categories.length > 0
      )
    },
    showPropertyDetails() {
      return this.addRequest.category === 'realestateRequest'
    },
    isNotarization() {
      return this.addRequest.category === 'contractNotarization'
    },
    usersListToShow() {
      if (
        this.addRequest.clientStatus === 'عميل محتمل' &&
        this.selectedWay === 'existing'
      ) {
        return this.allPTClients
      }
      return this.allClients
    },
    getRequestTypesOptions() {
      return this.requestTypes.filter((reqType) => {
        return this.isCustomer
          ? !!reqType?.userType?.includes(this.activeCustomerRole)
          : !!reqType?.role?.includes('business')
      })
    },
    isMaintenanceRequest() {
      return this.addRequest?.type?.code === 'maintenance'
    },
    getRequestSectionOptions() {
      if (!this.addRequest.type?.code) {
        return []
      }
      return this.addRequest?.type?.categories.filter((categoryType) => {
        return this.isCustomer
          ? !!categoryType.role?.includes('customer') &&
              !!categoryType.userType?.includes(this.activeCustomerRole)
          : !!categoryType?.role?.includes('business')
      })
    },
    isValidPhone() {
      return this.phoneData.isValid
    },
    getPhoneNumberErrorMessage() {
      return this.phoneNumberErrorMessage
    },
    getPhoneNumberSuccessMesage() {
      return this.phoneNumberSuccessMesage
    },
    noDataInTheListText() {
      return !this.realEstateLoading
        ? 'لا توجد عقارات متاحة'
        : 'جاري البحث عن عقارات العميل...'
    },
    clientLocation() {
      const isExistingClient = !this.showPropertType
      return isExistingClient
        ? this.locationExsitingClient
        : this.locationNewClient
    },
    locationExsitingClient() {
      return {
        _id: this.addRequest.realEstate.owner._id,
        address: {
          address: this.addRequest.realEstate?.propertyAddress.address,
          city: this.addRequest.realEstate?.propertyAddress.city,
          province: this.addRequest.realEstate?.propertyAddress.province
        },
        propertyName: this.addRequest.realEstate.propertyName
      }
    },
    locationNewClient() {
      return {
        address: {
          address: this.addRequest.realEstate?.location?.address,
          city: this.addRequest.realEstate?.location?.city,
          province: this.addRequest.realEstate?.location?.province
        }
      }
    },
    realEstateRestructuring() {
      const locationData = this.addRequest.realEstate.location
      const coordinates = locationData?.coordinates || []
      const lat = locationData?.lat
      const lng = locationData?.lng

      const location =
        coordinates.length > 0
          ? { lat: coordinates[0], lng: coordinates[1] }
          : { lat: lat || 0, lng: lng || 0 }
      const data = {
        ...this.clientLocation,
        type: this.addRequest.realEstate.type._id,
        location: {
          ...location
        }
      }
      return data
    },
    requestToAdd() {
      const data = {
        priority: this.addRequest.priority,
        moderator: this.addRequest.moderator,
        createdBy: this.user._id,
        environmentId: this.currentEnv?._id,
        applicant:
          this.ptClientId ?? this.addRequest.client?._id ?? this.user._id,
        ...(this.isRealestateInput && {
          realEstate: this.realEstateRestructuring
        }),
        type: this.addRequest.type?.code,
        category: this.addRequest.category,
        description: this.addRequest.description,
        attachment: this.attachments.map((attach) => attach.id),
        purpose: this.addRequest.realEstate.purpose?.nameEn
      }

      return data
    },
    newPTClinet() {
      return {
        name: this.addRequest.PTClientName,
        phoneNumber: this.addRequest.phoneNumber,
        clientType: this.addRequest.clientType,
        ...(this.addRequest.email && { email: this.addRequest.email }),
        environment: {
          whatsAppNumber: this.addRequest.whatsAppNumber,
          role: this.addRequest.clientKind,
          _id: this.currentEnv._id,
          name: this.currentEnv.name
        }
      }
    },
    dialogState_: {
      get() {
        return this.dialogState
      },
      set(val) {
        this.$emit('toggleDialogState', val)
      }
    }
  },
  watch: {
    routerReqType: {
      deep: true,
      immediate: true,
      handler(val) {
        if (hasValue(val)) {
          this.addRequest.type = val
        }
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    async uploadAttachment({ files }) {
      files = files.dataTransfer ? files.dataTransfer.files : files.target.files
      const selectedFiles = Object.values(files)
      selectedFiles.forEach((file, index) => {
        const fileIndex = index + this.attachments.length
        const reader = new FileReader()
        reader.onload = () => {
          this.attachments.splice(fileIndex, 1, { Location: reader.result })
          this.onUpload(file, fileIndex)
        }
        reader.readAsDataURL(file)
      })
    },
    getPTClientType({ code }) {
      this.addRequest.clientType = code
    },
    serviceRequest(val) {
      this.addRequest = { ...this.addRequest, realEstate: val }
    },
    getLocation(val) {
      this.addRequest.realEstate.location = { ...val }
    },
    getClientType({ val }) {
      if (!val) {
      }
      this.selectedWay = val
      // this.handleChange()
    },
    async onUpload(file, fileIndex) {
      this.$emit('setAddLoadingStatus', true)
      try {
        const fd = new FormData()
        fd.append('attachment', file, file.name)
        fd.append('entityType', 'request')
        fd.append('envId', this.currentEnv._id)
        const {
          data: { attachment }
        } = await userService.addDocument(fd)
        const fileObject = {
          Key: attachment.key,
          Location: attachment.url,
          id: attachment._id
        }
        this.attachments.splice(fileIndex, 1, fileObject)
        this.addNotification({
          text: 'تم إضافه المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.attachments.splice(fileIndex, 1)
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      } finally {
        this.$emit('setAddLoadingStatus', false)
      }
    },
    async addPTClient(newClient) {
      if (!this.isNewClient) {
        return
      }
      try {
        const payLoad = {
          isLead: true,
          ...newClient
        }
        const {
          data: { user }
        } = await userService.addPotentialClient(payLoad)
        this.ptClientId = user._id
      } catch {
        this.addNotification({
          text: 'لم نتمكن من اضافة العميل',
          color: 'error'
        })
      }
    },
    async deleteAttachment({ id, index }) {
      this.$emit('setAddLoadingStatus', true)
      try {
        await userService.deleteDocument(id)
        this.attachments.splice(index, 1)
        this.addNotification({
          text: 'تم حذف المرفق بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم يتم حذف المرفق يرجى المحاولة مره اخرى',
          color: 'error'
        })
      } finally {
        this.$emit('setAddLoadingStatus', false)
      }
    },
    convertObjIntoArr(obj = {}) {
      return Object.values(obj)
    },
    getPropertyNextData() {
      if (this.addRequest?.client?._id) {
        this.$emit('getPropertyNextData', this.addRequest)
      } else if (this.isCustomer) {
        this.$emit('getPropertyNextData')
      }
    },
    debounceCheckUserExistance: debounce(function (val) {
      // eslint-disable-next-line no-invalid-this
      this.checkUserExistance('phoneNumber')
      this.whatsAppNumberMask = this.addRequest.whatsAppNumber = val.replace(
        / /g,
        ''
      )
    }, 500),
    formatNumberAExistance(val, key) {
      this.addRequest[key] = convertNumbers2English(val)

      if (key === 'phoneNumber') {
        this.debounceCheckUserExistance(val)
      }
    },
    checkUserExistance(key) {
      let phoneNumber = this.addRequest.phoneNumber ?? ''
      phoneNumber = phoneNumber?.replace(/ /g, '')
      if (phoneNumber.length !== 10 && key === 'phoneNumber') {
        this.phoneNumberSuccessMesage = ''
        this.phoneNumberErrorMessage = 'من فضلك تأكد من رقم الهاتف'
      } else if (!/.+@.+\..+/.test(this.addRequest.email) && key === 'email') {
        this.emailSuccessMesage = ''
        this.emailErrorMessage = this.addRequest.email.length
          ? 'من فضلك تأكد من البريد الإلكتروني'
          : ''
      } else if (this.isValidPhone || /.+@.+\..+/.test(this.addRequest.email)) {
        if (key === 'phoneNumber') {
          this.checkPhoneNumberLoader = true
          this.phoneNumberSuccessMesage = ''
          this.phoneNumberErrorMessage = ''
          phoneNumber = phoneNumber.replace('0', '966')
          // this.addRequest.phoneNumber = phoneNumber
        } else if (key === 'email') {
          this.checkEmailLoader = true
          this.emailSuccessMesage = ''
          this.emailErrorMessage = ''
          phoneNumber = phoneNumber.replace('0', '966')
        }
        EnvService.checkClient({
          environment: this.currentEnv._id,
          phoneNumber
        })
          .then((response) => {
            if (response.data.available && key === 'phoneNumber') {
              this.phoneNumberSuccessMesage = 'العميل متاح للأضافه'
              this.phoneNumberValid = true
              this.phoneNumberErrorMessage = null
            } else if (key === 'email') {
              this.emailValid = true
              this.emailSuccessMesage = 'البريد متاح للدعوه'
            }
          })
          .catch((error) => {
            if (key === 'phoneNumber') {
              this.phoneNumberErrorMessage = ' الرقم مُسجل بإسم عميل أخر'
              this.phoneNumberValid = false
            } else if (key === 'email') {
              this.emailErrorMessage = error.response.data.error
              this.emailValid = false
            }
          })
          .finally(() => {
            this.checkPhoneNumberLoader = false
          })
      }
    },
    getPropertyDataBySearchKey(e) {
      if (this.addRequest?.client?._id) {
        this.$emit('getPropertyDataBySearchKey', {
          e,
          itemData: this.addRequest
        })
      } else if (this.isCustomer) {
        this.$emit('getPropertyDataBySearchKey', {
          e
        })
      }
    },
    loadClientProperties({ _id }) {
      this.$emit('loadClientProperties', _id)
    },
    getClientNextData() {
      this.$emit('getClientNextData')
    },
    getClientDataBySearchKey(payload) {
      this.$emit('getClientDataBySearchKey', payload)
    },
    async addMaintenanceRequest() {
      await this.addPTClient(this.newPTClinet)
      this.$emit('addNewRequest', this.requestToAdd)
    },
    onValidatePhoneNumber(data) {
      this.phoneData = data
    },
    closeDialog() {
      this.addRequest = {}
      this.$emit('resetAllProperties')
      this.dialogState_ = false
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/material-dashboard/_add-new-property.scss';
.phonenumber-field .v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 36px !important;
}
</style>
