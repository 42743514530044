var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card-text',{staticClass:"pa-0"},[(_vm.isPageLoading)?_c('Loader',_vm._b({},'Loader',_vm.loaderProps,false)):_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('h6',{staticClass:"mb-2 mt-4"},[_vm._v("نوع العقد")]),_c('v-layout',{staticClass:"d-flex justify-space-between radioGroup w-100"},[_c('v-radio-group',{staticClass:"mx-auto my-0",attrs:{"rules":_vm.contractTypeRule,"row":"","disabled":_vm.isEditMood},model:{value:(_vm.selectedContractType),callback:function ($$v) {_vm.selectedContractType=$$v},expression:"selectedContractType"}},_vm._l((_vm.contractTypes),function(contract){return _c('v-radio',{key:contract._id,staticClass:"mx-1",attrs:{"value":contract},on:{"change":_vm.setValdationStatus}},[_c('template',{slot:"label"},[_c('v-card',{staticClass:"mobile__w__auto mobile__mw__260px",class:{
                'brub--tag-border':
                  contract.nameEn === _vm.selectedContractTypeNameEn
              },attrs:{"width":"210","height":"100"}},[_c('div',{staticClass:"mt-6 text-center"},[_c('img',{attrs:{"src":contract.nameEn === _vm.selectedContractTypeNameEn
                      ? contract.activePic
                      : contract.pic,"lazy-src":contract.pic}}),_c('h6',{staticClass:"font-weight-medium mt-0",class:{
                    'text-primary':
                      contract.nameEn === _vm.selectedContractTypeNameEn
                  }},[_vm._v("\n                  "+_vm._s(contract.nameAr)+"\n                ")])])])],1)],2)}),1)],1),(_vm.isMaintenanceContract)?_c('v-layout',{staticClass:"d-flex justify-space-around w-100 mb-1"},_vm._l((_vm.subContractTypes),function(maintenanceType){return _c('v-card',{key:maintenanceType._id,class:{
          'brub--tag-border': _vm.isSubTypeContractChecked(maintenanceType._id)
        },attrs:{"width":"190"}},[_c('v-checkbox',{staticClass:"ma-0 contract mr-1",attrs:{"value":maintenanceType._id,"dense":"","hide-details":"","rules":_vm.subContractTypeRule},on:{"change":_vm.setValdationStatus},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('h5',{staticClass:"font-weight-medium mt-0 pt-1 mb-2",class:{
                  'text-primary': _vm.isSubTypeContractChecked(
                    maintenanceType._id
                  )
                }},[_vm._v("\n                "+_vm._s(maintenanceType.nameAr)+"\n              ")])])]},proxy:true}],null,true),model:{value:(_vm.selectedContractSuptypes),callback:function ($$v) {_vm.selectedContractSuptypes=$$v},expression:"selectedContractSuptypes"}})],1)}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }