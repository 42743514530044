import contractAction from '@/constans/contracts/contractAction.js'
import { contractsService } from '@/services'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      ...contractAction,
      cancelIcon: require('@/assets/img/svgs/cancel.svg'),
      contractUtilities: {}
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    tagColor(contractType) {
      switch (contractType) {
        case 'rent':
          return 'orange--tag'
        case 'propertyManagement':
          return 'blue--tag'
        case 'maintenance':
          return 'brub--tag'
      }
      return null
    },
    terminateContract(item) {
      this.$emit('onTerminate', { contract: item, isIncluded: true })
    },
    deleteContract(item) {
      this.$emit('onTerminate', { contract: item, isIncluded: false })
    },
    isAvalidAction({ item, action }) {
      const isEligible = action.eligibleContracts.includes(item.status)
      // check if the current item the status is any thing except expired and the previousContracts state is expired then return true
      const isExpired =
        (action.title === 'تجديد العقد' &&
          item.status === 'pendingRenewal' &&
          item.previousContracts?.length > 0 &&
          item.previousContracts.some(
            (contract) => contract?.status === 'expired'
          )) ||
        (item.status === 'expired' &&
          action.title === 'تجديد العقد' &&
          item.renewalProcessed === true)

      const isPmContract =
        item.status === 'expired' &&
        item.typeName === 'propertyManagement' &&
        action.title === 'تجديد العقد'

      const isEditInPmContract =
        item.typeName === 'propertyManagement' && action.title === 'إنهاء العقد'

      if (!isEligible || isExpired || isPmContract || isEditInPmContract) {
        return false
      }
      if (action.title === 'تأكيد عملية التجديد' && item.status === 'expired') {
        return !item.previousContracts.length
      }
      if (this.isCustomer) {
        if (item.status === 'pending' && action.includeCustomer) {
          return this.isCurrentUserOneOfParties(item)
        }
        return action.excludeCustomer ? false : isEligible
      }
      return isEligible && !action.includeCustomer
    },
    callActionFun({ action, item }) {
      const methodTitle = action.clickAction.methodTitle
      const payload = { ...item, methodTitle }
      // Wants only to send index in case of set(show) side details
      if (
        methodTitle !== 'showDetails' &&
        Object.prototype.hasOwnProperty.call(payload, 'index')
      ) {
        delete payload.index
      }
      this[methodTitle](payload, action.isApproved)
    },
    async showDetails(item) {
      await this.getContractPayments(item)
      this.$emit('showDetails', {
        visible: true,
        contract: item,
        contractUtilities: this.contractUtilities
      })
    },
    async getContractPayments(item) {
      try {
        const response = await contractsService.getContractPayments(item._id)
        this.contractUtilities = response.data.contractUtilities
      } catch {
        this.addNotification({
          text: 'حدث خطأ اثناء تحميل فواتير العقد, حاول في وقت لاحق ',
          color: 'error'
        })
      }
    },
    confirmRenew(item) {
      this.$emit('confirmRenew', item)
    },
    confirmExpiredRenew(item) {
      this.$emit('confirmExpiredRenew', item)
    },
    openContractModel(item) {
      this.$emit('updatedContractData', item)
    },
    openPDF(item) {
      this.$emit('openPDF', { visible: true, contract: item })
    },
    sharePdf(item) {
      this.$emit('sharePdf', item)
    },
    resendToClient(item) {
      this.$emit('resendToClient', { contractId: item?._id })
    },
    async acceptRejectPendingContract(item, isApproved) {
      const query = {
        environment: this.currentEnv._id,
        isApproved,
        id: item._id
      }
      try {
        await contractsService.approveRejectContract(query)
        this.$emit('closePdf')
        this.addNotification({
          text: isApproved ? 'تم قبول العقد' : 'تم رفض العقد',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ حاول مره اخري',
          color: 'error'
        })
      } finally {
        this.$emit('refreshContracts')
      }
    },
    getDurationNameAr(durationVal) {
      if (durationVal > 12) {
        return { nameAr: `${Math.floor(durationVal / 12)} سنه` }
      }
      return this.contractIntervals.find((D) => D.value === durationVal) ?? null
    },
    isOwnerApproved(item) {
      return !!(this.isOwnerReact(item) && item.approval.owner)
    },
    isRenterApproved(item) {
      return !!(this.isRenterReact(item) && item.approval.tenent)
    },
    iscurrentUserSameOwner(item) {
      return !!(this.user?._id === item.firstParty?._id)
    },
    iscurrentUserSameOwnerAndApprove(item) {
      return !!(
        this.user?._id === item.firstParty?._id && this.isOwnerApproved(item)
      )
    },
    iscurrentUserSameOwnerAndnotApprove(item) {
      return !!(
        this.iscurrentUserSameRenter(item) &&
        !this.iscurrentUserSameRenterAndApprove(item)
      )
    },
    iscurrentUserSameRenter(item) {
      return !!(this.user?._id === item.otherParties[0]?._id)
    },
    iscurrentUserSameRenterAndApprove(item) {
      return !!(
        this.user?._id === item.otherParties[0]?._id &&
        this.isRenterApproved(item)
      )
    },
    iscurrentUserSameRenterAndnotApprove(item) {
      return !!(
        this.iscurrentUserSameOwner(item) &&
        !this.iscurrentUserSameOwnerAndApprove(item)
      )
    },
    isCurrentUserOneOfParties(item) {
      return (
        this.iscurrentUserSameRenterAndnotApprove(item) ||
        this.iscurrentUserSameOwnerAndnotApprove(item)
      )
    },
    getPendingContractObj(item) {
      if (
        this.iscurrentUserSameOwnerAndApprove(item) ||
        this.iscurrentUserSameRenterAndApprove(item)
      ) {
        return {
          nameAr: 'في إنتظار موافقه الطرف الاخر',
          color: '#4fb3f9',
          tagColor: 'blue--tag'
        }
      } else if (
        !this.iscurrentUserSameOwner(item) &&
        !this.iscurrentUserSameRenter(item)
      ) {
        let text = 'في إنتظار موافقه الطرفين '
        if (this.isRenterApproved(item)) {
          text = 'في انتظار موافقه المالك'
        } else if (this.isOwnerApproved(item)) {
          text = 'في انتظار موافقه المستأجر'
        }
        return {
          nameAr: text,
          color: '#ed6237',
          tagColor: 'orange--tag'
        }
      }
      return {
        nameAr: 'في إنتظار موافقتك',
        color: '#ed6237',
        tagColor: 'orange--tag'
      }
    },
    isValidContract(item) {
      const todayDate = new Date()
      const contractDate = new Date(item.end)
      return !!(todayDate <= contractDate)
    },
    isTerminated(item) {
      return item?.terminated?.isTerminated
    },
    contratDuration(item) {
      const selected = this.getDurationNameAr(item.duration)
      return selected ? selected?.nameAr : selected
    },
    isOwnerReact(item) {
      return !!(
        item.hasOwnProperty('approval') && item.approval.hasOwnProperty('owner')
      )
    },
    isRenterReact(item) {
      return !!(
        item.hasOwnProperty('approval') &&
        item.approval.hasOwnProperty('tenent')
      )
    },
    isAnActiveContract(item) {
      return !!(item?.status == 'active')
    },
    firstPartyName(item) {
      if (item.typeName === 'rent') {
        return item?.firstParty?.name || 'مستخدم'
      }
      return this.companyName || ''
    },
    otherPartyName(item) {
      return item?.otherParties[0]?.name || 'مستخدم'
    },
    moderatorName(item) {
      return item?.moderator?.name || 'المسئول'
    },
    moderatorNameSplit(item) {
      return (
        this.moderatorName(item)
          .split(' ')
          .map((n) => n[0])
          .join('.') || 'م.س'
      )
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user']),
    ...mapGetters('accountState', ['isCustomer', 'contractsStatuses']),
    parentName() {
      return this.item?.realEstate?.parentName
        ? `${this.item?.realEstate?.parentName} - `
        : ''
    },
    realEstateName() {
      return this.item?.realEstate?.name ?? ''
    },
    statusTagColor() {
      const { tagColor = 'blue--tag' } =
        this.contractsStatuses[this.contract?.status || this.item?.status]
      return tagColor
    },
    statusColor() {
      const { color = '#4fb3f9' } =
        this.contractsStatuses[this.contract?.status || this.item?.status]
      return color
    },
    statusNameAr() {
      const { nameAr } =
        this.contractsStatuses[this.contract?.status || this.item?.status]
      return nameAr
    },
    getBreakPoint() {
      return this.$vuetify.breakpoint || {}
    },
    companyName() {
      return ['company', 'office'].includes(
        this.user.type || this.user.clientType
      )
        ? this.user?.company || this.currentEnv?.owner?.company
        : this.user?.name
    }
  }
}
