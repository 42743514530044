var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'task-details contentSection contracts__bonds__side__deatails',
    { 'show-detailes': !_vm.reqModalState }
  ]},[_c('v-slide-x-transition',[_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(!_vm.reqModalState),expression:"!reqModalState"}]},[_c('v-flex',{staticClass:"greySide",attrs:{"md4":""}}),_c('v-flex',{staticClass:"whiteSide flex-wrap",style:({ 'min-width': _vm.hasUpdatePermission ? '80vw' : '60vw' })},[_c('sideRequestsDetailesHeader',_vm._b({on:{"closeDetails":_vm.closeSideDetails}},'sideRequestsDetailesHeader',_vm.headerProps,false)),_c('v-divider',{staticClass:"mt-2"}),_c('v-layout',{staticClass:"sidedetails__layout mobile__px__15px"},[_c('v-flex',{class:{
              'details-container': _vm.getBreakPoint['mdAndUp'],
              md8: _vm.hasUpdatePermission
            }},[_c('v-tabs',{staticClass:"tabs-container",attrs:{"color":"primary","align-with-title":"","right":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.filteredTabs),function(tab){return _c('v-tab',{key:tab.component,staticClass:"font-14 mr-0",attrs:{"color":"#737373"}},[_vm._v("\n                "+_vm._s(tab.title)+"\n              ")])})],2),_c('v-tabs-items',{staticClass:"mt-3",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.filteredTabs),function(tab){return _c('v-tab-item',{key:tab.component,attrs:{"eager":true}},[_c(tab.component,_vm._g(_vm._b({tag:"component",on:{"setRequestDetails":_vm.setRequestDetails,"openReqModal":function($event){_vm.reqModalState = $event},"addExpense":_vm.addExpense,"deleteExpense":_vm.deleteExpense,"updateRequest":function($event){return _vm.$emit('updateRequest', $event)},"uploadAttachment":_vm.uploadDocument,"deleteAttachment":_vm.deleteDocument,"selectFile":_vm.uploadDocument,"delete-document":_vm.deleteDocument,"cancel-delete-document":_vm.showMaintenanceRequest}},'component',_vm.generateTabProps(tab),false),_vm.$listeners))],1)}),1)],1),(_vm.hasUpdatePermission)?_c('v-flex',{staticClass:"mr-2",attrs:{"md4":""}},[_c('CommentsSection',_vm._b({on:{"addComment":_vm.generateAddCommentBody,"updateComment":_vm.updateComment,"deleteComment":_vm.deleteComment}},'CommentsSection',_vm.commentsSectionProps,false))],1):_vm._e()],1)],1)],1)],1),(_vm.reqModalState)?_c('AddReqModal',{attrs:{"dialogState":_vm.reqModalState},on:{"addExpense":_vm.addExpense,"close-client-dialog":function($event){_vm.reqModalState = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }