<template>
  <v-card
    :class="{ 'brub--tag-border': obj_.newUserChecked }"
    class="d-flex align-center"
    height="45"
  >
    <v-checkbox
      class="ma-0 contract mr-1 rounded-15"
      dense
      hide-details
      v-model="obj_.newUserChecked"
      @change="handleChange"
    ></v-checkbox>
    <div class="text-center">
      <h6
        :class="{ 'text-primary': obj_.newUserChecked }"
        class="font-weight-medium ma-0"
      >
        عميل جديد
      </h6>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'NewAgent',
  props: ['obj'],
  computed: {
    obj_: {
      get() {
        return this.obj
      },
      set(value) {
        this.$emit('changeNewUser', value)
      }
    }
  },
  methods: {
    handleChange(state) {
      this.$emit('onChange', state)
    }
  }
}
</script>
