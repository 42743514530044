<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex justify-space-between align-center mb-2 mt-6 position-relative"
    >
      <div class="d-flex justify-start flex-wrap">
        <div class="d-flex align-center" style="gap: 1rem">
          <v-menu
            v-if="!isCustomer"
            content-class="vMenuFilter elevation-0"
            offset-y
            transition="slide-y-transition"
            width="150"
            ref="tasksType"
          >
            <!-- v-if="smAndUp" -->
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center">
                <v-slide-x-transition>
                  <v-card
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex justify-space-between px-2"
                    width="150"
                  >
                    <h6
                      class="mr-2 text-truncate"
                      v-if="filters.selectedRequestStatus"
                    >
                      {{ filters.selectedRequestStatus }}
                    </h6>
                    <h6 class="mr-2" v-else>حالة الطلب</h6>
                    <v-icon
                      >{{
                        menuStateSwitcher($refs.tasksType)
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-card>
                </v-slide-x-transition>
              </div>
            </template>
            <v-list
              class="contractVmenuList pa-0"
              v-for="(option, i) in requestPriorities"
              :key="i"
            >
              <v-list-item-group>
                <v-list-item class="px-1">
                  <v-list-item-title
                    ref="sortOption"
                    class="font-14 mr-1 mb-2 font-weight-medium"
                    @click="
                      setFiltervalue(option, 'selectedRequestStatus', 'status')
                    "
                  >
                    {{ option.nameAr }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-menu
            content-class="vMenuFilter elevation-0"
            offset-y
            transition="slide-y-transition"
            width="150"
            ref="assignTo"
          >
            <!-- v-if="smAndUp" -->
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center">
                <v-slide-x-transition>
                  <v-card
                    v-on="on"
                    v-bind="attrs"
                    class="d-flex justify-space-between px-2"
                    width="150"
                  >
                    <h6 class="mr-2 text-truncate" v-if="filters.selectedType">
                      {{ filters.selectedType }}
                    </h6>
                    <h6 v-else class="mr-2">نوع الطلب</h6>
                    <v-icon
                      >{{
                        menuStateSwitcher($refs.assignTo)
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-card>
                </v-slide-x-transition>
              </div>
            </template>
            <v-list
              class="contractVmenuList pa-0 pt-3"
              v-for="(req, i) in requestTypes"
              :key="i"
            >
              <v-list-item-group>
                <v-list-item class="">
                  <v-list-item-title
                    ref="sortOption"
                    class="font-14 mr-1 font-weight-medium"
                    @click="setFiltervalue(req, 'selectedType', 'type')"
                  >
                    <p>
                      {{ req.nameAr }}
                    </p>
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <div v-if="!isCustomer" class="max-width-220 mx-2">
            <v-text-field
              height="40"
              dense
              filled
              placeholder="ابحث بإسم العميل"
              :hide-details="true"
              :value="search"
              @input="searchForClients"
            />
          </div>
          <!-- <MultipleSelectionsForDelete
            ref="MultipleSelectionsForDelete"
            v-bind="multipleSelectionsData"
            @changeSelect="selectAllChanged"
            @switched="switched"
          /> -->
        </div>
      </div>

      <OrderdBy :sort-options="sortOptions" @handleSort="handleFilters" />
    </div>
    <div class="d-flex mt-4">
      <template v-for="(filter, idx) in filters">
        <v-card
          class="d-flex pa-1 flex-grow-0 justify-between align-center mx-1"
          v-if="filter"
          :key="idx"
        >
          <h5 class="ma-0 mx-2 text--purple font-12 text-no-wrap py-2">
            {{ filter }}
          </h5>
          <v-icon
            class="font-weight-medium font-14 pl-1"
            @click="restFilters(idx)"
          >
            mdi-close
          </v-icon>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from '@/helpers/debounce'
// import MultipleSelectionsForDelete from '@/components/helper/MultipleSelectionsForDelete'
import requests from '@/constans/Requests'
import multipleSelections from '@/mixins/multipleSelections'
import OrderdBy from '@/components/task-manager/OrderdBy.vue'

export default {
  name: 'RequestFilter',
  components: {
    // MultipleSelectionsForDelete,
    OrderdBy
  },
  mixins: [multipleSelections],
  props: {
    requestTypes: {
      default() {
        return []
      }
    },
    ptClientName: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    search: '',
    ...requests,
    filterQuery: {
      status: null,
      type: null,
      applicantName: null
    },
    filters: {
      selectedType: null,
      selectedRequestStatus: null
    },
    requestPriorities: [
      { nameAr: 'طارئ', nameEn: 'urgent' },
      { nameAr: 'عاجل', nameEn: 'high' },
      { nameAr: 'متوسط', nameEn: 'normal' },
      { nameAr: 'منخفض', nameEn: 'low' }
    ],
    sortOptions: [
      { nameAr: 'الأحدث', nameEn: 'Newest' },
      { nameAr: 'الأقدم', nameEn: 'Oldest' }
    ]
  }),
  watch: {
    ptClientName: {
      immediate: true,
      handler(val) {
        if (val) {
          this.search = val
          this.filterQuery.applicantName = val
          this.$emit('search', this.filterQuery)
        }
      }
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    deleteTitle() {
      return 'حذف المهمة'
    },
    restoreTitle() {
      return 'استرجاع المهمة المحذوفة'
    }
  },
  methods: {
    menuStateSwitcher(ref) {
      return ref?.isActive
    },
    getRequestTypesOptions() {
      return this.requestTypes.filter((reqType) =>
        this.isCustomer
          ? reqType?.userType?.includes(this.activeCustomerRole)
          : reqType?.role?.includes('business')
      )
    },
    searchForClients: debounce(function (searchText) {
      this.search = searchText
      this.filterQuery.applicantName = searchText
      this.$emit('search', this.filterQuery)
    }, 600),
    switched({ name, value }) {
      this.$emit('switched', { name, value })
    },
    setFiltervalue(filter, filterType, key) {
      this.$emit('resetPagination')
      this[filterType] = filter.nameAr
      this.filterQuery[key] = key === 'status' ? filter.nameEn : filter.code
      this.$set(this.filters, filterType, filter.nameAr)
      this.$emit('handleFilters', this.filterQuery)
    },
    restFilters(idx) {
      const formatIdx = idx === 'selectedType' ? 'type' : 'status'
      this.filters[idx] = ''
      this.filterQuery[formatIdx] = null
      this.$emit('handleFilters', this.filterQuery)
    },
    handleFilters(filters = {}) {
      this.filterQuery = { ...this.filterQuery, ...filters }
      this.$emit('handleFilters', this.filterQuery)
      // this.loadTasks()
    },
    // async deleteItems() {
    //   const message = this.showSelectAll
    //     ? `هل انت متاكد من حذف المهمات وعددهم ${this.getAllChecked.length}`
    //     : 'هل انت متاكد من حذف المهمة'
    //   // 1 - Open popup and confirm delete or not
    //   const status = await this.$root.$confirm.open({
    //     title: this.deleteTitle,
    //     message,
    //     options: {
    //       color: 'red'
    //     }
    //   })

    // If confirmed
    // if (status) {
    //   try {
    //     if (this.$refs?.MultipleSelectionsForDelete) {
    //       this.$refs.MultipleSelectionsForDelete.selectAll = false
    //     }
    //     const body = { ids: this.getAllChecked }
    //     await MultipleDeletion.deleteTasks(body)
    //     this.loadTasks()
    //     this.addNotification({
    //       text: 'تم الحذف بنجاح',
    //       color: 'success'
    //     })
    //   } catch {
    //     this.addNotification({
    //       text: 'حدث خطا يرجى المحاولة مره اخرى',
    //       color: 'error'
    //     })
    //   }
    // }
    // },
    async restoreDelete() {
      const message = this.showSelectAll
        ? `هل انت متاكد من استرجاع المهام المحذوفة وعددهم ${this.getAllChecked.length}`
        : 'هل انت متاكد من استرجاع المهمة المحذوفة'
      await this.$root.$confirm.open({
        title: this.restoreTitle,
        statusRestore: true,
        message,
        options: {
          color: 'red'
        }
      })
    }
  },
  mounted() {
    this.getRequestTypesOptions()
  }
}
</script>
