<template>
  <v-card class="mainCard mainTaxCard px-5 py-3 my-3 justify-space-between">
    <div class="d-flex">
      <div @click="showDetails" class="cursor d-flex justify-space-between">
        <div class="ml-4 align-center d-flex">
          <v-img :src="taxIcon" alt="taxIcon"></v-img>
        </div>
        <div class="min-w-120">
          <h5 class="max-w-100 text-truncate font-16 mb-2">
            {{ cardTitle }}
          </h5>
          <v-layout align-center>
            <h5
              v-if="!isCancelledTax"
              :class="specifyNameColor(item.status).color"
              class="ma-0 tag-sm text-center py-0 pb-1"
            >
              <span class="font-12 font-weight-bold my-auto text-truncate">
                {{ specifyNameColor(item.status).nameAr }}
              </span>
            </h5>
            <h5 v-else class="red--tag ma-0 tag-sm text-center py-0 pb-1">
              <span class="font-12 font-weight-bold my-auto text-truncate">
                ملغيه
              </span>
            </h5>
          </v-layout>
        </div>
      </div>
      <div
        v-if="+item?.paymentNotes?.length > 0 && !paymentNotes"
        class="d-flex align-center"
      >
        <v-tooltip content-class="v-tool" color="black" min-width="95" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              v-on="on"
              @click="showVersions"
              class="mx-3 cursor mt-3 w-100"
            >
              <h5
                class="ma-0 font-weight-medium versions bill-sm ma-auto d-flex justify-center align-center"
              >
                <span class="font-16 font-weight-bold">
                  {{ item.paymentNotes?.length }}
                </span>
              </h5>
            </div>
          </template>
          <span class="tool--tip">عدد إصدارات الفاتوره الضريبيه</span>
        </v-tooltip>
      </div>
    </div>

    <div
      class="d-flex justify-space-between flex-wrap"
      :class="{ 'mt-4': sm, 'mw-65 ': sm, 'mw-75': !sm }"
    >
      <div class="d-flex align-center mobile__ml__10px">
        <div class="d-flex flex-column">
          <div class="mb-2">
            <h6 class="text-gray ma-0 text-truncate font-12 fw-400">
              اسم المالك
            </h6>
            <h5 class="ma-0 fw-400 text-truncate w-120">
              {{ item.owner ? item.owner.name : 'المالك' }}
            </h5>
          </div>
          <div class="mb-2">
            <h6 class="text-gray ma-0 text-truncate font-12 fw-400">
              اسم المستأجر
            </h6>
            <h5 class="ma-0 fw-400 text-truncate w-120">
              {{ item.renter ? item.renter.name : 'المستأجر' }}
            </h5>
          </div>
        </div>
      </div>

      <div class="d-flex align-center mobile__ml__10px">
        <div class="d-flex flex-column">
          <div class="mb-2">
            <h6 class="text-gray ma-0 text-truncate font-12 fw-400">عقار</h6>
            <h5 class="text-truncate ma-0 fw-400 text-truncate w-120">
              {{ item.realEstate ? item.realEstate.name : 'اسم العقار' }}
            </h5>
          </div>
          <div class="mb-2">
            <h6 class="text-gray ma-0 text-truncate font-12 fw-400">
              تاريخ الانشاء
            </h6>
            <h5 class="ma-0 fw-400 text-truncate">
              {{ formatDate(item.createdAt) }}
            </h5>
          </div>
        </div>
      </div>

      <div class="d-flex mobile__ml__10px" v-if="isCancelledTax ? true : !sm">
        <div class="d-flex align-center">
          <div class="justify-space-between align-center">
            <div class="mb-2">
              <h6 class="text-gray ma-0 text-truncate font-12 fw-400">من</h6>
              <h5 class="text-truncate ma-0 fw-400 text-truncate">
                {{ taxInvoiceDateFrom }}
              </h5>
            </div>
            <div class="mt-3 mb-2">
              <h6 class="text-gray ma-0 text-truncate font-12 fw-400">إلي</h6>
              <h5 class="ma-0 fw-400 text-truncate">
                {{ taxInvoiceDateTo }}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex mobile__ml__10px" v-if="!xs">
        <div class="d-flex align-center">
          <div class="justify-space-between align-center">
            <div class="mb-2">
              <h6 class="text-gray ma-0 text-truncate font-12 fw-400">
                المبلغ
              </h6>
              <h5 class="text-truncate ma-0 fw-400 text-truncate">
                {{ numberFormatter(renderPaymant, 2) }}
              </h5>
            </div>
            <div class="mt-3 mb-2">
              <h6 class="text-gray ma-0 text-truncate font-12 fw-400">
                نسبه الضريبه المضافه
              </h6>
              <h5 class="ma-0 fw-400 text-truncate">{{ item.tax }} %</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-space-between align-center mobile__ml__10px">
        <div class="d-flex flex-column">
          <h6 class="text-gray ma-0 text-truncate font-12 fw-400">
            قيمه الضريبه المضافه
          </h6>
          <h5 class="ma-0 font-weight-medium">{{ taxTotal }} ريال</h5>
        </div>
      </div>

      <div
        v-if="
          !aboveMdView &&
          $hasPermission('users', 'taxes', 'add') &&
          showChangeToApproved &&
          !isReadonly
        "
        class="d-flex align-center"
      >
        <v-btn class="transferBtn" text @click="changeTaxToApproved">
          <span class="brub--flag font-14 font-weight-bold mb-1">
            تحويل الى ساريه
          </span>
        </v-btn>
      </div>

      <v-menu
        v-if="showDropdownMenu"
        content-class="dropdown-menu"
        transition="slide-y-transition"
        rigth
        offset-x
      >
        <template #activator="{ on, attrs }">
          <div class="d-flex align-center">
            <v-btn
              color="lamb"
              class="options-btn"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </div>
        </template>
        <v-list class="actionList" :width="!isDraftTax ? 170 : 190">
          <v-list-item @click="exportTheTax">
            <v-icon class="ml-4">mdi-export</v-icon>
            <v-list-item-content>
              <v-list-item-title>تصدير الفاتوره</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="aboveMdView && showChangeToApproved && !isCustomer"
            @click="changeTaxToApproved"
          >
            <img
              width="20"
              class="mt-2 ml-4"
              max-height="28"
              max-width="28"
              :src="exportTax"
              loading="lazy"
            />
            <v-list-item-content>
              <v-list-item-title>تحويل الى ساريه</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="editTax" v-if="!isCustomer">
            <img
              width="20"
              class="mt-2 ml-4"
              max-height="28"
              max-width="28"
              :src="edit"
              loading="lazy"
            />
            <v-list-item-content>
              <v-list-item-title>تعديل الفاتوره</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="$emit('sharePdf', item)">
            <img
              width="20"
              class="mt-2 ml-4"
              max-height="28"
              max-width="28"
              :src="shareIcon"
              loading="lazy"
            />
            <v-list-item-content>
              <v-list-item-title>مشاركة</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="deleteTax" v-if="!isApprovedTax && !isCustomer">
            <img
              width="20"
              class="mt-2 ml-4"
              max-height="28"
              max-width="28"
              :src="cancelIcon"
              loading="lazy"
            />
            <v-list-item-content>
              <v-list-item-title class="danger--text"
                >إلغاء الفاتوره</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- Multiple selections for delete -->
    <slot />
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import dateFormat from '@/mixins/dateFormat.js'
import numberFormatter from '@/mixins/numberFormatter.js'

export default {
  name: 'TaxInvoiceCard',
  props: {
    item: {},
    paymentNotes: {
      default: null
    },
    type: {
      type: String,
      default: 'payment'
    },
    sideVersions: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  mixins: [dateFormat, numberFormatter],
  data() {
    return {
      taxIcon: require('@/assets/img/tax-invoices/taxInvoiceIcon.png'),
      shareIcon: require('@/assets/img/svgs/share-pdf.svg'),
      cancelIcon: require('@/assets/img/svgs/cancel.svg'),
      exportTax: require('@/assets/img/svgs/exportTax.svg'),
      edit: require('@/assets/img/svgs/editPen.svg')
    }
  },
  computed: {
    ...mapGetters('accountState', ['isCustomer']),
    cardTitle() {
      if (this.paymentNotes) {
        return this.paymentNotes?.type === 'dr' ? 'دائن' : 'مدين'
      }
      return this.item.title ? this.item.title : 'فاتوره ضريبيه'
    },
    aboveMdView() {
      return this.md || this.sm || this.xs
    },
    md() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    },
    xs() {
      return this.$vuetify.breakpoint.xs
    },
    // eslint-disable-next-line consistent-return
    specifyNameColor: () => (app) => {
      switch (app) {
        case 'draft':
          return {
            nameAr: 'مسوده',
            color: 'orange--tag'
          }
        case 'approved':
          return {
            nameAr: 'ساريه',
            color: 'brub--tag'
          }
      }
    },
    isCancelledTax() {
      return !!this.item?.deleted
    },
    isApprovedTax() {
      return this.item?.status === 'approved'
    },
    isDraftTax() {
      return this.item?.status === 'draft'
    },
    taxTotal() {
      return this.item?.total.toLocaleString('en-US', {
        maximumFractionDigits: 2
      })
    },
    payment() {
      return this.item?.payment?.toFixed(2)
    },
    renderPaymant() {
      return this.type === 'notes' ? this.paymentNotes.amount : this.payment
    },
    showDropdownMenu() {
      return (
        // this.$hasPermission('users', 'taxes', 'add') &&
        !this.sideVersions && !this.isCancelledTax
      )
    },
    showChangeToApproved() {
      return !this.isCancelledTax ? this.isDraftTax : false
    },
    taxInvoiceDateFrom() {
      return this.item.hijri
        ? this.formatToHijriDate(this.item.dateFrom)
        : this.formatDate(this.item.dateFrom)
    },
    taxInvoiceDateTo() {
      return this.item.hijri
        ? this.formatToHijriDate(this.item.dateTo)
        : this.formatDate(this.item.dateTo)
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    showDetails() {
      this.$emit('showDetails', { visible: true, taxInvoice: this.item })
    },
    showVersions() {
      this.$emit('showVersions', { visible: true, taxInvoice: this.item })
    },
    exportTheTax() {
      this.$emit('exportPdfTax', { tax: this.item })
    },
    changeTaxToApproved() {
      this.$emit('exportTax', { taxInvoice: this.item })
    },
    editTax() {
      if (this.isApprovedTax) {
        this.$emit('editApprovedTax', this.item)
      } else {
        this.$emit('editDraftTax', this.item)
      }
    },
    deleteTax() {
      this.$emit('deleteTax', this.item)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_main-card-list.scss';
.w-120 {
  max-width: 120px !important;
}
</style>
