<template>
  <v-dialog max-width="680px" persistent :value="dialogState">
    <!-------------------------------------->
    <!-------------Modal-------------------->
    <!-------------------------------------->

    <v-card class="add-new-property pa-0 add__customer__popup">
      <!-------------------------------------->
      <!-------------Head modal--------------->
      <!-------------------------------------->

      <v-card-title
        class="py-0 pr-4 pl-0 mb-4 d-flex justify-space-between pb-1 custom__shadow mobile__py__6px mobile__px__15px"
      >
        <!------------------Title-------------------->
        <h4 class="ma-0" v-text="actionBtnText" />
        <!-------------Close modal-------------------->
        <v-btn
          @click.prevent="closeModal"
          data-test="closeClientModal"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-------------------------------------->
      <!-------------Details modal------------>
      <!-------------------------------------->
      <v-card-text class="px-5 pb-0">
        <v-tabs-items v-model="tab" class="pa-5 pt-0" touchless>
          <v-tab-item class="add__property__detail__popup">
            <clientDetails
              :clientData="clientData"
              :dialogState="dialogState"
              :checkPhoneNumberLoader="checkPhoneNumberLoader"
              @setCheckPhoneNumberLoader="checkPhoneNumberLoader = $event"
              @newUserAdded="newUserAdded"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <!-------------Submit (add or edit)------------>
      <v-card-actions class="pa-1 mt-4 add__customer__action__btn">
        <v-btn
          v-if="tab >= 1"
          class="ma-0 rounded-7 my-3 mr-3"
          color="grey"
          data-test="submit"
          @click="tab--"
        >
          <v-icon class="send-icon font-16 mt-1">mdi-arrow-left</v-icon>
          <span class="mobile__mb__0 ml-1 font-weight-bold font-12">
            السابق
          </span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!disableSubmit || checkPhoneNumberLoader"
          :loading="isLoading || checkPhoneNumberLoader"
          class="ma-0 rounded-7 my-3 ml-3"
          color="primary"
          data-test="submit"
          @click="addOrEdit"
        >
          <span
            class="mobile__mb__0 ml-1 font-weight-bold font-12"
            v-text="actionBtnText"
          />
          <v-icon class="send-icon font-16 mt-1">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { EnvService } from '@/services'
import { bus } from '@/helpers/eventBus'
import '@/styles/material-dashboard/_add-new-property.scss'
import clientDetails from '@/components/add-client/clientModalTabs/clientDetails.vue'
// import banksAccountsForm from '@/components/add-client/clientModalTabs/banksAccountsForm.vue'
export default {
  name: 'AddClientModal',
  props: {
    clientChange: {
      type: Function
    },
    realEstates: {
      default() {
        return []
      }
    },
    dialogState: {
      type: Boolean,
      default: false
    },
    clientData: {
      default() {
        return {}
      }
    },
    loadClient: {
      type: Function,
      required: false
    }
  },
  components: {
    clientDetails
    // banksAccountsForm
  },
  data() {
    return {
      tab: 0,
      isBankAccountValid: false,
      checkPhoneNumberLoader: false,
      client: {},
      isValid: false,
      isLoading: false
      // bankAccounts: [],
      // bankAccountsLoading: false
    }
  },
  watch: {
    dialogState: {
      handler(val) {
        if (val && this.isAnOwnerClient) {
          this.clientChange('owner')
        }
      }
    }
    // tab: {
    //   handler() {
    //     this.bankAccountsLoading = true
    //     setTimeout(async () => {
    //       if (this.$refs?.banksAccountsForm?.bankAccounts) {
    //         this.$refs.banksAccountsForm.bankAccounts = await [
    //           ...this.bankAccounts
    //         ]
    //       }
    //       this.bankAccountsLoading = false
    //     })
    //   }
    // }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    isEditMood() {
      return !!(Object.keys(this.clientData)?.length || 0)
    },
    isAnOwnerClient() {
      return !!this.client?.role?.find((R) => R === 'owner') ?? false
    },
    // If client._id exist will be edit else will be add
    addOrEdit() {
      return this.client._id ? this.editClient : this.addClient
    },
    // Title will render in head and submit
    actionBtnText() {
      return this.client._id ? 'تعديل عميل' : 'إضافة عميل'
    },
    // Data will sended
    dataToSend() {
      return {
        name: this.client.name,
        idNumber: this.client.idNumber,
        registrationNumber: this.client.registrationNumber,
        phoneNumber: (this.client.phoneNumber || '')
          .replace(/\s/g, '')
          ?.replace('0', '966'),
        clientType: this.client.clientType,
        email: this.client?.email,
        taxNumber: Number(this.client.taxNumber),
        environment: {
          _id: this.currentEnv._id,
          name: this.currentEnv.name,
          role: this.client.role,
          whatsAppNumber: this.client?.whatsAppNumber
        },
        userAddress: {
          address: this.client.address,
          city: this.client.city,
          district: this.client.district
        }
        // bankAccounts: [...this.bankAccounts]
      }
    },
    // Disable button submit
    disableSubmit() {
      return !!this.isValid
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    newUserAdded(val) {
      this.client = val.obj
      this.isValid = val.isValid
    },
    async addClient() {
      try {
        this.isLoading = true
        const { data } = await EnvService.addClient(
          this.dataToSend,
          this.currentEnv._id
        )

        if (data && data.user) {
          this.addNotification({
            text: 'تم اضافة العميل بنجاح',
            color: 'green'
          })
          this.$emit('refresh-clients-data')
          bus.$emit('clientsChanged')
        }
      } catch (err) {
        if (err.response?.data?.error === 'user_exists') {
          this.addNotification({
            text: 'هذا المستخدم موجود مسبقاً',
            color: 'error'
          })
        } else {
          this.addNotification({
            text: 'حدث خطا يرجى المحاولة مره اخرى',
            color: 'error'
          })
        }
      } finally {
        this.isLoading = false
        this.closeModal()
      }
    },
    async editClient() {
      try {
        this.isLoading = true
        await EnvService.editClient(this.dataToSend, this.client._id)
        this.addNotification({
          text: 'تم تحديث بيانات العميل بنجاح',
          color: 'green'
        })
        this.closeModal()
        this.loadClient()
      } catch {
        this.addNotification({
          text: 'حدث خطأ داخلي يرجى المحاولة لاحقا',
          color: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    closeModal() {
      this.client = {}
      this.tab = 0
      this.isValid = false
      this.$emit('close-client-dialog', 'close')
    },
    // async getClientBankAccounts() {
    //   try {
    //     const { data } = await EnvService.getEnvOrClientBankAccounts(
    //       this.currentEnv?._id,
    //       this.client?._id
    //     )
    //     this.bankAccounts = data.bankAccounts ?? []
    //   } catch {
    //     this.addNotification({
    //       text: 'حدث خطأ أثناء تحميل حسابات العميل',
    //       color: 'error'
    //     })
    //   }
    // },
    handleEditMood() {
      if (this.isEditMood) {
        this.client = {
          ...this.clientData,
          phoneNumber: this.clientData?.phoneNumber?.replace(/\s/g, '')
        }
        // this.getClientBankAccounts()
      }
    }
  },
  created() {
    this.handleEditMood()
  }
}
</script>
