<template>
  <div style="height: 75vh" class="contentSection column" v-if="item">
    <v-row class="mt-5 bond-data-container" no-gutters>
      <v-col lg="4" md="6" sm="6" cols="6">
        <h6 class="mb-1 text-light ma-0 font-12">اسم العقار</h6>
        <h5 class="ma-0 font-weight-medium line__height__fixes">
          {{ bondRealestate }}
        </h5>
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="6" v-if="bondUnit">
        <h6 class="mb-1 text-light ma-0 font-12">رقم الوحدة</h6>
        <h5 class="ma-0 font-weight-medium line__height__fixes">
          {{ bondUnit }}
        </h5>
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="6">
        <h6 class="mb-1 text-light ma-0 font-12">اسم المالك</h6>
        <h5 class="ma-0 font-weight-medium line__height__fixes">
          {{ getOwnerName(item) }}
        </h5>
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="6">
        <h6 class="mb-1 text-light ma-0 font-12">رقم السند</h6>
        <h5 class="ma-0 font-weight-medium line__height__fixes">
          {{ item?.number }}
        </h5>
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="6">
        <h6 class="mb-1 text-light ma-0 font-12">تاريخ السند</h6>
        <h5 class="ma-0 font-weight-medium line__height__fixes">
          {{ formatDate(item.date) }}
        </h5>
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="6">
        <h6 class="mb-1 text-light ma-0 font-12">بواسطة</h6>
        <div class="d-flex align-center">
          <h6
            class="red--tag bill-md text-center font-12 ma-0 ml-2 text-truncate"
          >
            {{ $subString(createdByName) }}
          </h6>
          <h5
            class="ma-0 font-weight-medium text-truncate max-w-120 line__height__fixes"
          >
            {{ createdByName }}
          </h5>
        </div>
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="6">
        <h6 class="mb-3 text-light ma-0 font-12">تاريخ الانشاء</h6>
        <h5 class="ma-0 font-weight-medium line__height__fixes">
          {{ formatDate(item?.createdAt) }}
        </h5>
      </v-col>
      <v-col lg="4" md="6" sm="6" cols="6" v-if="item?.renterName?.length">
        <h6 class="mb-1 text-light ma-0 font-12">اسم المستاجر</h6>
        <h5 class="ma-0 font-weight-medium line__height__fixes">
          {{ item?.renterName ?? item.renter.name }}
        </h5>
      </v-col>
    </v-row>

    <div class="my-4" v-if="item.description">
      <h6 class="mb-1 text-light ma-0 font-12">وصف السند</h6>
      <h5 class="ma-0 font-weight-medium">
        {{ item.description }}
      </h5>
    </div>
    <v-divider class="mt-3"></v-divider>
    <div class="px-8 mt-0">
      <v-data-table
        :headers="headers"
        :items="bonsSubtypes"
        class="bond-table mt-2"
        hide-default-footer
        mobile-breakpoint="0"
      />
    </div>
    <div class="px-8 mt-10">
      <div class="after_table_div after__table__block">
        <div class="after_table_content">
          <div class="transaction_detail after__table__details__block">
            <div class="d-block result__total align-items-center">
              <div>
                <div>
                  <div class="rest-bg">
                    <div class="d-flex mb-1" style="overflow: auto">
                      <h6 class="mb-1 fw-500 text-h5 mt-8 mb-5 pl-0">
                        {{ item.amountInWords ?? 'غير متوفر' }}
                      </h6>
                      <h6 class="mb-1 w-100 fw-500 text-h5 mt-8 mb-5 pr-3">
                        {{ item.amountInWordsEn ?? 'غير متوفر' }}
                      </h6>
                    </div>
                    <div class="d-flex mb-1" v-if="item?.paymentMethod">
                      <h6
                        class="fw-500 mt-8 mb-5 text-h5 d-flex justify-space-between align-center pl-0"
                      >
                        {{ item?.payMethod?.nameAr }}
                      </h6>
                      <h6 class="mb-1 w-100 fw-500 text-h5 mt-8 mb-5 pr-3">
                        {{ item?.payMethod?.nameEn }}
                      </h6>
                    </div>
                    <div
                      class="d-flex mb-1"
                      v-if="getBondType && item.thirdParty"
                    >
                      <h6
                        class="fw-400 mt-8 mb-5 mw-28 text-h5 d-flex justify-space-between align-center pl-0"
                      >
                        {{ getProviderName }}
                      </h6>
                      <h6 class="mb-1 w-100 fw-500 text-h5 mt-8 mb-5">
                        {{ item.thirdParty }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-8" v-if="item.isClaimed">
      <ul>
        <li class="font-16">تم سداد قيمة السند من المستأجر للمالك مباشرة</li>
      </ul>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/mixins/dateFormat.js'
import sanitizers from '@/mixins/sanitizers.js'
export default {
  name: 'bond-details',
  props: {
    item: {
      type: Object
    },
    headers: {
      type: Array
    },
    bonsSubtypes: {
      type: Array
    }
  },
  mixins: [dateFormat, sanitizers],
  computed: {
    getBondType() {
      return this.item?.bondType
    },
    getProviderName() {
      return this.getBondType?.name
    },
    createdByName() {
      return this.item?.createdBy?.name || ''
    },
    bondUnit() {
      return (
        this.item?.realEstate?.propertyName?.split('-')[1] ??
        this.item?.realEstate?.name?.split('-')[1] ??
        ''
      )
    },
    bondRealestate() {
      return (
        this.item?.realEstate?.propertyName?.split('-')[0] ??
        this.item?.realEstate?.name?.split('-')[0] ??
        ''
      )
    }
  },
  methods: {
    getOwnerName(item) {
      return item.contract
        ? item?.ownerName ?? item.owner.name
        : item.client.name
    }
  }
}
</script>

<style lang="scss" scoped></style>
