<template>
  <div class="billing__info__popup">
    <v-form ref="form" v-model="isTabValid" class="mobile__mb__15px">
      <v-container class="pa-0">
        <h5 class="pr-3 font-14 mobile__pl__0">بيانات الفاتوره الحره</h5>
        <v-col class="pl-3 mobile__pl__0" cols="12" sm="12">
          <h5
            :class="{
              'red--flag': validateForm ? billReportValidation : false
            }"
            class="mt-0 font-12 mb-2"
          >
            بيان الفاتورة*
          </h5>
          <v-text-field
            dense
            filled
            hide-details
            placeholder="بيان الفاتورة"
            required
            v-model="tabInfo.title"
            :rules="selectRules"
            ref="billReport"
          ></v-text-field>
        </v-col>
        <v-col class="pl-3 mobile__pl__0 d-flex align-center" cols="12" sm="12">
          <v-switch
            class="client__filter__switch mt-1"
            inset
            dense
            small
            hide-details="auto"
            v-model="isHijriDate"
            @change="setHijriObject"
          ></v-switch>
          <h6 class="font-12">تفعيل التاريخ الهجري</h6>
        </v-col>
        <v-row>
          <v-col class="pl-3" cols="12" sm="6">
            <h5
              :class="{
                'red--flag': validateForm ? startDateValidation : false
              }"
              class="mr-3 font-12 mb-2 mobile__mr__0"
            >
              تاريخ البدايه*
            </h5>

            <!--Hijri date from calendar-->
            <v-dialog
              v-if="isHijriDate"
              :return-value.sync="tabInfo.hijri.dateFrom"
              ref="startAtDialog"
              v-model="startAtModal"
              width="320px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="[(v) => !!v || 'من فضلك ادخل تاريخ البدايه']"
                  append-icon="mdi-calendar-month-outline"
                  dense
                  filled
                  hide-details
                  placeholder="تاريخ البدايه"
                  readonly
                  required
                  v-bind="attrs"
                  v-model="tabInfo.hijri.dateFrom"
                  v-on="on"
                  ref="startDate"
                  class="mr-3 mobile__mr__0"
                ></v-text-field>
              </template>
              <v-hijri-date-picker
                color="primary"
                scrollable
                locale="ar"
                :max="maxHijriDateFrom"
                v-model="tabInfo.hijri.dateFrom"
              >
                <v-spacer></v-spacer>
                <v-btn @click="startAtModal = false" color="primary" text>
                  الغاء
                </v-btn>
                <v-btn
                  @click="$refs.startAtDialog.save(tabInfo.hijri.dateFrom)"
                  color="primary"
                  text
                  >حفظ
                </v-btn>
              </v-hijri-date-picker>
            </v-dialog>

            <!--Gregorian date from calendar-->
            <v-dialog
              v-else
              :return-value.sync="tabInfo.dateFrom"
              ref="startAtDialog"
              v-model="startAtModal"
              width="320px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="[(v) => !!v || 'من فضلك ادخل تاريخ البدايه']"
                  append-icon="mdi-calendar-month-outline"
                  dense
                  filled
                  hide-details
                  placeholder="تاريخ البدايه"
                  readonly
                  required
                  v-bind="attrs"
                  :value="tabInfo.dateFrom | formatGregorianDate"
                  v-on="on"
                  ref="startDate"
                  class="mr-3 mobile__mr__0"
                ></v-text-field>
              </template>
              <v-date-picker color="primary" scrollable v-model="start">
                <v-spacer></v-spacer>
                <v-btn @click="startAtModal = false" color="primary" text>
                  الغاء
                </v-btn>
                <v-btn
                  @click="$refs.startAtDialog.save(start)"
                  color="primary"
                  text
                  >حفظ
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="12" sm="6">
            <h5
              :class="{ 'red--flag': validateForm ? endDateValidation : false }"
              class="12 mb-2 font-12"
            >
              تاريخ الانتهاء*
            </h5>

            <!--Hijri date to-->
            <v-dialog
              v-if="isHijriDate"
              :return-value.sync="tabInfo.hijri.dateTo"
              ref="endAtDialog"
              v-model="endAtModal"
              width="320px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="[(v) => !!v || 'من فضلك ادخل تاريخ الانتهاء']"
                  append-icon="mdi-calendar-month-outline"
                  dense
                  filled
                  hide-details
                  placeholder="تاريخ الانتهاء"
                  readonly
                  required
                  v-bind="attrs"
                  v-model="tabInfo.hijri.dateTo"
                  v-on="on"
                  ref="endDate"
                  class="ml-3 mobile__ml__0"
                ></v-text-field>
              </template>
              <v-hijri-date-picker
                color="primary"
                scrollable
                :min="minHijriDateTo"
                v-model="tabInfo.hijri.dateTo"
              >
                <v-spacer></v-spacer>
                <v-btn @click="endAtModal = false" color="primary" text>
                  الغاء
                </v-btn>
                <v-btn
                  @click="$refs.endAtDialog.save(tabInfo.hijri.dateTo)"
                  color="primary"
                  text
                  >حفظ
                </v-btn>
              </v-hijri-date-picker>
            </v-dialog>

            <!--Gregorian date to-->
            <v-dialog
              v-else
              :return-value.sync="tabInfo.dateTo"
              ref="endAtDialog"
              v-model="endAtModal"
              width="320px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="[(v) => !!v || 'من فضلك ادخل تاريخ الانتهاء']"
                  append-icon="mdi-calendar-month-outline"
                  dense
                  filled
                  hide-details
                  placeholder="تاريخ الانتهاء"
                  readonly
                  required
                  v-bind="attrs"
                  :value="tabInfo.dateTo | formatGregorianDate"
                  v-on="on"
                  ref="endDate"
                  class="ml-3 mobile__ml__0"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="start"
                color="primary"
                scrollable
                v-model="end"
              >
                <v-spacer></v-spacer>
                <v-btn @click="endAtModal = false" color="primary" text>
                  الغاء
                </v-btn>
                <v-btn @click="$refs.endAtDialog.save(end)" color="primary" text
                  >حفظ
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-3" cols="12" sm="6">
            <h5
              :class="{ 'red--flag': validateForm ? taxValidation : false }"
              class="mr-3 font-12 mb-2 mobile__mr__0"
            >
              المبلغ*
            </h5>
            <v-text-field
              dense
              filled
              hide-details
              placeholder="المبلغ"
              required
              min="0"
              @keydown="$formatNumberInputs($event, true)"
              @input="handleValuesChanging($event, paymentKeys)"
              :value="paymentMask"
              :rules="selectRules"
              suffix="ريال"
              ref="tax"
              class="mr-3 mobile__mr__0"
            ></v-text-field>
          </v-col>
          <v-col class="pl-3" cols="12" sm="6">
            <h5
              :class="{ 'red--flag': validateForm ? paymentValidation : false }"
              class="font-12 mb-2"
            >
              قيمه الضريبه*
            </h5>
            <v-text-field
              :disabled="true"
              dense
              filled
              hide-details
              placeholder="قيمه الضريبه"
              required
              type="number"
              min="0"
              @keydown="$formatNumberInputs($event, true)"
              v-model.number="tabInfo.tax"
              :rules="selectRules"
              ref="payment"
              suffix="%"
              class="ml-3 mobile__ml__0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0" cols="12" sm="6">
            <h5 class="mr-3 font-12 mb-2 mobile__mr__0">الإجمالي</h5>
            <div
              class="total d-flex pt-1 pb-2 px-3 mr-4 mobile__mr__0 total__input"
            >
              <span class="w-100">
                {{ CalculatedTotal }}
              </span>
              <v-spacer />
              <span> ريال </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-card-actions class="pa-1 mt-4 d-flex align-center justify-space-between">
      <v-btn
        @click="previousTab"
        class="ma-0 rounded-7 my-3 ml-3"
        color="primary"
      >
        <v-icon class="send-icon font-16">mdi-arrow-left</v-icon>
        <span class="mobile__mb__0 ml-1 font-weight-bold font-12">
          السابق
        </span>
      </v-btn>

      <v-btn
        @click="exportTax"
        class="ma-0 rounded-7 my-3 ml-3 mobile__ml__0"
        color="primary"
        :loading="addUpdateBtnLoading"
      >
        <span class="mb-0 mobile__mb__0 ml-1 font-weight-bold font-12">
          {{ !taxToBeEditValid ? 'إنشاء فاتوره' : 'تحديث  الفاتوره' }}
        </span>
        <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import VHijriDatePicker from 'vuetify-umalqura'
import inputMasking from '@/mixins/inputMasking.js'

export default {
  name: 'info-step',
  components: {
    VHijriDatePicker
  },
  props: {
    resetValidation: {
      default: false
    },
    taxToBeEditValid: {},
    taxToBeEdit: { default: {} }
  },
  watch: {
    resetValidation: {
      handler() {
        this.addTaxInfo = {}
        this.tabInfo = {
          tax: 15
        }
        this.validateForm = false
      }
    },
    taxToBeEdit: {
      immediate: true,
      handler() {
        if (this.taxToBeEditValid) {
          this.tabInfo = { ...this.taxToBeEdit }
          if (this.tabInfo.hijri) {
            this.isHijriDate = true
            this.formatHijriDates()
          } else {
            this.isHijriDate = false
            this.start = this.$options.filters.formatGregorianDate(
              this.tabInfo.dateFrom
            )
            this.end = this.$options.filters.formatGregorianDate(
              this.tabInfo.dateTo
            )
            this.paymentMask = this.tabInfo?.payment.toLocaleString('en-US')
          }
        }
      }
    }
  },
  mixins: [inputMasking],
  data() {
    return {
      paymentMask: '',
      addUpdateBtnLoading: false,
      taxType: [
        {
          title: 'خصم',
          _id: 0,
          nameEn: 'dr'
        },
        {
          title: 'إضافه',
          _id: 1,
          nameEn: 'cr'
        }
      ],
      selectRules: [(v) => !!v || 'هذا الحقل مطلوب'],
      validateForm: false,
      isTabValid: false,
      addTaxInfo: {},
      tabInfo: {
        tax: 15,
        hijri: {
          dateFrom: '',
          dateTo: ''
        }
      },
      start: new Date().toISOString().substr(0, 10),
      end: new Date().toISOString().substr(0, 10),
      startAtModal: false,
      endAtModal: false,
      isHijriDate: false
    }
  },
  computed: {
    paymentKeys() {
      return {
        maskValKey: 'paymentMask',
        parentKey: 'tabInfo',
        actualValKey: 'payment'
      }
    },
    CalculatedTotal() {
      const totalTaxValue =
        this.tabInfo?.payment * (this.tabInfo?.tax / 100) +
        this.tabInfo?.payment
      return totalTaxValue ? totalTaxValue.toLocaleString('en-US') : 0
    },
    isTypeSelected() {
      return !!this.tabInfo?.type
    },
    billReportValidation() {
      return this.validateForm ? !this.$refs?.billReport?.valid : false
    },
    startDateValidation() {
      return this.validateForm ? !this.$refs?.startDate?.valid : false
    },
    endDateValidation() {
      return this.validateForm ? !this.$refs?.endDate?.valid : false
    },
    taxValidation() {
      return this.validateForm ? !this.$refs?.tax?.valid : false
    },
    paymentValidation() {
      return this.validateForm ? !this.$refs?.payment?.valid : false
    },
    maxHijriDateFrom() {
      return this.tabInfo.hijri?.dateTo ?? null
    },
    minHijriDateTo() {
      return this.tabInfo.hijri?.dateFrom ?? null
    }
  },
  methods: {
    exportTax() {
      this.validateForm = true
      if (this.isTabValid) {
        this.isHijriDate && (this.addTaxInfo.taxInfo = this.tabInfo)
        !this.isHijriDate &&
          (this.addTaxInfo.taxInfo = { ...this.tabInfo, hijri: null })
        this.$emit('setAddTaxInfo', this.addTaxInfo)
        if (this.taxToBeEditValid) {
          this.$emit('updateTax')
        } else {
          this.$emit('exportTax')
        }
      } else {
        this.$emit('addNotification')
      }
    },
    previousTab() {
      this.$emit('previousTab')
    },
    setHijriObject(val) {
      val && !this.tabInfo.hijri && this.$set(this.tabInfo, 'hijri', {})
      return null
    },
    convertToHijriDate(date) {
      const currentDate = Intl.DateTimeFormat('en-SA-u-ca-islamic-umalqura', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
        .formatToParts(date ? new Date(date) : Date.now())
        .reduce((acc, part) => {
          acc[part.type] = part.value
          return acc
        }, {})
      return `${currentDate.year}-${currentDate.month}-${currentDate.day}`
    },
    formatHijriDates() {
      this.$set(
        this.tabInfo.hijri,
        'dateFrom',
        this.convertToHijriDate(this.tabInfo.dateFrom)
      )
      this.$set(
        this.tabInfo.hijri,
        'dateTo',
        this.convertToHijriDate(this.tabInfo.dateTo)
      )
    }
  },
  filters: {
    formatGregorianDate(val) {
      if (!val) {
        return ''
      } else {
        return new Date(val).toISOString().substr(0, 10)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.total {
  background: #fafafa !important;
  border: 1px solid #efebf2;
  border-radius: 8px !important;
}
.total__input {
  color: #737373;
  font-size: 12px;
}
::v-deep {
  .v-input {
    .v-input__control {
      .v-input__slot {
        .v-text-field__slot {
          color: #737373;
          font-size: 12px;
          input {
            color: #737373;
            font-size: 12px;
          }
          // input
        }
        // v-text-field__slot
      }
      // v-input__slot
    }
    // v-input__control
  }
  // v-input
}

// ::v-deep
</style>
