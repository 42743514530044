<template>
  <v-dialog max-width="480px" persistent :value="dialogState">
    <v-card class="add-new-property pa-0">
      <!-----------1# Header----------->
      <v-card-title
        class="py-0 px-3 mb-0 custom__shadow d-flex justify-space-between"
      >
        <!----------Title----------->
        <h4 class="my-2" v-text="modelTitle" />

        <!----------Close----------->
        <v-btn
          @click.prevent="closeDialog"
          class="ma-0 mt-2"
          color="grey"
          fab
          text
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-----------2# Actions----------->
      <v-form ref="form" v-model="isValid">
        <v-container class="mb-0">
          <v-row no-gutters>
            <v-col class="d-flex justify-space-between align-center" cols="12">
              <h4 class="font-14">تفعيل الموافقات</h4>
              <v-switch
                v-model="localModule.active"
                class="ma-0 pa-0"
                dense
                hide-details="auto"
                inset
                :disabled="!hasUpdatePermission"
              />
            </v-col>
          </v-row>
          <v-divider class="my-5" />
          <v-row no-gutters>
            <v-col cols="12">
              <h4 class="font-14">العمليات</h4>
              <v-col
                class="pt-0 mobile__p__0 employee--select--col pr-0"
                cols="12"
                sm="12"
              >
                <v-select
                  ref="employees"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  v-model="localModule.actions"
                  :items="filteredActions"
                  placeholder="نوع العملية"
                  append-icon="mdi-chevron-down"
                  dense
                  item-text="nameAr"
                  item-value="nameEn"
                  :rules="selectRules"
                  filled
                  clearable
                  class="font-14"
                  multiple
                  hide-details
                  :disabled="!hasUpdatePermission"
                >
                </v-select>
              </v-col>
            </v-col>
            <!-- <v-col cols="12">
              <h4 class="font-14">طريق إرسال طلب الموافقة</h4>
              <v-col
                class="pt-0 mobile__p__0 employee--select--col pr-0"
                cols="12"
                sm="12"
              >
                <v-select
                  ref="employees"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  v-model="localModule.processType"
                  :items="processesTypes"
                  append-icon="mdi-chevron-down"
                  dense
                  item-text="nameAr"
                  item-value="nameEn"
                  item-title="nameAr"
                  :rules="selectRules"
                  placeholder="اختر طريقة الارسال"
                  filled
                  class="font-14"
                  hide-details
                  :disabled="!hasUpdatePermission"
                >
                  <template v-slot:selection="{ item }">
                    <div style="display: flex; align-items: center">
                      <v-list-item-title
                        class="mb-0 d-flex align-center pb-0"
                        style="text-align: right"
                      >
                        <span class="mb-0">
                          {{ item.nameAr }}
                        </span>
                      </v-list-item-title>
                      <span
                        style="white-space: nowrap"
                        class="pr-2 purple--text pt-2"
                      >
                        {{ item.desc }}
                      </span>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-col> -->
            <v-col cols="12">
              <h4 class="font-14">عدد الموافقات</h4>
              <v-col
                class="pt-0 mobile__p__0 employee--select--col pr-0"
                cols="12"
                sm="12"
              >
                <v-select
                  ref="employees"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  :items="approvalLevels"
                  item-text="title"
                  item-value="level"
                  :rules="levelRules"
                  v-model="localModule.levels"
                  placeholder="أختر مستوى الموافقه"
                  append-icon="mdi-chevron-down"
                  dense
                  filled
                  clearable
                  class="font-14"
                  hide-details
                  :disabled="!hasUpdatePermission"
                >
                </v-select>
              </v-col>
            </v-col>
            <v-col cols="12" v-for="i in localModule.levels" :key="i">
              <h4 class="font-14">
                المسؤول
                {{
                  i === 1
                    ? 'الأول'
                    : i === 2
                    ? 'الثاني'
                    : i === 3
                    ? 'الثالث'
                    : 'الرابع'
                }}
              </h4>
              <v-col
                class="pt-0 mobile__p__0 employee--select--col pr-0"
                cols="12"
                sm="12"
              >
                <v-select
                  ref="employees"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  :items="jobTitles"
                  placeholder="أختر المسمى الوظيفي"
                  append-icon="mdi-chevron-down"
                  dense
                  :rules="[usersRules]"
                  filled
                  item-text="name"
                  item-value="_id"
                  clearable
                  class="font-14"
                  hide-details
                  @change="setJobTitles(`title${i}`, i)"
                  v-model="localJobTitle[`title${i}`]"
                >
                  <template v-slot:append-item>
                    <div v-intersect="onIntersect" />
                  </template>
                </v-select>
              </v-col>
              <v-col
                class="pt-0 mobile__p__0 employee--select--col pr-0"
                cols="12"
                v-if="!employeesLevel[`level${i}`]?.length"
              >
                <v-card class="py-3 px-2">
                  <h4 class="ma-0 font-14" style="color: #662d91">
                    لا يوجد موظف مضاف لهذا المسمي الوظيفي
                  </h4>
                </v-card>
              </v-col>
              <v-col
                v-else
                class="pt-0 mobile__p__0 employee--select--col pr-0"
                cols="12"
                sm="12"
              >
                <v-select
                  ref="employees"
                  :menu-props="{
                    transition: 'slide-y-transition',
                    bottom: true,
                    offsetY: true
                  }"
                  :items="employeesLevel[`level${i}`]"
                  placeholder="أختر المسؤول عن الموافقة"
                  append-icon="mdi-chevron-down"
                  dense
                  :rules="[usersRules]"
                  filled
                  item-text="name"
                  item-value="_id"
                  clearable
                  class="font-14"
                  hide-details
                  v-model="localUseres[`user${i}`]"
                  :disabled="!hasUpdatePermission"
                >
                  <template v-slot:append-item>
                    <div v-intersect="onIntersect" />
                  </template>
                </v-select>
              </v-col>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <div class="textTile mt-5 pt-4 pb-5 px-4">
        <div class="holder d-flex align-center">
          <div class="mr-3 ml-5">
            <v-img :src="exclamationMarkrounded" />
          </div>
          <div class="text">
            <p class="font-12 font-weight-bold ma-0">
              يجب أن يوافق المستوى الأول لينتقل الطلب للثانى ثم الثالث ثم الرابع
            </p>
          </div>
          <v-spacer />
        </div>
      </div>

      <!----------3# Save----------->
      <v-card-actions class="pa-1 mt-2">
        <v-btn
          class="ma-0 rounded-7 my-2 ml-3 mr-auto font-14"
          color="primary"
          @click="addApproval"
          :loading="isRequestLoading"
          :disabled="!isValid || !hasUpdatePermission"
        >
          <span class="ml-1 font-weight-bold"> حفظ التغيرات </span>
          <v-icon class="send-icon font-16">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ApprovalModal } from '@/constans/approval'
import { userService } from '@/services'
import { excludeKeys, hasFalsyValue, hasValue } from '@/utils/general'
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'ApprovalModal',
  props: {
    dialogState: {
      type: Boolean,
      required: true
    },
    allEmployees: {
      type: Object,
      required: true
    },
    jobTitles: {
      type: Array,
      default: () => []
    },
    module: {
      type: Object,
      default: () => ({})
    },
    hasUpdatePermission: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      localModule: {},
      isValid: false,
      isRequestLoading: false,
      exclamationMarkrounded: require('@/assets/img/svgs/exclamationMarkrounded.svg'),
      selectRules: [(v) => !!v.length || 'هذا الحقل مطلوب'],
      levelRules: [(v) => !!v || 'هذا الحقل مطلوب'],
      modelTitle: 'تعديل الموافقات',
      ...ApprovalModal
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    employeesLevel() {
      return this.allEmployees
    },
    usersRules() {
      return !hasFalsyValue(this.localModule.users)
    },
    filteredActions() {
      return this.actions.filter((action) => {
        if (action.hasOwnProperty('access')) {
          return action.access.includes(this.localModule.code)
        }
        return true
      })
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    closeDialog() {
      this.$emit('closeDialog', {
        dialogState: false
      })
    },
    setJobTitles(title, level) {
      const selectedJobTitleId = this.localJobTitle[`${title}`]
      this.$emit('setJobTitles', selectedJobTitleId, level)
    },
    updateUsers(levels) {
      this.localModule.users = [
        ...(levels > 0 ? [this.localUseres.user1] : []),
        ...(levels > 1 ? [this.localUseres.user2] : []),
        ...(levels > 2 ? [this.localUseres.user3] : []),
        ...(levels > 3 ? [this.localUseres.user3] : [])
      ]
    },
    updateJobTitles(levels) {
      this.localModule.jobRoles = [
        ...(levels > 0 ? [this.localJobTitle.title1] : []),
        ...(levels > 1 ? [this.localJobTitle.title2] : []),
        ...(levels > 2 ? [this.localJobTitle.title3] : []),
        ...(levels > 3 ? [this.localJobTitle.title3] : [])
      ]
    },
    reloadApprovments() {
      // reload approvemtn after edit to get last changes
      this.$parent.$refs.Approvement[0].getApprovals()
    },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.$emit('loadMoreEmployees')
      }
    },
    reset() {
      this.localModule.users = []
      this.localModule.levels = 0
      this.localModule.actions = []
      this.localModule.jobRoles = []
      this.localModule.active = false
      this.localJobTitle.title1 = ''
      this.localJobTitle.title2 = ''
      this.localJobTitle.title3 = ''
      this.localJobTitle.title4 = ''
      this.localUseres.user1 = ''
      this.localUseres.user2 = ''
      this.localUseres.user3 = ''
      this.localUseres.user4 = ''
    },
    async addApproval() {
      this.updateJobTitles(this.localModule.levels)
      if (!this.localModule.active) {
        this.reset()
      }
      this.isRequestLoading = true
      const newData = excludeKeys(this.localModule, ['title', 'body', 'icon'])
      const data = {
        code: 'reports',
        environment: this.currentEnv._id,
        ...newData
      }
      try {
        await userService.updateApprovalSettings(data)
        this.addNotification({
          text: 'تم تعديل الموافقات بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تجديد الموافقات',
          color: 'error'
        })
      } finally {
        this.isRequestLoading = false
        this.closeDialog()
        this.reloadApprovments()
      }
    }
  },
  watch: {
    dialogState(val) {
      if (!val) {
        this.reset()
      }
    },
    localUseres: {
      deep: true,
      immediate: true,
      handler() {
        this.updateUsers(this.localModule.levels)
      }
    },
    'localModule.levels'() {
      this.updateUsers(this.localModule.levels)
      this.isValid = hasValue(this.localModule.users)
    },
    'localModule.active'(val) {
      if (val && !hasValue(this.localModule.users)) {
        this.isValid = false
      } else {
        this.isValid = true
      }
    },
    localJobTitle: {
      deep: true,
      immediate: true,
      handler() {
        if (hasValue(this.localModule.jobRoles)) {
          this.updateJobTitles(this.localModule.levels)
        }
      }
    },
    module: {
      deep: true,
      handler(val) {
        this.localModule = { ...this.module }
        if (hasValue(val.users)) {
          this.localUseres.user1 = val.users[0]
          this.localUseres.user2 = val.users[1]
          this.localUseres.user3 = val.users[2]
          this.localUseres.user4 = val.users[3]
        }
        if (hasValue(val.jobRoles)) {
          this.localJobTitle.title1 = val.jobRoles[0]
          this.localJobTitle.title2 = val.jobRoles[1]
          this.localJobTitle.title3 = val.jobRoles[2]
          this.localJobTitle.title4 = val.jobRoles[3]
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.holder {
  background-color: #efebf2;
  padding: 0.3rem 0.3rem;
  border-radius: 20px;
  color: #662d91;
}
</style>
