<template>
  <div>
    <loader v-if="isPageLoading" v-bind="loaderProps" class="mt-2" />
    <ServicesCard
      v-for="item in approvals"
      :key="item.title"
      v-bind="{
        template: item,
        isActiveOption: true,
        icon: item.icon,
        width: 20
      }"
      @openSettingsModal="openModel"
    />
  </div>
</template>

<script>
import { userService } from '@/services'
import { mapState, mapMutations } from 'vuex'
import { transformResponse } from '@/constans/approval'
import loader from '@/components/helper/loader.vue'

export default {
  components: {
    ServicesCard: () => import('../modelUint.vue'),
    loader
  },
  data: () => ({
    approvals: [],
    isPageLoading: false
  }),
  computed: {
    ...mapState('accountState', ['currentEnv']),
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    openModel(val) {
      const selectedApproval = this.getApprovalById(val?._id)
      this.$emit('openApprovalModal', {
        approval: selectedApproval,
        dialogState: true
      })
    },
    async getApprovals() {
      this.isPageLoading = true
      try {
        const {
          data: { approvalSettings }
        } = await userService.getApprovalSettings(this.currentEnv._id)
        this.approvals = approvalSettings.map(transformResponse)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل الموافقات',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    getApprovalById(id) {
      return this.approvals.find((item) => item._id === id)
    }
  },
  async mounted() {
    await this.getApprovals()
  }
}
</script>
