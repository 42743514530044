<template>
  <div class="drop__down__container">
    <h6
      class="mb-2 font-14 drop__down__title"
      :class="{ 'red--flag': validateStatus }"
      :style="{ color: titleColor }"
    >
      {{ configrations.name }}
      <span class="text-gray" v-if="isSubTitle"
        >( {{ configrations.subTitle }})
      </span>
    </h6>
    <v-row align="center" class="pa-1">
      <v-col
        :key="type._id"
        :md="configrations.cols"
        class="py-0 px-1 mobile__col__33__prcnt"
        v-for="type in listingType"
      >
        <h6
          :class="{ 'brub--tag-border': id === type._id }"
          data-test="filter-dropdown"
          @click="filterTypes(type)"
          class="gery-tag-border text-center rounded-7 px-3 py-2 cursor ml-1 mt-2"
        >
          {{ type.nameAr || type.text || type.name }}
        </h6>
      </v-col>
    </v-row>
    <h6
      class="mt-2 font-12 red--flag"
      v-if="disableState && !isDisableMessageNotification"
    >
      {{ disable.message }}
    </h6>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'dropdownAlt',
  props: {
    subTitle: {
      default: ''
    },
    titleColor: {
      default: ''
    },
    configrations: {},
    listingType: {},
    defaultValue: {
      default: ''
    },
    validate: {
      default: false
    },
    disable: {
      type: Object,
      default() {
        return {}
      }
    },
    restId: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: '',
      checked: false
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    filterTypes(payload) {
      if (payload && !Object.keys(payload).length) {
        // eslint-disable-next-line no-useless-return
        return
      } else if (!this.disableState) {
        this.id = payload._id
        this.$emit('setValidate')
        this.checked = true
        this.$emit('filterTypes', payload)
      } else {
        this.isDisableMessageNotification &&
          this.addNotification({
            text: this.disable?.message || `لا يمكن تغيير النوع`,
            color: 'error'
          })
        return null
      }
    },
    setDefaultValue() {
      const defaultVal = this.listingType.find(
        (v) =>
          this.defaultValue === v.text ||
          this.defaultValue === v.nameAr ||
          this.defaultValue === v.val ||
          this.defaultValue === v.name
      )
      this.id = defaultVal ? defaultVal._id : ''
      this.checked = !!defaultVal
    }
  },
  computed: {
    isSubTitle() {
      return this.configrations?.subTitle?.length
    },
    validateStatus() {
      return this.validate ? !this.checked : false
    },
    disableState() {
      return this.disable.status
    },
    isDisableMessageNotification() {
      return this.disable.messageType === 'notification'
    }
  },
  watch: {
    restId() {
      this.id = 2
    },
    defaultValue() {
      this.setDefaultValue()
    }
  },
  created() {
    this.setDefaultValue()
  }
}
</script>

<style lang="scss" scoped>
.drop__down__container {
  padding-right: 3px;
  padding-left: 12px;
}
</style>
