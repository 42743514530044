import config from '../config'
import axios from 'axios'

// Delete for client
const deleteClients = (envId, body) => {
  const url = `${config.usersUrl}/clients/${envId}`
  return axios.delete(url, { data: body })
}

// Delete for realestate
const deleteRealestate = (body) => {
  const url = `${config.realEstateUrl}/realEstates`
  return axios.delete(url, { data: body })
}

// Delete for contracts
const deleteContracts = (body) => {
  const url = `${config.bonds}/contracts`
  return axios.delete(url, { data: body })
}

// Delete for bonds
const deleteBonds = (body) => {
  const url = `${config.bonds}/bonds`
  return axios.delete(url, { data: body })
}

// Delete for tasks
const deleteTasks = (body) => {
  const url = `${config.usersUrl}/tasks`
  return axios.delete(url, { data: body })
}

// Delete for teams
const deleteTeams = (envId, body) => {
  const url = `${config.usersUrl}/invitations/${envId}`
  return axios.delete(url, { data: body })
}

// Delete for reports
const deleteReports = (body) => {
  const url = `${config.reportsUrl}/reports/delete-reports`
  return axios.post(url, body)
}

// Delete for taxinvoices
const deleteTaxInvoices = (body) => {
  const url = `${config.bonds}/taxBills`
  return axios.delete(url, { data: body })
}

export const MultipleDeletion = {
  deleteClients,
  deleteRealestate,
  deleteContracts,
  deleteBonds,
  deleteTasks,
  deleteTeams,
  deleteReports,
  deleteTaxInvoices
}
