import Vue from 'vue'

Vue.prototype.$subString = (string) => {
  if (!string) return ''
  const x = string.replace(' ', ',')
  const arr = x.split(',')
  const str = arr.map((str_) => {
    return str_.substring(0, 1)
  })
  return str.join(' ')
}
